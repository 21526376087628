import React from 'react'
import { HLRContext } from './heatloss_report'
import { HLRSubheaderBlock } from './partials/hlr_subheader'
import { numberFormat } from '../../../code/number_format'
import _ from 'lodash'
import { HLRListBlock } from './partials/hlr_list'

export const HLREmittersIntro = () => {
  const hlrContext = React.useContext(HLRContext)

  const designRadiators = hlrContext!.design.radiators

  const emitterReplacementRooms: string[] = _.uniq(hlrContext!.survey.floors.flatMap(f => f.rooms.flatMap(r => {
    const designRadiatorsForRoom = designRadiators.filter(x => x.room_uuid === r.uuid)
    if (designRadiatorsForRoom.length === 0) return []
    return r.name
  })))

  return <div className='flex-col gap-6 flex'>
    <HLRSubheaderBlock
      section="System design"
      title="Emitters"
    />
    <div>Emitters (i.e., radiators/fan coils/underfloor heating) are a vital part of your heating system. They take the heat produced by the heat pump and distribute that heat into your rooms.</div>
    <div>Based on the room by room heat loss results, we propose the following emitter design for your home. This design will ensure each room can be heated to its set point when it's {numberFormat(1).format(hlrContext!.designTempC)} °C outside whilst maintaining high system efficiency.</div>

    <div>
      <div className="text-gray-900 text-lg font-bold">Design conditions</div>
      <HLRListBlock items={[
        <>Flow temperature = {hlrContext!.design.flow_temp} °C</>,
        <>Temperature drop across the radiator (delta T) = {hlrContext!.design.delta_t_flow_return_c} °C</>,
        <>Mean radiator temperature = {hlrContext!.design.flow_temp - (hlrContext!.design.delta_t_flow_return_c / 2)} °C</>
      ]} />
    </div>

    { emitterReplacementRooms.length > 0 && <div>
      <div className="text-gray-900 text-lg font-bold">Emitter replacements</div>
      <div>We propose replacing or adding emitters in the following rooms:</div>
      <HLRListBlock items={emitterReplacementRooms.map(x => <>{x}</>)} isNumbered={true} />
    </div>}

  </div>
}
