import { apiUrl, axiosDelete, axiosPatch, axiosPost, client } from '../axios'
import { AuthSDK } from '../utils/auth_provider'
import { parseWithZod } from '../zod'
import { InventoryHeatPumpSchema, type InventoryHeatPump } from './inventory'

export const getHeatPumps = async (companyUUID: string): Promise<InventoryHeatPump[] | undefined> => {
  try {
    const result = await client.get<{ data: Array<{ uuid: string, attributes: Record<any, any> }> }>(`${apiUrl}teams/${companyUUID}/heat-pumps`,
      { headers: { 'x-auth-token': AuthSDK.getToken() } }
    )
    const parsedResult = result.data.data.map(x => parseWithZod<InventoryHeatPump>(InventoryHeatPumpSchema, ({ ...x.attributes, uuid: x.uuid })))
    return parsedResult
  } catch (e: unknown) {
    console.error('Error getting heat pumps', e)
    return undefined
  }
}

export const updateHeatPump = async (heatPump: InventoryHeatPump, companyUUID: string) => {
  await axiosPatch(
    `teams/${companyUUID}/heat-pumps/${heatPump.uuid}`,
    {
      name: heatPump.name,
      description: heatPump.description,
      cost_price: heatPump.cost_price,
      markup: heatPump.markup,
      range_heat_pump_uuid: heatPump.range_heat_pump_uuid,
      default_pack_uuid: heatPump.default_pack_uuid
    },
    heatPump.uuid,
    'heat-pumps'
  )
}

export const insertHeatPump = async (heatPump: InventoryHeatPump, companyUUID: string) => {
  return await axiosPost(
    `teams/${companyUUID}/heat-pumps`,
    {
      uuid: heatPump.uuid,
      name: heatPump.name,
      description: heatPump.description,
      cost_price: heatPump.cost_price,
      markup: heatPump.markup,
      range_heat_pump_uuid: heatPump.range_heat_pump_uuid
    },
    'heat-pumps'
  )
}

export const deleteHeatPump = async (heatPumpUUID: string, companyUUID: string) => {
  await axiosDelete(
    `teams/${companyUUID}/heat-pumps/${heatPumpUUID}`
  )
}
