import React, { type Dispatch, type SetStateAction } from 'react'
import { type PropertySurvey } from '../../../code/models/property'
import { type FileWrapper } from '../file_wrapper'
import { SurveySectionFlowWrapper } from './components/survey_section_flow_wrapper'
import type { PagesFlowPage } from '../../../code/use_pages_flow'
import { getSiteDetailsChecks } from './validations'
import { type Lead } from '../../../code/models/lead'
import { SiteDetailsLocationBlock } from './site_details/site_details_location_block'
import { SiteDetailsPhotosBlock } from './site_details/site_details_photos_block'
import { SiteDetailsTypeBlock } from './site_details/site_details_type_block'
import { SiteDetailsParkingBlock } from './site_details/site_details_parking_block'
import { SiteDetailsMainsWaterPhotosBlock } from './site_details/site_details_mains_water_photos_block'
import { SiteDetailsMainsWaterDetailsBlock } from './site_details/site_details_mains_water_details_block'
import { type SetIndexedDb } from '../../admin/job_layout/job_layout'

type Props = {
  lead: Lead | undefined
  postcodeLocation: { lat: number, lng: number }
  survey: PropertySurvey
  setSurvey: SetIndexedDb<PropertySurvey>
  files: FileWrapper[]
  setFiles: Dispatch<SetStateAction<FileWrapper[]>>
  companyUUID: string
  isOffline: boolean
  allSynced: boolean
}

export const SurveySiteDetailsContext = React.createContext<Props | undefined>(undefined)

export const SiteDetails = (props: Props) => {
  // WARNING! Never change flagBits for the pages because they are stored in the DB.
  // instead, add new pages or replace existing with new flags
  const pages = [
    { flagBit: 0x001, pbValue: 15, page: <SiteDetailsLocationBlock /> },
    { flagBit: 0x002, pbValue: 30, page: <SiteDetailsPhotosBlock /> },
    { flagBit: 0x004, pbValue: 45, page: <SiteDetailsTypeBlock /> },
    { flagBit: 0x008, pbValue: 60, page: <SiteDetailsParkingBlock /> },
    { flagBit: 0x010, pbValue: 75, page: <SiteDetailsMainsWaterPhotosBlock /> },
    { flagBit: 0x020, pbValue: 100, page: <SiteDetailsMainsWaterDetailsBlock /> }
  ] as PagesFlowPage[]

  return <SurveySiteDetailsContext.Provider value={props}>
    <SurveySectionFlowWrapper
      isOffline={props.isOffline}
      allSynced={props.allSynced}
      completeSectionID={'PROPERTY'}
      sectionUIName={'Site settings'}
      pages={pages}
      flags_attribute_name={'flags_site_details_pages_completed'}
      onPageComplete={(flagBit) => {
        props.setSurvey({ ...props.survey, flags_site_details_pages_completed: props.survey.flags_site_details_pages_completed | flagBit })
      }}
      survey={props.survey}
      onFormComplete={() => window.history.back()}
      setSurvey={props.setSurvey}
      completionChecks={getSiteDetailsChecks(props.survey)}
    />
  </SurveySiteDetailsContext.Provider>
}
