import React from 'react'
import { type Company } from '../../../../../code/models/company'
import { Section } from '../../../../../components/containers/section'
import { TabGroup } from '../../../../../components/content_display/tab'
import { FormLabel } from '../../../../../components/inputs_and_selections/form_label'
import { Info } from '../../../../../components/buttons/info'
import { VerticalFormGroup } from '../../../../../components/inputs_and_selections/vertical_form_group'
import { Heading } from '../../../../../components/content_display/heading'
import { Input } from '../../../../../components/inputs_and_selections/input'
import { TextArea } from '../../../../../components/inputs_and_selections/text_area'

export type Step = {
  title: string
  description: string
}

// Spruce default next steps
export const NEXT_STEPS_ENGLAND_WALES: Step[] = [
  {
    title: 'View your cost estimates',
    description: "Don't worry about making a decision now, at this point we want to understand your needs and preferences so we can work together to find the right option for you."
  },
  {
    title: 'Book a full heat loss survey',
    description: "If you'd like to proceed, then the next step is to conduct an on-site survey of your home and begin creating a quote using the data we gather."
  },
  {
    title: 'Arrange installation',
    description: 'Once the survey has been completed and we have formalised a quote, the final step is to arrange installation.'
  }
]
export const NEXT_STEPS_SCOTLAND: Step[] = [
  {
    title: 'Book a full heat loss survey',
    description: 'The next step is to conduct an on-site survey of your home and create a detailed proposal.'
  },
  {
    title: 'Apply for HES funding',
    description: 'Submit your application for funding through Home Energy Scotland.'
  },
  {
    title: 'Schedule your installation',
    description: 'Once the funding is secured, the final step is to arrange the installation of your heat pump.'
  }
]

type NextStepsConfigurationProps = {
  region: 'England/Wales' | 'Scotland'
  company: Company
  setCompany: (c: Company) => void
}

const NextStepsConfiguration = ({ region, company, setCompany }: NextStepsConfigurationProps) => {
  const defaultNextSteps = region === 'England/Wales' ? NEXT_STEPS_ENGLAND_WALES : NEXT_STEPS_SCOTLAND
  const next_steps_key = region === 'England/Wales' ? 'next_steps' : 'next_steps_scottish'

  const handleUpdateSteps = (stepIndex: number, value: Step) => {
    // Parse company steps to Step[]
    // Update the step at stepIndex
    // Set company steps to newSteps (after converting it back into JSON)
    const newSteps = (company.public_info[next_steps_key] ? JSON.parse(company.public_info[next_steps_key]!) : defaultNextSteps).map((step, index) => index === stepIndex ? value : step)
    setCompany({ ...company, public_info: { ...company.public_info, [next_steps_key]: JSON.stringify(newSteps) } })
  }

  const parsedCompanyNextSteps = company.public_info[next_steps_key] ? JSON.parse(company.public_info[next_steps_key]!) : undefined

  return <div className='flex flex-col gap-8'>
    {[...Array(3)].map((e, index) => <div className='flex flex-col gap-4' key={`next_steps_${index}`}>
      <Heading size='lg'>Step {index + 1}</Heading>
      <VerticalFormGroup
        formLabel={<FormLabel labelText='Title' />}
        input={<Input
          value={(parsedCompanyNextSteps ?? defaultNextSteps)[index].title}
          setValue={val => handleUpdateSteps(index, { ...(parsedCompanyNextSteps ?? defaultNextSteps)[index], title: val })}
        />}
      />
      <VerticalFormGroup
        formLabel={<FormLabel labelText='Description' />}
        input={<TextArea
          value={(parsedCompanyNextSteps ?? parsedCompanyNextSteps ?? defaultNextSteps)[index].description}
          setValue={val => handleUpdateSteps(index, { ...(parsedCompanyNextSteps ?? defaultNextSteps)[index], description: val })}
        />}
      />
    </div>)}
  </div>
}

export const EstimateNextStepsBlock = ({ company, setCompany }: { company: Company, setCompany: (c: Company) => void }) => {
  const [currentTab, setCurrentTab] = React.useState<'England/Wales' | 'Scotland'>('England/Wales')

  return <Section border title={<div className='flex items-center gap-1'>Next steps <Info infoModalHeader="Property area" infoModalBody="We allow you to customise the 'next steps' depending on property location as we find this process usually varies.
    We show the homeowner the correct information depending on their location."/></div>}>
    <div className='flex flex-col gap-4'>
      <TabGroup items={[
        { name: 'England/Wales', onClick: () => setCurrentTab('England/Wales'), variant: currentTab === 'England/Wales' ? 'ACTIVE' : 'DEFAULT' },
        { name: 'Scotland', onClick: () => setCurrentTab('Scotland'), variant: currentTab === 'Scotland' ? 'ACTIVE' : 'DEFAULT' }
      ]}
      />
      <NextStepsConfiguration region={currentTab} company={company} setCompany={setCompany} />
    </div>
  </Section>
}
