import { type Floor } from '../../../../code/models/floor'
import { type PropertySurvey } from '../../../../code/models/property'
import { generateComplexRoom } from '../../add_room'
import { type SprucePoint } from './types'
import { calculateLineLength, calculateNewTouchingWalls } from './utils'
import { type KonvaEventObject } from 'konva/lib/Node'
import { type Dispatch, type SetStateAction } from 'react'
import { type CurrentFloorPlanPage } from '../floor'
import { type UndoEvent } from '../floor_canvas/undo'
import booleanClockwise from '@turf/boolean-clockwise'
import { getFloorAreaM2 } from '../../../../code/models/heat_loss'
import { type WallWindow } from '../../../../code/models/window'
import { snapPoint } from '../room_drawing'
import { type Door } from '../../../../code/models/door'

export const stageOnClick = (
  e: KonvaEventObject<Event>,
  drawingRooms: boolean,
  mousePosition: SprucePoint | undefined,
  scalingWindow: boolean,
  scalingDoor: boolean,
  scalingPoints: SprucePoint[],
  floor: Floor,
  survey: PropertySurvey,
  stageStep: number | undefined,
  stageScale: number,
  showUploadWidthPage: boolean,
  setScalingPoints: Dispatch<SetStateAction<SprucePoint[]>>,
  setCurrentRoomId: Dispatch<SetStateAction<string | undefined>>,
  setStageStep: Dispatch<SetStateAction<number>>,
  setPage: (page: CurrentFloorPlanPage) => void,
  setCurrentWallId: Dispatch<SetStateAction<string | undefined>>,
  setDrawingRooms: Dispatch<SetStateAction<boolean>>,
  setMousePosition: Dispatch<SetStateAction<SprucePoint | undefined>>,
  addEvent: (events: UndoEvent[]) => void,
  setScalingWindow: Dispatch<SetStateAction<boolean>>,
  setScalingDoor: Dispatch<SetStateAction<boolean>>,
  setWindow: Dispatch<SetStateAction<WallWindow>>,
  setDoor: Dispatch<SetStateAction<Door>>,
  setIsPageOpen: Dispatch<SetStateAction<boolean>>
) => {
  // Mouse position is updated on hover (onMouseMove), for mobile devices this is the first time we see mouse so get pointer position.
  let newPoint = mousePosition!
  if (!newPoint) {
    const pointer = e.currentTarget.getRelativePointerPosition()!
    const fakeRoom = generateComplexRoom(floor, survey, scalingPoints, 2.4)
    const snappedPoint = snapPoint(pointer, [...floor.rooms, fakeRoom], stageScale, [], 50)?.newPoint ?? pointer
    newPoint = { x: Math.round(snappedPoint.x / stageScale), y: Math.round(snappedPoint.y / stageScale) }
  }

  // If I am drawing a room either create a point, or I have completed my shape so create a new room.
  if (drawingRooms) {
    if (!newPoint) return

    // If the shape is greater than a line (points > 2) we can form a shape.
    // Check if the last point I made is equal to the first point I made, if so I have a closed room and can finish the room drawing.
    // Otherwise add a new point to the canvas and continue drawing.
    if (scalingPoints.length > 2 && newPoint.x === scalingPoints[0].x && newPoint.y === scalingPoints[0].y) {
      const newRoom = generateComplexRoom(floor, survey, scalingPoints, floor.default_room_height)
      const wallsToXY = newRoom.walls.map(x => [x.x!, x.y!])

      // Don't let invalid room shapes get created.
      const area = getFloorAreaM2(newRoom.walls)
      if (area <= 0) return

      // Make sure the room is always drawn counter clockwise, turf requires a closed shape to repeat its first element.
      // Because the canvas north is negative and south is positive (reverse of cartesian coordinates) turfs clockwise is actually counter clockwise.
      const newRoomFixRotation = booleanClockwise([...wallsToXY, wallsToXY[0]])
        ? newRoom
        : { ...newRoom, walls: newRoom.walls.reverse() }

      const newRooms = calculateNewTouchingWalls([...floor.rooms, newRoomFixRotation], survey.default_materials!)

      setDrawingRooms(false)
      setScalingPoints([])
      setMousePosition(undefined)
      addEvent([{ type: 'FLOOR', action: 'UPDATE', oldValue: floor, newValue: { ...floor, rooms: newRooms } }])
      setCurrentRoomId(newRoom.uuid)
      setPage('ROOM_DETAILS')
    } else {
      setScalingPoints(prev => [...prev, newPoint])
      setMousePosition(undefined)
    }

    return
  }

  if ((scalingWindow || scalingDoor) && newPoint && scalingPoints.length < 2) {
    if (scalingPoints.length < 1) {
      setScalingPoints(prev => [...prev, newPoint])
    } else {
      const lineLengthMM = calculateLineLength(scalingPoints[0].x, scalingPoints[0].y, newPoint.x, newPoint.y) * 1000

      if (scalingWindow) {
        setWindow(prev => ({ ...prev, width_mm: lineLengthMM, updated_at: new Date().getTime() }))
        setScalingWindow(false)
      }

      if (scalingDoor) {
        setDoor(prev => ({ ...prev, width_mm: lineLengthMM, updated_at: new Date().getTime() }))
        setScalingDoor(false)
      }

      setMousePosition(undefined)
      setScalingPoints([])
    }

    return
  }

  // If I am trying to scale a floor plan not draw a room.
  if (stageStep && newPoint && scalingPoints.length < 2 && !showUploadWidthPage) {
    setScalingPoints(prev => [...prev, newPoint])
    setStageStep(prev => prev + 1)
    if (stageStep === 2) setIsPageOpen(true)

    setMousePosition(undefined)
    return
  }

  // If I have clicked the stage and not another element I want to remove my room or wall selection.
  if (e.target === e.currentTarget) {
    setCurrentRoomId(undefined)
    setCurrentWallId(undefined)
    setPage('FLOOR_DETAILS')
  }
}
