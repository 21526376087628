import React from 'react'
import { ClickableCard } from '../../../components/content_display/card'
import { HLRContext } from './heatloss_report'
import {
  AGILE_PENCE_PER_KWH,
  AGILE_PRICE_EXPLANATION,
  OVO_HEAT_PUMP_PLUS_PENCE_PER_KWH,
  PRICE_CAP_ELECTRICITY_PENCE_PER_KWH
} from '../../../code/models/heating_fuel'
import { HLRListBlock } from './partials/hlr_list'

export const HLRPESystemEfficiency2 = () => {
  const hlrContext = React.useContext(HLRContext)
  const hddPerformanceEstimate = hlrContext!.performanceEstimateSummary.hddEstimate
  return (
    <div className='flex gap-6 flex-col'>
      <div>
        If you have solar (or are planning to install it) your electricity prices will also be lower because your heat
        pump will use some of the free electricity that your solar is generating. This is especially true for hot water
        consumption because you can schedule your hot water to run in the middle of the day.
      </div>

      <div className='grid grid-cols-1 sm:grid-cols-2 gap-4'>
        <ClickableCard variant="GREY" className='bg-gray-100 rounded-md p-5'>
          <div className='flex flex-col gap-4'>
            <div className='flex flex-row justify-between'>
              <div className="text-gray-900 font-bold">{hddPerformanceEstimate.existing.fuelName}</div>
              <div className="text-gray-900 font-bold">Unit price</div>
            </div>

            <div>
              <div className="py-2 items-baseline gap-2 flex justify-between">
                <div className="text-gray-900 text-xs font-semibold">Price cap</div>
                <div className="text-xs font-semibold text-nowrap">{hddPerformanceEstimate.existing.pencePerKwhPriceCap.toFixed(2)} p/kWh</div>
              </div>
            </div>
          </div>
        </ClickableCard>

        <ClickableCard variant="GREY" className='bg-gray-100 rounded-md p-5'>
          <div className='flex flex-col gap-4'>
            <div className='flex flex-row justify-between'>
              <div className="text-gray-900 font-bold">Electricity</div>
              <div className="text-gray-900 font-bold">Unit price</div>
            </div>

            <div>
              <div className="py-2 border-b border-gray-200 border-dashed items-baseline gap-2 flex justify-between">
                <div className="text-gray-900 text-xs font-semibold">Price cap</div>
                <div className="text-xs font-semibold text-nowrap">{PRICE_CAP_ELECTRICITY_PENCE_PER_KWH} p/kWh</div>
              </div>
              {hlrContext?.eligibleForHeatPumpPlus && <div className="py-2 border-b border-gray-200 border-dashed items-baseline gap-2 flex justify-between">
                <div className="text-gray-900 text-xs font-semibold">OVO Heat Pump Plus*</div>
                <div className="text-xs font-semibold text-nowrap">{OVO_HEAT_PUMP_PLUS_PENCE_PER_KWH} p/kWh</div>
              </div>}
              <div className="py-2 border-b border-gray-200 border-dashed items-baseline gap-2 flex justify-between">
                <div className="text-xs"><span className='text-gray-900 font-semibold'>Octopus Agile</span> last winter
                  average**
                </div>
                <div className="text-xs font-semibold text-nowrap">{AGILE_PENCE_PER_KWH} p/kWh</div>
              </div>
            </div>
            <div>
              <div className="text-xs">*Currently only available for Vaillant, Viessmann and Mitsubishi heat pumps</div>
              <div className="text-xs">**{AGILE_PRICE_EXPLANATION}</div>
            </div>
          </div>
        </ClickableCard>
      </div>

      <div className='flex flex-col gap-2'>
        <div className="">
          You may want to check out some of these heat pump tariffs (we haven’t vetted these):
        </div>

        <div className='font-bold'>Type of use:</div>
        <HLRListBlock items={[
          <><a className='underline' href='https://www.ovoenergy.com/heat-pump-plus'>OVO heat pump plus</a>: {OVO_HEAT_PUMP_PLUS_PENCE_PER_KWH}p/kWh. Vaillant, Viessmann and Mitsubishi heat pumps only currently.</>
        ]} />

        <div className='font-bold'>Time of use:</div>
        <HLRListBlock items={[
          <><a className='underline' href='https://www.edfenergy.com/heating/electric/air-source-heat-pump-tariff/trial'>EDF</a>: 10p/kWh off from 4-7am and from 1-4pm</>,
          <><a className='underline' href='https://octopus.energy/smart/cosy-octopus/'>Octopus Cosy</a>: Cheaper pricing 4am-7am and 1-4pm. Peak pricing 4pm -7pm</>,
          <><a className='underline' href='https://octopus.energy/smart/agile/'>Octopus Agile</a>: Half-hourly pricing which tracks the wholesale price. Goes both very high and negative.</>
        ]} />

        <div className='flex flex-col gap-2'>
          <div className="text-gray-900 text-lg font-bold">Emission factors</div>
          <div>We’ve used the emission factors from the government’s SAP 2010 methodology. For gas this value is fixed
            but for electricity it is falling over time as the grid decarbonises. This means your system will get
            cleaner and cleaner. The value used here is based on a projected continued reduction in grid carbon
            intensity.
          </div>
        </div>

        <div className='grid grid-cols-1 sm:grid-cols-2 gap-4'>
          <ClickableCard variant="GREY" className='bg-gray-100 rounded-md p-5'>
            <div className='flex flex-col gap-4'>
              <div className="text-gray-900 font-bold">Gas</div>
              <div className="gap-1 flex items-end">
                <div className="text-gray-900 text-4xl font-bold">{hddPerformanceEstimate.existing.emissionsFactorGPerKwh}</div>
                <div className="text-lg">gCO2/kWh</div>
              </div>
            </div>
          </ClickableCard>

          <ClickableCard variant="GREY" className='bg-gray-100 rounded-md p-5'>
            <div className='flex flex-col gap-4'>
              <div className="text-gray-900 font-bold">Electricity</div>
              <div className="gap-1 flex items-end">
                <div className="text-gray-900 text-4xl font-bold">{hddPerformanceEstimate.proposed.emissionsFactorGPerKwh}</div>
                <div className="text-lg">gCO2/kWh</div>
              </div>
            </div>
          </ClickableCard>
        </div>

      </div>

    </div>
  )
}
