import React, { useState } from 'react'
import { Modal } from '../../../components/containers/modal'
import { duplicateLead, type Lead } from '../../../code/models/lead'
import { Input } from '../../../components/inputs_and_selections/input'
import { validateNotNull } from '../../../code/validators'
import type { CompanyPublicInfo } from '../../../code/models/company'

type Props = {
  visible: boolean
  setVisible: (visible: boolean) => void
  lead: Lead
  redirectToSurvey?: boolean
  companyPublicInfo: CompanyPublicInfo
  navigateTo: (url: string) => void
}

export const DuplicateLeadModal = ({ visible, setVisible, lead, redirectToSurvey, companyPublicInfo, navigateTo }: Props) => {
  const [name, setName] = useState<string>((lead.customer?.name || '') + ' (copy)')

  const handleConfirm = async () => {
    const newLead = await duplicateLead(lead, companyPublicInfo.uuid, name)
    if (newLead) {
      const url = redirectToSurvey
        ? `/${companyPublicInfo.subdomain}/admin/surveys/${newLead.uuid}`
        : `/${companyPublicInfo.subdomain}/admin/quotes/${newLead.uuid}`

      navigateTo(url)
    }
  }

  return <Modal
    visible={visible}
    setVisible={setVisible}
    title='Duplicate job?'
    onConfirm={handleConfirm}
    confirmButtonLabel='Duplicate'
    confirmDisabled={!validateNotNull(name).value}
    allowOverflow={true}
  >
    <Input
      className='w-full'
      value={name}
      setValue={setName}
      validator={validateNotNull}
      label='Enter a name for the duplicated job'
      onEnter={() => {
        if (validateNotNull(name).value) {
          handleConfirm()
          setVisible(false)
        }
      }}
    />
  </Modal>
}
