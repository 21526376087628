import React from 'react'
import { Heading } from '../../../../components/content_display/heading'

type Props = {
  title?: string | React.ReactNode
  controls?: React.ReactNode
  children: React.ReactNode
  className?: string
}

export const Section = ({ title, controls, children, className }: Props) => {
  return (
    <div className={`p-5 border border-gray-200 bg-white rounded-md flex flex-col gap-5 ${className}`}>
      {title && <div className='flex justify-between items-center'>
        {typeof title === 'string' ? <Heading size="xl">{title}</Heading> : title}
        {controls}
      </div>}
      {children}
    </div>
  )
}
