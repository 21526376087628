import { type Vector2d } from 'konva/lib/types'
import { chain, cloneDeep, indexOf, max, min, minBy, uniq, uniqBy } from 'lodash'
import { type Dispatch, type SetStateAction } from 'react'
import { type Floor } from '../../../../code/models/floor'
import { type Room } from '../../../../code/models/room'
import { type Wall } from '../../../../code/models/wall'
import { ANGLE_TOLERANCE_DEGREES, calculateCentroid } from '../../constants'
import { createNewTouchingWalls, isPointOnLineSegment } from '../code'
import { maxScale, minScale } from './constants'
import { type SprucePoint } from './types'
import { numberFormat } from '../../../../code/number_format'
import { type SpruceLine } from '../line_with_text'
import { type MaterialsSet } from '../../../../code/models/material'

export const calculateRotation = (x1: number, y1: number, x2: number, y2: number) => {
  const dx = x2 - x1
  const dy = y2 - y1

  const radians = Math.atan2(dy, dx)
  const degrees = radians * (180 / Math.PI)

  // Keep it a value between 0-360
  return degrees < 0 ? degrees + 360 : degrees
}

export const fixRotation = (x1: number, x2: number, y1: number, y2: number): { x1: number, x2: number, y1: number, y2: number } => {
  if (x1 > x2) return { x1: x2, x2: x1, y1, y2 }
  if (y1 > y2) return { x1, x2, y1: y2, y2: y1 }

  return { x1, x2, y1, y2 }
}

export const getCenter = (p1, p2) => {
  return {
    x: (p1.x + p2.x) / 2,
    y: (p1.y + p2.y) / 2
  }
}

export const getDistance = (p1, p2) => {
  return Math.sqrt(Math.pow(p2.x - p1.x, 2) + Math.pow(p2.y - p1.y, 2))
}

export const wallToLine = (wall: Wall, nextWall: Wall, room: Room): SpruceLine => {
  return {
    uuid: wall.uuid!,
    p1: { x: wall.x! + room.x!, y: wall.y! + room.y! },
    p2: { x: nextWall.x! + room.x!, y: nextWall.y! + room.y! }
  }
}

export const simplifyRooms = (rooms: Room[]) => {
  return rooms.map(room => {
    const simplifiedWalls: Wall[] = []

    // Remove duplicate points otherwise they will kill each other below as both exist on each others lineSegment.
    // Combine the doors/windows of duplicate points so we don't lose any during simplify.
    const groupedWalls: Wall[] = chain(room.walls)
      .groupBy(x => `${x.x}-${x.y}`)
      .map((values) => ({ ...values[0], windows: values.flatMap(x => x.windows), doors: values.flatMap(x => x.doors) }))
      .value()

    // Iterate through vertices and identify consecutive duplicates
    for (let i = 0; i < groupedWalls.length; i++) {
      const currentVertex = groupedWalls[i]

      const prevVertex = getPrevWall(currentVertex, groupedWalls)
      const nextVertex = getNextWall(currentVertex, groupedWalls)

      const rotation1 = calculateRotation(prevVertex.x!, prevVertex.y!, currentVertex.x!, currentVertex.y!)
      const rotation2 = calculateRotation(prevVertex.x!, prevVertex.y!, nextVertex.x!, nextVertex.y!)

      if (
        Math.abs(rotation2 - rotation1) < ANGLE_TOLERANCE_DEGREES &&
        isPointOnLineSegment(wallToLine(prevVertex, nextVertex, room), { x: currentVertex.x! + room.x!, y: currentVertex.y! + room.y! }, ANGLE_TOLERANCE_DEGREES)) {
        prevVertex.doors = uniqBy([...prevVertex.doors, ...currentVertex.doors], x => x.uuid)
        prevVertex.windows = uniqBy([...prevVertex.windows, ...currentVertex.windows], x => x.uuid)
        continue // Skip redundant vertex
      }

      simplifiedWalls.push(currentVertex)
    }

    return { ...room, walls: simplifiedWalls }
  })
}

export const removeInvalidRoomGroups = (rooms: Room[]) => {
  return chain(rooms)
    .groupBy(x => x.room_group_uuid)
    .flatMap((values, key) => {
      return values.map(x => {
        const otherRoomUUIDs = uniq(x.walls.map(x => x.other_room_uuid))
        return values.some(y => otherRoomUUIDs.includes(y.uuid)) ? x : { ...x, room_group_uuid: undefined }
      })
    })
    .value()
}

export const areLinesSame = (line1: SpruceLine, line2: SpruceLine) => {
  const normLine1 = normalizeLine(line1)
  const normLine2 = normalizeLine(line2)

  return (normLine1.p1.x === normLine2.p1.x &&
      normLine1.p1.y === normLine2.p1.y &&
      normLine1.p2.x === normLine2.p2.x &&
      normLine1.p2.y === normLine2.p2.y)
}

export const calculateNewTouchingWalls = (rooms: Room[], defaultMaterials: MaterialsSet) => {
  const newRooms = cloneDeep(rooms)

  for (const room of newRooms) {
    const otherWalls = rooms.filter(x => x.uuid !== room.uuid).flatMap(r => {
      const walls = r.walls.map(w => {
        const nextWall = getNextWall(w, r.walls)
        return wallToLine(w, nextWall, r)
      })

      return walls
    })

    const newWalls: Wall[] = []
    for (const wall of room.walls) {
      const nextWall = getNextWall(wall, room.walls)
      const newWallSegments = createNewTouchingWalls(wallToLine(wall, nextWall, room), otherWalls)
      if (newWallSegments.length === 0 || newWallSegments.length === 1) {
        newWalls.push(wall)
        continue
      }

      newWalls.push(...newWallSegments.map(x => ({
        ...wall,
        uuid: x.uuid,
        x: Math.round((x.p1.x - room.x!)),
        y: Math.round((x.p1.y - room.y!))
      })))
    }
    room.walls = newWalls
  }

  for (const room of newRooms) {
    const otherWalls = newRooms.filter(x => x.uuid !== room.uuid).flatMap(r => {
      const walls = r.walls.map(w => {
        const nextWall = getNextWall(w, r.walls)
        return { ...wallToLine(w, nextWall, r), room_uuid: r.uuid }
      })

      return walls
    })

    for (const wall of room.walls) {
      const nextWall = getNextWall(wall, room.walls)
      const anySame = otherWalls.find(x => areLinesSame(x, wallToLine(wall, nextWall, room)))
      const isInternal = wall.material?.applicable_to === 'internal-wall'
      if (anySame) {
        wall.other_room_uuid = anySame.room_uuid
        wall.material = isInternal ? wall.material : defaultMaterials.internalWall
        wall.windows = []
        wall.doors = []
      } else {
        wall.other_room_uuid = undefined
        if (isInternal) { wall.material = defaultMaterials.externalWall }
      }
    }
  }

  return newRooms
}

const normalizeLine = (line: SpruceLine) => {
  const { p1, p2 } = line

  if (p1.x < p2.x || (p1.x === p2.x && p1.y < p2.y)) {
    return { p1, p2 }
  } else {
    return { p1: p2, p2: p1 }
  }
}

export const getEmitterOutputVsDemandText = (wattsEmitted: number, wattsLost: number) => {
  return `${numberFormat(0).format(wattsEmitted)} W of ${numberFormat(0).format(wattsLost)} W`
}

export const getPercentageText = (wattsEmitted: number, wattsLost: number) => {
  if (wattsLost < 0) { return `${Math.round(wattsLost)} W` } // Don't show percentage of negative heat loss values as very confusing
  const percentage = Math.round((wattsEmitted / wattsLost) * 100)
  return `${percentage}% of ${Math.round(wattsLost)} W`
}

// This method assumes 2 adjacent polygons have had their wall split already if they are only partially covering another wall.
// Find the room furthest left (x) on the map, set it to room1 if not already
// Starting in room1 go counterclockwise, add walls until you find a matching x,y pair in room2 (ignore if your starting x,y matches)
// in room2 go counterclockwise until my start x,y equals the start x,y of room1
// add the remainder of room1 until you are back at your starting location
// https://spruceretrofit.slack.com/files/U057AGU4750/F07HEE6KG72/screenshare_-_2024-08-13_5_20_25_pm.webm
export const mergeRooms = (room1: Room, room2: Room): Room | undefined => {
  if (!room1.walls.find(x => !!x.other_room_uuid) && !room2.walls.find(x => !!x.other_room_uuid)) return
  const roomBaseXandY = room1.x! <= room2.x! ? room1 : room2

  // Circular rotate both arrays so that the left/top most point always starts first, and the room1 shape is always the left most shape.
  // Now you can always assume for any 2 merge shapes start in the top left corner and work anti-clockwise around the perimiter.
  const absolutePositionRoom1Walls = room1.walls.map(x => ({ ...x, x: x.x! + room1.x!, y: x.y! + room1.y! }))
  const room1MinIndex = indexOf(absolutePositionRoom1Walls, minBy(absolutePositionRoom1Walls, x => x.x + x.y))
  const rotatedAbsolutePositionRoom1Walls = [...absolutePositionRoom1Walls.slice(room1MinIndex), ...absolutePositionRoom1Walls.slice(0, room1MinIndex)]

  const absolutePositionRoom2Walls = room2.walls.map(x => ({ ...x, x: x.x! + room2.x!, y: x.y! + room2.y! }))
  const room2MinIndex = indexOf(absolutePositionRoom2Walls, minBy(absolutePositionRoom2Walls, x => x.x + x.y))
  const rotatedAbsolutePositionRoom2Walls = [...absolutePositionRoom2Walls.slice(room2MinIndex), ...absolutePositionRoom2Walls.slice(0, room2MinIndex)]

  const firstRoom = rotatedAbsolutePositionRoom1Walls[0].x + rotatedAbsolutePositionRoom1Walls[0].y < rotatedAbsolutePositionRoom2Walls[0].x + rotatedAbsolutePositionRoom2Walls[0].y ? rotatedAbsolutePositionRoom1Walls : rotatedAbsolutePositionRoom2Walls
  const secondRoom = rotatedAbsolutePositionRoom1Walls[0].x + rotatedAbsolutePositionRoom1Walls[0].y < rotatedAbsolutePositionRoom2Walls[0].x + rotatedAbsolutePositionRoom2Walls[0].y ? rotatedAbsolutePositionRoom2Walls : rotatedAbsolutePositionRoom1Walls

  // Now we have sorted the arrays do the main merging logic.
  const room1EndIndex = firstRoom.findIndex(r1 => secondRoom.some(r2 => r1.x === r2.x && r1.y === r2.y))
  const room2StartIndex = secondRoom.findIndex(x => firstRoom[room1EndIndex].x === x.x && firstRoom[room1EndIndex].y === x.y)

  const room2EndIndex = findIndexCircular(secondRoom, room2StartIndex + 1, (r1) => firstRoom.some(r2 => r1.x === r2.x && r1.y === r2.y))
  const room1StartIndex = firstRoom.findIndex(x => secondRoom[room2EndIndex].x === x.x && secondRoom[room2EndIndex].y === x.y)

  const newWalls = [
    ...firstRoom.slice(0, room1EndIndex),
    ...secondRoom.slice(room2StartIndex, room2EndIndex < room2StartIndex ? secondRoom.length : room2EndIndex),
    ...(room2EndIndex < room2StartIndex ? secondRoom.slice(0, room2EndIndex) : []),
    ...firstRoom.slice(room1StartIndex, firstRoom.length)
  ].map(x => ({ ...x, x: x.x - roomBaseXandY.x!, y: x.y - roomBaseXandY.y! }))

  const mergedRoom = { ...room1, radiators: [...room1.radiators, ...room2.radiators], images: [...room1.images, ...room2.images], x: roomBaseXandY.x, y: roomBaseXandY.y, walls: newWalls }
  return mergedRoom
}

export const getNextWall = (wall: Wall, walls: Wall[]) => {
  const wallIndex = walls.findIndex(x => x.uuid === wall.uuid)
  const nextIndex = getNextIndex(wallIndex, walls)

  return walls[nextIndex]
}

export const getNextIndex = (index: number, walls: Wall[]) => {
  const nextIndex = walls.length - 1 === index ? 0 : index + 1
  return nextIndex
}

export const getPrevWall = (wall: Wall, walls: Wall[]) => {
  const wallIndex = walls.findIndex(x => x.uuid === wall.uuid)
  const prevIndex = getPrevIndex(wallIndex, walls)

  return walls[prevIndex]
}

export const getPrevIndex = (index: number, walls: Wall[]) => {
  const prevIndex = index === 0 ? walls.length - 1 : index - 1
  return prevIndex
}

export const findIndexCircular = <T extends {}>(arr: T[], startIndex: number, condition: (item: T) => boolean) => {
  const n = arr.length
  let index = startIndex % n // Make sure startIndex is within bounds

  for (let i = 0; i < n; i++) {
    if (condition(arr[index])) {
      return index
    }
    index = (index + 1) % n // Move to the next index, looping back if necessary
  }

  return -1 // If no index meets the condition
}

// Sometimes want to scale to a sensible level and centre on room. Scale on floor because zooming in on room is disorientating
export const centreOnRoomScaleOnFloor = (
  room: Room,
  floor: Floor,
  setStagePosition: Dispatch<SetStateAction<Vector2d>>,
  setStageScale: Dispatch<SetStateAction<number>>,
  width: number,
  height: number
) => {
  const scale = getStageScaleForFloor(floor, width, height)
  setStageScale(scale)
  // Need to scale before we can centre to get the centre of the room correct
  const { x, y } = getStagePositionToCentreOnRoom(room, scale, width, height)
  setStagePosition({ x, y })
}

export const centreAndScaleOnFloor = (
  floor: Floor,
  setStagePosition: Dispatch<SetStateAction<Vector2d>>,
  setStageScale: Dispatch<SetStateAction<number>>,
  width: number,
  height: number
) => {
  const scale = getStageScaleForFloor(floor, width, height)
  setStageScale(scale)
  // scale first so passing correct scale when centring
  const { x, y } = getStagePositionToCentreOnFloor(floor, width, height, scale)
  setStagePosition({ x, y })
}

export const getStagePositionToCentreOnRoom = (room: Room, stageScale: number, width: number, height: number) => {
  // Find the center of the shape
  const roomCenterRelative = calculateCentroid(room.walls.map(x => [x.x!, x.y!]))
  // Add the room center x, y to get the room absolute position on canvas
  const roomCenterAbsoluteX = roomCenterRelative[0] + room.x!
  const roomCenterAbsoluteY = roomCenterRelative[1] + room.y!

  return getPositionToCentreOnObject({ x: roomCenterAbsoluteX, y: roomCenterAbsoluteY }, width, height, stageScale)
}

export const getStagePositionToCentreOnFloor = (floor: Floor, width: number, height: number, stageScale: number) => {
  // Wall x and y are relative to room - so combined here to get the absolute position of all the points
  const wallsAbsoluteX = floor.rooms.map(r => r.walls.flatMap(w => w.x! + r.x!)).flat()
  const wallsAbsoluteY = floor.rooms.map(r => r.walls.flatMap(w => w.y! + r.y!)).flat()
  const floorCentreAbsoluteX = (max(wallsAbsoluteX)! + min(wallsAbsoluteX)!) / 2
  const floorCentreAbsoluteY = (max(wallsAbsoluteY)! + min(wallsAbsoluteY)!) / 2
  return getPositionToCentreOnObject({ x: floorCentreAbsoluteX, y: floorCentreAbsoluteY }, width, height, stageScale)
}

const getPositionToCentreOnObject = (objectCentreAbsolute: SprucePoint, width: number, height: number, stageScale: number) => {
  // The canvas x and y depend on the scale (confusingly!) so scale the x and y by the stage scale
  // The canvas position describes the top left, so putting the canvas in the position below would put the object centroid in the top left corner
  const canvasXPutsRoomTopLeft = -objectCentreAbsolute.x * stageScale
  const canvasYPutsRoomTopLeft = -objectCentreAbsolute.y * stageScale

  // To put the object in the center of the screen we shift the canvas right (positive x) and down (positive y) by half the screen width and height
  const newX = canvasXPutsRoomTopLeft + width / 2
  const newY = canvasYPutsRoomTopLeft + height / 2

  return { x: newX, y: newY }
}

// Same as above but the bounding box of the whole floor.
export const getStageScaleForFloor = (floor: Floor, width: number, height: number) => {
  // Wall x and y are relative to room - so combined here to get the absolute position of all the points
  const wallsX = floor.rooms.map(r => r.walls.flatMap(w => w.x! + r.x!)).flat()
  const wallsY = floor.rooms.map(r => r.walls.flatMap(w => w.y! + r.y!)).flat()
  return scaleObject(wallsX, wallsY, width, height)
}

// Same as above but the bounding box of the whole floor.
export const getStageScaleForRoom = (room: Room, width: number, height: number) => {
  // Wall x and y are relative to room - so combined here to get the absolute position of all the points
  const wallsX = room.walls.flatMap(w => w.x! + room.x!)
  const wallsY = room.walls.flatMap(w => w.y! + room.y!)
  return scaleObject(wallsX, wallsY, width, height)
}

export const scaleObject = (allXs: number[], allYs: number[], width: number, height: number, bufferMM: number = 1000) => {
  // Max percent of window defines how much of the window the shape should take when zoomed in
  // Find the bounding box of the shape by finding the min/max of x and y
  const objectWidthMM = (max(allXs)! - min(allXs)!)
  const objectLengthMM = (max(allYs)! - min(allYs)!)
  // - Divide screen width by shape width and screen height by shape height, get the min of these values and that is how far we can zoom in
  const newScale = max(
    [min(
      [width / (objectWidthMM + bufferMM),
        height / (objectLengthMM + bufferMM),
        maxScale]
    ),
    minScale])!
  return newScale
}

// Always move down and right
// Update either my point or my next point so we can ensure above by taking the larger of the 2 values
// If updating my point, go clockwise around the shape, make any lines that were previously straight straight again by setting its x/y to the new calculated x/y value
// If update my next point, go anti clockwise around the shape, make any lines that were previously straight straight again by setting its x/y to the new calculated x/y value
// Assumes you are the end of a split line if trying to update a split line, o
export const updateLength = (room: Room, wallIndex: number, newLength: number) => {
  const { ...oldWall } = room.walls[wallIndex]
  const nextWallIndex = getNextIndex(room.walls.findIndex(x => x.uuid === oldWall.uuid), room.walls)
  const { ...nextWall } = room.walls[nextWallIndex]

  const lineLength = calculateLineLength(oldWall.x!, oldWall.y!, nextWall.x!, nextWall.y!)
  const lineDiffRatio = newLength / lineLength

  const newWalls: Wall[] = [oldWall]

  // Should we update the oldWall point or nextWall to ensure we are always moving down and right?
  if (oldWall.x! + oldWall.y! > nextWall.x! + nextWall.y!) {
    // If line is horizontal update the y position, otherwise x
    const dx = oldWall.x! - nextWall.x!
    const dy = oldWall.y! - nextWall.y!
    const newDx = dx * lineDiffRatio
    const newDy = dy * lineDiffRatio

    oldWall.x = nextWall.x! + Math.round(newDx)
    oldWall.y = nextWall.y! + Math.round(newDy)

    // I am updating myself, so find me all other walls to loop through, clockwise, until I hit myself again (the array is circular).
    const wallsToIterate = [
      ...room.walls.slice(wallIndex + 1),
      ...room.walls.slice(0, wallIndex)
    ].reverse()

    wallsToIterate.map(x => {
      const { ...currWall } = x
      const nextWall = getNextWall(currWall, room.walls)

      // If the line was previously horizontal or vertical, align it back to that state.
      if (currWall.x === nextWall.x) {
        currWall.x = newWalls[newWalls.length - 1].x
      } else if (currWall.y === nextWall.y) {
        currWall.y = newWalls[newWalls.length - 1].y
      }

      newWalls.push(currWall)
    })

    return newWalls.reverse()
  }

  const dx = nextWall.x! - oldWall.x!
  const dy = nextWall.y! - oldWall.y!
  const newDx = dx * lineDiffRatio
  const newDy = dy * lineDiffRatio

  // I am updating my next wall instead, repeat the above logic but for couter-clockwise, it is not possible to merge the 2 functions into one easily.
  nextWall.x = oldWall.x! + Math.round(newDx)
  nextWall.y = oldWall.y! + Math.round(newDy)

  newWalls.push(nextWall)

  const wallsToIterate = [
    ...(nextWallIndex + 1 > wallIndex ? room.walls.slice(nextWallIndex + 1) : []),
    ...room.walls.slice(nextWallIndex + 1 > wallIndex ? 0 : 1, wallIndex)
  ]

  wallsToIterate.map(x => {
    const { ...currWall } = x
    const prevWall = getPrevWall(currWall, room.walls)

    if (currWall.x === prevWall.x) {
      currWall.x = newWalls[newWalls.length - 1].x
    } else if (currWall.y === prevWall.y) {
      currWall.y = newWalls[newWalls.length - 1].y
    }

    newWalls.push(currWall)
  })

  return newWalls
}

/*
    This function is used to calculate the length of a wall in meters. It simplifies the distinction between internal and external walls and just calculates the straight lengths - irrespective of whether they are internal or external.
 */
export const calculateSimplifiedWallLengths = (room: Room): number[] => {
  const simplifiedRoom = simplifyRooms([{ ...room, walls: room.walls.map(x => ({ ...x, other_room_uuid: undefined })) }])[0]
  return (simplifiedRoom.walls.map((w, i) => calculateWallLength(w, getNextWall(w, simplifiedRoom.walls))))
}

export const calculateWallLength = (wall: Wall, nextWall: Wall): number => {
  return calculateLineLength(wall.x!, wall.y!, nextWall.x!, nextWall.y!)
}

export const calculateLineLength = (x1: number, y1: number, x2: number, y2: number): number => {
  // TODO: duplicate of above? Use above and then do the scaling?
  const dx = x2 - x1
  const dy = y2 - y1
  const lengthMM = Math.sqrt(dx * dx + dy * dy)

  // 1 step is 10cm. Rounding to 1 dp rounds to nearest 10 cm
  return Number((lengthMM / 1000).toFixed(2))
}

export const getAreaM2 = (vertices: SprucePoint[]) => {
  // Vertices must in order for this to work but can be either clockwise or anticlockwise
  // Formula from here: https://en.wikipedia.org/wiki/Shoelace_formula
  let area = 0

  for (let i = 0, l = vertices.length; i < l; i++) {
    const x1 = vertices[i].x
    const y2 = vertices[i === vertices.length - 1 ? 0 : i + 1].y
    const x2 = vertices[i === vertices.length - 1 ? 0 : i + 1].x
    const y1 = vertices[i].y

    area += (x1 * y2) - (x2 * y1)
  }

  return Math.abs(area / 2) / 1000000
}

const pointToLineDistance = (px: number, py: number, x1: number, y1: number, x2: number, y2: number) => {
  const A = px - x1
  const B = py - y1
  const C = x2 - x1
  const D = y2 - y1
  const dot = A * C + B * D
  const len_sq = C * C + D * D
  const param = len_sq !== 0 ? dot / len_sq : -1
  let xx, yy
  if (param < 0) {
    xx = x1
    yy = y1
  } else if (param > 1) {
    xx = x2
    yy = y2
  } else {
    xx = x1 + param * C
    yy = y1 + param * D
  }
  const dx = px - xx
  const dy = py - yy
  return { distance: Math.sqrt(dx * dx + dy * dy), closestPoint: { x: xx, y: yy } }
}

const snapPointToLine = (point: SprucePoint, line: { start: SprucePoint, end: SprucePoint }, tolerance: number) => {
  const { distance, closestPoint } = pointToLineDistance(point.x, point.y, line.start.x, line.start.y, line.end.x, line.end.y)
  return distance <= tolerance ? closestPoint : null
}

export const snapPointToNearestLine = (
  point: SprucePoint,
  otherRoomPoints: Array<{ start: SprucePoint, end: SprucePoint }>,
  myRoomPoints: SprucePoint[],
  buffer: number
): SprucePoint => {
  let closestSnapPoint: SprucePoint | null = null
  let minDistance = buffer

  // Find the line of another shape I can snap to its angle.
  for (const line of otherRoomPoints) {
    const snapPoint = snapPointToLine(point, line, buffer)
    if (snapPoint) {
      const distanceToSnapPoint = Math.hypot(point.x - snapPoint.x, point.y - snapPoint.y)
      if (distanceToSnapPoint < minDistance) {
        minDistance = distanceToSnapPoint
        closestSnapPoint = snapPoint
      }
    }
  }

  if (closestSnapPoint) return closestSnapPoint

  // If no line can I snap to the x, y axis of any other points on my shape?
  const matchingX = myRoomPoints.find(x => Math.abs(x.x - point.x) <= buffer)
  const matchingY = myRoomPoints.find(x => Math.abs(x.y - point.y) <= buffer)

  // Otherwise return point
  return { x: matchingX?.x ?? point.x, y: matchingY?.y ?? point.y }
}

export const trimPointToAngles = (point: SprucePoint, prevPoint: SprucePoint, nextPoint: SprucePoint) => {
  const prevPointTrimmed = trimPointToAngle(point, prevPoint)
  const nextPointTrimmed = trimPointToAngle(point, nextPoint)

  return { x: (prevPointTrimmed.x + nextPointTrimmed.x) / 2, y: (prevPointTrimmed.y + nextPointTrimmed.y) / 2 }
}

const trimPointToAngle = (point: SprucePoint, otherPoint: SprucePoint) => {
  const angle = Math.atan2(otherPoint.y - point.y, otherPoint.x - point.x)
  let degrees = angle * (180 / Math.PI)
  if (degrees < 0) {
    degrees += 360
  }
  const snappedAngle = Math.round(degrees / 10) * 10
  const snappedAngleRadians = snappedAngle * (Math.PI / 180)

  const distance = Math.hypot(otherPoint.x - point.x, otherPoint.y - point.y)

  const newX = otherPoint.x - distance * Math.cos(snappedAngleRadians)
  const newY = otherPoint.y - distance * Math.sin(snappedAngleRadians)

  return { x: Math.round(newX), y: Math.round(newY) }
}
