import React from 'react'
import { SurveyHeatPumpLocationContext } from '../heat_pump_location'
import { Badge } from '../../../../components/indicators_and_messaging/badge'
import { Input } from '../../../../components/inputs_and_selections/input'
import { TextArea } from '../../../../components/inputs_and_selections/text_area'
import { validateInMm } from '../../../../code/validators'
import { Check } from 'lucide-react'

export const HPLProposedLocationDetailsBlock = () => {
  const hplContext = React.useContext(SurveyHeatPumpLocationContext)
  const { survey, setSurvey } = hplContext!

  return <>
    <div className="text-gray-900 text-xl font-bold">Location</div>

    <div className="flex-col gap-2 flex">
      <div className="flex-col flex">
        <div className="text-gray-900 font-bold">Available space</div>
        <div className="text-gray-500 text-sm">Space between windows, doors and drains that the heat pump plus
        protective zone must fit into.
        </div>
      </div>

      <div className="gap-4 flex">
        <div className="flex-col gap-2 flex">
          <div className="text-gray-600">Width</div>
          <Input type="number"
            value={survey.available_space_width_mm.toString()}
            setValue={(e) => setSurvey({ ...survey, available_space_width_mm: parseFloat(e) })}
            validator={validateInMm}
            postfix='mm'/>
        </div>
        <div className="flex-col gap-2 flex">
          <div className="text-gray-600">Height</div>
          <Input type='number' value={survey.available_space_height_mm.toString()}
            setValue={(e) => setSurvey({ ...survey, available_space_height_mm: parseFloat(e) })}
            validator={validateInMm}
            postfix='mm'/>
        </div>
        <div className="flex-col gap-2 flex">
          <div className="text-gray-600">Depth</div>
          <Input type='number' value={survey.available_space_depth_mm.toString()}
            setValue={(e) => setSurvey({ ...survey, available_space_depth_mm: parseFloat(e) })}
            validator={validateInMm}
            postfix='mm'/>
        </div>
      </div>
    </div>

    <div className="flex-col gap-2 flex">
      <div className='flex justify-between'>
        <div className="text-gray-900 font-bold">Location description</div>
        <Badge color={survey.location_description?.length > 0 ? 'GREEN' : 'YELLOW'} text='Required'
          icon={survey.location_description?.length > 0 ? Check : undefined}/>
      </div>
      <TextArea
        placeholder='Note any details that will impact installation, such as required ground works or wall/roof material.'
        value={survey.location_description} setValue={(e) => setSurvey({ ...survey, location_description: e })}/>
    </div>
  </>
}
