import React, { useContext, useEffect, useState } from 'react'
import { Caption } from '../components/caption_1'
import { SurveyActionType } from '../../../code/survey/survey'
import { Input } from '../../../components/inputs_and_selections/input'
import { Accordion } from '../../../components/content_display/accordion'
import { Button } from '../../../components/buttons/button'
import { type CompanyPublicInfo } from '../../../code/models/company'
import { logEvent } from '../../../code/log_event'
import { type CustomerDetailsType, getAddressIncludingPostcode, postLead } from '../../../code/models/lead'
import { calculateEstimate } from '../../../code/calculate_estimate'
import { ColoredBlockWrapper } from '../components/colored_block_wrapper'
import { StepHeader } from '../components/steps_header'
import { SubHeader } from '../components/sub_header'
import { SurveyContext, SurveyDispatchContext } from '../survey_page'
import { validateEmail, validateNotNull } from '../../../code/validators'
import { numberFormat } from '../../../code/number_format'
import { Loader } from '../../../components/indicators_and_messaging/loader'
import { geocodeAddress, type Location3D } from '../../../code/geocoding'
import { Select } from '../../../components/inputs_and_selections/select'
import { TextArea } from '../../../components/inputs_and_selections/text_area'
import { ArrowLeftIcon } from 'lucide-react'

export const useGeocodeAddress = (postcode: string, address: string) => {
  const [loadingLocation, setLoadingLocation] = useState<boolean>(true)
  const [location3D, setLocation3D] = useState<Location3D>()

  // Needed to allow for async loading of the location
  useEffect(() => {
    // Ensure we don't try to set state on an unmounted component (chatgpt suggested this, happy to loose if not needed)
    let isMounted = true
    setLoadingLocation(true)

    const loadAdressLocation = async () => {
      try {
        const location = await geocodeAddress(address, postcode)
        if (isMounted) {
          setLocation3D(location)
          setLoadingLocation(false)
        }
      } catch (error) {
        if (isMounted) {
          setLoadingLocation(false)
        }
      }
    }
    loadAdressLocation()
    return () => { isMounted = false }
  }, [address, postcode])

  return { location3D, loadingLocation }
}

export const GetContactsStep: React.FC<{ adminEstimate: boolean, companyPublicInfo: CompanyPublicInfo, navigateTo: (url: string) => void }> = ({ adminEstimate, companyPublicInfo, navigateTo }) => {
  // survey context and dispatch
  const survey = useContext(SurveyContext)
  const dispatch = useContext(SurveyDispatchContext)
  const [loading, setLoading] = useState(false)

  // Add location to the lead now as needed to get the heat loss calc right and then also it's stored for later
  const { location3D, loadingLocation } = useGeocodeAddress(getAddressIncludingPostcode(survey.lead), survey.lead.property.postcode)
  const lead = { ...survey.lead, property: { ...survey.lead.property, latLng: location3D ? [location3D.lat, location3D.lng] : undefined, altitudeM: location3D?.altitudeM } }
  const calculatedHeatLoss = calculateEstimate(lead, [], [], undefined)

  const [fieldsData, setFieldsData] = useState<CustomerDetailsType>({
    name: '',
    email: '',
    phone: '',
    // This is a legacy workaround for a specific customer - their company name gets put in the source field
    sourceCompany: survey.lead.customer!.source,
    source: ''
  })
  // const [isAgreementAccepted, setIsAgreementAccepted] = useState<boolean>(true) // Set to false when we re-enable component.
  const [comment, setComment] = useState<string>('')
  const [customSource, setCustomSource] = useState<string>('')

  const isValidForm = [validateEmail(fieldsData.email!), validateNotNull(fieldsData.name!), validateNotNull(fieldsData.phone!)].every(x => x.value)
  const handleNavigatePreviousPage = () => {
    dispatch({
      type: SurveyActionType.NavigatePreviousPage
    })
  }

  const handleNavigateNextPage = () => {
    logEvent({ name: 'Contact details step completed', properties: {} }, companyPublicInfo.subdomain)
    dispatch({
      type: SurveyActionType.NavigateNextPage,
      payload: {
        lead: { ...lead, customer: { ...fieldsData, source: getSource(fieldsData, customSource) }, notes: comment }
      }
    })
  }

  const getSource = (fieldsData: CustomerDetailsType, customSource: string) => {
    // If we've got the sourceCompany filled in from the workaround in the lead,
    // we concatenate it with the source
    const baseSource = fieldsData.source === 'Other' ? customSource : fieldsData.source
    return fieldsData.sourceCompany
      ? `${fieldsData.sourceCompany} - ${baseSource || 'Not provided'}`
      : baseSource || 'Not provided'
  }

  if (loading) return <Loader />

  const handleSubmit = async () => {
    survey.lead.customer = { ...fieldsData, source: getSource(fieldsData, customSource) }
    survey.lead.notes = comment
    setLoading(true)
    const leadUUID = await postLead(survey.lead, companyPublicInfo.uuid, adminEstimate)
    if (adminEstimate) {
      // If we're an installer, take us straight to the estimate
      navigateTo(`/${companyPublicInfo.subdomain}/admin/quotes/${leadUUID}/dashboard`)
    } else {
      // Otherwise, take us to the Thank You page
      handleNavigateNextPage()
      setLoading(false)
      window.parent.postMessage('spruce_customer_requested_estimate', '*')
    }
  }

  return <>
    {/* Back button */}
    <Button colour='LIGHT' onClick={handleNavigatePreviousPage} block={false} className="self-start">
      <div className="flex items-center gap-x-2">
        <ArrowLeftIcon className="h-4 w-4" />
        <div>Back</div>
      </div>
    </Button>

    {/* Header */}
    {!loadingLocation &&
      <StepHeader
        guideText={getAddressIncludingPostcode(survey.lead)}
        headerText={`We estimate your property has ${(calculatedHeatLoss.totalWatts / 1000).toFixed(1)}kW of heat loss`}
        captionText={`You could save ${numberFormat(0).format(calculatedHeatLoss.CO2SavedKg)}kg of CO2 every year by switching to a heat pump.`}
      />
    }

    {/* Heat loss estimation */}
    {/* don't calculate heat loss until postcode location returned because otherwise heat los will first calculate with */}
    {/* default outdoor temp and then will bounce to correct value when postcode location returned */}
    {loadingLocation &&
      <Loader />
    }

    {/* Request contacts */}
    <ColoredBlockWrapper color="blue">
      <div className="flex flex-col  gap-6">
        {/* Subheader text */}
        <div className="flex flex-col gap-2">
          <SubHeader content="Please enter your details if you'd like us to send a (no-obligation) cost estimate" />
        </div>

        {/* Input fields to fill */}
        <div className="w-full flex flex-col gap-3">
          <div className="space-y-2">
            <div className="text-gray-600 text-lg">Name</div>
            <Input
              size='LG'
              setValue={(value: string) => { setFieldsData(prev => ({ ...prev, name: value })) }}
              type={'text'}
              value={fieldsData.name!}
              validator={validateNotNull}
              placeholder={'Name'}
            />
          </div>
          <div className="space-y-2">
            <div className="text-gray-600 text-lg">Email</div>
            <Input
              size='LG'
              setValue={(value: string) => { setFieldsData(prev => ({ ...prev, email: value })) }}
              type={'text'}
              value={fieldsData.email!}
              validator={validateEmail}
              placeholder={'Email'}
            />
          </div>
          <div className="space-y-2">
            <div className="text-gray-600 text-lg">Phone</div>
            <Input
              size='LG'
              setValue={(value: string) => { setFieldsData(prev => ({ ...prev, phone: value })) }}
              type={'text'}
              validator={validateNotNull}
              value={fieldsData.phone!}
              placeholder={'Phone'}
            />
          </div>
        </div>

        {/* Where did you hear about us */}
        {companyPublicInfo.sources.length &&
        <div className="flex flex-col gap-4">
          <div className="text-gray-600 text-lg">Where did you hear about us? (optional)</div>
          <Select
            size='LG'
            options={[
              ...companyPublicInfo.sources.map(source => ({ key: source, value: source })),
              { key: 'Other', value: 'Other' }
            ]}
            setSelectedKey={(key: string) => {
              setFieldsData(prev => ({ ...prev, source: key }))
            }}
            selectedKey={fieldsData.source}
          />
          {fieldsData.source === 'Other' &&
            <Input
              size='LG'
              setValue={setCustomSource}
              type="text"
              value={customSource}
              placeholder={'Please specify'}
            />
          }
        </div>
        }

        {/* Additional info */}
        <div className="flex flex-col gap-4">
          <div className="text-gray-600 text-lg">Any final information we should know? (optional)</div>
          <TextArea
            size='LG'
            setValue={setComment}
            value={comment}
            placeholder={'E.g. I have a mix of double and single glazing'}
          />
        </div>

        {/* Disclaimer */}
        <Caption
          content="We won't share your details with anyone else." />

        {/* This should be disabled until we have a privacy policy ready */}
        {/* <label className="w-full h-10 justify-start items-start gap-3 inline-flex">
                    <input
                        value={isAgreementAccepted ? "checked" : ""}
                        onChange={e => setIsAgreementAccepted(e.target.checked)}
                        type="checkbox"
                        className="w-5 h-5"
                    />
                    <div className="grow basis-0 text-gray-600 text-sm ">
                        I understand and agree to the terms of the Spruce privacy policy.
                    </div>
                </label> */}

        <Button size='LG' block={true} disabled={!isValidForm} onClick={handleSubmit}>{adminEstimate ? 'Generate estimate' : 'Send me an estimate'}</Button>
      </div>
    </ColoredBlockWrapper>

    {/* Common questions */}
    <div className="flex flex-col gap-1">
      <SubHeader content="Common questions" />
      <Accordion rows={faqRows()} />
    </div>
  </>
}

export const faqRows = () => {
  return [
    {
      title: 'What is a heat loss survey and why is it important?',
      body: <div className="space-y-4">
        <p>This is a room-by-room survey of your property to work out how much heat each room loses - and so how much
          energy is required to keep it warm on the coldest days.</p>
        <p>Because heat pumps run at a lower temperature than a gas boiler (part of the reason they're more efficient)
          it's also important to create a detailed model of the whole current heating system so that you can rest
          assured that it will function effectively with a heat pump.</p>
        <p>Heat loss reports are mandated by MCS and so it is absolutely necessary if you want to be eligible for the
          government grant.</p>
      </div>
    },
    {
      title: 'What are the benefits of getting a heat pump?',
      body: <div className="space-y-4">
        <p>We're probably a bit biased but there are lots!</p>
        <p>They're <span className="font-semibold">green</span> - heat pumps only use electricity and produce no other
          emissions. On average you can expect to reduce your carbon emissions by 75% compared to an equivalent gas
          boiler.</p>
        <p>They're <span className="font-semibold">efficient</span> - heat pumps have the almost magical ability to turn
          electricity into heat with a 300-500% efficiency rate, meaning 3-5x the amount of energy that goes in as
          electricity comes out as heat! This also means that they're generally cheaper to run than a gas boiler, saving
          you money every year.</p>
        <p>They last a <span className="font-semibold">long time</span> - a heat pump should last 20-25 years, compared to
          an average UK gas boiler lifespan of only 10-12 years.</p>
      </div>
    },
    {
      title: 'What guarantees or insurance do I get with the heat pump?',
      body: <div className="space-y-4">
        <p>You can rest assured that if there are any issues they'll be promptly dealt with without any additional cost:
          every installation done by an MCS-accredited installer comes with a minimum 2 year workmanship guarantee as
          well as manufacturer's warranty on the heat pump itself which is normally 5-7 years, depending on the heat
          pump.</p>
      </div>
    },
    {
      title: 'Will a heat pump keep me warm in winter?',
      body: <div className="space-y-4">
        <p>In short, yes! Presuming that the system has been designed and installed well.</p>
        <p>Heat pumps have been very popular for years in Sweden, Norway, and Finland - and their winters get a lot
          colder than the UK!</p>
      </div>
    },
    {
      title: 'Will I need to replace my radiators if I have a heat pump?',
      body: <div className="space-y-4">
        <p>It's possible that you may need to upgrade your radiators when switching to a heat pump - this is because
          heat pumps operate at lower temperatures compared to traditional boilers, which is part of what makes them so
          efficient.</p>
        <p>Whether you will need to replace radiators depends on a number of factors, including how big your current
          radiators are, how many you have in each room, and how old they are.</p>
        <p>The type of heat pump you go for might also have an impact - for example, high-temperature heat pumps
          normally don't need as many radiators to be changed - though the trade-off is often that the ongoing running
          costs tend to be a bit higher.</p>
        <p>After the heat loss survey, your installer will advise you on whether you'll need to make changes and what
          your options are.</p>
      </div>
    },
    {
      title: 'What grants are available and how do I get them?',
      body: <div className="space-y-4">
        <p>If you’re in England🏴󠁧󠁢󠁥󠁮󠁧󠁿 or Wales🏴󠁧󠁢󠁷󠁬󠁳󠁿󠁧󠁢󠁷󠁬󠁳󠁿, you may be eligible for the £7.5k Boiler Upgrade
          Scheme (BUS) grant.</p>
        <p>Your installer will apply for the grant for you - you don't need to do anything yourself on this front.</p>
        <p>To be eligible, your property needs to have an up-to-date EPC and to not already have a heat pump.</p>
        <p>If you're in Scotland🏴󠁧󠁢󠁳󠁣󠁴󠁿, things are a little different.</p>
        <p>You can access up to £15,000 to install a heat pump (up to £7,500 as a grant plus up to £7,500 as an optional
          loan, or £9,000 grant plus £7,500 optional loan if the household qualifies for the rural uplift).</p>
        <p>You’ll need to speak to Home Energy Scotland, who will help you through the process. More details are
          available <a target="_blank" href="https://www.homeenergyscotland.org/funding/grants-loans/" className="underline"
          rel="noreferrer">here</a>.</p>
      </div>
    }
  ]
}
