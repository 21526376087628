import React, { useState } from 'react'
import { TextArea } from '../../../components/inputs_and_selections/text_area'

export const TextAreaDemo = () => {
  const [value, setValue] = useState('')

  return <div className="flex flex-col gap-8">
    <div className='flex flex-col gap-4'>
      <div className='text-2xl font-bold text-gray-900'>Definition</div>

      <pre className='bg-gray-100 rounded-lg p-4 overflow-x-auto'><code>
        {codeBlock}
      </code></pre>

      <div className='flex flex-col divide-y divide-dashed divide-gray-200 text-sm'>
        {componentProps.map(x => <div key={x.name} className='grid grid-cols-2 py-2'>
          <div className='font-bold text-gray-900'>{x.name}</div>
          <div>{x.description}</div>
        </div>)}
      </div>
    </div>

    <div className='flex flex-col gap-4'>
      <div className='text-2xl font-bold text-gray-900'>Component</div>
      <TextArea
        validator={(e) => ({ message: '', value: e })}
        value={value}
        setValue={setValue}
        placeholder='Placeholder text...'
        size='MD'
        className=''
        rows={3}
        disabled={false}
      />
    </div>

  </div>
}

const componentProps = [
  { name: 'validator', description: 'Any validation the text area should have, show error state when not valid' },
  { name: 'value', description: 'The current value of the text area' },
  { name: 'setValue', description: 'What should we do on keypress' },
  { name: 'label', description: 'The label for the text area' },
  { name: 'placeholder', description: 'Any placeholder value required' },
  { name: 'size', description: 'SM | MD | LG' },
  { name: 'className', description: 'Any additional styling or formatting required' },
  { name: 'rows', description: 'The size of the text area in rows (line count)' },
  { name: 'disabled', description: 'Should the text area be disabled or not' }
]

const codeBlock = `const [value, setValue] = useState('')

<TextArea
  validator={(e) => ({ message: '', value: e })}
  value={value}
  setValue={setValue}
  placeholder='Placeholder text...'
  size='MD'
  className=''
  rows={3}
  disabled={false}
/>
`
