import React, { type Dispatch, type SetStateAction } from 'react'
import { type FileWrapper } from '../file_wrapper'
import { type PropertySurvey } from '../../../code/models/property'
import { type PagesFlowPage } from '../../../code/use_pages_flow'
import { SurveySectionFlowWrapper } from './components/survey_section_flow_wrapper'
import { EHSPhotosBlock } from './existing_heating_system/ehs_photos_block'
import { EHSDetailsBlock } from './existing_heating_system/ehs_details_block'
import { EHSEnergyUseBlock } from './existing_heating_system/ehs_energy_use_block'
import { EHSDefaultRadiatorPipeworkBlock } from './existing_heating_system/ehs_default_radiator_pipework_block'
import { getExistingHeatingChecks } from './validations'
import { type SetIndexedDb } from '../../admin/job_layout/job_layout'

type ExistingHeatingSystemProps = {
  survey: PropertySurvey
  setSurvey: SetIndexedDb<PropertySurvey>
  files: FileWrapper[]
  setFiles: Dispatch<SetStateAction<FileWrapper[]>>
  companyUUID: string
  isOffline: boolean
  allSynced: boolean
}

export const SurveyEHSContext = React.createContext<ExistingHeatingSystemProps | undefined>(undefined)

export const ExistingHeatingSystem = ({ survey, files, setFiles, setSurvey, companyUUID, isOffline, allSynced }: ExistingHeatingSystemProps) => {
  // WARNING! Never change flagBits for the pages because they are stored in the DB.
  // instead, add new pages or replace existing with new flags
  const pages = [
    { flagBit: 0x001, pbValue: 10, page: <EHSPhotosBlock /> },
    { flagBit: 0x002, pbValue: 30, page: <EHSDetailsBlock /> },
    { flagBit: 0x004, pbValue: 60, page: <EHSEnergyUseBlock /> },
    { flagBit: 0x008, pbValue: 100, page: <EHSDefaultRadiatorPipeworkBlock /> }
  ] as PagesFlowPage[]

  return <SurveyEHSContext.Provider value={{ isOffline, allSynced, survey, setSurvey, files, setFiles, companyUUID }}>
    <SurveySectionFlowWrapper
      isOffline={isOffline}
      allSynced={allSynced}
      completeSectionID={'EXISTING_HEATING'}
      sectionUIName={'Existing system'}
      pages={pages}
      flags_attribute_name={'flags_ehs_pages_completed'}
      onPageComplete={(flagBit) => {
        setSurvey({ ...survey, flags_ehs_pages_completed: survey.flags_ehs_pages_completed | flagBit })
      }}
      onFormComplete={() => window.history.back()}
      survey={survey}
      setSurvey={setSurvey}
      completionChecks={getExistingHeatingChecks(survey)}
    />
  </SurveyEHSContext.Provider>
}
