import React, { useState } from 'react'
import { ReactSortable } from 'react-sortablejs'
import { Icon } from '../../../components/buttons/icon'
import { Input } from '../../../components/inputs_and_selections/input'
import { Button } from '../../../components/buttons/button'
import { Check, Edit, MoreVertical, Plus, Trash } from 'lucide-react'

export type SortableItem = {
  id: number
  name: string
  chosen?: boolean
}

type Props = {
  items: SortableItem[]
  setItems: (items: SortableItem[]) => void
  extraActionButtons?: React.ReactNode[]
}

type ItemProps = {
  item: SortableItem
  handleEditItem: (id: number) => void
  handleDeleteItem: (id: number) => void
  isEditing: boolean
  setIsEditing: (id: number) => void
  editingText: string
  setEditingText: (text: string) => void
  dragDisabled: boolean
}

const Item = ({ item, handleEditItem, handleDeleteItem, isEditing, setIsEditing, editingText, setEditingText, dragDisabled }: ItemProps) => {
  const editingTextValid = editingText.trim().length > 0
  return (
    <div className={`${item.chosen ? 'bg-gray-100' : 'bg-white'} hover:bg-gray-50 py-2 px-4 flex items-center gap-4`}>
      <span className={`handle ${dragDisabled && 'pointer-events-none'} pr-2 cursor-move`}>
        <Icon icon={MoreVertical} />
      </span>
      {!isEditing && (
        <span className='flex-grow select-none'>
          {item.name}
        </span>
      )}
      {isEditing && (
        <Input
          value={editingText}
          setValue={setEditingText}
          className='flex-grow'
        />
      )}
      {!isEditing && <Icon onClick={() => { setEditingText(item.name); setIsEditing(item.id) }} icon={Edit} />}
      {!isEditing && <Icon onClick={() => handleDeleteItem(item.id)} icon={Trash} />}
      {isEditing && <Icon disabled={!editingTextValid} onClick={() => handleEditItem(item.id)} data-cy='table_save_button' icon={Check} />}
    </div>
  )
}

export const SortableList = ({ items, setItems, extraActionButtons }: Props) => {
  const [editingId, setEditingId] = useState<number | null>(null)
  const [editingText, setEditingText] = useState<string>('')

  const updateIds = (newItems: SortableItem[]): SortableItem[] => {
    return newItems.map((item, index) => ({
      ...item,
      id: index + 1
    }))
  }

  const handleEditItem = (id: number) => {
    const newState = items.map((item) => {
      if (item.id === id) {
        return { ...item, name: editingText }
      }
      return item
    })
    setItems(updateIds(newState))
    setEditingId(null)
  }

  const handleDeleteItem = (id: number) => {
    const newState = items.filter((item) => item.id !== id)
    setItems(updateIds(newState))
  }

  const handleAddItem = () => {
    const newState = [...items, { id: items.length + 1, name: 'New item' }]
    setItems(updateIds(newState))
  }

  if (!items.length) {
    return (
      <>
        <div className='p-4 border border-gray-200 text-gray-500 text-sm text-center'>
            No options. Add some to get started.
        </div>
        <Button onClick={() => handleAddItem()}>Add option</Button>
      </>
    )
  }

  return (
    <>
      <ReactSortable
        list={items}
        setList={(newState) => {
          setItems(updateIds(newState))
        }}
        handle=".handle"
        className='divide-y'
      >
        {items.map((item) => (
          <Item
            dragDisabled={editingId !== null}
            key={item.id}
            item={item}
            handleEditItem={handleEditItem}
            handleDeleteItem={handleDeleteItem}
            isEditing={editingId === item.id}
            setIsEditing={setEditingId}
            editingText={editingText}
            setEditingText={setEditingText}
          />
        ))}
      </ReactSortable>
      <div className='flex gap-2'>
        <Button colour='LIGHT' onClick={() => handleAddItem()} iconLeft={Plus}>Add</Button>
        { extraActionButtons?.map(action => action) }
      </div>
    </>
  )
}
