import { axiosDeleteV2, axiosPatchV2, axiosPostV2 } from '../axios'
import { type ProposalQuoteItem } from './proposal'

export const updateProposalQuoteItem = async (proposalQuoteItem: ProposalQuoteItem, proposalUUID: string, leadUUID: string, companyUUID: string) => {
  await axiosPatchV2(`teams/${companyUUID}/leads/${leadUUID}/proposals/${proposalUUID}/quote_items/${proposalQuoteItem.uuid}`, { ...proposalQuoteItem, proposal_uuid: proposalUUID })
}
export const insertProposalQuoteItem = async (proposalQuoteItem: ProposalQuoteItem, proposalUUID: string, leadUUID: string, companyUUID: string) => {
  await axiosPostV2(`teams/${companyUUID}/leads/${leadUUID}/proposals/${proposalUUID}/quote_items`, { ...proposalQuoteItem, proposal_uuid: proposalUUID })
}
export const deleteProposalQuoteItem = async (proposalQuoteItemUUID: string, proposalUUID: string, leadUUID: string, companyUUID: string) => {
  await axiosDeleteV2(`teams/${companyUUID}/leads/${leadUUID}/proposals/${proposalUUID}/quote_items/${proposalQuoteItemUUID}`)
}
export const bulkInsertProposalQuoteItems = async (proposalQuoteItems: ProposalQuoteItem[], proposalUUID: string, leadUUID: string, companyUUID: string): Promise<ProposalQuoteItem[]> =>
  await axiosPostV2<ProposalQuoteItem[]>(
    `teams/${companyUUID}/leads/${leadUUID}/proposals/${proposalUUID}/quote_items/bulk`,
    proposalQuoteItems
  )
export const bulkDeleteProposalQuoteItems = async (proposalUUID: string, leadUUID: string, companyUUID: string) => {
  await axiosDeleteV2(`teams/${companyUUID}/leads/${leadUUID}/proposals/${proposalUUID}/quote_items/bulk`)
}
