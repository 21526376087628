import { apiUrl, client } from '../axios'
import { RangeHeatPumpSchema } from './range_heat_pump'
import { AuthSDK } from '../utils/auth_provider'
import { z } from 'zod'

export const BrandRangeSchema = z.object({
  uuid: z.string(),
  name: z.string(),
  brand: z.string(),
  deleted_at: z.date().nullable().optional(),
  // z.lazy() is necessary to avoid a circular dependency
  // TODO: Can you really have brand ranges without range heat pumps? Check why Python is sending us
  // undefineds here
  range_heat_pumps: z.array(z.lazy(() => RangeHeatPumpSchema)).optional()
})

export type BrandRange = z.infer<typeof BrandRangeSchema>

export const getBrandRanges = async (): Promise<BrandRange[] | undefined> => {
  try {
    const result = await client.get<BrandRange[]>(`${apiUrl}brand_ranges`,
      { headers: { 'x-auth-token': AuthSDK.getToken() } }
    )
    return result.data.map(x => ({ ...x, deleted_at: x.deleted_at ? new Date(x.deleted_at) : undefined }))
  } catch (e: unknown) {
    console.error('Error loading Brand Ranges Layers', e)
    return undefined
  }
}
