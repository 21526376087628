import React, { type ReactNode } from 'react'

type Props = {
  header: ReactNode
  headerHeightPx: number
  content: ReactNode
}

export const PrintLayoutContext = React.createContext<{ headerHeightPx: number }>({ headerHeightPx: 0 })

export const PrintLayout = (props: Props) => {
  // It's very tricky to print header on each page. There is a solution reused:
  // https://medium.com/@Idan_Co/the-ultimate-print-html-template-with-header-footer-568f415f6d2a

  return <>
    <div
      // using style because arbitrary class names are not allowed in print media
      style={{ height: `${props.headerHeightPx}px` }}
      className={'w-full print:top-0 print:fixed print:w-[210mm] print:max-w-[210mm]'}
    >
      {props.header}
    </div>

    {/* add top margin for the sticky header, applicable only to screens */}
    <table className={'w-full'}>
      {/* the `thead` tag consumes some visible space, hide it on the screen but show during printing */}
      <thead className='print:table-header-group hidden'>
        <tr>
          <td>
            {/* a space for printed header */}
            <div
              // using style because arbitrary class names are not allowed in print media
              style={{ height: `${props.headerHeightPx}px` }}
            >&nbsp;</div>
          </td>
        </tr>
      </thead>
      <tbody>
        <tr>
          <td>
            {/* add left and right margin for screen only, printing page has its own */}
            <div className='print:w-[210mm] print:max-w-[210mm]'>
              <PrintLayoutContext.Provider value={{ headerHeightPx: props.headerHeightPx }}>
                {props.content}
              </PrintLayoutContext.Provider>
            </div>
          </td>
        </tr>
      </tbody>
    </table>
  </>
}

type PrintLayoutPageBreakProps = {
  className?: string
}

export const PrintLayoutPageBreak = (props: PrintLayoutPageBreakProps) => {
  return <div className={`${props.className ?? ''} print:break-before-page`} />
}

type PrintLayoutPaginateBlocksProps = {
  blocks: ReactNode[]
}

// TODO: switch proposal and report rendering to this component
export const PrintLayoutPaginateBlocks = (props: PrintLayoutPaginateBlocksProps) => {
  return <>
    {props.blocks.map((block, index) => (
      <div key={index}>
        <PrintLayoutPageBreak/>
        {block}
      </div>
    ))}
  </>
}
