import React, { useContext, useState } from 'react'
import { SurveyActionType } from '../../../code/survey/survey'
import { Button } from '../../../components/buttons/button'
import { type CompanyPublicInfo } from '../../../code/models/company'
import { logEvent } from '../../../code/log_event'
import { getAddressIncludingPostcode } from '../../../code/models/lead'
import { SurveyContext, SurveyDispatchContext } from '../survey_page'
import { SurveyOptionGroup } from '../../../components/inputs_and_selections/survey_option_group'
import { StepHeader } from '../components/steps_header'
import { CAVITY_WALL_INSULATION } from '../../../code/models/u_value'
import { ArrowLeftIcon, ChevronRightIcon } from 'lucide-react'

export const WallCavityInsulationStep = ({ companyPublicInfo }: { companyPublicInfo: CompanyPublicInfo }) => {
  // survey context and dispatch
  const survey = useContext(SurveyContext)
  const dispatch = useContext(SurveyDispatchContext)

  const [selectedOption, setSelectedOption] = useState<string>(survey.lead.property.wallType ? survey.lead.property.wallType : survey.lead.property.construction_age_band?.wallType ?? '')

  const handleNavigatePreviousPage = () => {
    dispatch({
      type: SurveyActionType.NavigatePreviousPage
    })
  }

  const handleNavigateNextPage = () => {
    logEvent({ name: 'Cavity wall insulation step completed', properties: {} }, companyPublicInfo.subdomain)

    dispatch({
      type: SurveyActionType.NavigateNextPage,
      payload: {
        wallCavityInsulation: selectedOption
      }
    })
  }

  return <>
    {/* Back button */}
    <Button colour='LIGHT' onClick={handleNavigatePreviousPage} block={false} className="self-start">
      <div className="flex items-center gap-x-2">
        <ArrowLeftIcon className="h-4 w-4" />
        <div>Back</div>
      </div>
    </Button>

    {/* Header */}
    <StepHeader
      guideText={getAddressIncludingPostcode(survey.lead)}
      headerText="Cavity wall insulation"
      captionText="Let us know if your cavity walls are filled with insulating material."
    />

    {/* Options */}
    <SurveyOptionGroup options={CAVITY_WALL_INSULATION.map(x => ({ icon: x.icon, key: x.name, label: x.name }))} onSelect={(key: string) => { setSelectedOption(key) }} selectedOptionKey={selectedOption} />

    <Button size='LG' disabled={!selectedOption} onClick={handleNavigateNextPage}>
      <div className="flex items-center gap-x-2">
        <div>Continue</div>
        <ChevronRightIcon className="h-6 w-6" />
      </div>
    </Button>
  </>
}
