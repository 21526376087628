import React from 'react'
import { SurveyHeatPumpLocationContext } from '../heat_pump_location'
import { ClickableCard } from '../../../../components/content_display/card'
import { Toggle } from '../../../../components/inputs_and_selections/toggle'
import { checkIfWelsh } from '../../../../code/models/address'

export const HPLPlanningConsiderationsBlock = () => {
  const hplContext = React.useContext(SurveyHeatPumpLocationContext)
  const { survey, setSurvey, lead } = hplContext!

  return <>
    <div className="text-gray-900 text-xl font-bold">Planning considerations</div>
    <div className="gap-3 flex justify-between">
      <div className="text-gray-900 font-bold">Within {checkIfWelsh(lead.property.postcode) ? '3 metres' : '1 metre'} of boundary</div>
      <Toggle value={survey.within_1_metre_of_boundary} setValue={() => setSurvey({
        ...survey,
        within_1_metre_of_boundary: !survey.within_1_metre_of_boundary
      })}/>
    </div>
    <div className="gap-3 flex justify-between">
      <div className="text-gray-900 font-bold">Listed building</div>
      <Toggle value={survey.listing_building}
        setValue={() => setSurvey({ ...survey, listing_building: !survey.listing_building })}/>
    </div>
    <ClickableCard variant='GREY' className='gap-6'>
      <div className="gap-3 flex justify-between">
        <div className="text-gray-900 font-bold">Conservation area</div>
        <Toggle value={survey.conservation_area}
          setValue={() => setSurvey({ ...survey, conservation_area: !survey.conservation_area })}/>
      </div>
      <div className="gap-3 flex justify-between">
        <div className="text-gray-900 font-bold">Location fronts a highway</div>
        <Toggle value={survey.location_fronts_highway}
          setValue={() => setSurvey({ ...survey, location_fronts_highway: !survey.location_fronts_highway })}/>
      </div>
      {!survey.conservation_area && <div className="gap-3 flex justify-between">
        <div className="text-gray-900 font-bold">Location is above ground storey</div>
        <Toggle value={survey.location_above_ground_storey} setValue={() => setSurvey({
          ...survey,
          location_above_ground_storey: !survey.location_above_ground_storey
        })}/>
      </div>}
      {survey.conservation_area && <div className="gap-3 flex justify-between">
        <div className="text-gray-900 font-bold">Location is closer to highway than building</div>
        <Toggle value={survey.location_closer_to_highway} setValue={() => setSurvey({
          ...survey,
          location_closer_to_highway: !survey.location_closer_to_highway
        })}/>
      </div>}
    </ClickableCard>

  </>
}
