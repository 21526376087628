import React from 'react'
import { SurveyCylinderLocationContext } from '../cylinder_location'
import { Input } from '../../../../components/inputs_and_selections/input'
import { RadioGroup } from '../../../../components/inputs_and_selections/radio'
import { TextArea } from '../../../../components/inputs_and_selections/text_area'

export const CylinderLocationExistingDetailsBlock = () => {
  const cylinderLocationContext = React.useContext(SurveyCylinderLocationContext)
  const { survey, setSurvey } = cylinderLocationContext!

  const cylinderTypes = ['Vented', 'Unvented']

  return <>
    <div className="text-gray-900 text-xl font-bold">Existing cylinder</div>
    <div className="flex-col gap-2 flex">
      <div className="text-gray-900 font-bold ">Cylinder volume</div>
      <Input type='number' value={survey.existing_cylinder_volume_l.toString()}
        setValue={(e) => setSurvey({ ...survey, existing_cylinder_volume_l: Number(e) })}
        postfix={<span className='text-gray-500'>litres</span>}/>
    </div>
    <div className="flex-col gap-2 flex">
      <div className="text-gray-900 font-bold">Type</div>
      <RadioGroup items={cylinderTypes.map(x => ({
        name: x,
        onClick: () => setSurvey({ ...survey, existing_cylinder_type: x }),
        variant: survey.existing_cylinder_type === x ? 'ACTIVE' : 'DEFAULT'
      }))}/>
    </div>
    <div className="flex-col gap-2 flex">
      <div className="text-gray-900 font-bold ">Coil surface area</div>
      <Input type='number' value={survey.existing_cylinder_coil_size.toString()}
        setValue={(e) => setSurvey({ ...survey, existing_cylinder_coil_size: Number(e) })} postfix='m²'/>
    </div>
    <div className="flex-col gap-2 flex">
      <div className="text-gray-900 font-bold ">Brand and model</div>
      <Input value={survey.existing_cylinder_brand_and_model}
        setValue={(e) => setSurvey({ ...survey, existing_cylinder_brand_and_model: e })}/>
    </div>
    <div className="flex-col gap-2 flex">
      <div className="text-gray-900 font-bold">Location description</div>
      <TextArea value={survey.existing_cylinder_location_description}
        setValue={(e) => setSurvey({ ...survey, existing_cylinder_location_description: e })}
        placeholder='Physical location, where is it in relation to existing boiler'/>
    </div>
  </>
}
