import React from 'react'
import { Section } from '../../../../../components/containers/section'
import { updateCompanySettings, type Company } from '../../../../../code/models/company'
import { TextArea } from '../../../../../components/inputs_and_selections/text_area'
import { FormLabel } from '../../../../../components/inputs_and_selections/form_label'
import { VerticalFormGroup } from '../../../../../components/inputs_and_selections/vertical_form_group'
import { SettingsImageInput } from '../../components/settings_image_input'
import { ClickableCard } from '../../../../../components/content_display/card'
import { HorizontalFormGroup } from '../../../../../components/inputs_and_selections/horizontal_form_group'
import { Toggle } from '../../../../../components/inputs_and_selections/toggle'

export function EstimateAboutUsBlock ({ company, setCompany }: { company: Company, setCompany: (c: Company) => void }) {
  return (
    <Section border title='About us' >
      <div className='flex flex-col gap-4'>
        <ClickableCard variant='GREY'>
          <HorizontalFormGroup
            formLabel={
              <FormLabel
                labelText={'\'About us\' section'}
                helperText={'Display the \'About us\' section on the estimate'}
              />
            }
            input={<Toggle value={company.public_info.show_about_us_on_estimate}
              setValue={async () => {
                const newCompany = {
                  ...company,
                  public_info: {
                    ...company.public_info,
                    show_about_us_on_estimate: !company.public_info.show_about_us_on_estimate
                  }
                }
                setCompany(newCompany)
                await updateCompanySettings(newCompany)
              }} />}
          />
        </ClickableCard>
        <VerticalFormGroup
          formLabel={<FormLabel labelText='Description' />}
          input={<TextArea
            value={company.public_info.about_us ?? ''}
            setValue={aboutUsInput => setCompany({
              ...company,
              public_info: {
                ...company.public_info,
                about_us: aboutUsInput
              }
            })}
            placeholder='A little info about your company...'
            rows={6}
          />}
        />
        <VerticalFormGroup
          formLabel={<FormLabel labelText='Image' />}
          input={<SettingsImageInput
            buttonText='Upload image'
            placeholderText='No image'
            helperText='Maximum file size 5MB'
            b64Image={company.public_info.about_us_image ?? ''}
            altText='About us image'
            handleUpdateImage={async (imageSrc) => {
              const newCompany = {
                ...company,
                public_info: {
                  ...company.public_info,
                  about_us_image: imageSrc
                }
              }
              await updateCompanySettings(newCompany)
              setCompany(newCompany)
            }}
            handleRemoveImage={async () => {
              const newCompany = {
                ...company,
                public_info: {
                  ...company.public_info,
                  about_us_image: ''
                }
              }
              await updateCompanySettings(newCompany)
              setCompany(newCompany)
            }}
          />}
        />
      </div>
    </Section>
  )
}
