import React, { useEffect, useState } from 'react'
import { type CompanyPublicInfo } from '../code/models/company'
import { getHLReportPublicAPI, type HeatLossReportPublic } from '../code/models/heatloss_report'
import { HeatLossReportPage } from './heat_loss/proposal/heatloss_report'
import { Loader } from '../components/indicators_and_messaging/loader'

type Props = {
  reportUUID: string
  companyPublicInfo: CompanyPublicInfo
}

export const HeatLossReportPublicPage = (props: Props) => {
  const [report, setReport] = useState<HeatLossReportPublic>()

  useEffect(() => {
    const asyncCall = async () => {
      const result = await getHLReportPublicAPI(props.reportUUID, props.companyPublicInfo.uuid)
      if (result) {
        setReport(result)
      }
    }
    asyncCall()
  }, [])

  if (!report) {
    return (
      <div className="flex flex-col items-center justify-center mt-10 mx-auto">
        <Loader/>
      </div>
    )
  }

  return <>
    {/* no wrapper for print version */}
    {/* <div className='hidden print:inline'> */}
    {/*  <HeatLossReportPage */}
    {/*    reportUUID={props.reportUUID} */}
    {/*    sections={report.contents_bitmask} */}
    {/*    snapshot={report.snapshot} */}
    {/*    company_public_info={props.companyPublicInfo} */}
    {/*  /> */}
    {/* </div> */}

    {/* has wrapper for screen version */}
    <div className='print:bg-white bg-gray-100 lg:p-6 md:p-4 p-0'>
      <div className='bg-white print:max-w-none max-w-[260mm] print:m-0 m-auto pb-6 print:rounded-none rounded'>
        <HeatLossReportPage
          reportUUID={props.reportUUID}
          sections={report.contents_bitmask}
          snapshot={report.snapshot}
          company_public_info={props.companyPublicInfo}
        />
      </div>
    </div>

    {/* DO NOT REMOVE! This selector is used by browserless */}
    <div id='pdf-ready'/>
  </>
}
