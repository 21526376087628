import React from 'react'
import { ClickableCard } from '../../../components/content_display/card'
import { HLRContext } from './heatloss_report'
import { HOT_WATER_FLOW_TEMP_DELTA_T_C } from '../../../code/models/hot_water_cylinder'

export const HLRPESystemEfficiency1 = () => {
  const hlrContext = React.useContext(HLRContext)
  const heatPump = hlrContext!.currentHeatPump
  const hddPerformanceEstimate = hlrContext!.performanceEstimateSummary.hddEstimate
  return (
    <div className='flex gap-6 flex-col'>

      <div className='flex flex-col gap-2'>
        <div className="text-gray-900 text-lg font-bold">System efficiency</div>
        <div className="">
          The efficiency of a heating system describes how much useful output (heat) you get per unit of what you pay
          for
          (electricity/gas/oil). A system’s efficiency will often be different when it is doing space heating vs. when
          it’s making hot water because the temperature that the system is heating the water to is often hotter in water
          heating cycles. We have used the following inputs when modelling your system.
        </div>
      </div>

      <div className='grid grid-cols-1 sm:grid-cols-2 gap-4'>
        <ClickableCard variant="GREY" className='bg-gray-100 rounded-md p-5'>
          <div className='flex flex-col gap-4'>
            <div className="text-gray-900 font-bold">Existing heating system</div>

            <div>
              <div className="py-2 border-b border-gray-200 border-dashed items-baseline gap-2 flex justify-between">
                <div className="text-gray-900 text-xs font-semibold">Existing heating system fuel:</div>
                <div className="text-xs font-semibold text-nowrap">{hddPerformanceEstimate.existing.fuelName}</div>
              </div>
              <div className="py-2 border-b border-gray-200 border-dashed items-baseline gap-2 flex justify-between">
                <div className="text-gray-900 text-xs font-semibold">Hot water heated by:</div>
                <div className="text-xs font-semibold text-nowrap">{hddPerformanceEstimate.existing.fuelName}</div>
              </div>
              <div className="py-2 border-b border-gray-200 border-dashed items-baseline gap-2 flex justify-between">
                <div className="text-gray-900 text-xs font-semibold">Efficiency - space heating:</div>
                <div className="text-xs font-semibold text-nowrap">{hddPerformanceEstimate.existing.efficiencySpaceHeating * 100}%</div>
              </div>
              <div className="py-2 items-baseline gap-2 flex justify-between">
                <div className="text-gray-900 text-xs font-semibold">Efficiency - hot water:</div>
                <div className="text-xs font-semibold text-nowrap">{hddPerformanceEstimate.existing.efficiencySpaceHeating * 100}%</div>
              </div>
            </div>
          </div>
        </ClickableCard>

        <ClickableCard variant="GREY" className='bg-gray-100 rounded-md p-5'>
          <div className='flex flex-col gap-4'>
            <div className="text-gray-900 font-bold">Heat pump system</div>

            <div>
              <div className="py-2 border-b border-gray-200 border-dashed items-baseline gap-2 flex justify-between">
                <div className="text-gray-900 text-xs font-semibold">Type of system:</div>
                <div className="text-xs font-semibold text-nowrap">Air source heat pump</div>
              </div>
              <div className="py-2 border-b border-gray-200 border-dashed items-baseline gap-2 flex justify-between">
                <div className="text-gray-900 text-xs font-semibold">Model:</div>
                <div className="text-xs font-semibold text-nowrap">{heatPump.name}</div>
              </div>
              <div className="py-2 border-b border-gray-200 border-dashed items-baseline gap-2 flex justify-between">
                <div className="text-gray-900 text-xs font-semibold">MCS certificate numbers:</div>
                <div className="text-xs font-semibold text-nowrap">{heatPump.range_heat_pump?.mcs_certificate_numbers?.join(', ')}</div>
              </div>
              <div className="py-2 border-b border-gray-200 border-dashed items-baseline gap-2 flex justify-between">
                <div className="text-gray-900 text-xs font-semibold">Efficiency - space heating :</div>
                <div className="text-xs font-semibold text-nowrap">{(hddPerformanceEstimate.proposed.efficiencySpaceHeating * 100).toFixed(0)}%</div>
              </div>
              <div className="py-2 border-b border-gray-200 border-dashed items-baseline gap-2 flex justify-between">
                <div className="text-gray-900 text-xs font-semibold">Flow temperature - space heating:</div>
                <div className="text-xs font-semibold text-nowrap">{hlrContext!.design.flow_temp}°C</div>
              </div>
              <div className="py-2 border-b border-gray-200 border-dashed items-baseline gap-2 flex justify-between">
                <div className="text-gray-900 text-xs font-semibold">Efficiency - hot water:</div>
                <div className="text-xs font-semibold text-nowrap">{(hddPerformanceEstimate.proposed.efficiencyHotWater * 100).toFixed(0)}%</div>
              </div>
              <div className="py-2 items-baseline gap-2 flex justify-between">
                <div className="text-gray-900 text-xs font-semibold">Flow temperature - hot water:</div>
                <div className="text-xs font-semibold text-nowrap">{hlrContext!.design.hot_water_storage_temperature_c + HOT_WATER_FLOW_TEMP_DELTA_T_C}°C</div>
              </div>
            </div>
          </div>
        </ClickableCard>
      </div>

      <div className='flex flex-col gap-2'>
        <div className="text-gray-900 text-lg font-bold">Energy prices</div>
        <div className="">
          As the last few years have shown, energy prices are very hard to predict. In modelling your bills we have used
          the current energy price cap and also shown you a few different heat pump tariff options.
        </div>
        <div className="">
          There are an increasing number of heat pump tariffs that give you access to cheaper electricity. These fall
          into two categories: <span className='font-bold'>type of use</span> and <span className='font-bold'>time of use</span>.
        </div>
        <div className="">
          <span className='font-bold'>Type of use</span> heat pump tariffs detect how much electricity your heat pump is using, and charge you a lower rate
          for that electricity.
        </div>
        <div className="">
          <span className='font-bold'>Time of use</span> tariffs charge you different amounts depending on when you use the electricity. So you can set
          your hot water heating schedule (and to a lesser extent your space heating schedule) to make use of lower
          prices.
        </div>
      </div>

    </div>
  )
}
