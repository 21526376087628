import React from 'react'
import {
  getCylinderReheatCalculationRow,
  getDailyHotWaterVolumeL,
  getNumberOfOccupants
} from '../../../code/models/hot_water_cylinder'
import { ClickableCard } from '../../../components/content_display/card'
import { HLRContext } from './heatloss_report'
import { numberFormat } from '../../../code/number_format'

export const HLRHotWaterDemand = () => {
  const hlrContext = React.useContext(HLRContext)

  const numberOfOccupants = getNumberOfOccupants(hlrContext!.survey)
  const dailyHotWaterVolumeL = getDailyHotWaterVolumeL(numberOfOccupants, hlrContext!.survey.volume_per_person_l)
  const hotWaterConsumptionAnnual = hlrContext!.hotWaterRowNormal.electricityKWhPerYear + hlrContext!.hotWaterRowLegionella.electricityKWhPerYear
  const cylinderReheatRow = getCylinderReheatCalculationRow(hlrContext!.currentHotWaterCylinder, hlrContext!.design.hot_water_storage_temperature_c, hlrContext!.currentHeatPump?.range_heat_pump, hlrContext!.designTempC)

  return <div className='flex-col gap-9 flex'>
    <div className='flex-col gap-6 flex'>
      <div className='text-gray-900 font-bold'>Hot water demand</div>
      <div>The calculation below shows how much electricity we expect your heat pump to use for providing hot water,
        based
        on our assumptions about how much hot water people typically use in a year.
      </div>

      <div className='flex flex-col gap-4'>
        <div className="flex justify-between flew-row">
          <div className="text-gray-900 font-bold">Total electricity per year for hot water</div>
          <div className="text-gray-900 font-bold">{numberFormat(0).format(hotWaterConsumptionAnnual)} kWh</div>
        </div>

        <div className='grid grid-cols-1 sm:grid-cols-2 gap-4'>
          <ClickableCard variant="GREY" className='bg-gray-100 rounded-md p-5'>
            <div className='flex flex-col'>

              <div className="py-3 justify-between text-sm font-bold text-gray-900 flex">
                <div className=''>Normal operation</div>
                <div>{numberFormat(0).format(hlrContext!.hotWaterRowNormal.electricityKWhPerYear)} kWh</div>
              </div>

              <div className="py-3 justify-between flex text-xs">
                <div className='text-gray-900 font-semibold'>Electricity per day</div>
                <div>{numberFormat(2).format(hlrContext!.hotWaterRowNormal.electricityKwhPerCycle)} kWh</div>
              </div>
              <div className="py-3 border-b border-gray-200 border-dashed justify-between flex text-xs">
                <div className='text-gray-900 font-semibold'>Heat energy per day</div>
                <div>{numberFormat(2).format(hlrContext!.hotWaterRowNormal.heatEnergyKwhPerCycle)} kWh</div>
              </div>
              <div className="py-3 border-b border-gray-200 border-dashed justify-between flex indent-6 text-xs">
                <div>Volume per day</div>
                <div>{dailyHotWaterVolumeL} litres/day</div>
              </div>
              <div className="py-3 border-b border-gray-200 border-dashed justify-between flex indent-6 text-xs">
                <div>Assumed mains water temp</div>
                <div>{hlrContext!.hotWaterRowNormal.coldWaterTempC} °C</div>
              </div>
              <div className="py-3 border-b border-gray-200 border-dashed justify-between flex indent-6 text-xs">
                <div>Storage temp</div>
                <div>{hlrContext!.hotWaterRowNormal.hotWaterTempC} °C</div>
              </div>
              <div className="py-3 border-b border-gray-200 border-dashed justify-between flex indent-6 text-xs">
                <div>Distribution efficiency</div>
                <div>{hlrContext!.hotWaterRowNormal.distributionEfficiency * 100} %</div>
              </div>
              <div className="py-3 justify-between flex text-xs">
                <div className='text-gray-900 font-semibold'>Heat pump sCOP
                  ({hlrContext!.cylinderReheatRow.flowTempC} °C flow)
                </div>
                <div>{hlrContext!.hotWaterRowNormal.heatingEfficiency * 100} %</div>
              </div>
            </div>
          </ClickableCard>

          {/* Legionella */}
          <ClickableCard variant="GREY" className='bg-gray-100 rounded-md p-5'>
            <div className='flex flex-col'>

              <div className="py-3 justify-between text-sm font-bold text-gray-900 flex">
                <div className=''>Legionella cycles</div>
                <div>{numberFormat(0).format(hlrContext!.hotWaterRowLegionella.electricityKWhPerYear)} kWh</div>
              </div>

              <div className="py-3 justify-between flex text-xs">
                <div className='text-gray-900 font-semibold'>Electricity per cycle</div>
                <div>{numberFormat(2).format(hlrContext!.hotWaterRowLegionella.electricityKwhPerCycle)} kWh</div>
              </div>
              <div className="py-3 border-b border-gray-200 border-dashed justify-between flex text-xs">
                <div className='text-gray-900 font-semibold'>Heat energy per cycle</div>
                <div>{numberFormat(2).format(hlrContext!.hotWaterRowLegionella.heatEnergyKwhPerCycle)} kWh</div>
              </div>
              <div className="py-3 border-b border-gray-200 border-dashed justify-between flex indent-6 text-xs">
                <div>Volume per cycle</div>
                <div>{Math.round(cylinderReheatRow.cylinderVolumeL)} litres</div>
              </div>
              <div className="py-3 border-b border-gray-200 border-dashed justify-between flex indent-6 text-xs">
                <div>Tank starting temperature</div>
                <div>{hlrContext!.hotWaterRowLegionella.coldWaterTempC} °C</div>
              </div>
              <div className="py-3 border-b border-gray-200 border-dashed justify-between flex indent-6 text-xs">
                <div>Legionella set point</div>
                <div>{hlrContext!.hotWaterRowLegionella.hotWaterTempC} °C</div>
              </div>
              <div className="py-3 border-b border-gray-200 border-dashed justify-between flex indent-6 text-xs">
                <div>Distribution efficiency</div>
                <div>{hlrContext!.hotWaterRowLegionella.distributionEfficiency * 100} %</div>
              </div>
              <div className="py-3 border-b border-gray-200 border-dashed justify-between flex text-xs">
                <div className='text-gray-900 font-semibold'>Legionella cycle efficiency</div>
                <div>{hlrContext!.hotWaterRowLegionella.heatingEfficiency * 100} %</div>
              </div>
              <div className="py-3 justify-between flex text-xs">
                <div className='text-gray-900 font-semibold'>Cycles per year</div>
                <div>{(hlrContext!.hotWaterRowLegionella.cyclesPerYear).toFixed(0)}</div>
              </div>
            </div>
          </ClickableCard>

        </div>
      </div>
    </div>

    <div className='flex flex-col gap-4'>
      <div className="flex justify-between flew-row">
        <div className="text-gray-900 font-bold">Reheat time</div>
        <div className="text-gray-900 font-bold">{hlrContext!.cylinderReheatRow.reheatTimeHoursAndMinutes}</div>
      </div>

      <ClickableCard variant="GREY" className='bg-gray-100 rounded-md p-5'>
        <div className='flex flex-col'>
          <div className="">
            <span className="text-xs font-semibold">Once drained, it will take your cylinder</span>
            <span className="text-gray-900 text-xs font-semibold"> </span>
            <span className="text-gray-900 text-xs font-bold">{hlrContext!.cylinderReheatRow.reheatTimeHoursAndMinutes}</span>
            <span className="text-xs font-semibold"> to fully reheat based on the following inputs:</span>
          </div>

          <div className="py-3 border-b border-gray-200 border-dashed justify-between flex text-xs">
            <div className='text-gray-900 font-semibold'>Cylinder volume</div>
            <div>{Math.round(hlrContext!.cylinderReheatRow.cylinderVolumeL)} litres</div>
          </div>
          <div className="py-3 border-b border-gray-200 border-dashed justify-between flex text-xs">
            <div className='text-gray-900 font-semibold'>Mains water temp</div>
            <div>{hlrContext!.cylinderReheatRow.coldWaterTempC} °C</div>
          </div>
          <div className="py-3 border-b border-gray-200 border-dashed justify-between flex text-xs">
            <div className='text-gray-900 font-semibold'>Hot water storage temp</div>
            <div>{hlrContext!.cylinderReheatRow.hotWaterStorageTempC} °C</div>
          </div>
          <div className="py-3 justify-between flex text-xs">
            <div className='text-gray-900 font-semibold'>Heating power</div>
            <div>{hlrContext!.cylinderReheatRow.heatingPowerKw} kW</div>
          </div>
        </div>
      </ClickableCard>
    </div>
  </div>
}
