import React from 'react'

export type HorizontalFormGroupProps = {
  formLabel: JSX.Element
  input: JSX.Element
  disabled?: boolean
  className?: string
}

export const HorizontalFormGroup = (
  {
    formLabel,
    disabled,
    input,
    className = ''
  }: HorizontalFormGroupProps) => {
  return (
    <div className={`${className} gap-3 flex items-center justify-between ${disabled ? 'opacity-50' : ''}`}>
      <div className={'flex-col flex'}>{formLabel}</div>
      <div>{input}</div>
    </div>

  )
}
