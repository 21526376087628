import React, { type Dispatch, type SetStateAction, useEffect, useState } from 'react'
import { PageHeader } from '../components/design_page_header'
import { type Floor } from '../../../../code/models/floor'
import {
  type Emitter,
  getEmitterSizeName,
  getEmitterTypeName,
  getRadiatorHeightWidthText,
  type Radiator
} from '../../../../code/models/radiator'
import { Badge } from '../../../../components/indicators_and_messaging/badge'
import { Button } from '../../../../components/buttons/button'
import { getRoomTemp, getRoomWatts } from '../../../../code/models/heat_loss'
import {
  getEmitterVelocityMPerS,
  getEmitterWatts,
  getRadiatorWatts,
  type RadiatorModel
} from '../../../../code/models/radiator_model'
import { chain, noop, sum } from 'lodash'
import { Slider } from '../../../../components/inputs_and_selections/slider'
import { type PropertySurvey, type SurveyDesign } from '../../../../code/models/property'
import { getRoomEmitterWatts, type Room } from '../../../../code/models/room'
import {
  DEFAULT_DESIGN_RADIATOR,
  DEFAULT_SURVEY_RADIATOR,
  DEFAULT_SURVEY_UNDERFLOOR
} from '../../../../code/survey_defaults'
import { ClickableCard } from '../../../../components/content_display/card'
import { Link } from '../../../../components/buttons/link'
import { PhotoScroll } from '../../../../components/inputs_and_selections/photo_scroll'
import { type FileWrapper } from '../../file_wrapper'
import { SelectRadiatorModelInner } from './radiator_model_selector'
import { type UnderfloorHeating } from '../../../../code/models/underfloor_heating'
import { getEmitterOutputVsDemandText } from '../../floor/code/utils'
import { Toggle } from '../../../../components/inputs_and_selections/toggle'
import { TextArea } from '../../../../components/inputs_and_selections/text_area'
import { ListItem } from '../../../../components/content_display/list_item'
import { UnderfloorEmitter } from '../../underfloor/underfloor_emitter'
import { numberFormat } from '../../../../code/number_format'
import { useAnimate } from 'framer-motion'
import { type EmitterDemandStatus } from '../../../admin/job_layout/job_layout'
import { ArrowRight, Trash } from 'lucide-react'
import { WrappedIcon } from '../../../../components/buttons/wrapped_icon'

type AddRadiatorModalProps = {
  room: Room | undefined
  floor: Floor | undefined
  roomTempC: number | undefined
  requiredOutputW: number | undefined
  originalEmitter: Emitter | undefined
  replacementEmitter: Emitter | undefined
}

type EmitterDesignPageProps = {
  customRadiatorModels: RadiatorModel[]
  setCustomRadiatorModels: Dispatch<SetStateAction<RadiatorModel[]>>
  allRadiatorModels: RadiatorModel[]
  floors: Floor[]
  setFloor: (floor: Floor) => void
  setFlowTemp: (flowTemp: number) => void
  minFlowTemp: number
  maxFlowTemp: number
  designTempC: number
  groundTempC: number
  survey: PropertySurvey
  design: SurveyDesign
  setDesign: (design: SurveyDesign) => void
  annualBillEstimateGBP: string
  files: FileWrapper[]
  emitterDemandStatus: EmitterDemandStatus
  totalHeatLossKw: number
  companyUuid: string
  setSurvey: Dispatch<SetStateAction<PropertySurvey>>
  setFiles: Dispatch<SetStateAction<FileWrapper[]>>
  isOffline: boolean
  allSynced: boolean
}

export const EmitterDesignPage = ({
  customRadiatorModels,
  setCustomRadiatorModels,
  setFlowTemp,
  minFlowTemp,
  maxFlowTemp,
  designTempC,
  groundTempC,
  survey,
  design,
  setDesign,
  annualBillEstimateGBP,
  files,
  emitterDemandStatus,
  totalHeatLossKw,
  companyUuid,
  setSurvey,
  setFiles,
  isOffline,
  allSynced
}: EmitterDesignPageProps) => {
  // control link between each radiator card and the radiator type inner modal
  const [addRadiatorModal, setAddRadiatorModal] = useState<AddRadiatorModalProps>({
    room: undefined,
    floor: undefined,
    roomTempC: undefined,
    requiredOutputW: undefined,
    originalEmitter: undefined,
    replacementEmitter: undefined
  })

  const [emitterUUIDToHighlight, setEmitterUUIDToHighlight] = useState<string | undefined>(undefined)

  const setUnderfloorHeating: Dispatch<SetStateAction<UnderfloorHeating>> = async (updateFnOrValue: UnderfloorHeating | ((emitter: UnderfloorHeating) => UnderfloorHeating)) => setAddRadiatorModal(prev => {
    const updatedEmitter = typeof updateFnOrValue === 'function' ? updateFnOrValue(prev.replacementEmitter! as UnderfloorHeating) : updateFnOrValue
    return { ...prev, replacementEmitter: updatedEmitter }
  })

  // Duplicate radiator pages piece of floor plan pages
  type RadiatorPages = 'RADIATOR_MODEL' | 'ADD_CUSTOM_RADIATOR'
  const [pages, setPages] = useState<RadiatorPages[]>(['RADIATOR_MODEL'])
  const page = pages[pages.length - 1]

  const onBack = () => setPages(prev => prev.slice(0, -1))
  const setPage = (page: RadiatorPages) => setPages(prev => [...prev, page])

  const totalWattsEmitted = sum(survey.floors.map(f => sum(f.rooms.map(r => getRoomEmitterWatts(r, design, survey, designTempC, groundTempC)))))

  // Select an emitter type.
  return <>
    {/* Case add emitter (not replacing one) */}
    { addRadiatorModal.room && !addRadiatorModal.replacementEmitter &&
      <div className='flex flex-col h-full'>
        <PageHeader isOffline={isOffline} allSynced={allSynced} title='Select emitter' onBack={() => setAddRadiatorModal({
          room: undefined,
          roomTempC: undefined,
          requiredOutputW: undefined,
          originalEmitter: undefined,
          replacementEmitter: undefined,
          floor: undefined
        })}/>
        <div className='flex flex-col divide-y divide-gray-200'>
          <ListItem
            primaryText='Radiator'
            onClick={() => {
              setAddRadiatorModal(prev => ({
                ...prev,
                replacementEmitter: {
                  ...DEFAULT_SURVEY_RADIATOR,
                  survey_or_design: 'DESIGN',
                  emitter_type: 'RADIATOR',
                  room_uuid: prev.room!.uuid!,
                  pipe_model_uuid: survey.existing_system_radiator_pipework_uuid,
                  updated_at: new Date().getTime(),
                  created_at: new Date().getTime()
                }
              }))
            }}
          />
          {/* Don't allow replacing radiator with underfloor. */}
          {!addRadiatorModal.originalEmitter && <ListItem primaryText='Underfloor heating' onClick={() => {
            setAddRadiatorModal(prev => ({
              ...prev,
              replacementEmitter: {
                ...DEFAULT_SURVEY_UNDERFLOOR,
                survey_or_design: 'DESIGN',
                room_uuid: prev.room!.uuid!,
                updated_at: new Date().getTime(),
                created_at: new Date().getTime()
              }
            }))
          }}/>}
        </div>
      </div>
    }

    {/* Case editing underfloor */}
    {addRadiatorModal.room && addRadiatorModal.replacementEmitter && addRadiatorModal.replacementEmitter.emitter_type === 'UNDERFLOOR' &&
      <div className={'flex flex-col h-full'}>
        <PageHeader isOffline={isOffline} allSynced={allSynced} title={`Underfloor heating - ${addRadiatorModal.room.name}`} onBack={() => setAddRadiatorModal({
          room: undefined,
          roomTempC: undefined,
          requiredOutputW: undefined,
          originalEmitter: undefined,
          replacementEmitter: undefined,
          floor: undefined
        })}/>
        <UnderfloorEmitter
          underfloorHeating={addRadiatorModal.replacementEmitter }
          setUnderfloorHeating={setUnderfloorHeating}
          room={addRadiatorModal.room}
          floor={addRadiatorModal.floor!}
          survey={survey}
          designTempC={designTempC}
          groundTempC={groundTempC}
          setFlowTemp={setFlowTemp}
          minFlowTemp={minFlowTemp}
          maxFlowTemp={maxFlowTemp}
          onSave={() => {
            const newUUID = crypto.randomUUID()
            setDesign({
              ...design,
              radiators: addRadiatorModal.replacementEmitter?.uuid
                ? design.radiators.map(x => x.uuid === addRadiatorModal.replacementEmitter!.uuid ? addRadiatorModal.replacementEmitter! : x)
                : [...design.radiators, { ...addRadiatorModal.replacementEmitter!, uuid: newUUID }]
            })
            setAddRadiatorModal({
              room: undefined,
              roomTempC: undefined,
              requiredOutputW: undefined,
              originalEmitter: undefined,
              replacementEmitter: undefined,
              floor: undefined
            })

            setEmitterUUIDToHighlight(addRadiatorModal.replacementEmitter?.uuid ?? newUUID)
          }}
          files={files}
          setFiles={setFiles}
          design={design}
          setSurvey={setSurvey}
          page={page}
          setPage={setPage}
          onBack={onBack}
          companyUUID={companyUuid}
        />
      </div>
    }
    {/* Case replacing or editing a radiator */}
    { addRadiatorModal.room && addRadiatorModal.replacementEmitter && addRadiatorModal.replacementEmitter.emitter_type !== 'UNDERFLOOR' &&
      <div className='flex flex-col h-full'>
        {page === 'RADIATOR_MODEL' && <PageHeader
          title={`Select radiator - ${addRadiatorModal.room.name}`}
          isOffline={isOffline}
          allSynced={allSynced}
          onBack={() => setAddRadiatorModal({
            room: undefined,
            roomTempC: undefined,
            requiredOutputW: undefined,
            originalEmitter: undefined,
            replacementEmitter: undefined,
            floor: undefined
          })}/>}
        {page === 'ADD_CUSTOM_RADIATOR' && <PageHeader isOffline={isOffline} allSynced={allSynced} title='Add custom radiator' onBack={onBack}/>}
        <div className='overflow-y-auto'>
          <SelectRadiatorModelInner
            customRadiatorModels={customRadiatorModels}
            setCustomRadiatorModels={setCustomRadiatorModels}
            originallySelectedModelUuid={
              (addRadiatorModal.replacementEmitter?.emitter_type === 'RADIATOR' ? addRadiatorModal.replacementEmitter?.radiator_type?.uuid : '') ??
              (addRadiatorModal.originalEmitter?.emitter_type === 'RADIATOR' ? addRadiatorModal.originalEmitter?.radiator_type?.uuid : '')}
            maxWidthMm={addRadiatorModal.originalEmitter?.emitter_type === 'RADIATOR' ? addRadiatorModal.originalEmitter?.maximum_width : undefined}
            maxHeightMm={addRadiatorModal.originalEmitter?.emitter_type === 'RADIATOR' ? addRadiatorModal.originalEmitter?.maximum_height : undefined}
            flowTemp={design.flow_temp}
            deltaTFlowReturnC={design.delta_t_flow_return_c}
            roomTemp={addRadiatorModal.roomTempC!}
            requiredOutput={addRadiatorModal.requiredOutputW}
            companyUuid={companyUuid}
            addRadiator={(radiatorModel) => {
              // a variable to hightlight the newly added radiator
              let highlightUUID: string | undefined

              if (addRadiatorModal.replacementEmitter?.uuid) {
                highlightUUID = addRadiatorModal.replacementEmitter.uuid
                setDesign({
                  ...design,
                  radiators: design.radiators.map(x => x.uuid === addRadiatorModal.replacementEmitter!.uuid ? {
                    ...x,
                    radiator_type: radiatorModel,
                    radiator_type_uuid: radiatorModel.uuid!,
                    updated_at: new Date().getTime()
                  } : x)
                })
              } else { // add
                // if replacing another rad, use survey pipework diameter if defined. Otherwise assume same as default rad pipework for now. Should really let user choose on add
                const pipeModelUuid = addRadiatorModal.originalEmitter?.emitter_type === 'RADIATOR' || addRadiatorModal.originalEmitter?.emitter_type === 'UNDERFLOOR'
                  ? addRadiatorModal.originalEmitter.pipe_model_uuid
                  : survey.existing_system_radiator_pipework_uuid

                const newRadiator: Radiator = {
                  ...DEFAULT_DESIGN_RADIATOR,
                  uuid: crypto.randomUUID(),
                  emitter_type: 'RADIATOR',
                  radiator_type: radiatorModel,
                  radiator_type_uuid: radiatorModel.uuid!,
                  pipe_model_uuid: pipeModelUuid,
                  pipe_diameter_uuid: undefined,
                  survey_design_uuid: design.uuid,
                  room_uuid: addRadiatorModal.room!.uuid!,
                  replaces_uuid: addRadiatorModal.originalEmitter?.uuid,
                  created_at: new Date().getTime(),
                  updated_at: new Date().getTime(),
                  maximum_height: 0,
                  maximum_width: 0,
                  notes: ''
                }

                highlightUUID = newRadiator.uuid
                setDesign({ ...design, radiators: [...design.radiators, newRadiator] })
              }

              setAddRadiatorModal({
                room: undefined,
                roomTempC: undefined,
                requiredOutputW: undefined,
                originalEmitter: undefined,
                replacementEmitter: undefined,
                floor: undefined
              })

              setEmitterUUIDToHighlight(highlightUUID)
            }}
            designContextContent={<SelectRadiatorModelDesignContext files={files}
              requiredOutput={addRadiatorModal.requiredOutputW}
              originalEmitter={addRadiatorModal.originalEmitter as Radiator}
              companyUUID={companyUuid}/>}
            setPage={setPage}
            page={page}
            onBack={onBack}
          />
        </div>
      </div>}

    {/*
    We always should render this block, because we do some animations and scrolling magic on changing/adding emitters
    This is why we're hiding it if we need to show a modal (above), not returning the modal earlier from the component skipping renreding this block
    */}
    <div className={`${addRadiatorModal.room ? 'hidden' : 'flex'} bg-gray-50 flex-col h-full min-h-0`}>
      <PageHeader isOffline={isOffline} allSynced={allSynced} title='Emitters' onBack={() => window.history.back()}/>

      <div className='flex flex-col gap-6 p-4 overflow-y-auto'>
        {/* Summary section */}
        <ClickableCard border={false} variant='WHITE'>
          <div className='flex flex-col gap-4 justify-between flex-grow'>
            <FlowTempSlider
              flowTemp={design.flow_temp}
              setFlowTemp={setFlowTemp}
              minFlowTemp={minFlowTemp}
              maxFlowTemp={maxFlowTemp}
            />
            <div className="gap-3 flex justify-between">
              <div className="flex-col gap-1 flex">
                <div className="text-gray-900 text-xs font-bold">Running costs</div>
                <div className="text-gray-600 text-sm">{annualBillEstimateGBP} per year</div>
              </div>
              <div className="flex-col gap-1.5 flex">
                <div className="text-gray-900 text-xs font-bold">Emitters by room</div>
                <Badge
                  color={emitterDemandStatus === 'Sufficient' ? 'GREEN' : emitterDemandStatus === 'Accepted' ? 'YELLOW' : 'RED'}
                  text={emitterDemandStatus}/>
              </div>
              <div className="flex-col gap-1.5 flex">
                <div className="text-gray-900 text-xs font-bold">Emitters overall</div>
                <div
                  className="text-gray-600 text-sm">{`${numberFormat(2).format(totalWattsEmitted / 1000)} kW of ${numberFormat(2).format(totalHeatLossKw)} kW`}</div>
              </div>
            </div>
          </div>
        </ClickableCard>
        {/* For each floor */}
        {survey.floors.map((f, fIdx) => {
          const roomGroups = chain(f.rooms)
            .groupBy(x => x.room_group_uuid ? x.room_group_uuid : x.uuid)
            .map((values, key) => ({ key, values }))
            .value()
          const floorEmitterWatts = sum(f.rooms.map(r => getRoomEmitterWatts(r, design, survey, designTempC, groundTempC)))
          const floorDemandWatts = sum(f.rooms.map(r => getRoomWatts(r, f.rooms, designTempC, groundTempC, survey)))
          return <div key={fIdx} className='flex flex-col gap-4'>
            <div className="border border-gray-200"/>
            <div className='flex justify-between'>
              <div className='font-bold text-lg text-gray-900'>{f.name}</div>
              <div
                className="font-bold text-xs text-gray-900">{getEmitterOutputVsDemandText(floorEmitterWatts, floorDemandWatts)}</div>
            </div>
            {/* Badge is light (which doesn't actually show up on grey background, as don't want to imply it's ok if the total is high enough */}
            {
              roomGroups.map(rg => <>
                {getRoomGroupRadiatorCards(emitterUUIDToHighlight, setEmitterUUIDToHighlight, rg, f, designTempC, groundTempC, survey, design, setAddRadiatorModal, setDesign)}
              </>)
            }
          </div>
        })}
      </div>
    </div>
  </>
}

type UndersizedToggleProps = {
  design: SurveyDesign
  setDesign: (design: SurveyDesign) => void
  roomOrRoomGroupUUID: string
}
const UndersizedToggle = ({ design, roomOrRoomGroupUUID, setDesign }: UndersizedToggleProps) => {
  const undersized = design.undersized_emitter_rooms.find(x => x.room_uuid === roomOrRoomGroupUUID)
  return <div className="px-3 py-4 flex-col justify-center gap-4 flex bg-white rounded-md">
    <div className="items-center gap-4 flex justify-between">
      <div className="text-gray-900 text-sm font-bold">Accept undersized emitters?</div>
      <Toggle value={!!undersized} setValue={() => setDesign({
        ...design,
        undersized_emitter_rooms: undersized
          ? design.undersized_emitter_rooms.filter(x => x.room_uuid !== roomOrRoomGroupUUID)
          : [...design.undersized_emitter_rooms, { room_uuid: roomOrRoomGroupUUID, notes: '' }]
      })} />
    </div>
    {!!undersized && <div className="flex-col gap-2 flex">
      <div className="flex-col flex">
        <div className="text-gray-900 text-sm font-bold">Details</div>
        <div className="text-gray-500 text-xs">Please provide reasoning that will show on homeowner report</div>
      </div>
      <TextArea value={undersized.notes} setValue={(e) => setDesign({ ...design, undersized_emitter_rooms: design.undersized_emitter_rooms.map(x => x.room_uuid === undersized.room_uuid ? { ...undersized, notes: e } : x) })} />
    </div>}
  </div>
}

const getRoomGroupRadiatorCards = (
  emitterUUIDToHighlight: string | undefined,
  setEmitterUUIDToHighlight: Dispatch<SetStateAction<string | undefined>>,
  roomGroup: { key: string, values: Room[] },
  floor: Floor,
  designTempC: number,
  groundTempC: number,
  survey: PropertySurvey,
  design: SurveyDesign,
  setAddRadiatorModal: (addRadiatorModelProps: AddRadiatorModalProps) => void,
  setDesign: (design: SurveyDesign) => void
) => {
  const roomGroupEmitterWatts = sum(roomGroup.values.map(r => getRoomEmitterWatts(r, design, survey, designTempC, groundTempC)))
  const roomGroupRoomWatts = sum(roomGroup.values.map(r => getRoomWatts(r, floor.rooms, designTempC, groundTempC, survey)))
  const roomGroupShortfall = roomGroupRoomWatts - roomGroupEmitterWatts

  const isMultiRoomGroup = roomGroup.values.length > 1

  return <div key={roomGroup.key} className='flex flex-col gap-3'>
    {isMultiRoomGroup && <div className='flex justify-between'>
      <div className='text-gray-900 font-bold'>{roomGroup.values.map(x => x.name).join(', ')}</div>
      <Badge
        color={roomGroupEmitterWatts >= roomGroupRoomWatts
          ? 'GREEN' : design.undersized_emitter_rooms.some(x => x.room_uuid === roomGroup.key)
            ? 'YELLOW'
            : 'RED'}
        text={getEmitterOutputVsDemandText(roomGroupEmitterWatts, roomGroupRoomWatts)} />
    </div>}
    {isMultiRoomGroup && roomGroupShortfall > 0 && <UndersizedToggle design={design} roomOrRoomGroupUUID={roomGroup.key} setDesign={setDesign} />}
    {roomGroup.values.map(r => getRoomRadiatorCards(emitterUUIDToHighlight, setEmitterUUIDToHighlight, r, floor, designTempC, groundTempC, survey, design, isMultiRoomGroup, roomGroupShortfall, setAddRadiatorModal, setDesign))}
  </div>
}

const getRoomRadiatorCards = (
  emitterUUIDToHighlight: string | undefined,
  setEmitterUUIDToHighlight: Dispatch<SetStateAction<string | undefined>>,
  room: Room,
  floor: Floor,
  designTempC: number,
  groundTempC: number,
  survey: PropertySurvey,
  design: SurveyDesign,
  inMultiRoomGroup: boolean,
  roomGroupShortfall: number,
  setAddRadiatorModal: (addRadiatorModelProps: AddRadiatorModalProps) => void,
  setDesign: (design: SurveyDesign) => void
) => {
  const replacementEmitters = design.radiators.filter(x => x.room_uuid === room.uuid)
  const roomEmitterWatts = getRoomEmitterWatts(room, design, survey, designTempC, groundTempC)
  const roomWatts = getRoomWatts(room, floor.rooms, designTempC, groundTempC, survey)
  const roomTempC = getRoomTemp(room, survey)

  const [scope, animate] = useAnimate()

  useEffect(() => {
    if (emitterUUIDToHighlight) {
      const element = document.getElementById(emitterUUIDToHighlight)
      if (!element) return
      element.scrollIntoView({ behavior: 'smooth', block: 'center' })
      animate([
        [`[id="${emitterUUIDToHighlight}"]`, { scale: 0.98, backgroundColor: '#dcfce7' }, { ease: 'easeInOut', duration: 0.5 }],
        [`[id="${emitterUUIDToHighlight}"]`, { scale: 1 }, { ease: 'easeIn', duration: 0.1 }],
        [`[id="${emitterUUIDToHighlight}"]`, { backgroundColor: '#ffffff' }, { ease: 'easeOut', duration: 4 }]
      ])
      setEmitterUUIDToHighlight(undefined)
    }
  },
  // looking for design.radiators changes as well, because emitterUUIDToHighlight may be set before the radiator is really rendered
  [design.radiators, emitterUUIDToHighlight]
  )

  return <div ref={scope} key={room.uuid} className='flex flex-col gap-2'>
    <div className='flex justify-between items-center'>
      <div className={`${inMultiRoomGroup ? '' : 'font-bold text-gray-900'}`}>{room.name}</div>
      <div className='flex gap-2 items-center'>
        {!inMultiRoomGroup &&
            <Badge color={roomEmitterWatts >= roomWatts
              ? 'GREEN' : design.undersized_emitter_rooms.some(x => x.room_uuid === room.uuid)
                ? 'YELLOW'
                : 'RED'}
            text={getEmitterOutputVsDemandText(roomEmitterWatts, roomWatts)}/>
        }
        {inMultiRoomGroup && <div className='text-sm'>{Math.round(roomWatts)} W</div>}
        <Button onClick={() => {
          setAddRadiatorModal({
            room,
            floor,
            roomTempC,
            requiredOutputW: roomGroupShortfall,
            originalEmitter: undefined,
            replacementEmitter: undefined
          })
        }} colour='GRAY' size='SM'>Add</Button>
      </div>
    </div>
    {!inMultiRoomGroup && roomGroupShortfall > 0 && <UndersizedToggle roomOrRoomGroupUUID={room.uuid!} design={design} setDesign={setDesign} />}
    <div className='rounded-md bg-white divide divide-y divide-gray-100'>
      {[...replacementEmitters.filter(x => !x.replaces_uuid), ...room.radiators].map(
        ra => { // filter out replacement rads as they will be shown with the rad they replace
          const emitterWatts = Math.round(getEmitterWatts(ra, room, design, survey, designTempC, groundTempC))
          const velocity = getEmitterVelocityMPerS(ra, emitterWatts, design.delta_t_flow_return_c, design.flow_temp)

          // Survey rad is defined if "surveyed/kept" or "replaced"
          const originalEmitter = ra.survey_or_design === 'SURVEY' ? ra : undefined

          // Design radiator will be defined in cases 'Added' and 'Replacement'
          // Replacement rads filtered out above, so if it's a design rad it's an added rad
          const addedRadiator = ra.survey_or_design === 'DESIGN' ? ra : undefined
          // If the survey rad is being replaced then we have a replacement radiator, we can only replace Radiators not any other Emitters
          const replacementRadiator = originalEmitter && replacementEmitters.find(x => x.replaces_uuid === originalEmitter?.uuid) as Radiator
          const replacementEmitter = addedRadiator ?? replacementRadiator ?? undefined

          const replacementRadiatorWatts = replacementRadiator ? getRadiatorWatts(replacementRadiator.radiator_type!, design.flow_temp, design.delta_t_flow_return_c, roomTempC) : 0
          const replacementRadiatorVelocity = replacementRadiator
            ? getEmitterVelocityMPerS(replacementRadiator, replacementRadiatorWatts, design.delta_t_flow_return_c, design.flow_temp)
            : undefined

          // If there is a replacement emitter we are editing, then we need the required output excluding that replacement emitter
          // If we are replacing the selected emitter, then it's the required output excluding the existing emitter that we are replacing
          const requiredOutputW = roomGroupShortfall + (replacementRadiator ? replacementRadiatorWatts : emitterWatts)

          if (ra.emitter_type === 'UNDERFLOOR') {
            return <UnderfloorCard
              key={ra.uuid}
              radiator={ra}
              watts={emitterWatts}
              velocity={velocity}
              removedRadiators={design.removed_radiator_uuids}
              survey={survey}
              removeRadiator={() => {
                setDesign({
                  ...design,
                  radiators: replacementEmitter ? design.radiators.filter(x => x.uuid !== replacementEmitter.uuid) : design.radiators,
                  removed_radiator_uuids: originalEmitter
                    ? [...design.removed_radiator_uuids, originalEmitter.uuid!]
                    : design.removed_radiator_uuids
                })
              }}
              editRadiator={() => {
                setAddRadiatorModal({ room, floor, roomTempC, requiredOutputW, originalEmitter, replacementEmitter })
              }}
              restoreRadiator={() => {
                setDesign({
                  ...design,
                  radiators: design.radiators.filter(x => x.uuid !== replacementRadiator?.uuid),
                  removed_radiator_uuids: design.removed_radiator_uuids.filter(x => x !== ra.uuid!)
                })
              }}
            />
          }
          return <>
            <RadiatorCard
              hasReplacement={!!replacementRadiator}
              velocity={velocity}
              deltaTFlowReturnC={design.delta_t_flow_return_c}
              key={ra.uuid}
              radiator={ra}
              editRadiator={() => {
                setAddRadiatorModal({ room, floor, roomTempC, requiredOutputW, originalEmitter, replacementEmitter })
              }}
              watts={emitterWatts}
              removeRadiator={() => {
                setDesign({
                  ...design,
                  radiators: replacementEmitter ? design.radiators.filter(x => x.uuid !== replacementEmitter.uuid) : design.radiators,
                  removed_radiator_uuids: originalEmitter
                    ? [...design.removed_radiator_uuids, originalEmitter.uuid!]
                    : design.removed_radiator_uuids
                })
              }}
              replaceRadiator={() => {
                setAddRadiatorModal({
                  room,
                  floor,
                  roomTempC,
                  requiredOutputW,
                  originalEmitter,
                  replacementEmitter: addedRadiator ?? {
                    ...DEFAULT_SURVEY_RADIATOR,
                    survey_or_design: 'DESIGN',
                    emitter_type: 'RADIATOR',
                    room_uuid: room.uuid!,
                    pipe_model_uuid: survey.existing_system_radiator_pipework_uuid,
                    updated_at: new Date().getTime(),
                    created_at: new Date().getTime()
                  }
                })
              }}
              restoreRadiator={() => {
                setDesign({
                  ...design,
                  radiators: design.radiators.filter(x => x.uuid !== replacementRadiator?.uuid),
                  removed_radiator_uuids: design.removed_radiator_uuids.filter(x => x !== ra.uuid!)
                })
              }}
              isReplacement={false}
              removedRadiators={design.removed_radiator_uuids}
            />
            {replacementRadiator && <RadiatorCard
              hasReplacement={!!replacementRadiator}
              isReplacement={true}
              key={replacementRadiator.uuid}
              radiator={replacementRadiator}
              watts={replacementRadiatorWatts}
              removeRadiator={noop}
              replaceRadiator={noop}
              restoreRadiator={noop}
              editRadiator={() => {
                setAddRadiatorModal({
                  room,
                  floor,
                  roomTempC,
                  requiredOutputW,
                  originalEmitter,
                  replacementEmitter: replacementRadiator
                })
              }}
              velocity={replacementRadiatorVelocity}
              deltaTFlowReturnC={design.delta_t_flow_return_c}
              removedRadiators={design.removed_radiator_uuids}
            />}
          </>
        })
      }
    </div>
  </div>
}

type RadiatorCardProps = {
  radiator: Emitter
  watts: number
  replaceRadiator: () => void
  restoreRadiator: () => void
  editRadiator: () => void
  removeRadiator: () => void
  isReplacement: boolean
  velocity: number | undefined
  deltaTFlowReturnC: number
  removedRadiators: string[]
  hasReplacement: boolean
}
const RadiatorCard = ({ radiator, watts, replaceRadiator, removeRadiator, restoreRadiator, editRadiator, isReplacement, velocity, removedRadiators, hasReplacement }: RadiatorCardProps) => {
  const isAdded = radiator.survey_or_design === 'DESIGN' && !radiator.replaces_uuid
  const isReplaced = radiator.survey_or_design !== 'DESIGN' && hasReplacement
  const isRemoved = radiator.survey_or_design !== 'DESIGN' && removedRadiators.includes(radiator.uuid!)

  const badge = getRadiatorBadge(radiator, hasReplacement, removedRadiators, false)

  const removedOrReplaced = isRemoved || isReplaced

  return <div id={radiator.uuid} className={'text-gray-600 p-4 flex items-center justify-between'}>
    <div className='flex gap-4 items-center'>
      {isReplacement && <WrappedIcon icon={ArrowRight} />}
      <div className='flex flex-col gap-1 text-sm'>
        <div className='mb-2 self-start'>{badge}</div>
        <div className={`${removedOrReplaced ? 'line-through' : ''} text-gray-900 font-bold`}>{getEmitterTypeName(radiator)}</div>
        <div>{getEmitterSizeName(radiator)} • {watts} W { velocity ? `• ${velocity.toFixed(2)} m/s` : ''} </div>
      </div>
    </div>

    {<div className='flex gap-4 items-center'>
      {(isAdded || isReplacement) && <Link text='Edit' className='text-sm' onClick={editRadiator} />}
      {(!isReplacement && (removedOrReplaced
        ? <Button onClick={restoreRadiator} size='SM' colour='LIGHT'>Restore</Button>
        : radiator.survey_or_design === 'SURVEY' && radiator.emitter_type === 'RADIATOR' && <Button onClick={replaceRadiator} size='SM' colour='LIGHT'>Replace</Button>))}
      {!isReplacement && (!removedOrReplaced && <WrappedIcon className='cursor-pointer' onClick={removeRadiator} icon={Trash} />)}
    </div>}
  </div>
}

type UnderfloorCardProps = {
  radiator: UnderfloorHeating
  watts: number
  velocity: number
  removedRadiators: string[]
  restoreRadiator: () => void
  removeRadiator: () => void
  editRadiator: () => void
  survey: PropertySurvey
}
export const UnderfloorCard = ({ radiator, watts, velocity, removedRadiators, restoreRadiator, removeRadiator, editRadiator, survey }: UnderfloorCardProps) => {
  const badge = getRadiatorBadge(radiator, false, removedRadiators, false)
  const isReplaced = false
  const isRemoved = removedRadiators.includes(radiator.uuid!)
  const isReplacement = false
  const isAdded = radiator.survey_or_design === 'DESIGN' && !radiator.replaces_uuid
  const removedOrReplaced = isRemoved || isReplaced

  return <div id={radiator.uuid} className={'text-gray-600 p-4 flex items-center justify-between'}>
    <div className='flex gap-4 items-center'>
      <div className='flex flex-col gap-1 text-sm'>
        <div className='mb-2 self-start'>{badge}</div>
        <div className={`${removedOrReplaced ? 'line-through' : ''} text-gray-900 font-bold`}>UFH - {radiator.floor_construction_type}</div>
        <div>{survey.manifolds.find(x => x.uuid === radiator.manifold_uuid)?.name} • {radiator.pipe_centres_uuid} centres • {watts} W • {velocity.toFixed(2)} m/s</div>
      </div>
    </div>

    {<div className='flex gap-4 items-center'>
      {(isAdded || isReplacement) && <Link text='Edit' className='text-sm' onClick={editRadiator} />}
      {(!isReplacement && (removedOrReplaced
        ? <Button onClick={restoreRadiator} size='SM' colour='LIGHT'>Restore</Button>
        : <div></div>))}
      {!isReplacement && (!removedOrReplaced && <WrappedIcon className='cursor-pointer' onClick={removeRadiator} icon={Trash} />)}
    </div>}
  </div>
}

const SelectRadiatorModelDesignContext = ({ files, requiredOutput, originalEmitter, companyUUID }: { files: FileWrapper[], requiredOutput: number | undefined, originalEmitter?: Radiator, companyUUID: string }) => {
  return <div className='flex flex-col gap-5'>
    {originalEmitter && <div className='flex flex-col gap-5'>
      {originalEmitter.photos.length > 0 &&
            <PhotoScroll
              images={files.filter(x => originalEmitter?.photos.map(x => x.image_uuid).includes(x.uuid!))}
              companyUUID={companyUUID}
            />}
      {originalEmitter.notes !== '' && <div className='flex flex-col gap-1'>
        <div className='font-bold text-gray-900'>Notes:</div>
        <div className='text-sm'>{originalEmitter.notes}</div>
      </div>}
      <div className='flex flex-row justify-between'>
        <div className='font-bold text-gray-900'>Current emitter:</div>
        <div className='flex gap-2 items-center justify-between p1'>
          <div
            className='text-sm'>{originalEmitter.radiator_type?.type} - {getRadiatorHeightWidthText(originalEmitter.radiator_type!)}</div>
        </div>
      </div>
      {(originalEmitter.maximum_height > 0 || originalEmitter.maximum_width > 0) && <div className='flex flex-row justify-between'>
        <div className='font-bold text-gray-900'>Max size:</div>
        <div className='text-sm'>{originalEmitter.maximum_height} x {originalEmitter.maximum_width} mm
        </div>
      </div>}
    </div>
    }
    {requiredOutput && <div className='flex flex-row justify-between'>
      <div className='font-bold text-gray-900'>Output required:</div>
      <Badge
        color={requiredOutput > 0 ? 'INDIGO' : 'GREEN'}
        text={requiredOutput > 0 ? requiredOutput.toFixed() + ' W' : 'Demand met'}/>
    </div>}
  </div>
}

export const getRadiatorBadge = (radiator: Emitter, hasReplacement: boolean, removedRadiators: string[], isReport: boolean) => {
  const isAdded = radiator.survey_or_design === 'DESIGN' && !radiator.replaces_uuid
  const isReplacement = radiator.survey_or_design === 'DESIGN' && radiator.replaces_uuid
  const isReplaced = radiator.survey_or_design === 'SURVEY' && hasReplacement
  const isRemoved = removedRadiators.includes(radiator.uuid!)

  if (isAdded) return <Badge color='BLUE' text='Added' />
  if (isReplacement) return <Badge color='BLUE' text='Replacement' />
  if (isReplaced) return <Badge color='RED' text='Replaced' />
  if (isRemoved) return <Badge color='RED' text='Removed' />

  return <Badge color='LIGHT' text={isReport ? 'Keep' : 'Surveyed'} />
}

type FlowTempSliderProps = {
  flowTemp: number
  setFlowTemp: (flowTemp: number) => void
  minFlowTemp?: number | undefined
  maxFlowTemp?: number | undefined
}

export const FlowTempSlider = ({ flowTemp, setFlowTemp, minFlowTemp = 35, maxFlowTemp = 65 }: FlowTempSliderProps) => {
  return <div className="flex-col gap-2 flex">
    <div className="flex justify-between">
      <div className="text-gray-900 font-bold">Flow temperature</div>
      <div className="text-gray-900 font-semibold">{flowTemp} °C</div>
    </div>
    <Slider value={flowTemp} onChange={setFlowTemp} min={minFlowTemp ?? 35} max={maxFlowTemp ?? 65}/>
  </div>
}
