import React from 'react'
import { type Company } from '../../../../code/models/company'
import { BookingConfirmationOptionsBlock } from './blocks/booking_confirmation_options_block'
import { SettingsLayout } from '../components/settings_layout'

export const EmailTemplatesBookSurvey = ({ company, setCompany }: { company: Company, setCompany: (c: Company) => void }) => {
  return <SettingsLayout
    heading='Book survey'
    description='Customise the email sent to the customer when they ask to book a survey'
  >
    <BookingConfirmationOptionsBlock company={company} setCompany={setCompany} />
  </SettingsLayout>
}
