import React, { useCallback, useContext, useEffect, useState } from 'react'
import { SimpleLayout } from '../../../../layouts/simple_layout'
import { Heading } from '../../../../components/content_display/heading'
import { Button } from '../../../../components/buttons/button'
import { CalculatedQuoteDefaultGroups, QuoteOverrideType } from '../../../../code/calculate_quote'
import { groupBy, sum } from 'lodash'
import {
  type InventoryHeatPump,
  type InventoryHotWaterCylinder,
  type InventoryPart,
  type PrimaryInventoryItem
} from '../../../../code/models/inventory'
import { Section } from '../components/section'
import { InventoryTable, type InventoryTableColumn } from '../../costs_and_inventory/components/inventory_table'
import { type HydronicEmitterWithRoomAndWatts } from '../../../../code/models/pipes'
import { Input } from '../../../../components/inputs_and_selections/input'
import { formatPrice } from '../../../../code/format_price'
import { Text } from '../../../../components/content_display/text'
import { type Proposal, type ProposalQuoteItem } from '../../../../code/models/proposal'
import {
  bulkDeleteProposalQuoteItems,
  bulkInsertProposalQuoteItems,
  deleteProposalQuoteItem,
  insertProposalQuoteItem,
  updateProposalQuoteItem
} from '../../../../code/models/proposal_quote_item'
import { type CompanyPublicInfo } from '../../../../code/models/company'
import { Alert } from '../../../../components/indicators_and_messaging/alert'
import { AdminContext } from '../../admin_layout'
import { Select, type SelectOption } from '../../../../components/inputs_and_selections/select'
import { priceCalculations } from '../../../../code/price_calculations'
import { type Labour } from '../../../../code/models/labour'
import { Modal } from '../../../../components/containers/modal'
import { FormLabel } from '../../../../components/inputs_and_selections/form_label'
import { DropdownMenu } from '../../../../components/buttons/dropdown_menu'
import { TextArea } from '../../../../components/inputs_and_selections/text_area'
import { Tiptap } from '../../../../components/inputs_and_selections/tiptap'
import { validateIsNumber, validateIsPositiveNumber, validateNotNull } from '../../../../code/validators'
import { ChevronLeft, Edit, Plus, RotateCw, Trash } from 'lucide-react'

type Props = {
  proposal: Proposal
  companyPublicInfo: CompanyPublicInfo
  currentHeatPump?: InventoryHeatPump
  currentHotWaterCylinder?: InventoryHotWaterCylinder
  emitters: HydronicEmitterWithRoomAndWatts[]
  navigateTo: (path: string) => void
  setProposal: (proposal: Proposal) => void
  setAndUpdateProposal: (proposal: Proposal) => void
  calculatedQuote: ProposalQuoteItem[]
  quoteOverrideType: QuoteOverrideType
}

const DEFAULT_QUOTE_SECTION_ORDER = [
  CalculatedQuoteDefaultGroups.HEAT_PUMPS,
  CalculatedQuoteDefaultGroups.HOT_WATER_CYLINDERS,
  CalculatedQuoteDefaultGroups.PARTS,
  CalculatedQuoteDefaultGroups.RADIATORS,
  CalculatedQuoteDefaultGroups.UNDERFLOOR,
  CalculatedQuoteDefaultGroups.LABOUR,
  CalculatedQuoteDefaultGroups.SURVEY,
  CalculatedQuoteDefaultGroups.GRANTS,
  'Other deductions',
  'Optional items'
]

type AddableInventoryItem = InventoryHeatPump | InventoryHotWaterCylinder | InventoryPart | Labour
const GROUPS_TO_SHOW_DROPDOWN = [CalculatedQuoteDefaultGroups.HEAT_PUMPS, CalculatedQuoteDefaultGroups.HOT_WATER_CYLINDERS, CalculatedQuoteDefaultGroups.PARTS, CalculatedQuoteDefaultGroups.LABOUR]

export const QuoteBuilder = ({ proposal, companyPublicInfo, navigateTo, setProposal, setAndUpdateProposal, calculatedQuote, quoteOverrideType }: Props) => {
  const adminContext = useContext(AdminContext)
  const [addCustomItemModalVisible, setAddCustomItemModalVisible] = useState(false)
  const [addCustomItemModalGroup, setAddCustomItemModalGroup] = useState('')
  const [editItemModalVisible, setEditItemModalVisible] = useState(false)
  const [editingItem, setEditingItem] = useState<ProposalQuoteItem | null>(null)

  // Returns the same array of quote items, but with the 'order' keys
  // updated sequentially to reflect the new order
  const reorderQuoteItems = (quoteItems: ProposalQuoteItem[]) => {
    const groupedQuoteItems = groupBy(quoteItems, 'group_name')
    let order = 0
    return DEFAULT_QUOTE_SECTION_ORDER.flatMap(groupName => {
      const items = groupedQuoteItems[groupName]
      if (!items) return []
      return items.map(item => ({ ...item, order: order++ }))
    })
  }

  const createQuoteOverrideWithUpdatedItem = async (item: ProposalQuoteItem) => {
    const updatedPayload = reorderQuoteItems(calculatedQuote.map(x => x.uuid === item.uuid ? item : x).map(x => ({ ...x, uuid: crypto.randomUUID() })))
    setProposal({ ...proposal, quote_items: updatedPayload })
    await bulkInsertProposalQuoteItems(updatedPayload, proposal.uuid, proposal.lead_uuid, companyPublicInfo.uuid)
  }

  const createQuoteOverrideWithNewItem = async (item: ProposalQuoteItem) => {
    const updatedPayload = reorderQuoteItems([...calculatedQuote, item].map(x => ({ ...x, uuid: crypto.randomUUID() })))
    setProposal({ ...proposal, quote_items: updatedPayload })
    await bulkInsertProposalQuoteItems(updatedPayload, proposal.uuid, proposal.lead_uuid, companyPublicInfo.uuid)
  }

  const createQuoteOverrideWithoutItem = async (item: ProposalQuoteItem) => {
    const updatedPayload = reorderQuoteItems(calculatedQuote.filter(x => x.uuid !== item.uuid).map(x => ({ ...x, uuid: crypto.randomUUID() })))
    setProposal({ ...proposal, quote_items: updatedPayload })
    await bulkInsertProposalQuoteItems(updatedPayload, proposal.uuid, proposal.lead_uuid, companyPublicInfo.uuid)
  }

  const handleUpdateItem = async (item: ProposalQuoteItem) => {
    // Is the quote overridden?
    if (quoteOverrideType === QuoteOverrideType.FULL) {
      // Just update this specific item
      await updateProposalQuoteItem(item, proposal.uuid, proposal.lead_uuid, companyPublicInfo.uuid)
      return setProposal({ ...proposal, quote_items: proposal.quote_items.map(x => x.uuid === item.uuid ? item : x) })
    }
    // Otherwise, create a new quote override, which also updates the state
    await createQuoteOverrideWithUpdatedItem(item)
  }

  const handleAddItem = async (item: ProposalQuoteItem) => {
    // Is the quote overridden?
    if (quoteOverrideType === QuoteOverrideType.FULL) {
      // Create a new item
      await insertProposalQuoteItem(item, proposal.uuid, proposal.lead_uuid, companyPublicInfo.uuid)
      return setProposal({ ...proposal, quote_items: [...proposal.quote_items, item] })
    }
    // Otherwise, create a new quote override, which also updates the state
    await createQuoteOverrideWithNewItem(item)
  }

  const handleDeleteItem = async (item: ProposalQuoteItem) => {
    // Is the quote overridden?
    if (quoteOverrideType === QuoteOverrideType.FULL) {
      // Just delete this specific item
      await deleteProposalQuoteItem(item.uuid!, proposal.uuid, proposal.lead_uuid, companyPublicInfo.uuid)
      return setProposal({ ...proposal, quote_items: proposal.quote_items.filter(x => x.uuid !== item.uuid) })
    }
    // Otherwise, create a new quote override, which also updates the state
    await createQuoteOverrideWithoutItem(item)
  }

  const handleResetToDefault = async () => {
    setProposal({ ...proposal, quote_items: [] })
    await bulkDeleteProposalQuoteItems(proposal.uuid, proposal.lead_uuid, companyPublicInfo.uuid)
  }

  const handleEditItem = (item: ProposalQuoteItem) => {
    setEditingItem(item)
    setEditItemModalVisible(true)
  }

  const QUOTE_SECTION_TO_INVENTORY_ITEMS = {
    [CalculatedQuoteDefaultGroups.HEAT_PUMPS]: adminContext.data.heatPumps,
    [CalculatedQuoteDefaultGroups.HOT_WATER_CYLINDERS]: adminContext.data.hotWaterCylinders,
    [CalculatedQuoteDefaultGroups.PARTS]: adminContext.data.parts,
    [CalculatedQuoteDefaultGroups.LABOUR]: adminContext.data.labour
  }

  const handleAddAdditionalItem = async (key: string) => {
    if (!key) return
    if (key === 'radiator' || key === 'underfloor') {
      const item = {
        uuid: crypto.randomUUID(),
        proposal_uuid: proposal.uuid,
        group_name: key === 'radiator' ? CalculatedQuoteDefaultGroups.RADIATORS : CalculatedQuoteDefaultGroups.UNDERFLOOR,
        name: key === 'radiator' ? 'Radiator' : 'Underfloor heating',
        price: key === 'radiator' ? adminContext.data.company!.default_radiator_cost || 0 : adminContext.data.company!.default_underfloor_cost || 0,
        quantity: 1,
        include_vat: false,
        selected: true,
        created_at: new Date(),
        updated_at: new Date(),
        order: proposal.quote_items.length
      }
      if (proposal.quote_items.find(x => x.name === item.name)) {
        const existingItem = proposal.quote_items.find(x => x.name === item.name)!
        return await handleUpdateItem({ ...existingItem, quantity: existingItem.quantity + 1 })
      }
      return await handleAddItem(item)
    }
    // Search through all the inventory items to find the one with the matching key
    const [inventoryItemGroup, inventoryItem] = Object.entries(QUOTE_SECTION_TO_INVENTORY_ITEMS).reduce((acc, [groupName, items]) => {
      if (acc[0]) return acc
      const item = items?.find(x => x.uuid === key)
      if (item) return [groupName, item]
      return acc
    }, [undefined, undefined])

    if (inventoryItem) {
      // Does this part already exist in the quote?
      if (proposal.quote_items.find(x => x.name === inventoryItem.name)) {
        // If so, just increment the quantity
        const existingItem = proposal.quote_items.find(x => x.name === inventoryItem.name)!
        return await handleUpdateItem({ ...existingItem, quantity: existingItem.quantity + 1 })
      }
      // Otherwise, add a new item with a quantity of 1
      // If the item is a heat pump, we can also attach the image URL
      const imageUrl = inventoryItemGroup === (CalculatedQuoteDefaultGroups.HEAT_PUMPS && (inventoryItem as InventoryHeatPump).range_heat_pump_uuid)
        ? process.env.S3_BUCKET_URL + '/hp-images/' + (inventoryItem as InventoryHeatPump).range_heat_pump_uuid + '.png'
        : undefined

      await handleAddItem({
        uuid: crypto.randomUUID(),
        proposal_uuid: proposal.uuid,
        group_name: inventoryItemGroup,
        name: inventoryItem.name,
        description: inventoryItem.description || '',
        price: priceCalculations.calculateCustomerPrice(inventoryItem.cost_price, Object.prototype.hasOwnProperty.call(inventoryItem, 'markup') ? (inventoryItem as PrimaryInventoryItem).markup : 0),
        quantity: 1,
        include_vat: false,
        selected: true,
        created_at: new Date(),
        updated_at: new Date(),
        order: proposal.quote_items.length,
        image_url: imageUrl
      })
    }
  }

  const totalBeforeDeductions = sum(calculatedQuote.filter(x => x.group_name !== CalculatedQuoteDefaultGroups.GRANTS && x.group_name !== 'Other deductions' && x.selected).map(x => x.subtotal))
  const totalAfterDeductions = sum(calculatedQuote.filter(x => x.selected).map(x => x.subtotal))

  return (
    <div className='h-full min-h-0 absolute left-0 top-0 w-full bg-white'>
      <SimpleLayout stickyHeader header={<div className='h-full min-h-0 flex flex-col md:flex-row md:items-center md:justify-between gap-4'>
        <Heading size="xl">Edit quote</Heading>
        {/* Controls */}
        <div className='flex gap-2'>
          <Button colour='LIGHT' iconLeft={RotateCw} onClick={handleResetToDefault} disabled={quoteOverrideType !== QuoteOverrideType.FULL} confirmText="This will clear any changes you've made and revert back to defaults.">Reset to defaults</Button>
          <Button colour='DARK' iconLeft={ChevronLeft} onClick={() => navigateTo('/proposal')}>Return to proposal</Button>
        </div>
      </div>
      }>
        <div className='mt-6 space-y-6 px-6 pb-6'>

          {/* Quote override alert */}
          {quoteOverrideType === QuoteOverrideType.FULL && <Alert type="INFO">
            This quote has been manually overridden. It will not update automatically based on changes to the designed heat pump and hot water cylinder, or your costs and inventory. Reset to default to revert to automatic calculation.
          </Alert>}

          {/* Quote sections */}
          {DEFAULT_QUOTE_SECTION_ORDER.map(groupName => <QuoteBuilderSection
            key={groupName}
            groupName={groupName}
            calculatedQuote={calculatedQuote}
            handleEditItem={handleEditItem}
            handleUpdateItem={handleUpdateItem}
            handleDeleteItem={handleDeleteItem}
            setAddCustomItemModalVisible={setAddCustomItemModalVisible}
            setAddCustomItemModalGroup={setAddCustomItemModalGroup}
            totalBeforeDeductions={totalBeforeDeductions}
            totalAfterDeductions={totalAfterDeductions}
          />)}

          <Section title="Additional notes">
            <Tiptap
              editable={true}
              className='w-full rounded border border-gray-300 p-2 focus:outline-none'
              onUpdateCallback={async (editor) => {
                setAndUpdateProposal({
                  ...proposal,
                  additional_notes: JSON.stringify(editor.getJSON())
                })
              }}
              content={JSON.parse(proposal.additional_notes || '{}')}
            />
          </Section>
        </div>
      </SimpleLayout>
      <AddCustomItemModal
        addItem={handleAddItem}
        visible={addCustomItemModalVisible}
        setVisible={setAddCustomItemModalVisible}
        group={addCustomItemModalGroup}
        proposalUUID={proposal.uuid}
        handleAddAdditionalItem={handleAddAdditionalItem}
        addableItems={(QUOTE_SECTION_TO_INVENTORY_ITEMS[addCustomItemModalGroup] || []).map((x: AddableInventoryItem) => ({ key: x.uuid, value: `${x.name} - ${formatPrice(priceCalculations.calculateCustomerPrice(x.cost_price, Object.prototype.hasOwnProperty.call(x, 'markup') ? (x as PrimaryInventoryItem).markup : 0), 2)}` }))}
      />
      <EditQuoteItemModal
        item={editingItem}
        visible={editItemModalVisible}
        setVisible={setEditItemModalVisible}
        handleUpdateItem={handleUpdateItem}
      />
    </div>
  )
}

type SectionProps = {
  groupName: string
  calculatedQuote: ProposalQuoteItem[]
  handleEditItem: (item: ProposalQuoteItem) => void
  handleUpdateItem: (item: ProposalQuoteItem) => void
  handleDeleteItem: (item: ProposalQuoteItem) => void
  setAddCustomItemModalVisible: (visible: boolean) => void
  setAddCustomItemModalGroup: (group: string) => void
  totalBeforeDeductions: number
  totalAfterDeductions: number
}

const QuoteBuilderSection = ({ groupName, calculatedQuote, handleEditItem, handleUpdateItem, handleDeleteItem, setAddCustomItemModalVisible, setAddCustomItemModalGroup, totalAfterDeductions, totalBeforeDeductions }: SectionProps) => {
  const postfixForGroupName = useCallback((groupName: string) => {
    if (groupName === CalculatedQuoteDefaultGroups.UNDERFLOOR) {
      return 'm²'
    }
    if (groupName === CalculatedQuoteDefaultGroups.LABOUR) {
      return 'days'
    }
    return ''
  }, [])

  const groupedCalculatedQuote = groupBy(calculatedQuote, 'group_name')

  const quoteItemColumns: Array<InventoryTableColumn<{ description: React.ReactNode, price: React.ReactNode, quantity: React.ReactNode, total: React.ReactNode, menu: React.ReactNode }>> = [
    { key: 'description', name: 'Description' },
    { key: 'price', name: 'Price' },
    { key: 'quantity', name: 'Quantity' },
    { key: 'total', name: 'Total' },
    { key: 'menu', name: '', align: 'right' }
  ]

  const handleClickAddCustomItem = () => {
    setAddCustomItemModalGroup(groupName)
    setAddCustomItemModalVisible(true)
  }

  return (
    <>
      <Section
        title={groupName}
        key={groupName}
      >
        <InventoryTable
          columns={quoteItemColumns}
          rows={(groupedCalculatedQuote[groupName] || []).map(x => ({
            description: <>
              <Text size='SM' bold>{x.name}</Text>
              {x.description && <Text size='XS' className='text-gray-500'>{x.description}</Text>}
            </>,
            price: <Input type='number' value={x.price} prefix="£" setValue={(value) => handleUpdateItem({ ...x, price: parseFloat(value) })} />,
            quantity: <Input type='number' value={x.quantity} postfix={postfixForGroupName(groupName)} setValue={(value) => handleUpdateItem({ ...x, quantity: parseInt(value) })} />,
            total: <Text size="SM">{formatPrice(x.subtotal, 2)}</Text>,
            menu: <DropdownMenu
              items={[
                { label: 'Edit', onClick: () => handleEditItem(x), icon: Edit },
                { label: 'Delete', onClick: () => handleDeleteItem(x), icon: Trash, confirmText: 'Are you sure you want to delete this quote item?' }
              ]} />
          }))} />

        {/* Add new item form */}
        <div className="flex flex-col md:flex-row md:items-center md:justify-between gap-4">
          <Button iconLeft={Plus} colour='LIGHT' onClick={handleClickAddCustomItem}>Add item</Button>
          {/* Total */}
          <div className="flex items-center md:ml-auto">
            <Text size="SM" bold>Subtotal:</Text>
            <Text size="SM" bold className="ml-2">{formatPrice(sum((groupedCalculatedQuote[groupName] || []).map(x => x.subtotal)), 2)}</Text>
          </div>
        </div>
      </Section>
      {groupName === CalculatedQuoteDefaultGroups.SURVEY && <Section><div className='md:text-right'><Text size="SM" bold>Total before deductions: {formatPrice(totalBeforeDeductions, 2)}</Text></div></Section>}
      {groupName === 'Other deductions' && <Section><div className='md:text-right'><Text size="SM" bold>Total after deductions: {formatPrice(totalAfterDeductions, 2)}</Text></div></Section>}
    </>
  )
}

type AddCustomItemModalProps = {
  addItem: (item: ProposalQuoteItem) => void
  visible: boolean
  setVisible: (visible: boolean) => void
  group: string
  proposalUUID: string
  handleAddAdditionalItem: (key: string) => void
  addableItems: SelectOption[]
}

const AddCustomItemModal = ({ addItem, visible, setVisible, group, proposalUUID, handleAddAdditionalItem, addableItems }: AddCustomItemModalProps) => {
  const [item, setItem] = useState({
    name: '',
    description: '',
    price: 0,
    quantity: 1
  })
  const [selectedItemId, setSelectedItemId] = React.useState<string | undefined>(undefined)

  const handleSelectItem = (key: string) => {
    handleAddAdditionalItem(key)
    setVisible(false)
    setSelectedItemId(undefined)
  }

  const handleAddItem = () => {
    addItem({
      uuid: crypto.randomUUID(),
      proposal_uuid: proposalUUID,
      group_name: group,
      name: item.name,
      description: item.description,
      price: item.price,
      quantity: item.quantity,
      include_vat: false,
      selected: group !== 'Optional items',
      created_at: new Date(),
      updated_at: new Date(),
      order: 0
    })
    setItem({ name: '', description: '', price: 0, quantity: 1 })
  }

  const allFieldsValid = [validateNotNull(item.name), validateIsNumber(item.price), validateIsPositiveNumber(item.quantity)].every(x => x.value !== undefined) // Allow 0 in price

  return (
    <Modal
      visible={visible}
      setVisible={setVisible}
      title={`Add item to ${group}`}
      onConfirm={handleAddItem}
      confirmDisabled={!allFieldsValid}
    >
      <div className="w-full flex flex-col gap-2">

        {GROUPS_TO_SHOW_DROPDOWN.includes(group as CalculatedQuoteDefaultGroups) && <div className="w-full flex flex-col gap-1">
          <FormLabel labelText='Select an item from your inventory' />
          <Select
            placeholder='Type to search...'
            setSelectedKey={handleSelectItem}
            selectedKey={selectedItemId}
            options={addableItems}
            filter={true}
          />
        </div>}

        {GROUPS_TO_SHOW_DROPDOWN.includes(group as CalculatedQuoteDefaultGroups) && <>
          <div className="mt-3 mb-2 border-b border-gray-200" />
          <Text bold>Or add a custom item</Text>
        </>}

        <Input
          label='Name'
          placeholder='Enter item name'
          value={item.name}
          setValue={(value) => setItem(prev => ({ ...prev, name: value }))}
          validator={validateNotNull}
        />
        <TextArea
          label='Description'
          placeholder='Enter item description'
          value={item.description || ''}
          setValue={(value) => setItem(prev => ({ ...prev, description: value }))}
        />
        <Input type="number"
          label='Price'
          placeholder='Enter price'
          value={item.price}
          setValue={(value) => setItem(prev => ({ ...prev, price: parseFloat(value) }))}
          prefix='£'
          validator={validateIsNumber}
        />
        <Input type="number"
          label='Quantity'
          placeholder='Enter quantity'
          value={item.quantity}
          setValue={(value) => setItem(prev => ({ ...prev, quantity: parseInt(value) }))}
          validator={validateIsPositiveNumber}
        />
      </div>
    </Modal>
  )
}

type EditQuoteItemModalProps = {
  item: ProposalQuoteItem | null
  visible: boolean
  setVisible: (visible: boolean) => void
  handleUpdateItem: (item: ProposalQuoteItem) => void
}

const EditQuoteItemModal = ({ item, visible, setVisible, handleUpdateItem }: EditQuoteItemModalProps) => {
  const [editedItem, setEditedItem] = useState<ProposalQuoteItem | null>(null)

  useEffect(() => {
    setEditedItem(item)
  }, [item])

  const handleConfirm = () => {
    if (editedItem) {
      handleUpdateItem(editedItem)
      setVisible(false)
    }
  }

  if (!editedItem) return null

  const allFieldsValid = [validateNotNull(editedItem.name), validateIsNumber(editedItem.price), validateIsPositiveNumber(editedItem.quantity)].every(x => x.value !== undefined) // Allow 0 in price

  return (
    <Modal
      visible={visible}
      setVisible={setVisible}
      title='Edit quote item'
      onConfirm={handleConfirm}
      confirmDisabled={!allFieldsValid}
    >
      <div className="space-y-4 w-full">
        <Input label='Name'
          placeholder='Enter item name'
          value={editedItem.name}
          setValue={(value) => setEditedItem(prev => prev ? { ...prev, name: value } : null)}
          validator={validateNotNull}
        />
        <TextArea label='Description'
          placeholder='Enter item description'
          value={editedItem.description || ''}
          setValue={(value) => setEditedItem(prev => prev ? { ...prev, description: value } : null)}
        />
        <Input type="number"
          label='Price'
          placeholder='Enter price'
          value={editedItem.price}
          setValue={(value) => setEditedItem(prev => prev ? { ...prev, price: parseFloat(value) } : null)}
          prefix='£'
          validator={validateIsNumber}
        />
        <Input type="number"
          label='Quantity'
          placeholder='Enter quantity'
          value={editedItem.quantity}
          setValue={(value) => setEditedItem(prev => prev ? { ...prev, quantity: parseInt(value) } : null)}
          validator={validateIsPositiveNumber}
        />
      </div>
    </Modal>
  )
}
