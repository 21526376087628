import { type Company, updateCompanySettings } from '../../../../../code/models/company'
import { Section } from '../../../../../components/containers/section'
import React from 'react'
import { SortableList } from '../../../components/sortable_list'
import { Button } from '../../../../../components/buttons/button'
import { Info } from '../../../../../components/buttons/info'
import { RefreshCw } from 'lucide-react'

const SourcesTooltip = () => {
  return <div className='flex flex-col gap-3'>
    <p>Drag and drop using the handle on the left of each option to reorder them.</p>
    <p>Use the icons on the right to edit and remove options.</p>
    <p>Customers will also have the option to select "Other" and provide their own response.</p>
  </div>
}

export const SourcesBlock = ({ company, setCompany }: { company: Company, setCompany: (c: Company) => void }) => {
  const defaultOptions = ['Social media', 'Search engine', 'Word of mouth', 'Trade show']

  const resetToDefaults = async () => {
    const newCompany = {
      ...company,
      public_info: {
        ...company.public_info,
        sources: defaultOptions
      }
    }
    setCompany(newCompany)
    await updateCompanySettings(newCompany)
  }
  return <Section border={true} title="Lead source options">
    <div className='flex flex-col gap-6'>
      <p>Choose which options customers can choose from when asked "Where did you hear about us?"<span className='ml-2'><Info infoModalHeader='Lead source configuration' infoModalBody={<SourcesTooltip />}></Info></span></p>
      <SortableList items={company.public_info.sources.map((name, id) => ({ name, id }))} setItems={async (items) => {
        // If the last item is deleted, fill the list with default options
        if (items.length === 0) {
          resetToDefaults()
          return
        }

        const newCompany = {
          ...company,
          public_info: {
            ...company.public_info,
            sources: items.map((item) => item.name)
          }
        }
        setCompany(newCompany)
        await updateCompanySettings(newCompany)
      }}
      extraActionButtons={[
        <Button key='resetToDefault' iconLeft={RefreshCw} colour="LIGHT" onClick={async () => await resetToDefaults() } >
          Reset to defaults
        </Button>
      ]}
      />

    </div>
  </Section>
}
