import { type Company, getCompanyEstimateCoverNote, updateCompanySettings } from '../../../../../code/models/company'
import React from 'react'
import { Section } from '../../../../../components/containers/section'
import { Tiptap } from '../../../../../components/inputs_and_selections/tiptap'
import {
  TTPlaceholderCompanyName,
  TTPlaceholderCompanyPhone,
  TTPlaceholderEstimateContactName,
  TTPlaceholderEstimateContactPhone,
  TTPlaceholderInstallerName
} from '../../../../../code/tiptap_placeholders'
import { FormLabel } from '../../../../../components/inputs_and_selections/form_label'
import { VerticalFormGroup } from '../../../../../components/inputs_and_selections/vertical_form_group'
import { Input } from '../../../../../components/inputs_and_selections/input'
import { SettingsImageInput } from '../../components/settings_image_input'

export const EstimateCoverNoteTemplateBlock = ({ company, setCompany }: {
  company: Company
  setCompany: (c: Company) => void
}) => {
  return <Section border title={'Cover note'}>
    <div className='flex flex-col gap-4  '>
      <div className='flex flex-col gap-4'>
        <VerticalFormGroup
          formLabel={ <FormLabel labelText='Message' />}
          input={
            <Tiptap
              editable={true}
              className='w-full rounded border border-gray-300 p-2 focus:outline-none'
              onUpdateCallback={async (editor) => {
                setCompany({
                  ...company,
                  public_info: {
                    ...company.public_info,
                    calculated_estimate_cover_note: JSON.stringify(editor.getJSON())
                  }
                })
              }}
              placeholders={[
                TTPlaceholderInstallerName,
                TTPlaceholderCompanyName,
                TTPlaceholderCompanyPhone,
                TTPlaceholderEstimateContactName,
                TTPlaceholderEstimateContactPhone
              ]}
              content={getCompanyEstimateCoverNote(company.public_info.calculated_estimate_cover_note || '')}
            />
          }
        />

        <VerticalFormGroup
          formLabel={<FormLabel labelText='Sender' />}
          input={<Input value={company.public_info.estimate_contact_name ?? ''}
            setValue={senderInput => setCompany({
              ...company,
              public_info: {
                ...company.public_info,
                estimate_contact_name: senderInput
              }
            })}
            placeholder='Estimate contact name'
          />}
        />
        <VerticalFormGroup
          formLabel={<FormLabel labelText='Role' />}
          input={<Input value={company.public_info.estimate_contact_role ?? ''}
            setValue={roleInput => setCompany({
              ...company,
              public_info: {
                ...company.public_info,
                estimate_contact_role: roleInput
              }
            })}
            placeholder='Estimate contact role'
          />}
        />
        <VerticalFormGroup
          formLabel={<FormLabel labelText='Phone' />}
          input={<Input value={company.public_info.estimate_contact_phone ?? ''}
            setValue={senderInput => setCompany({
              ...company,
              public_info: {
                ...company.public_info,
                estimate_contact_phone: senderInput
              }
            })}
            placeholder='Estimate contact phone'
          />}
        />

      </div>
      <VerticalFormGroup
        formLabel={<FormLabel labelText='Headshot' helperText='For best results, image should be square' />}
        input={<SettingsImageInput
          imgClassName='rounded-full aspect-square object-cover'
          buttonText='Upload headshot'
          placeholderText='No headshot'
          helperText='Maximum file size 5MB'
          b64Image={company.public_info.estimate_contact_portrait ?? ''}
          altText='Estimate contact headshot'
          handleUpdateImage={async (imageSrc) => {
            const newCompany = {
              ...company,
              public_info: {
                ...company.public_info,
                estimate_contact_portrait: imageSrc
              }
            }
            await updateCompanySettings(newCompany)
            setCompany(newCompany)
          }}
          handleRemoveImage={async () => {
            const newCompany = {
              ...company,
              public_info: {
                ...company.public_info,
                estimate_contact_portrait: ''
              }
            }
            await updateCompanySettings(newCompany)
            setCompany(newCompany)
          }}
        />}
      />
    </div>
  </Section>
}
