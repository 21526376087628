import { type z } from 'zod'
import { captureMessage } from '@sentry/react'

export const parseWithZod = <T>(
  schema: z.ZodSchema<T>,
  payload: unknown
): T => {
  // Transform the payload to replace null with undefined
  const transformedPayload = JSON.parse(
    JSON.stringify(payload, (key, value) => (value === null ? undefined : value))
  )
  const result = schema.safeParse(transformedPayload)

  if (!result.success) {
    // Log the error to Sentry
    console.error('Zod schema validation failed', result.error, payload)
    captureMessage('Zod schema validation failed', {
      level: 'warning',
      extra: {
        errors: result.error,
        payload
      }
    })

    // Return the original payload
    return payload as T
  }

  // If parsing was successful, return the parsed data
  return result.data
}
