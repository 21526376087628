import React from 'react'
import { Icon } from './icon'
import { HelpCircle } from 'lucide-react'

type InfoProps = {
  infoModalHeader: string
  infoModalBody?: string | React.ReactNode
  colour?: string
  className?: string
}

export const Info = ({ infoModalHeader, infoModalBody, colour = 'text-gray-400', className }: InfoProps) => {
  return <Icon icon={HelpCircle}
    colour={colour}
    size='w-4 h-4'
    className={`cursor-pointer ${className}`}
    confirmTextHeader={infoModalHeader}
    confirmTextBody={infoModalBody} />
}
