import React, { useState } from 'react'
import { Heading } from '../../../../../components/content_display/heading'
import { Section } from '../../../job_layout/components/section'
import { Button } from '../../../../../components/buttons/button'
import { type Company } from '../../../../../code/models/company'
import { DropdownMenu } from '../../../../../components/buttons/dropdown_menu'
import { InventoryTable, type InventoryTableColumn } from '../inventory_table'
import { Modal } from '../../../../../components/containers/modal'
import { type InventoryPart } from '../../../../../code/models/inventory'
import { Input } from '../../../../../components/inputs_and_selections/input'
import { Text } from '../../../../../components/content_display/text'
import { Toggle } from '../../../../../components/inputs_and_selections/toggle'
import { priceCalculations } from '../../../../../code/price_calculations'
import { EmptySection } from '../empty_section'
import { TextArea } from '../../../../../components/inputs_and_selections/text_area'
import { FormLabel } from '../../../../../components/inputs_and_selections/form_label'
import { useDebounceCallback } from 'usehooks-ts'
import { Edit, Plus, Trash } from 'lucide-react'

type Props = {
  company: Company
  setCompany: (company: Company) => void
  addOrUpdatePart: (part: InventoryPart) => void
  deletePart: (uuid: string) => void
  parts: InventoryPart[]
}

type AddPartModalProps = {
  visible: boolean
  setVisible: (visible: boolean) => void
  addPart: (part: InventoryPart) => void
}

const AddPartModal = ({ addPart, visible, setVisible }: AddPartModalProps) => {
  const [part, setPart] = useState({
    name: '',
    description: '',
    cost_price: 0,
    markup: 0
  })

  const updatePart = (field: keyof typeof part, value: string | number) => {
    setPart(prev => ({ ...prev, [field]: value }))
  }

  return (
    <Modal
      visible={visible}
      setVisible={setVisible}
      title='Add part'
      onConfirm={() => {
        addPart({
          ...part,
          uuid: crypto.randomUUID(),
          default_include: false
        })
        setPart({ name: '', description: '', cost_price: 0, markup: 0 })
      }}
    >
      <div className="space-y-4 w-full">
        <Input label='Name' placeholder='Enter part name' value={part.name} setValue={(value) => updatePart('name', value)} />
        <div className="flex flex-col gap-1">
          <FormLabel labelText='Description' helperText='Part descriptions are displayed on the final quote, but not the estimate.' />
          <TextArea placeholder='Enter part description' value={part.description} setValue={(value) => updatePart('description', value)} />
        </div>
        <Input
          type="number"
          label='Cost price'
          placeholder='Enter cost price'
          value={part.cost_price}
          setValue={(value) => updatePart('cost_price', parseFloat(value))}
          prefix='£'
        />
        <Input
          type="number"
          label='Markup'
          placeholder='Enter markup'
          value={part.markup}
          setValue={(value) => updatePart('markup', parseFloat(value))}
          postfix='%'
        />
        <Input
          type="number"
          label='Customer price'
          placeholder='Enter customer price'
          value={priceCalculations.calculateCustomerPrice(part.cost_price, part.markup)}
          setValue={(value) => {
            updatePart('markup', 0)
            updatePart('cost_price', parseFloat(value))
          }}
          prefix='£'
        />
      </div>
    </Modal>
  )
}

const EditPartModal = ({ part, visible, setVisible, updatePart }: { part: InventoryPart, visible: boolean, setVisible: (visible: boolean) => void, updatePart: (part: InventoryPart) => void }) => {
  const [editingName, setEditingName] = useState(part.name)
  const [editingDescription, setEditingDescription] = useState(part.description)

  const debounceUpdatePart = useDebounceCallback(updatePart, 500)

  const handleUpdateDescription = (value: string) => {
    setEditingDescription(value)
    debounceUpdatePart({ ...part, description: value })
  }

  const handleUpdateName = (value: string) => {
    setEditingName(value)
    debounceUpdatePart({ ...part, name: value })
  }

  return (
    <Modal visible={visible} setVisible={setVisible} title='Edit part'>
      <div className="space-y-4 w-full">
        <Input label='Name' placeholder='Enter part name' value={editingName} setValue={handleUpdateName} />
        <div className="flex flex-col gap-1">
          <FormLabel labelText='Description' helperText='Part descriptions are displayed on the final quote, but not the estimate.' />
          <TextArea placeholder='Enter part description' value={editingDescription || ''} setValue={handleUpdateDescription} />
        </div>
      </div>
    </Modal>
  )
}

export const PartsInventory = ({ addOrUpdatePart, deletePart, parts }: Props) => {
  const [addPartModalVisible, setAddPartModalVisible] = useState(false)
  const [editPartModalPart, setEditPartModalPart] = useState<InventoryPart | undefined>(undefined)

  const partsColumns: Array<InventoryTableColumn<{ name: React.ReactNode, alwaysInclude: React.ReactNode, costPrice: React.ReactNode, markup: React.ReactNode, customerPrice: React.ReactNode, menu: React.ReactNode }>> = [
    { key: 'name', name: 'Name' },
    { key: 'alwaysInclude', name: 'Always include', infoText: 'If toggled, the part will always be added to new estimates and quotes.' },
    { key: 'costPrice', name: 'Cost price' },
    { key: 'markup', name: 'Markup' },
    { key: 'customerPrice', name: 'Customer price' },
    { key: 'menu', name: '', align: 'right' }
  ]

  return (
    <>
      <div className="space-y-6">
        <div className="flex flex-col gap-3 justify-start items-start sm:flex-row sm:justify-between sm:items-center w-full">
          <Heading size="2xl">Parts & materials</Heading>
          <Button colour='DARK' onClick={() => setAddPartModalVisible(true)} iconLeft={Plus}>Add part</Button>
        </div>
        {!parts.length
          ? <EmptySection
              title='No parts added'
              description='Add parts to your inventory to quickly add them to estimates and quotes.'
              button={<Button onClick={() => setAddPartModalVisible(true)} iconLeft={Plus}>Add part</Button>}
            />
          : <Section>
              <InventoryTable
                rows={parts
                  .sort((a, b) => a.name.localeCompare(b.name))
                  .map((x) => ({
                    name: <>
                      <Text size="SM">{x.name}</Text>
                      <Text size="XS" className='text-gray-500'>{x.description}</Text>
                    </>,
                    alwaysInclude: <Toggle value={x.default_include} setValue={() => addOrUpdatePart({ ...x, default_include: !x.default_include })} />,
                    costPrice: <Input type='number' value={x.cost_price} prefix="£" setValue={(value) => {
                      addOrUpdatePart(priceCalculations.updateCostPrice(x, parseFloat(value)))
                    }} />,
                    markup: <Input type='number' step={1} value={x.markup} setValue={(value) => {
                      addOrUpdatePart(priceCalculations.updateMarkup(x, parseFloat(value)))
                    }} postfix='%' />,
                    customerPrice: <Input type='number' value={priceCalculations.calculateCustomerPrice(x.cost_price, x.markup)} setValue={(value) => {
                      addOrUpdatePart(priceCalculations.updateCostPriceAndMarkupToMatchCustomerPrice(x, parseFloat(value)))
                    }} prefix='£' />,
                    menu: <DropdownMenu
                      items={[
                        { label: 'Edit', onClick: () => setEditPartModalPart(x), icon: Edit },
                        { label: 'Delete', onClick: () => deletePart(x.uuid), icon: Trash, confirmText: 'Are you sure you want to delete this part?' }
                      ]} />
                  }))
                }
                columns={partsColumns}
              />

            </Section>
        }
      </div>
      <AddPartModal addPart={addOrUpdatePart} visible={addPartModalVisible} setVisible={setAddPartModalVisible} />
      {editPartModalPart && <EditPartModal part={editPartModalPart} visible={!!editPartModalPart} setVisible={(visible) => {
        if (!visible) {
          setEditPartModalPart(undefined)
        }
      }
      } updatePart={addOrUpdatePart} />}
    </>
  )
}
