import React from 'react'
import { SurveyCylinderLocationContext } from '../cylinder_location'
import { Input } from '../../../../components/inputs_and_selections/input'
import { TextArea } from '../../../../components/inputs_and_selections/text_area'

export const CylinderLocationPipeRunsBlock = () => {
  const cylinderLocationContext = React.useContext(SurveyCylinderLocationContext)
  const { survey, setSurvey } = cylinderLocationContext!

  return <>
    <div className="text-gray-900 text-xl font-bold">Pipe runs</div>
    <div className="flex-col gap-2 flex">
      <div className="flex-col flex">
        <div className="text-gray-900 font-bold">Distance</div>
        <div className="text-gray-500 text-sm">Rough distance from the cylinder location to the heat pump location.
        </div>
      </div>
      <Input
        type='number'
        value={survey.pipe_run_distance_metre?.toString()}
        postfix='m'
        setValue={(e) => setSurvey({ ...survey, pipe_run_distance_metre: Number(e) })}
      />
    </div>
    <div className="flex-col gap-2 flex">
      <div className="flex-col flex">
        <div className="text-gray-900 font-bold">Pipe run description</div>
        <div className="text-gray-500 text-sm">Where the pipe runs should go from the cylinder location to the heat pump
          location.
        </div>
      </div>
      <TextArea value={survey.pipe_run_description} setValue={(e) => setSurvey({ ...survey, pipe_run_description: e })}/>
    </div>
  </>
}
