import React from 'react'
import { PageHeader } from '../design/components/design_page_header'
import { CompleteButton } from './complete_button'
import { FloorplanHeatLossSettingsBlock } from './floorplan/floorplan_heatloss_settings_block'
import type { PropertySurvey } from '../../../code/models/property'
import { type SetIndexedDb } from '../../admin/job_layout/job_layout'

type SurveySettingsProps = {
  survey: PropertySurvey
  setSurvey: SetIndexedDb<PropertySurvey>
  designTempDefault: number
  altitudeDefaultM: number
  isOffline: boolean
  allSynced: boolean
}

export const SurveySettings = ({ survey, setSurvey, designTempDefault, altitudeDefaultM, isOffline, allSynced }: SurveySettingsProps) => {
  const page = 'SETTINGS'
  return <div className='flex flex-col h-full min-h-0'>
    <PageHeader isOffline={isOffline} allSynced={allSynced} title='Settings' onBack={() => window.history.back()} completed={survey.completed_sections.includes(page)} />
    <div className="p-5 flex flex-col gap-6 overflow-y-auto flex-grow">
      <FloorplanHeatLossSettingsBlock survey={survey} setSurvey={setSurvey} designTempDefault={designTempDefault} altitudeDefaultM={altitudeDefaultM} />
    </div>
    <CompleteButton page={page} survey={survey} setSurvey={setSurvey} pageChecks={[]} onBack={async () => window.history.back()} />
  </div>
}
