import React from 'react'

type Props = {
  title: string
  description?: string
  descriptionNode?: React.ReactNode
}

export const HLRHeaderBlock = (props: Props) => {
  return (<>
    <div className="flex-col justify-start items-start gap-5 flex p-9 bg-gray-100">
      <div className="text-gray-900 text-4xl font-bold">{props.title}</div>
      { props.description &&
        <div className="text-lg">{props.description}</div>
      }
      { props.descriptionNode && props.descriptionNode}
    </div>
  </>
  )
}
