import React, { type Dispatch, type SetStateAction, useState } from 'react'
import { getRadiatorHeightWidthText, type Radiator } from '../../code/models/radiator'
import { type FileWrapper } from './file_wrapper'
import { Input } from '../../components/inputs_and_selections/input'
import { PhotoScroll } from '../../components/inputs_and_selections/photo_scroll'
import { ClickableCard } from '../../components/content_display/card'
import { Badge } from '../../components/indicators_and_messaging/badge'
import { RadioGroup } from '../../components/inputs_and_selections/radio'
import { TextArea } from '../../components/inputs_and_selections/text_area'
import { SelectRadiatorModelInner } from './design/pages/radiator_model_selector'
import { getPipeModelName, PIPE_MODELS } from '../../code/models/pipes'
import { type CurrentFloorPlanPage } from './floor/floor'
import { type RadiatorModel } from '../../code/models/radiator_model'
import { Select } from '../../components/inputs_and_selections/select'

type RadiatorPageProps = {
  radiator: Radiator
  setRadiator: Dispatch<SetStateAction<Radiator | undefined>>
  customRadiatorModels: RadiatorModel[]
  setCustomRadiatorModels: Dispatch<SetStateAction<RadiatorModel[]>>
  files: FileWrapper[]
  setFiles: Dispatch<SetStateAction<FileWrapper[]>>
  deltaTFlowReturnC: number
  roomTemp: number
  flowTemp: number
  setPage: (page: CurrentFloorPlanPage) => void
  page: CurrentFloorPlanPage
  companyUuid: string
  onBack: () => void
}

export const RadiatorPage = ({ radiator, setRadiator, customRadiatorModels, setCustomRadiatorModels, files, setFiles, deltaTFlowReturnC, roomTemp, flowTemp, setPage, page, companyUuid, onBack }: RadiatorPageProps) => {
  const images = radiator.photos.map(x => files.find(y => y.uuid === x.image_uuid)!)
  const currentPipe = PIPE_MODELS.find(x => x.uuid === radiator.pipe_model_uuid)
  const [currentPipeMaterial, setCurrentPipeMaterial] = useState<string>(currentPipe?.material ?? 'Copper')

  const deleteImage = (imageUUID: string) => {
    setRadiator(prev => ({ ...prev!, photos: prev!.photos.filter(x => x.image_uuid !== imageUUID) }))
  }

  if (page === 'RADIATOR_MODEL' || page === 'ADD_CUSTOM_RADIATOR') {
    return <SelectRadiatorModelInner
      customRadiatorModels={customRadiatorModels}
      setCustomRadiatorModels={setCustomRadiatorModels}
      originallySelectedModelUuid={radiator.radiator_type?.uuid}
      deltaTFlowReturnC={deltaTFlowReturnC}
      roomTemp={roomTemp}
      flowTemp={flowTemp}
      companyUuid={companyUuid}
      addRadiator={(rm) => {
        onBack()
        setRadiator(prev => ({ ...prev!, radiator_type_uuid: rm.uuid!, radiator_type: rm, updated_at: new Date().getTime() }))
      }}
      setPage={setPage}
      page={page}
      onBack={onBack}
    />
  }

  return <div className='flex flex-col'>
    <div className="p-5 bg-white flex-col gap-5 flex overflow-y-auto flex-grow">
      <div className='space-y-2'>
        <div className='text-gray-900 font-bold text-sm'>Radiator type</div>
        <ClickableCard size='SM' variant='WHITE' onClick={() => setPage('RADIATOR_MODEL')}>
          <div className='flex justify-between text-sm items-center'>
            <div>{radiator.radiator_type!.type}</div>
            <Badge color='INDIGO' text={getRadiatorHeightWidthText(radiator.radiator_type as any)}/>
          </div>
        </ClickableCard>
      </div>

      <div className='space-y-2'>
        <div className='text-gray-900 font-bold text-sm'>Maximum possible size</div>
        <div className='flex gap-2 text-sm text-gray-600'>
          <div className='flex-1 flex flex-col gap-1'>
            <div>Height</div>
            <Input type='number'
              value={radiator.maximum_height.toString()}
              setValue={(e) => setRadiator(prev => ({ ...prev!, maximum_height: parseFloat(e) }))}
              postfix={<span>mm</span>}/>
          </div>
          <div className='flex-1 flex flex-col gap-1'>
            <div>Width</div>
            <Input type='number'
              value={radiator.maximum_width.toString()}
              setValue={(e) => setRadiator(prev => ({ ...prev!, maximum_width: parseFloat(e) }))}
              postfix={<span>mm</span>}/>
          </div>
        </div>
      </div>

      <div className='space-y-2'>
        <div className='text-gray-900 font-bold text-sm'>Pipe material</div>
        <RadioGroup items={[
          {
            name: 'Copper',
            onClick: () => setCurrentPipeMaterial('Copper'),
            variant: currentPipeMaterial === 'Copper' ? 'ACTIVE' : 'DEFAULT'
          },
          {
            name: 'PEX',
            onClick: () => setCurrentPipeMaterial('PEX'),
            variant: currentPipeMaterial === 'PEX' ? 'ACTIVE' : 'DEFAULT'
          },
          {
            name: 'MLCP',
            onClick: () => setCurrentPipeMaterial('MLCP'),
            variant: currentPipeMaterial === 'MLCP' ? 'ACTIVE' : 'DEFAULT'
          }
        ]}/>
      </div>

      <div className='space-y-2'>
        <div className='text-gray-900 font-bold text-sm'>Pipe diameter</div>
        <Select options={PIPE_MODELS.filter(x => x.material === currentPipeMaterial).map(x => ({
          key: x.uuid,
          value: getPipeModelName(x)
        }))} selectedKey={radiator.pipe_model_uuid}
        setSelectedKey={(e) => setRadiator(prev => ({ ...prev!, pipe_model_uuid: e }))}/>
      </div>

      <div className='space-y-2'>
        <div className='text-gray-900 font-bold text-sm'>Photos</div>
        <PhotoScroll images={images} addImage={(fileWrapper) => {
          fileWrapper && setFiles(prev => [...prev, { ...fileWrapper, created_at: new Date().getTime(), updated_at: new Date().getTime(), is_modified: true }])
          fileWrapper && setRadiator(prev => ({
            ...prev!,
            photos: [...prev!.photos, {
              uuid: crypto.randomUUID(),
              entity_uuid: prev!.uuid!,
              image_uuid: fileWrapper.uuid!
            }]
          }))
        }} deleteImage={deleteImage} companyUUID={companyUuid} />
      </div>

      <div className='flex flex-col gap-2 flex-grow'>
        <div className='text-gray-900 font-bold text-sm'>Notes</div>
        <TextArea
          value={radiator.notes.toString()}
          setValue={(e) => setRadiator(prev => ({ ...prev!, notes: e }))}
          size={'SM'}
        />
      </div>
    </div>
  </div>
}
