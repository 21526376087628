import React from 'react'
import { type Room } from '../../../code/models/room'
import { CanvasNav } from './canvas_nav'
import { type CurrentFloorPlanPage } from './floor'
import { FileText, Box, Camera, Flame } from 'lucide-react'

type RoomButtonsProps = {
  currentRoom: Room
  setPage: (page: CurrentFloorPlanPage, open: boolean) => void }

export const RoomButtons = ({ currentRoom, setPage }: RoomButtonsProps) => {
  return <CanvasNav title={currentRoom.name} items={[
    { name: 'Details', onClick: () => setPage('ROOM_DETAILS', true), icon: FileText, variant: 'DEFAULT' },
    { name: 'Floor', onClick: () => setPage('ROOM_FLOOR', true), icon: Box, variant: 'DEFAULT' },
    { name: 'Ceiling', onClick: () => setPage('ROOM_CEILING', true), icon: Box, variant: 'DEFAULT' },
    { name: `${currentRoom.images.length > 0 ? currentRoom.images.length : ''} Photo${currentRoom.images.length > 1 || currentRoom.images.length === 0 ? 's' : ''}`, onClick: () => setPage('ROOM_PHOTOS', true), icon: Camera, variant: currentRoom.images.length > 0 ? 'BLUE' : 'DEFAULT' },
    { name: `${currentRoom.radiators.length > 0 ? currentRoom.radiators.length : ''} Emitter${currentRoom.radiators.length > 1 || currentRoom.radiators.length === 0 ? 's' : ''}`, onClick: () => setPage('ROOM_EMITTERS', true), icon: Flame, variant: currentRoom.radiators.length > 0 ? 'BLUE' : 'DEFAULT' }
  ]} />
}
