import React, { useEffect, useState } from 'react'
import { Button } from '../buttons/button'
import { Input } from '../inputs_and_selections/input'
import { WrappedIcon } from '../buttons/wrapped_icon'
import { Check, Edit, Trash, X } from 'lucide-react'

export type TableProps<T> = {
  columns: Array<TableColumn<T>>
  rows: T[]
  editable?: boolean
  addNew?: boolean
  onDeleteRow?: (uuid: string | number) => void
  onSaveRow?: (row: T) => void
  editableColumnWidth?: number
}

export type TableColumn<T> = {
  name: string
  key?: keyof T
  validator?: (row: T) => boolean
  render?: (row: T) => JSX.Element
  editRender?: (row: T, onChange: (value: string) => void) => JSX.Element
}

export const Table = <T extends { uuid?: string | number }>({ columns, rows, editable, addNew, onDeleteRow, onSaveRow, editableColumnWidth }: TableProps<T>) => {
  const newRowEmpty = Object.fromEntries(columns.map(x => ([x.key, '']))) as T
  const [editingRow, setEditingRow] = useState<T>()
  const [newRow, setNewRow] = useState<T>(newRowEmpty)
  const [addNewDisabled, setAddNewDisabled] = useState<boolean>(true)

  const isRowValid = (row: T) => columns.every(c => !c.validator || c.validator(row))

  useEffect(() => {
    // check each column has a value
    const hasValue = columns.every(x => newRow[x.key!] !== '')
    setAddNewDisabled(!hasValue)
  }, [newRow])

  return <div className="rounded overflow-auto">
    <table className="text-sm text-left text-gray-500 w-full shadow table-auto">
      <thead className="text-gray-700 bg-gray-50">
        <tr>
          {/* If editable set column width so switching to inputs don't change table size. */}
          {columns.map((x, i) => <th className={'px-2 py-4'} key={i}>{x.name}</th>)}
          {editable && <th style={{ width: editableColumnWidth }}></th>}
        </tr>
      </thead>
      <tbody>
        {rows.map((x, xi) => {
          const isEditing = editingRow?.uuid === x.uuid
          return <tr className="bg-white border-b hover:bg-gray-50" key={xi}>
            {columns.map((y, yi) => <td key={yi} className="p-2 h-full">{cell(y, isEditing ? editingRow : x, (e) => { setEditingRow((prev) => ({ ...prev!, [y.key!]: e })) }, isEditing)}</td>)}
            {editable && <td>
              <div className="flex items-center space-x-2 justify-end p-2 h-full">
                {!isEditing && <Button onClick={() => { setEditingRow({ ...x }) }}><WrappedIcon data-cy='table_edit_button' className="w-3 h-3" icon={Edit} /></Button>}
                {!isEditing && onDeleteRow && <Button onClick={() => { onDeleteRow(x.uuid!) }} ><WrappedIcon data-cy='table_delete_button' className="w-3 h-3" icon={Trash} /></Button>}
                {isEditing && <Button disabled={!isRowValid(editingRow!)} onClick={() => { onSaveRow?.(editingRow!); setEditingRow(undefined) }}><WrappedIcon data-cy='table_save_button' className="w-3 h-3" icon={Check} /></Button>}
                {isEditing && <Button onClick={() => { setEditingRow(undefined) }}><WrappedIcon className="w-3 h-3" icon={X} /></Button>}
              </div>
            </td>}
          </tr>
        })}
        {addNew && newRow && <tr>
          {columns.map((x, i) => <td key={i} className="p-2">{cell(x, newRow, (e) => { setNewRow((prev) => ({ ...prev, [x.key!]: e })) }, true)}</td>)}
          <td>
            <div className="h-full flex items-center justify-end p-2">
              <Button disabled={addNewDisabled} onClick={() => { onSaveRow?.(newRow); setNewRow(newRowEmpty) }}>Add New</Button>
            </div>
          </td>
        </tr>}
      </tbody>
    </table>
  </div>
}

const cell = <T extends {}>(column: TableColumn<T>, row: T | undefined, onChange: (value: string) => void, isEditing: boolean) => {
  if (isEditing) {
    if (column.editRender) return column.editRender(row!, onChange)
    if (column.key) return <Input setValue={(e) => { onChange(e) }} value={row![column.key]?.toString() ?? ''} />
    return
  }
  return column.render ? column.render(row!) : row![column.key!]?.toString()
}
