import React from 'react'
import { HLRContext } from './heatloss_report'
import { HLRSubheaderBlock } from './partials/hlr_subheader'
import { ClickableCard } from '../../../components/content_display/card'
import { ProgressChart } from '../../../components/indicators_and_messaging/progress_chart'
import { sum } from 'lodash'
import { type TableColumn, TableLite } from '../../../components/content_display/table_lite'
import { getAverageRoomHeightM, getRoomACH, getRoomTemp, getRoomWatts } from '../../../code/models/heat_loss'
import { numberFormat } from '../../../code/number_format'
import { getAreaM2 } from '../floor/code/utils'

export const HLRHeatlossByRoomPage = () => {
  const hlrContext = React.useContext(HLRContext)

  const roomRows = hlrContext!.survey.floors.flatMap((floor, floorIndex) => floor.rooms.flatMap(y => {
    const calculatedArea = getAreaM2(y.walls.map(w => ({ x: w.x!, y: w.y! })))
    const heatLoss = Math.round(getRoomWatts(y, floor.rooms, hlrContext!.designTempC, hlrContext!.groundTempC, hlrContext!.survey))

    return {
      floorIndex,
      name: y.name,
      roomTemp: getRoomTemp(y, hlrContext!.survey),
      ach: getRoomACH(y, hlrContext!.survey),
      floorArea: calculatedArea,
      volume: getAverageRoomHeightM(y) * calculatedArea,
      heatLoss,
      heatLossPerUnit: (heatLoss / calculatedArea)
    }
  }))

  const sortedRoomRows = roomRows.sort((a, b) =>
    a.floorIndex !== b.floorIndex
      ? a.floorIndex - b.floorIndex
      : a.name.localeCompare(b.name)
  )

  const elementsTableColumns: Array<TableColumn<typeof sortedRoomRows[0]>> = [
    {
      name: '',
      key: 'name',
      render: (row) => <span className='text-gray-900 text-xs font-bold'>{row.name}</span>
    },
    { name: 'Room temp', key: 'roomTemp', render: (row) => <span className='text-xs font-semibold'>{row.roomTemp} °C</span> },
    { name: 'ACH*', key: 'ach', render: (row) => <span className='text-xs font-semibold'>{row.ach} {hlrContext!.survey.mvhr_installed && 'x 50 % **'}</span> },
    { name: 'Floor area', key: 'floorArea', render: (row) => <span className='text-xs font-semibold'>{numberFormat(1).format(row.floorArea)} m²</span> },
    { name: 'Volume', key: 'volume', render: (row) => <span className='text-xs font-semibold'>{numberFormat(0).format(row.volume)} m³</span> },
    { name: 'Heat loss', key: 'heatLoss', render: (row) => <span className='text-xs font-semibold'>{numberFormat(0).format(row.heatLoss)} W</span> },
    { name: 'heat loss per unit area', key: 'heatLossPerUnit', render: (row) => <span className='text-xs font-semibold'>{numberFormat(0).format(row.heatLossPerUnit)} W/m²</span> }
  ]

  const totalHeatLossWFromRooms = Math.round(sum(sortedRoomRows.map(x => x.heatLoss)))

  return (
    <div className="flex-col gap-6 flex">
      <HLRSubheaderBlock
        section="Heat loss"
        title="Heat loss by room"
        description="This section shows the heat loss from each room in your home. These results are used to design the radiators for your new system."
      />

      <ClickableCard variant='GREY'>
        <div className="gap-3 flex justify-between">
          <div className="text-gray-900 text-lg font-bold ">Total heat loss</div>
          <div className="text-lg">{totalHeatLossWFromRooms} W</div>
        </div>

        <ProgressChart
          total={totalHeatLossWFromRooms}
          items={sortedRoomRows.map(x => ({ value: x.heatLoss, name: x.name }))}
        />
      </ClickableCard>

      <TableLite columns={elementsTableColumns} rows={sortedRoomRows}/>

      <div className="flex-col flex">
        <div className="">
          <span className="text-gray-900 text-xs tracking-tight font-bold">*ACH: </span>
          <span className="text-gray-600 text-xs tracking-tight">air changes per hour</span>
        </div>
        {hlrContext!.survey.mvhr_installed && <div className="">
          <span className="text-gray-900 text-xs tracking-tight font-bold">**MVHR: </span>
          <span className="text-gray-600 text-xs tracking-tight">Ventilation heat loss reduced by 50% to account for heat recovery through the MVHR unit</span>
        </div>}
      </div>
    </div>
  )
}
