export const AGILE_PENCE_PER_KWH = 17 // carbon brief article Average from December 2023 to February 2024
export const AGILE_PRICE_EXPLANATION = 'Octopus Agile avg. Dec 2023 - Feb 2024'
export const OVO_HEAT_PUMP_PLUS_PENCE_PER_KWH = 15 // https://www.ovoenergy.com/heat-pump-plus
export const PRICE_CAP_DESCRIPTION = 'Price cap 1st Oct - 31st Dec 2024'
export const PRICE_CAP_ELECTRICITY_PENCE_PER_KWH = 24.5 // https://www.ofgem.gov.uk/news/changes-energy-price-cap-between-1-october-31-december-2024
export const PRICE_CAP_GAS_PENCE_PER_KWH = 6.24 // https://www.ofgem.gov.uk/news/changes-energy-price-cap-between-1-october-31-december-2024
export const KWH_PER_LITRE_OF_OIL = 9.79 // (kerosene) https://www.nottenergy.com/advice-and-tools/project-energy-cost-comparison

export type HeatingFuel = {
  uuid: string
  name: string
  unit: string
  penceUnit: string
  default_tarrif: number
  emissions_g_per_kwh: number
  kWh_per_unit: number
}

// Emissions factors from Table 12, SAP 10
// Non-price cap values from https://www.nottenergy.com/advice-and-tools/project-energy-cost-comparison
// Coal value from august 2024 values, emissions factor is from SAP table 12
// https://linear.app/spruce-eco/issue/SPR-1082/add-solid-fuel-option-wood-or-coal-electric-boiler
export const HEATING_FUELS: HeatingFuel[] = [
  {
    uuid: 'mains_gas',
    name: 'Mains gas',
    unit: 'kWh',
    penceUnit: 'p/kWh',
    default_tarrif: PRICE_CAP_GAS_PENCE_PER_KWH,
    emissions_g_per_kwh: 210,
    kWh_per_unit: 1
  },
  {
    uuid: 'lpg',
    name: 'LPG',
    unit: 'litre',
    penceUnit: 'p/litre',
    default_tarrif: 55,
    emissions_g_per_kwh: 210,
    kWh_per_unit: 6.78
  },
  {
    uuid: 'oil',
    name: 'Oil',
    unit: 'litres',
    penceUnit: 'p/litre',
    default_tarrif: 68,
    emissions_g_per_kwh: 210,
    kWh_per_unit: KWH_PER_LITRE_OF_OIL
  },
  {
    uuid: 'electricity',
    name: 'Electricity',
    unit: 'kWh',
    penceUnit: 'p/kWh',
    default_tarrif: PRICE_CAP_ELECTRICITY_PENCE_PER_KWH,
    emissions_g_per_kwh: 136,
    kWh_per_unit: 1
  },
  {
    uuid: 'coal',
    name: 'Coal',
    unit: 'kg',
    penceUnit: 'p/kg',
    default_tarrif: 48.5,
    emissions_g_per_kwh: 395,
    kWh_per_unit: 7.95
  }
]
export const getHeatingFuel = (uuid: string) => {
  const heatingFuel = HEATING_FUELS.find(x => x.uuid === uuid)
  if (!heatingFuel) {
    throw new Error(`Heating fuel ${uuid} not found`)
  }
  return heatingFuel
}
export const getHeatingPencePerKWh = (fuel: HeatingFuel, existingSystemPencePerUnitOverride: number | undefined) => {
  return getHeatingPencePerUnit(fuel, existingSystemPencePerUnitOverride) / fuel.kWh_per_unit
}
export const getHeatingPencePerUnit = (fuel: HeatingFuel, existingSystemPencePerUnitOverride: number | undefined) => {
  if (existingSystemPencePerUnitOverride !== undefined) return existingSystemPencePerUnitOverride
  return fuel.default_tarrif
}
export const getElectricityPencePerKwh = (electricityPencePerKWhOverwride: number | undefined) => {
  if (electricityPencePerKWhOverwride !== undefined) return electricityPencePerKWhOverwride
  const electricity = HEATING_FUELS.find(x => x.uuid === 'electricity')
  return electricity!.default_tarrif / electricity!.kWh_per_unit
}
