import React from 'react'
import { Badge } from '../indicators_and_messaging/badge'
import { Check } from 'lucide-react'

export type FormLabelProps = {
  labelText: string
  helperText?: string
  required?: boolean
  complete?: boolean
  size?: 'SM' | 'MD' | 'LG' | 'XL'
  info?: React.ReactNode
}

export const FormLabel = (
  {
    labelText,
    helperText,
    required = false,
    complete = false,
    size = 'MD',
    info = undefined
  }: FormLabelProps) => {
  return (
    <div className={'flex-col flex'}>
      <div className={'flex justify-between gap-3'}>
        <div className="items-center gap-1 flex">
          <div
            className={`text-bold font-bold ${size === 'XL' ? 'text-xl' : size === 'LG' ? 'text-lg' : size === 'SM' ? 'text-sm' : 'text-base'}`}>{labelText}</div>
          {info && info}
        </div>
        {required &&
                  <Badge color={complete ? 'GREEN' : 'YELLOW'} text='Required' icon={complete ? Check : undefined}/>}
      </div>
      {
        helperText &&
              <div className={`text-gray-500 text-sm ${size === 'LG' ? 'text-base' : 'text-sm'}`}>{helperText}</div>
      }
    </div>
  )
}
