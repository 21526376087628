import React, { useContext, useState } from 'react'
import { Header } from '../components/header'
import { SurveyActionType, SurveyPagesEnum } from '../../../code/survey/survey'
import { Button } from '../../../components/buttons/button'
import { ChevronRightIcon } from 'lucide-react'
import { logEvent } from '../../../code/log_event'
import { type CompanyPublicInfo } from '../../../code/models/company'
import { DataLoaderContext } from '../components/data_loader_context'
import { SurveyContext, SurveyDispatchContext } from '../survey_page'
import { Select } from '../../../components/inputs_and_selections/select'
import { checkIfScottish, findAddresses, getAddressForUdprn, parseAddressData, parseAddressDataForManualEntry } from '../../../code/models/address'
import { getEpcForUprn, getEpcScottishForUprn, parseEnglishEpc } from '../../../code/models/epc'
import { useDebounceCallback } from 'usehooks-ts'
import { Link } from '../../../components/buttons/link'
import { parseScottishEpc } from '../../../code/models/epc_scotland'

export const ChooseAddressStep = ({ companyPublicInfo }: { companyPublicInfo: CompanyPublicInfo }) => {
  // survey context and dispatch
  const survey = useContext(SurveyContext)
  const dispatch = useContext(SurveyDispatchContext)

  const [selectedAddress, setSelectedAddress] = useState<string>()
  const [addressList, setAddressList] = useState<Array<{ key: string, value: string }>>([])

  // used to flag that we load some data from the server
  const { loadingData, setLoadingData } = useContext(DataLoaderContext)
  const [loadingAutocomplete, setLoadingAutocomplete] = useState<boolean>(false)

  const handleNavigateNextPage = async () => {
    setLoadingData(true)
    logEvent({ name: 'Select Address Step Completed', properties: {} }, companyPublicInfo.subdomain)

    let lead = survey.lead
    try {
      const udprn = addressList.find((x) => x.key === selectedAddress)?.key
      const address = await getAddressForUdprn(udprn!)
      if (address) {
        lead = parseAddressData(address, survey.lead)
        const isScottish = checkIfScottish(lead.property.postcode)
        if (isScottish) {
          const epc = await getEpcScottishForUprn(address.uprn)
          if (epc) {
            lead = await parseScottishEpc(epc, lead)
          }
        } else {
          const epc = await getEpcForUprn(address.uprn)
          if (epc) {
            lead = await parseEnglishEpc(epc, lead)
          }
        }
      }
      dispatch({
        type: SurveyActionType.NavigateNextPage,
        payload: {
          lead
        }
      })
    } catch (e) {
      // We've had a problem fetching the detailed address data, so we can't fetch the EPC for this address.
      // Drop back to the manual address entry page.
      console.error(e)
      dispatch({
        type: SurveyActionType.NavigateToPageFromParams,
        payload: {
          page: SurveyPagesEnum.ManualAddressStep
        }
      })
    } finally {
      setLoadingData(false)
    }
  }

  const handleOnNavigateManualAddressPage = async () => {
    logEvent({ name: 'Manual address entry clicked', properties: {} }, companyPublicInfo.subdomain)

    try {
      // Try and get as much data as possible from the address lookup for the manual address entry
      let manualEntryAddress = {}
      if (selectedAddress) {
        const udprn = addressList.find((x) => x.key === selectedAddress)?.key
        const address = await getAddressForUdprn(udprn!)
        if (address) {
          manualEntryAddress = parseAddressDataForManualEntry(address)
        }
      }

      dispatch({
        type: SurveyActionType.NavigateToPageFromParams,
        payload: {
          page: SurveyPagesEnum.ManualAddressStep,
          address: manualEntryAddress
        }
      })
    } catch (e) {
      console.error(e)
      // If we can't get the address data, just navigate to the manual address entry page without any data
      dispatch({
        type: SurveyActionType.NavigateToPageFromParams,
        payload: {
          page: SurveyPagesEnum.ManualAddressStep
        }
      })
    }
  }

  const searchForAddresses = async (query: string) => {
    try {
      if (!query || query.length < 3) {
        setAddressList([])
        setLoadingAutocomplete(false)
        return
      }
      const addresses = await findAddresses(query)
      if (addresses?.length) {
        setAddressList(addresses.map((x) => ({ key: x.udprn, value: x.suggestion })))
      } else {
        setAddressList([{ key: 'enter-address-manually', value: 'Enter address manually' }])
      }
      setLoadingAutocomplete(false)
    } catch (e) {
      console.error(e)
      setLoadingAutocomplete(false)
      setAddressList([{ key: 'enter-address-manually', value: 'Enter address manually' }])
    }
  }
  const debouncedSearch = useDebounceCallback(searchForAddresses, 500)

  const handleOnSearch = async (query: string) => {
    setLoadingAutocomplete(true)
    debouncedSearch(query)
  }

  const handleSetSelectedAddress = async (key: string) => {
    if (key === 'enter-address-manually') {
      return await handleOnNavigateManualAddressPage()
    }
    setSelectedAddress(key)
  }

  return <>
    <div className="flex flex-col gap-3">
      <Header content="Request a heat pump estimate" />
      <div className="text-gray-600">Get a personalised cost estimate by providing a few details about you and your property.</div>
      <div className='text-gray-600'>Estimated time: 2 minutes.</div>
    </div>

    <div className="space-y-3">
      <div className="space-y-1">
        <div className="font-bold text-gray-600">Find address</div>
      </div>
      <Select
        size='LG'
        placeholder="Start typing your address or postcode"
        options={addressList}
        selectedKey={selectedAddress}
        setSelectedKey={handleSetSelectedAddress}
        filter={true}
        filterCallback={handleOnSearch}
        isLoading={loadingAutocomplete}
        autoFocus={true}
      />
      <Link text='Enter address manually' onClick={handleOnNavigateManualAddressPage} />
    </div>

    <Button size='LG' disabled={loadingData || !selectedAddress} onClick={handleNavigateNextPage}>
      <div className="flex items-center gap-x-2">
        <div>Continue</div>
        <ChevronRightIcon className="h-5 w-5" />
      </div>
    </Button>
  </>
}
