import React, { useState } from 'react'
import { Text } from './text'
import { WrappedIcon } from '../buttons/wrapped_icon'
import { ChevronDown, ChevronUp } from 'lucide-react'

type AccordionProps = {
  rows: Array<{ title: string | JSX.Element, body: JSX.Element | string }>
}

export const Accordion = ({ rows }: AccordionProps) => {
  const [expandedId, setExpandedId] = useState<number>()

  if (rows.length === 0) return

  const toggleExpanded = (id: number) => { setExpandedId((prev) => prev === id ? undefined : id) }

  return <div className="rounded divide-y bg-white text-sm w-full">
    {rows.map((x, i) => <div key={i}>
      <div onClick={() => { toggleExpanded(i) }} className="py-3 flex flex-grow justify-between items-center cursor-pointer">
        <Text bold={true}>{x.title}</Text>
        <WrappedIcon icon={expandedId === i ? ChevronUp : ChevronDown} />
      </div>
      {expandedId === i && <div className="py-3 text-gray-500"> {x.body}</div>}
    </div>)}
  </div>
}
