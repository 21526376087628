import React, { useState } from 'react'
import { Icon } from '../buttons/icon'
import { ClickableCard } from '../content_display/card'
import { Alert } from '../indicators_and_messaging/alert'
import { Text } from '../content_display/text'
import { Upload } from 'lucide-react'

type FileDropzoneProps = {
  helperText?: string
  maxSizeMB?: number
  onChange: (files: FileList) => void
} & React.InputHTMLAttributes<HTMLInputElement>

export const FileDropzone: React.FC<FileDropzoneProps> = ({ helperText, maxSizeMB = 2, onChange, ...inputProps }) => {
  const [error, setError] = useState<string | null>(null)

  const handleFileInputChange = (event) => {
    // TODO - This does not yet handle multiple files!
    setError(null)
    const file = event.target.files[0]

    if (!file) return

    if (file.size > maxSizeMB * 1024 * 1024) {
      setError(`File size exceeds the maximum limit of ${maxSizeMB}MB`)
      return
    }
    onChange(event.target.files)
  }

  return (<>
    <ClickableCard variant="PLACEHOLDER" className={`hover:bg-gray-100 file:hover:bg-gray-100 relative ${error ? 'border-solid border-red-600' : ''}`}>
      <label htmlFor="dropzone-file" className="flex flex-col justify-center items-center text-center py-6 gap-4">
        <Icon icon={Upload} className="size-8" />
        <div className='flex flex-col gap-1'>
          <Text className="inline">Click to upload, or drag and drop</Text>
          <Text size="SM" className='text-light'>{helperText && `${helperText} - `}Max {maxSizeMB}MB</Text>
        </div>
        <input id="dropzone-file" type="file" className="absolute w-full h-full top-0 left-0 cursor-pointer opacity-0" onChange={handleFileInputChange} {...inputProps} />
      </label>
    </ClickableCard>
    { error && <Alert type='DANGER'>{error}</Alert>}
  </>
  )
}
