import React, { useContext } from 'react'
import { type Lead, type LeadStatusType } from '../../../../code/models/lead'
import { Text } from '../../../../components/content_display/text'
import { Icon } from '../../../../components/buttons/icon'
import { Button } from '../../../../components/buttons/button'
import { type Colour } from '../../../../code/models/colour'
import { Select } from '../../../../components/inputs_and_selections/select'
import { AdminContext } from '../../admin_layout'
import { SendEstimateModalBlock } from '../enquiry_manager/components/send_estimate_modal_block'
import { SendDeclinedModalBlock } from '../enquiry_manager/components/send_declined_modal_block'
import { type CalculatedEstimate, estimateAndLeadToCalculatedEstimate } from '../../../../code/models/calculated_estimate'
import { type Estimate } from '../../../../code/calculate_estimate'
import { type CompanyPublicInfo } from '../../../../code/models/company'
import { Badge } from '../../../../components/indicators_and_messaging/badge'
import { ArrowLeft, Ban, CheckCircle, Eye, RefreshCw, Send, Wifi } from 'lucide-react'

type Props = {
  lead: Lead
  setLead: (lead: Lead) => void
  estimates: CalculatedEstimate[]
  setEstimates: (estimates: CalculatedEstimate[]) => void
  estimate: Estimate
  leadSaved: boolean
  basePath: string
  selectedTabId: string | undefined
  isOffline: boolean
  companyPublicInfo: CompanyPublicInfo
  navigateTo: (url: string) => void
}

export const STATUS_ITEMS = [
  { key: 'New', value: 'Estimate Required', colour: 'YELLOW' },
  { key: 'Quoted', value: 'Estimate Sent', colour: 'BLUE' },
  { key: 'Converted', value: 'Estimate Accepted', colour: 'GREEN' },
  { key: 'Rejected', value: 'Estimate Rejected', colour: 'RED' },
  { key: 'Declined', value: 'Enquiry Declined', colour: 'RED' },
  { key: 'SurveyBooked', value: 'Survey Booked', colour: 'BLUE' },
  { key: 'SurveyInProgress', value: 'Survey In Progress', colour: 'YELLOW' },
  { key: 'SurveyCompleted', value: 'Survey Completed', colour: 'GREEN' },
  { key: 'ProposalSent', value: 'Proposal Sent', colour: 'BLUE' },
  { key: 'ProposalAccepted', value: 'Proposal Accepted', colour: 'GREEN' },
  { key: 'Archived', value: 'Archived', colour: 'LIGHT' }
]

export const JobLayoutHeader = ({ lead, setLead, setEstimates, estimate, leadSaved, basePath, selectedTabId, isOffline, navigateTo, companyPublicInfo }: Props) => {
  const adminContext = useContext(AdminContext)

  const [isEstimateModalVisible, setIsEstimateModalVisible] = React.useState(false)
  const [declineModalVisible, setDeclineModalVisible] = React.useState(false)

  const statusColour = STATUS_ITEMS.find(x => x.key === lead.status)?.colour as Colour
  const calculatedEstimate = estimateAndLeadToCalculatedEstimate(estimate, lead, adminContext.data)

  return (
    <>
      <div className="flex md:items-center flex-col md:flex-row gap-4 px-6 pt-3">
        <div className='flex items-center gap-4'>
          <Icon className='w-6 h-6' onClick={() => navigateTo(`/${companyPublicInfo.subdomain}/admin/enquiries`)} icon={ArrowLeft} />
          <div>
            <div className='flex items-center gap-2'>
              <h1 className="text-2xl font-semibold text-bold">{lead.customer?.name}</h1>
              {isOffline
                ? <Badge color='RED' text='Offline' icon={Wifi} />
                : leadSaved
                  ? <Badge color='GREEN' text='Synced' icon={CheckCircle} />
                  : <Badge color="YELLOW" icon={RefreshCw} text="Sync pending..." />}
            </div>
            <Text className='tracking-tight'>{lead.property.address}, {lead.property.postcode}</Text>
          </div>
        </div>
        <div className='grid grid-cols-2 md:flex md:ml-auto gap-4'>
          {(selectedTabId === 'estimate' || !selectedTabId) && !isOffline && <>
            <Button colour="LIGHT" iconLeft={Eye} onClick={() => navigateTo(`${basePath}/estimate/preview`)}>Preview</Button>
            <Button colour="LIGHT" iconLeft={Ban} onClick={() => setDeclineModalVisible(true)}>Decline</Button>
            <Button colour="DARK" iconLeft={Send} onClick={() => setIsEstimateModalVisible(true)} disabled={!estimate.heatPump}>Send</Button>
          </>}
          <Select colour={statusColour} options={STATUS_ITEMS} selectedKey={lead.status} setSelectedKey={async (e: LeadStatusType) => {
            setLead({ ...lead, status: e })
          }} />
        </div>
      </div>
      {/* Send estimate modal */}
      { isEstimateModalVisible && <SendEstimateModalBlock
        setEstimates={setEstimates}
        adminContext={adminContext}
        setIsVisible={setIsEstimateModalVisible}
        estimate={estimate}
        calculatedEstimate={calculatedEstimate}
        lead={lead}
        setLead={setLead}
      />}

      {/* Send declined */}
      <SendDeclinedModalBlock
        adminContext={adminContext}
        isVisible={declineModalVisible}
        setIsVisible={setDeclineModalVisible}
        lead={lead}
        setLead={setLead}
      />
    </>
  )
}
