import React from 'react'
import { type Company } from '../../../../../code/models/company'
import { Section } from '../../../../../components/containers/section'
import { FormLabel } from '../../../../../components/inputs_and_selections/form_label'
import { Input } from '../../../../../components/inputs_and_selections/input'
import { Button } from '../../../../../components/buttons/button'
import { Heading } from '../../../../../components/content_display/heading'
import { Text } from '../../../../../components/content_display/text'
import { Link } from '../../../../../components/buttons/link'
import { noop } from 'lodash'
import { DEFAULT_BRAND_COLOUR_PRIMARY, DEFAULT_BRAND_COLOUR_SECONDARY, determineBestTextShade } from '../../../../../code/utils/colour_utils'
import { validateHexColourCode } from '../../../../../code/validators'
import { RefreshCw } from 'lucide-react'

export const BrandColoursBlock = ({ company, setCompany }: { company: Company, setCompany: (c: Company) => void }) => {
  const handleResetColours = async () => {
    const newCompany: Company = {
      ...company,
      public_info: {
        ...company.public_info,
        colour_primary: DEFAULT_BRAND_COLOUR_PRIMARY,
        colour_secondary: DEFAULT_BRAND_COLOUR_SECONDARY
      }
    }
    setCompany(newCompany)
  }

  return <Section border title="Company colours">
    <div className='grid gap-4 grid-flow-row grid-cols-1 md:grid-cols-2'>
      <div className='flex flex-col justify-between gap-4 row-start-2 md:row-start-1'>

        <div className='flex flex-col gap-4'>
          <FormLabel
            labelText='Primary'
            helperText='Used for main buttons.'
          />
          <ColorInput
            value={company.public_info.colour_primary ?? DEFAULT_BRAND_COLOUR_PRIMARY.toUpperCase()}
            setValue={colour => {
              if (!colour) return
              if (validateHexColourCode(colour ?? DEFAULT_BRAND_COLOUR_PRIMARY).value) {
                setCompany({ ...company, public_info: { ...company.public_info, colour_primary: colour } })
              }
            }}
          />
          <FormLabel
            labelText='Secondary'
            helperText='Used for background accents.'
          />
          <ColorInput
            value={company.public_info.colour_secondary ?? DEFAULT_BRAND_COLOUR_SECONDARY.toUpperCase()}
            setValue={colour => {
              if (!colour) return
              if (validateHexColourCode(colour ?? DEFAULT_BRAND_COLOUR_SECONDARY).value) {
                setCompany({ ...company, public_info: { ...company.public_info, colour_secondary: colour } })
              }
            }}
          />
        </div>
        <Button colour='LIGHT' iconLeft={RefreshCw} disabled={(!company.public_info.colour_primary || company.public_info.colour_primary === DEFAULT_BRAND_COLOUR_PRIMARY) && (!company.public_info.colour_secondary || company.public_info.colour_secondary === DEFAULT_BRAND_COLOUR_SECONDARY)} onClick={handleResetColours} confirmText='Are you sure?'>Reset to defaults</Button>
      </div>
      <div className='flex flex-col gap-4'>
        <FormLabel
          labelText='Preview'
          helperText='This is what your estimates will look like'
        />
        <div
          style={{ backgroundColor: company.public_info.colour_secondary ?? DEFAULT_BRAND_COLOUR_SECONDARY }}
          className='w-full h-full border rounded-lg'
        >
          <div className="px-6 py-10 flex flex-col items-center w-full gap-5 rounded-lg">
            <div className="flex flex-col gap-3 text-center">
              <Heading size="2xl" className={determineBestTextShade(company.public_info.colour_secondary ?? DEFAULT_BRAND_COLOUR_SECONDARY) === 'LIGHT' ? 'text-black' : 'text-white' } >Book a full heat loss survey today</Heading>
              <Text className={determineBestTextShade(company.public_info.colour_secondary ?? DEFAULT_BRAND_COLOUR_SECONDARY) === 'LIGHT' ? 'text-black' : 'text-white' } >Or request a callback to discuss your needs in more detail.</Text>
            </div>
            <div className="w-full max-w-80 flex flex-col gap-3 items-center">
              <Button
                style={{
                  backgroundColor: company.public_info.colour_primary,
                  borderColor: company.public_info.colour_primary,
                  color: determineBestTextShade(company.public_info.colour_primary ?? DEFAULT_BRAND_COLOUR_PRIMARY) === 'LIGHT' ? '#000000' : '#ffffff'
                }}
                className='w-full'
                onClick={noop}>
              View cost estimate
              </Button>
              <Button colour='LIGHT' className='w-full'>Request a callback</Button>
              <Link className={determineBestTextShade(company.public_info.colour_secondary ?? DEFAULT_BRAND_COLOUR_SECONDARY) === 'LIGHT' ? '' : 'text-white' } text='I’m not interested right now' onClick={noop} />
            </div>
          </div>
        </div>
      </div>
    </div>
  </Section>
}

const ColorInput = ({ value, setValue, disabled }: { value: string, setValue: (value: string | null) => void, disabled?: boolean }) => {
  const handleColourChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setValue(e.target.value)
  }

  return <div className='flex gap-2'>
    <Input
      value={value.toUpperCase()}
      setValue={e => setValue(e)}
      validator={validateHexColourCode}
      validateImmediately
    />
    <div // Wrapper for <input> that allows us to style it appropriately.
      style={{ backgroundColor: value }}
      className={`rounded-lg border max-h-9 border-gray-300 ${disabled === true ? 'opacity-70' : 'opacity-100'}`}>
      <input
        type='color'
        className='opacity-0 h-full disabled:cursor-auto cursor-pointer'
        onChange={handleColourChange}
        value={value}
      ></input>
    </div>
  </div>
}
