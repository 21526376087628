import React, { type Dispatch, type SetStateAction, useState } from 'react'
import { PageHeader } from '../components/design_page_header'
import { Input } from '../../../../components/inputs_and_selections/input'
import {
  type CylinderReheatCalculationRow,
  getDailyHotWaterVolumeL,
  getNumberOfOccupants,
  getNumberOfOccupantsNoOverride,
  type HotWaterCalculationRow
} from '../../../../code/models/hot_water_cylinder'
import { type PropertySurvey, type SurveyDesign } from '../../../../code/models/property'
import { SurveyCylinderLocationContext } from '../../survey/cylinder_location'
import { type FileWrapper } from '../../file_wrapper'
import {
  CylinderLocationProposedPhotosBlock
} from '../../survey/cylinder_location/cylinder_location_proposed_photos_block'
import {
  CylinderLocationProposedLocationBlock
} from '../../survey/cylinder_location/cylinder_location_proposed_location_block'
import { Icon } from '../../../../components/buttons/icon'
import { ClickableCard } from '../../../../components/content_display/card'
import { ListItem } from '../../../../components/content_display/list_item'
import { Badge } from '../../../../components/indicators_and_messaging/badge'
import { FormLabel } from '../../../../components/inputs_and_selections/form_label'
import { VerticalFormGroup } from '../../../../components/inputs_and_selections/vertical_form_group'
import { type SetIndexedDb } from '../../../admin/job_layout/job_layout'
import { type InventoryHotWaterCylinder } from '../../../../code/models/inventory'
import { ChevronRight, XCircle } from 'lucide-react'
import { WrappedIcon } from '../../../../components/buttons/wrapped_icon'

type CylinderDesignPageProps = {
  currentHotWaterCylinderId: string | undefined
  hotWaterCylinders: InventoryHotWaterCylinder[]
  survey: PropertySurvey
  setSurvey: SetIndexedDb<PropertySurvey>
  cylinderReheatRow: CylinderReheatCalculationRow
  hotWaterRowNormal: HotWaterCalculationRow
  hotWaterRowLegionella: HotWaterCalculationRow
  design: SurveyDesign
  setDesign: Dispatch<SetStateAction<SurveyDesign>>
  minFlowTemp: number
  maxFlowTemp: number
  files: FileWrapper[]
  setFiles: (files: FileWrapper[]) => void
  companyUUID: string
  isOffline: boolean
  allSynced: boolean
}

type CylinderPage = 'MAIN' | 'DEMAND' | 'SELECT_CYLINDER'

export const CylinderDesignPage = (
  {
    currentHotWaterCylinderId,
    hotWaterCylinders,
    survey,
    setSurvey,
    cylinderReheatRow,
    hotWaterRowNormal,
    hotWaterRowLegionella,
    design,
    setDesign,
    minFlowTemp,
    maxFlowTemp,
    files,
    setFiles,
    companyUUID,
    isOffline,
    allSynced
  }: CylinderDesignPageProps) => {
  const [page, setPage] = useState<CylinderPage>('MAIN')

  if (page === 'DEMAND') return <DemandPage survey={survey} setSurvey={setSurvey} setPage={setPage} isOffline={isOffline} allSynced={allSynced} />

  const currentHotWaterCylinder = hotWaterCylinders.find(x => x.uuid === currentHotWaterCylinderId)

  const numberOfOccupants = getNumberOfOccupants(survey)
  const dailyHotWaterVolumeL = getDailyHotWaterVolumeL(numberOfOccupants, survey.volume_per_person_l)
  const hotWaterConsumptionAnnual = hotWaterRowNormal.electricityKWhPerYear + hotWaterRowLegionella.electricityKWhPerYear

  if (page === 'SELECT_CYLINDER') {
    return <div className='flex flex-col'>
      <PageHeader title='Select a cylinder' onBack={() => setPage('MAIN')} isOffline={isOffline} allSynced={allSynced} />
      <div className="bg-white rounded-md flex-col flex divide-y divide-gray-200">
        {hotWaterCylinders.map(x =>
          <ListItem
            onClick={() => {
              setDesign({ ...design, current_hot_water_cylinder_uuid: x.uuid })
              setPage('MAIN')
            }} key={x.uuid} primaryText={x.name}
            rightBadge={<Badge color='LIGHT' text={Math.round(x.litres) + ' litres'}/>}
          />)
        }
      </div>
    </div>
  }
  return <>
    <div className='flex flex-col h-full min-h-0'>
      <PageHeader title='Hot water & cylinder' onBack={() => window.history.back()} isOffline={isOffline} allSynced={allSynced} />
      <div className='flex flex-col gap-6 p-4 overflow-y-auto'>
        <div className='flex flex-col gap-6 border-b border-gray-200 pb-6'>
          <div className='font-bold text-xl text-gray-900'>Demand</div>
          <div className='flex justify-between cursor-pointer items-center' onClick={() => setPage('DEMAND')}>
            <div className='flex flex-col gap-1 text-gray-600'>
              <div className='font-bold text-gray-900'>{dailyHotWaterVolumeL} litres per day</div>
              <div className='text-sm'>{numberOfOccupants} occupants x {survey.volume_per_person_l} litres per occupant</div>
            </div>
            <WrappedIcon className='text-gray-600' icon={ChevronRight} />
          </div>
        </div>

        <div className='flex flex-col gap-6'>
          <div className='font-bold text-gray-900 text-xl'>Supply</div>

          <div className='flex flex-col gap-5'>
            <div className='flex flex-col gap-2'>
              <div className='flex flex-col'>
                <div className='text-gray-900 font-bold'>Cylinder model</div>
                <div className='text-gray-500 text-sm'>MCS guidance acknowledges that cylinder size may be limited by
                  available space
                </div>
              </div>
              {currentHotWaterCylinder ? <ClickableCard title={currentHotWaterCylinder.name} variant='WHITE' onClick={() => setPage('SELECT_CYLINDER')}>
                <div className="gap-2 flex justify-between">
                  <div className="text-xs font-semibold">Capacity</div>
                  <div className="text-xs font-semibold">{Math.round(currentHotWaterCylinder.litres)} litres</div>
                </div>
              </ClickableCard>
                : <ClickableCard variant='PLACEHOLDER' onClick={() => setPage('SELECT_CYLINDER')}>Please select a
                    cylinder</ClickableCard>}
            </div>

            <div className='flex flex-col gap-2'>
              <div className='font-bold text-gray-900'>Hot water storage temperature</div>
              <Input
                validator={validateHaveHeatPumpSCOPAtHotWaterStorageTemp(minFlowTemp, maxFlowTemp)}
                postfix='°C'
                type='number'
                value={design.hot_water_storage_temperature_c.toString()}
                setValue={(e) => setDesign({ ...design, hot_water_storage_temperature_c: parseFloat(e) })}/>
              {cylinderReheatRow.capacityWarning &&
                  <ClickableCard variant={'WARNING'} border={false} size={'SM'} className={'text-xs px-5 py-2'}>
                    <div className="flex-col gap-2">
                      <div className="font-bold"> Warning</div>
                      <div>{cylinderReheatRow.capacityWarning}</div>
                    </div>
                  </ClickableCard>}

            </div>
            <VerticalFormGroup
              formLabel={
                <FormLabel
                  labelText={'Legionella cycle interval'}
                  helperText={'The number of weeks between Legionella cycles. E.g. 2 means one cycle every two weeks'}/>}
              input={<Input
                validator={validateLegionairreCycleInterval()}
                postfix='weeks'
                type='number'
                value={survey.legionnaires_cycle_weeks.toString()}
                setValue={async (e) => await setSurvey({ ...survey, legionnaires_cycle_weeks: parseFloat(e) })}/>
              }
            />
          </div>

          <div className='border border-gray-200'/>

          <div className='flex flex-col gap-6'>
            <div className='font-bold text-xl text-gray-900'>Calculations</div>
            <div className='flex flex-col gap-2'>
              <div className='flex justify-between font-bold text-gray-900'>
                <div>Calculated reheat time</div>
                <div>{cylinderReheatRow.reheatTimeHoursAndMinutes}</div>
              </div>

              <div className="flex-col flex text-xs">
                <div className="py-2 justify-between gap-2 flex">
                  <div>Cylinder volume</div>
                  <div>{Math.round(cylinderReheatRow.cylinderVolumeL)} litres</div>
                </div>
                <div className="py-2 border-t border-gray-200 justify-between gap-2 flex">
                  <div>Assumed mains water temp</div>
                  <div>{cylinderReheatRow.coldWaterTempC} °C</div>
                </div>
                <div className="py-2 border-t border-gray-200 justify-between gap-2 flex">
                  <div>Heat pump flow temp</div>
                  <div>{cylinderReheatRow.flowTempC} °C</div>
                </div>
                <div className="py-2 border-t border-gray-200 justify-between gap-2 flex">
                  <div>Heat pump capacity ({cylinderReheatRow.flowTempC} °C flow, {cylinderReheatRow.outsideTempC} °C air)</div>
                  <div>{cylinderReheatRow.heatingPowerKw} kW</div>
                </div>
              </div>
            </div>

            {/* Demand calc */}
            <div className='flex flex-col gap-4'>
              <div className='flex justify-between font-bold text-gray-900'>
                <div>Calculated hot water demand</div>
                <div>{hotWaterConsumptionAnnual.toFixed(0)} kWh</div>
              </div>
              {/* Normal operation */}
              <div className="flex-col flex text-xs">
                <div className="py-2 justify-between gap-2 flex text-sm font-bold">
                  <div>Normal operation</div>
                  <div>{(hotWaterRowNormal.electricityKWhPerYear).toFixed(0)} kWh</div>
                </div>
                <div className="py-2 border-t border-gray-200 justify-between gap-2 flex indent-2">
                  <div>Electricity per day</div>
                  <div>{hotWaterRowNormal.electricityKwhPerCycle.toFixed(2)} kWh</div>
                </div>
                <div className="py-2 border-t border-gray-200 justify-between gap-2 flex indent-4">
                  <div>Heat energy per day</div>
                  <div>{hotWaterRowNormal.heatEnergyKwhPerCycle.toFixed(2)} kWh</div>
                </div>
                <div className="py-2 border-t border-gray-200 justify-between gap-2 flex indent-6">
                  <div>Volume per day</div>
                  <div>{dailyHotWaterVolumeL} litres</div>
                </div>
                <div className="py-2 border-t border-gray-200 justify-between gap-2 flex indent-6">
                  <div>Assumed mains water temp</div>
                  <div>{hotWaterRowNormal.coldWaterTempC} °C</div>
                </div>
                <div className="py-2 border-t border-gray-200 justify-between gap-2 flex indent-6">
                  <div>Storage temp</div>
                  <div>{hotWaterRowNormal.hotWaterTempC} °C</div>
                </div>
                <div className="py-2 border-t border-gray-200 justify-between gap-2 flex indent-6">
                  <div>Distribution efficiency</div>
                  <div>{hotWaterRowNormal.distributionEfficiency * 100} %</div>
                </div>
                <div className="py-2 border-t border-gray-200 justify-between gap-2 flex indent-4">
                  <div>Heat pump sCOP ({hotWaterRowNormal.flowTempC} °C flow)</div>
                  {hotWaterRowNormal.heatingEfficiency > 0 && <div>{(hotWaterRowNormal.heatingEfficiency * 100).toFixed(0)} %</div>}
                  {hotWaterRowNormal.heatingEfficiency === 0 && <Badge color={'RED'} text = {`${hotWaterRowNormal.heatingEfficiency} %`}></Badge>}
                </div>
                <div className="py-2 border-t border-gray-200 justify-between gap-2 flex indent-2">
                  <div>Days per year</div>
                  <div>{hotWaterRowNormal.cyclesPerYear}</div>
                </div>
              </div>

              {/* Legionella */}
              <div className="flex-col flex text-xs">
                <div className="py-2 justify-between gap-2 flex text-sm font-bold">
                  <div>Legionella cycles</div>
                  <div>{(hotWaterRowLegionella.electricityKWhPerYear).toFixed(0)} kWh</div>
                </div>
                <div className="py-2 border-t border-gray-200 justify-between gap-2 flex indent-2">
                  <div>Electricity per cycle</div>
                  <div>{hotWaterRowLegionella.electricityKwhPerCycle.toFixed(2)} kWh</div>
                </div>
                <div className="py-2 border-t border-gray-200 justify-between gap-2 flex indent-4">
                  <div>Heat energy per cycle</div>
                  <div>{hotWaterRowLegionella.heatEnergyKwhPerCycle.toFixed(2)} kWh</div>
                </div>
                <div className="py-2 border-t border-gray-200 justify-between gap-2 flex indent-6">
                  <div>Volume per cycle</div>
                  <div>{Math.round(cylinderReheatRow.cylinderVolumeL)} litres</div>
                </div>
                <div className="py-2 border-t border-gray-200 justify-between gap-2 flex indent-6">
                  <div>Tank starting temperature</div>
                  <div>{hotWaterRowLegionella.coldWaterTempC} °C</div>
                </div>
                <div className="py-2 border-t border-gray-200 justify-between gap-2 flex indent-6">
                  <div>Legionella set point</div>
                  <div>{hotWaterRowLegionella.hotWaterTempC} °C</div>
                </div>
                <div className="py-2 border-t border-gray-200 justify-between gap-2 flex indent-6">
                  <div>Distribution efficiency</div>
                  <div>{hotWaterRowLegionella.distributionEfficiency * 100} %</div>
                </div>
                <div className="py-2 border-t border-gray-200 justify-between gap-2 flex indent-4">
                  <div>Legionella cycle efficiency</div>
                  <div>{hotWaterRowLegionella.heatingEfficiency * 100} %</div>
                </div>
                <div className="py-2 border-t border-gray-200 justify-between gap-2 flex indent-2">
                  <div>Cycles per year</div>
                  <div>{(hotWaterRowLegionella.cyclesPerYear).toFixed(0)}</div>
                </div>
              </div>
            </div>
          </div>

          <div className='border border-gray-200'/>

          <div className='flex flex-col gap-6'>
            <div className='font-bold text-xl text-gray-900'>Location</div>
            <SurveyCylinderLocationContext.Provider value={{ isOffline, allSynced, survey, setSurvey, files, setFiles, companyUUID }}>
              <CylinderLocationProposedPhotosBlock />
              <CylinderLocationProposedLocationBlock />
            </SurveyCylinderLocationContext.Provider>
          </div>
        </div>
      </div>
    </div>
  </>
}

type DemandPageProps = {
  survey: PropertySurvey
  setSurvey: (survey: PropertySurvey) => void
  setPage: Dispatch<SetStateAction<CylinderPage>>
  isOffline: boolean
  allSynced: boolean
}

const DemandPage = ({ survey, setSurvey, setPage, isOffline, allSynced }: DemandPageProps) => {
  return <div className='flex flex-col h-full'>
    <PageHeader title='Domestic hot water' onBack={() => setPage('MAIN')} isOffline={isOffline} allSynced={allSynced} />
    <div className='flex flex-col gap-5 p-4 overflow-y-auto'>
      <div className='flex flex-col gap-2'>
        <div className='font-bold text-gray-900'>Bedrooms</div>
        <Input
          type='number'
          value={survey.bedrooms.toString()}
          setValue={(e) => setSurvey({ ...survey, bedrooms: parseFloat(e) })}/>
      </div>
      <div className='flex flex-col gap-2'>
        <div className='font-bold text-gray-900'>Occupants</div>
        <Input type='number'
          value={(survey.occupants_override ?? survey.bedrooms + 1).toString() ?? ''}
          setValue={(e) => setSurvey({ ...survey, occupants_override: Number(e) })}
          postfix={
            <div className="gap-2.5 flex items-center">
              {(survey.occupants_override !== undefined && (survey.occupants_override !== getNumberOfOccupantsNoOverride(survey.bedrooms))) &&
                <Icon
                  icon={XCircle}
                  onClick={() => setSurvey(({ ...survey, occupants_override: undefined }))}
                  colour='text-gray-400'
                />
              }
            </div>
          }
        />
      </div>
      <div className='flex flex-col gap-2'>
        <div className='font-bold text-gray-900'>Daily volume per person</div>
        <Input
          type='number'
          value={survey.volume_per_person_l.toString()}
          setValue={(e) => setSurvey({ ...survey, volume_per_person_l: parseFloat(e) })}
          postfix={'litres'}
        />
      </div>
    </div>
  </div>
}

export const validateHaveHeatPumpSCOPAtHotWaterStorageTemp = (minFlowTemp: number, maxFlowTemp: number) => (value: number | string | undefined): { message: string, value: number | undefined } => {
  if (minFlowTemp === 0 || maxFlowTemp === 0) return { value: undefined, message: 'Please select a heat pump before setting the hot water design parameters' }
  const parsedValue = (typeof value === 'string') ? parseFloat(value) : value
  if (parsedValue === undefined || isNaN(parsedValue)) return { value: undefined, message: 'Please enter a value' }
  const flowTempC = parsedValue + 5
  const isValid = flowTempC >= minFlowTemp && flowTempC <= maxFlowTemp
  return isValid ? { value: parsedValue, message: '' } : { value: undefined, message: `We don't have sCOP data for this heat pump at the flow temp required for a hot water storage temperature of ${parsedValue}. Please enter a number between ${minFlowTemp - 5} and ${maxFlowTemp - 5}.` }
}

export const validateLegionairreCycleInterval = () => (value: number | string | undefined): { message: string, value: number | undefined } => {
  const parsedValue = (typeof value === 'string') ? parseFloat(value) : value
  if (parsedValue === undefined || isNaN(parsedValue)) return { value: undefined, message: 'Please enter a value' }
  const isValid = parsedValue > 0
  return isValid ? { value: parsedValue, message: '' } : { value: undefined, message: 'Please enter a value of 1 or greater. This is the number of weeks between Legionella cycles. E.g. 2 would be one Legionella cycle every two weeks' }
}
