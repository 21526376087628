import React from 'react'
import { ClickableCard } from '../../../components/content_display/card'
import { HLRContext } from './heatloss_report'
import { Badge } from '../../../components/indicators_and_messaging/badge'
import { getSoundAssessmentData } from '../../../code/sound_assessment'
import { HLRListBlock } from './partials/hlr_list'
import { HLRHeaderBlock } from './partials/hlr_header'

export const HLRSoundAssessment = () => {
  const hlrContext = React.useContext(HLRContext)

  const {
    barrier,
    distanceReductionDba,
    barrierReductionDba,
    soundPressureLevelDba,
    backgroundNoiseLevelDba,
    differentialDba,
    correctionDB,
    finalResultDba
  } = getSoundAssessmentData(hlrContext!.survey.sound_barrier_uuid, hlrContext!.survey.reflective_surfaces, hlrContext!.survey.sound_distance, hlrContext!.currentHeatPump?.range_heat_pump?.sound_power_max_dba)

  let reflectiveSurfacesText = ''
  switch (hlrContext!.survey.reflective_surfaces) {
    case 1:
      reflectiveSurfacesText = 'one reflecting surface (Q=2)'
      break
    case 2:
      reflectiveSurfacesText = 'two reflecting surfaces (Q=4)'
      break
    case 3:
      reflectiveSurfacesText = 'three reflecting surfaces (Q=8)'
      break
  }

  return <div className='flex flex-col gap-8'>
    <HLRHeaderBlock
      title='Sound assessment'
      description='To class as “permitted development” the heat pump design must comply with regulations regarding the sound level at your neighbour’s nearest window/door. This section presents the results of the sound assessment we’ve conducted for for the proposed heat pump and location, as required by those regulations.'
    />

    <div className='flex flex-col gap-6'>
      <div className='flex flex-col gap-3'>
        <div className='grid grid-cols-1 sm:grid-cols-2 gap-6'>
          <div className='flex flex-col gap-2'>
            <div className="text-gray-900 font-bold">This assessment accounts for:</div>
            <HLRListBlock items={[
              <>The sound level of the heat pump itself</>,
              <>The influence of the space that it is in</>,
              <>Any barriers between the heat pump and the assessment position</>
            ]}/>
          </div>
          <div className='flex flex-col gap-2'>
            <div className="text-gray-900 font-bold">Assessment position:</div>
            <div className="">{hlrContext!.survey.assessment_description}
            </div>
          </div>
        </div>
      </div>

      <div className='grid grid-cols-1 sm:grid-cols-2 gap-6'>
        <ClickableCard variant="GREY" className='bg-gray-100 rounded-md p-5'>
          <div className='flex flex-col gap-4'>
            <div className="text-gray-900 font-bold">Sound pressure level</div>

            <div>
              <div className="py-2 border-b border-gray-200 border-dashed items-baseline gap-2 flex justify-between">
                <div className="flex-col gap-1 flex">
                  <div className="text-gray-900 text-xs font-semibold">Sound power level</div>
                  <div className="text-xs font-semibold">Manufacturer’s data</div>
                </div>
                <div
                  className="text-xs font-semibold text-nowrap">{hlrContext!.currentHeatPump.range_heat_pump?.sound_power_max_dba} dB(A)
                </div>
              </div>
              <div className="py-2 border-b border-gray-200 border-dashed items-baseline gap-2 flex justify-between">
                <div className="flex-col gap-1 flex">
                  <div className="text-gray-900 text-xs font-semibold">Reduction due to distance</div>
                  <div className="text-xs font-semibold">Reflective surfaces: {reflectiveSurfacesText}</div>
                  <div className="text-xs font-semibold">Distance from heat pump to assessment
                    position: {hlrContext!.survey.sound_distance} m
                  </div>
                </div>
                <div className="text-xs font-semibold text-nowrap">{distanceReductionDba} dB(A)</div>
              </div>
              <div className="py-2 border-b border-gray-200 border-dashed items-baseline gap-2 flex justify-between">
                <div className="flex-col gap-1 flex">
                  <div className="text-gray-900 text-xs font-semibold">Barriers</div>
                  <div className="text-xs font-semibold">Between heat pump and assessment
                    position: {barrier!.name}</div>
                  <div className="text-xs font-semibold">Distance from heat pump to assessment
                    position: {hlrContext!.survey.sound_distance} m
                  </div>
                </div>
                <div className="text-xs font-semibold text-nowrap">{barrierReductionDba} dB(A)</div>
              </div>
              <div className="py-2 gap-2 flex justify-between">
                <div className="text-gray-900 text-xs font-bold">Total</div>
                <div className="text-gray-900 text-xs font-bold text-nowrap">{soundPressureLevelDba} dB(A)</div>
              </div>
            </div>
          </div>
        </ClickableCard>

        <ClickableCard variant="GREY" className='bg-gray-100 rounded-md p-5'>
          <div className='flex flex-col gap-4'>
            <div className="text-gray-900 font-bold">Adjustment due to background noise</div>

            <div>
              <div className="py-2 border-b border-gray-200 border-dashed items-baseline gap-2 flex justify-between">
                <div className="flex-col gap-1 flex">
                  <div className="text-gray-900 text-xs font-semibold">Background noise level</div>
                </div>
                <div className="text-xs font-semibold text-nowrap">{backgroundNoiseLevelDba} dB(A)</div>
              </div>
              <div className="py-2 border-b border-gray-200 border-dashed items-baseline gap-2 flex justify-between">
                <div className="flex-col gap-1 flex">
                  <div className="text-gray-900 text-xs font-semibold">Difference</div>
                  <div className="text-xs font-semibold">Difference between background noise level and heat pump sound
                    pressure level
                  </div>
                </div>
                <div className="text-xs font-semibold text-nowrap">{differentialDba} dB(A)</div>
              </div>
              <div className="py-2 border-b border-gray-200 border-dashed items-baseline gap-2 flex justify-between">
                <div className="flex-col gap-1 flex">
                  <div className="text-gray-900 text-xs font-semibold">Adjustment</div>
                </div>
                <div className="text-xs font-semibold text-nowrap">{correctionDB} dB(A)</div>
              </div>
              <div className="py-2 gap-2 flex justify-between">
                <div className="text-gray-900 text-xs font-bold">Max of sound pressure level and background noise</div>
                <div className="text-gray-900 text-xs font-bold text-nowrap">{finalResultDba} dB(A)</div>
              </div>
            </div>
          </div>
        </ClickableCard>
      </div>

      <ClickableCard variant="GREY" className='bg-gray-100 rounded-md p-5'>
        <div className='flex flex-col gap-4'>
          <div className='flex flex-row justify-between'>
            <div className="text-gray-900 font-bold">Result</div>
            <Badge
              color={hlrContext!.soundAssessment === 0 ? 'YELLOW' : hlrContext!.soundAssessment <= 42 ? 'GREEN' : 'RED'}
              text={hlrContext!.soundAssessment === 0 ? 'Incomplete' : hlrContext!.soundAssessment <= 42 ? 'Pass' : 'Fail'}/>
          </div>

          <div>
            <div className="py-2 border-b border-gray-200 border-dashed items-baseline gap-2 flex justify-between">
              <div className="flex-col gap-1 flex">
                <div className="text-gray-900 text-xs font-semibold">Maximum allowed value</div>
              </div>
              <div className="text-xs font-semibold text-nowrap">42 dB(A)</div>
            </div>
            <div className="py-2 gap-2 flex justify-between">
              <div className="text-gray-900 text-xs font-bold">Final result at assessment position</div>
              <div className="text-gray-900 text-xs font-bold text-nowrap">{finalResultDba} dB(A)</div>
            </div>
          </div>
        </div>
      </ClickableCard>

    </div>
  </div>
}
