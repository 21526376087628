import { findNearestPoint } from '../../pages/heat_loss/constants'

export type DesignConditions = {
  designTempDefaultC: number
  degreeDays: number
  groundTempC: number
}

export const getDesignConditions = (postcodeLocation: google.maps.LatLngLiteral | undefined, altitudeM: number | undefined, exposedLocation: boolean): DesignConditions => {
  const correctionFactorExposedLocation = exposedLocation ? 1 : 0

  const designConditionsCIBSE = getDesignConditionsCIBSE(postcodeLocation, altitudeM)
  // const designConditionsDataMCS = getDesignConditionsMCS(postcode)
  // const designConditionsToUse = designConditionsDataMCS || designConditionsCIBSE
  const designConditionsToUse = designConditionsCIBSE // just use CIBSE for now. When hear back from MCS decide which to use

  // HeatPunk use MCS value, heat engineer use CIBSE value but automatically get altitude and adjust for that
  return { ...designConditionsToUse, designTempDefaultC: Number((designConditionsToUse.designTempDefaultC - correctionFactorExposedLocation).toFixed(2)) }
}

export const getDesignConditionsMCS = (postcode: string): DesignConditions | undefined => {
  const postcodeStart = postcode.match(/^[A-Z]+/)?.[0] // get start of postcode (before numbers)
  const designConditionsData = DESIGN_CONDITIONS_MCS.find(x => postcodeStart === x.postcode_start)
  if (designConditionsData) {
    return {
      designTempDefaultC: designConditionsData.design_outdoor_temperature_c,
      degreeDays: designConditionsData.degree_days,
      groundTempC: designConditionsData.mean_ground_temp
    }
  }

  console.log(`Could not find design conditions for postcode ${postcode}`)
  return undefined
}

export const getDesignConditionsCIBSE = (postcodeLocation: google.maps.LatLngLiteral | undefined, altitudeM: number | undefined): DesignConditions => {
  const nearestDesignTempLocation = postcodeLocation && findNearestPoint(postcodeLocation, DESIGN_TEMPERATURES_CIBSE.map(x => x.location))
  const designTemperatureCibse = nearestDesignTempLocation ? DESIGN_TEMPERATURES_CIBSE.find(x => x.location.lat === nearestDesignTempLocation.lat && x.location.lng === nearestDesignTempLocation.lng) : undefined
  // CIBSE  Guide A Environmental Design Guide Section 2.4: “These design temperatures should be reduced by 0.6C for every complete 100m by which the altitude of the design site exceeds that of the recording station indicated.”
  // Specifically states "exceeds" so don't correct if altitude is lower than the weather station
  const correctionMultiples = altitudeM && designTemperatureCibse ? (altitudeM - designTemperatureCibse.altitude) > 0 ? Math.floor((altitudeM - designTemperatureCibse.altitude) / 100) : 0 : 0
  const alitudeCorrectionC = Number((-0.6 * correctionMultiples).toFixed(1)) // get floating point inaccuracies here, so round them off
  const designOutdoorTempC = nearestDesignTempLocation ? designTemperatureCibse!.temperature_99_th_percentile + alitudeCorrectionC : -3

  const nearestDegreeDaysLocation = postcodeLocation && findNearestPoint(postcodeLocation, DEGREE_DAYS_REGIONS.map(x => x.location))
  const nearestDegreeDaysData = nearestDegreeDaysLocation ? DEGREE_DAYS_REGIONS.find(x => x.location.lat === nearestDegreeDaysLocation.lat && x.location.lng === nearestDegreeDaysLocation.lng) : undefined
  const degreeDays = nearestDegreeDaysLocation ? nearestDegreeDaysData?.degree_days ?? 2255 : 2255 // Use Gatwick if we cannot find location (rare).
  const groundTempC = nearestDegreeDaysLocation ? nearestDegreeDaysData?.mean_ground_temp ?? 10.2 : 10.2

  return {
    designTempDefaultC: designOutdoorTempC,
    degreeDays,
    groundTempC
  }
}

export type DesignConditionsMCS = {
  uuid: string
  postcode_start: string
  region: string
  degree_days: number
  mean_ground_temp: number
  design_outdoor_temperature_c: number
}

export const DESIGN_CONDITIONS_MCS: DesignConditionsMCS[] = [
  { uuid: ' 918ff4b1-772a-18d1-1527-728013e3e478', postcode_start: 'DG', region: 'Borders (Boulmer)', degree_days: 2483, mean_ground_temp: 9, design_outdoor_temperature_c: -3.8 },
  { uuid: ' 92f8763c-a76a-e92b-1794-319fc8194d09', postcode_start: 'TD', region: 'Borders (Boulmer)', degree_days: 2483, mean_ground_temp: 9, design_outdoor_temperature_c: -3.8 },
  { uuid: ' 4bece42e-b140-3e0f-8112-d891763194d5', postcode_start: 'CO', region: 'E Anglia (Honington)', degree_days: 2254, mean_ground_temp: 10, design_outdoor_temperature_c: -2.3 },
  { uuid: ' fa9383d2-0df4-5509-99f0-1ffb2733b0b1', postcode_start: 'IP', region: 'E Anglia (Honington)', degree_days: 2254, mean_ground_temp: 10, design_outdoor_temperature_c: -2.3 },
  { uuid: ' c2b9cb4b-acc6-a515-f364-ada0b10ac4dc', postcode_start: 'NG', region: 'E Anglia (Honington)', degree_days: 2254, mean_ground_temp: 10, design_outdoor_temperature_c: -3.2 },
  { uuid: ' 9a2c0a07-cd67-1aa5-9cf7-ec823ca69747', postcode_start: 'NR', region: 'E Anglia (Honington)', degree_days: 2254, mean_ground_temp: 10, design_outdoor_temperature_c: -2.4 },
  { uuid: ' 9ae798c2-ab6f-07d9-35c1-500e91bd9b06', postcode_start: 'PE', region: 'E Anglia (Honington)', degree_days: 2254, mean_ground_temp: 10, design_outdoor_temperature_c: -3 },
  { uuid: ' 3c55cea2-a280-20d4-79d4-35ab89ea9a64', postcode_start: 'BD', region: 'E Pennines (Finningley)', degree_days: 2307, mean_ground_temp: 10, design_outdoor_temperature_c: -2.5 },
  { uuid: ' 4235224d-cb6e-2522-19fa-3ab37e1c85e1', postcode_start: 'DN', region: 'E Pennines (Finningley)', degree_days: 2307, mean_ground_temp: 10, design_outdoor_temperature_c: -3.4 },
  { uuid: ' 4bb61c65-39eb-985e-f1e8-31667f3e9e2c', postcode_start: 'HD', region: 'E Pennines (Finningley)', degree_days: 2307, mean_ground_temp: 10, design_outdoor_temperature_c: -3.5 },
  { uuid: ' 712f8529-2f49-c4d0-f0e5-a09d3083d0da', postcode_start: 'HG', region: 'E Pennines (Finningley)', degree_days: 2307, mean_ground_temp: 10, design_outdoor_temperature_c: -3.5 },
  { uuid: ' 4031fe44-5aa1-bed9-82cf-735c993efc47', postcode_start: 'HU', region: 'E Pennines (Finningley)', degree_days: 2307, mean_ground_temp: 10, design_outdoor_temperature_c: -3 },
  { uuid: ' 78a58769-6ab8-e1db-9e1a-c97555a08ee9', postcode_start: 'LN', region: 'E Pennines (Finningley)', degree_days: 2307, mean_ground_temp: 10, design_outdoor_temperature_c: -3 },
  { uuid: ' 087dc586-c5b3-4523-62df-1ab0980b4683', postcode_start: 'LS', region: 'E Pennines (Finningley)', degree_days: 2307, mean_ground_temp: 10, design_outdoor_temperature_c: -2.5 },
  { uuid: ' 2681005d-db6f-3fca-d2fc-fc221bf2f390', postcode_start: 'WF', region: 'E Pennines (Finningley)', degree_days: 2307, mean_ground_temp: 10, design_outdoor_temperature_c: -2.5 },
  { uuid: ' 085d5826-11d1-6b40-6377-bde7420f4e0a', postcode_start: 'YO', region: 'E Pennines (Finningley)', degree_days: 2307, mean_ground_temp: 10, design_outdoor_temperature_c: -3.7 },
  { uuid: ' 0091b5cd-2064-11d7-2e9f-d75ec4b06545', postcode_start: 'DD', region: 'E Scotland (Leuchars)', degree_days: 2577, mean_ground_temp: 8.8, design_outdoor_temperature_c: -3.8 },
  { uuid: ' c04ec782-7e57-4ea5-08af-9f10d1d58377', postcode_start: 'EH', region: 'E Scotland (Leuchars)', degree_days: 2577, mean_ground_temp: 8.8, design_outdoor_temperature_c: -3.4 },
  { uuid: ' be71f1ae-6c59-079e-cd32-33d04b3e3861', postcode_start: 'FK', region: 'E Scotland (Leuchars)', degree_days: 2577, mean_ground_temp: 8.8, design_outdoor_temperature_c: -3.7 },
  { uuid: ' 2ba696aa-b7a7-efaa-a585-dec347fc1ce0', postcode_start: 'KY', region: 'E Scotland (Leuchars)', degree_days: 2577, mean_ground_temp: 8.8, design_outdoor_temperature_c: -3.4 },
  { uuid: ' 6351c256-6ebd-a88d-ff98-d5687e102c86', postcode_start: 'B', region: 'Midland (Elmdon)', degree_days: 2425, mean_ground_temp: 9.8, design_outdoor_temperature_c: -3.4 },
  { uuid: ' 4279bc7b-3410-6728-2175-12ae0e6b7aa8', postcode_start: 'CV', region: 'Midland (Elmdon)', degree_days: 2425, mean_ground_temp: 9.8, design_outdoor_temperature_c: -3.4 },
  { uuid: ' 461e9f6e-4e2c-25ec-f2c6-b039ce432849', postcode_start: 'DY', region: 'Midland (Elmdon)', degree_days: 2425, mean_ground_temp: 9.8, design_outdoor_temperature_c: -3.4 },
  { uuid: ' be73350d-a443-b8e6-ce22-0ad6e1acd9ae', postcode_start: 'GL', region: 'Midland (Elmdon)', degree_days: 2425, mean_ground_temp: 9.8, design_outdoor_temperature_c: -3.3 },
  { uuid: ' b320c64e-246f-b9af-cf91-160a912c3e10', postcode_start: 'HR', region: 'Midland (Elmdon)', degree_days: 2425, mean_ground_temp: 9.8, design_outdoor_temperature_c: -2 },
  { uuid: ' 51e3993e-290f-090a-d9e1-65aec2cfb6c1', postcode_start: 'LE', region: 'Midland (Elmdon)', degree_days: 2425, mean_ground_temp: 9.8, design_outdoor_temperature_c: -3 },
  { uuid: ' 727fc62b-3af8-13e7-ac0f-f653058b3227', postcode_start: 'MK', region: 'Midland (Elmdon)', degree_days: 2425, mean_ground_temp: 9.8, design_outdoor_temperature_c: -3.4 },
  { uuid: ' 95cc175a-0264-68ee-3ee9-a34c0de5b3e6', postcode_start: 'NN', region: 'Midland (Elmdon)', degree_days: 2425, mean_ground_temp: 9.8, design_outdoor_temperature_c: -3 },
  { uuid: ' 27f48060-2cd0-e4a7-9f2d-85c558b9213f', postcode_start: 'NP', region: 'Midland (Elmdon)', degree_days: 2425, mean_ground_temp: 9.8, design_outdoor_temperature_c: -1.6 },
  { uuid: ' 5bb00041-729b-54bf-dc3d-1373152779e8', postcode_start: 'OX', region: 'Midland (Elmdon)', degree_days: 2425, mean_ground_temp: 9.8, design_outdoor_temperature_c: -2.4 },
  { uuid: ' a4ab791c-09cb-41bf-cd4b-eeac99721d3f', postcode_start: 'SN', region: 'Midland (Elmdon)', degree_days: 2425, mean_ground_temp: 9.8, design_outdoor_temperature_c: -2.2 },
  { uuid: ' 8961f35f-16cd-2f03-4791-d8807dbc69c0', postcode_start: 'TF', region: 'Midland (Elmdon)', degree_days: 2425, mean_ground_temp: 9.8, design_outdoor_temperature_c: -3.4 },
  { uuid: ' 938ec8e9-47aa-bf37-cd35-cc0ce7c9b77f', postcode_start: 'WR', region: 'Midland (Elmdon)', degree_days: 2425, mean_ground_temp: 9.8, design_outdoor_temperature_c: -3.3 },
  { uuid: ' 6b8dc172-eaf6-94c2-062e-5e31862603f3', postcode_start: 'WS', region: 'Midland (Elmdon)', degree_days: 2425, mean_ground_temp: 9.8, design_outdoor_temperature_c: -3.4 },
  { uuid: ' 8008ff27-1c3c-3c46-9f54-d4bd282047cb', postcode_start: 'WV', region: 'Midland (Elmdon)', degree_days: 2425, mean_ground_temp: 9.8, design_outdoor_temperature_c: -3.4 },
  { uuid: ' e54a1452-a2e6-b818-c38a-37c8db0b0dd3', postcode_start: 'AB', region: 'NE Scotland (Dyce)', degree_days: 2668, mean_ground_temp: 8.5, design_outdoor_temperature_c: -4.2 },
  { uuid: ' d4687f92-a9c6-c1b4-7846-114e104b5100', postcode_start: 'IV', region: 'NE Scotland (Dyce)', degree_days: 2668, mean_ground_temp: 8.5, design_outdoor_temperature_c: -4 },
  { uuid: ' 3c9ec466-c2b2-ae4d-362a-5f26682250a1', postcode_start: 'KW', region: 'NE Scotland (Dyce)', degree_days: 2668, mean_ground_temp: 8.5, design_outdoor_temperature_c: -4.2 },
  { uuid: ' aaf7ca69-b3d4-a458-73ce-41454f2b9f6e', postcode_start: 'PH', region: 'NE Scotland (Dyce)', degree_days: 2668, mean_ground_temp: 8.5, design_outdoor_temperature_c: -3.8 },
  { uuid: ' ef91cd05-2110-4e95-126a-21d801dc4974', postcode_start: 'ZE', region: 'NE Scotland (Dyce)', degree_days: 2668, mean_ground_temp: 8.5, design_outdoor_temperature_c: -3 },
  { uuid: ' ac17e652-7a39-6812-7074-700a9375c9a4', postcode_start: 'DH', region: 'North-eastern (Leeming)', degree_days: 2370, mean_ground_temp: 9.4, design_outdoor_temperature_c: -3.7 },
  { uuid: ' 01de3974-926c-7425-3925-971e18b1975a', postcode_start: 'NE', region: 'North-eastern (Leeming)', degree_days: 2370, mean_ground_temp: 9.4, design_outdoor_temperature_c: -3.4 },
  { uuid: ' 7ff06144-cc59-d0f5-f793-20de060f033a', postcode_start: 'SR', region: 'North-eastern (Leeming)', degree_days: 2370, mean_ground_temp: 9.4, design_outdoor_temperature_c: -3.7 },
  { uuid: ' b120f425-088b-fa40-1d8a-f771de6c4fff', postcode_start: 'TS', region: 'North-eastern (Leeming)', degree_days: 2370, mean_ground_temp: 9.4, design_outdoor_temperature_c: -2.9 },
  { uuid: ' f7841628-9016-4191-89d8-3e4ef8179c00', postcode_start: 'CA', region: 'North-western (Carlisle)', degree_days: 2388, mean_ground_temp: 9.4, design_outdoor_temperature_c: -3.7 },
  { uuid: ' d6b8ff4e-6ead-3db6-3bab-5957dad5fa23', postcode_start: 'DL', region: 'North-western (Carlisle)', degree_days: 2388, mean_ground_temp: 9.4, design_outdoor_temperature_c: -3.7 },
  { uuid: ' 20e10ecb-6560-8bc6-26f4-df2af4a4efd3', postcode_start: 'FY', region: 'North-western (Carlisle)', degree_days: 2388, mean_ground_temp: 9.4, design_outdoor_temperature_c: -2.2 },
  { uuid: ' 2cbc9de5-09b5-dc84-8e44-cc7f644c3ac6', postcode_start: 'LA', region: 'North-western (Carlisle)', degree_days: 2388, mean_ground_temp: 9.4, design_outdoor_temperature_c: -2.2 },
  { uuid: ' ee2759f3-f9b4-e305-3a0b-89aaaec253f6', postcode_start: 'PR', region: 'North-western (Carlisle)', degree_days: 2388, mean_ground_temp: 9.4, design_outdoor_temperature_c: -3.2 },
  { uuid: ' 8e4a9d9c-b35e-ceb3-7edd-b796e42c68bf', postcode_start: 'BT', region: 'Northern Ireland (Belfast)', degree_days: 2360, mean_ground_temp: 9.4, design_outdoor_temperature_c: -1.2 },
  { uuid: ' 6b013530-c5dd-8a1e-abe6-8bd46eed0c3e', postcode_start: 'HS', region: 'NW Scotland (Stornoway)', degree_days: 1800, mean_ground_temp: 8.6, design_outdoor_temperature_c: -1 },
  { uuid: ' 452144e3-1624-2ecb-5533-d92eb8c6b0af', postcode_start: 'BA', region: 'Severn Valley (Filton)', degree_days: 1835, mean_ground_temp: 10.6, design_outdoor_temperature_c: -1.7 },
  { uuid: ' 91637784-ada3-021a-cecf-89a1dc00c70e', postcode_start: 'BS', region: 'Severn Valley (Filton)', degree_days: 1835, mean_ground_temp: 10.6, design_outdoor_temperature_c: -1.7 },
  { uuid: ' c30080cd-dd53-39c5-5fec-aa800fad876f', postcode_start: 'CF', region: 'Severn Valley (Filton)', degree_days: 1835, mean_ground_temp: 10.6, design_outdoor_temperature_c: -1.6 },
  { uuid: ' 198c4049-41dc-7b05-81cb-e3b9a102ccb0', postcode_start: 'TA', region: 'Severn Valley (Filton)', degree_days: 1835, mean_ground_temp: 10.6, design_outdoor_temperature_c: -2.1 },
  { uuid: ' 0a1569dd-de68-381c-35db-cf0e2727f883', postcode_start: 'BR', region: 'South-eastern (Gatwick)', degree_days: 2255, mean_ground_temp: 10.2, design_outdoor_temperature_c: -3.2 },
  { uuid: ' 8026d40d-26ea-393b-79db-f7d6d38dbfd1', postcode_start: 'CT', region: 'South-eastern (Gatwick)', degree_days: 2255, mean_ground_temp: 10.2, design_outdoor_temperature_c: -3.2 },
  { uuid: ' 71ad21b3-9366-63cf-727c-b051d1749a18', postcode_start: 'DA', region: 'South-eastern (Gatwick)', degree_days: 2255, mean_ground_temp: 10.2, design_outdoor_temperature_c: -3.2 },
  { uuid: ' e8022662-dc79-f290-67a1-c96458d4925b', postcode_start: 'EN', region: 'South-eastern (Gatwick)', degree_days: 2255, mean_ground_temp: 10.2, design_outdoor_temperature_c: -2.1 },
  { uuid: ' 95c6e32b-81a2-df18-143f-cea20772c52d', postcode_start: 'GY', region: 'South-eastern (Gatwick)', degree_days: 1800, mean_ground_temp: 10.2, design_outdoor_temperature_c: -1 },
  { uuid: ' 4b208a61-3328-8a7a-5b5a-31a8329667e4', postcode_start: 'JE', region: 'South-eastern (Gatwick)', degree_days: 1800, mean_ground_temp: 10.2, design_outdoor_temperature_c: -1 },
  { uuid: ' 90f797db-ef03-ae1b-2b43-f88e0b1541b4', postcode_start: 'TN', region: 'South-eastern (Gatwick)', degree_days: 2255, mean_ground_temp: 10.2, design_outdoor_temperature_c: -3.2 },
  { uuid: ' 4965a1e6-bfde-ea48-ca62-8c214586dd64', postcode_start: 'EX', region: 'South-western (Plymouth)', degree_days: 1858, mean_ground_temp: 11, design_outdoor_temperature_c: -1.5 },
  { uuid: ' fa8fad90-f82c-910d-256a-c10d070c9932', postcode_start: 'PL', region: 'South-western (Plymouth)', degree_days: 1858, mean_ground_temp: 11, design_outdoor_temperature_c: -0.2 },
  { uuid: ' 4c3523f1-2ff9-06d1-e404-c3dc2913269e', postcode_start: 'TQ', region: 'South-western (Plymouth)', degree_days: 1858, mean_ground_temp: 11, design_outdoor_temperature_c: -1.3 },
  { uuid: ' ed45515e-98ac-2262-c842-080ed9e6c8ca', postcode_start: 'TR', region: 'South-western (Plymouth)', degree_days: 1858, mean_ground_temp: 11, design_outdoor_temperature_c: -1.4 },
  { uuid: ' d2254e57-9f18-7925-74a6-a9032557ad80', postcode_start: 'BH', region: 'Southern (Hurn)', degree_days: 2224, mean_ground_temp: 10.4, design_outdoor_temperature_c: -1.5 },
  { uuid: ' febc6082-b611-dc74-efae-bd5f798f4dfa', postcode_start: 'BN', region: 'Southern (Hurn)', degree_days: 2224, mean_ground_temp: 10.4, design_outdoor_temperature_c: -2.1 },
  { uuid: ' 57966608-769f-3f69-878c-300ce24b9655', postcode_start: 'CR', region: 'Southern (Hurn)', degree_days: 2224, mean_ground_temp: 10.4, design_outdoor_temperature_c: -2 },
  { uuid: ' 599b506e-34e4-7db1-61a3-24efe1ae4964', postcode_start: 'DT', region: 'Southern (Hurn)', degree_days: 2224, mean_ground_temp: 10.4, design_outdoor_temperature_c: -1.7 },
  { uuid: ' 1efe1b8e-d12b-690d-b9c8-de87bdb6452e', postcode_start: 'PO', region: 'Southern (Hurn)', degree_days: 2224, mean_ground_temp: 10.4, design_outdoor_temperature_c: -1.8 },
  { uuid: ' bced210f-6348-8e5e-2cd4-24547ef86b16', postcode_start: 'SO', region: 'Southern (Hurn)', degree_days: 2224, mean_ground_temp: 10.4, design_outdoor_temperature_c: -1.8 },
  { uuid: ' cfdf72b5-c410-c3a8-a8a3-32e906140061', postcode_start: 'SP', region: 'Southern (Hurn)', degree_days: 2224, mean_ground_temp: 10.4, design_outdoor_temperature_c: -1.8 },
  { uuid: ' 4ee24ac0-5ca3-faf4-9221-8bd859e15067', postcode_start: 'AL', region: 'Thames Valley (Heathrow)', degree_days: 2033, mean_ground_temp: 11.3, design_outdoor_temperature_c: -2 },
  { uuid: ' 0ce97997-c828-210f-34d2-035407aae77d', postcode_start: 'CB', region: 'Thames Valley (Heathrow)', degree_days: 2033, mean_ground_temp: 11.3, design_outdoor_temperature_c: -2.5 },
  { uuid: ' eb174dc0-98c1-3019-42d0-0d43da748111', postcode_start: 'CM', region: 'Thames Valley (Heathrow)', degree_days: 2033, mean_ground_temp: 11.3, design_outdoor_temperature_c: -2.3 },
  { uuid: ' 63c06784-4380-e455-3be2-c371bce14968', postcode_start: 'E', region: 'Thames Valley (Heathrow)', degree_days: 2033, mean_ground_temp: 11.3, design_outdoor_temperature_c: -1.8 },
  { uuid: ' 3923cd59-340d-bcd4-4652-e07c201022a3', postcode_start: 'EC', region: 'Thames Valley (Heathrow)', degree_days: 2033, mean_ground_temp: 11.3, design_outdoor_temperature_c: -1.8 },
  { uuid: ' ba7193dd-d9c9-3281-f603-a415ea193362', postcode_start: 'GU', region: 'Thames Valley (Heathrow)', degree_days: 2033, mean_ground_temp: 11.3, design_outdoor_temperature_c: -2.2 },
  { uuid: ' 10936235-df2f-f4c2-416c-0191e1bfda66', postcode_start: 'HA', region: 'Thames Valley (Heathrow)', degree_days: 2033, mean_ground_temp: 11.3, design_outdoor_temperature_c: -1.8 },
  { uuid: ' 4a5a58db-e912-774e-0f4b-ddbd4481c9a0', postcode_start: 'HP', region: 'Thames Valley (Heathrow)', degree_days: 2033, mean_ground_temp: 11.3, design_outdoor_temperature_c: -2 },
  { uuid: ' 4c566087-96c1-25a1-e9e3-9d3e2f9509cd', postcode_start: 'IG', region: 'Thames Valley (Heathrow)', degree_days: 2033, mean_ground_temp: 11.3, design_outdoor_temperature_c: -1.8 },
  { uuid: ' 90b839dd-385c-39dd-cc3f-32bf9270baad', postcode_start: 'KT', region: 'Thames Valley (Heathrow)', degree_days: 2033, mean_ground_temp: 11.3, design_outdoor_temperature_c: -1.8 },
  { uuid: ' cbcf7927-8b61-496f-08d2-58c0d490782a', postcode_start: 'LU', region: 'Thames Valley (Heathrow)', degree_days: 2033, mean_ground_temp: 11.3, design_outdoor_temperature_c: -2.4 },
  { uuid: ' 5f73cfa6-d528-94b8-41ce-2b0edd4a11e2', postcode_start: 'ME', region: 'Thames Valley (Heathrow)', degree_days: 2033, mean_ground_temp: 11.3, design_outdoor_temperature_c: -1.8 },
  { uuid: ' 3ad40139-8db7-bb37-8674-2dc1df7bcbe8', postcode_start: 'N', region: 'Thames Valley (Heathrow)', degree_days: 2033, mean_ground_temp: 11.3, design_outdoor_temperature_c: -1.8 },
  { uuid: ' 48268f5c-6cfa-2281-bf76-162c97698a68', postcode_start: 'NW', region: 'Thames Valley (Heathrow)', degree_days: 2033, mean_ground_temp: 11.3, design_outdoor_temperature_c: -1.8 },
  { uuid: ' 1cbcaacb-3865-6cd7-811e-2cf92a4872ba', postcode_start: 'RG', region: 'Thames Valley (Heathrow)', degree_days: 2033, mean_ground_temp: 11.3, design_outdoor_temperature_c: -2.2 },
  { uuid: ' 51616515-d93b-81ca-363b-c278a75660bb', postcode_start: 'RH', region: 'Thames Valley (Heathrow)', degree_days: 2033, mean_ground_temp: 11.3, design_outdoor_temperature_c: -1.9 },
  { uuid: ' 450ca7c0-0808-9ac4-b418-cd254799f0f7', postcode_start: 'RM', region: 'Thames Valley (Heathrow)', degree_days: 2033, mean_ground_temp: 11.3, design_outdoor_temperature_c: -1.8 },
  { uuid: ' 746f5c65-c0c9-76b6-429d-486f8c3f8486', postcode_start: 'SE', region: 'Thames Valley (Heathrow)', degree_days: 2033, mean_ground_temp: 11.3, design_outdoor_temperature_c: -1.8 },
  { uuid: ' 2d2e617f-a1eb-415f-d6a0-8ac2d1e23615', postcode_start: 'SG', region: 'Thames Valley (Heathrow)', degree_days: 2033, mean_ground_temp: 11.3, design_outdoor_temperature_c: -2 },
  { uuid: ' 74aaec50-d100-d708-0a09-6a889f81f29f', postcode_start: 'SL', region: 'Thames Valley (Heathrow)', degree_days: 2033, mean_ground_temp: 11.3, design_outdoor_temperature_c: -2 },
  { uuid: ' b21ff1f5-702a-3372-842d-9832e5987dee', postcode_start: 'SM', region: 'Thames Valley (Heathrow)', degree_days: 2033, mean_ground_temp: 11.3, design_outdoor_temperature_c: -1.8 },
  { uuid: ' 43c73066-48b4-1858-35b4-6c2a8b5200ce', postcode_start: 'SS', region: 'Thames Valley (Heathrow)', degree_days: 2033, mean_ground_temp: 11.3, design_outdoor_temperature_c: -2.3 },
  { uuid: ' 3f97c55e-9ac7-4c77-2686-989caaedff2d', postcode_start: 'SW', region: 'Thames Valley (Heathrow)', degree_days: 2033, mean_ground_temp: 11.3, design_outdoor_temperature_c: -1.8 },
  { uuid: ' b53d5aa4-12aa-c46e-a8f6-70f997fa5db2', postcode_start: 'TW', region: 'Thames Valley (Heathrow)', degree_days: 2203, mean_ground_temp: 11.3, design_outdoor_temperature_c: -1.8 },
  { uuid: ' 123b32f7-a4cb-b141-efc3-4041030cbe38', postcode_start: 'UB', region: 'Thames Valley (Heathrow)', degree_days: 2203, mean_ground_temp: 11.3, design_outdoor_temperature_c: -1.8 },
  { uuid: ' 0c2f2872-28ba-880a-ddb2-2738897ba0b9', postcode_start: 'W', region: 'Thames Valley (Heathrow)', degree_days: 2203, mean_ground_temp: 11.3, design_outdoor_temperature_c: -1.8 },
  { uuid: ' b6c03127-746b-6870-7595-31daa5afb0f4', postcode_start: 'WC', region: 'Thames Valley (Heathrow)', degree_days: 2203, mean_ground_temp: 11.3, design_outdoor_temperature_c: -1.8 },
  { uuid: ' 868dcb4a-f219-6a36-1469-994662e86a8e', postcode_start: 'WD', region: 'Thames Valley (Heathrow)', degree_days: 2203, mean_ground_temp: 11.3, design_outdoor_temperature_c: -2 },
  { uuid: ' 477d2d95-96ad-4e82-4af8-053da5e24ac6', postcode_start: 'BB', region: 'W Pennines (Ringway)', degree_days: 2228, mean_ground_temp: 10, design_outdoor_temperature_c: -2.2 },
  { uuid: ' 9bfd6137-9c33-9bc5-2576-8c9f46a46ec8', postcode_start: 'BL', region: 'W Pennines (Ringway)', degree_days: 2228, mean_ground_temp: 10, design_outdoor_temperature_c: -2.2 },
  { uuid: ' e7d59d64-3813-5108-fdeb-af523953635e', postcode_start: 'CH', region: 'W Pennines (Ringway)', degree_days: 2228, mean_ground_temp: 10, design_outdoor_temperature_c: -2.2 },
  { uuid: ' dbdb4aad-b2bf-c70a-e3dc-7cfed6d26508', postcode_start: 'CW', region: 'W Pennines (Ringway)', degree_days: 2228, mean_ground_temp: 10, design_outdoor_temperature_c: -2.7 },
  { uuid: ' 8023a0b1-ed3c-d2d7-ee95-390e5628b0a8', postcode_start: 'DE', region: 'W Pennines (Ringway)', degree_days: 2228, mean_ground_temp: 10, design_outdoor_temperature_c: -2.2 },
  { uuid: ' ec9088ce-6bdd-bb2c-0fbc-e5a843f1d80c', postcode_start: 'HX', region: 'W Pennines (Ringway)', degree_days: 2228, mean_ground_temp: 10, design_outdoor_temperature_c: -2.5 },
  { uuid: ' bceae042-a164-c2e0-8152-b1f4f47f52a4', postcode_start: 'IM', region: 'W Pennines (Ringway)', degree_days: 2228, mean_ground_temp: 10, design_outdoor_temperature_c: -2.5 },
  { uuid: ' 6fc0ac61-7e66-6333-fc8b-3915bafae248', postcode_start: 'L', region: 'W Pennines (Ringway)', degree_days: 2228, mean_ground_temp: 10, design_outdoor_temperature_c: -2.4 },
  { uuid: ' 0761d096-ee83-c68e-aafd-d4790b11aa4f', postcode_start: 'LL', region: 'W Pennines (Ringway)', degree_days: 2228, mean_ground_temp: 10, design_outdoor_temperature_c: -3 },
  { uuid: ' 1b095621-1243-dd03-fd63-7a12867c68dd', postcode_start: 'M', region: 'W Pennines (Ringway)', degree_days: 2228, mean_ground_temp: 10, design_outdoor_temperature_c: -2.2 },
  { uuid: ' e82163d0-a426-6098-7fb7-f74626799959', postcode_start: 'OL', region: 'W Pennines (Ringway)', degree_days: 2228, mean_ground_temp: 10, design_outdoor_temperature_c: -3.2 },
  { uuid: ' d43911a0-e7bc-a592-c8e5-ab9bbc214acc', postcode_start: 'S', region: 'W Pennines (Ringway)', degree_days: 2228, mean_ground_temp: 10, design_outdoor_temperature_c: -2.8 },
  { uuid: ' 8522ad11-f882-3f45-fae7-b1797a2eee18', postcode_start: 'SK', region: 'W Pennines (Ringway)', degree_days: 2228, mean_ground_temp: 10, design_outdoor_temperature_c: -2.9 },
  { uuid: ' aa518f84-aab2-0edd-ec64-f3872c0510ab', postcode_start: 'ST', region: 'W Pennines (Ringway)', degree_days: 2228, mean_ground_temp: 10, design_outdoor_temperature_c: -3 },
  { uuid: ' fb0b031a-4c07-c3de-fd69-e7a15d5a8437', postcode_start: 'WA', region: 'W Pennines (Ringway)', degree_days: 2228, mean_ground_temp: 10, design_outdoor_temperature_c: -2.1 },
  { uuid: ' 5c81e3e1-9d1b-aa92-02c3-62f5d5273b25', postcode_start: 'WN', region: 'W Pennines (Ringway)', degree_days: 2228, mean_ground_temp: 10, design_outdoor_temperature_c: -2.1 },
  { uuid: ' 9914dd88-aa5d-65fc-c589-c640138fc238', postcode_start: 'G', region: 'W Scotland (Abbotsinch)', degree_days: 2494, mean_ground_temp: 9.1, design_outdoor_temperature_c: -3.9 },
  { uuid: ' 4925c23f-dd19-f43b-e5ab-2676333890c4', postcode_start: 'KA', region: 'W Scotland (Abbotsinch)', degree_days: 2494, mean_ground_temp: 9.1, design_outdoor_temperature_c: -3.7 },
  { uuid: ' bef62e74-48ce-6f9f-4079-fc62aad2baf3', postcode_start: 'ML', region: 'W Scotland (Abbotsinch)', degree_days: 2494, mean_ground_temp: 9.1, design_outdoor_temperature_c: -3.9 },
  { uuid: ' 99e8c3ec-adec-38f3-1ed7-68636470da2d', postcode_start: 'PA', region: 'W Scotland (Abbotsinch)', degree_days: 2494, mean_ground_temp: 9.1, design_outdoor_temperature_c: -4 },
  { uuid: ' f6c4bf0d-071c-7077-3415-d9a8626ed799', postcode_start: 'LD', region: 'Wales (Aberporth)', degree_days: 2161, mean_ground_temp: 9.9, design_outdoor_temperature_c: -2.9 },
  { uuid: ' 2626f6c8-21ed-8bcb-d0b1-5250784cbfa2', postcode_start: 'SA', region: 'Wales (Aberporth)', degree_days: 2161, mean_ground_temp: 9.9, design_outdoor_temperature_c: -1.6 },
  { uuid: ' 2f41d9ce-11f1-e7bd-4c30-e57aebeed1b0', postcode_start: 'SY', region: 'Wales (Aberporth)', degree_days: 2161, mean_ground_temp: 9.9, design_outdoor_temperature_c: -3.3 }
]

export type DesignTempCIBSE = {
  uuid: string
  name: string
  altitude: number
  temperature_99_th_percentile: number
  temperature_99_6th_percentile: number
  location: google.maps.LatLngLiteral
}

// CIBSE GUIDE A Table 2.5 2015 - Dry bulb values, altitude and location from Table 2.4
// Match values in MIS 3005 2021, but includes more values
// Initial values from MIS 3005 2019 were updated in latest version of MIS-3001:https://mcscertified.com/wp-content/uploads/2019/08/MIS-3005.pdf
export const DESIGN_TEMPERATURES_CIBSE: DesignTempCIBSE[] = [
  { uuid: '84c30bf7-394c-4b6d-b9bc-5fc63cb793cd', name: 'Belfast', altitude: 63, temperature_99_th_percentile: -1.5, temperature_99_6th_percentile: -3.2, location: { lat: 54.664, lng: -6.224 } },
  { uuid: '49cc6355-7984-46f9-8da5-c5bcf78b9da0', name: 'Birmingham', altitude: 160, temperature_99_th_percentile: -3.2, temperature_99_6th_percentile: -5.1, location: { lat: 52.47514, lng: -1.93374 } },
  { uuid: 'd9cfaec7-9447-40c4-9df2-15fccc863390', name: 'Cardiff', altitude: 49, temperature_99_th_percentile: -1.5, temperature_99_6th_percentile: -3.1, location: { lat: 51.4007, lng: -3.44477 } },
  { uuid: '2ee31c37-cd31-46ed-b98d-6c39579d5be5', name: 'Edinburgh', altitude: 57, temperature_99_th_percentile: -3.2, temperature_99_6th_percentile: -5.4, location: { lat: 55.9284, lng: -3.34294 } },
  { uuid: '29789384-f63f-4148-a7b5-d60796a77505', name: 'Glasgow', altitude: 59, temperature_99_th_percentile: -3.5, temperature_99_6th_percentile: -5.9, location: { lat: 55.9068, lng: -4.53122 } },
  { uuid: '79bce847-1a52-4929-a920-1d349fd1fb6e', name: 'Leeds', altitude: 8, temperature_99_th_percentile: -1.9, temperature_99_6th_percentile: -3.3, location: { lat: 53.8356, lng: -1.1973 } },
  { uuid: 'ccb57a7c-4894-4f1f-890f-899db8165cef', name: 'London', altitude: 43, temperature_99_th_percentile: -1.7, temperature_99_6th_percentile: -3, location: { lat: 51.521, lng: -0.11088 } },
  { uuid: '8beba536-6739-40cc-b602-68c38e02d8b2', name: 'Manchester', altitude: 69, temperature_99_th_percentile: -2.7, temperature_99_6th_percentile: -4.5, location: { lat: 53.3559, lng: -2.27949 } },
  { uuid: 'e743a0da-e350-489e-bc0c-003c3272c633', name: 'Newcastle', altitude: 33, temperature_99_th_percentile: -2.0, temperature_99_6th_percentile: -3.7, location: { lat: 54.2968, lng: -1.53145 } },
  { uuid: '88687192-9638-469e-a4df-2b2a27d56205', name: 'Norwich', altitude: 3, temperature_99_th_percentile: -3.1, temperature_99_6th_percentile: -4.6, location: { lat: 52.8729, lng: 0.14021 } },
  { uuid: '9c3e48bc-e8fd-4eac-a5c9-445835c1a1c3', name: 'Nottingham', altitude: 117, temperature_99_th_percentile: -2.4, temperature_99_6th_percentile: -3.9, location: { lat: 53.005, lng: -1.250 } },
  { uuid: '7802a553-6cda-4fa5-8843-d96dc445ef55', name: 'Plymouth', altitude: 87, temperature_99_th_percentile: -0.2, temperature_99_6th_percentile: -1.5, location: { lat: 50.2178, lng: -5.32656 } },
  { uuid: 'a130a575-f378-4ad3-9817-1a444d7d4702', name: 'Southampton', altitude: 4, temperature_99_th_percentile: -3.4, temperature_99_6th_percentile: -4.8, location: { lat: 50.8142, lng: -0.92098 } },
  { uuid: 'c1b45573-7b10-4eda-86fa-a6161bad4fda', name: 'Swindon', altitude: 210, temperature_99_th_percentile: -2.9, temperature_99_6th_percentile: -4.6, location: { lat: 51.8603, lng: -1.69148 } }
]

export type DegreeDayLocation = {
  uuid: string
  name: string
  degree_days: number
  mean_ground_temp: number
  location: google.maps.LatLngLiteral
}

// Degree day data from the MCS spreadsheet
// This comment used to say it was rom CIBSE Guide A (base temperature 15.5C) 1976-1995 but I can't find that data there
// Mean air temp (from MIS 2005 Appendix B)
// https://mcscertified.com/wp-content/uploads/2019/08/MIS-3005.pdf
// Same data as in the postcode table but with the coordinates so can use to get closest location where needed
export const DEGREE_DAYS_REGIONS: DegreeDayLocation[] = [
  { uuid: 'c3594abc-f011-44b9-9f95-67d567d24d03', name: 'Border (Boulmer)', degree_days: 2483, mean_ground_temp: 9, location: { lat: 55.422169, lng: -1.58249 } },
  { uuid: '9f95a99e-7d1b-4b3c-a38c-5623b29cb1a4', name: 'E Anglia (Honington)', degree_days: 2254, mean_ground_temp: 10.1, location: { lat: 52.3426, lng: 0.9436 } },
  { uuid: 'e0db341a-d77e-4988-8719-0b5e512c08e9', name: 'E Pennines (Finningley)', degree_days: 2307, mean_ground_temp: 10.0, location: { lat: 53.4827, lng: -0.99 } },
  { uuid: '18c52b72-7c7b-415e-8b53-48ccdbd5cb0c', name: 'E Scotland (Leuchars)', degree_days: 2577, mean_ground_temp: 8.8, location: { lat: 56.3783, lng: -2.877 } },
  { uuid: 'bcf26a48-399a-4794-8e1b-42f6f94ddc25', name: 'Midland (Elmdon)', degree_days: 2425, mean_ground_temp: 9.8, location: { lat: 52.4539, lng: -1.7422 } },
  { uuid: 'a2bcbf88-6f4c-43c1-ba88-692a8d890157', name: 'N Ireland (Aldergrove)', degree_days: 2360, mean_ground_temp: 9.4, location: { lat: 54.6524, lng: -6.2157 } },
  { uuid: '0d8ed773-1ad2-45a3-9ba7-5e001cf60e49', name: 'NE Scotland (Dyce)', degree_days: 2668, mean_ground_temp: 8.5, location: { lat: 57.2049, lng: -2.2145 } },
  { uuid: '0a8240ab-c568-4e1e-840d-d4b9a74a0c7e', name: 'North-eastern (Lemmington)', degree_days: 2370, mean_ground_temp: 9.4, location: { lat: 55.6181, lng: -1.6297 } },
  { uuid: 'e2e56db1-35c7-4a79-b819-3f2b53d6e9af', name: 'North-western (Carlisle)', degree_days: 2388, mean_ground_temp: 9.4, location: { lat: 54.8959, lng: -2.9266 } },
  { uuid: '9a8a7f68-2a0a-4646-b288-c6461a687bbb', name: 'NW Scotland (Stornoway)', degree_days: 2671, mean_ground_temp: 8.6, location: { lat: 58.2156, lng: -6.3083 } },
  { uuid: 'f8f26cc5-5de1-4a25-93cc-42d8de5d81d7', name: 'Severn Valley (Filton)', degree_days: 1835, mean_ground_temp: 10.6, location: { lat: 51.5188, lng: -2.5903 } },
  { uuid: '7d55a188-735a-4e5b-b5ff-1edfc49c8f62', name: 'South-eastern (Gatwick)', degree_days: 2255, mean_ground_temp: 10.2, location: { lat: 51.1537, lng: -0.1821 } },
  { uuid: '0d598769-3ab2-4d90-87a2-d796e2d8c3cc', name: 'Southern (Hurn)', degree_days: 2224, mean_ground_temp: 10.4, location: { lat: 50.7787, lng: -1.748 } },
  { uuid: '9e77395b-7292-41dc-b615-3a8b81b05117', name: 'South-western (Plymouth)', degree_days: 1858, mean_ground_temp: 11, location: { lat: 50.423, lng: -4.1426 } },
  { uuid: 'ca06a80c-6242-4b18-bf25-7c44061c4e27', name: 'Thames Valley (Heathrow)', degree_days: 2033, mean_ground_temp: 11.3, location: { lat: 51.47, lng: -0.4562 } },
  { uuid: '27bbbf6e-30dc-4ad9-9dcf-ebc8e26b22c9', name: 'W Pennines (Ringway)', degree_days: 2228, mean_ground_temp: 10, location: { lat: 53.3653, lng: -2.2747 } },
  { uuid: '05c17dd8-f28d-4a9a-9c56-9b0e0d5c20c5', name: 'W Scotland (Abbotsinch)', degree_days: 2494, mean_ground_temp: 9.1, location: { lat: 55.8642, lng: -4.43 } },
  { uuid: 'ee56cbe7-9d53-4989-9e24-7891f7e529c4', name: 'Wales (Aberporth)', degree_days: 2161, mean_ground_temp: 9.9, location: { lat: 52.1399, lng: -4.5708 } }
]
