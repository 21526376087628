import { apiUrl, client } from '../axios'
import { AuthSDK } from '../utils/auth_provider'
import { parseWithZod } from '../zod'
import { type InventoryPart, InventoryPartSchema } from './inventory'

export const getParts = async (companyUUID: string): Promise<InventoryPart[] | undefined> => {
  try {
    const result = await client.get<Array<Record<any, any>>>(`${apiUrl}teams/${companyUUID}/parts`,
      { headers: { 'x-auth-token': AuthSDK.getToken() } }
    )
    const parsedResult = result.data.map(x => parseWithZod<InventoryPart>(InventoryPartSchema, x))
    return parsedResult
  } catch (e: unknown) {
    console.error('Error getting parts', e)
    return undefined
  }
}

export const insertPart = async (part: InventoryPart, companyUUID: string): Promise<InventoryPart> => {
  const response = await client.post(
    `${apiUrl}teams/${companyUUID}/parts`,
    part,
    { headers: { 'x-auth-token': AuthSDK.getToken() } }
  )
  return response.data
}

export const updatePart = async (part: InventoryPart, companyUUID: string) => {
  await client.patch(
    `${apiUrl}teams/${companyUUID}/parts/${part.uuid}`,
    part,
    { headers: { 'x-auth-token': AuthSDK.getToken() } }
  )
}

export const deletePart = async (partUUID: string, companyUUID: string) => {
  await client.delete(
    `${apiUrl}teams/${companyUUID}/parts/${partUUID}`,
    { headers: { 'x-auth-token': AuthSDK.getToken() } }
  )
}
