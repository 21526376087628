import React, { useContext } from 'react'
import { Section } from '../../components/section'
import { Text } from '../../../../../components/content_display/text'
import { formatPrice } from '../../../../../code/format_price'
import { SummaryTable } from '../../../../../components/content_display/summary_table'
import { Button } from '../../../../../components/buttons/button'
import { type CompanyPublicInfo } from '../../../../../code/models/company'
import { DEFAULT_ESTIMATE_RAD_CHANGE_PERCENTAGE, type Estimate } from '../../../../../code/calculate_estimate'
import { type Lead } from '../../../../../code/models/lead'
import { Alert } from '../../../../../components/indicators_and_messaging/alert'
import { Badge } from '../../../../../components/indicators_and_messaging/badge'
import { CalculatedQuoteDefaultGroups, calculateQuote, quoteItemNameWithQuantity } from '../../../../../code/calculate_quote'
import { AdminContext } from '../../../admin_layout'
import { groupBy, sum } from 'lodash'
import { getApproxNumberOfRadiatorChanges } from '../../../../../code/models/estimated_rooms_and_radiators'
import { AlertTriangle, Edit } from 'lucide-react'

type Props = {
  lead: Lead
  estimate: Estimate
  navigateTo: (url: string) => void
  companyPublicInfo: CompanyPublicInfo
}

export const CostEstimate = ({ lead, estimate, navigateTo, companyPublicInfo }: Props) => {
  const adminContext = useContext(AdminContext)

  const [calculatedQuote] = calculateQuote({
    company: adminContext.data.company!,
    selectedHeatPump: estimate.heatPump,
    selectedHotWaterCylinder: estimate.hotWaterCylinder,
    defaultRadiatorChanges: getApproxNumberOfRadiatorChanges(lead, adminContext.data.company?.estimate_default_radiator_change_percentage ?? DEFAULT_ESTIMATE_RAD_CHANGE_PERCENTAGE),
    parts: adminContext.data.parts!,
    labour: adminContext.data.labour!,
    packs: adminContext.data.packs!,
    isScottish: estimate.isScottish,
    override: lead.estimate_quote_items || undefined
  })
  const groupedCalculatedQuote = groupBy(calculatedQuote, 'group_name')

  const quoteHeatPump = groupedCalculatedQuote[CalculatedQuoteDefaultGroups.HEAT_PUMPS]?.[0]
  const quoteHotWaterCylinder = groupedCalculatedQuote[CalculatedQuoteDefaultGroups.HOT_WATER_CYLINDERS]?.[0]

  const systemDesignRows = [
    { key: 'Heat pump', value: estimate.heatPump?.name || <Badge color="RED" text="No heat pump selected" icon={AlertTriangle} /> },
    { key: 'Cylinder', value: estimate.hotWaterCylinder?.name || <Badge color="RED" text="No hot water cylinder selected" icon={AlertTriangle} /> },
    { key: 'Flow temperature (°C)', value: estimate.flowTempC },
    // { key: 'Radiator changes', value: estimate.radiator_changes },
    { key: `SCOP at ${estimate.flowTempC}°C`, value: estimate.sCOP }
  ]

  const costBreakdownRows = [
    ...(quoteHeatPump ? [{ key: quoteItemNameWithQuantity(quoteHeatPump), value: formatPrice(quoteHeatPump.subtotal) }] : []),
    ...(quoteHotWaterCylinder ? [{ key: quoteItemNameWithQuantity(quoteHotWaterCylinder), value: formatPrice(quoteHotWaterCylinder.subtotal) }] : []),
    ...groupedCalculatedQuote[CalculatedQuoteDefaultGroups.PARTS]?.map(part => ({ key: quoteItemNameWithQuantity(part), value: formatPrice(part.subtotal) })) || [],
    ...groupedCalculatedQuote[CalculatedQuoteDefaultGroups.RADIATORS]?.map(radiator => ({ key: quoteItemNameWithQuantity(radiator), value: formatPrice(radiator.subtotal) })) || [],
    ...groupedCalculatedQuote[CalculatedQuoteDefaultGroups.UNDERFLOOR]?.map(ufh => ({ key: quoteItemNameWithQuantity(ufh, 'm²'), value: formatPrice(ufh.subtotal) })) || [],
    ...groupedCalculatedQuote[CalculatedQuoteDefaultGroups.LABOUR]?.map(labour => ({ key: quoteItemNameWithQuantity(labour, 'days'), value: formatPrice(labour.subtotal) })) || [],
    ...groupedCalculatedQuote[CalculatedQuoteDefaultGroups.SURVEY]?.map(survey => ({ key: quoteItemNameWithQuantity(survey), value: formatPrice(survey.subtotal) })) || [],
    ...(groupedCalculatedQuote[CalculatedQuoteDefaultGroups.GRANTS]?.some(grant => grant.selected) ? groupedCalculatedQuote[CalculatedQuoteDefaultGroups.GRANTS]?.map(grant => ({ key: quoteItemNameWithQuantity(grant), value: formatPrice(grant.subtotal) })) || [] : [])
  ]

  const handleEditCostEstimate = () => {
    const url = `/${companyPublicInfo.subdomain}/admin/quotes/${lead.uuid}/estimate/cost-estimate`
    navigateTo(url)
  }

  return (
    <Section title="Cost estimate" controls={<Button size="SM" iconLeft={Edit} colour='LIGHT' onClick={handleEditCostEstimate}>Edit cost estimate</Button>}>
      <div className='flex flex-col gap-5'>

        <div className='flex items-center justify-between'>
          <Text bold={true}>Estimate total</Text>
          <Text bold={true}>{formatPrice(sum(calculatedQuote.map(x => x.selected ? x.subtotal : 0)))}</Text>
        </div>

        <div>
          <Text bold={true} className='mb-1'>System design</Text>
          <SummaryTable rows={systemDesignRows} />
        </div>

        <div>
          <Text bold={true} className='mb-1'>Cost breakdown</Text>
          <SummaryTable rows={costBreakdownRows} />
          {(estimate.isScottish && lead.scottish_rural) && <Alert className="mt-3" type='SUCCESS'>This property is likely to be eligible for the HES rural uplift.</Alert>}
        </div>

      </div>
    </Section>
  )
}
