/*
  useUpload
  - Uploads a file to the server, gets progress and success states
  - Downloads a file from the server, gets progress and success states
*/

import { useState } from 'react'
import { axiosGetV2, axiosPostV2 } from './axios'
import { AuthSDK } from './utils/auth_provider'
import { type AxiosProgressEvent } from 'axios'
import { openInNewTab } from './helpers'

// RelativeUrl is the S3 location to upload/download the file i.e user-uploads.
export const useFiles = (relativeUrl: string) => {
  const [progress, setProgress] = useState(0)
  const [success, setSuccess] = useState(false)
  const [error, setError] = useState<string | undefined>(undefined)

  const uploadFile = async (formData: FormData) => {
    try {
      // Reset states before uploading
      setProgress(0)
      setSuccess(false)
      setError(undefined)

      // Use axiosPostV2 with onUploadProgress to track the upload progress
      const responseData = await axiosPostV2(relativeUrl, formData, {
        headers: {
          'x-auth-token': AuthSDK.getToken(),
          'Content-Type': 'multipart/form-data'
        },
        onUploadProgress: (event: AxiosProgressEvent) => {
          const percentCompleted = event.total ? Math.round((event.loaded * 100) / event.total) : 0
          setProgress(percentCompleted) // Update the progress state
        }
      })

      if (typeof responseData !== 'string') {
        throw new Error('Invalid response from the server')
      }
      // If the upload was successful, mark as success
      setSuccess(true)
      return new URL(responseData)
    } catch (err: any) {
      // If we got a response from the server, display that - otherwise use fallbacks
      setError(err.response?.data?.detail ?? err.message ?? 'Something went wrong during the upload')
    }
  }

  // If we've defined the relativeURL - we want to download a specific file.
  const downloadFile = async ({ fileKey, directDownload, fileNameOverride }: { fileKey: string, directDownload?: boolean, fileNameOverride?: string }) => {
    // Going to put this as part of use_upload as it's related to user uploads.
    //  Void method - downloads file from server.
    try {
      // Reset states before uploading
      setProgress(0)
      setSuccess(false)
      setError(undefined)

      const searchParams = new URLSearchParams()

      if (directDownload) {
        searchParams.append('direct_download', 'True')
      }
      if (fileNameOverride) {
        searchParams.append('name_override', fileNameOverride)
      }

      // Use axiosGetV2 with responseType and onDownloadProgress
      const responseBlob: Blob | undefined = await axiosGetV2(
        `${relativeUrl}/${fileKey}${searchParams.size > 0 ? `?${searchParams.toString()}` : ''}`, {
          headers: {
            'x-auth-token': AuthSDK.getToken()
          },
          responseType: 'blob',
          onDownloadProgress: (event: AxiosProgressEvent) => {
            const percentCompleted = event.total ? Math.round((event.loaded * 100) / event.total) : 0
            setProgress(percentCompleted) // Update the progress state
          }
        })

      if (!responseBlob) {
        throw new Error('Failed to download the file')
      }

      // In order to download the file, we need to create a URL object, click it and then clean up
      const downloadUrl = window.URL.createObjectURL(responseBlob)
      const fileElement = document.createElement(directDownload ? 'a' : 'div')
      if (directDownload) {
        // If direct download, then set download attribute
        (fileElement as HTMLAnchorElement).href = downloadUrl
        fileElement.setAttribute('download', fileNameOverride ?? fileKey)
      } else {
        // Otherwise preview the file in a new tab
        (fileElement as HTMLDivElement).onclick = () => openInNewTab(downloadUrl)
      }

      document.body.appendChild(fileElement)

      // Download
      fileElement.click()

      // Clean up
      document.body.removeChild(fileElement)
      window.URL.revokeObjectURL(downloadUrl)

      setSuccess(true)
    } catch (err: any) {
      setError(err.response.data.detail ?? err.message ?? 'Something went wrong during the download')
    }
  }

  return { progress, success, error, uploadFile, downloadFile }
}
