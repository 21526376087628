import React, { useState } from 'react'
import { leadWithNewOverride, type Lead } from '../../../../../code/models/lead'
import { Text } from '../../../../../components/content_display/text'
import { Heading } from '../../../../../components/content_display/heading'
import { type TableColumn, TableLite } from '../../../../../components/content_display/table_lite'
import { Input } from '../../../../../components/inputs_and_selections/input'
import { validateEmail, validateNotNull } from '../../../../../code/validators'
import { type HouseOverrides } from '../../../../../code/models/property'
import { Select } from '../../../../../components/inputs_and_selections/select'
import { PROPERTY_TYPES } from '../../../../../code/models/property_type'
import { BUILT_FORM_TYPES } from '../../../../../code/models/built_form'
import { ESTIMATE_AGE_BANDS } from '../../../../../code/models/age_bands'
import { CAVITY_WALL, CAVITY_WALL_INSULATION, FLOOR_INSULATION, LOFT_INSULATION, OUTDOOR_SPACE, SOLID_WALL_INSULATION, TIMBER, WALL_GROUPS, WINDOW_TYPES } from '../../../../../code/models/u_value'
import { FUELS } from '../../../../../code/models/fuel'
import { Section } from '../../components/section'
import { Button } from '../../../../../components/buttons/button'
import { HeatLossElementsTable, HeatLossSummaryCards } from '../../enquiry_manager/sections/heat_loss_estimate'
import { type Estimate } from '../../../../../code/calculate_estimate'
import { noop } from 'lodash'
import { TextArea } from '../../../../../components/inputs_and_selections/text_area'
import { parseEnglishEpcInner } from '../../../../../code/models/epc'
import { parseScottishEpcInner } from '../../../../../code/models/epc_scotland'
import { ChevronRight, ChevronLeft, RefreshCw } from 'lucide-react'

type Props = {
  lead: Lead
  setLead: (lead: Lead) => void
  estimate: Estimate
}

type ResponsesTable = {
  question: string | JSX.Element
  enquiryResponse: string | JSX.Element
  estimateValue: JSX.Element | null
}

const responseRow = (question: string, enquiryResponse: string, editComponent: JSX.Element | null) => {
  return {
    question: <Text size="SM" bold={true}>{question}</Text>,
    enquiryResponse,
    estimateValue: editComponent
  }
}

export const EnquiryData = ({ lead, setLead, estimate }: Props) => {
  const propertyMappedFromEpc = lead.epcData ? parseEnglishEpcInner(lead.epcData, lead.property) : parseScottishEpcInner(lead.epc_scotland!, lead.property)

  const contactColumns: Array<TableColumn<ResponsesTable>> = [
    { key: 'question', name: 'Question' },
    { key: 'estimateValue', name: 'Enquiry response' }
  ]

  const propertyColumns: Array<TableColumn<ResponsesTable>> = [
    { key: 'question', name: 'Question' },
    { key: 'enquiryResponse', name: 'Enquiry response' },
    { key: 'estimateValue', name: 'Estimate value' }
  ]

  const contactRows: ResponsesTable[] = [
    responseRow('Name', lead.customer?.name || '', <Input value={lead.customer?.name || ''} setValue={(v) => setLead({ ...lead, customer: { ...lead.customer, name: v } })} validator={validateNotNull} />),
    responseRow('Email', lead.customer?.email || '', <Input value={lead.customer?.email || ''} setValue={(v) => setLead({ ...lead, customer: { ...lead.customer, email: v } })} validator={validateEmail} />),
    responseRow('Phone', lead.customer?.phone || '', <Input value={lead.customer?.phone || ''} setValue={(v) => setLead({ ...lead, customer: { ...lead.customer, phone: v } })} />),
    responseRow('Where did you hear about us?', lead.customer?.source || '', <Input value={lead.customer?.source || ''} setValue={(v) => setLead({ ...lead, customer: { ...lead.customer, source: v } })} />),
    responseRow('Customer comments', '', <TextArea readonly value={lead.notes || ''} setValue={noop} rows={2} />)
  ]

  const setOverride = (key: keyof HouseOverrides, value: string | number | undefined) => {
    setLead(leadWithNewOverride(lead, estimate.defaults, key, value))
  }

  const propertyRows: ResponsesTable[] = [
    responseRow('Property type', lead.property.propertyType || propertyMappedFromEpc?.propertyType || '',
      <Select options={PROPERTY_TYPES.map(x => ({ key: x.uuid, value: x.name }))}
        selectedKey={lead.property.houseOverrides?.propertyType ?? lead.property.propertyType}
        setSelectedKey={(e) => setOverride('propertyType', e)} />
    ),
    responseRow('Built form', lead.property.builtForm,
      <Select options={BUILT_FORM_TYPES.map(x => ({ key: x.uuid, value: x.name }))}
        selectedKey={lead.property.houseOverrides?.builtForm ?? lead.property.builtForm}
        setSelectedKey={(e) => setOverride('builtForm', e)} />
    ),
    responseRow('Built', ESTIMATE_AGE_BANDS.find(x => x.uuid === lead.property.construction_age_band_uuid)?.name || '',
      <Select options={ESTIMATE_AGE_BANDS.map(x => ({ key: x.uuid, value: x.name }))}
        selectedKey={lead.property.houseOverrides?.construction_age_band_uuid ?? lead.property.construction_age_band_uuid}
        setSelectedKey={(e) => setOverride('construction_age_band_uuid', e)} />
    ),
    responseRow('Wall construction', lead.property.wallGroup || '',
      <Select options={WALL_GROUPS.map(x => ({ key: x.name, value: x.name }))}
        selectedKey={lead.property.houseOverrides?.wallGroup ?? lead.property.wallGroup}
        setSelectedKey={(e) => setOverride('wallGroup', e)} />
    ),
    responseRow('Wall insulation', ((lead.property.houseOverrides?.wallGroup ?? lead.property.wallGroup) === CAVITY_WALL ? CAVITY_WALL_INSULATION : SOLID_WALL_INSULATION)
      .find(x => x.uuid === lead.property.wallType)?.name || '',
    (lead.property.houseOverrides?.wallGroup ?? lead.property.wallGroup) !== TIMBER
      ? <Select
          options={((lead.property.houseOverrides?.wallGroup ?? lead.property.wallGroup) === CAVITY_WALL ? CAVITY_WALL_INSULATION : SOLID_WALL_INSULATION).map(x => ({
            key: x.uuid,
            value: x.name
          }))}
          selectedKey={lead.property.houseOverrides?.wallType ?? lead.property.wallType}
          setSelectedKey={(e) => setOverride('wallType', e)} />
      : null
    ),
    responseRow('Windows', lead.property.windowType,
      <Select options={WINDOW_TYPES.map(x => ({ key: x.uuid, value: x.name }))}
        selectedKey={lead.property.houseOverrides?.windowType ?? lead.property.windowType}
        setSelectedKey={(e) => setOverride('windowType', e)} />
    ),
    responseRow('Floor insulation', lead.property.floorType,
      <Select options={FLOOR_INSULATION.map(x => ({ key: x.uuid, value: x.name }))}
        selectedKey={lead.property.houseOverrides?.floorType ?? lead.property.floorType}
        setSelectedKey={(e) => setOverride('floorType', e)} />
    ),
    responseRow('Loft insulation', lead.property.loftInsulation || '',
      <Select options={LOFT_INSULATION.map(x => ({ key: x.uuid, value: x.name }))}
        selectedKey={lead.property.houseOverrides?.loftInsulation ?? lead.property.loftInsulation}
        setSelectedKey={(e) => setOverride('loftInsulation', e)} />
    ),
    responseRow('Bedrooms', lead.property.noBedrooms.toString(),
      <Input
        type='number'
        value={(lead.property.houseOverrides?.noBedrooms ?? lead.property.noBedrooms ?? 0).toString()}
        setValue={(e) => setOverride('noBedrooms', parseFloat(e))}
      />
    ),
    responseRow('Bathrooms', lead.property.noBathrooms.toString(),
      <Input
        type='number'
        value={(lead.property.houseOverrides?.noBathrooms ?? lead.property.noBathrooms ?? 0).toString()}
        setValue={(e) => setOverride('noBathrooms', parseFloat(e))}
      />
    ),
    responseRow('Fuel type', lead.property.fuelType,
      <Select options={FUELS.map(x => ({ key: x.name, value: x.name }))}
        selectedKey={lead.property.houseOverrides?.fuelType ?? lead.property.fuelType}
        setSelectedKey={(e) => setOverride('fuelType', e)} />
    ),
    responseRow('Floor area (m²)', lead.property.floorArea?.toString() || '',
      <Input
        type='number'
        value={(lead.property.houseOverrides?.floorArea ?? lead.property.floorArea).toString()}
        setValue={(e) => setOverride('floorArea', parseFloat(e))}
      />
    ),
    responseRow('Room height (m)', lead.property.roomHeight?.toString() || '',
      <Input
        type='number'
        value={(lead.property.houseOverrides?.roomHeight ?? lead.property.roomHeight).toString()}
        setValue={(e) => setOverride('roomHeight', parseFloat(e))}
      />
    ),
    responseRow('Outdoor space', lead.property.outdoorSpace || '',
      <Select options={OUTDOOR_SPACE.map(x => ({ key: x.key, value: x.label }))}
        selectedKey={lead.property.houseOverrides?.outdoorSpace ?? lead.property.outdoorSpace}
        setSelectedKey={(e) => setOverride('outdoorSpace', e)} />
    )
  ]

  const [heatLossSidebarVisible, setHeatLossSidebarVisible] = useState(true)

  const handleResetEditingData = () => {
    setLead({
      ...lead,
      property: {
        ...lead.property,
        houseOverrides: {
          ...lead.property.houseOverrides,
          designTempOverride: undefined,
          internalTempOverride: undefined,
          airChangeOverride: undefined,
          externalWallUValueOverride: undefined,
          partyWallUValueOverride: undefined,
          windowsUValueOverride: undefined,
          floorUValueOverride: undefined,
          roofUValueOverride: undefined
        }
      }
    })
  }

  return (
    <div className='flex flex-col lg:flex-row gap-6'>
      <div className='flex-1'>
        <Section title="Enquiry data" controls={<Button size="SM" iconRight={heatLossSidebarVisible ? ChevronRight : ChevronLeft} onClick={() => setHeatLossSidebarVisible(!heatLossSidebarVisible)} className="hidden lg:block">{heatLossSidebarVisible ? 'Hide' : 'Show'} heat loss sidebar</Button>}>
          <div className='flex flex-col gap-2'>
            <Heading size="lg">Contact details</Heading>
            <TableLite rows={contactRows} columns={contactColumns} alignRight={false} />
          </div>
          <div className='flex flex-col gap-2' data-cy="property_info">
            <Heading size="lg">Property details</Heading>
            <TableLite rows={propertyRows} columns={propertyColumns} alignRight={false} />
          </div>
          <div className='flex flex-col gap-2'>
            <Heading size="lg">Installer notes</Heading>
            <TextArea value={lead.installer_notes || ''} setValue={(v) => setLead({ ...lead, installer_notes: v })} />
          </div>
        </Section>
      </div>
      <div className={`flex ${heatLossSidebarVisible ? '' : 'lg:hidden'}`}>
        <Section className='lg:max-w-[500px] w-full' title="Heat loss estimate" controls={<Button colour='LIGHT' iconLeft={RefreshCw} onClick={handleResetEditingData} size="SM">Reset to defaults</Button>} >
          <HeatLossElementsTable
            lead={lead}
            estimate={estimate}
            setOverride={setOverride}
          />
          <hr className='my-2 border-light' />
          <Heading size="md">Estimates</Heading>
          <HeatLossSummaryCards lead={lead} estimate={estimate} columns={1} />
        </Section>
      </div>
    </div>
  )
}
