import React from 'react'
import { Link } from '../../../components/buttons/link'
import { noop } from 'lodash'

export const LinkDemo = () => {
  return <div className="flex flex-col gap-8">
    <div className='flex flex-col gap-4'>
      <div className='text-2xl font-bold text-gray-900'>Definition</div>

      <pre className='bg-gray-100 rounded-lg p-4 overflow-x-auto'><code>
        {codeBlock}
      </code></pre>

      <div className='flex flex-col divide-y divide-dashed divide-gray-200 text-sm'>
        {componentProps.map(x => <div key={x.name} className='grid grid-cols-2 py-2'>
          <div className='font-bold text-gray-900'>{x.name}</div>
          <div>{x.description}</div>
        </div>)}
      </div>
    </div>

    <div className='flex flex-col gap-4'>
      <div className='text-2xl font-bold text-gray-900'>Component</div>
      <Link
        onClick={noop}
        text='Hello World'
      />
    </div>

  </div>
}

const componentProps = [
  { name: 'text', description: 'Text to display as link' },
  { name: 'onClick', description: 'Action to perform when clicked, shows cursor or not if populated' },
  { name: 'className', description: 'Any additional styling or positioning required' }
]

const codeBlock = `<Link
  text='Hello World'
  onClick={noop}
  className=''
/>`
