// bit mask for granular user assess
import { apiUrl, client } from '../axios'
import { AuthSDK } from '../utils/auth_provider'
// import { isFlagSet } from '../helpers'
import { type Company } from './company'
import { type Invitation } from './invitations'

export const USER_ROLE_SIMPLE = 0x00
export const USER_ROLE_HAS_SURVEY_ACCESS = 0x01

// superadmin has access to everything
// 12 bits only because the `role` column is a signed smallint (2 bytes)
export const USER_ROLE_SUPERADMIN = 0xFFF

// Surveyors only have access to surveys, not estimates or the settings page
export const USER_ROLE_SURVEYOR = 0x02

export type User = {
  uuid: string
  name: string
  email: string
  role: number
  companies: Array<{
    uuid: string
    name: string
    role: number
    subdomain: string
    logo: string
  }>
  invitations: {
    sent: Invitation[]
    received: Invitation[]
  }
  accepted_terms_and_conditions_id: number
}

export enum UserEventType {
  GeneratedReport = 1,
  GeneratedProposal = 2,
  GeneratedQuote = 3,
  Stub = 100 // For testing
}

export type UserEvent = {
  event_type: UserEventType
  company_uuid?: string
  extra_data: object
}

/* Returns the numeric role for the user in the given company,
 * or -1 if the user doesn't have access to the company */
export const getRoleForCompany = (user: User | undefined, companySubdomain: string | undefined): number => {
  if (!user || !companySubdomain) {
    return -1
  }
  if (user.role === USER_ROLE_SUPERADMIN) {
    return USER_ROLE_SUPERADMIN
  }
  return user.companies.find(company => company.subdomain === companySubdomain)?.role || -1
}

export const isSuperAdmin = (user: User | undefined): boolean => {
  if (!user) {
    return false
  }
  return user.role === USER_ROLE_SUPERADMIN
}

const hasAccess = (company: Company | undefined, user: User | undefined, roles: number[]): boolean => {
  if (!user || !company) {
    return false
  }
  if (isSuperAdmin(user)) {
    return true
  }
  const companyRole = getRoleForCompany(user, company.public_info.subdomain)
  if (companyRole === -1) {
    return false
  }
  return roles.some(requiredRole => requiredRole === companyRole)
}

export const hasSurveyAccess = (company: Company | undefined, user: User | undefined) => hasAccess(company, user, [USER_ROLE_HAS_SURVEY_ACCESS, USER_ROLE_SURVEYOR])

export const hasEnquriesAccess = (company: Company | undefined, user: User | undefined) => hasAccess(company, user, [USER_ROLE_SIMPLE, USER_ROLE_HAS_SURVEY_ACCESS])

export const hasInventoryAccess = (company: Company | undefined, user: User | undefined) => hasAccess(company, user, [USER_ROLE_SIMPLE, USER_ROLE_HAS_SURVEY_ACCESS])

export const hasSettingsAccess = (company: Company | undefined, user: User | undefined) => hasAccess(company, user, [USER_ROLE_SIMPLE, USER_ROLE_HAS_SURVEY_ACCESS])

export const getUser = async (): Promise<User | undefined> => {
  try {
    const result = await client.get<User>(`${apiUrl}users/me`,
      { headers: { 'x-auth-token': AuthSDK.getToken() } }
    )
    return result.data
  } catch (e: unknown) {
    console.error('Error getting user', e)
    return undefined
  }
}

export const postUserEvent = async (event: UserEvent): Promise<string | undefined> => {
  try {
    const result = await client.post<string>(`${apiUrl}users/event`,
      event,
      { headers: { 'x-auth-token': AuthSDK.getToken() } }
    )
    return result.data
  } catch (e: unknown) {
    console.error('Error posting user event user', e)
    return undefined
  }
}
