import React from 'react'
import { type Lead } from '../../../../../code/models/lead'
import { SystemDesign } from '../../enquiry_manager/sections/system_design'
import { SimpleLayout } from '../../../../../layouts/simple_layout'
import { Heading } from '../../../../../components/content_display/heading'
import { Icon } from '../../../../../components/buttons/icon'
import { CostBreakdown } from '../../enquiry_manager/sections/cost_breakdown'
import { type Estimate } from '../../../../../code/calculate_estimate'
import { type CompanyPublicInfo } from '../../../../../code/models/company'
import { ArrowLeft } from 'lucide-react'

type Props = {
  lead: Lead
  setLead: (lead: Lead) => void
  estimate: Estimate
  navigateTo: (url: string) => void
  companyPublicInfo: CompanyPublicInfo
}

const CostEstimateEditorHeader = ({ navigateTo }: { navigateTo: (url: string) => void }) => {
  const handleReturn = () => {
    const baseUrl = location.pathname.split('/').slice(0, -1).join('/')
    navigateTo(`${baseUrl}/dashboard`)
  }

  return (
    <div className='flex items-center justify-between'>
      <div className='flex items-center gap-4'>
        <Icon className='w-6 h-6' onClick={handleReturn} icon={ArrowLeft} />
        <Heading size='xl'>Edit cost estimate</Heading>
      </div>
    </div>
  )
}

export const CostEstimateEditor = ({ lead, setLead, estimate, navigateTo, companyPublicInfo }: Props) => {
  return (
    <div className='absolute inset-0 h-screen z-10 overflow-auto'>
      <SimpleLayout header={<CostEstimateEditorHeader navigateTo={navigateTo} />}>
        <div className="space-y-6 p-6">
          <SystemDesign
            lead={lead}
            setLead={setLead}
            estimate={estimate}
          />
          <CostBreakdown
            lead={lead}
            setLead={setLead}
            estimate={estimate}
            companyPublicInfo={companyPublicInfo}
          />
        </div>
      </SimpleLayout>
    </div>
  )
}
