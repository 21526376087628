import { type Room } from '../../../../code/models/room'
import intersect from '@turf/intersect'
import { featureCollection, polygon } from '@turf/helpers'
import { compact } from 'lodash'
import { captureException } from '@sentry/react'

export const getIntersectingShapes = (rooms: Room[]) => {
  const roomsToPolygons = rooms.map(r => ({ uuid: r.uuid!, coords: r.walls.map(w => [w.x! + r.x!, w.y! + r.y!]) }))
  const shapes = roomsToPolygons.map(r => ({ ...r, coords: polygon([[...r.coords, r.coords[0]]]) })) // Turf requires a closed shape to repeat the first coord, which is different to Konva.
  try {
    const results = shapes.map(x => {
      const intersections = compact(shapes
        .filter(y => y.uuid !== x.uuid) // don't compare shape to itself
        .map(y => {
          const result = intersect(featureCollection([x.coords, y.coords]))
          return result?.geometry?.coordinates as (Array<Array<[number, number]>> | undefined)
        }))

      return intersections
    }).flat().flat()

    return results
  } catch (e) {
    captureException(e)
  }

  return []
}
