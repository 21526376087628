// define a dedicated type to avoid confusion with just a "number" in the code
import { type Property, type PropertySurvey } from './property'
import { type CustomerDetailsType } from './lead'
import { type FileWrapper } from '../../pages/heat_loss/file_wrapper'
import { type InventoryHeatPump, type InventoryHotWaterCylinder } from './inventory'
import { axiosGetV2, axiosPostV2 } from '../axios'

export const HEATLOSS_REPORT_SECTION_SUMMARY = 0x0001

export const HEATLOSS_REPORT_SECTION_HEATLOSS_SUMMARY = 0x0002 // UI should enable this if any of the following are enabled
export const HEATLOSS_REPORT_SECTION_HEATLOSS_INTRO = 0x0004
export const HEATLOSS_REPORT_SECTION_HEATLOSS_PER_ROOM = 0x0008

export const HEATLOSS_REPORT_SECTION_DESIGN = 0x0010
export const HEATLOSS_REPORT_SECTION_SOUND_ASSESSMENT = 0x0020
export const HEATLOSS_REPORT_SECTION_PERFORMANCE_ESTIMATE = 0x0040

// all sections
export const HEATLOSS_REPORT_SECTION_ALL =
  HEATLOSS_REPORT_SECTION_SUMMARY |
  HEATLOSS_REPORT_SECTION_HEATLOSS_SUMMARY |
  HEATLOSS_REPORT_SECTION_HEATLOSS_INTRO |
  HEATLOSS_REPORT_SECTION_HEATLOSS_PER_ROOM |
  HEATLOSS_REPORT_SECTION_DESIGN |
  HEATLOSS_REPORT_SECTION_SOUND_ASSESSMENT |
  HEATLOSS_REPORT_SECTION_PERFORMANCE_ESTIMATE

// static Heatloss report data
// this snapshot is stored in the database and fetched to display report to a homeowner by auth-free link
export type HeatLossReportSnapshot = {
  // lead data
  // NB! do not store the whole lead here because it contains information not for public access
  property: Property
  customer: CustomerDetailsType

  // survey data
  survey: PropertySurvey
  files: FileWrapper[]
  currentHeatPump: InventoryHeatPump
  currentHotWaterCylinder: InventoryHotWaterCylinder
}

export type HeatLossReportPublic = {
  uuid?: string
  contents_bitmask: number
  snapshot: HeatLossReportSnapshot
}

export type HeatLossReport = HeatLossReportPublic & {
  company_uuid: string
  lead_uuid: string
  recipients?: string[]
  email_text?: string
}

export const createHLReportAPI = async (report: HeatLossReport) => await axiosPostV2<{ uuid: string }>(`teams/${report.company_uuid}/leads/${report.lead_uuid}/heatloss_reports`, report)
export const sendHLReportAPI = async (report: HeatLossReport, email_html: string) => await axiosPostV2(`teams/${report.company_uuid}/leads/${report.lead_uuid}/heatloss_reports/${report.uuid!}/send`, { email_html })

export const getHLReportPublicAPI = async (reportUUID: string, companyUUID: string) => await axiosGetV2<HeatLossReportPublic>(`teams/${companyUUID}/heatloss_reports/${reportUUID}`)
export const getHLReportPDFAPI = async (reportUUID: string, companyUUID: string) => await axiosGetV2<Promise<Blob>>(`teams/${companyUUID}/heatloss_reports/${reportUUID}/pdf`, { responseType: 'blob' })
export const getHLReportPreviewPDFAPI = async (reportUUID: string, companyUUID: string) => await axiosGetV2<Promise<Blob>>(`teams/${companyUUID}/heatloss_reports/${reportUUID}/preview_pdf`, { responseType: 'blob' })
