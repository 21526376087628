import { type AxiosResponse } from 'axios'
import { apiUrl, client, url } from '../axios'
import { getRoleForCompany, USER_ROLE_HAS_SURVEY_ACCESS, USER_ROLE_SIMPLE, USER_ROLE_SUPERADMIN, type User } from './user'
import { type SignUpReturnType } from './auth'
import { z } from 'zod'
import { AuthSDK } from '../utils/auth_provider'

const InvitationSchema = z.object({
  uuid: z.string(),
  entity_uuid: z.string(),
  entity_type: z.literal('survey'),
  email: z.string(),
  status: z.union([z.literal('pending'), z.literal('accepted'), z.literal('rejected')]),
  expires_at: z.string().datetime(),
  recipient_uuid: z.string().nullable()
})

export type Invitation = z.infer<typeof InvitationSchema>

const InvitationsResponseSchema = z.object({
  sent: z.array(InvitationSchema),
  received: z.array(InvitationSchema)
})

export type InvitationsResponse = z.infer<typeof InvitationsResponseSchema>

// Returns sent and received invitations for the current user
export const getInvitations = async (): Promise<InvitationsResponse> => {
  try {
    const result = await client.get<InvitationsResponse>(`${apiUrl}invitations`,
      { headers: { 'x-auth-token': AuthSDK.getToken() } }
    )
    return result.data
  } catch (e) {
    console.error('Error getting invitations', e)
    return { sent: [], received: [] }
  }
}

export const getInvitationByToken = async (token: string): Promise<Invitation | undefined> => {
  try {
    const response = await client.get(`${apiUrl}invitations/${token}`)
    return response.data
  } catch (e) {
    console.error('Error getting invitation', e)
    return undefined
  }
}

export const createSurveyInvitation = async ({
  companyUuid,
  surveyUuid,
  email
}: {
  companyUuid: string
  surveyUuid: string
  email: string
}): Promise<Invitation | undefined> => {
  try {
    const response = await client.post(`${apiUrl}invitations/survey`, { email, company_uuid: companyUuid, survey_uuid: surveyUuid },
      { headers: { 'x-auth-token': AuthSDK.getToken() } }
    )
    return response.data
  } catch (e) {
    console.error('Error creating invitation', e)
    return undefined
  }
}

export const acceptInvitation = async ({ email, password, name, token }: { email: string, password?: string, name?: string, token: string }): Promise<AxiosResponse<SignUpReturnType>> => {
  try {
    return await client.post(`${url}invitation/accept`, { email, password, name, token })
  } catch (e) {
    console.error('Error accepting invitation', e)
    return e.response
  }
}

export const revokeInvitation = async (invitationUuid: string): Promise<boolean> => {
  try {
    await client.delete(`${apiUrl}invitations/${invitationUuid}`,
      { headers: { 'x-auth-token': AuthSDK.getToken() } }
    )
    return true
  } catch (e) {
    console.error('Error revoking invitation', e)
    return false
  }
}

export const canViewSurvey = ({ user, leadUUID, companySubdomain }: { user: User | undefined, leadUUID: string | undefined, companySubdomain: string | undefined }): boolean => {
  if (!user || !leadUUID || !user.invitations) {
    return false
  }
  const currentRole = getRoleForCompany(user, companySubdomain)
  if ([USER_ROLE_HAS_SURVEY_ACCESS, USER_ROLE_SUPERADMIN].includes(currentRole)) {
    // Company owners (and superadmin) can always access their own surveys
    return true
  }
  if (currentRole === USER_ROLE_SIMPLE) {
    // Users without access to the survey tool can never access surveys
    return false
  }
  // Users who have been invited to a specific survey can access it
  return user.invitations.received.some(invitation => invitation.entity_type === 'survey' && invitation.status === 'accepted' && invitation.entity_uuid === leadUUID)
}

export const invitationIsValid = (invitation: Invitation | undefined): boolean => {
  if (!invitation) {
    return false
  }
  if (invitation.status !== 'pending') {
    return false
  }
  const expiresAt = new Date(invitation.expires_at)
  return expiresAt > new Date()
}
