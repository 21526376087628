import React, { type PropsWithChildren } from 'react'
import { Icon } from '../buttons/icon'
import { ChevronLeft } from 'lucide-react'

// goBack, onBack legacy naming should eventually be merged.
// goBack = call the root goBack method which handles page management and open/closed state
// onBack = any additional operation you want to perform (state cleanup) when going back
type BottomSheetHeaderProps = {
  title: string
  goBack?: () => void
  onBack?: () => void
}

export const BottomSheetHeader = ({ title, goBack, children, onBack }: PropsWithChildren<BottomSheetHeaderProps>) => {
  return <div className='flex flex-col gap-2'>
    <div className='flex justify-between items-center'>
      <Icon onClick={() => {
        goBack && goBack()
        onBack?.()
      }} icon={ChevronLeft} />
      <div className='font-bold text-gray-900'>{title}</div>
      <div></div>
    </div>
    {children}
  </div>
}
