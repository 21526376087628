import React, { useState } from 'react'
import { Heading } from '../../../../../components/content_display/heading'
import { type BrandRange } from '../../../../../code/models/brand_range'
import { chain, orderBy } from 'lodash'
import { Section } from '../../../job_layout/components/section'
import { Button } from '../../../../../components/buttons/button'
import { updateCompany, type Company } from '../../../../../code/models/company'
import { Badge } from '../../../../../components/indicators_and_messaging/badge'
import { DropdownMenu } from '../../../../../components/buttons/dropdown_menu'
import { InventoryTable, type InventoryTableColumn } from '../inventory_table'
import { Modal } from '../../../../../components/containers/modal'
import { getHeatPumpCapacityAtOutsideTempAndFlowTemp, type RangeHeatPump } from '../../../../../code/models/range_heat_pump'
import { type TableColumn, TableLite } from '../../../../../components/content_display/table_lite'
import { Select } from '../../../../../components/inputs_and_selections/select'
import { type InventoryHeatPump } from '../../../../../code/models/inventory'
import { Input } from '../../../../../components/inputs_and_selections/input'
import { Text } from '../../../../../components/content_display/text'
import { HeatPumpModal } from '../heat_pump_modal'
import { priceCalculations } from '../../../../../code/price_calculations'
import { type Pack } from '../../../../../code/models/packs'
import { EmptySection } from '../empty_section'
import { Check, Edit, Plus, Trash } from 'lucide-react'

type Props = {
  brandRanges: BrandRange[]
  heatPumps: InventoryHeatPump[]
  addHeatPumps: (heatPumps: InventoryHeatPump[]) => Promise<void>
  deleteHeatPump: (uuid: string) => Promise<void>
  updateHeatPump: (heatPump: InventoryHeatPump) => Promise<void>
  company: Company
  setCompany: (company: Company) => void
  packs: Pack[]
}

export const HeatPumpsInventory = ({ brandRanges, heatPumps, addHeatPumps, deleteHeatPump, updateHeatPump, company, setCompany, packs }: Props) => {
  const [modalHeatPumpId, setModalHeatPumpId] = useState<string | undefined>()
  const [addHeatPumpModalVisible, setAddHeatPumpModalVisible] = useState(false)
  const [selectedRangeId, setSelectedRangeId] = useState('')
  const [addRangeModalVisible, setAddRangeModalVisible] = useState(false)
  const [selectedHeatPumpIds, setSelectedHeatPumpIds] = useState<string[]>([])

  const rangeHeatPumps = brandRanges.flatMap(x => x.range_heat_pumps).filter(x => x.deleted_at === null)
  const heatPumpsWithRange: InventoryHeatPump[] = heatPumps.map(x => ({ ...x, range_heat_pump: rangeHeatPumps.find(rhp => rhp.uuid === x.range_heat_pump_uuid) }))
  const heatsPumpsWithBrand: InventoryHeatPump[] = heatPumpsWithRange.map(x => ({ ...x, range_heat_pump: { ...x.range_heat_pump!, brand_range: brandRanges.find(br => br.uuid === x.range_heat_pump?.brand_range_uuid) } }))
  const hydratedHeatPumps = chain(orderBy(heatsPumpsWithBrand, x => x.range_heat_pump?.name))
    .groupBy(x => x.range_heat_pump?.brand_range?.name ?? '')
    .map((values, key) => ({ key, values }))
    .value()

  const modalHeatPump = heatPumpsWithRange.find(x => x.uuid === modalHeatPumpId)

  const heatPumpsColumns: Array<InventoryTableColumn<{ name: React.ReactNode, costPrice: React.ReactNode, markup: React.ReactNode, customerPrice: React.ReactNode, menu: React.ReactNode }>> = [
    { key: 'name', name: 'Name' },
    { key: 'costPrice', name: 'Cost price' },
    { key: 'markup', name: 'Markup' },
    { key: 'customerPrice', name: 'Customer price' },
    { key: 'menu', name: '', align: 'right' }
  ]

  const rangeHeatPumpColumns: Array<TableColumn<RangeHeatPump>> = [
    { key: 'name', name: 'Name' },
    { name: 'Heat Capacity @ 45°C (kW)', render: (row) => <div>{getHeatPumpCapacityAtOutsideTempAndFlowTemp(row, -3, 45).capacityKw}</div> }
  ]

  const setDefaultRange = async (uuid: string | undefined) => {
    if (!uuid) return
    const updatedCompany: Company = { ...company, default_brand_range_uuid: uuid }
    await updateCompany(updatedCompany)
    setCompany(updatedCompany)
  }

  return (
    <>
      <div className="space-y-6">
        <div className="flex flex-col gap-3 justify-start items-start sm:flex-row sm:justify-between sm:items-center w-full">
          <Heading size="2xl">Heat pumps</Heading>
          <Button colour='DARK' onClick={() => setAddRangeModalVisible(true)} iconLeft={Plus}>Add range</Button>
        </div>
        {!hydratedHeatPumps.length
          ? <EmptySection
              title='No heat pumps added'
              description='Add heat pumps to your inventory to create estimates and quotes for your customers.'
              button={<Button onClick={() => setAddRangeModalVisible(true)} iconLeft={Plus}>Add range</Button>}
            />
          : hydratedHeatPumps.map((brandRange, index) => (
            <Section title={
              <div className='flex items-center justify-between gap-3 w-full'>
                <div className='flex items-center gap-2'>
                  <Heading size="xl">{brandRange.key}</Heading>
                  {brandRange.values[0].range_heat_pump?.brand_range_uuid === company.default_brand_range_uuid && <Badge color="LIGHT" text="Default range" />}
                </div>
                <DropdownMenu
                  items={[
                    { label: 'Make default range', onClick: () => setDefaultRange(brandRange.values[0].range_heat_pump?.brand_range_uuid), icon: Check }
                    // { label: 'Delete range', onClick: noop, icon: Trash } // Not currently implemented
                  ]}
                />
              </div>} key={index}>
              <InventoryTable
                rows={brandRange.values
                  .sort((a, b) => getHeatPumpCapacityAtOutsideTempAndFlowTemp(a.range_heat_pump, -3, 45).capacityKw - getHeatPumpCapacityAtOutsideTempAndFlowTemp(b.range_heat_pump, -3, 45).capacityKw)
                  .map(x => {
                    const packForHeatPump = packs.find(pack => pack.uuid === x.default_pack_uuid)
                    return {
                      name: <div className="flex flex-col gap-2 items-start"><Text size="SM">{x.name}</Text>{packForHeatPump && <div className='flex gap-1 items-center'>{/* <Text size="SM" bold className="text-gray-600">Associated pack:</Text> */}<Badge color="LIGHT" text={packForHeatPump.name} /></div>}</div>,
                      costPrice: <Input type='number' value={x.cost_price} prefix="£" setValue={(value) => updateHeatPump(priceCalculations.updateCostPrice(x, parseFloat(value)))} />,
                      markup: <Input type='number' step={1} value={Math.round(x.markup)} setValue={(value) => updateHeatPump(priceCalculations.updateMarkup(x, parseFloat(value)))} postfix='%' />,
                      customerPrice: <Input type='number' value={priceCalculations.calculateCustomerPrice(x.cost_price, x.markup)} setValue={(value) => updateHeatPump(priceCalculations.updateCostPriceAndMarkupToMatchCustomerPrice(x, parseFloat(value)))} prefix='£' />,
                      menu: <DropdownMenu
                        items={[
                          { label: 'Edit', onClick: () => setModalHeatPumpId(x.uuid), icon: Edit },
                          { label: 'Delete', onClick: () => deleteHeatPump(x.uuid), icon: Trash, confirmText: 'Are you sure you want to delete this heat pump?' }
                        ]} />
                    }
                  })}
                columns={heatPumpsColumns}
              />
            </Section>
          ))}
      </div>
      {addRangeModalVisible && <Modal allowOverflow={true} confirmButtonLabel='Continue' visible={true}
        setVisible={() => setAddRangeModalVisible(false)} title='Add a heat pump range'
        onConfirm={() => {
          setAddRangeModalVisible(false)
          setAddHeatPumpModalVisible(true)
        }}>
        <div className='flex flex-col gap-2'>
          <Select dataCy="add_dropdown" filter={true} selectedKey={selectedRangeId}
            options={orderBy(brandRanges.map(x => ({ key: x.uuid, value: x.name })), x => x.value)}
            setSelectedKey={setSelectedRangeId}/>
          <div className="text-gray-600 text-xs">
              Can't find the range you are looking for? <a target="_blank" href="mailto:support@spruce.eco" className="underline" rel="noreferrer">Let us know</a> and
            we'll add it for you.
          </div>
        </div>
      </Modal>}
      {addHeatPumpModalVisible && <Modal onConfirm={() => {
        const heatPumpsToAdd = rangeHeatPumps.filter(x => selectedHeatPumpIds.includes(x.uuid)).map(x => ({
          uuid: crypto.randomUUID(),
          name: x.name,
          cost_price: 0,
          markup: 0,
          range_heat_pump_uuid: x.uuid
        })) as InventoryHeatPump[]
        addHeatPumps(heatPumpsToAdd)
        setSelectedHeatPumpIds([])
      }} confirmButtonLabel='Add' confirmDisabled={selectedHeatPumpIds.length === 0} visible={true} setVisible={() => setAddHeatPumpModalVisible(false)} title='Add a heat pump'>
        <TableLite
          rows={orderBy(rangeHeatPumps.filter(x => x.brand_range_uuid === selectedRangeId && !heatPumps.map(x => x.range_heat_pump_uuid).includes(x.uuid)), x => getHeatPumpCapacityAtOutsideTempAndFlowTemp(x, -3, 45).capacityKw)}
          rowIdName='uuid'
          columns={rangeHeatPumpColumns}
          selectedIds={selectedHeatPumpIds}
          setSelectedIds={setSelectedHeatPumpIds}
        />
      </Modal>}
      {modalHeatPump && <HeatPumpModal
        heatPump={modalHeatPump}
        setVisible={() => setModalHeatPumpId(undefined)}
        updateHeatPump={updateHeatPump}
        packs={packs}
      />}
    </>
  )
}
