import { axiosDeleteV2, axiosGetV2, axiosPatchV2, axiosPostV2 } from '../axios'
import { DEFAULT_ESTIMATE_RAD_CHANGE_PERCENTAGE, type Estimate } from '../calculate_estimate'
import { calculateQuote, type QuoteItem } from '../calculate_quote'
import { type EPC } from './epc'
import { type InventoryHotWaterCylinder } from './inventory'
import { type Lead } from './lead'
import { type RangeHeatPump } from './range_heat_pump'
import { type AdminContextData } from '../../pages/admin/admin_layout'
import { getApproxNumberOfRadiatorChanges } from './estimated_rooms_and_radiators'

export type CalculatedEstimateStatus = undefined | 'Sent' | 'Opened' | 'Reminder sent' | 'Accepted' | 'Rejected'

export type CalculatedEstimate = {
  uuid?: string
  name?: string
  address: string
  postcode: string
  total_watts: number
  heat_pump_name: string
  hot_water_cylinder_name: string
  co2_saved_kg: number
  commutesSaved: number
  flightsSaved: number
  status: CalculatedEstimateStatus

  // These properties were added to the model after initial implementation
  range_heat_pump_uuid?: string
  range_heat_pump?: RangeHeatPump
  scop?: number // space heating only
  flow_temperature_c?: number
  range_hot_water_cylinder_uuid?: string
  range_hot_water_cylinder?: InventoryHotWaterCylinder

  annual_kwh_heat_required?: number
  average_efficiency_baseline?: number
  tariff_p_per_kwh_baseline?: number
  fuel_name_baseline?: string
  annual_bill_baseline_gbp?: number

  average_efficiency_hp?: number // including hot water
  tariff_p_per_kwh_electricity_hp_tariff?: number
  electric_hp_tariff_based_on?: string
  annual_bill_hp_tariff_gbp?: number
  tariff_p_per_kwh_electricity_price_cap?: number
  price_cap_description?: string
  annual_bill_hp_price_cap_gbp?: number

  lead_uuid: string
  lead?: Lead

  // EPC key and data
  lmk_key?: string
  epc?: EPC

  calculated_estimate_quote_items?: QuoteItem[]
  created_at?: string
  updated_at?: string
  reminder_sent_at?: string

  cover_note?: string
}

// TODO SPR-1232: FIX
export const estimateAndLeadToCalculatedEstimate = (estimate: Estimate, lead: Lead, adminData: AdminContextData): CalculatedEstimate => {
  const [calculatedQuote] = calculateQuote({
    company: adminData.company!,
    selectedHeatPump: estimate.heatPump,
    selectedHotWaterCylinder: estimate.hotWaterCylinder,
    defaultRadiatorChanges: getApproxNumberOfRadiatorChanges(lead, adminData.company?.estimate_default_radiator_change_percentage ?? DEFAULT_ESTIMATE_RAD_CHANGE_PERCENTAGE),
    parts: adminData.parts!,
    labour: adminData.labour!,
    packs: adminData.packs!,
    isScottish: estimate.isScottish,
    override: lead.estimate_quote_items || undefined
  })

  return {
    name: lead.customer!.name,
    address: lead.property.address,
    postcode: lead.property.postcode,
    total_watts: estimate.totalWatts,
    range_heat_pump_uuid: estimate.heatPump?.range_heat_pump?.uuid ?? '',
    range_heat_pump: estimate.heatPump?.range_heat_pump,
    heat_pump_name: estimate.heatPump?.name ?? '',
    range_hot_water_cylinder_uuid: estimate.hotWaterCylinder?.uuid ?? '',
    range_hot_water_cylinder: estimate.hotWaterCylinder,
    hot_water_cylinder_name: estimate.hotWaterCylinder?.name ?? '',
    co2_saved_kg: estimate.CO2SavedKg,
    annual_kwh_heat_required: estimate.annualKwhHeat,
    average_efficiency_baseline: estimate.efficiencyBaseline,
    fuel_name_baseline: estimate.fuelNameBaseline,
    tariff_p_per_kwh_baseline: estimate.tariffPPerKwhBaseline,
    annual_bill_baseline_gbp: estimate.annualBillBaselineGBP,
    average_efficiency_hp: estimate.averageEfficiencyHeatPump,
    tariff_p_per_kwh_electricity_hp_tariff: estimate.tariffPPerKwhElectricityHpTariff,
    electric_hp_tariff_based_on: estimate.electricHpTariffBasedOn,
    annual_bill_hp_tariff_gbp: estimate.annualBillHeatPumpTariffGBP,
    tariff_p_per_kwh_electricity_price_cap: estimate.tariffPPerKwhElectricityPriceCap,
    price_cap_description: estimate.priceCapDescription,
    annual_bill_hp_price_cap_gbp: estimate.annualBillHeatPumpPriceCapGBP,
    scop: estimate.sCOP,
    flow_temperature_c: estimate.flowTempC,
    lead_uuid: lead.uuid!,
    commutesSaved: estimate.commutesSaved,
    flightsSaved: estimate.flightsSaved,
    lmk_key: lead.epcData?.lmkKey ?? '',
    status: undefined,
    calculated_estimate_quote_items: calculatedQuote
  }
}

export const getCalculatedEstimate = async (calculatedEstimateId: string, companyUUID: string) => await axiosGetV2<CalculatedEstimate>(`teams/${companyUUID}/calculated_estimates/${calculatedEstimateId}`)
export const getCalculatedEstimates = async (leadUUID: string, companyUUID: string) => await axiosGetV2<CalculatedEstimate[]>(`teams/${companyUUID}/leads/${leadUUID}/calculated_estimates`)

export const insertCalculatedEstimate = async (calculatedEstimate: CalculatedEstimate, calculatedEstimateQuoteItems: QuoteItem[], companyUUID: string) => {
  await axiosPostV2<{ uuid: string }>(`teams/${companyUUID}/calculated_estimates`, {
    ...calculatedEstimate,
    calculated_estimate_quote_items: calculatedEstimateQuoteItems.map(x => ({ ...x, lead_uuid: calculatedEstimate.lead_uuid }))
  })
}

export const updateCalculatedEstimate = async (calculatedEstimate: Partial<CalculatedEstimate>, companyUUID: string) => {
  await axiosPatchV2(`teams/${companyUUID}/calculated_estimates/${calculatedEstimate.uuid}`, calculatedEstimate)
}

export const deleteCalculatedEstimate = async (calculatedEstimateId: string, companyUUID: string) => { await axiosDeleteV2(`teams/${companyUUID}/calculated_estimates/${calculatedEstimateId}`) }

export const sendCallback = async (calculatedEstimateId: string, companyUUID: string, discussionContent?: string, callTime?: string) => await axiosPostV2(`teams/${companyUUID}/calculated_estimates/${calculatedEstimateId}/callback`, { discussion_content: discussionContent, call_time: callTime })

export const sendSurvey = async (calculatedEstimate: CalculatedEstimate, companyUUID: string, emailHTML: string) => {
  await axiosPostV2(`teams/${companyUUID}/calculated_estimates/${calculatedEstimate.uuid}/survey`, { email_html: emailHTML })
  await updateCalculatedEstimate({ ...calculatedEstimate, status: 'Accepted' }, companyUUID)
}

export const sendRejected = async (calculatedEstimate: CalculatedEstimate, companyUUID: string, reason: string) => {
  await axiosPostV2(`teams/${companyUUID}/calculated_estimates/${calculatedEstimate.uuid}/not_interested`, { reason })
  await updateCalculatedEstimate({ ...calculatedEstimate, status: 'Rejected' }, companyUUID)
}
