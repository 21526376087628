import React from 'react'
import { BrandLogoBlock } from './blocks/brand_logo_block'
import { type Company } from '../../../../code/models/company'
import { SettingsLayout } from '../components/settings_layout'
import { BrandColoursBlock } from './blocks/brand_colours_block'

export const AccountBranding = ({ company, setCompany }: { company: Company, setCompany: (c: Company) => void }) => {
  return <SettingsLayout
    heading='Branding'
    description='Manage your company&apos;s branding on estimates and reports'
  >
    <BrandLogoBlock company={company} setCompany={setCompany} />
    <BrandColoursBlock company={company} setCompany={setCompany} />

  </SettingsLayout>
}
