import React, { type FC, type PropsWithChildren } from 'react'

type SectionProps = {
  title?: React.JSX.Element | string
  className?: string
  border?: boolean
  padding?: boolean
  onClick?: () => void
}

export const Section: FC<PropsWithChildren<SectionProps>> = ({ children, title, className, border, onClick, padding = true }) => {
  return <div data-cy="section" className={`overflow-auto bg-white ${border && 'border border-gray-200'} rounded-lg w-full ${className} flex flex-col`}>
    {title && <div onClick={onClick} className={`${onClick ? 'cursor-pointer' : ''} font-bold px-6 py-4 border-b border-gray-200`}>{title}</div>}
    <div className={`flex-grow flex flex-col ${padding ? 'p-6' : ''} overflow-auto`}>{children}</div>
  </div>
}
