import React, { useState } from 'react'
import { logEvent } from '../../code/log_event'
import { Input } from '../../components/inputs_and_selections/input'
import { Button } from '../../components/buttons/button'
import { AuthSDK } from '../../code/utils/auth_provider'
import { SpruceLogoBlack } from '../../assets/images/spruce_logo/spruce_logo'
import { AppContext } from '../../app_context'
import { Link } from '../../components/buttons/link'

export const LoginPage = ({ navigateTo }: { navigateTo: (url: string) => void }) => {
  const appContext = React.useContext(AppContext)

  // common admin/ preloaded data
  const [email, setEmail] = useState('')
  const [password, setPassword] = useState('')

  const handleSubmit = async (event?: any) => {
    event?.preventDefault()

    logEvent({ name: 'Login Clicked', properties: {} }, null)

    await AuthSDK.signIn(email, password).then(() => {
      // Send them back to the page they tried to visit when they were
      // redirected to the login page. Use { replace: true } so we don't create
      // another entry in the history stack for the login page.  This means that
      // when they get to the protected page and click the back button, they
      // won't end up back on the login page, which is also really nice for the
      // user experience.
      navigateTo('/')
    }).catch((e) => {
      console.error('Error logging in', e)
      appContext.setError(e)
    })
  }

  return <div className="flex flex-col items-center justify-center gap-16 w-2/3 max-w-80 mx-auto">
    <img alt="logo" className="mt-20 max-h-28 max-w-2/3" src={SpruceLogoBlack} />
    <div onSubmit={handleSubmit} className="w-full">
      <div className="flex flex-col w-full gap-4">
        <div className="space-y-2">
          <div className="text-slate-600 text-lg font-bold">Email</div>
          <Input
            onEnter={async () => { await handleSubmit(null) }}
            setValue={setEmail}
            type={'text'}
            value={email}
            placeholder={'Email'}
          />
        </div>
        <div className="space-y-2">
          <div className="text-slate-600 text-lg font-bold">Password</div>
          <Input
            onEnter={async () => { await handleSubmit(null) }}
            setValue={setPassword}
            type="password"
            value={password}
            placeholder={'Password'}
          />
        </div>
        <Link onClick={() => navigateTo('/remind-password')} text='Forgot password?' />
        <Button onClick={handleSubmit}>Login</Button>
      </div>
    </div>
  </div>
}
