import React, { useState } from 'react'
import { ClickableCard } from '../../components/content_display/card'
import { AddExternalAttachmentModal } from './add_external_attachment_modal'
import { AddInternalAttachmentModal } from './add_internal_attachment_modal'
import { Text } from '../../components/content_display/text'
import { Button } from '../../components/buttons/button'

export const ProposalAttachmentPlaceholder = ({ addAttachment, forceTitle }: { addAttachment: (title: string, url: string, internal: boolean) => void, forceTitle?: string }) => {
  const [modal, setModal] = useState<'internal' | 'external' | undefined>(undefined)

  const handleCloseModal = (open: boolean) => {
    if (!open) {
      setModal(undefined)
    }
  }

  return <>
    <ClickableCard variant='PLACEHOLDER' className='flex items-center justify-center'>
      <div className='flex flex-wrap gap-3 items-center justify-center p-0 sm:py-4'>
        <Text>No file provided</Text>
        <Text>-</Text>
        <div className='flex gap-3 items-center'>
          <Button colour='LIGHT' onClick={() => setModal('internal')}>Choose file</Button>
          <Text>or</Text>
          <Button colour='LIGHT' onClick={() => setModal('external')}>Add link</Button>
        </div>
      </div>
    </ClickableCard>
    <AddInternalAttachmentModal forceTitle={forceTitle} visible={modal === 'internal'} onAddAttachment={(title, url) => addAttachment(title, url, true)} setVisible={handleCloseModal}/>
    <AddExternalAttachmentModal forceTitle={forceTitle} visible={modal === 'external'} onAddAttachment={(title, url) => addAttachment(title, url, false)} setVisible={handleCloseModal}/>
  </>
}
