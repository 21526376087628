import React from 'react'
import { SurveyElectricsContext } from '../electrics'
import { ClickableCard } from '../../../../components/content_display/card'
import { Input } from '../../../../components/inputs_and_selections/input'
import { TextArea } from '../../../../components/inputs_and_selections/text_area'
import { Toggle } from '../../../../components/inputs_and_selections/toggle'

export const ElectricsCableRoutingBlock = () => {
  const electricsContext = React.useContext(SurveyElectricsContext)

  const { survey, setSurvey } = electricsContext!

  return <>
    <div className="text-gray-900 text-xl font-bold">Cable routing</div>
    <div className="flex-col gap-5 flex">
      <div className="flex-col gap-2 flex">
        <div className="text-gray-900 font-bold">Distance from main board to heat pump location</div>
        <Input
          type='number'
          value={survey.electrics_distance_from_board_to_heat_pump_m.toString()}
          setValue={(e) => setSurvey({ ...survey, electrics_distance_from_board_to_heat_pump_m: Number(e) })}
          postfix='m'/>
      </div>
      <div className="flex-col gap-2 flex">
        <div className="text-gray-900 font-bold">Distance from heat pump location to cylinder</div>
        <Input
          type='number'
          value={survey.electrics_distance_from_cylinder_to_heat_pump_m.toString()}
          setValue={(e) => setSurvey({ ...survey, electrics_distance_from_cylinder_to_heat_pump_m: Number(e) })}
          postfix='m'/>
      </div>
      <ClickableCard variant='GREY'>
        <div className='flex flex-col gap-5'>
          <div className="gap-3 flex items-center justify-between">
            <div className="text-gray-900 font-bold">Cylinder immersion feed required</div>
            <Toggle value={survey.electrics_cylinder_immersion_required} setValue={() => setSurvey({
              ...survey,
              electrics_cylinder_immersion_required: !survey.electrics_cylinder_immersion_required
            })}/>
          </div>
          {survey.electrics_cylinder_immersion_required && <>
            <div className='flex flex-col gap-2'>
              <div className='font-bold text-gray-900'>Distance from main board to cylinder</div>
              <Input
                type='number'
                value={survey.electrics_distance_from_board_to_cylinder_m.toString()}
                setValue={(e) => setSurvey({ ...survey, electrics_distance_from_board_to_cylinder_m: Number(e) })}
                postfix='m'/>
            </div>
          </>}
        </div>
      </ClickableCard>
      <div className="flex-col gap-2 flex">
        <div className="text-gray-900 font-bold">Notes</div>
        <TextArea value={survey.electrics_cable_notes}
          setValue={(e) => setSurvey({ ...survey, electrics_cable_notes: e })}/>
      </div>
    </div>
  </>
}
