import React, { type Dispatch, type SetStateAction, useState } from 'react'
import { PageHeader } from '../components/design_page_header'
import { Input } from '../../../../components/inputs_and_selections/input'
import { getFluidProperties } from '../../../../code/models/fluids'
import { tryParseFloat } from '../../../../code/helpers'
import { type PropertySurvey, type SurveyDesign } from '../../../../code/models/property'
import { type HydronicEmitter, type HydronicEmitterWithRoomAndWatts, type PipeData } from '../../../../code/models/pipes'
import { sum } from 'lodash'
import {
  INDEX_PIPE_LENGTH_HELPER_TEXT, IndexCircuitInfo, IndexEmitterFormLabel,
  IndexEmitterSelect,
  PipeworkInputs, PRIMARY_PIPE_LENGTH_HELPER_TEXT, PrimaryPipeFormLabel, SECONDARY_PIPE_LENGTH_HELPER_TEXT,
  SecondaryEmittersCard,
  SecondaryEmittersSelectPage, SecondaryPipeFormLabel
} from '../../survey/pipework'
import { type Room } from '../../../../code/models/room'
import { Icon } from '../../../../components/buttons/icon'
import { FormLabel } from '../../../../components/inputs_and_selections/form_label'
import { VerticalFormGroup } from '../../../../components/inputs_and_selections/vertical_form_group'
import { Alert } from '../../../../components/indicators_and_messaging/alert'
import { Info } from '../../../../components/buttons/info'
import { Badge, type badgeColours } from '../../../../components/indicators_and_messaging/badge'
import { numberFormat } from '../../../../code/number_format'
import { ChevronRight } from 'lucide-react'

type PipeworkDesignPageProps = {
  flowTempC: number
  deltaTFlowReturnC: number
  design: SurveyDesign
  setDesign: Dispatch<SetStateAction<SurveyDesign>>
  survey: PropertySurvey
  pipeDataList: PipeData[]
  designedEmitters: HydronicEmitterWithRoomAndWatts[]
  indexEmitterUUID: string | undefined
  secondaryEmitterUUIDS: string[]
  secondaryEmitterUUIDsThroughReplacement: string[] // the subset of secondary emitters that are on the secondary index pipe because they have replaced survey rads that were
  isOffline: boolean
  allSynced: boolean
}

export const PipeworkDesignPage = ({
  flowTempC,
  deltaTFlowReturnC,
  design,
  setDesign,
  survey,
  pipeDataList,
  designedEmitters,
  indexEmitterUUID,
  secondaryEmitterUUIDS,
  secondaryEmitterUUIDsThroughReplacement,
  isOffline,
  allSynced
}: PipeworkDesignPageProps) => {
  const [selectedPipeMaterial, setSelectedPipeMaterial] = useState<string>()
  const [selectedPipeSection, setSelectedPipeSection] = useState<'PRIMARY' | 'SECONDARY' | 'INDEX'>()
  const [page, setPage] = useState<'MAIN' | 'SELECTED_PIPE' | 'SECONDARY_EMITTERS'>()

  const {
    temperature_c,
    density_kg_per_m3,
    specific_heat_capacity_j_per_kg_k,
    kinematic_viscosity_m_per_s
  } = getFluidProperties('WATER', flowTempC, deltaTFlowReturnC)!
  const emittersOnSecondaryLength = designedEmitters.filter(x => secondaryEmitterUUIDS.includes(x.uuid!)).length

  // Extract the different lines of pipe data for clarity and to avoid repetition
  const primaryPipeData = pipeDataList.find(x => x.id === 'PRIMARY')
  const secondaryPipeData = pipeDataList.find(x => x.id === 'SECONDARY')
  const indexEmitterPipeData = pipeDataList.find(x => x.id === 'INDEX')

  const indexEmitter = designedEmitters.find(x => x.uuid === indexEmitterUUID)
  const secondaryEmitters = designedEmitters.filter(x => secondaryEmitterUUIDS.includes(x.uuid!))

  // We keep 3 seperate lists here: design secondary index emitter uuids, survey secondary index emitter uuids and removed secondary index emitter uuids.
  // If we know the rad hasn't been replaced and isn't an added design radiator work from removed array, otherwise work from design radiators array.
  const toggleEmitter = (emitter: HydronicEmitter & {
    room: Room
  }) => {
    // if the emitter is on the list of survey index emitters then toggle it on and off that list
    if (emitter.type !== 'DESIGN' && survey.secondary_index_emitter_uuids.some(y => y === emitter.uuid)) {
      const updatedDesign = { ...design }

      // if it is also on the list of design index emitters (because it was added belatedly to the list of survey emitters) then remove it from the design list as that gets very confusing
      if (updatedDesign.secondary_index_emitter_uuids.some(y => y === emitter.uuid)) {
        updatedDesign.secondary_index_emitter_uuids = updatedDesign.secondary_index_emitter_uuids.filter(y => y !== emitter.uuid)
      }
      // Either way toggle it on and off the "removed from survey list"
      updatedDesign.removed_secondary_index_emitter_uuids = updatedDesign.removed_secondary_index_emitter_uuids.some(y => y === emitter.uuid)
        ? updatedDesign.removed_secondary_index_emitter_uuids.filter(y => y !== emitter.uuid) // add back in
        : [...updatedDesign.removed_secondary_index_emitter_uuids, emitter.uuid!] // otherwise remove

      setDesign(updatedDesign)
    } else {
      // if the emitter is here because it replaced a survey emitter that was on the secondary then then toggle it on and off the removed list
      if (secondaryEmitterUUIDsThroughReplacement.includes(emitter.uuid!)) {
        setDesign(({
          ...design,
          removed_secondary_index_emitter_uuids: design.removed_secondary_index_emitter_uuids.some(y => y === emitter.uuid)
            ? design.removed_secondary_index_emitter_uuids.filter(y => y !== emitter.uuid) // add back in
            : [...design.removed_secondary_index_emitter_uuids, emitter.uuid!] // otherwise remove
        }))
      } else {
        // otherwise add/remove it from the design list (even if it's a survey emitter - the index circuit may just have changed)
        setDesign(({
          ...design,
          secondary_index_emitter_uuids: design.secondary_index_emitter_uuids.some(y => y === emitter.uuid) // if design rad that is already in the list
            ? design.secondary_index_emitter_uuids.filter(y => y !== emitter.uuid) // remove it
            : [...design.secondary_index_emitter_uuids, emitter.uuid!] // otherwise add in
        }))
      }
    }
  }

  if (page === 'SECONDARY_EMITTERS') {
    return <SecondaryEmittersSelectPage
      onBack={() => setPage('MAIN')}
      emitterUUIDs={secondaryEmitterUUIDS}
      toggleEmitter={toggleEmitter}
      allEmitters={designedEmitters}
      isOffline={isOffline}
      allSynced={allSynced}
    />
  }

  // Detailed pages for each pipe
  if (selectedPipeSection === 'PRIMARY' && primaryPipeData) {
    return <div className='flex flex-col h-full min-h-0'>
      <PageHeader isOffline={isOffline} allSynced={allSynced} title={'Pipework'} onBack={() => setSelectedPipeSection(undefined)}/>
      <div className="p-5 bg-white flex-col gap-6 flex overflow-y-auto">
        <PrimaryPipeFormLabel/>
        <PipeworkInputs
          pipeMaterial={selectedPipeMaterial}
          setPipeMaterial={setSelectedPipeMaterial}
          pipeModelUUID={primaryPipeData?.pipeMaterial?.uuid}
          setPipeModel={(e: string) => setDesign({ ...design, primary_pipework_uuid_override: e })}
          pipeLengthHelperText={PRIMARY_PIPE_LENGTH_HELPER_TEXT}
          pipeworkLengthM={primaryPipeData.lengthM}
          setPipeworkLength={(e: number) => setDesign({ ...design, primary_pipework_length_m_override: e })}
          lengthOverridden={design.primary_pipework_length_m_override !== undefined && survey.primary_pipework_length_m !== undefined}
          clearOverride={() => setDesign({ ...design, primary_pipework_length_m_override: undefined }) }
        />
        <div className="h-0 border border-gray-200"></div>
        <CalculationSection pipeData={primaryPipeData}/>
      </div>
    </div>
  }
  if (selectedPipeSection === 'SECONDARY' && secondaryPipeData) {
    return <div className='flex flex-col h-full min-h-0'>
      <PageHeader isOffline={isOffline} allSynced={allSynced} title={'Pipework'} onBack={() => setSelectedPipeSection(undefined)}/>
      <div className="p-5 bg-white flex-col gap-6 flex overflow-y-auto">
        <SecondaryPipeFormLabel/>
        <SecondaryEmittersCard
          setCurrentPage={setPage}
          emittersOnCircuitLength={emittersOnSecondaryLength}
          numberOfEmitters={designedEmitters.length}
        />
        <PipeworkInputs
          pipeMaterial={selectedPipeMaterial}
          setPipeMaterial={setSelectedPipeMaterial}
          pipeModelUUID={secondaryPipeData?.pipeMaterial?.uuid}
          setPipeModel={(e: string) => setDesign({ ...design, secondary_pipework_uuid_override: e })}
          pipeLengthHelperText={SECONDARY_PIPE_LENGTH_HELPER_TEXT}
          pipeworkLengthM={secondaryPipeData.lengthM}
          setPipeworkLength={(e: number) => setDesign({ ...design, secondary_pipework_length_m_override: e })}
          lengthOverridden={design.secondary_pipework_length_m_override !== undefined && survey.secondary_index_pipework_length_m !== undefined}
          clearOverride={() => setDesign({ ...design, secondary_pipework_length_m_override: undefined }) }
        />
        <div className="h-0 border border-gray-200"></div>
        <CalculationSection pipeData={secondaryPipeData}/>
      </div>

    </div>
  }
  if (selectedPipeSection === 'INDEX' && indexEmitterPipeData) {
    return <div className='flex flex-col h-full min-h-0'>
      <PageHeader isOffline={isOffline} allSynced={allSynced} title={'Pipework'} onBack={() => setSelectedPipeSection(undefined)}/>
      <div className="p-5 bg-white flex-col gap-6 flex overflow-y-auto">
        <IndexEmitterFormLabel/>
        <IndexEmitterSelect
          indexEmitterUuid={indexEmitterUUID}
          setIndexEmitterUuid={(e: string) => setDesign({ ...design, index_emitter_uuid_override: e })}
          possibleIndexEmittersWithRooms={
            secondaryEmitterUUIDS.length > 0
              ? secondaryEmitters
              : designedEmitters}
        />
        {indexEmitter && <PipeworkInputs
          pipeMaterial={selectedPipeMaterial}
          setPipeMaterial={setSelectedPipeMaterial}
          pipeModelUUID={indexEmitterPipeData?.pipeMaterial?.uuid}
          setPipeModel={(e: string) => setDesign({ ...design, index_pipework_uuid_override: e })}
          pipeLengthHelperText={INDEX_PIPE_LENGTH_HELPER_TEXT}
          pipeworkLengthM={indexEmitterPipeData.lengthM}
          setPipeworkLength={(e: number) => setDesign({ ...design, index_pipework_length_m_override: e })}
          editPipeModel={indexEmitter.type !== 'UNDERFLOOR'}
          lengthOverridden={design.index_pipework_length_m_override !== undefined && survey.index_emitter_pipe_length_m !== undefined}
          clearOverride={() => setDesign({ ...design, index_pipework_length_m_override: undefined }) }
        />}
        <div className="h-0 border border-gray-200"></div>
        <CalculationSection pipeData={indexEmitterPipeData}/>
      </div>

    </div>
  }

  return <div className='flex flex-col h-full min-h-0'>
    <PageHeader isOffline={isOffline} allSynced={allSynced} title='Pipework' onBack={() => window.history.back()}/>
    <div className="p-5 bg-white flex-col gap-6 flex overflow-y-auto">

      <FormLabel labelText={'Index circuit'} info={<IndexCircuitInfo />} size={'XL'}/>
      {/* TODO: calculate UFH and state in here that have done so */}
      <div className="flex-col flex">
        <FormLabel labelText={'Sections'} size={'LG'}/>
        <div className='flex flex-col divide-y divide-gray-200'>
          {primaryPipeData && <PipeSectionCard
            pipeData={primaryPipeData}
            onClick={() => {
              setSelectedPipeSection('PRIMARY')
              setSelectedPipeMaterial(primaryPipeData?.pipeMaterial?.material ?? 'Copper')
            }}
          />}
          {secondaryPipeData && <PipeSectionCard
            pipeData={secondaryPipeData}
            onClick={() => {
              setSelectedPipeSection('SECONDARY')
              setSelectedPipeMaterial(secondaryPipeData?.pipeMaterial?.material ?? 'Copper')
            }}
          />}
          {indexEmitterPipeData && <PipeSectionCard
            pipeData={indexEmitterPipeData}
            onClick={() => {
              setSelectedPipeSection('INDEX')
              setSelectedPipeMaterial(indexEmitterPipeData?.pipeMaterial?.material ?? 'Copper')
            }}
          />}
        </div>
      </div>

      <div className={'flex flex-col gap-3'}>
        <FormLabel labelText={'Totals'} size={'LG'}/>
        <VerticalFormGroup
          formLabel={<FormLabel
            labelText={'Allowance for emitters and fittings'}
            helperText={'Enter the uplift you want to use to account for emitters and fittings on the circuit. CIBSE suggest an allowance of 33 to 50%'}
          />}
          input={<Input
            value={design.allowance_for_emitters_and_fittings ?? ''}
            setValue={(e) => setDesign({ ...design, allowance_for_emitters_and_fittings: tryParseFloat(e, undefined) })}
            postfix='%'/>}
        />
        <div className='flex flex-col gap-3'>
          <div className="py-2 border-t border-gray-200 justify-between items-center gap-3 flex">
            <div className="text-sm font-bold">Total pipes only</div>
            <div className="text-sm font-bold">{numberFormat(1).format(sum(pipeDataList.map(x => x.totalPressureDropkPa)))} kPa</div>
          </div>
          <div className="py-2 border-t border-gray-200 justify-between items-center gap-3 flex">
            <div className="items-center gap-1 flex">
              <div className="text-sm font-bold">Total including fittings and emitters (approximate)</div>
              <Info
                infoModalHeader={'What\'s included in this pressure drop?'}
                infoModalBody={'This estimate includes the pressure drop through the straight pipework, plus an allowance for the drop through the fittings and emitters. It doesn\'t include the pressure drop through the heat pump\'s heat exchanger.'}
              ></Info>
            </div>
            <div
              className="text-sm font-bold">{numberFormat(1).format(sum(pipeDataList.map(x => x.totalPressureDropkPa)) * (1 + ((design.allowance_for_emitters_and_fittings ?? 0) / 100)))} kPa
            </div>
          </div>
        </div>
      </div>

      <div className="h-0 border border-gray-200"></div>

      <FluidPropertiesSection
        temperature_c={temperature_c}
        density_kg_per_m3={density_kg_per_m3}
        specific_heat_capacity_j_per_kg_k={specific_heat_capacity_j_per_kg_k}
        kinematic_viscosity_m_per_s={kinematic_viscosity_m_per_s}/>
    </div>
  </div>
}

type PipeSectionCardProps = {
  pipeData: PipeData
  onClick?: () => void
}

export const PipeSectionCard = ({ pipeData, onClick }: PipeSectionCardProps) => {
  return <div key={pipeData.name}
    className="py-4 justify-between items-center gap-4 flex cursor-pointer"
    onClick={onClick}>
    <div className="flex-col flex">
      <FormLabel labelText={pipeData.name}/>
      <div className="text-gray-500 text-sm">
        {pipeData.kwatts} kW
        • <div className="inline-block">{pipeData.velocityAlert
          ? <Badge color='RED' text={pipeData.velocityMPerS + ' m/s'}/>
          : <div>{pipeData.velocityMPerS} m/s</div>
        }</div>
        • <div className="inline-block">{pipeData.linearPressureDropAlert
          ? <Badge color='YELLOW' text={pipeData.pressureDropPaPerM + ' Pa/m'}/>
          : <div>{pipeData.pressureDropPaPerM} Pa/m</div>
        }</div>
        • {pipeData.totalPressureDropkPa} kPa
      </div>
    </div>
    {onClick && <Icon icon={ChevronRight}/>}
  </div>
}

type CalculationSectionProps = {
  pipeData: PipeData
}

const CalculationSection = ({ pipeData }: CalculationSectionProps) => {
  const attributes: Array<{ key: string, value: string, badge_color?: keyof typeof badgeColours }> = [
    { key: 'Heat demand', value: `${pipeData.kwatts} kW` },
    { key: 'Flow rate', value: `${((pipeData.flowRateM3PerS * 1000) * 60).toFixed(1)} l/min` },
    {
      key: 'Velocity',
      value: `${pipeData.velocityMPerS} m/s`,
      badge_color: pipeData.velocityAlert ? 'RED' : undefined
    },
    {
      key: 'Pressure drop per unit',
      value: `${pipeData.pressureDropPaPerM} Pa/m`,
      badge_color: pipeData.linearPressureDropAlert ? 'YELLOW' : undefined
    },
    { key: 'Pipe pressure drop', value: `${numberFormat(1).format(pipeData.totalPressureDropkPa)} kPa` }
  ]

  return <div className="flex-col gap-3 flex">
    <div className="text-gray-900 font-bold">Calculations</div>
    <div className='flex-col flex'>
      {pipeData.velocityAlert && <Alert type={'DANGER'}>
        <div className="items-center gap-1 flex">
          {'Pipe velocity exceeds 1.5m/s'}
          <Info infoModalHeader={'Pipe velocity exceeds 1.5m/s'}
            infoModalBody={'This will result in excessive noise and the frictional resistance to flow will be significant. Increase the pipe diameter to reduce the flow velocity.'}
            colour={'text-red-800'}
          />
        </div>
      </Alert>}
      {pipeData.linearPressureDropAlert && <Alert type={'WARNING'}>
        <div className="items-center gap-1 flex">
          {'Pipe pressure drop exceeds 300Pa/m'}
          <Info infoModalHeader={'Pipe pressure drop exceeds 300Pa/m'}
            infoModalBody={'This may be ok in certain circumstances, such as when the pipe is very short, but you should check the pump sizing carefully if you want to stick with this pipe size. Alternatively, increase the pipe diameter to reduce the linear pressure drop.'}
            colour={'text-yellow-800'}
          />
        </div>
      </Alert>}
    </div>
    <div className="flex-col flex divide-y divide-dashed divide-gray-200">
      {attributes.map(x =>
        <div key={x.key} className="py-2 justify-between gap-2 flex">
          <div className="text-sm">{x.key}</div>
          {x.badge_color
            ? <Badge color={x.badge_color} text={x.value}/>
            : <div className= "text-gray-900 text-sm font-semibold">{x.value}</div>}
        </div>
      )}
    </div>
  </div>
}

type FluidPropertiesSectionProps = {
  temperature_c: number
  density_kg_per_m3: number
  specific_heat_capacity_j_per_kg_k: number
  kinematic_viscosity_m_per_s: number
}

const FluidPropertiesSection = ({ temperature_c, density_kg_per_m3, specific_heat_capacity_j_per_kg_k, kinematic_viscosity_m_per_s }: FluidPropertiesSectionProps) => {
  return <div className="flex-col gap-6 flex">

    <FormLabel
      labelText={'Fluid Properties'}
      helperText={'This section shows the fluid properties we have used in the calculations above'}
      size={'XL'}
      info={<Info
        infoModalHeader={'Fluid Properties'}
        infoModalBody={'The properties of the fluid in the heating system impact how much heat the system can carry and the pressure drop through the system. ' +
                'We are assuming you are just using water in the system, as anti-freeze additives like glycol significantly reduce the heat carrying capacity of the system.'
        }/>}
    />

    <div className="flex-col flex">
      <div className="pb-2 justify-between gap-2 flex">
        <div className="text-sm">Fluid</div>
        <div className="text-right text-sm">Water</div>
      </div>
      <div className="py-2 border-t border-gray-200 justify-between gap-2 flex">
        <div className="text-sm">Average flow temperature</div>
        <div className="text-right text-sm">{temperature_c} °C</div>
      </div>
      <div className="py-2 border-t border-gray-200 justify-between gap-2 flex">
        <div className="text-sm">Density</div>
        <div className="text-right text-sm">{density_kg_per_m3.toFixed(0)} kg/m³</div>
      </div>
      <div className="py-2 border-t border-gray-200 justify-between gap-2 flex">
        <div className="text-sm">Specific heat capacity</div>
        <div className="text-right text-sm">{specific_heat_capacity_j_per_kg_k.toFixed(0)} J/kg·K</div>
      </div>
      <div className="py-2 border-t border-gray-200 justify-between gap-2 flex">
        <div className="text-sm">Kinematic viscosity</div>
        <div className="text-right text-sm">{kinematic_viscosity_m_per_s.toExponential(2)} m/s</div>
      </div>
    </div>
  </div>
}
