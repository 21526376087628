import React, { useContext } from 'react'
import { getAddressIncludingPostcode } from '../../../code/models/lead'
import { SurveyContext } from '../survey_page'
import { StepHeader } from '../components/steps_header'
import { SubHeader } from '../components/sub_header'
import { Accordion } from '../../../components/content_display/accordion'
import { faqRows } from './get_contacts_step'
import { type CompanyPublicInfo } from '../../../code/models/company'
import { checkIfScottish } from '../../../code/models/address'
import { NextStepsComponent } from '../components/next_steps'

export const ThankYouStep = ({ companyPublicInfo }: { companyPublicInfo: CompanyPublicInfo }) => {
  // survey context and dispatch
  const survey = useContext(SurveyContext)

  return <>
    {/* Header */}
    <StepHeader
      guideText={getAddressIncludingPostcode(survey.lead)}
      headerText="Enquiry Sent 🙌"
      captionText={`Thanks ${survey.lead.customer?.name}! We'll send you an email with a detailed cost estimate within the next 48 hours. If you have any queries in the meantime, drop us an email at ${companyPublicInfo.notification_email}, or give us a call on ${companyPublicInfo.phone}.`}
    />

    <NextStepsComponent isScottish={checkIfScottish(survey.lead.property.postcode)} isChecked={false} />

    {/* Common questions */}
    <div className="w-full flex flex-col justify-start items-start gap-1">
      <SubHeader content="Common questions" />
      {/* TODO: add texts */}
      <Accordion rows={faqRows()} />
    </div>
  </>
}
