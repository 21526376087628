export const SNAPPING_TOLERANCE_PIXELS = 10
export const ANGLE_TOLERANCE_DEGREES = 1 // Are lines collinear
export const INTERSECTING_AREA_TOLERANCE_CM2 = 10 // Should we stop intersecting shapes

export type RoomType = {
  uuid: string
  name: string
  indoor_temp_c: number
  indoor_temp_post_2006_c: number
}

export const ROOM_TYPES: RoomType[] = [
  { uuid: 'kitchen', name: 'Kitchen', indoor_temp_c: 18, indoor_temp_post_2006_c: 21 },
  { uuid: 'living_lounge', name: 'Living/Lounge', indoor_temp_c: 21, indoor_temp_post_2006_c: 21 },
  { uuid: 'bedroom', name: 'Bedroom', indoor_temp_c: 18, indoor_temp_post_2006_c: 21 },
  { uuid: 'hall_landing', name: 'Hall/Landing', indoor_temp_c: 18, indoor_temp_post_2006_c: 21 },
  { uuid: 'bath_shower', name: 'Bath/Shower', indoor_temp_c: 22, indoor_temp_post_2006_c: 22 },
  { uuid: 'cloaks_wc', name: 'Cloaks/WC', indoor_temp_c: 18, indoor_temp_post_2006_c: 21 },
  { uuid: 'study', name: 'Study', indoor_temp_c: 21, indoor_temp_post_2006_c: 21 },
  { uuid: 'bed_ensuite', name: 'Bed & Ensuite', indoor_temp_c: 21, indoor_temp_post_2006_c: 21 },
  { uuid: 'dining', name: 'Dining', indoor_temp_c: 21, indoor_temp_post_2006_c: 21 },
  { uuid: 'store', name: 'Store', indoor_temp_c: 16, indoor_temp_post_2006_c: 21 },
  { uuid: 'utility', name: 'Utility', indoor_temp_c: 18, indoor_temp_post_2006_c: 21 },
  { uuid: 'dressing', name: 'Dressing', indoor_temp_c: 18, indoor_temp_post_2006_c: 21 },
  { uuid: 'internal', name: 'Internal', indoor_temp_c: 18, indoor_temp_post_2006_c: 21 }
]

export const ACH_AGES = [
  { key: 'pre_2000', value: 'Pre 2000' },
  { key: 'ach_2000_to_2006', value: '2000 to 2006' },
  { key: 'ach_post_2006', value: 'Post 2006' }
]

export const ROOM_TYPE_ACH = [
  { uuid: '0bcc3e3d-217c-4c04-be83-6a4db47ca264', room_uuid: 'bath_shower', age_uuid: 'pre_2000', value: 3 },
  { uuid: 'c10dd798-8508-434f-b1ec-15308e90181c', room_uuid: 'bath_shower', age_uuid: 'ach_2000_to_2006', value: 1.5 },
  { uuid: 'f2bc7b8e-8609-4047-a1e0-bad3803529d4', room_uuid: 'bath_shower', age_uuid: 'ach_post_2006', value: 1.5 },
  { uuid: '0959f616-4cb9-4118-a075-93abd969071f', room_uuid: 'bed_ensuite', age_uuid: 'pre_2000', value: 2 },
  { uuid: '179686dc-5675-4327-b7e4-a8ab5d114467', room_uuid: 'bed_ensuite', age_uuid: 'ach_2000_to_2006', value: 1.5 },
  { uuid: '0bcc8261-11e4-4e0d-9366-d6f755778de8', room_uuid: 'bed_ensuite', age_uuid: 'ach_post_2006', value: 1 },
  { uuid: '2876a08a-eddb-4e66-93ba-ad027ae17bd5', room_uuid: 'bedroom', age_uuid: 'pre_2000', value: 1 },
  { uuid: 'd3129e25-9828-4ab2-af2e-d948fe4137f4', room_uuid: 'bedroom', age_uuid: 'ach_2000_to_2006', value: 1 },
  { uuid: '2aa93e01-d059-4cae-8a25-3485605e4255', room_uuid: 'bedroom', age_uuid: 'ach_post_2006', value: 0.5 },
  { uuid: 'c72117be-3bfb-442d-8945-81fa38f7924a', room_uuid: 'cloaks_wc', age_uuid: 'pre_2000', value: 2 },
  { uuid: '63162246-988f-41f5-bb6d-f66fa8f09e35', room_uuid: 'cloaks_wc', age_uuid: 'ach_2000_to_2006', value: 1.5 },
  { uuid: 'c911839e-4d9a-4057-bae9-c77124fc9dd1', room_uuid: 'cloaks_wc', age_uuid: 'ach_post_2006', value: 1.5 },
  { uuid: 'b2eab855-3ffd-45fe-af7d-b94a49bf5827', room_uuid: 'dining', age_uuid: 'pre_2000', value: 1.5 },
  { uuid: 'bb83af80-97be-4c8f-9b17-3bded6ffe4a3', room_uuid: 'dining', age_uuid: 'ach_2000_to_2006', value: 1 },
  { uuid: '7d49a2c4-dbcb-4190-a35a-6377bb0b4b12', room_uuid: 'dining', age_uuid: 'ach_post_2006', value: 0.5 },
  { uuid: '3c19c20d-0617-49fc-bff9-06108e72dd79', room_uuid: 'dressing', age_uuid: 'pre_2000', value: 1.5 },
  { uuid: '2eb97387-5307-42dc-a7e3-c4c1dc4f1167', room_uuid: 'dressing', age_uuid: 'ach_2000_to_2006', value: 1 },
  { uuid: '16661a22-d7e8-4d99-8de1-6d9325e66d0c', room_uuid: 'dressing', age_uuid: 'ach_post_2006', value: 0.5 },
  { uuid: '11937b48-c5cb-4450-93d2-893c0970db62', room_uuid: 'hall_landing', age_uuid: 'pre_2000', value: 2 },
  { uuid: '1fd39640-0178-485a-94f9-585dafff7aa2', room_uuid: 'hall_landing', age_uuid: 'ach_2000_to_2006', value: 1 },
  { uuid: '2595013f-5846-4288-a43a-338da05da3b1', room_uuid: 'hall_landing', age_uuid: 'ach_post_2006', value: 0.5 },
  { uuid: 'f03b2579-8cff-48ab-8f9f-48b0c50f7b6c', room_uuid: 'internal', age_uuid: 'pre_2000', value: 0 },
  { uuid: '0a3fc59e-6517-404d-819b-c1d72a0097fe', room_uuid: 'internal', age_uuid: 'ach_2000_to_2006', value: 0 },
  { uuid: '3475d9af-fcc4-4f35-b7ec-62677c38100d', room_uuid: 'internal', age_uuid: 'ach_post_2006', value: 0 },
  { uuid: '34fc0c53-9acd-4a73-bbea-1a05778f6789', room_uuid: 'kitchen', age_uuid: 'pre_2000', value: 2 },
  { uuid: '50ec3096-9507-4f6c-8b4c-ba24d06e0a05', room_uuid: 'kitchen', age_uuid: 'ach_2000_to_2006', value: 1.5 },
  { uuid: '72939a95-a92f-4be4-8b0c-8fddfb9c70dc', room_uuid: 'kitchen', age_uuid: 'ach_post_2006', value: 1.5 },
  { uuid: '0214ec4a-0324-43a5-8725-803f06c1bb9f', room_uuid: 'living_lounge', age_uuid: 'pre_2000', value: 1.5 },
  { uuid: 'fe07da8e-67f6-4053-bafa-97b9b38082f0', room_uuid: 'living_lounge', age_uuid: 'ach_2000_to_2006', value: 1 },
  { uuid: '50807cc8-0966-4e68-aad3-31f62f4e0f43', room_uuid: 'living_lounge', age_uuid: 'ach_post_2006', value: 0.5 },
  { uuid: 'b1a1cc3a-f01d-44eb-a5b5-2b397d58e261', room_uuid: 'store', age_uuid: 'pre_2000', value: 1 },
  { uuid: '109cc3c1-0eff-45aa-bbf8-3670fded5c85', room_uuid: 'store', age_uuid: 'ach_2000_to_2006', value: 0.5 },
  { uuid: 'd5e407a5-3383-47fb-902a-d574f2bf4693', room_uuid: 'store', age_uuid: 'ach_post_2006', value: 0.5 },
  { uuid: '924de47b-e542-4c52-a0ab-5f16eb88d607', room_uuid: 'study', age_uuid: 'pre_2000', value: 1.5 },
  { uuid: 'e09461d2-33cd-4a0c-a26d-f1b847e3dd16', room_uuid: 'study', age_uuid: 'ach_2000_to_2006', value: 1.5 },
  { uuid: 'de534c93-ac1c-487a-b331-e2e1820fade2', room_uuid: 'study', age_uuid: 'ach_post_2006', value: 0.5 },
  { uuid: '7375ec71-b062-4888-8801-e124b53f50ca', room_uuid: 'utility', age_uuid: 'pre_2000', value: 3 },
  { uuid: 'bdb629bd-8b68-47ab-afd5-74c63b1c1e16', room_uuid: 'utility', age_uuid: 'ach_2000_to_2006', value: 2 },
  { uuid: '5775a586-2286-400d-89b2-923166a2e685', room_uuid: 'utility', age_uuid: 'ach_post_2006', value: 0.5 }
]

// For notes on thermal bridging see here: https://www.notion.so/spruce-energy/Thermal-Bridging-12323e02f2cd8092b757d6ccca6f70fc?pvs=4
export type ThermalBridgingAddition = {
  key: string
  value: string
  description: string
  uValueAdditionWPerM2: number
}
export const THERMAL_BRIDGING_ADDITIONS: ThermalBridgingAddition[] = [
  {
    key: 'all_other_buildings',
    value: 'All other buildings - 0.1 W/m²K',
    description: 'All other buildings',
    uValueAdditionWPerM2: 0.1
  },
  {
    key: 'above_current_standards',
    value: 'Insulation above current national standards - 0.02 W/m²K',
    description: 'Buildings built with a high level of heat insulation (over and above current national standards) and which implement minimisation of thermal bridging practices that exceed generally recognised rules of practice',
    uValueAdditionWPerM2: 0.02
  },
  {
    key: 'current_standards',
    value: 'Current national standards - 0.05 W/m²K',
    description: 'Buildings built to current national standards and in compliance with generally recognised rules of practice regarding the minimisation of thermal bridges',
    uValueAdditionWPerM2: 0.05
  },
  {
    key: 'ewi_broken_by_solid_ceilings',
    value: 'Exterior wall insulation broken by solid ceilings - 0.15 W/m²K',
    description: 'Buildings with exterior wall insulation broken by solid ceilings (e.g. reinforced concrete)',
    uValueAdditionWPerM2: 0.15
  }
]

export const SOUND_POWER_DISTANCE_REDUCTIONS = [
  { reflectiveSurfaces: 1, distanceM: 1, reductionDB: 8 },
  { reflectiveSurfaces: 1, distanceM: 1.5, reductionDB: 11 },
  { reflectiveSurfaces: 1, distanceM: 2, reductionDB: 14 },
  { reflectiveSurfaces: 1, distanceM: 3, reductionDB: 17 },
  { reflectiveSurfaces: 1, distanceM: 4, reductionDB: 20 },
  { reflectiveSurfaces: 1, distanceM: 5, reductionDB: 21 },
  { reflectiveSurfaces: 1, distanceM: 6, reductionDB: 23 },
  { reflectiveSurfaces: 1, distanceM: 8, reductionDB: 26 },
  { reflectiveSurfaces: 1, distanceM: 10, reductionDB: 28 },
  { reflectiveSurfaces: 1, distanceM: 12, reductionDB: 29 },
  { reflectiveSurfaces: 1, distanceM: 15, reductionDB: 31 },
  { reflectiveSurfaces: 1, distanceM: 20, reductionDB: 34 },
  { reflectiveSurfaces: 1, distanceM: 25, reductionDB: 36 },
  { reflectiveSurfaces: 1, distanceM: 30, reductionDB: 37 },
  { reflectiveSurfaces: 2, distanceM: 1, reductionDB: 5 },
  { reflectiveSurfaces: 2, distanceM: 1.5, reductionDB: 8 },
  { reflectiveSurfaces: 2, distanceM: 2, reductionDB: 11 },
  { reflectiveSurfaces: 2, distanceM: 3, reductionDB: 14 },
  { reflectiveSurfaces: 2, distanceM: 4, reductionDB: 17 },
  { reflectiveSurfaces: 2, distanceM: 5, reductionDB: 19 },
  { reflectiveSurfaces: 2, distanceM: 6, reductionDB: 20 },
  { reflectiveSurfaces: 2, distanceM: 8, reductionDB: 23 },
  { reflectiveSurfaces: 2, distanceM: 10, reductionDB: 25 },
  { reflectiveSurfaces: 2, distanceM: 12, reductionDB: 26 },
  { reflectiveSurfaces: 2, distanceM: 15, reductionDB: 28 },
  { reflectiveSurfaces: 2, distanceM: 20, reductionDB: 31 },
  { reflectiveSurfaces: 2, distanceM: 25, reductionDB: 33 },
  { reflectiveSurfaces: 2, distanceM: 30, reductionDB: 34 },
  { reflectiveSurfaces: 3, distanceM: 1, reductionDB: 2 },
  { reflectiveSurfaces: 3, distanceM: 1.5, reductionDB: 5 },
  { reflectiveSurfaces: 3, distanceM: 2, reductionDB: 8 },
  { reflectiveSurfaces: 3, distanceM: 3, reductionDB: 11 },
  { reflectiveSurfaces: 3, distanceM: 4, reductionDB: 14 },
  { reflectiveSurfaces: 3, distanceM: 5, reductionDB: 16 },
  { reflectiveSurfaces: 3, distanceM: 6, reductionDB: 17 },
  { reflectiveSurfaces: 3, distanceM: 8, reductionDB: 20 },
  { reflectiveSurfaces: 3, distanceM: 10, reductionDB: 22 },
  { reflectiveSurfaces: 3, distanceM: 12, reductionDB: 23 },
  { reflectiveSurfaces: 3, distanceM: 15, reductionDB: 25 },
  { reflectiveSurfaces: 3, distanceM: 20, reductionDB: 28 },
  { reflectiveSurfaces: 3, distanceM: 25, reductionDB: 30 },
  { reflectiveSurfaces: 3, distanceM: 30, reductionDB: 31 }
]

export const BARRIER_MAPPING = [
  { uuid: 'no_view', name: 'No view', reductionDba: 10 },
  { uuid: 'partial', name: 'Partial', reductionDba: 5 },
  { uuid: 'visible', name: 'Visible', reductionDba: 0 }
]

export const DIFFERENCE_IN_BACKGROUND_NOISE = [
  { difference: 0, correctionDB: 3 },
  { difference: 1, correctionDB: 2.5 },
  { difference: 2, correctionDB: 2.1 },
  { difference: 3, correctionDB: 1.8 },
  { difference: 4, correctionDB: 1.5 },
  { difference: 5, correctionDB: 1.2 },
  { difference: 6, correctionDB: 1 },
  { difference: 7, correctionDB: 0.8 },
  { difference: 8, correctionDB: 0.6 },
  { difference: 9, correctionDB: 0.5 },
  { difference: 10, correctionDB: 0.4 },
  { difference: 11, correctionDB: 0.3 },
  { difference: 12, correctionDB: 0.3 },
  { difference: 13, correctionDB: 0.2 },
  { difference: 14, correctionDB: 0.2 },
  { difference: 15, correctionDB: 0.1 }
]

const calculateDistance = (lat1: number, lon1: number, lat2: number, lon2: number) => {
  const R = 6371 // Radius of the Earth in kilometers
  const dLat = (lat2 - lat1) * (Math.PI / 180) // Convert degrees to radians
  const dLon = (lon2 - lon1) * (Math.PI / 180) // Convert degrees to radians
  const a =
      Math.sin(dLat / 2) * Math.sin(dLat / 2) +
      Math.cos(lat1 * (Math.PI / 180)) * Math.cos(lat2 * (Math.PI / 180)) *
      Math.sin(dLon / 2) * Math.sin(dLon / 2)
  const c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a))
  const distance = R * c // Distance in kilometers
  return distance
}

export const findNearestPoint = (findingPoint: google.maps.LatLngLiteral, points: google.maps.LatLngLiteral[]) => {
  let minDistance = Infinity
  let nearestPoint: google.maps.LatLngLiteral | undefined

  for (const point of points) {
    const distance = calculateDistance(findingPoint.lat, findingPoint.lng, point.lat, point.lng)
    if (distance < minDistance) {
      minDistance = distance
      nearestPoint = point
    }
  }

  return nearestPoint
}

export const calculateCentroid = (shape: number[][]) => {
  let centroidX = 0
  let centroidY = 0
  let signedArea = 0

  // For all vertices except last
  for (let i = 0; i < shape.length - 1; i++) {
    const x0 = shape[i][0]
    const y0 = shape[i][1]
    const x1 = shape[i + 1][0]
    const y1 = shape[i + 1][1]
    const a = x0 * y1 - x1 * y0
    signedArea += a
    centroidX += (x0 + x1) * a
    centroidY += (y0 + y1) * a
  }

  // Do last vertex separately to close the loop
  const x0 = shape[shape.length - 1][0]
  const y0 = shape[shape.length - 1][1]
  const x1 = shape[0][0]
  const y1 = shape[0][1]
  const a = x0 * y1 - x1 * y0
  signedArea += a
  centroidX += (x0 + x1) * a
  centroidY += (y0 + y1) * a

  signedArea *= 0.5
  centroidX /= (6 * signedArea)
  centroidY /= (6 * signedArea)

  return [centroidX, centroidY]
}

// UI
// TODO: should we remove these? They are unused
export const TEXT_BOLD = 'font-bold text-gray-900'
export const PAGE_VERTICAL = 'm-6'
export const PAGE_MARGIN = 'm-4'
export const FORM_VERTICAL = 'm-4'
