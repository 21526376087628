import React, { type Dispatch, type SetStateAction, useState } from 'react'
import { PageHeader } from '../components/design_page_header'
import { Input } from '../../../../components/inputs_and_selections/input'
import { type TableColumn, TableLite } from '../../../../components/content_display/table_lite'
import { type PropertySurvey } from '../../../../code/models/property'
import { Button } from '../../../../components/buttons/button'
import { openInNewTab } from '../../../../code/helpers'
import { ClickableCard } from '../../../../components/content_display/card'
import { Toggle } from '../../../../components/inputs_and_selections/toggle'
import { numberFormat } from '../../../../code/number_format'
import { Icon } from '../../../../components/buttons/icon'
import {
  type PerformanceEstimateRow,
  type PerformanceEstimateSummary
} from '../../../../code/models/performance_estimate'
import {
  getElectricityPencePerKwh,
  getHeatingFuel,
  getHeatingPencePerUnit,
  HEATING_FUELS,
  type HeatingFuel
} from '../../../../code/models/heating_fuel'
import { getExistingSystemEfficiencyFromSurvey } from '../../../../code/models/heating_system'
import { defaultTariffIsOverridden } from '../../survey/existing_heating_system/ehs_energy_use_block'
import { ExternalLink, XCircle } from 'lucide-react'

type PerformanceDesignPageProps = {
  performanceEstimateColumns: Array<TableColumn<PerformanceEstimateRow>>
  performanceEstimateSummary: PerformanceEstimateSummary
  survey: PropertySurvey
  setSurvey: (survey: PropertySurvey) => void
  postcode: string
  degreeDays: number
  heatLossWattsPerKelvin: number
  scopSpaceHeating: number
  scopHotWater: number
  isOffline: boolean
  allSynced: boolean
}

export const PerformanceDesignPage = ({
  performanceEstimateColumns,
  performanceEstimateSummary,
  survey,
  setSurvey,
  postcode,
  degreeDays,
  heatLossWattsPerKelvin,
  scopSpaceHeating,
  scopHotWater,
  isOffline,
  allSynced
}: PerformanceDesignPageProps) => {
  const [page, setPage] = useState<string>('')

  const existingFuel = getHeatingFuel(survey.existing_system_fuel_uuid)
  const existingSystemEfficiency = getExistingSystemEfficiencyFromSurvey(survey)
  const existingSystemPencePerUnit = getHeatingPencePerUnit(existingFuel, survey.existing_system_p_per_unit_override)
  const electricity = HEATING_FUELS.find(x => x.uuid === 'electricity')!

  if (page === 'EPC') return <EpcPage isOffline={isOffline} allSynced={allSynced} postcode={postcode} survey={survey} setSurvey={setSurvey} setPage={setPage} />
  if (page === 'DEGREE_DAYS') {
    return <HeatLossDegreeDaysPage
      isOffline={isOffline}
      allSynced={allSynced}
      heatLossWattsPerKelvin={heatLossWattsPerKelvin}
      degreeDays={degreeDays}
      spaceHeatingDemandKwh={performanceEstimateSummary.hddEstimate.proposed.demandSpaceHeatingKwh}
      hotWaterDemandKwh={performanceEstimateSummary.hddEstimate.proposed.demandHotWaterKwh}
      setPage={setPage}/>
  }
  if (page === 'CURRENT_CONSUMPTION') {
    return <CurrentConsumptionPage
      isOffline={isOffline}
      allSynced={allSynced}
      existingSystemEfficiency={existingSystemEfficiency}
      spaceHeatingDemandKwh={performanceEstimateSummary.currentConsumptionEstimate.proposed.demandSpaceHeatingKwh}
      hotWaterDemandKwh={performanceEstimateSummary.currentConsumptionEstimate.proposed.demandHotWaterKwh}
      survey={survey}
      setSurvey={setSurvey}
      setPage={setPage}/>
  }
  if (page === 'FUEL_INPUTS') {
    return <FuelInputsPage
      isOffline={isOffline}
      allSynced={allSynced}
      survey={survey}
      setSurvey={setSurvey}
      setPage={setPage}
      scopSpaceHeating={scopSpaceHeating}
      scopHotWater={scopHotWater}
      heatingFuel={existingFuel}
      heatingSystemEfficiency={existingSystemEfficiency}

    />
  }

  return <div className='flex flex-col h-full min-h-0'>
    <PageHeader isOffline={isOffline} allSynced={allSynced} title='Performance estimate' onBack={() => window.history.back()} />
    <div className='p-5 flex flex-col gap-6 overflow-y-auto'>
      {survey.use_epc_performance
        ? (survey.epc_heating_kwh ?? 0) > 0 && (survey.epc_hot_water_kwh ?? 0) > 0
            ? <ClickableCard variant='WHITE' title='EPC' onClick={() => setPage('EPC')}>
                <div className='overflow-x-auto p-2'>
                  <TableLite columns={performanceEstimateColumns} rows={[performanceEstimateSummary.epcEstimate.existing, performanceEstimateSummary.epcEstimate.proposed, performanceEstimateSummary.epcEstimate.savings]} />
                </div>
              </ClickableCard>
            : <ClickableCard title='⚠ Please enter EPC annual demand data' onClick={() => setPage('EPC')} variant='YELLOW' border={true} />
        : <ClickableCard title='EPC not possible to obtain' onClick={() => setPage('EPC')} variant='PLACEHOLDER' />}
      <ClickableCard variant='WHITE' title='Heat Loss & Degree Days' onClick={() => setPage('DEGREE_DAYS')}>
        <div className='overflow-x-auto p-2'>
          <TableLite columns={performanceEstimateColumns} rows={[performanceEstimateSummary.hddEstimate.existing, performanceEstimateSummary.hddEstimate.proposed, performanceEstimateSummary.hddEstimate.savings]} />
        </div>
      </ClickableCard>
      <ClickableCard variant='WHITE' title='Based on their existing consumption' onClick={() => setPage('CURRENT_CONSUMPTION')} >
        <div className='overflow-x-auto p-2'>
          <TableLite columns={performanceEstimateColumns} rows={[performanceEstimateSummary.currentConsumptionEstimate.existing, performanceEstimateSummary.currentConsumptionEstimate.proposed, performanceEstimateSummary.currentConsumptionEstimate.savings]} />
        </div>
      </ClickableCard>
      <ClickableCard variant='WHITE' title='Fuel inputs' onClick={() => setPage('FUEL_INPUTS')}>
        <div className='flex flex-col gap-4 text-sm'>
          <div className='flex flex-col gap-1'>
            <div className='font-bold text-gray-900'>Existing system</div>
            <div className='text-gray-500'>{existingSystemEfficiency * 100}% Efficiency · {existingSystemPencePerUnit} {existingFuel.penceUnit} · {existingFuel.emissions_g_per_kwh} g/kWh</div>
          </div>
          <div className='flex flex-col gap-1'>
            <div className='font-bold text-gray-900'>Heat pump</div>
            <div className='text-gray-500'>{(scopSpaceHeating * 100).toFixed()}% Efficiency · {getElectricityPencePerKwh(survey.electricity_p_per_kwh_override)} {electricity.penceUnit} · {electricity.emissions_g_per_kwh} g/kWh</div>
          </div>
        </div>
      </ClickableCard>
    </div>
  </div>
}

type EpcPageProps = {
  setPage: Dispatch<SetStateAction<string>>
  postcode: string
  survey: PropertySurvey
  setSurvey: (survey: PropertySurvey) => void
  isOffline: boolean
  allSynced: boolean
}

const EpcPage = ({ setPage, survey, setSurvey, postcode, isOffline, allSynced }: EpcPageProps) => {
  const epcUrl = survey.epc_certificate_number ? `https://find-energy-certificate.service.gov.uk/energy-certificate/${survey.epc_certificate_number}` : `https://find-energy-certificate.service.gov.uk/find-a-certificate/search-by-postcode?postcode=${postcode}`
  return <div className='flex flex-col h-full min-h-0'>
    <PageHeader isOffline={isOffline} allSynced={allSynced} title='EPC energy requirements' onBack={() => setPage('')} />
    <div className='flex flex-col gap-6 p-5 overflow-y-auto'>
      <div className='flex justify-between items-center gap-2'>
        <div className='flex flex-col gap-1'>
          <div className='font-bold text-gray-900'>Possible to obtain a valid EPC</div>
          <div className='text-gray-500 text-sm'>Cases where this will not be possible include refurbishment & extension projects</div>
        </div>
        <Toggle value={survey.use_epc_performance} setValue={() => setSurvey({ ...survey, use_epc_performance: !survey.use_epc_performance })} />
      </div>
      {survey.use_epc_performance && <><Button colour='LIGHT' onClick={() => openInNewTab(epcUrl)} iconLeft={ExternalLink}>Find energy performance certificate</Button>
        <div className="flex-col gap-2 flex">
          <div className="text-gray-900 text-base font-bold">EPC certificate number</div>
          <Input value={survey.epc_certificate_number} setValue={(e) => setSurvey({ ...survey, epc_certificate_number: e })} />
        </div>
        <div className="flex-col gap-2 flex">
          <div className="text-gray-900 text-base font-bold">Heating energy required</div>
          <Input
            postfix='kWh'
            type='number'
            value={survey.epc_heating_kwh?.toString() ?? ''}
            setValue={(e) => setSurvey({ ...survey, epc_heating_kwh: parseFloat(e) })}
          />
        </div>
        <div className="flex-col gap-2 flex">
          <div className="text-gray-900 text-base font-bold">Hot water energy required</div>
          <Input postfix='kWh'
            type='number'
            value={survey.epc_hot_water_kwh?.toString() ?? ''}
            setValue={(e) => setSurvey({ ...survey, epc_hot_water_kwh: parseFloat(e) })}
          />
        </div></>}
    </div>
  </div>
}

type HeatLossDegreeDaysPageProps = {
  setPage: Dispatch<SetStateAction<string>>
  degreeDays: number
  heatLossWattsPerKelvin: number
  hotWaterDemandKwh: string
  spaceHeatingDemandKwh: string
  isOffline: boolean
  allSynced: boolean
}

const HeatLossDegreeDaysPage = ({ setPage, degreeDays, spaceHeatingDemandKwh, hotWaterDemandKwh, heatLossWattsPerKelvin, isOffline, allSynced }: HeatLossDegreeDaysPageProps) => {
  return <div className='flex flex-col h-full min-h-0'>
    <PageHeader isOffline={isOffline} allSynced={allSynced} title='Heat loss & degree days' onBack={() => setPage('')} />
    <div className='flex flex-col gap-6 p-5 overflow-y-auto'>
      <div className='text-gray-500'>Heating degree day data is based on the property's postcode.</div>
      <div>
        <div className='text-gray-900 font-bold'>Heat loss per °C</div>
        <div>{heatLossWattsPerKelvin.toFixed(0)} W/°C</div>
      </div>
      <div>
        <div className='text-gray-900 font-bold'>Heating degree days</div>
        <div>{numberFormat(0).format(degreeDays)} °C days</div>
      </div>
      <div>
        <div className='text-gray-900 font-bold'>Heating energy required</div>
        <div>{spaceHeatingDemandKwh}</div>
      </div>
      <div>
        <div className='text-gray-900 font-bold'>Hot water energy required</div>
        <div>{hotWaterDemandKwh}</div>
      </div>
    </div>
  </div>
}

type CurrentConsumptionPageProps = {
  existingSystemEfficiency: number
  spaceHeatingDemandKwh: string
  hotWaterDemandKwh: string
  survey: PropertySurvey
  setSurvey: (survey: PropertySurvey) => void
  setPage: Dispatch<SetStateAction<string>>
  isOffline: boolean
  allSynced: boolean
}

const CurrentConsumptionPage = ({ spaceHeatingDemandKwh, hotWaterDemandKwh, existingSystemEfficiency, survey, setSurvey, setPage, isOffline, allSynced }: CurrentConsumptionPageProps) => {
  const heatingFuel = getHeatingFuel(survey.existing_system_fuel_uuid)
  const consumptionFuelUnits = survey.existing_system_annual_consumption_kWh > 0 ? Math.round(survey.existing_system_annual_consumption_kWh / heatingFuel.kWh_per_unit) : ''

  return <div className='flex flex-col h-full min-h-0'>
    <PageHeader isOffline={isOffline} allSynced={allSynced} title='Based on your existing consumption' onBack={() => setPage('')} />
    <div className='flex flex-col gap-6 p-5 overflow-y-auto'>
      <div className='text-gray-500'>The property's existing consumption and heating system efficiency gives another way
        to estimate how much heat the property actually uses.
      </div>
      <div className="flex-col gap-2 flex">
        <div className="text-gray-900 font-bold">{heatingFuel.name} annual consumption</div>
        <div className="text-gray-500 text-sm">E.g. from a recent bill or from the smart meter in-home display</div>
        <Input
          step={0.01}
          type='number'
          value={consumptionFuelUnits.toString()} // Careful - number entered in heating fuel unit but stored in kWh. Done to prevent errors in unit when the fuel is changed.
          setValue={(e) => setSurvey({
            ...survey,
            existing_system_annual_consumption_kWh: Number(e) * heatingFuel.kWh_per_unit
          })}
          postfix={heatingFuel.unit}/>
      </div>
      <div>
        <div className='text-gray-900 font-bold'>Existing system efficiency</div>
        <div>{existingSystemEfficiency * 100} %</div>
      </div>
      <div>
        <div className='text-gray-900 font-bold'>Heating energy required</div>
        <div>{spaceHeatingDemandKwh}</div>
      </div>
      <div>
        <div className='text-gray-900 font-bold'>Hot water energy required</div>
        <div>{hotWaterDemandKwh}</div>
      </div>
    </div>
  </div>
}

type FuelInputsProps = {
  survey: PropertySurvey
  setSurvey: (survey: PropertySurvey) => void
  setPage: Dispatch<SetStateAction<string>>
  scopSpaceHeating: number
  scopHotWater: number
  heatingFuel: HeatingFuel
  heatingSystemEfficiency: number
  isOffline: boolean
  allSynced: boolean
}

export const FuelInputsPage = ({
  survey,
  setSurvey,
  setPage,
  scopSpaceHeating,
  scopHotWater,
  heatingFuel,
  heatingSystemEfficiency,
  isOffline,
  allSynced
}: FuelInputsProps) => {
  const electricity = HEATING_FUELS.find(x => x.uuid === 'electricity')!
  return <div className='flex flex-col h-full min-h-0'>
    <PageHeader isOffline={isOffline} allSynced={allSynced} title='Fuel inputs' onBack={() => setPage('')}/>
    <div className='flex flex-col gap-6 p-5 overflow-y-auto'>
      <div className='font-bold text-gray-900 text-xl'>Existing system</div>
      <div className='flex flex-col gap-2'>
        <div className='flex flex-col gap-1'>
          <div className="text-gray-900 font-bold">Efficiency</div>
        </div>
        <div className="text-gray-600">{heatingSystemEfficiency * 100}%</div>
      </div>
      <div className='flex flex-col gap-2'>
        {heatingFuel.uuid !== 'electricity' ? <>
          <div className='font-bold text-gray-900'>{heatingFuel.name} tariff</div>
          <Input
            step={0.01}
            type='number'
            value={getHeatingPencePerUnit(heatingFuel, survey.existing_system_p_per_unit_override).toString()}
            setValue={(e) => setSurvey({ ...survey, existing_system_p_per_unit_override: parseFloat(e) })}
            postfix={<div className='flex items-center gap-2.5'>
              <div>{heatingFuel.penceUnit}</div>
              <div>{defaultTariffIsOverridden(heatingFuel, survey.existing_system_p_per_unit_override) &&
                    <Icon icon={XCircle}
                      onClick={() => setSurvey(({ ...survey, existing_system_p_per_unit_override: undefined }))}
                      colour='text-gray-400'/>}</div>
            </div>}/>
        </> : <>
          <div className='flex flex-col gap-1'>
            <div className="text-gray-900 font-bold">{heatingFuel.name} tariff</div>
          </div>
          <div
            className="text-gray-600">{getElectricityPencePerKwh(survey.electricity_p_per_kwh_override)} {electricity.penceUnit}</div>
        </>}
      </div>
      <div className='flex flex-col gap-2'>
        <div className='font-bold text-gray-900'>Emissions</div>
        <div>{heatingFuel.emissions_g_per_kwh} g/kWh</div>
      </div>

      <div className='border border-gray-200'/>

      <div className='font-bold text-gray-900 text-xl'>Heat pump</div>
      <div className='flex flex-col gap-2'>
        <div className='font-bold text-gray-900'>Efficiency</div>
        <div>Heating {(scopSpaceHeating * 100).toFixed()} %</div>
        <div>Hot water {scopHotWater * 100} %</div>
      </div>
      <div className='flex flex-col gap-2'>
        <div className="text-gray-900 font-bold">Electricity tariff</div>
        <Input
          step={0.01}
          type='number'
          value={getElectricityPencePerKwh(survey.electricity_p_per_kwh_override).toString()}
          setValue={(e) => setSurvey({ ...survey, electricity_p_per_kwh_override: parseFloat(e) })}
          postfix={<div className='flex items-center gap-2.5'>
            <div>{electricity?.penceUnit}</div>
            <div>{defaultTariffIsOverridden(electricity, survey.electricity_p_per_kwh_override) &&
                  <Icon
                    icon={XCircle}
                    onClick={() => setSurvey(({ ...survey, electricity_p_per_kwh_override: undefined }))}
                    colour='text-gray-400' />}
            </div>
          </div>}/>
      </div>
      <div className='flex flex-col gap-2'>
        <div className='font-bold text-gray-900'>Emissions</div>
        <div>{electricity?.emissions_g_per_kwh} g/kWh</div>
      </div>
    </div>
  </div>
}
