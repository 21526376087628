import React from 'react'

type Props = {
  header?: React.ReactNode
  children: React.ReactNode
  stickyHeader?: boolean
}

export const SimpleLayout = ({ header, children, stickyHeader }: Props) => {
  return (
    <div className={'flex flex-col flex-1 bg-gray-100 md:flex-col overflow-y-auto'}>
      <div className={`bg-white px-6 py-4 ${stickyHeader ? 'sticky top-0 z-10 border-b border-gray-200' : ''}`}>
        {header}
      </div>
      {children}
    </div>
  )
}
