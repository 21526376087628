import { useState, useEffect } from 'react'

const DATABASE_NAME = 'fallback'

export const useIndexedDBFallback = <T>(
  tableName: string,
  key: string,
  initialValue: T,
  fetcher: () => Promise<T | undefined>,
  onLoadFinished: (value: T) => void
) => {
  const [db, setDB] = useState<IDBDatabase | null>(null)
  const [value, setValue] = useState<T>(initialValue)
  const [hasFetchingError, setHasFetchingError] = useState<boolean>(false)

  useEffect(() => {
    let database: IDBDatabase

    const request = window.indexedDB.open(DATABASE_NAME)
    request.onsuccess = (event) => {
      database = (event.target as IDBRequest).result
      setDB(database)
    }
    request.onerror = (e) => console.log('ERROR: ', e)
    request.onupgradeneeded = (event) => {
      database = (event.target as IDBRequest).result
      if (!database.objectStoreNames.contains(tableName)) {
        database.createObjectStore(tableName, { keyPath: 'id' })
      }
      setDB(database)
    }

    return () => {
      if (db) {
        db.close()
        setDB(null)
      }
    }
  }, [])

  useEffect(() => {
    if (!db) return
    getValue()
  }, [db])

  const getValue = async () => {
    if (!db) return

    // try fetch data from the network
    const data = await fetcher()

    // if data exists and is not an error, return it
    if (data) {
      // save the value to the DB
      setHasFetchingError(false)
      updateValue(data)
      onLoadFinished(data)
      return
    }

    // if there is no data (usually means a fetching error), set the flag
    setHasFetchingError(true)

    // get the value from the DB
    const transaction = db.transaction(tableName, 'readonly')
    const store = transaction.objectStore(tableName)
    const request = store.get(key)
    request.onsuccess = () => {
      const storedValue = request.result ? request.result.value : null

      if (storedValue !== null) {
        // if the value is in the DB, return it
        setValue(storedValue)
        onLoadFinished(storedValue)
      } else {
        // otherwise use the initial value
        setValue(initialValue)
        onLoadFinished(initialValue)
      }
    }
    request.onerror = (e) => console.log('ERROR: ', e)
  }

  const updateValue = (newValue: T) => {
    setValue(newValue)
    if (!db) return
    const transaction = db.transaction(tableName, 'readwrite')
    const store = transaction.objectStore(tableName)
    const request = store.put({ id: key, value: newValue })
    request.onerror = (e) => console.log('ERROR: ', e)
  }

  const handleReload = async () => {
    if (!db) return
    await getValue()
  }

  return [value, updateValue, hasFetchingError, handleReload] as const
}
