import React from 'react'
import { Info } from '../../../components/buttons/info'

export const InfoDemo = () => {
  return <div className="flex flex-col gap-8">
    <div className='flex flex-col gap-4'>
      <div className='text-2xl font-bold text-gray-900'>Definition</div>

      <pre className='bg-gray-100 rounded-lg p-4 overflow-x-auto'><code>
        {codeBlock}
      </code></pre>

      <div className='flex flex-col divide-y divide-dashed divide-gray-200 text-sm'>
        {componentProps.map(x => <div key={x.name} className='grid grid-cols-2 py-2'>
          <div className='font-bold text-gray-900'>{x.name}</div>
          <div>{x.description}</div>
        </div>)}
      </div>
    </div>

    <div className='flex flex-col gap-4'>
      <div className='text-2xl font-bold text-gray-900'>Component</div>
      <div className='flex justify-start items-end gap-4'>
        <Info infoModalHeader={'What is this?'} infoModalBody={'Lots of nice info about heat pumps'} ></Info>
      </div>
    </div>

  </div>
}

const componentProps = [
  { name: 'infoModalHeader', description: 'What text should be in the header of the modal that opens on click' },
  { name: 'infoModalBody', description: 'What text should be in the body of the modal that opens on click [optional]' },
  { name: 'colour', description: 'What colour should the icon be [optional - defaults to text-gray-400]' },
  { name: 'className', description: 'Any additional classes you want to add to the component [optional]' }
]

const codeBlock = `<Info
    infoModalHeader={"What is this?"}
    infoModalBody={undefined}
    colour={"text-red-800"}  // default is text-gray-400
></Info>`
