import React, { useContext } from 'react'
import { AdminContext } from './admin_layout'
import { Menu } from 'lucide-react'

type Props = {
  backURL?: string
  customHeader?: string
  customMessage?: string
}

export const UnauthorizedPage = ({ customHeader, customMessage }: Props) => {
  const adminContext = useContext(AdminContext)

  return <div className='w-full'>
    <div className='px-4 py-4 flex gap-x-4 items-center'>
      <Menu data-cy='nav_open' className="lg:hidden h-8 w-8 cursor-pointer" onClick={() => {
        adminContext.showSidebar()
      }}/>
    </div>
    <div className='p-8 flex flex-col gap-4'>
      <div className='flex flex-col gap-2'>
        <div className='text-lg'>{ customHeader ?? 'You are not authorized to access this page.'}</div>
        <div className=''>{ customMessage ?? 'Try logging in as a different user.'}</div>
      </div>
    </div>
  </div>
}
