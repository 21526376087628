import React, { type PropsWithChildren, useState, type FC } from 'react'
import { type Toast, toastError } from './components/indicators_and_messaging/toast'
import { type AxiosError } from 'axios'
import { noop } from 'lodash'

export type AppStateType = {
  toastState?: Toast
  source: string
}

export type AppContextType = {
  globalState: AppStateType
  setGlobalState: (state: (prev: any) => any) => void
  setError: (e: unknown) => void
}

export const AppContext = React.createContext<AppContextType>({
  globalState: {
    source: ''
  },
  setGlobalState: noop,
  setError: noop
})

export const AppProvider: FC<PropsWithChildren> = ({ children }) => {
  const [globalState, setGlobalState] = useState<AppStateType>({
    source: ''
  })

  const setError = (e: any) => {
    const axiosError = e as AxiosError<any>
    if (axiosError.response?.status === 401) {
      // TODO: logout setGlobalState((prev) => ({ ...prev, isLoggedIn: false }));
      // setGlobalState((prev) => ({ ...prev, toastState: toastError("You are not logged in.") }));
      // return;
    }

    let errorMessage = 'We had an issue fulfilling that request, please contact joseph@spruce.eco.'
    if (axiosError.response?.data) {
      errorMessage = axiosError.response.data.detail?.length > 0
        ? axiosError.response.data.detail
        : <ul>{(axiosError.response.data.errors?.map(x => x.detail) as string[]).map(x => <li key={x}>{x}</li>)}</ul>
    }

    setGlobalState((prev) => ({ ...prev, toastState: toastError(errorMessage) }))
    if (axiosError.response?.status !== 401) throw e
  }

  return (
    <AppContext.Provider value={{ globalState, setGlobalState, setError }}>
      {children}
    </AppContext.Provider>
  )
}
