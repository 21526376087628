import React from 'react'
import { type ReactElement } from 'react'

type HLRListBlockProps = {
  items: ReactElement[]
  isNumbered?: boolean
  className?: string
  gapSize?: number
}

// We can't use <ul> or <ol> because they have a lot of default styling that we don't want
// e.g. offset between the bullet and the text, padding, etc.
// so we're using a flexbox container with custom styling instead
export const HLRListBlock = ({ items, isNumbered, className, gapSize = 2 }: HLRListBlockProps) => {
  if (items.length === 0) return <></>

  // gap between the bullet and the text
  const gapClass = 'gap-' + gapSize

  // item width class for make the bullets/items column equal width
  const itemWidthClass = 'w-' + (gapSize + 1)

  return (
    <div className='w-full flex flex-col'>
      { items.map((item, idx) => (
        <div key={idx} className={`${gapClass} flex items-start justify-start`}>
          { isNumbered && <div className={`inline-block ${className} ${itemWidthClass}`}>{idx + 1}.</div> }
          { !isNumbered && <div className={`${className} ${itemWidthClass}`}>•</div> }
          <div className={className}>{item}</div>
        </div>
      )) }
    </div>
  )
}
