import React from 'react'
import { noop } from 'lodash'
import { Section } from '../../../components/containers/section'

export const SectionDemo = () => {
  return <div className="flex flex-col gap-8">
    <div className='flex flex-col gap-4'>
      <div className='text-2xl font-bold text-gray-900'>Definition</div>

      <pre className='bg-gray-100 rounded-lg p-4 overflow-x-auto'><code>
        {codeBlock}
      </code></pre>

      <div className='flex flex-col divide-y divide-dashed divide-gray-200 text-sm'>
        {componentProps.map(x => <div key={x.name} className='grid grid-cols-2 py-2'>
          <div className='font-bold text-gray-900'>{x.name}</div>
          <div>{x.description}</div>
        </div>)}
      </div>
    </div>

    <div className='flex flex-col gap-4'>
      <div className='text-2xl font-bold text-gray-900'>Component</div>
      <Section
        title='Section title'
        className=''
        border={true}
        padding={true}
        onClick={noop}
      >Hello world</Section>
    </div>

  </div>
}

const componentProps = [
  { name: 'title', description: 'Title of the section' },
  { name: 'className', description: 'Any additional styling or positioning required' },
  { name: 'border', description: 'If the section has an outer border' },
  { name: 'padding', description: 'If the section should include any padding' },
  { name: 'onClick', description: 'Show right chevron and cursor-pointer if populated, complete some action' }
]

const codeBlock = `<Section
  title='Section title'
  className=''
  border={true}
  padding={true}
  onClick={noop}
>Hello world</Section>`
