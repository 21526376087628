import React, { type Dispatch, type SetStateAction, useState } from 'react'
import { PageHeader } from '../components/design_page_header'
import { type QuoteLineItem } from '../../../../code/models/property'
import { Button } from '../../../../components/buttons/button'
import { Input } from '../../../../components/inputs_and_selections/input'
import { sum } from 'lodash'
import { ChevronLeft, ChevronRight, Trash } from 'lucide-react'
import { WrappedIcon } from '../../../../components/buttons/wrapped_icon'

type QuoteDesignPageProps = {
  defaultLineItems: QuoteLineItem[]
  lineItems: QuoteLineItem[]
  setLineItems: (lineItems: QuoteLineItem[]) => void
  isOffline: boolean
  allSynced: boolean
}

export const QuoteDesignPage = ({ defaultLineItems, lineItems, setLineItems, isOffline, allSynced }: QuoteDesignPageProps) => {
  const [currentLineItem, setCurrentLineItem] = useState<QuoteLineItem>()

  const onDelete = () => {
    setLineItems(lineItems.filter(x => x.uuid !== currentLineItem?.uuid))
    setCurrentLineItem(undefined)
  }

  const onSave = () => {
    setLineItems(currentLineItem?.uuid ? lineItems.map(x => x.uuid === currentLineItem.uuid ? currentLineItem : x) : [...lineItems, { ...currentLineItem!, uuid: crypto.randomUUID() }])
    setCurrentLineItem(undefined)
  }

  const hydratedLineItems = [...defaultLineItems.map(dli => {
    const override = lineItems.find(li => li.type === dli.type)
    return override || dli
  }), ...lineItems.filter(x => x.type === 'ADDITIONAL')]

  const total = sum(hydratedLineItems.map(x => x.value * x.quantity!))

  if (currentLineItem) return <EditOrAddLineItem lineItem={currentLineItem} setLineItem={setCurrentLineItem} onSave={onSave} onDelete={onDelete} />

  return <div className='flex flex-col h-full'>
    <PageHeader isOffline={isOffline} allSynced={allSynced} title='Quote' onBack={() => window.history.back()} />
    <div className='flex flex-col p-4 divide divide-y divide-gray-200 overflow-y-auto'>
      {hydratedLineItems.map(x => <div key={x.name} onClick={() => setCurrentLineItem(x)} className='flex justify-between p-2 items-center cursor-pointer'>
        <div className='flex flex-col'>
          <div className='font-bold text-gray-900'>{x.name}</div>
          <div className='text-gray-600'>{x.quantity} x £{x.value}</div>
        </div>
        <div className='flex gap-4 items-center'>
          {x.quantity && <div className='text-gray-600'>£{x.quantity * x.value}</div>}
          <WrappedIcon icon={ChevronRight} className='text-gray-600 cursor-pointer'></WrappedIcon>
        </div>
      </div>)}
      <div className='p-2 flex justify-between font-bold text-gray-900'>
        <div>Total</div>
        <div>£{total}</div>
      </div>
    </div>
    <div className='flex p-4'>
      <Button block={true} onClick={() => setCurrentLineItem({ uuid: undefined, name: '', value: 0, quantity: 0, type: 'ADDITIONAL' })}>Add item</Button>
    </div>
  </div>
}

type EditOrAddLineItemProps = {
  lineItem: QuoteLineItem
  setLineItem: Dispatch<SetStateAction<QuoteLineItem | undefined>>
  onSave: () => void
  onDelete: () => void
}

const EditOrAddLineItem = ({ lineItem, setLineItem, onSave, onDelete }: EditOrAddLineItemProps) => {
  return <div>
    <div className='flex justify-between gap-3 px-5 py-4 bg-white border-b border-gray-200 items-center'>
      <div className='flex flex-wrap items-center gap-3 cursor-pointer' onClick={() => setLineItem(undefined)} >
        <WrappedIcon className='text-gray-600 cursor-pointer' icon={ChevronLeft} />
        <div className='text-gray-900 text-xl font-bold'>Line item</div>
      </div>
      <div className='flex gap-2 items-center'>
        {lineItem.type === 'ADDITIONAL' && lineItem.uuid && <WrappedIcon onClick={onDelete} className='text-gray-600 cursor-pointer' icon={Trash} />}
      </div>
    </div>
    <div className='flex flex-col gap-4 p-4'>
      <div className='flex flex-col gap-2'>
        <div className='font-bold text-gray-900'>Description</div>
        <Input value={lineItem.name} setValue={(e) => setLineItem(prev => ({ ...prev!, name: e }))} />
      </div>
      <div className='flex flex-col gap-2'>
        <div className='font-bold text-gray-900'>Price (£)</div>
        <Input type="number" value={lineItem.value.toString()} setValue={(e) => setLineItem(prev => ({ ...prev!, value: Number(e) }))} />
      </div>
      <div className='flex flex-col gap-2'>
        <div className='font-bold text-gray-900'>Quantity</div>
        <Input type="number" value={lineItem.quantity?.toString() ?? ''} setValue={(e) => setLineItem(prev => ({ ...prev!, quantity: Number(e) }))} />
      </div>
      <div className='flex justify-between'>
        <div className='font-bold text-gray-900'>Total</div>
        {lineItem.quantity && <div className='text-gray-600'>£{lineItem.quantity * lineItem.value}</div>}
      </div>
      <div className='flex gap-2'>
        {lineItem.type !== 'ADDITIONAL' && lineItem.uuid && <Button className='flex-1' onClick={onDelete}>Reset to default</Button>}
        <Button className='flex-1' onClick={onSave}>Save</Button>
      </div>
    </div>
  </div>
}
