import { type AxiosError } from 'axios'
import { apiUrl, client } from '../axios'
import { type EpcScotland, getEpcScotlandByUPRN } from './epc_scotland'
import type { Lead } from './lead'
import {
  getBuiltForm,
  getConstructionAge,
  getFloorInsulation,
  getLoftInsulation,
  getPropertyType,
  getUValue,
  getWallGroup,
  getWallType,
  getWindowType
} from './u_value'
import { parseConstructionAgeBand } from './age_bands'
import { tryParseFloat } from '../helpers'
import { getFuelTypeFromEPC } from './fuel'
import { type Property } from './property'
import { getNumberOfBedroomsFromHabitableRooms } from './estimated_rooms_and_radiators'
import { z } from 'zod'

export const EPCRatingSchema = z.enum(['A', 'B', 'C', 'D', 'E', 'F', 'G'])
export type EPCRating = z.infer<typeof EPCRatingSchema>

// Note: these are currently all strings because that's how they come from the API
// TODO later: use zod to coerce these to the correct types. SPR-1296
export const EPCSchema = z.object({
  builtForm: z.string(),
  currentEnergyRating: EPCRatingSchema,
  floorHeight: z.string(),
  inspectionDate: z.string(),
  lmkKey: z.string(),
  lodgementDate: z.string(),
  mainFuel: z.string(),
  propertyType: z.string(),
  totalFloorArea: z.string(),
  uprn: z.string(),
  windowsDescription: z.string(),
  floorDescription: z.string(),
  roofDescription: z.string(),
  wallsDescription: z.string(),
  posttown: z.string(),
  constructionAgeBand: z.string(),
  numberHeatedRooms: z.string(), // unsure how this is used in SAP (the EPC "Standard Assessment Procedure used to calculate EPC scores) so better to use the numberHabitableRooms. In data looks like numberHabitableRooms is typically greater
  numberHabitableRooms: z.string(), // number of rooms excluding bathrooms and kitchen (unless kitchen can fit a dining table)
  transactionType: z.string(),
  address: z.string()
})
export type EPC = z.infer<typeof EPCSchema>

// TODO: check if there was a good reason for the keys being strings here
export const EPCRecommendationSchema = z.object({
  'improvement-descr-text': z.string(), // often empty
  'improvement-id': z.string(),
  'improvement-id-text': z.string(), // often empty
  'improvement-item': z.string(),
  'improvement-summary-text': z.string(), // often empty
  'indicative-cost': z.string(),
  'lmk-key': z.string()
})

export type EPCRecommendation = z.infer<typeof EPCRecommendationSchema>

export const getEpcRecommendations = async (epcId: string): Promise<EPCRecommendation[]> => {
  try {
    const result = await client.get<EPCRecommendation[]>(`${apiUrl}recommendations?id=${epcId}`)
    return result.data
  } catch (e) {
    const axiosError = e as AxiosError
    if (axiosError.response?.status === 404) return []

    throw e
  }
}

// Returns the latest EPC for a given English or Welsh UPRN
export const getEpcForUprn = async (uprn: string): Promise<EPC | undefined> => {
  try {
    // English EPCs are fetched from the EPC API
    const result = await client.get<EPC>(`${apiUrl}epcs/${uprn}`)
    return result.data
  } catch (e) {
    const axiosError = e as AxiosError
    if (axiosError.response?.status === 404) return undefined
    throw e
  }
}

// Returns the latest EPC for a given Scottish UPRN
export const getEpcScottishForUprn = async (uprn: string): Promise<EpcScotland | undefined> => {
  try {
    // Scottish EPCs are in the database
    return await getEpcScotlandByUPRN(uprn)
  } catch (e) {
    const axiosError = e as AxiosError
    if (axiosError.response?.status === 404) return undefined
    throw e
  }
}

export const epcToBackgroundColour = (rating: EPCRating) => {
  if (rating === 'A') return 'bg-green-600'
  if (rating === 'B') return 'bg-green-500'
  if (rating === 'C') return 'bg-green-400'
  if (rating === 'D') return 'bg-yellow-500'
  if (rating === 'E') return 'bg-orange-400'
  if (rating === 'F') return 'bg-orange-500'
  if (rating === 'G') return 'bg-red-500'
}
export const parseEnglishEpc = async (epc: EPC, lead: Lead) => {
  const newLead = { ...lead }
  newLead.epcId = epc.lmkKey
  newLead.epcData = epc
  newLead.property = parseEnglishEpcInner(epc, newLead.property) || newLead.property
  newLead.epc_recommendations = epc ? await getEpcRecommendations(epc.lmkKey) : []

  return newLead
}
export const parseEnglishEpcInner = (epc: EPC, property: Property) => {
  if (!epc) return

  const wallGroup = getWallGroup(epc.wallsDescription)
  const constructionAge = getConstructionAge(epc)
  const parsedConstructionAgeBand = parseConstructionAgeBand(constructionAge)
  const noBedrooms = getNumberOfBedroomsFromHabitableRooms(parseInt(epc.numberHabitableRooms))

  return {
    ...property,
    yearBuilt: constructionAge,
    construction_age_band_uuid: parsedConstructionAgeBand?.uuid,
    construction_age_band: parsedConstructionAgeBand,
    epcRating: epc.currentEnergyRating,
    floorArea: tryParseFloat(epc.totalFloorArea, property.floorArea) ?? 0,
    roomHeight: tryParseFloat(epc.floorHeight, property.roomHeight) ?? 0,
    propertyType: getPropertyType(epc),
    builtForm: getBuiltForm(epc),
    windowType: getWindowType(epc),
    fuelType: getFuelTypeFromEPC(epc.mainFuel),
    wallGroup,
    wallType: (wallGroup && getWallType(epc.wallsDescription, wallGroup)),
    floorType: (getFloorInsulation(epc.floorDescription)),
    loftInsulation: getLoftInsulation(epc.roofDescription),
    windowUValue: getUValue(epc.windowsDescription),
    floorUValue: getUValue(epc.floorDescription),
    roofUValue: getUValue(epc.roofDescription),
    wallUValue: getUValue(epc.wallsDescription),
    noBedrooms,
    noBathrooms: getNumberOfBedroomsFromHabitableRooms(noBedrooms)
  }
}
