import React, { useContext } from 'react'
import { acceptTermsAndConditions, type TermsAndConditions } from '../../../code/models/terms_and_conditions'
import { Modal } from '../../../components/containers/modal'
import { Link } from '../../../components/buttons/link'
import { AdminContext } from '../admin_layout'

type Props = {
  visible: boolean
  setVisible: React.Dispatch<boolean>
  termsAndConditions?: TermsAndConditions
}

export const TermsAndConditionsModal = ({ visible, setVisible, termsAndConditions }: Props) => {
  const adminContext = useContext(AdminContext)

  return (
    <Modal
      title='Terms & Conditions'
      visible={visible}
      setVisible={setVisible}
      confirmButtonLabel='Accept'
      onConfirm={async () => {
        if (await acceptTermsAndConditions() && termsAndConditions) {
          adminContext.acceptTermsAndConditions(termsAndConditions.id)
          setVisible(false)
        }
      }}
      cancelButtonLabel="I'll do it later"
    >
      <div className="flex flex-col gap-3">
        <div>
            Hello! Before you carry on, we’d be really grateful if you could please review and accept our <Link text='Terms & Conditions' className="inline" onClick={() => window.open(termsAndConditions?.url, '_blank')} />.
        </div>
        <div>
            We’ve recently introduced these to give both us and you requisite legal protections and to improve our GDPR compliance.
        </div>
        <div>
            If you have any questions about these, please contact Jo on joseph@spruce.eco or 07795533150.
        </div>
        <div>
            Thank you!
        </div>
      </div>
    </Modal>
  )
}
