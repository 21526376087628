import * as axios from 'axios'
import { setError } from '../components/indicators_and_messaging/toast'
import { AuthSDK } from './utils/auth_provider'

export const url = process.env.API_URL
const api = 'api/v1/'
export const apiUrl = `${url}${api}`
export const client = axios.default
// client.defaults.withCredentials = true;

export const axiosGet = async <T>(relativeUrl: string) => await axiosGetInner<T>(relativeUrl)
const axiosGetInner = async <T>(relativeUrl: string) => {
  const result = await client.get<{ data: Array<{ uuid: string, attributes: T }> }>(`${apiUrl}${relativeUrl}`, {
    headers: {
      'x-auth-token': AuthSDK.getToken()
    }
  })
  return result.data.data.map(x => ({ ...x.attributes, uuid: x.uuid }))
}

export const axiosPatch = async <T>(relativeUrl: string, data: T, id: string, type: string) => {
  await axiosPatchInner<T>(relativeUrl, data, id, type)
}
const axiosPatchInner = async <T>(relativeUrl: string, data: T, id: string, type: string) => {
  await client.patch(`${apiUrl}${relativeUrl}`, {
    data: {
      type,
      attributes: data
    }
  }, {
    headers: {
      'Content-Type': 'application/vnd.api+json',
      'x-auth-token': AuthSDK.getToken()
    }
  })
}

export const axiosPost = async <T>(relativeUrl: string, data: T, type: string) => {
  return await axiosPostInner<T>(relativeUrl, data, type)
}

const axiosPostInner = async <T>(relativeUrl: string, data: T, type: string): Promise<string> => {
  const result = await client.post(`${apiUrl}${relativeUrl}`, {
    data: {
      type,
      attributes: data
    }
  }, {
    headers: {
      'Content-Type': 'application/vnd.api+json',
      'x-auth-token': AuthSDK.getToken()
    }
  })
  return result.data.data.uuid
}

export const axiosDelete = async (relativeUrl: string) => {
  await axiosDeleteInner(relativeUrl)
}
const axiosDeleteInner = async (relativeUrl: string) => {
  await client.delete(`${apiUrl}${relativeUrl}`, {
    headers: {
      Accept: 'application/vnd.api+json',
      'x-auth-token': AuthSDK.getToken()
    }
  })
}

// V2 endpoints without JSON API
export const axiosGetV2 = async <T>(relativeUrl: string, config?: axios.AxiosRequestConfig) => {
  try {
    const result = await client.get<T>(`${apiUrl}${relativeUrl}`, { headers: { 'x-auth-token': AuthSDK.getToken() }, ...config })
    return result.data
  } catch (e) {
    setError(e)
  }
}

export const axiosPostV2 = async <T>(relativeUrl: string, data: any, config?: axios.AxiosRequestConfig): Promise<T> => {
  try {
    const result = await client.post<T>(`${apiUrl}${relativeUrl}`, data, { headers: { 'x-auth-token': AuthSDK.getToken() }, ...config })
    return result.data
  } catch (e) {
    setError(e)
    return e
  }
}

export const axiosPostUnwrapped = async <T>(relativeUrl: string, data: any) => {
  const result = await client.post<T>(`${apiUrl}${relativeUrl}`, data, { headers: { 'x-auth-token': AuthSDK.getToken() } })
  return result.data
}

export const axiosPatchV2 = async (relativeUrl: string, data: any) => {
  try {
    await client.patch(`${apiUrl}${relativeUrl}`, data, { headers: { 'x-auth-token': AuthSDK.getToken() } })
  } catch (e) {
    setError(e)
  }
}

export const axiosDeleteV2 = async (relativeUrl: string) => {
  try {
    await client.delete(`${apiUrl}${relativeUrl}`, { headers: { 'x-auth-token': AuthSDK.getToken() } })
  } catch (e) {
    setError(e)
  }
}
