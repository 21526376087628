import React from 'react'
import { Caption } from './caption_1'
import { Header } from './header'

type StepHeaderProps = {
  guideText?: string
  headerText: string
  captionText?: string
}

export const StepHeader: React.FC<StepHeaderProps> = ({ guideText, headerText, captionText }) => {
  return <div className="flex flex-col gap-2">
    <div className="flex flex-col gap-2">
      {guideText && <Caption content={guideText} size="sm" uppercase={true} />}
      <Header content={headerText} />
    </div>
    {captionText && <Caption content={captionText} />}
  </div>
}
