import { Trash } from 'lucide-react'
import { formatMaterialUValue, getMaterialUValue, type Material } from '../../../code/models/material'
import { WrappedIcon } from '../../../components/buttons/wrapped_icon'
import { Badge } from '../../../components/indicators_and_messaging/badge'
import React from 'react'

export const MaterialItemBlock = ({ selectedMaterial, material, onClick, onDelete }: { selectedMaterial: Material, material: Material, onClick: () => void, onDelete?: () => void }) => {
  const uValue = getMaterialUValue(material)
  // TODO: should be able to refactor this to use navCard

  return <div className="cursor-pointer self-stretch px-5 justify-center items-center gap-2 inline-flex bg-white">
    <div className="grow shrink basis-0 py-3 border-t border-gray-200 justify-center items-center gap-3 flex">
      <div className="grow shrink basis-0 text-gray-600 text-sm font-semibold" onClick={onClick}>
        {material.name}
      </div>
      {uValue !== undefined && <Badge color='LIGHT' text={formatMaterialUValue(uValue)}/>}

      {/* if on delete callback is provided and the material is not generic (i.e. a custom material not provided by us), show the delete button */}
      { (onDelete && material.type !== 'generic' && selectedMaterial?.uuid !== material.uuid) && <WrappedIcon
        className='h-5 w-5 text-gray-400'
        icon={Trash}
        onClick={onDelete}
      />}
    </div>
  </div>
}
