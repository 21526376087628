import React from 'react'
import { Text } from '../../../../components/content_display/text'

type Props = {
  title: string | React.ReactNode
  value: string | number | React.ReactNode
  info?: React.JSX.Element
}

export const InfoCard = ({ title, value, info }: Props) => {
  return (
    <div className={`px-4 py-3 bg-gray-100 rounded-md ${info && 'cursor-pointer'}`}>
      <div className='flex gap-1 items-center'>
        <Text size="XS" bold={true}>{title}</Text>
        {info}
      </div>
      <Text size="MD">{value}</Text>
    </div>
  )
}
