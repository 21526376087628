import { Button } from '../../components/buttons/button'
import React, { useContext } from 'react'
import { AdminContext } from './admin_layout'
import { Menu } from 'lucide-react'

type Props = {
  backURL?: string
  customHeader?: string
  customMessage?: string
  navigateTo: (url: string) => void
}

export const OfflinePage = ({ backURL, customHeader, customMessage, navigateTo }: Props) => {
  const adminContext = useContext(AdminContext)

  return <div className='w-full'>
    <div className='px-4 py-4 flex gap-x-4 items-center'>
      <Menu data-cy='nav_open' className="lg:hidden h-8 w-8 cursor-pointer" onClick={() => {
        adminContext.showSidebar()
      }}/>
    </div>
    <div className='p-8 flex flex-col gap-4'>
      <div className='flex flex-col gap-2'>
        <div className='text-lg'>{ customHeader ?? 'Unable to connect to the internet.'}</div>
        <div className=''>{ customMessage ?? 'This page is not accessible offline'}</div>
      </div>
      { backURL && <Button className='w-32' onClick={() => {
        navigateTo(backURL)
      }}>Back</Button> }
    </div>
  </div>
}
