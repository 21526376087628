import React, { Fragment } from 'react'
import { HLRTitlePage } from './hlr_title_page'
import { HLRSummaryPage } from './hlr_summary_page'
import { HLRIntroductionPage } from './hlr_introduction_page'
import { HLRCalculationConditionsPage } from './hlr_calculation_conditions_page'
import { HLRHeatlossByElementPage } from './hlr_heatloss_by_element_page'
import { HLRHeatlossByRoomPage } from './hlr_heatloss_by_room_page'
import { HLRFloorHeatlossPage } from './hlr_floor_heatloss_page'
import { HLRRoomHeatlossPage } from './hlr_room_heatloss_page'
import type { Property, PropertySurvey, SurveyDesign } from '../../../code/models/property'
import { type CustomerDetailsType } from '../../../code/models/lead'
import {
  type CylinderReheatCalculationRow,
  getCylinderReheatCalculationRow,
  getDailyHotWaterVolumeL,
  getHotWaterCalculationRowLegionella,
  getHotWaterCalculationRowNormalOperation,
  getNumberOfOccupants,
  type HotWaterCalculationRow
} from '../../../code/models/hot_water_cylinder'
import type { TableColumn } from '../../../components/content_display/table_lite'
import {
  eligibleForHeatPumpPlus,
  getPerformanceEstimateSummary,
  type PerformanceEstimateRow,
  type PerformanceEstimateSummary
} from '../../../code/models/performance_estimate'
import type { FileWrapper } from '../file_wrapper'
import { type CompanyPublicInfo } from '../../../code/models/company'
import { HLRHeatPump } from './hlr_heat_pump'
import { HLREmittersIntro } from './hlr_emitters_intro'
import { HLREmittersCurrent } from './hlr_emitters_current'
import { HLREmittersProposed } from './hlr_emitters_proposed'
import { HLRHotWater } from './hlr_hot_water'
import { HLRHotWaterDemand } from './hlr_hot_water_demand'
import { HLRSoundAssessment } from './hlr_sound_assessment'
import { HLRPESummary } from './hlr_pe_summary'
import { HLRPEDetailedResultsIntroduction } from './hlr_pe_detailed_results_intro'
import { HLRPEResultsTable } from './hlr_pe_results_table'
import { HLRPEInputsAndAssumptions } from './hlr_pe_inputs_and_assumptions'
import { HLRPESystemEfficiency1 } from './hlr_pe_system_efficiency_1'
import { HLRPESystemEfficiency2 } from './hlr_pe_system_efficiency_2'
import { HLRPEFlowTempChart } from './hlr_pe_flow_temp_chart'
import { HLRPEMCSKeyFacts } from './hlr_pe_mcs_key_facts'
import { getFullEmittersListByStatus } from '../../../code/models/radiator'
import { type InventoryHeatPump, type InventoryHotWaterCylinder } from '../../../code/models/inventory'
import {
  getHLReportPDFAPI,
  HEATLOSS_REPORT_SECTION_DESIGN,
  HEATLOSS_REPORT_SECTION_HEATLOSS_INTRO,
  HEATLOSS_REPORT_SECTION_HEATLOSS_PER_ROOM,
  HEATLOSS_REPORT_SECTION_HEATLOSS_SUMMARY,
  HEATLOSS_REPORT_SECTION_PERFORMANCE_ESTIMATE,
  HEATLOSS_REPORT_SECTION_SOUND_ASSESSMENT,
  HEATLOSS_REPORT_SECTION_SUMMARY,
  type HeatLossReportSnapshot
} from '../../../code/models/heatloss_report'
import { downloadBlob, isFlagSet } from '../../../code/helpers'
import { convertLatLngListToLatLongLiteral } from '../../../code/geocoding'
import { getDesignConditions } from '../../../code/models/design_temp'
import { getHeatTransferCoefficientWattsPerKelvin, getRoomWatts } from '../../../code/models/heat_loss'
import {
  type CapacityResult,
  getHeatPumpCapacityAtOutsideTempAndFlowTemp,
  getHeatPumpScopAtFlowTemp
} from '../../../code/models/range_heat_pump'
import { getSoundAssessmentData } from '../../../code/sound_assessment'
import { getAreaM2 } from '../floor/code/utils'
import { PrintLayout, PrintLayoutPageBreak } from '../../components/print_layout'
import { Button } from '../../../components/buttons/button'
import { FileText } from 'lucide-react'

export type HeatLossReportContextProps = {
  survey: PropertySurvey
  property: Property
  customer: CustomerDetailsType
  company_public_info: CompanyPublicInfo
  design: SurveyDesign
  designTempC: number
  groundTempC: number
  degreeDays: number
  currentHeatPump: InventoryHeatPump
  currentHotWaterCylinder?: InventoryHotWaterCylinder
  cylinderReheatRow: CylinderReheatCalculationRow
  hotWaterRowNormal: HotWaterCalculationRow
  hotWaterRowLegionella: HotWaterCalculationRow
  performanceEstimateColumns: Array<TableColumn<PerformanceEstimateRow>>
  performanceEstimateSummary: PerformanceEstimateSummary
  eligibleForHeatPumpPlus: boolean
  soundAssessment: number
  files: FileWrapper[]
  summaryData: HRLSummaryDataType
  hpCapacityResult: CapacityResult
  scop: number
}

type Props = {
  reportUUID?: string
  sections: number
  snapshot: HeatLossReportSnapshot
  company_public_info: CompanyPublicInfo
}

export const HLRContext = React.createContext<HeatLossReportContextProps | undefined>(undefined)

export const HeatLossReportPage = (props: Props) => {
  const survey = props.snapshot.survey
  const calculatedData = hlrMakeCalcsFromSnapshot(props.snapshot)

  // If ONLY sound assessment don't show header and contents
  const emittersDefined = getFullEmittersListByStatus(survey, calculatedData.design).length > 0

  const logoImageData = props.company_public_info.logo

  return (
    <HLRContext.Provider value={{
      survey,
      property: props.snapshot.property,
      customer: props.snapshot.customer,
      company_public_info: props.company_public_info,
      design: calculatedData.design,
      designTempC: calculatedData.designTempC,
      groundTempC: calculatedData.groundTempC,
      degreeDays: calculatedData.degreeDays,
      currentHeatPump: props.snapshot.currentHeatPump,
      currentHotWaterCylinder: props.snapshot.currentHotWaterCylinder,
      cylinderReheatRow: calculatedData.cylinderReheatRow,
      hotWaterRowNormal: calculatedData.hotWaterRowNormal,
      hotWaterRowLegionella: calculatedData.hotWaterRowLegionella,
      performanceEstimateColumns: calculatedData.performanceEstimateColumns,
      performanceEstimateSummary: calculatedData.performanceEstimateSummary,
      eligibleForHeatPumpPlus: calculatedData.eligibleForHeatPumpPlus,
      soundAssessment: calculatedData.soundAssessment,
      files: props.snapshot.files,
      summaryData: calculatedData.summaryData,
      hpCapacityResult: calculatedData.hpCapacityResult,
      scop: calculatedData.scop
    }}>
      {/*
          It's very tricky to print header on each page. There is a solution reused:
          https://medium.com/@Idan_Co/the-ultimate-print-html-template-with-header-footer-568f415f6d2a
       */}

      <PrintLayout
        /*
          Carefully estimate this number based on the header's real height.
          This number is used to offset content below the print-version header.
        */
        headerHeightPx={120}

        header={
          <div className={[
            'w-full bg-white border-b border-b-gray-200 justify-between items-center gap-6 flex px-1 py-1',
            'print:p-6 print:sticky print:top-0 ', // print
            'sm:px-3 sm:py-3 ', // sm
            'md:px-6 md:py-6 ', // md
            'lg:sticky lg:top-0 ' // lg
          ].join(' ')}>
            {/* logo and MCS certification */}
            <div className='flex flex-row gap-4'>
              {logoImageData && <img src={logoImageData} className="max-h-10 md:max-h-12" alt="Installer logo"/>}
              {/* TODO MCS cert logo */}
            </div>

            {/* action buttons */}
            <div className={'flex flex-row gap-4 print:hidden'}>
              {props.reportUUID && <Button
                colour={'LIGHT'}
                iconLeft={FileText}
                onClick={async () => {
                  const pdfData = await getHLReportPDFAPI(
                    props.reportUUID!,
                    props.company_public_info.uuid
                  )
                  downloadBlob(pdfData!, props.snapshot.property.address + ' — Heat Loss Report.pdf')
                }}
              ><span className='hidden sm:inline'>Save to PDF</span></Button>}
            </div>
          </div>
        }

        // The gap is used to make space between pages
        content={<div className={'print:px-0 px-1 sm:px-3 md:px-6 flex flex-col print:gap-0 gap-8'}>
          <div>
            <PrintLayoutPageBreak/>
            <HLRTitlePage/>
          </div>

          {/* TODO: return report contents page? */}
          {/* <PrintLayoutPageBreak/> */}
          {/* <HLRContentsPage excludedSections={excludedSections} /> */}

          {isFlagSet(props.sections, HEATLOSS_REPORT_SECTION_SUMMARY) && <>
            <div>
              <PrintLayoutPageBreak/>
              <HLRSummaryPage/>
            </div>
          </>}
          {isFlagSet(props.sections, HEATLOSS_REPORT_SECTION_HEATLOSS_SUMMARY) && <>
            <div>
              <PrintLayoutPageBreak/>
              <HLRIntroductionPage hasIntroduction={isFlagSet(props.sections, HEATLOSS_REPORT_SECTION_HEATLOSS_INTRO)}/>
            </div>
            <div>
              <PrintLayoutPageBreak/>
              <HLRCalculationConditionsPage/>
            </div>
            <div>
              <PrintLayoutPageBreak/>
              <HLRHeatlossByElementPage/>
            </div>
            <div>
              <PrintLayoutPageBreak/>
              <HLRHeatlossByRoomPage/>
            </div>

            {/* render floors and rooms using "floor-room-room-room, floor-room-room, floor-..." scheme */}
            {survey.floors.map((floor, floorIdx) => {
              // skip floors without rooms
              if (floor.rooms.length === 0) return null
              return (<Fragment key={'floor-' + floorIdx}>
                <div>
                  <PrintLayoutPageBreak/>
                  <HLRFloorHeatlossPage floor={floor}/>
                </div>
                {isFlagSet(props.sections, HEATLOSS_REPORT_SECTION_HEATLOSS_PER_ROOM) &&
                  floor.rooms.sort((a, b) => a.name.localeCompare(b.name))
                    .map((room, roomIdx) => {
                      return <Fragment key={'floor-' + floorIdx + '-room-' + roomIdx}>
                        <div>
                          <PrintLayoutPageBreak/>
                          <HLRRoomHeatlossPage floor={floor} room={room}/>
                        </div>
                      </Fragment>
                    })
                }
              </Fragment>
              )
            })}
          </>}

          {isFlagSet(props.sections, HEATLOSS_REPORT_SECTION_DESIGN) && <>
            <div>
              <PrintLayoutPageBreak/>
              <HLRHeatPump/>
            </div>

            {emittersDefined && <>
              <div>
                <PrintLayoutPageBreak/>
                <HLREmittersIntro/>
              </div>
              <div>
                <PrintLayoutPageBreak/>
                <HLREmittersCurrent/>
              </div>
              <div>
                <PrintLayoutPageBreak/>
                <HLREmittersProposed/>
              </div>
            </>}

            <div>
              <PrintLayoutPageBreak/>
              <HLRHotWater/>
            </div>
            <div>
              <PrintLayoutPageBreak/>
              <HLRHotWaterDemand/>
            </div>
          </>}

          {isFlagSet(props.sections, HEATLOSS_REPORT_SECTION_SOUND_ASSESSMENT) && <>
            <div>
              <PrintLayoutPageBreak/>
              <HLRSoundAssessment/>
            </div>
          </>}

          {/* performance estimate pages below */}
          {isFlagSet(props.sections, HEATLOSS_REPORT_SECTION_PERFORMANCE_ESTIMATE) && <>
            <div>
              <PrintLayoutPageBreak/>
              <HLRPESummary/>
            </div>
            <div>
              <PrintLayoutPageBreak/>
              <HLRPEDetailedResultsIntroduction/>
            </div>
            <div>
              <PrintLayoutPageBreak/>
              <HLRPEResultsTable/>
            </div>
            <div>
              <PrintLayoutPageBreak/>
              <HLRPEInputsAndAssumptions/>
            </div>
            <div>
              <PrintLayoutPageBreak/>
              <HLRPESystemEfficiency1/>
            </div>
            <div>
              <PrintLayoutPageBreak/>
              <HLRPESystemEfficiency2/>
            </div>
            <div>
              <PrintLayoutPageBreak/>
              <HLRPEFlowTempChart/>
            </div>
            <div>
              <PrintLayoutPageBreak/>
              <HLRPEMCSKeyFacts/>
            </div>
          </>}
        </div>}
      />
    </HLRContext.Provider>
  )
}

// dynamic Heatloss report data
// this data is calculated in real-time from the snapshot on opening the report
type HeatlossReportCalcs = {
  design: SurveyDesign
  designTempC: number
  groundTempC: number
  degreeDays: number
  cylinderReheatRow: CylinderReheatCalculationRow
  hotWaterRowNormal: HotWaterCalculationRow
  hotWaterRowLegionella: HotWaterCalculationRow
  performanceEstimateColumns: Array<TableColumn<PerformanceEstimateRow>>
  performanceEstimateSummary: PerformanceEstimateSummary
  eligibleForHeatPumpPlus: boolean
  soundAssessment: number
  summaryData: HRLSummaryDataType
  hpCapacityResult: CapacityResult
  scop: number
}

type HRLSummaryDataType = {
  floorArea: number
  heatLoss: number
  radiatorsNew: number
  radiatorsReplaced: number
  ufhNew: number
  emittersRemoved: number
  emittersUnchanged: number
}

// the main function to calculate the report data from the snapshot
// the function could not be in a .ts file because it uses React FCs to render some views
export const hlrMakeCalcsFromSnapshot = (snapshot: HeatLossReportSnapshot): HeatlossReportCalcs => {
  const design = snapshot.survey.designs[0]
  const latLng = convertLatLngListToLatLongLiteral(snapshot.property.postcodeLocation)
  const altitudetoUseM = snapshot.survey.altitude_override_m ?? snapshot.property.altitudeM
  const {
    designTempDefaultC,
    degreeDays,
    groundTempC
  } = getDesignConditions(latLng, altitudetoUseM, snapshot.survey.exposed_location) // done separately as needed in survey_settings
  const designTempC = snapshot.survey.design_temp_override_c ?? designTempDefaultC
  const cylinderReheatRow = getCylinderReheatCalculationRow(snapshot.currentHotWaterCylinder, design.hot_water_storage_temperature_c, snapshot.currentHeatPump?.range_heat_pump, designTempC)
  const numberOfOccupants = getNumberOfOccupants(snapshot.survey)
  const dailyHotWaterVolumeL = getDailyHotWaterVolumeL(numberOfOccupants, snapshot.survey.volume_per_person_l)
  const hotWaterRowNormal = getHotWaterCalculationRowNormalOperation(snapshot.currentHeatPump?.range_heat_pump, dailyHotWaterVolumeL, design.hot_water_storage_temperature_c)
  const hotWaterRowLegionella = getHotWaterCalculationRowLegionella(design.hot_water_storage_temperature_c, snapshot.currentHotWaterCylinder?.litres ?? 0, snapshot.survey.legionnaires_cycle_weeks)
  const heatLossWattsPerKelvin = getHeatTransferCoefficientWattsPerKelvin(snapshot.survey, designTempC, groundTempC)
  const designHotWaterDemandKwh = hotWaterRowNormal.heatEnergyKwhPerCycle * hotWaterRowNormal.cyclesPerYear
  const scopSpaceHeating = getHeatPumpScopAtFlowTemp(snapshot.currentHeatPump?.range_heat_pump, design.flow_temp)

  const eligibleForHeatPumpPlusResult = eligibleForHeatPumpPlus(snapshot.currentHeatPump)
  const performanceEstimateSummary = getPerformanceEstimateSummary(snapshot.survey, heatLossWattsPerKelvin, degreeDays, designHotWaterDemandKwh, hotWaterRowNormal.heatingEfficiency, scopSpaceHeating, eligibleForHeatPumpPlusResult)
  const performanceEstimateColumns: Array<TableColumn<PerformanceEstimateRow>> = [
    {
      key: 'name',
      name: 'Name',
      render: (row) => <> {row.name === 'Savings' ? <div className='font-bold'>{row.name}</div>
        : <div>{row.name}</div>}</>
    },
    {
      key: 'kwh',
      name: 'Energy',
      render: (row) => <> {row.name === 'Savings' ? <div className='font-bold'>{row.kwh}</div>
        : <div>{row.kwh}</div>}</>
    },
    {
      key: 'costUserEnteredTariff', // render with the user entered tariff so they can play. In report this won't be show, the other 3 options will be
      name: 'Bills',
      render: (row) => <> {row.name === 'Savings' ? <div className='font-bold'>{row.costUserEnteredTariff}</div>
        : <div>{row.costUserEnteredTariff}</div>}</>
    },
    {
      key: 'emissionsKG',
      name: 'Emissions',
      render: (row) => <> {row.name === 'Savings' ? <div className='font-bold'>{row.emissionsKG}</div>
        : <div>{row.emissionsKG}</div>}</>
    }
  ]
  const soundAssessmentData = getSoundAssessmentData(snapshot.survey.sound_barrier_uuid, snapshot.survey.reflective_surfaces, snapshot.survey.sound_distance, snapshot.currentHeatPump?.range_heat_pump?.sound_power_max_dba)
  const soundAssessment = snapshot.currentHeatPump ? soundAssessmentData?.finalResultDba ?? 0 : 0

  const summaryData = {
    floorArea: 0,
    heatLoss: 0,
    radiatorsNew: 0,
    radiatorsReplaced: 0,
    ufhNew: 0,
    emittersRemoved: 0,
    emittersUnchanged: 0
  }

  snapshot.survey.floors.forEach(floor => {
    floor.rooms.forEach(room => {
      const roomHeatLoss = getRoomWatts(room, floor.rooms, designTempC, groundTempC, snapshot.survey)
      const roomArea = getAreaM2(room.walls.map(w => ({ x: w.x!, y: w.y! })))

      summaryData.heatLoss += roomHeatLoss
      summaryData.floorArea += roomArea
    })
  })

  const emitters = getFullEmittersListByStatus(snapshot.survey, design)
  emitters.forEach(emitter => {
    if (emitter.status === 'ADDED') {
      if (emitter.radiator.emitter_type === 'UNDERFLOOR') {
        summaryData.ufhNew++
      } else {
        summaryData.radiatorsNew++
      }
    } else if (emitter.status === 'REPLACED') {
      // Currently cannot "Replace" underfloor heating so only need to worry about radiators here
      summaryData.radiatorsReplaced++
    } else if (emitter.status === 'REMOVED') {
      summaryData.emittersRemoved++
    } else if (emitter.status === 'UNCHANGED') {
      summaryData.emittersUnchanged++
    }
  })

  const hpCapacityResult = getHeatPumpCapacityAtOutsideTempAndFlowTemp(
    snapshot.currentHeatPump.range_heat_pump,
    designTempC,
    design.flow_temp
  )
  const scop = getHeatPumpScopAtFlowTemp(snapshot.currentHeatPump?.range_heat_pump, design.flow_temp)

  return {
    design: snapshot.survey.designs[0],
    designTempC,
    groundTempC,
    degreeDays,
    cylinderReheatRow,
    hotWaterRowNormal,
    hotWaterRowLegionella,
    performanceEstimateSummary,
    performanceEstimateColumns,
    eligibleForHeatPumpPlus: eligibleForHeatPumpPlusResult,
    soundAssessment,
    summaryData,
    hpCapacityResult,
    scop
  } satisfies HeatlossReportCalcs
}
