import { type Material } from '../../../code/models/material'
import { type SprucePoint } from './code/types'
import { uniqBy } from 'lodash'
import { ANGLE_TOLERANCE_DEGREES } from '../constants'
import { type SpruceLine } from './line_with_text'

export type WallLine = {
  uuid: string
  x1: number
  y1: number
  x2: number
  y2: number
  room_uuid?: string
  other_room_uuid?: string | undefined
  material?: Material
}

export const calculateAngle = (point1: SprucePoint, point2: SprucePoint) =>
  Math.atan2(point2.y - point1.y, point2.x - point1.x)

export const normalizeAngle = (angleRadians: number) => {
  // Ensure angleDifference is within [0, 2π]
  angleRadians = angleRadians % (2 * Math.PI)

  // If the difference is greater than π, use the smaller complementary angle
  if (angleRadians > Math.PI) {
    angleRadians = 2 * Math.PI - angleRadians
  }

  return angleRadians
}

// Loop through each line, for each line find all walls that are not in my room.
// For the 4 points, are all 4 points collinear (they share the same infinite line)?
// If so order the points along line, create a new set of walls by linking each point to the next point e.g. given 4 points we get 3 lines. *---*---*---*
// Filter out:
//   - Any non-unique points that would create a 0 length line
//   - My original line if there is an absolute intersection
//   - Lines that do not intersect my original line (collinear points do not necessarily intersect, only get me those line segments that are inside of me)
export const createNewTouchingWalls = (wallA: SpruceLine, otherWalls: SpruceLine[]): SpruceLine[] => {
  // Find all co-linear points between this wall and the walls in all other rooms
  const allColinearPoints: SprucePoint[] = []
  otherWalls.map(x => {
    const points = [
      { x: wallA.p1.x, y: wallA.p1.y },
      { x: wallA.p2.x, y: wallA.p2.y },
      { x: x.p1.x, y: x.p1.y },
      { x: x.p2.x, y: x.p2.y }
    ]
    if (!arePointsCollinear(points, ANGLE_TOLERANCE_DEGREES)) return []
    allColinearPoints.push(...points)
  })

  // Now order and deduplicate those points and split them into segments
  const sortedPointsInitial = orderPointsAlongWall(wallA, allColinearPoints)
  const segments: SpruceLine[] = []
  for (let i = 0; i < sortedPointsInitial.length - 1; i++) {
    const point = sortedPointsInitial[i]
    const nextPoint = sortedPointsInitial[i + 1]
    // ignore duplicate points
    if (point.x === nextPoint.x && point.y === nextPoint.y) continue
    const segment: SpruceLine = {
      uuid: crypto.randomUUID(),
      p1: { x: point.x, y: point.y },
      p2: { x: nextPoint.x, y: nextPoint.y }
    }
    segments.push(segment)
  }

  // Finally filter out the segments that are not subsets of the original line
  const segmentsWithinOriginal = segments.filter(x =>
    !(x.p1.x === wallA.p1.x && x.p2.x === wallA.p2.x && x.p1.y === wallA.p1.y && x.p2.y === wallA.p2.y) && // The line is not my original line
          isLineInsideAnother(wallA, x, ANGLE_TOLERANCE_DEGREES) // The line is inside my original line dimensions
  )

  return segmentsWithinOriginal
}

const orderPointsAlongWall = (wallLine: SpruceLine, points: SprucePoint[]): SprucePoint[] => {
  const refPoint = points[0]
  const dx = wallLine.p2.x - wallLine.p1.x
  const dy = wallLine.p2.y - wallLine.p1.y

  // Function to compute projection of a point on the wallA direction
  const projection = (point: SprucePoint) => {
    const { x: px, y: py } = point
    const { x: rx, y: ry } = refPoint

    // Calculate the projection using the dot product
    return ((px - rx) * dx + (py - ry) * dy) / Math.sqrt(dx * dx + dy * dy)
  }

  // Sort points based on their projection on the wallA direction
  const pointsSorted = points.slice().sort((a, b) => {
    return projection(a) - projection(b)
  })

  return pointsSorted
}

export const isPointOnLineSegment = (wallLine: SpruceLine, point: SprucePoint, tolerance: number) => {
  const dxLine = wallLine.p2.x - wallLine.p1.x
  const dyLine = wallLine.p2.y - wallLine.p1.y
  const dxPoint = point.x - wallLine.p1.x
  const dyPoint = point.y - wallLine.p1.y

  // Check if the point is behind the start of the line segment
  const dotProduct = dxPoint * dxLine + dyPoint * dyLine
  if (dotProduct < 0) return false

  // Check if the point is beyond the end of the line segment
  const squaredLengthBA = dxLine * dxLine + dyLine * dyLine
  if (dotProduct > squaredLengthBA) return false

  const isCollinear = arePointsCollinear([
    { x: wallLine.p1.x, y: wallLine.p1.y },
    { x: wallLine.p2.x, y: wallLine.p2.y },
    point
  ], tolerance)
  if (!isCollinear) return false

  // If all checks pass, the point is on the line segment
  return true
}

export const isLineInsideAnother = (line1: SpruceLine, line2: SpruceLine, tolerance: number) =>
  isPointOnLineSegment(line1, { x: line2.p1.x, y: line2.p1.y }, tolerance) && isPointOnLineSegment(line1, { x: line2.p2.x, y: line2.p2.y }, tolerance)

export const arePointsCollinear = (points: SprucePoint[], tolerance: number) => {
  const uniquePoints = uniqBy(points, x => `${x.x}-${x.y}`)
  if (uniquePoints.length < 3) return true

  const slope = (p1: SprucePoint, p2: SprucePoint) => {
    if (p1.x === p2.x) return Infinity
    return (p2.y - p1.y) / (p2.x - p1.x)
  }

  const calculateAngleBetweenSlopes = (slope1: number, slope2: number) => {
    if (slope1 === slope2) return 0
    if (slope1 === Infinity && slope2 === Infinity) return 0
    if ((slope1 === 0 && slope2 === Infinity) || (slope1 === Infinity && slope2 === 0)) return 90

    const angleRad = Math.atan(Math.abs((slope1 - slope2) / (1 + slope1 * slope2)))
    const angleDeg = angleRad * (180 / Math.PI)
    return angleDeg
  }

  const isCollinear = (p1: SprucePoint, p2: SprucePoint, p3: SprucePoint) => {
    if ((p1.x === p2.x && p2.x === p3.x) || (p1.y === p2.y && p2.y === p3.y)) return true

    const slopeAB = slope(p1, p2)
    const slopeBC = slope(p2, p3)

    const angleBetween = calculateAngleBetweenSlopes(slopeAB, slopeBC)
    return angleBetween <= tolerance || (180 - angleBetween) <= tolerance
  }

  // Use the first two points to establish the line
  const basePoint = uniquePoints[0]
  const secondPoint = uniquePoints[1]

  return uniquePoints.slice(2).every(x => isCollinear(basePoint, secondPoint, x))
}
