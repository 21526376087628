import React, { useContext } from 'react'
import { Section } from '../../components/section'
import { InfoCard } from '../../components/info_card'
import { convertLatLngListToLatLongLiteral } from '../../../../../code/geocoding'
import { type Lead } from '../../../../../code/models/lead'
import { Alert } from '../../../../../components/indicators_and_messaging/alert'
import { Map, Marker } from '@vis.gl/react-google-maps'
import { Heading } from '../../../../../components/content_display/heading'
import { SummaryTable } from '../../../../../components/content_display/summary_table'
import { ESTIMATE_AGE_BANDS } from '../../../../../code/models/age_bands'
import { contextSensitiveFormatDate, formatDate } from '../../../../../code/format_date'
import { type Estimate, type GrantEligibleReason } from '../../../../../code/calculate_estimate'
import { type CompanyPublicInfo } from '../../../../../code/models/company'
import { Button } from '../../../../../components/buttons/button'
import { Info } from '../../../../../components/buttons/info'
import { SendDeclinedModalBlock } from '../components/send_declined_modal_block'
import { SendEstimateModalBlock } from '../components/send_estimate_modal_block'
import { AdminContext } from '../../../admin_layout'
import { type CalculatedEstimate, estimateAndLeadToCalculatedEstimate } from '../../../../../code/models/calculated_estimate'
import { AlertCircle, AlertCircleIcon, Ban, CheckCircleIcon, Edit, Eye, Send, XCircleIcon } from 'lucide-react'

export const BUSGrantInfo = ({ lead, notBusGrantEligible, busGrantEligibleReasons }: { lead: Lead, notBusGrantEligible: boolean, busGrantEligibleReasons: GrantEligibleReason[] }) => {
  return <Info
    infoModalHeader={'BUS grant eligibility'}
    infoModalBody={
      <div className='flex flex-col gap-4'>
        <div className='space-y-2'>
          <div className='text-gray-900 font-semibold'>This property
            is {notBusGrantEligible ? 'not currently' : 'likely to be'} eligible for a BUS grant.
          </div>
          <div className='text-gray-600 text-sm'>{lead.epcData ? <>The latest EPC we have access to was lodged on <span
            className='font-bold'>{formatDate(lead.epcData.inspectionDate.toString())}</span>.</> : ''} Please note that
            EPC data only updates monthly, typically during the final working week of the month.
          </div>
        </div>

        {lead.epcData?.lmkKey
          ? <div>
              <div
                className='space-y-2 text-gray-600'>{busGrantEligibleReasons.filter(x => !['cavity_wall', 'loft_insulation'].includes(x.id)).map((x, i) =>
                  <div key={i}
                    className='flex gap-x-2'>
                    {x.warning ? <AlertCircleIcon className='w-6 h-6 fill-yellow-500'/> : x.hasPassed
                      ? <CheckCircleIcon className='w-6 h-6 fill-green-500'/>
                      : <XCircleIcon className='w-6 h-6 fill-red-500'/>}
                    <div>{x.message}</div>
                  </div>)}
              </div>
              <div className='mt-5 flex flex-col gap-2'>
                <div className='text-gray-900 font-semibold mt-5'>Energy efficiency opportunities</div>
                <div className='text-gray-600 text-sm'>On the 8th May 2024 the requirement to have no outstanding
                recommendations for loft or cavity wall insulation was removed. We continue to flag them here for your
                reference only.
                </div>
                <div
                  className='space-y-2 text-gray-600 mt-2'>{busGrantEligibleReasons.filter(x => ['cavity_wall', 'loft_insulation'].includes(x.id)).map((x, i) =>
                    <div key={i}
                      className='flex gap-x-2'>
                      {x.warning ? <AlertCircle className='w-6 h-6 fill-yellow-500'/> : x.hasPassed
                        ? <CheckCircleIcon className='w-6 h-6 fill-green-500'/>
                        : <XCircleIcon className='w-6 h-6 fill-red-500'/>}
                      <div>{x.message}</div>
                    </div>)}
                </div>
              </div>
            </div>
          : <Alert type="WARNING">No EPC found for this address. The property will need an EPC before it can be assessed
            for BUS grant eligibility.</Alert>}
      </div>}
  />
}

export const HESGrantInfo = ({ lead, notHesGrantEligible, hesGrantEligibleReasons }: { lead: Lead, notHesGrantEligible: boolean, hesGrantEligibleReasons: GrantEligibleReason[] }) => {
  return <Info
    infoModalHeader={'HES grant eligibility'}
    infoModalBody={
      <div className='flex flex-col gap-4'>
        {!lead?.epc_scotland &&
            <div className='flex flex-col gap-8'>
              <div className='space-y-1'>
                <div className='text-gray-900 font-semibold'>This property cannot be assessed for a HES grant.</div>
                <div className='text-gray-600 text-sm'>Please note that EPC data only updates quarterly.</div>
              </div>
              <Alert type="WARNING">No EPC found for this address so we cannot assess whether loft insulation or cavity wall insulation would be recommended in the post-install EPC.</Alert>
            </div>
        }

        {lead?.epc_scotland &&
            <>
              <div className='space-y-1'>
                <div className='text-gray-900 font-semibold'>This property {notHesGrantEligible ? 'is unlikely to be' : 'may be'} eligible for a HES grant.</div>
                <div className='text-gray-600 text-sm'>{lead.epc_scotland ? <>Data from the EPC lodged on <span className='font-bold'>{formatDate(lead.epc_scotland.inspection_date.toString())}</span>.</> : ''} Please note that EPC data only updates quarterly.</div>
              </div>

              {lead?.epc_scotland?.improvements
                ? <div className='space-y-2 text-gray-600'>{hesGrantEligibleReasons.map((x, i) => <div key={i}
                    className='flex gap-x-2'>
                    {x.hasPassed ? <CheckCircleIcon className='w-6 h-6 fill-green-500' />
                      : <XCircleIcon className='w-6 h-6 fill-red-500' />}
                    <div>{x.message}</div>
                  </div>)}
                  </div>
                : <Alert type="WARNING">No EPC found for this address. The property will need an EPC before it can be
                  assessed
                  for HES grant eligibility.</Alert>
              }
            </>}
      </div>}
  />
}

type Props = {
  lead: Lead
  estimate: Estimate
  navigateTo: (url: string) => void
  companyPublicInfo: CompanyPublicInfo
  setLead: (lead: Lead) => void
  setEstimates: (estimates: CalculatedEstimate[]) => void
}

export const EnquiryOverview = ({ lead, estimate, navigateTo, companyPublicInfo, setLead, setEstimates }: Props) => {
  const adminContext = useContext(AdminContext)
  const latLng = convertLatLngListToLatLongLiteral(lead.property.postcodeLocation)
  const { isScottish, busGrantEligibleReasons, hesGrantEligibleReasons } = estimate
  const notBusGrantEligible = (!lead.epc_scotland && (!lead.epcData?.lmkKey || busGrantEligibleReasons.some(x => !x.hasPassed)))
  const notHesGrantEligible = isScottish && hesGrantEligibleReasons.some(x => !x.hasPassed)

  const handleEditEnquiryData = () => {
    const url = `/${companyPublicInfo.subdomain}/admin/quotes/${lead.uuid}/estimate/enquiry-data`
    navigateTo(url)
  }

  const [isEstimateModalVisible, setIsEstimateModalVisible] = React.useState(false)
  const [declineModalVisible, setDeclineModalVisible] = React.useState(false)

  const calculatedEstimate = estimateAndLeadToCalculatedEstimate(estimate, lead, adminContext.data)

  return (
    <>
      <div className='flex flex-col gap-4'>
        <div className='flex gap-4 overflow-x-auto'>
          <Button colour="LIGHT" iconLeft={Eye} onClick={() => navigateTo(`/${companyPublicInfo.subdomain}/admin/quotes/${lead.uuid}/estimate/preview`)}>Preview</Button>
          <Button colour="LIGHT" iconLeft={Ban} onClick={() => setDeclineModalVisible(true)}>Decline</Button>
          <Button colour="DARK" iconLeft={Send} onClick={() => setIsEstimateModalVisible(true)} disabled={!estimate.heatPump}>Send</Button>
        </div>
        <Section title="Enquiry overview" controls={<Button size="SM" iconLeft={Edit} colour='LIGHT' onClick={handleEditEnquiryData}>Edit enquiry data</Button>}>
          <div className="grid grid-cols-1 md:grid-cols-2 gap-5">
            <InfoCard title="Received" value={contextSensitiveFormatDate(new Date(lead.createdAt!))} />
            {/* <InfoCard title="Comments to review" value="3" /> */}
            {!isScottish && <InfoCard title="BUS grant eligibility" value={notBusGrantEligible ? 'Not eligible' : 'Eligible'} info={BUSGrantInfo({ lead, notBusGrantEligible, busGrantEligibleReasons })} />}
            {isScottish && <InfoCard title="HES grant eligibility" value={notHesGrantEligible ? 'Not eligible' : 'Eligible'} info={HESGrantInfo({ lead, notHesGrantEligible, hesGrantEligibleReasons })} />}
          </div>

          <div className="grid grid-cols-1 md:grid-cols-2 gap-5">
            <div className="flex flex-col cursor-pointer" onClick={handleEditEnquiryData}>
              <Heading size="md">Contact details</Heading>
              <SummaryTable rows={[
                { key: 'Name', value: lead.customer?.name || 'N/A' },
                { key: 'Email', value: lead.customer?.email || 'N/A' },
                { key: 'Phone', value: lead.customer?.phone || 'N/A' }
              ]} />
              <Heading size="md" className="mt-5">Property details</Heading>
              <SummaryTable rows={[
                { key: 'Property type', value: [lead.property.houseOverrides?.propertyType ?? lead.property.propertyType, lead.property.houseOverrides?.builtForm ?? lead.property.builtForm].join(', ') || 'N/A' },
                { key: 'Built', value: lead.property.houseOverrides?.construction_age_band_uuid ? ESTIMATE_AGE_BANDS.find(x => x.uuid === lead.property.houseOverrides?.construction_age_band_uuid)?.name : ESTIMATE_AGE_BANDS.find(x => x.uuid === lead.property.construction_age_band_uuid)?.name || 'N/A' },
                { key: 'Walls', value: [lead.property.houseOverrides?.wallGroup ?? lead.property.wallGroup, lead.property.houseOverrides?.wallType ?? lead.property.wallType].join(', ') || 'N/A' },
                { key: 'Windows', value: lead.property.houseOverrides?.windowType ?? (lead.property.windowType || 'N/A') },
                { key: 'Bedrooms', value: lead.property.houseOverrides?.noBedrooms ?? (lead.property.noBedrooms || 'N/A') },
                { key: 'Bathrooms', value: lead.property.houseOverrides?.noBathrooms ?? (lead.property.noBathrooms || 'N/A') },
                { key: 'Floor area', value: <span>{lead.property.houseOverrides?.floorArea ?? (lead.property.floorArea || 'N/A')} m<sup>2</sup></span> }
              ]} />
            </div>
            <div>
              {latLng
                ? <>
                    <Map zoom={18} className="min-h-[420px] w-full h-full rounded overflow-hidden" mapTypeId="satellite" center={latLng}>
                      <Marker position={latLng} />
                    </Map>
                  </>
                : <Alert type="WARNING">Sorry, Google Maps can't find this address.</Alert>
              }
            </div>
          </div>
        </Section>
      </div>
      {/* Send estimate modal */}
      {isEstimateModalVisible && <SendEstimateModalBlock
        setEstimates={setEstimates}
        adminContext={adminContext}
        setIsVisible={setIsEstimateModalVisible}
        estimate={estimate}
        calculatedEstimate={calculatedEstimate}
        lead={lead}
        setLead={setLead}
      />}

      {/* Send declined */}
      <SendDeclinedModalBlock
        adminContext={adminContext}
        isVisible={declineModalVisible}
        setIsVisible={setDeclineModalVisible}
        lead={lead}
        setLead={setLead}
      />
    </>
  )
}
