import React from 'react'
import { TableLite } from '../../../components/content_display/table_lite'
import { HLRContext } from './heatloss_report'
import { getHeatPumpScopRowsAndColumns } from '../../admin/costs_and_inventory/components/heat_pump_modal'
import { reversePriceOrNumberFormat } from '../../../code/models/performance_estimate'

export const HLRPEFlowTempChart = () => {
  const hlrContext = React.useContext(HLRContext)
  const annualHeatDemand = reversePriceOrNumberFormat(hlrContext!.performanceEstimateSummary.hddEstimate.existing.demandTotalKwh)
  const { scopRows, scopColumns } = getHeatPumpScopRowsAndColumns(hlrContext!.currentHeatPump?.range_heat_pump)

  const tableRows = [
    scopRows[0],
    {
      name: 'Electricity consumed (kWh/year)',
      at35: Math.round(annualHeatDemand / scopRows[0].at35),
      at40: Math.round(annualHeatDemand / scopRows[0].at40),
      at45: Math.round(annualHeatDemand / scopRows[0].at45),
      at50: Math.round(annualHeatDemand / scopRows[0].at50),
      at55: Math.round(annualHeatDemand / scopRows[0].at55)

    }
  ]

  return (
    <div className='flex gap-6 flex-col'>

      <div className='flex flex-col gap-2'>
        <div className="text-gray-900 text-lg font-bold">Dependence of performance on flow temperature</div>
        <div className="">
          How much electricity your heat pump will use to provide heating depends on the flow temperature that your
          system runs at. We have designed your system to run at {hlrContext!.design.flow_temp}C when it's {hlrContext!.designTempC}°C outside and we’ll set it up to
          use weather compensation so it runs more efficiently at milder temperatures.
        </div>
        <div className="">
          To demonstrate the importance of flow temperature, the table below shows how much electricity your system
          would consume to provide heating at a range of flow temperatures. This graph is based on the heat loss
          calculations and heating degree days based estimate, but the pattern would be similar for any of the inputs.
        </div>
      </div>

      {/*  TODO later: add chart instead of table */}
      <TableLite rows={tableRows} columns={scopColumns}/>
    </div>
  )
}
