import React from 'react'
import { HLRHeaderBlock } from './partials/hlr_header'

type Props = {
  hasIntroduction: boolean
}

export const HLRIntroductionPage = (props: Props) => {
  return <div className='flex flex-col gap-8'>
    <HLRHeaderBlock
      title={'Heat loss report'}
      description={'This section presents the results of our detailed heat loss calculations for your home. The overall heat loss determines how big your heat pump needs to be, and the room-by-room breakdown allows us to size the emitters (radiators and underfloor heating) correctly to keep each room warm on a cold day.'}
    />

    {props.hasIntroduction &&
      <div className="flex-col justify-start items-start gap-6 flex">
        <div className='flex flex-col gap-5'>
          <div className="text-gray-900 text-3xl font-bold">Introduction</div>
          <div className="">
            How quickly your home loses heat depends on how big your home is, the materials it is made from,
            and how air tight it is. Our detailed survey captured all this information
            and we have laid it out in this report so you can understand how and why we produce our recommendations.
            There are several key questions that we’re looking to answer as we go through this process for you:
          </div>

          <div className="flex flex-col gap-2">
            <div className="text-lg font-bold">Are the heat pump and radiators big enough?</div>
            <div className="">
              To keep you warm, the heat pump needs to deliver heat to the
              emitters (by which we just mean radiators and underfloor heating) as quickly as your home loses it, and
              in
              turn the emitters need to deliver that heat as quickly as each room loses it.
            </div>
            <div className="">
              We cannot rely on the fact that your existing radiators keep your home warm with your existing heating
              system, because heat pumps typically work at a much lower flow temperature than fossil-fuel boilers.
            </div>
            <div className="">
              Whilst heat pumps are capable of making very hot water, they work most efficiently when generating
              low-temperature heat, typically between 35-50C. Gas boilers have traditionally been set up to generate
              high-temperature heat, with water temperatures of 70-80C (although they also work more efficiently at
              lower temperatures!).
            </div>
            <div className="">
              Lower flow temperature, while more efficient, does mean the heat output from each radiator will be
              reduced. We use the heat loss survey to calculate how that reduced output compares to the room’s demand
              for heat and then determine which (if any) radiators need replacing. In this way we ensure your new
              system
              will run efficiently with a low flow temperature, while still keeping you warm on cold days.
            </div>
          </div>

          <div className="flex flex-col gap-2">
            <div className="text-lg font-bold">Is the heat pump too big?</div>
            <div className="">
              This strikes many as a funny question, but we do not want to “oversize” your heat pump. While we design
              your heating system to ensure it keeps you warm on cold days, most of the time it isn’t that cold! On
              milder days, your house loses heat more slowly so the heat pump will need to provide less heat.
            </div>
            <div className="">
              Heat pumps have to be sized quite precisely, as opposed to the majority of gas or oil boilers which are
              typically far more powerful than is actually needed for the property. If heat pumps turn on and off all
              the time, it can be really inefficient and unnecessarily increase your energy bills. So we want to
              choose
              a model that can keep you warm on a cold day, but “modulate down” to keep running efficiently on milder
              days.
            </div>
          </div>

          <div className="flex flex-col gap-2">
            <div className="text-lg font-bold"></div>
            <div className="">
              We hope this all makes sense! Please do give the report a thorough read through and let us know if you
              have any questions – we’re more than happy to explain what bits mean in more detail if you would like!
            </div>
          </div>

        </div>
      </div>
    }
  </div>
}
