import React from 'react'
import { getTotalHeatLossWatts } from '../../../code/models/heat_loss'
import { ClickableCard } from '../../../components/content_display/card'
import { TableLite } from '../../../components/content_display/table_lite'
import {
  getHeatPumpCapacityAtOutsideTempAndFlowTemp,
  getHeatPumpScopAtFlowTemp
} from '../../../code/models/range_heat_pump'
import { HLRContext } from './heatloss_report'
import { numberFormat } from '../../../code/number_format'
import {
  getHeatPumpCapacityRowsAndColumns,
  getHeatPumpScopRowsAndColumns
} from '../../admin/costs_and_inventory/components/heat_pump_modal'
import { HLRListBlock } from './partials/hlr_list'
import _ from 'lodash'
import { HLRHeaderBlock } from './partials/hlr_header'
import { PrintLayoutPageBreak } from '../../components/print_layout'

export const HLRHeatPump = () => {
  const hlrContext = React.useContext(HLRContext)

  const totalHeatLossKW = getTotalHeatLossWatts(hlrContext!.survey, hlrContext!.designTempC, hlrContext!.groundTempC) / 1000

  const capacity = getHeatPumpCapacityAtOutsideTempAndFlowTemp(hlrContext!.currentHeatPump.range_heat_pump, hlrContext!.designTempC, hlrContext!.design.flow_temp).capacityKw
  const capacityPercentage = Math.round((capacity / totalHeatLossKW) * 100)

  const scop = getHeatPumpScopAtFlowTemp(hlrContext!.currentHeatPump?.range_heat_pump, hlrContext!.design.flow_temp)
  const { scopRows, scopColumns } = getHeatPumpScopRowsAndColumns(hlrContext!.currentHeatPump?.range_heat_pump)
  const { capacityRows, capacityColumns } = getHeatPumpCapacityRowsAndColumns(hlrContext!.currentHeatPump?.range_heat_pump)

  const locationPhotos = hlrContext!.files.filter(x => hlrContext!.survey.location_images.some(y => y.image_uuid === x.uuid))

  return <div className=''>
    <HLRHeaderBlock
      title='System design'
      description='This section presents our proposed system design for your home. The design is based on the detailed room by room calculations in the previous section.'
    />

    <div className='flex flex-col gap-6 pb-9 pt-14'>
      <div className='text-3xl font-bold text-gray-900'>Proposed heat pump</div>
      <div>Based on your home's design heat loss of <span
        className='font-bold text-gray-900'>{numberFormat(1).format(totalHeatLossKW)} kW</span> and on all the attributes of your
        home that we recorded during our site survey, we would suggest the following heat pump for your home.
      </div>

      <ClickableCard variant='WHITE'>
        {hlrContext!.currentHeatPump ? <div className="flex-col gap-2 flex">
          <div className="text-gray-900 text-sm font-bold">{hlrContext!.currentHeatPump?.name}</div>
          <div className="flex-col flex">
            <div className="py-2 border-t border-gray-200 justify-between gap-2 flex">
              <div className="text-gray-600 text-xs font-semibold">Capacity at {hlrContext!.design.flow_temp} °C
                ({hlrContext!.designTempC} °C)
              </div>
              <div className="text-right text-gray-600 text-xs font-semibold">{capacity} kW</div>
            </div>
            <div className="py-2 border-t border-gray-200 justify-between gap-2 flex">
              <div className="text-gray-600 text-xs font-semibold">SCOP at {hlrContext!.design.flow_temp} °C</div>
              <div className="text-right text-gray-600 text-xs font-semibold">{scop}</div>
            </div>

            <div className="py-2 border-t border-gray-200 justify-between gap-2 flex">
              <div className="text-gray-600 text-xs font-semibold">Flow temperature</div>
              <div className='text-right text-xs font-semibold'>{hlrContext!.design.flow_temp} °C</div>
            </div>
          </div>
        </div> : <div>No heat pump</div>}
      </ClickableCard>

      <div>This heat pump covers <span className='font-bold text-gray-900'>{capacityPercentage} %</span> of the heating
        requirement at and above the design temperature of <span
        className='font-bold text-gray-900'>{hlrContext!.designTempC} °C</span>. The heat pump will be run on weather
        compensation so the efficiency will be higher when the weather is milder.
      </div>

      {hlrContext!.currentHeatPump.range_heat_pump && <div className='grid grid-cols-3 gap-3 text-sm'>
        <div className='flex flex-col gap-1'>
          <div className='text-gray-900 font-bold'>MCS certificate number</div>
          <div className='text-gray-600'>{hlrContext!.currentHeatPump.range_heat_pump?.mcs_certificate_numbers?.join(', ')}</div>
        </div>
        <div className='flex flex-col gap-1'>
          <div className='text-gray-900 font-bold'>Refrigerant</div>
          <div className='text-gray-600'>{hlrContext!.currentHeatPump.range_heat_pump?.refrigerant}</div>
        </div>
        <div className='flex flex-col gap-1'>
          <div className='text-gray-900 font-bold'>Serial number</div>
          <div className='text-gray-600'>{hlrContext!.currentHeatPump.range_heat_pump?.model_number}</div>
        </div>
        <div className='flex flex-col gap-1'>
          <div className='text-gray-900 font-bold'>Sound power</div>
          <div className='text-gray-600'>{hlrContext!.currentHeatPump.range_heat_pump?.sound_power_max_dba} dB(A)
          </div>
        </div>
        <div className='flex flex-col gap-1'>
          <div className='text-gray-900 font-bold'>Width</div>
          <div className='text-gray-600'>{hlrContext!.currentHeatPump.range_heat_pump?.width_mm} mm</div>
        </div>
        <div className='flex flex-col gap-1'>
          <div className='text-gray-900 font-bold'>Height</div>
          <div className='text-gray-600'>{hlrContext!.currentHeatPump.range_heat_pump?.height_mm} mm</div>
        </div>
        <div className='flex flex-col gap-1'>
          <div className='text-gray-900 font-bold'>Depth</div>
          <div className='text-gray-600'>{hlrContext!.currentHeatPump.range_heat_pump?.depth_mm} mm</div>
        </div>
        <div className='flex flex-col gap-1'>
          <div className='text-gray-900 font-bold'>Weight</div>
          <div className='text-gray-600'>{hlrContext!.currentHeatPump.range_heat_pump?.weight_kg} kg</div>
        </div>
      </div>}

      <div className='flex flex-col gap-2'>
        <PrintLayoutPageBreak/>
        <div className='text-gray-900 text-lg font-bold'>Capacity (kW)</div>
        <TableLite rows={capacityRows} columns={capacityColumns}/>
      </div>

      {scopRows.length > 0 && <div className='flex flex-col gap-2'>
        <div className='font-bold text-gray-900 text-lg'>Seasonal Coefficient of Performance (SCOP)</div>
        <div>SCOP is the average coefficient of performance over the heating season, accounting for the variation in
              outdoor temperature. Manufacturers have to test their equipment in standard conditions and report their
              SCOP performance data to be eligible for certification.
        </div>
        <TableLite rows={scopRows} columns={scopColumns}/>
      </div>}

      {(hlrContext!.survey.location_description || hlrContext!.survey.mounting_location || hlrContext!.survey.condensate_drain || hlrContext!.survey.condensate_drain || locationPhotos.length > 0) && <div
        className='text-gray-900 text-3xl font-bold'>Location</div>}
      <div className=''>
        {hlrContext!.survey.location_description && <div>{hlrContext!.survey.location_description}</div>}
        {(hlrContext!.survey.mounting_location || hlrContext!.survey.condensate_drain || hlrContext!.survey.condensate_drain) && <HLRListBlock items={_.compact([
          hlrContext!.survey.mounting_location && <>Mounting location: {hlrContext!.survey.mounting_location}</>,
          hlrContext!.survey.mounting_location === 'Ground' ? <>Base to be built by: {hlrContext!.survey.condensate_drain}</> : undefined,
          hlrContext!.survey.condensate_drain && <>Condensate drain: {hlrContext!.survey.condensate_drain}</>
        ])} />}
      </div>

      {locationPhotos.length > 0 && <div className='h-44 flex flex-wrap gap-6'>
        {locationPhotos.map(x => <img alt='' key={x.uuid} className='h-44 rounded-md' src={x.file_base64 || `${process.env.S3_BUCKET_URL}/${x.file_url}`}/>)}
      </div>}
    </div>
  </div>
}
