import React from 'react'
import { WrappedIcon } from '../buttons/wrapped_icon'
import { Check } from 'lucide-react'

const sizes = {
  SM: 'w-9 h-5',
  MD: 'w-10 h-6',
  LG: 'w-12 h-7'
}

const circleSize = {
  SM: 'w-4 h-4',
  MD: 'w-5 h-5',
  LG: 'w-6 h-6'
}

const checkSize = {
  SM: 'w-2 h-2',
  MD: 'w-3 h-3',
  LG: 'w-4 h-4'
}

type ToggleProps = {
  value: boolean
  setValue: () => void
  size?: keyof typeof sizes
  disabled?: boolean
}

const values = new Map([
  [false, 'bg-gray-300 justify-start'],
  [true, 'bg-gray-900 justify-end']
])

export const Toggle = ({ value, setValue, disabled, size = 'MD' }: ToggleProps) => {
  return <div onClick={() => !disabled && setValue()} className={`${sizes[size]} py-1 px-0.5 cursor-pointer rounded-full flex flex-shrink-0 items-center ${values.get(value)} transition-all`}>
    <div className={`${circleSize[size]} rounded-full bg-white flex items-center justify-center`}>
      <WrappedIcon className={`${checkSize[size]} ${value ? 'visble' : 'hidden'}`} icon={Check} />
    </div>
  </div>
}
