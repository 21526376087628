import { type LucideIcon } from 'lucide-react'
import { type FC } from 'react'
import React from 'react'
import { WrappedIcon } from '../buttons/wrapped_icon'

type EmptyStateProps = {
  icon: LucideIcon
  primaryText: string
  secondaryText: string
  primaryButton: JSX.Element
  secondaryButton?: JSX.Element
  alert?: JSX.Element
}
export const EmptyState: FC<EmptyStateProps> = ({ icon, primaryText, secondaryText, primaryButton, secondaryButton, alert }) => {
  return <div className="px-6 py-20 bg-gray-50 rounded-lg border-gray-400 flex-col justify-center items-center gap-4 flex h-full">
    <div className="flex-col justify-center items-center gap-2 flex">
      <WrappedIcon className='w-12 h-12' icon={icon} />
      <div className='flex flex-col gap-1 justify-center items-center'>
        <div className="text-gray-900 text-xl font-bold">{primaryText}</div>
        <div className="self-stretch text-center text-gray-500 text-sm font-medium leading-tight">{secondaryText}</div>
      </div>

    </div>
    <div className='flex gap-4'>
      {primaryButton}
      {secondaryButton}
    </div>
    <div className='mt-10'>{alert}</div>
  </div>
}
