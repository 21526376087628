import React from 'react'
import { EnquiryOverview } from '../sections/enquiry_overview'
import { HeatLossEstimate } from '../sections/heat_loss_estimate'
import { CostEstimate } from '../sections/cost_estimate'
import { type Lead } from '../../../../../code/models/lead'
import { type Estimate } from '../../../../../code/calculate_estimate'
import { OfflinePage } from '../../../offline_page'
import { RequireRole } from '../../../../../require_role'
import { USER_ROLE_SIMPLE, USER_ROLE_HAS_SURVEY_ACCESS, USER_ROLE_SURVEYOR } from '../../../../../code/models/user'
import { type CompanyPublicInfo } from '../../../../../code/models/company'
import { type CalculatedEstimate } from '../../../../../code/models/calculated_estimate'

type Props = {
  lead: Lead
  setLead: (lead: Lead) => void
  estimate: Estimate
  isOffline: boolean
  navigateTo: (url: string) => void
  companyPublicInfo: CompanyPublicInfo
  setEstimates: (estimates: CalculatedEstimate[]) => void
}

export const EnquiryManagerDashboard = ({ navigateTo, lead, setLead, estimate, isOffline, companyPublicInfo, setEstimates }: Props) => {
  if (isOffline) return <OfflinePage navigateTo={navigateTo} />

  return (
    <RequireRole roles={[USER_ROLE_SIMPLE, USER_ROLE_HAS_SURVEY_ACCESS, USER_ROLE_SURVEYOR]}>
      <div className="space-y-6">
        <EnquiryOverview
          lead={lead}
          estimate={estimate}
          companyPublicInfo={companyPublicInfo}
          navigateTo={navigateTo}
          setEstimates={setEstimates}
          setLead={setLead}
        />
        <HeatLossEstimate
          lead={lead}
          setLead={setLead}
          estimate={estimate}
        />
        <CostEstimate
          estimate={estimate}
          lead={lead}
          companyPublicInfo={companyPublicInfo}
          navigateTo={navigateTo}
        />
      </div>
    </RequireRole>
  )
}
