import React from 'react'
import { ClickableCard } from '../../../components/content_display/card'
import { HLRContext } from './heatloss_report'
import { HLRSubheaderBlock } from './partials/hlr_subheader'

export const HLRHotWater = () => {
  const hlrContext = React.useContext(HLRContext)

  const locationPhotos = hlrContext!.files.filter(x => hlrContext!.survey.cylinder_photos.some(y => y.image_uuid === x.uuid))

  return <div className='flex-col gap-6 flex'>
    <HLRSubheaderBlock
      section="System design"
      title="Hot water"
    />

    <div>
      Your heat pump will provide 100% of your hot water requirement. Heat pumps cannot create heat as quickly as
      combi boilers, so they can’t heat up hot water instantaneously when you need it. Instead we install a cylinder
      which stores hot water so it's always ready when you need it.
    </div>

    <div className='text-gray-900 font-bold'>Proposed cylinder model</div>
    <div>We've selected the following hot water cylinder based on the number of bedrooms in your home.</div>

    {hlrContext!.currentHotWaterCylinder
      ? <ClickableCard variant='WHITE'>
          <div className="flex-col gap-3 flex">
            <div className="text-gray-900 text-sm font-bold">{hlrContext!.currentHotWaterCylinder.name}</div>
            <div className="gap-2 flex justify-between">
              <div className="text-xs font-semibold">Capacity</div>
              <div className="text-xs font-semibold">{Math.round(hlrContext!.currentHotWaterCylinder.litres)} litres
              </div>
            </div>
          </div>
        </ClickableCard>
      : <ClickableCard variant='PLACEHOLDER'>No cylinder found</ClickableCard>
    }

    { (hlrContext!.survey.cylinder_location_description || locationPhotos.length > 0) && <>
      <div className='text-gray-900 font-bold'>Cylinder location</div>

      { hlrContext!.survey.cylinder_location_description &&
        <div>{hlrContext!.survey.cylinder_location_description}</div>
      }

      {locationPhotos.length > 0 && <div className='flex flex-wrap gap-6'>
        {locationPhotos.map(x => <img key={x.uuid} className='max-h-64 rounded-md'
          src={x.file_base64 || `${process.env.S3_BUCKET_URL}/${x.file_url}`}/>)}
      </div>}

    </>}
  </div>
}
