import React, { type Dispatch, type SetStateAction } from 'react'
import { CanvasNav } from './floor/canvas_nav'
import { type SprucePoint } from './floor/code/types'
import { Circle, X } from 'lucide-react'

type RoomDrawingButtonsProps = {
  cancelDrawingRooms: () => void
  scalingPoints: SprucePoint[]
  setScalingPoints: Dispatch<SetStateAction<SprucePoint[]>>
}

export const RoomDrawingButtons = ({ cancelDrawingRooms, setScalingPoints, scalingPoints }: RoomDrawingButtonsProps) => {
  return <CanvasNav title='Draw room' items={[
    { name: 'Remove last point', icon: Circle, onClick: () => setScalingPoints(prev => prev.slice(0, -1)), variant: 'DEFAULT' },
    { name: 'Cancel', icon: X, onClick: cancelDrawingRooms, variant: 'DEFAULT' }
  ]} />
}
