import React from 'react'
import { Section } from '../../../../../components/containers/section'
import { type Company, updateCompanySettings } from '../../../../../code/models/company'
import { SettingsImageInput } from '../../components/settings_image_input'

export const BrandLogoBlock = ({ company, setCompany }: { company: Company, setCompany: (c: Company) => void }) => {
  const handleRemoveLogo = () => {
    const newCompany = {
      ...company,
      public_info: {
        ...company.public_info,
        logo: ''
      }
    }
    setCompany(newCompany)
    updateCompanySettings(newCompany)
  }

  const handleUpdateLogo = async (imageSrc: string) => {
    const newCompany = {
      ...company,
      public_info: {
        ...company.public_info,
        logo: imageSrc
      }
    }
    await updateCompanySettings(newCompany)
    setCompany(newCompany)
  }

  return <Section border={true} title="Company logo">
    <SettingsImageInput
      buttonText='Upload logo'
      placeholderText='No logo'
      helperText='Maximum file size 5MB'
      b64Image={company.public_info.logo ?? ''}
      altText='Company logo'
      handleUpdateImage={handleUpdateLogo}
      handleRemoveImage={handleRemoveLogo}
    />
  </Section>
}
