import React from 'react'

type Size = 'md' | 'lg' | 'xl' | '2xl' | '3xl'

type HeadingProps = {
  children: React.ReactNode | string
  size: Size
  className?: string
}

const sizeToElement: Record<Size, React.ElementType> = {
  md: 'h5',
  lg: 'h4',
  xl: 'h3',
  '2xl': 'h2',
  '3xl': 'h1'
}

const sizeToTailwind: Record<Size, string> = {
  md: 'text-md',
  lg: 'text-lg',
  xl: 'text-xl',
  '2xl': 'text-2xl',
  '3xl': 'text-3xl'
}

export const Heading: React.FC<HeadingProps> = ({ size, children, className = '' }) => {
  const Element = sizeToElement[size]

  return <Element className={`font-bold text-bold ${sizeToTailwind[size]} ${className}`}>
    {children}
  </Element>
}
