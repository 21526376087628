import React, { useState } from 'react'
import { type Company, updateCompany } from '../../../../../code/models/company'
import { type Labour } from '../../../../../code/models/labour'
import { Heading } from '../../../../../components/content_display/heading'
import { Button } from '../../../../../components/buttons/button'
import { InventoryTable, type InventoryTableColumn } from '../inventory_table'
import { Section } from '../../../job_layout/components/section'
import { Input } from '../../../../../components/inputs_and_selections/input'
import { DropdownMenu } from '../../../../../components/buttons/dropdown_menu'
import { Modal } from '../../../../../components/containers/modal'
import { Toggle } from '../../../../../components/inputs_and_selections/toggle'
import { Edit, Plus, Trash } from 'lucide-react'

type Props = {
  labour: Labour[]
  addOrUpdateLabour: (labour: Labour) => void
  deleteLabour: (labour: Labour) => void
  company: Company
  setCompany: (company: Company) => void
}

const ModalInputs = ({ labour, updateLabour }: { labour: Pick<Labour, 'name' | 'cost_price' | 'days'>, updateLabour: (field: keyof Labour, value: string | number) => void }) => {
  return (
    <div className="space-y-4 w-full">
      <Input label='Name' placeholder='Enter labour name' value={labour.name} setValue={(value) => updateLabour('name', value)} />
      <Input label='Price/day (ex. VAT)' type="number" value={labour.cost_price} setValue={(value) => updateLabour('cost_price', parseFloat(value))} prefix="£" postfix="/day" />
      <Input label='Default number of days' type="number" value={labour.days} setValue={(value) => updateLabour('days', parseFloat(value))} />
    </div>
  )
}

type AddLabourModalProps = {
  visible: boolean
  setVisible: (visible: boolean) => void
  addLabour: (labour: Labour) => void
}

const AddLabourModal = ({ addLabour, visible, setVisible }: AddLabourModalProps) => {
  const [labour, setLabour] = useState({
    name: '',
    cost_price: 0,
    days: 0
  })

  const updateLabour = (field: keyof typeof labour, value: string | number) => {
    setLabour(prev => ({ ...prev, [field]: value }))
  }

  return (
    <Modal
      visible={visible}
      setVisible={setVisible}
      title='Add labour'
      onConfirm={() => {
        addLabour({
          ...labour,
          uuid: crypto.randomUUID(),
          default_include: false
        })
        setLabour({ name: '', cost_price: 0, days: 0 })
      }}
    >
      <ModalInputs labour={labour} updateLabour={updateLabour} />
    </Modal>
  )
}

type EditLabourModalProps = {
  visible: boolean
  setVisible: (visible: boolean) => void
  labour: Labour
  updateLabour: (labour: Labour) => void
}

const EditLabourModal = ({ labour, updateLabour, visible, setVisible }: EditLabourModalProps) => {
  const [localLabour, setLocalLabour] = useState(labour)

  const updateLocalLabour = (field: keyof Labour, value: string | number) => {
    setLocalLabour(prev => ({ ...prev, [field]: value }))
  }

  return (
    <Modal
      visible={visible}
      setVisible={setVisible}
      title='Edit labour'
      onConfirm={() => {
        updateLabour(localLabour)
      }}
    >

      <ModalInputs labour={labour} updateLabour={updateLocalLabour} />
    </Modal>
  )
}

export const LabourInventory = ({ labour, addOrUpdateLabour, deleteLabour, company, setCompany }: Props) => {
  const [addLabourModalVisible, setAddLabourModalVisible] = useState(false)
  const [editLabourModalLabour, setEditLabourModalLabour] = useState<Labour | undefined>(undefined)

  const labourColumns: Array<InventoryTableColumn<{ name: React.ReactNode, alwaysInclude: React.ReactNode, costPrice: React.ReactNode, days: React.ReactNode, menu: React.ReactNode }>> = [
    { key: 'name', name: 'Name' },
    { key: 'alwaysInclude', name: 'Always include', infoText: 'If toggled, this labour item will always be added to new estimates and quotes.' },
    { key: 'costPrice', name: 'Price/day (ex. VAT)' },
    { key: 'days', name: 'Default number of days' },
    { key: 'menu', name: '', align: 'right' }
  ]

  const labourRows = [
    {
      name: 'Survey',
      alwaysInclude: <Toggle value={company.default_include_survey || false} setValue={async () => {
        await updateCompany({ ...company, default_include_survey: !company.default_include_survey })
        setCompany({ ...company, default_include_survey: !company.default_include_survey })
      }} />,
      costPrice: <Input type='number' value={company.survey_cost || 0} prefix="£" setValue={async (value) => {
        await updateCompany({ ...company, survey_cost: parseFloat(value) })
        setCompany({ ...company, survey_cost: parseFloat(value) })
      }} />,
      days: '',
      menu: ''
    },
    ...labour
      .sort((a, b) => a.name.localeCompare(b.name))
      .map(labour => ({
        name: labour.name,
        alwaysInclude: <Toggle value={labour.default_include} setValue={() => addOrUpdateLabour({ ...labour, default_include: !labour.default_include })} />,
        costPrice: <Input type='number' value={labour.cost_price} prefix="£" postfix="/day" setValue={(value) => {
          addOrUpdateLabour({ ...labour, cost_price: parseFloat(value) })
        }} />,
        days: <Input type='number' value={labour.days} setValue={(value) => {
          addOrUpdateLabour({ ...labour, days: parseFloat(value) })
        }} />,
        menu: <DropdownMenu
          items={[
            { label: 'Edit', onClick: () => setEditLabourModalLabour(labour), icon: Edit },
            { label: 'Delete', onClick: () => deleteLabour(labour), icon: Trash, confirmText: 'Are you sure you want to delete this labour item?' }
          ]}
        />
      }))
  ]

  return (
    <>
      <div className="space-y-6">
        <div className="flex flex-col gap-3 justify-start items-start sm:flex-row sm:justify-between sm:items-center w-full">
          <Heading size="2xl">Labour</Heading>
          <Button colour='DARK' onClick={() => setAddLabourModalVisible(true)} iconLeft={Plus}>Add labour</Button>
        </div>
        <Section>
          <InventoryTable
            rows={labourRows}
            columns={labourColumns}
          />
        </Section>
      </div>
      <AddLabourModal
        visible={addLabourModalVisible}
        setVisible={setAddLabourModalVisible}
        addLabour={addOrUpdateLabour}
      />
      {editLabourModalLabour && <EditLabourModal
        labour={editLabourModalLabour}
        updateLabour={addOrUpdateLabour}
        visible={!!editLabourModalLabour}
        setVisible={(visible) => {
          if (!visible) {
            setEditLabourModalLabour(undefined)
          }
        }}
      />}
    </>
  )
}
