import React from 'react'
import { EmptyState } from '../../../components/content_display/empty_state'
import { Button } from '../../../components/buttons/button'
import { Square } from 'lucide-react'

export const EmptyStateDemo = () => {
  return <div className="flex flex-col gap-8">
    <div className='flex flex-col gap-4'>
      <div className='text-2xl font-bold text-gray-900'>Definition</div>

      <pre className='bg-gray-100 rounded-lg p-4 overflow-x-auto'><code>
        {codeBlock}
      </code></pre>

      <div className='flex flex-col divide-y divide-dashed divide-gray-200 text-sm'>
        {componentProps.map(x => <div key={x.name} className='grid grid-cols-2 py-2'>
          <div className='font-bold text-gray-900'>{x.name}</div>
          <div>{x.description}</div>
        </div>)}
      </div>
    </div>

    <div className='flex flex-col gap-4'>
      <div className='text-2xl font-bold text-gray-900'>Component</div>
      <EmptyState
        icon={Square}
        primaryText='Hello world'
        secondaryText='My secondary text'
        primaryButton={<Button iconLeft={Square}>Primary</Button>}
        secondaryButton={<Button iconLeft={Square}>Secondary</Button>}
      />
    </div>

  </div>
}

const componentProps = [
  { name: 'icon', description: 'Main icon of empty state' },
  { name: 'primaryText', description: 'Bold primary text' },
  { name: 'secondaryText', description: 'Secondary text' },
  { name: 'primaryButton', description: 'The left side button, accepts a whole button as props' },
  { name: 'secondaryButton', description: 'The right side button, accepts a whole button as props' }
]

const codeBlock = `<EmptyState
  icon={Square}
  primaryText='Hello world'
  secondaryText='My secondary text'
  primaryButton={<Button iconLeft={Square}>Primary</Button>}
  secondaryButton={<Button iconLeft={Square}>Secondary</Button>}
/>`
