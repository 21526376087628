import React, { useState } from 'react'
import { type Proposal, type ProposalPaymentSchedule, type ProposalQuoteItem } from '../../../../code/models/proposal'
import { ClickableCard } from '../../../../components/content_display/card'
import { Button } from '../../../../components/buttons/button'
import { Badge } from '../../../../components/indicators_and_messaging/badge'
import { Text } from '../../../../components/content_display/text'
import { formatPrice } from '../../../../code/format_price'
import { DropdownMenu } from '../../../../components/buttons/dropdown_menu'
import { Heading } from '../../../../components/content_display/heading'
import { Input } from '../../../../components/inputs_and_selections/input'
import { TextArea } from '../../../../components/inputs_and_selections/text_area'
import { Icon } from '../../../../components/buttons/icon'
import { roundTo2dp } from '../../../../code/number_format'
import { calculateQuoteSnapshot } from './proposal_configurator'
import { Copy, Percent, Plus, Trash, XCircle } from 'lucide-react'

type Props = {
  proposal: Proposal
  updateProposal: (proposal: Proposal) => void
  calculatedQuote: ProposalQuoteItem[]
}

const percentageToAmount = (percentage: number, total: number) => (percentage / 100) * total

export const PaymentSchedule = ({ proposal, updateProposal, calculatedQuote }: Props) => {
  const [isEditing, setIsEditing] = useState(false)

  const handleAddRow = () => {
    const newSchedule = [
      ...(proposal.payment_schedule || []),
      {
        name: '',
        description: '',
        due_date: undefined,
        percentage: 0
      }
    ]
    updateProposal({ ...proposal, payment_schedule: newSchedule })
  }

  const calculatedQuoteSnapshot = calculateQuoteSnapshot(calculatedQuote, proposal)
  const total = calculatedQuoteSnapshot.total_pre_deductions + calculatedQuoteSnapshot.vat_on_all + calculatedQuoteSnapshot.vat_on_survey + calculatedQuoteSnapshot.discount_total + calculatedQuoteSnapshot.bus_grant

  if (!isEditing) {
    return (
      <ClickableCard variant="LIGHT" border title={
        <div className='w-full flex flex-row gap-2 items-center justify-between'>
          <Heading size="md">Payment schedule</Heading>
        </div>
      }>
        {proposal.payment_schedule?.map((row, index) => <PaymentScheduleRow key={index} row={row} index={index} totalRows={proposal.payment_schedule?.length || 0} total={total} />)}
        <Button colour="LIGHT" onClick={() => {
          if (!proposal.payment_schedule || proposal.payment_schedule.length === 0) {
            // Create a default payment schedule row
            handleAddRow()
          }
          setIsEditing(true)
        }}>Edit schedule</Button>
      </ClickableCard>
    )
  } else {
    return (
      <>
        <Heading size="md">Edit payment schedule</Heading>
        {proposal.payment_schedule?.map((row, index) => <PaymentScheduleRowEditor
          key={index}
          row={row}
          index={index}
          totalRows={proposal.payment_schedule?.length || 0}
          total={total}
          proposal={proposal}
          updateProposal={updateProposal}
        />)}
        <div className='flex gap-2'>
          <Button colour='LIGHT' iconLeft={Plus} onClick={handleAddRow}>Add stage</Button>
          <Button colour="DARK" onClick={() => setIsEditing(false)}>Save changes</Button>
        </div>
      </>
    )
  }
}

export const PaymentScheduleRow = ({ row, index, totalRows, total }: { row: ProposalPaymentSchedule, index: number, totalRows: number, total: number }) => {
  return (
    <div className="w-full flex flex-row gap-4 items-start">
      <div>
        <Badge color="LIGHT" text={`${index + 1}/${totalRows}`} />
      </div>
      <div>
        <Text size="SM" bold>{row.name}</Text>
        {row.due_date && <Text size="SM" className="text-gray-500">{row.due_date.toLocaleDateString('en-GB', { year: 'numeric', month: 'short', day: 'numeric' })}</Text>}
        {row.description && <div className='border-l-2 border-gray-300 pl-2 mt-2'>
          <Text size="XS" className='text-gray-600'>{row.description}</Text>
        </div>}
      </div>
      <div className='text-right ml-auto'>
        <Text size="SM" bold>{formatPrice(percentageToAmount(row.percentage, total), 2)}</Text>
      </div>
    </div>
  )
}

type EditorProps = {
  row: ProposalPaymentSchedule
  index: number
  totalRows: number
  total: number
  proposal: Proposal
  updateProposal: (proposal: Proposal) => void
}

export const PaymentScheduleRowEditor = ({ row, index, totalRows, total, proposal, updateProposal }: EditorProps) => {
  const [name, setName] = useState(row.name)
  const [description, setDescription] = useState(row.description)
  const [percentage, setPercentage] = useState(row.percentage)
  const [dueDate, setDueDate] = useState<Date | undefined>(row.due_date || undefined)
  const [amount, setAmount] = useState(percentageToAmount(percentage, total))

  const handleSetField = (field: string, value: any) => {
    updateProposal({
      ...proposal,
      payment_schedule: proposal.payment_schedule?.map((r, i) => {
        if (i === index) {
          return {
            ...r,
            [field]: value
          }
        } else {
          return r
        }
      })
    })
  }

  const handleSetName = (value: string) => {
    setName(value)
    handleSetField('name', value)
  }

  const handleSetDescription = (value: string) => {
    setDescription(value)
    handleSetField('description', value)
  }

  const handleSetDueDate = (value: Date | undefined) => {
    // Make sure this is a real date
    if (value instanceof Date && !isNaN(value.getTime())) {
      setDueDate(value)
      handleSetField('due_date', value)
    } else {
      setDueDate(undefined)
      handleSetField('due_date', undefined)
    }
  }

  const handleSetPercentage = (value: string) => {
    setPercentage(roundTo2dp(parseFloat(value)))
    setAmount(roundTo2dp(percentageToAmount(parseFloat(value), total)))
    handleSetField('percentage', roundTo2dp(parseFloat(value)))
  }

  const handleSetAmount = (value: string) => {
    setAmount(roundTo2dp(parseFloat(value)))
    setPercentage(roundTo2dp((parseFloat(value) / total) * 100))
    handleSetField('percentage', roundTo2dp((parseFloat(value) / total) * 100))
  }

  const handleDeleteStage = () => {
    updateProposal({ ...proposal, payment_schedule: proposal.payment_schedule?.filter((ps, i) => i !== index) })
  }

  const handleDuplicateStage = () => {
    const newSchedule = proposal.payment_schedule || []
    newSchedule.splice(index, 0, {
      name,
      description,
      due_date: dueDate ? new Date(dueDate) : undefined,
      percentage
    })
    updateProposal({ ...proposal, payment_schedule: newSchedule })
  }

  // TODO: This validation should happen inside the Input component
  const setDateValue = (date: Date | string | undefined): string => {
    try {
      if (date instanceof Date) {
        return date.toISOString().split('T')[0]
      } else {
        return date ?? ''
      }
    } catch (e) {
      return ''
    }
  }

  return (
    <ClickableCard variant="LIGHT" border>
      <div className="flex flex-row items-center justify-between">
        <Text size="SM" className='flex gap-1 items-center text-gray-600 font-semibold'>Payment stage <Badge color="LIGHT" text={`${index + 1}/${totalRows}`} /></Text>
        <DropdownMenu
          items={[
            {
              label: 'Delete',
              onClick: handleDeleteStage,
              icon: Trash,
              confirmText: 'Are you sure you want to delete this payment stage?'
            },
            {
              label: 'Duplicate',
              onClick: handleDuplicateStage,
              icon: Copy
            }
          ]}
        />
      </div>
      <Input placeholder="Stage name" value={name} setValue={handleSetName} />
      <TextArea placeholder="Description" value={description || ''} setValue={handleSetDescription} />
      <div className='grid grid-cols-2 gap-2'>
        <Input type="number" value={percentage} setValue={handleSetPercentage} postfix={<Icon icon={Percent} />} />
        <Input type="number" value={amount} setValue={handleSetAmount} prefix="£" />
      </div>
      <Input
        type="date"
        value={setDateValue(dueDate)}
        setValue={(e) => handleSetDueDate(new Date(e))}
        postfix={<Icon icon={XCircle} onClick={() => handleSetDueDate(undefined)} />}
      />
    </ClickableCard>
  )
}
