import React, { useContext, useState } from 'react'
import { ClickableCard } from '../../../components/content_display/card'
import { Toggle } from '../../../components/inputs_and_selections/toggle'
import { Button } from '../../../components/buttons/button'
import { FormLabel } from '../../../components/inputs_and_selections/form_label'
import { HorizontalFormGroup } from '../../../components/inputs_and_selections/horizontal_form_group'
import { type Lead } from '../../../code/models/lead'
import {
  createHLReportAPI,
  getHLReportPreviewPDFAPI,
  HEATLOSS_REPORT_SECTION_ALL,
  HEATLOSS_REPORT_SECTION_DESIGN,
  HEATLOSS_REPORT_SECTION_HEATLOSS_INTRO,
  HEATLOSS_REPORT_SECTION_HEATLOSS_PER_ROOM,
  HEATLOSS_REPORT_SECTION_HEATLOSS_SUMMARY,
  HEATLOSS_REPORT_SECTION_PERFORMANCE_ESTIMATE,
  HEATLOSS_REPORT_SECTION_SOUND_ASSESSMENT,
  HEATLOSS_REPORT_SECTION_SUMMARY,
  type HeatLossReport,
  sendHLReportAPI
} from '../../../code/models/heatloss_report'
import { type CompanyPublicInfo, getCompanyReportEmailTemplate } from '../../../code/models/company'
import { downloadBlob, isFlagSet } from '../../../code/helpers'
import { HeatLossReportPage } from './heatloss_report'
import type { PropertySurvey } from '../../../code/models/property'
import type { FileWrapper } from '../file_wrapper'
import type { InventoryHeatPump, InventoryHotWaterCylinder } from '../../../code/models/inventory'
import {
  renderHTMLReplacingPlaceholders,
  renderJSONReplacingPlaceholders,
  TTPlaceholderCompanyName,
  TTPlaceholderCompanyPhone,
  TTPlaceholderCustomerAddress,
  TTPlaceholderCustomerName,
  TTPlaceholderEstimateContactName,
  TTPlaceholderEstimateContactPhone,
  TTPlaceholderViewReport
} from '../../../code/tiptap_placeholders'
import { AdminContext } from '../../admin/admin_layout'
import { type JSONContent } from '@tiptap/react'
import { SendEmailWithTemplateModal } from './partials/send_email_with_template_modal'
import { FullscreenPreviewModal } from './partials/fullscreen_preview_modal'
import { Loader } from '../../../components/indicators_and_messaging/loader'
import { postUserEvent, UserEventType } from '../../../code/models/user'
import { FileText } from 'lucide-react'

type HeatLossSettingsProps = {
  companyPublicInfo: CompanyPublicInfo

  // required to save report generation settings: enabled sections
  lead: Lead
  setLead: (l: Lead) => void

  // required to generate report
  survey: PropertySurvey
  files: FileWrapper[]
  currentHeatPump: InventoryHeatPump
  currentHotWaterCylinder: InventoryHotWaterCylinder
}
export const HeatLossReportSettings = ({
  companyPublicInfo,
  lead,
  setLead,
  survey,
  files,
  currentHeatPump,
  currentHotWaterCylinder
}: HeatLossSettingsProps) => {
  const adminContext = useContext(AdminContext)

  const tiptapMappings = {
    [TTPlaceholderCustomerName.id]: lead.customer!.name!,
    [TTPlaceholderCustomerAddress.id]: lead.property.address + ', ' + lead.property.postcode,
    [TTPlaceholderEstimateContactName.id]: adminContext.data.company!.public_info?.estimate_contact_name ?? '',
    [TTPlaceholderEstimateContactPhone.id]: adminContext.data.company!.public_info?.estimate_contact_phone ?? '',
    [TTPlaceholderCompanyName.id]: adminContext.data.company!.public_info?.name,
    [TTPlaceholderCompanyPhone.id]: adminContext.data.company!.public_info?.phone
  }

  // use saved list of recipients or default to customer's email
  const [showPreviewWindow, setShowPreviewWindow] = React.useState(false)
  const [showSendingModal, setShowSendingModal] = React.useState(false)
  const [emailText, setEmailText] = React.useState<JSONContent>(
    renderJSONReplacingPlaceholders(
      getCompanyReportEmailTemplate(adminContext.data.company!.report_email_template!),
      tiptapMappings
    )
  )

  const [pdfGenerating, setPdfGenerating] = useState(false)

  const sections = lead.report_sections ?? HEATLOSS_REPORT_SECTION_ALL
  const snapshot = {
    customer: lead.customer!,
    property: lead.property,
    survey,
    files,
    currentHeatPump,
    currentHotWaterCylinder
  }

  const handleSend = async (recipients: string[]) => {
    try {
      const report = {
        lead_uuid: lead.uuid!,
        company_uuid: adminContext.data.company!.public_info.uuid,
        recipients,
        email_text: JSON.stringify(emailText),
        contents_bitmask: sections,
        snapshot
      } satisfies HeatLossReport

      const reportCreateResponse = await createHLReportAPI(report)

      if (!reportCreateResponse) {
        alert('Failed to create report')
      }

      (report as HeatLossReport).uuid = reportCreateResponse.uuid

      await sendHLReportAPI(report, renderEmailHTML(reportCreateResponse.uuid))

      // We only post the user event if the email was successfully sent, because it's connected to billing
      postUserEvent({
        event_type: UserEventType.GeneratedReport,
        company_uuid: adminContext.data.company!.public_info.uuid,
        extra_data: {
          survey_uuid: survey.uuid
        }
      })
    } catch (e) {
      console.error(e)
    }
  }

  const handleDownloadReportPreviewPDF = async () => {
    try {
      setPdfGenerating(true)

      const report = {
        lead_uuid: lead.uuid!,
        company_uuid: adminContext.data.company!.public_info.uuid,
        contents_bitmask: sections,
        snapshot
      } satisfies HeatLossReport

      const reportCreateResponse = await createHLReportAPI(report)

      if (!reportCreateResponse) {
        alert('Failed to create report')
      }

      const pdfData = await getHLReportPreviewPDFAPI(reportCreateResponse.uuid, companyPublicInfo.uuid)
      downloadBlob(pdfData!, lead.property.address + ' — Heat Loss Report.pdf')

      // We only post the user event if the PDF was successfully generated, because it's connected to billing
      postUserEvent({
        event_type: UserEventType.GeneratedReport,
        company_uuid: adminContext.data.company!.public_info.uuid,
        extra_data: {
          survey_uuid: survey.uuid
        }
      })
    } catch (e) {
      console.error(e)
    } finally {
      setPdfGenerating(false)
    }
  }

  const renderEmailHTML = (reportUUID: string) => {
    return renderHTMLReplacingPlaceholders(emailText, {
      ...tiptapMappings,
      [TTPlaceholderViewReport.id]: process.env.BASE_URL! + adminContext.data.company!.public_info.subdomain + '/report/' + reportUUID
    })
  }

  return <div>
    <SendEmailWithTemplateModal
      visible={showSendingModal}
      setVisible={setShowSendingModal}
      handleSend={handleSend}

      emailRecipients={!lead.report_recipients ? (lead.customer?.email ?? '') : lead.report_recipients}
      setEmailRecipients={(e: string) => {
        setLead({
          ...lead,
          report_recipients: e
        })
      }}
      emailText={emailText}
      setEmailText={setEmailText}
      editorPlaceholders={[
        TTPlaceholderViewReport
      ]}
    />

    <FullscreenPreviewModal
      title={'Report preview'}
      visible={showPreviewWindow}
      setVisible={setShowPreviewWindow}
      previewContent={
        <HeatLossReportPage
          sections={lead.report_sections ?? HEATLOSS_REPORT_SECTION_ALL}
          snapshot={snapshot}
          company_public_info={companyPublicInfo}
        />
      }
      sidebarTitle={'Send & Save to PDF'}
      sidebarContent={<>
        <Button className='w-full' onClick={() => setShowSendingModal(true)}>Send via Email</Button>
        <div className='flex flex-row gap-0 items-center w-full justify-between py-6'>
          <div className='h-[1px] border-b border-dashed border-gray-200 w-2/5'></div>
          <div className='text-center text-gray-500 text-xs w-1/5 '>or</div>
          <div className='h-[1px] border-b border-dashed border-gray-200 w-2/5'></div>
        </div>
        {!pdfGenerating && <Button
          iconLeft={FileText}
          onClick={handleDownloadReportPreviewPDF}
          colour='LIGHT'
          className='w-full'
          disabled={pdfGenerating}
        >Save to PDF</Button>}
        { pdfGenerating && <div className='text-center text-gray-500 text-xs flex flex-col gap-2'>
          <Loader/>
          <span>Generating PDF</span>
        </div>
        }
      </>}
    />

    {!showPreviewWindow && <div className="flex-col gap-5 flex">
      <div className='flex flex-row justify-between items-center'>
        <div className="text-gray-900 text-xl font-bold">Configure report</div>
        <Button onClick={() => setShowPreviewWindow(true)}>Preview and Send</Button>
      </div>

      <div className='gap-5 flex flex-col'>
        <ClickableCard variant='WHITE'>
          <HorizontalFormGroup
            formLabel={<FormLabel labelText={'Summary'} helperText={'A page providing the customer with an overview of the heat loss findings, chosen heat pump and cost savings.'} />}
            input={<Toggle
              value={isFlagSet(sections, HEATLOSS_REPORT_SECTION_SUMMARY)}
              setValue={() => setLead({
                ...lead,
                report_sections: sections ^ HEATLOSS_REPORT_SECTION_SUMMARY
              })}
            />}
          />
        </ClickableCard>

        <ClickableCard variant='WHITE'>
          <HorizontalFormGroup
            formLabel={<FormLabel labelText={'Overall heat loss'} helperText={'Includes the calculation conditions, heat loss breakdown by room and element, and heat loss by floor including the floor-plan.'} />}
            input={<Toggle
              value={isFlagSet(sections, HEATLOSS_REPORT_SECTION_HEATLOSS_SUMMARY)}
              setValue={() => {
                let mask = sections

                // if it was enabled, we're about to disable it, so we need to disable all child sections
                if (isFlagSet(sections, HEATLOSS_REPORT_SECTION_HEATLOSS_SUMMARY)) {
                  mask &= ~HEATLOSS_REPORT_SECTION_HEATLOSS_INTRO
                  mask &= ~HEATLOSS_REPORT_SECTION_HEATLOSS_PER_ROOM
                }

                setLead({
                  ...lead,
                  report_sections: mask ^ HEATLOSS_REPORT_SECTION_HEATLOSS_SUMMARY
                })
              }} />}
          />
          <HorizontalFormGroup
            className='border-l-gray-300 border-l-4 pl-4'
            disabled={!isFlagSet(sections, HEATLOSS_REPORT_SECTION_HEATLOSS_SUMMARY)}
            formLabel={<FormLabel labelText={'Introduction'} helperText={'A description of what heat loss is and why it\'s important'} />}
            input={<Toggle
              disabled={!isFlagSet(sections, HEATLOSS_REPORT_SECTION_HEATLOSS_SUMMARY)}
              value={isFlagSet(sections, HEATLOSS_REPORT_SECTION_HEATLOSS_INTRO)}
              setValue={() => setLead({
                ...lead,
                report_sections: sections ^ HEATLOSS_REPORT_SECTION_HEATLOSS_INTRO
              })}
            />}
          />
          <HorizontalFormGroup
            className='border-l-gray-300 border-l-4 pl-4'
            disabled={!isFlagSet(sections, HEATLOSS_REPORT_SECTION_HEATLOSS_SUMMARY)}
            formLabel={<FormLabel labelText={'Detailed page per room'} helperText={'Include a heat loss page for each room showing full details of all the inputs used and the results'} />}
            input={<Toggle
              disabled={!isFlagSet(sections, HEATLOSS_REPORT_SECTION_HEATLOSS_SUMMARY)}
              value={isFlagSet(sections, HEATLOSS_REPORT_SECTION_HEATLOSS_PER_ROOM)}
              setValue={() => setLead({
                ...lead,
                report_sections: sections ^ HEATLOSS_REPORT_SECTION_HEATLOSS_PER_ROOM
              })}
            />}
          />
        </ClickableCard>

        <ClickableCard variant='WHITE'>
          <HorizontalFormGroup
            formLabel={<FormLabel labelText={'Design'} helperText={'Details of the proposed heat pump, emitter replacement, proposed cylinder and associated hot water calcs.'} />}
            input={<Toggle
              value={isFlagSet(sections, HEATLOSS_REPORT_SECTION_DESIGN)}
              setValue={() => setLead({
                ...lead,
                report_sections: sections ^ HEATLOSS_REPORT_SECTION_DESIGN
              })}
            />}
          />
        </ClickableCard>

        <ClickableCard variant='WHITE'>
          <HorizontalFormGroup
            formLabel={<FormLabel labelText={'Sound assessment'} helperText={'Results of the sound assessment along with the inputs and calculations behind the assessment.'} />}
            input={<Toggle
              value={isFlagSet(sections, HEATLOSS_REPORT_SECTION_SOUND_ASSESSMENT)}
              setValue={() => setLead({
                ...lead,
                report_sections: sections ^ HEATLOSS_REPORT_SECTION_SOUND_ASSESSMENT
              })}
            />}
          />
        </ClickableCard>

        <ClickableCard variant='WHITE'>
          <HorizontalFormGroup
            formLabel={<FormLabel labelText={'Performance estimate'} helperText={'An estimate of system performance covering energy, bills and carbon, plus the assumptions behind the numbers.'} />}
            input={<Toggle
              value={isFlagSet(sections, HEATLOSS_REPORT_SECTION_PERFORMANCE_ESTIMATE)}
              setValue={() => setLead({
                ...lead,
                report_sections: sections ^ HEATLOSS_REPORT_SECTION_PERFORMANCE_ESTIMATE
              })}
            />}
          />
        </ClickableCard>
      </div>
    </div> }
  </div>
}
