import React, { type Dispatch, type SetStateAction, useEffect, useState } from 'react'
import type { JSONContent } from '@tiptap/react'
import { getCompanyDeclinedEmailTemplate } from '../../../../../code/models/company'
import {
  renderHTMLReplacingPlaceholders,
  renderJSONReplacingPlaceholders,
  TTPlaceholderCompanyName,
  TTPlaceholderCompanyPhone,
  TTPlaceholderCustomerAddress,
  TTPlaceholderCustomerName
} from '../../../../../code/tiptap_placeholders'
import { Modal } from '../../../../../components/containers/modal'
import { logEvent } from '../../../../../code/log_event'
import { type Lead, sendDeclined } from '../../../../../code/models/lead'
import { Tiptap } from '../../../../../components/inputs_and_selections/tiptap'
import type { AdminContextType } from '../../../admin_layout'

type DeclineModalBlockProps = {
  isVisible: boolean
  setIsVisible: Dispatch<SetStateAction<boolean>>
  adminContext: AdminContextType
  lead: Lead
  setLead: (lead: Lead) => void
}

export const SendDeclinedModalBlock = ({ isVisible, setIsVisible, adminContext, lead, setLead }: DeclineModalBlockProps) => {
  const [emailTemplateJSON, setEmailTemplateJSON] = useState<JSONContent>()

  useEffect(() => {
    if (!adminContext.data?.company) return

    // the email template used to send the estimate
    const companyDeclineEmailTemplate = getCompanyDeclinedEmailTemplate(adminContext.data.company.decline_template!)

    // render all placeholders EXCEPT the View Estimate button
    const emailJSON = renderJSONReplacingPlaceholders(companyDeclineEmailTemplate, {
      [TTPlaceholderCustomerName.id]: lead.customer?.name ?? '',
      [TTPlaceholderCustomerAddress.id]: `${lead.property.address}, ${lead.property.postcode}`,
      [TTPlaceholderCompanyName.id]: adminContext.data?.company?.public_info?.name,
      [TTPlaceholderCompanyPhone.id]: adminContext.data?.company?.public_info?.phone
    })

    setEmailTemplateJSON(emailJSON)
  }, [adminContext])

  if (!adminContext.data?.company) return

  return <Modal
    title={'Decline enquiry message'}
    visible={isVisible}
    setVisible={setIsVisible}
    onConfirm={async () => {
      logEvent({ name: 'Decline Enquiry Clicked', properties: {} }, adminContext.data?.company?.public_info?.subdomain ?? '')

      // render the email template with the View Estimate button
      const emailHTML = renderHTMLReplacingPlaceholders(emailTemplateJSON!)

      await sendDeclined(lead.uuid!, adminContext.data?.company?.public_info?.uuid ?? '', emailHTML)
      setLead({ ...lead, status: 'Declined' })
    }}
    confirmButtonLabel="Send"
  >
    <div className="flex flex-col gap-4">
      <div className="flex flex-col gap-4">
        <Tiptap
          editable={true}
          className='w-full rounded border border-gray-300 p-2 focus:outline-none'
          onUpdateCallback={async (editor) => {
            setEmailTemplateJSON(editor.getJSON())
          }}
          content={emailTemplateJSON!}
        />
        {adminContext.data?.company?.public_info?.logo && <img src={adminContext.data?.company?.public_info?.logo} className="max-w-40" alt="Installer logo"/>}
      </div>
    </div>
  </Modal>
}
