import { formatMaterialUValue, getMaterialByUuid, getMaterialUValue } from '../../../code/models/material'
import { Badge } from '../../../components/indicators_and_messaging/badge'
import React from 'react'
import { type MaterialsSelectorGroupedProps } from './materials_selector'
import { type CurrentFloorPlanPage } from '../floor/floor'

export type MaterialInputFieldProps = {
  selectorProps: MaterialsSelectorGroupedProps
  setMsProps: React.Dispatch<React.SetStateAction<MaterialsSelectorGroupedProps | undefined>>
  setPage: (page: CurrentFloorPlanPage) => void }

export const MaterialInputField = ({ selectorProps, setMsProps, setPage }: MaterialInputFieldProps) => {
  const uValue = getMaterialUValue(selectorProps.selectedMaterial!)

  return <div
    className='w-full cursor-pointer flex items-center justify-between gap-4 border px-4 py-2 rounded-lg border-gray-200'
    onClick={() => {
      setMsProps(selectorProps)
      setPage('MATERIALS')
    }}
  >
    <div className='text-gray-600 text-sm font-semibold'>
      {getMaterialByUuid(selectorProps.selectedMaterial!.uuid, selectorProps.materials!)?.name}
    </div>
    {uValue !== undefined && <Badge color='INDIGO' text={formatMaterialUValue(uValue)}></Badge>}
  </div>
}
