import React from 'react'
import { HLRContext } from './heatloss_report'

export const HLRTitlePage = () => {
  const hlrContext = React.useContext(HLRContext)

  const reportGenerationDate = new Date().toLocaleDateString('en-GB', { day: '2-digit', month: 'long', year: 'numeric' })

  const preparedFor = [
    hlrContext!.customer.name ?? '',
    hlrContext!.property.address,
    hlrContext!.property.postcode
  ]

  const preparedBy = [
    hlrContext!.company_public_info.installer_first_name + ' ' + hlrContext!.company_public_info.installer_last_name,
    hlrContext!.company_public_info.name,
    hlrContext!.company_public_info.notification_email,
    hlrContext!.company_public_info.phone
  ]

  const propertyPhotos = hlrContext!.files.filter(x => hlrContext!.survey.property_images.some(y => y.image_uuid === x.uuid))

  return (
    <div className="flex-col gap-16 flex">
      <div className="flex-col gap-9 flex">
        {/* page title */}
        <div className="flex-col gap-1 flex">
          <div className="">
            {reportGenerationDate}
          </div>
          <div className="self-stretch text-gray-900 lg:text-6xl text-3xl font-bold">
            Heat Loss Report<br/>& System Design
          </div>
        </div>

        {/* prepared for / prepared by info */}
        <div className="w-full flex">
          <div className="w-1/2 flex-col gap-3 flex">
            <div className="text-gray-900 text-lg font-bold">Prepared for</div>
            <div className="flex-col gap-1 flex">
              {preparedFor.map((x, i) => (
                <div key={'preparedFor-' + i} className="">{x}</div>
              ))}
            </div>
          </div>
          <div className="w-1/2 flex-col gap-3 flex">
            <div className="text-gray-900 text-lg font-bold">Prepared by</div>
            <div className="flex-col gap-1 flex">
              {preparedBy.map((x, i) => (
                <div key={'preparedBy' + i} className="">{x}</div>
              ))}
            </div>
          </div>
        </div>
      </div>

      <div className="text-xs tracking-tight">
        { [hlrContext!.company_public_info.name, hlrContext!.company_public_info.address, hlrContext!.company_public_info.phone]
          .filter(x => x.trim() !== '')
          .join(', ') }
      </div>

      {propertyPhotos.length > 0 &&
          <img className="max-h-96 max-w-full object-cover" src={propertyPhotos[0].file_base64 || `${process.env.S3_BUCKET_URL}/${propertyPhotos[0].file_url}`} alt={''}/>
      }
    </div>
  )
}
