import React from 'react'
import { BottomSheetHeader } from '../../../components/containers/bottom_sheet_header'

export const BottomSheetHeaderDemo = () => {
  return <div className="flex flex-col gap-8">
    <div className='flex flex-col gap-4'>
      <div className='text-2xl font-bold text-gray-900'>Definition</div>

      <pre className='bg-gray-100 rounded-lg p-4 overflow-x-auto'><code>
        {codeBlock}
      </code></pre>

      <div className='flex flex-col divide-y divide-dashed divide-gray-200 text-sm'>
        {componentProps.map(x => <div key={x.name} className='grid grid-cols-2 py-2'>
          <div className='font-bold text-gray-900'>{x.name}</div>
          <div>{x.description}</div>
        </div>)}
      </div>
    </div>

    <div className='flex flex-col gap-4'>
      <div className='text-2xl font-bold text-gray-900'>Component</div>
      <BottomSheetHeader
        title='Hello world'
      />
    </div>

  </div>
}

const componentProps = [
  { name: 'title', description: 'Title of the header' },
  { name: 'setPages', description: 'Always show back button, by default pop the last page off the stack to get to the previous page' },
  { name: 'onBack', description: 'Any additional state cleanup that should happen when going back' }
]

const codeBlock = `<BottomSheetHeader
  title: 'Hello world'
  setPages: noop
  onBack: noop
/>`
