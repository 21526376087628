import { type Wall } from './wall'
import {
  type CustomAttributes,
  type ExposedFloorAttributes,
  type GroundFloorAttributes,
  type IntermediateFloorAndCeilingAttributes,
  type Material,
  type RoofAttributes
} from './material'
import { type SurveyAgeBand } from './age_bands'
import type { PropertySurvey, SurveyDesign, SurveyImage } from './property'
import { ceilingShapeAImg } from '../../assets/images/ceiling_shapes/ceiling_shape_a'
import { ceilingShapeBImg } from '../../assets/images/ceiling_shapes/ceiling_shape_b'
import { ceilingShapeCImg } from '../../assets/images/ceiling_shapes/ceiling_shape_c'
import { ceilingShapeDImg } from '../../assets/images/ceiling_shapes/ceiling_shape_d'
import { getExposedPerimeterM, getFloorAreaM2 } from './heat_loss'
import { sum } from 'lodash'

import { getEmitterWatts } from './radiator_model'
import { type RoofLight } from './rooflight'
import { type Emitter } from './radiator'

export const CEILING_SHAPE_TYPES = ['Flat', 'Vaulted'] as const
export type CeilingTypeCategory = typeof CEILING_SHAPE_TYPES[number]

export const CEILING_TYPES = [
  { uuid: 'flat', name: 'Flat', category_uuid: 'Flat' as CeilingTypeCategory, showCeilingWidth: false, img: undefined, getAverageRoomHeightM: (room: Room) => room.height_m ?? 0 },
  { uuid: 'a', name: 'A', category_uuid: 'Vaulted' as CeilingTypeCategory, showCeilingWidth: false, img: ceilingShapeAImg, getAverageRoomHeightM: (room: Room) => ((room.height_m ?? 0) + (room.height_2_m ?? 0)) / 2 },
  {
    uuid: 'b',
    name: 'B',
    category_uuid: 'Vaulted' as CeilingTypeCategory,
    showCeilingWidth: true,
    img: ceilingShapeBImg,
    getAverageRoomHeightM: (room: Room) => {
      if (!room.height_2_m || !room.ceiling_width_1_m || !room.height_m || !room.ceiling_width_2_m) return 0
      return (room.height_2_m * room.ceiling_width_1_m + (room.height_m + room.height_2_m) / 2 * (room.ceiling_width_2_m - room.ceiling_width_1_m)) / room.ceiling_width_2_m
    }
  },
  { uuid: 'c', name: 'C', category_uuid: 'Vaulted' as CeilingTypeCategory, showCeilingWidth: false, img: ceilingShapeCImg, getAverageRoomHeightM: (room: Room) => ((room.height_m ?? 0) + (room.height_2_m ?? 0)) / 2 },
  {
    uuid: 'd',
    name: 'D',
    category_uuid: 'Vaulted' as CeilingTypeCategory,
    showCeilingWidth: true,
    img: ceilingShapeDImg,
    getAverageRoomHeightM: (room: Room) => {
      if (!room.height_m || !room.height_2_m || !room.ceiling_width_1_m || !room.ceiling_width_2_m) return 0
      return ((room.height_2_m * room.ceiling_width_1_m + (room.height_m + room.height_2_m) / 2 * (room.ceiling_width_2_m - room.ceiling_width_1_m)) / room.ceiling_width_2_m)
    }
  }
]

export type CeilingType = typeof CEILING_TYPES[number]

export const FLUE_TYPES = [
  { uuid: 'no', name: 'None', ach_volume_less_than_or_equal_to_40_m3: 0, ach_volume_greater_than_40_m3: 0 },
  { uuid: 'yes_throat', name: 'Yes (with throat restrictor)', ach_volume_less_than_or_equal_to_40_m3: 3, ach_volume_greater_than_40_m3: 2 },
  { uuid: 'yes_without_throat', name: 'Yes (without throat restrictor)', ach_volume_less_than_or_equal_to_40_m3: 5, ach_volume_greater_than_40_m3: 4 }
]
export type FlueType = typeof FLUE_TYPES[0]

export const ROOM_OVERWRITTEN_ATTRIBUTE_HEIGHT_M = 0x000001
export const ROOM_OVERWRITTEN_ATTRIBUTE_FLOOR_MATERIAL = 0x000002
export const ROOM_OVERWRITTEN_ATTRIBUTE_CEILING_MATERIAL = 0x000004

export type Room = {
  uuid?: string

  // This is a bitfield to store overwritten attributes of the room.
  //
  // Used to flag is particular attribute has been overwritten by the user.
  // We can't rely on optional fields, `undefiled` attributes, etc
  // because in some cases we need to flag something as overwritten if any of two values changed, e.g. ceiling type or ceiling material
  //
  // Also, having `undefiled` attributed is VERy confusing: it's not clear enough was it:
  // - on purpose
  // - we get value from the parent entity
  // - we calculate value somewhere
  // - or it's just a bug
  //
  // Also, having `undefined` values as marks to get a parent entity value requires to pass the parent entity everywhere along with the child entity.
  // This leads to increasing complexity and more attributes in functions and components.
  //
  // Another one reason to have the bitfield is avoiding complex logic to determine if the attribute was changed by the user,
  // e.g. in some cases we need to compare system default value <> parent entity default value <> room value,
  // and also understand was they changed or not on ALL levels. This is clunky and error-prone.
  //
  // So, we explicitly set attribute as overwritten if it's changed by the user.
  // Expected behaviour on changing parent entity settings: override child attributes if the flag is NOT set
  //
  // Finally, we're using a bitmap to store overwritten attributes because it's a very efficient way to store multiple boolean flags in a single number.
  overwritten_attributes_flags: number

  name: string
  x?: number
  y?: number
  ach_override?: number
  indoor_temp_override_c?: number
  ceiling_type_uuid: string
  // Sloped ceilings allow the user to enter additional height and width information, see sloped ceiling options for clarification.
  height_m: number | undefined
  height_2_m: number | undefined
  ceiling_width_1_m: number | undefined
  ceiling_width_2_m: number | undefined
  rooflights: RoofLight[]
  flue_type_uuid?: string
  notes: string

  walls: Wall[]
  radiators: Emitter[]

  images: SurveyImage[]

  ceiling_material: Material | undefined
  floor_material: Material | undefined
  room_type_uuid?: string
  age_band?: SurveyAgeBand
  room_group_uuid: string | undefined

  ceiling_other_side_temp_override_c?: number | undefined
  floor_other_side_temp_override_c?: number | undefined

  // calculated
  text?: string
  wattsCoveredPct?: string
  emitterOutputVsRoomDemandText?: string
  wattsLost?: number
  roomText?: string
}

export const getCeilingUValue = (room: Room, thermalBridgingUValueAdditionWPerM2K: number): number => {
  if (room.ceiling_material === undefined) {
    // FIXME: this is a temporary fix, we should not have rooms without ceiling material
    return 0
  }

  const uValueAddition = room.ceiling_material.applicable_to === 'roof' ? thermalBridgingUValueAdditionWPerM2K : 0

  if (['custom_layered', 'custom_simple'].includes(room.ceiling_material.type)) {
    return (room.ceiling_material.extra_data as CustomAttributes).u_value! + uValueAddition
  }

  if (room.ceiling_material.applicable_to === 'roof') {
    return (room.ceiling_material.extra_data as RoofAttributes).u_value + uValueAddition
  }

  if (room.ceiling_material.applicable_to === 'intermediate-floor-and-ceiling') {
    const uValue = (room.ceiling_material.extra_data as IntermediateFloorAndCeilingAttributes).u_value_flow_up
    if (uValue === undefined) {
      throw new Error(`The exposed floor material ${room.ceiling_material.name} has been selected for the ceiling`)
    }
    return uValue
  }

  throw new Error('Invalid ceiling material type')
}

export const getFloorUValue = (room: Room, thermalBridgingUValueAdditionWPerM2K: number): number => {
  // Have to do at the room level in case they put different materials for different rooms
  if (room.floor_material === undefined) {
    // FIXME: this is a temporary fix, we should not have rooms without floor material
    return 0
  }

  const uValueAddition = room.floor_material.applicable_to === 'intermediate-floor-and-ceiling' ? 0 : thermalBridgingUValueAdditionWPerM2K

  if (['custom_layered', 'custom_simple'].includes(room.floor_material.type)) {
    return (room.floor_material.extra_data as CustomAttributes).u_value! + uValueAddition
  }

  if (room.floor_material.applicable_to === 'ground-floor') {
    return getGroundFloorUValue(room) + uValueAddition
  }

  // Will work with old un-migrated intermediate floor materials on survey because their 'applicable-to' won't be 'exposed-floor'
  if (room.floor_material.applicable_to === 'exposed-floor') {
    return (room.floor_material.extra_data as ExposedFloorAttributes).u_value + uValueAddition
  }

  if (room.floor_material.applicable_to === 'intermediate-floor-and-ceiling') {
    return (room.floor_material.extra_data as IntermediateFloorAndCeilingAttributes).u_value_flow_down
  }
  throw new Error(`Invalid floor material type ${room.floor_material.applicable_to}`)
}

export const getGroundFloorUValue = (room: Room): number => {
  const floorMaterialExtraData = room.floor_material!.extra_data as GroundFloorAttributes

  if (floorMaterialExtraData.u_value) return floorMaterialExtraData.u_value // new build case

  const floorInsulationUValueWPerM2K = floorMaterialExtraData.insulation_u_value
  const floorInsulationRValueM2KPerW = floorInsulationUValueWPerM2K ? 1 / floorInsulationUValueWPerM2K : 0

  // Section 6.12 of the SAP 10 document: https://bregroup.com/expertise/energy/sap/sap10
  const areaM2 = getFloorAreaM2(room.walls)
  const exposedPerimeterM = getExposedPerimeterM(room.walls)
  // if exposed perimeter is 0 then BRatioM will be infinity so set to 0.05 in accordance with CIBSE equation 5
  if (exposedPerimeterM === 0) {
    const uValueInternalRoom = 0.05
    return 1 / (1 / uValueInternalRoom + floorInsulationRValueM2KPerW)
  }
  const BRatioM = 2 * areaM2 / exposedPerimeterM
  const wallThicknessM = 0.3 // later maybe take actual thickness of selected material

  // constants
  const soilConductivityWPerMK = 1.5
  const RSiM2KPerW = 0.17
  const RSeM2KPerW = 0.04
  const heightAboveGroundM = 0.3
  const UWallsToUnderfloorSpaceWPerM2K = 1.5
  const ventilationOpeningsPerExposedPerimeterM2PerM = 0.003
  const averageWindSpeedMPerS = 5
  const windShieldingFactor = 0.05

  if (floorMaterialExtraData.construction === 'Suspended') {
    const dgM = wallThicknessM + soilConductivityWPerMK * (RSiM2KPerW + RSeM2KPerW)
    const Ug = 2 * soilConductivityWPerMK * Math.log(Math.PI * BRatioM / dgM + 1) / (Math.PI * BRatioM + dgM)
    const Ux = (2 * heightAboveGroundM * UWallsToUnderfloorSpaceWPerM2K / BRatioM) + (1450 * ventilationOpeningsPerExposedPerimeterM2PerM * averageWindSpeedMPerS * windShieldingFactor / BRatioM)
    return 1 / (2 * RSiM2KPerW + floorInsulationRValueM2KPerW + 1 / (Ug + Ux))
  }
  // solid
  const dtM = wallThicknessM + soilConductivityWPerMK * (RSiM2KPerW + RSeM2KPerW + floorInsulationRValueM2KPerW)
  if (dtM < BRatioM) return 2 * soilConductivityWPerMK * Math.log(Math.PI * BRatioM / dtM + 1) / (Math.PI * BRatioM + dtM)
  return soilConductivityWPerMK / (0.457 * BRatioM + dtM)
}

export const getRoomEmitterWatts = (room: Room, design: SurveyDesign, survey: PropertySurvey, designTempC: number, groundTempC: number) => {
  const designRadiators = design.radiators.filter(x => x.room_uuid === room.uuid)

  const allRadiators = [...designRadiators, ...room.radiators]
  const radiatorsToCalculate = allRadiators.filter(x => !design.removed_radiator_uuids.includes(x.uuid!) && !design.radiators.some(y => y.replaces_uuid === x.uuid!))
  const radiatorWatts = sum(radiatorsToCalculate.map(x => getEmitterWatts(x, room, design, survey, designTempC, groundTempC)))

  return radiatorWatts
}
