import React, { type Dispatch, type SetStateAction, useEffect, useState } from 'react'
import type { JSONContent } from '@tiptap/react'
import { type CalculatedEstimate, updateCalculatedEstimate } from '../../../code/models/calculated_estimate'
import { getCompanyNudgeLeadEmailTemplate } from '../../../code/models/company'
import {
  renderHTMLReplacingPlaceholders,
  renderJSONReplacingPlaceholders,
  TTPlaceholderCarbonSaving,
  TTPlaceholderCompanyName,
  TTPlaceholderCompanyPhone,
  TTPlaceholderCustomerAddress,
  TTPlaceholderCustomerName,
  TTPlaceholderHeatPumpModel,
  TTPlaceholderViewEstimate
} from '../../../code/tiptap_placeholders'
import { numberFormat } from '../../../code/number_format'
import { formatPrice } from '../../../code/format_price'
import { Modal } from '../../../components/containers/modal'
import { logEvent } from '../../../code/log_event'
import { type Lead, sendNudgeEmail } from '../../../code/models/lead'
import { Tiptap } from '../../../components/inputs_and_selections/tiptap'
import type { AdminContextType } from '../admin_layout'
import { calculateQuote } from '../../../code/calculate_quote'
import { DEFAULT_ESTIMATE_RAD_CHANGE_PERCENTAGE, type Estimate } from '../../../code/calculate_estimate'
import { sum } from 'lodash'
import { getApproxNumberOfRadiatorChanges } from '../../../code/models/estimated_rooms_and_radiators'

type SendNudgeLeadEmailModalProps = {
  isVisible: boolean
  setIsVisible: Dispatch<SetStateAction<boolean>>
  adminContext: AdminContextType
  estimate: Estimate
  calculatedEstimate?: CalculatedEstimate
  lead: Lead
  reloadTrigger: () => void
}
export const SendNudgeLeadEmailModal = ({ isVisible, setIsVisible, adminContext, calculatedEstimate, estimate, lead, reloadTrigger }: SendNudgeLeadEmailModalProps) => {
  if (!calculatedEstimate) return null

  const [emailTemplateJSON, setEmailTemplateJSON] = useState<JSONContent>()
  const [estimateToSend, setEstimateToSend] = useState<CalculatedEstimate>()

  const currentEstimateHeatPump = adminContext.data?.heatPumps?.find(x => x.uuid === calculatedEstimate.range_heat_pump_uuid)
  const currentEstimateHotWaterCylinder = adminContext.data?.hotWaterCylinders?.find(x => x.uuid === calculatedEstimate.range_hot_water_cylinder_uuid)

  const [calculatedQuote] = calculateQuote({
    company: adminContext.data.company!,
    selectedHeatPump: currentEstimateHeatPump,
    selectedHotWaterCylinder: currentEstimateHotWaterCylinder,
    defaultRadiatorChanges: getApproxNumberOfRadiatorChanges(lead, adminContext.data.company?.estimate_default_radiator_change_percentage ?? DEFAULT_ESTIMATE_RAD_CHANGE_PERCENTAGE),
    parts: adminContext.data.parts!,
    labour: adminContext.data.labour!,
    packs: adminContext.data.packs!,
    isScottish: estimate.isScottish,
    override: calculatedEstimate.calculated_estimate_quote_items
  })

  useEffect(() => {
    if (!adminContext.data?.company) return
    const estimateToSend = {
      ...calculatedEstimate,
      status: 'Reminder sent',
      updated_at: new Date().toString()
    } satisfies CalculatedEstimate

    // the email template used to send the estimate
    const emailTemplate = getCompanyNudgeLeadEmailTemplate(adminContext.data.company.nudge_lead_template!)

    // render all placeholders EXCEPT the View Estimate button
    const emailJSON = renderJSONReplacingPlaceholders(emailTemplate, {
      [TTPlaceholderCustomerName.id]: estimateToSend.name!,
      [TTPlaceholderCustomerAddress.id]: `${estimateToSend.address}, ${estimateToSend.postcode}`,
      [TTPlaceholderHeatPumpModel.id]: estimateToSend.heat_pump_name,
      [TTPlaceholderCarbonSaving.id]: numberFormat(0).format(estimateToSend.co2_saved_kg),
      [TTPlaceholderCompanyName.id]: adminContext.data?.company?.public_info?.name,
      [TTPlaceholderCompanyPhone.id]: adminContext.data?.company?.public_info?.phone
    })

    setEmailTemplateJSON(emailJSON)
    setEstimateToSend(estimateToSend)
  }, [adminContext, calculatedEstimate])

  if (!adminContext.data?.company || !estimateToSend) return
  const companyUUID = adminContext.data.company.public_info.uuid

  return <Modal
    title={'Send reminder to lead'}
    visible={isVisible}
    setVisible={setIsVisible}
    onConfirm={async () => {
      logEvent({
        name: 'Send Nudge Lead Email',
        properties: {}
      }, adminContext.data?.company?.public_info?.subdomain ?? '')

      // render the email template with the View Estimate button
      const emailHTML = renderHTMLReplacingPlaceholders(emailTemplateJSON!, {
        [TTPlaceholderViewEstimate.id]: process.env.BASE_URL! + adminContext.data?.company?.public_info?.subdomain + '/estimate/' + estimateToSend.uuid
      })

      await sendNudgeEmail(lead.uuid!, calculatedEstimate.uuid!, companyUUID, emailHTML)
      await updateCalculatedEstimate(estimateToSend, companyUUID)
      reloadTrigger()
    }}
    confirmButtonLabel="Send"
  >
    <div className="flex flex-col gap-4">
      <div>
        This will send an estimate of <span className="font-bold">{formatPrice(sum(calculatedQuote.map(x => x.selected ? x.subtotal : 0)))}</span> to <span
          className="font-bold">{estimateToSend.name}</span> at <span className="font-bold">{lead?.customer?.email}</span>.
      </div>
      <div className="flex flex-col gap-4">
        <div className="text-xl font-medium">Customer email</div>

        <Tiptap
          editable={true}
          className='w-full rounded border border-gray-300 p-2 focus:outline-none'
          onUpdateCallback={async (editor) => {
            setEmailTemplateJSON(editor.getJSON())
          }}
          placeholders={[
            TTPlaceholderViewEstimate
          ]}
          content={emailTemplateJSON!}
        />
        {adminContext.data?.company?.public_info?.logo && <img src={adminContext.data?.company?.public_info?.logo} className="max-w-40" alt="Installer logo"/>}
      </div>
    </div>
  </Modal>
}
