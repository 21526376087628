import React, { type Dispatch, type SetStateAction, useContext, useState } from 'react'
import { PageHeader } from '../design/components/design_page_header'
import { Icon } from '../../../components/buttons/icon'
import { Button } from '../../../components/buttons/button'
import { type Floor } from '../../../code/models/floor'
import { chain, sum } from 'lodash'
import { Badge } from '../../../components/indicators_and_messaging/badge'
import {
  combineHeatLossesForProgressChart,
  combineSortConductionHeatLosses,
  combineVentilationHeatLosses,
  type ConductionHeatLoss,
  getConductionHeatLossAllElements,
  getFloorAreaM2,
  getRoomTemp,
  getRoomWatts,
  getVentilationHeatLoss,
  type VentilationHeatLoss
} from '../../../code/models/heat_loss'
import { ClickableCard } from '../../../components/content_display/card'
import { type PropertySurvey, type SurveyDesign } from '../../../code/models/property'
import { TabGroup } from '../../../components/content_display/tab'
import { type TableColumn, TableLite } from '../../../components/content_display/table_lite'
import { ProgressChart } from '../../../components/indicators_and_messaging/progress_chart'
import { CompleteButton } from '../survey/complete_button'
import { ListItem } from '../../../components/content_display/list_item'
import { getEmitterWatts } from '../../../code/models/radiator_model'
import { getAreaM2, getEmitterOutputVsDemandText } from './code/utils'
import { pluralise } from '../../../code/helpers'
import { FlowTempSlider } from '../design/pages/emitter_design_page'
import { AdminContext } from '../../admin/admin_layout'
import { type CompanyPublicInfo, isCompanyHasMagicPlanIntegration } from '../../../code/models/company'
import { MagicplanModal } from '../magicplan/magicplan_modal'
import { type SetIndexedDb } from '../../admin/job_layout/job_layout'
import { FloorplanFlow } from '../survey/floorplan_flow'
import { type Lead } from '../../../code/models/lead'
import { type Material } from '../../../code/models/material'
import { CloudDownload, Layers } from 'lucide-react'
import { WrappedIcon } from '../../../components/buttons/wrapped_icon'

type FloorListProps = {
  setFlowTemp: (value: number) => void
  floors: Floor[]
  minFlowTemp: number
  maxFlowTemp: number
  designTempC: number
  groundTempC: number
  survey: PropertySurvey
  design: SurveyDesign
  setCurrentRoomId: Dispatch<SetStateAction<string>>
  setSurvey: SetIndexedDb<PropertySurvey>
  navigateTo: (url: string) => void
  isOffline: boolean
  allSynced: boolean
  useFloorplanFlow: boolean
  lead: Lead
  customMaterials: Material[]
  setCustomMaterials: SetIndexedDb<Material[]>
  companyPublicInfo: CompanyPublicInfo
}

const pages = ['Rooms', 'Heat loss']

export const FloorList = ({
  setFlowTemp,
  floors,
  minFlowTemp,
  maxFlowTemp,
  designTempC,
  groundTempC,
  survey,
  design,
  setCurrentRoomId,
  setSurvey,
  navigateTo,
  isOffline,
  allSynced,
  useFloorplanFlow,
  lead,
  customMaterials,
  setCustomMaterials,
  companyPublicInfo
}: FloorListProps) => {
  const adminContext = useContext(AdminContext)
  const [currentPage, setPage] = useState('Rooms')
  const [showMagicplanModal, setShowMagicplanModal] = useState(false)

  if (useFloorplanFlow) {
    return <FloorplanFlow
      survey={survey}
      setSurvey={setSurvey}
      designTempDefault={designTempC}
      altitudeDefaultM={lead.property.altitudeM ?? 0}
      materials={[...adminContext.data.genericMaterials!, ...customMaterials]}
      setMaterials={setCustomMaterials}
      materialsLayers={adminContext.data.materialsGenericLayers!}
      navigateTo={navigateTo}
      companyUUID={companyPublicInfo.uuid}
      isOffline={isOffline}
      allSynced={allSynced}
    />
  }

  const roomRows = survey.floors.flatMap(x => x.rooms.flatMap(y => {
    const area = getAreaM2(y.walls.map(w => ({ x: w.x!, y: w.y! })))
    const heatLoss = getRoomWatts(y, x.rooms, designTempC, groundTempC, survey)
    return {
      name: y.name,
      designTemp: getRoomTemp(y, survey),
      area,
      heatLoss,
      wPerM2: heatLoss / area
    }
  }))

  const roomColumns: Array<TableColumn<typeof roomRows[0]>> = [
    { key: 'name', name: '' },
    { key: 'designTemp', name: 'Design Temp', render: (row) => <div>{row.designTemp} °C</div> },
    { key: 'area', name: 'Area', render: (row) => <div>{row.area.toFixed(1)} m²</div> },
    { key: 'heatLoss', name: 'Heat loss', render: (row) => <div>{row.heatLoss.toFixed(0)} W</div> },
    { key: 'wPerM2', name: 'W/m²', render: (row) => <div>{(row.heatLoss / row.area).toFixed(0)} W/m²</div> }
  ]

  const conductionHeatLossColumns: Array<TableColumn<ConductionHeatLoss>> = [
    { key: 'elementName', name: '' },
    { key: 'otherSideTempC', name: 'Other side temp', render: (row) => <div>{row.otherSideTempC} °C</div> },
    { key: 'uValueWPerM2K', name: 'U-value (W/m²K)', render: (row) => <div>{row.uValueWPerM2K.toFixed(2)}</div> },
    { key: 'areaM2', name: 'Area', render: (row) => <div>{row.areaM2.toFixed(1)} m²</div> },
    { key: 'watts', name: 'Heat loss', render: (row) => <div>{row.watts.toFixed(0)} W</div> }
  ]

  const ventilationHeatLossColumns: Array<TableColumn<VentilationHeatLoss>> = [
    { key: 'elementName', name: '' },
    { key: 'heatRecoveryPercentage', name: 'Heat recovery', render: (row) => <div>{row.heatRecoveryPercentage * 100} %</div> },
    { key: 'ACH', name: 'ACH', render: (row) => <div>{row.ACH}</div> },
    { key: 'volumeM3', name: 'Volume', render: (row) => <div>{row.volumeM3.toFixed(1)} m³</div> },
    { key: 'watts', name: 'Heat loss', render: (row) => <div>{row.watts.toFixed(0)} W</div> }
  ]

  const totalFloorAreaM2 = sum(survey.floors.flatMap(floor => floor.rooms.map(room => getFloorAreaM2(room.walls))))

  const conductionHeatLossRows = survey.floors.flatMap(floor => floor.rooms.flatMap(room => getConductionHeatLossAllElements(room, floor.rooms, designTempC, groundTempC, survey)))
  const ventilationHeatLosses = survey.floors.flatMap(floor => floor.rooms.map(room => getVentilationHeatLoss(room, designTempC, survey)))

  // For chart
  const heatLossForChart = combineHeatLossesForProgressChart(conductionHeatLossRows, ventilationHeatLosses)

  // For tables
  const conductionHeatLossRowsCombinedForTable = combineSortConductionHeatLosses(conductionHeatLossRows, true)
  const ventilationHeatLossCombinedForTable = combineVentilationHeatLosses(ventilationHeatLosses)
  const totalHeatLossWFromElements = Math.round(sum([...conductionHeatLossRowsCombinedForTable, ...ventilationHeatLossCombinedForTable].map(x => x.watts)))
  const totalHeatLossWFromRooms = Math.round(sum(roomRows.map(x => x.heatLoss)))
  const totalWPm2 = Math.round(totalHeatLossWFromRooms / totalFloorAreaM2)

  // FIXME: enable once Metropix signed and integrated
  // const metropixFileInputRef = useRef<HTMLInputElement>(null)

  const page = 'FLOORPLAN'

  if (!adminContext) return null

  return <div className='h-full flex flex-col min-h-0'>
    <MagicplanModal
      survey={survey}
      setSurvey={setSurvey}
      showMagicplanModal={showMagicplanModal}
      setShowMagicplanModal={setShowMagicplanModal}
    />

    <PageHeader isOffline={isOffline} allSynced={allSynced} title='Floorplan' onBack={() => navigateTo('/survey')} completed={survey.completed_sections.includes(page)}>
      <TabGroup items={pages.map(x => ({
        name: x,
        secondaryText: x === 'Heat loss'
          ? `${totalHeatLossWFromElements} W`
          : `${totalFloorAreaM2.toFixed(1)} m²`,
        onClick: () => setPage(x),
        variant: x === currentPage ? 'ACTIVE' : 'DEFAULT'
      }))} />
    </PageHeader>

    {currentPage === 'Rooms' && <div className="p-5 bg-gray-100 flex-col gap-6 flex flex-grow overflow-y-auto">
      {/* Floors */}
      <div className="flex-col gap-3 flex">
        <div className="justify-between items-center flex">
          <div className=" text-gray-900 text-xl font-bold">Floors</div>
          <div className="flex gap-3">
            {/* FIXME: enable once Metropix signed and integrated */}
            {/* <Button onClick={() => metropixFileInputRef.current?.click() } colour='DARK'>Import Metropix file</Button> */}
            { isCompanyHasMagicPlanIntegration(adminContext.data.company!) &&
              <Button onClick={() => setShowMagicplanModal(prev => !prev) } colour='LIGHT'><WrappedIcon icon={CloudDownload} />Magicplan</Button>
            }
            <Button onClick={() => navigateTo('/survey/new_floor')} colour='LOZENGE_DARK'>Add floor</Button>
          </div>
        </div>
        {/* FIXME: enable once Metropix signed and integrated */}
        {/* <MetropixFloorPlanInput inputRef={metropixFileInputRef} survey={survey} setSurvey={setSurvey} /> */}
        {floors.length === 0 && <ClickableCard onClick={() => navigateTo('/survey/new_floor')} variant='PLACEHOLDER'>
          <div className='flex flex-col items-center gap-2'>
            <Icon className='text-2xl' icon={Layers} />
            <div>No floors found</div>
          </div>
        </ClickableCard>}
        <div className="bg-white rounded-md flex-col flex divide-y divide-gray-200">
          {floors.map(x =>
            <ListItem
              onClick={() => navigateTo(`/survey/floors/${x.uuid}`)}
              key={x.uuid}
              primaryText={x.name}
              secondaryText={`${x.rooms.length} room${x.rooms.length > 1 && x.rooms.length !== 0 ? 's' : ''} • ${Math.round(sum(x.rooms.map(y => getAreaM2(y.walls.map(x => ({ x: x.x!, y: x.y! }))))))} m²`}
            />)}
        </div>
      </div>

      {/* Rooms */}
      {floors.length > 0 && <div className='flex flex-col gap-3'>
        <div className="text-gray-900 text-xl font-bold">Rooms</div>
        <ClickableCard border={false} variant='WHITE'>
          <div className='flex flex-col gap-4 justify-between flex-grow'>
            <FlowTempSlider
              flowTemp={design.flow_temp}
              setFlowTemp={setFlowTemp}
              minFlowTemp={minFlowTemp}
              maxFlowTemp={maxFlowTemp}
            />
          </div>
        </ClickableCard>
        {floors.map(x => {
          // If we have a group, group them otherwise group by individual rooms e.g. just list the rooms as normal.
          const roomGroups = chain(x.rooms)
            .groupBy(x => x.room_group_uuid ? x.room_group_uuid : x.uuid)
            .map((values, key) => ({ key, values }))
            .value()

          return <div key={x.uuid}>
            <div className="text-gray-500 font-semibold">{x.name}</div>
            {x.rooms.length === 0 && <ClickableCard variant='PLACEHOLDER'>
              <div className='flex flex-col items-center gap-2'>
                <div>No rooms found</div>
              </div>
            </ClickableCard>}
            {roomGroups.map(({ key, values }) => {
              const wattsLost = sum(values.map(y => Math.round(getRoomWatts(y, x.rooms, designTempC, groundTempC, survey))))
              const wattsEmitted = sum(values.map(y => Math.round(sum(y.radiators.map(r => getEmitterWatts(r, y, design, survey, designTempC, groundTempC))))))
              const windows = sum(values.flatMap(y => y.walls.map(x => x.windows.length)))
              const doors = sum(values.flatMap(y => y.walls.map(x => x.doors.length)))
              const roomArea = getAreaM2(values.flatMap(y => y.walls.map(x => ({ x: x.x!, y: x.y! }))))
              const wattsPerM2 = Math.round(wattsLost / roomArea)

              return <div className='flex flex-col gap-3' key={key}>
                {values.length > 1 && <div className='flex justify-between'>
                  <div className='font-bold text-gray-900'>{values.map(x => x.name).join(', ')}</div>
                  <Badge color={wattsEmitted >= wattsLost ? 'GREEN' : 'RED'} text={getEmitterOutputVsDemandText(wattsEmitted, wattsLost)} />
                </div>}
                <div className='bg-white rounded-md flex flex-col divide-y divide-gray-200'>
                  {values.map((y, i) => <ListItem
                    onClick={() => {
                      setCurrentRoomId(y.uuid!)
                      navigateTo(`/survey/floors/${x.uuid}`)
                    }}
                    key={i}
                    primaryText={y.name}
                    secondaryText={`${wattsPerM2} W/m² • ${y.radiators.length} ${pluralise('emitter', y.radiators.length)}, ${windows} ${pluralise('win', windows)}, ${doors} ${pluralise('door', doors)}`}
                    rightBadge={values.length === 1 ? <Badge color={wattsEmitted >= wattsLost ? 'GREEN' : 'RED'} text={getEmitterOutputVsDemandText(wattsEmitted, wattsLost)} /> : <div></div>}
                  />)}
                </div>
              </div>
            })}
          </div>
        })}
      </div>}
    </div>}
    {currentPage === 'Rooms' && <CompleteButton page={page} pageChecks={[]} survey={survey} setSurvey={setSurvey} onBack={async () => window.history.back()} />}
    {currentPage === 'Heat loss' && <div className='flex flex-col p-5'>
      <div className='flex flex-col gap-4'>
        {/* By room */}
        <ClickableCard variant='GREY'>
          <div className='flex flex-col gap-2'>
            <div className='flex justify-between text-lg'>
              <div className='text-gray-900 font-bold'>Heat loss by room</div>
              <div>{totalHeatLossWFromRooms} W</div>
            </div>
            <ProgressChart total={totalHeatLossWFromRooms}
              items={roomRows.map(x => ({ value: x.heatLoss, name: x.name }))}/>
          </div>
        </ClickableCard>
        <div className={'flex flex-col px-2 gap-4'}>
          <TableLite columns={roomColumns} rows={roomRows}/>
          <HeatLossTotalLines totalHeatLossW={totalHeatLossWFromRooms} totalWPerM2={totalWPm2} totalFloorAreaM2={totalFloorAreaM2}/>
        </div>
        {/* By element */}
        <ClickableCard variant='GREY'>
          <div className='flex flex-col gap-2'>
            <div className='flex justify-between text-lg'>
              <div className='text-gray-900 font-bold'>Heat loss by element</div>
              <div>{totalHeatLossWFromElements} W</div>
            </div>
            <ProgressChart total={totalHeatLossWFromElements}
              items={heatLossForChart}/>
          </div>
        </ClickableCard>
        <div className={'flex flex-col px-2 gap-4'}>
          <TableLite columns={conductionHeatLossColumns} rows={conductionHeatLossRowsCombinedForTable}/>
          <TableLite columns={ventilationHeatLossColumns} rows={ventilationHeatLossCombinedForTable}/>
          <HeatLossTotalLines totalHeatLossW={totalHeatLossWFromRooms} totalWPerM2={totalWPm2} totalFloorAreaM2={totalFloorAreaM2}/>
        </div>
      </div>
    </div>}

  </div>
}

// FIXME: enable once Metropix signed and integrated
// type MetropixFloorPlanInputProps = {
//   inputRef: React.RefObject<HTMLInputElement>
//   survey: PropertySurvey
//   setSurvey: Dispatch<SetStateAction<PropertySurvey>>
// }
//
// const MetropixFloorPlanInput: FC<MetropixFloorPlanInputProps> = ({ inputRef, survey, setSurvey }) => {
//   return <input ref={inputRef} className='hidden' type="file" accept='*' onChange={(e) => {
//     const selectedFile = e.target?.files?.[0]
//     if (!selectedFile) return
//
//     const fileReader = new FileReader()
//     if (selectedFile.type === 'text/xml') {
//       fileReader.onload = async (e) => {
//         const floors = metropixImportFloorplan(e.target?.result as string, survey)
//         if (!floors) {
//           alert('Invalid Metropix file')
//           return
//         }
//         floors.forEach(floor => {
//           setSurvey(prev => ({ ...prev, floors: [...prev.floors, floor] }))
//         })
//       }
//       fileReader.readAsText(selectedFile)
//     } else {
//       alert('Invalid file type')
//     }
//   }} />
// }

export type HeatLossTotalLineProps = {
  totalHeatLossW: number
  totalWPerM2: number
  totalFloorAreaM2: number
}

export const HeatLossTotalLines = ({ totalHeatLossW, totalWPerM2, totalFloorAreaM2 }: HeatLossTotalLineProps) => {
  return <div className='flex flex-col gap-2'>
    <div className='flex justify-between text-gray-900 font-bold'>
      <div>Total</div>
      <div>{totalHeatLossW} W</div>
    </div>
    <div className='flex justify-between text-xs font-semibold'>
      <div>Heat loss per m²</div>
      <div>{totalWPerM2} W/m²</div>
    </div>
    <div className='flex justify-between text-xs font-semibold'>
      <div>Floor area</div>
      <div>{totalFloorAreaM2.toFixed(0)} m²</div>
    </div>
  </div>
}
