import React, { type Dispatch, type FC, type SetStateAction, useEffect, useRef, useState } from 'react'
import { type Floor } from '../../../code/models/floor'
import { type Room } from '../../../code/models/room'
import { type FileWrapper } from '../file_wrapper'
import { type Wall } from '../../../code/models/wall'
import { type Vector2d } from 'konva/lib/types'
import { getFloorAreaM2, getRoomWatts } from '../../../code/models/heat_loss'
import Konva from 'konva'
import { type PropertySurvey, type SurveyDesign } from '../../../code/models/property'
import { type Material, MATERIAL_ELEMENT_NAMES, type MaterialLayer } from '../../../code/models/material'
import { MaterialsSelector, type MaterialsSelectorGroupedProps } from '../materials/materials_selector'
import { Icon } from '../../../components/buttons/icon'
import { type Lead } from '../../../code/models/lead'
import { PageHeader } from '../design/components/design_page_header'
import { TabGroup } from '../../../components/content_display/tab'
import { type UndoEvent } from './floor_canvas/undo'
import { getRoomsWithMetaData } from './code/get_rooms_with_meta_data'
import { FloorStage } from './floor_stage'
import {
  calculateNewTouchingWalls,
  centreAndScaleOnFloor,
  centreOnRoomScaleOnFloor,
  fixRotation,
  getNextWall,
  removeInvalidRoomGroups,
  simplifyRooms,
  updateLength
} from './code/utils'
import { findBestCreateRoomPosition } from './code/find_best_create_room_position'
import { onStageTouchMove } from './code/on_stage_touch_move'
import { onWheel } from './code/on_wheel'
import { Button } from '../../../components/buttons/button'
import { RoomHeatLossPage } from './room_heat_loss'
import { EntityToolbar } from './entity_toolbar'
import { WallPage } from '../wall'
import { RoomPage } from '../room'
import { AddRoomTab } from '../add_room'
import { FloorDetails } from './floor_details'
import { BottomSheet } from 'react-spring-bottom-sheet'
import { CustomMaterialPopup } from '../materials/custom_material_popup'
import { type Door } from '../../../code/models/door'
import { type WallWindow } from '../../../code/models/window'
import { type RoofLight } from '../../../code/models/rooflight'
import { type RadiatorModel } from '../../../code/models/radiator_model'
import { EmptyState } from '../../../components/content_display/empty_state'
import { FloorPlanInput } from './floor_buttons'
import { ScalePage } from './scale_page'
import { stageOnMouseMove } from './code/stage_on_mouse_move'
import { stageOnClick } from './code/stage_on_click'
import { type SprucePoint } from './code/types'
import { type SpruceLine } from './line_with_text'
import { DEFAULT_IMAGE_SCALE } from './code/constants'
import { UndoRedoRecenterButtonsAndAlerts } from './undo_redo_recenter_buttons'
import { useLocalStorage } from 'usehooks-ts'
import { getIntersectingShapes } from './floor_canvas/get_intersecting_shapes'
import { ChevronLeft, Trash, List, Plus, X, Upload } from 'lucide-react'

Konva.hitOnDragEnabled = true

type FloorPageProps = {
  files: FileWrapper[]
  setFiles: (files: FileWrapper[]) => void
  floors: Floor[]
  floor: Floor
  setFloor: (floor: Floor) => void
  removeFloor: (id: string) => void
  survey: PropertySurvey
  designTempC: number
  groundTempC: number
  materials: Material[]
  setMaterials: React.Dispatch<React.SetStateAction<Material[]>>
  materialsLayers: MaterialLayer[]
  setSurvey: Dispatch<SetStateAction<PropertySurvey>>
  lead: Lead
  customRadiatorModels: RadiatorModel[]
  setCustomRadiatorModels: Dispatch<SetStateAction<RadiatorModel[]>>
  allRadiatorModels: RadiatorModel[]
  design: SurveyDesign
  currentRoomId: string | undefined
  setCurrentRoomId: Dispatch<SetStateAction<string | undefined>>
  stageScale: number
  setStageScale: Dispatch<SetStateAction<number>>
  stagePosition: Vector2d
  setStagePosition: Dispatch<SetStateAction<Vector2d>>
  setFlowTemp: (flowTemp: number) => void
  minFlowTemp: number
  maxFlowTemp: number
  stageRef: React.RefObject<HTMLDivElement>
  stageSize: { width: number, height: number }
  setStageSize: Dispatch<SetStateAction<{ width: number, height: number }>>
  companyUuid: string
  isOffline: boolean
  allSynced: boolean
  navigateTo: (url: string) => void
}

const WALL_PAGES = ['WALL_LENGTH', 'WALL_MATERIALS', 'WALL_WINDOWS', 'WALL_DOORS', 'REMOVE_WALL', 'WINDOW_PAGE', 'DOOR_PAGE'] as const
const ROOM_PAGES = ['ROOM_DETAILS', 'ROOM_FLOOR', 'ROOM_CEILING', 'ROOM_EMITTERS', 'CHOOSE_EMITTER', 'ADD_CUSTOM_RADIATOR', 'ROOM_PHOTOS', 'SLOPED_CEILING', 'ROOFLIGHT', 'RADIATOR', 'RADIATOR_MODEL', 'UNDERFLOOR', 'MANIFOLD', 'SECONDARY_HEATING'] as const
const FLOOR_PAGES = ['CHANGE_FLOOR', 'ADD_ROOM', 'FLOOR_DETAILS'] as const
const FLOOR_PLAN_PAGES = [...ROOM_PAGES, ...FLOOR_PAGES, ...WALL_PAGES, 'MATERIALS', 'CUSTOM_MATERIAL'] as const
const ROOM_TABS = ['Dimensions', 'Heat loss'] as const

export type CurrentRoomPage = typeof ROOM_PAGES[number]
export type CurrentWallPage = typeof WALL_PAGES[number]
export type CurrentFloorPlanPage = typeof FLOOR_PLAN_PAGES[number]

export const FloorPage = (
  {
    files,
    setFiles,
    floors,
    floor,
    setFloor,
    survey,
    design,
    removeFloor,
    designTempC,
    groundTempC,
    materials,
    setMaterials,
    materialsLayers,
    setSurvey,
    customRadiatorModels,
    setCustomRadiatorModels,
    allRadiatorModels,
    currentRoomId,
    setCurrentRoomId,
    stagePosition,
    setStagePosition,
    stageScale,
    setStageScale,
    setFlowTemp,
    minFlowTemp,
    maxFlowTemp,
    stageRef,
    stageSize,
    setStageSize,
    companyUuid,
    isOffline,
    allSynced,
    navigateTo
  }: FloorPageProps) => {
  const [currentWallId, setCurrentWallId] = useState<string>()
  const [wallWindow, setWallWindow] = useLocalStorage<WallWindow | undefined>('last_window', undefined)
  const [door, setDoor] = useLocalStorage<Door | undefined>('last_door', undefined)
  const [tempCustomMaterial, setTempCustomMaterial] = useState<Material>()
  const [currentRooflight, setCurrentRooflight] = useState<RoofLight>()
  const [dragStopped, setDragStopped] = useState<boolean>(false)
  const [lastCenter, setLastCenter] = useState<Vector2d>()
  const [lastDist, setLastDist] = useState<number>()
  const [drawingRooms, setDrawingRooms] = useState<boolean>(false)
  const [scalingWindow, setScalingWindow] = useState(false)
  const [scalingDoor, setScalingDoor] = useState(false)
  const [pages, setPages] = useState<CurrentFloorPlanPage[]>(['FLOOR_DETAILS'])
  const [isPageOpen, setIsPageOpen] = useState(false)
  const [showFloorPlanButtons, setShowFloorPlanButtons] = useState(false)
  const [guidelines, setGuidelines] = useState<SpruceLine[]>([])
  const [planOpacity, setPlanOpacity] = useLocalStorage('floorplan_opacity', 0.2)

  const page = pages[pages.length - 1]
  const intersectingShapes = getIntersectingShapes(floor.rooms)

  // The page is always open on large screens but is dynamic on mobile size.
  // If I click a room in the canvas set the page to ROOM_DETAILS, however isPageOpen remains false.
  // If I click the canvas buttons, also set the page component to open for both desktop and mobile.
  const setPage = (page: CurrentFloorPlanPage, open: boolean = false) => {
    setPages(prev => [...prev, page])
    const closePage = page === 'FLOOR_DETAILS' && !open
    const newIsPageOpen = !closePage && (open || isPageOpen)

    setIsPageOpen(newIsPageOpen)
  }
  const goBack = () => {
    setPages(prev => {
      const newPages = prev.slice(0, -1)

      // If on mobile, make sure we close the modal if we are on a main details page, avoid closing the modal if we are on a nested page.
      if (['FLOOR_DETAILS', 'ROOM_DETAILS'].includes(newPages[newPages.length - 1]) || prev[prev.length - 1] === 'WALL_LENGTH') {
        setIsPageOpen(false)
      }
      return prev[prev.length - 1] === 'FLOOR_DETAILS' ? prev : newPages
    })
  }

  const [roomTab, setRoomTab] = useState('Dimensions')
  const [scalingPoints, setScalingPoints] = useState<SprucePoint[]>([])
  const [mousePosition, setMousePosition] = useState<SprucePoint>()
  const [scaleLength, setScaleLength] = useState<number>()
  const [tempImageAndScale, setTempImageAndScale] = useState<{ image: string, scale: number }>({ image: '', scale: 10 })

  const [undoEvents, setUndoEvents] = useState<UndoEvent[]>([])
  const [undoIndex, setUndoIndex] = useState(0)
  const [header, setHeader] = useState<JSX.Element>()

  const hasUnsnappedInternalWalls: boolean = floor.rooms.some(room => room.walls.some(wall => wall.other_room_uuid === undefined && wall.material?.applicable_to === 'internal-wall'))

  const addEvent = (events: UndoEvent[]) => {
    setUndoEvents(prev => [...prev.slice(0, undoIndex + (events.length - 1)), ...events])
    for (const event of events) { redo(event) }
  }

  const undo = () => {
    const lastAction = undoEvents[undoIndex - 1]
    if (lastAction.action === 'ADD') {
      if (lastAction.type === 'ROOM') removeRoom(lastAction.newValue!.uuid!)
    } else if (lastAction.action === 'UPDATE') {
      if (lastAction.type === 'FLOOR') setFloor(lastAction.oldValue!)
      else if (lastAction.type === 'ROOM') setRoom(lastAction.oldValue!)
    } else if (lastAction.action === 'DELETE') {
      if (lastAction.type === 'ROOM') setFloor({ ...floor, rooms: [...floor.rooms, lastAction.oldValue!] })
    }

    setUndoIndex(prev => prev - 1)
  }

  const redo = (event: UndoEvent) => {
    if (event.action === 'ADD') {
      if (event.type === 'ROOM') setFloor({ ...floor, rooms: [...floor.rooms, event.newValue!] })
    } else if (event.action === 'UPDATE') {
      if (event.type === 'FLOOR') setFloor(event.newValue!)
      else if (event.type === 'ROOM') setRoom(event.newValue!)
    } else if (event.action === 'DELETE') {
      if (event.type === 'ROOM') removeRoom(event.oldValue!.uuid!)
    }

    setUndoIndex(prev => prev + 1)
  }

  // We want to show the user the add room page if it is their first room on the floor.
  // When the page loads, we need to set the canvas size to its container so we can size floorplan correctly.
  useEffect(() => {
    // We must do this here because we do not know what size the canvas will be when we click on a floor or a room.
    if (currentRoom) centreOnRoomScaleOnFloor(currentRoom, floor, setStagePosition, setStageScale, stageRef.current?.clientWidth ?? 0, stageRef.current?.clientHeight ?? 0)
    else if (floor.rooms.length > 0) centreAndScaleOnFloor(floor, setStagePosition, setStageScale, stageRef.current?.clientWidth ?? 0, stageRef.current?.clientHeight ?? 0)
  }, [])

  const resetFloorPlan = () => {
    setScalingPoints([])
    setMousePosition(undefined)
    setScaleLength(undefined)
    setTempImageAndScale({ image: '', scale: DEFAULT_IMAGE_SCALE })
    setStageStep(undefined)
  }

  const roomsWithMetaData = getRoomsWithMetaData(survey, floor, designTempC, groundTempC, design, stageScale)

  const wallsWithoutDuplicatesReduce = (prev, curr) => {
    if (!prev.some(x => x.x1 === curr.x1 && x.x2 === curr.x2 && x.y1 === curr.y1 && x.y2 === curr.y2)) return [...prev, curr]
    return prev
  }
  const wallsWithoutDuplicates = floor.rooms
    .flatMap(r => r.walls.filter(x => x.other_room_uuid)
      .map(w => {
        const nextWall = getNextWall(w, r.walls)
        const line = { x1: w.x! + r.x!, x2: nextWall.x! + r.x!, y1: w.y! + r.y!, y2: nextWall.y! + r.y! }
        const lineRotated = fixRotation(line.x1, line.x2, line.y1, line.y2)
        return { ...lineRotated, uuid: w.uuid }
      }))
    .reduce<Array<{ x1: number, x2: number, y1: number, y2: number, uuid: string }>>(wallsWithoutDuplicatesReduce, [])

  const currentRoom = floor.rooms.find(x => x.uuid === currentRoomId)
  const currentWall = currentRoom?.walls.find(x => x.uuid === currentWallId)

  const currentWallIndex = currentRoom?.walls.findIndex(x => x.uuid === currentWall?.uuid)
  const nextWallIndex = currentWallIndex !== undefined && currentRoom ? (currentRoom.walls.length - 1 === currentWallIndex ? 0 : currentWallIndex + 1) : undefined
  const linkedWall = nextWallIndex !== undefined ? currentRoom?.walls[nextWallIndex] : undefined

  const pageTitle = currentWall ? MATERIAL_ELEMENT_NAMES[currentWall.material!.applicable_to] ?? 'Wall' : currentRoomId ? currentRoom?.name : floor.name

  const setWalls = (walls: Wall[]) => {
    const newRoom: Room = { ...currentRoom!, walls }
    setFloor({ ...floor, rooms: floor.rooms.map(x => x.uuid === newRoom.uuid ? newRoom : x) })
  }

  const setRoom = (room: Room) => {
    setFloor({
      ...floor,
      rooms: floor.rooms.map(x => x.uuid === room.uuid ? room : x)
    })
  }

  const setWall = (wall: Wall) => {
    const newWalls = currentRoom?.walls.map(x => x.uuid === wall.uuid ? wall : x)
    setFloor({
      ...floor,
      rooms: floor.rooms.map(x => x.uuid === currentRoom!.uuid ? { ...currentRoom!, walls: newWalls! } : x)
    })
  }

  const removeRoom = (id: string) => {
    const newRooms = calculateNewTouchingWalls(floor.rooms.filter(x => x.uuid !== id), survey.default_materials!)
    setFloor({ ...floor, rooms: newRooms })
    setCurrentRoomId(undefined)
    setCurrentWallId(undefined)
    goBack()
  }

  const saveWall = (wall: Wall, length: number) => {
    let newWalls = currentRoom!.walls
    if (length > 0) {
      newWalls = updateLength(currentRoom!, currentRoom!.walls.findIndex(x => x.uuid === wall.uuid), length)
    }

    const newRooms = floor.rooms.map(x => x.uuid === currentRoom?.uuid ? ({ ...currentRoom!, walls: newWalls }) : x)

    const simplifiedRooms = simplifyRooms(newRooms)
    const calculatedNewRooms = calculateNewTouchingWalls(simplifiedRooms, survey.default_materials!)
    const removedInvalidRoomGroups = removeInvalidRoomGroups(calculatedNewRooms)
    const events: UndoEvent[] = [{ type: 'FLOOR', action: 'UPDATE', oldValue: floor, newValue: { ...floor, rooms: removedInvalidRoomGroups } }]

    addEvent(events)
  }

  const centerX = (((stageSize.width / 2) - (stagePosition.x)) / stageScale)
  const centerY = (((stageSize.height / 2) - (stagePosition.y)) / stageScale)
  const { roomCenterX, roomCenterY } = findBestCreateRoomPosition(centerX, centerY, floor.rooms)

  const [msProps, setMsProps] = useState<MaterialsSelectorGroupedProps>()
  const [stageStep, setStageStep] = useState<number | undefined>(undefined)

  const steps = [
    { step: 1, primaryText: 'Find a line you know the measurement of and select the start point.', button: <Button iconLeft={X}>Cancel</Button> },
    { step: 2, primaryText: 'Great! Now select the end point.', button: <Button iconLeft={ChevronLeft}>Back</Button> },
    { step: 3, primaryText: 'Finally, enter the length of the object.', button: <Button iconLeft={ChevronLeft}>Back</Button> }
  ]

  const showUploadWidthPage = stageStep === steps.length

  const pageSelector = <PageSelector
    currentRoom={currentRoom}
    currentWall={currentWall}
    linkedWall={linkedWall}
    floor={floor}
    setFloor={setFloor}
    setWall={setWall}
    page={page}
    setPage={setPage}
    setCurrentWallId={setCurrentWallId}
    survey={survey}
    saveWall={saveWall}
    materials={materials}
    msProps={msProps}
    setMsProps={setMsProps}
    addEvent={addEvent}
    design={design}
    setRoom={setRoom}
    files={files}
    setFiles={setFiles}
    setSurvey={setSurvey}
    designTempC={designTempC}
    groundTempC={groundTempC}
    setFlowTemp={setFlowTemp}
    minFlowTemp={minFlowTemp}
    maxFlowTemp={maxFlowTemp}
    floors={floors}
    roomCenterX={roomCenterX}
    roomCenterY={roomCenterY}
    setCurrentRoomId={setCurrentRoomId}
    scaleLength={scaleLength}
    setScaleLength={setScaleLength}
    tempImageAndScale={tempImageAndScale}
    setTempImageAndScale={setTempImageAndScale}
    scalingPoints={scalingPoints}
    mousePosition={mousePosition}
    setScalingPoints={setScalingPoints}
    materialsLayers={materialsLayers}
    setMaterials={setMaterials}
    setHeader={setHeader}
    window={wallWindow}
    setWindow={setWallWindow}
    door={door}
    setDoor={setDoor}
    currentRooflight={currentRooflight}
    setCurrentRooflight={setCurrentRooflight}
    tempCustomMaterial={tempCustomMaterial}
    setTempCustomMaterial={setTempCustomMaterial}
    customRadiatorModels={customRadiatorModels}
    setCustomRadiatorModels={setCustomRadiatorModels}
    allRadiatorModels={allRadiatorModels}
    companyUuid={companyUuid}
    showUploadWidthPage={showUploadWidthPage}
    resetUploadPlan={resetFloorPlan}
    setStageStep={setStageStep}
    setScalingWindow={setScalingWindow}
    setScalingDoor={setScalingDoor}
    onBack={goBack}
    setPageOpen={setIsPageOpen}
  />

  const fileInputRef = useRef<HTMLInputElement>(null)

  return <div className='flex flex-col h-full'>
    <PageHeader
      isOffline={isOffline}
      allSynced={allSynced}
      title={pageTitle!}
      onBack={() => navigateTo('/survey/floors')}
      rightHandChildren={!currentWall && roomTab !== 'Heat loss' &&
              <Icon
                confirmTextHeader={currentRoomId ? (`Delete ${currentRoom?.name ?? 'room'}`) : `Delete ${floor.name}?`}
                icon={Trash}
                onClick={() => {
                  if (currentRoomId) {
                    addEvent([{ type: 'ROOM', action: 'DELETE', oldValue: currentRoom }])
                    setCurrentRoomId(undefined)
                  } else {
                    removeFloor(floor.uuid!)
                  }
                }}
              />}
    >
      {currentRoom && !currentWall && <TabGroup items={ROOM_TABS.map(x => ({
        name: x,
        secondaryText: x === 'Heat loss'
          ? `${Math.round(getRoomWatts(currentRoom, floor.rooms, designTempC, groundTempC, survey))} W`
          : `${getFloorAreaM2(currentRoom.walls).toFixed(1)} m²`,
        onClick: () => setRoomTab(x),
        variant: x === roomTab ? 'ACTIVE' : 'DEFAULT'
      }))} />}
    </PageHeader>
    {/* h-0 is required here as a quirk for children containers to overflow correctly */}
    <div className='flex flex-grow h-0'>
      {(floor.rooms.length === 0 && !stageStep && page === 'FLOOR_DETAILS' && !floor.floor_plan_image && !floor.floor_plan_url) && <div className='bg-gray-50 flex items-center justify-center w-full'>
        <EmptyState
          icon={List}
          primaryText='No rooms'
          secondaryText={'Upload a floor plan to trace, or Add a room to begin mapping your customer\'s home.'}
          primaryButton={<Button onClick={() => setPage('ADD_ROOM')} iconLeft={Plus}>Add room</Button>}
          secondaryButton={<Button onClick={() => fileInputRef.current?.click()} iconLeft={Upload}>Upload floor plan</Button>}
        />
        <FloorPlanInput inputRef={fileInputRef} setTempImageAndScale={setTempImageAndScale} setStageStep={setStageStep} />
      </div>}
      {currentRoom && roomTab === 'Heat loss' &&
              <RoomHeatLossPage
                room={currentRoom}
                roomsThisFloor={floor.rooms}
                designTempC={designTempC}
                groundTempC={groundTempC}
                flowTemp={design.flow_temp}
                setFlowTemp={setFlowTemp}
                minFlowTemp={minFlowTemp}
                maxFlowTemp={maxFlowTemp}
                deltaTFlowReturnC={design.delta_t_flow_return_c}
                survey={survey}
                design={design}
              />
      }
      {roomTab !== 'Heat loss' && (floor.rooms.length > 0 || stageStep || page !== 'FLOOR_DETAILS' || floor.floor_plan_image || floor.floor_plan_url) && <><div ref={stageRef} className='relative flex-grow'>
        <div className='absolute left-0 right-0 top-0 z-10 m-4 flex flex-col gap-4'>
          {stageStep && <UploadPlanTooltip
            step={stageStep}
            setStep={setStageStep}
            setScalingPoints={setScalingPoints}
            resetFloorPlan={resetFloorPlan}
            primaryText={steps[stageStep - 1].primaryText}
          />}
          {!stageStep && <UndoRedoRecenterButtonsAndAlerts
            undo={undo}
            redo={redo}
            undoIndex={undoIndex}
            undoEvents={undoEvents}
            floor={floor}
            setStagePosition={setStagePosition}
            setStageScale={setStageScale}
            stageSize={stageSize}
            drawingRooms={drawingRooms}
            currentRoom={currentRoom}
            intersectingShapes={intersectingShapes}
            hasUnsnappedInternalWalls={hasUnsnappedInternalWalls}
          />}
        </div>
        {/* Bottom button group */}
        {!stageStep && <div className='absolute bottom-0 left-1/2 m-4 z-10 transform -translate-x-1/2'>
          <EntityToolbar
            setTempImageAndScale={setTempImageAndScale}
            setPage={setPage}
            currentWall={currentWall!}
            currentRoom={currentRoom!}
            currentFloor={floor}
            setStageStep={setStageStep}
            showFloorPlanButtons={showFloorPlanButtons}
            setIsPageOpen={setIsPageOpen}
            setShowFloorPlanButtons={setShowFloorPlanButtons}
            setFloor={setFloor}
            drawingRooms={drawingRooms}
            setDrawingRooms={setDrawingRooms}
            cancelDrawingRooms={() => {
              setDrawingRooms(false)
              setScalingPoints([])
              setMousePosition(undefined)
            }}
            scalingPoints={scalingPoints}
            setScalingPoints={setScalingPoints}
            intersectingShapes={intersectingShapes}
            planOpacity={planOpacity}
            setPlanOpacity={setPlanOpacity}
          />
        </div>}
        <FloorStage
          survey={survey}
          stageScale={stageScale}
          stagePosition={stagePosition}
          stageSize={stageSize}
          stageRef={stageRef}
          setStageSize={setStageSize}
          defaultMaterials={survey.default_materials!}
          roomsWithMetaData={roomsWithMetaData}
          currentRoom={currentRoom}
          currentRoomId={currentRoomId}
          setCurrentRoomId={setCurrentRoomId}
          wallsWithoutDuplicates={wallsWithoutDuplicates}
          floor={floor}
          dragStopped={dragStopped}
          currentWall={currentWall}
          setStageScale={setStageScale}
          setPage={setPage}
          setCurrentWallId={setCurrentWallId}
          setWalls={setWalls}
          setStagePosition={setStagePosition}
          addEvent={addEvent}
          scalingPoints={scalingPoints}
          mousePosition={mousePosition}
          tempImageAndScale={tempImageAndScale}
          stageStep={stageStep}
          isDrawing={drawingRooms}
          onMouseMove={(e) => stageOnMouseMove(e, scalingPoints, drawingRooms, scalingWindow, scalingDoor, floor, setMousePosition, stageStep, setGuidelines, stageScale, survey)}
          onClick={(e) => stageOnClick(
            e,
            drawingRooms,
            mousePosition,
            scalingWindow,
            scalingDoor,
            scalingPoints,
            floor,
            survey,
            stageStep,
            stageScale,
            showUploadWidthPage,
            setScalingPoints,
            setCurrentRoomId,
            setStageStep,
            setPage,
            setCurrentWallId,
            setDrawingRooms,
            setMousePosition,
            addEvent,
            setScalingWindow,
            setScalingDoor,
            setWallWindow,
            setDoor,
            setIsPageOpen
          )}
          onTouchEnd={() => {
            setLastCenter(undefined)
            setLastDist(0)
          }}
          onDragMove={(e) => {
            if (e.currentTarget === e.target) {
              const newPos = e.currentTarget.position()
              e.currentTarget.position(({ x: stagePosition.x, y: stagePosition.y }))
              setStagePosition(newPos)
            }
          }}
          onWheelProp={(e) => onWheel(e, stageScale, stagePosition, setStageScale, setStagePosition)}
          onTouchMove={(e) => onStageTouchMove(e, dragStopped, setDragStopped, lastCenter, setLastCenter, lastDist, setLastDist, stagePosition, setStagePosition, stageScale, setStageScale)}
          intersectingShapes={intersectingShapes}
          scalingWindow={scalingWindow}
          scalingDoor={scalingDoor}
          guidelines={guidelines}
          setGuidelines={setGuidelines}
          showFloorPlan={true}
          planOpacity={planOpacity}
        />
      </div>
      {/* Side peek for large screens and above */}
      {roomTab !== 'Heat loss' && !scalingWindow && !scalingDoor && <div className='w-96 bg-white border-l border-gray-300 shadow-lg z-10 hidden md:flex flex-col'>
        <div className='px-5 py-3 border-b border-gray-300'>
          {header}
        </div>
        <div className='overflow-y-auto'>{pageSelector}</div>
      </div>}
      {/* Bottom sheet for medium screens and below */}
      </>}
      <BottomSheet header={header} initialFocusRef={false} scrollLocking={false} className='w-full md:hidden' open={roomTab !== 'Heat loss' && !scalingWindow && !scalingDoor && isPageOpen} onDismiss={() => {
        if (showUploadWidthPage) {
          setStageStep(prev => prev! - 1)
          setScalingPoints(prev => prev.slice(0, -1))
        } else {
          setPage('FLOOR_DETAILS')
        }
      }}>
        {pageSelector}
      </BottomSheet>
    </div>
  </div>
}

type PageSelectorProps = {
  currentRoom: Room | undefined
  currentWall: Wall | undefined
  linkedWall: Wall | undefined
  floor: Floor
  setFloor: (floor: Floor) => void
  setWall: (wall: Wall) => void
  page: CurrentFloorPlanPage
  setPage: (page: CurrentFloorPlanPage) => void
  setCurrentWallId: Dispatch<SetStateAction<string>>
  survey: PropertySurvey
  saveWall: (wall: Wall, length: number) => void
  materials: Material[]
  msProps: MaterialsSelectorGroupedProps | undefined
  setMsProps: Dispatch<SetStateAction<MaterialsSelectorGroupedProps>>
  addEvent: (events: UndoEvent[]) => void
  design: SurveyDesign
  setRoom: (room: Room) => void
  files: FileWrapper[]
  setFiles: (files: FileWrapper[]) => void
  setSurvey: Dispatch<SetStateAction<PropertySurvey>>
  designTempC: number
  groundTempC: number
  setFlowTemp: (value: number) => void
  minFlowTemp: number
  maxFlowTemp: number
  floors: Floor[]
  roomCenterX: number
  roomCenterY: number
  setCurrentRoomId: Dispatch<SetStateAction<string>>
  scalingPoints: SprucePoint[]
  mousePosition: SprucePoint | undefined
  setScalingPoints: Dispatch<SetStateAction<SprucePoint[]>>
  scaleLength: number | undefined
  setScaleLength: Dispatch<SetStateAction<number | undefined>>
  tempImageAndScale: { image: string, scale: number }
  setTempImageAndScale: Dispatch<SetStateAction<{ image: string, scale: number }>>
  materialsLayers: MaterialLayer[]
  setMaterials: Dispatch<SetStateAction<Material[]>>
  setHeader: Dispatch<SetStateAction<JSX.Element>>
  window: WallWindow | undefined
  setWindow: Dispatch<SetStateAction<WallWindow | undefined>>
  door: Door | undefined
  setDoor: Dispatch<SetStateAction<Door | undefined>>
  currentRooflight: RoofLight | undefined
  setCurrentRooflight: Dispatch<SetStateAction<RoofLight | undefined>>
  tempCustomMaterial: Material | undefined
  setTempCustomMaterial: Dispatch<SetStateAction<Material | undefined>>
  customRadiatorModels: RadiatorModel[]
  setCustomRadiatorModels: Dispatch<SetStateAction<RadiatorModel[]>>
  allRadiatorModels: RadiatorModel[]
  companyUuid: string
  showUploadWidthPage: boolean
  resetUploadPlan: () => void
  setStageStep: Dispatch<SetStateAction<number>>
  setScalingWindow: Dispatch<SetStateAction<boolean>>
  setScalingDoor: Dispatch<SetStateAction<boolean>>
  onBack: () => void
  setPageOpen: Dispatch<SetStateAction<boolean>>
}

const PageSelector = ({
  currentRoom,
  currentWall,
  linkedWall,
  floor,
  setFloor,
  setWall,
  page,
  setPage,
  setCurrentWallId,
  survey,
  saveWall,
  materials,
  msProps,
  setMsProps,
  addEvent,
  design,
  setRoom,
  files,
  setFiles,
  setSurvey,
  designTempC,
  groundTempC,
  setFlowTemp,
  minFlowTemp,
  maxFlowTemp,
  floors,
  roomCenterX,
  roomCenterY,
  setCurrentRoomId,
  scalingPoints,
  scaleLength,
  setScaleLength,
  tempImageAndScale,
  materialsLayers,
  setMaterials,
  setHeader,
  window,
  setWindow,
  door,
  setDoor,
  currentRooflight,
  setCurrentRooflight,
  tempCustomMaterial,
  setTempCustomMaterial,
  customRadiatorModels,
  setCustomRadiatorModels,
  allRadiatorModels,
  companyUuid,
  showUploadWidthPage,
  resetUploadPlan,
  setStageStep,
  setScalingPoints,
  setScalingWindow,
  setScalingDoor,
  onBack,
  setPageOpen
}: PageSelectorProps) => {
  if (currentRoom && currentWall && linkedWall && WALL_PAGES.includes(page as CurrentWallPage)) {
    return <WallPage
      key={currentWall.uuid}
      onBack={onBack}
      currentRoom={currentRoom}
      floor={floor}
      setFloor={setFloor}
      wall={currentWall}
      setWall={setWall}
      page={page as CurrentWallPage}
      setPage={setPage}
      linkedWall={linkedWall}
      survey={survey}
      onSave={saveWall}
      materials={materials}
      setMsProps={setMsProps}
      addEvent={addEvent}
      defaultMaterials={survey.default_materials!}
      designTempC={designTempC}
      setHeader={setHeader}
      window={window}
      setWindow={setWindow}
      door={door}
      setDoor={setDoor}
      setScalingWindow={setScalingWindow}
      setScalingDoor={setScalingDoor}
      setStageStep={setStageStep}
    />
  }

  if (ROOM_PAGES.includes(page as CurrentRoomPage) && currentRoom) {
    return <RoomPage
      key={currentRoom.uuid}
      design={design}
      page={page as CurrentRoomPage}
      setPage={setPage}
      room={currentRoom}
      setRoom={setRoom}
      files={files}
      setFiles={setFiles}
      survey={survey}
      setSurvey={setSurvey}
      materials={materials}
      setMsProps={setMsProps}
      floor={floor}
      customRadiatorModels={customRadiatorModels}
      setCustomRadiatorModels={setCustomRadiatorModels}
      allRadiatorModels={allRadiatorModels}
      designTempC={designTempC}
      groundTempC={groundTempC}
      setFlowTemp={setFlowTemp}
      minFlowTemp={minFlowTemp}
      maxFlowTemp={maxFlowTemp}
      setHeader={setHeader}
      currentRooflight={currentRooflight}
      setCurrentRooflight={setCurrentRooflight}
      companyUuid={companyUuid}
      onBack={onBack}
    />
  }

  if (page === 'ADD_ROOM') {
    return <AddRoomTab
      setFloor={setFloor}
      startingX={roomCenterX}
      startingY={roomCenterY}
      survey={survey}
      floor={floor}
      setPage={setPage}
      addEvent={addEvent}
      setCurrentRoomId={setCurrentRoomId}
      setHeader={setHeader}
      onBack={onBack}
      setPageOpen={setPageOpen}
    />
  }

  if (showUploadWidthPage) {
    return <ScalePage
      scalingPoints={scalingPoints}
      scaleLength={scaleLength}
      setScaleLength={setScaleLength}
      floor={floor}
      tempImageAndScale={tempImageAndScale}
      addEvent={addEvent}
      resetUploadPlan={resetUploadPlan}
      setStageStep={setStageStep}
      setHeader={setHeader}
      setScalingPoints={setScalingPoints}
      setIsPageOpen={setPageOpen}
    />
  }

  if (page === 'MATERIALS') {
    return <MaterialsSelector
      groupedProps={msProps!}
      setGroupedProps={setMsProps}
      setMaterialsCallback={setMaterials}
      setPage={setPage}
      setHeader={setHeader}
      setTempCustomMaterial={setTempCustomMaterial}
      companyUUID={companyUuid}
      onBack={onBack}
    />
  }

  if (page === 'CUSTOM_MATERIAL') {
    return <CustomMaterialPopup
      material={tempCustomMaterial}
      setMaterial={setTempCustomMaterial}
      materialsLayers={materialsLayers}
      onSave={(newMaterial) => {
      // NB! do not override materials with the `materials` variable!
      // because it contains filtered values for the current surface and will override the global materials list
        setMaterials(prev => ([...prev, newMaterial]))
        setMsProps(prev => ({ ...prev, materials: [...prev.materials!, newMaterial], selectedMaterial: newMaterial }))
        if (msProps!.onSelectCallback) {
        // trigger global callback
          msProps!.onSelectCallback(newMaterial)
        }
      }}
      setVisible={() => onBack()}
      setHeader={setHeader}
      onBack={onBack}
    />
  }

  if (page === 'FLOOR_DETAILS') {
    return <FloorDetails
      survey={survey}
      setPage={setPage}
      floors={floors}
      floor={floor}
      setFloor={setFloor}
      setHeader={setHeader}
      onBack={onBack}
    />
  }
}

type UploadPlanTooltipProps = {
  primaryText: string
  step: number
  setStep: Dispatch<SetStateAction<number>>
  resetFloorPlan: () => void
  setScalingPoints: Dispatch<SetStateAction<SprucePoint[]>>
}

const UploadPlanTooltip: FC<UploadPlanTooltipProps> = ({ primaryText, step, setStep, resetFloorPlan, setScalingPoints }) => {
  return <div className="text-white px-5 pt-4 pb-5 bg-sky-700 rounded-lg flex-col justify-center gap-4 flex w-full">
    <div className="flex-col gap-3 flex">
      <div className="font-bold">{primaryText}</div>
      <div className="items-center gap-3 flex">
        {step === 1
          ? <Button onClick={resetFloorPlan} iconLeft={X}>Cancel</Button>
          : <Button onClick={() => { setScalingPoints(prev => prev.slice(0, -1)); setStep(prev => prev - 1) }} iconLeft={ChevronLeft}>Back</Button>}
        <div className="text-xs">Step {step} of 3</div>
      </div>
    </div>
  </div>
}
