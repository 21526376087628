import React, { type Dispatch, type SetStateAction } from 'react'
import { type FileWrapper } from '../file_wrapper'
import { type PropertySurvey } from '../../../code/models/property'
import { type PagesFlowPage } from '../../../code/use_pages_flow'
import { ElectricsIncomingMainAndFusePhotosBlock } from './electrics/electrics_incoming_main_and_fuse_photos_block'
import { ElectricsIncomingMainAndFuseDetailsBlock } from './electrics/electrics_incoming_main_and_fuse_details_block'
import { ElectricsEarthingArrangementPhotosBlock } from './electrics/electrics_earthing_arrangement_photos_block'
import { ElectricsEarthingArrangementDetailsBlock } from './electrics/electrics_earthing_arrangement_details_block'
import { ElectricsConsumerUnitPhotosBlock } from './electrics/electrics_consumer_unit_photos_block'
import { ElectricsConsumerUnitDetailsBlock } from './electrics/electrics_consumer_unit_details_block'
import { ElectricsDirectLoadsBlock } from './electrics/electrics_direct_loads_block'
import { ElectricsCableRoutingBlock } from './electrics/electrics_cable_routing_block'
import { SurveySectionFlowWrapper } from './components/survey_section_flow_wrapper'
import { type SetIndexedDb } from '../../admin/job_layout/job_layout'

type ElectricsProps = {
  survey: PropertySurvey
  setSurvey: SetIndexedDb<PropertySurvey>
  files: FileWrapper[]
  setFiles: Dispatch<SetStateAction<FileWrapper[]>>
  companyUUID: string
  isOffline: boolean
  allSynced: boolean
}

export const SurveyElectricsContext = React.createContext<ElectricsProps | undefined>(undefined)

export const Electrics = ({ survey, files, setFiles, setSurvey, companyUUID, isOffline, allSynced }: ElectricsProps) => {
  // WARNING! Never change flagBits for the pages because they are stored in the DB.
  // instead, add new pages or replace existing with new flags
  const pages = [
    { flagBit: 0x001, pbValue: 10, page: <ElectricsIncomingMainAndFusePhotosBlock /> },
    { flagBit: 0x002, pbValue: 30, page: <ElectricsIncomingMainAndFuseDetailsBlock /> },
    { flagBit: 0x004, pbValue: 40, page: <ElectricsEarthingArrangementPhotosBlock /> },
    { flagBit: 0x008, pbValue: 60, page: <ElectricsEarthingArrangementDetailsBlock /> },
    { flagBit: 0x010, pbValue: 70, page: <ElectricsConsumerUnitPhotosBlock /> },
    { flagBit: 0x020, pbValue: 80, page: <ElectricsConsumerUnitDetailsBlock /> },
    { flagBit: 0x040, pbValue: 90, page: <ElectricsDirectLoadsBlock /> },
    { flagBit: 0x080, pbValue: 100, page: <ElectricsCableRoutingBlock /> }
  ] as PagesFlowPage[]

  return <SurveyElectricsContext.Provider value={{ isOffline, allSynced, survey, setSurvey, files, setFiles, companyUUID }}>
    <SurveySectionFlowWrapper
      isOffline={isOffline}
      allSynced={allSynced}
      completeSectionID={'ELECTRICS'}
      sectionUIName={'Electrics'}
      pages={pages}
      flags_attribute_name={'flags_electrics_pages_completed'}
      onPageComplete={(flagBit) => {
        setSurvey({ ...survey, flags_electrics_pages_completed: survey.flags_electrics_pages_completed | flagBit })
      }}
      onFormComplete={() => window.history.back()}
      survey={survey}
      setSurvey={setSurvey}
      completionChecks={[]}
    />
  </SurveyElectricsContext.Provider>
}
