import React, { type Dispatch, type SetStateAction } from 'react'
import { ACH_AGES, THERMAL_BRIDGING_ADDITIONS } from '../../constants'
import { Select } from '../../../../components/inputs_and_selections/select'
import type { PropertySurvey } from '../../../../code/models/property'
import { Icon } from '../../../../components/buttons/icon'
import { ClickableCard } from '../../../../components/content_display/card'
import { FormLabel } from '../../../../components/inputs_and_selections/form_label'
import { Input } from '../../../../components/inputs_and_selections/input'
import { VerticalFormGroup } from '../../../../components/inputs_and_selections/vertical_form_group'
import { Toggle } from '../../../../components/inputs_and_selections/toggle'
import { HorizontalFormGroup } from '../../../../components/inputs_and_selections/horizontal_form_group'
import { Info } from '../../../../components/buttons/info'
import { Link } from '../../../../components/buttons/link'
import { openInNewTab } from '../../../../code/helpers'
import { XCircle } from 'lucide-react'

type FloorplanHeatLossSettingsBlockProps = {
  survey: PropertySurvey
  setSurvey: Dispatch<SetStateAction<PropertySurvey>>
  designTempDefault: number
  altitudeDefaultM: number
}

export const FloorplanHeatLossSettingsBlock = ({
  survey,
  setSurvey,
  designTempDefault,
  altitudeDefaultM
}: FloorplanHeatLossSettingsBlockProps) => {
  return <>
    <div className="flex-col justify-start items-start gap-3 flex">
      <div className="text-gray-900 text-xl font-bold">Settings</div>
      <div className="">Set the calculating conditions for the property.</div>
    </div>
    <div className="flex flex-col gap-5 ">
      <VerticalFormGroup
        formLabel={<FormLabel labelText={'Altitude'} helperText={'Automatically filled in based on the address'}/>}
        input={<Input
          type='number'
          value={survey.altitude_override_m?.toString() ?? altitudeDefaultM.toString()}
          setValue={(e) => setSurvey(prev => ({ ...prev, altitude_override_m: Number(e) }))}
          postfix={
            <div className="gap-2.5 flex items-center">
              <div className="">m</div>
              {survey.altitude_override_m !== undefined && survey.altitude_override_m !== altitudeDefaultM &&
                  <Icon icon={XCircle} onClick={() => setSurvey(prev => ({ ...prev, altitude_override_m: undefined }))}
                    colour='text-gray-400'/>}
            </div>
          }/>}
      />

      <VerticalFormGroup
        formLabel={<FormLabel
          labelText={'Design outdoor temperature'}
          helperText={'This defaults to the 99th percentile'}
          info={<Info
            infoModalHeader={'Design outdoor temperature'}
            infoModalBody={<div>The heat pump must be able to keep the home at the specified room temperatures when it's this temperature outside.
              This is automatically populated based on the property's location and altitude using the values in the CIBSE's Guide A - Environmental Design. More info <Link
              onClick={() => openInNewTab('https://spruce-energy.notion.site/Design-outdoor-temperature-247b30607c514fdbaca06bc2e92a84c5')}
              className='inline'
              text='here'
            />.
            </div>}
          />}
        />}
        input={<Input
          type='number'
          value={survey.design_temp_override_c ?? designTempDefault}
          setValue={(e) => setSurvey(prev => ({ ...prev, design_temp_override_c: Number(e) }))}
          postfix={
            <div className="gap-2.5 flex items-center">
              <div className="">°C</div>
              {survey.design_temp_override_c !== undefined && survey.design_temp_override_c !== designTempDefault &&
                  <Icon icon={XCircle} onClick={() => setSurvey(prev => ({ ...prev, design_temp_override_c: undefined }))}
                    colour='text-gray-400'/>}
              {/* Set override to undefined rather than the default value so that the altitude input changes the design temp if the alititude is changed */}
            </div>
          }/>}
      />
      <ClickableCard variant='GREY'>
        <div className='flex flex-col gap-5'>
          <HorizontalFormGroup
            formLabel={<FormLabel labelText={'Indoor temperature based on CIBSE defaults'}
              helperText={'Based on room type'}/>}
            input={<Toggle size='LG' value={survey.use_cibse_indoor_temps} setValue={() => setSurvey(prev => ({
              ...prev, use_cibse_indoor_temps: !survey.use_cibse_indoor_temps
            }))}/>}
          />
          {!survey.use_cibse_indoor_temps &&
              <VerticalFormGroup
                formLabel={<FormLabel labelText={'Indoor temperature default'}/>}
                input={<Input type='number' value={survey.indoor_temp_overall_c.toString()}
                  setValue={(e) => setSurvey(prev => ({ ...prev, indoor_temp_overall_c: Number(e) }))}
                  postfix={<span className='text-gray-500'>°C</span>}/>}
              />
          }
        </div>
      </ClickableCard>
      <ClickableCard variant='GREY'>
        <div className='flex flex-col gap-5'>
          <HorizontalFormGroup
            formLabel={<FormLabel
              labelText={'Air changes per hours based on CIBSE defaults'}
              info={<Info
                infoModalHeader={'CIBSE defaults'}
                infoModalBody={<div>The CIBSE default ACH values are based on the room type and age band of the property. <Link
                  onClick={() => openInNewTab('https://docs.openenergymonitor.org/heatpumps/air_change_rate_calculations.html')}
                  className='inline'
                  text='Other methods and empirical data'
                /> suggest that these values are very conservative and may overestimate the actual ACH of the property.
                </div>
                }
              />}
              helperText={'Based on room type and age band'}/>}
            input={<Toggle size='LG' value={survey.use_cibse_air_change_values} setValue={() => setSurvey(prev => ({
              ...prev,
              use_cibse_air_change_values: !survey.use_cibse_air_change_values
            }))}/>}
          />
          {survey.use_cibse_air_change_values &&
            <VerticalFormGroup
              formLabel={<FormLabel labelText={'Property age'}/>}
              input={<Select selectedKey={survey.air_change_year_uuid} options={ACH_AGES}
                setSelectedKey={(e) => setSurvey(prev => ({ ...prev, air_change_year_uuid: e }))}/>}
            />
          }
          {!survey.use_cibse_air_change_values &&
              <VerticalFormGroup
                formLabel={<FormLabel
                  labelText={'Air changes per hour default'}
                  info={<Info
                    infoModalHeader={'Air changes input'}
                    infoModalBody={'The air changes per hour (ACH) value is the number of times the volume of air in a room is replaced in an hour. ' +
                  ' This should be the sum of the air changes due to mechanical ventilation and the air changes from uncontrolled infiltration. '
                    }
                  />}
                />}
                input={<Input type='number' value={survey.air_change_per_hour_overall.toString()}
                  setValue={(e) => setSurvey(prev => ({ ...prev, air_change_per_hour_overall: Number(e) }))}/>}
              ></VerticalFormGroup>
          }
        </div>
      </ClickableCard>

      <ClickableCard variant='GREY'>
        <HorizontalFormGroup
          formLabel={
            <FormLabel
              labelText={'Thermal bridging'}
              helperText={'Increase u-values to account for thermal bridging'}
              info={<Info
                infoModalHeader={'Thermal bridging'}
                infoModalBody={<div>A thermal bridge is a detail of a building's construction that allows heat to pass through significantly more easily than through the surrounding materials.
                  This is typically where there is a break in the insulation, less insulation or the insulation is penetrated by an element with a higher thermal conductivity, such as wall ties, junctions in materials, or lintels.
                  This can lead to a higher heat loss at this point than the calculated value.
                  It is not accounted for in the standard u-values.
                </div>
                }
              />}
            />
          }
          input={<Toggle size={'LG'} value={survey.include_thermal_bridging} setValue={() => setSurvey(prev => ({
            ...prev,
            include_thermal_bridging: !survey.include_thermal_bridging
          }))}/>}
        />
        {survey.include_thermal_bridging &&
            <VerticalFormGroup
              formLabel={<FormLabel
                labelText={'Increase in u-value based on'}
                info={<Info infoModalHeader={'Increase in u-value based on thermal bridging'}
                  infoModalBody={<div>These options are based on Table 3.4 in the CIBSE domestic heating design guide:
                    <ul className = "list-disc pl-5 mt-1 space-y-1">
                      <li><strong>All other buildings - 0.1 W/m²K:</strong> All other buildings</li>
                      <li><strong>Insulation above current national standards - 0.02 W/m²K:</strong> Buildings built
                        with a high level of heat insulation (over and above current national standards) and which
                        implement minimisation of thermal bridging practices that exceed generally recognised rules of
                        practice
                      </li>
                      <li><strong>Current national standards - 0.05 W/m²K:</strong> Buildings built to current national
                        standards and in compliance with generally recognised rules of practice regarding the
                        minimisation of thermal bridges
                      </li>
                      <li><strong>Exterior wall insulation broken by solid ceilings - 0.15 W/m²K:</strong> Buildings
                        with exterior wall insulation broken by solid ceilings (e.g. reinforced concrete)
                      </li>
                    </ul>
                  </div>}
                />}
              />}
              input={<Select selectedKey={survey.thermal_bridging_addition_key} options={THERMAL_BRIDGING_ADDITIONS}
                setSelectedKey={(e) => setSurvey(prev => ({
                  ...prev,
                  thermal_bridging_addition_key: e
                }))}/>}
            />}
      </ClickableCard>

      <ClickableCard variant='GREY'>
        <HorizontalFormGroup
          formLabel={<FormLabel labelText={'Intermittent heating required'}
            helperText={'Home is heated intermittently so owner wants oversize system to reduce reheat time (not advised)'}/>}
          input={<Toggle size='LG' value={survey.intermittent_heating} setValue={() => setSurvey(prev => ({
            ...prev,
            intermittent_heating: !survey.intermittent_heating
          }))}/>}
        />
        {survey.intermittent_heating &&
            <VerticalFormGroup
              formLabel={<FormLabel labelText={'Oversize factor'}/>}
              input={<Input type='number' value={survey.intermittent_heating_oversize_factor_percentage.toString()}
                setValue={(e) => setSurvey(prev => ({
                  ...prev,
                  intermittent_heating_oversize_factor_percentage: Number(e)
                }))} postfix='%'/>}
            />
        }
      </ClickableCard>

      <HorizontalFormGroup
        formLabel={<FormLabel
          labelText={'MVHR installed'}
          helperText={'Mechanical ventilation with heat recovery is installed at the property'}
          info={<Info
            infoModalHeader={'How we treat MVHR'}
            infoModalBody={<div className="space-y-4">
              <p>Reduces the modelled ventilation heat loss by 50%.</p>
              <p>
                This is an approximation based on the assumption that the MVHR has a sensible heat recovery efficiency
                of ~80% and that ~60% of the total ventilation goes through the MVHR, with the remaining ~40% being uncontrolled infiltration.
              </p>
              <p>For this to be correct you need to ensure that the ACH value entered covers both the mechanical ventilation and uncontrolled infiltration.</p>
            </div>}
          />}
        />}
        input={<Toggle size='LG' value={survey.mvhr_installed}
          setValue={() => setSurvey(prev => ({
            ...prev,
            mvhr_installed: !survey.mvhr_installed
          }))}/>}
      />
      {survey.mvhr_installed && <VerticalFormGroup
        formLabel={<FormLabel labelText={'Reduction in ventilation heat loss (%)'}/>}
        input={<Input type={'number'} value={'50'} postfix={'%'} disabled={true} setValue={() => {}}/>}
      />}

      <HorizontalFormGroup
        formLabel={<FormLabel labelText={'Exposed location'}
          helperText={'Property is exposed to adverse weather conditions (e.g. located close to the coast)'}/>}
        input={<Toggle size='LG' value={survey.exposed_location} setValue={() => setSurvey(prev => ({
          ...prev,
          exposed_location: !survey.exposed_location,
          design_temp_override_c: undefined
        }))}/>}
      />

    </div>
  </>
}
