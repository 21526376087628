import React from 'react'
import { Text } from './text'

export const Progress = ({ progress }: { progress: number }) => {
  const clampedProgress = Math.max(0, Math.min(progress, 100))

  return <div className='w-full flex flex-row items-center gap-2'>
    {/* Shell */}
    <div className="w-full h-2 bg-white border border-light rounded-full">
      {/* Bar */}
      <div style={{ width: `${clampedProgress}%` }} className='h-full bg-slate-200 transition-all'></div>
    </div>
    <Text size='SM' className='w-10 text-center'>{Math.round(clampedProgress)}%</Text>
  </div>
}
