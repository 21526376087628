import React, { type Dispatch, type SetStateAction } from 'react'
import { type Floor } from '../../../code/models/floor'
import { type Room } from '../../../code/models/room'
import { type Wall } from '../../../code/models/wall'
import { type CurrentFloorPlanPage } from './floor'
import { FloorButtons } from './floor_buttons'
import { RoomButtons } from './room_buttons'
import { WallButtons } from './wall_buttons'
import { FloorPlanButtons } from './floor_plan_buttons'
import { RoomDrawingButtons } from '../room_drawing_buttons'
import { type SprucePoint } from './code/types'

type EntityToolbarProps = {
  setPage: (page: CurrentFloorPlanPage, open: boolean) => void
  currentWall: Wall
  currentRoom: Room
  currentFloor: Floor
  showFloorPlanButtons: boolean
  setTempImageAndScale: Dispatch<SetStateAction<{ image: string, scale: number }>>
  setStageStep: Dispatch<SetStateAction<number | undefined>>
  setFloor: (floor: Floor) => void
  setShowFloorPlanButtons: Dispatch<SetStateAction<boolean>>
  drawingRooms: boolean
  setDrawingRooms: Dispatch<SetStateAction<boolean>>
  cancelDrawingRooms: () => void
  scalingPoints: SprucePoint[]
  setScalingPoints: Dispatch<SetStateAction<SprucePoint[]>>
  intersectingShapes: Array<Array<[number, number]>>
  setIsPageOpen: Dispatch<SetStateAction<boolean>>
  planOpacity: number
  setPlanOpacity: Dispatch<SetStateAction<number>>
}

export const EntityToolbar = ({ setPage, currentWall, currentRoom, currentFloor, setTempImageAndScale, setStageStep, showFloorPlanButtons, setShowFloorPlanButtons, setFloor, drawingRooms, setDrawingRooms, cancelDrawingRooms, scalingPoints, setScalingPoints, intersectingShapes, planOpacity, setPlanOpacity }: EntityToolbarProps) => {
  if (drawingRooms) return <RoomDrawingButtons cancelDrawingRooms={cancelDrawingRooms} scalingPoints={scalingPoints} setScalingPoints={setScalingPoints} />
  if (showFloorPlanButtons) return <FloorPlanButtons floor={currentFloor} setFloor={setFloor} setShowFloorPlanButtons={setShowFloorPlanButtons} setTempImageAndScale={setTempImageAndScale} setStageStep={setStageStep} planOpacity={planOpacity} setPlanOpacity={setPlanOpacity} />
  if (currentWall) return <WallButtons setPage={setPage} currentWall={currentWall} />
  if (currentRoom) return <RoomButtons setPage={setPage} currentRoom={currentRoom} />

  return <FloorButtons
    setShowFloorPlanButtons={setShowFloorPlanButtons}
    setStageStep={setStageStep}
    setPage={setPage}
    currentFloor={currentFloor}
    setTempImageAndScale={setTempImageAndScale}
    setDrawingRooms={setDrawingRooms}
    intersectingShapes={intersectingShapes}
  />
}
