import { getEmitterVelocityMPerS, getEmitterWatts, type RadiatorModel } from './radiator_model'
import { type SurveyImage, type PropertySurvey, type SurveyDesign } from './property'
import { type UnderfloorHeating } from './underfloor_heating'
import { type Room } from './room'

export type Emitter = Radiator | UnderfloorHeating | SecondaryEmitter

export type EmitterBase = {
  uuid: string | undefined
  survey_or_design: 'SURVEY' | 'DESIGN'
  survey_design_uuid?: string
  room_uuid?: string
  created_at: number | undefined
  updated_at: number | undefined
  replaces_uuid?: string

  /**
   * @deprecated This property is superseded by emitter_type and survey_or_design
   */
  type?: string
}

export type Radiator = EmitterBase & {
  emitter_type: 'RADIATOR'
  photos: SurveyImage[]

  radiator_type_uuid: string // TODO: decide whether to rename and if so write migration on client only maybe?
  radiator_type?: RadiatorModel // TODO: decide whether to rename and if so write migration on client only maybe?
  pipe_model_uuid: string | undefined
  maximum_width: number
  maximum_height: number
  notes: string

  /**
   * @deprecated This property is superseded by pipe_model_uuid
   */
  pipe_diameter_uuid?: string
}

export type SecondaryEmitter = EmitterBase & {
  emitter_type: 'SECONDARY'
  name: string
  room_heating_load_covered_percentage: number | undefined
  notes: string
  photos: SurveyImage[]
}

export const getRadiatorHeightWidthText = (radiatorModel: RadiatorModel) => `${radiatorModel.height_mm} x ${radiatorModel.width_mm} mm`

export type EmitterOutputRow = {
  typeName: string
  size: string
  velocityMPerS: number | undefined
  watts: number
}
export const getEmitterOutputRow = (radiator: Emitter, room: Room, survey: PropertySurvey, design: SurveyDesign, designTempC: number, groundTempC: number) => {
  const radiatorWatts = getEmitterWatts(radiator, room, design, survey, designTempC, groundTempC)
  const velocityMPerS = getEmitterVelocityMPerS(radiator, radiatorWatts, design.delta_t_flow_return_c, design.flow_temp)

  const emitterRow: EmitterOutputRow = {
    typeName: getEmitterTypeName(radiator) ?? '',
    size: getEmitterSizeName(radiator),
    velocityMPerS,
    watts: Math.round(radiatorWatts)
  }
  return emitterRow
}

export const getEmitterTypeName = (emitter: Emitter) => {
  if (emitter.emitter_type === 'SECONDARY') return emitter.name
  if (emitter.emitter_type === 'UNDERFLOOR') return emitter.floor_construction_type

  return emitter.radiator_type?.type
}
export const getEmitterSizeName = (radiator: Emitter) => {
  if (radiator.emitter_type === 'SECONDARY') return radiator.room_heating_load_covered_percentage + '%'
  if (radiator.emitter_type === 'UNDERFLOOR') return radiator.pipe_centres_uuid + ' centres'

  return getRadiatorHeightWidthText(radiator.radiator_type!)
}

export type RadiatorWithStatus = {
  radiator: Emitter
  status: 'REMOVED' | 'REPLACED' | 'ADDED' | 'UNCHANGED'
  replacedBy: Emitter | undefined
  room: Room
}

export const getFullEmittersListByStatus = (survey: PropertySurvey, design: SurveyDesign): RadiatorWithStatus[] => {
  const result: RadiatorWithStatus[] = []

  const designRadiators = design.radiators

  const rooms = survey.floors.flatMap(x => x.rooms)
  // collect current radiators
  rooms.forEach(room => {
    room.radiators.forEach(radiator => {
      const replacedBy = designRadiators.find(x => x.replaces_uuid === radiator.uuid)
      if (replacedBy) {
        result.push({ radiator, status: 'REPLACED', replacedBy, room })
      } else {
        if (design.removed_radiator_uuids.includes(radiator.uuid!)) {
          result.push({ radiator, status: 'REMOVED', replacedBy: undefined, room })
        } else {
          result.push({ radiator, status: 'UNCHANGED', replacedBy: undefined, room })
        }
      }
    })
  })

  // collect designed radiators
  design.radiators.forEach(radiator => {
    const room = rooms.find(x => x.uuid === radiator.room_uuid)
    // if the radiator replaces something, it's already in the list as `replacedBy` in a 'REPLACED' status row
    // or if the original room has been deleted
    if (radiator.replaces_uuid || !room) return

    result.push({ radiator, status: 'ADDED', replacedBy: undefined, room })
  })

  return result
}
