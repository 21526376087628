import { type Door } from './door'
import { type WallWindow } from './window'
import {
  type CustomAttributes,
  type ExternalWallAttributes,
  type InternalWallAttributes,
  type Material,
  type PartyWallAttributes
} from './material'

export type Wall = {
  uuid?: string
  // room on the other side of the wall
  other_room_uuid: string | undefined
  other_side_temp_override_c?: number | undefined // override the temperature of the other side of the wall
  room_uuid?: string
  x?: number
  y?: number
  material: Material | undefined
  windows: WallWindow[]
  doors: Door[]
}

export const getWallUValue = (wall: Wall, thermalBridgingUValueAdditionWPerM2K: number): number => {
  // Thermal bridging is only added to external elements
  const uValueAddition = wall.material?.applicable_to === 'external-wall' ? thermalBridgingUValueAdditionWPerM2K : 0

  if (['custom_layered', 'custom_simple'].includes(wall.material!.type)) {
    return (wall.material?.extra_data as CustomAttributes).u_value! + uValueAddition
  }
  switch (wall.material?.applicable_to) {
    case 'internal-wall':
      return (wall.material?.extra_data as InternalWallAttributes).u_value
    case 'external-wall':
      return (wall.material?.extra_data as ExternalWallAttributes).u_value + uValueAddition
    case 'party-wall':
      return (wall.material?.extra_data as PartyWallAttributes).u_value
    default:
      return 0
  }
}
