import React from 'react'
import { SurveySiteDetailsContext } from '../site_details'
import { TextArea } from '../../../../components/inputs_and_selections/text_area'
import { Toggle } from '../../../../components/inputs_and_selections/toggle'

export const SiteDetailsParkingBlock = () => {
  const prContext = React.useContext(SurveySiteDetailsContext)
  const { survey, setSurvey } = prContext!

  return <>
    <div className="text-gray-900 text-xl font-bold">Parking</div>
    <div className="items-center gap-3 flex justify-between">
      <div className="flex-col flex">
        <div className="text-gray-900 text-base font-bold">Parking available</div>
      </div>
      <Toggle size='LG' value={survey.parking_available}
        setValue={() => setSurvey({ ...survey, parking_available: !survey.parking_available })}/>
    </div>
    <div className="items-center gap-3 flex justify-between">
      <div className="flex-col flex">
        <div className="text-gray-900 text-base font-bold">Permit required</div>
      </div>
      <Toggle size='LG' value={survey.permit_required}
        setValue={() => setSurvey({ ...survey, permit_required: !survey.permit_required })}/>
    </div>
    <div className="items-center gap-3 flex justify-between">
      <div className="flex-col flex">
        <div className="text-gray-900 text-base font-bold">Space for kit to be delivered</div>
      </div>
      <Toggle size='LG' value={survey.space_for_kit_to_be_delivered} setValue={() => setSurvey({
        ...survey,
        space_for_kit_to_be_delivered: !survey.space_for_kit_to_be_delivered
      })}/>
    </div>
    <div className="gap-3 flex flex-col justify-between">
      <div className="flex-col flex">
        <div className="text-gray-900 text-base font-bold">Notes</div>
      </div>
      <TextArea size='LG' value={survey.notes} setValue={(e) => setSurvey({ ...survey, notes: e })}/>
    </div>
  </>
}
