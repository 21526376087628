import mixpanel from 'mixpanel-browser'

type Event =
  { name: 'Form Loaded', properties: {} } |
  { name: 'Postcode Step Completed', properties: {} } |
  { name: 'Select Address Step Completed', properties: {} } |
  { name: 'Property Details Step Completed', properties: {} } |
  { name: 'Customer Details Step Completed', properties: { fromAdmin: boolean } } |
  { name: 'Login Clicked', properties: {} } |
  { name: 'New Enquiry Clicked', properties: {} } |
  { name: 'Enquiry Detail Page Clicked', properties: {} } |
  { name: 'Enquiry Status Dropdown Changed', properties: { statusId: string } } |
  { name: 'UValue Save Override Clicked', properties: {} } |
  { name: 'Get Directions Clicked', properties: {} } |
  { name: 'Preview Estimate Clicked', properties: {} } |
  { name: 'Send Estimate Clicked', properties: {} } |
  { name: 'Heat Pump Added', properties: {} } |
  { name: 'Heat Pump Deleted', properties: {} } |
  { name: 'Heat Pump Modified', properties: {} } |
  { name: 'Cylinder Added', properties: {} } |
  { name: 'Cylinder Deleted', properties: {} } |
  { name: 'Cylinder Modified', properties: {} } |
  { name: 'Labour Modified', properties: {} } |
  { name: 'Survey Costs Modified', properties: {} } |
  { name: 'Sign Up Posted', properties: {} } |
  { name: 'Company Settings Saved', properties: {} } |
  { name: 'Password Reminder Sent', properties: {} } |
  { name: 'New Password Set', properties: {} } |

  { name: 'Manual address entry clicked', properties: {} } |
  { name: 'Address clicked', properties: {} } |
  { name: 'Manual address step completed', properties: {} } |
  { name: 'EPC found step completed', properties: {} } |
  { name: 'No EPC found step completed', properties: {} } |
  { name: 'Property type step completed', properties: {} } |
  { name: 'Built form step completed', properties: {} } |
  { name: 'Outdoor space step completed', properties: {} } |
  { name: 'More bedrooms clicked', properties: {} } |
  { name: 'Count bedroom step completed', properties: {} } |
  { name: 'More bathrooms clicked', properties: {} } |
  { name: 'Count bathroom step completed', properties: {} } |
  { name: 'Current heating type step completed', properties: {} } |
  { name: 'Changes since EPC step completed', properties: {} } |
  { name: 'Construction age band step completed', properties: {} } |
  { name: 'Floor area step completed', properties: {} } |
  { name: 'Windows step completed', properties: {} } |
  { name: 'Wall type step completed', properties: {} } |
  { name: 'Cavity wall insulation step completed', properties: {} } |
  { name: 'Other wall insulation step completed', properties: {} } |
  { name: 'Loft insulation step completed', properties: {} } |
  { name: 'Enquiry reasoning step completed', properties: {} } |
  { name: 'Contact details step completed', properties: {} } |
  { name: 'Spruce privacy policy clicked', properties: {} } |
  { name: 'Contact details question accordion clicked', properties: {} } |
  { name: 'Estimate link clicked', properties: {} } |
  { name: 'Estimate question accordion clicked', properties: {} } |
  { name: 'Request heat loss survey clicked', properties: {} } |
  { name: 'Request a callback clicked', properties: {} } |
  { name: 'Not interested clicked', properties: {} } |
  { name: 'Visit Home Energy Scotland clicked', properties: {} } |
  { name: 'Home Energy Scotland Step Completed', properties: {} } |
  { name: 'Decline Enquiry Clicked', properties: {} } |
  { name: 'Send Nudge Lead Email', properties: {} }

export const logEvent = (event: Event, companySubdomain: string | null) => {
  // allow Sign Up event without a company subdomain
  if (!companySubdomain && event.name !== 'Sign Up Posted') return

  // Only log events in production and staging
  if (!['production', 'staging'].includes(process.env.APP_ENV!)) return

  // Do not rename companyId key name here! It's used by MixPanel
  mixpanel.track(event.name, { ...event.properties, companyId: companySubdomain })
}
