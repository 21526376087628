import type { PropertySurvey } from '../../../code/models/property'
import React, { type Dispatch, type SetStateAction, useContext, useEffect, useState } from 'react'
import { AdminContext } from '../../admin/admin_layout'
import {
  convertMagicplanPlanDetailsToFloors,
  getMagicplanPlanDetails,
  getMagicplanPlans,
  type MagicplanPlan
} from '../../../code/floorplan_adapters/magicplan'
import _ from 'lodash'
import { RadioGroup, type RadioGroupItem } from '../../../components/inputs_and_selections/radio'
import { Modal } from '../../../components/containers/modal'
import { Loader } from '../../../components/indicators_and_messaging/loader'
import { Button } from '../../../components/buttons/button'

type MagicplanModalProps = {
  survey: PropertySurvey
  setSurvey: Dispatch<SetStateAction<PropertySurvey>>
  showMagicplanModal: boolean
  setShowMagicplanModal: Dispatch<SetStateAction<boolean>>

  // used to make additional actions when a floorplan is imported
  // in our case it's showing Floor List is the modal was opened in the New Floor page.
  afterImport?: () => void
}

export const MagicplanModal = ({ survey, setSurvey, showMagicplanModal, setShowMagicplanModal, afterImport }: MagicplanModalProps) => {
  const adminContext = useContext(AdminContext)

  const [showFetchLoader, setShowFetchLoader] = useState(false)
  const [showImportLoader, setShowImportLoader] = useState(false)
  const [projects, setProjects] = useState<MagicplanPlan[]>([])

  const [selectedProject, setSelectedProject] = useState<MagicplanPlan>()

  const handleLoadProjects = async () => {
    if (!adminContext) return

    setShowFetchLoader(true)
    const projects = await getMagicplanPlans(adminContext.data.company!.public_info.uuid)
    if (!projects) {
      alert('Error getting Magicplan projects. Check your Magicplan API key and integration settings')
      setShowFetchLoader(false)
      return
    }

    setProjects(projects)
    setShowFetchLoader(false)
  }

  const projectsItems = !showMagicplanModal ? [] : projects.map((p) => {
    return {
      name: p.name,
      description: p.address.postal_code ? _.compact([p.address.street, p.address.street_number, p.address.city, p.address.postal_code]).join(', ') : undefined,
      onClick: () => setSelectedProject(p),
      variant: selectedProject === p ? 'ACTIVE' : 'DEFAULT',
      rightContent: p.thumbnail_url ? <img alt='' className='w-20 h-20' src={p.thumbnail_url} onError={(e) => {
        // if thumbnail fails to load, set it to empty src
        e.currentTarget.onerror = null
        e.currentTarget.src = 'data:image/gif;base64,R0lGODlhAQABAAD/ACwAAAAAAQABAAACADs=' // Empty GIF
      }}/> : undefined
    } satisfies RadioGroupItem
  })

  const handleConfirmation = async () => {
    if (!selectedProject) return

    setShowImportLoader(true)

    const planDetails = await getMagicplanPlanDetails(selectedProject.id, survey.uuid!, adminContext.data.company!.public_info.uuid)

    if (!planDetails) {
      alert('Error getting plan details')
    } else {
      const floors = convertMagicplanPlanDetailsToFloors(planDetails, survey)

      if (floors.length === 0) {
        alert('No floors found in plan')
      } else {
        setSurvey(prev => ({
          ...prev,
          floors: [
            ...prev.floors,
            ...floors
          ]
        }))
        if (afterImport) afterImport()
      }
    }

    setShowImportLoader(false)
    setShowMagicplanModal(false)
  }

  useEffect(() => {
    if (showMagicplanModal) handleLoadProjects()
  }, [showMagicplanModal])

  if (!showMagicplanModal) return null

  return <Modal
    visible={showMagicplanModal}
    setVisible={setShowMagicplanModal}
    title='Import Magicplan project'
    onConfirm={handleConfirmation}
    confirmButtonLabel='Import'
    confirmDisabled={!selectedProject || showImportLoader || showFetchLoader}
    thirdButton={undefined}
    hideOnConfirm={false}
  >
    <div className='w-full flex flex-col gap-2 h-96'>
      { showImportLoader &&
          <div className='flex flex-col justify-center items-center h-96 gap-4'>
            <span>Importing Magicplan project: {selectedProject?.name}</span>
            <Loader />
          </div>
      }
      { !showImportLoader && <>
        <Button disabled={showFetchLoader} onClick={handleLoadProjects}>Reload projects</Button>
        { showFetchLoader && <Loader />}
        { !showFetchLoader && projects.length !== 0 && <>
          <div className='h-96 overflow-y-scroll'>
            <RadioGroup
              items={projectsItems}
              isVertical={true}
            />
          </div>
        </>}
      </>}
      { !showFetchLoader && projects.length === 0 && <>
          No projects found
      </>}
    </div>
  </Modal>
}
