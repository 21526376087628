import React, { useState } from 'react'
import { useFiles } from '../use_files'
import _ from 'lodash'
import { Icon } from '../../components/buttons/icon'
import { Modal } from '../../components/containers/modal'
import { Card } from '../../components/content_display/card'
import { FileDropzone } from '../../components/inputs_and_selections/file_dropzone'
import { FormLabel } from '../../components/inputs_and_selections/form_label'
import { VerticalFormGroup } from '../../components/inputs_and_selections/vertical_form_group'
import { validateNotNull } from '../validators'
import { Input } from '../../components/inputs_and_selections/input'
import { Progress } from '../../components/content_display/progress'
import { File, RefreshCw, X } from 'lucide-react'

export const AddInternalAttachmentModal = ({ visible, setVisible, onAddAttachment, forceTitle }: { visible: boolean, setVisible: (boolean) => void, onAddAttachment: (title, url) => void, forceTitle?: string }) => {
  const { uploadFile, progress, success, error } = useFiles('user-uploads')
  const [fileURL, setFileURL] = useState<URL>()
  const [fileName, setFileName] = useState(forceTitle ?? '')
  const [fileToUpload, setFileToUpload] = useState<File | null>(null)

  const commonDocumentFileTypesToAccept = 'application/pdf,application/msword,application/vnd.openxmlformats-officedocument.wordprocessingml.document,' +
  'application/vnd.ms-excel,application/vnd.openxmlformats-officedocument.spreadsheetml.sheet,' +
  'image/jpeg, image/png, image/webp, image/tiff'

  const handleCloseModal = (open: boolean) => {
    if (visible) {
      setFileName(forceTitle || '')
      setFileToUpload(null)
      setVisible(open)
    }
  }

  // Main file upload logic
  const handleUploadFile = async (file: File) => {
    setFileToUpload(file) // Set the file to display name and status
    const formData = new FormData() // Create the form data to send to the backend
    formData.append('file', file)
    const responseData = await uploadFile(formData)
    setFileURL(responseData) // on success - set the file URL of the proposal attachment
  }

  return <Modal
    visible={visible}
    setVisible={() => handleCloseModal(false)}
    title='Upload file'
    confirmButtonLabel='Save'
    confirmDisabled={!success || fileName.length === 0}
    onConfirm={() => onAddAttachment(fileName, fileURL!.toString())}
  >
    <div className='w-full flex flex-col gap-4'>
      <VerticalFormGroup
        formLabel={<FormLabel labelText='Document title' helperText='The file will be renamed to this' required complete={fileName !== ''}/>}
        input={<Input
          placeholder='e.g. Case Study'
          setValue={(fileName: string) => setFileName(fileName)}
          value={fileName}
          disabled={Boolean(forceTitle)}
          validator={validateNotNull}
        />}
      />
      <VerticalFormGroup
        formLabel={<FormLabel labelText={'File'} required complete={fileURL !== undefined} />}
        input={
          fileToUpload
            ? <Card color={error ? 'RED' : 'DARK'}>
                <div className='flex p-2 justify-between items-center'>
                  <div className='flex gap-4 items-center'>
                    <Card color='WHITE'>
                      <Icon icon={File} className='size-6' />
                    </Card>
                    <FormLabel labelText={_.truncate(fileToUpload.name, { length: 30 })} helperText={ error || (success ? 'Uploaded' : 'Uploading')} />
                  </div>
                  {error
                    ? <Icon icon={RefreshCw} className='cursor-pointer p-1' onClick={() => handleUploadFile(fileToUpload)} />
                    : <Icon icon={X} className='cursor-pointer p-1' onClick={() => setFileToUpload(null)} />}
                </div>
                {progress !== 100 && <Progress progress={progress} />}
              </Card>
            // Only allow one file to be uploaded.
            : <FileDropzone helperText='Files must be pdf, document, spreadsheet or images' maxSizeMB={10} accept={commonDocumentFileTypesToAccept} onChange={files => files && handleUploadFile(files[0])} />
        }
      />
    </div>
  </Modal>
}
