import React from 'react'
import { Icon } from '../../components/buttons/icon'
import { Card } from '../../components/content_display/card'
import { FormLabel } from '../../components/inputs_and_selections/form_label'
import { VerticalFormGroup } from '../../components/inputs_and_selections/vertical_form_group'
import { ProposalAttachmentPlaceholder } from './proposal_attachment_placeholder'
import { openInNewTab } from '../helpers'
import { useFiles } from '../use_files'
import { type ProposalLink } from '../models/proposal_link'
import { FileText, Link, X } from 'lucide-react'

type AttachmentCardProps = {
  title?: string
  attachment?: ProposalLink
  onAddAttachment: (title: string, url: string, internal: boolean) => void
  onRemoveAttachment: () => void
  forceTitle?: string
  showLabel?: boolean
}

// An attachment card displays an attachment or placeholder. If we want to force a title (in the case of recommended attachments)
export const AttachmentCard = ({ title, attachment, onAddAttachment, onRemoveAttachment, forceTitle, showLabel = true }: AttachmentCardProps) => {
  const { error, downloadFile } = useFiles('user-uploads')

  const handleGetAttachment = (attachment: ProposalLink) => {
    if (attachment.internal) {
      // Download the file
      // Get the original filename and keep the extension:
      const fileParts = attachment.url.split('.')
      const fileExtension = fileParts.length > 1 ? fileParts.pop() : ''
      downloadFile({ fileKey: attachment.url.split('user-uploads/')[1], directDownload: false, fileNameOverride: attachment.title + '.' + fileExtension })
    } else {
      // Redirect to the external link
      openInNewTab(attachment.url)
    }
  }

  // If no attachment - render placeholder
  if (!attachment) {
    if (title) {
      return <VerticalFormGroup
        formLabel={showLabel ? <FormLabel labelText={title} /> : <></>}
        input={<ProposalAttachmentPlaceholder forceTitle={forceTitle} addAttachment={onAddAttachment} />}
      />
    } else {
      return <ProposalAttachmentPlaceholder forceTitle={forceTitle} addAttachment={onAddAttachment} />
    }
  } else {
    // Otherwise show the attachment and allow us to remove it if we want to
    return <>
      <VerticalFormGroup
        formLabel={showLabel ? <FormLabel labelText={title ?? attachment.title} /> : <></>}
        input={<Card color='DARK'>
          <div className='flex p-2 justify-between items-center'>
            <div className='flex text-center justify-center sm:text-left flex-wrap gap-4 items-center cursor-pointer hover:underline' onClick={() => handleGetAttachment(attachment)}>
              <Card color='WHITE' >
                <Icon icon={attachment.internal ? FileText : Link} className='size-6' />
              </Card>
              <FormLabel labelText={attachment.title} helperText={error} />
            </div>
            { onRemoveAttachment && <Icon icon={X} className='cursor-pointer p-1' onClick={onRemoveAttachment} confirmTextHeader='Remove document' confirmTextBody='Are you sure you want to remove this document? This action cannot be undone.' /> }
          </div>
        </Card>}
      />
    </>
  }
}
