import { type Company, getCompanyEstimateEmailTemplate } from '../../../../../code/models/company'
import React from 'react'
import { Section } from '../../../../../components/containers/section'
import { Tiptap } from '../../../../../components/inputs_and_selections/tiptap'
import {
  TTPlaceholderCarbonSaving,
  TTPlaceholderCompanyName,
  TTPlaceholderCompanyPhone,
  TTPlaceholderCustomerAddress,
  TTPlaceholderCustomerName,
  TTPlaceholderHeatPumpModel,
  TTPlaceholderViewEstimate
} from '../../../../../code/tiptap_placeholders'

export const SendEstimateEmailTemplateBlock = ({ company, setCompany }: {
  company: Company
  setCompany: (c: Company) => void
}) => {
  return <Section border={true} title="Send estimate email template">
    <div className='flex flex-col gap-4'>
      <div className='space-y-2'>
        <Tiptap
          editable={true}
          className='w-full rounded border border-gray-300 p-2 focus:outline-none'
          onUpdateCallback={async (editor) => {
            setCompany({
              ...company,
              estimate_template: JSON.stringify(editor.getJSON())
            })
          }}
          placeholders={[
            TTPlaceholderViewEstimate,
            TTPlaceholderCustomerName,
            TTPlaceholderCustomerAddress,
            TTPlaceholderHeatPumpModel,
            TTPlaceholderCarbonSaving,
            TTPlaceholderCompanyName,
            TTPlaceholderCompanyPhone
          ]}
          content={getCompanyEstimateEmailTemplate(company.estimate_template || '')}
        />
      </div>
    </div>
  </Section>
}
