import React from 'react'
import { Heading } from '../../../components/content_display/heading'

export const HeadingDemo = () => {
  return (
    <div className="flex flex-col gap-8">
      <div className='flex flex-col gap-4'>
        <div className='text-2xl font-bold text-gray-900'>Definition</div>

        <pre className='bg-gray-100 rounded-lg p-4 overflow-x-auto'>
          <code>{codeBlock}</code>
        </pre>

        <div className='flex flex-col divide-y divide-dashed divide-gray-200 text-sm'>
          {componentProps.map(x => (
            <div key={x.name} className='grid grid-cols-2 py-2'>
              <div className='font-bold text-gray-900'>{x.name}</div>
              <div>{x.description}</div>
            </div>
          ))}
        </div>
      </div>

      <div className='flex flex-col gap-4'>
        <div className='text-2xl font-bold text-gray-900'>Component</div>
        <div>Sizes</div>
        <div className='flex flex-col justify-start items-start gap-4'>
          <Heading size="md">Medium heading (h5)</Heading>
          <Heading size="lg">Large heading (h4)</Heading>
          <Heading size="xl">Extra large heading (h3)</Heading>
          <Heading size="2xl">Double extra large heading (h2)</Heading>
          <Heading size="3xl">Triple extra large heading (h1)</Heading>
        </div>

        <div>Custom class example</div>
        <Heading size="2xl" className="bg-yellow-300 rounded italic">
          Custom styled heading
        </Heading>
      </div>
    </div>
  )
}

const componentProps = [
  { name: 'children', description: 'React.ReactNode or string to be rendered as heading content' },
  { name: 'size', description: 'md | lg | xl | 2xl | 3xl - Determines both the heading level and text size' },
  { name: 'className', description: 'Additional CSS classes to apply (optional)' }
]

const codeBlock = `<Heading
  size="2xl"
>
  Hello World
</Heading>`
