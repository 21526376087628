import {
  BuiltFormDetachedIcon,
  BuiltFormMidTerraceIcon,
  BuiltFormSemiDetachedIcon
} from '../../assets/images/domain_icons/domain_icons'

export type BuiltForm = 'Detached' | 'Semi-Detached' | 'Mid-Terrace' | 'End-Terrace'

export type BuiltFormObject = {
  icon: any // TODO: not sure how to type this properly
  name: string
  uuid: BuiltForm
  numberOfPartyWalls: number
}

export const BUILT_FORM_TYPES: BuiltFormObject[] = [
  { icon: BuiltFormDetachedIcon, name: 'Detached', uuid: 'Detached', numberOfPartyWalls: 0 },
  { icon: BuiltFormSemiDetachedIcon, name: 'Semi-Detached', uuid: 'Semi-Detached', numberOfPartyWalls: 1 },
  { icon: BuiltFormMidTerraceIcon, name: 'End-Terrace', uuid: 'End-Terrace', numberOfPartyWalls: 1 },
  { icon: BuiltFormMidTerraceIcon, name: 'Mid-Terrace', uuid: 'Mid-Terrace', numberOfPartyWalls: 2 }
]
