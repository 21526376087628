import React, { type ChangeEvent, useEffect, useRef, useState } from 'react'
import { FormLabel } from './form_label'
import { Textarea } from '@headlessui/react'

const sizes = {
  SM: 'text-xs p-2.5',
  MD: 'text-sm px-4 py-2',
  LG: 'px-4 py-3'
}

export type TextAreaProps = {
  value: string
  setValue: (value: string) => void
  label?: string
  placeholder?: string
  size?: 'SM' | 'MD' | 'LG'
  validator?: (e: string) => { message: string, value: number | string | undefined }
  className?: string
  disabled?: boolean
  readonly?: boolean
  rows?: number
}
export const TextArea = ({ validator, value, setValue, label, placeholder, size = 'MD', className, rows, disabled, readonly }: TextAreaProps) => {
  const validation = validator?.(value)
  const isInvalid = validator && !validation?.value

  const [cursor, setCursor] = useState(0)
  const ref = useRef<HTMLTextAreaElement>(null)

  // Save the cursor position, not handled automatically by React for async updates (to Dexie)
  useEffect(() => {
    const input = ref.current
    if (input) {
      input.setSelectionRange(cursor, cursor)
    }
  }, [ref, cursor, value])

  const handleChange = (e: ChangeEvent<HTMLTextAreaElement>) => {
    setCursor(e.target.selectionStart)
    setValue && setValue(e.currentTarget.value)
  }

  return (
    <div className='flex flex-col space-y-1 w-full'>
      {label && <FormLabel labelText={label} size="SM" />}
      <Textarea
        ref={ref}
        data-cy="textarea"
        placeholder={placeholder}
        className={`outline-none w-full ${sizes[size]} ${className} ${disabled ? 'bg-gray-100 border-gray-300 text-gray-500' : 'bg-white text-gray-600'} placeholder:text-gray-500 rounded-lg border ${isInvalid ? 'border-red-500 text-red-800' : 'border-gray-300'}`}
        onChange={handleChange}
        value={value}
        disabled={disabled ?? false}
        readOnly={readonly ?? false}
        rows={rows ?? 4}
      />
      {isInvalid && <div className="text-red-700 text-sm">{validation?.message}</div>}
    </div>
  )
}
