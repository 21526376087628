import React from 'react'
import { type Company } from '../../../../code/models/company'
import { SettingsLayout } from '../components/settings_layout'
import { NewQuoteEmailTemplateBlock } from './blocks/new_quote_email_template_block'

export const EmailTemplatesNewQuote = ({ company, setCompany }: { company: Company, setCompany: (c: Company) => void }) => {
  return <SettingsLayout
    heading='New quote email template'
    description='Customise the email template that is sent to customers when you send them a quote.'
  >
    <NewQuoteEmailTemplateBlock company={company} setCompany={setCompany} />
  </SettingsLayout>
}
