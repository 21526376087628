import React, { type Dispatch, type SetStateAction } from 'react'
import { Button } from '../../../../components/buttons/button'
import { Input } from '../../../../components/inputs_and_selections/input'
import { validateInMm, validateIsPositiveNumber, validateNotNull } from '../../../../code/validators'
import { type RadiatorModel } from '../../../../code/models/radiator_model'

type CustomRadiatorPageProps = {
  tempCustomRadiatorModel: RadiatorModel
  setTempCustomRadiatorModel: Dispatch<SetStateAction<RadiatorModel>>
  setSelectedCustomRadiatorModel: Dispatch<SetStateAction<RadiatorModel | undefined>>
  setCustomRadiatorModels: Dispatch<SetStateAction<RadiatorModel[]>>
  onBack: () => void
}

export const AddCustomRadiatorPage = ({ tempCustomRadiatorModel, setTempCustomRadiatorModel, setSelectedCustomRadiatorModel, setCustomRadiatorModels, onBack }: CustomRadiatorPageProps) => {
  return <div className="flex flex-col">
    <div className='flex flex-col p-5 gap-5 overflow-y-auto'>
      {/*  TODO later: add tabs: two methods - data entry (existing) and select type then enter dimensions and calculate */}
      <div className='flex flex-col gap-2'>
        <div className='text-gray-900 font-bold'>Type</div>
        <Input
          value={tempCustomRadiatorModel.type}
          setValue={(e) => setTempCustomRadiatorModel(prev => ({ ...prev, type: e }))}
          validator={validateNotNull}
        />
      </div>
      <div className='flex flex-col gap-2'>
        <div className='font-bold text-gray-900'>Height</div>
        <Input
          type='number'
          postfix='mm'
          step={100}
          value={tempCustomRadiatorModel.height_mm.toString()}
          setValue={(e) => setTempCustomRadiatorModel(prev => ({ ...prev, height_mm: parseFloat(e) }))}
          validator={validateInMm}
        />
      </div>
      <div className='flex flex-col gap-2'>
        <div className='font-bold text-gray-900'>Width</div>
        <Input
          type='number'
          postfix='mm'
          step={100}
          value={tempCustomRadiatorModel.width_mm.toString()}
          setValue={(e) => setTempCustomRadiatorModel(prev => ({ ...prev, width_mm: parseFloat(e) }))}
          validator={validateInMm}
        />
      </div>
      <div className='flex flex-col gap-2'>
        <div className='font-bold text-gray-900'>Nominal output at delta T 50°C</div>
        <Input
          type='number'
          postfix='W'
          step={1}
          value={tempCustomRadiatorModel.output_capacity_w_at_50_c.toString()}
          setValue={(e) => setTempCustomRadiatorModel(prev => ({ ...prev, output_capacity_w_at_50_c: parseFloat(e) }))}
          validator={(e) => validateIsPositiveNumber(e)}
        />
      </div>
    </div>
    {/* TODO: should we stop people from adding models with the same attributes? Or too wierd a case to bother for? */}
    <div className='p-4 bg-white'>
      <Button
        disabled={
          !validateNotNull(tempCustomRadiatorModel.type).value ||
              !validateInMm(tempCustomRadiatorModel.height_mm.toString()).value ||
              !validateInMm(tempCustomRadiatorModel.width_mm.toString()).value ||
              !validateIsPositiveNumber(tempCustomRadiatorModel.output_capacity_w_at_50_c.toString()).value
        }
        onClick={() => {
          const selectedCustomRadiatorModel = {
            ...tempCustomRadiatorModel,
            uuid: tempCustomRadiatorModel.uuid ?? crypto.randomUUID()
          }
          setSelectedCustomRadiatorModel(selectedCustomRadiatorModel)
          setCustomRadiatorModels(prev => {
            return tempCustomRadiatorModel.uuid // temp model only has uuid if editing
              ? prev.map(x => x.uuid === selectedCustomRadiatorModel.uuid ? selectedCustomRadiatorModel : x) // editing
              : [...prev, selectedCustomRadiatorModel] // add new
          })
          setTempCustomRadiatorModel({ ...tempCustomRadiatorModel, uuid: undefined }) // clear uuid of temp model but keep other characteristics so user can add another similar model
          onBack()
        }}>Apply</Button>
    </div>
  </div>
}
