import React, { type PropsWithChildren } from 'react'
import { FormLabel } from '../../../../components/inputs_and_selections/form_label'

type SettingsLayoutProps = PropsWithChildren<{
  heading: string
  description?: string
}>

export const SettingsLayout = ({ heading, description, children }: SettingsLayoutProps) => {
  return <div className='flex flex-col gap-6'>
    <FormLabel size='XL' labelText={heading} helperText={description} />
    {children}
  </div>
}
