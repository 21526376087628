import React, { type PropsWithChildren } from 'react'

type Size = 'XS' | 'SM' | 'MD' | 'LG'

type TextProps = {
  children: React.ReactNode | string
  bold?: boolean
  className?: string
  size?: Size
}

const sizeToClass: Record<Size, string> = {
  XS: 'text-xs',
  SM: 'text-sm',
  MD: 'text-base',
  LG: 'text-lg'
}

export const Text: React.FC<PropsWithChildren<TextProps>> = ({ bold, children, className = '', size = 'MD' }) => {
  return <p className={`${sizeToClass[size]} ${bold ? 'font-bold text-bold' : 'text-default'} ${className}`}>
    {children}
  </p>
}
