import React from 'react'
import { SurveyEHSContext } from '../existing_heating_system'
import {
  getElectricityPencePerKwh,
  getHeatingFuel,
  getHeatingPencePerUnit,
  HEATING_FUELS, type HeatingFuel
} from '../../../../code/models/heating_fuel'
import { Icon } from '../../../../components/buttons/icon'
import { Input } from '../../../../components/inputs_and_selections/input'
import { XCircle } from 'lucide-react'

export const defaultTariffIsOverridden = (fuel: HeatingFuel, existingSystemPencePerUnitOverride: number | undefined) => {
  return existingSystemPencePerUnitOverride !== undefined && existingSystemPencePerUnitOverride !== fuel.default_tarrif
}

export const EHSEnergyUseBlock = () => {
  const ehsContext = React.useContext(SurveyEHSContext)
  const { survey, setSurvey } = ehsContext!

  const heatingFuelId = survey.existing_system_fuel_uuid
  const heatingFuel = getHeatingFuel(heatingFuelId)
  const consumptionFuelUnits = survey.existing_system_annual_consumption_kWh > 0 ? Math.round(survey.existing_system_annual_consumption_kWh / heatingFuel.kWh_per_unit) : ''
  const electricity = HEATING_FUELS.find(x => x.uuid === 'electricity')!

  return <>
    <div className="text-gray-900 text-xl font-bold">Energy use</div>
    <div className="flex-col gap-5 flex">
      <div className="flex-col gap-2 flex">
        <div className="text-gray-900 font-bold">{heatingFuel.name} annual consumption</div>
        <div className="text-gray-500 text-sm">E.g. from a recent bill or from the smart meter in-home display</div>
        <Input
          step={0.01}
          type='number'
          value={consumptionFuelUnits.toString()} // Careful - number entered in heating fuel unit but stored in kWh. Done to prevent errors in unit when the fuel is changed.
          setValue={(e) => setSurvey({
            ...survey,
            existing_system_annual_consumption_kWh: Number(e) * heatingFuel.kWh_per_unit
          })}
          postfix={heatingFuel.unit}/>
      </div>
      <div className="flex-col gap-2 flex">
        <div className="text-gray-900 font-bold">{heatingFuel.name} tariff</div>
        {/* Set and reset the electricity overwrides too if the existing heating system fuel is electricity */}
        <Input
          step={0.01}
          type='number'
          value={getHeatingPencePerUnit(heatingFuel, survey.existing_system_p_per_unit_override).toString()}
          setValue={(e) => setSurvey({ ...survey, existing_system_p_per_unit_override: Number(e), electricity_p_per_kwh_override: heatingFuelId === 'electricity' ? parseFloat(e) : survey.electricity_p_per_kwh_override })}
          postfix={<div className='flex items-center gap-2.5'>
            <div>{heatingFuel.penceUnit}</div>
            <div>{defaultTariffIsOverridden(heatingFuel, survey.existing_system_p_per_unit_override) && <Icon icon={XCircle} onClick={() => setSurvey(({ ...survey, existing_system_p_per_unit_override: undefined, electricity_p_per_kwh_override: heatingFuelId === 'electricity' ? undefined : survey.electricity_p_per_kwh_override }))} colour='text-gray-400' />}</div>
          </div>} />
      </div>
      {heatingFuelId !== 'electricity' && <div className="flex-col gap-2 flex">
        <div className="text-gray-900 font-bold">Electricity tariff</div>
        <Input
          step={0.01}
          type='number'
          value={getElectricityPencePerKwh(survey.electricity_p_per_kwh_override).toString()}
          setValue={(e) => setSurvey({ ...survey, electricity_p_per_kwh_override: Number(e) })}
          postfix={<div className='flex items-center gap-2.5'>
            <div>{electricity?.penceUnit}</div>
            <div>{defaultTariffIsOverridden(electricity, survey.electricity_p_per_kwh_override) &&
                    <Icon
                      icon={XCircle}
                      onClick={() => setSurvey(({ ...survey, electricity_p_per_kwh_override: undefined }))}
                      colour='text-gray-400' />}
            </div>
          </div>}/>
      </div>}
    </div>
  </>
}
