import React from 'react'

export const ceilingShapeAImg = (className: string) => <svg className={className} viewBox="0 0 186 134" fill="none" xmlns="http://www.w3.org/2000/svg">
  <rect transform="translate(0 3)" fill="white"/>
  <line y1="-0.5" x2="84" y2="-0.5" transform="matrix(1 0 0 -1 92 130)" stroke="#BE185D" strokeDasharray="2 1"/>
  <line y1="-0.5" x2="5" y2="-0.5" transform="matrix(1 -8.74228e-08 -8.74228e-08 -1 176 130)" stroke="#BE185D"/>
  <path d="M176 130L178.887 125L173.113 125L176 130ZM176.5 125.5L176.5 75L175.5 75L175.5 125.5L176.5 125.5Z" fill="#BE185D"/>
  <path d="M169.128 72V63.36H170.196V67.17H174.744V63.36H175.812V72H174.744V68.184H170.196V72H169.128ZM177.375 71.994L177.381 71.034L181.215 67.584C181.535 67.296 181.749 67.024 181.857 66.768C181.969 66.508 182.025 66.242 182.025 65.97C182.025 65.638 181.949 65.338 181.797 65.07C181.645 64.802 181.437 64.59 181.173 64.434C180.913 64.278 180.617 64.2 180.285 64.2C179.941 64.2 179.635 64.282 179.367 64.446C179.099 64.606 178.887 64.82 178.731 65.088C178.579 65.356 178.505 65.648 178.509 65.964H177.417C177.417 65.42 177.543 64.94 177.795 64.524C178.047 64.104 178.389 63.776 178.821 63.54C179.257 63.3 179.751 63.18 180.303 63.18C180.839 63.18 181.319 63.304 181.743 63.552C182.167 63.796 182.501 64.13 182.745 64.554C182.993 64.974 183.117 65.45 183.117 65.982C183.117 66.358 183.069 66.684 182.973 66.96C182.881 67.236 182.737 67.492 182.541 67.728C182.345 67.96 182.099 68.208 181.803 68.472L178.617 71.334L178.479 70.974H183.117V71.994H177.375Z" fill="#9D174D"/>
  <path d="M176 4L173.113 9L178.887 9L176 4ZM176.5 59L176.5 8.5L175.5 8.5L175.5 59L176.5 59Z" fill="#BE185D"/>
  <line x1="92" y1="3.5" x2="176" y2="3.49999" stroke="#BE185D" strokeDasharray="2 1"/>
  <line x1="176" y1="3.5" x2="181" y2="3.5" stroke="#BE185D"/>
  <path d="M29 130V67.4142L92 4.41421L155 67.4142V130H29Z" fill="#F3F4F6" stroke="#6B7280" strokeWidth="2"/>
  <line x1="28" y1="130.5" x2="10" y2="130.5" stroke="#4F46E5" strokeDasharray="2 1"/>
  <line x1="10" y1="130.5" x2="5" y2="130.5" stroke="#4F46E5"/>
  <path d="M9 130L6.11325 125L11.8868 125L9 130ZM8.5 125.5L8.5 107L9.5 107L9.5 125.5L8.5 125.5Z" fill="#4F46E5"/>
  <path d="M3.15891 104V95.36H4.22691V99.17H8.77491V95.36H9.84291V104H8.77491V100.184H4.22691V104H3.15891ZM13.1521 104V96.542L11.5261 97.526V96.332L13.1521 95.36H14.2381V104H13.1521Z" fill="#4338CA"/>
  <path d="M9 68L11.8868 73L6.11325 73L9 68ZM8.5 91L8.5 72.5L9.5 72.5L9.5 91L8.5 91Z" fill="#4F46E5"/>
  <line y1="-0.5" x2="18" y2="-0.5" transform="matrix(-1 0 0 1 28 68)" stroke="#4F46E5" strokeDasharray="2 1"/>
  <line y1="-0.5" x2="5" y2="-0.5" transform="matrix(-1 8.74228e-08 8.74228e-08 1 10 68)" stroke="#4F46E5"/>
</svg>
