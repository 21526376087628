import React, { useContext } from 'react'
import { EstimatePageInner } from '../../../../estimate_page'
import { type Lead } from '../../../../../code/models/lead'
import { type Estimate } from '../../../../../code/calculate_estimate'
import { estimateAndLeadToCalculatedEstimate } from '../../../../../code/models/calculated_estimate'
import { Section } from '../../components/section'
import { Alert } from '../../../../../components/indicators_and_messaging/alert'
import { type CompanyPublicInfo } from '../../../../../code/models/company'
import { AdminContext } from '../../../admin_layout'

type Props = {
  estimate: Estimate
  lead: Lead
  companyPublicInfo: CompanyPublicInfo
}

export const EstimatePreview = ({ estimate, lead, companyPublicInfo }: Props) => {
  const adminContext = useContext(AdminContext)

  const calculatedEstimate = estimateAndLeadToCalculatedEstimate(estimate, lead, adminContext.data)

  const notGrantEligible = (!lead.epc_scotland && (!lead.epcData?.lmkKey || estimate.busGrantEligibleReasons.some(x => !x.hasPassed)))

  return (
    <Section title="Estimate preview">
      <Alert type="INFO">This is a preview of the estimate that the customer will receive by email.</Alert>
      <div className="border border-light rounded overflow-hidden">
        <EstimatePageInner
          estimate={calculatedEstimate}
          companyPublicInfo={adminContext.data.company?.public_info ?? companyPublicInfo}
          isScottish={estimate.isScottish}
          grantEligible={!notGrantEligible}
          isPreview={true}
        />
      </div>
    </Section>
  )
}
