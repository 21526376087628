import { type AxiosError } from 'axios'
import React, { useContext } from 'react'
import { AppContext } from '../../app_context'
import { WrappedIcon } from '../buttons/wrapped_icon'
import { X } from 'lucide-react'

export type Toast = {
  colour: 'bg-red-500' | 'bg-yellow-500' | 'bg-blue-500' | 'bg-green-500'
  message: string | React.JSX.Element
}
export const toastError = (message: string): Toast => ({ colour: 'bg-red-500', message })
export const toastSuccess = (message: string): Toast => ({ colour: 'bg-green-500', message })

export const setError = (e: unknown) => {
  const axiosError = e as AxiosError<any>
  // if (axiosError.response?.status === 401) setIsLoggedIn("", setState);

  // let errorMessage = 'We had an issue fulfilling that request, please contact joseph@spruce.eco.'
  // if (axiosError.response?.data) {
  //   errorMessage = axiosError.response.data.detail?.length > 0
  //     ? axiosError.response.data.detail
  //     : <ul>{(axiosError.response.data.errors?.map(x => x.detail) as string[]).map(x => <li>{x}</li>)}</ul>
  // }

  // setState((prev) => ({ ...prev, toastState: toastError(errorMessage) }));
  if (axiosError.response?.status !== 401) throw e
}

export const ToastPage = () => {
  const { globalState, setGlobalState } = useContext(AppContext)

  return (
    <div
      style={{
        paddingTop: 'env(safe-area-inset-top)',
        paddingRight: 'env(safe-area-inset-right)',
        paddingBottom: 'env(safe-area-inset-bottom)',
        paddingLeft: 'env(safe-area-inset-left)'
      }}
      className="absolute top-0 right-0 flex"
    >
      {globalState.toastState && <ToastInner toast={globalState.toastState} setGlobalState={setGlobalState} />}
    </div>
  )
}

export const ToastInner = ({ toast, setGlobalState }: { toast: Toast, setGlobalState: (state: (prev: any) => any) => void }) => <div
  className={`${toast.colour} z-20 rounded m-2 px-4 py-2 break-all text-slate-50 flex justify-between space-x-4 items-center`}>
  <div>{toast.message}</div>
  <WrappedIcon onClick={() => { setGlobalState((prev) => ({ ...prev, toastState: null })) }} className="text-xl cursor-pointer" icon={X} />
</div>
