import * as Sentry from '@sentry/react'
import mixpanel from 'mixpanel-browser'
import React from 'react'
import { createRoot } from 'react-dom/client'
import 'react-spring-bottom-sheet/dist/style.css'
import './assets/index.css'
import { Main } from './main'
import { APIProvider } from '@vis.gl/react-google-maps'
import { Error500Page } from './pages/error_pages'

Sentry.init({
  enabled: ['production', 'staging'].includes(process.env.APP_ENV!),
  dsn: 'https://6689804d517b435c94d667f179c82da0@o4505555166101504.ingest.sentry.io/4505555167739904',
  environment: process.env.APP_ENV,
  integrations: [
    Sentry.replayIntegration(),
    Sentry.replayCanvasIntegration(),
    Sentry.browserTracingIntegration(),
    Sentry.browserProfilingIntegration()
  ],
  tracesSampleRate: 0.2,
  profilesSampleRate: 1,
  tracePropagationTargets: [process.env.API_URL!],
  replaysSessionSampleRate: 0.1,
  replaysOnErrorSampleRate: 1.0,
  release: process.env.GITHUB_SHA
})

const MainWithProfiler = Sentry.withProfiler(Main)

mixpanel.init('1a4f2e2269da83f0f43c6a6f7993e682', { persistence: 'localStorage' })

const root = createRoot(document.getElementById('root')!)
root.render(<APIProvider libraries={['places']} apiKey={process.env.GOOGLE_MAPS_API_KEY!}>
  <Sentry.ErrorBoundary showDialog={true} fallback={(e) => <Error500Page error={e.error} />}>
    <MainWithProfiler />
  </Sentry.ErrorBoundary>
</APIProvider>)

if ('serviceWorker' in navigator && ['production', 'staging', 'pr'].includes(process.env.APP_ENV!)) {
  window.addEventListener('load', () => {
    navigator.serviceWorker.register('/service-worker.js').then(registration => {
      console.log('SW registered: ', registration)
      registration.onupdatefound = () => {
        const newWorker = registration.installing
        if (!newWorker) return
        newWorker.onstatechange = () => {
          if (newWorker.state === 'installed' && navigator.serviceWorker.controller) {
            window.location.reload()
          }
        }
      }
    }).catch(registrationError => {
      console.log('SW registration failed: ', registrationError)
    })
  })
}

// If you mess up the service worker it will remain on the target machine even if you remove it, use this code to enforce removal on page load.
// const getRidOfAllServiceWorkers = async () => {
//   const registry = await navigator.serviceWorker.getRegistrations()
//   for (const entry of registry) {
//     entry.unregister()
//   }
// }

// getRidOfAllServiceWorkers()
