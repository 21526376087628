import React, { useEffect, useState } from 'react'
import { apiUrl, client } from '../code/axios'
import { logEvent } from '../code/log_event'
import { isValidEmail, isValidPassword, isValidPhone } from '../code/validators'
import { Button } from '../components/buttons/button'
import { Input } from '../components/inputs_and_selections/input'
import { Section } from '../components/containers/section'
import { Map, Marker } from '@vis.gl/react-google-maps'
import { signUp } from '../code/models/auth'
import { AuthSDK } from '../code/utils/auth_provider'

type ChangedFields = {
  email?: boolean
  password?: boolean
  passwordRepeated?: boolean
  firstName?: boolean
  lastName?: boolean
  companyName?: boolean
  companyPhone?: boolean
  companyAddress?: boolean
  stripeCustomerId?: boolean
}

export const SignUpPage = () => {
  const [email, setEmail] = useState('')
  const [password, setPassword] = useState('')
  const [passwordRepeated, setPasswordRepeated] = useState('')
  const [firstName, setFirstName] = useState('')
  const [lastName, setLastName] = useState('')
  const [companyName, setCompanyName] = useState('')
  const [companyPhone, setCompanyPhone] = useState('')
  const [companyAddress, setCompanyAddress] = useState('')
  const [logo, setLogo] = useState('')
  const [stripeCustomerId, setstripeCustomerId] = useState('')

  const [emailValid, setEmailValid] = useState(true)
  const [passwordValid, setPasswordValid] = useState(true)
  const [passwordRepeatedValid, setPasswordRepeatedValid] = useState(true)
  const [firstNameValid, setFirstNameValid] = useState(true)
  const [lastNameValid, setLastNameValid] = useState(true)
  const [companyNameValid, setCompanyNameValid] = useState(true)
  const [companyPhoneValid, setCompanyPhoneValid] = useState(true)
  // we use companyAddressValid == null to store uncertain state.
  // In this state we don't know if the address is valid or not
  // and we don't show any error message
  const [companyAddressValid, setCompanyAddressValid] = useState<boolean>()
  const [logoValid, setLogoValid] = useState(true)
  const [stripeCustomerIdValid, setstripeCustomerIdValid] = useState(true)

  // to delay user input for address
  const [mapCenter, setMapCenter] = useState({ lat: 0, lng: 0 })

  const [changed, setChanged] = useState<ChangedFields>({
    email: false,
    password: false,
    passwordRepeated: false,
    firstName: false,
    lastName: false,
    companyName: false,
    companyPhone: false,
    companyAddress: false,
    stripeCustomerId: false
  })

  const isValid = () => {
    return emailValid &&
      passwordValid &&
      passwordRepeatedValid &&
      firstNameValid &&
      lastNameValid &&
      companyNameValid &&
      companyPhoneValid &&
      companyAddressValid &&
      logoValid &&
      stripeCustomerIdValid
  }

  const handleLogoChange = async (event) => {
    const file = event.target.files[0]
    if (file) {
      const image = new Image()
      image.onload = () => {
        const maxWidth = 640
        const maxHeight = 480
        let width = image.width
        let height = image.height

        // Calculate the scale factor
        const widthScale = maxWidth / width
        const heightScale = maxHeight / height
        const scaleFactor = Math.min(widthScale, heightScale)

        // If the image is larger than the max dimensions, resize it
        if (scaleFactor < 1) {
          width *= scaleFactor
          height *= scaleFactor
        }

        // Draw the resized image on the canvas
        const canvas = document.createElement('canvas')
        canvas.width = width
        canvas.height = height
        const ctx = canvas.getContext('2d')
        ctx!.drawImage(image, 0, 0, width, height)

        // Convert canvas to base64
        canvas.toBlob(blob => {
          const reader = new FileReader()
          reader.onloadend = () => {
            setLogo(reader.result?.toString() ?? '')
          }
          reader.readAsDataURL(blob!)
        })
      }
      image.src = URL.createObjectURL(file)
    }
  }

  const handleAddressChange = (e) => {
    const newAddress = e
    setCompanyAddress(newAddress)
    setChanged({ ...changed, companyAddress: true })
    // we use null to store uncertain state.
    // In this state we don't know if the address is valid or not
    // and we don't show any error message
    setCompanyAddressValid(undefined)
  }

  const updateDelayedAddress = (address) => {
    const result = async () => {
      if (address === '') return

      // check if address is valid and exists
      const response = await client.get(`${apiUrl}geocode?address=${encodeURIComponent(address)}`)
      if (response?.data?.lat && response.data.lng) {
        setCompanyAddressValid(true)
        setMapCenter(response.data)
      } else {
        setCompanyAddressValid(false)
        setMapCenter({ lat: 0, lng: 0 })
      }
    }
    result()
  }

  const onSubmit = async () => {
    if (!isValid()) return
    logEvent({ name: 'Sign Up Posted', properties: {} }, null)
    const response = await signUp(email, password, firstName, lastName, companyName, companyPhone, companyAddress, mapCenter, logo, stripeCustomerId)
    await AuthSDK.signIn(email, password)
    if (response?.status === 200) {
      window.location.href = '/' + response.data.company.subdomain + '/admin'
    } else {
      // FIXME: show error message
      alert('Something went wrong. Please try again.')
    }
  }

  useEffect(() => {
    setEmailValid(isValidEmail(email))
    setPasswordValid(isValidPassword(password))
    setPasswordRepeatedValid(password === passwordRepeated)
    setFirstNameValid(firstName.length > 0)
    setLastNameValid(lastName.length > 0)
    setCompanyNameValid(companyName.length > 0)
    setCompanyPhoneValid(isValidPhone(companyPhone))
    setLogoValid(logo.length > 0)
    setstripeCustomerIdValid(stripeCustomerId.length > 0)
  }, [email, password, passwordRepeated, firstName, lastName, companyName, companyPhone, logo, stripeCustomerId])

  useEffect(() => {
    const timer = setTimeout(() => {
      updateDelayedAddress(companyAddress)
    }, 800)

    return () => { clearTimeout(timer) }
  }, [companyAddress])

  return <div className="flex flex-col items-center justify-center mt-10 max-w-3xl mx-auto">
    <Section border={true} title="Sign up">
      <div className="space-y-4">
        <div className="space-y-2">
          <div className="font-semibold">Email</div>
          <Input onEnter={onSubmit} type="email" value={email} setValue={(e) => {
            setEmail(e)
            setChanged({ ...changed, email: true })
          }} placeholder="Enter your email address" />
        </div>
        {changed.email && !emailValid && <span className="text-red-500">Invalid email address</span>}

        <div className="space-y-2">
          <div className="font-semibold">Password</div>
          <Input onEnter={onSubmit} type="password" value={password} setValue={(e) => {
            setPassword(e)
            setChanged({ ...changed, password: true })
          }} placeholder="Enter your password" />
        </div>
        {changed.password && !passwordValid && <span className="text-red-500">
          Invalid password. <br /> Must be at least 8 characters long and contain at least one letter and one number.
        </span>}

        <div className="space-y-2">
          <div className="font-semibold">Repeat your password</div>
          <Input onEnter={onSubmit} type="password" value={passwordRepeated} setValue={(e) => {
            setPasswordRepeated(e)
            setChanged({ ...changed, passwordRepeated: true })
          }} placeholder="Enter your password again" />
        </div>
        {changed.passwordRepeated && !passwordRepeatedValid && <span className="text-red-500">Password is not the same</span>}

        <div className="flex space-x-4">
          <div className="space-y-2 w-1/2">
            <div className="font-semibold">First Name</div>
            <Input onEnter={onSubmit} type="text" value={firstName} setValue={(e) => {
              setFirstName(e)
              setChanged({ ...changed, firstName: true })
            }} placeholder="Enter your first name" />
            {changed.firstName && !firstNameValid && <span className="text-red-500">Invalid first name</span>}
          </div>

          <div className="space-y-2 w-1/2">
            <div className="font-semibold">Last Name</div>
            <Input onEnter={onSubmit} type="text" value={lastName} setValue={(e) => {
              setLastName(e)
              setChanged({ ...changed, lastName: true })
            }} placeholder="Enter your last name" />
            {changed.lastName && !lastNameValid && <span className="text-red-500">Invalid last name</span>}
          </div>
        </div>

        <div className="space-y-2">
          <div className="font-semibold">Company name</div>
          <Input onEnter={onSubmit} type="text" value={companyName} setValue={(e) => {
            setCompanyName(e)
            setChanged({ ...changed, companyName: true })
          }} placeholder="Enter your company name" />
        </div>
        {changed.companyName && !companyNameValid && <span className="text-red-500">Invalid name</span>}

        <div className="space-y-2">
          <div className="font-semibold">Company phone</div>
          <Input onEnter={onSubmit} type="text" value={companyPhone} setValue={(e) => {
            setCompanyPhone(e)
            setChanged({ ...changed, companyPhone: true })
          }} placeholder="Enter your company phone number" />
        </div>
        {changed.companyPhone && !companyPhoneValid && <span className="text-red-500">Invalid phone</span>}

        <div className="space-y-2">
          <div className="font-semibold">Company address</div>
          <Input onEnter={onSubmit} type="text" value={companyAddress} setValue={(e) => {
            handleAddressChange(e)
            setChanged({ ...changed, companyAddress: true })
          }} placeholder="Enter your company address" />
        </div>
        {changed.companyAddress && companyAddressValid === false && <span className="text-red-500">Invalid address</span>}
        {changed.companyAddress && companyAddressValid && mapCenter.lat !== 0 && mapCenter.lng !== 0 &&
          <Map
            center={mapCenter}
            className="min-h-[200px] w-full h-full"
            zoom={13}
          >
            <Marker position={mapCenter} />
          </Map>
        }

        <div className="space-y-2">
          <div className="font-semibold">Stripe customer ID</div>
          <Input onEnter={onSubmit} type="text" value={stripeCustomerId} setValue={(e) => {
            setstripeCustomerId(e)
            setChanged({ ...changed, stripeCustomerId: true })
          }} placeholder="cus_..." />
        </div>
        {changed.stripeCustomerId && !stripeCustomerIdValid && <span className="text-red-500">Invalid Stripe customer ID</span>}

        <div className="space-y-2">
          <div className="font-semibold">Company Logo</div>
          <input type="file" onChange={handleLogoChange} accept="image/*" />
        </div>
        {changed.email &&
          changed.password &&
          changed.passwordRepeated &&
          changed.firstName &&
          changed.lastName &&
          changed.companyName &&
          changed.companyPhone &&
          changed.companyAddress &&
          changed.stripeCustomerId &&
          !logo && <span className="text-red-500">Add logo</span>}

        {/* FIXME: add captcha? */}

        <Button disabled={!isValid()} block={true} onClick={onSubmit}>Sign Up</Button>
      </div>
    </Section>
  </div>
}
