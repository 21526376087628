import React, { useEffect, useState } from 'react'
import { SettingsLayout } from '../components/settings_layout'
import { Section } from '../../../../components/containers/section'
import { Button } from '../../../../components/buttons/button'
import { Text } from '../../../../components/content_display/text'

export const AccountTools = () => {
  const [databaseExists, setDatabaseExists] = useState(false)

  useEffect(() => {
    const main = async () => {
      const check = await checkDatabaseExists('spruce')
      setDatabaseExists(check)
    }
    main()
  }, [])

  return <SettingsLayout
    heading="Developer tools"
    description=""
  >
    <Section border title='Reset local database'>
      <div className="flex flex-col gap-6">
        <Text size='SM'>Warning, this will delete any local data on your device that has not synced with the server. Use this only if your device is failing to sync with the server and you are happy with any local data loss.</Text>
        <div className="flex">
          <Button
            confirmText="Reset local database"
            disabled={!databaseExists}
            onClick={() => {
              const request = indexedDB.deleteDatabase('spruce')
              request.onsuccess = () => {
                setDatabaseExists(false)
                localStorage.setItem('last_synced', '0')
              }
            }}
          >Reset local database</Button>
        </div>
      </div>
    </Section>
  </SettingsLayout>
}

const checkDatabaseExists = (dbName: string): Promise<boolean> => {
  return new Promise((resolve, reject) => {
    const request = indexedDB.open(dbName)

    request.onsuccess = function (event) {
      (event.target as any).result.close()
      resolve(true)
    }

    request.onupgradeneeded = function (event) {
      (event.target as any).transaction.abort()
      resolve(false)
    }

    request.onerror = function () {
      reject(request.error)
    }
  })
}
