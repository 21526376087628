import { z } from 'zod'
import { apiUrl, axiosGetV2, axiosPatchV2, axiosPostV2, client } from '../axios'
import { parseWithZod } from '../zod'
import { AuthSDK } from '../utils/auth_provider'
import { QuoteItemSchema } from '../calculate_quote'
import { type HeatLossReportSnapshot } from './heatloss_report'
import { ProposalLinkSchema } from './proposal_link'

export const ProposalQuoteItemSchema = QuoteItemSchema.extend({
  proposal_uuid: z.string().uuid(),
  order: z.number(),
  updated_at: z.coerce.date(),
  created_at: z.coerce.date()
})

export const CustomerFacingQuoteItemSchema = z.object({
  uuid: z.string().uuid(),
  name: z.string(),
  description: z.string().optional(),
  group_name: z.string(),
  quantity: z.number(),
  selected: z.boolean(),
  image_url: z.string().optional(),
  subtotal: z.number().optional()
})

export const ProposalPaymentScheduleSchema = z.object({
  name: z.string(),
  description: z.string().optional(),
  percentage: z.coerce.number(),
  due_date: z.coerce.date().optional().nullable()
})

export const QuoteSnapshotDataSchema = z.object({
  quote_items: z.array(CustomerFacingQuoteItemSchema),
  total_pre_deductions: z.number(),
  discount_total: z.number(),
  vat_on_all: z.number(),
  vat_on_survey: z.number(),
  bus_grant: z.number(),
  group_totals: z.record(z.string(), z.number()),
  payment_schedule: z.array(ProposalPaymentScheduleSchema).optional(),
  additional_notes: z.string().optional()
})

export const QuoteSnapshotSchema = z.object({
  uuid: z.string().uuid().optional(),
  snapshot: QuoteSnapshotDataSchema,
  recipients: z.array(z.string()).optional(),
  email_text: z.string().optional()
})

export const ProposalSnapshotSchema = z.object({
  quote: QuoteSnapshotDataSchema.optional(),
  // TODO: redefine to HeatLossReportSnapshot
  report: z.record(z.unknown()).optional()
})

export const ProposalSchema = z.object({
  uuid: z.string().uuid(),
  lead_uuid: z.string().uuid(),
  installer_status: z.number(),
  sent_at: z.coerce.date().nullable().optional(),
  show_cost: z.boolean(),
  include_vat_on_all: z.boolean(),
  include_vat_on_survey: z.boolean(),
  valid_days: z.number().optional().nullable(),
  cover_note: z.string(), // a stringified JSON
  contents_bitmask: z.number(),
  additional_notes: z.string().optional().nullable(), // stringified JSON

  recipients: z.array(z.string()).optional().nullable(),
  email_text: z.string().optional().nullable(),

  snapshot: ProposalSnapshotSchema,
  payment_schedule: z.array(ProposalPaymentScheduleSchema).optional().nullable(),

  acceptance_customer_name: z.string().nullable().optional(),
  acceptance_customer_date: z.coerce.date().nullable().optional(),
  acceptance_status: z.number().nullable().optional(),
  acceptance_changed_at: z.coerce.date().nullable().optional(),

  quote_items: z.array(ProposalQuoteItemSchema),

  links: z.array(ProposalLinkSchema).optional().nullable()
})

export const ProposalSignRequestSchema = z.object({
  acceptance_customer_name: z.string(),
  acceptance_customer_date: z.string()
})

export type ProposalQuotePublic = {
  uuid?: string
  snapshot: HeatLossReportSnapshot
}

export type Proposal = z.infer<typeof ProposalSchema>
export type ProposalSnapshot = z.infer<typeof ProposalSnapshotSchema>
export type ProposalQuoteItem = z.infer<typeof ProposalQuoteItemSchema>
export type ProposalPaymentSchedule = z.infer<typeof ProposalPaymentScheduleSchema>
export type QuoteSnapshot = z.infer<typeof QuoteSnapshotSchema>
export type QuoteSnapshotData = z.infer<typeof QuoteSnapshotDataSchema>
export type CustomerFacingQuoteItem = z.infer<typeof CustomerFacingQuoteItemSchema>
export type ProposalSignRequest = z.infer<typeof ProposalSignRequestSchema>

export const getDraftProposalAPI = async (leadUUID: string, companyUUID: string): Promise<Proposal | undefined> => {
  try {
    const result = await client.get(`${apiUrl}teams/${companyUUID}/leads/${leadUUID}/proposals/draft`, {
      headers: {
        'x-auth-token': AuthSDK.getToken()
      }
    })
    const parsedResult = parseWithZod<Proposal>(ProposalSchema, result.data)
    return parsedResult
  } catch (e: unknown) {
    console.error('Error getting draft proposal', e)
    return undefined
  }
}

export const updateProposalAPI = async (proposal: Proposal, leadUUID: string, companyUUID: string) => {
  await client.patch(`${apiUrl}teams/${companyUUID}/leads/${leadUUID}/proposals/${proposal.uuid}`, proposal, {
    headers: {
      'x-auth-token': AuthSDK.getToken()
    }
  })
}

export const sendProposalAPI = async (leadUUID: string, companyUUID: string, proposalUUID: string, email_html: string) => await axiosPostV2(`teams/${companyUUID}/leads/${leadUUID}/proposals/${proposalUUID}/send`, { email_html })
export const getProposalPublicAPI = async (reportUUID: string, companyUUID: string) => await axiosGetV2<Proposal>(`teams/${companyUUID}/proposals/${reportUUID}`)
export const getProposalPDFAPI = async (proposalUUID: string, companyUUID: string) => await axiosGetV2<Promise<Blob>>(`teams/${companyUUID}/proposals/${proposalUUID}/pdf`, { responseType: 'blob' })
export const getProposalPreviewPDFAPI = async (proposalUUID: string, companyUUID: string) => await axiosGetV2<Promise<Blob>>(`teams/${companyUUID}/proposals/${proposalUUID}/preview_pdf`, { responseType: 'blob' })

export const createQuoteSnapshotAPI = async (leadUUID: string, companyUUID: string, quoteSnapshot: QuoteSnapshot) => await axiosPostV2<{ uuid: string }>(`teams/${companyUUID}/leads/${leadUUID}/proposals/quote_snapshot`, quoteSnapshot)
export const sendQuoteAPI = async (leadUUID: string, companyUUID: string, quoteSnapshotUUID: string, email_html: string) => await axiosPostV2(`teams/${companyUUID}/leads/${leadUUID}/proposals/quote_snapshot/${quoteSnapshotUUID}/send`, { email_html })
export const getQuotePublicAPI = async (reportUUID: string, companyUUID: string) => await axiosGetV2<QuoteSnapshot>(`teams/${companyUUID}/quotes/${reportUUID}`)
export const getQuotePDFAPI = async (quoteUUID: string, companyUUID: string) => await axiosGetV2<Promise<Blob>>(`teams/${companyUUID}/quotes/${quoteUUID}/pdf`, { responseType: 'blob' })
export const getQuotePreviewPDFAPI = async (quoteUUID: string, companyUUID: string) => await axiosGetV2<Promise<Blob>>(`teams/${companyUUID}/quotes/${quoteUUID}/preview_pdf`, { responseType: 'blob' })

export const signProposalAPI = async (proposalSignRequest: ProposalSignRequest, leadUUID: string, companyUUID: string, proposalUUID: string) => await axiosPatchV2(`teams/${companyUUID}/leads/${leadUUID}/proposals/${proposalUUID}/sign`, proposalSignRequest)
