import React, { useEffect, useState } from 'react'
import { type CompanyPublicInfo, getCompanyPublicInfoSilent } from './code/models/company'
import { AdminLayout } from './pages/admin/admin_layout'
import { LoginPage } from './pages/admin/login_page'
import { RemindPasswordPage } from './pages/admin/remind_password_page'
import { Components } from './pages/components/components'
import { Error404Page } from './pages/error_pages'
import { EstimatePage } from './pages/estimate_page'
import { SignUpPage } from './pages/sign_up_page'
import { SurveyPageWrapper } from './pages/survey/survey_page'
import { AcceptInvitationPage } from './pages/accept_invitation_page'
import { useNavigation } from './code/use_navigation'
import { match } from 'path-to-regexp'
import { AuthSDK } from './code/utils/auth_provider'
import { useLocalStorage } from 'usehooks-ts'
import { getUser } from './code/models/user'
import { HeatLossReportPublicPage } from './pages/heatloss_report_public_page'
import { QuotePublicPage } from './pages/quote_public_page'
import { ProposalPublicPage } from './pages/proposal_public_page'

export type RouteConfig = {
  path: string
  component: (params: any) => JSX.Element
}
export const Router = () => {
  const { currentPath, navigateTo } = useNavigation()
  const [subdomain, setSubdomain] = useLocalStorage<string | undefined>('subdomain', '')

  useEffect(() => {
    const fetchUser = async () => {
      if (!match('')(currentPath)) return
      const userUUID = AuthSDK.getTokenPayload()?.user_uuid

      if (userUUID && !subdomain) {
        const fetchedUser = await getUser()
        const subdomain = fetchedUser?.companies[0].subdomain
        setSubdomain(subdomain)
        navigateTo(`/${subdomain}/admin`)
        return
      }

      if (userUUID) {
        navigateTo(`/${subdomain}/admin`)
        return
      }

      navigateTo('/login')
    }

    fetchUser()
  }, [currentPath])

  const ROUTES: RouteConfig[] = [{
    path: '/login',
    component: () => <LoginPage navigateTo={navigateTo} />
  }, {
    path: '/remind-password',
    component: () => <RemindPasswordPage navigateTo={navigateTo} />
  }, {
    path: '/sign_up',
    component: () => <SignUpPage />
  }, {
    path: '/invitation/accept/:token',
    component: ({ token }) => {
      return <AcceptInvitationPage token={token} navigateTo={navigateTo} />
    }
  }, {
    path: '/components{/*subPath}',
    component: () => <Components currentPath={currentPath} navigateTo={navigateTo} />
  }, {
    path: '/:companySubdomain{/*subPath}',
    component: ({ companySubdomain }) => {
      const baseURL = `/${companySubdomain}`
      return <CompanyRouter companySubdomain={companySubdomain} currentPath={currentPath} navigateTo={navigateTo} baseURL={baseURL} />
    }
  }]

  const page = ROUTES.map(x => ({ evaluatedPath: match(x.path)(currentPath), component: x.component })).find(x => x.evaluatedPath)
  if (page?.evaluatedPath) return page.component(page.evaluatedPath.params)

  return <Error404Page />
}

type CompanyRouterProps = {
  companySubdomain: string
  currentPath: string
  baseURL: string
  navigateTo: (url: string) => void
}

const CompanyRouter = ({ companySubdomain, baseURL, currentPath, navigateTo }: CompanyRouterProps) => {
  const [companyPublicInfo, setCompanyPublicInfo] = useState<CompanyPublicInfo>()

  useEffect(() => {
    const main = async () => {
      const companyPublicInfo = await getCompanyPublicInfoSilent(companySubdomain)
      if (companyPublicInfo) setCompanyPublicInfo(companyPublicInfo)
    }
    main()
  }, [])
  if (!companyPublicInfo) return

  const ROUTES: RouteConfig[] = [{
    path: '',
    component: () => <SurveyPageWrapper navigateTo={navigateTo} companyPublicInfo={companyPublicInfo} showLogo={true} isAdmin={false} />
  }, {
    path: '/embed',
    component: () => <SurveyPageWrapper navigateTo={navigateTo} companyPublicInfo={companyPublicInfo} showLogo={false} isAdmin={false} />
  }, {
    path: '/estimate/:estimateId',
    component: ({ estimateId }) => {
      return <EstimatePage estimateId={estimateId} companyPublicInfo={companyPublicInfo} />
    }
  }, {
    path: '/report/:reportUUID',
    component: ({ reportUUID }) => {
      return <HeatLossReportPublicPage reportUUID={reportUUID} companyPublicInfo={companyPublicInfo} />
    }
  }, {
    path: '/quote/:quoteUUID',
    component: ({ quoteUUID }) => {
      return <QuotePublicPage quoteUUID={quoteUUID} companyPublicInfo={companyPublicInfo} />
    }
  }, {
    path: '/proposal/:proposalUUID',
    component: ({ proposalUUID }) => {
      return <ProposalPublicPage proposalUUID={proposalUUID} companyPublicInfo={companyPublicInfo} />
    }
  }, {
    path: '/admin{/*subPath}',
    component: () => {
      const basePath = `${baseURL}/admin`
      return <AdminLayout companyPublicInfo={companyPublicInfo} basePath={basePath} currentPath={currentPath} navigateTo={navigateTo} />
    }
  }]

  const page = ROUTES.map(x => ({ evaluatedPath: match(x.path)(currentPath.replace(baseURL, '')), component: x.component })).find(x => x.evaluatedPath)
  if (page?.evaluatedPath) return page.component(page.evaluatedPath.params)

  return <Error404Page />
}
