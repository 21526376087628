import React, { useState } from 'react'
import { SurveyEHSContext } from '../existing_heating_system'
import { HEATING_FUELS } from '../../../../code/models/heating_fuel'
import {
  getHeatingSystemEfficiency,
  HEATING_SYSTEM_AGES,
  HEATING_SYSTEM_TYPES
} from '../../../../code/models/heating_system'
import { Select } from '../../../../components/inputs_and_selections/select'
import { FlowWrapperContext } from '../components/survey_section_flow_wrapper'
import { Badge } from '../../../../components/indicators_and_messaging/badge'
import { RadioGroup } from '../../../../components/inputs_and_selections/radio'
import { TextArea } from '../../../../components/inputs_and_selections/text_area'
import { Check } from 'lucide-react'

export const EHSDetailsBlock = () => {
  const ehsContext = React.useContext(SurveyEHSContext)
  const flowContext = React.useContext(FlowWrapperContext)

  const { survey, setSurvey } = ehsContext!

  const [heatingFuelId, setHeatingFuelId] = useState(survey.existing_system_fuel_uuid)

  const heatingSystemType = HEATING_SYSTEM_TYPES.find(x => x.uuid === survey.existing_system_type_uuid)
  const fuelsToIgnoreAgeFor = ['electricity', 'coal']

  const heatingSystemAge = HEATING_SYSTEM_AGES.find(x => x.name === survey.existing_system_age_uuid)

  return <>
    { flowContext!.viewMode === 'FLOW' && <div className="text-gray-900 text-xl font-bold">Existing system</div> }
    <div className="flex-col gap-5 flex">
      <div className="flex-col gap-2 flex">
        <div className="text-gray-900 font-bold">Fuel</div>
        <RadioGroup items={HEATING_FUELS.map(x => ({
          name: x.name,
          onClick: () => {
            setHeatingFuelId(x.uuid)
            setSurvey({
              ...survey,
              existing_system_fuel_uuid: x.uuid,
              existing_system_p_per_unit_override: undefined,
              existing_system_type_uuid: (fuelsToIgnoreAgeFor.includes(x.uuid) || fuelsToIgnoreAgeFor.includes(survey.existing_system_fuel_uuid) || (!heatingSystemType))
                ? ''
                : HEATING_SYSTEM_TYPES.filter(y => y.fuel_uuid === x.uuid && y.name === heatingSystemType.name)[0].uuid,
              existing_system_age_uuid: (fuelsToIgnoreAgeFor.includes(x.uuid) || fuelsToIgnoreAgeFor.includes(survey.existing_system_fuel_uuid))
                ? ''
                : survey.existing_system_age_uuid
            }) // Try to maintain e.g. combi boiler and age when going from mains gas to LGP and Oil. Only reset if going to or from electricity
          },
          variant: heatingFuelId === x.uuid ? 'ACTIVE' : 'DEFAULT'
        }))}/>
      </div>
      <div className="flex-col gap-2 flex">
        <div className='flex justify-between'>
          <div className="text-gray-900 font-bold">Type</div>
          <Badge color={survey.existing_system_type_uuid ? 'GREEN' : 'YELLOW'} text='Required'
            icon={survey.existing_system_type_uuid ? Check : undefined}/>
        </div>

        <Select
          selectedKey={survey.existing_system_type_uuid}
          setSelectedKey={(e) => setSurvey({ ...survey, existing_system_type_uuid: e })}
          options={HEATING_SYSTEM_TYPES.filter(x => x.fuel_uuid === heatingFuelId).map(x => ({
            key: x.uuid,
            value: x.name
          }))}
        />
      </div>
      {!fuelsToIgnoreAgeFor.includes(heatingFuelId) && <div className="flex-col gap-2 flex">
        <div className='flex justify-between'>
          <div className="text-gray-900 font-bold">Age of existing system</div>
          <Badge color={survey.existing_system_age_uuid ? 'GREEN' : 'YELLOW'} text='Required'
            icon={survey.existing_system_age_uuid ? Check : undefined}/>
        </div>
        <Select
          selectedKey={survey.existing_system_age_uuid}
          options={HEATING_SYSTEM_AGES.map(x => ({ key: x.name, value: `${x.name}: ${x.efficiency_percentage}%` }))}
          setSelectedKey={(e) => setSurvey({ ...survey, existing_system_age_uuid: e })}
        />
      </div>}
      <div className="flex-col gap-1 flex">
        <div className="text-gray-900 font-bold">Efficiency</div>
        <div className="text-gray-600">{getHeatingSystemEfficiency(heatingSystemType, heatingSystemAge) * 100}%</div>
      </div>
      <div className="flex-col gap-2 flex">
        <div className="text-gray-900 font-bold">Location</div>
        <TextArea value={survey.existing_system_location}
          setValue={(e) => setSurvey({ ...survey, existing_system_location: e })}/>
      </div>
    </div>
  </>
}
