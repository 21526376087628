import React from 'react'
import { type Company } from '../../../../code/models/company'
import { DeclineEnquiryEmailTemplateBlock } from './blocks/decline_enquiry_email_template_block'
import { SettingsLayout } from '../components/settings_layout'

export const EmailTemplatesDeclineEnquiry = ({ company, setCompany }: { company: Company, setCompany: (c: Company) => void }) => {
  return <SettingsLayout
    heading='Decline enquiry'
    description='Customise the email sent to a customer if you decline their enquiry'
  >
    <DeclineEnquiryEmailTemplateBlock company={company} setCompany={setCompany} />
  </SettingsLayout>
}
