import React from 'react'
import { SurveySiteDetailsContext } from '../site_details'
import { FlowWrapperContext } from '../components/survey_section_flow_wrapper'
import { Input } from '../../../../components/inputs_and_selections/input'
import { TextArea } from '../../../../components/inputs_and_selections/text_area'
import { Toggle } from '../../../../components/inputs_and_selections/toggle'

export const SiteDetailsMainsWaterDetailsBlock = () => {
  const prContext = React.useContext(SurveySiteDetailsContext)
  const flowContext = React.useContext(FlowWrapperContext)

  const { survey, setSurvey } = prContext!

  const isFullScreen = flowContext?.viewMode === 'FLOW'

  return <>
    { isFullScreen && <div className="text-gray-900 text-xl font-bold">Mains water</div> }
    <div className="flex-col gap-5 flex">
      <div className="items-center gap-3 flex justify-between">
        <div className="flex-col flex">
          <div className="text-gray-900 text-base font-bold">Stopcock works</div>
        </div>
        <Toggle size='LG' value={survey.stopcock_works}
          setValue={() => setSurvey({ ...survey, stopcock_works: !survey.stopcock_works })}/>
      </div>

      <div className="flex-col gap-2 flex">
        <div className="text-gray-900 font-bold">Stopcock location</div>
        <TextArea value={survey.stopcock_location} setValue={(e) => setSurvey({ ...survey, stopcock_location: e })}/>
      </div>

      <div className="flex-col gap-2 flex">
        <div className="text-gray-900 font-bold">Size of water main</div>
        <Input type='number' value={survey.size_of_water_main_mm.toString()}
          setValue={(e) => setSurvey({ ...survey, size_of_water_main_mm: Number(e) })}
          postfix={<span className='text-gray-500'>mm</span>}/>
      </div>

      <div className="flex-col gap-2 flex">
        <div className="text-gray-900 font-bold">Mains flow rate</div>
        <Input type='number' value={survey.mains_flow_rate_l_per_min.toString()}
          setValue={(e) => setSurvey({ ...survey, mains_flow_rate_l_per_min: Number(e) })}
          postfix={<span className='text-gray-500'>L/min</span>}/>
      </div>

      <div className="flex-col gap-2 flex">
        <div className="text-gray-900 font-bold">Mains pressure</div>
        <Input type='number' value={survey.mains_pressure_bar.toString()}
          setValue={(e) => setSurvey({ ...survey, mains_pressure_bar: Number(e) })}
          postfix={<span className='text-gray-500'>bar</span>}/>
      </div>
    </div>
  </>
}
