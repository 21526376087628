import { VerticalFormGroup } from '../../../../components/inputs_and_selections/vertical_form_group'
import { FormLabel } from '../../../../components/inputs_and_selections/form_label'
import { RadioGroup } from '../../../../components/inputs_and_selections/radio'
import { type Material } from '../../../../code/models/material'
import React from 'react'
import { type PropertySurvey } from '../../../../code/models/property'
import { floorCeilingTypeNameMap } from '../floor_attributes_block'
import { Info } from '../../../../components/buttons/info'

type FloorTypeSelectorProps = {
  floorMaterial: Material
  setFloorMaterial: (material: Material) => void
  survey: PropertySurvey
}

export const FloorTypeSelector = ({ floorMaterial, setFloorMaterial, survey }: FloorTypeSelectorProps) => {
  return (
    <>
      <VerticalFormGroup
        formLabel={<FormLabel
          labelText={'Floor type'}
          size={'SM'}
          info={FloorTypeInfo}
        />}
        input={<RadioGroup items={[
          {
            name: floorCeilingTypeNameMap['ground-floor'],
            onClick: () => setFloorMaterial(survey.default_materials!.groundFloor!),
            variant: floorMaterial.applicable_to === 'ground-floor' ? 'ACTIVE' : 'DEFAULT'
          },
          {
            name: floorCeilingTypeNameMap['intermediate-floor-and-ceiling'],
            onClick: () => setFloorMaterial(survey.default_materials!.intermediateFloorAndCeiling!),
            variant: floorMaterial.applicable_to === 'intermediate-floor-and-ceiling' ? 'ACTIVE' : 'DEFAULT'
          },
          {
            name: floorCeilingTypeNameMap['exposed-floor'],
            onClick: () => setFloorMaterial(survey.default_materials!.exposedFloor!),
            // Default exposed floor won't be set for surveys from before 25th September 2024 so fallback first on the first exposed floor material
            variant: floorMaterial.applicable_to === 'exposed-floor' ? 'ACTIVE' : 'DEFAULT'
          }
        ]} />}
      />
    </>
  )
}

export const FloorTypeInfo = <Info
  infoModalHeader={'Floor type'}
  infoModalBody={'Used to determine the temperature on the other side of the floor and the material options for the floor. ' +
                'Set the floor type to "Exposed floor" if the floor is above an adjoining property or unheated space. '}
/>
