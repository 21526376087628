import React, { type Dispatch, type SetStateAction, useState, useEffect } from 'react'
import { Button } from '../../components/buttons/button'
import { Input } from '../../components/inputs_and_selections/input'
import { type Room } from '../../code/models/room'
import { type Floor, getFloorAndCeilingDefaultMaterialsForFloor } from '../../code/models/floor'
import { ROOM_TYPES } from './constants'
import { type PropertySurvey } from '../../code/models/property'
import { DEFAULT_SURVEY_ROOM, DEFAULT_SURVEY_WALL } from '../../code/survey_defaults'
import { type UndoEvent } from './floor/floor_canvas/undo'
import { validateWallLength } from '../../code/validators'
import { type SprucePoint } from './floor/code/types'
import { Select } from '../../components/inputs_and_selections/select'
import { BottomSheetHeader } from '../../components/containers/bottom_sheet_header'
import { type CurrentFloorPlanPage } from './floor/floor'

type AddRoomTabProps = {
  floor: Floor
  startingX: number
  startingY: number
  survey: PropertySurvey
  setFloor: (floor: Floor) => void
  setPage: (page: CurrentFloorPlanPage) => void
  setCurrentRoomId: Dispatch<SetStateAction<string>>
  addEvent: (events: UndoEvent[]) => void
  setHeader: Dispatch<SetStateAction<JSX.Element>>
  onBack: () => void
  setPageOpen: Dispatch<SetStateAction<boolean>>
}

export const getRoomPostfix = (roomTypeId: string, survey: PropertySurvey) => {
  if (roomTypeId !== 'bedroom') return '' // only do this for bedrooms for now
  const roomTypeCount = survey.floors.flatMap(x => x.rooms).filter(x => x.room_type_uuid === roomTypeId).length
  const roomPostfix = ` ${roomTypeCount + 1}`
  return roomPostfix
}

export const AddRoomTab = ({ floor, setFloor, setPage, onBack, startingX, startingY, survey, addEvent, setCurrentRoomId, setHeader, setPageOpen }: AddRoomTabProps) => {
  const [roomTypeId, setRoomTypeId] = useState<string>('')
  const [roomName, setRoomName] = useState<string>('')
  const [width, setWidth] = useState<number>()
  const [length, setLength] = useState<number>()

  useEffect(() => {
    setHeader(<BottomSheetHeader onBack={onBack} title='Add a new room' />)
  }, [])

  return <div className='flex flex-col'>
    <div className='p-5 flex flex-col gap-5'>
      <div className='flex flex-col gap-2 flex-grow'>
        <div className='text-gray-900 font-bold text-sm'>Room type</div>
        <Select options={ROOM_TYPES.map(x => ({ key: x.uuid, value: x.name }))} selectedKey={roomTypeId} setSelectedKey={(e) => {
          const roomType = ROOM_TYPES.find(x => x.uuid === e)!
          setRoomTypeId(roomType.uuid)
          setRoomName(roomType.name + getRoomPostfix(roomType.uuid, survey))
        }} />
      </div>
      <div className='flex flex-col gap-2 flex-grow'>
        <div className='text-gray-900 font-bold text-sm'>Name</div>
        <Input value={roomName} setValue={(e) => setRoomName(e)} />
      </div>
      <div className='flex gap-2'>
        <div className='flex flex-col gap-2 flex-1'>
          <div className='text-gray-900 font-bold text-sm'>Width</div>
          <Input placeholder='2.5' postfix='m' type='number' value={width ?? ''} validator={validateWallLength} setValue={(e) => setWidth(parseFloat(e))} />
        </div>
        <div className='flex flex-col gap-2 flex-1'>
          <div className='text-gray-900 font-bold text-sm'>Length</div>
          <Input placeholder='2.5' postfix='m' type='number' value={length ?? ''} validator={validateWallLength} setValue={(e) => setLength(parseFloat(e))} />
        </div>
      </div>

      <Button
        disabled={!roomName || !roomTypeId || (length ? !validateWallLength(length).value : false) || (width ? !validateWallLength(width).value : false)}
        colour='DARK'
        onClick={() => {
          const newRoom = generateRoom(floor, length && length > 0 ? length : 2.5, width && width > 0 ? width : 2.5, floor.default_room_height, roomName, startingX, startingY, survey, roomTypeId)
          addEvent([{ type: 'ROOM', action: 'ADD', newValue: newRoom, oldValue: undefined }])
          setPage('ROOM_DETAILS')
          setCurrentRoomId(newRoom.uuid!)
          setPageOpen(false)
        }}>Create room</Button>
    </div>
  </div>
}

export const generateComplexRoom = (floor: Floor, survey: PropertySurvey, lines: SprucePoint[], height: number) => {
  const [floorMaterial, ceilingMaterial] = getFloorAndCeilingDefaultMaterialsForFloor(floor, survey)
  const defaultRoomType = ROOM_TYPES.find(x => x.uuid === 'living_lounge')!

  const newRoom: Room = {
    ...DEFAULT_SURVEY_ROOM,
    uuid: crypto.randomUUID(),
    walls: lines.map((x, i) => ({
      ...DEFAULT_SURVEY_WALL,
      uuid: crypto.randomUUID(),
      x: x.x,
      y: x.y,
      material: survey.default_materials!.externalWall!
    })),
    name: defaultRoomType.name,
    age_band: survey.age_band,
    floor_material: floorMaterial,
    ceiling_material: ceilingMaterial,
    room_type_uuid: defaultRoomType.uuid,
    height_m: height,
    x: 0,
    y: 0
  }

  return newRoom
}

export const generateRoom = (floor: Floor, length: number, width: number, height: number, name: string, startingX: number, startingY: number, survey: PropertySurvey, roomType: string) => {
  const roomLengthM = length
  const roomWidthM = width
  const distanceLength = Math.round(roomLengthM * 1000)
  const distanceWidth = Math.round(roomWidthM * 1000)

  const rectangleWalls = [
    { x: 0, y: 0 },
    { x: 0, y: distanceLength },
    { x: distanceWidth, y: distanceLength },
    { x: distanceWidth, y: 0 }
  ]

  const [floorMaterial, ceilingMaterial] = getFloorAndCeilingDefaultMaterialsForFloor(floor, survey)

  const newRoom: Room = {
    ...DEFAULT_SURVEY_ROOM,
    uuid: crypto.randomUUID(),
    walls: rectangleWalls.map((x, i) => ({
      ...DEFAULT_SURVEY_WALL,
      uuid: crypto.randomUUID(),
      x: x.x,
      y: x.y,
      material: survey.default_materials!.externalWall!
    })),
    name,
    age_band: survey.age_band,
    floor_material: floorMaterial,
    ceiling_material: ceilingMaterial,
    room_type_uuid: roomType,
    height_m: height,
    x: Math.round(startingX - (distanceWidth / 2)),
    y: Math.round(startingY - (distanceLength / 2))
  }

  return newRoom
}
