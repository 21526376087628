import React, { useRef, useState } from 'react'
import { Button } from '../../../../components/buttons/button'
import { resizeBase64Image } from '../../../../components/inputs_and_selections/photo_scroll'
import { Trash, Upload } from 'lucide-react'

type SettingsImageProps = {
  b64Image: string | undefined
  altText?: string
  placeholderText?: string
  helperText?: string
  buttonText?: string
  handleUpdateImage: (imageSrc: string) => void
  handleRemoveImage: () => void
  imgClassName?: string
}

export const SettingsImageInput = ({
  b64Image,
  altText = 'Image upload result',
  handleUpdateImage: handleUpdateLogo,
  handleRemoveImage: handleRemoveLogo,
  placeholderText: inputText,
  helperText,
  buttonText,
  imgClassName
}: SettingsImageProps) => {
  const [fileSizeValid, setFileSizeValid] = useState(true)

  const fileInputRef = useRef<HTMLInputElement>(null)
  return (
    <div className='flex flex-col gap-4'>
      {(b64Image) && <div className='flex flex-wrap gap-4 justify-between'>
        <img src={b64Image} className={` ${imgClassName}`} alt={altText} />
        <div className="justify-start items-center gap-3 flex">
          <Button colour='LIGHT' iconLeft={Upload} onClick={() => fileInputRef.current?.click()}>Change</Button>
          <Button colour='LIGHT' iconLeft={Trash} onClick={handleRemoveLogo}>Remove</Button>
        </div>
      </div>}
      {!b64Image && <div className={`h-56 px-6 py-12 bg-gray-50 rounded-lg ${!fileSizeValid && 'border border-red-500'}`}>
        <div className='flex flex-col gap-4 items-center justify-center'>
          <div className='font-bold'>{inputText}</div>
          <div className='text-gray-500'>{helperText}</div>
          <Button iconLeft={Upload} onClick={() => fileInputRef.current?.click()}>{buttonText}</Button>
        </div>
      </div>}
      <input ref={fileInputRef} id="company-logo" className='hidden' type="file" accept='image/*' capture="environment" onChange={(e) => {
        const newFile = e.target?.files?.[0]
        if (!newFile) return

        // Max file size is 5MB
        if (newFile.size > 5 * 1024 * 1024) {
          setFileSizeValid(false)
          return
        }
        setFileSizeValid(true)

        const reader = new FileReader()
        reader.onload = async (e) => {
          const resized = await resizeBase64Image(e.target?.result)
          handleUpdateLogo(resized)
        }
        reader.readAsDataURL(newFile)
      }} />
      {!fileSizeValid && <div className='text-red-700 text-sm'>File size must be less than 5MB</div>}
    </div>
  )
}
