import React from 'react'
import { Circle, Line } from 'react-konva'
import { gray200 } from './code/constants'

type GridProps = {
  size: 'MM' | 'CM' | 'M'
  scale: number
  position: { x: number, y: number }
  stageWidth: number
  stageHeight: number
}

const GRIDS = {
  MM: 1,
  CM: 10,
  M: 1000
}

// Grid is 100m x 100m
export const Grid = ({ size, scale, position, stageWidth, stageHeight }: GridProps) => {
  const gridIncrementPixels = GRIDS[size] * scale
  const gridSize = 100 * (1000 / GRIDS[size])
  if (gridIncrementPixels < 15) return

  const viewportWidth = stageWidth
  const viewportHeight = stageHeight
  const { x: stageX, y: stageY } = position

  const minX = -stageX
  const minY = -stageY
  const maxX = -stageX + viewportWidth
  const maxY = -stageY + viewportHeight

  const startingPosition = -((gridIncrementPixels * gridSize) / 2)
  const endPosition = gridIncrementPixels * gridSize + startingPosition

  const verticalLines: JSX.Element[] = []
  for (let i = 0; i < gridSize + 1; i++) {
    const x = i * gridIncrementPixels + startingPosition

    if (x >= minX && x <= maxX) {
      const line = <Line
        key={i}
        opacity={0.5}
        points={[x, startingPosition, x, endPosition]}
        stroke={gray200}
      />
      verticalLines.push(line)
    }
  }

  const horizontalLines: JSX.Element[] = []
  for (let i = 0; i < gridSize + 1; i++) {
    const startingPosition = -((gridIncrementPixels * gridSize) / 2)
    const y = i * gridIncrementPixels + startingPosition

    if (y >= minY && y <= maxY) {
      const line = <Line
        key={i}
        opacity={0.5}
        points={[startingPosition, y, endPosition, y]}
        stroke={gray200}
      />

      horizontalLines.push(line)
    }
  }

  return <>
    <Circle x={0} y={0} fill={gray200} radius={5} />
    {verticalLines}
    {horizontalLines}
  </>
}
