import React, { useContext, useEffect, useState } from 'react'
import { acceptInvitation, getInvitationByToken, invitationIsValid, type Invitation } from '../code/models/invitations'
import { getUser, type User } from '../code/models/user'
import { validateNotNull, validatePassword, validatePasswordMatch } from '../code/validators'
import { UnauthorizedPage } from './admin/unauthorized_page'
import { SpruceLogoWhite } from '../assets/images/spruce_logo/spruce_logo'
import { AuthContext, AuthSDK, AuthProvider } from '../code/utils/auth_provider'
import { Button } from '../components/buttons/button'
import { Section } from '../components/containers/section'
import { Card } from '../components/content_display/card'
import { Loader } from '../components/indicators_and_messaging/loader'
import { Input } from '../components/inputs_and_selections/input'

type AcceptInvitationProps = {
  email: string
  password?: string
  passwordRepeated?: string
  name?: string
}

const INVALID_ERROR_MESSAGE = 'This invitation no longer exists. Please sign in or request a new invitation.'

const SignedInForm = ({ handleAcceptInvitation, email }: { handleAcceptInvitation: (args: AcceptInvitationProps) => void, email: string }) => {
  const [user, setUser] = useState<User | undefined>(undefined)

  useEffect(() => {
    const fetchUser = async () => {
      const user = await getUser()
      setUser(user)
    }
    fetchUser()
  }, [])

  if (email !== user?.email) {
    return <UnauthorizedPage />
  }

  return (
    <div className='flex flex-col items-center'>
      <div className='pb-4 text-sm'>
        <div>You've been invited to access a survey on Spruce.</div>
      </div>
      <Button onClick={() => handleAcceptInvitation({ email: user.email })} className="mt-4">Accept Invitation</Button>
    </div>
  )
}

const SignedOutForm = ({ handleAcceptInvitation, email }: { email: string, handleAcceptInvitation: (args: AcceptInvitationProps) => void }) => {
  const [password, setPassword] = useState('')
  const [passwordRepeated, setPasswordRepeated] = useState('')
  const [firstName, setFirstName] = useState('')
  const [lastName, setLastName] = useState('')

  const isValidForm = [validatePassword(password), validatePasswordMatch(password, passwordRepeated), validateNotNull(firstName), validateNotNull(lastName)].every(x => x.value)

  const onSubmit = () => {
    handleAcceptInvitation({ email, password, name: `${firstName} ${lastName}` })
  }

  return (
    <div>
      <div className='pb-4 text-sm'>
        <div>You've been invited to access a survey on Spruce. Please create an account for the email address <span className='font-bold'>{email}</span> to continue.</div>
      </div>
      <div className="space-y-4">
        <div className="space-y-2">
          <div className="font-semibold">Email</div>
          <Card color='DARK'>
            <div className="text-sm text-gray-500">
              {email}
            </div>
          </Card>
        </div>

        <div className="space-y-2">
          <div className="font-semibold">Password</div>
          <Input onEnter={onSubmit} type="password" value={password} setValue={(e) => {
            setPassword(e)
          }} placeholder="Enter your password" validator={validatePassword} />
        </div>

        <div className="space-y-2">
          <div className="font-semibold">Repeat your password</div>
          <Input onEnter={onSubmit} type="password" value={passwordRepeated} setValue={(e) => {
            setPasswordRepeated(e)
          }} placeholder="Enter your password again" validator={(value) => validatePasswordMatch(value, password)} />
        </div>

        <div className="flex space-x-4">
          <div className="space-y-2 w-1/2">
            <div className="font-semibold">First Name</div>
            <Input onEnter={onSubmit} type="text" value={firstName} setValue={(e) => {
              setFirstName(e)
            }} placeholder="Enter your first name" validator={validateNotNull} />
          </div>

          <div className="space-y-2 w-1/2">
            <div className="font-semibold">Last Name</div>
            <Input onEnter={onSubmit} type="text" value={lastName} setValue={(e) => {
              setLastName(e)
            }} placeholder="Enter your last name" validator={validateNotNull}/>
          </div>
        </div>

        <Button disabled={!isValidForm} block={true} onClick={onSubmit}>Sign Up</Button>
      </div>
    </div>
  )
}

type AcceptInvitationPageInnerProps = {
  token: string
  navigateTo: (url: string) => void
}
const AcceptInvitationPageInner = ({ token, navigateTo }: AcceptInvitationPageInnerProps) => {
  const authContext = useContext(AuthContext)
  const [invitation, setInvitation] = useState<Invitation | undefined>(undefined)
  const [loading, setLoading] = useState(true)
  const [error, setError] = useState<string | undefined>(undefined)

  const handleAcceptInvitation = async ({ email, password, name }: AcceptInvitationProps) => {
    if (!token) {
      console.error('No token found')
      return
    }
    const response = await acceptInvitation({ email, password, name, token })
    if (!authContext.isSignedIn()) {
      await AuthSDK.signIn(email, password!)
    }

    // Redirect to the all surveys page
    navigateTo(`/${response.data.company.subdomain}/admin/enquiries/survey_booked`)
  }

  useEffect(() => {
    const fetchInvitation = async () => {
      if (!token) {
        console.error('No token found')
        return
      }
      const invitation = await getInvitationByToken(token)
      if (!invitationIsValid(invitation)) {
        setError(INVALID_ERROR_MESSAGE)
        setLoading(false)
        return
      }
      setInvitation(invitation)
      setLoading(false)
    }
    fetchInvitation()
  }, [])

  if (error) {
    return (
      <div className="flex flex-col items-center justify-center mt-10 max-w-3xl mx-auto">
        <Section border={true} title="Accept Invitation">
          <div>{error}</div>
        </Section>
      </div>
    )
  }

  if (loading || !invitation) {
    return (
      <div className="flex flex-col items-center justify-center mt-10 max-w-3xl mx-auto">
        <Section border={true} title="Accept Invitation">
          <Loader />
        </Section>
      </div>
    )
  }

  // If the invitation is pending but already has a user associated with it, redirect to the login page
  if (!authContext.isSignedIn() && invitation.recipient_uuid) {
    navigateTo('/login')
    return
  }

  return (
    <>
      <div className="w-full bg-gray-900 flex items-center justify-between p-4">
        <img alt="Spruce logo" className="w-32 h-12" src={SpruceLogoWhite}/>
      </div>
      <div className="flex flex-col items-center justify-center mt-10 max-w-3xl mx-auto">
        <Section border={true} title="Accept Invitation">
          { authContext.isSignedIn()
            ? <SignedInForm handleAcceptInvitation={handleAcceptInvitation} email={invitation.email}/>
            : <SignedOutForm handleAcceptInvitation={handleAcceptInvitation} email={invitation.email}/>
          }
        </Section>
      </div>
    </>
  )
}

export const AcceptInvitationPage = ({ token, navigateTo }: { token: string, navigateTo: (url: string) => void }) => {
  return (
    <AuthProvider>
      <AcceptInvitationPageInner token={token} navigateTo={navigateTo} />
    </AuthProvider>
  )
}
