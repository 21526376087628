import React, { useState } from 'react'
import { Checkbox } from '../../../components/inputs_and_selections/checkbox'

export const CheckboxDemo = () => {
  const [checked, setChecked] = useState(true)

  return <div className="flex flex-col gap-8">
    <div className='flex flex-col gap-4'>
      <div className='text-2xl font-bold text-gray-900'>Definition</div>

      <pre className='bg-gray-100 rounded-lg p-4 overflow-x-auto'><code>
        {codeBlock}
      </code></pre>

      <div className='flex flex-col divide-y divide-dashed divide-gray-200 text-sm'>
        {componentProps.map(x => <div key={x.name} className='grid grid-cols-2 py-2'>
          <div className='font-bold text-gray-900'>{x.name}</div>
          <div>{x.description}</div>
        </div>)}
      </div>
    </div>

    <div className='flex flex-col gap-4'>
      <div className='text-2xl font-bold text-gray-900'>Component</div>
      <Checkbox
        checked={checked}
        setChecked={() => setChecked(!checked)}
      />
    </div>

  </div>
}

const componentProps = [
  { name: 'checked', description: 'Whether the checkbox is checked or not' },
  { name: 'setChecked', description: 'What to do onClick of checkbox' }
]

const codeBlock = `<Checkbox
  checked={checked}
  setChecked={() => setChecked(!checked)}
/>`
