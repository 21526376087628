import React from 'react'
import { Text } from '../../../components/content_display/text'

export const TextDemo = () => {
  return (
    <div className="flex flex-col gap-8">
      <div className='flex flex-col gap-4'>
        <div className='text-2xl font-bold text-gray-900'>Definition</div>

        <pre className='bg-gray-100 rounded-lg p-4 overflow-x-auto'>
          <code>{codeBlock}</code>
        </pre>

        <div className='flex flex-col divide-y divide-dashed divide-gray-200 text-sm'>
          {componentProps.map(x => (
            <div key={x.name} className='grid grid-cols-2 py-2'>
              <div className='font-bold text-gray-900'>{x.name}</div>
              <div>{x.description}</div>
            </div>
          ))}
        </div>
      </div>

      <div className='flex flex-col gap-4'>
        <div className='text-2xl font-bold text-gray-900'>Component</div>
        <div>Sizes</div>
        <div className='flex flex-col justify-start items-start gap-4'>
          <Text size="XS">Extra small text</Text>
          <Text size="SM">Small text</Text>
          <Text size="MD">Medium text</Text>
          <Text size="LG">Large text</Text>
        </div>

        <div>Variants</div>
        <div className='flex flex-col justify-start items-start gap-4'>
          <Text>Default text</Text>
          <Text bold>Bold text</Text>
          <Text className="bg-orange-500 rounded underline">Custom class text</Text>
        </div>
      </div>
    </div>
  )
}

const componentProps = [
  { name: 'children', description: 'React.ReactNode or string to be rendered as text content' },
  { name: 'bold', description: 'Whether the text should be bold (optional)' },
  { name: 'className', description: 'Additional CSS classes to apply (optional)' },
  { name: 'size', description: 'XS | SM | MD | LG (optional, defaults to MD)' }
]

const codeBlock = `<Text
  bold={true}
  size="LG"
>
  Hello World
</Text>`
