import { z } from 'zod'
import { apiUrl, client } from '../axios'
import { AuthSDK } from '../utils/auth_provider'
import { parseWithZod } from '../zod'
import { type InventoryPartWithQuantity, InventoryPartWithQuantitySchema } from './inventory'

export const PackSchema = z.object({
  uuid: z.string().uuid(),
  name: z.string(),
  deleted_at: z.coerce.date().nullable().optional(),
  parts: z.array(InventoryPartWithQuantitySchema).optional()
})

export type Pack = z.infer<typeof PackSchema>

export const getPacks = async (companyUUID: string): Promise<Pack[] | undefined> => {
  try {
    const result = await client.get<Array<Record<any, any>>>(`${apiUrl}teams/${companyUUID}/packs`,
      { headers: { 'x-auth-token': AuthSDK.getToken() } }
    )
    const parsedResult = result.data.map(x => parseWithZod<Pack>(PackSchema, x))
    return parsedResult
  } catch (e: unknown) {
    console.error('Error getting packs', e)
    return undefined
  }
}

export const insertPack = async (pack: Pack, companyUUID: string): Promise<Pack> => {
  const response = await client.post(
    `${apiUrl}teams/${companyUUID}/packs`,
    pack,
    { headers: { 'x-auth-token': AuthSDK.getToken() } }
  )
  return response.data
}

export const updatePack = async (pack: Pack, companyUUID: string): Promise<Pack> => {
  const response = await client.patch(
    `${apiUrl}teams/${companyUUID}/packs/${pack.uuid}`,
    pack,
    { headers: { 'x-auth-token': AuthSDK.getToken() } }
  )
  return response.data
}

export const deletePack = async (pack: Pack, companyUUID: string): Promise<void> => {
  await client.delete(
    `${apiUrl}teams/${companyUUID}/packs/${pack.uuid}`,
    { headers: { 'x-auth-token': AuthSDK.getToken() } }
  )
}

export const addPartToPack = async (pack: Pack, part: InventoryPartWithQuantity, companyUUID: string): Promise<void> => {
  await client.post(
    `${apiUrl}teams/${companyUUID}/packs/${pack.uuid}/items`,
    { uuid: part.uuid },
    { headers: { 'x-auth-token': AuthSDK.getToken() } }
  )
}

export const removePartFromPack = async (pack: Pack, part: InventoryPartWithQuantity, companyUUID: string): Promise<void> => {
  await client.delete(
    `${apiUrl}teams/${companyUUID}/packs/${pack.uuid}/items/${part.uuid}`,
    { headers: { 'x-auth-token': AuthSDK.getToken() } }
  )
}

export const updatePartQuantityInPack = async (pack: Pack, part: InventoryPartWithQuantity, companyUUID: string): Promise<void> => {
  await client.patch(
    `${apiUrl}teams/${companyUUID}/packs/${pack.uuid}/items/${part.uuid}`,
    { quantity: part.quantity },
    { headers: { 'x-auth-token': AuthSDK.getToken() } }
  )
}
