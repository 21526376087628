import React from 'react'
import { Modal } from '../../../../components/containers/modal'
import { Loader } from '../../../../components/indicators_and_messaging/loader'
import { yellowEnvelope } from '../../../../assets/images/images'
import { Input } from '../../../../components/inputs_and_selections/input'
import _, { noop } from 'lodash'
import { Icon } from '../../../../components/buttons/icon'
import { FormLabel } from '../../../../components/inputs_and_selections/form_label'
import { Tiptap } from '../../../../components/inputs_and_selections/tiptap'
import { type TiptapPlaceholderProps } from '../../../../code/tiptap_placeholders'
import { validateEmail } from '../../../../code/validators'
import type { JSONContent } from '@tiptap/react'
import { Send } from 'lucide-react'

type Props = {
  // visiblity and actions
  visible: boolean
  setVisible: (v: boolean) => void
  handleSend: (recipients: string[]) => Promise<void>

  // email recipients
  emailRecipients: string
  setEmailRecipients: (e: string) => void

  // email text
  emailText: JSONContent
  setEmailText: (e: JSONContent) => void
  editorPlaceholders: TiptapPlaceholderProps[]
}

export const SendEmailWithTemplateModal = (props: Props) => {
  const [sendingStatus, setSendingStatus] = React.useState<'INIT' | 'SENDING' | 'SENT'>('INIT')

  const [emailRecipients, setEmailRecipients] = React.useState(props.emailRecipients)
  const [emailText, setEmailText] = React.useState<JSONContent>(props.emailText)

  const recipientsStringToArray = (): string[] => {
    return _.uniq(_.compact(props.emailRecipients.split(',').map(r => validateEmail(r.trim()).value)))
  }

  return <>
    <Modal
      visible={props.visible}
      setVisible={props.setVisible}
      title='Send via Email'
      cancelDisabled={sendingStatus !== 'INIT'}
      onConfirm={async () => {
        // dual logic for the confirm button: either send the email or close the modal
        if (sendingStatus === 'INIT') {
          setSendingStatus('SENDING')
          await props.handleSend(
            recipientsStringToArray()
          )
          setSendingStatus('SENT')
        } else {
          props.setVisible(false)
          setSendingStatus('INIT')
        }
      }}
      confirmButtonLabel={sendingStatus === 'INIT' ? 'Send' : 'Close'}
      confirmDisabled={sendingStatus === 'SENDING'}
      hideOnConfirm={false}
    >
      <div className='w-full flex flex-col gap-6'>
        {/* sending emails */}
        { sendingStatus === 'SENDING' && <Loader /> }

        {/* successfully sent! */}
        { sendingStatus === 'SENT' && <div className='px-5 py-8 bg-green-50 rounded-lg flex-col justify-center items-center gap-2 flex'>
          <img alt='envelope' src={yellowEnvelope} className='w-16 h-16'/>
          <div className="text-gray-900 text-xl font-bold">Success</div>
          <div className="text-center flex flex-col gap-1">
            <span>Your email was successfully sent to</span>
            <div className={'flex flex-col gap-0'}>
              { recipientsStringToArray().map((r, i) => <span className={'font-bold'} key={i}>{r}</span>) }
            </div>
          </div>
        </div>}

        {/* form and controls, sending hasn't started */}
        {sendingStatus === 'INIT' && <>
          <div className='flex flex-col gap-0'>
            <p>Send an email to your recipient(s) with a link to the estimate.</p>
            <p>You can edit this templated email in your Settings.</p>
          </div>
          <div className='flex flex-col gap-3 w-full'>
            <Input
              onEnter={noop}
              value={emailRecipients}
              setValue={(e) => {
                setEmailRecipients(e)
                props.setEmailRecipients(e)
              }}
              type='text'
              label='Send to'
              placeholder='jack@example.com, robert@example.com'
              size='MD'
              shrink={false}
              validator={(e) => ({ message: '', value: e })}
              className=''
              prefix={<Icon icon={Send} />}
            />
            <div className='flex flex-wrap gap-3'>
              { recipientsStringToArray().map((r, i) => <span key={i} className='rounded-lg px-2 py-1 text-sm bg-gray-200'>{r}</span>) }
            </div>
          </div>
          <div className='flex flex-col gap-2'>
            <FormLabel
              labelText='Customer email'
              size='SM'
            />
            <div className='flex flex-col gap-4'>
              <div className='space-y-2'>
                <Tiptap
                  editable={true}
                  className='w-full rounded border border-gray-300 p-2 focus:outline-none'
                  onUpdateCallback={async (editor) => {
                    setEmailText(editor.getJSON())
                    props.setEmailText(editor.getJSON())
                  }}
                  placeholders={props.editorPlaceholders}
                  content={emailText}
                />
              </div>
            </div>
          </div>
        </>}
      </div>
    </Modal>
  </>
}
