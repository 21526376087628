import React from 'react'
import { HLRContext } from './heatloss_report'
import { HLRSubheaderBlock } from './partials/hlr_subheader'
import { numberFormat } from '../../../code/number_format'
import { formatPrice } from '../../../code/format_price'
import { CylinderIcon, HeatPumpIcon2, RadiatorIcon } from '../../../assets/images/survey_images/survey_images'

export const HLRSummaryPage = () => {
  const hlrContext = React.useContext(HLRContext)

  const estimateSummary = hlrContext!.performanceEstimateSummary
  const hpImage = process.env.S3_BUCKET_URL + '/hp-images/' + hlrContext!.currentHeatPump?.range_heat_pump?.uuid + '.png'

  return (
    <div className="flex-col gap-6 flex">
      <HLRSubheaderBlock title="Summary"/>

      {/* Your home’s heat loss */}
      <div className="flex-col flex gap-4">
        <div className="text-gray-900 text-lg font-bold">Your home’s heat loss</div>
        <div className='grid grid-cols-1 sm:grid-cols-3 gap-3'>
          <div className='h-32 p-5 flex flex-col justify-between bg-gray-100 rounded-md'>
            <div className="">Floor area</div>
            <div className='flex gap-1 items-end'>
              <div className="text-gray-900 text-4xl font-bold">{numberFormat(0).format(hlrContext!.summaryData.floorArea)}</div>
              <div className=" text-lg">m²</div>
            </div>
          </div>
          <div className='h-32 p-5 flex flex-col justify-between bg-gray-100 rounded-md'>
            <div className="">Total heat loss</div>
            <div className='flex gap-1 items-end'>
              <div className="text-gray-900 text-4xl font-bold">{numberFormat(2).format(hlrContext!.summaryData.heatLoss / 1000)}</div>
              <div className=" text-lg">kW</div>
            </div>
          </div>
          <div className='h-32 p-5 flex flex-col justify-between bg-gray-100 rounded-md'>
            <div className="">Average heat load</div>
            <div className='flex gap-1 items-end'>
              <div className="text-gray-900 text-4xl font-bold">{numberFormat(0).format(hlrContext!.summaryData.heatLoss / hlrContext!.summaryData.floorArea)}</div>
              <div className=" text-lg">W/m²</div>
            </div>
          </div>
        </div>
      </div>

      {/* Heat pump design proposal */}
      <div className="flex-col flex gap-4">
        <div className="text-gray-900 text-lg font-bold">Heat pump design proposal</div>
        <div className='grid grid-cols-1 sm:grid-cols-3 gap-3'>

          {/* Heat pump block */}
          <div className='sm:h-44 sm:col-span-2 p-5 flex flex-row items-center bg-gray-100 rounded-md gap-8'>
            <img alt='' className="w-24 max-h-32" src={hpImage} onError={(e) => {
              e.currentTarget.onerror = null
              e.currentTarget.src = HeatPumpIcon2
            }} />
            <div className='w-full flex flex-col gap-4'>
              <div className="">Heat pump</div>
              <div className='flex flex-col gap-3'>
                <div className="text-gray-900 text-sm font-bold">{hlrContext!.currentHeatPump.name}</div>
                <div className='flex flex-col gap-2'>
                  <div className='flex flex-row gap-8 justify-between'>
                    <div className=" text-xs font-semibold">
                      Capacity at {hlrContext!.hpCapacityResult.flowTempC} °C flow temp and {hlrContext!.hpCapacityResult.outsideTempC} °C outdoor
                      air temperature
                    </div>
                    <div className="text-right  text-xs font-semibold text-nowrap">{hlrContext!.hpCapacityResult.capacityKw} kW</div>
                  </div>
                  <div className='flex flex-row gap-8 justify-between'>
                    <div className=" text-xs font-semibold">SCOP at {hlrContext!.design.flow_temp} °C</div>
                    <div className="text-right  text-xs font-semibold text-nowrap">{hlrContext!.scop}</div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          {/* hidden on mobile */}
          <div className='row-span-2 p-5 hidden sm:flex flex-col justify-between bg-gray-100 rounded-md'>
            <div className='w-full h-full flex flex-col justify-between'>
              <div className="">Emitter changes</div>
              <img alt='' className="w-36 h-36" src={RadiatorIcon}/>
              <div className="flex-col gap-2 flex">
                <div className="justify-start items-baseline gap-1 flex">
                  <div
                    className="text-gray-900 text-4xl font-bold">{hlrContext!.summaryData.radiatorsNew + hlrContext!.summaryData.radiatorsReplaced}</div>
                  <div className="text-lg">new radiators</div>
                </div>
                {hlrContext!.summaryData.ufhNew > 0 && <div className="justify-start items-baseline gap-1 flex">
                  <div
                    className="text-gray-900 text-4xl font-bold">{hlrContext!.summaryData.ufhNew}</div>
                  <div className="text-lg">new ufh zones</div>
                </div>}
                <div className="justify-start items-baseline gap-1 flex">
                  <div className="text-gray-900 text-4xl font-bold">{hlrContext!.summaryData.emittersUnchanged}</div>
                  <div className="text-lg">retained</div>
                </div>
                <div className="justify-start items-baseline gap-1 flex">
                  <div
                    className="text-gray-900 text-4xl font-bold">{hlrContext!.summaryData.emittersRemoved + hlrContext!.summaryData.radiatorsReplaced}</div>
                  <div className="text-lg">removed</div>
                </div>
              </div>
            </div>
          </div>

          {/* Cylinder block */}
          <div className='sm:h-44 sm:col-span-2 p-5 flex flex-row items-center bg-gray-100 rounded-md gap-8'>
            <img alt='' className="w-24 h-24" src={CylinderIcon}/>
            <div className='w-full flex flex-col gap-4'>
              <div className="">Cylinder</div>
              <div className='flex flex-col gap-3'>
                <div className="text-gray-900 text-sm font-bold">{hlrContext!.currentHotWaterCylinder?.name || '?'}</div>
                <div className='flex flex-col gap-2'>
                  <div className='flex flex-row gap-8 justify-between'>
                    <div className="text-xs font-semibold">Capacity</div>
                    <div className="text-right text-xs font-semibold">{Math.round(hlrContext!.currentHotWaterCylinder!.litres)} litres</div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        {/* hidden on sm+ screen sizes */}
        <div className='p-5 sm:hidden flex-col justify-between bg-gray-100 rounded-md'>
          <div className="">Emitter changes</div>
          <div className='flex flex-row justify-between'>
            <img alt='' className="w-24 h-24" src={RadiatorIcon}/>
            <div className="flex-col gap-2 flex">
              <div className="justify-start items-baseline gap-1 flex">
                <div
                  className="text-gray-900 font-bold">{hlrContext!.summaryData.radiatorsNew + hlrContext!.summaryData.radiatorsReplaced}</div>
                <div>new radiators</div>
              </div>
              {hlrContext!.summaryData.ufhNew > 0 && <div className="justify-start items-baseline gap-1 flex">
                <div
                  className="text-gray-900 font-bold">{hlrContext!.summaryData.ufhNew}</div>
                <div>new ufh zones</div>
              </div>}
              <div className="justify-start items-baseline gap-1 flex">
                <div className="text-gray-900 font-bold">{hlrContext!.summaryData.emittersUnchanged}</div>
                <div>retained</div>
              </div>
              <div className="justify-start items-baseline gap-1 flex">
                <div
                  className="text-gray-900 font-bold">{hlrContext!.summaryData.emittersRemoved + hlrContext!.summaryData.radiatorsReplaced}</div>
                <div>removed</div>
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* Performance comparison */}
      {(!Number.isNaN(estimateSummary.minEmissionsTonnesSavings) && !Number.isNaN(estimateSummary.minBillSavings)) && <div className="flex-col flex gap-4">
        <div className="text-gray-900 text-lg font-bold">Performance comparison</div>
        <div className='grid grid-cols-2 gap-3'>
          {!Number.isNaN(estimateSummary.minEmissionsTonnesSavings) && <div className='h-32 p-5 flex flex-col justify-between bg-gray-100 rounded-md'>
            <div className="">Annual CO2 savings</div>
            <div className='flex flex-row gap-1 items-end'>
              <div className="text-gray-900 text-4xl font-bold px-1 gap-1 flex">
                <div
                  className={`${estimateSummary.minEmissionsTonnesSavings > 0 ? 'bg-green-200' : 'bg-rose-200'} px-1 rounded-md gap-2.5 flex`}>
                  {numberFormat(1).format(estimateSummary.minEmissionsTonnesSavings)}
                </div>
                {numberFormat(1).format(estimateSummary.minEmissionsTonnesSavings) !== numberFormat(1).format(estimateSummary.maxEmissionsTonnesSavings) && <>
                  <div>to</div>
                  <div className={`${estimateSummary.maxEmissionsTonnesSavings > 0 ? 'bg-green-200' : 'bg-rose-200'} px-1 rounded-md gap-2.5 flex`}>
                    {numberFormat(1).format(estimateSummary.maxEmissionsTonnesSavings)}
                  </div>
                </>}
              </div>
              <div className=" text-lg">tonnes</div>
            </div>
          </div>}
          {!Number.isNaN(estimateSummary.minBillSavings) && <div className='h-32 p-5 flex flex-col justify-between bg-gray-100 rounded-md'>
            <div className="">Annual running cost savings</div>
            <div className='flex flex-row gap-1 items-end'>
              <div className="text-gray-900 text-4xl font-bold px-1 gap-1 flex">
                <div className={`${estimateSummary.minBillSavings > 0 ? 'bg-green-200' : 'bg-rose-200'} px-1 rounded-md gap-2.5 flex`} >
                  {formatPrice(estimateSummary.minBillSavings, 0)}
                </div>
                <div>to</div>
                <div className = {`${estimateSummary.maxBillSavings > 0 ? 'bg-green-200' : 'bg-rose-200'} px-1 rounded-md gap-2.5 flex`}>
                  {formatPrice(estimateSummary.maxBillSavings, 0)}
                </div>
              </div>
              <div className="text-lg">per year</div>
            </div>
          </div>}
        </div>
      </div>}
    </div>
  )
}
