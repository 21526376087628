import { type KonvaEventObject } from 'konva/lib/Node'
import { type Vector2d } from 'konva/lib/types'
import { type Dispatch, type SetStateAction } from 'react'
import { Line, Text } from 'react-konva'
import { type WallElement } from '../../../code/models/material'
import { type Room } from '../../../code/models/room'
import { type Wall } from '../../../code/models/wall'
import { type CurrentFloorPlanPage } from './floor'
import React from 'react'
import { indigo600, gray900, gray400, FONT_SIZE } from './code/constants'
import { getNextWall, calculateRotation, calculateLineLength } from './code/utils'
import { type SprucePoint } from './code/types'
import { calculateAngle, normalizeAngle } from './code'
import Konva from 'konva'

type LineWithTextProps = {
  otherRoomId: string
  onClick: () => void
  innerText: string
  scale: Vector2d
  wallIndex: number
  setWalls: (walls: Wall[]) => void
  walls: Wall[]
  x: number
  y: number
  draggable: boolean
  setCurrentWallId: Dispatch<SetStateAction<string>>
  setPage: (page: CurrentFloorPlanPage) => void
  isCurrentRoom: boolean
  isCurrentWall: boolean
  onDragEnd: () => void
  onDragStart: () => void
  rooms: Room[]
  setGuidelines: Dispatch<SetStateAction<SpruceLine[]>>
  stageScale: number
  snappingTolerancePixels: number
  isDrawing: boolean
}

export const LineWithText = ({
  onDragEnd,
  onDragStart,
  onClick,
  innerText,
  draggable,
  wallIndex,
  walls,
  scale,
  setWalls,
  x,
  y,
  setCurrentWallId,
  setPage,
  isCurrentRoom,
  isCurrentWall,
  rooms,
  setGuidelines,
  stageScale,
  snappingTolerancePixels,
  isDrawing
}: LineWithTextProps) => {
  const currentVertex = walls[wallIndex]
  const nextVertex = getNextWall(currentVertex, walls)

  const trueCurrentVertex = { ...currentVertex, x: currentVertex.x! * stageScale, y: currentVertex.y! * stageScale }
  const trueNextVertex = { ...nextVertex, x: nextVertex.x! * stageScale, y: nextVertex.y! * stageScale }

  // Calculate the midpoint of the line
  const midX = (trueCurrentVertex.x + trueNextVertex.x) / 2
  const midY = (trueCurrentVertex.y + trueNextVertex.y) / 2

  const text = `${(calculateLineLength(currentVertex.x!, currentVertex.y!, nextVertex.x!, nextVertex.y!))} m`

  const rotation = calculateRotation(currentVertex.x!, currentVertex.y!, nextVertex.x!, nextVertex.y!)
  const constrainedRotationAndOffset = rotation < 270 && rotation >= 90
    ? { rotation: rotation - 180, outer: 20, inner: 10 }
    : { rotation, outer: -10, inner: -20 }

  const points = [trueCurrentVertex.x + x, trueCurrentVertex.y + y, trueNextVertex.x + x, trueNextVertex.y + y]

  const onDragMove = (e: KonvaEventObject<DragEvent>, setGuidelines: Dispatch<SetStateAction<SpruceLine[]>>) => {
    if (e.target === e.currentTarget) {
      e.target.position({ x: 0, y: 0 })
      const cursor = e.currentTarget.getStage()!.getRelativePointerPosition()!

      const trueX = trueCurrentVertex.x + x
      const trueY = trueCurrentVertex.y + y
      const trueNextX = trueNextVertex.x + x
      const trueNextY = trueNextVertex.y + y

      const rotation = calculateRotation(trueX, trueY, trueNextX, trueNextY)
      const moveX = !((rotation <= 45 || rotation >= 315) || (rotation >= 135 && rotation <= 225))

      const midX = (trueX + trueNextX) / 2
      const midY = (trueY + trueNextY) / 2

      const deltaX = midX - cursor.x
      const deltaY = midY - cursor.y

      const newX = moveX ? trueX - deltaX : trueX
      const newY = !moveX ? trueY - deltaY : trueY
      const newNextX = moveX ? trueNextX - deltaX : trueNextX
      const newNextY = !moveX ? trueNextY - deltaY : trueNextY

      const snapped = snapLine({
        p1: { x: newX, y: newY },
        p2: { x: newNextX, y: newNextY }
      }, rooms, stageScale, [currentVertex.uuid!, nextVertex.uuid!], snappingTolerancePixels)

      const newSnappedX = moveX ? snapped ? snapped.newLine.p1.x : newX : trueX
      const newSnappedY = !moveX ? snapped ? snapped.newLine.p1.y : newY : trueY
      const newSnappedNextX = moveX ? snapped ? snapped.newLine.p2.x : newNextX : trueNextX
      const newSnappedNextY = !moveX ? snapped ? snapped.newLine.p2.y : newNextY : trueNextY

      const newWalls = walls.map(nw => nw.uuid === currentVertex.uuid
        ? { ...currentVertex, x: Math.round((newSnappedX - x) / stageScale), y: Math.round((newSnappedY - y) / stageScale) }
        : nw.uuid === nextVertex.uuid
          ? { ...nextVertex, x: Math.round((newSnappedNextX - x) / stageScale), y: Math.round((newSnappedNextY - y) / stageScale) }
          : nw)
      setWalls(newWalls)
      setGuidelines(snapped?.guidelines ?? [])
    }
  }

  const strokeWidth = isCurrentRoom
    ? 4
    : 1

  const applicable = walls[wallIndex].material?.applicable_to

  const wallMaterialMappings: Record<WallElement, { dash: number[], stroke: string }> = {
    'external-wall': { dash: [], stroke: isCurrentWall ? indigo600 : gray900 },
    'internal-wall': { dash: isCurrentRoom ? [8, 8] : [6, 6], stroke: isCurrentWall ? indigo600 : gray400 },
    'party-wall': { dash: isCurrentRoom ? [8, 8] : [6, 6], stroke: isCurrentWall ? indigo600 : gray900 }
  }

  const stroke = applicable && wallMaterialMappings[applicable]

  return <>
    <Line
      lineJoin='round'
      lineCap='round'
      dash={stroke?.dash}
      hitStrokeWidth={isCurrentRoom ? (25 - scale.x) : 0}
      onTap={onClick}
      onClick={onClick}
      listening={!isDrawing}
      points={points}
      stroke={stroke?.stroke}
      strokeWidth={strokeWidth}
      draggable={draggable}
      onDragStart={onDragStart}
      onDragEnd={onDragEnd}
      onDragMove={(e) => onDragMove(e, setGuidelines)}
      onMouseEnter={(e) => {
        const stage = e.currentTarget.getStage()!.container()
        stage.style.cursor = 'pointer'
      }}
      onMouseLeave={(e) => {
        const stage = e.currentTarget.getStage()!.container()
        stage.style.cursor = 'move'
      }}
    />
    {isCurrentRoom && <Text
      onClick={() => {
        setCurrentWallId(walls[wallIndex].uuid!)
        setPage('WALL_LENGTH')
      }}
      onTap={() => {
        setCurrentWallId(walls[wallIndex].uuid!)
        setPage('WALL_LENGTH')
      }}
      fontFamily='Manrope'
      fill={indigo600}
      text={text}
      x={x + midX}
      y={y + midY}
      offsetX={getTextDimensions(text).width / 2}
      rotation={constrainedRotationAndOffset.rotation}
      fontSize={FONT_SIZE}
      offsetY={constrainedRotationAndOffset.outer}
    />}
    {<Text
      onClick={() => {
        setCurrentWallId(walls[wallIndex].uuid!)
        setPage('WALL_LENGTH')
      }}
      onTap={() => {
        setCurrentWallId(walls[wallIndex].uuid!)
        setPage('WALL_LENGTH')
      }}
      listening={!isDrawing}
      fontFamily='Manrope'
      fill={isCurrentRoom ? gray900 : indigo600}
      text={innerText}
      opacity={isCurrentRoom ? 1 : 0.5}
      x={x + midX}
      y={y + midY}
      offsetX={getTextDimensions(innerText).width / 2}
      rotation={constrainedRotationAndOffset.rotation}
      fontSize={FONT_SIZE}
      offsetY={-constrainedRotationAndOffset.inner}
    />}
  </>
}

export type SnappingType = 'POINT'
| 'VERTICAL_HORIZONTAL'
| 'VERTICAL'
| 'HORIZONTAL'
| 'ANGLE'
| 'ANGLE_INTERSECTION'
export type MatchedLine = { newLine: SpruceLine, guidelines: SpruceLine[], type: SnappingType, distance: number }
export type MatchedPoint = { newPoint: SprucePoint, guidelines: SpruceLine[], type: SnappingType, distance: number }

export type SpruceLine = {
  uuid?: string
  p1: SprucePoint
  p2: SprucePoint
}

export const snapLine = (
  line: SpruceLine,
  rooms: Room[],
  stageScale: number,
  excludeWallUUIDs: string[] = [],
  snappingTolerance: number
): MatchedLine | undefined => {
  const pointTolerance = snappingTolerance
  const wallSegments: SpruceLine[] = [...rooms.flatMap(r => {
    const walls = r.walls.map(w => {
      const nextWall = getNextWall(w, r.walls)

      return {
        p1: { uuid: w.uuid!, x: (w.x! + r.x!) * stageScale, y: (w.y! + r.y!) * stageScale },
        p2: { uuid: nextWall.uuid!, x: (nextWall.x! + r.x!) * stageScale, y: (nextWall.y! + r.y!) * stageScale }
      }
    })

    return walls
  })].filter(x => !excludeWallUUIDs.includes(x.p1.uuid) && !excludeWallUUIDs.includes(x.p2.uuid))
  const points = wallSegments.map(x => x.p1)

  const anyClosePointP1 = points.find(x =>
    Math.abs(x.x - line.p1.x) < pointTolerance &&
      Math.abs(x.y - line.p1.y) < pointTolerance
  )

  if (anyClosePointP1) {
    const deltaX = line.p1.x - anyClosePointP1.x
    const deltaY = line.p1.y - anyClosePointP1.y

    return {
      newLine: { p1: anyClosePointP1, p2: { x: line.p2.x - deltaX, y: line.p2.y - deltaY } },
      type: 'POINT',
      guidelines: [],
      distance: 0
    }
  }

  const anyClosePointP2 = points.find(x =>
    Math.abs(x.x - line.p2.x) < pointTolerance &&
      Math.abs(x.y - line.p2.y) < pointTolerance
  )
  if (anyClosePointP2) {
    const deltaX = line.p2.x - anyClosePointP2.x
    const deltaY = line.p2.y - anyClosePointP2.y

    return {
      newLine: { p1: { x: line.p1.x - deltaX, y: line.p1.y - deltaY }, p2: anyClosePointP2 },
      type: 'POINT',
      guidelines: [],
      distance: 0
    }
  }

  for (const segment of wallSegments) {
    const snappedP1 = snapToAngle(segment, line.p1, snappingTolerance)?.newPoint
    const snappedP2 = snapToAngle(segment, line.p2, snappingTolerance)?.newPoint

    if (snappedP1 && snappedP2) {
      const sortedPoints = sortPointsByAngle([
        segment.p1,
        segment.p2,
        snappedP1,
        snappedP2
      ])

      return {
        newLine: { p1: snappedP1, p2: snappedP2 },
        type: 'ANGLE',
        guidelines: [
          { p1: sortedPoints[0], p2: sortedPoints[sortedPoints.length - 1] }
        ],
        distance: 0
      }
    }
  }
}

export const snapToAngle = (line: SpruceLine, mousePosition: SprucePoint, tolerance: number): MatchedPoint | undefined => {
  const lineAngle = calculateAngle({ x: line.p1.x, y: line.p1.y }, { x: line.p2.x, y: line.p2.y })
  const mouseAngle = calculateAngle({ x: line.p1.x, y: line.p1.y }, mousePosition)

  const angleDifference = Math.abs(normalizeAngle(lineAngle - mouseAngle))
  const oppositeAngleDifference = Math.abs(normalizeAngle(lineAngle - mouseAngle + Math.PI))

  const distance = Math.sqrt((mousePosition.x - line.p1.x) ** 2 + (mousePosition.y - line.p1.y) ** 2)
  const angleTolerance = tolerance / distance

  if (angleDifference < angleTolerance || oppositeAngleDifference < angleTolerance) {
    const snapAngle = angleDifference < angleTolerance
      ? lineAngle
      : lineAngle + Math.PI

    // Calculate new mouse position that preserves distance but snaps to the angle.
    const newPoint = {
      x: line.p1.x + distance * Math.cos(snapAngle),
      y: line.p1.y + distance * Math.sin(snapAngle)
    }

    return {
      newPoint,
      guidelines: [{ p1: newPoint, p2: line.p1 }],
      distance: getDistance(newPoint, mousePosition),
      type: 'ANGLE'
    }
  }
}

export const getDistance = (p1: SprucePoint, p2: SprucePoint) => {
  return Math.sqrt(Math.pow(p2.x - p1.x, 2) + Math.pow(p2.y - p1.y, 2))
}

export const sortPointsByAngle = (points: SprucePoint[], refPoint = { x: 0, y: 0 }) => {
  return points.sort((a, b) => {
    // Calculate angles relative to the reference point
    const angleA = Math.atan2(a.y - refPoint.y, a.x - refPoint.x)
    const angleB = Math.atan2(b.y - refPoint.y, b.x - refPoint.x)

    // Sort by angle
    return angleA - angleB
  })
}

export const getTextDimensions = (text: string | undefined, isBold: boolean = false, fontSize = FONT_SIZE): { width: number, height: number } => {
  if (!text || text.length === 0) return { width: 0, height: 0 }

  const test = new Konva.Text({
    text,
    fontFamily: 'Manrope',
    fontSize,
    fontStyle: isBold ? 'bold' : ''
  })

  return { width: test.getWidth(), height: test.getHeight() }
}
