import React from 'react'
import { Emoji } from './emoji'

export type SummaryTableRow = {
  key: string | React.ReactNode
  value: string | number | React.ReactNode
  size?: 'SM' | 'MD' | 'LG'
  emoji?: string
  infoButton?: React.ReactNode
}

type Props = {
  rows: SummaryTableRow[]
}

const defineMajorStyling = (size: 'SM' | 'MD' | 'LG') => {
  switch (size) {
    case 'SM':
      return 'text-xs font-bold text-default'
    case 'LG':
      return 'text-md text-bold font-bold'
    case 'MD':
    default:
      return 'text-sm font-bold text-default'
  }
}

const defineMinorStyling = (size: 'SM' | 'MD' | 'LG') => {
  switch (size) {
    case 'SM':
      return 'text-xs text-default'
    case 'LG':
      return 'text-md text-default'
    case 'MD':
    default:
      return 'text-sm text-default'
  }
}

export const SummaryTable = ({ rows }: Props) => {
  if (!rows) return

  return <div className='w-full divide divide-y border-light'>
    {rows.map(({ key, value, size = 'MD', emoji, infoButton }, i) => <div key={i} className='flex flex-col gap-1 sm:flex-row justify-between py-2 last:pb-0'>
      <div className={`flex gap-2 items-center ${defineMajorStyling(size)}`}>
        {emoji && <Emoji emoji={emoji} />}
        <p>{key} {infoButton}</p>
      </div>
      <p className={defineMinorStyling(size)}>{value}</p>
    </div>)}
  </div>
}
