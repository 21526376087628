import { type Company, getCompanyReportEmailTemplate } from '../../../../../code/models/company'
import React from 'react'
import { Section } from '../../../../../components/containers/section'
import { Tiptap } from '../../../../../components/inputs_and_selections/tiptap'
import {
  TTPlaceholderCompanyName,
  TTPlaceholderCompanyPhone,
  TTPlaceholderCustomerAddress,
  TTPlaceholderCustomerName,
  TTPlaceholderEstimateContactName,
  TTPlaceholderEstimateContactPhone,
  TTPlaceholderViewReport
} from '../../../../../code/tiptap_placeholders'

export const ReportEmailTemplateBlock = ({ company, setCompany }: {
  company: Company
  setCompany: (c: Company) => void
}) => {
  return <Section border={true} title="Report email template">
    <div className='flex flex-col gap-4'>
      <div className='space-y-2'>
        <Tiptap
          editable={true}
          className='w-full rounded border border-gray-300 p-2 focus:outline-none'
          onUpdateCallback={async (editor) => {
            setCompany({
              ...company,
              report_email_template: JSON.stringify(editor.getJSON())
            })
          }}
          placeholders={[
            TTPlaceholderViewReport,
            TTPlaceholderCustomerName,
            TTPlaceholderCustomerAddress,
            TTPlaceholderEstimateContactName,
            TTPlaceholderEstimateContactPhone,
            TTPlaceholderCompanyName,
            TTPlaceholderCompanyPhone
          ]}
          content={getCompanyReportEmailTemplate(company.report_email_template || '')}
        />
      </div>
    </div>
  </Section>
}
