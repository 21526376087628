import React, { useState } from 'react'
import { Heading } from '../../../../../components/content_display/heading'
import { Section } from '../../../job_layout/components/section'
import { Button } from '../../../../../components/buttons/button'
import { type Company } from '../../../../../code/models/company'
import { DropdownMenu } from '../../../../../components/buttons/dropdown_menu'
import { InventoryTable, type InventoryTableColumn } from '../inventory_table'
import { Modal } from '../../../../../components/containers/modal'
import { type InventoryHotWaterCylinder } from '../../../../../code/models/inventory'
import { Input } from '../../../../../components/inputs_and_selections/input'
import { Text } from '../../../../../components/content_display/text'
import { priceCalculations } from '../../../../../code/price_calculations'
import { Badge } from '../../../../../components/indicators_and_messaging/badge'
import { type Pack } from '../../../../../code/models/packs'
import { FormLabel } from '../../../../../components/inputs_and_selections/form_label'
import { Select } from '../../../../../components/inputs_and_selections/select'
import { EmptySection } from '../empty_section'
import { Edit, Plus, Trash } from 'lucide-react'

type EditableCylinder = Pick<InventoryHotWaterCylinder, 'name' | 'cost_price' | 'markup' | 'litres' | 'default_pack_uuid'>

type Props = {
  hotWaterCylinders: InventoryHotWaterCylinder[]
  addHotWaterCylinder: (cylinder: EditableCylinder) => Promise<void>
  deleteHotWaterCylinder: (uuid: string) => Promise<void>
  updateHotWaterCylinder: (cylinder: InventoryHotWaterCylinder) => Promise<void>
  company: Company
  setCompany: (company: Company) => void
  packs: Pack[]
}

type CylinderInputsProps = {
  cylinder: EditableCylinder
  setCylinder: (cylinder: EditableCylinder) => void
  isEditing: boolean
  packs: Pack[]
}

const CylinderInputs = ({ cylinder, setCylinder, isEditing, packs }: CylinderInputsProps) => {
  const [selectedPackId, setSelectedPackId] = useState<string | undefined>(cylinder.default_pack_uuid ?? undefined)

  const packOptions = [
    { key: null, value: 'None' },
    ...packs.map(pack => ({ key: pack.uuid, value: pack.name }))
  ]

  const handleSetPack = async (packId: string | undefined) => {
    setCylinder({ ...cylinder, default_pack_uuid: packId })
    setSelectedPackId(packId)
  }

  return (
    <div className='space-y-4'>
      <Input label='Name' value={cylinder.name || ''} setValue={(value) => setCylinder({ ...cylinder, name: value })} />
      <div className="flex flex-col gap-2">
        <FormLabel labelText='Associated pack' />
        <Select placeholder="Select a pack" options={packOptions} selectedKey={selectedPackId} setSelectedKey={handleSetPack} />
        <Text size="XS" className="text-gray-500">This pack will be automatically included when this hot water cylinder is added to an estimate or quote.</Text>
      </div>
      <Input
        type="number"
        label='Cost price'
        placeholder='Enter cost price'
        value={cylinder.cost_price}
        setValue={(value) => setCylinder({ ...cylinder, cost_price: parseFloat(value) })}
        prefix='£'
      />
      <Input
        type="number"
        label='Markup'
        placeholder='Enter markup'
        value={cylinder.markup}
        setValue={(value) => setCylinder({ ...cylinder, markup: parseFloat(value) })}
        postfix='%'
      />
      <Input
        type="number"
        label='Customer price'
        placeholder='Enter customer price'
        value={priceCalculations.calculateCustomerPrice(cylinder.cost_price, cylinder.markup)}
        setValue={(value) => {
          setCylinder({ ...cylinder, markup: 0, cost_price: parseFloat(value) })
        }}
        prefix='£'
      />
      <Input type='number' label='Capacity' value={cylinder.litres || 0} postfix="L" setValue={(value) => setCylinder({ ...cylinder, litres: parseInt(value) })} />
    </div>
  )
}

type AddCylinderModalProps = {
  visible: boolean
  setVisible: (visible: boolean) => void
  addHotWaterCylinder: (cylinder: InventoryHotWaterCylinder) => Promise<void>
  packs: Pack[]
}

const AddCylinderModal = ({ visible, setVisible, addHotWaterCylinder, packs }: AddCylinderModalProps) => {
  const [newCylinder, setNewCylinder] = useState<EditableCylinder>({
    name: '',
    cost_price: 0,
    markup: 0,
    litres: 0
  })

  const handleAddCylinder = () => {
    const cylinder: InventoryHotWaterCylinder = {
      ...newCylinder,
      uuid: crypto.randomUUID()
    }
    addHotWaterCylinder(cylinder)
    setVisible(false)
    setNewCylinder({ name: '', cost_price: 0, markup: 0, litres: 0 })
  }

  return (
    <Modal
      visible={visible}
      setVisible={setVisible}
      title='Add a hot water cylinder'
      onConfirm={handleAddCylinder}
      confirmButtonLabel='Add'
    >
      <CylinderInputs cylinder={newCylinder} setCylinder={setNewCylinder} isEditing={false} packs={packs} />
    </Modal>
  )
}

type EditCylinderModalProps = {
  cylinder: InventoryHotWaterCylinder
  visible: boolean
  setVisible: (visible: boolean) => void
  updateHotWaterCylinder: (cylinder: InventoryHotWaterCylinder) => Promise<void>
  packs: Pack[]
}

const EditCylinderModal = ({ cylinder, visible, setVisible, updateHotWaterCylinder, packs }: EditCylinderModalProps) => {
  const [localCylinder, setLocalCylinder] = useState<EditableCylinder>({
    name: cylinder.name,
    cost_price: cylinder.cost_price,
    markup: cylinder.markup,
    litres: cylinder.litres
  })

  const handleUpdateCylinder = () => {
    updateHotWaterCylinder({
      ...cylinder,
      ...localCylinder
    })
    setVisible(false)
  }

  return (
    <Modal
      visible={visible}
      setVisible={setVisible}
      title='Edit hot water cylinder'
      onConfirm={handleUpdateCylinder}
      confirmButtonLabel='Save'
    >
      <CylinderInputs cylinder={localCylinder} setCylinder={setLocalCylinder} isEditing={true} packs={packs} />
    </Modal>
  )
}

export const HotWaterCylindersInventory = ({ hotWaterCylinders, addHotWaterCylinder, deleteHotWaterCylinder, updateHotWaterCylinder, packs }: Props) => {
  const [modalCylinderId, setModalCylinderId] = useState<string | undefined>()
  const [addCylinderModalVisible, setAddCylinderModalVisible] = useState(false)

  const modalCylinder = hotWaterCylinders.find(x => x.uuid === modalCylinderId)

  const cylindersColumns: Array<InventoryTableColumn<{ name: React.ReactNode, costPrice: React.ReactNode, markup: React.ReactNode, customerPrice: React.ReactNode, litres: React.ReactNode, menu: React.ReactNode }>> = [
    { key: 'name', name: 'Name' },
    { key: 'litres', name: 'Capacity (L)' },
    { key: 'costPrice', name: 'Cost price' },
    { key: 'markup', name: 'Markup' },
    { key: 'customerPrice', name: 'Customer price' },
    { key: 'menu', name: '', align: 'right' }
  ]

  return (
    <>
      <div className="space-y-6">
        <div className="flex flex-col gap-3 justify-start items-start sm:flex-row sm:justify-between sm:items-center w-full">
          <Heading size="2xl">Hot Water Cylinders</Heading>
          <Button colour='DARK' onClick={() => setAddCylinderModalVisible(true)} iconLeft={Plus}>Add Cylinder</Button>
        </div>
        {!hotWaterCylinders.length
          ? <EmptySection
              title='No hot water cylinders added'
              description='Add hot water cylinders to your inventory to create estimates and quotes for your customers.'
              button={<Button onClick={() => setAddCylinderModalVisible(true)} iconLeft={Plus}>Add hot water cylinder</Button>}
            />
          : <Section>
              <InventoryTable
                rows={hotWaterCylinders
                  .sort((a, b) => a.name.localeCompare(b.name))
                  .map(x => {
                    const packForHotWaterCylinder = packs.find(pack => pack.uuid === x.default_pack_uuid)
                    return {
                      name: <div className="flex flex-col gap-2 items-start"><Text size="SM">{x.name}</Text>{packForHotWaterCylinder && <div className='flex gap-1 items-center'>{/* <Text size="SM" bold className="text-gray-600">Associated pack:</Text> */}<Badge color="LIGHT" text={packForHotWaterCylinder.name} /></div>}</div>,
                      litres: <Input type='number' value={x.litres} setValue={(value) => updateHotWaterCylinder({ ...x, litres: parseInt(value) })} />,
                      costPrice: <Input type='number' value={x.cost_price} prefix="£" setValue={(value) => updateHotWaterCylinder(priceCalculations.updateCostPrice(x, parseFloat(value)))} />,
                      markup: <Input type='number' step={1} value={Math.round(x.markup)} setValue={(value) => updateHotWaterCylinder(priceCalculations.updateMarkup(x, parseFloat(value)))} postfix='%' />,
                      customerPrice: <Input type='number' value={priceCalculations.calculateCustomerPrice(x.cost_price, x.markup)} setValue={(value) => updateHotWaterCylinder(priceCalculations.updateCostPriceAndMarkupToMatchCustomerPrice(x, parseFloat(value)))} prefix='£' />,
                      menu: <DropdownMenu
                        items={[
                          { label: 'Edit', onClick: () => setModalCylinderId(x.uuid), icon: Edit },
                          { label: 'Delete', onClick: () => deleteHotWaterCylinder(x.uuid), icon: Trash, confirmText: 'Are you sure you want to delete this hot water cylinder?' }
                        ]} />
                    }
                  })}
                columns={cylindersColumns}
              />
            </Section>
        }
      </div>
      <AddCylinderModal
        visible={addCylinderModalVisible}
        setVisible={setAddCylinderModalVisible}
        addHotWaterCylinder={addHotWaterCylinder}
        packs={packs}
      />
      {modalCylinder && (
        <EditCylinderModal
          cylinder={modalCylinder}
          visible={!!modalCylinderId}
          setVisible={(visible) => setModalCylinderId(visible ? modalCylinderId : undefined)}
          updateHotWaterCylinder={updateHotWaterCylinder}
          packs={packs}
        />
      )}
    </>
  )
}
