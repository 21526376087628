import React, { useState } from 'react'
import { Toggle } from '../../../components/inputs_and_selections/toggle'

export const ToggleDemo = () => {
  const [toggle, setToggle] = useState(true)

  return <div className="flex flex-col gap-8">
    <div className='flex flex-col gap-4'>
      <div className='text-2xl font-bold text-gray-900'>Definition</div>

      <pre className='bg-gray-100 rounded-lg p-4 overflow-x-auto'><code>
        {codeBlock}
      </code></pre>

      <div className='flex flex-col divide-y divide-dashed divide-gray-200 text-sm'>
        {componentProps.map(x => <div key={x.name} className='grid grid-cols-2 py-2'>
          <div className='font-bold text-gray-900'>{x.name}</div>
          <div>{x.description}</div>
        </div>)}
      </div>
    </div>

    <div className='flex flex-col gap-4'>
      <div className='text-2xl font-bold text-gray-900'>Component</div>
      <Toggle
        value={toggle}
        setValue={() => setToggle(!toggle)}
        size='MD'
      />
    </div>

  </div>
}

const componentProps = [
  { name: 'value', description: 'Is the toggle enabled or not' },
  { name: 'setValue', description: 'Do something on toggle click' },
  { name: 'size', description: 'SM | MD | LG' }
]

const codeBlock = `const [toggle, setToggle] = useState(true)

<Toggle
  value={toggle}
  setValue={() => setToggle(!toggle)}
  size='MD'
/>
`
