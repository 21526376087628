import React, { useState } from 'react'
import { Heading } from '../../../../../components/content_display/heading'
import { Button } from '../../../../../components/buttons/button'
import { Modal } from '../../../../../components/containers/modal'
import { Input } from '../../../../../components/inputs_and_selections/input'
import { type Pack } from '../../../../../code/models/packs'
import { Section } from '../../../job_layout/components/section'
import { type InventoryPart, type InventoryHeatPump, type InventoryHotWaterCylinder, type PrimaryInventoryItem, type InventoryPartWithQuantity } from '../../../../../code/models/inventory'
import { Badge } from '../../../../../components/indicators_and_messaging/badge'
import { Text } from '../../../../../components/content_display/text'
import { FormLabel } from '../../../../../components/inputs_and_selections/form_label'
import { Select } from '../../../../../components/inputs_and_selections/select'
import { DropdownMenu } from '../../../../../components/buttons/dropdown_menu'
import { priceCalculations } from '../../../../../code/price_calculations'
import { Icon } from '../../../../../components/buttons/icon'
import { InventoryTable, type InventoryTableColumn } from '../inventory_table'
import { formatPrice } from '../../../../../code/format_price'
import { EmptySection } from '../empty_section'
import { Pencil, Plus, Trash, X } from 'lucide-react'

type Props = {
  packs: Pack[]
  parts: InventoryPart[]
  heatPumps: InventoryHeatPump[]
  hotWaterCylinders: InventoryHotWaterCylinder[]
  addPack: (pack: Pack, heatPumpIds: string[]) => void
  deletePack: (pack: Pack) => void
  addPartToPack: (pack: Pack, part: InventoryPartWithQuantity) => void
  removePartFromPack: (pack: Pack, part: InventoryPartWithQuantity) => void
  updatePartInPack: (pack: Pack, part: InventoryPartWithQuantity) => void
  updatePack: (pack: Pack, heatPumpIds: string[], hotWaterCylinderIds: string[]) => void
}

type AddPackModalProps = Pick<Props, 'addPack' | 'heatPumps' | 'hotWaterCylinders'> & {
  visible: boolean
  setVisible: (visible: boolean) => void
}

type EditPackModalProps = Pick<Props, 'updatePack' | 'heatPumps' | 'hotWaterCylinders'> & {
  visible: boolean
  setVisible: (visible: boolean) => void
  pack: Pack | undefined
  setEditingPack: (pack: Pack | undefined) => void
}

const AddPackModal = ({ visible, setVisible, addPack, heatPumps, hotWaterCylinders }: AddPackModalProps) => {
  const primaryInventoryItems: PrimaryInventoryItem[] = [...heatPumps, ...hotWaterCylinders]
  const [name, setName] = useState('')
  const [selectedPrimaryInventoryItemIds, setSelectedPrimaryInventoryItemIds] = useState<string[]>([])

  return (
    <Modal visible={visible} setVisible={setVisible} title='Add pack' onConfirm={() => {
      addPack({ uuid: crypto.randomUUID(), name }, selectedPrimaryInventoryItemIds)
    }}>
      <div className="space-y-4 w-full">
        <Input label='Name' placeholder='Enter pack name' value={name} setValue={setName} />
        <div className="flex flex-col gap-2">
          <FormLabel labelText='Always include this pack when adding' />
          <Text size="SM" className='text-gray-500'>The items in this pack will be automatically included when adding the following heat pumps or hot water cylinders to an estimate or quote.</Text>
          <Select
            options={primaryInventoryItems.map(item => ({ key: item.uuid, value: item.name }))}
            selectedKey={selectedPrimaryInventoryItemIds}
            setSelectedKey={setSelectedPrimaryInventoryItemIds}
            multiple
            filter
          />
        </div>
        <div className="flex flex-row gap-2 items-center flex-wrap">
          {selectedPrimaryInventoryItemIds.map(itemId => (
            <Badge
              key={itemId}
              color='LIGHT'
              text={primaryInventoryItems.find(item => item.uuid === itemId)?.name}
              button={<Icon icon={X} onClick={() => setSelectedPrimaryInventoryItemIds(prev => prev.filter(id => id !== itemId))} />}
            />
          ))}
        </div>
      </div>
    </Modal>
  )
}

const EditPackModal = ({ visible, setVisible, updatePack, heatPumps, hotWaterCylinders, pack, setEditingPack }: EditPackModalProps) => {
  if (!pack) return null

  const primaryInventoryItems: PrimaryInventoryItem[] = [...heatPumps, ...hotWaterCylinders]
  const [name, setName] = useState(pack.name)
  const [selectedPrimaryInventoryItemIds, setSelectedPrimaryInventoryItemIds] = useState<string[]>(primaryInventoryItems.filter(item => item.default_pack_uuid === pack.uuid).map(item => item.uuid))

  return (
    <Modal visible={visible} setVisible={setVisible} title={pack ? 'Edit pack' : 'Add pack'} onConfirm={() => {
      // Break the primary IDs back down into heat pump and hot water cylinder IDs
      const selectedHeatPumpIds = selectedPrimaryInventoryItemIds.filter(id => heatPumps.some(hp => hp.uuid === id))
      const selectedHotWaterCylinderIds = selectedPrimaryInventoryItemIds.filter(id => hotWaterCylinders.some(hwc => hwc.uuid === id))
      updatePack(
        { ...pack, name },
        selectedHeatPumpIds,
        selectedHotWaterCylinderIds
      )
      setEditingPack(undefined)
    }}>
      <div className="space-y-4 w-full">
        <Input label='Name' placeholder='Enter pack name' value={name} setValue={setName} />
        <div className="flex flex-col gap-2">
          <FormLabel labelText='Always include this pack when adding' />
          <Text size="SM" className='text-gray-500'>The items in this pack will be automatically included when adding the following heat pumps or hot water cylinders to an estimate or quote.</Text>
          <Select
            options={primaryInventoryItems.map(item => ({ key: item.uuid, value: item.name }))}
            selectedKey={selectedPrimaryInventoryItemIds}
            setSelectedKey={setSelectedPrimaryInventoryItemIds}
            multiple
            filter
          />
        </div>
        <div className="flex flex-row gap-2 items-center flex-wrap">
          {selectedPrimaryInventoryItemIds
            .map(itemId => (
              <Badge
                key={itemId}
                color='LIGHT'
                text={primaryInventoryItems.find(item => item.uuid === itemId)?.name}
                button={<Icon icon={X} onClick={() => setSelectedPrimaryInventoryItemIds(prev => prev.filter(id => id !== itemId))} />}
              />
            ))}
        </div>
      </div>
    </Modal>
  )
}

type PackSectionProps = Pick<Props, 'deletePack' | 'addPartToPack' | 'removePartFromPack' | 'updatePartInPack' | 'heatPumps' | 'hotWaterCylinders' | 'parts'> & {
  pack: Pack
  setEditingPack: (pack: Pack | undefined) => void
}

const PackSection = ({ pack, deletePack, addPartToPack, removePartFromPack, updatePartInPack, setEditingPack, heatPumps, hotWaterCylinders, parts }: PackSectionProps) => {
  const [selectedPartId, setSelectedPartId] = useState<string | undefined>()
  const primaryInventoryItemsIncludingPack: PrimaryInventoryItem[] = heatPumps.concat(hotWaterCylinders).filter(item => item.default_pack_uuid === pack.uuid)

  const handleAddPart = (key: string) => {
    if (!key) return
    const part = { ...parts.find(part => part.uuid === key), quantity: 1 }
    if (part) {
      addPartToPack(pack, part as InventoryPartWithQuantity)
      setSelectedPartId(undefined)
    }
  }

  const handleSetPartQuantity = (part: InventoryPart, quantity: number) => {
    updatePartInPack(pack, { ...part, quantity })
  }

  const partsTableColumns: Array<InventoryTableColumn<{ name: string | React.ReactNode, quantity: React.ReactNode, customerPrice: React.ReactNode, controls: React.ReactNode }>> = [
    { key: 'name', name: 'Name' },
    { key: 'quantity', name: 'Quantity' },
    { key: 'customerPrice', name: 'Customer price', align: 'right' },
    { key: 'controls', name: '', align: 'right' }
  ]

  const partsTableRows = [
    ...(pack.parts
      ?.sort((a, b) => a.name.localeCompare(b.name))
      .map(part => {
        return ({
          name: part.name,
          quantity: <Input type='number' value={part.quantity} setValue={(value) => handleSetPartQuantity(part, parseInt(value))} />,
          customerPrice: formatPrice(priceCalculations.calculateCustomerPrice(part.cost_price, part.markup) * part.quantity),
          controls: <Icon icon={Trash} onClick={() => removePartFromPack(pack, part)} confirmTextHeader='Are you sure you want to remove this part from the pack?' confirmTextBody='This action cannot be undone.' />
        })
      }) ?? []),
    {
      name: <Text size="MD" bold>Total</Text>,
      quantity: null,
      customerPrice: <Text size="MD" bold>{formatPrice(pack.parts?.reduce((acc, part) => acc + priceCalculations.calculateCustomerPrice(part.cost_price, part.markup) * part.quantity, 0) ?? 0)}</Text>,
      controls: null
    }
  ]

  return (
    <Section title={
      <div className='flex items-center justify-between gap-3 w-full'>
        <Heading size="xl">{pack.name}</Heading>
        <DropdownMenu
          items={[
            { label: 'Edit', onClick: () => setEditingPack(pack), icon: Pencil },
            { label: 'Delete', onClick: () => deletePack(pack), icon: Trash, confirmText: 'Are you sure you want to delete this pack?' }
          ]}
        />
      </div>}>
      <div className="flex flex-col gap-3 items-start">
        <div className="flex flex-row gap-2 items-center flex-wrap">
          {primaryInventoryItemsIncludingPack.length > 0 &&
            <>
              <Text size="SM" bold>Included with:</Text>
              {primaryInventoryItemsIncludingPack
                .sort((a, b) => a.name.localeCompare(b.name))
                .map(item => (
                  <Badge key={item.uuid} color='LIGHT' text={item.name} />
                ))}
            </>
          }
        </div>
        {pack.parts?.length
          ? <InventoryTable
              columns={partsTableColumns}
              rows={partsTableRows}
            />
          : <EmptySection title="No parts added" description="Add parts to this pack to automatically add them to estimates and quotes." />}
        <Select placeholder='Add part' options={parts
          .filter(part => !pack.parts?.some(packPart => packPart.uuid === part.uuid))
          .map(part => ({ key: part.uuid, value: `${part.name} - ${formatPrice(priceCalculations.calculateCustomerPrice(part.cost_price, part.markup))}` }))
        }
        selectedKey={selectedPartId}
        setSelectedKey={handleAddPart}
        filter
        />
      </div>
    </Section>
  )
}

export const PacksInventory = ({ addPack, deletePack, updatePack, addPartToPack, removePartFromPack, updatePartInPack, packs, parts, heatPumps, hotWaterCylinders }: Props) => {
  const [addPackModalVisible, setAddPackModalVisible] = useState(false)
  const [editingPack, setEditingPack] = useState<Pack | undefined>()

  return (
    <>
      <div className="space-y-6">
        <div className="flex flex-col gap-3 justify-start items-start sm:flex-row sm:justify-between sm:items-center w-full">
          <div className='flex flex-col gap-1 w-full lg:w-3/4'>
            <Heading size="2xl">Packs</Heading>
            <Text>
              Packs allow you to link one or more items to an inventory item.
              For example, you can create a pack for a specific heat pump and include that pack every time you include the heat pump on a quote.
            </Text>
          </div>
          <Button colour='DARK' onClick={() => setAddPackModalVisible(true)} iconLeft={Plus}>Add pack</Button>
        </div>
        {!packs.length
          ? <EmptySection
              title="Create a parts pack"
              description="Add packs to automatically add parts to estimates and quotes."
              button={<Button colour='DARK' onClick={() => setAddPackModalVisible(true)} iconLeft={Plus}>Add pack</Button>}
            />
          : packs
            .sort((a, b) => a.name.localeCompare(b.name))
            .map(pack => <PackSection
              key={pack.uuid}
              pack={pack}
              heatPumps={heatPumps}
              hotWaterCylinders={hotWaterCylinders}
              deletePack={deletePack}
              parts={parts}
              addPartToPack={addPartToPack}
              removePartFromPack={removePartFromPack}
              updatePartInPack={updatePartInPack}
              setEditingPack={setEditingPack}
            />)
        }
      </div>
      <AddPackModal addPack={addPack} visible={addPackModalVisible || !!editingPack} setVisible={setAddPackModalVisible} heatPumps={heatPumps} hotWaterCylinders={hotWaterCylinders} />
      <EditPackModal visible={!!editingPack} setVisible={() => setEditingPack(undefined)} heatPumps={heatPumps} hotWaterCylinders={hotWaterCylinders} updatePack={updatePack} pack={editingPack} setEditingPack={setEditingPack} />
    </>
  )
}
