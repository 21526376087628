import React from 'react'
import { type SurveyImage } from '../../../../code/models/property'
import { type FileWrapper } from '../../file_wrapper'
import { Check } from 'lucide-react'
import { SurveySiteDetailsContext } from '../site_details'
import { FlowWrapperContext } from '../components/survey_section_flow_wrapper'
import { Badge } from '../../../../components/indicators_and_messaging/badge'
import { PhotoScroll } from '../../../../components/inputs_and_selections/photo_scroll'

export const SiteDetailsPhotosBlock = () => {
  const prContext = React.useContext(SurveySiteDetailsContext)
  const flowContext = React.useContext(FlowWrapperContext)
  const { survey, setSurvey, files, setFiles, companyUUID } = prContext!

  const propertyImagesHydrated = survey.property_images.map(x => files.find(y => y.uuid === x.image_uuid)!)
  const setPropertyImages = (images: SurveyImage[]) => setSurvey({ ...survey, property_images: images })

  const isFullScreen = propertyImagesHydrated.length === 0 && flowContext!.viewMode === 'FLOW'

  return <>
    <div className={isFullScreen ? 'p-5 h-full bg-gray-50 flex flex-col items-center justify-center gap-4' : 'flex flex-col gap-4'}>
      <div className={`${isFullScreen ? 'items-center' : ''} flex flex-col gap-1`}>
        <div className="text-gray-900 text-xl font-bold">Property location</div>
        <div className={`${isFullScreen ? 'text-center' : ''} text-gray-500 text-sm`}>
          Add photos of the property and the surrounding area
        </div>
      </div>

      <div className="flex-col gap-3 flex">
        {!isFullScreen &&
          <div className='flex justify-between'>
            <div className="text-gray-900 font-bold">Photos</div>
            <Badge color={prContext!.survey.property_images.length > 0 ? 'GREEN' : 'YELLOW'} text='Required'
              icon={survey.property_images.length > 0 ? Check : undefined}/>
          </div>
        }

        <PhotoScroll
          viewMode={isFullScreen ? 'COMPACT' : 'NORMAL'}
          images={propertyImagesHydrated}
          deleteImage={(imageUUID: string) => {
            setPropertyImages(survey.property_images.filter(x => x.image_uuid !== imageUUID))
          }}
          addImage={(image: FileWrapper) => {
            setFiles(prev => [...prev, { ...image, created_at: new Date().getTime(), updated_at: new Date().getTime(), is_modified: true }])
            const newImage: SurveyImage = { uuid: crypto.randomUUID(), image_uuid: image.uuid!, entity_uuid: 'survey', entity_attribute: 'property_images' }
            setPropertyImages([...survey.property_images, newImage])
          }}
          companyUUID={companyUUID}
        />
      </div>
    </div>
  </>
}
