import React from 'react'
import { ClickableCard } from '../../../components/content_display/card'
import { sum } from 'lodash'
import { ProgressChart } from '../../../components/indicators_and_messaging/progress_chart'
import { type TableColumn, TableLite } from '../../../components/content_display/table_lite'
import {
  type ConductionHeatLoss,
  type VentilationHeatLoss,
  getConductionHeatLossAllElements,
  getVentilationHeatLoss,
  getFloorAreaM2,
  getRoomTemp,
  combineSortConductionHeatLosses,
  combineHeatLossesForProgressChart, getRoomWatts

} from '../../../code/models/heat_loss'
import { type Room } from '../../../code/models/room'
import { type SurveyDesign, type PropertySurvey } from '../../../code/models/property'
import { type EmitterOutputRow, getEmitterOutputRow } from '../../../code/models/radiator'
import { FlowTempSlider } from '../design/pages/emitter_design_page'
import { getEmitterOutputVsDemandText } from './code/utils'

const conductionHeatLossColumns: Array<TableColumn<ConductionHeatLoss>> = [
  { key: 'elementName', name: '' },
  { key: 'otherSideTempC', name: 'Other side temp', render: (row) => <div>{row.otherSideTempC} °C</div> },
  { key: 'uValueWPerM2K', name: 'U-value (W/m²K)', render: (row) => <div>{row.uValueWPerM2K.toFixed(2)}</div> },
  { key: 'areaM2', name: 'Area', render: (row) => <div>{row.areaM2.toFixed(1)} m²</div> },
  { key: 'watts', name: 'Heat loss', render: (row) => <div>{row.watts.toFixed(0)} W</div> }
]

const ventilationHeatLossColumns: Array<TableColumn<VentilationHeatLoss>> = [
  { key: 'elementName', name: '' },
  { key: 'heatRecoveryPercentage', name: 'Heat recovery', render: (row) => <div>{row.heatRecoveryPercentage * 100} %</div> },
  { key: 'ACH', name: 'ACH', render: (row) => <div>{row.ACH}</div> },
  { key: 'volumeM3', name: 'Volume', render: (row) => <div>{row.volumeM3.toFixed(1)} m³</div> },
  { key: 'watts', name: 'Heat loss', render: (row) => <div>{row.watts.toFixed(0)} W</div> }
]

const emitterColumns: Array<TableColumn<EmitterOutputRow>> = [
  { key: 'typeName', name: '' },
  { key: 'size', name: 'Size', render: (row) => <div>{row.size}</div> },
  { key: 'velocityMPerS', name: 'Velocity', render: (row) => <div>{row.velocityMPerS ? row.velocityMPerS.toFixed(2) + ' m/s' : ''} </div> },
  { key: 'watts', name: 'Output', render: (row) => <div>{row.watts} W</div> }
]

type RoomHeatLossPageProps = {
  room: Room
  roomsThisFloor: Room[]
  designTempC: number
  groundTempC: number
  flowTemp: number
  setFlowTemp: (value: number) => void
  minFlowTemp: number
  maxFlowTemp: number
  deltaTFlowReturnC: number
  survey: PropertySurvey
  design: SurveyDesign
}

export const RoomHeatLossPage = ({ room, roomsThisFloor, designTempC, groundTempC, flowTemp, setFlowTemp, minFlowTemp, maxFlowTemp, deltaTFlowReturnC, survey, design }: RoomHeatLossPageProps) => {
  const otherRoomsInGroup = roomsThisFloor.filter(x => x.room_group_uuid !== undefined && x.room_group_uuid === room.room_group_uuid && x.uuid !== room.uuid)

  const roomTempC = getRoomTemp(room, survey)
  const conductionHeatLossRows = getConductionHeatLossAllElements(room, roomsThisFloor, designTempC, groundTempC, survey)
  const ventilationHeatLoss = getVentilationHeatLoss(room, designTempC, survey)

  const heatLossTotalW = sum(conductionHeatLossRows.map(x => x.watts)) + ventilationHeatLoss.watts
  const heatLossOtherRoomsW = sum(otherRoomsInGroup.map(x => getRoomWatts(x, roomsThisFloor, designTempC, groundTempC, survey)))
  const heatLossTotalRoomGroupW = heatLossTotalW + heatLossOtherRoomsW

  const heatLossTotalWRounded = Math.round(heatLossTotalW)
  const floorAreaM2 = getFloorAreaM2(room.walls)
  const wattsPerM2 = (heatLossTotalW / floorAreaM2).toFixed(0)

  const heatLossForChart = combineHeatLossesForProgressChart(conductionHeatLossRows, [ventilationHeatLoss])

  const conductionHeatLossRowsCombinedForTable = combineSortConductionHeatLosses(conductionHeatLossRows)

  const emitterRowsThisRoom = room.radiators
    .map(x => getEmitterOutputRow(x, room, survey, design, designTempC, groundTempC))
  const emitterRowsOtherRooms = otherRoomsInGroup.flatMap(r => r.radiators
    .map(x => getEmitterOutputRow(x, room, survey, design, designTempC, groundTempC)))

  const emitterRows = [...emitterRowsThisRoom, ...emitterRowsOtherRooms]
  const emitterTotalW = sum(emitterRows.map(x => x.watts))

  return <div className="p-5 bg-white flex-col gap-6 flex flex-grow overflow-y-auto">
    <div className="flex-col gap-4 flex">
      {/* Key data boxes */}
      {/* Initially used clickable card for these boxes but they looked terrible because the gaps were too big */}
      <div className="gap-2 flex">
        <div className='gap-1 rounded-md flex flex-col bg-gray-50 flex-grow p-2'>
          <div className="text-gray-900 text-xs font-bold">Room temp</div>
          <div className="text-gray-600 text-base">{roomTempC} °C</div>
        </div>
        <div className='gap-1 rounded-md flex flex-col bg-gray-50 flex-grow p-2'>
          <div className="text-gray-900 text-xs font-bold">W/m²</div>
          <div className="text-gray-600 text-base">{wattsPerM2}</div>
        </div>
        <div className='gap-1 rounded-md flex flex-col bg-gray-50  flex-grow p-2'>
          <div className="text-gray-900 text-xs font-bold">ACH</div>
          <div className="text-gray-600 text-base">{ventilationHeatLoss.ACH}</div>
        </div>
      </div>

      {/* Chart */}
      <ClickableCard variant='GREY'>
        <div className='flex flex-col gap-2'>
          <div className='flex justify-between text-lg'>
            <div className='text-gray-900 font-bold'>Heat loss</div>
            <div>{heatLossTotalWRounded} W</div>
          </div>
          <ProgressChart total={heatLossTotalW}
            items={heatLossForChart}/>
        </div>
      </ClickableCard>

      {/* Table */}
      <div className="gap-4 flex flex-col">
        <TableLite columns={conductionHeatLossColumns} rows={conductionHeatLossRowsCombinedForTable}/>
        <TableLite columns={ventilationHeatLossColumns} rows={[ventilationHeatLoss]}/>
        {/* Total */}
        <div className='flex justify-between text-gray-900 font-bold px-2'>
          <div>Total</div>
          <div>{heatLossTotalWRounded} W</div>
        </div>
        {survey.intermittent_heating && <div className='text-xs text-gray-500 italic font-light'>{`* Intermittent heating correction factor of ${survey.intermittent_heating_oversize_factor_percentage}% applied`}</div>}
      </div>
    </div>

    {emitterRows.length > 0 && <div className='flex flex-col gap-1'>
      <div className='text-gray-900 font-bold text-lg'>Heat output</div>
      {otherRoomsInGroup.length > 0 && <div className='text-sm'>This room is sharing its emitters with {otherRoomsInGroup.map(x => x.name).join(', ')}</div>}
    </div>}

    {/*  Flow temp slider */}
    {(emitterRows.length > 0) && <div className='flex-col gap-2 flex'>
      <ClickableCard border={false} variant='GREY'>
        <div className='flex flex-col gap-4 justify-between flex-grow'>
          <FlowTempSlider
            flowTemp={design.flow_temp}
            setFlowTemp={setFlowTemp}
            minFlowTemp={minFlowTemp}
            maxFlowTemp={maxFlowTemp}
          />
        </div>
      </ClickableCard>
    </div>
    }

    {/* Emitters */}
    {(emitterRows.length > 0) && <div className="flex-col gap-4 flex">
      {/* Chart */}
      <ClickableCard variant='GREY'>
        <div className='flex flex-col gap-2'>
          <div className='flex justify-between text-lg'>
            <div className='text-gray-900 font-bold'>Emitters</div>
            <div>{getEmitterOutputVsDemandText(emitterTotalW, heatLossTotalRoomGroupW)}</div>
          </div>
          <ProgressChart total={heatLossTotalRoomGroupW}
            items={emitterRows.map(x => ({ value: x.watts, name: x.typeName }))}
            colorVariant={'EMITTER'}
          />
        </div>
      </ClickableCard>

      {/* Table - emitters */}
      <TableLite columns={emitterColumns} rows={emitterRows}/>
      {/* Total */}
      <div className='flex flex-col gap-1'>
        <div className='flex justify-between text-gray-900 font-bold px-2'>
          <div>Total</div>
          <div>{sum(emitterRows.map(x => x.watts))} W</div>
        </div>
      </div>
    </div>}
  </div>
}
