import React from 'react'
import { SubHeader } from './sub_header'
import { CheckCircleIcon } from 'lucide-react'

export const NextStepsComponent = ({ isScottish, isChecked }: { isScottish: boolean, isChecked: boolean }) => {
  return <div className="w-full flex flex-col justify-start items-start gap-2">
    <SubHeader content="Next steps"/>

    {/* Common step */}
    <div className="self-stretch justify-start items-start gap-2 inline-flex">
      {isChecked ? <CheckCircleIcon className='w-6 h-6 text-green-500' /> : <CheckCircleIcon className='w-6 h-6 text-gray-400' />}
      <div
        className={`grow shrink basis-0 text-gray-600 text-base font-medium ${isChecked ? 'line-through' : ''}`}>
        Initial cost estimate
      </div>
    </div>

    {/* For Scottish enquiries */}
    { isScottish && <>
      <div className="self-stretch justify-start items-start gap-2 inline-flex">
        <CheckCircleIcon className="w-6 h-6 text-gray-400"/>
        <div className="grow shrink basis-0 text-gray-600 text-base font-medium">Home Energy Improvement
            Report from Home Energy Scotland
        </div>
      </div>
      <div className="self-stretch justify-start items-start gap-2 inline-flex">
        <CheckCircleIcon className="w-6 h-6 text-gray-400"/>
        <div className="grow shrink basis-0 text-gray-600 text-base font-medium">Detailed room-by-room heat
            loss survey and full proposal
        </div>
      </div>
      <div className="self-stretch justify-start items-start gap-2 inline-flex">
        <CheckCircleIcon className="w-6 h-6 text-gray-400"/>
        <div className="grow shrink basis-0 text-gray-600 text-base font-medium">Apply for funding from Home
            Energy Scotland
        </div>
      </div>
      <div className="self-stretch justify-start items-start gap-2 inline-flex">
        <CheckCircleIcon className="w-6 h-6 text-gray-400"/>
        <div className="grow shrink basis-0 text-gray-600 text-base font-medium">Installation</div>
      </div>
      <div className="text-sm mt-5">
        Please note some of these steps can happen concurrently - your installer can explain in more detail.
      </div>
    </>}

    {/* For non-Scottish enquiries */}
    {!isScottish && <>
      <div className="self-stretch justify-start items-start gap-2 inline-flex">
        <CheckCircleIcon className="w-6 h-6 text-gray-400"/>
        <div
          className="grow shrink basis-0 text-gray-600 text-base font-medium  ">Full
                heat loss survey
        </div>
      </div>
      <div className="self-stretch justify-start items-start gap-2 inline-flex">
        <CheckCircleIcon className="w-6 h-6 text-gray-400"/>
        <div
          className="grow shrink basis-0 text-gray-600 text-base font-medium  ">Your
          heat pump is installed
        </div>
      </div>
    </>}
  </div>
}
