export type Location3D = {
  lat: number
  lng: number
  altitudeM: number
}

export const geocodeAddress = async (address: string, postcode: string): Promise<Location3D | undefined> => {
  // Try to geocode the address, if that fails, geocode the postcode. Geocoding postcode is more likely to succeed.
  const svc = new google.maps.Geocoder()
  const elevationService = new google.maps.ElevationService()

  let locationResult: google.maps.GeocoderResponse
  try {
    try {
      locationResult = await svc.geocode({ address, componentRestrictions: { postalCode: postcode } })
    } catch (e) {
      locationResult = await svc.geocode({ address: postcode })
      // Postcode result can individually succeed when this fails.
      //   TODO: in this case should we add a flag on the result so we don't use this to display the property location on the map?
      //   Or is it still useful to show the middle of the postcode to the user to give them a sense of where it is?
    }
    const location = locationResult.results[0].geometry.location
    const elevationResult = await elevationService.getElevationForLocations({ locations: [location] })
    const latLng = location.toJSON()
    // Round altitude so displays nicely and because that level of precision isn't required or reasonable
    return { lat: latLng.lat, lng: latLng.lng, altitudeM: Number(elevationResult.results[0].elevation.toFixed(2)) }
  } catch (e) {
    console.error('Geocoding address failed', e)
  }
}

export const convertLatLngListToLatLongLiteral = (latLngList: number[] | undefined): google.maps.LatLngLiteral | undefined => {
  // We should just loose this list of lat/longs. But until we have, add a utility function to convert them to the google.maps.LatLngLiteral format
  if (!latLngList) { return undefined }
  return { lat: latLngList[0], lng: latLngList[1] }
}
