import React, { type PropsWithChildren } from 'react'
import { OfflineBlock } from '../../particles/offline_block'
import { ProgressBar } from '../../../survey/components/progress_bar'
import { Icon } from '../../../../components/buttons/icon'
import { Link } from '../../../../components/buttons/link'
import { ChevronLeft, X } from 'lucide-react'

type FlowPageHeaderProps = {
  title: string
  progress?: number
  onBack: () => void
  onClose?: () => void
  onSkip?: () => void
}
export const FlowPageHeader = ({ title, progress, onBack, onClose, onSkip }: PropsWithChildren<FlowPageHeaderProps>) => {
  return <>
    {/* no bottom border if there is a progress bar */}
    <div className={`${progress ? '' : 'border-b border-gray-300'} px-5 pt-4 pb-3 gap-2 flex flex-col bg-white`}>
      <div className='flex justify-between'>
        <div className='flex gap-4 items-center cursor-pointer' onClick={onBack}>
          <Icon icon={ChevronLeft} />
          <div className='text-gray-900 text-xl font-bold'>{title}</div>
        </div>
        <div className='flex gap-3 items-center'>
          { onSkip && <Link text='Skip' onClick={onSkip} className='font-bold no-underline' /> }
          { onClose && <Icon onClick={onClose} icon={X} /> }
        </div>
      </div>
    </div>
    { progress && <ProgressBar progress={progress} /> }
    <OfflineBlock />
  </>
}
