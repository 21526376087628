import React, { type Dispatch, useState } from 'react'
import {
  type Material,
  type MaterialLayer,
  updateDefaultMaterial
} from '../../../code/models/material'
import { MaterialsSelector, type MaterialsSelectorGroupedProps } from './materials_selector'
import { PageHeader } from '../design/components/design_page_header'
import type { PropertySurvey } from '../../../code/models/property'
import { MaterialInputField } from './material_input_field'
import { CompleteButton } from '../survey/complete_button'
import { type CurrentFloorPlanPage } from '../floor/floor'
import { CustomMaterialPopup } from './custom_material_popup'
import { type SetIndexedDb } from '../../admin/job_layout/job_layout'

type MaterialsTabProps = {
  survey: PropertySurvey
  setSurvey: SetIndexedDb<PropertySurvey>
  materials: Material[]
  setMaterials: Dispatch<React.SetStateAction<Material[]>>
  materialsLayers: MaterialLayer[]
  companyUUID: string
  isOffline: boolean
  allSynced: boolean
}

export const DefaultMaterialsTab = ({
  materials,
  setMaterials,
  setSurvey,
  survey,
  materialsLayers,
  companyUUID,
  isOffline,
  allSynced
}: MaterialsTabProps) => {
  const selectedMaterialsSet = survey.default_materials
  // materials selector props
  const [selectorProps, setSelectorProps] = useState<MaterialsSelectorGroupedProps>()
  const [innerPages, setInnerPages] = useState<Array<CurrentFloorPlanPage | 'MATERIAL_HOME' | 'CUSTOM_MATERIAL'>>(['MATERIAL_HOME'])
  const [tempCustomMaterial, setTempCustomMaterial] = useState<Material>()
  const [customMaterialHeader, setCustomMaterialHeader] = useState<JSX.Element>()
  const innerPage = innerPages[innerPages.length - 1]
  const setInnerPage = (page: CurrentFloorPlanPage | 'MATERIAL_HOME' | 'CUSTOM_MATERIAL') => setInnerPages(prev => [...prev, page])

  const page = 'MATERIALS'

  const handleMaterialUpdate = (material: Material) => {
    return updateDefaultMaterial(survey, setSurvey, material)
  }

  if (!selectedMaterialsSet || !materials) return null

  if (innerPage === 'MATERIALS') {
    return <div className='flex flex-col h-full min-h-0'>
      <PageHeader isOffline={isOffline} allSynced={allSynced} title='Choose a material' onBack={() => {
        setInnerPages(prev => prev.slice(0, -1))
      }} />
      <div className='overflow-y-auto'>
        <MaterialsSelector
          groupedProps={selectorProps!}
          setGroupedProps={setSelectorProps}
          setMaterialsCallback={setMaterials}
          setPage={setInnerPage}
          onBack={() => setInnerPages(prev => prev.slice(0, -1))}
          setTempCustomMaterial={setTempCustomMaterial}
          companyUUID={companyUUID}
        />
      </div>
    </div>
  }

  if (innerPage === 'CUSTOM_MATERIAL') {
    return <div className='flex flex-col h-full'>
      <div className='px-5 pt-4 pb-3 gap-2 flex flex-col border-b border-gray-300 bg-white'>
        {customMaterialHeader}
      </div>
      <div className='overflow-y-auto'>
        <CustomMaterialPopup
          material={tempCustomMaterial}
          materialsLayers={materialsLayers}
          setMaterial={setTempCustomMaterial}
          onSave={(newMaterial) => {
            // NB! do not override materials with the `materials` variable!
            // because it contains filtered values for the current surface and will override the global materials list
            setMaterials(prev => ([...prev, newMaterial]))
            setSelectorProps(prev => ({ ...prev!, materials: [...prev!.materials!, newMaterial], selectedMaterial: newMaterial }))
            if (selectorProps!.onSelectCallback) {
              // trigger global callback
              selectorProps!.onSelectCallback(newMaterial)
            }
          }}
          setVisible={() => setInnerPages(prev => prev.slice(0, -1))}
          setHeader={setCustomMaterialHeader}
          onBack={() => setInnerPages(prev => prev.slice(0, -1))}
        />
      </div>
    </div>
  }

  return <div className='flex flex-col h-full'>
    <PageHeader isOffline={isOffline} allSynced={allSynced} title='Materials' onBack={() => window.history.back()} completed={survey.completed_sections.includes(page)} />

    {/* Page content */}
    <div className={'p-5 w-full bg-white flex-col justify-start items-start gap-6 inline-flex overflow-y-auto flex-grow'}>

      {/* Header */}
      <div className="flex-col justify-start items-start gap-3 flex">
        <div className="text-gray-900 text-xl font-bold">Default materials</div>
        <div className="">
          Set default materials for the property. You can override these in each room.
        </div>
      </div>

      {/* Walls */}
      <div className="self-stretch flex-col justify-start items-start gap-5 flex">
        <div className="self-stretch text-gray-900 text-xl font-bold">Walls</div>

        {/* External wall */}
        <div className="w-full flex-col justify-start items-start gap-2 flex">
          <div className="self-stretch font-bold text-gray-900">External wall</div>
          <MaterialInputField
            selectorProps={{
              title: 'External wall',
              surfaceType: 'external-wall',
              materials,
              ageBand: survey.age_band,
              selectedMaterial: selectedMaterialsSet.externalWall,
              onSelectCallback: handleMaterialUpdate
            } satisfies MaterialsSelectorGroupedProps}
            setMsProps={setSelectorProps}
            setPage={setInnerPage}
          />
        </div>

        {/* Party wall */}
        <div className="w-full flex-col justify-start items-start gap-2 flex">
          <div className="self-stretch font-bold text-gray-900">Party wall</div>
          <MaterialInputField
            selectorProps={{
              title: 'Party wall',
              surfaceType: 'party-wall',
              materials,
              ageBand: survey.age_band,
              selectedMaterial: selectedMaterialsSet.partyWall,
              onSelectCallback: handleMaterialUpdate
            } satisfies MaterialsSelectorGroupedProps}
            setMsProps={setSelectorProps}
            setPage={setInnerPage}
          />
        </div>

        {/* Internal wall */}
        <div className="w-full flex-col justify-start items-start gap-2 flex">
          <div className="self-stretch font-bold text-gray-900">Internal wall</div>
          <MaterialInputField
            selectorProps={{
              title: 'Internal wall',
              surfaceType: 'internal-wall',
              materials,
              ageBand: survey.age_band,
              selectedMaterial: selectedMaterialsSet.internalWall,
              onSelectCallback: handleMaterialUpdate
            } satisfies MaterialsSelectorGroupedProps}
            setMsProps={setSelectorProps}
            setPage={setInnerPage}
          />
        </div>
      </div>

      <div className="self-stretch h-px border border-gray-200"></div>

      {/* Floors */}
      <div className="self-stretch flex-col justify-start items-start gap-5 flex">
        <div className="self-stretch text-gray-900 text-xl font-bold">Floors</div>

        {/* Ground floor */}
        <div className="w-full flex-col justify-start items-start gap-2 flex">
          <div className="self-stretch font-bold text-gray-900">Ground floor</div>
          <MaterialInputField
            selectorProps={{
              title: 'Ground floor',
              surfaceType: 'ground-floor',
              materials,
              ageBand: survey.age_band,
              selectedMaterial: selectedMaterialsSet.groundFloor,
              onSelectCallback: handleMaterialUpdate
            } satisfies MaterialsSelectorGroupedProps}
            setMsProps={setSelectorProps}
            setPage={setInnerPage}
          />
        </div>

        {/* Intermediate floor/ceiling */}
        <div className="w-full flex-col justify-start items-start gap-2 flex">
          <div className="self-stretch font-bold text-gray-900">Intermediate floors/ceilings</div>
          <MaterialInputField
            selectorProps={{
              title: 'Intermediate floors/ceilings',
              surfaceType: 'intermediate-floor-and-ceiling',
              materials,
              ageBand: survey.age_band,
              selectedMaterial: selectedMaterialsSet.intermediateFloorAndCeiling,
              onSelectCallback: handleMaterialUpdate
            } satisfies MaterialsSelectorGroupedProps}
            setMsProps={setSelectorProps}
            setPage={setInnerPage}
          />
        </div>
      </div>

      {/* Divider */}
      <div className="self-stretch h-px border border-gray-200"></div>

      {/* Roof */}
      <div className="self-stretch flex-col justify-start items-start gap-5 flex">
        <div className="self-stretch text-gray-900 text-xl font-bold">Roof</div>
        <MaterialInputField
          selectorProps={{
            title: 'Roof',
            surfaceType: 'roof',
            materials,
            ageBand: survey.age_band,
            selectedMaterial: selectedMaterialsSet.roof,
            onSelectCallback: handleMaterialUpdate
          } satisfies MaterialsSelectorGroupedProps}
          setMsProps={setSelectorProps}
          setPage={setInnerPage}
        />
      </div>

      {/* Divider: show only if there is a section below */}
      <div className="self-stretch h-px border border-gray-200"></div>

      {/* Door */}
      <div className="self-stretch flex-col justify-start items-start gap-5 flex">
        <div className="self-stretch text-gray-900 text-xl font-bold">Doors</div>
        <MaterialInputField
          selectorProps={{
            title: 'Door',
            surfaceType: 'door',
            materials,
            ageBand: survey.age_band,
            selectedMaterial: selectedMaterialsSet.door,
            onSelectCallback: handleMaterialUpdate
          } satisfies MaterialsSelectorGroupedProps}
          setMsProps={setSelectorProps}
          setPage={setInnerPage}
        />
      </div>

      {/* Divider */}
      <div className="self-stretch h-px border border-gray-200"></div>

      {/* Windows */}
      <div className="self-stretch flex-col justify-start items-start gap-5 flex">
        <div className="self-stretch text-gray-900 text-xl font-bold">Windows</div>
        <MaterialInputField
          selectorProps={{
            title: 'Window',
            surfaceType: 'window',
            materials,
            ageBand: survey.age_band,
            selectedMaterial: selectedMaterialsSet.window,
            onSelectCallback: handleMaterialUpdate
          } satisfies MaterialsSelectorGroupedProps}
          setMsProps={setSelectorProps}
          setPage={setInnerPage}
        />
      </div>
    </div>

    <CompleteButton page={page} pageChecks={[]} survey={survey} setSurvey={setSurvey} onBack={async () => window.history.back()} />
  </div>
}
