import React from 'react'
import { Icon } from '../../../components/buttons/icon'
import { Trash } from 'lucide-react'

export const IconDemo = () => {
  return <div className="flex flex-col gap-8">
    <div className='flex flex-col gap-4'>
      <div className='text-2xl font-bold text-gray-900'>Definition</div>

      <pre className='bg-gray-100 rounded-lg p-4 overflow-x-auto'><code>
        {codeBlock}
      </code></pre>

      <div className='flex flex-col divide-y divide-dashed divide-gray-200 text-sm'>
        {componentProps.map(x => <div key={x.name} className='grid grid-cols-2 py-2'>
          <div className='font-bold text-gray-900'>{x.name}</div>
          <div>{x.description}</div>
        </div>)}
      </div>
    </div>

    <div className='flex flex-col gap-4'>
      <div className='text-2xl font-bold text-gray-900'>Component</div>
      <Icon icon={Trash} />
    </div>

  </div>
}

const componentProps = [
  { name: 'icon', description: 'Font awesome icon the component should use' },
  { name: 'onClick', description: 'Action to perform when clicked, shows cursor or not if populated' },
  { name: 'confirmTextHeader', description: 'If populated, show a confirmation modal with the specified text in the title before actioning the onClick' },
  { name: 'confirmTextBody', description: 'Text to put in body of the modal. Defaults to `This action cannot be undone`. ' },
  { name: 'colour', description: 'The stroke colour of the icon' },
  { name: 'className', description: 'Any additional styling or positioning required' },
  { name: 'disabled', description: 'If the icon is disabled or not' }
]

const codeBlock = `<Icon
    icon={Trash}
    onClick={noop}
    confirmTextHeader=''
    confirmTextBody='This action cannot be undone.'
    colour=''
    className=''
    disabled={false}
/>`
