import React from 'react'
import { type PropsWithChildren } from 'react'

type MenuSectionProps = {
  name: string
}

export const MenuSection = ({ name, children }: PropsWithChildren<MenuSectionProps>) => {
  return <div className='flex flex-col gap-3'>
    <div className="text-light text-xs font-semibold uppercase leading-none tracking-wide">{name}</div>
    <div className='flex flex-col gap-1'>
      {children}
    </div>
  </div>
}
