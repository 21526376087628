import React, { type Dispatch, type SetStateAction, useEffect, useState } from 'react'
import type { JSONContent } from '@tiptap/react'
import { type CalculatedEstimate, insertCalculatedEstimate } from '../../../../../code/models/calculated_estimate'
import { getCompanyEstimateCoverNote, getCompanyEstimateEmailTemplate } from '../../../../../code/models/company'
import {
  renderHTMLReplacingPlaceholders,
  renderJSONReplacingPlaceholders,
  TTPlaceholderCarbonSaving,
  TTPlaceholderCompanyName,
  TTPlaceholderCompanyPhone,
  TTPlaceholderCustomerAddress,
  TTPlaceholderCustomerName,
  TTPlaceholderEstimateContactName,
  TTPlaceholderEstimateContactPhone,
  TTPlaceholderHeatPumpModel,
  TTPlaceholderInstallerName,
  TTPlaceholderViewEstimate
} from '../../../../../code/tiptap_placeholders'
import { numberFormat } from '../../../../../code/number_format'
import { formatPrice } from '../../../../../code/format_price'
import { Modal } from '../../../../../components/containers/modal'
import { logEvent } from '../../../../../code/log_event'
import { sendEstimate, type Lead } from '../../../../../code/models/lead'
import { Tiptap } from '../../../../../components/inputs_and_selections/tiptap'
import type { AdminContextType } from '../../../admin_layout'
import { calculateQuote } from '../../../../../code/calculate_quote'
import { DEFAULT_ESTIMATE_RAD_CHANGE_PERCENTAGE, type Estimate } from '../../../../../code/calculate_estimate'
import { sum } from 'lodash'
import { Text } from '../../../../../components/content_display/text'
import { VerticalFormGroup } from '../../../../../components/inputs_and_selections/vertical_form_group'
import { FormLabel } from '../../../../../components/inputs_and_selections/form_label'
import { ContactCardWrapper } from '../../../../estimate_page'
import { Heading } from '../../../../../components/content_display/heading'
import { getApproxNumberOfRadiatorChanges } from '../../../../../code/models/estimated_rooms_and_radiators'
import { TabGroup } from '../../../../../components/content_display/tab'

type SendEstimateModalBlockProps = {
  setEstimates: Dispatch<SetStateAction<CalculatedEstimate[]>>
  setIsVisible: Dispatch<SetStateAction<boolean>>
  adminContext: AdminContextType
  estimate: Estimate
  calculatedEstimate: CalculatedEstimate
  lead: Lead
  setLead: (lead: Lead) => void
}

export const SendEstimateModalBlock = ({
  setEstimates,
  setIsVisible,
  adminContext,
  estimate,
  calculatedEstimate,
  lead,
  setLead
}: SendEstimateModalBlockProps) => {
  const [customCoverNoteJSON, setCustomCoverNoteJSON] = useState<JSONContent>()
  const [customEmailJSON, setCustomEmailJSON] = useState<JSONContent>()
  const [overrideCoverNoteJSON, setOverrideCoverNoteJSON] = useState(false) // Flag to mark when cover note is edited
  const [currentTab, setCurrentTab] = useState<'email' | 'cover_note'>('email')

  const [calculatedQuote] = calculateQuote({
    company: adminContext.data.company!,
    selectedHeatPump: estimate.heatPump,
    selectedHotWaterCylinder: estimate.hotWaterCylinder,
    defaultRadiatorChanges: getApproxNumberOfRadiatorChanges(lead, adminContext.data.company?.estimate_default_radiator_change_percentage ?? DEFAULT_ESTIMATE_RAD_CHANGE_PERCENTAGE),
    parts: adminContext.data.parts!,
    labour: adminContext.data.labour!,
    packs: adminContext.data.packs!,
    isScottish: estimate.isScottish,
    override: lead.estimate_quote_items || undefined
  })

  useEffect(() => {
    if (!adminContext.data?.company) return
    // At the point of opening the modal, we regenerate the estimate email and cover note content.

    const companyEstimateEmailTemplate = getCompanyEstimateEmailTemplate(adminContext.data.company.estimate_template!)
    const renderedEmailJSON = renderJSONReplacingPlaceholders(companyEstimateEmailTemplate, {
      [TTPlaceholderCustomerName.id]: calculatedEstimate.name!,
      [TTPlaceholderCustomerAddress.id]: `${calculatedEstimate.address}, ${calculatedEstimate.postcode}`,
      [TTPlaceholderHeatPumpModel.id]: calculatedEstimate.heat_pump_name,
      [TTPlaceholderCarbonSaving.id]: numberFormat(0).format(calculatedEstimate.co2_saved_kg),
      [TTPlaceholderCompanyName.id]: adminContext.data.company.public_info.name,
      [TTPlaceholderCompanyPhone.id]: adminContext.data.company.phone
    })

    const companyEstimateCoverNote = getCompanyEstimateCoverNote(adminContext.data.company.public_info.calculated_estimate_cover_note!)
    const renderedEstimateCoverNoteJSON = renderJSONReplacingPlaceholders(companyEstimateCoverNote, {
      [TTPlaceholderInstallerName.id]: `${adminContext.data.company.public_info.installer_first_name} ${adminContext.data.company.public_info.installer_last_name}`,
      [TTPlaceholderCompanyName.id]: adminContext.data.company.public_info.name,
      [TTPlaceholderCompanyPhone.id]: adminContext.data.company.phone,
      [TTPlaceholderEstimateContactName.id]: adminContext.data.company.public_info.estimate_contact_name ?? '',
      [TTPlaceholderEstimateContactPhone.id]: adminContext.data.company.public_info.estimate_contact_name ?? ''
    })

    setCustomEmailJSON(renderedEmailJSON)
    setCustomCoverNoteJSON(renderedEstimateCoverNoteJSON)
  }, [adminContext.data.company])

  if (!adminContext.data?.company) return
  const companyUUID = adminContext.data.company.public_info.uuid

  const insertEstimate = async (setEstimates: Dispatch<SetStateAction<CalculatedEstimate[]>>, companyUUID: string, estimate: CalculatedEstimate, lead: Lead) => {
    await insertCalculatedEstimate(estimate, calculatedQuote, companyUUID)
    setEstimates(prev => [...prev, estimate])
  }

  return <Modal
    title={'Send estimate'}
    visible={true}
    setVisible={setIsVisible}
    onConfirm={async () => {
      logEvent({
        name: 'Send Estimate Clicked',
        properties: {}
      }, adminContext.data.company!.public_info?.subdomain ?? '')

      const estimateToSend = {
        ...calculatedEstimate,
        status: 'Sent',
        created_at: new Date().toString(),
        uuid: crypto.randomUUID(),
        cover_note: overrideCoverNoteJSON ? JSON.stringify(customCoverNoteJSON) : undefined
      } satisfies CalculatedEstimate

      const emailHTML = renderHTMLReplacingPlaceholders(customEmailJSON!, {
        [TTPlaceholderViewEstimate.id]: process.env.BASE_URL! + adminContext.data?.company?.public_info?.subdomain + '/estimate/' + estimateToSend.uuid
      })

      // Save the custom cover note to the estimate and store it.
      await insertEstimate(setEstimates, companyUUID, estimateToSend, lead)
      await sendEstimate(lead.uuid!, companyUUID, emailHTML)
      setLead({ ...lead, status: 'Quoted' })
    }}
    confirmButtonLabel="Send"
  >
    <div className='flex flex-col gap-4 h-full'>
      <Text size='MD'>This will send an email to <span className="font-bold">{lead?.customer?.email}</span> with a link to this <span className="font-bold">{formatPrice(sum(calculatedQuote.map(x => x.selected ? x.subtotal : 0)))}</span> estimate.</Text>
      <TabGroup
        items={[
          { name: 'Email', onClick: () => setCurrentTab('email'), variant: currentTab === 'email' ? 'ACTIVE' : 'DEFAULT' },
          { name: 'Cover note', onClick: () => setCurrentTab('cover_note'), variant: currentTab === 'cover_note' ? 'ACTIVE' : 'DEFAULT' }
        ]}
      />
      { (currentTab === 'email' && customEmailJSON) &&
      <VerticalFormGroup
        formLabel={<FormLabel
          labelText='Homeowner Email'
          helperText='Customise the email sent to the homeowner.'
        />}
        input={<Tiptap
          editable={true}
          className='w-full rounded border border-gray-300 p-2 focus:outline-none'
          onUpdateCallback={async (editor) => {
            setCustomEmailJSON(editor.getJSON())
          }}
          placeholders={[
            TTPlaceholderViewEstimate
          ]}
          content={customEmailJSON}
        />}
      />}
      { (currentTab === 'cover_note' && customCoverNoteJSON) && <>
        <FormLabel labelText='Estimate cover note' helperText='Customise the cover note content shown on this estimate.' />
        <div className='w-full flex flex-col gap-5'>
          <Heading size="lg">Hi {calculatedEstimate.name}!</Heading>
          <Tiptap
            editable={true}
            className='w-full rounded border border-gray-300 p-2 focus:outline-none'
            onUpdateCallback={(editor) => {
              setCustomCoverNoteJSON(editor.getJSON())
              if (!overrideCoverNoteJSON) {
                setOverrideCoverNoteJSON(true)
              }
            }}
            content={customCoverNoteJSON}
          />
          <ContactCardWrapper hasContent={Boolean(adminContext.data.company.public_info.estimate_contact_name || adminContext.data.company.public_info.estimate_contact_phone || adminContext.data.company.public_info.estimate_contact_role || adminContext.data.company.public_info.estimate_contact_portrait)}>
            <div className='flex justify-start items-center pt-1 gap-4'>
              {adminContext.data.company.public_info.estimate_contact_portrait && <img
                src={adminContext.data.company.public_info.estimate_contact_portrait}
                alt='Estimate contact headshot'
                className='rounded-full w-20 h-20 object-cover'
              />}
              <div className='flex flex-col gap-1'>
                <div>
                  <Text bold size='MD'>{adminContext.data.company.public_info.estimate_contact_name}</Text>
                  <Text size='SM'>{adminContext.data.company.public_info.estimate_contact_role}</Text>
                </div>
                <a className='text-base text-sky-800 font-bold underline' href={`tel:${adminContext.data.company.public_info.estimate_contact_phone}`}>{adminContext.data.company.public_info.estimate_contact_phone}</a>
              </div>
            </div>
          </ContactCardWrapper>
        </div>
      </>}
    </div>
  </Modal>
}
