import React from 'react'
import { SettingsLayout } from '../components/settings_layout'
import { type Company } from '../../../../code/models/company'
import { Section } from '../../../../components/containers/section'
import { Alert } from '../../../../components/indicators_and_messaging/alert'
import { VerticalFormGroup } from '../../../../components/inputs_and_selections/vertical_form_group'
import { FormLabel } from '../../../../components/inputs_and_selections/form_label'
import { Input } from '../../../../components/inputs_and_selections/input'
import { FlowTempSlider } from '../../../heat_loss/design/pages/emitter_design_page'
import { DEFAULT_AIR_CHANGES, DEFAULT_ESTIMATE_RAD_CHANGE_PERCENTAGE, DEFAULT_FLOW_TEMP_C, DEFAULT_INTERNAL_TEMP_C } from '../../../../code/calculate_estimate'
import { validateIntegerIsInRange, validateIsPositiveNumberOrZero } from '../../../../code/validators'
import { Info } from '../../../../components/buttons/info'

export const EstimatePreferences = ({ company, setCompany }: { company: Company, setCompany: (c: Company) => void }) => {
  return <SettingsLayout
    heading='Estimate preferences'
    description='Configure how your estimates are generated'
  >
    <Section border title='Heat loss estimate defaults' >
      <div className='flex flex-col gap-8'>
        <Alert type='INFO' >Changing these settings will not impact estimates that have already been sent to customers.</Alert>
        <div className='grid grid-cols-1 sm:grid-cols-3 grid-flow-row gap-4'>
          <VerticalFormGroup
            formLabel={<FormLabel labelText='Internal temp. (°C)' />}
            input={<Input
              type='number'
              value={ company.estimate_default_internal_temp_c ?? DEFAULT_INTERNAL_TEMP_C}
              setValue={temp => {
                if (validateIsPositiveNumberOrZero(temp)) {
                  setCompany({ ...company, estimate_default_internal_temp_c: parseInt(temp) })
                }
              }}
              validator={validateIsPositiveNumberOrZero}
              validateImmediately
              postfix={<>°C</>}
            />}
          />
          <VerticalFormGroup
            formLabel={<FormLabel labelText='ACH' />}
            input={<Input
              type='number'
              step={0.1}
              value={ company.estimate_default_ach ?? DEFAULT_AIR_CHANGES }
              validator={validateIsPositiveNumberOrZero}
              validateImmediately
              setValue={ach => {
                if (validateIsPositiveNumberOrZero(ach)) {
                  setCompany({ ...company, estimate_default_ach: parseFloat(ach) })
                }
              }}
            />}
          />
          <VerticalFormGroup
            formLabel={
              <FormLabel
                labelText='Rad changes (%)'
                info={<Info
                  infoModalHeader={'Radiator changes'}
                  infoModalBody={'Approximate percentage of the radiators you would typically replace on a job. We estimate the number of radiators in the home based on the enquiry data, then apply this percentage to approximate the number of rad changes. These are included on the estimates by default. If you don\'t want to show any radiator changes by default, set this to 0%.'}
                />}
              />
            }
            input={<Input
              type='number'
              step={1}
              value={ company.estimate_default_radiator_change_percentage ?? DEFAULT_ESTIMATE_RAD_CHANGE_PERCENTAGE }
              validator={e => validateIntegerIsInRange(e, 0, 100)}
              validateImmediately
              setValue={percentage => {
                if (validateIntegerIsInRange(percentage, 0, 100)) {
                  setCompany({ ...company, estimate_default_radiator_change_percentage: parseInt(percentage) })
                }
              }}
              postfix={<>%</>}
            />}
          />
        </div>
        <FlowTempSlider
          minFlowTemp={35}
          maxFlowTemp={55}
          flowTemp={company.default_flow_temp_c ?? DEFAULT_FLOW_TEMP_C}
          setFlowTemp={flowTemp => setCompany({ ...company, default_flow_temp_c: flowTemp })}
        />
      </div>
    </Section>
  </SettingsLayout>
}
