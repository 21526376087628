import React, { useEffect, useState } from 'react'
import { Section } from '../../../../components/containers/section'
import { Input } from '../../../../components/inputs_and_selections/input'
import { Select } from '../../../../components/inputs_and_selections/select'
import { type Company, getSimproAPICompaniesList } from '../../../../code/models/company'
import { SettingsLayout } from '../components/settings_layout'
import { testMagicplanCreds } from '../../../../code/floorplan_adapters/magicplan'
import { Alert } from '../../../../components/indicators_and_messaging/alert'
import { VerticalFormGroup } from '../../../../components/inputs_and_selections/vertical_form_group'
import { FormLabel } from '../../../../components/inputs_and_selections/form_label'
import { Text } from '../../../../components/content_display/text'

export const AccountIntegrations = ({ company, setCompany }: { company: Company, setCompany: (c: Company) => void }) => {
  const [simproCompanies, setSimproCompanies] = useState<Array<{ key: string, value: string }>>([])
  const [testingMagicplan, setTestingMagicplan] = useState<'TESTING' | 'SUCCESSFUL' | 'UNSUCCESSFUL' | undefined >(undefined)

  const getSimproCompaniesList = async (apiKey: string | undefined, url: string | undefined) => {
    if (!apiKey || !url) return []
    const list = await getSimproAPICompaniesList(company.public_info.uuid, apiKey, url)
    setSimproCompanies(list || [])
  }

  useEffect(() => {
    if (company.magicplan_api_key && company.magicplan_customer_id) {
      setTestingMagicplan('TESTING')
      setTimeout(async () => {
        if (company.magicplan_api_key && company.magicplan_customer_id) {
          setTestingMagicplan('TESTING')

          const projects = await testMagicplanCreds(company.public_info.uuid, company.magicplan_api_key, company.magicplan_customer_id)
          if (!projects || projects === -1) {
            setTestingMagicplan('UNSUCCESSFUL')
          } else {
            setTestingMagicplan('SUCCESSFUL')
          }
        } else {
          setTestingMagicplan(undefined)
        }
      }, 1000)
    }

    return () => {
      setTestingMagicplan(undefined)
    }
  }, [company.magicplan_api_key, company.magicplan_customer_id])

  return <SettingsLayout
    heading='Integrations'
    description='Connect Spruce to your other apps'
  >
    <Section border title='Your integrations'>
      <div className='flex flex-col gap-4'>
        {/* Payaca */}
        <VerticalFormGroup
          formLabel={
            <FormLabel
              labelText='Payaca'
              helperText='Link your Payaca account to receive new leads from Spruce.'
            />
          }
          input={
            <Input
              placeholder='Customer ID'
              value={company.payaca_customer_id ?? ''}
              setValue={(customerId) => setCompany({ ...company, payaca_customer_id: customerId })}
            />
          }
        />
        {/* Commusoft */}
        <VerticalFormGroup
          formLabel={
            <FormLabel
              labelText='Commusoft'
              helperText='Link your Commusoft account to receive leads from Spruce.'
            />
          }
          input={
            <>
              <Input
                placeholder='Client ID'
                value={company.commusoft_client_id ?? ''}
                setValue={(clientId) => setCompany({ ...company, commusoft_client_id: clientId })}
              />
              <Input
                placeholder='API key'
                value={company.commusoft_api_key ?? ''}
                setValue={(key) => setCompany({ ...company, commusoft_api_key: key })}
              />
            </>
          }
        />
        {/* Simpro */}
        <VerticalFormGroup
          formLabel={<FormLabel labelText='Simpro' helperText='Link your Simpro account to receive leads from Spruce.' />}
          input={<>
            { (company.simpro_company_id && company.simpro_company_name) && <div className='flex flex-row'>
              <Text>Linked to {company.simpro_company_name} - {company.simpro_company_id}</Text>
            </div>}
            <Input
              placeholder='API Key'
              value={company.simpro_api_key ?? ''}
              setValue={async (key) => {
                setCompany({
                  ...company,
                  simpro_api_key: key,
                  simpro_company_id: '',
                  simpro_company_name: ''
                })
                await getSimproCompaniesList(key.trim(), (company.simpro_url ?? '').trim())
              }}
            />
            <Input
              placeholder='URL'
              value={company.simpro_url ?? ''}
              setValue={async (url) => {
                setCompany({
                  ...company,
                  simpro_url: url,
                  simpro_company_id: '',
                  simpro_company_name: ''
                })
                await getSimproCompaniesList((company.simpro_api_key ?? '').trim(), url.trim())
              }}
            />
            {/* If we've entered a url or key, and If there is no stored company id and name, AND we're not finding any via simpro - then show warning.
                If there is a stored company id and name, but no simpro company list
                  - it is likely the user has already connected their simpro account, and are refreshing the page (so we haven't asked simpro for companies.)
                 */}
            { (Boolean(company.simpro_api_key || company.simpro_url) &&
                (Boolean(!company.simpro_company_id && !company.simpro_company_name) && simproCompanies.length === 0)) &&
            <Alert type='WARNING'>No companies found for the API key</Alert>}
            {simproCompanies.length !== 0 && <>
              {/* If there is no company id and name selected, then prompt user to select one.  */}
              { (!company.simpro_company_id) && <Alert type='INFO'>Please select a Simpro company</Alert> }
              <Select
                options={company.simpro_company_id ? [{ key: company.simpro_company_id, value: company.simpro_company_name! }] : simproCompanies}
                selectedKey={company.simpro_company_id}
                setSelectedKey={(selectedCompany) => {
                  setCompany({
                    ...company,
                    simpro_company_id: selectedCompany,
                    simpro_company_name: simproCompanies.find(x => x.key === selectedCompany)?.value ?? ''
                  })
                }}
              />
            </>}
          </>}
        />
        {/* Magicplan */}
        <VerticalFormGroup
          formLabel={<FormLabel labelText='Magicplan' helperText='Link your Magicplan account to import floor plans into your surveys.' />}
          input={<>
            <Input
              placeholder={'API Key'}
              value={company.magicplan_api_key ?? ''}
              setValue={(key) => {
                setCompany({ ...company, magicplan_api_key: key })
              }}
            />
            <Input
              placeholder={'Customer ID'}
              value={company.magicplan_customer_id ?? ''}
              setValue={(customerId) => {
                setCompany({ ...company, magicplan_customer_id: customerId })
              }}
            />
            { testingMagicplan === 'TESTING' &&
                <Alert
                  type='INFO'
                >Verifying Magicplan integration details...</Alert>
            }
            { testingMagicplan === 'SUCCESSFUL' &&
                <Alert type='SUCCESS'>You've successfully integrated Magicplan with Spruce!</Alert>
            }
            { testingMagicplan === 'UNSUCCESSFUL' &&
                <Alert type='WARNING'>Error getting Magicplan projects. Check your Magicplan API key and Customer ID.</Alert>
            }
          </>
          }
        />
      </div>
    </Section>
  </SettingsLayout>
}
