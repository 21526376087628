import React, { useContext } from 'react'
import TabbedDashboardLayout from '../../../layouts/tabbed_dashboard_layout'
import { AdminContext } from '../admin_layout'
import { type CompanyPublicInfo } from '../../../code/models/company'
import { OfflinePage } from '../offline_page'
import { UnauthorizedPage } from '../unauthorized_page'
import { Loader } from '../../../components/indicators_and_messaging/loader'
import { hasInventoryAccess } from '../../../code/models/user'
import { HeatPumpsInventory } from './components/tabs/heat_pumps_inventory'
import { deleteHeatPump, insertHeatPump, updateHeatPump } from '../../../code/models/heat_pump'
import { logEvent } from '../../../code/log_event'
import { type InventoryPart, type InventoryHeatPump, type InventoryHotWaterCylinder, type InventoryPartWithQuantity } from '../../../code/models/inventory'
import { PartsInventory } from './components/tabs/parts_inventory'
import { insertPart, deletePart as deletePartApi, updatePart } from '../../../code/models/parts'
import { PacksInventory } from './components/tabs/packs_inventory'
import { insertPack, type Pack, deletePack as deletePackApi, addPartToPack as addPartToPackApi, removePartFromPack as removePartFromPackApi, updatePack as updatePackApi, updatePartQuantityInPack } from '../../../code/models/packs'
import { insertLabour, type Labour, updateLabour, deleteLabour as deleteLabourApi } from '../../../code/models/labour'
import { LabourInventory } from './components/tabs/labour_inventory'
import { EmittersInventory } from './components/tabs/emitters_inventory'
import { HotWaterCylindersInventory } from './components/tabs/hot_water_cylinders_inventory'
import { deleteHotWaterCylinders, insertHotWaterCylinders, updateHotWaterCylinders } from '../../../code/models/hot_water_cylinder'

type CostsAndInventoryPageProps = {
  navigateTo: (url: string) => void
  tab: string
  secondaryTab: string
  companyPublicInfo: CompanyPublicInfo
}
export const CostsAndInventoryPage = ({ navigateTo, tab, secondaryTab, companyPublicInfo }: CostsAndInventoryPageProps) => {
  const adminContext = useContext(AdminContext)
  const companySubdomain = companyPublicInfo.subdomain
  const companyUUID = companyPublicInfo.uuid

  const basePath = `/${companyPublicInfo.subdomain}/admin/costs`

  if (adminContext.isOffline) {
    return <OfflinePage navigateTo={navigateTo} />
  }

  if (adminContext.isLoading || !adminContext.data.company) {
    return (
      <div className='flex justify-center items-center h-full'>
        <Loader />
      </div>
    )
  }

  if (!adminContext.isLoading && !hasInventoryAccess(adminContext.data.company, adminContext.data.user)) {
    return <UnauthorizedPage />
  }

  const updateOrInsertHeatPumpRow = async (heatPump: InventoryHeatPump, updateLocal: boolean = true) => {
    if (adminContext.data.heatPumps!.some(x => x.uuid === heatPump.uuid)) {
      logEvent({ name: 'Heat Pump Modified', properties: {} }, companySubdomain)
      await updateHeatPump(heatPump, companyUUID)
      if (updateLocal) adminContext.setHeatPumps(adminContext.data.heatPumps!.map(x => x.uuid === heatPump.uuid ? heatPump : x))
    } else {
      logEvent({ name: 'Heat Pump Added', properties: {} }, companySubdomain)
      await insertHeatPump(heatPump, companyUUID)
      if (updateLocal) adminContext.setHeatPumps([...adminContext.data.heatPumps!, heatPump])
    }
  }

  const addHeatPumps = async (heatPumps: InventoryHeatPump[]) => {
    await Promise.all(heatPumps.map(async x => await updateOrInsertHeatPumpRow(x, false)))
    const newHeatPumps = heatPumps.filter(x => !adminContext.data.heatPumps?.some(y => y.uuid === x.uuid))
    const updatedHeatPumps = adminContext.data.heatPumps!.map(x => x)
    adminContext.setHeatPumps([...updatedHeatPumps.map(x => {
      const heatPump = heatPumps.find(y => y.uuid === x.uuid)
      return heatPump || x
    }), ...newHeatPumps])
  }

  const deleteHeatPumpRow = async (heatPumpUUID: string) => {
    logEvent({ name: 'Heat Pump Deleted', properties: {} }, companySubdomain)
    await deleteHeatPump(heatPumpUUID, companyUUID)
    adminContext.setHeatPumps(adminContext.data.heatPumps!.map(x => {
      // artificially delete the HP in the state variable by setting deleted_at
      // in reality the deleted_at is set by the server, but we don't need a precise time here
      return x.uuid === heatPumpUUID ? { ...x, deleted_at: new Date() } satisfies InventoryHeatPump : x
    }))
  }

  const addOrUpdatePart = async (part: InventoryPart) => {
    if (adminContext.data?.parts!.some(x => x.uuid === part.uuid)) {
      await updatePart(part, companyUUID)
      adminContext.setParts(adminContext.data.parts!.map(x => x.uuid === part.uuid ? part : x))
    } else {
      const newPart = await insertPart(part, companyUUID)
      adminContext.setParts([...adminContext.data.parts!, newPart])
    }
  }

  const deletePart = async (partUUID: string) => {
    await deletePartApi(partUUID, companyUUID)
    adminContext.setParts(adminContext.data.parts!.map(x => {
      return x.uuid === partUUID ? { ...x, deleted_at: new Date() } satisfies InventoryPart : x
    }))
    adminContext.setPacks(adminContext.data.packs!.map(x => {
      return { ...x, parts: (x.parts || []).filter(y => y.uuid !== partUUID) }
    }))
  }

  const addPack = async (pack: Pack, heatPumpIds: string[]) => {
    await insertPack(pack, companyUUID)
    adminContext.setPacks([...adminContext.data.packs!, pack])

    // Wait until all heat pumps are updated before updating the local state
    await Promise.all(heatPumpIds.map(async heatPumpId => {
      const heatPump = adminContext.data.heatPumps!.find(x => x.uuid === heatPumpId)
      if (heatPump) {
        // We pass false here to updateLocal because we want to update the local state once at the end
        await updateOrInsertHeatPumpRow({ ...heatPump, default_pack_uuid: pack.uuid }, false)
      }
    }))
    // Update the local state
    adminContext.setHeatPumps(adminContext.data.heatPumps!.map(x => {
      const updatedHeatPump = heatPumpIds.includes(x.uuid) ? { ...x, default_pack_uuid: pack.uuid } : x
      return updatedHeatPump
    }))
  }

  const updatePack = async (pack: Pack, newHeatPumpIds: string[], newHotWaterCylinderIds: string[]) => {
    const oldHeatPumpIds = adminContext.data.heatPumps?.filter(x => x.default_pack_uuid === pack.uuid).map(x => x.uuid) ?? []
    const heatPumpsToAssociate = newHeatPumpIds.filter(x => !oldHeatPumpIds.includes(x))
    const heatPumpsToDisassociate = oldHeatPumpIds.filter(x => !newHeatPumpIds.includes(x))

    const oldHotWaterCylinderIds = adminContext.data.hotWaterCylinders?.filter(x => x.default_pack_uuid === pack.uuid).map(x => x.uuid) ?? []
    const hotWaterCylindersToAssociate = newHotWaterCylinderIds.filter(x => !oldHotWaterCylinderIds.includes(x))
    const hotWaterCylindersToDisassociate = oldHotWaterCylinderIds.filter(x => !newHotWaterCylinderIds.includes(x))

    const updateSingleHeatPump = async (heatPumpId: string, defaultPackUuid: string | null) => {
      const heatPump = adminContext.data.heatPumps?.find(x => x.uuid === heatPumpId)
      if (heatPump) {
        await updateOrInsertHeatPumpRow({ ...heatPump, default_pack_uuid: defaultPackUuid }, false)
      }
    }

    const updateSingleHotWaterCylinder = async (hotWaterCylinderId: string, defaultPackUuid: string | null) => {
      const hotWaterCylinder = adminContext.data.hotWaterCylinders?.find(x => x.uuid === hotWaterCylinderId)
      if (hotWaterCylinder) {
        await updateHotWaterCylinders({ ...hotWaterCylinder, default_pack_uuid: defaultPackUuid }, companyUUID)
      }
    }

    // Update all associated and disassociated heat pumps and hot water cylinders and the pack
    await Promise.all([
      ...heatPumpsToDisassociate.map((heatPumpId) => updateSingleHeatPump(heatPumpId, null)),
      ...heatPumpsToAssociate.map((heatPumpId) => updateSingleHeatPump(heatPumpId, pack.uuid)),
      ...hotWaterCylindersToDisassociate.map((hotWaterCylinderId) => updateSingleHotWaterCylinder(hotWaterCylinderId, null)),
      ...hotWaterCylindersToAssociate.map((hotWaterCylinderId) => updateSingleHotWaterCylinder(hotWaterCylinderId, pack.uuid)),
      updatePackApi(pack, companyUUID)
    ])

    // Update the local state last
    adminContext.setPacks(adminContext.data.packs!.map(x => x.uuid === pack.uuid ? pack : x))
    adminContext.setHeatPumps(adminContext.data.heatPumps!.map(x => {
      if (heatPumpsToDisassociate.includes(x.uuid)) {
        return { ...x, default_pack_uuid: null }
      }
      if (heatPumpsToAssociate.includes(x.uuid)) {
        return { ...x, default_pack_uuid: pack.uuid }
      }
      return x
    }))
    adminContext.setHotWaterCylinders(adminContext.data.hotWaterCylinders!.map(x => {
      if (hotWaterCylindersToDisassociate.includes(x.uuid)) {
        return { ...x, default_pack_uuid: null }
      }
      if (hotWaterCylindersToAssociate.includes(x.uuid)) {
        return { ...x, default_pack_uuid: pack.uuid }
      }
      return x
    }))
  }

  const deletePack = async (pack: Pack) => {
    await deletePackApi(pack, companyUUID)
    adminContext.setPacks(adminContext.data.packs!.map(x => {
      return x.uuid === pack.uuid ? { ...x, deleted_at: new Date() } : x
    }))
  }

  const addPartToPack = async (pack: Pack, part: InventoryPartWithQuantity) => {
    await addPartToPackApi(pack, part, companyUUID)
    adminContext.setPacks(adminContext.data.packs!.map(x => {
      return x.uuid === pack.uuid ? { ...x, parts: [...x.parts || [], part] } : x
    }))
  }

  const removePartFromPack = async (pack: Pack, part: InventoryPartWithQuantity) => {
    await removePartFromPackApi(pack, part, companyUUID)
    adminContext.setPacks(adminContext.data.packs!.map(x => {
      return x.uuid === pack.uuid ? { ...x, parts: (x.parts || []).filter(y => y.uuid !== part.uuid) } : x
    }))
  }

  const updatePartInPack = async (pack: Pack, part: InventoryPartWithQuantity) => {
    const updatedParts = (pack.parts || []).map(x => x.uuid === part.uuid ? part : x)
    const updatedPack = { ...pack, parts: updatedParts }
    await updatePartQuantityInPack(pack, part, companyUUID)
    adminContext.setPacks(adminContext.data.packs!.map(x => x.uuid === pack.uuid ? updatedPack : x))
  }

  const addOrUpdateLabour = async (labour: Labour) => {
    if (adminContext.data?.labour!.some(x => x.uuid === labour.uuid)) {
      await updateLabour(labour, companyUUID)
      adminContext.setLabour(adminContext.data.labour!.map(x => x.uuid === labour.uuid ? labour : x))
    } else {
      await insertLabour(labour, companyUUID)
      adminContext.setLabour([...adminContext.data.labour!, { ...labour }])
    }
  }

  const deleteLabour = async (labour: Labour) => {
    await deleteLabourApi(labour, companyUUID)
    adminContext.setLabour(adminContext.data.labour!.filter(x => x.uuid !== labour.uuid))
  }

  const updateOrInsertHotWaterCylinderRow = async (hotWaterCylinder: InventoryHotWaterCylinder) => {
    if (adminContext.data?.hotWaterCylinders!.some(x => x.uuid === hotWaterCylinder.uuid)) {
      await updateHotWaterCylinders(hotWaterCylinder, companyUUID)
      adminContext.setHotWaterCylinders(adminContext.data.hotWaterCylinders!.map(x => x.uuid === hotWaterCylinder.uuid ? hotWaterCylinder : x))
    } else {
      await insertHotWaterCylinders(hotWaterCylinder, companyUUID)
      adminContext.setHotWaterCylinders([...adminContext.data.hotWaterCylinders!, hotWaterCylinder])
    }
  }

  const deleteHotWaterCylinderRow = async (hotWaterCylinderId: string) => {
    await deleteHotWaterCylinders(hotWaterCylinderId, companyUUID)
    adminContext.setHotWaterCylinders(adminContext.data.hotWaterCylinders!.map(x => {
      return x.uuid === hotWaterCylinderId ? { ...x, deleted_at: new Date() } satisfies InventoryHotWaterCylinder : x
    }))
  }

  const company = adminContext.data.company

  return (
    <TabbedDashboardLayout
      navigateTo={navigateTo}
      basePath={basePath}
      title='Costs & inventory'
      selectedTabId={tab}
      isOffline={adminContext.isOffline}
      tabs={[
        {
          id: 'heat-pumps',
          label: 'Heat pumps',
          content: <HeatPumpsInventory
            brandRanges={adminContext.data.brandRanges ?? []}
            heatPumps={(adminContext.data.heatPumps || []).filter(x => !x.deleted_at)}
            addHeatPumps={addHeatPumps}
            deleteHeatPump={deleteHeatPumpRow}
            updateHeatPump={updateOrInsertHeatPumpRow}
            company={company}
            setCompany={adminContext.setCompany}
            packs={(adminContext.data.packs || []).filter(x => !x.deleted_at)}
          />
        },
        {
          id: 'hot-water-cylinders',
          label: 'Hot water cylinders',
          content: <HotWaterCylindersInventory
            hotWaterCylinders={(adminContext.data.hotWaterCylinders || []).filter(x => !x.deleted_at)}
            addHotWaterCylinder={updateOrInsertHotWaterCylinderRow}
            deleteHotWaterCylinder={deleteHotWaterCylinderRow}
            updateHotWaterCylinder={updateOrInsertHotWaterCylinderRow}
            company={company}
            setCompany={adminContext.setCompany}
            packs={(adminContext.data.packs || []).filter(x => !x.deleted_at)}
          />
        },
        {
          id: 'parts',
          label: 'Parts',
          content: <PartsInventory
            parts={(adminContext.data.parts || []).filter(x => !x.deleted_at)}
            company={company}
            setCompany={adminContext.setCompany}
            addOrUpdatePart={addOrUpdatePart}
            deletePart={deletePart}
          />
        },
        {
          id: 'emitters',
          label: 'Emitters',
          content: <EmittersInventory
            company={company}
            setCompany={adminContext.setCompany}
          />
        },
        {
          id: 'labour',
          label: 'Labour',
          content: <LabourInventory
            labour={(adminContext.data.labour || []).filter(x => !x.deleted_at)}
            addOrUpdateLabour={addOrUpdateLabour}
            deleteLabour={deleteLabour}
            company={company}
            setCompany={adminContext.setCompany}
          />
        },
        {
          id: 'packs',
          label: 'Packs',
          content: <PacksInventory
            packs={(adminContext.data.packs || []).filter(x => !x.deleted_at)}
            heatPumps={(adminContext.data.heatPumps || []).filter(x => !x.deleted_at)}
            hotWaterCylinders={(adminContext.data.hotWaterCylinders || []).filter(x => !x.deleted_at)}
            parts={(adminContext.data.parts || []).filter(x => !x.deleted_at)}
            addPack={addPack}
            deletePack={deletePack}
            updatePack={updatePack}
            addPartToPack={addPartToPack}
            removePartFromPack={removePartFromPack}
            updatePartInPack={updatePartInPack}
          />
        }
      ]} />
  )
}
