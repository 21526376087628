import React, { type Dispatch, type SetStateAction } from 'react'
import { type WallWindow } from '../../code/models/window'
import { Button } from '../../components/buttons/button'
import { Input } from '../../components/inputs_and_selections/input'
import { type MaterialsSelectorGroupedProps } from './materials/materials_selector'
import type { Material } from '../../code/models/material'
import type { PropertySurvey } from '../../code/models/property'
import { MaterialInputField } from './materials/material_input_field'
import { validateInMm } from '../../code/validators'
import { type CurrentFloorPlanPage } from './floor/floor'
import { type Floor } from '../../code/models/floor'

type WindowPageProps = {
  floor: Floor
  wallWindow: WallWindow
  setWindow: Dispatch<SetStateAction<WallWindow | undefined>>
  onSave: () => void
  survey: PropertySurvey
  materials: Material[]
  setMsProps: Dispatch<SetStateAction<MaterialsSelectorGroupedProps | undefined>>
  setPage: (page: CurrentFloorPlanPage) => void
  setScalingWindow: Dispatch<SetStateAction<boolean>>
  setStageStep: Dispatch<SetStateAction<number>>
  onBack: () => void
}

export const WindowPage = ({ floor, wallWindow, setScalingWindow, setWindow, onSave, survey, materials, setMsProps, setPage, setStageStep, onBack }: WindowPageProps) => {
  const isValidForm = [validateInMm(wallWindow.width_mm), validateInMm(wallWindow.height_mm)].every(x => x.value)
  return <>
    {/* Window settings */}
    <div className='flex flex-col'>
      <div className="p-5 bg-white flex-col gap-5 flex">
        <div className='space-y-2'>
          <div className='text-gray-900 font-bold text-sm'>Window material</div>
          <MaterialInputField
            selectorProps={{
              title: 'Window',
              surfaceType: 'window',
              materials,
              ageBand: survey.age_band,
              selectedMaterial: wallWindow.material,
              onSelectCallback: (material: Material) => {
                setWindow(prev => ({ ...prev!, material, updated_at: new Date().getTime() }))
              }
            } satisfies MaterialsSelectorGroupedProps}
            setMsProps={setMsProps}
            setPage={setPage}
          />
        </div>
        <div className='flex gap-2'>
          <div className='flex flex-col gap-2 flex-grow'>
            <div className='text-gray-900 font-bold text-sm'>Width</div>
            <Input
              step={10}
              type='number'
              value={wallWindow.width_mm.toString()}
              setValue={(e) => setWindow(prev => ({ ...prev!, width_mm: parseFloat(e), updated_at: new Date().getTime() }))}
              validator={validateInMm}
              postfix='mm' />
          </div>
          <div className='flex flex-col gap-2 flex-grow'>
            <div className='text-gray-900 font-bold text-sm'>Height</div>
            <Input step={10}
              type='number'
              value={wallWindow.height_mm.toString()}
              setValue={(e) => setWindow(prev => ({ ...prev!, height_mm: parseFloat(e), updated_at: new Date().getTime() }))}
              validator={validateInMm}
              postfix='mm' />
          </div>
        </div>
        <div className='flex gap-2'>
          {floor.floor_plan_is_showing && (floor.floor_plan_image || floor.floor_plan_url) && <Button
            className='flex-1'
            colour='LIGHT'
            onClick={() => { setScalingWindow(true) }}
          >Measure width</Button>}
          <Button
            className='flex-1'
            onClick={() => { onSave(); setWindow(undefined); onBack() }}
            disabled={!isValidForm}
          >Apply</Button>
        </div>
      </div>
    </div>
  </>
}
