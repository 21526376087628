import React from 'react'
import { HLRSubheaderBlock } from './partials/hlr_subheader'
import { HLRContext } from './heatloss_report'

export const HLRPEInputsAndAssumptions = () => {
  const hlrContext = React.useContext(HLRContext)
  const estimatesGroup = hlrContext!.performanceEstimateSummary.estimatesGroup
  const columnNamesInOrder = Object.keys(estimatesGroup)
  const haveCol2 = estimatesGroup[columnNamesInOrder[1]] !== undefined
  const haveCol3 = estimatesGroup[columnNamesInOrder[2]] !== undefined

  return (
    <div className='flex gap-6 flex-col'>
      <HLRSubheaderBlock
        section="Performance estimate"
        title="Inputs and assumptions"
      />

      <div className=''>
        In each of the following sections, we break down how we’ve calculated the numbers that appear in the results
        table.
      </div>

      <div className='flex flex-col gap-2'>
        <div className="text-gray-900 text-lg font-bold">Heat energy required</div>
        <div className="">To work out how much
          energy your home needs to keep it warm, we first have to work out how much heat is required. That might sound
          strange, but because different heating sources have wildly different efficiencies, this heat energy number
          will need to be divided by the system efficiency (see next section) to calculate the actual energy required.
        </div>
      </div>

      <div className='grid grid-cols-4'>
        {/*  header */}
        <div className='pb-2'></div>
        <div className='pb-2 pr-2 text-gray-900 text-xs font-semibold uppercase tracking-wide flex items-end'>
          {columnNamesInOrder[0]}
        </div>
        <div className='pb-2 pr-2 text-gray-900 text-xs font-semibold uppercase tracking-wide flex items-end'>
          {columnNamesInOrder[1]}
        </div>
        <div className='pb-2 pr-2 text-gray-900 text-xs font-semibold uppercase tracking-wide flex items-end'>
          {columnNamesInOrder[2]}
        </div>

        {/* table subheader row */}
        <div className='bg-gray-100 rounded-tl-md pl-3 pt-6 pb-3'></div>
        <div className='bg-gray-100 pt-6 pb-3 text-gray-500 text-xs tracking-tight'>
          {estimatesGroup[columnNamesInOrder[0]].existing.subtitle}
        </div>
        <div className='bg-gray-100 pt-6 pb-3 text-gray-500 text-xs tracking-tight'>
          {haveCol2 ? estimatesGroup[columnNamesInOrder[1]].existing.subtitle : 'Unavailable'}
        </div>
        <div className='bg-gray-100 rounded-tr-md pr-3 pt-6 pb-3 text-gray-500 text-xs tracking-tight'>
          {haveCol3 ? estimatesGroup[columnNamesInOrder[2]].existing.subtitle : 'Unavailable'}
        </div>

        {/* data rows */}

        {/* Heating */}
        <div className={'rounded-bl-md bg-gray-100 pl-3 text-gray-900 text-xs font-bold'}>
          {/* a trick to draw border not from the edge: draw it for the embedded div */}
          <div className={'py-3 w-full flex border-t border-dashed border-gray-300 pr-2'}>Heating</div>
        </div>
        <div className={'py-3 border-t border-dashed border-gray-300 bg-gray-100 text-xs font-semibold'}>
          {estimatesGroup[columnNamesInOrder[0]].existing.demandSpaceHeatingKwh}
        </div>
        <div className={'py-3 border-t border-dashed border-gray-300 bg-gray-100 text-xs font-semibold'}>
          {haveCol2 ? estimatesGroup[columnNamesInOrder[1]].existing.demandSpaceHeatingKwh : ''}
        </div>
        <div className={'rounded-br-md bg-gray-100 pr-3 text-xs font-semibold'}>
          {/* a trick to draw border not from the edge: draw it for the embedded div */}
          <div className={'py-3 w-full flex border-t border-dashed border-gray-300'}>
            {haveCol3 ? estimatesGroup[columnNamesInOrder[2]].existing.demandSpaceHeatingKwh : ''}</div>
        </div>

        {/* Hot water */}
        <div className={'rounded-bl-md bg-gray-100 pl-3 text-gray-900 text-xs font-bold'}>
          {/* a trick to draw border not from the edge: draw it for the embedded div */}
          <div className={'py-3 w-full flex border-t border-dashed border-gray-300 pr-2'}>Hot water</div>
        </div>
        <div className={'py-3 border-t border-dashed border-gray-300 bg-gray-100 text-xs font-semibold'}>
          {estimatesGroup[columnNamesInOrder[0]].existing.demandHotWaterKwh}
        </div>
        <div className={'py-3 border-t border-dashed border-gray-300 bg-gray-100 text-xs font-semibold'}>
          {haveCol2 ? estimatesGroup[columnNamesInOrder[1]].existing.demandHotWaterKwh : ''}
        </div>
        <div className={'rounded-br-md bg-gray-100 pr-3 text-xs font-semibold'}>
          {/* a trick to draw border not from the edge: draw it for the embedded div */}
          <div className={'py-3 w-full flex border-t border-dashed border-gray-300'}>
            {haveCol3 ? estimatesGroup[columnNamesInOrder[2]].existing.demandHotWaterKwh : ''}</div>
        </div>

        {/* Total */}
        <div className={'rounded-bl-md bg-gray-100 pl-3 text-gray-900 text-xs font-bold'}>
          {/* a trick to draw border not from the edge: draw it for the embedded div */}
          <div className={'pb-6 pt-3  w-full flex border-t border-dashed border-gray-300 pr-2 font-bold'}>Total</div>
        </div>
        <div className={'pb-6 pt-3  border-t border-dashed border-gray-300 bg-gray-100 text-xs font-bold'}>
          {estimatesGroup[columnNamesInOrder[0]].existing.demandTotalKwh}
        </div>
        <div className={'pb-6 pt-3  border-t border-dashed border-gray-300 bg-gray-100 text-xs font-bold'}>
          {haveCol2 ? estimatesGroup[columnNamesInOrder[1]].existing.demandTotalKwh : ''}
        </div>
        <div className={'rounded-br-md bg-gray-100 pr-3 text-xs font-bold'}>
          {/* a trick to draw border not from the edge: draw it for the embedded div */}
          <div className={'pb-6 pt-3 w-full flex border-t border-dashed border-gray-300'}>
            {haveCol3 ? estimatesGroup[columnNamesInOrder[2]].existing.demandTotalKwh : ''}
          </div>
        </div>
      </div>

    </div>
  )
}
