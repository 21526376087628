import React from 'react'
import { SurveyHeatPumpLocationContext } from '../heat_pump_location'
import { FlowWrapperContext } from '../components/survey_section_flow_wrapper'
import { Badge } from '../../../../components/indicators_and_messaging/badge'
import { PhotoScroll } from '../../../../components/inputs_and_selections/photo_scroll'
import { Check } from 'lucide-react'

export const HPLProposedLocationPhotosBlock = () => {
  const hplContext = React.useContext(SurveyHeatPumpLocationContext)
  const flowContext = React.useContext(FlowWrapperContext)

  const { survey, files, setFiles, locationImages, setLocationImages, companyUUID } = hplContext!

  const locationImagesHydrated = hplContext!.locationImages.map(x => files.find(y => y.uuid === x.image_uuid)!)

  const isFullScreen = locationImagesHydrated.length === 0 && flowContext!.viewMode === 'FLOW'

  return <>
    <div className={isFullScreen ? 'p-5 h-full bg-gray-50 flex flex-col items-center justify-center gap-4' : 'flex flex-col gap-4'}>
      <div className={`${isFullScreen ? 'items-center' : ''} flex flex-col gap-1`}>
        <div className="text-gray-900 text-xl font-bold">Proposed location</div>
        <div className={`${isFullScreen ? 'text-center' : ''} text-gray-500 text-sm`}>Add photos of the proposed
              location for the heat pump and the surrounding the area
        </div>
      </div>

      <div className="flex-col gap-3 flex">
        {!isFullScreen &&
            <div className='flex justify-between'>
              <div className="text-gray-900 font-bold">Photos</div>
              <Badge color={hplContext!.survey.location_images.length > 0 ? 'GREEN' : 'YELLOW'} text='Required'
                icon={survey.location_images.length > 0 ? Check : undefined}/>
            </div>
        }

        <PhotoScroll
          viewMode={isFullScreen ? 'COMPACT' : 'NORMAL'}
          images={locationImagesHydrated}
          deleteImage={(id) => {
            setLocationImages(locationImages.filter(x => x.image_uuid !== id))
          }}
          addImage={(image) => {
            setFiles(prev => [...prev, { ...image, created_at: new Date().getTime(), updated_at: new Date().getTime(), is_modified: true }])
            const newLocationImage = {
              uuid: crypto.randomUUID(),
              image_uuid: image.uuid!,
              entity_uuid: 'survey',
              entity_attribute: 'location_images'
            }
            setLocationImages([...locationImages, newLocationImage])
          }} companyUUID={companyUUID} />
      </div>
    </div>
  </>
}
