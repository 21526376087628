import React from 'react'
import { ClickableCard } from '../../components/content_display/card'
import { type SurveyDesign } from '../../code/models/property'
import { Badge } from '../../components/indicators_and_messaging/badge'
import { type TableColumn, TableLite } from '../../components/content_display/table_lite'
import { type PerformanceEstimate, type PerformanceEstimateRow } from '../../code/models/performance_estimate'
import { type CapacityResult } from '../../code/models/range_heat_pump'
import { type PipeData } from '../../code/models/pipes'
import { PipeSectionCard } from './design/pages/pipework_design_page'
import { FlowTempSlider } from './design/pages/emitter_design_page'
import { type EmitterDemandStatus } from '../admin/job_layout/job_layout'
import { type InventoryHeatPump, type InventoryHotWaterCylinder } from '../../code/models/inventory'

type DesignHomeProps = {
  design: SurveyDesign
  setDesign: (design: SurveyDesign) => void
  currentHeatPump?: InventoryHeatPump
  currentHotWaterCylinder?: InventoryHotWaterCylinder
  soundAssessment: number
  heatPumpCapacityResult: CapacityResult
  totalHeatLossKwSurvey: number
  annualBillEstimateGBP: string
  scop: number
  minFlowTemp: number
  maxFlowTemp: number
  emitterColumns: any
  emitterRows: any
  reheatTimeHoursAndMinutes: string
  hotWaterConsumptionAnnual: number
  performanceEstimateColumns: Array<TableColumn<PerformanceEstimateRow>>
  hddPerformanceEstimate: PerformanceEstimate
  lineItemColumns: any
  emitterDemandStatus: EmitterDemandStatus
  pipeData: PipeData[]
  navigateTo: (url: string) => void
}

export const DesignHome = ({
  design,
  setDesign,
  currentHeatPump,
  currentHotWaterCylinder,
  heatPumpCapacityResult,
  totalHeatLossKwSurvey,
  annualBillEstimateGBP,
  scop,
  minFlowTemp,
  maxFlowTemp,
  emitterColumns,
  emitterRows,
  reheatTimeHoursAndMinutes,
  hotWaterConsumptionAnnual,
  performanceEstimateColumns,
  hddPerformanceEstimate,
  soundAssessment,
  emitterDemandStatus,
  pipeData,
  navigateTo
}: DesignHomeProps) => {
  const setFlowTemp = (temp: number) => setDesign({ ...design, flow_temp: temp })

  // Demand calculations
  const heatPumpBadgeColor = heatPumpCapacityResult.capacityKw < totalHeatLossKwSurvey ? 'RED' : heatPumpCapacityResult.warning ? 'YELLOW' : 'GREEN'

  return <div className='flex flex-col h-full min-h-0'>
    <div className='grid md:grid-cols-2 lg:grid-cols-3 grid-cols-1 gap-3 overflow-y-auto'>
      <ClickableCard variant='WHITE' border={true}>
        <div className='flex flex-col gap-4'>
          <FlowTempSlider
            flowTemp={design.flow_temp}
            setFlowTemp={setFlowTemp}
            minFlowTemp={minFlowTemp}
            maxFlowTemp={maxFlowTemp}
          />
          <div className="gap-3 flex justify-between">
            {/* Putting total heat loss here for now until we do the quote section */}
            <div className="flex-col gap-1 flex">
              <div className="text-gray-900 text-xs font-bold">Heat loss</div>
              <div className="text-gray-600 text-base">{totalHeatLossKwSurvey.toFixed(2)} kW</div>
            </div>
            <div className="flex-col gap-1 flex">
              <div className="text-gray-900 text-xs font-bold">Running costs</div>
              <div className="text-gray-600 text-base">{annualBillEstimateGBP} per year</div>
            </div>
            <div className="flex-col gap-1.5 flex">
              <div className="text-gray-900 text-xs font-bold">Emitters</div>
              {totalHeatLossKwSurvey > 0 && <Badge
                color={emitterDemandStatus === 'Sufficient' ? 'GREEN' : emitterDemandStatus === 'Accepted' ? 'YELLOW' : 'RED'}
                text={emitterDemandStatus} />}
            </div>
          </div>
        </div>
      </ClickableCard>
      <ClickableCard variant='WHITE' border={true} title={
        <div className='flex gap-2 justify-between flex-grow'>
          <div>Heat pump</div>
          {totalHeatLossKwSurvey > 0 && <Badge
            color={heatPumpBadgeColor}
            text={heatPumpBadgeColor === 'GREEN' ? 'Meets demand' : heatPumpBadgeColor === 'RED' ? 'Demand not met' : 'Data not available at design condition'} />}
        </div>
      } onClick={() => navigateTo('/design/heat_pump')}>
        {currentHeatPump ? <div className="flex-col gap-2 flex">
          <div className="text-gray-900 text-sm font-bold">{currentHeatPump?.name}</div>
          <div className="flex-col flex">
            <div className="py-2 border-t border-gray-200 justify-between gap-2 flex">
              <div className="text-gray-600 text-xs font-semibold">SCOP at {design.flow_temp} °C</div>
              <div className="text-right text-gray-600 text-xs font-semibold">{scop}</div>
            </div>
            <div className="py-2 border-t border-gray-200 justify-between gap-2 flex">
              <div className="text-gray-600 text-xs font-semibold">Capacity at {heatPumpCapacityResult.flowTempC} °C ({heatPumpCapacityResult.outsideTempC} °C)</div>
              <div className="text-right text-gray-600 text-xs font-semibold">{heatPumpCapacityResult.capacityKw} kW</div>
            </div>
            <div className="py-2 border-t border-gray-200 justify-between gap-2 flex">
              <div className="text-gray-600 text-xs font-semibold">Sound assessment</div>
              <Badge
                color={soundAssessment === 0 ? 'YELLOW' : soundAssessment <= 42 ? 'GREEN' : 'RED'}
                text={soundAssessment === 0 ? 'Incomplete' : soundAssessment <= 42 ? 'Pass' : 'Fail'} />
            </div>
          </div>
        </div> : <div>No heat pump</div>}
      </ClickableCard>
      <ClickableCard variant='WHITE' border={true} title={
        <div className='flex gap-2 flex-grow justify-between'>
          <div>Emitters</div>
          {totalHeatLossKwSurvey > 0 && <Badge
            color={emitterDemandStatus === 'Sufficient' ? 'GREEN' : emitterDemandStatus === 'Accepted' ? 'YELLOW' : 'RED'}
            text={emitterDemandStatus} />}
        </div>
      } onClick={() => navigateTo('/design/emitters')}>
        <TableLite
          columns={emitterColumns}
          rows={emitterRows}
        />
      </ClickableCard>
      <ClickableCard variant='WHITE' border={true} title='Cylinder' onClick={() => navigateTo('/design/cylinders')}>
        {currentHotWaterCylinder
          ? <div className="flex-col gap-2 flex">
              <div className="text-gray-900 text-sm font-bold">{currentHotWaterCylinder.name}</div>
              <div className="flex-col flex">
                <div className="py-2 border-t border-gray-200 justify-between gap-2 flex">
                  <div className="text-gray-600 text-xs font-semibold">Capacity</div>
                  <div className="text-right text-gray-600 text-xs font-semibold">{Math.round(currentHotWaterCylinder.litres)} litres</div>
                </div>
                <div className="py-2 border-t border-gray-200 justify-between gap-2 flex">
                  <div className="text-gray-600 text-xs font-semibold">Reheat time</div>
                  <div className="text-right text-gray-600 text-xs font-semibold">{reheatTimeHoursAndMinutes}</div>
                </div>
                <div className="py-2 border-t border-gray-200 justify-between gap-2 flex">
                  <div className="text-gray-600 text-xs font-semibold">Electricity for hot water</div>
                  <div className="text-right text-gray-600 text-xs font-semibold">{hotWaterConsumptionAnnual.toFixed(0)} kWh</div>
                </div>
              </div>
            </div>
          : <div>No cylinder</div>}
      </ClickableCard>
      <ClickableCard variant='WHITE' border={true} title='Pipework' onClick={() => navigateTo('/design/pipework')}>
        {pipeData && <div className='flex flex-col divide-y divide-gray-200'>
          {pipeData.map(x => <PipeSectionCard key={x.id} pipeData={x}/>)}
        </div>}
      </ClickableCard>
      <ClickableCard variant='WHITE' border={true} title='Performance estimate' onClick={() => navigateTo('/design/performance')}>
        <div className='overflow-y-auto'>
          <TableLite columns={performanceEstimateColumns} rows={[hddPerformanceEstimate.existing, hddPerformanceEstimate.proposed, hddPerformanceEstimate.savings]}/>
        </div>
      </ClickableCard>
    </div>
  </div>
}
