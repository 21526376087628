export const scaleBy = 1.05 // controls sensitivity of zoom
export const maxScale = 100
export const minScale = 0.02
// Canvas colors
export const gray900 = '#111827'
export const gray200 = '#E5E7EB'
export const gray400 = '#9ca3af'
export const indigo600 = '#4F46E5'
export const white = '#FFFFFF'
export const red500 = '#ef4444'
export const FONT_SIZE = 12
export const DEFAULT_IMAGE_SCALE = 10
