import React from 'react'

export type VerticalFormGroupProps = {
  formLabel: JSX.Element
  input: JSX.Element
  size?: 'MD' | 'LG'
}

export const VerticalFormGroup = (
  {
    formLabel,
    input,
    size = 'MD'
  }: VerticalFormGroupProps) => {
  return (
    <div className={`flex-col ${size === 'LG' ? 'gap-3' : 'gap-2'} flex`}>
      {formLabel}
      {input}
    </div>
  )
}
