import { AdminContext } from './pages/admin/admin_layout'
import { getRoleForCompany, USER_ROLE_SUPERADMIN } from './code/models/user'
import { UnauthorizedPage } from './pages/admin/unauthorized_page'
import React, { type PropsWithChildren, useContext } from 'react'
import { Loader } from './components/indicators_and_messaging/loader'

export const RequireRole = ({ children, roles }: PropsWithChildren<{ roles: number[] }>) => {
  const adminContext = useContext(AdminContext)

  if (adminContext.isLoading) {
    return (
      <div className='w-full flex justify-center items-center my-4'>
        <Loader />
      </div>
    )
  }

  const currentRole = getRoleForCompany(adminContext.data?.user, adminContext.data?.company?.public_info.subdomain)

  if (currentRole === USER_ROLE_SUPERADMIN) {
    return children
  }

  if (currentRole && roles.includes(currentRole)) {
    return children
  }

  return <UnauthorizedPage/>
}
