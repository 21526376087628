import { noop } from 'lodash'
import React, { useEffect, useRef, useState } from 'react'
import { HLRContext } from '../heatloss_report'
import { type Floor } from '../../../../code/models/floor'
import { type Room } from '../../../../code/models/room'
import { getRoomsWithMetaData } from '../../floor/code/get_rooms_with_meta_data'
import {
  fixRotation,
  getNextWall,
  getStagePositionToCentreOnFloor,
  getStagePositionToCentreOnRoom,
  getStageScaleForFloor,
  getStageScaleForRoom
} from '../../floor/code/utils'
import { FloorStage } from '../../floor/floor_stage'

type Props = {
  floor: Floor
  height?: number
  room?: Room
}

export const HLRFloorplanBlock = ({ floor, room, height }: Props) => {
  const hlrContext = React.useContext(HLRContext)

  const stageRef = useRef<HTMLDivElement>(null)
  const [stageWidth, setStageWidth] = useState(0)
  const [stageHeight, setStageHeight] = useState(0)

  useEffect(() => {
    const width2height = (width: number) => {
      if (height) {
        return height
      }

      // if width is less than 640, it's very likely a mobile device, so we want to make the height bigger
      if (width < 640) {
        return width * 1.25
      } else {
        return width * 0.75
      }
    }

    const currentWidth = stageRef?.current?.clientWidth ?? 0
    const currentHeight = width2height(currentWidth)

    setStageWidth(currentWidth)
    setStageHeight(currentHeight)

    const handleResize = () => {
      setStageWidth(stageRef?.current?.clientWidth ?? 0)
      setStageHeight(width2height(stageRef?.current?.clientWidth ?? 0))
    }

    const resizeObserver = new ResizeObserver(handleResize)

    if (stageRef?.current) {
      resizeObserver.observe(stageRef?.current)
    }

    handleResize()

    return () => {
      if (stageRef?.current) {
        resizeObserver.unobserve(stageRef?.current)
      }
    }
  }, [])

  // window.onresize = () => {
  //   stageRef.current && setStageWidth(stageRef.current.clientWidth)
  // }
  //
  // useEffect(() => {
  //   stageRef.current && setStageWidth(stageRef.current.clientWidth)
  // }, [stageRef])

  const scalingCoefficient = 0.8

  // if room passed, scale on room, otherwise scale on floor
  const stageScale = room
    // SW: The scaleObject function that is called inside the getStageScaleForRoom function
    // has a bufferPixels input already which descries what the clearance should be around the room.
    // I played with using a multiplier when I first did this but it doesn't work very well because
    // for big rooms it feels very zoomed out. It would be better to play with the bufferPixels
    // argument inside the function here, or to pass that input into getStageScaleForRoom
    // if you need it to be configurable. I think it's only being used for this though
    // so shouldn't need multiple options.
    ? getStageScaleForRoom(room, stageWidth, stageHeight) * scalingCoefficient
    : getStageScaleForFloor(floor, stageWidth, stageHeight)

  // if room passed, center on room, otherwise center on floor
  const { x: stageX, y: stageY } = room
    ? getStagePositionToCentreOnRoom(room, stageScale, stageWidth, stageHeight)
    : getStagePositionToCentreOnFloor(floor, stageWidth, stageHeight, stageScale)

  const roomsWithMetaData = getRoomsWithMetaData(hlrContext!.survey, floor, hlrContext!.designTempC, hlrContext!.groundTempC, hlrContext!.design, stageScale)

  const wallsWithoutDuplicatesReduce = (prev, curr) => {
    if (!prev.some(x => x.x1 === curr.x1 && x.x2 === curr.x2 && x.y1 === curr.y1 && x.y2 === curr.y2)) return [...prev, curr]
    return prev
  }

  const wallsWithoutDuplicates = floor.rooms
    .flatMap(r => r.walls.filter(x => x.other_room_uuid)
      .map(w => {
        const nextWall = getNextWall(w, r.walls)
        const line = { x1: w.x! + r.x!, x2: nextWall.x! + r.x!, y1: w.y! + r.y!, y2: nextWall.y! + r.y! }
        const lineRotated = fixRotation(line.x1, line.x2, line.y1, line.y2)
        return { ...lineRotated, uuid: w.uuid }
      }))
    .reduce<Array<{ x1: number, x2: number, y1: number, y2: number, uuid: string }>>(wallsWithoutDuplicatesReduce, [])

  return <div className={'rounded-xl overflow-hidden w-full self-center'} ref={stageRef}>
    <FloorStage
      planOpacity={0}
      scalingDoor={false}
      survey={hlrContext!.survey}
      scalingPoints={[]}
      mousePosition={undefined}
      tempImageAndScale={{ image: '', scale: 1 }}
      addEvent={noop}
      stageScale={stageScale}
      stagePosition={{ x: stageX, y: stageY }}
      stageSize={{ width: stageWidth, height: stageHeight }}
      defaultMaterials={hlrContext!.survey.default_materials!}
      roomsWithMetaData={roomsWithMetaData}
      currentRoom={undefined}
      currentRoomId={undefined}
      setCurrentRoomId={noop}
      wallsWithoutDuplicates={wallsWithoutDuplicates}
      floor={floor}
      dragStopped={false}
      currentWall={undefined}
      setStageScale={noop}
      setPage={noop}
      setCurrentWallId={noop}
      onMouseMove={noop}
      setWalls={noop}
      setStagePosition={noop}
      onClick={noop}
      onTouchEnd={noop}
      onDragMove={noop}
      onWheelProp={noop}
      onTouchMove={noop}
      isStatic={true}
      stageRef={undefined}
      setStageSize={noop}
      stageStep={undefined}
      isDrawing={false}
      intersectingShapes={[]}
      scalingWindow={false}
      guidelines={[]}
      setGuidelines={noop}
      showFloorPlan={false}
    />
  </div>
}
