import { type LucideIcon } from 'lucide-react'
import { WrappedIcon } from '../../../components/buttons/wrapped_icon'
import React from 'react'

type MenuItemProps = {
  isSelected: boolean
  name: string
  icon: LucideIcon
  onClick: () => void
}

export const MenuItem = ({ name, icon, isSelected, onClick }: MenuItemProps) => {
  return <div className={`p-2 ${isSelected ? 'bg-white shadow' : ''} rounded-md items-center flex cursor-pointer`} onClick={onClick}>
    <div className="items-center gap-3 flex">
      <WrappedIcon icon={icon} />
      <div className={`${isSelected ? 'font-bold text-bold' : 'font-semibold'} text-sm font-semibold leading-tight`}>{name}</div>
    </div>
  </div>
}
