import React from 'react'
import { orderBy, sum } from 'lodash'
import { type ElectricsConsumerUnitItem } from '../../../../code/models/property'
import { SurveyElectricsContext } from '../electrics'
import { ClickableCard } from '../../../../components/content_display/card'
import { Table } from '../../../../components/content_display/table'
import { type TableColumn } from '../../../../components/content_display/table_lite'
import { Input } from '../../../../components/inputs_and_selections/input'
import { TextArea } from '../../../../components/inputs_and_selections/text_area'
import { Toggle } from '../../../../components/inputs_and_selections/toggle'

export const ElectricsConsumerUnitDetailsBlock = () => {
  const electricsContext = React.useContext(SurveyElectricsContext)
  const { survey, setSurvey } = electricsContext!

  const setConsumerUnitItems = (items: ElectricsConsumerUnitItem[]) => setSurvey({ ...survey, electrics_consumer_unit_items: items })

  const tableColumns: Array<TableColumn<ElectricsConsumerUnitItem>> = [
    { key: 'name', name: 'Name' },
    { key: 'fuse_size_a', name: 'Fuse size (A)' }
  ]

  // Calculate the maximum demand by getting 100% of the highest fuse item and 40% of all other items.
  const getConsumerUnitMaximumDemand = (consumerUnitItems: ElectricsConsumerUnitItem[]) => {
    if (consumerUnitItems.length === 0) return 0

    const orderedItems = orderBy(consumerUnitItems, x => x.fuse_size_a, 'desc')
    const maxItem = orderedItems[0].fuse_size_a
    const rest = sum(orderedItems.slice(1).map(x => x.fuse_size_a))

    return `100% x ${maxItem} A + 40% x ${rest} A = ${maxItem + rest * 0.4} A`
  }

  // This function assumes a UK 230V system.
  const kwToAmps = (kw: number) => {
    const voltage = 230
    const watts = kw * 1000

    return watts / voltage
  }

  return <>
    <div className="text-gray-900 text-xl font-bold">Consumer unit</div>
    <div className='flex flex-col gap-2'>
      <div className='text-gray-900 font-bold'>Contents</div>
      <Table
        columns={tableColumns}
        rows={survey.electrics_consumer_unit_items}
        addNew={true}
        editable={true}
        onDeleteRow={(id) => setConsumerUnitItems(survey.electrics_consumer_unit_items.filter(x => x.uuid !== id))}
        onSaveRow={(row) => row.uuid ? setConsumerUnitItems(survey.electrics_consumer_unit_items.map(x => x.uuid === row.uuid ? {
          ...row,
          fuse_size_a: Number(row.fuse_size_a)
        } : x)) : setConsumerUnitItems([...survey.electrics_consumer_unit_items, {
          ...row,
          fuse_size_a: Number(row.fuse_size_a),
          uuid: crypto.randomUUID()
        }])}
      />
      <div className='font-bold text-gray-900'>Calculated maximum demand</div>
      <div>{getConsumerUnitMaximumDemand(survey.electrics_consumer_unit_items)}</div>
    </div>
    <div className="gap-3 flex justify-between">
      <div className="text-gray-900 font-bold">Spare way on the consumer unit</div>
      <Toggle value={survey.electrics_spare_way}
        setValue={() => setSurvey({ ...survey, electrics_spare_way: !survey.electrics_spare_way })}/>
    </div>
    <div className="flex-col gap-2 flex">
      <div className="text-gray-900 font-bold">MCB Brand</div>
      <Input value={survey.electrics_breaker_make} placeholder='e.g. Hager'
        setValue={(e) => setSurvey({ ...survey, electrics_breaker_make: e })}/>
    </div>
    <div className="flex-col gap-2 flex">
      <div className="text-gray-900 font-bold">Notes</div>
      <TextArea value={survey.electrics_notes} setValue={(e) => setSurvey({ ...survey, electrics_notes: e })}/>
    </div>
    <ClickableCard variant='GREY'>
      <div className='flex flex-col gap-5'>
        <div className="justify-between items-center gap-3 flex">
          <div className="text-gray-900 font-bold">Smart meter</div>
          <Toggle value={survey.electrics_has_smart_meter} setValue={() => setSurvey({
            ...survey,
            electrics_has_smart_meter: !survey.electrics_has_smart_meter
          })}/>
        </div>
        {survey.electrics_has_smart_meter && <>
          <div className='flex flex-col gap-2'>
            <div className='text-gray-900'>
              <div className='font-bold text-gray-900'>Peak electricity demand</div>
              <div className='text-sm text-gray-500'>If the homeowner has an in-home display or an app connected
                        to their smart meter, find the max half-hourly energy consumption from last year. We'll
                        convert this to the average current in that half hour. This can be used as an alternative
                        measure of max demand.
              </div>
            </div>
            <Input
              type='number'
              value={survey.electrics_peak_electricity_demand_kwh.toString()}
              setValue={(e) => setSurvey({ ...survey, electrics_peak_electricity_demand_kwh: Number(e) })}
              postfix='kWh'/>
          </div>
          <div className='flex flex-col gap-2'>
            <div className='font-bold text-gray-900'>Calculated maximum demand</div>
            <div>{Math.round(kwToAmps(survey.electrics_peak_electricity_demand_kwh / 0.5))} A</div>
          </div>
        </>}
      </div>
    </ClickableCard>
    <div className="flex-col gap-2 flex">
      <div className="flex-col flex">
        <div className="text-gray-900 font-bold">MPAN number</div>
        <div className="text-gray-500 text-sm">A 13 digit number typically printed on an electricity bill.</div>
      </div>
      <Input value={survey.electrics_mpan_number} setValue={(e) => setSurvey({ ...survey, electrics_mpan_number: e })}/>
    </div>
  </>
}
