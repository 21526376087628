import React, { type FC, useState } from 'react'
import { fileToBase64 } from '../../code/helpers'
import { type FileWrapper } from '../../pages/heat_loss/file_wrapper'
import { noop } from 'lodash'
import { Icon } from '../buttons/icon'
import { createPortal } from 'react-dom'
import { WrappedIcon } from '../buttons/wrapped_icon'
import { Camera, Plus, Wifi, X } from 'lucide-react'

export const MAX_IMAGE_WIDTH = 1800
export const MAX_IMAGE_HEIGHT = 1800

type PhotoScrollProps = {
  images: FileWrapper[]
  deleteImage?: ((id: string) => void)
  addImage?: ((image: FileWrapper) => void)
  doNotScale?: boolean
  companyUUID: string
  viewMode?: 'COMPACT' | 'NORMAL'
}
export const PhotoScroll = ({ images, deleteImage, addImage, doNotScale, companyUUID, viewMode }: PhotoScrollProps) => {
  const [currentImageId, setCurrentImageId] = useState<string | undefined>(undefined)

  // sometimes we got inconsistent data, so we need to filter out undefined images
  const cleanedImages = images.filter(x => x !== undefined)
  const currentImage = cleanedImages.find(x => x.uuid === currentImageId)
  const randomUUID = crypto.randomUUID()

  const handleFileSelect = addImage ? async (e) => {
    // if doNotScale is true, we don't need to scale the image
    if (doNotScale) {
      const newFile = e.target?.files?.[0]
      const base64 = await fileToBase64(newFile)
      const newFileWrapper: FileWrapper | undefined = newFile && {
        uuid: crypto.randomUUID(),
        file_base64: base64,
        created_at: new Date().getTime(),
        updated_at: new Date().getTime(),
        is_modified: true,
        server_updated_at: 0,
        deleted_at: undefined,
        company_uuid: companyUUID
      }
      newFileWrapper && addImage(newFileWrapper)
      return
    }

    // otherwise, we need to scale the image
    const selectedFile = e.target?.files?.[0]
    if (!selectedFile) return

    // change image size
    const image = new Image()
    image.src = URL.createObjectURL(selectedFile)

    // Ensure image is loaded before drawing
    image.onload = () => {
      const canvas = document.createElement('canvas')

      let width = image.width
      let height = image.height

      // Maintain aspect ratio and scale if necessary
      if (width > MAX_IMAGE_WIDTH || height > MAX_IMAGE_HEIGHT) {
        const aspectRatio = width / height
        if (width > height) {
          width = MAX_IMAGE_WIDTH
          height = MAX_IMAGE_WIDTH / aspectRatio
        } else {
          height = MAX_IMAGE_HEIGHT
          width = MAX_IMAGE_HEIGHT * aspectRatio
        }
      }

      canvas.width = width
      canvas.height = height
      const context = canvas.getContext('2d')
      if (!context) return
      context.drawImage(image, 0, 0, width, height)

      const base64 = canvas.toDataURL()
      const newFileWrapper = {
        uuid: crypto.randomUUID(),
        file_base64: base64,
        file_url: undefined,
        created_at: new Date().getTime(),
        updated_at: new Date().getTime(),
        is_modified: true,
        server_updated_at: 0,
        deleted_at: undefined,
        company_uuid: companyUUID
      }
      addImage(newFileWrapper)
    }
  } : noop

  const isCompactMode = viewMode === 'COMPACT' && cleanedImages.length === 0

  return <>
    <div className='flex w-full overflow-auto gap-2'>
      {addImage && <>
        <label htmlFor={`dropzone-file-${randomUUID}`}>

          {/* COMPACT view: used e.g. for survey workflow */}
          {isCompactMode &&
            <div className="px-4 py-3 rounded-md border border-gray-300 flex gap-2 items-center cursor-pointer">
              <WrappedIcon className='text-sm' icon={Plus}/>
              <div className="text-sm font-bold">Add photos</div>
            </div>
          }

          {/* NORMAL mode: works as usual */}
          {!isCompactMode &&
              <div
                className='flex flex-col gap-2 w-32 h-32 bg-gray-100 items-center justify-center cursor-pointer text-gray-600 rounded-lg'>
                <WrappedIcon className='text-lg' icon={Camera}/>
                <div>Add photo</div>
              </div>
          }
        </label>
        <input id={`dropzone-file-${randomUUID}`} className='hidden' type="file" accept='image/*' onChange={handleFileSelect}/>
      </>}

      {/* gallery */}
      {cleanedImages.reverse().map(x => <ImageWithFallback key={x.uuid} fileWrapper={x} deleteImage={deleteImage} setCurrentImageId={setCurrentImageId} />)}
    </div>

    {/* if an image opened fullscreen */}
    {currentImage && createPortal(<div
      onMouseDown={(e) => e.target === e.currentTarget ? setCurrentImageId(undefined) : noop}
      className={'fixed inset-0 bg-gray-500 bg-opacity-75 flex items-center justify-center p-4 z-20'}>
      <img className='rounded-lg max-h-full max-w-full object-contain' src={currentImage.file_base64 || `${process.env.S3_BUCKET_URL}/${currentImage.file_url}`} />
    </div>, document.body)}
  </>
}

type ImageWithFallbackProps = {
  deleteImage: ((id: string) => void) | undefined
  fileWrapper: FileWrapper
  setCurrentImageId: (id: string) => void
}
const ImageWithFallback: FC<ImageWithFallbackProps> = ({ deleteImage, fileWrapper, setCurrentImageId }) => {
  const [fallback, setFallback] = useState(false)

  return <div key={fileWrapper.uuid} className='relative flex-shrink-0'>
    {fallback && <div className='rounded-lg h-32 flex flex-col gap-1 items-center justify-center bg-gray-200 p-4'>
      <Icon icon={Wifi} className='mb-2' />
      <div className='text-sm font-bold text-gray-900'>Image not available</div>
      <div className='text-sm text-gray-600'>Images will reappear <br />when you are online</div>
    </div>}
    {deleteImage && !fallback && <WrappedIcon onClick={() => deleteImage(fileWrapper.uuid!)} className='text-gray-600 cursor-pointer absolute top-0 right-0 z-10 p-2' icon={X} />}
    {!fallback && <img className='rounded-lg h-32 cursor-pointer' onError={() => setFallback(true)} onClick={() => setCurrentImageId(fileWrapper.uuid!)} src={fileWrapper.file_base64 || `${process.env.S3_BUCKET_URL}/${fileWrapper.file_url}`} />}
  </div>
}

export const resizeBase64Image = async (base64Str, maxWidth = MAX_IMAGE_WIDTH, maxHeight = MAX_IMAGE_HEIGHT) => {
  return await new Promise<string>((resolve, reject) => {
    const image = new Image()
    image.onload = () => {
      let { width, height } = image

      // Calculate new dimensions while maintaining aspect ratio
      if (width > maxWidth || height > maxHeight) {
        const aspectRatio = width / height

        if (width > height) {
          width = maxWidth
          height = maxWidth / aspectRatio
        } else {
          height = maxHeight
          width = maxHeight * aspectRatio
        }
      }

      const canvas = document.createElement('canvas')
      canvas.width = width
      canvas.height = height
      const ctx = canvas.getContext('2d')
      ctx!.drawImage(image, 0, 0, width, height)
      const resizedBase64 = canvas.toDataURL('image/png')

      resolve(resizedBase64)
    }
    image.onerror = (error) => reject(error)
    image.src = base64Str
  })
}
