import React from 'react'
import { FileDropzone } from '../../../components/inputs_and_selections/file_dropzone'

export const FileDropzoneDemo = () => {
  return <div className="flex flex-col gap-8">
    <div className='flex flex-col gap-4'>
      <div className='text-2xl font-bold text-gray-900'>Definition</div>

      <pre className='bg-gray-100 rounded-lg p-4 overflow-x-auto'><code>
        {codeBlock}
      </code></pre>

      <div className='flex flex-col divide-y divide-dashed divide-gray-200 text-sm'>
        {componentProps.map(x => <div key={x.name} className='grid grid-cols-2 py-2'>
          <div className='font-bold text-gray-900'>{x.name}</div>
          <div>{x.description}</div>
        </div>)}
      </div>
    </div>

    <div className='flex flex-col gap-4'>
      <div className='text-2xl font-bold text-gray-900'>Component</div>
      <FileDropzone
        helperText='File must be an image or pdf'
        multiple
        accept='image/*, application/pdf'
        maxSizeMB={2}
        onChange={files => console.log(files)}
      />
    </div>

  </div>
}

const componentProps = [
  { name: 'helperText', description: 'Helper text for input - optional' },
  { name: 'maxSizeMB', description: 'Set the max size for files in MB - optional, defaults to 2MB' },
  { name: 'onChange', description: 'Function to run when a file is selected for upload' },
  { name: '...inputProps', description: 'Extra props spread on the file input - optional' }
]

const codeBlock = `<FileDropzone
  helperText='File must be an image or pdf'
  maxSizeMB={2}
  onChange={files => console.log(files)}
  multiple
  accept='image/*, application/pdf'
/>`
