import React from 'react'
import { HLRContext } from './heatloss_report'
import { formatPrice } from '../../../code/format_price'
import { HLRHeaderBlock } from './partials/hlr_header'

export const HLRPESummary = () => {
  const hlrContext = React.useContext(HLRContext)
  const estimateSummary = hlrContext!.performanceEstimateSummary

  return <div className='flex flex-col gap-8'>
    <HLRHeaderBlock
      title='Performance estimate'
      descriptionNode={<>
        <div className="text-lg">Predicting your running costs with a heat pump is hard as it depends on a lot of
          factors,
          many of which are outside our control. But it’s clearly really important that you’re able to make an informed
          decision. So in this section we give you our best estimate of what the running costs and carbon savings will
          look like for your home if and when you switch to a heat pump.
        </div>
        <div className="text-lg">We then provide an in-depth description of how we’ve calculated these figures in case
          you
          want to dig into the details. Please do ask us if anything doesn't make sense!
        </div>
      </>
      }
    />

    <div className='flex flex-col gap-6'>
      <div className='text-3xl font-bold text-gray-900'>Summary</div>
      <div className='grid grid-cols-1 sm:grid-cols-2 gap-3'>
        <div className='p-5 rounded-md flex-col gap-10 flex bg-gray-100 border-gray-100 border'>
          <div className="text-gray-900 text-2xl font-bold">Bills</div>
          <div className="flex flex-col gap-2">
            <div className='flex flex-row justify-between'>
              <div className="text-gray-900 font-semibold">Current</div>
              <div className="font-semibold">{estimateSummary.currentBillRange}</div>
            </div>
            <div className='flex flex-row justify-between'>
              <div className="text-gray-900 font-semibold">Proposed system</div>
              <div className="font-semibold">{estimateSummary.proposedBillRange}</div>
            </div>

          </div>
          <div className="flex flex-row justify-between">
            <div className="text-gray-900 text-lg font-bold">Savings</div>
            <div className="px-1 rounded-md gap-1 flex">
              <div
                className={`${estimateSummary.minBillSavings > 0 ? 'bg-green-200' : 'bg-rose-200'} px-1 rounded-md gap-2.5 flex`}>
                <div
                  className="text-green-950 text-3xl font-bold">{formatPrice(estimateSummary.minBillSavings, 0)}</div>
              </div>
              <div className="text-green-950 text-3xl font-bold">to</div>
              <div
                className={`${estimateSummary.maxBillSavings > 0 ? 'bg-green-200' : 'bg-rose-200'} px-1 rounded-md gap-2.5 flex`}>
                <div
                  className="text-green-950 text-3xl font-bold">{formatPrice(estimateSummary.maxBillSavings, 0)}</div>
              </div>
            </div>
          </div>
        </div>

        <div className='p-5 rounded-md flex-col gap-10 flex bg-gray-100 border-gray-100 border'>
          <div className="text-gray-900 text-2xl font-bold">Carbon</div>
          <div className="flex flex-col gap-2">
            <div className='flex flex-row justify-between'>
              <div className="text-gray-900 font-semibold">Current</div>
              <div className="font-semibold">{estimateSummary.currentEmissionsRange}</div>
            </div>
            <div className='flex flex-row justify-between'>
              <div className="text-gray-900 font-semibold">Proposed system</div>
              <div className="font-semibold">{estimateSummary.proposedEmissionsRange}</div>
            </div>

          </div>
          <div className="flex flex-row justify-between">
            <div className="text-gray-900 text-lg font-bold">Savings</div>
            <div className="px-1 rounded-md gap-1 flex">
              <div
                className={`${estimateSummary.minEmissionsPercentageSavings > 0 ? 'bg-green-200' : 'bg-rose-200'} px-1 rounded-md gap-2.5 flex`}>
                <div
                  className="text-green-950 text-3xl font-bold">{(estimateSummary.minEmissionsPercentageSavings * 100).toFixed(0)}%
                </div>
              </div>
              {(estimateSummary.minEmissionsPercentageSavings * 100).toFixed(0) !== (estimateSummary.maxEmissionsPercentageSavings * 100).toFixed() && <>
                <div className="text-green-950 text-3xl font-bold">to</div>
                <div
                  className={`${estimateSummary.maxEmissionsPercentageSavings > 0 ? 'bg-green-200' : 'bg-rose-200'} px-1 rounded-md gap-2.5 flex`}>
                  <div
                    className="text-green-950 text-3xl font-bold">{(estimateSummary.maxEmissionsPercentageSavings * 100).toFixed(0)}%
                  </div>
                </div>
              </>}
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
}
