import type { CustomAttributes, Material, WindowAttributes } from './material'

export type RoofLight = {
  uuid?: string
  material: Material | undefined
  width_mm: number
  height_mm: number
}

export const getRooflightUValue = (rooflight: RoofLight, ceilingTypeUUID: string, thermalBridgingUValueAdditionWPerM2K: number) => {
  return getRooflightMaterialUValue(rooflight) + getRoofligthUValuePitchCorrection(ceilingTypeUUID) + thermalBridgingUValueAdditionWPerM2K
}

export const getRooflightMaterialUValue = (rooflight: RoofLight) => {
  // if rooflight window made of custom material, use the u value from the material
  // this is only to ensure we're using the right typing system and accessing right properties
  if (['custom_layered', 'custom_simple'].includes(rooflight.material!.type)) {
    return (rooflight.material!.extra_data as CustomAttributes).u_value!
  }
  return (rooflight.material!.extra_data as WindowAttributes).u_value
}

export const getRoofligthUValuePitchCorrection = (ceilingTypeUUID: string) => {
  // U values for windows/rooflights are defined as if they are vertical. Not being vertical changes the U value.
  // More details: https://www.notion.so/spruce-energy/Rooflights-2e4514d7fabd4096ab31d7cb0b996178?pvs=4#2ff31aca645b44ab8e04fdf946f58565
  if (ceilingTypeUUID === 'flat') {
    // for flat, use +0.5
    return 0.5
  } else {
    // for vaulted use +0.3 (assuming pitch between 50 and 20 and it’s double glazed)
    return 0.3
  }
}
