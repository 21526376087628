import React from 'react'

type ColoredBlockWrapperProps = {
  children: React.ReactNode
  color: 'gray' | 'green' | 'blue' | 'yellow'
}

export const ColoredBlockWrapper: React.FC<ColoredBlockWrapperProps> = ({ children, color }) => {
  const colors = {
    gray: 'bg-gray-100',
    green: 'bg-green-50',
    blue: 'bg-slate-100',
    yellow: 'bg-yellow-50'
  }

  return <div className={`${colors[color]} w-full p-5 rounded-lg flex-col flex`}>
    {children}
  </div>
}
