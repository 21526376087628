import React from 'react'
import { type Size } from '../../../code/extra_types'

type CaptionProps = {
  content: string
  className?: string
  size?: Size
  uppercase?: boolean
}

export const Caption: React.FC<CaptionProps> = ({ content, className = '', size = 'md', uppercase = false }) => {
  let textSize: string = ''

  switch (size) {
    case 'sm':
      textSize = 'text-sm'
      break
    case 'md':
      textSize = ' '
      break
    case 'lg':
      textSize = 'text-lg '
      break
  }
  return <div className={`${className} ${textSize} ${uppercase ? 'uppercase' : ''} text-gray-600`}>{content}</div>
}
