import Dexie, { type EntityTable } from 'dexie'
import { type PropertySurveyWrapper } from '../../code/models/property'
import { type FileWrapper } from './file_wrapper'
import { type Material } from '../../code/models/material'

export type Db = typeof db
export const db = new Dexie('spruce') as Dexie & {
  surveys: EntityTable<PropertySurveyWrapper, 'uuid'>
  custom_materials: EntityTable<Material, 'uuid'>
  files: EntityTable<FileWrapper, 'uuid'>
}

(window as any).db = db

// Schema declaration:
db.version(1).stores({
  surveys: '++uuid, updated_at, deleted_at, is_modified',
  files: '++uuid, updated_at, deleted_at, company_uuid, is_modified',
  custom_materials: '++uuid, updated_at, delted_at, company_uuid, is_modified'
})
