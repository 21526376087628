import React from 'react'
import { type Company, updateCompany } from '../../../../../code/models/company'
import { Heading } from '../../../../../components/content_display/heading'
import { InventoryTable, type InventoryTableColumn } from '../inventory_table'
import { Section } from '../../../job_layout/components/section'
import { Input } from '../../../../../components/inputs_and_selections/input'

type Props = {
  company: Company
  setCompany: (company: Company) => void
}

export const EmittersInventory = ({ company, setCompany }: Props) => {
  const emitterColumns: Array<InventoryTableColumn<{ name: React.ReactNode, price: React.ReactNode }>> = [
    { key: 'name', name: 'Emitter type' },
    { key: 'price', name: 'Default price' }
  ]

  const emitterRows = [
    {
      name: 'Radiator',
      price: <Input
        type='number'
        value={company.default_radiator_cost || 0}
        prefix="£"
        postfix="/radiator"
        setValue={async (value) => {
          const updatedCompany = { ...company, default_radiator_cost: parseFloat(value) }
          await updateCompany(updatedCompany)
          setCompany(updatedCompany)
        }}
      />
    },
    {
      name: 'Underfloor Heating',
      price: <Input
        type='number'
        value={company.default_underfloor_cost || 0}
        prefix="£"
        postfix="/m²"
        setValue={async (value) => {
          const updatedCompany = { ...company, default_underfloor_cost: parseFloat(value) }
          await updateCompany(updatedCompany)
          setCompany(updatedCompany)
        }}
      />
    }
  ]

  return (
    <div className="space-y-6">
      <div className="flex flex-col gap-3 justify-start items-start sm:flex-row sm:justify-between sm:items-center w-full">
        <Heading size="2xl">Emitters</Heading>
      </div>
      <Section>
        <InventoryTable
          rows={emitterRows}
          columns={emitterColumns}
        />
      </Section>
    </div>
  )
}
