import React, { type FC, useContext, useState } from 'react'
import { SurveyActionType } from '../../../code/survey/survey'
import { Button } from '../../../components/buttons/button'
import { logEvent } from '../../../code/log_event'
import { getAddressIncludingPostcode } from '../../../code/models/lead'
import { SurveyContext, SurveyDispatchContext } from '../survey_page'
import { SurveyOptionGroup } from '../../../components/inputs_and_selections/survey_option_group'
import { StepHeader } from '../components/steps_header'
import { WALL_GROUPS } from '../../../code/models/u_value'
import { type CompanyPublicInfo } from '../../../code/models/company'
import { ArrowLeftIcon, ChevronRightIcon } from 'lucide-react'

type WallTypeStepProps = {
  companyPublicInfo: CompanyPublicInfo
}

export const WallTypeStep: FC<WallTypeStepProps> = ({ companyPublicInfo }) => {
  // survey context and dispatch
  const survey = useContext(SurveyContext)
  const dispatch = useContext(SurveyDispatchContext)

  const [hasSelectionChanged, setHasSelectionChanged] = useState(false)
  const [selectedOption, setSelectedOption] = useState<string>(survey.lead.property.wallGroup ? survey.lead.property.wallGroup : survey.lead.property.construction_age_band?.wallGroup ?? '')

  const handleNavigatePreviousPage = () => {
    dispatch({
      type: SurveyActionType.NavigatePreviousPage
    })
  }

  const handleNavigateNextPage = () => {
    logEvent({ name: 'Wall type step completed', properties: {} }, companyPublicInfo.subdomain)

    dispatch({
      type: SurveyActionType.NavigateNextPage,
      payload: {
        wallGroup: selectedOption,
        // If we know it's cavity wall but they select solid brick, we need to remove e.g. Insulated wallType from EPC so our defaults can take over.
        wallType: hasSelectionChanged ? undefined : survey.lead.property.wallType
      }
    })
  }

  return <>
    {/* Back button */}
    <Button colour='LIGHT' onClick={handleNavigatePreviousPage} block={false} className="self-start">
      <div className="flex items-center gap-x-2">
        <ArrowLeftIcon className="h-4 w-4" />
        <div>Back</div>
      </div>
    </Button>

    {/* Header */}
    <StepHeader
      guideText={getAddressIncludingPostcode(survey.lead)}
      headerText="What are most of your externals walls made of?"
    />

    {/* Options */}
    <SurveyOptionGroup options={WALL_GROUPS.map(x => ({ icon: x.icon, key: x.name, label: x.name }))} onSelect={(key: string) => {
      setSelectedOption(key)
      setHasSelectionChanged(true)
    }} selectedOptionKey={selectedOption} />

    <Button size='LG' disabled={!selectedOption} onClick={handleNavigateNextPage}>
      <div className="flex items-center gap-x-2">
        <div>Continue</div>
        <ChevronRightIcon className="h-6 w-6" />
      </div>
    </Button>
  </>
}
