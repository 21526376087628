import React, { useState } from 'react'
import { Modal } from '../../components/containers/modal'
import { FormLabel } from '../../components/inputs_and_selections/form_label'
import { VerticalFormGroup } from '../../components/inputs_and_selections/vertical_form_group'
import { validateURLAndHasProtocol, validateNotNull } from '../validators'
import { Input } from '../../components/inputs_and_selections/input'

export const AddExternalAttachmentModal = ({ visible, setVisible, onAddAttachment, forceTitle }: { visible: boolean, setVisible: (boolean) => void, onAddAttachment: (title, url) => void, forceTitle?: string }) => {
  const [fileName, setTitle] = useState(forceTitle ?? '')
  const [url, setUrl] = useState('')

  const handleCloseModal = (open: boolean) => {
    if (visible) {
      setTitle(forceTitle || '')
      setUrl('')
      setVisible(open)
    }
  }

  const validFields = [validateURLAndHasProtocol(url), validateNotNull(fileName)].every(e => e.value)

  return <Modal
    visible={visible}
    setVisible={() => handleCloseModal(false)}
    title='Add external attachment'
    confirmButtonLabel='Save'
    confirmDisabled={!validFields}
    onConfirm={() => onAddAttachment(fileName, url)}
  >
    <div className='w-full flex flex-col gap-4'>
      <VerticalFormGroup
        formLabel={<FormLabel labelText='Document title' required complete={fileName !== ''}/>}
        input={<Input
          placeholder='e.g. Case Study'
          setValue={title => setTitle(title)}
          value={forceTitle ?? fileName ?? ''}
          validator={validateNotNull}
          disabled={Boolean(forceTitle)}
        />}
      />
      <VerticalFormGroup
        formLabel={<FormLabel labelText={'File URL'} required complete={url !== '' && !!validateURLAndHasProtocol(url).value}/>}
        input={<Input
          placeholder='URL'
          setValue={url => setUrl(url)}
          value={url ?? ''}
          validator={validateURLAndHasProtocol}
          doNotValidateWhenEmpty
        />}
      />
    </div>
  </Modal>
}
