import React, { useContext, useState } from 'react'
import { orderBy, sum } from 'lodash'
import { AdminContext } from '../../../admin_layout'
import { type CalculatedEstimate } from '../../../../../code/models/calculated_estimate'
import { type TableColumn } from '../../../../../components/content_display/table'
import { type Lead } from '../../../../../code/models/lead'
import { formatPrice } from '../../../../../code/format_price'
import { formatDate } from '../../../../../code/format_date'
import { Link } from '../../../../../components/buttons/link'
import { Button } from '../../../../../components/buttons/button'
import { Section } from '../../components/section'
import { TableLite } from '../../../../../components/content_display/table_lite'
import { SendNudgeLeadEmailModal } from '../../../components/send_nudge_lead_email_modal'
import { type Estimate } from '../../../../../code/calculate_estimate'

type Props = {
  lead: Lead
  estimate: Estimate
  calculatedEstimates: CalculatedEstimate[]
  subdomain: string
  reloadTrigger: () => void
}

export const EnquiryHistory = ({ lead, estimate, calculatedEstimates, subdomain, reloadTrigger }: Props) => {
  const adminContext = useContext(AdminContext)

  const [isEstimateModalVisible, setIsEstimateModalVisible] = useState(false)
  const [currentCalculatedQuote, setCurrentCalculatedQuote] = useState<CalculatedEstimate | undefined>(undefined)

  const columns: Array<TableColumn<CalculatedEstimate>> = [
    { name: 'Estimate', render: (x) => <div>{x.heat_pump_name}, {formatPrice(sum(x.calculated_estimate_quote_items?.map(i => i.selected ? i.price * i.quantity : 0)))}</div> },
    { name: 'Status', render: (x) => <div>{x.status}</div> },
    { name: 'Date Sent', render: (x) => <div>{(x.created_at && formatDate(x.created_at)) || 'n/a'}</div> },
    { name: 'Date Reminder Sent', render: (x) => <div>{formatDate(x.reminder_sent_at)}</div> },
    { name: '', render: (x) => <Link text='View estimate' onClick={() => window.open(`/${subdomain}/estimate/${x.uuid}?installer=true`)} /> },
    {
      name: '',
      render: (x) => {
        return <div className='flex justify-end gap-4 items-center'>
          {(x.status === 'Sent' || x.status === 'Opened') &&
            <Button onClick={() => {
              setCurrentCalculatedQuote(x)
              setIsEstimateModalVisible(true)
            }}>Send reminder</Button>
          }
        </div>
      }
    }
  ]

  const orderedEstimates = orderBy(calculatedEstimates, x => new Date((x.created_at || Date.now()).toString()), 'desc')

  return (
    <>
      <Section title='Enquiry history'>
        <TableLite columns={columns} rows={orderedEstimates} />
      </Section>
      <SendNudgeLeadEmailModal
        adminContext={adminContext}
        isVisible={isEstimateModalVisible}
        setIsVisible={setIsEstimateModalVisible}
        estimate={estimate}
        calculatedEstimate={currentCalculatedQuote}
        lead={lead}
        reloadTrigger={reloadTrigger}
      />
    </>
  )
}
