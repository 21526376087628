import React from 'react'
import { Button } from '../../../components/buttons/button'
import { type PropertySurvey } from '../../../code/models/property'
import { type SetIndexedDb } from '../../admin/job_layout/job_layout'
import { Check, Undo } from 'lucide-react'

type CompleteButtonProps = {
  page: string
  pageChecks: boolean[]
  survey: PropertySurvey
  setSurvey: SetIndexedDb<PropertySurvey>
  onBack: () => Promise<void>
  secondaryButton?: React.ReactNode
}
export const CompleteButton = ({ page, pageChecks, survey, setSurvey, onBack, secondaryButton }: CompleteButtonProps) => {
  const isCompleted = survey.completed_sections.includes(page)
  const addOrRemoveSection = async () => await setSurvey(prev => ({
    ...prev,
    completed_sections: isCompleted
      ? prev.completed_sections.filter(x => x !== page)
      : [...prev.completed_sections, page]
  }))

  return <div className="p-3 bg-white flex-col gap-3 flex justify-center items-center shadow-2xl">
    {pageChecks.length > 0 && <div className="text-sm font-bold">{pageChecks.filter(x => !!x).length}/{pageChecks.length} required questions complete</div>}
    <div className='flex flex-col w-full'>
      <div className='flex-row flex gap-2.5 flex-grow'>
        { secondaryButton && <div className='w-1/2'>
          { secondaryButton }
        </div>}
        <div className={secondaryButton ? 'w-1/2' : 'w-full'}>
          <Button block={true} colour='DARK' onClick={async () => {
            await addOrRemoveSection()
            await onBack()
          }} iconLeft={isCompleted ? Undo : Check}>Mark as {isCompleted ? 'incomplete' : 'complete'}</Button>
        </div>
      </div>
    </div>
  </div>
}
