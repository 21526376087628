import React, { useState } from 'react'
import { Slider } from '../../../components/inputs_and_selections/slider'

export const SliderDemo = () => {
  const [value, setValue] = useState(0)

  return <div className="flex flex-col gap-8">
    <div className='flex flex-col gap-4'>
      <div className='text-2xl font-bold text-gray-900'>Definition</div>

      <pre className='bg-gray-100 rounded-lg p-4 overflow-x-auto'><code>
        {codeBlock}
      </code></pre>

      <div className='flex flex-col divide-y divide-dashed divide-gray-200 text-sm'>
        {componentProps.map(x => <div key={x.name} className='grid grid-cols-2 py-2'>
          <div className='font-bold text-gray-900'>{x.name}</div>
          <div>{x.description}</div>
        </div>)}
      </div>
    </div>

    <div className='flex flex-col gap-4'>
      <div className='text-2xl font-bold text-gray-900'>Component</div>
      <Slider
        value={value}
        min={0}
        max={100}
        onChange={setValue}
        className=''
        step={10}
      />
    </div>

  </div>
}

const componentProps = [
  { name: 'value', description: 'The current value of the slider' },
  { name: 'min', description: 'The minimum value the slider should go to' },
  { name: 'max', description: 'The maximum value the slider should go to' },
  { name: 'onChange', description: 'Do something after the slider moves with the new value' },
  { name: 'className', description: 'Any extra styling or formatting required' },
  { name: 'step', description: 'The increment each step of the slider should take when moving' }
]

const codeBlock = `const [value, setValue] = useState(0)

<Slider
  value={value}
  min={0}
  max={100}
  onChange={setValue}
  className=''
  step={10}
/>
`
