import {
  type Company,
  getCompanyBookingConfirmationActionEmailTemplate,
  getCompanyBookingConfirmationNoActionEmailTemplate
} from '../../../../../code/models/company'
import React from 'react'
import { Section } from '../../../../../components/containers/section'
import { Input } from '../../../../../components/inputs_and_selections/input'
import { Tiptap } from '../../../../../components/inputs_and_selections/tiptap'
import {
  TTPlaceholderCompanyName,
  TTPlaceholderCompanyPhone,
  TTPlaceholderCustomerName
} from '../../../../../code/tiptap_placeholders'
import { Toggle } from '../../../../../components/inputs_and_selections/toggle'
import { HorizontalFormGroup } from '../../../../../components/inputs_and_selections/horizontal_form_group'
import { FormLabel } from '../../../../../components/inputs_and_selections/form_label'
import { VerticalFormGroup } from '../../../../../components/inputs_and_selections/vertical_form_group'

const BookingConfirmationWithAction = ({ company, setCompany }: { company: Company, setCompany: (c: Company) => void }) => {
  return <VerticalFormGroup
    formLabel={<FormLabel labelText='Message' helperText='Book in directly' />}
    input={<Tiptap
      editable={true}
      className={'w-full rounded border border-gray-300 p-2 focus:outline-none'}
      onUpdateCallback={async (editor) => {
        setCompany({
          ...company,
          booking_confirmation_action_template: JSON.stringify(editor.getJSON())
        })
      }}
      placeholders={[
        TTPlaceholderCustomerName,
        TTPlaceholderCompanyName,
        TTPlaceholderCompanyPhone
      ]}
      content={getCompanyBookingConfirmationActionEmailTemplate(company.booking_confirmation_action_template ?? '')}
    />}
  />
}

const BookingConfirmationWithoutAction = ({ company, setCompany }: { company: Company, setCompany: (c: Company) => void }) => {
  return <VerticalFormGroup
    formLabel={<FormLabel labelText='Message' helperText='Wait for callback' />}
    input={
      <Tiptap
        editable={true}
        className={'w-full rounded border border-gray-300 p-2 focus:outline-none'}
        onUpdateCallback={async (editor) => {
          setCompany({
            ...company,
            booking_confirmation_noaction_template: JSON.stringify(editor.getJSON())
          })
        }}
        placeholders={[
          TTPlaceholderCustomerName,
          TTPlaceholderCompanyName,
          TTPlaceholderCompanyPhone
        ]}
        content={getCompanyBookingConfirmationNoActionEmailTemplate(company.booking_confirmation_noaction_template ?? '')}
      />}
  />
}

export const BookingConfirmationOptionsBlock = ({ company, setCompany }: { company: Company, setCompany: (c: Company) => void }) => {
  return <Section border title="Booking survey options">
    <div className='flex flex-col gap-4'>
      <HorizontalFormGroup
        formLabel={<FormLabel labelText='Take user straight to booking link' />}
        input={<Toggle value={company.booking_redirect_enabled ?? false} setValue={async () => setCompany({
          ...company,
          booking_redirect_enabled: !company.booking_redirect_enabled
        })} />}
      />
      <VerticalFormGroup
        formLabel={<FormLabel labelText='Booking redirect URL' />}
        input={<Input
          validator={(value: string) => value?.length > 0 ? { value, message: '' } : {
            value: undefined,
            message: 'Please enter a valid URL'
          }}
          disabled={!company.booking_redirect_enabled}
          placeholder={'Enter url here'}
          value={company.booking_redirect_url ?? ''}
          setValue={url => setCompany({
            ...company,
            booking_redirect_url: url
          })}
        />}
      />
      {company.booking_redirect_enabled
        ? <BookingConfirmationWithAction company={company} setCompany={setCompany} />
        : <BookingConfirmationWithoutAction company={company} setCompany={setCompany} />
      }
    </div>
  </Section>
}
