import React from 'react'
import { DropdownMenu } from '../../../components/buttons/dropdown_menu'
import { Edit, Share, Trash } from 'lucide-react'

export const DropdownMenuDemo = () => {
  const handleEdit = () => console.log('Edit clicked')
  const handleDelete = () => console.log('Delete clicked')
  const handleShare = () => console.log('Share clicked')

  const items = [
    { label: 'Edit', icon: Edit, onClick: handleEdit },
    { label: 'Delete', icon: Trash, onClick: handleDelete, confirmText: 'Are you sure you want to delete this item?' },
    { label: 'Share', icon: Share, onClick: handleShare }
  ]

  return (
    <div className="flex flex-col gap-8">
      <div className='flex flex-col gap-4'>
        <div className='text-2xl font-bold text-gray-900'>Definition</div>
        <pre className='bg-gray-100 rounded-lg p-4 overflow-x-auto'>
          <code>{codeBlock}</code>
        </pre>
        <div className='flex flex-col divide-y divide-dashed divide-gray-200 text-sm'>
          {componentProps.map(x => (
            <div key={x.name} className='grid grid-cols-2 py-2'>
              <div className='font-bold text-gray-900'>{x.name}</div>
              <div>{x.description}</div>
            </div>
          ))}
        </div>
      </div>
      <div className='flex flex-col gap-4'>
        <div className='text-2xl font-bold text-gray-900'>Component</div>
        <div>Default (with ellipsis icon)</div>
        <div className='flex justify-start items-center gap-4'>
          <DropdownMenu items={items} />
        </div>
        <div>Custom icon</div>
        <div className='flex justify-start items-center gap-4'>
          <DropdownMenu items={items} icon={Trash} />
        </div>
      </div>
    </div>
  )
}

const componentProps = [
  { name: 'icon', description: 'Custom icon for the dropdown button (optional, defaults to ellipsis)' },
  { name: 'items', description: 'Array of menu items' }
]

const codeBlock = `<DropdownMenu
  icon={faEllipsis}
  items={[
    { label: 'Edit', icon: Edit, onClick: handleEdit },
    { label: 'Delete', icon: Trash, onClick: handleDelete, confirmText: 'Are you sure you want to delete this item?' },
    { label: 'Share', icon: Share, onClick: handleShare },
  ]}
/>`
