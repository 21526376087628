import React, { type Dispatch, type SetStateAction } from 'react'
import { type Manifold } from '../../../code/models/manifold'
import { Input } from '../../../components/inputs_and_selections/input'
import { PhotoScroll } from '../../../components/inputs_and_selections/photo_scroll'
import { type FileWrapper } from '../file_wrapper'
import { validateNotNull, validateTemperatureRange } from '../../../code/validators'
import { TextArea } from '../../../components/inputs_and_selections/text_area'
import { ClickableCard } from '../../../components/content_display/card'
import { HorizontalFormGroup } from '../../../components/inputs_and_selections/horizontal_form_group'
import { FormLabel } from '../../../components/inputs_and_selections/form_label'
import { Toggle } from '../../../components/inputs_and_selections/toggle'
import { Info } from '../../../components/buttons/info'
import { VerticalFormGroup } from '../../../components/inputs_and_selections/vertical_form_group'

type ManifoldDetailPageProps = {
  manifold: Manifold
  setManifold: Dispatch<SetStateAction<Manifold>>
  files: FileWrapper[]
  setFiles: Dispatch<SetStateAction<FileWrapper[]>>
  companyUUID: string
}
export const ManifoldDetailPage = ({ manifold, setManifold, files, setFiles, companyUUID }: ManifoldDetailPageProps) => {
  const deleteImage = (imageUUID: string) => {
    setManifold(prev => ({ ...prev, photos: prev.photos.filter(x => x.image_uuid !== imageUUID) }))
  }
  const images = manifold.photos.map(x => files.find(y => y.uuid === x.image_uuid)!)

  return <div className='flex flex-col gap-5 p-5 overflow-y-auto'>
    <div className='flex flex-col gap-1'>
      <div className='font-bold text-gray-900'>Name</div>
      <Input
        value={manifold.name}
        validateImmediately={true}
        validator={validateNotNull}
        setValue={(e) => setManifold(prev => ({ ...prev, name: e }))} />
    </div>

    <ClickableCard variant='GREY'>
      <div className='flex flex-col gap-5'>
        <HorizontalFormGroup
          formLabel={
            <FormLabel
              labelText={'UFH temp is lower than the system temp'}
              helperText={'I.e. there is a mixing valve on the UFH'}
              info={<Info
                infoModalHeader={'UFH temp is lower than the system temp'}
                infoModalBody={'If a mixing valve is present at the UFH manifold to limit the temperature of the water going through the UFH system,' +
                    ' enter that temperature limit here. If you modify the system temperature to fall below this limit we will then assume the mixing valve' +
                    ' no longer functions and the UFH is at the same temp as the rest of the system. '}
              />}
            />
          }
          input={
            <Toggle
              value = {manifold.ufh_temp_differs_from_system_temp}
              setValue={() => setManifold(prev => ({ ...prev, ufh_temp_differs_from_system_temp: !prev.ufh_temp_differs_from_system_temp }))}
            />
          }
        />
        {manifold.ufh_temp_differs_from_system_temp &&
            <VerticalFormGroup
              formLabel={<FormLabel
                labelText={'Mean UFH temperature'}
                helperText={'The average temperature of the water in the UFH system'}/>}
              input={<Input
                type='number'
                postfix='°C'
                step={5}
                value={manifold.max_mean_water_temp_c ?? ''}
                setValue={(e) => setManifold(prev => ({ ...prev, max_mean_water_temp_c: parseFloat(e) }))}
                validator={(e) => validateTemperatureRange(e, 30, 55)}
                validateImmediately={true}
              />
              }
            />
        }
      </div>
    </ClickableCard>
    <div className='flex flex-col gap-1'>
      <div className='font-bold text-gray-900'>Number of loops</div>
      <Input
        type='number'
        value={manifold.number_of_loops ?? ''}
        setValue={(e) => setManifold(prev => ({ ...prev, number_of_loops: parseFloat(e) }))}
      />
    </div>
    <div className='flex flex-col gap-1'>
      <div className='font-bold text-gray-900'>Location description</div>
      <TextArea value={manifold.location_description}
        setValue={(e) => setManifold(prev => ({ ...prev, location_description: e }))}/>
    </div>
    <div className='flex flex-col gap-1'>
      <div className='font-bold text-gray-900'>Photos</div>
      <PhotoScroll images={images} addImage={(fileWrapper) => {
        fileWrapper && setFiles(prev => [...prev, { ...fileWrapper, created_at: new Date().getTime(), updated_at: new Date().getTime(), is_modified: true }])
        fileWrapper && setManifold(prev => ({
          ...prev,
          photos: [...prev.photos, {
            uuid: crypto.randomUUID(),
            entity_uuid: prev.uuid!,
            image_uuid: fileWrapper.uuid!
          }]
        }))
      }} deleteImage={deleteImage} companyUUID={companyUUID} />
    </div>
  </div>
}
