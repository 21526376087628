import React, { useContext, useEffect, useState } from 'react'
import { SurveyActionType } from '../../../code/survey/survey'
import { Input } from '../../../components/inputs_and_selections/input'
import { Button } from '../../../components/buttons/button'
import { ArrowLeftIcon, ChevronRightIcon } from 'lucide-react'
import { logEvent } from '../../../code/log_event'
import { type CompanyPublicInfo } from '../../../code/models/company'
import { getAddressIncludingPostcode } from '../../../code/models/lead'
import { SurveyOptionGroup } from '../../../components/inputs_and_selections/survey_option_group'
import { StepHeader } from '../components/steps_header'
import { SurveyContext, SurveyDispatchContext } from '../survey_page'

export const BathroomsStep = ({ companyPublicInfo }: { companyPublicInfo: CompanyPublicInfo }) => {
  // survey context and dispatch
  const survey = useContext(SurveyContext)
  const dispatch = useContext(SurveyDispatchContext)

  // used to represent the "5 or more bathrooms" option
  const uncertainKey = '-1'

  const [selectedOption, setSelectedOption] = useState<string>(survey.lead.property.noBathrooms.toString())
  const [textboxValue, setTextboxValue] = useState<string>(survey.lead.property.noBathrooms.toString())

  const noBathrooms = selectedOption === uncertainKey ? Number(textboxValue) : Number(selectedOption)

  // If the parsed noBedrooms exceeds 6 we need to show the input box immediately with that number.
  useEffect(() => {
    if (survey.lead.property.noBathrooms >= 5) setSelectedOption(uncertainKey)
  }, [survey])

  const handleNavigatePreviousPage = () => {
    dispatch({
      type: SurveyActionType.NavigatePreviousPage
    })
  }

  const handleNavigateNextPage = () => {
    logEvent({ name: 'Count bathroom step completed', properties: {} }, companyPublicInfo.subdomain)

    dispatch({
      type: SurveyActionType.NavigateNextPage,
      payload: {
        noBathrooms
      }
    })
  }

  return <>
    {/* Back button */}
    <Button colour='LIGHT' onClick={handleNavigatePreviousPage} block={false} className="self-start">
      <div className="flex items-center gap-x-2">
        <ArrowLeftIcon className="h-4 w-4" />
        <div>Back</div>
      </div>
    </Button>

    {/* Header */}
    <StepHeader
      guideText={getAddressIncludingPostcode(survey.lead)}
      headerText="How many bathrooms does your property have?"
      captionText="Count all rooms with a shower or bathtub."
    />

    {/* Options */}
    <SurveyOptionGroup
      options={[
        { label: '1 bathroom', key: '1' },
        { label: '2 bathrooms', key: '2' },
        { label: '3 bathrooms', key: '3' },
        { label: '4 bathrooms', key: '4' },
        { label: '5 or more bathrooms', key: uncertainKey }
      ]}
      onSelect={(key: string) => {
        // for the -1 option, we need to set the number of bathrooms to the textbox value
        if (key === uncertainKey) setTextboxValue('5')
        setSelectedOption(key)
      }}
      selectedOptionKey={selectedOption}
    />

    {selectedOption === uncertainKey && <Input
      size='LG'
      setValue={setTextboxValue}
      type="number"
      value={textboxValue}
      placeholder={'Enter number of bathrooms'}
    />}

    <Button size='LG' disabled={!noBathrooms || noBathrooms <= 0} onClick={handleNavigateNextPage}>
      <div className="flex items-center gap-x-2">
        <div>Continue</div>
        <ChevronRightIcon className="h-6 w-6" />
      </div>
    </Button>
  </>
}
