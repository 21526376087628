import React from 'react'
import { type Size } from '../../../code/extra_types'

type SubHeaderProps = {
  content: string
  className?: string
  size?: Size
}
export const SubHeader: React.FC<SubHeaderProps> = ({ content, className = '', size = 'lg' }) => {
  let sigingClasses = ''

  switch (size) {
    case 'sm':
      sigingClasses = 'text-sm '
      break
    case 'md':
      sigingClasses = ' '
      break
    case 'lg':
      sigingClasses = 'text-xl '
      break
  }

  return <div className={`${className} ${sigingClasses} text-gray-900 font-bold `}>{content}</div>
}
