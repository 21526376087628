import React from 'react'

export const ceilingShapeDImg = (className: string) => <svg className={className} viewBox="0 0 186 176" fill="none" xmlns="http://www.w3.org/2000/svg">
  <rect transform="translate(0 4)" fill="white"/>
  <line y1="-0.5" x2="63" y2="-0.5" transform="matrix(1 0 0 -1 113 151)" stroke="#BE185D" strokeDasharray="2 1"/>
  <line y1="-0.5" x2="5" y2="-0.5" transform="matrix(1 -8.74228e-08 -8.74228e-08 -1 176 151)" stroke="#BE185D"/>
  <path d="M176 151L178.887 146L173.113 146L176 151ZM176.5 146.5L176.5 96L175.5 96L175.5 146.5L176.5 146.5Z" fill="#BE185D"/>
  <path d="M169.128 93V84.36H170.196V88.17H174.744V84.36H175.812V93H174.744V89.184H170.196V93H169.128ZM177.375 92.994L177.381 92.034L181.215 88.584C181.535 88.296 181.749 88.024 181.857 87.768C181.969 87.508 182.025 87.242 182.025 86.97C182.025 86.638 181.949 86.338 181.797 86.07C181.645 85.802 181.437 85.59 181.173 85.434C180.913 85.278 180.617 85.2 180.285 85.2C179.941 85.2 179.635 85.282 179.367 85.446C179.099 85.606 178.887 85.82 178.731 86.088C178.579 86.356 178.505 86.648 178.509 86.964H177.417C177.417 86.42 177.543 85.94 177.795 85.524C178.047 85.104 178.389 84.776 178.821 84.54C179.257 84.3 179.751 84.18 180.303 84.18C180.839 84.18 181.319 84.304 181.743 84.552C182.167 84.796 182.501 85.13 182.745 85.554C182.993 85.974 183.117 86.45 183.117 86.982C183.117 87.358 183.069 87.684 182.973 87.96C182.881 88.236 182.737 88.492 182.541 88.728C182.345 88.96 182.099 89.208 181.803 89.472L178.617 92.334L178.479 91.974H183.117V92.994H177.375Z" fill="#9D174D"/>
  <path d="M176 25L173.113 30L178.887 30L176 25ZM176.5 80L176.5 29.5L175.5 29.5L175.5 80L176.5 80Z" fill="#BE185D"/>
  <line x1="113" y1="24.5" x2="176" y2="24.5" stroke="#BE185D" strokeDasharray="2 1"/>
  <line x1="176" y1="24.5" x2="181" y2="24.5" stroke="#BE185D"/>
  <line x1="92.5" y1="24" x2="92.5" y2="10" stroke="#0891B2" strokeDasharray="2 1"/>
  <line x1="92.5" y1="10" x2="92.5" y2="5" stroke="#0891B2"/>
  <path d="M93 8L96.75 5.83494V10.1651L93 8ZM96.375 7.625L113.5 7.625V8.375L96.375 8.375V7.625Z" fill="#0891B2"/>
  <path d="M118.489 13L115.987 4.36H117.103L119.023 11.194L120.937 4.366L122.059 4.36L123.979 11.194L125.893 4.36H127.015L124.513 13H123.445L121.501 6.226L119.557 13H118.489ZM129.664 13V5.542L128.038 6.526V5.332L129.664 4.36H130.75V13H129.664Z" fill="#0E7490"/>
  <path d="M155 8L151.25 10.1651V5.83494L155 8ZM134.5 7.625H151.625V8.375H134.5V7.625Z" fill="#0891B2"/>
  <line y1="-0.5" x2="14" y2="-0.5" transform="matrix(4.37114e-08 -1 -1 -4.37114e-08 155 24)" stroke="#0891B2" strokeDasharray="2 1"/>
  <line y1="-0.5" x2="5" y2="-0.5" transform="matrix(-4.37114e-08 -1 -1 4.37114e-08 155 10)" stroke="#0891B2"/>
  <line x1="28" y1="151.5" x2="10" y2="151.5" stroke="#4F46E5" strokeDasharray="2 1"/>
  <line x1="10" y1="151.5" x2="5" y2="151.5" stroke="#4F46E5"/>
  <path d="M9 151L6.11325 146L11.8868 146L9 151ZM8.5 146.5L8.5 128L9.5 128L9.5 146.5L8.5 146.5Z" fill="#4F46E5"/>
  <path d="M3.15891 125V116.36H4.22691V120.17H8.77491V116.36H9.84291V125H8.77491V121.184H4.22691V125H3.15891ZM13.1521 125V117.542L11.5261 118.526V117.332L13.1521 116.36H14.2381V125H13.1521Z" fill="#4338CA"/>
  <path d="M9 89L11.8868 94L6.11325 94L9 89ZM8.5 112L8.5 93.5L9.5 93.5L9.5 112L8.5 112Z" fill="#4F46E5"/>
  <line y1="-0.5" x2="18" y2="-0.5" transform="matrix(-1 0 0 1 28 89)" stroke="#4F46E5" strokeDasharray="2 1"/>
  <line y1="-0.5" x2="5" y2="-0.5" transform="matrix(-1 8.74228e-08 8.74228e-08 1 10 89)" stroke="#4F46E5"/>
  <path d="M29 151V88.4142L92.4142 25H155V88V151H29Z" fill="#F3F4F6" stroke="#6B7280" strokeWidth="2"/>
  <line y1="-0.5" x2="14" y2="-0.5" transform="matrix(-4.37114e-08 1 1 4.37114e-08 29 152)" stroke="#059669" strokeDasharray="2 1"/>
  <line y1="-0.5" x2="5" y2="-0.5" transform="matrix(4.37114e-08 1 1 -4.37114e-08 29 166)" stroke="#059669"/>
  <path d="M29 168L34 170.887V165.113L29 168ZM33.5 168.5H80.5V167.5H33.5V168.5Z" fill="#059669"/>
  <path d="M85.4579 173L82.9559 164.36H84.0719L85.9919 171.194L87.9059 164.366L89.0279 164.36L90.9479 171.194L92.8619 164.36H93.9839L91.4819 173H90.4139L88.4699 166.226L86.5259 173H85.4579ZM94.8866 172.994L94.8926 172.034L98.7266 168.584C99.0466 168.296 99.2606 168.024 99.3686 167.768C99.4806 167.508 99.5366 167.242 99.5366 166.97C99.5366 166.638 99.4606 166.338 99.3086 166.07C99.1566 165.802 98.9486 165.59 98.6846 165.434C98.4246 165.278 98.1286 165.2 97.7966 165.2C97.4526 165.2 97.1466 165.282 96.8786 165.446C96.6106 165.606 96.3986 165.82 96.2426 166.088C96.0906 166.356 96.0166 166.648 96.0206 166.964H94.9286C94.9286 166.42 95.0546 165.94 95.3066 165.524C95.5586 165.104 95.9006 164.776 96.3326 164.54C96.7686 164.3 97.2626 164.18 97.8146 164.18C98.3506 164.18 98.8306 164.304 99.2546 164.552C99.6786 164.796 100.013 165.13 100.257 165.554C100.505 165.974 100.629 166.45 100.629 166.982C100.629 167.358 100.581 167.684 100.485 167.96C100.393 168.236 100.249 168.492 100.053 168.728C99.8566 168.96 99.6106 169.208 99.3146 169.472L96.1286 172.334L95.9906 171.974H100.629V172.994H94.8866Z" fill="#047857"/>
  <path d="M155 168L150 165.113V170.887L155 168ZM103.5 168.5H150.5V167.5H103.5V168.5Z" fill="#059669"/>
  <line x1="155.5" y1="152" x2="155.5" y2="166" stroke="#059669" strokeDasharray="2 1"/>
  <line x1="155.5" y1="166" x2="155.5" y2="171" stroke="#059669"/>
</svg>
