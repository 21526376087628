import React, { useState } from 'react'
import { Modal } from '../containers/modal'
import { type LucideIcon } from 'lucide-react'

type IconProps = {
  icon: LucideIcon
  onClick?: () => void
  confirmTextHeader?: string
  confirmTextBody?: string | React.ReactNode
  colour?: string
  className?: string
  disabled?: boolean
  spin?: boolean
  size?: string
}
export const Icon = ({ icon, onClick, confirmTextHeader, confirmTextBody = 'This action cannot be undone.', colour = 'text-default', className, disabled, spin, size }: IconProps) => {
  const [modalOpen, setModalOpen] = useState(false)
  const onClickToUse = disabled ? undefined : onClick
  const colourToUse = disabled ? 'text-gray-300' : colour
  const Icon = icon
  const sizeToUse = size ?? 'w-5 h-5'

  return <>
    <Icon
      strokeWidth={1.5}
      className={`${sizeToUse} ${className} ${colourToUse} ${onClickToUse ? 'cursor-pointer' : ''} ${spin ? 'animate-spin' : ''}`}
      onClick={confirmTextHeader ? () => setModalOpen(true) : onClickToUse} />
    {/* TODO later: if there isn't enough space for the container for this modal it gets squashed so you don't see the "This action cannot be undone text" */}
    {confirmTextHeader && modalOpen &&
        <Modal
          onConfirm={onClickToUse}
          visible={true}
          title={confirmTextHeader}
          setVisible={() => setModalOpen(!modalOpen)}>{confirmTextBody}</Modal>}
  </>
}
