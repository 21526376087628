import { z } from 'zod'
import { RangeHeatPumpSchema } from './range_heat_pump'

const InventoryItemBaseSchema = z.object({
  uuid: z.string().uuid(),
  name: z.string(),
  description: z.string().nullable().optional(),
  cost_price: z.coerce.number(),
  markup: z.coerce.number(),
  deleted_at: z.coerce.date().nullable().optional()
})

// 'Primary' inventory items (e.g. heat pumps, hot water cylinders, emitters)
// are the core of inventory lists. They can have a default pack associated with them.
const PrimaryInventoryItemSchema = InventoryItemBaseSchema.extend({
  // What pack is included when this item is added to an inventory list
  default_pack_uuid: z.string().uuid().nullable().optional()
})

// 'Parts' are additional items that can be added to an inventory list, either
// individually or as part of a pack.
export const InventoryPartSchema = InventoryItemBaseSchema.extend({
  default_include: z.boolean()
})

// Used when we need to include a quantity, for instance when the part
// is part of a pack.
export const InventoryPartWithQuantitySchema = InventoryPartSchema.extend({
  quantity: z.coerce.number()
})

export const InventoryHeatPumpSchema = PrimaryInventoryItemSchema.extend({
  range_heat_pump_uuid: z.string().uuid().optional(),
  range_heat_pump: RangeHeatPumpSchema.optional()
})

export const InventoryHotWaterCylinderSchema = PrimaryInventoryItemSchema.extend({
  // Necessary until we set up range hot water cylinders
  litres: z.coerce.number()
})

export type InventoryItemBase = z.infer<typeof InventoryItemBaseSchema>

export type PrimaryInventoryItem = z.infer<typeof PrimaryInventoryItemSchema>

export type InventoryPart = z.infer<typeof InventoryPartSchema>

export type InventoryPartWithQuantity = z.infer<typeof InventoryPartWithQuantitySchema>

export type InventoryHeatPump = z.infer<typeof InventoryHeatPumpSchema>

export type InventoryHotWaterCylinder = z.infer<typeof InventoryHotWaterCylinderSchema>
