import { z } from 'zod'
import { apiUrl, client } from '../axios'
import { AuthSDK } from '../utils/auth_provider'
import { parseWithZod } from '../zod'

export const LabourSchema = z.object({
  uuid: z.string().uuid(),
  name: z.string(),
  description: z.string().nullable().optional(),
  cost_price: z.coerce.number(),
  days: z.coerce.number(),
  default_include: z.boolean(),
  deleted_at: z.coerce.date().nullable().optional()
})

export type Labour = z.infer<typeof LabourSchema>

export const getLabour = async (companyUUID: string): Promise<Labour[] | undefined> => {
  try {
    const result = await client.get<Array<Record<any, any>>>(`${apiUrl}teams/${companyUUID}/labour`,
      { headers: { 'x-auth-token': AuthSDK.getToken() } }
    )
    const parsedResult = result.data.map(x => parseWithZod<Labour>(LabourSchema, x))
    return parsedResult
  } catch (e: unknown) {
    console.error('Error getting labour', e)
    return undefined
  }
}

export const insertLabour = async (labour: Labour, companyUUID: string): Promise<Labour> => {
  const response = await client.post(
    `${apiUrl}teams/${companyUUID}/labour`,
    labour,
    { headers: { 'x-auth-token': AuthSDK.getToken() } }
  )
  return response.data
}

export const updateLabour = async (labour: Labour, companyUUID: string): Promise<Labour> => {
  const response = await client.patch(
    `${apiUrl}teams/${companyUUID}/labour/${labour.uuid}`,
    labour,
    { headers: { 'x-auth-token': AuthSDK.getToken() } }
  )
  return response.data
}

export const deleteLabour = async (labour: Labour, companyUUID: string): Promise<void> => {
  await client.delete(
    `${apiUrl}teams/${companyUUID}/labour/${labour.uuid}`,
    { headers: { 'x-auth-token': AuthSDK.getToken() } }
  )
}
