import React from 'react'
import { client, url } from '../axios'
import { AppContext } from '../../app_context'
import * as jose from 'jose'

const LOCAL_STORAGE_TOKEN_KEY = 'token'

export type JWTTokenSprucePayload = {
  user_uuid: string
  company_subdomain: string
}

// This AuthSDK is required to be outside of ANY React components.
// It's used in routers and other places where we need to check if the user is signed in.
export const AuthSDK = {
  isSignedIn: (): boolean => {
    return !!localStorage.getItem(LOCAL_STORAGE_TOKEN_KEY)
    // FIXME: get user info from server to check if token is valid
  },

  getToken: (): string | null => {
    return localStorage.getItem(LOCAL_STORAGE_TOKEN_KEY)
  },

  getTokenPayload: (): JWTTokenSprucePayload | null => {
    return localStorage.getItem(LOCAL_STORAGE_TOKEN_KEY)
      ? jose.decodeJwt(localStorage.getItem(LOCAL_STORAGE_TOKEN_KEY)!)
      : null
  },

  signIn: async (email: string, password: string) => {
    const result = await client.post<string>(`${url}login`, { email, password })
    const token = result.data

    localStorage.setItem(LOCAL_STORAGE_TOKEN_KEY, token)
  },
  signOut: () => {
    localStorage.removeItem(LOCAL_STORAGE_TOKEN_KEY)
  }
}

type AuthContextType = {
  isSignedIn: () => boolean
  getToken: () => string
  signIn: (email: string, password: string, callback: VoidFunction) => Promise<void>
  signOut: (callback: VoidFunction) => void
}

export const AuthContext = React.createContext<AuthContextType>(null!)

export type AuthProviderProps = {
  children: React.ReactNode
  loginCallback?: (isLoggedIn: boolean) => void
}

// ReactJS wrapper around the AuthSDK
export const AuthProvider = ({ loginCallback, children }: AuthProviderProps) => {
  const appContext = React.useContext(AppContext)

  const isSignedIn = () => {
    return AuthSDK.isSignedIn()
  }

  const getToken = () => {
    return AuthSDK.getToken()!
  }

  const signIn = async (email: string, password: string, callback: VoidFunction) => {
    AuthSDK.signIn(email, password).then(() => {
      callback()
      if (loginCallback) loginCallback(true)
    }).catch((e) => {
      appContext.setError(e)
    })
  }

  const signOut = (callback: VoidFunction) => {
    AuthSDK.signOut()
    callback()
    if (loginCallback) loginCallback(false)
  }

  return <AuthContext.Provider value={{ isSignedIn, getToken, signIn, signOut }}>{children}</AuthContext.Provider>
}
