import React, { useState } from 'react'
import { Icon } from '../../../components/buttons/icon'
import { Modal } from '../../../components/containers/modal'
import { type LucideIcon } from 'lucide-react'

const variants = {
  BLUE: 'text-blue-800 bg-indigo-100',
  DEFAULT: 'text-gray-600 bg-gray-50'
}
const iconVariants = {
  BLUE: 'text-blue-800',
  DEFAULT: 'text-gray-600'
}

type CanvasNavProps = {
  title: string
  items: CanvasNavItem[]
  headerContent?: JSX.Element
}

export type CanvasNavItem = {
  name: string
  onClick: () => void
  confirmText?: string
  icon: LucideIcon
  variant: keyof typeof variants
  disabled?: boolean
}

const CanvasNavButton = ({ item }: { item: CanvasNavItem }) => {
  const [modalOpen, setModalOpen] = useState(false)

  return (
    <>
      <button
        onClick={item.confirmText ? () => setModalOpen(true) : item.onClick}
        className={`p-2 ${variants[item.variant]} rounded-md flex flex-col gap-1 whitespace-nowrap items-center justify-center ${item.disabled ? 'opacity-50' : 'cursor-pointer'}`}
      >
        <Icon icon={item.icon} colour={iconVariants[item.variant]} />
        <div className="text-xs font-semibold">{item.name}</div>
      </button>
      <Modal
        onConfirm={() => {
          item.onClick()
        }}
        visible={modalOpen}
        title={item.name}
        setVisible={setModalOpen}
      >
        {item.confirmText}
      </Modal>
    </>
  )
}

export const CanvasNav = ({ items, title, headerContent }: CanvasNavProps) => {
  return (
    <>
      <div className="p-1 bg-white rounded-lg shadow flex flex-col gap-1 items-center">
        <div className='text-gray-900 text-sm font-semibold px-4'>{title}</div>
        {headerContent}
        <div className='flex gap-1 overflow-x-auto w-full items-center justify-center'>
          {items.map(x => <CanvasNavButton key={x.name} item={x} />)}
        </div>
      </div>
    </>
  )
}
