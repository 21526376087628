import { type Room } from '../../../../code/models/room'

export const findBestCreateRoomPosition = (centerX: number, centerY: number, rooms: Room[]) => {
  const distanceLengthMM = Number(2.5) * 1000
  const distanceWidthMM = Number(2.5) * 1000

  const shapeCenterX = centerX - (distanceWidthMM / 2)
  const shapeCenterY = centerY - (distanceLengthMM / 2)

  const roomsOnSameLocation = rooms.filter(x => x.x === shapeCenterX && x.y === shapeCenterY)
  const filteredRooms = rooms.filter(x => !roomsOnSameLocation.map(y => y.uuid).includes(x.uuid))

  if (roomsOnSameLocation.length > 0) return findBestCreateRoomPosition(centerX + 10, centerY + 10, filteredRooms)
  return { roomCenterX: centerX, roomCenterY: centerY }
}
