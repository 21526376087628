import React, { useCallback, useContext, useState } from 'react'
import { AdminContext } from '../admin_layout'
import { OfflinePage } from '../offline_page'
import { type Company, type CompanyPublicInfo, updateCompanySettings } from '../../../code/models/company'
import { RequireRole } from '../../../require_role'
import { USER_ROLE_HAS_SURVEY_ACCESS, USER_ROLE_SIMPLE } from '../../../code/models/user'
import TabbedDashboardLayout from '../../../layouts/tabbed_dashboard_layout'
import { AccountProfile } from './account_settings/account_profile'
import { EmailTemplatesSendEstimate } from './email_templates_settings/email_templates_send_estimate'
import { EstimateEnquiryForm } from './estimate_settings/estimate_enquiry_form'
import { AccountIntegrations } from './account_settings/account_integrations'
import { AccountBranding } from './account_settings/account_branding'
import { EstimateResultsPage } from './estimate_settings/estimate_results_page'
import { EmailTemplatesNudgeFollowup } from './email_templates_settings/email_templates_nudge_follow-up'
import { EmailTemplatesBookSurvey } from './email_templates_settings/email_templates_book_survey'
import { EmailTemplatesDeclineEnquiry } from './email_templates_settings/email_templates_decline_enquiry'
import { useDebounceCallback } from 'usehooks-ts'
import { EstimatePreferences } from './estimate_settings/estimate_preferences'
import { AccountTools } from './account_settings/account_tools'
import { EmailTemplatesReport } from './email_templates_settings/email_templates_report'
import { EmailTemplatesNewQuote } from './email_templates_settings/email_templates_new_quote'
import { ProposalTemplates } from './proposal_settings/proposal_templates'
import { EmailTemplatesNewProposal } from './email_templates_settings/email_templates_new_proposal'

type SettingsPageProps = {
  tab: string
  secondaryTab: string
  companyPublicInfo: CompanyPublicInfo
  navigateTo: (url: string) => void
}
export const SettingsPage = ({ tab, secondaryTab, companyPublicInfo, navigateTo }: SettingsPageProps) => {
  const adminContext = useContext(AdminContext)
  const [companySaved, setCompanySaved] = useState(true)

  const debouncePatchSettings = useDebounceCallback(async (company: Company) => {
    try {
      await updateCompanySettings(company)
      setCompanySaved(true)
    } catch (e) {
      console.error(e)
    }
  }, 1000)

  const setCompany = useCallback((company: Company) => {
    setCompanySaved(false)
    adminContext.setCompany(company)
    debouncePatchSettings(company)
  }, [])

  if (!adminContext.data.company) return
  if (adminContext.isOffline) {
    return <OfflinePage navigateTo={navigateTo} />
  }

  return (
    <RequireRole roles={[USER_ROLE_SIMPLE, USER_ROLE_HAS_SURVEY_ACCESS]}>
      <TabbedDashboardLayout
        navigateTo={navigateTo}
        title='Settings'
        basePath={`/${companyPublicInfo.subdomain}/admin/settings`}
        selectedTabId={tab}
        selectedSecondaryTabId={secondaryTab}
        isOffline={adminContext.isOffline}
        allSynced={companySaved}
        tabs={[
          {
            id: 'account',
            label: 'Account',
            secondaryTabs: [
              {
                id: 'profile',
                label: 'Profile',
                content: <AccountProfile
                  company={adminContext.data.company}
                  setCompany={setCompany}
                />
              },
              {
                id: 'integrations',
                label: 'Integrations',
                content: <AccountIntegrations
                  company={adminContext.data.company}
                  setCompany={setCompany}
                />
              },
              {
                id: 'branding',
                label: 'Branding',
                content: <AccountBranding
                  company={adminContext.data.company}
                  setCompany={setCompany}
                />
              },
              {
                id: 'developer_tools',
                label: 'Developer tools',
                content: <AccountTools />
              }
            ]
          },
          {
            id: 'estimate',
            label: 'Estimate',
            secondaryTabs: [
              {
                id: 'enquiry-form',
                label: 'Enquiry form',
                content: <EstimateEnquiryForm
                  company={adminContext.data.company}
                  setCompany={setCompany}
                />
              },
              {
                id: 'estimate-preferences',
                label: 'Estimate preferences',
                content: <EstimatePreferences
                  company={adminContext.data.company}
                  setCompany={setCompany}
                />
              },
              {
                id: 'results-page',
                label: 'Results page',
                content: <EstimateResultsPage
                  company={adminContext.data.company}
                  setCompany={setCompany}
                />
              }
            ]
          },
          {
            id: 'proposal',
            label: 'Proposal',
            secondaryTabs: [
              {
                id: 'templates',
                label: 'Templates',
                content: <ProposalTemplates
                  company={adminContext.data.company}
                  setCompany={setCompany}
                />
              }
            ]
          },
          {
            id: 'email-templates',
            label: 'Email templates',
            secondaryTabs: [
              // {
              //   id: 'enquiry-received',
              //   label: 'Enquiry received',
              //   content: <EmailTemplatesEnquiryReceived
              //     company={adminContext.data.company}
              //     setCompany={setCompany}
              //   />
              // },
              {
                id: 'send-estimate',
                label: 'Send estimate',
                content: <EmailTemplatesSendEstimate
                  company={adminContext.data.company}
                  setCompany={setCompany}
                />
              },
              {
                id: 'nudge-follow-up',
                label: 'Nudge follow-up',
                content: <EmailTemplatesNudgeFollowup
                  company={adminContext.data.company}
                  setCompany={setCompany}
                />
              },
              {
                id: 'book-survey',
                label: 'Book survey',
                content: <EmailTemplatesBookSurvey
                  company={adminContext.data.company}
                  setCompany={setCompany}
                />
              },
              {
                id: 'decline-enquiry',
                label: 'Decline enquiry',
                content: <EmailTemplatesDeclineEnquiry
                  company={adminContext.data.company}
                  setCompany={setCompany}
                />
              },
              {
                id: 'report',
                label: 'Send report',
                content: <EmailTemplatesReport
                  company={adminContext.data.company}
                  setCompany={setCompany}
                />
              },
              {
                id: 'new-quote',
                label: 'Send quote',
                content: <EmailTemplatesNewQuote
                  company={adminContext.data.company}
                  setCompany={setCompany}
                />
              },
              {
                id: 'new-proposal',
                label: 'Send proposal',
                content: <EmailTemplatesNewProposal
                  company={adminContext.data.company}
                  setCompany={setCompany}
                />
              }
            ]
          }
        ]}
      />
    </RequireRole>
  )
}
