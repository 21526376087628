import React, { useEffect, useRef, useState } from 'react'
import { Accordion } from '../components/content_display/accordion'
import { Button } from '../components/buttons/button'
import {
  type CompanyPublicInfo,
  getCompanyBookingConfirmationActionEmailTemplate,
  getCompanyBookingConfirmationNoActionEmailTemplate,
  getCompanyEstimateCoverNote
} from '../code/models/company'
import {
  type CalculatedEstimate,
  getCalculatedEstimate,
  sendCallback,
  sendRejected,
  sendSurvey,
  updateCalculatedEstimate
} from '../code/models/calculated_estimate'
import { checkIfEligibleForBusGrant, getCommutesSaved, getFlightsSaved } from '../code/calculate_estimate'
import { numberFormat } from '../code/number_format'
import { LogoHeader } from './survey/components/logo_header'
import { faqRows } from './survey/steps/get_contacts_step'
import { checkIfScottish } from '../code/models/address'
import { Link } from '../components/buttons/link'
import { logEvent } from '../code/log_event'
import { formatPrice } from '../code/format_price'
import {
  renderHTMLReplacingPlaceholders,
  TTPlaceholderCompanyName,
  TTPlaceholderCompanyPhone,
  TTPlaceholderCustomerName,
  TTPlaceholderEstimateContactName,
  TTPlaceholderEstimateContactPhone,
  TTPlaceholderInstallerName
} from '../code/tiptap_placeholders'
import { openInNewTab } from '../code/helpers'
import { Text } from '../components/content_display/text'
import { Heading } from '../components/content_display/heading'
import { Icon } from '../components/buttons/icon'
import { Modal } from '../components/containers/modal'
import { RadioGroup } from '../components/inputs_and_selections/radio'
import { Info } from '../components/buttons/info'
import { Emoji } from '../components/content_display/emoji'
import { TextArea } from '../components/inputs_and_selections/text_area'
import { SummaryTable } from '../components/content_display/summary_table'
import { Alert } from '../components/indicators_and_messaging/alert'
import { Card } from '../components/content_display/card'
import { type Step, NEXT_STEPS_ENGLAND_WALES, NEXT_STEPS_SCOTLAND } from './admin/settings_page/estimate_settings/blocks/estimate_next_steps_block'
import {
  DEFAULT_BRAND_COLOUR_PRIMARY,
  DEFAULT_BRAND_COLOUR_SECONDARY,
  determineBestTextShade
} from '../code/utils/colour_utils'
import { CalculatedQuoteDefaultGroups, quoteItemNameWithQuantity } from '../code/calculate_quote'
import { groupBy, sum, lowerCase } from 'lodash'
import { ArrowDown, CheckCircle } from 'lucide-react'

type EstimatePageProps = {
  estimateId: string
  companyPublicInfo: CompanyPublicInfo
}
export const EstimatePage = ({ estimateId, companyPublicInfo }: EstimatePageProps) => {
  const isInstallerClick = new URLSearchParams(window.location.search).get('installer')
  const [estimate, setEstimate] = useState<CalculatedEstimate>()
  const [isScottish, setIsScottish] = useState<boolean>(false)
  const [grantEligible, setGrantEligible] = useState<boolean>(false)

  useEffect(() => {
    const asyncCall = async () => {
      const estimate = await getCalculatedEstimate(estimateId, companyPublicInfo.uuid)
      if ((estimate?.status === 'Sent' || !estimate?.status) && !isInstallerClick) await updateCalculatedEstimate({ ...estimate, status: 'Opened' }, companyPublicInfo.subdomain)
      setEstimate(estimate)
      setIsScottish(checkIfScottish(estimate!.postcode))
      if (isScottish || !estimate!.lmk_key) return
      const epc = estimate?.epc
      const inspectionDate = epc?.inspectionDate ? new Date(epc?.inspectionDate) : undefined
      // the epc_recommendations might be not stored in the Lead
      // if installer have sent the estimate before our release to store the epc_recommendations in the Lead
      // in that case we just don't show the green box on the page
      if (estimate!.lead?.epc_recommendations) {
        const grantEligibleReasons = checkIfEligibleForBusGrant(inspectionDate, estimate!.lead?.epc_recommendations)
        setGrantEligible(isScottish || grantEligibleReasons.every(x => x.hasPassed))
      }
    }
    if (estimateId) asyncCall()
  }, [estimateId])

  if (!estimate) return

  return <EstimatePageInner
    estimate={estimate}
    isScottish={isScottish}
    grantEligible={grantEligible}
    companyPublicInfo={companyPublicInfo}
  />
}

type EstimatePageInnerProps = {
  estimate?: CalculatedEstimate
  companyPublicInfo: CompanyPublicInfo
  isScottish: boolean
  grantEligible: boolean
  isPreview?: boolean
}

const ESTIMATE_CARD_COLORS = {
  WHITE: 'bg-white',
  GREY: 'bg-slate-50',
  YELLOW: 'bg-yellow-100',
  GREEN: 'bg-green-100',
  BRIGHT_GREEN: 'bg-green-600'
}

const ESTIMATE_CARD_PADDINGS = {
  XS: 'p-2',
  SM: 'p-3',
  MD: 'p-4',
  LG: 'p-6'
}

// Component used for the cards on this page
export const EstimateCard = ({ color, size = 'MD', className, children }: { color: keyof typeof ESTIMATE_CARD_COLORS, size?: keyof typeof ESTIMATE_CARD_PADDINGS, className?: string, children: React.ReactNode }) => {
  return <div className={`border border-gray-200 rounded-lg flex flex-col ${ESTIMATE_CARD_COLORS[color]} ${ESTIMATE_CARD_PADDINGS[size]} ${className}`}>
    {children}
  </div>
}

// A version of the estimate card that is styled to look more like a tag
const TagCard = ({ color, children, className }: { color: keyof typeof ESTIMATE_CARD_COLORS, children: React.ReactNode, className?: string }) => {
  return <div className={`rounded flex justify-between ${ESTIMATE_CARD_COLORS[color]} p-2 ${className}`}>
    {children}
  </div>
}

// Shared between the modals on this page
const ModalConfirmationMessage = ({ heading, text }: { heading: string, text: string }) => {
  return (
    <div className="bg-green-50 rounded-lg flex-col justify-center items-center text-center gap-2 flex px-6 py-20">
      <Icon icon={CheckCircle} colour="text-green-600 text-3xl" />
      <Heading size="lg">{heading}</Heading>
      <Text>{text}</Text>
    </div>
  )
}

const NotInterestedModal = ({ visible, setVisible, onConfirm }: { visible: boolean, setVisible: (visible: boolean) => void, onConfirm: (reason: string) => void }) => {
  const [reason, setReason] = useState<string>()
  const [otherReason, setOtherReason] = useState<string>()
  const [otherReasonFieldVisible, setOtherReasonFieldVisible] = useState<boolean>(false)
  const [showConfirmation, setShowConfirmation] = useState<boolean>(false)

  const variant = (name: string): 'ACTIVE' | 'DEFAULT' => reason === name ? 'ACTIVE' : 'DEFAULT'

  const reasons = [
    'Estimates are too high',
    'Estimated savings not enough',
    'Already gone with another installer',
    'Boiler fixed/replaced',
    'Don’t know enough to take the next step'
  ]

  const handleConfirm = () => {
    if (!reason && !otherReason) return
    onConfirm(otherReason ?? reason ?? '')
    setShowConfirmation(true)
  }

  return (
    <Modal visible={visible} setVisible={setVisible} title="I’m not interested right now" onConfirm={showConfirmation ? undefined : handleConfirm} hideOnConfirm={false} confirmDisabled={!reason && !otherReason}>
      {showConfirmation ? <ModalConfirmationMessage heading="Response received" text="Thanks! We appreciate your feedback." />
        : (
            <div className="flex flex-col gap-5">
              <Text>If you’re now not interested then we would love to know the reason why.</Text>
              <div className='flex flex-col gap-1'>
                <Heading size="lg">Select your reason</Heading>
                <RadioGroup items={[
                  ...reasons.map((name) => ({ name, onClick: () => { setReason(name); setOtherReasonFieldVisible(false) }, variant: variant(name) })),
                  { name: 'Other', onClick: () => { setOtherReasonFieldVisible(true); setReason('Other') }, variant: variant('Other') }
                ]} isVertical={true} />
              </div>
              {otherReasonFieldVisible && <div className='flex flex-col gap-1'>
                <Heading size="lg">Tell us why</Heading>
                <Text>Briefly explain why you are no longer interested.</Text>
                <TextArea value={otherReason!} setValue={setOtherReason} />
              </div>}
            </div>
          )}
    </Modal>
  )
}

const RequestCallbackModal = ({ visible, setVisible, onConfirm }: { visible: boolean, setVisible: (visible: boolean) => void, onConfirm: (discussionContent?: string, callTime?: string) => void }) => {
  const [discussionContent, setDiscussionContent] = useState<string>()
  const [callTime, setCallTime] = useState<string>()
  const [showConfirmation, setShowConfirmation] = useState<boolean>(false)

  const handleConfirm = () => {
    if (!discussionContent && !callTime) return
    onConfirm(discussionContent, callTime)
    setShowConfirmation(true)
  }

  return (
    <Modal visible={visible} setVisible={setVisible} title="Request a callback" onConfirm={showConfirmation ? undefined : handleConfirm} hideOnConfirm={false} confirmDisabled={!discussionContent && !callTime}>
      {showConfirmation ? <ModalConfirmationMessage heading="Callback request sent" text="Thanks! We will review your request and be in touch via email to schedule a call to discuss your query in more detail." />
        : (
            <div className="flex flex-col gap-5">
              <div className="flex flex-col gap-1">
                <Text>We’d love to walk you through our recommendations in more details and respond to any questions or concerns you may have.</Text>
                <Text>Please tell us what you’d like to discuss and when would be a good time to call below.</Text>
              </div>
              <div className='flex flex-col gap-1'>
                <Heading size="lg">What would you like to discuss?</Heading>
                <Text>Briefly summarise the questions you have, or the topics you’d like to cover.</Text>
                <textarea className='border border-medium p-3 rounded-lg resize-none focus:outline-2 focus:outline-accent' value={discussionContent} onChange={(e) => setDiscussionContent(e.target.value)} />
              </div>
              <div className='flex flex-col gap-1'>
                <Heading size="lg">When would be a good time to call?</Heading>
                <Text>Tell us when you will be available.</Text>
                <textarea className='border border-medium p-3 rounded-lg resize-none focus:outline-2 focus:outline-accent' value={callTime} onChange={(e) => setCallTime(e.target.value)} />
              </div>
            </div>
          )}
    </Modal>
  )
}

// Request a survey modal is currently just the confirmation, no functionality
const RequestSurveyModal = ({ visible, setVisible }: { visible: boolean, setVisible: (visible: boolean) => void }) => {
  return (
    <Modal visible={visible} setVisible={setVisible} title="Book your survey" confirmButtonLabel="Request survey">
      <ModalConfirmationMessage heading="Survey request sent" text="Thanks! We will review your request and be in touch via email to schedule your survey." />
    </Modal>
  )
}

const NextSteps = ({ isScottish, companyPublicInfo }: { isScottish: boolean, companyPublicInfo: CompanyPublicInfo }) => {
  let steps: Step[]
  if (isScottish) {
    steps = companyPublicInfo.next_steps_scottish ? JSON.parse(companyPublicInfo.next_steps_scottish) : NEXT_STEPS_SCOTLAND
  } else {
    steps = companyPublicInfo.next_steps ? JSON.parse(companyPublicInfo.next_steps) : NEXT_STEPS_ENGLAND_WALES
  }

  return (
    <div className={'grid lg:grid-cols-3 gap-5 w-full max-w-screen-2xl'}>
      {steps.map((step, index) => (
        <EstimateCard key={index} color="WHITE" className="gap-4 shadow">
          <div className="grid grid-cols-[32px_1fr] gap-2">
            <div>
              <Heading size="3xl">{`${index + 1}.`}</Heading>
            </div>
            <div>
              <Text bold={true}>{step.title}</Text>
              <Text size="SM">{step.description}</Text>
            </div>
          </div>
        </EstimateCard>
      ))}
    </div>
  )
}

export const ContactCardWrapper = ({ hasContent, children }: { hasContent: boolean, children: React.ReactNode }) => {
  if (hasContent) {
    return <Card color='LIGHT' className='bg-slate-50 flex flex-col gap-4'>
      {children}
    </Card>
  } else {
    return <div>{children}</div>
  }
}

const BILL_SAVINGS_EMOJI = <Emoji emoji='💰' />
const CARBON_SAVINGS_EMOJI = <Emoji emoji='✈️' />

type BillSavingsInfoContent = Pick<CalculatedEstimate,
'annual_bill_hp_tariff_gbp' |
'annual_bill_baseline_gbp' |
'annual_kwh_heat_required' |
'average_efficiency_baseline' |
'average_efficiency_hp' |
'fuel_name_baseline' |
'tariff_p_per_kwh_baseline' |
'tariff_p_per_kwh_electricity_hp_tariff' |
'electric_hp_tariff_based_on' |
'annual_bill_hp_price_cap_gbp' |
'tariff_p_per_kwh_electricity_price_cap' |
'price_cap_description' |
'heat_pump_name' |
'flow_temperature_c'
>

const INFO_POPUPS = {
  heatLoss: {
    title: 'Heat loss explained',
    content: 'This shows how fast your home loses heat in cold weather. Your heating system needs to keep up with this loss to keep your home warm and comfortable.'
  },
  carbonSavings: (estimate: CalculatedEstimate) => ({
    title: 'Estimated carbon savings',
    content: `With the projected decarbonization of the grid, you could lower your carbon footprint by up to ${numberFormat(0).format(estimate.co2_saved_kg)}kg of CO2 each year. That’s like avoiding ${getFlightsSaved(estimate.co2_saved_kg)} flights to Spain or ${getCommutesSaved(estimate.co2_saved_kg)} daily commutes to work.`
  }),
  billSavings: (billSavingsInfoContent: BillSavingsInfoContent, homeownerFacing = true) => ({
    title: 'Potential bill savings',
    content: <div className='space-y-3'>
      {homeownerFacing && <div>Estimating your running costs with a heat pump can be tricky since it depends on many factors, some beyond
        our control.</div>}

      <div>This estimate is based on typical usage and weather patterns, and assumes you'll switch to a specialised heat
        pump tariff, which offers lower electricity rates. Providers like OVO, EDF, and Octopus offer heat pump
        tariffs—check <Link
        onClick={() => openInNewTab('https://spruce-energy.notion.site/Heat-pump-tariffs-569d003c41014e8b9e519b1e11ce7b66')}
        className='inline'
        text='this page'
      /> for the latest options. </div>

      {billSavingsInfoContent.annual_bill_hp_tariff_gbp && billSavingsInfoContent.annual_bill_baseline_gbp && billSavingsInfoContent.annual_kwh_heat_required && billSavingsInfoContent.average_efficiency_baseline && billSavingsInfoContent.average_efficiency_hp && <div>

        The calculations behind this estimate are as follows:
        <ul className='pl-5 mt-3 space-y-3'>
          <li><span className={'font-semibold'}>Existing system</span>
            <ul className='list-disc list-inside pl-5'>
              <li>{numberFormat(0).format(billSavingsInfoContent.annual_kwh_heat_required)} kWh of heat needed for heating and hot
                water
              </li>
              <li>{billSavingsInfoContent.average_efficiency_baseline ? (billSavingsInfoContent.average_efficiency_baseline * 100).toFixed(0) : null}%
                assumed
                efficiency
              </li>
              <li> → {numberFormat(0).format(billSavingsInfoContent.annual_kwh_heat_required / billSavingsInfoContent.average_efficiency_baseline)} kWh
                of {lowerCase(billSavingsInfoContent.fuel_name_baseline)}</li>
              <li>{billSavingsInfoContent.tariff_p_per_kwh_baseline?.toFixed(2)} p/kWh
                for {lowerCase(billSavingsInfoContent.fuel_name_baseline)}</li>
              <li className={'font-semibold'}> → £{numberFormat(0).format(billSavingsInfoContent.annual_bill_baseline_gbp)} for heating and hot water
                ({billSavingsInfoContent.tariff_p_per_kwh_baseline && billSavingsInfoContent.average_efficiency_baseline ? numberFormat(2).format(billSavingsInfoContent.tariff_p_per_kwh_baseline / billSavingsInfoContent.average_efficiency_baseline) : null} p/kWh of heat)
              </li>
            </ul>
          </li>
          <li><span className={'font-semibold'}>Heat pump - with a heat pump tariff</span>
            <ul className='list-disc list-inside pl-5'>
              <li>{numberFormat(0).format(billSavingsInfoContent.annual_kwh_heat_required)} kWh of heat needed for heating and hot
                water
              </li>
              <li>{(billSavingsInfoContent.average_efficiency_hp * 100).toFixed(0)}% predicted average efficiency</li>
              <li> → {numberFormat(0).format(billSavingsInfoContent.annual_kwh_heat_required / billSavingsInfoContent.average_efficiency_hp)} kWh of
                electricity
              </li>
              <li>{billSavingsInfoContent.tariff_p_per_kwh_electricity_hp_tariff?.toFixed(2)} p/kWh for electricity
                ({billSavingsInfoContent.electric_hp_tariff_based_on})
              </li>
              <li className={'font-semibold'}> → £{numberFormat(0).format(billSavingsInfoContent.annual_bill_hp_tariff_gbp)} for heating and hot water
                ({billSavingsInfoContent.tariff_p_per_kwh_electricity_hp_tariff ? numberFormat(2).format(billSavingsInfoContent.tariff_p_per_kwh_electricity_hp_tariff / billSavingsInfoContent.average_efficiency_hp) : null} p/kWh of heat)
              </li>
            </ul>
          </li>
          {billSavingsInfoContent.annual_bill_hp_price_cap_gbp && billSavingsInfoContent.tariff_p_per_kwh_electricity_price_cap && <li><span className={'font-semibold'}>Heat pump - with the price cap</span>
            <ul className='list-disc list-inside pl-5'>
              <li>{numberFormat(0).format(billSavingsInfoContent.annual_kwh_heat_required)} kWh of heat needed for heating and hot
                water
              </li>
              <li>{(billSavingsInfoContent.average_efficiency_hp * 100).toFixed(0)}% predicted average efficiency</li>
              <li> → {numberFormat(0).format(billSavingsInfoContent.annual_kwh_heat_required / billSavingsInfoContent.average_efficiency_hp)} kWh of
                electricity
              </li>
              <li>{billSavingsInfoContent.tariff_p_per_kwh_electricity_price_cap?.toFixed(2)} p/kWh for electricity ({billSavingsInfoContent.price_cap_description})
              </li>
              <li className={'font-semibold'} > → £{numberFormat(0).format(billSavingsInfoContent.annual_bill_hp_price_cap_gbp)} for heating and hot water
                ({billSavingsInfoContent.tariff_p_per_kwh_electricity_hp_tariff ? numberFormat(2).format(billSavingsInfoContent.tariff_p_per_kwh_electricity_price_cap / billSavingsInfoContent.average_efficiency_hp) : null} p/kWh of heat)
              </li>
            </ul>
          </li>}
        </ul>
      </div>}
      <div> The heat pump efficiency values used are based on sCOP data for the proposed heat pump ({billSavingsInfoContent.heat_pump_name}) at the proposed flow temperature ({billSavingsInfoContent.flow_temperature_c}°C).
        They account for a lower heat pump efficiency when making hot water due to the higher flow temperatures involved.
      </div>
      <Alert type="WARNING">
        Note that these potential bill savings are not guaranteed – they're estimated based upon what we know about your property at this stage, with broad-brush assumptions about how you heat your home.
      </Alert>
    </div>
  }),
  flowTemperature: {
    title: 'Flow temperature explained',
    content:
      <div className='space-y-2'>
        <div>Flow temperature is the temperature of the water coming out of your heat pump and into your radiators.
        </div>
        <div>While heat pumps can produce very hot water, they operate most efficiently at lower temperatures, typically
          between 35-50°C. In contrast, gas boilers have traditionally been set to produce water at higher temperatures,
          around 70-80°C, although they also work more efficiently at lower temperatures.
        </div>
        <div>This is why it’s best to run a heat pump system consistently at a lower flow temperature (‘low and slow’)
          rather than in short, powerful bursts.
        </div>
      </div>
  },
  busGrant: {
    title: 'BUS grant eligibility',
    content: <div className='space-y-2'>
      <div>Based on the information you’ve provided we believe you will be eligible for the <a
        href="https://www.gov.uk/apply-boiler-upgrade-scheme/check-if-youre-eligible" target="_blank" className="text-sky-800 font-bold underline" rel="noreferrer">
        Boiler Upgrade Scheme (BUS)</a>.</div>
      <div>You and your property must meet the following criteria:</div>
      <div className='text-accent font-bold'>You must:</div>
      <div className='space-y-2 text-gray-600'>
        <div className='flex gap-2 items-top'>
          <Icon icon={CheckCircle} colour='text-green-600' className='h-5' />
          <div>Own the property you are applying for.</div>
        </div>
        <div className='flex gap-2 items-top'>
          <Icon icon={CheckCircle} colour='text-green-600' className='h-5' />
          <div>Be replacing fossil fuel heating systems.</div>
        </div>
      </div>

      <div className='text-accent font-bold'>Your property must:</div>
      <div className='space-y-2 text-gray-600'>
        <div className='flex gap-2 items-top'>
          <Icon icon={CheckCircle} colour='text-green-600' className='h-5' />
          <div>Have an Energy Performance Certificate (EPC) that is under 10 years old.</div>
        </div>
      </div>
    </div>
  },
  hesGrant: {
    title: 'HES grant eligibility',
    content: <div className='space-y-2'>
      <div>You may be eligible for a grant of up to £7,500-£9,000 from <a href="https://www.homeenergyscotland.org/home-energy-scotland-grant-loan" target="_blank" className="text-sky-800 font-bold underline" rel="noreferrer">Home Energy Scotland (HES)</a>, plus an interest free loan of up to £7,500.</div>
      <div>You must follow the HES process to secure the funding, and meet the following criteria:</div>
      <div className='text-accent font-bold'>You must:</div>
      <div className='space-y-2 text-gray-600'>
        <div className='flex gap-2 items-top'>
          <Icon icon={CheckCircle} colour='text-green-600' className='h-5' />
          <div>Own and live in the property you are applying for, and it must be your primary residence.</div>
        </div>
        <div className='flex gap-2 items-top'>
          <Icon icon={CheckCircle} colour='text-green-600' className='h-5' />
          <div>Not be replacing an existing heat pump.</div>
        </div>
      </div>
    </div>
  },
  estimatedCost: {
    title: 'Estimated cost',
    content: "This is an initial estimate based on the information we have so far. We'll be able to provide a final quote after completing a room-by-room heat loss survey of your property."
  },
  systemDesign: {
    title: 'System design explained',
    content: <div className='space-y-2'>
      <div>One of the most important factors in a successful heat pump installation is the design of the overall system.</div>
      <div className='text-accent font-bold'>Flow temperature</div>
      <div>This is the temperature of the water running through your radiators.  A lower flow temperature makes the heat pump run more efficiently, reducing your energy bills.</div>
      <div className='text-accent font-bold'>Radiators</div>
      <div>Lower flow temperatures increase efficiency but reduce the heat output from each radiator. To maintain comfort on cold days, some radiators may need to be upgraded to work effectively with lower flow temperatures.</div>
      <div className='text-accent font-bold'>Cylinder</div>
      <div>Also known as hot water tanks, cylinders are  required in heat pump systems to ensure continuous supply of hot water, similar to with traditional gas boilers.</div>
    </div>
  },
  soundPower: {
    title: 'Sound power level explained',
    content: <div className='space-y-2'>
      <div>Sound power level measures the total acoustic energy emitted by a noise source. The number shown here represents the maximum sound level the heat pump can produce. In normal operation, it will typically be quieter.</div>
      <div>Sound pressure level is what you actually hear and varies based on the location of the noise source and your distance from it. During the heat loss survey, we'll conduct a sound assessment to ensure the sound pressure level meets planning requirements.</div>
      <div className='text-accent font-bold'>How do these values compare to everyday sounds?</div>
      <ul className='list-disc list-inside'>
        <li>0 dB: Threshold of hearing</li>
        <li>40 dB: Very soft - Refrigerator humming</li>
        <li>60 dB: Moderately loud - Normal conversation, restaurant</li>
        <li>80 dB: Very loud - City traffic, lorry</li>
      </ul>
    </div>
  },
  efficiency: (estimate: CalculatedEstimate) => ({
    title: 'Efficiency explained',
    content: <div className='space-y-2'>
      <div>This figure shows how much heat the heat pump generates compared to the electricity it uses in space heating mode.</div>
      <div className='text-accent font-bold'>How can it be over 100% efficient?</div>
      <div>Heat pumps work by capturing heat from the outdoor air and using a small amount of electricity to increase that heat's temperature. Essentially, they’re harnessing free solar energy stored in the air!</div>
      <div>An efficiency of {Math.floor(estimate.scop! * 100)}% means your heat pump delivers {estimate.scop} units of heat for every 1 unit of electricity, because it captures {estimate.scop! - 1} units of heat from the air.</div>
      <div>For comparison, even the most modern gas boilers are around 90% efficient and can never reach the same efficiency levels as a heat pump.</div>
    </div>
  }),
  radiatorChanges: {
    title: 'Estimated radiator changes explained',
    content: <div className='space-y-2'>
      <div>Heat pumps operate at a lower flow temperature than boilers, so they need a larger radiator surface area to effectively heat your home. This means some radiators in your property may need to be upgraded to ensure the system runs efficiently.</div>
      <div className='text-accent font-bold'>How is this estimated?</div>
      <div>We’ve made a rough estimate based on your property size to give you an idea of potential costs. However, we’ll need to complete a full survey to determine the exact requirements.</div>
    </div>
  }
}

export const BillSavingsInfo = ({ billSavingsInfoContent, homeownerFacing = true }: { billSavingsInfoContent: BillSavingsInfoContent, homeownerFacing?: boolean }) => {
  return <Info infoModalHeader={INFO_POPUPS.billSavings(billSavingsInfoContent).title} infoModalBody={INFO_POPUPS.billSavings(billSavingsInfoContent, homeownerFacing).content} />
}

export const EstimatePageInner = ({ estimate, isScottish, grantEligible, companyPublicInfo, isPreview }: EstimatePageInnerProps) => {
  const costEstimateHeadingRef = useRef<HTMLDivElement>(null)
  const [showNotInterestedModal, setShowNotInterestedModal] = useState<boolean>(false)
  const [showRequestCallbackModal, setShowRequestCallbackModal] = useState<boolean>(false)
  const [showRequestSurveyModal, setShowRequestSurveyModal] = useState<boolean>(false)
  const [imageLoaded, setImageLoaded] = useState(true)

  if (!estimate) return

  // Only show heat loss button and text if english property and the installer allows survey booking
  const showRequestSurveyButton = !companyPublicInfo.no_survey_booking && !isScottish

  // Currently, do not show bill savings
  const billSavings = (estimate.annual_bill_baseline_gbp && estimate.annual_bill_hp_tariff_gbp) ? estimate.annual_bill_baseline_gbp - estimate.annual_bill_hp_tariff_gbp : undefined
  const showBillSavings = companyPublicInfo.show_bill_savings_on_estimate && billSavings && billSavings > 0 && estimate.range_heat_pump

  // on Request Callback button click
  const handleRequestCallback = async (discussionContent?: string, callTime?: string) => {
    logEvent({ name: 'Request a callback clicked', properties: { discussionContent, callTime } }, companyPublicInfo.subdomain)
    await sendCallback(estimate.uuid!, companyPublicInfo.uuid, discussionContent, callTime)
  }

  // on Not Interested button click
  const handleNotInterested = async (reason: string) => {
    logEvent({ name: 'Not interested clicked', properties: { reason } }, companyPublicInfo.subdomain)
    await sendRejected(estimate, companyPublicInfo.uuid, reason)
  }

  // on Request Survey button click
  const handleRequestSurvey = async () => {
    logEvent({ name: 'Request heat loss survey clicked', properties: {} }, companyPublicInfo.subdomain)

    let actionTemplateRendered: string
    if (companyPublicInfo.booking_redirect_enabled) {
      actionTemplateRendered = renderHTMLReplacingPlaceholders(getCompanyBookingConfirmationActionEmailTemplate(companyPublicInfo.booking_confirmation_action_template!), {
        [TTPlaceholderCustomerName.id]: estimate.name ?? '',
        [TTPlaceholderCompanyName.id]: companyPublicInfo.name,
        [TTPlaceholderCompanyPhone.id]: companyPublicInfo.phone
      })
      openInNewTab(companyPublicInfo.booking_redirect_url!)
    } else {
      actionTemplateRendered = renderHTMLReplacingPlaceholders(getCompanyBookingConfirmationNoActionEmailTemplate(companyPublicInfo.booking_confirmation_noaction_template!), {
        [TTPlaceholderCustomerName.id]: estimate.name ?? '',
        [TTPlaceholderCompanyName.id]: companyPublicInfo.name,
        [TTPlaceholderCompanyPhone.id]: companyPublicInfo.phone
      })
    }

    await sendSurvey(estimate, companyPublicInfo.uuid, actionTemplateRendered)

    if (!companyPublicInfo.booking_redirect_enabled) {
      setShowRequestSurveyModal(true)
    }
  }

  const heatPumpHasDimensions = estimate.range_heat_pump?.width_mm && estimate.range_heat_pump?.height_mm && estimate.range_heat_pump?.depth_mm

  const quoteItemsWithSubtotals = estimate.calculated_estimate_quote_items?.map(item => ({
    ...item,
    subtotal: item.selected ? item.price * item.quantity : 0
  }))

  const groupedQuoteItems = groupBy(quoteItemsWithSubtotals, 'group_name')

  const quoteGrantEnabled = groupedQuoteItems[CalculatedQuoteDefaultGroups.GRANTS]?.some(grant => grant.selected)
  const quoteGrant = sum(groupedQuoteItems[CalculatedQuoteDefaultGroups.GRANTS]?.map(x => x.selected ? x.price : 0))

  return <>
    <div className="flex flex-col">

      {/* Header */}
      <div className='bg-white border-solid border-b border-gray-300 px-6 py-3 justify-between items-center flex flex-col sm:flex-row gap-2 md:sticky md:top-0 z-10'>
        <LogoHeader companyPublicInfo={companyPublicInfo} />
        <div className='hidden sm:flex gap-3'>
          <Button
            style={{
              backgroundColor: companyPublicInfo.colour_primary,
              borderColor: companyPublicInfo.colour_primary,
              color: determineBestTextShade(companyPublicInfo.colour_primary ?? DEFAULT_BRAND_COLOUR_PRIMARY) === 'LIGHT' ? '#000000' : '#ffffff'
            }}
            onClick={() => costEstimateHeadingRef.current?.scrollIntoView({ behavior: 'smooth' })}>
            View cost estimate
          </Button>
          {(!companyPublicInfo.no_request_callback || isScottish) && <Button colour='LIGHT' onClick={() => setShowRequestCallbackModal(true)} disabled={isPreview}>Request a callback</Button>}
        </div>
      </div>

      {/* Overview */}
      <div
        style={{ backgroundColor: companyPublicInfo.colour_secondary ?? DEFAULT_BRAND_COLOUR_SECONDARY }}
        className="px-4 md:px-6 py-8 flex flex-col items-center gap-8 w-full">
        <div className='flex flex-col items-center gap-1 text-center max-w-screen-2xl'>
          <Text className={determineBestTextShade(companyPublicInfo.colour_secondary ?? DEFAULT_BRAND_COLOUR_SECONDARY) === 'LIGHT' ? 'text-black' : 'text-white'}>{estimate.address}, {estimate.postcode}</Text>
          <Heading className={determineBestTextShade(companyPublicInfo.colour_secondary ?? DEFAULT_BRAND_COLOUR_SECONDARY) === 'LIGHT' ? 'text-black' : 'text-white'} size="3xl">Great news! Your property is suitable for a heat pump.</Heading>
        </div>
        <div className='grid lg:grid-cols-2 gap-5 w-full max-w-screen-2xl'>
          <div className='flex flex-col gap-5'>
            {/* Cover note */}
            <EstimateCard color="WHITE" className='gap-5 shadow flex-1'>
              <Heading size="lg">Hi {estimate.name}!</Heading>
              <div className='flex flex-col gap-2 text-sm' dangerouslySetInnerHTML={{
                __html:
                  renderHTMLReplacingPlaceholders(
                    getCompanyEstimateCoverNote(estimate.cover_note ?? companyPublicInfo.calculated_estimate_cover_note ?? ''), {
                      [TTPlaceholderCompanyName.id]: companyPublicInfo.name,
                      [TTPlaceholderCompanyPhone.id]: companyPublicInfo.phone,
                      [TTPlaceholderInstallerName.id]: `${companyPublicInfo.installer_first_name} ${companyPublicInfo.installer_last_name}`,
                      [TTPlaceholderEstimateContactName.id]: companyPublicInfo.estimate_contact_name ?? '',
                      [TTPlaceholderEstimateContactPhone.id]: companyPublicInfo.estimate_contact_phone ?? ''
                    }, undefined, 'text-base text-bold font-bold')
              }} />
              <ContactCardWrapper hasContent={Boolean(companyPublicInfo.estimate_contact_name || companyPublicInfo.estimate_contact_phone || companyPublicInfo.estimate_contact_role || companyPublicInfo.estimate_contact_portrait)}>
                {/* Estimate Contact */}
                <div className='flex justify-start items-center pt-1 gap-4'>
                  {companyPublicInfo.estimate_contact_portrait && <img
                    src={companyPublicInfo.estimate_contact_portrait}
                    alt='Estimate contact headshot'
                    className='rounded-full w-20 h-20 object-cover'
                  />}
                  <div className='flex flex-col gap-1'>
                    <div>
                      <Text bold size='MD'>{companyPublicInfo.estimate_contact_name}</Text>
                      <Text size='SM'>{companyPublicInfo.estimate_contact_role}</Text>
                    </div>
                    <a className='text-base text-sky-800 font-bold underline' href={`tel:${companyPublicInfo.estimate_contact_phone}`}>{companyPublicInfo.estimate_contact_phone}</a>
                  </div>
                </div>
                <div className='grid sm:grid-cols-2 gap-4 pb-1'>
                  <Button
                    style={{
                      backgroundColor: companyPublicInfo.colour_primary,
                      borderColor: companyPublicInfo.colour_primary,
                      color: determineBestTextShade(companyPublicInfo.colour_primary ?? DEFAULT_BRAND_COLOUR_PRIMARY) === 'LIGHT' ? '#000000' : '#ffffff'
                    }}
                    className={(!companyPublicInfo.no_request_callback || isScottish) ? 'col-span-1' : 'col-span-full'}
                    onClick={() => costEstimateHeadingRef.current?.scrollIntoView({ behavior: 'smooth' })}>View cost estimate</Button>
                  {(!companyPublicInfo.no_request_callback || isScottish) && <Button colour='LIGHT' onClick={() => setShowRequestCallbackModal(true)} disabled={isPreview}>Request a callback</Button>}
                </div>
              </ContactCardWrapper>
            </EstimateCard>
            {/* Did you know? */}
            <EstimateCard color="WHITE" className='gap-1 shadow flex-1'>
              <Heading size="lg">Did you know?</Heading>
              <Text size="SM">Heat pumps are more than 100% efficient! That's possible because they use a small amount of electricity to capture a larger amount of free heat from outdoor air, and do this happily in temperatures well below 0°C.</Text>
            </EstimateCard>
          </div>
          <div className='flex flex-col gap-5'>
            {/* Estimate overview */}
            <EstimateCard color="WHITE" className='gap-4 shadow flex-1'>
              <Heading size="lg">Estimate overview</Heading>
              <EstimateCard size="SM" color="GREY" className='gap-1'>
                <Text size="SM">Estimated cost of installation <Info infoModalHeader={INFO_POPUPS.estimatedCost.title} infoModalBody={INFO_POPUPS.estimatedCost.content} /></Text>
                <Heading size="xl">{formatPrice(sum(quoteItemsWithSubtotals?.map(i => i.selected ? i.subtotal : 0)))}</Heading>
              </EstimateCard>
              <EstimateCard size="SM" color="GREY" className='gap-1'>
                <Text size="SM">Estimated total heat loss <Info infoModalHeader={INFO_POPUPS.heatLoss.title} infoModalBody={INFO_POPUPS.heatLoss.content} /></Text>
                <Heading size="xl">{(estimate.total_watts / 1000).toFixed(1) + ' kW'}</Heading>
              </EstimateCard>
              {((isScottish || grantEligible) && quoteGrantEnabled) && <EstimateCard size='SM' color="GREY" className='gap-1'>
                <Text size="SM">Predicted grant eligibility <Info infoModalHeader={isScottish ? INFO_POPUPS.hesGrant.title : INFO_POPUPS.busGrant.title} infoModalBody={isScottish ? INFO_POPUPS.hesGrant.content : INFO_POPUPS.busGrant.content} /></Text>
                <Heading size="xl">{<span><Icon icon={CheckCircle} colour='text-green-600' className='mr-1' />Eligible</span>}</Heading>
              </EstimateCard>}
            </EstimateCard>
            {/* Savings */}
            <EstimateCard color="WHITE" className='gap-4 shadow flex-1'>
              <Heading size="lg">What impact would it have?</Heading>
              {showBillSavings && <EstimateCard size='SM' color="YELLOW" className='gap-1'>
                <Text size="SM">Potential bill savings <BillSavingsInfo billSavingsInfoContent={estimate}/></Text>
                <Heading size="xl">{
                  <>
                    <span>{BILL_SAVINGS_EMOJI} £{numberFormat(0).format(billSavings)}</span>
                    <span className='text-base font-medium text-default'>/year</span>
                  </>
                }</Heading>
              </EstimateCard>}
              <EstimateCard size='SM' color="GREEN" className='gap-1'>
                <Text size="SM">Estimated carbon savings <Info infoModalHeader={INFO_POPUPS.carbonSavings(estimate).title} infoModalBody={INFO_POPUPS.carbonSavings(estimate).content} /></Text>
                <Heading size="xl">{
                  <>
                    <span>{CARBON_SAVINGS_EMOJI} {getFlightsSaved(estimate.co2_saved_kg)} flights to Spain</span>
                    <span className='text-base font-medium text-default'>/year</span>
                  </>
                }</Heading>
              </EstimateCard>
            </EstimateCard>
          </div>
        </div> {/* /cards */}
        <div className='flex flex-col items-center gap-2 text-center max-w-screen-2xl'>
          <Text className={determineBestTextShade(companyPublicInfo.colour_secondary ?? DEFAULT_BRAND_COLOUR_SECONDARY) === 'LIGHT' ? 'text-black' : 'text-white'} >Scroll to view the personalised cost estimates we have prepared for you</Text>
          <Icon className={determineBestTextShade(companyPublicInfo.colour_secondary ?? DEFAULT_BRAND_COLOUR_SECONDARY) === 'LIGHT' ? 'text-black' : 'text-white'} icon={ArrowDown} />
        </div>
      </div>

      <div className='flex flex-col items-center w-full gap-10 bg-gray-50'>

        {/* Next steps */}
        <div className="px-4 pt-10 md:px-6 flex flex-col items-center w-full">
          <div className='text-center pb-6'>
            <Heading size="2xl">So, what happens next?</Heading>
          </div>
          <NextSteps isScottish={isScottish} companyPublicInfo={companyPublicInfo} />
        </div>

        <div className="px-4 md:px-6">
          <div className='pb-6 text-center' ref={costEstimateHeadingRef}>
            <Heading size="2xl">Your personalised cost estimate</Heading>
          </div>

          {/* Heat pump details */}
          <EstimateCard color="WHITE" size="LG" className='gap-4 shadow w-full max-w-screen-lg'>
            <Heading size="2xl">{estimate.heat_pump_name}</Heading>
            <div className={`grid ${imageLoaded ? 'sm:grid-cols-[2fr_1fr]' : ''} gap-5`}>
              <div className='flex flex-col gap-4'>
                <div className='flex flex-col gap-2'>
                  {showBillSavings && <TagCard color="YELLOW">
                    <Text size="XS" bold={true}>{BILL_SAVINGS_EMOJI} Potential bill savings</Text>
                    <Text size="XS" bold={true} className='text-default'>£{numberFormat(0).format(billSavings)}/year <BillSavingsInfo billSavingsInfoContent={estimate}/></Text>
                  </TagCard>}
                  <TagCard color="GREEN">
                    <Text size="XS" bold={true}>{CARBON_SAVINGS_EMOJI} Estimated carbon savings </Text>
                    <Text size="XS" bold={true} className='text-default'>{getFlightsSaved(estimate.co2_saved_kg)} flights to Spain/year <Info infoModalHeader={INFO_POPUPS.carbonSavings(estimate).title} infoModalBody={INFO_POPUPS.carbonSavings(estimate).content} /></Text>
                  </TagCard>
                </div>

                {/* Key specs and system design */}
                <EstimateCard color="WHITE" size="MD" className='gap-4'>
                  {/* Key specs */}
                  {(estimate.range_heat_pump || heatPumpHasDimensions || estimate.scop) && <div>
                    <Text size="MD" bold={true} className='pb-2'>Key specifications</Text>
                    <SummaryTable rows={[
                      // { key: 'Warranty', value: '7 years' },
                      estimate.range_heat_pump?.sound_power_max_dba ? { key: 'Sound power level', value: `${estimate.range_heat_pump?.sound_power_max_dba} dB(A)`, infoButton: <Info infoModalHeader={INFO_POPUPS.soundPower.title} infoModalBody={INFO_POPUPS.soundPower.content} />, size: 'MD' } : undefined,
                      heatPumpHasDimensions ? { key: 'Dimensions (WxHxD)', value: `${estimate.range_heat_pump?.width_mm}mm x ${estimate.range_heat_pump?.height_mm}mm x ${estimate.range_heat_pump?.depth_mm}mm`, size: 'MD' } : undefined,
                      estimate.scop ? { key: 'Efficiency', value: `${Math.floor(estimate.scop * 100)}%`, infoButton: <Info infoModalHeader={INFO_POPUPS.efficiency(estimate).title} infoModalBody={INFO_POPUPS.efficiency(estimate).content} />, size: 'MD' } : undefined
                    ].filter((row): row is { key: string, value: string, size: 'MD' } => row !== undefined)} />
                  </div>}

                  {/* System design */}
                  <div>
                    <Text size="MD" bold={true} className='pb-2'>System design <Info infoModalHeader={INFO_POPUPS.systemDesign.title} infoModalBody={INFO_POPUPS.systemDesign.content} /></Text>
                    <SummaryTable rows={[
                      estimate.flow_temperature_c ? { key: 'Flow temperature (°C)', value: `${estimate.flow_temperature_c}°C`, infoButton: <Info infoModalHeader={INFO_POPUPS.flowTemperature.title} infoModalBody={INFO_POPUPS.flowTemperature.content} />, size: 'MD' } : undefined,
                      // { key: 'Estimated # radiator changes', value: '0' },
                      { key: 'Cylinder', value: estimate.hot_water_cylinder_name, size: 'MD' }
                    ].filter((row): row is { key: string, value: string, size: 'MD' } => row !== undefined)} />
                  </div>
                </EstimateCard>
              </div>
              <div className="flex sm:justify-end sm:items-end items-center justify-center">
                <img
                  src={process.env.S3_BUCKET_URL + '/hp-images/' + estimate.range_heat_pump_uuid + '.png'}
                  alt={estimate.heat_pump_name}
                  className='max-h-80 object-contain'
                  onError={(e) => {
                    e.currentTarget.onerror = null
                    e.currentTarget.remove()
                    setImageLoaded(false)
                  }}
                />
              </div>
            </div>

            {/* Estimated cost breakdown */}
            <EstimateCard color="GREY" size="MD" className='gap-4'>
              <div>
                {/* Estimated cost (pre-grant) */}
                <div className='flex flex-row justify-between items-center'>
                  <Text size="MD" bold={true} className='pb-2'>Estimate cost</Text>
                  <Text size="SM" bold={true}>{formatPrice(sum(quoteItemsWithSubtotals?.map(i => i.selected ? i.subtotal : 0)) - quoteGrant)}</Text>
                </div>
                <div className="ml-5">
                  <SummaryTable rows={[
                    ...(groupedQuoteItems[CalculatedQuoteDefaultGroups.HEAT_PUMPS]?.map((x) => ({ key: quoteItemNameWithQuantity(x), value: companyPublicInfo.show_line_item_costs_on_estimate ? formatPrice(x.subtotal) : '', size: 'MD' })) || []),
                    ...(groupedQuoteItems[CalculatedQuoteDefaultGroups.HOT_WATER_CYLINDERS]?.map((x) => ({ key: quoteItemNameWithQuantity(x), value: companyPublicInfo.show_line_item_costs_on_estimate ? formatPrice(x.subtotal) : '', size: 'MD' })) || []),
                    ...(groupedQuoteItems[CalculatedQuoteDefaultGroups.PARTS]?.map((x) => ({ key: quoteItemNameWithQuantity(x), value: companyPublicInfo.show_line_item_costs_on_estimate ? formatPrice(x.subtotal) : '', size: 'MD' })) || []),
                    ...(groupedQuoteItems[CalculatedQuoteDefaultGroups.UNDERFLOOR]?.map((x) => ({ key: quoteItemNameWithQuantity(x, 'm²'), value: companyPublicInfo.show_line_item_costs_on_estimate ? formatPrice(x.subtotal) : '', size: 'MD' })) || []),
                    ...(groupedQuoteItems[CalculatedQuoteDefaultGroups.LABOUR]?.map((x) => ({ key: quoteItemNameWithQuantity(x, 'days'), value: companyPublicInfo.show_line_item_costs_on_estimate ? formatPrice(x.subtotal) : '', size: 'MD' })) || []),
                    ...(groupedQuoteItems[CalculatedQuoteDefaultGroups.SURVEY]?.map((x) => ({ key: quoteItemNameWithQuantity(x), value: companyPublicInfo.show_line_item_costs_on_estimate ? formatPrice(x.subtotal) : '', size: 'MD' })) || [])
                  ].filter((row): row is { key: string, value: string, emoji: string, size: 'MD' } => row !== undefined)} />
                </div>
              </div>
              {/* Radiators */}
              { groupedQuoteItems[CalculatedQuoteDefaultGroups.RADIATORS]?.length > 0 && <Card color='WHITE'>
                <div className='flex gap-1 items-center'>
                  <Text size="MD" bold={true}>Estimated additional cost of radiator changes</Text>
                  <Info infoModalHeader='Estimated radiator changes explained' infoModalBody={
                    <div className='flex flex-col gap-3'>
                      <Text>Heat pumps operate at a lower flow temperature than boilers, so they need a larger radiator surface area to effectively heat your home. This means some radiators in your property may need to be upgraded to ensure the system runs efficiently.</Text>
                      <div className='flex flex-col gap-1'>
                        <Heading size='md'>How is this estimated?</Heading>
                        <Text>We’ve made a rough estimate based on your property size to give you an idea of potential costs. However, we’ll need to complete a full survey to determine the exact requirements.</Text>
                      </div>
                    </div>
                  } />
                </div>
                <div className='py-2 px-6'>
                  <SummaryTable rows={[
                    { key: 'Number of changes', value: sum(groupedQuoteItems[CalculatedQuoteDefaultGroups.RADIATORS]?.map(x => x.quantity)), size: 'MD' as const },
                    { key: 'Estimated cost', value: companyPublicInfo.show_line_item_costs_on_estimate ? formatPrice(sum(groupedQuoteItems[CalculatedQuoteDefaultGroups.RADIATORS]?.map(x => x.subtotal))) : undefined, size: 'MD' as const }
                  ].filter(row => row.value !== undefined)}
                  />
                </div>
              </Card>}

              {/* BUS grant */}
              {(!isScottish && quoteGrantEnabled) && <TagCard color="BRIGHT_GREEN">
                <div className='flex gap-1 items-center'>
                  <Icon icon={CheckCircle} colour='text-white' className='w-6' />
                  <Text size="XS" className='text-white'>Boiler Upgrade Scheme (BUS) Grant</Text>
                </div>
                <Text size="XS" className='text-white'>{formatPrice(quoteGrant)}</Text>
              </TagCard>}

              {/* Total (post-grant) */}
              <div className="flex items-center justify-between gap-4">
                <Text bold={true}>Estimated total</Text>
                <Text bold={true}>{formatPrice(sum(quoteItemsWithSubtotals?.map(i => i.selected ? i.subtotal : 0)))}</Text>
              </div>
            </EstimateCard>

            {/* Disclaimer */}
            <Alert type="WARNING">
              <div className={'flex flex-col space-y-2'}>
                <p>Please note this is an initial estimate based on the information we have so far. We'll be able to provide a final quote once we've done a room-by-room heat loss survey of your property.</p>
                {showBillSavings && <p>Note also that these potential bill savings are not guaranteed – they're estimated based upon what we know about your property, with broad-brush assumptions about how you heat your home.</p>}
              </div>
            </Alert>

          </EstimateCard>
        </div>

        {/* Call to action */}
        <div className="w-full max-w-screen-2xl mx-auto px-4 md:px-6">
          <div
            style={{ backgroundColor: companyPublicInfo.colour_secondary ?? DEFAULT_BRAND_COLOUR_SECONDARY }}
            className="px-6 py-10 flex flex-col items-center w-full gap-5 rounded-lg">
            {showRequestSurveyButton
              ? <div className="flex flex-col gap-3 text-center">
                  <Heading size="2xl" className={determineBestTextShade(companyPublicInfo.colour_secondary ?? DEFAULT_BRAND_COLOUR_SECONDARY) === 'LIGHT' ? 'text-black' : 'text-white'}>Book a full heat loss survey today</Heading>
                  {(!companyPublicInfo.no_request_callback || isScottish) && <Text className={determineBestTextShade(companyPublicInfo.colour_secondary ?? DEFAULT_BRAND_COLOUR_SECONDARY) === 'LIGHT' ? 'text-black' : 'text-white'}>Or request a callback to discuss your needs in more detail.</Text>}
                </div>
              : <div className="flex flex-col gap-3 text-center">
                  <Heading size="2xl" className={determineBestTextShade(companyPublicInfo.colour_secondary ?? DEFAULT_BRAND_COLOUR_SECONDARY) === 'LIGHT' ? 'text-black' : 'text-white'}>Let us know if you'd like to proceed</Heading>
                  <Text className={determineBestTextShade(companyPublicInfo.colour_secondary ?? DEFAULT_BRAND_COLOUR_SECONDARY) === 'LIGHT' ? 'text-black' : 'text-white'}>Please request a callback so we can answer any outstanding questions and book in the next steps. And if you're not
                  interested in progressing right now, please just let us know below.
                  </Text>
                </div>}
            <div className="w-full max-w-80 flex flex-col gap-3 items-center">
              {showRequestSurveyButton &&
                <Button
                  style={{
                    backgroundColor: companyPublicInfo.colour_primary,
                    borderColor: companyPublicInfo.colour_primary,
                    color: determineBestTextShade(companyPublicInfo.colour_primary ?? DEFAULT_BRAND_COLOUR_PRIMARY) === 'LIGHT' ? '#000000' : '#ffffff'
                  }}
                  className='w-full' onClick={handleRequestSurvey} disabled={isPreview}>Book a full heat loss survey</Button>
              }
              {(!companyPublicInfo.no_request_callback || isScottish) && <Button
                style={isScottish ? {
                  backgroundColor: companyPublicInfo.colour_primary,
                  borderColor: companyPublicInfo.colour_primary,
                  color: determineBestTextShade(companyPublicInfo.colour_primary ?? DEFAULT_BRAND_COLOUR_PRIMARY) === 'LIGHT' ? '#000000' : '#ffffff'
                } : {}}
                className='w-full' colour={showRequestSurveyButton ? 'LIGHT' : 'DARK'} onClick={() => setShowRequestCallbackModal(true)} disabled={isPreview}>Request a callback</Button>}
              {isScottish && <>
                {/* Home Energy Scotland button */}
                <Button colour="LIGHT" className='w-full' disabled={isPreview}>
                  <a target="_blank" href="https://www.homeenergyscotland.org/funding/grants-loans/" rel="noreferrer">Visit Home Energy Scotland</a>
                </Button>
              </>}
              <Link className={determineBestTextShade(companyPublicInfo.colour_secondary ?? DEFAULT_BRAND_COLOUR_SECONDARY) === 'LIGHT' ? '' : 'text-white'} text='I’m not interested right now' onClick={() => setShowNotInterestedModal(true)} disabled={isPreview} />
            </div>
          </div>
        </div>
        {/* About Us / Customer Reviews */}
        {(companyPublicInfo.show_about_us_on_estimate || companyPublicInfo.show_customer_review_on_estimate) && <div className='w-full max-w-screen-2xl mx-auto px-4 md:px-6 flex flex-col items-center gap-6'>
          <Heading size="2xl" className='text-center'>Why choose {companyPublicInfo.name}?</Heading>
          <div className={`grid grid-cols-1 ${(companyPublicInfo.show_about_us_on_estimate && companyPublicInfo.show_customer_review_on_estimate) ? 'sm:grid-cols-2' : ''} gap-5`}>
            {companyPublicInfo.show_about_us_on_estimate && <EstimateCard color='WHITE' className='p-4 gap-4'>
              <Heading size="lg">About us</Heading>
              <div className='grid lg:grid-cols-2 gap-4'>
                <Text size='SM' className={`w-full whitespace-pre-line ${!companyPublicInfo.about_us_image && 'col-span-full'}`}>{companyPublicInfo.about_us}</Text>
                {companyPublicInfo.about_us_image && <img
                  src={companyPublicInfo.about_us_image}
                  alt='About us'
                  className='object-contain justify-self-center'
                />}
              </div>
            </EstimateCard>}
            {companyPublicInfo.show_customer_review_on_estimate && <EstimateCard color='WHITE' className='p-4 gap-4'>
              <Heading size="lg">See what customers are saying</Heading>
              <EstimateCard color='GREY' className='flex-col gap-2 grow'>
                <Text className='whitespace-pre-line'>{companyPublicInfo.customer_review}</Text>
                {/* Only show name - location on review if NAME is populated */}
                {companyPublicInfo.customer_review_name && <Text size='SM' bold className='pl-1' >{` - ${companyPublicInfo.customer_review_name}${companyPublicInfo.customer_review_location ? `, ${companyPublicInfo.customer_review_location}` : ''}`}</Text>}
              </EstimateCard>
              {companyPublicInfo.customer_review_link_text && <Button colour='LIGHT' onClick={() => companyPublicInfo.customer_review_link && openInNewTab(companyPublicInfo.customer_review_link)}>{companyPublicInfo.customer_review_link_text}</Button>}
            </EstimateCard>}
          </div>
        </div>}

        {/* Common questions */}
        <div className='w-full bg-white'>

          <div className='w-full max-w-screen-2xl px-4 md:px-6 py-10 flex flex-col gap-6 mx-auto'>
            <Heading size="2xl">Common questions</Heading>
            <Accordion rows={faqRows()} />
          </div>
        </div>

      </div>

    </div>
    <NotInterestedModal visible={showNotInterestedModal} setVisible={setShowNotInterestedModal} onConfirm={handleNotInterested} />
    <RequestCallbackModal visible={showRequestCallbackModal} setVisible={setShowRequestCallbackModal} onConfirm={handleRequestCallback} />
    <RequestSurveyModal visible={showRequestSurveyModal} setVisible={setShowRequestSurveyModal} />
  </>
}
