import React from 'react'
import { SurveyCylinderLocationContext } from '../cylinder_location'
import { ClickableCard } from '../../../../components/content_display/card'
import { Toggle } from '../../../../components/inputs_and_selections/toggle'

export const CylinderLocationHasExistingHWCBlock = () => {
  const cylinderLocationContext = React.useContext(SurveyCylinderLocationContext)
  const { survey, setSurvey } = cylinderLocationContext!

  return <>
    <ClickableCard variant='GREY' >
      <div className="items-center gap-3 flex justify-between">
        <div className="flex-col flex">
          <div className="text-gray-900 font-bold">Existing cylinder</div>
          <div className="text-gray-500 text-sm">Property has an existing hot water cylinder</div>
        </div>
        <Toggle value={survey.has_existing_cylinder} setValue={() => setSurvey({ ...survey, has_existing_cylinder: !survey.has_existing_cylinder })} />
      </div>
    </ClickableCard>
  </>
}
