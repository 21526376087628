import React, { type Dispatch, type SetStateAction } from 'react'
import { type Door } from '../../code/models/door'
import { Input } from '../../components/inputs_and_selections/input'
import { Button } from '../../components/buttons/button'
import { type MaterialsSelectorGroupedProps } from './materials/materials_selector'
import type { Material } from '../../code/models/material'
import type { PropertySurvey } from '../../code/models/property'
import { MaterialInputField } from './materials/material_input_field'
import { validateInMm } from '../../code/validators'
import { type CurrentFloorPlanPage } from './floor/floor'
import { type Floor } from '../../code/models/floor'

type DoorPageProps = {
  floor: Floor
  door: Door
  setDoor: Dispatch<SetStateAction<Door | undefined>>
  onSave: () => void
  survey: PropertySurvey
  materials: Material[]
  setMsProps: React.Dispatch<React.SetStateAction<MaterialsSelectorGroupedProps | undefined>>
  setPage: (page: CurrentFloorPlanPage) => void
  setScalingDoor: Dispatch<SetStateAction<boolean>>
  onBack: () => void
}

export const DoorPage = ({ door, setDoor, onSave, survey, materials, setMsProps, setPage, onBack, floor, setScalingDoor }: DoorPageProps) => {
  const isValidForm = [validateInMm(door.width_mm), validateInMm(door.height_mm)].every(x => x.value)

  return <div className='flex flex-col'>
    <div className="p-5 bg-white flex-col gap-5 flex">
      <div className='space-y-2'>
        <div className='text-gray-900 font-bold text-sm'>Door material</div>
        <MaterialInputField
          selectorProps={{
            title: 'Door',
            surfaceType: 'door',
            materials,
            ageBand: survey.age_band,
            selectedMaterial: door.material,
            onSelectCallback: (material: Material) => {
              setDoor(prev => ({ ...prev!, material, updated_at: new Date().getTime() }))
            }
          } satisfies MaterialsSelectorGroupedProps}
          setMsProps={setMsProps}
          setPage={setPage}
        />
      </div>
      <div className='flex gap-2'>
        <div className='flex flex-col gap-2 flex-grow'>
          <div className='text-gray-900 font-bold text-sm'>Width</div>
          <Input
            step={10}
            type='number'
            value={door.width_mm.toString()}
            setValue={(e) => setDoor(prev => ({ ...prev!, width_mm: parseFloat(e), updated_at: new Date().getTime() }))}
            validator={validateInMm}
            postfix='mm' />
        </div>
        <div className='flex flex-col gap-2 flex-grow'>
          <div className='text-gray-900 font-bold text-sm'>Height</div>
          <Input
            step={10}
            type='number'
            value={door.height_mm.toString()}
            setValue={(e) => setDoor(prev => ({ ...prev!, height_mm: parseFloat(e), updated_at: new Date().getTime() }))}
            validator={validateInMm}
            postfix='mm' />
        </div>
      </div>

      <div className='flex gap-2'>
        {floor.floor_plan_is_showing && (floor.floor_plan_image || floor.floor_plan_url) && <Button
          className='flex-1'
          colour='LIGHT'
          onClick={() => { setScalingDoor(true) }}
        >Measure width</Button>}
        <Button
          className='flex-1'
          onClick={() => { onSave(); setDoor(undefined); onBack() }}
          disabled={!isValidForm}
        >Apply</Button>
      </div>
    </div>
  </div>
}
