import { Button } from '../../../components/buttons/button'
import { centreAndScaleOnFloor, centreOnRoomScaleOnFloor } from './code/utils'
import React from 'react'
import { Alert } from '../../../components/indicators_and_messaging/alert'
import { UnsnappedWallAlert } from '../wall'
import { CornerUpLeft, CornerUpRight, Crosshair } from 'lucide-react'

export type UndoRedoRecenterButtonsProps = {
  undo: () => void
  redo: (event: any) => void
  undoIndex: number
  undoEvents: any[]
  floor: any
  setStagePosition: any
  setStageScale: any
  stageSize: any
  drawingRooms: boolean
  currentRoom: any
  intersectingShapes: Array<Array<[number, number]>>
  hasUnsnappedInternalWalls: boolean
}

export const UndoRedoRecenterButtonsAndAlerts = ({ drawingRooms, undoIndex, undo, redo, undoEvents, currentRoom, floor, setStagePosition, setStageScale, stageSize, intersectingShapes, hasUnsnappedInternalWalls }: UndoRedoRecenterButtonsProps) => {
  // Use absolute positioning for these buttons rather than a flex container so that the area between the Undo/Redo on the left and the Re-centre on the right is clickable
  return (<>
    <div className="absolute top-4 left-2 flex gap-2">
      {/* Buttons */}
      {/* Undo, Redo */}
      {/* Hide undo/redo when drawing a room so that we dont accidentally click it and, say, remove our floor plan */}
      {!drawingRooms && <>
        <Button
          size={'SM'}
          colour={'LIGHT'}
          className={'shadow'}
          disabled={undoIndex === 0}
          onClick={undo}>
          <div className="flex justify-center gap-1">
            <CornerUpLeft className="h-4 w-4"/>
            <div>Undo</div>
          </div>
        </Button>
        <Button
          size={'SM'}
          colour={'LIGHT'}
          className={'shadow'}
          disabled={undoIndex === undoEvents.length}
          onClick={() => redo(undoEvents[undoIndex])}>
          <div className="flex justify-center gap-1">
            <CornerUpRight className="h-4 w-4"/>
            <div>Redo</div>
          </div>
        </Button>
      </>}
    </div>
    {/* Recenter */}
    <div className="absolute top-4 right-2">
      <Button
        size={'SM'}
        colour={'LIGHT'}
        onClick={() => {
          if (currentRoom) {
            centreOnRoomScaleOnFloor(currentRoom, floor, setStagePosition, setStageScale, stageSize.width, stageSize.height)
            return
          }

          if (floor.rooms.length > 0) {
            centreAndScaleOnFloor(floor, setStagePosition, setStageScale, stageSize.width, stageSize.height)
          } else {
            setStageScale(0.05)
            setStagePosition({ x: stageSize.width / 2, y: stageSize.height / 2 })
          }
        }}
      >
        <div className="flex justify-center gap-1">
          <Crosshair className="h-4 w-4"/>
          <div>Re-centre</div>
        </div>
      </Button>
    </div>
    {/* Alerts */}
    <div className="absolute top-16 left-0 right-0 w-full">
      <div className="space-y-2">
        {intersectingShapes.length > 0 && (
          <Alert type="DANGER">
              You have <span className="font-bold">{intersectingShapes.length / 2}</span> rooms overlapping, you cannot continue until this is resolved. Locate the overlapping rooms (highlighted in red) and drag them apart.
          </Alert>
        )}
        {hasUnsnappedInternalWalls && UnsnappedWallAlert}
      </div>
    </div>
  </>)
}
