import React, { useState } from 'react'
import { Input } from '../../components/inputs_and_selections/input'
import { Button } from '../../components/buttons/button'
import { remindPassword } from '../../code/models/auth'
import { SpruceLogoBlack } from '../../assets/images/spruce_logo/spruce_logo'
import { Link } from '../../components/buttons/link'

export const RemindPasswordPage = ({ navigateTo }: { navigateTo: (url: string) => void }) => {
  // common admin/ preloaded data
  const [email, setEmail] = useState('')

  // component specific
  const [showSent, setShowSent] = useState(false)

  const handleSubmit = async () => {
    await remindPassword(email)
    setShowSent(true)
  }

  return <div className="flex flex-col items-center justify-center gap-16 w-2/3 max-w-80 mx-auto">
    <img alt="logo" className="mt-20 max-h-28 max-w-2/3" src={SpruceLogoBlack} />
    <div className="w-full">
      <div className="flex flex-col gap-4">
        <div className="space-y-2">
          <div className="text-slate-600 text-lg font-bold">Email</div>
          <Input
            onEnter={async () => { await handleSubmit() }}
            setValue={setEmail}
            type="text"
            value={email}
            placeholder={'Email'}
          />
        </div>
        <Link text='Back to Login page' onClick={() => navigateTo('/login')} />
        <Button onClick={handleSubmit}>Send link</Button>
        {showSent && <div className="text-sm text-green-600">If your email address is registered on Spruce, we will send you an email with a link to reset your password.</div>}
      </div>
    </div>
  </div>
}
