import React from 'react'
import { SurveyElectricsContext } from '../electrics'
import { RadioGroup } from '../../../../components/inputs_and_selections/radio'
import { FormLabel } from '../../../../components/inputs_and_selections/form_label'
import { Info } from '../../../../components/buttons/info'
import { tnsEarthing, tncsEarthing, ttEarthing } from '../../../../assets/images/images'
import { VerticalFormGroup } from '../../../../components/inputs_and_selections/vertical_form_group'

export const ElectricsEarthingArrangementDetailsBlock = () => {
  const electricsContext = React.useContext(SurveyElectricsContext)
  const { survey, setSurvey } = electricsContext!

  const ARRANGEMENTS = [
    { title: 'TN-C-S (PME)', description: 'Connected to neutral conductor on the incoming supply.', image: tncsEarthing, altText: 'TN-C-S (PME) earthing arrangement image' },
    { title: 'TN-S (SNE)', description: 'Connected to the metallic sheath/armour of the incoming supply.', image: tnsEarthing, altText: 'TN-S (SNE) earthing arrangement image' },
    { title: 'TT (Direct)', description: 'Connected to a spike in the ground at the installation itself.', image: ttEarthing, altText: 'TT (Direct) earthing arrangement image' }
  ]

  return <>
    <VerticalFormGroup
      formLabel={<FormLabel labelText="Earthing Arrangement"/>}
      input={<RadioGroup items={ARRANGEMENTS.map(x => ({
        name: x.title,
        description: x.description,
        info: <Info
          infoModalHeader={x.title}
          infoModalBody={<img className="object-contain justify-center" src={x.image} alt={x.altText}/>} // object contain maintains aspect ratio
          colour={'text-gray-500'}
        />,
        onClick: () => setSurvey({ ...survey, electrics_arrangement_uuid: x.title }),
        variant: x.title === survey.electrics_arrangement_uuid ? 'ACTIVE' : 'DEFAULT'
      }))}/>}
    />
  </>
}
