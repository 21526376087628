import React, { useContext, useEffect, useState } from 'react'
import { type CompanyPublicInfo } from '../../code/models/company'
import { getAddressIncludingPostcode, getLeads, type LeadStatusType, patchLead, type Lead } from '../../code/models/lead'
import { chain, sum } from 'lodash'
import { Badge, leadStatusToBadge } from '../../components/indicators_and_messaging/badge'
import { AdminContext } from './admin_layout'
import { calculateEstimate, DEFAULT_ESTIMATE_RAD_CHANGE_PERCENTAGE } from '../../code/calculate_estimate'
import { formatPrice } from '../../code/format_price'
import { contextSensitiveFormatDate } from '../../code/format_date'
import { USER_ROLE_HAS_SURVEY_ACCESS, USER_ROLE_SIMPLE, USER_ROLE_SURVEYOR } from '../../code/models/user'
import { RequireRole } from '../../require_role'
import { Input } from '../../components/inputs_and_selections/input'
import { Icon } from '../../components/buttons/icon'
import { useLocalStorage } from 'usehooks-ts'
import { calculateQuote } from '../../code/calculate_quote'
import { Button } from '../../components/buttons/button'
import { getApproxNumberOfRadiatorChanges } from '../../code/models/estimated_rooms_and_radiators'
import { DuplicateLeadModal } from './components/duplicate_lead_modal'
import TabbedDashboardLayout from '../../layouts/tabbed_dashboard_layout'
import { DropdownMenu } from '../../components/buttons/dropdown_menu'
import { ChevronLeft, ChevronRight, Copy, Search, X } from 'lucide-react'
import { ClickableCard } from '../../components/content_display/card'

type EnquiriesListPageProps = {
  filter: string
  companyPublicInfo: CompanyPublicInfo
  basePath: string
  navigateTo: (url: string) => void
}

type LeadStatusMap = {
  display_name: string
  code: LeadStatusType | 'AllJobs'
  urlKey: string
}
const LEAD_STATUSES: LeadStatusMap[] = [
  { display_name: 'All jobs', code: 'AllJobs', urlKey: 'all' }, // Not real status
  { display_name: 'Estimate sent', code: 'Quoted', urlKey: 'estimated' },
  { display_name: 'Accepted', code: 'Converted', urlKey: 'accepted' },
  { display_name: 'Survey booked', code: 'SurveyBooked', urlKey: 'survey_booked' },
  { display_name: 'Survey in progress', code: 'SurveyInProgress', urlKey: 'survey_in_progress' },
  { display_name: 'Survey completed', code: 'SurveyCompleted', urlKey: 'survey_completed' },
  { display_name: 'Proposal sent', code: 'ProposalSent', urlKey: 'proposal_sent' },
  { display_name: 'Proposal accepted', code: 'ProposalAccepted', urlKey: 'proposal_accepted' },
  { display_name: 'Rejected', code: 'Rejected', urlKey: 'rejected' },
  { display_name: 'Enquiry declined', code: 'Declined', urlKey: 'declined' },
  { display_name: 'Archived', code: 'Archived', urlKey: 'archived' }
]

export const LeadsListPage = (props: EnquiriesListPageProps) => {
  const adminContext = useContext(AdminContext)
  const { filter, basePath, navigateTo } = props

  return (
    <TabbedDashboardLayout
      navigateTo={navigateTo}
      basePath={basePath + '/enquiries'}
      title='Jobs'
      selectedTabId={filter}
      isOffline={adminContext.isOffline}
      tabs={LEAD_STATUSES.map(x => ({ id: x.urlKey, label: x.display_name, content: <LeadsListInner {...props} /> }))} />
  )
}

const LeadsListInner = ({ filter, companyPublicInfo, basePath, navigateTo }: EnquiriesListPageProps) => {
  const adminContext = useContext(AdminContext)

  // duplicate Lead state
  const [isDuplicateLeadModalOpen, setIsDuplicateLeadModalOpen] = useState<boolean>(false)
  const [leadToDuplicate, setLeadToDuplicate] = useState<Lead | undefined>(undefined)
  const toggleDuplicateLeadModalVisibility = (visible: boolean) => {
    if (!visible) setLeadToDuplicate(undefined)
    setIsDuplicateLeadModalOpen(visible)
  }

  const [leads, setLeads] = useLocalStorage<Lead[]>('leads', [])

  useEffect(() => {
    const main = async () => {
      const allLeads = await getLeads(companyPublicInfo.uuid)
      if (allLeads && allLeads.length > 0) setLeads(allLeads)
    }
    main()
  }, [companyPublicInfo])

  const [filterString, setFilterString] = useState<string | undefined>(undefined)
  const [paginationIndex, setPaginationIndex] = useState(1)
  const pageSize = 20

  const filterLogic = (lead: Lead, filterString: string) => {
    const filterStringLower = filterString.toLowerCase()
    if (lead.customer?.name?.toLowerCase().includes(filterStringLower)) return true
    if (getAddressIncludingPostcode(lead).toLowerCase().includes(filterStringLower)) return true
    if (lead.customer?.email?.toLowerCase().includes(filterStringLower)) return true

    return false
  }

  const filterStatus = LEAD_STATUSES.find(x => x.urlKey === filter)?.code ?? 'AllJobs'

  // filter leads
  const filteredLeads = chain(leads)
    .filter(x =>
      (filterStatus === 'AllJobs' ? x.status !== 'Archived' : x.status === filterStatus) &&
      (!filterString || filterLogic(x, filterString))
    )
    .orderBy(x => new Date(x.createdAt!), 'desc')
    .value()

  const maxPageSize = Math.ceil(filteredLeads.length / pageSize)
  const paginatedLeads = chain(filteredLeads)
    .drop((paginationIndex - 1) * pageSize)
    .take(pageSize)
    .value()

  const FilterAndPagination = <div className='grid grid-cols-1 sm:grid-cols-2 gap-4 items-center justify-between w-full mb-6'>
    <Input
      className='w-full'
      prefix={<Icon icon={Search} />}
      postfix={filterString && <Icon icon={X} onClick={() => setFilterString(undefined)} />}
      placeholder={`Search ${filteredLeads.length} jobs`}
      value={filterString ?? ''}
      setValue={(e) => {
        setFilterString(e)
        setPaginationIndex(1)
      }}
    />
    {maxPageSize > 1 && <div className="md:justify-end items-center gap-7 flex w-full justify-between">
      <Button
        onClick={() => setPaginationIndex(prev => prev - 1)}
        disabled={paginationIndex === 1}
        iconLeft={ChevronLeft}
        colour='LIGHT'
      />
      <div className='text-default text-sm'>Page <span className='font-bold'>{paginationIndex}</span> of <span className='font-bold'>{maxPageSize}</span></div>
      <Button
        onClick={() => setPaginationIndex(prev => prev + 1)}
        disabled={paginationIndex === maxPageSize}
        iconLeft={ChevronRight}
        colour='LIGHT'
      />
    </div>}
  </div>

  const { heatPumps, hotWaterCylinders, company } = adminContext.data
  const setLead = async (lead: Lead) => {
    setLeads(leads.map(x => x.uuid === lead.uuid ? lead : x))
    await patchLead(lead.uuid!, lead, companyPublicInfo.uuid)
  }

  return <RequireRole roles={[USER_ROLE_SIMPLE, USER_ROLE_HAS_SURVEY_ACCESS, USER_ROLE_SURVEYOR]}>
    <>
      {FilterAndPagination}
      <div className='flex flex-col gap-2'>
        {paginatedLeads.map(x => {
          const { heatPump, hotWaterCylinder } = calculateEstimate(x, heatPumps!, hotWaterCylinders!, company)
          if (!heatPump || !hotWaterCylinder || !company) return
          const [calculatedQuote] = calculateQuote({
            company,
            selectedHeatPump: heatPump,
            selectedHotWaterCylinder: hotWaterCylinder,
            defaultRadiatorChanges: getApproxNumberOfRadiatorChanges(x, company?.estimate_default_radiator_change_percentage ?? DEFAULT_ESTIMATE_RAD_CHANGE_PERCENTAGE),
            parts: adminContext.data.parts!,
            labour: adminContext.data.labour!,
            packs: adminContext.data.packs!
          })
          const totalPrice = sum(calculatedQuote.map(y => y.selected ? y.subtotal : 0))

          return <ClickableCard
            key={x.uuid}
            variant='WHITE'
          >
            <div className='grid grid-cols-1 xl:grid-cols-2 py-2 gap-4'>
              <div className='flex flex-grow'>
                <div className="flex-col flex-grow gap-2 xl:gap-1 flex cursor-pointer" onClick={() => navigateTo(`${basePath}/quotes/${x.uuid}`)}>
                  <div className='flex gap-2 justify-between'>
                    <div className="text-bold font-bold">{x.customer?.name}</div>
                    <div className='xl:hidden flex items-center gap-2 justify-end'>
                      <Badge {...leadStatusToBadge(x.status!)} />
                      <DropdownMenu
                        items={[...LEAD_STATUSES.filter(x => x.code !== 'AllJobs').map(y => ({
                          label: y.display_name, onClick: () => setLead({ ...x, status: y.code as LeadStatusType }), group: 'status_change'
                        })), {
                          label: 'Duplicate',
                          icon: Copy,
                          onClick: () => {
                            toggleDuplicateLeadModalVisibility(true)
                            setLeadToDuplicate(x)
                          }
                        }]}
                      />
                    </div>
                  </div>
                  <div className="text-light text-xs font-medium">{getAddressIncludingPostcode(x)}</div>
                </div>
              </div>
              <div className='grid grid-cols-3 xl:grid-cols-4 gap-4 xl:gap-8 items-center justify-end'>
                <div className="flex-col flex">
                  <div className="text-light text-xs font-medium break-words">{x.customer?.email}</div>
                  <div className="text-light text-xs font-medium">{x.customer?.phone}</div>
                </div>
                <div className='text-light text-xs'>{contextSensitiveFormatDate(new Date(x.createdAt!)) }</div>
                <div className='text-bold text-xl justify-end xl:justify-start flex'>{formatPrice(totalPrice)}</div>
                <div className='hidden xl:flex items-center gap-2 justify-end'>
                  <Badge {...leadStatusToBadge(x.status!)} />
                  <DropdownMenu
                    items={[...LEAD_STATUSES.filter(x => x.code !== 'AllJobs').map(y => ({
                      label: y.display_name, onClick: () => setLead({ ...x, status: y.code as LeadStatusType }), group: 'status_change'
                    })), {
                      label: 'Duplicate',
                      icon: Copy,
                      onClick: () => {
                        toggleDuplicateLeadModalVisibility(true)
                        setLeadToDuplicate(x)
                      }
                    }]}
                  />
                </div>
              </div>
            </div>
          </ClickableCard>
        })}
      </div>
      {leadToDuplicate && isDuplicateLeadModalOpen && <DuplicateLeadModal
        visible={true}
        setVisible={toggleDuplicateLeadModalVisibility}
        lead={leadToDuplicate}
        companyPublicInfo={companyPublicInfo}
        navigateTo={navigateTo}
      />}
    </>
  </RequireRole>
}
