export const isValidEmail = (email: string) => {
  const re = /^[^\s@]+@[^\s@]+\.[^\s@]+$/
  return re.test(email.toLowerCase())
}

export const isValidPassword = (password: string) => {
  return password.length >= 8 && /[a-zA-Z]/.test(password) && /[0-9]/.test(password)
}

export const isMatchingPassword = (password: string, otherPassword: string) => {
  return password === otherPassword
}

export const isValidPhone = (phone: string) => {
  const re = /^\+?[\d ]{8,}$/
  return re.test(phone)
}

// Migrate to this interface to support input component.
export const validatePhone = (value: string): { message: string, value: string | undefined } => {
  const isValid = value.match(/(?:\D*\d){10,}/)
  return isValid ? { value, message: '' } : { value: undefined, message: 'Invalid phone number.' }
}

export const validateNotNull = (value: string): { message: string, value: string | undefined } => {
  const isValid = value && value.length > 0
  return isValid ? { value, message: '' } : { value: undefined, message: 'Value required.' }
}

export const validatePassword = (value: string): { message: string, value: string | undefined } => {
  const isValid = isValidPassword(value)
  return isValid ? { value, message: '' } : { value: undefined, message: 'Password must be at least 8 characters long and contain at least one letter and one number.' }
}

export const validatePasswordMatch = (value: string | number, password: string): { message: string, value: string | undefined } => {
  const isValid = value === password
  return isValid ? { value, message: '' } : { value: undefined, message: 'Passwords do not match.' }
}

export const validateEmail = (email: string): { message: string, value: string | undefined } => {
  const isValid = email
    .toLowerCase()
    .match(/^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|.(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/)

  return isValid ? { value: email, message: '' } : { value: undefined, message: 'Invalid email address.' }
}

export const validateIsNumber: (e: string | number) => { message: string, value: undefined | number } = (e: string) => {
  const num = parseFloat(e)
  if (isNaN(num)) { // Allow 0 (empty string)
    return {
      value: undefined,
      message: 'Please enter a number'
    }
  } else {
    return { value: num, message: '' }
  }
}

export const validateWallLength: (e: string | number) => { message: string, value: undefined | number } = (e: string) => {
  const num = parseFloat(e)
  if (isNaN(num) || num < 0.2) {
    return {
      value: undefined,
      message: 'Please enter a number greater than or equal to 0.2m'
    }
  } else {
    return { value: num, message: '' }
  }
}

export const validateIsPositiveNumberOrZero: (e: string | number) => { message: string, value: undefined | number } = (e: string) => {
  const num = parseFloat(e)
  if (isNaN(num) || num < 0) {
    return {
      value: undefined,
      message: 'Please enter a number greater than or equal to 0'
    }
  } else {
    return { value: num, message: '' }
  }
}

export const validateIsPositiveNumber: (e: string | number | undefined) => { message: string, value: undefined | number } = (e: string) => {
  const num = parseFloat(e)
  if (isNaN(num) || num <= 0) {
    return {
      value: undefined,
      message: 'Please enter a number greater than 0'
    }
  } else {
    return { value: num, message: '' }
  }
}

export const validateIntegerIsInRange = (e: string | number, minValue: number, maxValue: number) => {
  const parsed = Number(e)
  if (isNaN(parsed) || !Number.isInteger(parsed)) return { message: 'Please enter a valid integer', value: undefined }
  if (parsed < minValue) return { message: 'Value must be greater than ' + minValue, value: undefined }
  if (parsed > maxValue) return { message: 'Value must be less than ' + maxValue, value: undefined }
  return { message: '', value: parsed }
}

export const validateTemperatureRange = (e: string | number, minValue: number, maxValue: number) => {
  const parsed = parseFloat(e as string)
  if (parsed < minValue) return { message: 'Value must be greater than ' + minValue + '°C', value: undefined }
  if (parsed > maxValue) return { message: 'Value must be less than ' + maxValue + '°C', value: undefined }
  return { message: '', value: parsed }
}

export const validateInMm: (e: string | number) => { message: string, value: undefined | number } = (e: string) => {
  const num = parseFloat(e)
  if (isNaN(num) || num <= 0) {
    return {
      value: undefined,
      message: 'Please enter a number greater than 0'
    }
  } else {
    // If someone enters a dimension number less than 10, chances are they think its in m
    // Risk here is we stop someone entering a tiny thing, but I think 10mm is a reasonable cut off
    if (num <= 10) {
      return {
        value: undefined,
        message: 'Warning: This number should be entered in mm, not m'
      }
    }
    return { value: num, message: '' }
  }
}
export const validateH1LessThanH2: (e: string | number, h2: number | undefined) => { message: string, value: undefined | number } = (e: string, h2: number | undefined) => {
  const result = validateIsPositiveNumber(e)
  if (result.value === undefined) return result

  const num = result.value
  const isValid = !h2 || (num && num < h2)
  return isValid ? { value: num, message: '' } : { value: undefined, message: 'Value should be less than H2' }
}

export const validateW1LessThanW2: (e: string | number, w2: number | undefined) => { message: string, value: number | undefined } = (e, w2) => {
  const result = validateIsPositiveNumber(e)
  if (result.value === undefined) return result

  const num = result.value
  const isValid = !w2 || (num && num < w2)
  return isValid ? { value: num, message: '' } : { value: undefined, message: 'Value should be less than W2' }
}

export const validateHexColourCode = (value: string): { message: string, value: string | undefined } => {
  const isValid = value.match(/^#(?:[0-9a-fA-F]{3}){1,2}$/)
  return isValid ? { value, message: '' } : { value: undefined, message: 'Invalid hex colour code' }
}

export const validateURLAndHasProtocol = (value: string): { message: string, value: string | undefined } => {
  const errorMsg = 'Invalid URL. URLs should start with http or https and be formatted correctly.'
  try {
    const url = new URL(value)
    if (url.protocol === 'http:' || url.protocol === 'https:') {
      return { value, message: '' }
    }
  } catch (e) {
    return { value: undefined, message: errorMsg }
  }
  return { value: undefined, message: errorMsg }
}

export const validateLooksLikeDate = (value: string): { message: string, value: string | undefined } => {
  const isValid = value.match(/^\d{2}\/\d{2}\/\d{2}$/)
  return isValid ? { value, message: '' } : { value: undefined, message: 'Date must be in the format DD/MM/YY' }
}
