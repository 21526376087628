import React, { type Dispatch, type SetStateAction } from 'react'
import { type PropertySurvey, type SurveyDesign, type SurveyImage } from '../../../code/models/property'
import { type FileWrapper } from '../file_wrapper'
import { SurveySectionFlowWrapper } from './components/survey_section_flow_wrapper'
import type { PagesFlowPage } from '../../../code/use_pages_flow'
import { HPLProposedHeatPumpBlock } from './heat_pump_location/hpl_proposed_heat_pump_block'
import { HPLProposedLocationPhotosBlock } from './heat_pump_location/hpl_proposed_location_photos_block'
import { HPLProposedLocationDetailsBlock } from './heat_pump_location/hpl_proposed_location_details_block'
import { HPLInstallationDetailsBlock } from './heat_pump_location/hpl_installation_details_block'
import { HPLPlanningConsiderationsBlock } from './heat_pump_location/hpl_planning_considerations_block'
import { HPLDrainOrDownpipePhotosBlock } from './heat_pump_location/hpl_drain_or_downpipe_photos_block'
import { HPLSoundAssessmentBlock } from './heat_pump_location/hpl_sound_assessment_block'
import { getHeatPumpChecks } from './validations'
import { type Lead } from '../../../code/models/lead'
import { type SetIndexedDb } from '../../admin/job_layout/job_layout'
import { type InventoryHeatPump } from '../../../code/models/inventory'

type HeatPumpLocationProps = {
  survey: PropertySurvey
  design: SurveyDesign
  setSurvey: SetIndexedDb<PropertySurvey>
  setDesign: (design: SurveyDesign) => void
  currentHeatPump?: InventoryHeatPump
  files: FileWrapper[]
  setFiles: Dispatch<SetStateAction<FileWrapper[]>>
  locationImages: SurveyImage[]
  setLocationImages: (locationImages: SurveyImage[]) => void
  soundCalculation: number
  heatPumps: InventoryHeatPump[]
  designTempC: number
  flowTempC: number
  companyUUID: string
  lead: Lead
  isOffline: boolean
  allSynced: boolean
}

export const SurveyHeatPumpLocationContext = React.createContext<HeatPumpLocationProps | undefined>(undefined)

export const HeatPumpLocation = (props: HeatPumpLocationProps) => {
  // WARNING! Never change flagBits for the pages because they are stored in the DB.
  // instead, add new pages or replace existing with new flags
  const pages = [
    { flagBit: 0x001, pbValue: 10, page: <HPLProposedHeatPumpBlock /> },
    { flagBit: 0x002, pbValue: 20, page: <HPLProposedLocationPhotosBlock /> },
    { flagBit: 0x004, pbValue: 40, page: <HPLProposedLocationDetailsBlock /> },
    { flagBit: 0x008, pbValue: 50, page: <HPLInstallationDetailsBlock /> },
    { flagBit: 0x010, pbValue: 60, page: <HPLDrainOrDownpipePhotosBlock /> },
    { flagBit: 0x020, pbValue: 80, page: <HPLPlanningConsiderationsBlock /> },
    { flagBit: 0x040, pbValue: 100, page: <HPLSoundAssessmentBlock /> }
  ] as PagesFlowPage[]

  return <SurveyHeatPumpLocationContext.Provider value={props}>
    <SurveySectionFlowWrapper
      isOffline={props.isOffline}
      allSynced={props.allSynced}
      completeSectionID={'HEAT_PUMP'}
      sectionUIName={'Heat pump location'}
      pages={pages}
      flags_attribute_name={'flags_hpl_pages_completed'}
      onPageComplete={(flagBit) => {
        props.setSurvey({ ...props.survey, flags_hpl_pages_completed: props.survey.flags_hpl_pages_completed | flagBit })
      }}
      onFormComplete={() => window.history.back()}
      survey={props.survey}
      setSurvey={props.setSurvey}
      completionChecks={getHeatPumpChecks(props.survey, props.currentHeatPump)}
    />
  </SurveyHeatPumpLocationContext.Provider>
}
