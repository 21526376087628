import React, { useEffect, useState } from 'react'
import { createSurveyInvitation, revokeInvitation, type Invitation } from '../../../code/models/invitations'
import { validateEmail } from '../../../code/validators'
import _ from 'lodash'
import { Button } from '../../../components/buttons/button'
import { Section } from '../../../components/containers/section'
import { Table } from '../../../components/content_display/table'
import { Badge } from '../../../components/indicators_and_messaging/badge'
import { Input } from '../../../components/inputs_and_selections/input'
import { type AdminContextType } from '../admin_layout'
import { type CompanyPublicInfo } from '../../../code/models/company'
import { Slash } from 'lucide-react'

type Props = {
  title: string
  helpText?: string
  adminContext: AdminContextType
  companyPublicInfo: CompanyPublicInfo
  leadUUID: string
}

export const Invitations = ({ title, helpText, adminContext, leadUUID, companyPublicInfo }: Props) => {
  const [invitationEmail, setInvitationEmail] = useState<string>('')
  const [invitations, setInvitations] = useState<Invitation[]>([])

  const sendInvitation = async () => {
    try {
      await sendInvitationInner(invitationEmail)
      setInvitationEmail('')
    } catch (e) {
      console.error('Error sending invitation', e)
    }
  }

  const revokeSurveyInvitation = async (uuid: string) => {
    await revokeSurveyInvitationInner(uuid)
  }

  useEffect(() => {
    setInvitations(adminContext.data?.user?.invitations?.sent.filter(x => x.entity_type === 'survey' && x.entity_uuid === leadUUID) ?? [])
  }, [adminContext.data?.user?.invitations?.sent])

  const sendInvitationInner = async (email: string) => {
    if (!email) return
    const invitation = await createSurveyInvitation({
      email,
      companyUuid: companyPublicInfo.uuid,
      surveyUuid: leadUUID
    })
    if (invitation) {
      setInvitations([...invitations, invitation])
    }
  }

  const revokeSurveyInvitationInner = async (uuid: string) => {
    const success = await revokeInvitation(uuid)
    if (success) {
      setInvitations(invitations.filter(x => x.uuid !== uuid))
    }
  }

  return (
    <Section border={true} title={title}>
      <div className="flex flex-col gap-4">
        {helpText && <p className="text-sm text-gray-500">{helpText}</p>}
        <div className='flex flex-col sm:flex-row gap-4 sm:items-start sm:justify-between'>
          <Input
            className='flex-1'
            value={invitationEmail}
            setValue={setInvitationEmail}
            placeholder='Email address'
            validator={validateEmail}
            doNotValidateWhenEmpty={true}
          />
          <Button onClick={sendInvitation} disabled={!validateEmail(invitationEmail).value}>Send invitation</Button>
        </div>
        {/* List of existing invitations */}
        {invitations.length > 0 &&
        <Table
          columns={[
            { name: 'Email', key: 'email' },
            { name: 'Status', render: (row: Invitation) => <Badge color={row.status === 'accepted' ? 'GREEN' : 'YELLOW'} text={_.capitalize(row.status)} /> },
            { name: 'Actions', render: (row: Invitation) => <Button size="SM" colour="LIGHT" onClick={async () => await revokeSurveyInvitation(row.uuid)} iconLeft={Slash}>Revoke</Button> }
          ]}
          rows={invitations}
        />
        }
      </div>
    </Section>
  )
}
