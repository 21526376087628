import React, { useCallback, useContext, useEffect, useState } from 'react'
import { Button } from '../../../../components/buttons/button'
import { Section } from '../components/section'
import { HorizontalFormGroup } from '../../../../components/inputs_and_selections/horizontal_form_group'
import { FormLabel } from '../../../../components/inputs_and_selections/form_label'
import { Toggle } from '../../../../components/inputs_and_selections/toggle'
import { downloadBlob, isFlagSet } from '../../../../code/helpers'
import {
  createQuoteSnapshotAPI,
  getDraftProposalAPI,
  getProposalPreviewPDFAPI,
  getQuotePreviewPDFAPI,
  type Proposal,
  type ProposalQuoteItem,
  type ProposalSnapshot,
  type QuoteSnapshotData,
  sendProposalAPI,
  sendQuoteAPI,
  updateProposalAPI
} from '../../../../code/models/proposal'
import {
  type CompanyPublicInfo,
  getCompanyProposalCoverNoteTemplate,
  getCompanyProposalEmailTemplate,
  getCompanyQuoteEmailTemplate
} from '../../../../code/models/company'
import { type Lead } from '../../../../code/models/lead'
import {
  HEATLOSS_REPORT_SECTION_HEATLOSS_INTRO,
  HEATLOSS_REPORT_SECTION_HEATLOSS_PER_ROOM,
  type HeatLossReportSnapshot
} from '../../../../code/models/heatloss_report'
import { Tiptap } from '../../../../components/inputs_and_selections/tiptap'
import {
  renderHTMLReplacingPlaceholders,
  renderJSONReplacingPlaceholders,
  TTPlaceholderCompanyName,
  TTPlaceholderCompanyPhone,
  TTPlaceholderCustomerAddress,
  TTPlaceholderCustomerName,
  TTPlaceholderEstimateContactName,
  TTPlaceholderEstimateContactPhone,
  TTPlaceholderViewProposal,
  TTPlaceholderViewQuote
} from '../../../../code/tiptap_placeholders'
import type { JSONContent } from '@tiptap/react'
import { AdminContext } from '../../admin_layout'
import { Modal } from '../../../../components/containers/modal'
import { Input } from '../../../../components/inputs_and_selections/input'
import { validateIsNumber } from '../../../../code/validators'
import { ClickableCard } from '../../../../components/content_display/card'
import { Badge } from '../../../../components/indicators_and_messaging/badge'
import { Text } from '../../../../components/content_display/text'
import { groupBy, sum } from 'lodash'
import { QuoteBuilder } from './quote_builder'
import { type InventoryHeatPump, type InventoryHotWaterCylinder } from '../../../../code/models/inventory'
import { type HydronicEmitterWithRoomAndWatts } from '../../../../code/models/pipes'
import { CalculatedQuoteDefaultGroups, calculateQuote, QuoteOverrideType } from '../../../../code/calculate_quote'
import { checkIfScottish } from '../../../../code/models/address'
import { useDebounceCallback } from 'usehooks-ts'
import { HeatPump } from '../../../../assets/images/survey_images/survey_images'
import { formatPrice } from '../../../../code/format_price'
import { contextSensitiveFormatDate } from '../../../../code/format_date'
import { FullscreenPreviewModal } from '../../../heat_loss/proposal/partials/fullscreen_preview_modal'
import { SendEmailWithTemplateModal } from '../../../heat_loss/proposal/partials/send_email_with_template_modal'
import { PaymentSchedule } from './payment_schedule'
import { type PropertySurvey } from '../../../../code/models/property'
import { type FileWrapper } from '../../../heat_loss/file_wrapper'
import { ProposalFiles } from './proposal_files'
import { QuotePublicPageInner } from '../../../quote_public_page'
import { ProposalPublicPageInner } from '../../../proposal_public_page'
import { Loader } from '../../../../components/indicators_and_messaging/loader'
import { postUserEvent, UserEventType } from '../../../../code/models/user'
import { Edit, FileText } from 'lucide-react'

type Props = {
  currentPathWithoutBase: string
  currentHeatPump?: InventoryHeatPump
  currentHotWaterCylinder?: InventoryHotWaterCylinder
  emitters: HydronicEmitterWithRoomAndWatts[]
  lead: Lead
  survey: PropertySurvey
  files: FileWrapper[]
  setLead: (l: Lead) => void
  companyPublicInfo: CompanyPublicInfo
  navigateTo: (path: string) => void
}

export const calculateQuoteSnapshot = (calculatedQuote: ProposalQuoteItem[], proposal?: Proposal): QuoteSnapshotData => {
  const calculatedQuoteWithoutVATOnSurvey = calculatedQuote.map(x => {
    if (x.group_name === CalculatedQuoteDefaultGroups.SURVEY) {
      return {
        ...x,
        subtotal: (x.subtotal || 0) / 1.2
      }
    }
    return x
  })

  const customerFacingCalculatedQuote = calculatedQuoteWithoutVATOnSurvey.map(x => {
    return {
      uuid: x.uuid!,
      name: x.name,
      description: x.description || undefined,
      quantity: x.quantity,
      group_name: x.group_name,
      selected: x.selected || true,
      image_url: x.image_url || undefined,
      subtotal: x.group_name === 'Optional items' ? x.subtotal : undefined
    }
  })

  const calculateVATOnSurvey = (quote: ProposalQuoteItem[]) => {
    if (proposal?.include_vat_on_survey) {
      return sum(quote.filter(x => x.selected && x.group_name === CalculatedQuoteDefaultGroups.SURVEY).map(x => x.subtotal)) * 0.2
    }
    return 0
  }

  const calculateVATOnAll = (quote: ProposalQuoteItem[]) => {
    if (proposal?.include_vat_on_all) {
      const totalPreDeductions = sum(quote.filter(x => x.selected && x.group_name !== CalculatedQuoteDefaultGroups.GRANTS && x.group_name !== 'Other deductions').map(x => x.subtotal))
      const totalOtherDeductions = sum(quote.filter(x => x.selected && x.group_name === 'Other deductions').map(x => x.subtotal))
      // VAT calculated on the total cost, pre-discount, minus other deductions
      return (totalPreDeductions - totalOtherDeductions) * 0.2
    }
    return 0
  }

  const groupedQuote = groupBy(calculatedQuoteWithoutVATOnSurvey, 'group_name')
  const totalPreDeductions = sum(calculatedQuoteWithoutVATOnSurvey.filter(x => x.selected && x.group_name !== CalculatedQuoteDefaultGroups.GRANTS && x.group_name !== 'Other deductions').map(x => x.subtotal))
  const otherDeductionsTotal = sum(calculatedQuoteWithoutVATOnSurvey.filter(x => x.selected && x.group_name === 'Other deductions').map(x => x.selected ? x.subtotal : 0))
  return {
    quote_items: customerFacingCalculatedQuote,
    total_pre_deductions: totalPreDeductions,
    discount_total: otherDeductionsTotal,
    vat_on_all: calculateVATOnAll(calculatedQuoteWithoutVATOnSurvey),
    vat_on_survey: calculateVATOnSurvey(calculatedQuoteWithoutVATOnSurvey),
    bus_grant: sum(calculatedQuoteWithoutVATOnSurvey.filter(x => x.selected && x.group_name === CalculatedQuoteDefaultGroups.GRANTS).map(x => x.subtotal)),
    group_totals: proposal?.show_cost ? Object.entries(groupedQuote).reduce((acc, [key, value]) => {
      acc[key] = value.reduce((sum, item) => item.selected ? sum + (item.subtotal || 0) : sum, 0)
      return acc
    }, {}) : {},
    payment_schedule: proposal?.payment_schedule || undefined,
    additional_notes: proposal?.additional_notes || undefined
  }
}

export const ProposalConfigurator = ({
  currentPathWithoutBase,
  currentHeatPump,
  currentHotWaterCylinder,
  emitters: designedEmitters,
  lead,
  survey,
  files,
  setLead,
  companyPublicInfo,
  navigateTo
}: Props) => {
  const adminContext = useContext(AdminContext)

  const [proposal, setProposal] = useState<Proposal>()
  const [coverNoteText, setCoverNoteText] = React.useState<JSONContent>()

  // settings popup
  const [showSettingsPopup, setShowSettingsPopup] = useState(false)
  const [proposalEditingSettings, setProposalEditingSettings] = useState<Proposal | undefined>(undefined)

  // all email content should be rendered and all tiptap has almost the same placeholders
  // worth to define them here once for all editors and renderers
  const tiptapRenderMappings = {
    [TTPlaceholderCustomerName.id]: lead.customer?.name || '',
    [TTPlaceholderCustomerAddress.id]: lead.property.address + ', ' + lead.property.postcode,
    [TTPlaceholderEstimateContactName.id]: companyPublicInfo.estimate_contact_name || '',
    [TTPlaceholderEstimateContactPhone.id]: companyPublicInfo.estimate_contact_phone || '',
    [TTPlaceholderCompanyName.id]: adminContext.data.company!.public_info?.name,
    [TTPlaceholderCompanyPhone.id]: adminContext.data.company!.public_info?.phone
  }

  // Proposal modal windows
  const [showProposalPreviewWindow, setShowProposalPreviewWindow] = React.useState(false)
  const [showProposalSendingModal, setShowProposalSendingModal] = React.useState(false)
  const [proposalEmailText, setProposalEmailText] = React.useState<JSONContent>(
    renderJSONReplacingPlaceholders(
      getCompanyProposalEmailTemplate(adminContext.data.company!.proposal_email_template || ''),
      tiptapRenderMappings
    )
  )

  // Quote modal windows
  const [showQuotePreviewWindow, setShowQuotePreviewWindow] = React.useState(false)
  const [showQuoteSendingModal, setShowQuoteSendingModal] = React.useState(false)
  const [quoteEmailText, setQuoteEmailText] = React.useState<JSONContent>(
    renderJSONReplacingPlaceholders(
      getCompanyQuoteEmailTemplate(adminContext.data.company!.quote_email_template || ''),
      tiptapRenderMappings
    )
  )

  const [pdfGenerating, setPdfGenerating] = useState(false)

  const [calculatedQuote, quoteOverrideType] = calculateQuote({
    company: adminContext.data.company!,
    selectedHeatPump: currentHeatPump,
    selectedHotWaterCylinder: currentHotWaterCylinder,
    selectedEmitters: designedEmitters,
    parts: adminContext.data.parts!,
    labour: adminContext.data.labour!,
    packs: adminContext.data.packs!,
    isScottish: checkIfScottish(lead.property.postcode),
    override: proposal?.quote_items,
    // We can copy across any custom added parts from the estimate
    additionalItemsFromEarlierStages: lead.estimate_quote_items?.filter(x => x.selected && x.group_name === CalculatedQuoteDefaultGroups.PARTS)
  }) as [ProposalQuoteItem[], QuoteOverrideType]

  const debounceUpdateProposal = useDebounceCallback(async (proposal: Proposal) => {
    try {
      await updateProposalAPI(proposal, lead.uuid!, companyPublicInfo.uuid)
    } catch (e: unknown) {
      console.error('Error updating proposal', e)
    }
  }, 1000)

  const setAndUpdateProposal = useCallback((proposal: Proposal) => {
    setProposal(proposal)
    debounceUpdateProposal(proposal)
  }, [])

  useEffect(() => {
    const fetchProposal = async () => {
      const proposal = await getDraftProposalAPI(lead.uuid!, companyPublicInfo.uuid)
      if (!proposal || !adminContext) return

      // hydrate proposal
      proposal.cover_note = proposal.cover_note
        ? proposal.cover_note
        : JSON.stringify(getCompanyProposalCoverNoteTemplate(adminContext.data.company!.proposal_cover_note_template || ''))

      setCoverNoteText(JSON.parse(proposal.cover_note))
      setAndUpdateProposal(proposal)
    }
    fetchProposal()
  }, [lead.uuid, companyPublicInfo.uuid])

  const handleProposalSend = async (recipients: string[]) => {
    try {
      const proposalSentState = {
        ...proposal!,
        // Necessary as the ... is a shallow copy and we need to copy the array
        links: proposal?.links || [],
        snapshot: {
          report: calculateReportSnapshot(),
          quote: calculateQuoteSnapshot(calculatedQuote, proposal)
        } satisfies ProposalSnapshot,
        recipients,
        email_text: JSON.stringify(proposalEmailText)
      } satisfies Proposal

      // save snapshot details and email config datas
      await updateProposalAPI(proposalSentState, lead.uuid!, companyPublicInfo.uuid)

      // send proposal. Back-end also will mark it as sent
      await sendProposalAPI(lead.uuid!, companyPublicInfo.uuid, proposalSentState.uuid, renderProposalEmailHTML(proposalEmailText, proposalSentState.uuid))

      // Update the lead status to 'proposal sent'
      setLead({ ...lead, status: 'ProposalSent' })

      // get a new draft proposal from the back-end (also clones the current proposal's quote items and links)
      const draftProposal = await getDraftProposalAPI(lead.uuid!, companyPublicInfo.uuid)
      if (!draftProposal) return

      // copy proposal data over the draft proposal, except the uuid
      const newProposal = { ...draftProposal, ...proposal, uuid: draftProposal.uuid }

      // update the proposal state to new draft proposal
      setAndUpdateProposal(newProposal)

      // We only post the user event if the email was successfully sent, because it's connected to billing
      postUserEvent({
        event_type: UserEventType.GeneratedProposal,
        company_uuid: adminContext.data.company!.public_info.uuid,
        extra_data: {
          survey_uuid: survey.uuid
        }
      })
    } catch (e: unknown) {
      console.error('Error sending proposal', e)
    }
  }

  const handleDownloadProposalPreviewPDF = async () => {
    try {
      setPdfGenerating(true)

      // save snapshot details — it's required for the PDF
      const proposalSentState = {
        ...proposal!,
        snapshot: {
          report: calculateReportSnapshot(),
          quote: calculateQuoteSnapshot(calculatedQuote, proposal)
        } satisfies ProposalSnapshot
      } satisfies Proposal

      // save snapshot details — and wait until finished — it's necessary for the PDF generating
      await updateProposalAPI(proposalSentState, lead.uuid!, companyPublicInfo.uuid)

      const pdfData = await getProposalPreviewPDFAPI(proposal!.uuid, companyPublicInfo.uuid)
      downloadBlob(pdfData!, lead.property.address + ' — Full Proposal.pdf')

      // We only post the user event if the PDF was successfully generated, because it's connected to billing
      postUserEvent({
        event_type: UserEventType.GeneratedProposal,
        company_uuid: adminContext.data.company!.public_info.uuid,
        extra_data: {
          survey_uuid: survey.uuid
        }
      })
    } catch (e: unknown) {
      console.error('Error generating PDF', e)
    } finally {
      setPdfGenerating(false)
    }
  }

  const handleDownloadQuotePreviewPDF = async () => {
    try {
      setPdfGenerating(true)

      const quoteSnapshotResponse = await createQuoteSnapshotAPI(lead.uuid!, companyPublicInfo.uuid, {
        snapshot: calculateQuoteSnapshot(calculatedQuote, proposal)
      })

      const pdfData = await getQuotePreviewPDFAPI(quoteSnapshotResponse.uuid, companyPublicInfo.uuid)
      downloadBlob(pdfData!, lead.property.address + ' — Quote.pdf')

      // We only post the user event if the PDF was successfully generated, because it's connected to billing
      postUserEvent({
        event_type: UserEventType.GeneratedQuote,
        company_uuid: adminContext.data.company!.public_info.uuid,
        extra_data: {
          survey_uuid: survey.uuid
        }
      })
    } catch (e: unknown) {
      console.error('Error generating PDF', e)
    } finally {
      setPdfGenerating(false)
    }
  }

  const calculateReportSnapshot = (): HeatLossReportSnapshot => {
    return {
      customer: lead.customer!,
      property: lead.property,
      survey,
      files,
      currentHeatPump: currentHeatPump!,
      currentHotWaterCylinder: currentHotWaterCylinder!
    } satisfies HeatLossReportSnapshot
  }

  const handleQuoteSend = async (recipients: string[]) => {
    try {
      const quoteSnapshotResponse = await createQuoteSnapshotAPI(lead.uuid!, companyPublicInfo.uuid, {
        snapshot: calculateQuoteSnapshot(calculatedQuote, proposal),
        recipients,
        email_text: JSON.stringify(quoteEmailText)
      })

      if (!quoteSnapshotResponse) {
        alert('Failed to create report')
      }

      await sendQuoteAPI(lead.uuid!, companyPublicInfo.uuid, quoteSnapshotResponse.uuid, renderQuoteEmailHTML(quoteEmailText, quoteSnapshotResponse.uuid))

      // We only post the user event if the email was successfully sent, because it's connected to billing
      postUserEvent({
        event_type: UserEventType.GeneratedQuote,
        company_uuid: adminContext.data.company!.public_info.uuid,
        extra_data: {
          survey_uuid: survey.uuid
        }
      })
    } catch (e: unknown) {
      console.error('Error sending quote', e)
    }
  }

  const renderProposalEmailHTML = (emailTextJSON: JSONContent, proposalUUID: string) => {
    return renderHTMLReplacingPlaceholders(emailTextJSON, {
      ...tiptapRenderMappings,
      [TTPlaceholderViewProposal.id]: process.env.BASE_URL! + adminContext.data.company!.public_info.subdomain + '/proposal/' + proposalUUID
    })
  }

  const renderQuoteEmailHTML = (emailTextJSON: JSONContent, quoteUUID: string) => {
    return renderHTMLReplacingPlaceholders(emailTextJSON, {
      ...tiptapRenderMappings,
      [TTPlaceholderViewQuote.id]: process.env.BASE_URL! + adminContext.data.company!.public_info.subdomain + '/quote/' + quoteUUID
    })
  }

  if (!proposal) return null

  const quoteHeatPumpName = calculatedQuote.find(x => x.group_name === CalculatedQuoteDefaultGroups.HEAT_PUMPS)?.name
  const quoteImage = calculatedQuote.find(x => x.group_name === CalculatedQuoteDefaultGroups.HEAT_PUMPS)?.image_url
  const quoteTotal = sum(calculatedQuote.map(x => x.selected ? x.subtotal : 0))
  const quoteLastEdited = quoteOverrideType === QuoteOverrideType.FULL ? proposal.quote_items.sort((a, b) => new Date(b.updated_at).getTime() - new Date(a.updated_at).getTime())[0].updated_at : undefined

  const ROUTES = [
    {
      path: /proposal\/quote/,
      component: () => <QuoteBuilder
        calculatedQuote={calculatedQuote}
        quoteOverrideType={quoteOverrideType}
        currentHeatPump={currentHeatPump}
        currentHotWaterCylinder={currentHotWaterCylinder}
        emitters={designedEmitters}
        navigateTo={navigateTo}
        setProposal={setProposal}
        setAndUpdateProposal={setAndUpdateProposal}
        proposal={proposal}
        companyPublicInfo={companyPublicInfo}
      />
    }
  ]

  const page = ROUTES.find(x => x.path.test(currentPathWithoutBase))
  if (page) return page.component()

  return <>
    <SendEmailWithTemplateModal
      visible={showProposalSendingModal}
      setVisible={setShowProposalSendingModal}
      handleSend={handleProposalSend}
      emailRecipients={!lead.proposal_recipients ? (lead.customer?.email ?? '') : lead.proposal_recipients}
      setEmailRecipients={(e: string) => {
        setLead({
          ...lead,
          proposal_recipients: e
        })
      }}
      emailText={proposalEmailText}
      setEmailText={setProposalEmailText}
      editorPlaceholders={[
        TTPlaceholderViewProposal
      ]}
    />

    <SendEmailWithTemplateModal
      visible={showQuoteSendingModal}
      setVisible={setShowQuoteSendingModal}
      handleSend={handleQuoteSend}
      emailRecipients={!lead.quote_recipients ? (lead.customer?.email ?? '') : lead.quote_recipients}
      setEmailRecipients={(e: string) => {
        setLead({
          ...lead,
          quote_recipients: e
        })
      }}
      emailText={quoteEmailText}
      setEmailText={setQuoteEmailText}
      editorPlaceholders={[
        TTPlaceholderViewQuote
      ]}
    />

    <FullscreenPreviewModal
      title={'Proposal preview'}
      visible={showProposalPreviewWindow}
      setVisible={setShowProposalPreviewWindow}
      previewContent={
        <ProposalPublicPageInner
          proposal={proposal}
          quoteSnapshot={calculateQuoteSnapshot(calculatedQuote, proposal)}
          reportSnapshot={calculateReportSnapshot()}
          companyPublicInfo={companyPublicInfo}
          previewMode
        />
      }
      sidebarTitle={'Send & Save to PDF'}
      sidebarContent={<div className={'flex flex-col gap-6 w-full'}>
        <ProposalSettingsBlock
          proposalEditingSettings={proposal}
          setProposalEditingSettings={setAndUpdateProposal}
          calculatedQuote={calculatedQuote}
        />
        <div className='h-[1px] border-b border-gray-200'></div>
        <Button className='w-full' onClick={() => setShowProposalSendingModal(true)}>Send via Email</Button>
        <div className='flex flex-row gap-0 items-center w-full justify-between'>
          <div className='h-[1px] border-b border-dashed border-gray-200 w-2/5'></div>
          <div className='text-center text-gray-500 text-xs w-1/5 '>or</div>
          <div className='h-[1px] border-b border-dashed border-gray-200 w-2/5'></div>
        </div>
        {!pdfGenerating && <Button
          iconLeft={FileText}
          onClick={handleDownloadProposalPreviewPDF}
          colour='LIGHT'
          className='w-full'
          disabled={pdfGenerating}
        >Save to PDF</Button>}
        { pdfGenerating && <div className='text-center text-gray-500 text-xs flex flex-col gap-2'>
          <Loader/>
          <span>Generating PDF</span>
        </div>
        }
      </div>}
    />

    <FullscreenPreviewModal
      title={'Quote preview'}
      visible={showQuotePreviewWindow}
      setVisible={setShowQuotePreviewWindow}
      previewContent={
        <QuotePublicPageInner snapshot={calculateQuoteSnapshot(calculatedQuote, proposal)} companyPublicInfo={companyPublicInfo}/>
      }
      sidebarTitle={'Send & Save to PDF'}
      sidebarContent={<div className={'flex flex-col gap-6 w-full'}>
        <ProposalSettingsBlock
          proposalEditingSettings={proposal}
          setProposalEditingSettings={setAndUpdateProposal}
          calculatedQuote={calculatedQuote}
        />
        <div className='h-[1px] border-b border-gray-200'></div>
        <Button className='w-full' onClick={() => setShowQuoteSendingModal(true)}>Send via Email</Button>
        <div className='flex flex-row gap-0 items-center w-full justify-between'>
          <div className='h-[1px] border-b border-dashed border-gray-200 w-2/5'></div>
          <div className='text-center text-gray-500 text-xs w-1/5 '>or</div>
          <div className='h-[1px] border-b border-dashed border-gray-200 w-2/5'></div>
        </div>
        {!pdfGenerating && <Button
          iconLeft={FileText}
          onClick={handleDownloadQuotePreviewPDF}
          colour='LIGHT'
          className='w-full'
          disabled={pdfGenerating}
        >Save to PDF</Button>}
        { pdfGenerating && <div className='text-center text-gray-500 text-xs flex flex-col gap-2'>
          <Loader/>
          <span>Generating PDF</span>
        </div>
        }
      </div>}
    />

    {showSettingsPopup && <Modal
      visible={showSettingsPopup}
      setVisible={(v) => {
        setShowSettingsPopup(v)
        if (!v) setProposalEditingSettings(undefined)
      }}
      title='Proposal settings'
      onConfirm={async () => {
        setAndUpdateProposal(proposalEditingSettings!)
        setProposalEditingSettings(undefined)
      }}
      confirmButtonLabel={'Apply'}
      confirmDisabled={false}
      hideOnConfirm={true}
    >
      <ProposalSettingsBlock
        proposalEditingSettings={proposalEditingSettings!}
        setProposalEditingSettings={setProposalEditingSettings}
        calculatedQuote={calculatedQuote}
      />
    </Modal>}

    <div className='flex flex-col gap-6'>
      <div className='flex flex-row justify-between items-center'>
        <div className="text-gray-900 text-xl font-bold">Configure proposal</div>
        <div className='flex flex-row gap-4'>
          <Button onClick={() => {
            setProposalEditingSettings(proposal)
            setShowSettingsPopup(true)
          }} colour='LIGHT'>Settings</Button>
          <Button onClick={() => setShowProposalPreviewWindow(true)} colour='DARK'>Preview and Send</Button>
        </div>
      </div>

      <Section title="Cover note">
        <Tiptap
          editable={true}
          className='w-full rounded border border-gray-300 p-2 focus:outline-none'
          onUpdateCallback={async (editor) => {
            setCoverNoteText(editor.getJSON())
            setAndUpdateProposal({
              ...proposal,
              // the proposal.cover_note is NULL until an installer makes any changes.
              // So this is the reason we're storing the cover note in a state variable
              // Once it's saved, we can update the proposal.cover_note.
              cover_note: JSON.stringify(editor.getJSON())
            })
          }}
          content={renderJSONReplacingPlaceholders(coverNoteText!, tiptapRenderMappings)}
        />
      </Section>

      <Section title="Quote"
        controls={<Button onClick={() => setShowQuotePreviewWindow(true)}>Preview and send</Button>}>
        <ClickableCard onClick={() => navigateTo('/proposal/quote')} variant="LIGHT" border>
          <div className='flex justify-between items-center'>
            <div className="grid grid-cols-[56px_1fr] gap-2">
              {/* Image or fallback icon */}
              <img
                src={quoteImage || HeatPump}
                alt={quoteHeatPumpName}
                className='w-12 h-12 object-contain'
                onError={({ currentTarget }) => {
                  currentTarget.onerror = null
                  currentTarget.src = HeatPump
                }}
              />
              <div className='flex flex-col gap-1'>
                <Text size='SM' bold>{quoteHeatPumpName}</Text>
                <Text size="SM" className='text-gray-500'>{formatPrice(quoteTotal, 0)}</Text>
              </div>
            </div>
            <Button colour="TRANSPARENT" iconLeft={Edit}>Edit</Button>
          </div>
        </ClickableCard>
        <div className='flex justify-end'>
          {(quoteOverrideType === QuoteOverrideType.FULL && quoteLastEdited) ? <Badge color='LIGHT'
            text={`Last edited ${contextSensitiveFormatDate(quoteLastEdited)}`}/> : null}
        </div>
      </Section>

      <Section title="Contents">
        <HorizontalFormGroup
          formLabel={<FormLabel
            labelText={'Heat loss report'}
            helperText={'Detailed heat loss report with floor-plans and heat loss by element and room.'}
          />}
          input={<></>}
        />
        <HorizontalFormGroup
          className='border-l-gray-300 border-l-4 pl-4'
          formLabel={<FormLabel
            labelText={'Introduction'}
            helperText={"A description of what heat loss is and why it's important."}
          />}
          input={<Toggle
            value={isFlagSet(proposal?.contents_bitmask, HEATLOSS_REPORT_SECTION_HEATLOSS_INTRO)}
            setValue={() => setAndUpdateProposal({
              ...proposal,
              contents_bitmask: proposal?.contents_bitmask ^ HEATLOSS_REPORT_SECTION_HEATLOSS_INTRO
            })}
          />}
        />
        <HorizontalFormGroup
          className='border-l-gray-300 border-l-4 pl-4'
          formLabel={<FormLabel
            labelText={'Detailed page per room'}
            helperText={'Include a heat loss page for each room showing full details of all the inputs used and the results.'}
          />}
          input={<Toggle
            value={isFlagSet(proposal?.contents_bitmask, HEATLOSS_REPORT_SECTION_HEATLOSS_PER_ROOM)}
            setValue={() => setAndUpdateProposal({
              ...proposal,
              contents_bitmask: proposal?.contents_bitmask ^ HEATLOSS_REPORT_SECTION_HEATLOSS_PER_ROOM
            })}
          />}
        />
        <HorizontalFormGroup
          formLabel={<FormLabel
            labelText={'System design'}
            helperText={'Details of the proposed heat pump, emitter replacement, proposed cylinder and associated hot water calcs.'}
          />}
          input={<></>}
        />
        <HorizontalFormGroup
          formLabel={<FormLabel
            labelText={'Sound assessment'}
            helperText={'Results of the sound assessment along with the inputs and calculations behind the assessment.'}
          />}
          input={<></>}
        />
        <HorizontalFormGroup
          formLabel={<FormLabel
            labelText={'Performance estimate'}
            helperText={'An estimate of system performance covering energy, bills and carbon, plus the assumptions behind the numbers.'}
          />}
          input={<></>}
        />
      </Section>

      {/* Files */}
      <ProposalFiles
        proposal={proposal}
        setProposal={setProposal}
        companyPublicInfo={companyPublicInfo}
      />
    </div>
  </>
}

type ProposalSettingsBlockProps = {
  proposalEditingSettings: Proposal
  setProposalEditingSettings: (proposal: Proposal) => void
  calculatedQuote: ProposalQuoteItem[]
}

const ProposalSettingsBlock = ({ proposalEditingSettings, setProposalEditingSettings, calculatedQuote }: ProposalSettingsBlockProps) => {
  return <div className='w-full flex flex-col gap-6'>
    <div className='flex w-full flex-row justify-between'>
      <FormLabel
        labelText='Show cost for each line item'
        helperText='Show the cost alongside the line item'
      />
      <Toggle value={proposalEditingSettings.show_cost} setValue={() => setProposalEditingSettings({
        ...proposalEditingSettings,
        show_cost: !proposalEditingSettings.show_cost
      })}/>
    </div>
    <div className='w-full flex flex-row justify-between'>
      <FormLabel
        labelText='Include VAT on all'
      />
      <Toggle value={proposalEditingSettings.include_vat_on_all} setValue={() => setProposalEditingSettings({
        ...proposalEditingSettings,
        include_vat_on_all: !proposalEditingSettings.include_vat_on_all,
        include_vat_on_survey: !proposalEditingSettings.include_vat_on_all ? false : proposalEditingSettings.include_vat_on_survey
      })}
      />
    </div>
    <div className='w-full flex flex-row justify-between'>
      <FormLabel
        labelText='Include VAT on survey only'
      />
      <Toggle value={proposalEditingSettings.include_vat_on_survey} setValue={() => setProposalEditingSettings({
        ...proposalEditingSettings,
        include_vat_on_survey: !proposalEditingSettings.include_vat_on_survey,
        include_vat_on_all: !proposalEditingSettings.include_vat_on_survey ? false : proposalEditingSettings.include_vat_on_all
      })}
      />
    </div>
    <Input
      className={'w-full'}
      value={proposalEditingSettings.valid_days || ''}
      setValue={(e) => setProposalEditingSettings({ ...proposalEditingSettings, valid_days: parseInt(e) })}
      type='number'
      label='Valid for'
      validator={validateIsNumber}
      postfix={'days'}
    />
    <PaymentSchedule
      proposal={proposalEditingSettings}
      updateProposal={setProposalEditingSettings}
      calculatedQuote={calculatedQuote}
    />
  </div>
}
