import { type InventoryHeatPump } from '../../../code/models/inventory'
import { type PropertySurvey } from '../../../code/models/property'

export const getSiteDetailsChecks = (survey: PropertySurvey) => [
  survey.property_images.length > 0
]

export const getExistingHeatingChecks = (survey: PropertySurvey) => {
  const checks = [
    !!survey.existing_system_type_uuid && survey.existing_system_type_uuid.length > 0,
    !!survey.existing_system_radiator_pipework_uuid
  ]
  if (survey.existing_system_fuel_uuid !== 'electricity') { checks.push(!!survey.existing_system_age_uuid) }

  return checks
}

export const getHeatPumpChecks = (survey: PropertySurvey, currentHeatPump?: InventoryHeatPump) => {
  const checks = [
    !!currentHeatPump,
    survey.location_images.length > 0,
    !!survey.location_description,
    !!survey.mounting_location,
    !!survey.condensate_drain,
    !!survey.assessment_description,
    survey.sound_distance > 0,
    !!survey.reflective_surfaces,
    !!survey.sound_barrier_uuid
  ]

  if (survey.mounting_location === 'Ground') { checks.push(!!survey.base_to_be_build_by) }

  return checks
}

export const getCylinderChecks = (survey: PropertySurvey) => {
  return [
    survey.cylinder_photos.length > 0,
    survey.cylinder_location_description?.length > 0
  ]
}
