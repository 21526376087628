import React, { type Dispatch, useState } from 'react'
import { type PropertySurvey } from '../../../code/models/property'
import { SurveySectionFlowWrapper } from './components/survey_section_flow_wrapper'
import { type PagesFlowPage } from '../../../code/use_pages_flow'
import { FloorplanHeatLossSettingsBlock } from './floorplan/floorplan_heatloss_settings_block'
import {
  type Material,
  type MaterialLayer,
  updateDefaultMaterial
} from '../../../code/models/material'
import _ from 'lodash'
import type { CurrentFloorPlanPage } from '../floor/floor'
import { MaterialInputField } from '../materials/material_input_field'
import { MaterialsSelector, type MaterialsSelectorGroupedProps } from '../materials/materials_selector'
import { PageHeader } from '../design/components/design_page_header'
import { CustomMaterialPopup } from '../materials/custom_material_popup'
import { type SetIndexedDb } from '../../admin/job_layout/job_layout'

type FloorplanFlowProps = {
  survey: PropertySurvey
  setSurvey: SetIndexedDb<PropertySurvey>
  designTempDefault: number
  altitudeDefaultM: number
  materials: Material[]
  setMaterials: Dispatch<React.SetStateAction<Material[]>>
  materialsLayers: MaterialLayer[]
  companyUUID: string
  navigateTo: (url: string) => void
  isOffline: boolean
  allSynced: boolean
}

export const FloorplanFlow = (props: FloorplanFlowProps) => {
  const [selectorProps, setSelectorProps] = useState<MaterialsSelectorGroupedProps>()
  const [tempCustomMaterial, setTempCustomMaterial] = useState<Material>()
  const [customMaterialHeader, setCustomMaterialHeader] = useState<JSX.Element>()

  const selectedMaterialsSet = props.survey.default_materials

  const [innerPages, setInnerPages] = useState<Array<CurrentFloorPlanPage | 'FLOW' | 'MATERIAL_HOME' | 'CUSTOM_MATERIAL'>>(['FLOW'])
  const innerPage = innerPages[innerPages.length - 1]
  const setInnerPage = (page: CurrentFloorPlanPage | 'FLOW' | 'MATERIAL_HOME' | 'CUSTOM_MATERIAL') => setInnerPages(prev => [...prev, page])

  const handleMaterialUpdate = (material: Material) => {
    updateDefaultMaterial(props.survey, props.setSurvey, material)
  }

  // WARNING! Never change flagBits for the pages because they are stored in the DB.
  // instead, add new pages or replace existing with new flags
  const pages = [
    {
      flagBit: 0x001,
      pbValue: 25,
      page: <FloorplanHeatLossSettingsBlock
        survey={props.survey}
        setSurvey={props.setSurvey}
        designTempDefault={props.designTempDefault}
        altitudeDefaultM={props.altitudeDefaultM}
      />
    },
    {
      flagBit: 0x002,
      pbValue: 50,
      page:
        <div className="self-stretch flex-col justify-start items-start gap-5 flex">
          <div className="self-stretch text-gray-900 text-xl font-bold">Walls</div>

          {/* External wall */}
          <div className="w-full flex-col justify-start items-start gap-2 flex">
            <div className="self-stretch font-bold text-gray-900">External wall</div>
            <MaterialInputField
              selectorProps={{
                title: 'External wall',
                surfaceType: 'external-wall',
                materials: props.materials,
                ageBand: props.survey.age_band,
                selectedMaterial: selectedMaterialsSet?.externalWall,
                onSelectCallback: handleMaterialUpdate
              } satisfies MaterialsSelectorGroupedProps}
              setMsProps={setSelectorProps}
              setPage={setInnerPage}
            />
          </div>

          {/* Party wall */}
          <div className="w-full flex-col justify-start items-start gap-2 flex">
            <div className="self-stretch font-bold text-gray-900">Party wall</div>
            <MaterialInputField
              selectorProps={{
                title: 'Party wall',
                surfaceType: 'party-wall',
                materials: props.materials,
                ageBand: props.survey.age_band,
                selectedMaterial: selectedMaterialsSet?.partyWall,
                onSelectCallback: handleMaterialUpdate
              } satisfies MaterialsSelectorGroupedProps}
              setMsProps={setSelectorProps}
              setPage={setInnerPage}
            />
          </div>

          {/* Internal wall */}
          <div className="w-full flex-col justify-start items-start gap-2 flex">
            <div className="self-stretch font-bold text-gray-900">Internal wall</div>
            <MaterialInputField
              selectorProps={{
                title: 'Internal wall',
                surfaceType: 'internal-wall',
                materials: props.materials,
                ageBand: props.survey.age_band,
                selectedMaterial: selectedMaterialsSet?.internalWall,
                onSelectCallback: handleMaterialUpdate
              } satisfies MaterialsSelectorGroupedProps}
              setMsProps={setSelectorProps}
              setPage={setInnerPage}
            />
          </div>
        </div>
    },
    {
      flagBit: 0x004,
      pbValue: 75,
      page: <>
        <div className="self-stretch flex-col justify-start items-start gap-5 flex">
          <div className="self-stretch text-gray-900 text-xl font-bold">Floors</div>

          {/* Ground floor */}
          <div className="w-full flex-col justify-start items-start gap-2 flex">
            <div className="self-stretch font-bold text-gray-900">Ground floor</div>
            <MaterialInputField
              selectorProps={{
                title: 'Ground floor',
                surfaceType: 'ground-floor',
                materials: props.materials,
                ageBand: props.survey.age_band,
                selectedMaterial: selectedMaterialsSet?.groundFloor,
                onSelectCallback: handleMaterialUpdate
              } satisfies MaterialsSelectorGroupedProps}
              setMsProps={setSelectorProps}
              setPage={setInnerPage}
            />
          </div>

          {/* Intermediate floor/ceiling */}
          <div className="w-full flex-col justify-start items-start gap-2 flex">
            <div className="self-stretch font-bold text-gray-900">Intermediate floors/ceilings</div>
            <MaterialInputField
              selectorProps={{
                title: 'Intermediate floors/ceilings',
                surfaceType: 'intermediate-floor-and-ceiling',
                materials: props.materials,
                ageBand: props.survey.age_band,
                selectedMaterial: selectedMaterialsSet?.intermediateFloorAndCeiling,
                onSelectCallback: handleMaterialUpdate
              } satisfies MaterialsSelectorGroupedProps}
              setMsProps={setSelectorProps}
              setPage={setInnerPage}
            />
          </div>
        </div>

        {/* Divider */}
        <div className="self-stretch h-px border border-gray-200"></div>

        {/* Roof */}
        <div className="self-stretch flex-col justify-start items-start gap-5 flex">
          <div className="self-stretch text-gray-900 text-xl font-bold">Roof</div>
          <MaterialInputField
            selectorProps={{
              title: 'Roof',
              surfaceType: 'roof',
              materials: props.materials,
              ageBand: props.survey.age_band,
              selectedMaterial: selectedMaterialsSet?.roof,
              onSelectCallback: handleMaterialUpdate
            } satisfies MaterialsSelectorGroupedProps}
            setMsProps={setSelectorProps}
            setPage={setInnerPage}
          />
        </div>
      </>
    },
    {
      flagBit: 0x008,
      pbValue: 100,
      page: <>
        <div className="self-stretch flex-col justify-start items-start gap-5 flex">
          <div className="self-stretch text-gray-900 text-xl font-bold">Doors</div>
          <MaterialInputField
            selectorProps={{
              title: 'Door',
              surfaceType: 'door',
              materials: props.materials,
              ageBand: props.survey.age_band,
              selectedMaterial: selectedMaterialsSet?.door,
              onSelectCallback: handleMaterialUpdate
            } satisfies MaterialsSelectorGroupedProps}
            setMsProps={setSelectorProps}
            setPage={setInnerPage}
          />
        </div>

        {/* Divider */}
        <div className="self-stretch h-px border border-gray-200"></div>

        {/* Windows */}
        <div className="self-stretch flex-col justify-start items-start gap-5 flex">
          <div className="self-stretch text-gray-900 text-xl font-bold">Windows</div>
          <MaterialInputField
            selectorProps={{
              title: 'Window',
              surfaceType: 'window',
              materials: props.materials,
              ageBand: props.survey.age_band,
              selectedMaterial: selectedMaterialsSet?.window,
              onSelectCallback: handleMaterialUpdate
            } satisfies MaterialsSelectorGroupedProps}
            setMsProps={setSelectorProps}
            setPage={setInnerPage}
          />
        </div>
      </>
    }
  ] as PagesFlowPage[]

  const [currentPage, setCurrentPage] = useState<number>(0x001)

  if (innerPage === 'MATERIALS') {
    return <div className='flex flex-col h-full'>
      <PageHeader isOffline={props.isOffline} allSynced={props.allSynced} title='Choose a material' onBack={() => {
        setInnerPages(prev => prev.slice(0, -1))
      }}/>
      <div className='overflow-y-auto'>
        <MaterialsSelector
          groupedProps={selectorProps!}
          setGroupedProps={setSelectorProps}
          setMaterialsCallback={props.setMaterials}
          setPage={setInnerPage}
          setTempCustomMaterial={setTempCustomMaterial}
          onBack={() => setInnerPages(prev => prev.slice(0, -1))}
          companyUUID={props.companyUUID}
        />
      </div>
    </div>
  }

  if (innerPage === 'CUSTOM_MATERIAL') {
    return <div className='flex flex-col h-full'>
      <div className='px-5 pt-4 pb-3 gap-2 flex flex-col border-b border-gray-300 bg-white'>
        {customMaterialHeader}
      </div>
      <div className='overflow-y-auto'>
        <CustomMaterialPopup
          material={tempCustomMaterial}
          materialsLayers={props.materialsLayers}
          setMaterial={setTempCustomMaterial}
          onSave={(newMaterial) => {
            // NB! do not override materials with the `materials` variable!
            // because it contains filtered values for the current surface and will override the global materials list
            props.setMaterials(prev => ([...prev, newMaterial]))
            setSelectorProps(prev => ({
              ...prev!,
              materials: [...prev!.materials!, newMaterial],
              selectedMaterial: newMaterial
            }))
            if (selectorProps!.onSelectCallback) {
              // trigger global callback
              selectorProps!.onSelectCallback(newMaterial)
            }
          }}
          setVisible={() => setInnerPages(prev => prev.slice(0, -1))}
          onBack={() => setInnerPages(prev => prev.slice(0, -1))}
          setHeader={setCustomMaterialHeader}
        />
      </div>
    </div>
  }

  return <SurveySectionFlowWrapper
    completeSectionID={'FLOOR'}
    hideCompleteButton={true}
    completionChecks={[]}
    isOffline={props.isOffline}
    allSynced={props.allSynced}
    sectionUIName={'Start floor plan'}
    pages={pages}
    flags_attribute_name={'flags_floorplan_pages_completed'}
    startPage={currentPage}
    onPageShow={(flagBit) => {
      // save visited page as a page to continue flow from
      // it's required when we back from the materials selector sub-flow
      // without this approach, we will always start from the first page
      setCurrentPage(flagBit)
    }}
    onPageComplete={async (flagBit) => {
      // mark some sections as completed if user has seen them
      switch (flagBit) {
        case 0x001:
          // user sees materials: mark SETTINGS section as completed
          await props.setSurvey(prev => ({ ...prev, completed_sections: _.uniq([...prev.completed_sections, 'SETTINGS']) }))
          break
        case 0x008:
          // user sees floorplan: mark MATERIALS section as completed
          await props.setSurvey(prev => ({ ...prev, completed_sections: _.uniq([...prev.completed_sections, 'MATERIALS']) }))
          break
      }

      // mark page as visited
      await props.setSurvey(prev => ({
        ...prev,
        flags_floorplan_pages_completed: props.survey.flags_floorplan_pages_completed | flagBit
      }))
    }}
    onFormComplete={() => props.navigateTo('/survey/new_floor')}
    onClose={() => window.history.back()}
    survey={props.survey}
    setSurvey={props.setSurvey}
  />
}
