import { AuthSDK } from '../utils/auth_provider'
import { setError } from '../../components/indicators_and_messaging/toast'
import { apiUrl, client } from '../axios'
import {
  TTPlaceholderCarbonSaving,
  TTPlaceholderCompanyName,
  TTPlaceholderCompanyPhone,
  TTPlaceholderCustomerAddress,
  TTPlaceholderCustomerName,
  TTPlaceholderEstimateContactName,
  TTPlaceholderEstimateContactPhone,
  TTPlaceholderHeatPumpModel,
  TTPlaceholderInstallerName,
  TTPlaceholderViewEstimate,
  TTPlaceholderViewProposal,
  TTPlaceholderViewQuote,
  TTPlaceholderViewReport
} from '../tiptap_placeholders'
import { type JSONContent } from '@tiptap/react'
import { tiptapHTMLToJSON } from '../../components/inputs_and_selections/tiptap'

export type CompanyPublicInfo = {
  uuid: string
  subdomain: string
  logo: string
  colour_primary?: string
  colour_secondary?: string
  name: string
  phone: string
  address: string
  installer_first_name: string
  installer_last_name: string
  notification_email: string

  // booking settings
  booking_confirmation_noaction_template?: string
  booking_confirmation_action_template?: string
  booking_redirect_enabled?: boolean
  booking_redirect_url?: string
  sources: string[]

  // Homeowner-facing estimate settings
  no_survey_booking?: boolean
  no_request_callback?: boolean
  show_line_item_costs_on_estimate: boolean
  show_bill_savings_on_estimate: boolean
  calculated_estimate_cover_note?: string
  estimate_contact_name?: string
  estimate_contact_phone?: string
  estimate_contact_role?: string
  estimate_contact_portrait?: string
  show_about_us_on_estimate: boolean
  about_us?: string
  about_us_image?: string
  show_customer_review_on_estimate: boolean
  customer_review_name?: string
  customer_review_location?: string
  customer_review?: string
  customer_review_link_text?: string
  customer_review_link?: string
  next_steps?: string
  next_steps_scottish?: string
}

export type GetAllCompaniesListResult = Pick<CompanyPublicInfo, 'uuid' | 'name' | 'subdomain'>

export type Company = {
  public_info: CompanyPublicInfo
  phone: string
  notification_email: string
  lat?: number
  lng?: number

  install_days?: number
  install_day_rate?: number
  survey_cost?: number
  default_radiator_cost?: number
  default_underfloor_cost?: number
  default_include_survey?: boolean
  decline_template?: string
  estimate_template?: string
  nudge_lead_template?: string
  report_email_template?: string
  quote_email_template?: string
  proposal_email_template?: string
  proposal_cover_note_template?: string
  default_brand_range_uuid?: string
  payaca_customer_id?: string
  commusoft_client_id?: string
  commusoft_api_key?: string
  simpro_api_key?: string
  simpro_url?: string
  simpro_company_id?: string
  simpro_company_name?: string // denormalized for display. Fetched from the API using simpro_company_id

  magicplan_api_key?: string
  magicplan_customer_id?: string

  // booking settings
  booking_confirmation_noaction_template?: string
  booking_confirmation_action_template?: string
  booking_redirect_enabled?: boolean
  booking_redirect_url?: string
  calculated_estimate_cover_note?: string

  // estimate/survey calculation settings
  estimate_default_internal_temp_c?: number
  estimate_default_ach?: number
  default_flow_temp_c?: number
  estimate_default_radiator_change_percentage?: number
}

export type CompanyApiKey = {
  uuid: string
  created_at: string
  disabled_at: string
}

export type LabourRow = {
  uuid: string
  name: string
  price: number
}
export type SurveyRow = {
  price: number | undefined
}

export const getSimproAPICompaniesList = async (companyUUID: string, apiKey: string, url: string): Promise<Array<{ key: string, value: string }> | undefined> => {
  try {
    const result = await client.get<Array<{ key: string, value: string }>>(`${apiUrl}teams/${companyUUID}/simpro_companies?api_key=${apiKey}&url=${url}`,
      { headers: { 'x-auth-token': AuthSDK.getToken() } }
    )
    return result.data
  } catch (e: unknown) {
    console.error('Error getting user', e)
    return undefined
  }
}

export const isCompanyHasMagicPlanIntegration = (company: Company): boolean => {
  return !!company.magicplan_api_key && !!company.magicplan_customer_id
}

export const getCompanyPublicInfoSilent = async (companySubdomain: string): Promise<CompanyPublicInfo | null> => {
  try {
    const result = await client.get<CompanyPublicInfo>(`${apiUrl}teams/${companySubdomain}/info`,
      { headers: { 'x-auth-token': AuthSDK.getToken() } }
    )
    return result.data
  } catch (e: unknown) {
    console.error('Error getting user', e)
    return null
  }
}

export const getCompanyPublicInfo = async (companySubdomain: string): Promise<CompanyPublicInfo | undefined> => {
  try {
    const result = await client.get<CompanyPublicInfo>(`${apiUrl}teams/${companySubdomain}/info`)
    return result.data
  } catch (e: unknown) {
    setError(e)
  }
}

export const getCompany = async (companySubdomain: string): Promise<Company | undefined> => {
  try {
    const result = await client.get<{ data: { uuid: string, attributes: Company } }>(`${apiUrl}teams/${companySubdomain}`,
      { headers: { 'x-auth-token': AuthSDK.getToken() } }
    )
    return { ...result.data.data.attributes }
  } catch (e: unknown) {
    console.error('Error getting company', e)
    return undefined
  }
}

export const getCompanyApiKeys = async (company: Company): Promise<CompanyApiKey[] | undefined> => {
  try {
    const result = await client.get<CompanyApiKey[]>(`${apiUrl}teams/${company.public_info.uuid}/api_keys`,
      { headers: { 'x-auth-token': AuthSDK.getToken() } }
    )
    return result.data
  } catch (e: unknown) {
    console.error('Error getting company', e)
    return undefined
  }
}

export const addNewCompanyApiKey = async (company: Company): Promise<CompanyApiKey | undefined> => {
  try {
    const result = await client.post<CompanyApiKey>(`${apiUrl}teams/${company.public_info.uuid}/api_keys`,
      {},
      { headers: { 'x-auth-token': AuthSDK.getToken() } }
    )
    return result.data
  } catch (e: unknown) {
    console.error('Error getting company', e)
    return undefined
  }
}

export const invalidateCompanyApiKey = async (keyUuid: string, company: Company): Promise<CompanyApiKey | undefined> => {
  try {
    const result = await client.patch<CompanyApiKey>(`${apiUrl}teams/${company.public_info.uuid}/api_keys/${keyUuid}`,
      {},
      { headers: { 'x-auth-token': AuthSDK.getToken() } }
    )
    return result.data
  } catch (e: unknown) {
    console.error('Error getting company', e)
    return undefined
  }
}

export const getAllCompaniesList = async (): Promise<GetAllCompaniesListResult[] | undefined> => {
  try {
    const result = await client.get<GetAllCompaniesListResult[]>(`${apiUrl}teams`,
      { headers: { 'x-auth-token': AuthSDK.getToken() } }
    )
    return result.data
  } catch (e: unknown) {
    console.error('Error getting all companies', e)
    return undefined
  }
}

export const updateCompanySettings = async (company: Company) => {
  try {
    await client.patch(`${apiUrl}teams/${company.public_info.uuid}`, {
      data: {
        type: 'teams',
        attributes: {
          name: company.public_info.name,
          phone: company.phone,
          notification_email: company.notification_email,
          address: company.public_info.address,
          installer_first_name: company.public_info.installer_first_name,
          installer_last_name: company.public_info.installer_last_name,
          payaca_customer_id: company.payaca_customer_id,
          commusoft_client_id: company.commusoft_client_id,
          commusoft_api_key: company.commusoft_api_key,
          simpro_api_key: company.simpro_api_key,
          simpro_url: company.simpro_url,
          simpro_company_id: company.simpro_company_id,
          simpro_company_name: company.simpro_company_name,
          magicplan_api_key: company.magicplan_api_key,
          magicplan_customer_id: company.magicplan_customer_id,
          lat: company.lat,
          lng: company.lng,
          estimate_default_internal_temp_c: company.estimate_default_internal_temp_c,
          estimate_default_ach: company.estimate_default_ach,
          estimate_default_radiator_change_percentage: company.estimate_default_radiator_change_percentage,
          default_flow_temp_c: company.default_flow_temp_c,
          no_survey_booking: company.public_info.no_survey_booking,
          no_request_callback: company.public_info.no_request_callback,
          show_line_item_costs_on_estimate: company.public_info.show_line_item_costs_on_estimate,
          show_bill_savings_on_estimate: company.public_info.show_bill_savings_on_estimate,
          decline_template: company.decline_template,
          estimate_template: company.estimate_template,
          nudge_lead_template: company.nudge_lead_template,
          report_email_template: company.report_email_template,
          quote_email_template: company.quote_email_template,
          proposal_email_template: company.proposal_email_template,
          proposal_cover_note_template: company.proposal_cover_note_template,
          booking_confirmation_noaction_template: company.booking_confirmation_noaction_template,
          booking_confirmation_action_template: company.booking_confirmation_action_template,
          booking_redirect_enabled: company.booking_redirect_enabled,
          booking_redirect_url: company.booking_redirect_url,
          sources: company.public_info.sources,
          calculated_estimate_cover_note: company.public_info.calculated_estimate_cover_note,
          logo: company.public_info.logo,
          colour_primary: company.public_info.colour_primary,
          colour_secondary: company.public_info.colour_secondary,
          estimate_contact_name: company.public_info.estimate_contact_name,
          estimate_contact_phone: company.public_info.estimate_contact_phone,
          estimate_contact_role: company.public_info.estimate_contact_role,
          estimate_contact_portrait: company.public_info.estimate_contact_portrait,
          show_about_us_on_estimate: company.public_info.show_about_us_on_estimate,
          about_us: company.public_info.about_us,
          about_us_image: company.public_info.about_us_image,
          show_customer_review_on_estimate: company.public_info.show_customer_review_on_estimate,
          customer_review_name: company.public_info.customer_review_name,
          customer_review_location: company.public_info.customer_review_location,
          customer_review: company.public_info.customer_review,
          customer_review_link_text: company.public_info.customer_review_link_text,
          customer_review_link: company.public_info.customer_review_link,
          next_steps: company.public_info.next_steps,
          next_steps_scottish: company.public_info.next_steps_scottish
        }
      }
    }, {
      headers: {
        'Content-Type': 'application/vnd.api+json',
        'x-auth-token': localStorage.getItem('token')
      }
    })
  } catch (e: unknown) {
    setError(e)
  }
}

// FIXME: merge these two methods ^^
export const updateCompany = async (company: Company) => {
  try {
    await client.patch(`${apiUrl}teams/${company.public_info.uuid}`, {
      data: {
        type: 'teams',
        attributes: {
          install_day_rate: company.install_day_rate,
          install_days: company.install_days,
          survey_cost: company.survey_cost,
          default_radiator_cost: company.default_radiator_cost,
          default_underfloor_cost: company.default_underfloor_cost,
          default_brand_range_uuid: company.default_brand_range_uuid,
          default_include_survey: company.default_include_survey
        }
      }
    }, {
      headers: {
        'Content-Type': 'application/vnd.api+json',
        'x-auth-token': localStorage.getItem('token')
      }
    })
  } catch (e: unknown) {
    setError(e)
  }
}

// ESTIMATE COVER NOTE
export function getCompanyEstimateCoverNote (template: string): JSONContent {
  // try to get the saved email text
  if (template) {
    try {
      return JSON.parse(template)
    } catch (e: unknown) {
      console.error('Error parsing template', e)
    }
  }

  const defaultCoverNoteText = `
    <p>We’ve finished creating your personalised cost estimate.</p>
    <p>Please feel free to give me a call directly on ${TTPlaceholderCompanyPhone.code} if you have any questions, I’d be happy to help!
    <p>All the best,</p>
    <p>${TTPlaceholderInstallerName.code}</p>
    <p><strong>${TTPlaceholderCompanyName.code}</strong></p>
  `

  // if no saved estimate email text, use the default
  return tiptapHTMLToJSON(defaultCoverNoteText)
}

// EMAILS
export function getCompanyEstimateEmailTemplate (template: string): JSONContent {
  // try to get the saved email text
  if (template) {
    try {
      return JSON.parse(template)
    } catch (e: unknown) {
      console.error('Error parsing template', e)
    }
  }

  const defaultEmailText = `
    <p>Hi ${TTPlaceholderCustomerName.code},</p>
    <p>Thank you for enquiring about getting a heat pump installed at ${TTPlaceholderCustomerAddress.code}.</p>
    <p>
      Based on the information you provided, we estimate that you will need a ${TTPlaceholderHeatPumpModel.code} heat pump which could save you
      ${TTPlaceholderCarbonSaving.code} kg CO2 per year.
    </p>
    ${TTPlaceholderViewEstimate.code}
    <p>
      Kind regards,<br/>
      ${TTPlaceholderCompanyName.code}<br/>
      ${TTPlaceholderCompanyPhone.code}
    </p>
  `

  // if no saved estimate email text, use the default
  return tiptapHTMLToJSON(defaultEmailText)
}

export function getCompanyDeclinedEmailTemplate (template: string): JSONContent {
  // try to get the saved email text
  if (template) {
    try {
      return JSON.parse(template)
    } catch (e: unknown) {
      console.error('Error parsing template', e)
    }
  }

  const defaultEmailText = `
    <p>Hi ${TTPlaceholderCustomerName.code},</p>
    <p>Thank you for enquiring about getting a heat pump installed at ${TTPlaceholderCustomerAddress.code}.</p>
    <p>
      We have reviewed the information you submitted and unfortunately we can't take on your heat pump install because your home is outside of the area that we work in.
      We hope you can find an installer closer to home to do your install.
    </p>
    <p>
      Kind regards,<br/>
      ${TTPlaceholderCompanyName.code}<br/>
      ${TTPlaceholderCompanyPhone.code}
    </p>
  `

  // if no saved estimate email text, use the default
  return tiptapHTMLToJSON(defaultEmailText)
}

export function getCompanyBookingConfirmationNoActionEmailTemplate (template: string): JSONContent {
  // try to get the saved email text
  if (template) {
    try {
      return JSON.parse(template)
    } catch (e: unknown) {
      console.error('Error parsing template', e)
    }
  }

  const defaultEmailText = `
    <p>Hi ${TTPlaceholderCustomerName.code},</p>
    <p>
      Thanks so much for indicating that you’d like to book a heat loss survey!
      This will allow us to understand exactly what you and your home need, and produce a detailed design to meet those requirements.
      Someone from our team will be in touch to book in a time that suits you.
    </p>
    <p>
      Kind regards,<br/>
      ${TTPlaceholderCompanyName.code}<br/>
      ${TTPlaceholderCompanyPhone.code}
    </p>
  `

  // if no saved estimate email text, use the default
  return tiptapHTMLToJSON(defaultEmailText)
}

export function getCompanyBookingConfirmationActionEmailTemplate (template: string): JSONContent {
  // try to get the saved email text
  if (template) {
    try {
      return JSON.parse(template)
    } catch (e: unknown) {
      console.error('Error parsing template', e)
    }
  }

  const defaultEmailText = `
    <p>Hi ${TTPlaceholderCustomerName.code},</p>
    <p>
      Thanks so much for indicating that you’d like to book a heat loss survey!
      This will allow us to understand exactly what you and your home need, and produce a detailed design to meet those requirements.
      You can book a slot that suits you straight into my calendar: 
    </p>
    <p>
      Kind regards,<br/>
      ${TTPlaceholderCompanyName.code}<br/>
      ${TTPlaceholderCompanyPhone.code}
    </p>
  `

  // if no saved estimate email text, use the default
  return tiptapHTMLToJSON(defaultEmailText)
}

export function getCompanyNudgeLeadEmailTemplate (template: string): JSONContent {
  // try to get the saved email text
  if (template) {
    try {
      return JSON.parse(template)
    } catch (e: unknown) {
      console.error('Error parsing template', e)
    }
  }

  const defaultEmailText = `
    <p>Hi ${TTPlaceholderCustomerName.code},</p>
    <p>
      I just wanted to follow up on the heat pump estimate we sent you recently.
      Have you had a chance to have a look yet, and if so do you have any questions I could help with?
    </p> 
    <p>
      As a reminder, our initial calculations suggest you need a ${TTPlaceholderHeatPumpModel.code} heat pump which could save you ${TTPlaceholderCarbonSaving.code} kg CO2 per year. You can view the estimate by clicking the button below.
    </p>
    ${TTPlaceholderViewEstimate.code}
    <p>
      If you have any questions about the estimate, about heat pumps, or about us as a company please do give us a call on ${TTPlaceholderCompanyPhone.code} or reply to this email.
      We’d love to help you take this really impactful step to decarbonise your home and improve your comfort!
    </p>
    <p>
      Kind regards,<br/>
      ${TTPlaceholderCompanyName.code}<br/>
      ${TTPlaceholderCompanyPhone.code}
    </p>
  `

  // if no saved estimate email text, use the default
  return tiptapHTMLToJSON(defaultEmailText)
}

export function getCompanyReportEmailTemplate (template: string): JSONContent {
  // try to get the saved email text
  if (template) {
    try {
      return JSON.parse(template)
    } catch (e: unknown) {
      console.error('Error parsing template', e)
    }
  }

  // Available placeholders:
  // TTPlaceholderViewReport,
  // TTPlaceholderCustomerName,
  // TTPlaceholderCustomerAddress,
  // TTPlaceholderEstimateContactName,
  // TTPlaceholderEstimateContactPhone,
  // TTPlaceholderCompanyName,
  // TTPlaceholderCompanyPhone

  const defaultEmailText = `
    <p>Dear ${TTPlaceholderCustomerName.code},</p>
    <p>
      Thank you for considering ${TTPlaceholderCompanyName.code} for your heat pump installation needs. 
      We are pleased to provide you with a comprehensive heat loss report and system design for a heat pump installation at your property.
    </p>
    ${TTPlaceholderViewReport.code}
    <p>
      Warm regards,<br/>
      ${TTPlaceholderCompanyName.code}<br/>
      ${TTPlaceholderCompanyPhone.code}
    </p>
  `

  // if no saved estimate email text, use the default
  return tiptapHTMLToJSON(defaultEmailText)
}

export function getCompanyProposalEmailTemplate (template: string): JSONContent {
  // try to get the saved email text
  if (template) {
    try {
      return JSON.parse(template)
    } catch (e: unknown) {
      console.error('Error parsing template', e)
    }
  }

  // Available placeholders:
  // TTPlaceholderViewProposal,
  // TTPlaceholderCustomerName,
  // TTPlaceholderCustomerAddress,
  // TTPlaceholderEstimateContactName,
  // TTPlaceholderEstimateContactPhone,
  // TTPlaceholderCompanyName,
  // TTPlaceholderCompanyPhone

  const defaultEmailText = `
    <p>Dear ${TTPlaceholderCustomerName.code},</p>
    <p>
      Thank you for considering ${TTPlaceholderCompanyName.code} for your heat pump installation needs.
      We are pleased to provide you with a comprehensive proposal for a full heat pump installation at your property. 
    </p>
    ${TTPlaceholderViewProposal.code}
    <p>
      We understand that selecting the right heating and cooling system is a critical decision for your comfort and energy efficiency.
      Our team has conducted a thorough assessment of your property to ensure that the proposed solution will meet your specific needs while maximising efficiency and cost-effectiveness.
    </p>
    <p>
      Warm regards,<br/>
      ${TTPlaceholderCompanyName.code}<br/>
      ${TTPlaceholderCompanyPhone.code}
    </p>
  `

  // if no saved estimate email text, use the default
  return tiptapHTMLToJSON(defaultEmailText)
}

export function getCompanyQuoteEmailTemplate (template: string): JSONContent {
  // try to get the saved email text
  if (template) {
    try {
      return JSON.parse(template)
    } catch (e: unknown) {
      console.error('Error parsing template', e)
    }
  }

  // Available placeholders:
  // TTPlaceholderViewQuote,
  // TTPlaceholderCustomerName,
  // TTPlaceholderCustomerAddress,
  // TTPlaceholderEstimateContactName,
  // TTPlaceholderEstimateContactPhone,
  // TTPlaceholderCompanyName,
  // TTPlaceholderCompanyPhone

  const defaultEmailText = `
    <p>Dear ${TTPlaceholderCustomerName.code},</p>
    <p>
      Thank you for considering ${TTPlaceholderCompanyName.code} for your heat pump installation needs.
      We are pleased to present this quote for the installation of a heat pump system at ${TTPlaceholderCustomerAddress.code}. 
    </p>
    ${TTPlaceholderViewQuote.code}
    <p>
      If you’re happy to continue, please get in touch so that we can send across the full proposal which includes all the relevant documentation as well as the link to sign.
    </p>
    <p>
      Warm regards,<br/>
      ${TTPlaceholderCompanyName.code}<br/>
      ${TTPlaceholderCompanyPhone.code}
    </p>
  `

  // if no saved estimate email text, use the default
  return tiptapHTMLToJSON(defaultEmailText)
}

export function getCompanyProposalCoverNoteTemplate (template: string): JSONContent {
  // try to get the saved email text
  if (template) {
    try {
      return JSON.parse(template)
    } catch (e: unknown) {
      console.error('Error parsing template', e)
    }
  }

  // Available placeholders:
  // TTPlaceholderCustomerName,
  // TTPlaceholderCustomerAddress,
  // TTPlaceholderEstimateContactName,
  // TTPlaceholderEstimateContactPhone,
  // TTPlaceholderCompanyName,
  // TTPlaceholderCompanyPhone

  const defaultEmailText = `
    <p>Hi ${TTPlaceholderCustomerName.code},</p>
    <p>
      We are pleased to present this proposal for the installation of a heat pump system at ${TTPlaceholderCustomerAddress.code}.
    </p>
    <p>
      Following our site survey we’ve carried out a detailed heat loss calculation for your home and have designed the system to match every room in your home. These detailed calculations ensure your system will work quietly and efficiently.
    </p>
    <p>
      Thank you for considering our proposal. We are confident that a heat pump installation will not only enhance your comfort but also contribute to a greener and more sustainable future.
    </p>
    
    <p>
      Warm regards,<br/>
      ${TTPlaceholderEstimateContactName.code}<br/>
      ${TTPlaceholderCompanyName.code}<br/>
      ${TTPlaceholderEstimateContactPhone.code}<br/>
    </p>
  `

  // if no saved estimate email text, use the default
  return tiptapHTMLToJSON(defaultEmailText)
}
