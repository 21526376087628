import React from 'react'

export const Error404Page = () => {
  return (
    <div className="flex flex-col justify-center items-center mt-10 gap-y-2">
      <div className="text-9xl font-semibold text-slate-700">404</div>
      <div>Sorry, we couldn't find this page.</div>
      <div>Have you entered the correct company name?</div>
    </div>
  )
}

export const Error500Page = ({ error }: { error: any }) => {
  return (
    <div className="flex flex-col justify-center items-center mt-10 gap-y-2">
      <div className="text-9xl font-semibold text-slate-700">500</div>
      <div>{error?.cause?.message || JSON.stringify(error)}</div>
      <div><span onClick={() => { window.location.reload() }} className="text-blue-500 underline cursor-pointer">Click here</span> to reload the app.</div>
    </div>
  )
}
