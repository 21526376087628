import { type Room } from './room'
import { type Material, type MaterialElement } from './material'
import { type PropertySurvey } from './property'

export type Floor = {
  uuid?: string
  name: string
  rooms: Room[]

  default_room_height: number
  default_floor_material_type: MaterialElement
  default_ceiling_material_type: MaterialElement

  floor_plan_image: string | undefined
  floor_plan_url: string | undefined
  floor_plan_scale: number
  floor_plan_is_showing: boolean
}

export const getFloorAndCeilingDefaultMaterialsForFloor = (floor: Floor, survey: PropertySurvey): [Material, Material] => {
  const floorMaterial = getDefaultFloorMaterialForFloor(floor, survey)
  const ceilingMaterial = getDefaultCeilingMaterialForFloor(floor, survey)
  return [floorMaterial, ceilingMaterial]
}

export const getDefaultCeilingMaterialForFloor = (floor: Floor, survey: PropertySurvey): Material => {
  switch (floor.default_ceiling_material_type) {
    case 'intermediate-floor-and-ceiling':
      return survey.default_materials!.intermediateFloorAndCeiling!
    case 'roof':
      return survey.default_materials!.roof!
    default:
      throw new Error('Unknown ceiling material type')
  }
}

export const getDefaultFloorMaterialForFloor = (floor: Floor, survey: PropertySurvey): Material => {
  switch (floor.default_floor_material_type) {
    case 'ground-floor':
      return survey.default_materials!.groundFloor!
    case 'intermediate-floor-and-ceiling':
      return survey.default_materials!.intermediateFloorAndCeiling!
    case 'exposed-floor':
      return survey.default_materials!.exposedFloor!
    default:
      throw new Error('Unknown floor material type')
  }
}
