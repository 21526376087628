import React from 'react'
import { Accordion } from '../../../components/content_display/accordion'

export const AccordionDemo = () => {
  return <div className="flex flex-col gap-8">
    <div className='flex flex-col gap-4'>
      <div className='text-2xl font-bold text-gray-900'>Definition</div>

      <pre className='bg-gray-100 rounded-lg p-4 overflow-x-auto'><code>
        {codeBlock}
      </code></pre>

      <div className='flex flex-col divide-y divide-dashed divide-gray-200 text-sm'>
        {componentProps.map(x => <div key={x.name} className='grid grid-cols-2 py-2'>
          <div className='font-bold text-gray-900'>{x.name}</div>
          <div>{x.description}</div>
        </div>)}
      </div>
    </div>

    <div className='flex flex-col gap-4'>
      <div className='text-2xl font-bold text-gray-900'>Component</div>
      <Accordion
        rows={[
          { title: 'Row 1', body: 'Hello world 1' },
          { title: 'Row 2', body: 'Hello world 2' },
          { title: 'Row 3', body: 'Hello world 3' }

        ]}
      />
    </div>

  </div>
}

const componentProps = [
  { name: 'rows', description: 'An array of items that should appear in the accordion' },
  { name: 'title', description: 'Title of specific row' },
  { name: 'body', description: 'Body of specific row, hidden if accordion item is closed' }
]

const codeBlock = `<Accordion
  rows={[
    { title: 'Row 1', body: 'Hello world 1' },
    { title: 'Row 2', body: 'Hello world 2' },
    { title: 'Row 3', body: 'Hello world 3' }
  ]}
/>`
