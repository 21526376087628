import React from 'react'
import { HLRContext } from './heatloss_report'
import { HLRSubheaderBlock } from './partials/hlr_subheader'
import { ClickableCard } from '../../../components/content_display/card'
import { ProgressChart } from '../../../components/indicators_and_messaging/progress_chart'
import { sum } from 'lodash'
import { type Floor } from '../../../code/models/floor'
import { getRoomWatts } from '../../../code/models/heat_loss'
import { HLRFloorplanBlock } from './partials/hlr_floorplan'

type Props = {
  floor: Floor
}

export const HLRFloorHeatlossPage = ({ floor }: Props) => {
  const hlrContext = React.useContext(HLRContext)

  const rooms = floor.rooms.flatMap(y => {
    return {
      name: y.name,
      heatLoss: Math.round(getRoomWatts(y, floor.rooms, hlrContext!.designTempC, hlrContext!.groundTempC, hlrContext!.survey))
    }
  })

  const totalHeatLossW = sum(rooms.map(x => x.heatLoss))

  return (
    <div className="flex-col gap-6 flex">
      <HLRSubheaderBlock
        section="Heat loss by floor"
        title={floor.name}
      />

      <ClickableCard variant='GREY'>
        <div className="gap-3 flex justify-between">
          <div className="text-gray-900 text-lg font-bold ">Heat loss by room</div>
          <div className="text-lg">{totalHeatLossW} W</div>
        </div>

        <ProgressChart
          items={rooms.map(x => ({
            name: x.name,
            value: x.heatLoss
          }))}
          total={totalHeatLossW}
        />
      </ClickableCard>

      <HLRFloorplanBlock floor={floor} />

      <div className="flex-col flex">
        <div className="">
          <span className="text-gray-900 text-xs tracking-tight font-bold">Rads: </span>
          <span className="text-gray-600 text-xs tracking-tight">number of radiators</span>
        </div>
        <div className="">
          <span className="text-gray-900 text-xs tracking-tight font-bold">UFH: </span>
          <span className="text-gray-600 text-xs tracking-tight">underfloor heating in room</span>
        </div>
        <div className="">
          <span className="text-gray-900 text-xs tracking-tight font-bold">°C: </span>
          <span className="text-gray-600 text-xs tracking-tight">room temperature</span>
        </div>
        <div className="">
          <span className="text-gray-900 text-xs tracking-tight font-bold">ACH: </span>
          <span className="text-gray-600 text-xs tracking-tight">air changes per hour</span>
        </div>
        <div className="">
          <span className="text-gray-900 text-xs tracking-tight font-bold">W: </span>
          <span className="text-gray-600 text-xs tracking-tight">number of windows</span>
        </div>
        <div className="">
          <span className="text-gray-900 text-xs tracking-tight font-bold">D: </span>
          <span className="text-gray-600 text-xs tracking-tight">number of doors</span>
        </div>
      </div>
    </div>
  )
}
