import React, { useContext, useState } from 'react'
import { SurveyActionType } from '../../../code/survey/survey'
import { type FloorAreaUnit } from '../../../code/extra_types'
import { Input } from '../../../components/inputs_and_selections/input'
import { Button } from '../../../components/buttons/button'
import { ArrowLeftIcon, ChevronRightIcon } from 'lucide-react'
import { logEvent } from '../../../code/log_event'
import { type CompanyPublicInfo } from '../../../code/models/company'
import { getAddressIncludingPostcode } from '../../../code/models/lead'
import { SurveyOptionGroup } from '../../../components/inputs_and_selections/survey_option_group'
import { StepHeader } from '../components/steps_header'
import { SurveyContext, SurveyDispatchContext } from '../survey_page'

export const FloorAreaStep = ({ companyPublicInfo }: { companyPublicInfo: CompanyPublicInfo }) => {
  // survey context and dispatch
  const survey = useContext(SurveyContext)
  const dispatch = useContext(SurveyDispatchContext)

  // save user input in state, because when we do calculations we want to use the original value
  // and not the converted one
  const [userInput, setUserInput] = useState<number>(survey.lead.property.floorArea || 0)
  const [userInputUnit, setUserInputUnit] = useState<FloorAreaUnit>('sqm')

  const handleValueChange = (value: string) => {
    const valueAsNumber = parseInt(value)
    setUserInput(valueAsNumber)
  }

  const handleNavigatePreviousPage = () => {
    dispatch({
      type: SurveyActionType.NavigatePreviousPage
    })
  }

  const handleNavigateNextPage = () => {
    logEvent({ name: 'Floor area step completed', properties: {} }, companyPublicInfo.subdomain)

    dispatch({
      type: SurveyActionType.NavigateNextPage,
      payload: {
        floorArea: Number((userInputUnit === 'sqm' ? userInput : userInput / 10.764).toFixed(2))
      }
    })
  }

  return <>
    {/* Back button */}
    <Button colour='LIGHT' onClick={handleNavigatePreviousPage} block={false} className="self-start">
      <div className="flex items-center gap-x-2">
        <ArrowLeftIcon className="h-4 w-4" />
        <div>Back</div>
      </div>
    </Button>

    {/* Header */}
    <StepHeader
      guideText={getAddressIncludingPostcode(survey.lead)}

      // show different text depending on whether we have an EPC or not
      headerText={survey.lead.epcData !== undefined
        ? 'Confirm your property’s floor area'
        : 'Your property’s floor area'}

      // show different text depending on whether we have an EPC or not
      captionText={survey.lead.epcData !== undefined
        ? 'We have pre-filled this based on your EPC. Let us know if it has changed or you have a more accurate figure.'
        : 'This is the floor area of all heatable parts of your home'}
    />

    {/* Controls */}
    <div className="flex-col gap-3 flex">
      <Input
        size='LG'
        setValue={handleValueChange}
        type="number"
        value={userInput.toString()}
        placeholder={'E.g. 169'}
      />
      <SurveyOptionGroup noCheckmark={true} inline={true} selectedOptionKey={userInputUnit} options={[
        { label: 'Square meters', key: 'sqm' },
        { label: 'Square feet', key: 'sqft' }
      ]} onSelect={(value: FloorAreaUnit) => {
        setUserInputUnit(value)
        setUserInput(value === 'sqm' ? Number((userInput / 10.764).toFixed(2)) : Number((userInput * 10.764).toFixed(2)))
      }} />
    </div>
    {/* TODO: SPR-1293 Bug here: we are not saving this floorAreaUnit on the property object  */}

    <Button size='LG' disabled={userInput <= 0} onClick={handleNavigateNextPage}>
      <div className="flex items-center gap-x-2">
        <div>Continue</div>
        <ChevronRightIcon className="h-6 w-6" />
      </div>
    </Button>
  </>
}
