import React from 'react'
import { SurveyCylinderLocationContext } from '../cylinder_location'
import type { FileWrapper } from '../../file_wrapper'
import { FlowWrapperContext } from '../components/survey_section_flow_wrapper'
import { Check } from 'lucide-react'
import { Badge } from '../../../../components/indicators_and_messaging/badge'
import { PhotoScroll } from '../../../../components/inputs_and_selections/photo_scroll'

export const CylinderLocationProposedPhotosBlock = () => {
  const cylinderLocationContext = React.useContext(SurveyCylinderLocationContext)
  const flowContext = React.useContext(FlowWrapperContext)

  // This page could be included into a non-flow pages. Let's assign a default value
  const viewMode = flowContext?.viewMode ?? 'FULL'

  const { survey, setSurvey, files, setFiles, companyUUID } = cylinderLocationContext!

  const images = survey.cylinder_photos.map(x => files.find(y => y.uuid === x.image_uuid)!)

  const deleteImage = (imageUUID: string) => {
    setSurvey({ ...survey, cylinder_photos: survey.cylinder_photos.filter(x => x.image_uuid !== imageUUID) })
  }

  const isFullScreen = images.length === 0 && viewMode === 'FLOW'

  return <>
    <div className={isFullScreen ? 'p-5 h-full bg-gray-50 flex flex-col items-center justify-center gap-4' : 'flex flex-col gap-4'}>
      <div className={`${isFullScreen ? 'items-center' : ''} flex flex-col gap-1`}>
        <div className="text-gray-900 text-xl font-bold">Proposed location</div>
        <div className={`${isFullScreen ? 'text-center' : ''} text-gray-500 text-sm`}>Add photos of the proposed location of the new cylinder</div>
      </div>

      <div className="flex-col gap-3 flex">
        {!isFullScreen &&
          <div className='flex justify-between'>
            <div className="text-gray-900 font-bold">Photos</div>
            <Badge color={survey.cylinder_photos.length > 0 ? 'GREEN' : 'YELLOW'} text='Required'
              icon={survey.cylinder_photos.length > 0 ? Check : undefined}/>
          </div>
        }

        <PhotoScroll viewMode={isFullScreen ? 'COMPACT' : 'NORMAL'} images={images} addImage={(fileWrapper: FileWrapper) => {
          fileWrapper && setFiles(prev => [...prev, { ...fileWrapper, updated_at: new Date().getTime(), created_at: new Date().getTime(), is_modified: true }])
          fileWrapper && setSurvey(({
            ...survey,
            cylinder_photos: [...survey.cylinder_photos, {
              uuid: crypto.randomUUID(),
              entity_uuid: survey.uuid,
              image_uuid: fileWrapper.uuid!
            }]
          }))
        }} deleteImage={(id) => deleteImage(id)} companyUUID={companyUUID} />
      </div>
    </div>
  </>
}
