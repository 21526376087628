import { type ReactNode, useState } from 'react'
import { isFlagSet } from './helpers'

export type PagesFlowPage = {
  flagBit: number
  page: ReactNode
  pbValue: number
  hideOrSkipPage?: () => boolean
}

type Props = {
  flagsCompletedPages: number
  startPage?: number
  onEndOfFlow: () => void
  onPageCompleted: (flagBit: number) => void
  pages: PagesFlowPage[]
}

// calculate flow state ...

export const usePagesFlow = (props: Props) => {
  if (props.pages.length === 0) {
    throw new Error('No pages provided')
  }

  // modes for the next page
  const NEXT_PAGE_MODE_UNVISITED = 0x1 // find the first unvisited page (bit == 0)
  const NEXT_PAGE_MODE_THROUGH = 0x2 // go through the pages one by one
  const [mode] = useState(NEXT_PAGE_MODE_THROUGH)

  const findInitialPage = () => {
    // filter out pages that should be hidden or skipped
    const availablePages = props.pages.filter(p => !p.hideOrSkipPage?.())

    let initialPage: PagesFlowPage = availablePages[0]

    // if the startPage is provided, use it
    if (props.startPage) {
      initialPage = availablePages.find(p => p.flagBit === props.startPage) ?? initialPage
    } else {
      // if the startPage is not provided AND if the mode is NEXT_PAGE_MODE_UNVISITED
      if (mode === NEXT_PAGE_MODE_UNVISITED) {
        // find the first page that is not visited
        initialPage = availablePages.find(p => {
          // return the first page that is not visited
          // and not hidden or skipped
          // and not the current page
          return !isFlagSet(props.flagsCompletedPages, p.flagBit) && !p.hideOrSkipPage?.()
        }) ?? initialPage
      }
    }

    return initialPage
  }

  const [currentPageBitFlag, setCurrentPageBitFlag] = useState<number>(findInitialPage().flagBit)

  const next = () => {
    let next_page: PagesFlowPage | undefined

    // filter out pages that should be hidden or skipped
    const availablePages = props.pages.filter(p => !p.hideOrSkipPage?.())

    // find the next page based on the mode
    switch (mode) {
      case NEXT_PAGE_MODE_UNVISITED:
        // find first unset bit
        next_page = availablePages.find(p => {
          // return the first page that is not visited
          // and not hidden or skipped
          return p.flagBit !== currentPageBitFlag && !isFlagSet(props.flagsCompletedPages, p.flagBit) && !p.hideOrSkipPage?.()
        })
        break
      case NEXT_PAGE_MODE_THROUGH:

        // find next page to the one with the flagBit
        const index = availablePages.findIndex(p => {
          return p.flagBit === currentPageBitFlag
        })
        // is this the last page?
        if (index === -1 || index === availablePages.length - 1) {
          next_page = undefined
        } else {
          next_page = availablePages[index + 1]
        }
        break
    }

    // if there is a next page
    if (next_page) {
      // set the page
      props.onPageCompleted(currentPageBitFlag)
      setCurrentPageBitFlag(next_page.flagBit)
    } else {
      // notify the parent component that there are no more pages
      props.onEndOfFlow()
    }
  }

  const prev = () => {
    // filter out pages that should be hidden or skipped
    const availablePages = props.pages.filter(p => !p.hideOrSkipPage?.())

    // find previous page to the one with the flagBit
    const index = availablePages.findIndex(p => {
      return p.flagBit === currentPageBitFlag && !p.hideOrSkipPage?.()
    })
    setCurrentPageBitFlag(availablePages[index - 1].flagBit)
  }

  return [currentPageBitFlag, next, prev] as const
}
