import { apiUrl, client } from '../axios'
import { AuthSDK } from '../utils/auth_provider'
import { setError } from '../../components/indicators_and_messaging/toast'
import { type Lead } from './lead'
import * as Sentry from '@sentry/react'
import { getUValue } from './u_value'
import type React from 'react'
import { type EPC } from './epc'
import { type SyncItem } from '../../pages/admin/sync'
import { type PropertySurvey } from './property'
import _ from 'lodash'
import { ROOM_OVERWRITTEN_ATTRIBUTE_CEILING_MATERIAL, ROOM_OVERWRITTEN_ATTRIBUTE_FLOOR_MATERIAL } from './room'
import { isFlagSet } from '../helpers'
import { estimateAgeBandToSurveyAgeBand, type SurveyAgeBand } from './age_bands'

export type BuildType = 'retrofit' | 'new-build' | undefined
export type MaterialType = 'generic' | 'custom_simple' | 'custom_layered'

export type WallElement = 'external-wall' | 'internal-wall' | 'party-wall'

export type MaterialElement =
WallElement
| 'roof'
| 'intermediate-floor-and-ceiling'
| 'exposed-floor'
| 'ground-floor'
| 'window'
| 'door'

export const MATERIAL_ELEMENT_NAMES: Record<MaterialElement, string> = {
  roof: 'Roof',
  'external-wall': 'External Wall',
  'internal-wall': 'Internal Wall',
  'party-wall': 'Party Wall',
  'intermediate-floor-and-ceiling': 'Intermediate Floor/Ceiling',
  'exposed-floor': 'Exposed Floor',
  'ground-floor': 'Ground Floor',
  window: 'Window',
  door: 'Door'
}

export type RoofAttributes = {
  construction: string
  insulation_thickness_mm: number
  u_value: number
  estimate_mapping: string

  // optional
  layers_description_CIBSE?: string
  external_covering?: string
  felt?: string
  internal_finish?: string
}

export type ExternalWallAttributes = {
  category: string
  construction_sub_type: string
  insulation_type: string
  total_thickness_mm: number
  u_value: number
  estimate_mapping_wall_group: string
  estimate_mapping_insulation: string

  // optional
  layers_description_CIBSE?: string
  construction_detail_outer_layer?: string
  construction_thickness_outer_layer?: number
  construction_detail_inner_layer?: string
  construction_thickness_inner_layer_mm?: string
  insulation?: string
  insulation_thickness_mm?: string
  exterior_finish?: string
  exterior_finish_thickness_mm?: string
  internal_finish?: string
  internal_finish_thickness?: string
}

export type InternalWallAttributes = {
  finish: string
  construction: string
  thickness_mm: number
  u_value: number
  estimate_mapping: string

  // optional
  layers_description_CIBSE?: string
}

export type PartyWallAttributes = {
  u_value: number
}

export type ExposedFloorAttributes = {
  type: string
  insulation_thickness_mm: string
  u_value: number
  /**
   * @deprecated This property is superseded by u_value
   */
  u_value_flow_down: number
  estimate_mapping: string

  // optional
  layers_description_CIBSE?: string
}

export type IntermediateFloorAndCeilingAttributes = {
  type: string
  insulation_thickness_mm: string
  u_value_flow_down: number
  u_value_flow_up: number
  estimate_mapping: string

  // optional
  layers_description_CIBSE?: string
}

export type GroundFloorAttributes = {
  construction: string
  insulation_thickness_mm: string
  insulation_u_value: number | undefined // can be blank
  u_value: number | undefined // for new-builds only
  estimate_mapping: string
}

export type WindowAttributes = {
  glazing_type: string
  frame_materials: string
  u_value: number
  estimate_mapping: string

  // optional
  layers_description_CIBSE?: string
  glass?: string
  fill?: string
}

export type DoorAttributes = {
  type: string
  u_value: number
  estimate_mapping: string

  // optional
  layers_description_CIBSE?: string
}

export type CustomAttributes = {
  u_value: number | undefined // cached value only for custom_layered
}

export type MaterialExtraData =
  | RoofAttributes
  | ExternalWallAttributes
  | InternalWallAttributes
  | PartyWallAttributes
  | IntermediateFloorAndCeilingAttributes
  | ExposedFloorAttributes
  | GroundFloorAttributes
  | WindowAttributes
  | DoorAttributes
  | CustomAttributes

export type MaterialAgeBand = {
  uuid: string
  min_year?: number
  max_year?: number
  build_type: BuildType
}

export type MaterialLayerExtraDataAttributes = {
  applies_to: string
  material: string
  conductivity_w_per_m_k?: number | undefined
  density_kg_m3?: number | undefined
  const_r_value?: number | undefined
}

export type MaterialLayer = {
  uuid: string
  name: string
  applicable_to: MaterialElement
  extra_data: MaterialLayerExtraDataAttributes
}

// a N-N link between Material and MaterialLayer
export type MaterialLayerAssociation = {
  uuid?: string
  material_layer: MaterialLayer
  layer_thickness_mm: number
  layer_order: number
}

export type Material = SyncItem & {
  company_uuid: string | undefined
  name: string
  type: MaterialType
  applicable_to: MaterialElement
  extra_data: MaterialExtraData

  // specific for generic materials
  build_type: BuildType
  age_bands: MaterialAgeBand[]
  layers: MaterialLayerAssociation[]
}

// a set of materials
// usually used for transferring materials from Estimate to Survey
// and for providing default materials for different scopes: platform, installer, lead ...
export type MaterialsSet = {
  roof?: Material
  externalWall?: Material
  internalWall?: Material
  partyWall?: Material
  intermediateFloorAndCeiling?: Material
  exposedFloor?: Material // Not actually viewed on the default materials page as rarely used
  groundFloor?: Material
  window?: Material
  door?: Material
}

export const isMaterialAgeBandIntersectsSurveyAgeBand = (ageBand: SurveyAgeBand, materialAgeBand: MaterialAgeBand): boolean => {
  const lowerBound = Math.max(ageBand.min_year || 0, materialAgeBand.min_year || 0)
  const upperBound = Math.min(ageBand.max_year || Infinity, materialAgeBand.max_year || Infinity)
  return lowerBound <= upperBound
}

const findMaterialForConstructionAgeBand = (materials: Material[], ageBand: SurveyAgeBand): Material | undefined => {
  return materials.find(m => m.age_bands.some(ab => isMaterialAgeBandIntersectsSurveyAgeBand(ageBand, ab)))
}

export const instantiateMaterialSet = (materialsSet: MaterialsSet, setCustomMaterials: React.Dispatch<React.SetStateAction<Material[]>>): void => {
  // check is all the materials are exists, if not, create them

  // iterate each material and create it if it doesn't exist
  Object.keys(materialsSet).forEach(key => {
    const material = materialsSet[key as keyof MaterialsSet]
    if (!material) {
      return
    }

    if (!material.uuid || material.uuid.length === 0) {
      setCustomMaterials(prev => [...prev, { ...material, uuid: crypto.randomUUID(), created_at: new Date().getTime(), updated_at: new Date().getTime(), is_modified: true }])
    }
  })
}

export const getSimpleName = (surface: MaterialElement, uValue: number | undefined): string => {
  return MATERIAL_ELEMENT_NAMES[surface] + ' - ' + uValue + ' W/m²K'
}

export const getLayersName = (layers: MaterialLayerAssociation[]) => layers.map(l => l.material_layer.extra_data.material + ' - ' + l.layer_thickness_mm.toFixed(0) + 'mm').join('. ')

export const mapElementByOverrideOrEPC = (
  overrideUValue: number | undefined,
  epc: EPC | undefined,
  epcAttribute: string,
  customMaterials: Material[],
  elementType: MaterialElement,
  companyUUID: string
): Material | undefined => {
  const customUValue = overrideUValue ?? (epc && getUValue(epc[epcAttribute]))
  if (!customUValue) return undefined

  const customMaterial = customMaterials.find(x => x.applicable_to === elementType && (x.extra_data as CustomAttributes).u_value === customUValue)
  if (customMaterial) return customMaterial

  // Create custom value if it doesn't exist.
  return {
    uuid: undefined,
    name: getSimpleName(elementType, customUValue),
    type: 'custom_simple',
    applicable_to: elementType,
    extra_data: {
      u_value: customUValue
    },
    build_type: undefined,
    age_bands: [],
    layers: [],
    created_at: new Date().getTime(),
    updated_at: new Date().getTime(),
    server_updated_at: 0,
    deleted_at: undefined,
    company_uuid: companyUUID,
    is_modified: true
  }
}

export const mapEstimateMaterials = (lead: Lead, genericMaterials: Material[], customMaterials: Material[], companyUUID: string): MaterialsSet => {
  // map only generic materials
  const customSimpleMaterials = customMaterials.filter(x => x.type === 'custom_simple')

  // get some information from the Lead
  const leadLoftInsulation = lead.property.houseOverrides?.loftInsulation ? lead.property.houseOverrides.loftInsulation : lead.property.loftInsulation
  const leadWallGroup = lead.property.houseOverrides?.wallGroup ? lead.property.houseOverrides.wallGroup : lead.property.wallGroup
  const leadWallType = lead.property.houseOverrides?.wallType ? lead.property.houseOverrides.wallType : lead.property.wallType
  const leadFloorType = lead.property.houseOverrides?.floorType ? lead.property.houseOverrides.floorType : lead.property.floorType
  const leadWindowType = lead.property.houseOverrides?.windowType ? lead.property.houseOverrides.windowType : lead.property.windowType
  const ageBand = estimateAgeBandToSurveyAgeBand(lead)!

  // MAPPING RULES are defined here: https://share.cleanshot.com/MN373bNT
  // and here: https://www.figma.com/file/AonpPIOgtqRuknUdA32vZ0/Architecture?type=whiteboard&node-id=342-2347&t=uyQ6ddIa4u7L0X8i-4

  // map roof
  let roofMapping: Material | undefined

  // try to map by UValue override or an UValue from EPC
  roofMapping = mapElementByOverrideOrEPC(
    lead.property.houseOverrides?.roofUValueOverride,
    lead.epcData,
    'roofDescription',
    customSimpleMaterials,
    'roof',
    companyUUID)

  // if no mapping found, try to map by type
  if (!roofMapping) {
    const roofMaterials = genericMaterials.filter(x => x.applicable_to === 'roof')
    roofMapping = roofMaterials.find(x => (x.extra_data as RoofAttributes).estimate_mapping === leadLoftInsulation)
    // try to map by age band
    if (!roofMapping && ageBand) {
      roofMapping = findMaterialForConstructionAgeBand(roofMaterials, ageBand)
    }
  }

  // map external wall
  let externalWallMapping: Material | undefined

  // try to map by UValue override or an UValue from EPC
  externalWallMapping = mapElementByOverrideOrEPC(
    lead.property.houseOverrides?.externalWallUValueOverride,
    lead.epcData,
    'wallsDescription',
    customSimpleMaterials,
    'external-wall',
    companyUUID)

  // if no mapping found, try to map by type
  if (!externalWallMapping) {
    const externalWallMaterials = genericMaterials.filter(x => x.applicable_to === 'external-wall')

    let wallTypeEvened: string
    if (leadWallType === undefined || ['', 'null', 'idk', 'none'].includes(leadWallType.toLowerCase().trim())) {
      wallTypeEvened = 'None'
    } else {
      wallTypeEvened = leadWallType
    }

    // find the material by wall group and wall type
    externalWallMapping = externalWallMaterials.find(x => {
      return (x.extra_data as ExternalWallAttributes).estimate_mapping_wall_group === leadWallGroup && (x.extra_data as ExternalWallAttributes).estimate_mapping_insulation === wallTypeEvened
    })

    // try to map by age band
    if (!externalWallMapping && ageBand) {
      externalWallMapping = findMaterialForConstructionAgeBand(externalWallMaterials, ageBand)
    }
  }

  // map internal wall
  const internalWallMaterials = genericMaterials.filter(x => x.applicable_to === 'internal-wall')
  let internalWallMapping = internalWallMaterials.find(x => (x.extra_data as InternalWallAttributes).estimate_mapping === leadWallGroup)
  // try to map by age band
  if (!internalWallMapping && ageBand) {
    internalWallMapping = findMaterialForConstructionAgeBand(internalWallMaterials, ageBand)
  }

  // map party wall
  let partyWallMapping: Material | undefined

  // try to map by UValue override
  partyWallMapping = mapElementByOverrideOrEPC(
    lead.property.houseOverrides?.partyWallUValueOverride,
    lead.epcData,
    'wallsDescription',
    customSimpleMaterials,
    'party-wall',
    companyUUID)

  // if no mapping found, try to map by type
  if (!partyWallMapping) {
    const partyWallMaterials = genericMaterials.filter(x => x.applicable_to === 'party-wall')
    // try to map by age band
    if (ageBand) {
      partyWallMapping = findMaterialForConstructionAgeBand(partyWallMaterials, ageBand)
    }

    // FIXME: this equals to the first and probably only material in the list, which is `MCS default party wall`
    if (!partyWallMapping) {
      partyWallMapping = partyWallMaterials[0]
    }
  }

  // map intermediate floor and ceiling
  const intermediateFloorAndCeilingMaterials = genericMaterials.filter(x => x.applicable_to === 'intermediate-floor-and-ceiling')
  let intermediateFloorAndCeilingMapping: Material | undefined = intermediateFloorAndCeilingMaterials.find(x => {
    // map uncertain values to a single one
    let floorTypeEvened: string
    if (leadFloorType === undefined || ['', 'none'].includes(leadFloorType.toLowerCase().trim())) {
      floorTypeEvened = 'None'
    } else {
      floorTypeEvened = leadFloorType
    }
    return (x.extra_data as IntermediateFloorAndCeilingAttributes).estimate_mapping === floorTypeEvened
  })
  // try to map by age band
  if (!intermediateFloorAndCeilingMapping && ageBand) {
    intermediateFloorAndCeilingMapping = findMaterialForConstructionAgeBand(intermediateFloorAndCeilingMaterials, ageBand)
  }

  // map exposed floor
  const exposedFloorMapping = getExposedFloorMaterial(genericMaterials, ageBand)

  // map ground floor
  let groundFloorMapping: Material | undefined

  // try to map by UValue override
  groundFloorMapping = mapElementByOverrideOrEPC(
    lead.property.houseOverrides?.floorUValueOverride,
    lead.epcData,
    'floorDescription',
    customSimpleMaterials,
    'ground-floor',
    companyUUID)

  if (!groundFloorMapping) {
    const groundFloorMaterials = genericMaterials.filter(x => x.applicable_to === 'ground-floor')
    groundFloorMapping = groundFloorMaterials.find(x => {
      // map uncertain values to a single one
      let floorTypeEvened: string
      if (leadFloorType === undefined || ['', 'none'].includes(leadFloorType.toLowerCase().trim())) {
        floorTypeEvened = 'None'
      } else {
        floorTypeEvened = leadFloorType
      }
      return (x.extra_data as GroundFloorAttributes).estimate_mapping === floorTypeEvened
    })

    // try to map by age band
    if (!groundFloorMapping && ageBand) {
      groundFloorMapping = findMaterialForConstructionAgeBand(groundFloorMaterials, ageBand)
    }
  }

  // map windows
  let windowsMapping: Material | undefined

  // try to map by UValue override or an UValue from EPC
  windowsMapping = mapElementByOverrideOrEPC(
    lead.property.houseOverrides?.windowsUValueOverride,
    lead.epcData,
    'windowsDescription',
    customSimpleMaterials,
    'window',
    companyUUID)

  // if no mapping found, try to map by type
  if (!windowsMapping) {
    const windowsMaterials = genericMaterials.filter(x => x.applicable_to === 'window')
    windowsMapping = windowsMaterials.find(x => {
      // map uncertain values to a single one
      let windowTypeEvened: string
      if (leadWindowType === undefined) {
        windowTypeEvened = 'None'
      } else if (leadWindowType.toLowerCase().trim() === 'single') {
        windowTypeEvened = 'Single Glazed'
      } else if (leadWindowType.toLowerCase().trim() === 'double') {
        windowTypeEvened = 'Double Glazed'
      } else if (leadWindowType.toLowerCase().trim() === 'triple') {
        windowTypeEvened = 'Triple Glazed'
      } else {
        windowTypeEvened = leadWindowType
      }
      return (x.extra_data as WindowAttributes).estimate_mapping === windowTypeEvened
    })
    // try to map by age band
    if (!windowsMapping && ageBand) {
      windowsMapping = findMaterialForConstructionAgeBand(windowsMaterials, ageBand)
    }
  }

  // map doors
  const doorsMaterials = genericMaterials.filter(x => x.applicable_to === 'door')
  let doorsMapping = doorsMaterials.find(x => {
    // map uncertain values to a single one
    let windowTypeEvened: string
    if (leadWindowType === undefined) {
      windowTypeEvened = 'None'
    } else if (leadWindowType.toLowerCase().trim() === 'single') {
      windowTypeEvened = 'Single'
    } else if (leadWindowType.toLowerCase().trim() === 'double') {
      windowTypeEvened = 'Double'
    } else if (leadWindowType.toLowerCase().trim() === 'triple') {
      windowTypeEvened = 'Triple'
    } else {
      windowTypeEvened = leadWindowType
    }
    return (x.extra_data as DoorAttributes).estimate_mapping === windowTypeEvened
  })
  // try to map by age band
  if (!doorsMapping && ageBand) {
    doorsMapping = findMaterialForConstructionAgeBand(doorsMaterials, ageBand)
  }

  // log errors if no mapping found
  if (!roofMapping) {
    console.error(`No mapping for roof u-value: ${leadLoftInsulation}`)
    Sentry.captureException(new Error(`No mapping for roof u-value: ${leadLoftInsulation}`))
  }

  if (!externalWallMapping) {
    console.error(`No mapping for external wall u-value where wall group/wall type is: ${leadWallGroup}/${leadWallType}`)
    Sentry.captureException(new Error(`No mapping for external wall u-value where wall group/wall type is: ${leadWallGroup}/${leadWallType}`))
  }

  if (!internalWallMapping) {
    console.error(`No mapping for internal wall u-value: ${leadWallGroup}`)
    Sentry.captureException(new Error(`No mapping for internal wall u-value: ${leadWallGroup}`))
  }

  if (!partyWallMapping) {
    console.error('No mapping for party wall u-value')
    Sentry.captureException(new Error('No mapping for party wall u-value'))
  }

  if (!intermediateFloorAndCeilingMapping) {
    console.error(`No mapping for intermediate floor and ceiling u-value: ${leadFloorType}`)
    Sentry.captureException(new Error(`No mapping for intermediate floor and ceiling u-value: ${leadFloorType}`))
  }

  if (!groundFloorMapping) {
    console.error('No mapping for ground floor u-value')
    Sentry.captureException(new Error('No mapping for ground floor u-value'))
  }

  if (!windowsMapping) {
    console.error(`No mapping for windows u-value: ${leadWindowType}`)
    Sentry.captureException(new Error(`No mapping for windows u-value: ${leadWindowType}`))
  }

  if (!doorsMapping) {
    console.error(`No mapping for door u-value: ${leadWindowType}`)
    Sentry.captureException(new Error(`No mapping for door u-value: ${leadWindowType}`))
  }

  // return the mapping
  return {
    roof: roofMapping,
    externalWall: externalWallMapping,
    internalWall: internalWallMapping,
    partyWall: partyWallMapping,
    intermediateFloorAndCeiling: intermediateFloorAndCeilingMapping,
    exposedFloor: exposedFloorMapping,
    groundFloor: groundFloorMapping,
    window: windowsMapping,
    door: doorsMapping
  }
}

export const formatMaterialUValue = (uValue: any): string => {
  if (typeof uValue === 'number') {
    return uValue.toFixed(2) + ' W/m²K'
  }
  return uValue
}

// for some cases (mostly for UI) we need to get the material UValue, but it's available in different type of structures, so we need to get it from the right place
export const getMaterialUValue = (material: Material): number | undefined | string => {
  if (material.applicable_to === 'roof') {
    return (material.extra_data as RoofAttributes).u_value
  } else if (material.applicable_to === 'external-wall') {
    return (material.extra_data as ExternalWallAttributes).u_value
  } else if (material.applicable_to === 'internal-wall') {
    return (material.extra_data as InternalWallAttributes).u_value
  } else if (material.applicable_to === 'party-wall') {
    return (material.extra_data as PartyWallAttributes).u_value
  } else if (material.applicable_to === 'exposed-floor') {
    return (material.extra_data as ExposedFloorAttributes).u_value ?? (material.extra_data as ExposedFloorAttributes).u_value_flow_down // keep in old version as a fallback
  } else if (material.applicable_to === 'intermediate-floor-and-ceiling') {
    const str: string[] = []
    if ((material.extra_data as IntermediateFloorAndCeilingAttributes).u_value_flow_up) {
      str.push('Up: ' + (material.extra_data as IntermediateFloorAndCeilingAttributes).u_value_flow_up + ' W/m²K')
    }
    if ((material.extra_data as IntermediateFloorAndCeilingAttributes).u_value_flow_down) {
      str.push('Down: ' + (material.extra_data as IntermediateFloorAndCeilingAttributes).u_value_flow_down + ' W/m²K')
    }
    return str.join(', ')
  } else if (material.applicable_to === 'ground-floor') {
    // New build and Custom ground floors do have U values
    if (material.build_type === 'new-build' || material.build_type === undefined) {
      return (material.extra_data as GroundFloorAttributes).u_value
    } else {
      // ground floor for retrofit buildings has insulation UValue, which is not what we need to show
      return undefined
    }
  } else if (material.applicable_to === 'window') {
    return (material.extra_data as WindowAttributes).u_value
  } else if (material.applicable_to === 'door') {
    return (material.extra_data as DoorAttributes).u_value
  } else {
    throw new Error('Invalid material type')
  }
}

export const getMaterialByUuid = (uuid: string | undefined, materials: Material[]): Material | undefined => {
  if (!uuid) {
    return undefined
  }
  return materials.find(x => x.uuid === uuid)
}

export const getCustomMaterials = async (lead: Lead, companyUUID: string): Promise<Material[] | undefined> => {
  try {
    const result = await client.get<{ materials: Material[] }>(`${apiUrl}teams/${companyUUID}/leads/${lead.uuid}/materials`,
      { headers: { 'x-auth-token': AuthSDK.getToken() } }
    )
    return result.data.materials
  } catch (e: unknown) {
    setError(e)
  }
}

export const getGenericMaterials = async (): Promise<Material[] | undefined> => {
  try {
    const result = await client.get<{ materials: Material[] }>(`${apiUrl}materials`,
      { headers: { 'x-auth-token': AuthSDK.getToken() } }
    )
    return result.data.materials
  } catch (e: unknown) {
    console.error('Error loading Generic Materials', e)
    return undefined
  }
}

export const getMaterialsGenericLayers = async (): Promise<MaterialLayer[] | undefined> => {
  try {
    const result = await client.get<{ layers: MaterialLayer[] }>(`${apiUrl}materials_layers`,
      { headers: { 'x-auth-token': AuthSDK.getToken() } }
    )
    return result.data.layers
  } catch (e: unknown) {
    console.error('Error loading Generic Materials Layers', e)
    return undefined
  }
}

export const updateDefaultMaterial = (
  survey: PropertySurvey,
  setSurvey: React.Dispatch<React.SetStateAction<PropertySurvey>>,
  material: Material
): MaterialsSet => {
  // a copy of materials to compare with
  const materialsBeforeChanges = _.cloneDeep(survey.default_materials)!

  // a material set assigned as a default for the survey
  // only one of its attributes will be changed in the switch case
  const materialsSet = _.cloneDeep(survey.default_materials)!

  // make a copy of survey
  survey = _.cloneDeep(survey)

  switch (material.applicable_to) {
    case 'external-wall':
      materialsSet.externalWall = material
      // if the material is changed, update it in the survey
      if (materialsBeforeChanges.externalWall?.uuid !== material.uuid) {
        survey.floors.forEach(floor => {
          floor.rooms.forEach(room => {
            room.walls.forEach(wall => {
              // update only external walls with default material
              if (wall.material?.applicable_to === 'external-wall' && wall.material.uuid === materialsBeforeChanges.externalWall?.uuid) {
                wall.material = material
              }
            })
          })
        })
      }
      break
    case 'party-wall':
      materialsSet.partyWall = material
      // if the material is changed, update it in the survey
      if (materialsBeforeChanges.partyWall?.uuid !== material.uuid) {
        survey.floors.forEach(floor => {
          floor.rooms.forEach(room => {
            room.walls.forEach(wall => {
              // update only party walls with default material
              if (wall.material?.applicable_to === 'party-wall' && wall.material.uuid === materialsBeforeChanges.partyWall?.uuid) {
                wall.material = material
              }
            })
          })
        })
      }
      break
    case 'internal-wall':
      materialsSet.internalWall = material
      // if the material is changed, update it in the survey
      if (materialsBeforeChanges.internalWall?.uuid !== material.uuid) {
        survey.floors.forEach(floor => {
          floor.rooms.forEach(room => {
            room.walls.forEach(wall => {
              // update only internal walls with default material
              if (wall.material?.applicable_to === 'internal-wall' && wall.material.uuid === materialsBeforeChanges.internalWall?.uuid) {
                wall.material = material
              }
            })
          })
        })
      }
      break
    case 'ground-floor':
      materialsSet.groundFloor = material
      // if the material is changed, update it in the survey
      if (materialsBeforeChanges.groundFloor?.uuid !== material.uuid) {
        survey.floors.forEach(floor => {
          floor.rooms.forEach(room => {
            // skip if room's floor was overwritten
            if (isFlagSet(room.overwritten_attributes_flags, ROOM_OVERWRITTEN_ATTRIBUTE_FLOOR_MATERIAL)) return

            // update only if the floor is ground floor
            if (room.floor_material?.applicable_to === 'ground-floor') {
              room.floor_material = material
            }
          })
        })
      }
      break
    case 'intermediate-floor-and-ceiling':
      materialsSet.intermediateFloorAndCeiling = material
      // if the material is changed, update it in the survey
      if (materialsBeforeChanges.intermediateFloorAndCeiling?.uuid !== material.uuid) {
        survey.floors.forEach(floor => {
          floor.rooms.forEach(room => {
            // if room's floor wasn't overwritten
            if (!isFlagSet(room.overwritten_attributes_flags, ROOM_OVERWRITTEN_ATTRIBUTE_FLOOR_MATERIAL)) {
              // update only if floor type is intermediate floor
              if (room.floor_material?.applicable_to === 'intermediate-floor-and-ceiling') {
                room.floor_material = material
              }
            }

            // if room's ceiling wasn't overwritten
            if (!isFlagSet(room.overwritten_attributes_flags, ROOM_OVERWRITTEN_ATTRIBUTE_CEILING_MATERIAL)) {
              if (room.ceiling_material?.applicable_to === 'intermediate-floor-and-ceiling') {
                room.ceiling_material = material
              }
            }
          })
        })
      }
      break
    case 'roof':
      materialsSet.roof = material
      // if the material is changed, update it in the survey
      if (materialsBeforeChanges.roof?.uuid !== material.uuid) {
        survey.floors.forEach(floor => {
          floor.rooms.forEach(room => {
            // skip if room's ceiling was overwritten
            if (isFlagSet(room.overwritten_attributes_flags, ROOM_OVERWRITTEN_ATTRIBUTE_CEILING_MATERIAL)) return

            // update only if ceiling type is intermediate floor
            if (room.ceiling_material?.applicable_to === 'roof') {
              room.ceiling_material = material
            }
          })
        })
      }
      break
    case 'door':
      materialsSet.door = material
      // if the material is changed, update it in the survey
      if (materialsBeforeChanges.door?.uuid !== material.uuid) {
        survey.floors.forEach(floor => {
          floor.rooms.forEach(room => {
            room.walls.forEach(wall => {
              wall.doors.forEach(door => {
                // update only doors with default material
                if (door.material?.uuid === materialsBeforeChanges.door?.uuid) {
                  door.material = material
                }
              })
            })
          })
        })
      }
      break
    case 'window':
      materialsSet.window = material
      // if the material is changed, update it in the survey
      if (materialsBeforeChanges.window?.uuid !== material.uuid) {
        survey.floors.forEach(floor => {
          floor.rooms.forEach(room => {
            room.walls.forEach(wall => {
              wall.windows.forEach(window => {
                // update only doors with default material
                if (window.material?.uuid === materialsBeforeChanges.window?.uuid) {
                  window.material = material
                }
              })
            })
          })
        })
      }
      break
  }

  survey.default_materials = materialsSet

  // overwrite the survey completely
  // TODO: probably, bad practice but there is no way now to update all elements in one go
  setSurvey(survey)

  return materialsSet
}

export const getExposedFloorMaterial = (genericMaterials: Material[], ageBand?: SurveyAgeBand): Material => {
  // map exposed floor
  const exposedFloorMaterials = genericMaterials.filter(x => x.applicable_to === 'exposed-floor')
  let exposedFloorMapping: Material | undefined
  // try to map by age band
  if (ageBand) {
    exposedFloorMapping = findMaterialForConstructionAgeBand(exposedFloorMaterials, ageBand)
  }
  if (!exposedFloorMapping) {
    exposedFloorMapping = exposedFloorMaterials[0]
  }
  if (!exposedFloorMapping) {
    console.error('No mapping for exposed floor u-value')
    Sentry.captureException(new Error(`No mapping for exposed floor u-value ${ageBand ? `for age band ${ageBand.min_year}-${ageBand.max_year}` : ''}`))
    //   fallback
    exposedFloorMapping = genericMaterials.filter(x => x.applicable_to === 'roof')[0]
  }

  return exposedFloorMapping
}
