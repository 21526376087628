import { hps, ohm } from '../../../assets/images/images'
import React from 'react'
import { type CompanyPublicInfo } from '../../../code/models/company'

type LogoHeaderProps = {
  companyPublicInfo: CompanyPublicInfo
  center?: boolean
}

export const LogoHeader: React.FC<LogoHeaderProps> = ({ companyPublicInfo, center }) => {
  return <div className={`${center ? 'm-auto text-center' : ''}`}>
    <div className="h-16 px-7 py-4 bg-white flex-row justify-start items-start gap-2.5 inline-flex">
      {/* we have to show two logos for OHM company on the top of the enquiry form. Ugly, but fast */}
      {companyPublicInfo.subdomain === 'ohm' &&
        <>
          <img className="h-9" src={ohm} />
          <img className="h-9" src={hps} />
        </>
      }
      {companyPublicInfo.subdomain !== 'ohm' && <img className="h-9" src={companyPublicInfo.logo} />}
    </div>
  </div>
}
