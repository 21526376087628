import React from 'react'
import { type LeadStatusType } from '../../code/models/lead'
import { type LucideIcon } from 'lucide-react'
import { WrappedIcon } from '../buttons/wrapped_icon'

export const badgeColours = {
  DARK: 'bg-gray-900 text-gray-100',
  LIGHT: 'bg-gray-100 text-gray-900',
  YELLOW: 'bg-yellow-100 text-yellow-900',
  RED: 'bg-red-100 text-red-900',
  BLUE: 'bg-blue-100 text-blue-900',
  INDIGO: 'bg-purple-100 text-purple-900',
  GREEN: 'bg-green-100 text-green-900'
}

export type BadgeProps = {
  color: keyof typeof badgeColours
  text?: string
  icon?: LucideIcon
  button?: React.ReactNode
}

export const leadStatusToBadge = (status: LeadStatusType): BadgeProps => {
  if (status === 'New') return { color: 'YELLOW', text: 'Estimate required' }
  if (status === 'Quoted') return { color: 'BLUE', text: 'Estimate sent' }
  if (status === 'Converted') return { color: 'GREEN', text: 'Accepted' }
  if (status === 'Rejected') return { color: 'RED', text: 'Rejected' }
  if (status === 'Declined') return { color: 'RED', text: 'Enquiry Declined' }
  if (status === 'SurveyBooked') return { color: 'BLUE', text: 'Survey Booked' }
  if (status === 'SurveyInProgress') return { color: 'YELLOW', text: 'Survey In Progress' }
  if (status === 'SurveyCompleted') return { color: 'GREEN', text: 'Survey Completed' }
  if (status === 'ProposalSent') return { color: 'BLUE', text: 'Proposal Sent' }
  if (status === 'ProposalAccepted') return { color: 'GREEN', text: 'Proposal Accepted' }
  if (status === 'Archived') return { color: 'LIGHT', text: 'Archived' }

  return { color: 'DARK', text: '' }
}

export const Badge: React.FC<BadgeProps> = ({ color, text, icon, button }) => {
  if (!text) return

  return <div className={`${badgeColours[color]} px-2 py-1 rounded flex items-center justify-center`}>
    <div className='flex gap-2 items-center'>
      {icon && <WrappedIcon icon={icon} />}
      <div className="text-xs font-semibold text-center truncate">{text}</div>
      {button}
    </div>
  </div>
}
