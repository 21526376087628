import { type KonvaEventObject } from 'konva/lib/Node'
import { type SprucePoint } from './types'
import { type Floor } from '../../../../code/models/floor'
import { type Dispatch, type SetStateAction } from 'react'
import { type SpruceLine } from '../line_with_text'
import { snapPoint } from '../room_drawing'
import { generateComplexRoom } from '../../add_room'
import { type PropertySurvey } from '../../../../code/models/property'
import { SNAPPING_TOLERANCE_PIXELS } from '../../constants'

export const stageOnMouseMove = (
  e: KonvaEventObject<MouseEvent>,
  scalingPoints: SprucePoint[],
  drawingRooms: boolean,
  scalingWindows: boolean,
  scalingDoor: boolean,
  floor: Floor,
  setMousePosition: Dispatch<SetStateAction<SprucePoint>>,
  stageStep: number | undefined,
  setGuidelines: Dispatch<SetStateAction<SpruceLine[]>>,
  stageScale: number,
  survey: PropertySurvey
) => {
  // If we are adding a complex room.
  if (drawingRooms || stageStep || scalingWindows || scalingDoor) {
    const pointerPosition = e.currentTarget.getRelativePointerPosition()!

    // Once we have made the line vertical or horizontal, snapped to the canvas or another object, we can set the value.
    const fakeRoom = generateComplexRoom(floor, survey, scalingPoints, 2.4)
    const snappedPoint = snapPoint(pointerPosition, [...floor.rooms, fakeRoom], stageScale, [], SNAPPING_TOLERANCE_PIXELS)

    const newPoint = snappedPoint?.newPoint ?? pointerPosition

    setMousePosition({ x: Math.round(newPoint.x / stageScale), y: Math.round(newPoint.y / stageScale) })
    snappedPoint ? setGuidelines(snappedPoint.guidelines) : setGuidelines([])
  }
}
