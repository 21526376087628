import { z } from 'zod'
import { type Company } from './models/company'
import { type InventoryHeatPump, type InventoryHotWaterCylinder, type InventoryPart } from './models/inventory'
import { type Labour } from './models/labour'
import { type Pack } from './models/packs'
import { priceCalculations } from './price_calculations'
import { type HydronicEmitterWithRoomAndWatts } from './models/pipes'
import { getEmitterSizeName, getEmitterTypeName } from './models/radiator'
import { roundTo2dp } from './number_format'

export enum CalculatedQuoteDefaultGroups {
  HEAT_PUMPS = 'Heat Pumps',
  HOT_WATER_CYLINDERS = 'Hot Water Cylinders',
  PARTS = 'Parts',
  RADIATORS = 'Radiators',
  UNDERFLOOR = 'Underfloor heating',
  LABOUR = 'Labour',
  SURVEY = 'Survey',
  GRANTS = 'Grants'
}

type CalculateQuoteProps = {
  company: Company
  selectedHeatPump?: InventoryHeatPump
  selectedHotWaterCylinder?: InventoryHotWaterCylinder
  defaultRadiatorChanges?: number
  selectedEmitters?: HydronicEmitterWithRoomAndWatts[]
  parts: InventoryPart[]
  labour: Labour[]
  packs: Pack[]
  isScottish?: boolean
  override?: CalculatedQuote
  additionalItemsFromEarlierStages?: QuoteItem[]
}

type SelectPacksProps = {
  packs: Pack[]
  selectedHeatPump?: InventoryHeatPump
  selectedHotWaterCylinder?: InventoryHotWaterCylinder
}

export const QuoteItemSchema = z.object({
  group_name: z.string(),
  uuid: z.string().optional(),
  lead_uuid: z.string().optional(),
  name: z.string(),
  description: z.string().nullable().optional(),
  quantity: z.coerce.number(),
  price: z.coerce.number(),
  include_vat: z.boolean(),
  image_url: z.string().nullable().optional(),
  subtotal: z.number().optional(),
  selected: z.boolean().optional()
})

export type QuoteItem = z.infer<typeof QuoteItemSchema>

export const CalculatedQuoteSchema = z.array(QuoteItemSchema)

export type CalculatedQuote = z.infer<typeof CalculatedQuoteSchema>

const addPartOrIncrementQuantity = (parts: QuoteItem[], part: InventoryPart): QuoteItem[] => {
  const existingPart = parts.find(x => x.uuid === part.uuid)
  if (existingPart) {
    existingPart.quantity++
    return parts
  }
  return [...parts, {
    group_name: 'Parts',
    uuid: part.uuid,
    name: part.name,
    description: part.description || '',
    price: priceCalculations.calculateCustomerPrice(part.cost_price, part.markup),
    quantity: 1,
    include_vat: false,
    selected: true
  }]
}

const addEmitterOrIncrementQuantity = (emitters: QuoteItem[], emitter: HydronicEmitterWithRoomAndWatts, company: Company): QuoteItem[] => {
  const emitterName = `${getEmitterTypeName(emitter)} ${getEmitterSizeName(emitter)}`
  const existingEmitter = emitters.find(x => x.name === emitterName)
  if (existingEmitter) {
    existingEmitter.quantity++
    return emitters
  }
  return [
    ...emitters,
    {
      group_name: emitter.emitter_type === 'RADIATOR' ? CalculatedQuoteDefaultGroups.RADIATORS : CalculatedQuoteDefaultGroups.UNDERFLOOR,
      uuid: emitter.uuid,
      name: emitterName,
      price: emitter.emitter_type === 'RADIATOR' ? company.default_radiator_cost ?? 0 : company.default_underfloor_cost ?? 0,
      quantity: emitter.emitter_type === 'RADIATOR' ? 1 : roundTo2dp(emitter.coverageM2 || 0) ?? 0,
      include_vat: false,
      selected: true
    } satisfies QuoteItem
  ]
}

export enum QuoteOverrideType {
  NONE,
  PARTIAL,
  FULL
}

// Quotes exist in three states post-migration in https://linear.app/spruce-eco/issue/SPR-1232/costs-and-inventory-enquiry-cost-estimate:
// 1. Quotes with additional line items (parts) saved in the estimate_quote_items table, which are a 'partial' override
//    since they're missing the heat pump, hot water cylinder, labour, survey, and grant.
// 2. Quotes overriden post-migration, with all line items saved in the estimate_quote_items table, which are a 'full' override.
// 3. Quotes calculated on the fly, which are not overrides.
// The second value in the return tuple, 'isOverridden', is used to determine whether the returned quote is a full override or not.
export const calculateQuote = ({ company, selectedHeatPump, selectedHotWaterCylinder, defaultRadiatorChanges, selectedEmitters, parts, labour, packs, isScottish, override, additionalItemsFromEarlierStages }: CalculateQuoteProps): [CalculatedQuote, QuoteOverrideType] => {
  const calculateInnerQuote = (selectedHeatPump?: InventoryHeatPump, selectedHotWaterCylinder?: InventoryHotWaterCylinder, additionalParts?: QuoteItem[], additionalItemsFromEarlierStages?: QuoteItem[]): [CalculatedQuote, QuoteOverrideType] => {
    const defaultParts = parts.filter(part => !part.deleted_at && part.default_include).map(part => ({
      group_name: CalculatedQuoteDefaultGroups.PARTS,
      uuid: part.uuid,
      name: part.name,
      description: part.description || '',
      price: priceCalculations.calculateCustomerPrice(part.cost_price, part.markup),
      quantity: 1,
      include_vat: false,
      selected: true
    }))
    const defaultLabour = labour.filter(labour => !labour.deleted_at && labour.default_include).map(labour => ({
      group_name: CalculatedQuoteDefaultGroups.LABOUR,
      uuid: labour.uuid,
      name: labour.name,
      description: labour.description || '',
      price: labour.cost_price,
      quantity: labour.days,
      include_vat: false,
      selected: true
    }))
    const defaultPacks = selectPacks({ packs, selectedHeatPump, selectedHotWaterCylinder })

    const explodedPacksCombinedWithDefaultParts = defaultPacks
      .flatMap((pack: Pack) => pack.parts || [])
      .reduce<QuoteItem[]>((acc, part) => addPartOrIncrementQuantity(acc, part), defaultParts)

    const additionalPartsFromEarlierStagesWhichDoNotAlreadyExist = additionalItemsFromEarlierStages?.filter(item => !explodedPacksCombinedWithDefaultParts.some(x => x.name === item.name)) || []

    const explodedPacksCombinedWithDefaultPartsAndAdditionalPartsFromEarlierStages = explodedPacksCombinedWithDefaultParts.concat(additionalPartsFromEarlierStagesWhichDoNotAlreadyExist)

    const calculatedQuote = [
      ...(selectedHeatPump ? [{
        group_name: CalculatedQuoteDefaultGroups.HEAT_PUMPS,
        uuid: selectedHeatPump.uuid,
        name: selectedHeatPump.name,
        price: priceCalculations.calculateCustomerPrice(selectedHeatPump.cost_price, selectedHeatPump.markup),
        quantity: 1,
        include_vat: false,
        selected: true,
        image_url: process.env.S3_BUCKET_URL + '/hp-images/' + selectedHeatPump.range_heat_pump_uuid + '.png'
      }] : []),
      ...(selectedHotWaterCylinder ? [{
        group_name: CalculatedQuoteDefaultGroups.HOT_WATER_CYLINDERS,
        uuid: selectedHotWaterCylinder.uuid,
        name: selectedHotWaterCylinder.name,
        price: priceCalculations.calculateCustomerPrice(selectedHotWaterCylinder.cost_price, selectedHotWaterCylinder.markup),
        quantity: 1,
        include_vat: false,
        selected: true
      }] : []),
      ...(defaultRadiatorChanges ? [{
        group_name: CalculatedQuoteDefaultGroups.RADIATORS,
        uuid: crypto.randomUUID(),
        name: 'Radiator',
        price: company.default_radiator_cost ?? 0,
        quantity: defaultRadiatorChanges,
        include_vat: false,
        selected: true
      }] : []),
      ...explodedPacksCombinedWithDefaultPartsAndAdditionalPartsFromEarlierStages,
      ...additionalParts || [],
      ...(selectedEmitters
        ? selectedEmitters.sort((a, b) => a.emitter_type.localeCompare(b.emitter_type)).reduce<QuoteItem[]>((acc, emitter) => addEmitterOrIncrementQuantity(acc, emitter, company), [])
        : []),
      ...defaultLabour,
      {
        group_name: CalculatedQuoteDefaultGroups.SURVEY,
        uuid: crypto.randomUUID(),
        name: 'Survey',
        price: company.survey_cost ?? 0,
        quantity: 1,
        include_vat: true,
        selected: true
      },
      ...(!isScottish ? [{
        group_name: CalculatedQuoteDefaultGroups.GRANTS,
        uuid: crypto.randomUUID(),
        name: 'BUS Grant',
        description: 'Boiler Upgrade Scheme grant',
        price: -7500,
        quantity: 1,
        selected: true,
        include_vat: false
      }] : [])
    ]
      // Calculate all subtotals
      .map(x => x.include_vat ? { ...x, subtotal: x.price * x.quantity * 1.2 } : { ...x, subtotal: x.price * x.quantity })

    // We set the second value, 'isOverridden', to false if we receive the 'additionalParts' argument,
    // because we only receive it when we need to calculate a full quote from a partial (parts-only) override
    // which came to us post-migration. We set isOverridden to true if we receive a full override.
    if (additionalParts && additionalParts.length > 0) {
      return [calculatedQuote, QuoteOverrideType.PARTIAL]
    } else {
      return [calculatedQuote, QuoteOverrideType.NONE]
    }
  }

  if (override?.length && override.length > 0) {
    // Post-migration in https://linear.app/spruce-eco/issue/SPR-1232/costs-and-inventory-enquiry-cost-estimate,
    // some of our quote overrides might contain only 'Parts' items,
    // because we were only able to migrate the estimate_line_items table, which equates
    // to parts - the heat pump, hot water cylinder, labour, survey, and grant were all
    // generated on the fly by the calculateEstimate function. So, if we have an override,
    // and the override contains only 'Parts' items, we need to calculate the missing
    // items and return the full quote.
    // TODO: If we ever end up archiving old estimates, we could remove this check
    // once we're sure that all estimates which require this check have been archived.
    const hasOnlyParts = override.every(item => item.group_name === CalculatedQuoteDefaultGroups.PARTS)
    if (hasOnlyParts && selectedHeatPump && selectedHotWaterCylinder) {
      return calculateInnerQuote(selectedHeatPump, selectedHotWaterCylinder, override, additionalItemsFromEarlierStages)
    }

    // We have a full override
    return [override.map(item => {
      return { ...item, subtotal: item.include_vat ? item.price * item.quantity * 1.2 : item.price * item.quantity }
    }), QuoteOverrideType.FULL]
  }

  return calculateInnerQuote(selectedHeatPump, selectedHotWaterCylinder, undefined, additionalItemsFromEarlierStages)
}

const selectPacks = ({ packs, selectedHeatPump, selectedHotWaterCylinder }: SelectPacksProps): Pack[] =>
  packs.filter(pack => {
    return pack.uuid === selectedHeatPump?.default_pack_uuid || pack.uuid === selectedHotWaterCylinder?.default_pack_uuid
  })

export const quoteItemNameWithQuantity = (quoteItem: QuoteItem, postfix?: string) => quoteItem.quantity > 1 ? `${quoteItem.name} × ${quoteItem.quantity}${postfix ? ` ${postfix}` : ''}` : quoteItem.name
