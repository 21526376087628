import type { PropertySurvey } from './property'

export type HeatingSystemAge = {
  name: string
  efficiency_percentage: number
}
export const HEATING_SYSTEM_AGES: HeatingSystemAge[] = [
  { name: 'Pre-1994', efficiency_percentage: 82 },
  { name: '1994-2007', efficiency_percentage: 87 },
  { name: 'Post 2007', efficiency_percentage: 92 }
]
export type HeatingSystemType = {
  uuid: string
  name: string
  fuel_uuid: string
  efficiency_percentage: number | undefined
}
export const HEATING_SYSTEM_TYPES: HeatingSystemType[] = [
  { uuid: 'mains_gas_combi_boiler', name: 'Combi Boiler', fuel_uuid: 'mains_gas', efficiency_percentage: undefined },
  { uuid: 'mains_gas_system_boiler', name: 'System Boiler', fuel_uuid: 'mains_gas', efficiency_percentage: undefined },
  { uuid: 'lpg_combi_boiler', name: 'Combi Boiler', fuel_uuid: 'lpg', efficiency_percentage: undefined },
  { uuid: 'lpg_system_boiler', name: 'System Boiler', fuel_uuid: 'lpg', efficiency_percentage: undefined },
  { uuid: 'oil_combi_boiler', name: 'Combi Boiler', fuel_uuid: 'oil', efficiency_percentage: undefined },
  { uuid: 'oil_system_boiler', name: 'System Boiler', fuel_uuid: 'oil', efficiency_percentage: undefined },
  { uuid: 'coal_stove', name: 'Solid Fuel Stove', fuel_uuid: 'coal', efficiency_percentage: 75 }, // SPR-1082: 75% is lowest value in MCS performance estimate, but highest value in SAP
  {
    uuid: 'electricity_air_source_heat_pump',
    name: 'Air source heat pump',
    fuel_uuid: 'electricity',
    efficiency_percentage: 250
  },
  {
    uuid: 'electricity_ground_source_heat_pump',
    name: 'Ground source heat pump',
    fuel_uuid: 'electricity',
    efficiency_percentage: 300
  },
  { uuid: 'electricity_boiler', name: 'Electric Boiler', fuel_uuid: 'electricity', efficiency_percentage: 100 },
  { uuid: 'electricity_storage_heater', name: 'Storage heaters', fuel_uuid: 'electricity', efficiency_percentage: 100 },
  { uuid: 'electricity_in_room_heater', name: 'In-room heaters', fuel_uuid: 'electricity', efficiency_percentage: 100 }
]
export const getExistingSystemEfficiencyFromSurvey = (survey: PropertySurvey): number => {
  const existingSystemType = HEATING_SYSTEM_TYPES.find(x => x.uuid === survey.existing_system_type_uuid)
  const existingSystemAge = HEATING_SYSTEM_AGES.find(x => x.name === survey.existing_system_age_uuid)
  return getHeatingSystemEfficiency(existingSystemType, existingSystemAge)
}
export const getHeatingSystemEfficiency = (heatingSystemType: HeatingSystemType | undefined, heatingSystemAge: HeatingSystemAge | undefined) => {
  if (heatingSystemType?.efficiency_percentage) return heatingSystemType.efficiency_percentage / 100
  return (heatingSystemAge?.efficiency_percentage ?? 0) / 100
}
