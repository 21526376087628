import React, { useContext, useState } from 'react'
import { SurveyActionType } from '../../../code/survey/survey'
import { Button } from '../../../components/buttons/button'
import { ArrowLeftIcon, ChevronRightIcon } from 'lucide-react'
import { type CompanyPublicInfo } from '../../../code/models/company'
import { logEvent } from '../../../code/log_event'
import { type EPCChangesType, getAddressIncludingPostcode } from '../../../code/models/lead'
import { StepHeader } from '../components/steps_header'
import { SurveyCheckbox } from '../components/survey_checkbox'
import { SurveyContext, SurveyDispatchContext } from '../survey_page'
import { formatDateMonthYear } from '../../../code/format_date'

export const EPCChangesStep = ({ companyPublicInfo }: { companyPublicInfo: CompanyPublicInfo }) => {
  // survey context and dispatch
  const survey = useContext(SurveyContext)
  const dispatch = useContext(SurveyDispatchContext)

  const [selectedOptions, setSelectedOptions] = useState<EPCChangesType>(survey.lead.epcChanges!)

  const handleToggleOption = (option: string, selected: boolean) => {
    const newState = { ...selectedOptions }

    // no changes option is a special case
    // if it's selected, we have to unselect all other options
    if (option === 'noChanges' && selected) {
      newState.insulation = false
      newState.loftConversion = false
      newState.extension = false
      newState.replacedWindows = false
      newState.other = false
    } else {
      // if any other option is selected, we have to unselect "no changes" option
      newState.noChanges = false
    }
    newState[option] = selected
    setSelectedOptions(newState)
  }

  const handleNavigatePreviousPage = () => {
    dispatch({
      type: SurveyActionType.NavigatePreviousPage
    })
  }

  const handleNavigateNextPage = () => {
    logEvent({ name: 'Changes since EPC step completed', properties: {} }, companyPublicInfo.subdomain)

    dispatch({
      type: SurveyActionType.NavigateNextPage,
      payload: {
        epcChanges: selectedOptions
      }
    })
  }

  return <>
    {/* Back button */}
    <Button colour='LIGHT' onClick={handleNavigatePreviousPage} block={false} className="self-start">
      <div className="flex items-center gap-x-2">
        <ArrowLeftIcon className="h-4 w-4" />
        <div>Back</div>
      </div>
    </Button>

    {/* Header */}
    <StepHeader
      guideText={getAddressIncludingPostcode(survey.lead)}
      headerText={`Have you made changes to your home since your last EPC
      (${formatDateMonthYear(survey.lead.epcData?.inspectionDate ?? survey.lead.epc_scotland?.inspection_date)})
      that impact your heating and hot water needs?`}
    />

    {/* Options */}
    <div className="w-full flex-col justify-start items-start gap-3 inline-flex">
      <SurveyCheckbox
        label="Improved insulation"
        onSelect={(v) => { handleToggleOption('insulation', v) }}
        selected={selectedOptions.insulation}
      />
      <SurveyCheckbox
        label="Loft conversion"
        onSelect={(v) => { handleToggleOption('loftConversion', v) }}
        selected={selectedOptions.loftConversion}
      />
      <SurveyCheckbox
        label="Extension"
        onSelect={(v) => { handleToggleOption('extension', v) }}
        selected={selectedOptions.extension}
      />
      <SurveyCheckbox
        label="Replaced windows"
        onSelect={(v) => { handleToggleOption('replacedWindows', v) }}
        selected={selectedOptions.replacedWindows}
      />
      <SurveyCheckbox
        label="No changes"
        onSelect={(v) => { handleToggleOption('noChanges', v) }}
        selected={selectedOptions.noChanges}
      />
    </div>

    <Button size='LG' disabled={
      // if no options are selected, disable the button
      !selectedOptions.noChanges &&
      !selectedOptions.insulation &&
      !selectedOptions.loftConversion &&
      !selectedOptions.extension &&
      !selectedOptions.replacedWindows &&
      !selectedOptions.other
    } onClick={handleNavigateNextPage}>
      <div className="flex items-center gap-x-2">
        <div>Continue</div>
        <ChevronRightIcon className="h-6 w-6" />
      </div>
    </Button>
  </>
}
