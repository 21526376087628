import React from 'react'
import { Heading } from '../../../../components/content_display/heading'
import { Text } from '../../../../components/content_display/text'

type Props = {
  title: string
  description: string
  icon?: React.ReactNode
  button?: React.ReactNode
}

export const EmptySection = ({ title, description, icon, button }: Props) => {
  return (
    <div className="px-6 py-20 rounded-lg bg-gray-50 flex-col justify-center items-center gap-4 flex w-full">
      <div className="flex-col justify-center items-center gap-1 flex">
        <Heading size="xl">{title}</Heading>
        <Text size="SM" className='text-gray-500'>{description}</Text>
      </div>
      {button}
    </div>
  )
}
