import React, { type Dispatch, type SetStateAction } from 'react'
import { Checkbox } from '../inputs_and_selections/checkbox'

export type TableProps<T> = {
  columns: Array<TableColumn<T>>
  rows: T[]
  rowIdName?: keyof T
  selectedIds?: string[]
  setSelectedIds?: Dispatch<SetStateAction<string[]>>
  size?: 'SM' | 'MD'
  showCheckboxes?: boolean
  alignRight?: boolean
}

export type TableColumn<T> = {
  name: string
  key?: keyof T
  render?: (row: T) => JSX.Element
}

const sizes = {
  SM: 'text-xs',
  MD: 'text-sm'
}

export const TableLite = <T extends {}>({ selectedIds, setSelectedIds, columns, rows, rowIdName, size = 'MD', showCheckboxes = true, alignRight = true }: TableProps<T>) => {
  const toggleSelected = setSelectedIds && ((id: string) => setSelectedIds(prev => prev.includes(id) ? prev.filter(x => x !== id) : [...prev, id]))
  const allChecked = selectedIds && rowIdName && rows.every(x => selectedIds.includes(x[rowIdName] as string))

  return <table className={`${sizes[size]} text-gray-600 w-full sm:divide-y border-light`}>
    <thead className='uppercase tracking-wide text-xs text-gray-500 font-semibold hidden sm:table-header-group'>
      <tr>
        {showCheckboxes && setSelectedIds && rowIdName && <th className='py-2 text-start'><Checkbox checked={!!allChecked} setChecked={() => allChecked ? setSelectedIds([]) : setSelectedIds(rows.map(x => x[rowIdName] as string))} /></th>}
        {columns.map((x, i) => <th key={i} className={`p-2 first:px-0 last:px-0 ${alignRight ? i === 0 ? 'text-start' : 'sm:text-end' : 'text-start'}`}>{x.name}</th>)}
      </tr>
    </thead>
    <tbody className='sm:divide-y sm:border-light'>
      {rows.map((x, xi) => {
        return <tr key={xi} className='flex flex-col sm:table-row border border-light rounded sm:border-0 mb-2 p-2 sm:mb-0 sm:p-0'>
          {showCheckboxes && selectedIds && toggleSelected && rowIdName && <td className='p-2'><Checkbox checked={selectedIds.includes(x[rowIdName] as string)} setChecked={() => toggleSelected(x[rowIdName] as string)} /></td>}
          {columns.map((y, yi) => <td key={yi} className={`grid grid-cols-[1fr_2fr] sm:table-cell border-b last:border-0 border-gray-100 sm:border-0 ${alignRight ? yi === 0 ? 'text-start' : 'sm:text-end' : 'text-start'} py-2 sm:p-2 sm:first:ps-0 sm:last:pe-0`}>
            {/* Mobile-only headers */}
            {(x[y.key!] as string !== undefined) && <span className='sm:hidden uppercase tracking-wide text-xs text-gray-500 font-semibold'>{columns[yi].name}</span>}
            {y.render ? y.render(x) : <div>{x[y.key!] as string}</div>}
          </td>)}
        </tr>
      })}
    </tbody>
  </table>
}
