import React, { useContext } from 'react'
import { SurveyActionType } from '../../../code/survey/survey'
import { Caption } from '../components/caption_1'
import { Button } from '../../../components/buttons/button'
import { type CompanyPublicInfo } from '../../../code/models/company'
import { logEvent } from '../../../code/log_event'
import { ColoredBlockWrapper } from '../components/colored_block_wrapper'
import { StepHeader } from '../components/steps_header'
import { SurveyDispatchContext } from '../survey_page'
import { ChevronRightIcon } from 'lucide-react'

export const NoEPCFoundStep = ({ companyPublicInfo }: { companyPublicInfo: CompanyPublicInfo }) => {
  // survey context and dispatch
  const dispatch = useContext(SurveyDispatchContext)

  const handleNavigateNextPage = () => {
    logEvent({ name: 'No EPC found step completed', properties: {} }, companyPublicInfo.subdomain)

    dispatch({
      type: SurveyActionType.NavigateNextPage
    })
  }

  return <>
    {/* Header */}
    <StepHeader
      headerText="We cannot find an energy certificate for your home"
    />

    {/* Controls */}
    <ColoredBlockWrapper color="blue">
      <div className="flex flex-col gap-6">
        <Caption content="Please tell us about your home in the following steps." />
        <Button size='LG' onClick={handleNavigateNextPage}>
          <div className="flex items-center gap-x-2">
            <div>Tell us about your home</div>
            <ChevronRightIcon className="h-6 w-6" />
          </div>
        </Button>
      </div>
    </ColoredBlockWrapper>
  </>
}
