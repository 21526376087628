import React, { useContext } from 'react'
import { Map, Marker } from '@vis.gl/react-google-maps'
import { SurveyContext, SurveyDispatchContext } from '../survey_page'
import { Header } from '../components/header'
import { Button } from '../../../components/buttons/button'
import { ChevronRightIcon } from 'lucide-react'
import { SurveyActionType } from '../../../code/survey/survey'

export const ConfirmLocationStep: React.FC = () => {
  const survey = useContext(SurveyContext)
  const dispatch = useContext(SurveyDispatchContext)
  const initialLatLng = { lat: survey.lead.property.postcodeLocation?.[0] || 0, lng: survey.lead.property.postcodeLocation?.[1] || 0 }

  const [latLng, setLatLng] = React.useState<{ lat: number, lng: number }>({ lat: initialLatLng.lat, lng: initialLatLng.lng })

  const handleOnDragEnd = (event: google.maps.MapMouseEvent) => {
    setLatLng({ lat: event.latLng?.lat() || initialLatLng.lat, lng: event.latLng?.lng() || initialLatLng.lng })
  }

  const handleNavigateNextPage = () => {
    dispatch({
      type: SurveyActionType.NavigateNextPage,
      payload: {
        lead: {
          ...survey.lead,
          property: {
            ...survey.lead.property,
            postcodeLocation: [latLng.lat, latLng.lng]
          }
        }
      }
    })
  }

  return (
    <>
      <div className="flex flex-col gap-3">
        <Header content="Confirm the location of your property" />
        <div className="text-gray-600">Drag the pin on the map below to the exact location of your property.</div>
      </div>

      <div className="space-y-3">
        {initialLatLng &&
        <Map
          zoom={18}
          className="min-h-[400px] w-full h-full rounded overflow-hidden"
          mapTypeId="satellite"
          center={initialLatLng}
        >
          <Marker
            position={latLng.lat === 0 && latLng.lng === 0 ? initialLatLng : latLng}
            draggable={true}
            onDragEnd={handleOnDragEnd}
          />
        </Map>
        }
      </div>

      <Button size='LG' onClick={handleNavigateNextPage}>
        <div className="flex items-center gap-x-2">
          <div>Confirm location</div>
          <ChevronRightIcon className="h-5 w-5" />
        </div>
      </Button>
    </>
  )
}
