import type { PropertySurvey } from './property'
import {
  getHeatPumpCapacityAtOutsideTempAndFlowTemp,
  getHeatPumpScopAtFlowTemp,
  type RangeHeatPump
} from './range_heat_pump'
import { apiUrl, axiosDelete, axiosPatch, axiosPost, client } from '../axios'
import { AuthSDK } from '../utils/auth_provider'
import { type InventoryHotWaterCylinder, InventoryHotWaterCylinderSchema } from './inventory'
import { parseWithZod } from '../zod'

export const getHotWaterCylinders = async (companyUUID: string): Promise<InventoryHotWaterCylinder[] | undefined> => {
  try {
    const result = await client.get<{ data: Array<{ uuid: string, attributes: Record<any, any> }> }>(`${apiUrl}teams/${companyUUID}/hot-water-cylinders`,
      { headers: { 'x-auth-token': AuthSDK.getToken() } }
    )
    const parsedResult = result.data.data.map(x => parseWithZod<InventoryHotWaterCylinder>(InventoryHotWaterCylinderSchema, ({ ...x.attributes, uuid: x.uuid })))
    return parsedResult
  } catch (e: unknown) {
    console.error('Error getting HotWaterCylinders', e)
    return undefined
  }
}

export const updateHotWaterCylinders = async (hotWaterCylinder: InventoryHotWaterCylinder, companyUUID: string) => {
  await axiosPatch(
    `teams/${companyUUID}/hot-water-cylinders/${hotWaterCylinder.uuid}`,
    {
      name: hotWaterCylinder.name,
      description: hotWaterCylinder.description,
      cost_price: hotWaterCylinder.cost_price,
      markup: hotWaterCylinder.markup,
      litres: hotWaterCylinder.litres,
      default_pack_uuid: hotWaterCylinder.default_pack_uuid
    },
    hotWaterCylinder.uuid,
    'hot-water-cylinders'
  )
}

export const insertHotWaterCylinders = async (hotWaterCylinder: InventoryHotWaterCylinder, companyUUID: string) => {
  return await axiosPost(
    `teams/${companyUUID}/hot-water-cylinders`,
    {
      uuid: hotWaterCylinder.uuid,
      name: hotWaterCylinder.name,
      description: hotWaterCylinder.description,
      cost_price: hotWaterCylinder.cost_price,
      markup: hotWaterCylinder.markup,
      litres: hotWaterCylinder.litres,
      default_pack_uuid: hotWaterCylinder.default_pack_uuid
    },
    'hot-water-cylinders'
  )
}

export const deleteHotWaterCylinders = async (hotWaterCylinderUUID: string | number, companyUUID: string) => {
  await axiosDelete(
    `teams/${companyUUID}/hot-water-cylinders/${hotWaterCylinderUUID}`
  )
}

const VOLUMETRIC_HEAT_WATER_J_PER_LITRE_K = 4187
const J_PER_KWH = 3.6e+6

// Later could make these user inputs
const MAINS_WATER_INPUT_TEMP_C = 10
const LEGIONELLA_SET_POINT_C = 60
const HOT_WATER_DISTRIBUTION_EFFICIENCY = 0.7
export const HOT_WATER_FLOW_TEMP_DELTA_T_C = 5

export type CylinderReheatCalculationRow = {
  coldWaterTempC: number
  hotWaterStorageTempC: number
  flowTempC: number
  outsideTempC: number
  heatingPowerKw: number
  cylinderVolumeL: number
  reheatTimeHoursAndMinutes: string
  capacityWarning?: string
}

export const getCylinderReheatCalculationRow = (
  currentHotWaterCylinder: InventoryHotWaterCylinder | undefined,
  hotWaterStorageTempC: number,
  rangeHeatPump: RangeHeatPump | undefined,
  designTempC: number
): CylinderReheatCalculationRow => {
  const flowTempC = getHotWaterFlowTemp(hotWaterStorageTempC)
  const capacityResult = getHeatPumpCapacityAtOutsideTempAndFlowTemp(rangeHeatPump, designTempC, flowTempC)
  const coldWaterTempC = MAINS_WATER_INPUT_TEMP_C
  const cylinderVolumeL = currentHotWaterCylinder?.litres ?? 0
  // 14.3 = seconds per minute * (watts per kw) / (density of water in kg per litre * heat capacity of water in joules per kg kelvin)
  const reheatTimeMinutes = (cylinderVolumeL * (hotWaterStorageTempC - MAINS_WATER_INPUT_TEMP_C)) / (14.3 * capacityResult.capacityKw)
  const reheatTimeHoursAndMinutes = reheatTimeMinutes ? `${Math.floor(reheatTimeMinutes / 60)} h ${(reheatTimeMinutes % 60).toFixed(0)} min` : ''
  return {
    coldWaterTempC,
    hotWaterStorageTempC,
    flowTempC: capacityResult.flowTempC,
    outsideTempC: capacityResult.outsideTempC,
    heatingPowerKw: capacityResult.capacityKw,
    cylinderVolumeL,
    reheatTimeHoursAndMinutes,
    capacityWarning: capacityResult.warning ? `We don't have capacity data at the flow temp required for this hot water storage temperature  (${flowTempC} °C flow, ${designTempC} °C air). We've used the closest value we have (${capacityResult.flowTempC} °C flow, ${capacityResult.outsideTempC} °C air). Please get in touch if you think we should have this data!` : undefined
  }
}

export const getHotWaterFlowTemp = (hotWaterStorageTempC: number): number => {
  return hotWaterStorageTempC + HOT_WATER_FLOW_TEMP_DELTA_T_C
}

export type HotWaterCalculationRow = {
  coldWaterTempC: number
  hotWaterTempC: number
  distributionEfficiency: number
  heatEnergyKwhPerCycle: number
  heatingEfficiency: number
  flowTempC: number
  electricityKwhPerCycle: number
  cyclesPerYear: number
  electricityKWhPerYear: number
}

export const getHotWaterCalculationRowNormalOperation = (
  rangeHeatPump: RangeHeatPump | undefined,
  dailyHotWaterVolumeL: number,
  hotWaterStorageTempC: number
): HotWaterCalculationRow => {
  const flowTempHotWaterCycleC = getHotWaterFlowTemp(hotWaterStorageTempC)
  const scopHotWaterCalculated = getHeatPumpScopAtFlowTemp(rangeHeatPump, flowTempHotWaterCycleC)
  const scopHotWater = scopHotWaterCalculated > 0 ? scopHotWaterCalculated : 2.5 // use 2.5 as a fallback when range heat pump not defined. Should only happen for old estimates

  return getHotWaterCalculationRows(
    MAINS_WATER_INPUT_TEMP_C,
    hotWaterStorageTempC,
    dailyHotWaterVolumeL,
    HOT_WATER_DISTRIBUTION_EFFICIENCY,
    scopHotWater,
    365
  )
}

export const getHotWaterCalculationRowLegionella = (
  hotWaterStorageTempC: number,
  cylinderVolumeL: number,
  legionellaCycleIntervalWeeks: number
): HotWaterCalculationRow => {
  return getHotWaterCalculationRows(
    hotWaterStorageTempC,
    LEGIONELLA_SET_POINT_C,
    cylinderVolumeL,
    HOT_WATER_DISTRIBUTION_EFFICIENCY,
    1, // Later: could edit this but often don't have MCS COP data at flow 65
    52 / legionellaCycleIntervalWeeks
  )
}

export const getHotWaterCalculationRows = (
  coldWaterTempC: number,
  hotWaterTempC: number,
  volumeL: number,
  distributionEfficiency: number,
  heatingEfficiency: number,
  cyclesPerYear: number
): HotWaterCalculationRow => {
  const heatEnergyKwhPerCycle = volumeL * (hotWaterTempC - coldWaterTempC) * VOLUMETRIC_HEAT_WATER_J_PER_LITRE_K / distributionEfficiency / J_PER_KWH
  const electricityKwhPerCycle = heatEnergyKwhPerCycle / heatingEfficiency
  const electricityKWhPerYear = electricityKwhPerCycle * cyclesPerYear
  return {
    coldWaterTempC,
    hotWaterTempC,
    distributionEfficiency,
    heatEnergyKwhPerCycle,
    heatingEfficiency,
    flowTempC: getHotWaterFlowTemp(hotWaterTempC),
    electricityKwhPerCycle,
    cyclesPerYear,
    electricityKWhPerYear
  }
}

export const getDailyHotWaterVolumeL = (numberOfOccupants: number, volumePerPersonPerDay: number) => {
  return numberOfOccupants * volumePerPersonPerDay
}

export const getNumberOfOccupants = (survey: PropertySurvey) => {
  return survey.occupants_override ?? getNumberOfOccupantsNoOverride(survey.bedrooms)
}

export const getNumberOfOccupantsNoOverride = (numberOfBedrooms: number) => {
  return numberOfBedrooms + 1
}
