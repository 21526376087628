// Figma link: https://www.figma.com/design/8qckV47cTJMgQVf2N5m8Vf/Design-System---Spruce?node-id=5397-8709&m=dev

import React from 'react'

export const colours = {
  DARK: 'text-gray-900',
  LIGHT: 'text-gray-300'
}

type ListItemProps = {
  onClick?: () => void // covers whole card if rightIcon or actionButton is not provided, otherwise just on rightIcon
  topBadge?: JSX.Element
  leftIcon?: JSX.Element // shouldn't be clickable
  primaryText: string
  primaryTextColour?: keyof typeof colours
  secondaryText?: string
  rightClickableIcon?: JSX.Element // can be clickable
  rightBadge?: JSX.Element
  actionButton?: JSX.Element // can be clickable
  figure?: JSX.Element

}

export const ListItem = ({ onClick, topBadge, leftIcon, primaryText, primaryTextColour, secondaryText, rightClickableIcon, rightBadge, actionButton, figure }: ListItemProps) => {
  const allClickable = !actionButton && !rightClickableIcon && onClick // if no clickable content on right - make all clickable
  const onlyMiddleClickable = (actionButton || rightClickableIcon) && onClick // if no clickable content at all - make only middle clickable

  return <div
    className={`p-4 justify-between items-center gap-4 flex first:border-none border-t border-gray-200 ${allClickable ? 'cursor-pointer' : ''}`}
    onClick={allClickable ? onClick : undefined}>
    {/* Left icon */}
    {leftIcon && <div className="p-2 bg-gray-100 rounded-xl gap-2.5 flex items-center justify-center w-10 h-10">
      {leftIcon}
    </div>}
    {/* Central block - flex-grow means this takes up remaining space so the onClick covers up to the right content */}
    <div
      className={`flex flex-col flex-grow ${onlyMiddleClickable ? 'cursor-pointer' : ''}`}
      onClick={!allClickable ? onClick : undefined}
    >
      {topBadge && <div>{topBadge}</div>}
      <div className={`${primaryTextColour ? colours[primaryTextColour] : colours.DARK} text-base font-bold`}>{primaryText}</div>
      {secondaryText && <div className=" text-gray-500 text-xs tracking-tight">{secondaryText}</div>}
    </div>
    {/* Right content */}
    <div className='flex gap-4 items-center'>
      {rightBadge && <div className={onlyMiddleClickable ? 'cursor-pointer' : ''} onClick={onlyMiddleClickable ? onClick : undefined}>{rightBadge}</div>}
      {actionButton && <div>{actionButton}</div>}
      {figure && <div>{figure}</div>}
      {rightClickableIcon && <div>{rightClickableIcon}</div>}
    </div>
  </div>
}
