import { z } from 'zod'
import { axiosDeleteV2, axiosPatchV2, axiosPostV2 } from '../axios'

export const ProposalLinkSchema = z.object({
  uuid: z.string(),
  proposal_uuid: z.string(),
  title: z.string(),
  url: z.string(),
  internal: z.boolean(),
  category: z.enum(['terms_and_conditions', 'heat_pump_spec', 'hot_water_cylinder_spec', 'custom'])
})

export type ProposalLink = z.infer<typeof ProposalLinkSchema>

export const insertProposalLink = async (proposalLink: ProposalLink, proposalUUID: string, leadUUID: string, companyUUID: string) => {
  await axiosPostV2(`teams/${companyUUID}/leads/${leadUUID}/proposals/${proposalUUID}/links`, { ...proposalLink, proposal_uuid: proposalUUID })
}

export const updateProposalLink = async (proposalLink: ProposalLink, proposalUUID: string, leadUUID: string, companyUUID: string) => {
  await axiosPatchV2(`teams/${companyUUID}/leads/${leadUUID}/proposals/${proposalUUID}/links/${proposalLink.uuid}`, { ...proposalLink, proposal_uuid: proposalUUID })
}

export const deleteProposalLink = async (proposalLinkUUID: string, proposalUUID: string, leadUUID: string, companyUUID: string) => {
  await axiosDeleteV2(`teams/${companyUUID}/leads/${leadUUID}/proposals/${proposalUUID}/links/${proposalLinkUUID}`)
}
