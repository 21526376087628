import React from 'react'
import { HLRContext } from './heatloss_report'
import { HLRSubheaderBlock } from './partials/hlr_subheader'
import { ClickableCard } from '../../../components/content_display/card'
import { ProgressChart } from '../../../components/indicators_and_messaging/progress_chart'
import { sum } from 'lodash'
import { type TableColumn, TableLite } from '../../../components/content_display/table_lite'
import { type Room } from '../../../code/models/room'
import { type Floor } from '../../../code/models/floor'
import {
  combineHeatLossesForProgressChart,
  combineSortConductionHeatLosses,
  combineVentilationHeatLosses,
  getAverageRoomHeightM,
  getConductionHeatLossAllElements,
  getFloorAreaM2,
  getRoomACH,
  getRoomTemp,
  getRoomWatts,
  getVentilationHeatLoss
} from '../../../code/models/heat_loss'
import { getEmitterSizeName, getEmitterTypeName } from '../../../code/models/radiator'
import { HLRFloorplanBlock } from './partials/hlr_floorplan'
import { RadiatorIcon, UFHIcon } from '../../../assets/images/survey_images/survey_images'
import { numberFormat } from '../../../code/number_format'
import { getEmitterWatts } from '../../../code/models/radiator_model'

type Props = {
  floor: Floor
  room: Room
}

export const HLRRoomHeatlossPage = ({ floor, room }: Props) => {
  const hlrContext = React.useContext(HLRContext)

  const roomHeatLoss = Math.round(getRoomWatts(room, floor.rooms, hlrContext!.designTempC, hlrContext!.groundTempC, hlrContext!.survey))
  const roomACH = numberFormat(1).format(getRoomACH(room, hlrContext!.survey))
  const roomFloorAreaNum = getFloorAreaM2(room.walls)
  const roomFloorArea = numberFormat(1).format(roomFloorAreaNum)
  const roomAvgHeight = getAverageRoomHeightM(room)
  const roomVolume = numberFormat(1).format(roomAvgHeight * parseFloat(roomFloorArea))

  const conductionHeatLossRows = getConductionHeatLossAllElements(room, floor.rooms, hlrContext!.designTempC, hlrContext!.groundTempC, hlrContext!.survey)
  const ventilationHeatLoss = getVentilationHeatLoss(room, hlrContext!.designTempC, hlrContext!.survey)

  const heatLossForChart = combineHeatLossesForProgressChart(conductionHeatLossRows, [ventilationHeatLoss])

  const conductionHeatLossRowsCombinedForTable = combineSortConductionHeatLosses(conductionHeatLossRows, false)
  const ventilationHeatLossCombinedForTable = combineVentilationHeatLosses([ventilationHeatLoss])

  const elements = conductionHeatLossRowsCombinedForTable.map(x => ({
    name: x.elementName,
    temp: numberFormat(1).format(x.otherSideTempC) + ' °C',
    uValue: numberFormat(2).format(x.uValueWPerM2K) + ' W/m²K',
    area: numberFormat(1).format(x.areaM2) + ' m²',
    heatLoss: x.watts
  })).concat(ventilationHeatLossCombinedForTable.map(x => ({
    name: x.elementName,
    temp: numberFormat(1).format(x.externalTempC) + ' °C',
    uValue: '',
    area: '',
    heatLoss: x.watts
  })))

  const elementsTableColumns: Array<TableColumn<typeof elements[0]>> = [
    {
      name: '',
      key: 'name',
      render: (row) => <span className='text-gray-900 text-xs font-bold'>{row.name}</span>
    },
    { name: 'Other side temperature', key: 'temp', render: (row) => <span className='text-xs font-semibold'>{row.temp}</span> },
    { name: 'U-value', key: 'uValue', render: (row) => <span className='text-xs font-semibold'>{row.uValue}</span> },
    { name: 'Area', key: 'area', render: (row) => <span className='text-xs font-semibold'>{row.area}</span> },
    { name: 'Heat loss', key: 'heatLoss', render: (row) => <span className='text-xs font-semibold'>{numberFormat(0).format(row.heatLoss)} W</span> }
  ]

  const emitters = room.radiators.map(radiator => {
    const images = radiator.photos.map(x => hlrContext!.files.find(y => y.uuid === x.image_uuid)!)
    const radiatorWatts = Math.round(getEmitterWatts(radiator, room, hlrContext!.design, hlrContext!.survey, hlrContext!.designTempC, hlrContext!.groundTempC))

    return {
      type: radiator.emitter_type === 'UNDERFLOOR' ? 'UFH' : radiator.emitter_type === 'SECONDARY' ? 'Secondary' : 'Radiator',
      image: images[0]
        ? <img src={images[0].file_base64 || `${process.env.S3_BUCKET_URL}/${images[0].file_url}`} alt='' className='w-10 h-10 rounded'/>
        : radiator.emitter_type === 'UNDERFLOOR'
          ? <img alt='' src={UFHIcon} className="p-2 w-10 h-10 rounded bg-gray-100" />
          : <img alt='' src={RadiatorIcon} className="p-2 w-10 h-10 rounded bg-gray-100" />,
      details: <div className='flex flex-col gap-1'>
        <div>{getEmitterTypeName(radiator)}</div>
        <div>{getEmitterSizeName(radiator)}</div>
      </div>,
      emitterOutput: radiatorWatts,
      roomDemand: roomHeatLoss,
      percentMet: Math.round((radiatorWatts / roomHeatLoss) * 100),

      // the roomOutputStub is a placeholder for the table as it requires a key.
      // the real value in the cell rendered NOT from this object
      roomOutputStub: 0
    }
  })

  const roomOutput = emitters.reduce((acc, radiator) => acc + radiator.emitterOutput, 0)
  const emittersTotalPercent = sum(emitters.map(x => x.percentMet))

  const emittersTableColumns: Array<TableColumn<typeof emitters[0]>> = [
    {
      name: 'Emitter',
      key: 'type',
      render: (row) => <span className='text-gray-900 text-xs font-bold'>{row.type}</span>
    },
    { name: 'Image', key: 'image', render: (row) => <div className='flex justify-end'>{row.image}</div> },
    { name: 'Details', key: 'details', render: (row) => <span className='text-xs font-semibold'>{row.details}</span> },
    { name: 'Emitter output', key: 'emitterOutput', render: (row) => <span className='text-xs font-semibold'>{row.emitterOutput} W</span> },
    { name: 'Room output', key: 'roomOutputStub', render: () => <span className='text-xs font-semibold'>{roomOutput} W</span> },
    { name: 'Room demand', key: 'roomDemand', render: (row) => <span className='text-xs font-semibold'>{row.roomDemand} W</span> },
    {
      name: '% heat demand met*',
      key: 'percentMet',
      render: (row) => {
        // `inline-block` prevents span from taking full width
        return <span className='inline-block'>
          {row.percentMet.toString() + ' %'}
        </span>
      }
    }
  ]

  const totalHeatLossW = Math.round(sum([...conductionHeatLossRowsCombinedForTable, ...ventilationHeatLossCombinedForTable].map(x => x.watts)))
  const roomTemp = getRoomTemp(room, hlrContext!.survey)

  return (
    <div className="flex-col gap-6 flex">
      <div className="flex-col print:flex-row md:flex-row gap-6 flex">
        <div className='grow flex flex-col gap-4'>
          <HLRSubheaderBlock
            section="Heat loss by room"
            title={room.name}
          />
          <div className='grid grid-cols-3 gap-3'>
            <div className='p-3 bg-gray-100 rounded-md flex flex-col'>
              <div className="text-xs tracking-tight">Total heat loss</div>
              <div className='flex flex-row gap-1 items-end'>
                <div className="text-gray-900 text-xl font-bold">{roomHeatLoss}</div>
                <div className="text-gray-900 text-lg font-semibold">W</div>
              </div>
            </div>
            <div className='p-3 bg-gray-100 rounded-md'>
              <div className="text-xs tracking-tight">Room temp</div>
              <div className='flex flex-row gap-1 items-end'>
                <div className="text-gray-900 text-xl font-bold">{roomTemp}</div>
                <div className="text-gray-900 text-lg font-semibold">°C</div>
              </div>
            </div>
            <div className='p-3 bg-gray-100 rounded-md'>
              <div className="text-xs tracking-tight">Air changes per hour</div>
              <div className='flex flex-row gap-1 items-end'>
                <div className="text-gray-900 text-xl font-bold">{roomACH}</div>
              </div>
            </div>
            <div className='p-3 bg-gray-100 rounded-md'>
              <div className="text-xs tracking-tight">Floor area</div>
              <div className='flex flex-row gap-1 items-end'>
                <div className="text-gray-900 text-xl font-bold">{roomFloorArea}</div>
                <div className="text-gray-900 text-xl font-bold">m²</div>
              </div>
            </div>
            <div className='p-3 bg-gray-100 rounded-md'>
              <div className="text-xs tracking-tight">Average height</div>
              <div className='flex flex-row gap-1 items-end'>
                <div className="text-gray-900 text-xl font-bold">{numberFormat(1).format(roomAvgHeight)}</div>
                <div className="text-gray-900 text-xl font-bold">m</div>
              </div>
            </div>
            <div className='p-3 bg-gray-100 rounded-md'>
              <div className="text-xs tracking-tight">Volume</div>
              <div className='flex flex-row gap-1 items-end'>
                <div className="text-gray-900 text-xl font-bold">{roomVolume}</div>
                <div className="text-gray-900 text-xl font-bold">m³</div>
              </div>
            </div>
          </div>
        </div>
        <div className='md:w-60 bg-gray-100'>
          <HLRFloorplanBlock floor={floor} room={room} height={240}/>
        </div>
      </div>

      <ClickableCard variant='GREY'>
        <div className="gap-3 flex justify-between">
          <div className="text-gray-900 text-lg font-bold ">Heat loss by element</div>
          <div className="text-lg">{numberFormat(0).format(totalHeatLossW)} W</div>
        </div>
        <ProgressChart items={heatLossForChart} total={totalHeatLossW}/>
      </ClickableCard>
      <TableLite size='SM' rows={elements} columns={elementsTableColumns}/>
      <div className='flex justify-between font-semibold'>
        <div>Heat loss per m²</div>
        <div>{Math.round(totalHeatLossW / roomFloorAreaNum)} W/m²</div>
      </div>

      {emitters.length > 0 && <>
        <ClickableCard variant='GREY'>
          <div className="gap-3 flex justify-between">
            <div className="text-gray-900 text-lg font-bold ">Emitter performance</div>
            <div className="text-lg">{emittersTotalPercent}% of {totalHeatLossW} W at {hlrContext!.design.flow_temp} °C
            </div>
          </div>

          <ProgressChart
            items={emitters.map(x => ({
              name: x.type,
              value: x.percentMet
            }))}
            total={100}
            colorVariant={'EMITTER'}
          />
        </ClickableCard>
        <TableLite size='SM' rows={emitters} columns={emittersTableColumns}/>
        <div className="">
          <span className="text-gray-900 text-xs font-bold">*% Heat demand met: </span>
          <span
            className="text-xs tracking-tight">This is calculated for a day when the outdoor temperature is {numberFormat(1).format(hlrContext!.designTempC)} °C and the flow temperature is {numberFormat(0).format(hlrContext!.design.flow_temp)} °C</span>
        </div>
      </>}

    </div>
  )
}
