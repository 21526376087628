import React from 'react'
import { SurveyElectricsContext } from '../electrics'
import { FlowWrapperContext } from '../components/survey_section_flow_wrapper'
import { PhotoScroll } from '../../../../components/inputs_and_selections/photo_scroll'

export const ElectricsConsumerUnitPhotosBlock = () => {
  const electricsContext = React.useContext(SurveyElectricsContext)
  const flowContext = React.useContext(FlowWrapperContext)

  const { survey, setSurvey, files, setFiles, companyUUID } = electricsContext!

  const consumerUnitImages = survey.electrics_consumer_unit_images.map(x => files.find(y => y.uuid === x.image_uuid)!)

  const deleteConsumerUnitImages = (imageUUID: string) => {
    setSurvey({ ...survey, electrics_consumer_unit_images: survey.electrics_consumer_unit_images.filter(x => x.image_uuid !== imageUUID) })
  }

  const isFullScreen = consumerUnitImages.length === 0 && flowContext!.viewMode === 'FLOW'

  return <>
    <div className={isFullScreen ? 'p-5 h-full bg-gray-50 flex flex-col items-center justify-center gap-4' : 'flex flex-col gap-4'}>
      <div className={`${isFullScreen ? 'items-center' : ''} flex flex-col gap-1`}>
        <div className="text-gray-900 text-xl font-bold">Consumer unit</div>
        <div className={`${isFullScreen ? 'text-center' : ''} text-gray-500 text-sm`}>Capture photos of the consumer unit</div>
      </div>

      <div className="flex-col gap-3 flex">
        {!isFullScreen && <div className="text-gray-900 font-bold">Photos</div>}
        <PhotoScroll viewMode={isFullScreen ? 'COMPACT' : 'NORMAL'} images={consumerUnitImages} addImage={(fileWrapper) => {
          fileWrapper && setFiles(prev => [...prev, { ...fileWrapper, created_at: new Date().getTime(), updated_at: new Date().getTime(), is_modified: true }])
          fileWrapper && setSurvey({
            ...survey,
            electrics_consumer_unit_images: [...survey.electrics_consumer_unit_images, {
              uuid: crypto.randomUUID(),
              entity_uuid: 'survey',
              entity_attribute: 'electrics_consumer_unit_images',
              image_uuid: fileWrapper.uuid!
            }]
          })
        }} deleteImage={deleteConsumerUnitImages} companyUUID={companyUUID} />
      </div>
    </div>
  </>
}
