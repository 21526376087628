import type { CustomAttributes, DoorAttributes, Material } from './material'

export type Door = {
  uuid?: string
  width_mm: number
  height_mm: number
  width_m?: number // TODO later: remove once migrated
  height_m?: number // TODO later: remove once migrated
  material: Material | undefined
  created_at: number
  updated_at: number
}

export const getDoorUValue = (door: Door, thermalBridgingUValueAdditionWPerM2K: number) => {
  // If this is actually an internal door we shouldn't really apply thermal bridging, but it doesn't actually matter as it will net out to 0
  if (['custom_layered', 'custom_simple'].includes(door.material!.type)) {
    return (door.material!.extra_data as CustomAttributes).u_value! + thermalBridgingUValueAdditionWPerM2K
  }
  return (door.material!.extra_data as DoorAttributes).u_value + thermalBridgingUValueAdditionWPerM2K
}
