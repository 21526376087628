import React from 'react'
import { Map, Marker } from '@vis.gl/react-google-maps'
import { SurveySiteDetailsContext } from '../site_details'
import { getAddressIncludingPostcode } from '../../../../code/models/lead'

export const SiteDetailsLocationBlock = () => {
  const prContext = React.useContext(SurveySiteDetailsContext)
  const { lead, postcodeLocation } = prContext!

  return <>
    <div className="flex-col justify-end items-start gap-3 flex">
      <div className="text-gray-900 text-xl font-bold">Location</div>
      {postcodeLocation &&
          <Map zoom={18} className="min-h-[200px] w-full h-full rounded overflow-hidden" mapTypeId="satellite"
            center={postcodeLocation}>
            <Marker position={postcodeLocation}/>
          </Map>}
      <div className="text-gray-600 text-sm">{getAddressIncludingPostcode(lead!)}</div>
    </div>
  </>
}
