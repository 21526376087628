import React from 'react'
import { type Company } from '../../../../code/models/company'
import { SettingsLayout } from '../components/settings_layout'
import { ProposalCoverNoteBlock } from './blocks/proposal_cover_note_block'

export const ProposalTemplates = ({ company, setCompany }: { company: Company, setCompany: (c: Company) => void }) => {
  return <SettingsLayout
    heading='Proposal templates'
    description='Customise the template for the cover note that displayed at the top of your full proposals.'
  >
    <ProposalCoverNoteBlock company={company} setCompany={setCompany} />
  </SettingsLayout>
}
