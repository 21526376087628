import React from 'react'
import { HLRSubheaderBlock } from './partials/hlr_subheader'
import { Badge, type badgeColours } from '../../../components/indicators_and_messaging/badge'
import { HLRContext } from './heatloss_report'
import { reversePriceOrNumberFormat } from '../../../code/models/performance_estimate'
import {
  AGILE_PENCE_PER_KWH,
  OVO_HEAT_PUMP_PLUS_PENCE_PER_KWH,
  PRICE_CAP_ELECTRICITY_PENCE_PER_KWH
} from '../../../code/models/heating_fuel'

export const HLRPEResultsTable = () => {
  const hlrContext = React.useContext(HLRContext)

  const fuelName = hlrContext!.performanceEstimateSummary.hddEstimate.existing.fuelName
  const estimatesGroup = hlrContext!.performanceEstimateSummary.estimatesGroup
  const numberOfMethods = hlrContext!.performanceEstimateSummary.numberOfMethods
  const columnNamesInOrder = Object.keys(estimatesGroup)

  type tableCell = {
    text: string
    badgeText?: string
    badgeStyle?: string
  }
  type tableRow = Record<string, tableCell[] | undefined>

  const tables: Record<string, Array<Record<string, tableRow>>> = {
    Energy: [
      {
        Heating: Object.fromEntries( // package list of single key-value pairs into an object
          Object.entries(estimatesGroup).map(([key, value]) => {
            return [key, value ? [{ text: value.existing.consumptionSpaceHeatingKwh }, { text: value.proposed.consumptionSpaceHeatingKwh }] : undefined]
          })
        )
      },

      {
        'Hot Water': Object.fromEntries(
          Object.entries(estimatesGroup).map(([key, value]) => {
            return [[key], value ? [{ text: value.existing.consumptionHotWaterKwh }, { text: value.proposed.consumptionHotWaterKwh }] : undefined]
          })
        )
      },
      {
        Total: Object.fromEntries(
          Object.entries(estimatesGroup).map(([key, value]) => {
            return [[key], value ? [{ text: value.existing.kwh }, { text: value.proposed.kwh }] : undefined]
          })
        )
      }
    ],
    Bills: [
      {
        [`Price cap (${PRICE_CAP_ELECTRICITY_PENCE_PER_KWH} p/kWh)`]: Object.fromEntries(
          Object.entries(estimatesGroup).map(([key, value]) => {
            return [[key], value ? [{ text: value.existing.costPriceCap },
              { text: value.proposed.costPriceCap, badgeText: value.savings.costPriceCap, badgeStyle: reversePriceOrNumberFormat(value.savings.costPriceCap) > 0 ? 'GREEN' : 'RED' }] : undefined]
          })
        )
      },
      ...hlrContext?.eligibleForHeatPumpPlus
        ? [{
            [`OVO Heat Pump Plus (${OVO_HEAT_PUMP_PLUS_PENCE_PER_KWH} p/kWh)`]: Object.fromEntries(
              Object.entries(estimatesGroup).map(([key, value]) => {
                return [[key], value ? [{ text: value.existing.costOvoHeatPumpPlus },
                  { text: value.proposed.costOvoHeatPumpPlus, badgeText: value.savings.costOvoHeatPumpPlus, badgeStyle: reversePriceOrNumberFormat(value.savings.costOvoHeatPumpPlus) > 0 ? 'GREEN' : 'RED' }] : undefined]
              })
            )
          }] : [], // empty list if not eligible
      {
        [`Octopus Agile (${AGILE_PENCE_PER_KWH} p/kWh)`]: Object.fromEntries(
          Object.entries(estimatesGroup).map(([key, value]) => {
            return [[key], value
              ? [{ text: value.existing.costAgile }, { text: value.proposed.costAgile, badgeText: value.savings.costAgile, badgeStyle: reversePriceOrNumberFormat(value.savings.costAgile) > 0 ? 'GREEN' : 'RED' }]
              : undefined]
          })
        )
      }
    ],
    Carbon: [
      {
        Totals: Object.fromEntries(
          Object.entries(estimatesGroup).map(([key, value]) => {
            return [key, value
              ? [{ text: value.existing.emissionsKG }, { text: value.proposed.emissionsKG, badgeText: value.savings.emissionsKG, badgeStyle: reversePriceOrNumberFormat(value.savings.emissionsKG) > 0 ? 'GREEN' : 'RED' }]
              : undefined]
          })
        )

      }
    ]
  }

  const colContent = (colData: tableCell) => {
    return <>
      {/* if there is a badge content */}
      {colData.badgeText !== '' && <div className='inline-flex flex-col gap-2'>
        <div>{colData.text}</div>
        <Badge color={colData.badgeStyle as keyof typeof badgeColours} text={colData.badgeText}/>
      </div>}

      {/* no badge content */}
      {colData.badgeText === '' && <div>{colData.text}</div>}
    </>
  }

  return (
    <div className='flex gap-6 flex-col'>
      <HLRSubheaderBlock
        section="Performance estimate"
        title="Results table"
      />

      {/* using grid and custom code (not TableLight component) just because it needs to be aligned with the top header */}
      <div className='grid grid-cols-7'>
        {/* header row */}
        <div className='pb-2'></div>
        <div
          className='col-span-2 pb-2 pr-2 text-gray-900 text-xs font-semibold uppercase tracking-wide flex items-end'>
          {columnNamesInOrder[0]}
        </div>
        <div className='col-span-2 pb-2 pr-2 text-gray-900 text-xs font-semibold uppercase tracking-wide flex items-end'>
          {columnNamesInOrder[1]}
        </div>
        <div className='col-span-2 pb-2 pr-2 text-gray-900 text-xs font-semibold uppercase tracking-wide flex items-end'>
          {columnNamesInOrder[2]}
        </div>

        {Object.entries(tables).map((table, idx: number) => {
          const tableTitle: string = table[0]
          const tableRows: Array<Record<string, tableRow>> = table[1]

          return <React.Fragment key={'table-' + idx}>
            {/* table subheader row */}
            <div className='bg-gray-100 rounded-tl-md pl-3 pt-6 pb-3 text-gray-900 text-lg font-bold'>
              {tableTitle}
            </div>
            {/* first columns pair */}
            <div className='bg-gray-100 pt-6 pb-3 text-gray-500 text-xs tracking-tight'>Existing <br/>({fuelName})</div>
            <div className='bg-gray-100 pt-6 pb-3 text-gray-500 text-xs tracking-tight'>Heat pump <br/>(Electricity)</div>

            {/* second columns pair */}
            { numberOfMethods > 1 && <>
              <div className='bg-gray-100 pt-6 pb-3 text-gray-500 text-xs tracking-tight'>Existing <br/>({fuelName})</div>
              <div className='bg-gray-100 pt-6 pb-3 text-gray-500 text-xs tracking-tight'>Heat pump <br/>(Electricity)</div>
            </>}
            { numberOfMethods <= 1 && <>
              <div className='bg-gray-100 pt-6 pb-3 text-gray-500 text-xs tracking-tight col-span-2'>Unavailable</div>
            </>}

            {/* third columns pair */}
            { numberOfMethods > 2 && <>
              <div className='bg-gray-100 pt-6 pb-3 text-gray-500 text-xs tracking-tight'>Existing <br/>({fuelName})</div>
              <div className='bg-gray-100 rounded-tr-md pr-3 pt-6 pb-3 text-gray-500 text-xs tracking-tight'>Heat pump <br/>(Electricity)</div>
            </>}
            { numberOfMethods <= 2 && <>
              <div className='bg-gray-100 rounded-tr-md pr-3 pt-6 pb-3 text-gray-500 text-xs tracking-tight col-span-2'>Unavailable</div>
            </>}

            {/* Data rows */}
            {tableRows.map((row, rowIdx) => {
              const rowTitle: string = Object.keys(row)[0]
              const rowData: tableRow = Object.keys(row).map(key => row[key])[0]

              const lastRow = rowIdx === tableRows.length - 1
              const padding = lastRow ? 'pb-6 pt-3' : 'py-3'

              const firstPairCells: tableCell[] = rowData[columnNamesInOrder[0]]!
              const secondPairCells: tableCell[] | undefined = rowData[columnNamesInOrder[1]]
              const thirdPairCells: tableCell[] | undefined = rowData[columnNamesInOrder[2]]

              return <React.Fragment key={'row-' + rowIdx}>
                {/* row title: first column */}
                <div className={`${lastRow ? 'rounded-bl-md' : ''} bg-gray-100 pl-3 text-gray-900 text-xs font-bold`}>
                  {/* a trick to draw border not from the edge: draw it for the embedded div */}
                  <div className={`${padding} w-full flex border-t border-dashed border-gray-300 pr-2`}>{rowTitle}</div>
                </div>

                {/* columns in the middle */}
                {/* first pair: always visible */}
                <div className={`${padding} border-t border-dashed border-gray-300 bg-gray-100 text-xs font-semibold`}>
                  {colContent(firstPairCells[0])}
                </div>
                <div className={`${padding} border-t border-dashed border-gray-300 bg-gray-100 text-xs font-semibold`}>
                  {colContent(firstPairCells[1])}
                </div>

                {/* second pair */}
                { !secondPairCells && <>
                  <div className={`${padding} border-t border-dashed border-gray-300 bg-gray-100 text-xs font-semibold col-span-2`}></div>
                </>}
                { secondPairCells && <>
                  <div className={`${padding} border-t border-dashed border-gray-300 bg-gray-100 text-xs font-semibold`}>
                    {colContent(secondPairCells[0])}
                  </div>
                  <div className={`${padding} border-t border-dashed border-gray-300 bg-gray-100 text-xs font-semibold`}>
                    {colContent(secondPairCells[1])}
                  </div>
                </>}

                {/* third pair */}
                { !thirdPairCells && <>
                  <div className={`${lastRow ? 'rounded-br-md' : ''} bg-gray-100 pr-3 text-xs font-semibold col-span-2`}>
                    {/* a trick to draw border not from the edge: draw it for the embedded div */}
                    <div className={`${padding} w-full flex border-t border-dashed border-gray-300`}></div>
                  </div>
                </>}
                { thirdPairCells && <>
                  <div className={`${padding} border-t border-dashed border-gray-300 bg-gray-100 text-xs font-semibold`}>
                    {colContent(thirdPairCells[0])}
                  </div>

                  {/* last column */}
                  <div className={`${lastRow ? 'rounded-br-md' : ''} bg-gray-100 pr-3 text-xs font-semibold`}>
                    {/* a trick to draw border not from the edge: draw it for the embedded div */}
                    <div
                      className={`${padding} w-full flex border-t border-dashed border-gray-300`}>{colContent(thirdPairCells[1])}</div>
                  </div>
                </>}
              </React.Fragment>
            })}

            {/* separator between all tables except last table */}
            {idx < Object.keys(tables).length - 1 && <div className='col-span-7 py-3'></div>}
          </React.Fragment>
        })}
      </div>
    </div>
  )
}
