import React, { forwardRef, type HTMLInputTypeAttribute, useState, useEffect } from 'react'
import { validateIsNumber } from '../../code/validators'
import { FormLabel } from './form_label'

const sizes = {
  SM: 'text-xs py-2 px-2.5',
  MD: 'text-sm px-4 py-2',
  LG: 'px-4 py-3'
}

export type InputProps = {
  onEnter?: () => void
  value: string | number
  setValue: (value: string) => void
  type?: HTMLInputTypeAttribute
  step?: number
  label?: string
  placeholder?: string
  size?: 'SM' | 'MD' | 'LG'
  shrink?: boolean
  validator?: (e: string | number) => { message: string, value: number | string | undefined }
  validateImmediately?: boolean // if true the validator is applied even before the input is visited
  doNotValidateWhenEmpty?: boolean // only validate when the input has a value (false by default)
  className?: string
  disabled?: boolean
  postfix?: JSX.Element | string
  prefix?: JSX.Element | string
}
export const Input = forwardRef((
  {
    validator,
    validateImmediately = false,
    doNotValidateWhenEmpty = false,
    onEnter,
    value,
    setValue,
    step,
    label,
    placeholder,
    type = 'text',
    size = 'MD',
    shrink = false,
    className,
    disabled,
    postfix,
    prefix
  }: InputProps, ref) => {
  const [rawValue, setRawValue] = useState<string | number>(value)
  const [visited, setVisited] = useState(false)

  // Update the rawValue when the value changes. Needed because value may change outside the component if
  // e.g. the user clears the override or another input controls the value of this input
  useEffect(() => { setRawValue(value) }, [value])

  // Handle component unmount in case onBlur is not called (it does seem like it normally is)
  // Remove component unmount handling because was resetting valid values to 0 where the setValue initial input hadn't updated yet
  // See https://spruceretrofit.slack.com/archives/C06008YPC85/p1718435078288539?thread_ts=1718383595.167479&cid=C06008YPC85
  // useEffect(() => { return () => { handleCleanUp() } }, [])

  const handleKeyDown = (event) => {
    if (event.key === 'Enter') {
      event.preventDefault()
      event.stopPropagation()
      if (onEnter) onEnter()
    }
  }

  //   Called on every keystroke. Updates internal state (rawValue). If the value is valid, updates the value
  const handleChange = (e) => {
    const inputValue = e.currentTarget.value
    setRawValue(inputValue)
    if (type === 'number' && !isNaN(parseFloat(inputValue))) {
      setValue(inputValue) //   Only update the value if the input is a valid number
    } else if (type !== 'number') {
      //   For other input types you can always set the value
      setValue(inputValue)
    }
  }

  //   Called when the user clicks away
  const handleBlur = () => {
    handleCleanUp()
    // needed where other fields in the same page depend on that value (e.g. the domestic hot water demand field)
    setVisited(true)
  }

  const handleCleanUp = () => {
    if (type === 'number') { // if rawValue isn't a valid number, set the value to 0
      if (isNaN(parseFloat(rawValue.toString()))) {
        setValue('0')
      }
    }
  }

  // If no validator is provided for numbers then add a default one
  const validatorToUse = validator ?? (type === 'number' ? validateIsNumber : undefined)
  const validation = validatorToUse?.(rawValue)
  // Check explicitly for null or undefined as 0 may be a valid value
  //   Apply validation if input has been visited or we want to validate the input immediately
  const isInvalid = () => {
    if (!validation) {
      return false
    }
    if (doNotValidateWhenEmpty && rawValue === '') {
      return false
    }
    return (visited || validateImmediately) && (validation.value === null || validation.value === undefined)
  }

  return (
    <div className={`flex flex-col space-y-2 ${className || ''}`}>
      {label && <FormLabel labelText={label} size="SM" />}
      <div className={`${disabled ? 'bg-gray-100 border-gray-300' : 'bg-white'} placeholder:text-gray-500 text-gray-600 rounded-lg border ${isInvalid() ? 'border-red-500 text-red-800' : 'border-gray-300 text-gray-600'} ${shrink ? '' : 'w-full'} `}>
        <div className={`flex justify-between gap-2   ${sizes[size]} `}>
          {prefix && <div className='text-sm items-center flex text-gray-500'>{prefix}</div>}
          <input
            data-cy="input"
            ref={ref as any}
            inputMode={type === 'number' ? 'decimal' : 'text'}
            onBlur={handleBlur}
            placeholder={placeholder}
            onKeyDown={handleKeyDown}
            onWheel={(e) => e.currentTarget.blur()} // prevent scrolling to prevent accidental number change
            type={type}
            step={step}
            className={`${disabled ? 'bg-gray-100 border-gray-300 text-gray-500' : 'bg-white text-gray-600'} placeholder:text-gray-500 outline-none w-full`}
            onChange={handleChange}
            value={rawValue}
            disabled={disabled ?? false}
          />
          {postfix && <div className='text-sm items-center flex text-gray-500 whitespace-nowrap'>{postfix}</div>}
        </div>
      </div>
      {isInvalid() && <div className="text-red-700 text-sm">{validation?.message}</div>}
    </div>
  )
})
