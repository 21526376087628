import React from 'react'
import { type Company } from '../../../../code/models/company'
import { NudgeLeadEmailTemplateBlock } from './blocks/nudge_lead_email_template_block'
import { SettingsLayout } from '../components/settings_layout'

export const EmailTemplatesNudgeFollowup = ({ company, setCompany }: { company: Company, setCompany: (c: Company) => void }) => {
  return <SettingsLayout
    heading='Nudge follow-up'
    description='Customise the follow-up email sent to the customer after an estimate has been generated'
  >
    <NudgeLeadEmailTemplateBlock company={company} setCompany={setCompany} />
  </SettingsLayout>
}
