import React, { type Dispatch, type SetStateAction } from 'react'
import { TextArea } from '../../components/inputs_and_selections/text_area'
import { Input } from '../../components/inputs_and_selections/input'
import { tryParseFloat } from '../../code/helpers'
import { Button } from '../../components/buttons/button'
import { type SecondaryEmitter } from '../../code/models/radiator'

type SecondaryHeatingPageProps = {
  secondaryHeating: SecondaryEmitter | undefined
  setSecondaryHeating: Dispatch<SetStateAction<SecondaryEmitter | undefined>>
  onSave: () => void
}

export const SecondaryHeatingPage = ({ secondaryHeating, setSecondaryHeating, onSave }: SecondaryHeatingPageProps) => {
  if (!secondaryHeating) return

  return <div className="p-5 bg-white flex-col gap-5 flex">
    <div className="flex-col gap-1 flex">
      <div className="text-gray-900 text-base font-bold">Name of heat source</div>
      <Input value={secondaryHeating.name} setValue={(e) => setSecondaryHeating(prev => ({ ...prev!, name: e }))} />
    </div>
    <div className="flex-col gap-1 flex">
      <div className="text-gray-900 text-base font-bold">Estimated % of room's heating load covered:</div>
      <Input postfix='%' value={secondaryHeating.room_heating_load_covered_percentage ?? ''} setValue={(e) => setSecondaryHeating(prev => ({ ...prev!, room_heating_load_covered_percentage: tryParseFloat(e, undefined) }))} />
    </div>
    <div className="flex-col gap-1 flex">
      <div className="text-gray-900 text-base font-bold">Notes</div>
      <TextArea value={secondaryHeating.notes} setValue={(e) => setSecondaryHeating(prev => ({ ...prev!, notes: e }))} />
    </div>
    <Button disabled={!secondaryHeating.name || !secondaryHeating.room_heating_load_covered_percentage} onClick={onSave}>Apply</Button>
  </div>
}
