import React from 'react'
import { EstimateCoverNoteTemplateBlock } from './blocks/estimate_cover_note_template_block'
import { SettingsLayout } from '../components/settings_layout'
import { EstimateConfigurationBlock } from './blocks/estimate_configuration_block'
import { type Company } from '../../../../code/models/company'
import { EstimateAboutUsBlock } from './blocks/estimate_about_us_block'
import { EstimateCustomerReviewBlock } from './blocks/estimate_customer_review_block'
import { EstimateNextStepsBlock } from './blocks/estimate_next_steps_block'

export const EstimateResultsPage = ({ company, setCompany }: { company: Company, setCompany: (c: Company) => void }) => {
  return <SettingsLayout
    heading='Results page'
    description='Customise the content displayed on your estimates'>
    <EstimateConfigurationBlock company={company} setCompany={setCompany} />
    <EstimateCoverNoteTemplateBlock company={company} setCompany={setCompany} />
    <EstimateNextStepsBlock company={company} setCompany={setCompany} />
    <EstimateAboutUsBlock company={company} setCompany={setCompany} />
    <EstimateCustomerReviewBlock company={company} setCompany={setCompany} />
  </SettingsLayout>
}
