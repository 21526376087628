import { axiosDeleteV2, axiosPatchV2, axiosPostV2 } from '../axios'
import { type QuoteItem } from '../calculate_quote'

export const updateEstimateQuoteItem = async (estimateQuoteItem: QuoteItem, leadUUID: string, companyUUID: string) => await axiosPatchV2(`teams/${companyUUID}/leads/${leadUUID}/estimate_quote_items/${estimateQuoteItem.uuid}`, estimateQuoteItem)
export const insertEstimateQuoteItem = async (estimateQuoteItem: QuoteItem, leadUUID: string, companyUUID: string) => await axiosPostV2(`teams/${companyUUID}/leads/${leadUUID}/estimate_quote_items`, estimateQuoteItem)
export const deleteEstimateQuoteItem = async (estimateQuoteItemUUID: string, leadUUID: string, companyUUID: string) => await axiosDeleteV2(`teams/${companyUUID}/leads/${leadUUID}/estimate_quote_items/${estimateQuoteItemUUID}`)
export const bulkInsertEstimateQuoteItems = async (leadLineItems: QuoteItem[], leadUUID: string, companyUUID: string): Promise<QuoteItem[]> =>
  await axiosPostV2<QuoteItem[]>(
    `teams/${companyUUID}/leads/${leadUUID}/estimate_quote_items/bulk`,
    leadLineItems
  )
export const bulkDeleteEstimateQuoteItems = async (leadUUID: string, companyUUID: string) => await axiosDeleteV2(`teams/${companyUUID}/leads/${leadUUID}/estimate_quote_items/bulk`)
