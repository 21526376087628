import React, { Fragment, useEffect, useRef, useState } from 'react'
import { type CompanyPublicInfo } from '../code/models/company'
import { Loader } from '../components/indicators_and_messaging/loader'
import {
  getProposalPDFAPI,
  getProposalPublicAPI,
  type Proposal,
  type QuoteSnapshotData,
  signProposalAPI
} from '../code/models/proposal'
import { Button } from '../components/buttons/button'
import {
  renderHTMLReplacingPlaceholders,
  TTPlaceholderCompanyName,
  TTPlaceholderCompanyPhone,
  TTPlaceholderCustomerAddress,
  TTPlaceholderCustomerName,
  TTPlaceholderEstimateContactName,
  TTPlaceholderEstimateContactPhone
} from '../code/tiptap_placeholders'
import { CylinderIcon, HeatPumpIcon2, RadiatorIcon } from '../assets/images/survey_images/survey_images'
import { HLRContext, hlrMakeCalcsFromSnapshot } from './heat_loss/proposal/heatloss_report'
import {
  HEATLOSS_REPORT_SECTION_HEATLOSS_INTRO,
  HEATLOSS_REPORT_SECTION_HEATLOSS_PER_ROOM,
  type HeatLossReportSnapshot
} from '../code/models/heatloss_report'
import { HLRSoundAssessment } from './heat_loss/proposal/hlr_sound_assessment'
import { HLRPESummary } from './heat_loss/proposal/hlr_pe_summary'
import { HLRPEDetailedResultsIntroduction } from './heat_loss/proposal/hlr_pe_detailed_results_intro'
import { HLRPEResultsTable } from './heat_loss/proposal/hlr_pe_results_table'
import { HLRPEInputsAndAssumptions } from './heat_loss/proposal/hlr_pe_inputs_and_assumptions'
import { HLRPESystemEfficiency1 } from './heat_loss/proposal/hlr_pe_system_efficiency_1'
import { HLRPESystemEfficiency2 } from './heat_loss/proposal/hlr_pe_system_efficiency_2'
import { HLRPEFlowTempChart } from './heat_loss/proposal/hlr_pe_flow_temp_chart'
import { HLRPEMCSKeyFacts } from './heat_loss/proposal/hlr_pe_mcs_key_facts'
import { getFullEmittersListByStatus } from '../code/models/radiator'
import { HLRHeatPump } from './heat_loss/proposal/hlr_heat_pump'
import { HLREmittersIntro } from './heat_loss/proposal/hlr_emitters_intro'
import { HLREmittersCurrent } from './heat_loss/proposal/hlr_emitters_current'
import { HLREmittersProposed } from './heat_loss/proposal/hlr_emitters_proposed'
import { HLRHotWater } from './heat_loss/proposal/hlr_hot_water'
import { HLRHotWaterDemand } from './heat_loss/proposal/hlr_hot_water_demand'
import { downloadBlob, isFlagSet } from '../code/helpers'
import { HLRIntroductionPage } from './heat_loss/proposal/hlr_introduction_page'
import { HLRCalculationConditionsPage } from './heat_loss/proposal/hlr_calculation_conditions_page'
import { HLRHeatlossByElementPage } from './heat_loss/proposal/hlr_heatloss_by_element_page'
import { HLRHeatlossByRoomPage } from './heat_loss/proposal/hlr_heatloss_by_room_page'
import { HLRFloorHeatlossPage } from './heat_loss/proposal/hlr_floor_heatloss_page'
import { HLRRoomHeatlossPage } from './heat_loss/proposal/hlr_room_heatloss_page'
import { Icon } from '../components/buttons/icon'
import _ from 'lodash'
import { formatPrice } from '../code/format_price'
import { numberFormat } from '../code/number_format'
import { PublicQuoteBlock, PublicQuotePaymentScheduleBlock } from './quote_public_page'
import { type ProposalLink } from '../code/models/proposal_link'
import { Modal } from '../components/containers/modal'
import { Text } from '../components/content_display/text'
import { Input } from '../components/inputs_and_selections/input'
import { validateLooksLikeDate, validateNotNull } from '../code/validators'
import { yellowEnvelope } from '../assets/images/images'
import { PrintLayoutPageBreak } from './components/print_layout'
import { Heading } from '../components/content_display/heading'
import confetti from 'canvas-confetti'
import { DEFAULT_BRAND_COLOUR_PRIMARY, determineBestTextShade } from '../code/utils/colour_utils'
import { Check, Download, ExternalLink, FileText, Signature } from 'lucide-react'

type ProposalPublicPageProps = {
  proposalUUID: string
  companyPublicInfo: CompanyPublicInfo
}

// const HLRContext = React.createContext<HeatLossReportContextProps | undefined>(undefined)

const ProposalLinkAnchor = ({ index, link }: { index?: any, link: ProposalLink }) => {
  return <a
    className='bg-gray-50 rounded text-gray-600 text-sm font-bold w-full underline p-2 flex items-center justify-between gap-2'
    key={index}
    rel='noreferrer'
    target='_blank' // Open all links in new tab.
    href={`${link.url}${link.internal ? `?name_override=${encodeURIComponent(link.title)}.${link.url.split('.')[1]}` : ''}`} // Download as title.extension if attachment is internal
  >
    <div>{link.title}</div>
    <Icon icon={ link.internal ? Download : ExternalLink}/>
  </a>
}

export const ProposalPublicPage = (props: ProposalPublicPageProps) => {
  const [proposal, setProposal] = useState<Proposal>()

  useEffect(() => {
    const asyncCall = async () => {
      const result = await getProposalPublicAPI(props.proposalUUID, props.companyPublicInfo.uuid)
      if (result) {
        setProposal(result)
      }
    }
    asyncCall()
  }, [])

  if (!proposal) {
    return (
      <div className="flex flex-col items-center justify-center mt-10 mx-auto">
        <Loader/>
      </div>
    )
  }

  const quoteSnapshot = proposal.snapshot.quote!
  const reportSnapshot = proposal.snapshot.report as HeatLossReportSnapshot

  return <>
    <ProposalPublicPageInner
      proposal={proposal}
      quoteSnapshot={quoteSnapshot}
      reportSnapshot={reportSnapshot}
      companyPublicInfo={props.companyPublicInfo}
    />
    {/* DO NOT REMOVE! This selector is used by browserless */}
    <div id='pdf-ready' />
  </>
}

type ProposalPublicPageInnerProps = {
  proposal: Proposal
  quoteSnapshot: QuoteSnapshotData
  reportSnapshot: HeatLossReportSnapshot
  companyPublicInfo: CompanyPublicInfo
  previewMode?: boolean
}

export const ProposalPublicPageInner = ({
  proposal,
  quoteSnapshot,
  reportSnapshot,
  companyPublicInfo,
  previewMode
}: ProposalPublicPageInnerProps) => {
  const reportCalculatedData = hlrMakeCalcsFromSnapshot(reportSnapshot)
  const emittersDefined = getFullEmittersListByStatus(reportSnapshot.survey, reportCalculatedData.design).length > 0
  const hasPaymentSchedule = proposal.payment_schedule && proposal.payment_schedule.length > 0

  const [acceptProposalModalVisible, setAcceptProposalModalVisible] = useState(false)

  const coverNoteHTML = proposal?.cover_note
    ? renderHTMLReplacingPlaceholders(JSON.parse(proposal.cover_note), {
      [TTPlaceholderCustomerName.id]: reportSnapshot.customer.name!,
      [TTPlaceholderCustomerAddress.id]: reportSnapshot.property.address + ', ' + reportSnapshot.property.postcode,
      [TTPlaceholderEstimateContactName.id]: companyPublicInfo.estimate_contact_name ?? '',
      [TTPlaceholderEstimateContactPhone.id]: companyPublicInfo.estimate_contact_phone ?? '',
      [TTPlaceholderCompanyName.id]: companyPublicInfo.name,
      [TTPlaceholderCompanyPhone.id]: companyPublicInfo.phone
    })
    : ''

  const hpImage = process.env.S3_BUCKET_URL + '/hp-images/' + reportSnapshot.currentHeatPump?.range_heat_pump?.uuid + '.png'

  const logoImageData = companyPublicInfo.logo

  // left menu definitions and state
  type sectionNameType =
    'summary'
    | 'quote'
    | 'paymentSchedule'
    | 'hlr'
    | 'systemDesign'
    | 'soundAssessment'
    | 'performanceEstimate'
    | 'documents'
  const sectionRefs = useRef<Record<sectionNameType, HTMLDivElement | null>>({
    summary: null,
    quote: null,
    paymentSchedule: null,
    hlr: null,
    systemDesign: null,
    soundAssessment: null,
    performanceEstimate: null,
    documents: null
  })

  const [activeSection, setActiveSection] = useState<sectionNameType>('summary')

  const sections: Array<{ id: sectionNameType, title: string, conditions?: () => boolean, threshold: number }> = [
    { id: 'summary', title: 'Summary', threshold: 0 },
    { id: 'quote', title: 'Quote', threshold: 0 },
    { id: 'paymentSchedule', conditions: () => (!!hasPaymentSchedule), title: 'Payment schedule', threshold: 0 },
    { id: 'hlr', title: 'Heat loss report', threshold: 0 },
    { id: 'systemDesign', title: 'System design', threshold: 0 },
    { id: 'soundAssessment', title: 'Sound assessment', threshold: 0 },
    { id: 'performanceEstimate', title: 'Performance estimate', threshold: 0 },
    {
      id: 'documents',
      conditions: () => {
        return !!(proposal?.links && proposal.links.length > 0)
      },
      title: 'Documents',
      threshold: 0
    }
  ]

  useEffect(() => {
    const observers: IntersectionObserver[] = []

    sections.forEach((section) => {
      const observer = new IntersectionObserver(
        (entries) => {
          entries.forEach((entry) => {
            if (entry.isIntersecting) {
              setActiveSection(section.id)
            }
          })
        },
        {
          threshold: section.threshold,
          rootMargin: '100px 0px -50% 0px'
        }
      )

      if (sectionRefs.current[section.id]) {
        observer.observe(sectionRefs.current[section.id]!)
      }

      observers.push(observer)
    })

    return () => {
      observers.forEach((observer) => observer.disconnect())
    }
  }, [])

  const total = quoteSnapshot.total_pre_deductions + quoteSnapshot.vat_on_all + quoteSnapshot.vat_on_survey + quoteSnapshot.discount_total + quoteSnapshot.bus_grant

  // For local state only, manually toggle the acceptance status
  // once the proposal has been signed, as this is all customer-facing
  const setProposalSigned = (value: boolean) => {
    if (value) {
      proposal.acceptance_status = 1
    }
  }

  const gapBetweenPages = 'gap-6 sm:gap-6 md:gap-8 lg:gap-10'

  return <>
    {/* wrap all calculations and report data into a context */}
    <HLRContext.Provider value={{
      survey: reportSnapshot.survey,
      property: reportSnapshot.property,
      customer: reportSnapshot.customer,
      company_public_info: companyPublicInfo,
      design: reportCalculatedData.design,
      designTempC: reportCalculatedData.designTempC,
      groundTempC: reportCalculatedData.groundTempC,
      degreeDays: reportCalculatedData.degreeDays,
      currentHeatPump: reportSnapshot.currentHeatPump,
      currentHotWaterCylinder: reportSnapshot.currentHotWaterCylinder,
      cylinderReheatRow: reportCalculatedData.cylinderReheatRow,
      hotWaterRowNormal: reportCalculatedData.hotWaterRowNormal,
      hotWaterRowLegionella: reportCalculatedData.hotWaterRowLegionella,
      performanceEstimateColumns: reportCalculatedData.performanceEstimateColumns,
      performanceEstimateSummary: reportCalculatedData.performanceEstimateSummary,
      eligibleForHeatPumpPlus: reportCalculatedData.eligibleForHeatPumpPlus,
      soundAssessment: reportCalculatedData.soundAssessment,
      files: reportSnapshot.files,
      summaryData: reportCalculatedData.summaryData,
      hpCapacityResult: reportCalculatedData.hpCapacityResult,
      scop: reportCalculatedData.scop
    }}>

      {/* has wrapper for screen version */}
      <div className={`${previewMode ? 'h-[calc(100vh-150px)]' : 'print:h-full lg:h-screen'} w-full bg-white flex flex-col items-center`}>

        {/* sticky header */}
        <div className='w-full border-b border-b-gray-200 flex flex-col items-center'>
          <div className={'w-full max-w-[1104px] flex flex-row justify-between gap-2 items-center p-3 ' +
            'print:p-6 print:sticky print:top-0 ' + // print
            'sm:px-3 sm:pt-3 ' + // sm
            'md:px-6 md:pt-6 ' + // md
            'lg:sticky lg:top-0 ' // lg
          }>
            {/* logo and MCS certification */}
            <div className='flex flex-row gap-4'>
              {logoImageData && <img src={logoImageData} className="max-h-10 md:max-h-12" alt="Installer logo"/>}
              {/* TODO MCS cert logo */}
            </div>

            {/* action buttons: do not show in preview mode */}
            {!previewMode && <div className='print:hidden flex flex-row gap-4'>
              <Button
                colour={'LIGHT'}
                iconLeft={FileText}
                onClick={async () => {
                  const pdfData = await getProposalPDFAPI(
                    proposal.uuid,
                    companyPublicInfo.uuid
                  )
                  downloadBlob(pdfData!, reportSnapshot.property.address + ' — Full Proposal.pdf')
                }}
              ><span className='hidden sm:inline'>Save to PDF</span></Button>
              {!proposal.acceptance_status &&
                <Button
                  style={{
                    backgroundColor: companyPublicInfo.colour_primary,
                    borderColor: companyPublicInfo.colour_primary,
                    color: determineBestTextShade(companyPublicInfo.colour_primary ?? DEFAULT_BRAND_COLOUR_PRIMARY) === 'LIGHT' ? '#000000' : '#ffffff'
                  }}
                  iconLeft={Signature}
                  onClick={() => setAcceptProposalModalVisible(true)}>
                  <span className={'hidden sm:inline'}>Review & accept quote</span>
                </Button>
              }
              {proposal.acceptance_status &&
                <div className="p-2.5 bg-green-100 rounded-md justify-center items-center flex gap-1 text-green-800 ">
                  <Icon icon={Check} className='text-green-800'/>
                  <div className="text-sm font-bold">Accepted</div>
                </div>
              }
            </div>}
          </div>
        </div>

        {/* Top menu. Visible only on smaller screens */}
        <div className={'lg:hidden print:hidden w-full overflow-scroll no-scrollbar sticky top-0 bg-white z-20 py-2 px-1 print:px-0 sm:px-3 md:px-6 '}>

          {/* menu items */}
          <div className='flex flex-row gap-2'>
            { _.compact(sections.map((section) => {
              if (section.conditions && !section.conditions()) return null
              return (
                <div
                  key={section.id}
                  className={(activeSection === section.id ? 'bg-gray-100 font-semibold' : '') + 'select-none cursor-pointer px-3 py-2 rounded justify-start items-start text-sm text-nowrap'}
                  onClick={() => sectionRefs.current[section.id]!.scrollIntoView({ behavior: 'smooth' })}
                >{section.title}</div>
              )
            }))}
          </div>

          {/* TODO: documents in menu on mobile? */}
          {/* /!* Documents *!/ */}
          {/* {(proposal.links && proposal.links.length > 0) && ( */}
          {/*  <div className='flex flex-col gap-4'> */}
          {/*    {proposal.links?.map((link, index) => ( */}
          {/*      <ProposalLinkAnchor key={index} link={link}/> */}
          {/*    ))} */}
          {/*  </div> */}
          {/* )} */}
        </div>

        {/* content container */}
        <div className='w-full max-w-[1104px] print:px-0 px-1 sm:px-3 md:px-6 '>
          {/* two-column layout. The gap is used to make space between pages */}
          <div className='flex flex-row gap-8 print:gap-0'>

            {/* Left menu. Note different top padding than for the content block. It visually makes block on the same line. */}
            <div className={'hidden min-w-48 flex-col gap-5 sticky top-0 pr-2 ' +
              'print:hidden ' + // print
              'lg:flex lg:pt-12 ' + // lg
              'overflow-y-scroll ' + // Scroll on overflow
              (previewMode ? 'h-[calc(100vh-250px)] ' : 'lg:h-[calc(100vh-100px)] ')
            }>
              <div className="px-3 text-gray-500 text-xs font-semibold uppercase">Contents</div>
              {/* menu items */}
              <div className='flex flex-col gap-0'>
                { _.compact(sections.map((section) => {
                  if (section.conditions && !section.conditions()) return null
                  return <div
                    key={section.id}
                    className={(activeSection === section.id ? 'bg-gray-100 font-semibold' : '') + ' cursor-pointer px-3 py-2 rounded justify-start items-start text-sm'}
                    onClick={() => sectionRefs.current[section.id]!.scrollIntoView({ behavior: 'smooth' })}
                  >{section.title}</div>
                }))}
              </div>
              {/* Documents */}
              {(proposal.links && proposal.links.length > 0) && (
                <div className='flex flex-col gap-4'>
                  {proposal.links?.map((link, index) => (
                    <ProposalLinkAnchor key={index} link={link}/>
                  ))}
                </div>
              )}
            </div>

            {/*
              Content. Note different top padding than for the content block. It visually makes block on the same line.
              When a homeowner opens the page — we need to subtract only own header from the content height to fit it into the screen.
              When an installer opens the page — we need to subtract own header PLUS the header of the preview mode

              Every section below is wrapped into its own div — this is required to manage gaps between sections in different screen sizes.
            */}
            <div
              className={[
                (previewMode ? 'h-[calc(100vh-250px)] ' : 'lg:h-[calc(100vh-100px)] ') + 'overflow-scroll grow shrink flex flex-col pt-6 pb-6 px-3 ',
                (previewMode ? '' : 'print:h-full ') + 'print:pt-0 ', // print
                'sm:pt-6 ', // sm
                'md:pt-8 ', // md
                'lg:pt-12',
                gapBetweenPages// lg: pt- should match with left menu's pt- value
              ].join(' ')}
            >

              {/* Summary page */}
              <div ref={(el) => (sectionRefs.current.summary = el)} className={`lg:scroll-mt-4 scroll-mt-14 flex flex-col ${gapBetweenPages}`}>

                {/* cover note */}
                <div>
                  {/* title */}
                  <Heading size={'3xl'}>Your heat pump proposal</Heading>

                  {/* cover note, quote block and action butons */}
                  <div className='grow shrink flex flex-row justify-between'>

                    {/* cover note */}
                    <div className={'flex flex-col gap-8'}>

                      {/* prepared for */}
                      <div className='flex flex-col gap-4'>
                        <div className="text-gray-900 text-sm font-semibold uppercase">Prepared for</div>
                        <div className='flex flex-col gap-1'>
                          <div className="text-gray-900 text-sm font-bold">{reportSnapshot.customer.name ?? ''}</div>
                          <div className="text-sm flex-col flex gap-0">
                            <span>{reportSnapshot.property.address}</span>
                            <span>{reportSnapshot.property.postcode}</span>
                            <span>{reportSnapshot.customer.email}</span>
                            <span>{reportSnapshot.customer.phone}</span>
                          </div>
                        </div>
                      </div>

                      {/* prepared by */}
                      <div className='flex flex-col gap-4'>
                        <div className="text-gray-900 text-sm font-semibold uppercase">Prepared by</div>
                        <div className='flex flex-row gap-4'>
                          {companyPublicInfo.estimate_contact_portrait && (
                            <img src={companyPublicInfo.estimate_contact_portrait}
                              className="rounded-full aspect-square object-cover w-16 h-16"
                              alt={`${companyPublicInfo.installer_first_name} ${companyPublicInfo.installer_last_name}`}
                            />
                          )}
                          <div className="text-sm flex-col flex gap-1">
                            <div
                              className='text-gray-900 font-bold'>{companyPublicInfo.installer_first_name + ' ' + companyPublicInfo.installer_last_name}</div>
                            <span className=''>{companyPublicInfo.estimate_contact_role}</span>
                            <a href={'tel:' + companyPublicInfo.phone}
                              className='font-bold text-sky-700 underline'>{companyPublicInfo.phone}</a>
                          </div>
                        </div>
                      </div>

                      {/* cover note */}
                      {/* add gap between <p> tags inside the cover note */}
                      <div className='flex flex-col gap-3' dangerouslySetInnerHTML={{ __html: coverNoteHTML }}/>
                    </div>

                    {/* right block */}
                    <div className='hidden print:block md:block w-60 min-w-60'>

                      {/* gray wrapper around the right block */}
                      <div className='p-4 bg-gray-50 rounded-md border border-gray-200 flex-col flex gap-4'>

                        {/* quote card items */}
                        <div className="text-gray-900 text-lg font-bold">Your quote</div>

                        {/* quote items */}
                        <div className='flex flex-col gap-2'>
                          <div className='flex flex-row justify-between items-center text-xs'>
                            <div className=''>Total before grant (excl.&nbsp;VAT)</div>
                            <div
                              className='whitespace-nowrap text-nowrap'>{formatPrice(quoteSnapshot.total_pre_deductions, 2)}</div>
                          </div>
                          {quoteSnapshot.bus_grant < 0 &&
                              <div className='flex flex-row justify-between items-start text-xs'>
                                <div className=''>Boiler Upgrade Scheme (BUS) Grant</div>
                                <div
                                  className='whitespace-nowrap text-nowrap'>{formatPrice(quoteSnapshot.bus_grant, 2)}</div>
                              </div>
                          }
                        </div>

                        <div className='flex flex-row justify-between items-start text-neutral-950 font-bold'>
                          <div className=''>Total</div>
                          <div className='whitespace-nowrap text-nowrap'>{formatPrice(total, 2)}</div>
                        </div>

                        {/* action buttons: do not show in preview mode */}
                        {!previewMode && <div className='print:hidden flex flex-col gap-3'>
                          {!proposal.acceptance_status &&
                            <Button
                              style={{
                                backgroundColor: companyPublicInfo.colour_primary,
                                borderColor: companyPublicInfo.colour_primary,
                                color: determineBestTextShade(companyPublicInfo.colour_primary ?? DEFAULT_BRAND_COLOUR_PRIMARY) === 'LIGHT' ? '#000000' : '#ffffff'
                              }}
                              onClick={() => setAcceptProposalModalVisible(true)}
                            >
                                Review & accept
                            </Button>}
                          {proposal.acceptance_status && <div
                            className="p-2.5 bg-green-100 rounded-md justify-center items-center flex gap-1 text-green-800 ">
                            <Icon icon={Check} className='text-green-800'/>
                            <div className="text-sm font-bold">Accepted</div>
                          </div>}
                          <Button
                            colour={'LIGHT'}
                            onClick={() => {
                              sectionRefs.current.quote!.scrollIntoView({ behavior: 'smooth' })
                            }}
                          >View full quote</Button>
                        </div>}

                        <div className='flex flex-col gap-2'>
                          <div className='flex flex-row justify-between items-center text-xs'>
                            <div className=''>Issue date</div>
                            {proposal.sent_at && <div>{new Date(proposal.sent_at).toLocaleDateString()}</div>}
                            {!proposal.sent_at && <div>(not sent yet)</div>}
                          </div>
                          <div className='flex flex-row justify-between items-center text-xs'>
                            <div>Valid until</div>
                            {proposal.sent_at &&
                                <div>{new Date(new Date(proposal.sent_at).getTime() + (proposal.valid_days! * 24 * 60 * 60 * 1000)).toLocaleDateString()}</div>}
                            {!proposal.sent_at && <div>(not sent yet)</div>}
                          </div>
                        </div>

                        {/* Terms & conditions link - bit of a gnarly self-calling function here so we can use terms_and_conditions_link */}
                        {proposal.links?.find(link => link.category === 'terms_and_conditions') && (() => {
                          const terms_and_conditions_link = proposal.links.find(link => link.category === 'terms_and_conditions')
                          return terms_and_conditions_link && (
                            <a
                              rel='noreferrer'
                              target='_blank'
                              href={`${terms_and_conditions_link.url}${terms_and_conditions_link.internal ? `?name_override=${encodeURIComponent(terms_and_conditions_link.title)}.${terms_and_conditions_link.url.split('.')[1]}` : ''}`} // Download as title.extension if attachment is internal
                              className='text-xs bold flex justify-between items-center underline'
                            >
                              <div>{terms_and_conditions_link.title}</div>
                              <Icon icon={terms_and_conditions_link.internal ? Download : ExternalLink}
                                className='size-3'/>
                            </a>
                          )
                        })()}
                      </div>
                    </div>
                  </div>
                </div>

                {/* Your home heatloss + your proposed system */}
                <div>
                  <PrintLayoutPageBreak/>

                  <div className='flex flex-col gap-4'>
                    {/* Total data */}
                    <div className="flex-col flex gap-4">
                      <div className="text-gray-900 text-lg font-bold">Your home’s heat loss</div>
                      <div className='grid grid-cols-1 sm:grid-cols-3 gap-3'>
                        <div className='p-5 gap-4 flex flex-col justify-between bg-gray-100 rounded-md'>
                          <div className="">Floor area</div>
                          <div className='flex gap-1 items-end'>
                            <div
                              className="text-gray-900 text-xl font-bold">{numberFormat(0).format(reportCalculatedData.summaryData.floorArea)}</div>
                            <div className=" text-lg">m²</div>
                          </div>
                        </div>
                        <div className='p-5 gap-4 flex flex-col justify-between bg-gray-100 rounded-md'>
                          <div className="">Total heat loss</div>
                          <div className='flex gap-1 items-end'>
                            <div
                              className="text-gray-900 text-xl font-bold">{numberFormat(2).format(reportCalculatedData.summaryData.heatLoss / 1000)}</div>
                            <div className=" text-lg">kW</div>
                          </div>
                        </div>
                        <div className='p-5 gap-4 flex flex-col justify-between bg-gray-100 rounded-md'>
                          <div className="">Average heat load</div>
                          <div className='flex gap-1 items-end'>
                            <div
                              className="text-gray-900 text-xl font-bold">{numberFormat(0).format(reportCalculatedData.summaryData.heatLoss / reportCalculatedData.summaryData.floorArea)}</div>
                            <div className=" text-lg">W/m²</div>
                          </div>
                        </div>
                      </div>
                    </div>

                    <div className="text-gray-900 text-lg font-bold">Your proposed system</div>

                    {/* HP & HWC */}
                    {/* Do NOT use flex here: there is a bug in print version ignoring the gap before emitters block. Switching to grid fixes it. */}
                    <div className='grid grid-cols-1 sm:grid-cols-2 gap-4'>

                      { /* HP */}
                      <div className='p-5 bg-slate-100 rounded-md flex flex-row gap-8'>
                        <img alt='' className="w-20 max-h-20 object-contain" src={hpImage} onError={(e) => {
                          e.currentTarget.onerror = null
                          e.currentTarget.src = HeatPumpIcon2
                        }}/>

                        <div className="flex-col gap-1 flex">
                          <div className="text-xs font-semibold uppercase">Heat pump</div>
                          <div className="flex-col justify-start items-start gap-3 flex">
                            <div className="text-gray-900 font-semibold">{reportSnapshot.currentHeatPump.name}</div>
                            <div className="flex-col justify-start items-start gap-1 flex">
                              <div className="self-stretch flex justify-between gap-3">
                                <div className="text-xs font-semibold">
                                  Capacity at {reportCalculatedData.hpCapacityResult.flowTempC}&nbsp;°C flow temp
                                  and {reportCalculatedData.hpCapacityResult.outsideTempC}&nbsp;°C outdoor
                                  air temperature
                                </div>
                                <div
                                  className="text-right text-xs font-semibold whitespace-nowrap">{reportCalculatedData.hpCapacityResult.capacityKw} kW
                                </div>
                              </div>
                              <div className="self-stretch flex justify-between gap-3">
                                <div className="text-xs font-semibold">SCOP
                                  at {reportCalculatedData.design.flow_temp} °C
                                </div>
                                <div
                                  className="text-right text-xs font-semibold whitespace-nowrap">{reportCalculatedData.scop}</div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>

                      { /* HWC */}
                      <div className='p-5 bg-slate-100 rounded-md flex flex-row gap-8'>
                        <img src={CylinderIcon} alt="Heat pump" className="w-20 max-h-20"/>

                        <div className="flex-col gap-1 flex">
                          <div className="text-xs font-semibold uppercase">Cylinder</div>
                          <div className="flex-col justify-start items-start gap-3 flex">
                            <div
                              className="text-gray-900 font-semibold">{reportSnapshot.currentHotWaterCylinder?.name || '?'}</div>
                            <div className="self-stretch flex justify-between gap-3">
                              <div className="text-xs font-semibold">Capacity</div>
                              <div
                                className="text-right text-xs font-semibold whitespace-nowrap">{Math.round(reportSnapshot.currentHotWaterCylinder.litres)} litres
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>

                      {/* emitters */}
                      <div className='sm:col-span-2 p-5 bg-slate-100 rounded-md flex flex-row gap-8'>
                        <img alt='' src={RadiatorIcon} className="p-2 w-10 h-10 rounded bg-gray-100"/>

                        <div className="flex-col gap-1 flex">
                          <div className="text-xs font-semibold uppercase">Emitters</div>
                          <div className="flex-col gap-2 flex">
                            <div className="justify-start items-baseline gap-1 flex">
                              <div
                                className="text-gray-900 font-semibold">{reportCalculatedData.summaryData.radiatorsNew + reportCalculatedData.summaryData.radiatorsReplaced}</div>
                              <div className="">new radiators</div>
                            </div>
                            {reportCalculatedData.summaryData.ufhNew > 0 &&
                              <div className="justify-start items-baseline gap-1 flex">
                                <div
                                  className="text-gray-900 font-semibold">{reportCalculatedData.summaryData.ufhNew}</div>
                                <div className="">new ufh zones</div>
                              </div>}
                            <div className="justify-start items-baseline gap-1 flex">
                              <div
                                className="text-gray-900 font-semibold">{reportCalculatedData.summaryData.emittersUnchanged}</div>
                              <div className="">retained</div>
                            </div>
                            <div className="justify-start items-baseline gap-1 flex">
                              <div
                                className="text-gray-900 font-semibold">{reportCalculatedData.summaryData.emittersRemoved + reportCalculatedData.summaryData.radiatorsReplaced}</div>
                              <div className="">removed</div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>

                    {/* HP data Sheet and Cylinder Data Sheet */}
                    { proposal.links && <div className='grid grid-cols-2 gap-4 w-full mb-12'>
                      {proposal.links?.filter(link => ['heat_pump_spec', 'hot_water_cylinder_spec'].includes(link.category)).map((link, index) =>
                        <ProposalLinkAnchor key={index} link={link}/>)}
                    </div>}
                  </div>

                  {/* Performance */}
                  <div className='flex flex-col gap-4'>
                    <div className="text-gray-900 text-lg font-bold">Performance</div>
                    <div className='grid grid-cols-1 sm:grid-cols-2 gap-4'>
                      <div className='p-5 bg-stone-100 rounded-md'>
                        <div className={'flex flex-col gap-2'}>
                          <div className="text-xs font-semibold uppercase">Annual CO₂ savings</div>
                          <div className={'flex flex-row gap-2 items-end'}>
                            <div className="text-gray-900 text-xl font-semibold">
                              {numberFormat(0).format(reportCalculatedData.performanceEstimateSummary.minEmissionsTonnesSavings)}
                              &mdash;
                              {numberFormat(0).format(reportCalculatedData.performanceEstimateSummary.maxEmissionsTonnesSavings)}
                            </div>
                            <div className="text-sm">tonnes</div>
                          </div>
                        </div>
                      </div>
                      <div className='p-5 bg-stone-100 rounded-md'>
                        <div className={'flex flex-col gap-2'}>
                          <div className="text-xs font-semibold uppercase">Annual running cost savings</div>
                          <div className={'flex flex-row gap-2 items-end'}>
                            <div className="text-gray-900 text-xl font-semibold">
                              {formatPrice(reportCalculatedData.performanceEstimateSummary.minBillSavings, 0)}
                              &nbsp;to&nbsp;
                              {formatPrice(reportCalculatedData.performanceEstimateSummary.maxBillSavings, 0)}
                            </div>
                            <div className="text-sm">per year</div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              {/* Quote */}
              <div ref={(el) => (sectionRefs.current.quote = el)} className={'lg:scroll-mt-4 scroll-mt-14'}>
                <PrintLayoutPageBreak/>
                <div className="text-black text-3xl font-bold pb-8">Quote</div>
                <PublicQuoteBlock quoteSnapshot={quoteSnapshot}/>
              </div>

              {/* Payment schedule */}
              {(quoteSnapshot.payment_schedule && quoteSnapshot.payment_schedule.length > 0) &&
                  <div ref={(el) => (sectionRefs.current.paymentSchedule = el)} className={'lg:scroll-mt-4 scroll-mt-14'}>
                    <PublicQuotePaymentScheduleBlock quoteSnapshot={quoteSnapshot}/>
                  </div>
              }

              {/* Heat loss report */}
              <div ref={(el) => (sectionRefs.current.hlr = el)} className={`lg:scroll-mt-4 scroll-mt-14 flex flex-col ${gapBetweenPages}`}>
                <div>
                  <PrintLayoutPageBreak/>
                  <HLRIntroductionPage
                    hasIntroduction={isFlagSet(proposal.contents_bitmask, HEATLOSS_REPORT_SECTION_HEATLOSS_INTRO)}/>
                </div>

                <div>
                  {isFlagSet(proposal.contents_bitmask, HEATLOSS_REPORT_SECTION_HEATLOSS_INTRO) &&
                      <PrintLayoutPageBreak/>}
                  <HLRCalculationConditionsPage/>
                </div>

                <div>
                  <PrintLayoutPageBreak/>
                  <HLRHeatlossByElementPage/>
                </div>

                <div>
                  <PrintLayoutPageBreak/>
                  <HLRHeatlossByRoomPage/>
                </div>

                {/* render floors and rooms using "floor-room-room-room, floor-room-room, floor-..." scheme */}
                {reportSnapshot.survey.floors.map((floor, floorIdx) => {
                  // skip floors without rooms
                  if (floor.rooms.length === 0) return null
                  return (<Fragment key={'floor-' + floorIdx}>
                    <div>
                      <PrintLayoutPageBreak/>
                      <HLRFloorHeatlossPage floor={floor}/>
                    </div>
                    {isFlagSet(proposal.contents_bitmask, HEATLOSS_REPORT_SECTION_HEATLOSS_PER_ROOM) &&
                        floor.rooms.sort((a, b) => a.name.localeCompare(b.name))
                          .map((room, roomIdx) => {
                            return <Fragment key={'floor-' + floorIdx + '-room-' + roomIdx}>
                              <div>
                                <PrintLayoutPageBreak/>
                                <HLRRoomHeatlossPage floor={floor} room={room}/>
                              </div>
                            </Fragment>
                          })
                    }
                  </Fragment>
                  )
                })}
              </div>

              {/* System design */}
              <div ref={(el) => (sectionRefs.current.systemDesign = el)} className={`lg:scroll-mt-4 scroll-mt-14 flex flex-col  ${gapBetweenPages}`}>
                <div>
                  <PrintLayoutPageBreak/>
                  <HLRHeatPump/>
                </div>

                {emittersDefined && <>
                  <div>
                    <PrintLayoutPageBreak/>
                    <HLREmittersIntro/>
                  </div>
                  <div>
                    <PrintLayoutPageBreak/>
                    <HLREmittersCurrent/>
                  </div>
                  <div>
                    <PrintLayoutPageBreak/>
                    <HLREmittersProposed/>
                  </div>
                </>}

                <div>
                  <PrintLayoutPageBreak/>
                  <HLRHotWater/>
                </div>

                <div>
                  <PrintLayoutPageBreak/>
                  <HLRHotWaterDemand/>
                </div>
              </div>

              {/* Sound assessment */}
              <div ref={(el) => (sectionRefs.current.soundAssessment = el)} className={'lg:scroll-mt-4 scroll-mt-14'}>
                <PrintLayoutPageBreak/>
                <HLRSoundAssessment/>
              </div>

              {/* Performance estimate */}
              <div ref={(el) => (sectionRefs.current.performanceEstimate = el)} className={`lg:scroll-mt-4 scroll-mt-14 flex flex-col ${gapBetweenPages}`}>
                <div>
                  <PrintLayoutPageBreak/>
                  <HLRPESummary/>
                </div>
                <div>
                  <PrintLayoutPageBreak/>
                  <HLRPEDetailedResultsIntroduction/>
                </div>
                <div>
                  <PrintLayoutPageBreak/>
                  <HLRPEResultsTable/>
                </div>
                <div>
                  <PrintLayoutPageBreak/>
                  <HLRPEInputsAndAssumptions/>
                </div>
                <div>
                  <PrintLayoutPageBreak/>
                  <HLRPESystemEfficiency1/>
                </div>
                <div>
                  <PrintLayoutPageBreak/>
                  <HLRPESystemEfficiency2/>
                </div>
                <div>
                  <PrintLayoutPageBreak/>
                  <HLRPEFlowTempChart/>
                </div>
                <div>
                  <PrintLayoutPageBreak/>
                  <HLRPEMCSKeyFacts/>
                </div>
              </div>

              {/* Documents */}
              {(proposal.links && proposal.links.length > 0) && (
                <div ref={(el) => (sectionRefs.current.documents = el)} className='lg:scroll-mt-4 scroll-mt-14 flex flex-col gap-6'>
                  <PrintLayoutPageBreak/>
                  <div className="text-black text-3xl font-bold">Documents</div>
                  <div className='flex flex-col gap-4'>
                    {proposal.links?.map((link, index) => <ProposalLinkAnchor key={index} link={link}/>)}
                  </div>
                </div>
              )}
            </div>
          </div>
        </div>
      </div>

      <AcceptProposalModal
        companyPublicInfo={companyPublicInfo}
        proposal={proposal}
        setProposalSigned={setProposalSigned}
        quoteSnapshot={quoteSnapshot}
        visible={acceptProposalModalVisible}
        setVisible={setAcceptProposalModalVisible}
        termsAndConditionsLink={proposal.links?.find(link => link.category === 'terms_and_conditions')}
      />
    </HLRContext.Provider>
  </>
}

type AcceptProposalModalProps = {
  companyPublicInfo: CompanyPublicInfo
  proposal: Proposal
  setProposalSigned: (value: boolean) => void
  quoteSnapshot: QuoteSnapshotData
  visible: boolean
  setVisible: (value: boolean) => void
  termsAndConditionsLink?: ProposalLink
}

const AcceptProposalModal = ({ companyPublicInfo, proposal, setProposalSigned, quoteSnapshot, visible, setVisible, termsAndConditionsLink }: AcceptProposalModalProps) => {
  const [accepted, setAccepted] = useState(false)
  const [name, setName] = useState('')
  const [date, setDate] = useState('')
  const [status, setStatus] = useState('NOT_ACCEPTED')
  const total = quoteSnapshot.total_pre_deductions + quoteSnapshot.vat_on_all + quoteSnapshot.vat_on_survey + quoteSnapshot.discount_total + quoteSnapshot.bus_grant

  const termsAndConditionsLinkUrl = termsAndConditionsLink?.url + (termsAndConditionsLink?.internal ? `?name_override=${encodeURIComponent(termsAndConditionsLink.title)}.${termsAndConditionsLink.url.split('.')[1]}` : '')

  const handleOnConfirm = async () => {
    if (status === 'ACCEPTED') {
      setVisible(false)
      return
    }
    try {
      await signProposalAPI({ acceptance_customer_name: name, acceptance_customer_date: date }, proposal.lead_uuid, companyPublicInfo.uuid, proposal.uuid)
      setStatus('ACCEPTED')
      setProposalSigned(true)
      confetti()
    } catch (e) {
      console.error(e)
    }
  }

  const allValid = (validateNotNull(name).value && validateLooksLikeDate(date).value && accepted)

  return (
    <Modal
      visible={visible}
      setVisible={setVisible}
      title={'Accept proposal'}
      confirmButtonLabel={status === 'ACCEPTED' ? 'Done' : 'Sign'}
      onConfirm={handleOnConfirm}
      hideOnConfirm={false}
      confirmDisabled={!allValid}
      cancelDisabled={status === 'ACCEPTED'}
    >
      { status === 'ACCEPTED' && <div className='px-5 py-8 bg-green-50 rounded-lg flex-col justify-center items-center gap-2 flex w-full'>
        <img alt='envelope' src={yellowEnvelope} className='w-16 h-16'/>
        <div className="text-gray-900 text-xl font-bold">Success</div>
        <div className="text-center flex flex-col gap-1">
          <span>Your proposal was successfully signed.</span>
        </div>
      </div>}

      {status === 'NOT_ACCEPTED' && <div className='flex flex-col gap-5 w-full'>
        <div className='flex flex-col gap-2 w-full border-b border-gray-300 pb-5'>
          <div className='flex flex-row justify-between items-center text-xs'>
            <Text size="XS" className="text-gray-600">Total before grant</Text>
            <Text size="XS" className="text-gray-600">{formatPrice(quoteSnapshot.total_pre_deductions, 2)}</Text>
          </div>
          {quoteSnapshot.bus_grant < 0 &&
          <div className='flex flex-row justify-between items-start'>
            <Text size="XS" className="text-gray-600">Boiler Upgrade Scheme (BUS) Grant</Text>
            <Text size="XS" className="text-gray-600">{formatPrice(quoteSnapshot.bus_grant, 2)}</Text>
          </div>
          }
          <div className='flex flex-row justify-between items-center'>
            <Text bold>Total</Text>
            <Text bold>{formatPrice(total, 2)}</Text>
          </div>
        </div>
        {/* Checkbox */}
        <label className="flex items-center gap-2">
          <input type="checkbox" className="h-4 w-4" checked={accepted} onChange={() => setAccepted(!accepted)}/>
          <div className="text-gray-600 text-sm">I accept the proposal and agree to be bound by the <a href={termsAndConditionsLinkUrl} target="_blank" rel="noreferrer" className="underline">terms and conditions</a>.</div>
        </label>
        <Input
          label='Your name'
          placeholder='Full name'
          value={name}
          setValue={setName}
          validator={validateNotNull}
        />
        <Input
          label="Today's date"
          placeholder='dd/mm/yyyy'
          value={date}
          setValue={setDate}
          validator={validateLooksLikeDate}
        />
      </div>}
    </Modal>
  )
}
