import React from 'react'
import { type Company } from '../../../../code/models/company'
import { SettingsLayout } from '../components/settings_layout'
import { NewProposalEmailTemplateBlock } from './blocks/new_proposal_email_template_block'

export const EmailTemplatesNewProposal = ({ company, setCompany }: { company: Company, setCompany: (c: Company) => void }) => {
  return <SettingsLayout
    heading='New proposal email template'
    description='Customise the email template that is sent to customers when you send them a full proposal.'
  >
    <NewProposalEmailTemplateBlock company={company} setCompany={setCompany} />
  </SettingsLayout>
}
