import React, { useEffect, useState } from 'react'
import { Tab, TabGroup, TabList, TabPanel, TabPanels } from '@headlessui/react'
import { UnauthorizedPage } from '../pages/admin/unauthorized_page'
import { PageHeaderNoPadding } from '../pages/heat_loss/design/components/design_page_header'
import { type Lead } from '../code/models/lead'

type TabItem = {
  id: string
  label: string
  content?: React.ReactNode
  secondaryTabs?: Array<Pick<TabItem, 'id' | 'label' | 'content' | 'hidden'>>
  hidden?: boolean
  availableOffline?: boolean
  isDisabled?: boolean
  roles?: number[]
}

type Props = {
  basePath: string
  tabs: TabItem[]
  title: string
  selectedTabId?: string
  selectedSecondaryTabId?: string
  isOffline: boolean
  allSynced?: boolean
  userRole?: number
  navigateTo: (url: string) => void
  lead?: Lead
  setLead?: (lead: Lead) => void
  onBack?: () => void
}

const TabbedDashboardLayout = ({ basePath, tabs, title, selectedTabId, selectedSecondaryTabId, isOffline, allSynced, userRole, navigateTo, lead, setLead, onBack }: Props) => {
  tabs.forEach(tab => {
    if (!tab.content && !tab.secondaryTabs) {
      throw new Error(`Provide content OR secondaryTabs to tab ${tab.id}`)
    }
  })

  const [selectedIndex, setSelectedIndex] = useState(0)
  const [selectedSecondaryIndex, setSelectedSecondaryIndex] = useState(0)

  useEffect(() => {
    const tabIndex = tabs.findIndex(x => x.id === selectedTabId)
    setSelectedIndex(tabIndex !== -1 ? tabIndex : 0)

    if (tabs[tabIndex]?.secondaryTabs) {
      const secondaryTabIndex = tabs[tabIndex].secondaryTabs!.findIndex(x => x.id === selectedSecondaryTabId)
      setSelectedSecondaryIndex(secondaryTabIndex !== -1 ? secondaryTabIndex : 0)
    }
  }, [selectedTabId, selectedSecondaryTabId, tabs])

  const handleTabChange = (index: number) => {
    const newTabId = tabs[index].id
    if (tabs[index].secondaryTabs) {
      navigateTo(`${basePath}/${newTabId}/${tabs[index].secondaryTabs?.[0].id}`)
    } else {
      navigateTo(`${basePath}/${newTabId}`)
    }
  }

  const handleSecondaryTabChange = (index: number) => {
    const currentTab = tabs[selectedIndex]
    if (currentTab.secondaryTabs) {
      const newSecondaryTabId = currentTab.secondaryTabs[index].id
      navigateTo(`${basePath}/${currentTab.id}/${newSecondaryTabId}`)
    }
  }

  return (
    <TabGroup selectedIndex={selectedIndex} onChange={handleTabChange} className='relative flex flex-col flex-1 bg-white md:flex-col'>
      <div className="px-5 pt-4 gap-2 flex flex-col border-b border-gray-300 bg-white">
        <PageHeaderNoPadding onBack={onBack} lead={lead} setLead={setLead} title={title} isOffline={isOffline} allSynced={allSynced}>
          <div className='overflow-x-auto no-scrollbar w-full'>
            <TabList className='flex gap-8'>
              {tabs.map(tab => (
                <Tab
                  key={tab.id}
                  disabled={tab.isDisabled}
                  className={`
                  ${tab.hidden && 'hidden'} 
                  outline-none disabled:opacity-50 py-4 whitespace-nowrap cursor-pointer text-default text-xs font-semibold leading-none 
                  data-[selected]:border-black data-[selected]:border-b-2 data-[selected]:text-bold`
                  }
                >
                  {tab.label}
                </Tab>
              ))}
            </TabList>
          </div>
        </PageHeaderNoPadding>
      </div>
      <TabPanels className="overflow-y-auto flex-1 px-5 py-6">
        {tabs.map(tab => {
          if (tab.roles && userRole && !tab.roles.includes(userRole)) {
            return <TabPanel key={tab.id}>
              <UnauthorizedPage />
            </TabPanel>
          }
          return <TabPanel key={tab.id}>
            {tab.secondaryTabs && (!isOffline || tab.availableOffline) ? (
              <TabGroup vertical selectedIndex={selectedSecondaryIndex} onChange={handleSecondaryTabChange} className='flex flex-col md:flex-row gap-6'>
                <TabList className='flex flex-row md:flex-col md:min-w-fit md:w-60 overflow-x-auto'>
                  {tab.secondaryTabs.map(secondaryTab => (
                    <Tab key={secondaryTab.id} className={`${secondaryTab.hidden ? 'hidden' : ''} outline-none p-3 md:py-2.5 md:px-2 rounded-md cursor-pointer text-xs text-default whitespace-nowrap text-left data-[selected]:bg-gray-200 data-[selected]:text-bold data-[selected]:font-semibold`}>
                      {secondaryTab.label}
                    </Tab>
                  ))}
                </TabList>
                <TabPanels className='w-full'>
                  {tab.secondaryTabs.map(secondaryTab => (
                    <TabPanel key={secondaryTab.id}>
                      {secondaryTab.content}
                    </TabPanel>
                  ))}
                </TabPanels>
              </TabGroup>
            ) : tab.content}
          </TabPanel>
        })}
      </TabPanels>
    </TabGroup>
  )
}

export default TabbedDashboardLayout
