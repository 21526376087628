import { apiUrl, client } from '../axios'
import { AuthSDK } from '../utils/auth_provider'
import { type Floor, getFloorAndCeilingDefaultMaterialsForFloor } from '../models/floor'
import { XMLParser } from 'fast-xml-parser'
import {
  DEFAULT_SURVEY_DOOR,
  DEFAULT_SURVEY_FLOOR,
  DEFAULT_SURVEY_ROOM,
  DEFAULT_SURVEY_WALL,
  DEFAULT_SURVEY_WINDOW
} from '../survey_defaults'
import { type PropertySurvey } from '../models/property'
import { type Room } from '../models/room'
import { ROOM_TYPES, type RoomType } from '../../pages/heat_loss/constants'
import _ from 'lodash'
import { type Wall } from '../models/wall'
import {
  calculateLineLength,
  calculateNewTouchingWalls,
  removeInvalidRoomGroups
} from '../../pages/heat_loss/floor/code/utils'
import { type Door } from '../models/door'
import { type WallWindow } from '../models/window'

export type MagicplanPlan = {
  id: string
  name: string
  address: MagicplanPlanAddress
  thumbnail_url: string
}

export type MagicplanPlanDetails = {
  magicplan_format_xml: string
}

export type MagicplanPlanAddress = {
  street: string
  street_number: string
  postal_code: string
  city: string
  country: string
}

type MagicplanPoint = {
  wallUUID: string | undefined
  pointIdx: number
  matchingIdx: number
  height: number
  x: number
  y: number
}

type MagicplanRoom = {
  name: string
  x: number
  y: number
}

type MetaWall = {
  wall: Wall
  length: number
  mPoint: MagicplanPoint
}

export const testMagicplanCreds = async (companyUUID: string, apiKey: string, customerID: string): Promise<number | undefined > => {
  // you can't call Magicplan API directly from the browser because of CORS, so you need to call it from the server
  try {
    const result = await client.get<number>(`${apiUrl}${companyUUID}/magicplan/test_creds?api_key=${apiKey}&customer_id=${customerID}`,
      { headers: { 'x-auth-token': AuthSDK.getToken() } }
    )
    return result.data
  } catch (e: unknown) {
    console.error('Error getting all companies', e)
    return undefined
  }
}

export const getMagicplanPlans = async (companyUUID: string): Promise<MagicplanPlan[] | undefined > => {
  // you can't call Magicplan API directly from the browser because of CORS, so you need to call it from the server
  try {
    const result = await client.get<MagicplanPlan[]>(`${apiUrl}${companyUUID}/magicplan/plans`,
      { headers: { 'x-auth-token': AuthSDK.getToken() } }
    )
    return result.data
  } catch (e: unknown) {
    console.error('Error getting all companies', e)
    return undefined
  }
}

export const getMagicplanPlanDetails = async (planID: string, survey_uuid: string, companyUUID: string): Promise<MagicplanPlanDetails | undefined > => {
  // you can't call Magicplan API directly from the browser because of CORS, so you need to call it from the server
  try {
    const result = await client.get<MagicplanPlanDetails>(`${apiUrl}${companyUUID}/magicplan/plan/${planID}?survey_uuid=${survey_uuid}`,
      { headers: { 'x-auth-token': AuthSDK.getToken() } }
    )
    return result.data
  } catch (e: unknown) {
    console.error('Error getting all companies', e)
    return undefined
  }
}

const TRIM_PRECISION = 20

const trimRooms = (rooms: Room[]) => {
  const newRooms: Room[] = []
  let originalRooms = rooms

  while (originalRooms.length > 0) {
    const otherXY = newRooms.flatMap(r => r.walls.map(w => ({ x: Math.round(r.x! + w.x!), y: Math.round(r.y! + w.y!) })))

    // Find me the next room that would snap to the rooms I have already done.
    const roomMatch = originalRooms.map(x => {
      return {
        room: x,
        wallIndex: x.walls.findIndex(y => {
          const trueX = Math.round(x.x! + y.x!)
          const trueY = Math.round(x.y! + y.y!)

          return otherXY.some(x => Math.abs(x.x - trueX) <= TRIM_PRECISION && Math.abs(x.y - trueY) <= TRIM_PRECISION)
        })
      }
    }).find(x => x.wallIndex >= 0)

    // If I cannot find a room to snap to just take the next item.
    const room = roomMatch?.room ?? originalRooms[0]
    const wallIndex = roomMatch?.wallIndex ?? 0

    // Loop through walls based on the point that room is snapping to another room on.
    const reorderedWalls = [...room.walls.slice(wallIndex), ...room.walls.slice(0, wallIndex)]
    const newWalls: Wall[] = []
    for (const wall of reorderedWalls) {
      const trueX = Math.round(room.x! + wall.x!)
      const trueY = Math.round(room.y! + wall.y!)

      // Compare my new snapped wall to to walls in other rooms, snap to a matching x/y coordinate if close enough.
      const snapped = otherXY.find(x => Math.abs(x.x - trueX) <= TRIM_PRECISION && Math.abs(x.y - trueY) <= TRIM_PRECISION)
      const snappedX = snapped?.x ?? trueX
      const snappedY = snapped?.y ?? trueY

      // Compare my wall to other walls in my own room and any previously imported rooms, make sure we simplify any non straight walls on the x/y axis independently
      const otherXYWalls = newWalls.map(w => ({ x: Math.round(room.x! + w.x!), y: Math.round(room.y! + w.y!) }))
      const snappedRoomX = !snapped ? [...otherXYWalls, ...otherXY].find(x => Math.abs(x.x - trueX) <= TRIM_PRECISION)?.x ?? trueX : snappedX
      const snappedRoomY = !snapped ? [...otherXYWalls, ...otherXY].find(x => Math.abs(x.y - trueY) <= TRIM_PRECISION)?.y ?? trueY : snappedY

      newWalls.push({ ...wall, x: snappedRoomX - Math.round(room.x!), y: snappedRoomY - Math.round(room.y!) })
    }

    newRooms.push({ ...room, walls: newWalls })
    originalRooms = originalRooms.filter(x => x.uuid !== room.uuid)
  }

  return newRooms
}

export const convertMagicplanPlanDetailsToFloors = (planDetails: MagicplanPlanDetails, survey: PropertySurvey): Floor[] => {
  const xmlString = planDetails.magicplan_format_xml

  const parser = new XMLParser({
    ignoreAttributes: false,
    parseAttributeValue: true,
    parseTagValue: true
  })

  const xmlPlan = parser.parse(xmlString).plan // .interiorRoomPoints

  if (xmlPlan.floor === undefined) {
    return []
  }

  const xmlFloors = Array.isArray(xmlPlan.floor) ? xmlPlan.floor : [xmlPlan.floor]

  // keep only floors with rooms (compact)
  return _.compact(xmlFloors.map((xmlFloor: any) => {
    const xmlSymbolInstances = Array.isArray(xmlFloor.symbolInstance) ? xmlFloor.symbolInstance : [xmlFloor.symbolInstance]

    // if there are no rooms on the floor, return undefined
    if (xmlFloor.floorRoom === undefined) {
      return undefined
    }

    const xmlRooms = Array.isArray(xmlFloor.floorRoom) ? xmlFloor.floorRoom : [xmlFloor.floorRoom]

    const { offsetX, offsetY } = calculateOffset(xmlRooms)

    const mRoomsPoints = xmlRooms.map(xmlRoom => xmlPointsToMagicplanPoints(xmlRoom, offsetX, offsetY))

    const floor = {
      ...DEFAULT_SURVEY_FLOOR,
      uuid: crypto.randomUUID(),
      name: xmlFloor.name ?? 'Floor'

      // mandatory fields
      // TODO: dry the code, generate default objects somewhere else in a single place
      // default_floor_material_type: floorMaterialType,
      // default_ceiling_material_type: ceilingMaterialType
    }
    const [floorMaterial, ceilingMaterial] = getFloorAndCeilingDefaultMaterialsForFloor(floor, survey)

    // make an array of rooms without windows and doors
    const roomsForWalls = xmlRooms.map((xmlRoom: any, idxRoom: number) => {
      const mRoom = xmlRoomToMagicplanRoom(xmlRoom, offsetX, offsetY)
      const roomType = roomNameToRoomType(mRoom.name)

      const room: Room = {
        ...DEFAULT_SURVEY_ROOM,
        uuid: crypto.randomUUID(),
        name: mRoom.name,
        room_type_uuid: roomType.uuid,
        x: mRoom.x,
        y: mRoom.y,

        // mandatory fields
        // TODO: dry the code, generate default objects somewhere else in a single place
        floor_material: floorMaterial,
        ceiling_material: ceilingMaterial,
        age_band: survey.age_band
      } satisfies Room

      // xmlPoints are defined clockwise, but we need them counter-clockwise
      const roomWallsHeights: number[] = []

      // reverse the points to make them counter-clockwise
      const walls = mRoomsPoints[idxRoom].map((point: MagicplanPoint) => {
        const wall = {
          ...DEFAULT_SURVEY_WALL,
          uuid: crypto.randomUUID(),
          doors: [], // empty for now, will be filled later
          windows: [], // empty for now, will be filled later

          // mandatory fields
          // TODO: dry the code, generate default objects somewhere else in a single place
          material: survey.default_materials!.externalWall!
        } satisfies Wall

        // update parent point entity attribute for further mapping this to a wall
        point.wallUUID = wall.uuid
        roomWallsHeights.push(point.height)

        return {
          ...wall,
          x: point.x,
          y: point.y
        } satisfies Wall
      })

      // Calculate room height as average of all wall heights
      const avgRoomHeight = roomWallsHeights.reduce((acc, height) => acc + height, 0) / roomWallsHeights.length

      return {
        ...room,
        height_m: avgRoomHeight,
        walls
      } satisfies Room
    }) as Room[]

    const trimmedRooms = _.sortBy(trimRooms(roomsForWalls), (room) => {
      return roomsForWalls.map((r) => r.uuid).indexOf(room.uuid)
    })

    // reverse update room points to reflect changes after trimming
    trimmedRooms.forEach((room: Room, idxRoom: number) => {
      const mRoomPoints = mRoomsPoints[idxRoom]
      room.walls.forEach((wall: Wall) => {
        const mPoint = mRoomPoints.find((p: MagicplanPoint) => p.wallUUID === wall.uuid)
        if (mPoint) {
          mPoint.x = wall.x!
          mPoint.y = wall.y!
        }
      })
    })

    // snap rooms to each other
    const newRooms = calculateNewTouchingWalls(trimmedRooms, survey.default_materials!)
    const removedInvalidRoomGroups = removeInvalidRoomGroups(newRooms)

    // after merging walls we need to add windows and doors to proper walls in the room
    const roomsWithDoorsAndWindows = removedInvalidRoomGroups.map((room: Room, idxRoom: number) => {
      const xmlRoom = xmlRooms[idxRoom]
      const xmlWindows = !xmlRoom.window ? undefined : (Array.isArray(xmlRoom.window) ? xmlRoom.window : [xmlRoom.window])
      const xmlDoors = !xmlRoom.door ? undefined : (Array.isArray(xmlRoom.door) ? xmlRoom.door : [xmlRoom.door])

      // if no doors and windows in the room, return the room as is
      if (!xmlWindows && !xmlDoors) {
        return room
      }

      // get and convert all points from Magicplan XML to our format
      // const mPoints = xmlPointsToMagicplanPoints(xmlRoom, offsetX, offsetY)
      const mPoints = mRoomsPoints[idxRoom]

      // after calling `calculateNewTouchingWalls` we'll potentially have new common walls between rooms
      // these walls are NOT defined in magicplan format
      // here ,we're grouping such walls using the magicplan points:
      // 1. if wall's starting position matches with a point — this is a magicplan wall
      // 2. if wall's starting position doesn't match with a point — this is a new wall, created by our algorithm
      // Basically, the new walls are just segments of the original Magicplan walls.
      // It's important to know what walls segments we have to properly position windows and doors below
      let tmpPoint: MagicplanPoint | undefined
      const groupedWalls = _.groupBy(
        room.walls.map((wall: Wall, idx: number) => {
          let mPoint = mPoints.find((p: MagicplanPoint) => p.x === wall.x! && p.y === wall.y!)

          // if the wall has a Point — it's a magicplan wall
          // it not — it's a wall, created by our algorithm
          // let's assign the leading Point to such walls. It's safe because the walls are in a strict order.
          if (mPoint) {
          // save the Point for potential assignment to the next wall
            tmpPoint = mPoint
          } else {
          // assign the last available Point to the wall
            mPoint = tmpPoint
          }

          // calculate the length of the wall
          const linkedWallIdx = (idx === room.walls.length - 1) ? 0 : idx + 1 // use index === 0 as a linked wall index for the last wall
          const linkedWall = room.walls[linkedWallIdx]
          const length = calculateLineLength(wall.x!, wall.y!, linkedWall.x!, linkedWall.y!)

          return {
            wall,
            mPoint: mPoint!,
            length
          } satisfies MetaWall
        }),
        // group by point
        (metaWall: MetaWall) => metaWall.mPoint.pointIdx
      )

      // aggregate walls here in the next cycle
      const walls: Wall[] = []

      // iterate each group of walls
      Object.keys(groupedWalls).forEach((groupKey: string) => {
        const groupWalls: MetaWall[] = groupedWalls[groupKey]

        // point is the same for all walls in the group, so we can use the first one
        const mPoint = groupWalls[0].mPoint

        // get all windows for the group of walls
        // using starting point of the wall
        const groupXmlWindows = _.compact(xmlWindows
          ?.filter((xmlWindow: any) => parseInt(xmlWindow['@_point']) === mPoint.matchingIdx)
          .map((xmlWindow: any) => {
            const xmlSymbolInstance = xmlSymbolInstances.find((xmlSymbolInstance: any) => xmlSymbolInstance['@_id'] === xmlWindow['@_symbolInstance'])
            // skip if the symbol is not a window
            if (!xmlSymbolInstance['@_symbol'].match(/^window.*/i)) {
              return null
            }
            return xmlWindow
          })
        )

        // get all doors for the group of walls
        // using starting point of the wall
        const groupXmlDoors = _.compact(xmlDoors
          ?.filter((xmlDoor: any) => parseInt(xmlDoor['@_point']) === mPoint.matchingIdx)
          .map((xmlDoor: any) => {
            // find the door in the xmlSymbolInstances
            const xmlSymbolInstance = xmlSymbolInstances.find((xmlSymbolInstance: any) => xmlSymbolInstance['@_id'] === xmlDoor['@_symbolInstance'])
            // skip if the symbol is not a door (could be a furniture door)
            if (!xmlSymbolInstance['@_symbol'].match(/^door.*/i)) {
              return null
            }
            return xmlDoor
          })
        )

        // if the walls has no segments (single wall)
        // let's just add windows and doors to the wall skipping complex calculations happening after
        if (groupWalls.length === 1) {
          // clone the wall to prevent mutation of the original wall
          const wall = _.cloneDeep(groupWalls[0].wall)

          // Is there windows? Returns empty array if not.
          wall.windows = groupXmlWindows.map((xmlWindow: any) => {
            return {
              ...DEFAULT_SURVEY_WINDOW,
              uuid: crypto.randomUUID(),
              material: survey.default_materials!.window!,
              width_mm: Math.round(parseFloat(xmlWindow['@_width']) * 1000),
              height_mm: Math.round(parseFloat(xmlWindow['@_height']) * 1000)
            } satisfies WallWindow
          })

          // Is there doors? Returns empty array if not.
          wall.doors = groupXmlDoors.map((xmlDoor: any) => {
            return {
              ...DEFAULT_SURVEY_DOOR,
              uuid: crypto.randomUUID(),
              material: survey.default_materials!.door!,
              width_mm: Math.round(parseFloat(xmlDoor['@_width']) * 1000),
              height_mm: Math.round(parseFloat(xmlDoor['@_height']) * 1000)
            } satisfies Door
          })

          walls.push(wall)
          return
        }

        // !! The logic implemented below
        // Each window and door in XML notation of Magicplan has attribute `snappedPosition`, which is defined as:
        //   > The corrected relative position of the center of the window on the wall represented
        //   > by a floating point number between 0.0 and 1.0. This value should be used when drawing an assembled project.
        //   Ref: https://apidocs.magicplan.app/#floor-plan-xml-room-window
        // It means, we need to do the following:
        // 1. calculate a total length of the wall group
        // 2. calculate the position (in meters) of the window on the wall
        // 3. find a wall where the window is located

        // 1. calculate a total length of the wall group
        const groupWallsTotalLength = groupWalls.reduce((acc, w) => acc + w.length, 0)

        // accumulating starting offset for each wall [segment] in the group
        let lengthOffset: number = 0

        // iterate each wall in the group
        groupWalls.forEach((w: MetaWall) => {
          const wall = _.cloneDeep(w.wall)

          // calculate start position of the wall segment (from 0 to 1)
          const startPosition = lengthOffset / groupWallsTotalLength
          // calculate end position of the wall segment (from 0 to 1)
          const endPosition = (lengthOffset + w.length) / groupWallsTotalLength

          // add windows on the wall
          groupXmlWindows.forEach((xmlWindow: any) => {
            // get and inverse position because Magicplan uses clockwise points, but we need counter-clockwise
            const windowPosition = 1 - parseFloat(xmlWindow['@_snappedPosition'])

            if (windowPosition >= startPosition && windowPosition <= endPosition) {
              const window = {
                ...DEFAULT_SURVEY_WINDOW,
                uuid: crypto.randomUUID(),
                material: survey.default_materials!.window!,
                width_mm: Math.round(parseFloat(xmlWindow['@_width']) * 1000),
                height_mm: Math.round(parseFloat(xmlWindow['@_height']) * 1000)
              } satisfies WallWindow

              wall.windows.push(window)
            }
          })

          // add windows on the wall
          groupXmlDoors.forEach((xmlDoor: any) => {
            // get and inverse position because Magicplan uses clockwise points, but we need counter-clockwise
            const doorPosition = 1 - parseFloat(xmlDoor['@_snappedPosition'])

            if (doorPosition >= startPosition && doorPosition <= endPosition) {
              const door = {
                ...DEFAULT_SURVEY_DOOR,
                uuid: crypto.randomUUID(),
                material: survey.default_materials!.door!,
                width_mm: Math.round(parseFloat(xmlDoor['@_width']) * 1000),
                height_mm: Math.round(parseFloat(xmlDoor['@_height']) * 1000)
              } satisfies Door

              wall.doors.push(door)
            }
          })

          lengthOffset += w.length
          walls.push(wall)
        })
      })

      const result = {
        ...room,
        walls
      } satisfies Room

      return result
    })

    const result = {
      ...floor,
      name: 'Imported: ' + floor.name,
      rooms: roomsWithDoorsAndWindows
    } satisfies Floor

    // FIXME: floorplan image is disabled for now
    // if (planDetails.floors[floorIdx].image) {
    //   // BEGIN: calculate floor plan image scaling factor
    //   // there is `image_map` attribute in the API response which represents coordinates of the floor plan SVG image
    //   // and the coordinates array is flat, i.e. one-dimension: [x1, y1, x2, y2, ...]
    //   // so, let's get first two points and calculate the distance between them
    //   const floorPlanImageMap = planDetails.floors[floorIdx].image_map[0]
    //
    //   const [x1, y1, x2, y2] = floorPlanImageMap.coordinates.slice(0, 4)
    //   const svgDistance = Math.sqrt(Math.pow(x2 - x1, 2) + Math.pow(y2 - y1, 2))
    //
    //   // not calculate distance for the first wall in the XML data
    //   const [xmlWallPoint1X, xmlWallPoint1Y, xmlWallPoint2X, xmlWallPoint2Y] = [
    //     xmlFloor.exploded.wall[0].point[0]['@_x'],
    //     xmlFloor.exploded.wall[0].point[0]['@_y'],
    //     xmlFloor.exploded.wall[0].point[1]['@_x'],
    //     xmlFloor.exploded.wall[0].point[1]['@_y']
    //   ]
    //   const xmlDistance = Math.sqrt(Math.pow(xmlWallPoint2X - xmlWallPoint1X, 2) + Math.pow(xmlWallPoint2Y - xmlWallPoint1Y, 2))
    //   // END: calculate floor plan image scaling factor
    //
    //   result.floor_plan_image = await svgToPng(planDetails.floors[floorIdx].image)
    //   result.floor_plan_is_showing = true
    //   // calculate the scaling factor
    //   result.floor_plan_scale = xmlDistance / svgDistance * 50 // 50 points per meter in our system
    // }

    return result satisfies Floor
  }))
}

// const trimPointerPosition = (x: number, y: number) => {
//   const stepSizeLocal = 5
//   const newX = Math.round(x / stepSizeLocal) * stepSizeLocal
//   const newY = Math.round(y / stepSizeLocal) * stepSizeLocal
//   // const newX = Math.round(x)
//   // const newY = Math.round(y)

//   return { x: newX, y: newY }
// }

const xmlPointsToMagicplanPoints = (xmlRoom: any, offsetX: number, offsetY: number): MagicplanPoint[] => {
  // reverse the points to make them counter-clockwise
  const xmlPoints = Array.isArray(xmlRoom.point) ? xmlRoom.point : [xmlRoom.point]

  // reverse() changes the original array! Clone it before reversing
  return _.reverse(_.cloneDeep(xmlPoints))
    .map((xmlPoint: any, pointIdx: number) => {
      const x = Math.round(offsetX + (parseFloat(xmlPoint['@_snappedX']) * 1000))
      const y = Math.round(offsetY + (parseFloat(xmlPoint['@_snappedY']) * 1000))

      return {
        wallUUID: undefined, // will be filled later
        pointIdx, // e.g.: 0 1 2 3 4
        matchingIdx: pointIdx === (xmlPoints.length - 1) ? (xmlPoints.length - 1) : Math.abs(pointIdx - (xmlPoints.length - 2)),
        height: parseFloat(xmlPoint['@_height']),
        x,
        y
      } satisfies MagicplanPoint
    })
}

const xmlRoomToMagicplanRoom = (xmlRoom: any, offsetX: number, offsetY: number): MagicplanRoom => {
  return {
    name: xmlRoom['@_type'],
    x: Math.round(offsetX + (parseFloat(xmlRoom['@_x']) * 1000)),
    y: Math.round(offsetY + (parseFloat(xmlRoom['@_y']) * 1000))
  }
}

// used to center the floor plan on the canvas
const calculateOffset = (xmlRooms: any[]) => {
  let minX = Infinity
  let maxX = -Infinity
  let minY = Infinity
  let maxY = -Infinity

  xmlRooms.forEach((xmlRoom: any) => {
    const roomX = Math.round(parseFloat(xmlRoom['@_x']) * 1000)
    const roomY = Math.round(parseFloat(xmlRoom['@_y']) * 1000)
    const xmlPoints = Array.isArray(xmlRoom.point) ? xmlRoom.point : [xmlRoom.point]

    xmlPoints.forEach((xmlPoint: any) => {
      const x = roomX + (parseFloat(xmlPoint['@_snappedX']) * 1000)
      const y = roomY + (parseFloat(xmlPoint['@_snappedY']) * 1000)

      minX = Math.min(minX, x)
      maxX = Math.max(maxX, x)
      minY = Math.min(minY, y)
      maxY = Math.max(maxY, y)
    })
  })

  // these constants are to center the floor plan
  const offsetX = -((maxX - minX) / 2)
  const offsetY = -((maxY - minY) / 2)

  return { offsetX, offsetY }
}

const roomNameToRoomType = (roomName: string): RoomType => {
  /*
  Magicplan Room Types (https://apidocs.magicplan.app/#code-lists-predefined-list-of-residential-rooms):
  */

  if (roomName.match(/kitchen/i)) {
    return ROOM_TYPES.find(x => x.uuid === 'kitchen')!
  }

  if (roomName.match(/(closet|workshop)/i)) {
    return ROOM_TYPES.find(x => x.uuid === 'store')!
  }

  if (roomName.match(/dining/i)) {
    return ROOM_TYPES.find(x => x.uuid === 'dining')!
  }

  if (roomName.match(/(living|music|playroom)/i)) {
    return ROOM_TYPES.find(x => x.uuid === 'living_lounge')!
  }

  if (roomName.match(/(hall|corridor|stairway|vestibule)/i)) {
    return ROOM_TYPES.find(x => x.uuid === 'hall_landing')!
  }

  if (roomName.match(/bedroom/i)) {
    return ROOM_TYPES.find(x => x.uuid === 'bedroom')!
  }

  if (roomName.match(/bathroom/i)) {
    return ROOM_TYPES.find(x => x.uuid === 'bath_shower')!
  }

  if (roomName.match(/study/i)) {
    return ROOM_TYPES.find(x => x.uuid === 'study')!
  }

  if (roomName.match(/(toilet|wc)/i)) {
    return ROOM_TYPES.find(x => x.uuid === 'cloaks_wc')!
  }

  if (roomName.match(/(laundry|furnace)/i)) {
    return ROOM_TYPES.find(x => x.uuid === 'utility')!
  }

  // return default for:
  // - Balcony -> default
  // - Garage -> default
  // - Cellar -> default
  // - Other -> default
  // - Hatched Room -> default
  // and other unmatched room names
  return ROOM_TYPES.find(x => x.uuid === 'living_lounge')!
}
