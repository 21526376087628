import React, { type ReactNode } from 'react'
import { Heading } from '../../../../components/content_display/heading'
import { Icon } from '../../../../components/buttons/icon'
import { Alert } from '../../../../components/indicators_and_messaging/alert'
import { ChevronLeft, X } from 'lucide-react'
import { WrappedIcon } from '../../../../components/buttons/wrapped_icon'

type Props = {
  title: string

  visible: boolean
  setVisible: (v: boolean) => void

  previewContent: ReactNode

  sidebarTitle: string
  sidebarContent: ReactNode
}

export const FullscreenPreviewModal = (props: Props) => {
  if (!props.visible) return null

  return <>
    <div
      style={{
        paddingTop: 'env(safe-area-inset-top)',
        paddingRight: 'env(safe-area-inset-right)',
        paddingBottom: 'env(safe-area-inset-bottom)',
        paddingLeft: 'env(safe-area-inset-left)'
      }}
      className='fixed top-0 left-0 w-full h-full bg-white z-10'
    >
      <div className={'bg-white rounded-md flex flex-col w-full h-full'}>
        <div className='p-5 flex justify-between items-center border-b border-gray-200'>
          <div className={'flex flex-row gap-4 items-center'}>
            <Icon icon={ChevronLeft} onClick={() => props.setVisible(false)} />
            <Heading size="xl">{props.title}</Heading>
          </div>
          <WrappedIcon icon={X} onClick={() => props.setVisible(false)}
            className={'cursor-pointer'}/>
        </div>
        <div className='flex flex-row justify-between items-center h-[calc(100vh-74px)]'>
          <div className='hidden lg:block bg-gray-100 h-[calc(100vh-74px)] flex-grow overflow-x-scroll p-6'>
            <div className='bg-white max-w-[260mm] m-auto pb-6 rounded'>
              {props.previewContent}
            </div>
          </div>
          <div className='w-full lg:max-w-96 lg:min-w-96 h-full flax flex-col gap-0'>
            <div className="text-center text-gray-900 font-bold p-4 border-b border-gray-200">
              {props.sidebarTitle}
            </div>
            <div className='h-[calc(100vh-130px)] overflow-scroll w-full flex flex-col gap-0 p-5 items-center'>
              <Alert className='block lg:hidden w-full mb-4' type="INFO">To view the preview, please open Spruce on a tablet or desktop device.</Alert>
              {props.sidebarContent}
            </div>
          </div>
        </div>
      </div>
    </div>
  </>
}
