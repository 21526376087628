import React from 'react'
import { Section } from '../../components/section'
import { InfoCard } from '../../components/info_card'
import { numberFormat } from '../../../../../code/number_format'
import { leadWithNewOverride, type Lead } from '../../../../../code/models/lead'
import { Heading } from '../../../../../components/content_display/heading'
import { Text } from '../../../../../components/content_display/text'
import { Input } from '../../../../../components/inputs_and_selections/input'
import { type TableColumn, TableLite } from '../../../../../components/content_display/table_lite'
import { Button } from '../../../../../components/buttons/button'
import { type Estimate } from '../../../../../code/calculate_estimate'
import { Icon } from '../../../../../components/buttons/icon'
import { type HouseOverrides } from '../../../../../code/models/property'
import { CAVITY_WALL, CAVITY_WALL_INSULATION, SOLID_WALL_INSULATION } from '../../../../../code/models/u_value'
import { BillSavingsInfo } from '../../../../estimate_page'
import { XCircle, RefreshCw } from 'lucide-react'

type Props = {
  lead: Lead
  setLead: (lead: Lead) => void
  estimate: Estimate
}

type ElementsTable = {
  element: string | JSX.Element
  uValue: string | JSX.Element
  estimate: string | JSX.Element
}

type HeatLossElementsTableProps = {
  lead: Lead
  setOverride: (key: string, value: number | undefined) => void
  estimate: Estimate
}

export const HeatLossElementsTable = ({ lead, setOverride, estimate }: HeatLossElementsTableProps) => {
  const columns: Array<TableColumn<ElementsTable>> = [
    { key: 'element', name: 'Element' },
    { key: 'uValue', name: 'U-Value (W/m²K)' },
    { key: 'estimate', name: 'Estimate' }
  ]

  const valueIsOverridden = (key: string) => {
    return lead.property.houseOverrides && lead.property.houseOverrides[key] !== undefined
  }

  // TODO: This is kind of an ugly hack - it should be somewhere more general
  // Cf.: https://github.com/SpruceEco/spruce/pull/512#issuecomment-2333580417
  const insulationLabel = (lead: Lead) => {
    const wallTypeId = lead.property.houseOverrides?.wallType ?? lead.property.wallType
    const wallGroup = lead.property.houseOverrides?.wallGroup ?? lead.property.wallGroup
    if (!wallTypeId || !wallGroup) return ''
    const wallType = (wallGroup === CAVITY_WALL ? CAVITY_WALL_INSULATION : SOLID_WALL_INSULATION).find(x => x.uuid === wallTypeId)
    if (wallGroup === CAVITY_WALL) {
      return wallType?.name || 'Uninsulated' // Insultated or Uninsulated
    }
    switch (wallType?.uuid) {
      case 'internal':
        return 'Internal insulation'
      case 'external':
        return 'External insulation'
      case 'idk':
        return 'Unknown insulation'
      case 'none':
      default:
        return 'No insulation'
    }
  }

  const rows = [
    {
      element: <><Text bold={true} size="SM">External walls</Text><Text size="SM" className='text-light'>{[
        lead.property.houseOverrides?.wallGroup ?? lead.property.wallGroup,
        insulationLabel(lead).toLowerCase()
      ].filter(Boolean).join(', ')}</Text></>,
      uValue: <Input
        type="number"
        step={0.1}
        shrink={true}
        value={lead.property.houseOverrides?.externalWallUValueOverride?.toString() || estimate.externalWallUValue.toString()}
        setValue={(e) => setOverride('externalWallUValueOverride', parseFloat(e))}
        postfix={<div>{valueIsOverridden('externalWallUValueOverride') && <Icon icon={XCircle} onClick={() => setOverride('externalWallUValueOverride', undefined)} colour='text-gray-400' />} </div>}
      />,
      estimate: `${numberFormat(0).format(estimate.externalWallWatts)} W`
    },
    {
      element: <><Text bold={true} size="SM">Party walls</Text><Text size="SM" className='text-light'>{[
        lead.property.houseOverrides?.wallGroup ?? lead.property.wallGroup,
        insulationLabel(lead).toLowerCase()
      ].filter(Boolean).join(', ')}</Text></>,
      uValue: <Input
        type="number"
        step={0.1}
        shrink={true}
        value={lead.property.houseOverrides?.partyWallUValueOverride?.toString() || estimate.partyWallUValue.toString()}
        setValue={(e) => setOverride('partyWallUValueOverride', parseFloat(e))}
        postfix={<div>{valueIsOverridden('partyWallUValueOverride') && <Icon icon={XCircle} onClick={() => setOverride('partyWallUValueOverride', undefined)} colour='text-gray-400' />} </div>}
      />,
      estimate: `${numberFormat(0).format(estimate.partyWallWatts)} W`
    },
    {
      element: <><Text bold={true} size="SM">Windows</Text><Text size="SM" className='text-light'>{lead.property.houseOverrides?.windowType ?? lead.property.windowType}</Text></>,
      uValue: <Input
        type="number"
        step={0.1}
        shrink={true}
        value={lead.property.houseOverrides?.windowsUValueOverride?.toString() || estimate.windowsUValue.toString()}
        setValue={(e) => setOverride('windowsUValueOverride', parseFloat(e))}
        postfix={<div>{valueIsOverridden('windowsUValueOverride') && <Icon icon={XCircle} onClick={() => setOverride('windowsUValueOverride', undefined)} colour='text-gray-400' />} </div>}
      />,
      estimate: `${numberFormat(0).format(estimate.windowWatts)} W`
    },
    {
      element: <><Text bold={true} size="SM">Floors</Text><Text size="SM" className='text-light'>{lead.property.houseOverrides?.floorType ?? lead.property.floorType}</Text></>,
      uValue: <Input
        type="number"
        step={0.1}
        shrink={true}
        value={lead.property.houseOverrides?.floorUValueOverride?.toString() || estimate.floorUValue.toString()}
        setValue={(e) => setOverride('floorUValueOverride', parseFloat(e))}
        postfix={<div>{valueIsOverridden('floorUValueOverride') && <Icon icon={XCircle} onClick={() => setOverride('floorUValueOverride', undefined)} colour='text-gray-400' />} </div>}
      />,
      estimate: `${numberFormat(0).format(estimate.floorWatts)} W`
    },
    {
      element: <><Text bold={true} size="SM">Roof</Text><Text size="SM" className='text-light'>{lead.property.houseOverrides?.loftInsulation ?? lead.property.loftInsulation}</Text></>,
      uValue: <Input
        type="number"
        step={0.1}
        shrink={true}
        value={lead.property.houseOverrides?.roofUValueOverride?.toString() || estimate.roofUValue.toString()}
        setValue={(e) => setOverride('roofUValueOverride', parseFloat(e))}
        postfix={<div>{valueIsOverridden('roofUValueOverride') && <Icon icon={XCircle} onClick={() => setOverride('roofUValueOverride', undefined)} colour='text-gray-400' />} </div>}
      />,
      estimate: `${numberFormat(0).format(estimate.roofWatts)} W`
    },
    {
      element: <Text bold={true} size="SM">Ventilation</Text>,
      uValue: '',
      estimate: `${numberFormat(0).format(estimate.ventilationWatts)} W`
    },
    {
      element: <Text bold={true}>Total</Text>,
      uValue: '',
      estimate: <Text bold={true}>{(estimate.totalWatts / 1000).toFixed(2)} kW</Text>
    }
  ]

  return (
    <>
      <Heading size="md">Elements</Heading>
      <TableLite columns={columns} rows={rows} alignRight={false} />
    </>
  )
}

export const HeatLossSummaryCards = ({ lead, estimate, columns }: { lead: Lead, estimate: Estimate, columns: 1 | 4 }) => {
  return (
    <div className={`grid grid-cols-1 ${columns === 4 && 'md:grid-cols-4'} gap-5`}>
      <InfoCard title="Total heat loss" value={<>{(estimate.totalWatts / 1000).toFixed(2)} kW</>} />
      <InfoCard title="Heat loss per m²" value={<>{numberFormat(0).format(estimate.totalWatts / lead.property.floorArea)} W/m²</>} />
      <InfoCard title={<>CO<sub>2</sub> savings/year</>} value={<>{numberFormat(0).format(estimate.CO2SavedKg)} kg/year</>} />
      <InfoCard
        title="Bill savings/year"
        value={<>£{numberFormat(0).format(estimate.annualBillBaselineGBP - estimate.annualBillHeatPumpTariffGBP)}/year</>}
        info={<BillSavingsInfo
          billSavingsInfoContent={{
            annual_bill_hp_tariff_gbp: estimate.annualBillHeatPumpTariffGBP,
            annual_bill_baseline_gbp: estimate.annualBillBaselineGBP,
            annual_kwh_heat_required: estimate.annualKwhHeat,
            average_efficiency_baseline: estimate.efficiencyBaseline,
            average_efficiency_hp: estimate.averageEfficiencyHeatPump,
            fuel_name_baseline: estimate.fuelNameBaseline,
            tariff_p_per_kwh_baseline: estimate.tariffPPerKwhBaseline,
            tariff_p_per_kwh_electricity_hp_tariff: estimate.tariffPPerKwhElectricityHpTariff,
            electric_hp_tariff_based_on: estimate.electricHpTariffBasedOn,
            annual_bill_hp_price_cap_gbp: estimate.annualBillHeatPumpPriceCapGBP,
            tariff_p_per_kwh_electricity_price_cap: estimate.tariffPPerKwhElectricityPriceCap,
            price_cap_description: estimate.priceCapDescription,
            heat_pump_name: estimate.heatPump?.name ?? '',
            flow_temperature_c: estimate.flowTempC
          }}
          homeownerFacing={false}/>}
      />
    </div>
  )
}

export const HeatLossEstimate = ({ lead, setLead, estimate }: Props) => {
  const handleResetEditingData = () => {
    setLead({
      ...lead,
      property: {
        ...lead.property,
        houseOverrides: {
          ...lead.property.houseOverrides,
          designTempOverride: undefined,
          internalTempOverride: undefined,
          airChangeOverride: undefined,
          externalWallUValueOverride: undefined,
          partyWallUValueOverride: undefined,
          windowsUValueOverride: undefined,
          floorUValueOverride: undefined,
          roofUValueOverride: undefined
        }
      }
    })
  }

  const setOverride = (key: keyof HouseOverrides, value: number | undefined) => {
    setLead(leadWithNewOverride(lead, estimate.defaults, key, value))
  }

  const valueIsOverridden = (key: string) => {
    return lead.property.houseOverrides && lead.property.houseOverrides[key] !== undefined
  }

  return (
    <Section title="Heat loss estimate" controls={
      <>
        <Button colour='LIGHT' iconLeft={RefreshCw} onClick={handleResetEditingData} size="SM">Reset to defaults</Button>
      </>
    }>

      <HeatLossSummaryCards lead={lead} estimate={estimate} columns={4} />

      <Heading size="md">Variables</Heading>

      <div className='grid grid-cols-1 md:grid-cols-3 gap-5'>
        <div>
          <Input type="number" step={0.1} label='Design temp. (°C)' value={estimate.designTempC.toString()} setValue={(e) => setOverride('designTempOverride', parseFloat(e))}
            postfix={<div>°C {valueIsOverridden('designTempOverride') && <Icon icon={XCircle} onClick={() => setOverride('designTempOverride', undefined)} colour='text-gray-400' />} </div>}
          />
        </div>
        <div>
          <Input type="number" step={0.1} label='Internal temp. (°C)' value={estimate.internalTempC.toString()} setValue={(e) => setOverride('internalTempOverride', parseFloat(e))}
            postfix={<div>°C {valueIsOverridden('internalTempOverride') && <Icon icon={XCircle} onClick={() => setOverride('internalTempOverride', undefined)} colour='text-gray-400' />} </div>}
          />
        </div>
        <div>
          <Input type="number" step={0.1} label="ACH" value={estimate.airChanges.toString()} setValue={(e) => setOverride('airChangeOverride', parseFloat(e))}
            postfix={<div> {valueIsOverridden('airChangeOverride') && <Icon icon={XCircle} onClick={() => setOverride('airChangeOverride', undefined)} colour='text-gray-400' />} </div>}
          />
        </div>
      </div>

      <HeatLossElementsTable
        lead={lead}
        setOverride={setOverride}
        estimate={estimate}
      />

    </Section>
  )
}
