import React, { type PropsWithChildren } from 'react'
import { Icon } from '../buttons/icon'
import { AlertCircle, AlertTriangle, CheckCircle, Info } from 'lucide-react'

type AlertProps = {
  type: 'INFO' | 'WARNING' | 'DANGER' | 'SUCCESS'
  className?: string
}

const colorMap = {
  INFO: 'text-blue-900 bg-blue-100',
  WARNING: 'text-yellow-900 bg-yellow-100',
  DANGER: 'text-red-900 bg-red-100',
  SUCCESS: 'text-green-900 bg-green-100'
}

const iconMap = {
  INFO: Info,
  WARNING: AlertCircle,
  DANGER: AlertTriangle,
  SUCCESS: CheckCircle
}

export const Alert = ({ type, className, children }: PropsWithChildren<AlertProps>) => {
  const color = colorMap[type]

  return <div className={`relative flex items-start p-3 gap-2 text-sm rounded-lg ${color} ${className}`} role="alert">
    <Icon icon={iconMap[type]} colour={color} className='relative top-0.5' />
    <div>
      {children}
    </div>
  </div>
}
