import React from 'react'
import { type Company } from '../../../../code/models/company'
import { SettingsLayout } from '../components/settings_layout'
import { ReportEmailTemplateBlock } from './blocks/report_email_template_block'

export const EmailTemplatesReport = ({ company, setCompany }: { company: Company, setCompany: (c: Company) => void }) => {
  return <SettingsLayout
    heading='Report sending email template'
    description='Customise the email template that is sent to customers when you send them a heat loss report.'
  >
    <ReportEmailTemplateBlock company={company} setCompany={setCompany} />
  </SettingsLayout>
}
