import React, { useEffect, useState } from 'react'
import { logEvent } from '../../code/log_event'
import { type CompanyPublicInfo } from '../../code/models/company'
import { Input } from '../../components/inputs_and_selections/input'
import { Button } from '../../components/buttons/button'
import { validatePasswordResetToken, updateUserPassword } from '../../code/models/auth'

type NewPasswordPageProps = {
  navigateTo: (url: string) => void
  companyPublicInfo: CompanyPublicInfo
}

export const NewPasswordPage = ({ navigateTo, companyPublicInfo }: NewPasswordPageProps) => {
  // component specific
  const [token, setToken] = useState('')
  const [password, setPassword] = useState('')
  const [passwordRepeat, setPasswordRepeat] = useState('')

  useEffect(() => {
    // get query params
    const urlParams = new URLSearchParams(window.location.search)
    const token = urlParams.get('token')

    if (!token) {
      return
    }
    setToken(token)

    const result = async () => {
      const valid = await validatePasswordResetToken(token)
      if (!valid) {
        navigateTo('/login')
      }
    }
    result()
  }, [])

  const handleSubmit = async (event?: any) => {
    if (token.length === 0 || password.length === 0 || passwordRepeat.length === 0) {
      return
    }
    if (password !== passwordRepeat) {
      return
    }

    logEvent({ name: 'New Password Set', properties: {} }, companyPublicInfo.subdomain)
    await updateUserPassword(token, password)
    navigateTo('/login')
  }

  const isValid = () => {
    return !(password.length === 0 || passwordRepeat.length === 0 || password !== passwordRepeat)
  }

  return <div className="flex flex-col items-center justify-center gap-16 max-w-80 mx-auto">
    <img alt="logo" className="mt-20 max-h-28 max-w-2/3" src={companyPublicInfo.logo} />
    <div className="flex flex-col gap-4 w-full">
      <div className="space-y-2">
        <div className="text-slate-600 text-lg font-bold">Password</div>
        <Input
          onEnter={async () => { await handleSubmit(null) }}
          setValue={setPassword}
          type="password"
          value={password}
          placeholder={'Enter password'}
        />
      </div>
      <div className="space-y-2">
        <div className="text-slate-600 text-lg font-bold">Repeat password</div>
        <Input
          onEnter={async () => { await handleSubmit(null) }}
          setValue={setPasswordRepeat}
          type="password"
          value={passwordRepeat}
          placeholder={'Repeat password'}
        />
      </div>
      <Button disabled={!isValid()} onClick={handleSubmit}>Save</Button>
    </div>
  </div>
}
