import {
  type Company,
  getCompanyQuoteEmailTemplate
} from '../../../../../code/models/company'
import React from 'react'
import { Section } from '../../../../../components/containers/section'
import { Tiptap } from '../../../../../components/inputs_and_selections/tiptap'
import {
  TTPlaceholderCompanyName,
  TTPlaceholderCompanyPhone,
  TTPlaceholderCustomerAddress,
  TTPlaceholderCustomerName,
  TTPlaceholderEstimateContactName,
  TTPlaceholderEstimateContactPhone,
  TTPlaceholderViewQuote
} from '../../../../../code/tiptap_placeholders'

export const NewQuoteEmailTemplateBlock = ({ company, setCompany }: {
  company: Company
  setCompany: (c: Company) => void
}) => {
  return <div className='flex flex-col gap-4'>
    <Section border={true} title="New quote email template">
      <div className='space-y-2'>
        <Tiptap
          editable={true}
          className='w-full rounded border border-gray-300 p-2 focus:outline-none'
          onUpdateCallback={async (editor) => {
            setCompany({
              ...company,
              quote_email_template: JSON.stringify(editor.getJSON())
            })
          }}
          placeholders={[
            TTPlaceholderViewQuote,
            TTPlaceholderCustomerName,
            TTPlaceholderCustomerAddress,
            TTPlaceholderEstimateContactName,
            TTPlaceholderEstimateContactPhone,
            TTPlaceholderCompanyName,
            TTPlaceholderCompanyPhone
          ]}
          content={getCompanyQuoteEmailTemplate(company.quote_email_template || '')}
        />
      </div>
    </Section>
  </div>
}
