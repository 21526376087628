import React, { type Dispatch, type SetStateAction, useContext, useState } from 'react'
import { ClickableCard } from '../../../components/content_display/card'
import { type Lead } from '../../../code/models/lead'
import { type PropertySurveyWrapper, type PropertySurvey } from '../../../code/models/property'
import { PageHeader } from '../design/components/design_page_header'
import { type Floor } from '../../../code/models/floor'
import { sum } from 'lodash'
import { FloorAttributesBlock } from '../floor/floor_attributes_block'
import { Badge } from '../../../components/indicators_and_messaging/badge'
import { getAreaM2 } from '../floor/code/utils'
import { MagicplanModal } from '../magicplan/magicplan_modal'
import { type CompanyPublicInfo, isCompanyHasMagicPlanIntegration } from '../../../code/models/company'
import { Button } from '../../../components/buttons/button'
import { AdminContext, type AdminContextType } from '../../admin/admin_layout'
import { EmptyState } from '../../../components/content_display/empty_state'
import { Alert } from '../../../components/indicators_and_messaging/alert'
import { DEFAULT_PROPERTY_SURVEY, DEFAULT_SURVEY_DESIGN } from '../../../code/survey_defaults'
import { db } from '../db'
import { type SetIndexedDb } from '../../admin/job_layout/job_layout'
import { type Material } from '../../../code/models/material'
import { handleEstimateMapping } from '../../../code/handle_estimate_mapping'
import { Battery, ChevronRight, CloudDownload, CloudLightning, Database, Home, House, List, Plus, RefreshCcw, Wrench } from 'lucide-react'
import { WrappedIcon } from '../../../components/buttons/wrapped_icon'

type SurveyProps = {
  lead: Lead | undefined
  survey: PropertySurvey
  navigateTo: (url: string) => void
  adminContext: AdminContextType
  leadUUID: string
  setCustomMaterials: SetIndexedDb<Material[]>
  surveyDB: PropertySurveyWrapper | undefined
  companyPublicInfo: CompanyPublicInfo
  useFloorplanFlow: boolean
  customMaterials: Material[]
}

export const Survey = (
  {
    lead,
    survey,
    navigateTo,
    adminContext,
    leadUUID,
    setCustomMaterials,
    surveyDB,
    companyPublicInfo,
    useFloorplanFlow,
    customMaterials
  }: SurveyProps) => {
  const totalArea = Math.round(sum(survey.floors.flatMap(x => x.rooms.flatMap(y => getAreaM2(y.walls.map(z => ({ x: z.x!, y: z.y! })))))))
  const estimatedArea = lead?.property.floorArea ?? 0
  const areaPercentage = Math.round((totalArea / estimatedArea) * 100)

  const siteDetailsCompleted = survey.completed_sections.includes('PROPERTY')
  const existingHeatingSystemCompleted = survey.completed_sections.includes('EXISTING_HEATING')
  const heatPumpCompleted = survey.completed_sections.includes('HEAT_PUMP')
  const cylinderCompleted = survey.completed_sections.includes('CYLINDER')
  const electricsCompleted = survey.completed_sections.includes('ELECTRICS')
  const pipeworkCompleted = survey.completed_sections.includes('PIPEWORK')
  const settingsCompleted = survey.completed_sections.includes('SETTINGS')
  const materialsCompleted = survey.completed_sections.includes('MATERIALS')
  const floorplanCompleted = survey.completed_sections.includes('FLOORPLAN')

  const addNewSurvey = async () => {
    const newSurvey = { ...DEFAULT_PROPERTY_SURVEY, uuid: leadUUID, designs: [{ ...DEFAULT_SURVEY_DESIGN, uuid: crypto.randomUUID() }] }
    const hydratedFromLead = lead
      ? handleEstimateMapping(
        lead,
        adminContext.data.genericMaterials!,
        customMaterials,
        adminContext.data?.heatPumps ?? [],
        adminContext.data?.hotWaterCylinders ?? [],
        adminContext.data?.company,
        newSurvey,
        setCustomMaterials)
      : newSurvey

    await db.surveys.add({
      uuid: leadUUID,
      created_at: new Date().getTime(),
      updated_at: new Date().getTime(),
      deleted_at: undefined,
      server_updated_at: 0,
      data: hydratedFromLead,
      company_uuid: companyPublicInfo.uuid,
      is_modified: true
    })
  }

  if (!surveyDB || !survey) {
    return <EmptyState
      icon={List}
      primaryText='Start survey'
      secondaryText={'Starting a survey will pull in material, heat pump and other data from your enquiry.'}
      primaryButton={<Button onClick={addNewSurvey} iconLeft={Plus}>Start survey</Button>}
      alert={<Alert type='WARNING'>If you have previously started this survey on another device please wait for the synchronisation to finish or the data will be overwritten by this process.</Alert> }
    />
  }

  return <div className='flex flex-col h-full min-h-0'>
    <div className='flex flex-col gap-4 overflow-y-auto'>
      <div className='text-xl font-bold text-gray-900'>Site survey</div>
      <ClickableCard variant='WHITE' border={true} title={<div className='flex flex-grow justify-between'>
        <div className='font-bold text-gray-900'>Site details</div>
        <Badge color={siteDetailsCompleted ? 'GREEN' : 'LIGHT'} text={siteDetailsCompleted ? 'Complete' : 'Incomplete'} />
      </div>} titleIcon={Home} onClick={() => navigateTo('/survey/site_details')} submitIcon={ChevronRight} />
      <ClickableCard variant='WHITE' border={true} title={<div className='flex flex-grow justify-between'>
        <div className='font-bold text-gray-900'>Existing heating system</div>
        <Badge color={existingHeatingSystemCompleted ? 'GREEN' : 'LIGHT'} text={existingHeatingSystemCompleted ? 'Complete' : 'Incomplete'} />
      </div>} titleIcon={Battery} onClick={() => navigateTo('/survey/existing_heating')} submitIcon={ChevronRight} />

      <ClickableCard variant='WHITE' border={true} title={<div className='flex flex-grow justify-between'>
        <div className='font-bold text-gray-900'>Heat pump location</div>
        <Badge color={heatPumpCompleted ? 'GREEN' : 'LIGHT'} text={heatPumpCompleted ? 'Complete' : 'Incomplete'} />
      </div>} titleIcon={RefreshCcw} onClick={() => navigateTo('/survey/heat_pump_location')} submitIcon={ChevronRight} />
      <ClickableCard variant='WHITE' border={true} titleIcon={Database} title={<div className='flex flex-grow justify-between'>
        <div className='font-bold text-gray-900'>Cylinder location</div>
        <Badge color={cylinderCompleted ? 'GREEN' : 'LIGHT'} text={cylinderCompleted ? 'Complete' : 'Incomplete'} />
      </div>} onClick={() => navigateTo('/survey/cylinder_location')} submitIcon={ChevronRight} />

      <ClickableCard variant='WHITE' border={true} titleIcon={CloudLightning} title={<div className='flex flex-grow justify-between'>
        <div className='font-bold text-gray-900'>Electrics</div>
        <Badge color={electricsCompleted ? 'GREEN' : 'LIGHT'} text={electricsCompleted ? 'Complete' : 'Incomplete'} />
      </div>} onClick={() => navigateTo('/survey/electrics')} submitIcon={ChevronRight} />

      <div className='flex flex-col gap-1'>
        <div className='text-xl font-bold text-gray-900'>Heat loss</div>
      </div>

      {/* Buttons if using floorplan flow */}
      {useFloorplanFlow &&
        <ClickableCard variant='WHITE' border={true} className='flex-1' title={<div className='flex flex-grow justify-between'>
          <div className='font-bold text-gray-900'>Start floor plan</div>
          <Badge color='LIGHT' text='Incomplete' />
        </div>} onClick={() => navigateTo('/survey/floors')} />
      }

      {/* Buttons if NOT using floorplan flow */}
      {!useFloorplanFlow && <>
        <ClickableCard variant='WHITE' border={true} className='flex-1' title={<div className='flex flex-grow justify-between'>
          <div className='font-bold text-gray-900'>Settings</div>
          <Badge color={settingsCompleted ? 'GREEN' : 'LIGHT'} text={settingsCompleted ? 'Complete' : 'Incomplete'} />
        </div>} onClick={() => navigateTo('/survey/settings')} />

        <ClickableCard variant='WHITE' border={true} className='flex-1' title={<div className='flex flex-grow justify-between'>
          <div className='font-bold text-gray-900'>Materials</div>
          <Badge color={materialsCompleted ? 'GREEN' : 'LIGHT'} text={materialsCompleted ? 'Complete' : 'Incomplete'} />
        </div>} onClick={() => navigateTo('/survey/materials')} />

        <ClickableCard variant='WHITE' border={true} onClick={() => {
          survey.floors.length === 0 ? navigateTo('/survey/new_floor') : navigateTo('/survey/floors')
        }} title={<div className='flex flex-grow justify-between'>
          <div className='font-bold text-gray-900'>Floorplan</div>
          <Badge color={floorplanCompleted ? 'GREEN' : 'LIGHT'} text={floorplanCompleted ? 'Complete' : 'Incomplete'} />
        </div>} titleIcon={House} submitIcon={ChevronRight}>
          <div className='flex gap-4'>
            <div>
              <div className='text-sm text-gray-600'>Estimated area</div>
              <div className='text-gray-900 font-bold'>{lead?.property.floorArea} m²</div>
            </div>
            <div>
              <div className='text-sm text-gray-600'>Measured area</div>
              <div className='text-gray-900 font-bold'>{totalArea} m² <span className='text-gray-600 font-medium'>({areaPercentage}%)</span></div>
            </div>
          </div>
        </ClickableCard>
      </>}

      <ClickableCard variant='WHITE' border={true} titleIcon={Wrench} title={<div className='flex flex-grow justify-between'>
        <div className='font-bold text-gray-900'>Pipework</div>
        <Badge color={pipeworkCompleted ? 'GREEN' : 'LIGHT'} text={pipeworkCompleted ? 'Complete' : 'Incomplete'} />
      </div>} onClick={() => navigateTo('/survey/pipework')} submitIcon={ChevronRight} />
    </div>
  </div>
}

export const FLOOR_NAMES = ['Ground floor', 'First floor', 'Second floor', 'Third floor']

type NewFloorProps = {
  survey: PropertySurvey
  setSurvey: Dispatch<SetStateAction<PropertySurvey>>
  floors: Floor[]
  showHeader?: boolean
  navigateTo: (url: string) => void
  isOffline: boolean
  allSynced: boolean
}
export const NewFloor = (
  {
    survey,
    setSurvey,
    floors,
    showHeader = true,
    navigateTo,
    isOffline,
    allSynced
  }: NewFloorProps) => {
  const adminContext = useContext(AdminContext)
  const [showMagicplanModal, setShowMagicplanModal] = useState(false)

  return <>
    <div className='flex flex-col h-full'>
      {showHeader && <PageHeader isOffline={isOffline} allSynced={allSynced} title='Add floor' onBack={() => window.history.back()} />}
      <MagicplanModal
        survey={survey}
        setSurvey={setSurvey}
        showMagicplanModal={showMagicplanModal}
        setShowMagicplanModal={setShowMagicplanModal}
        afterImport={() => navigateTo('/survey/floor_list')}
      />
      <div className='p-6 flex flex-col gap-6 items-center'>
        { isCompanyHasMagicPlanIntegration(adminContext.data.company!) && <>
          <div>
            <Button onClick={() => setShowMagicplanModal(prev => !prev) } colour='DARK'><WrappedIcon icon={CloudDownload} />Magicplan</Button>
          </div>
          <div className='text-gray-900 font-bold'>OR</div>
        </>}
        <div className='w-full'>
          <FloorAttributesBlock
            floors={floors}
            onSave={(newFloor) => {
              setSurvey(prev => ({ ...prev, floors: [...floors, newFloor] }))
              navigateTo(`/survey/floors/${newFloor.uuid}`)
            }}
          />
        </div>
      </div>
    </div>
  </>
}
