import React, { useState } from 'react'
import { TabGroup } from '../../../components/content_display/tab'

export const TabDemo = () => {
  const [currentTab, setCurrentTab] = useState('One')

  return <div className="flex flex-col gap-8">
    <div className='flex flex-col gap-4'>
      <div className='text-2xl font-bold text-gray-900'>Definition</div>

      <pre className='bg-gray-100 rounded-lg p-4 overflow-x-auto'><code>
        {codeBlock}
      </code></pre>

      <div className='flex flex-col divide-y divide-dashed divide-gray-200 text-sm'>
        {componentProps.map(x => <div key={x.name} className='grid grid-cols-2 py-2'>
          <div className='font-bold text-gray-900'>{x.name}</div>
          <div>{x.description}</div>
        </div>)}
      </div>
    </div>

    <div className='flex flex-col gap-4'>
      <div className='text-2xl font-bold text-gray-900'>Component</div>
      <TabGroup items={[
        { name: 'One', secondaryText: 'secondary', onClick: () => setCurrentTab('One'), variant: currentTab === 'One' ? 'ACTIVE' : 'DEFAULT' },
        { name: 'Two', secondaryText: undefined, onClick: () => setCurrentTab('Two'), variant: currentTab === 'Two' ? 'ACTIVE' : 'DEFAULT' },
        { name: 'Three', secondaryText: undefined, onClick: () => setCurrentTab('Three'), variant: currentTab === 'Three' ? 'ACTIVE' : 'DEFAULT' }
      ]} />
    </div>
  </div>
}

const componentProps = [
  { name: 'items', description: 'Action that should happen when clicking' },
  { name: 'name', description: 'Primary tab text' },
  { name: 'secondaryText', description: 'Lighter secondary text, optional' },
  { name: 'onClick', description: 'What should we action onClick, usually update tab state' },
  { name: 'variant', description: 'ACTIVE | DEFAULT' }
]

const codeBlock = `<TabGroup items={[
  { name: 'One', secondaryText: 'secondary', onClick: () => setCurrentTab('One'), variant: currentTab === 'One' ? 'ACTIVE' : 'DEFAULT' },
  { name: 'Two', secondaryText: undefined, onClick: () => setCurrentTab('Two'), variant: currentTab === 'Two' ? 'ACTIVE' : 'DEFAULT' },
  { name: 'Three', secondaryText: undefined, onClick: () => setCurrentTab('Three'), variant: currentTab === 'Three' ? 'ACTIVE' : 'DEFAULT' }
]} />`
