import React from 'react'
import { SourcesBlock } from './blocks/sources_block'
import { SettingsLayout } from '../components/settings_layout'
import { type Company } from '../../../../code/models/company'
export const EstimateEnquiryForm = ({ company, setCompany }: { company: Company, setCompany: (c: Company) => void }) => {
  return <SettingsLayout
    heading='Enquiry form'
    description='Configure the customer enquiry flow'
  >
    <SourcesBlock company={company} setCompany={setCompany} />
    {/* <Section border title='Optional Questions' >
      <div className='flex flex-col divide-y' >
        <p className='pb-6'>Choose which questions you would like to appear in the customer enquiry form.</p>
        <HorizontalFormGroup
          disabled
          className='py-3'
          formLabel={
            <FormLabel
              labelText='Solar installed'
              helperText='Do you have solar installed?'
            />
          }
          toggle={<Toggle disabled value={false} setValue={noop} />}
        />
        <HorizontalFormGroup
          disabled
          className='py-3'
          formLabel={
            <FormLabel
              labelText={'Renovation planned'}
              helperText={'Are you planning any renovation work in the near future?'}
            />
          }
          toggle={<Toggle disabled value={false} setValue={noop} />}
        />
        <HorizontalFormGroup
          disabled
          className='py-3'
          formLabel={
            <FormLabel
              labelText='Architect involved'
              helperText={'do you have an architect involved?'}
            />
          }
          toggle={<Toggle disabled value={false} setValue={noop} />}
        />
      </div>
    </Section> */}
  </SettingsLayout>
}
