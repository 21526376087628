import { type CustomAttributes, type Material, type WindowAttributes } from './material'

export type WallWindow = {
  uuid?: string
  width_mm: number
  height_mm: number

  /**
   * @deprecated This property is superseded by width_mm
   */
  width_m?: number
  /**
   * @deprecated This property is superseded by height_mm
   */
  height_m?: number
  material: Material | undefined
  created_at: number
  updated_at: number
}

export const getWindowUValue = (window: WallWindow, thermalBridgingUValueAdditionWPerM2K: number) => {
  if (['custom_layered', 'custom_simple'].includes(window.material!.type)) {
    return (window.material!.extra_data as CustomAttributes).u_value! + thermalBridgingUValueAdditionWPerM2K
  }
  return (window.material!.extra_data as WindowAttributes).u_value + thermalBridgingUValueAdditionWPerM2K
}
