import {
  PropertyTypeBungalowIcon,
  PropertyTypeFlatIcon,
  PropertyTypeHouseIcon
} from '../../assets/images/domain_icons/domain_icons'

export type PropertyType = 'House' | 'Flat' | 'Bungalow'

export type PropertyTypeObject = {
  icon: any // TODO: not sure how to type this properly
  name: string
  uuid: PropertyType
  numberOfStoriesDefault: number
}

export const PROPERTY_TYPES: PropertyTypeObject[] = [
  { icon: PropertyTypeHouseIcon, name: 'House', uuid: 'House', numberOfStoriesDefault: 2 },
  { icon: PropertyTypeFlatIcon, name: 'Flat', uuid: 'Flat', numberOfStoriesDefault: 1 },
  { icon: PropertyTypeBungalowIcon, name: 'Bungalow', uuid: 'Bungalow', numberOfStoriesDefault: 1 }
]
