import React, { useContext } from 'react'
import { SurveyActionType } from '../../../code/survey/survey'
import { Input } from '../../../components/inputs_and_selections/input'
import { Button } from '../../../components/buttons/button'
import { logEvent } from '../../../code/log_event'
import { type CompanyPublicInfo } from '../../../code/models/company'
import { StepHeader } from '../components/steps_header'
import { SurveyContext, SurveyDispatchContext } from '../survey_page'
import { buildSingleLineAddressFromLines } from '../../../code/models/address'
import { ChevronRightIcon } from 'lucide-react'

export const ManualAddressStep = ({ companyPublicInfo }: { companyPublicInfo: CompanyPublicInfo }) => {
  // survey context and dispatch
  const survey = useContext(SurveyContext)
  const dispatch = useContext(SurveyDispatchContext)

  // component state
  const [addressLine1, setAddressLine1] = React.useState<string>(survey.address?.line_1 || '')
  const [addressLine2, setAddressLine2] = React.useState<string>(survey.address?.line_2 || '')
  const [addressLine3, setAddressLine3] = React.useState<string>(survey.address?.line_3 || '')
  const [town, setTown] = React.useState<string>(survey.address?.town || '')
  const [postcode, setPostcode] = React.useState<string>(survey.address?.postcode || '')

  const handleNavigateNextPage = () => {
    logEvent({ name: 'Manual address step completed', properties: {} }, companyPublicInfo.subdomain)

    dispatch({
      type: SurveyActionType.NavigateNextPage,
      payload: {
        address: buildSingleLineAddressFromLines({ line_1: addressLine1, line_2: addressLine2, line_3: addressLine3, post_town: town, postcode }),
        postcode
      }
    })
  }

  const validateAddress: (x: string) => { value: string, message: string } = (x) =>
    ({ value: x, message: x.length > 0 ? '' : 'Please enter a valid address' })

  return <>
    {/* Header */}
    <StepHeader
      headerText="Enter your address"
      captionText={'Fields marked with * are required.'}
    />

    {/* Controls */}
    <div className="w-full flex flex-col gap-3">
      <div className="space-y-1">
        <div className="text-gray-600 font-bold">Address line 1 *</div>
        <Input
          size='LG'
          setValue={setAddressLine1}
          type={'text'}
          value={addressLine1}
          validator={validateAddress}
        />
      </div>
      <div className="space-y-1">
        <div className="text-gray-600 font-bold">Address line 2</div>
        <Input
          size='LG'
          setValue={setAddressLine2}
          type={'text'}
          value={addressLine2}
        />
      </div>
      <div className="space-y-1">
        <div className="text-gray-600 font-bold">Address line 3</div>
        <Input
          size='LG'
          setValue={setAddressLine3}
          type={'text'}
          value={addressLine3}
        />
      </div>
      <div className="space-y-1">
        <div className="text-gray-600 font-bold">Town or city *</div>
        <Input
          size='LG'
          setValue={setTown}
          type={'text'}
          value={town}
          validator={validateAddress}
        />
      </div>
      <div className="space-y-1">
        <div className="text-gray-600 font-bold">Postcode *</div>
        <Input
          size='LG'
          setValue={setPostcode}
          type={'text'}
          value={postcode}
          validator={(x: string) => x.length > 0 ? { value: x, message: '' } : { value: x, message: 'Please enter a valid postcode' }}
        />
      </div>
    </div>

    <Button size='LG' disabled={addressLine1.length === 0 || postcode.length === 0} onClick={handleNavigateNextPage}>
      <div className="flex items-center gap-x-2">
        <div>Continue</div>
        <ChevronRightIcon className="h-5 w-5" />
      </div>
    </Button>
  </>
}
