import React, { useEffect, type Dispatch, type SetStateAction } from 'react'
import { type Floor, getFloorAndCeilingDefaultMaterialsForFloor } from '../../../code/models/floor'
import { FloorAttributesBlock } from './floor_attributes_block'
import type { CurrentFloorPlanPage } from './floor'
import { type PropertySurvey } from '../../../code/models/property'
import _ from 'lodash'
import { isFlagSet } from '../../../code/helpers'
import {
  ROOM_OVERWRITTEN_ATTRIBUTE_CEILING_MATERIAL,
  ROOM_OVERWRITTEN_ATTRIBUTE_FLOOR_MATERIAL,
  ROOM_OVERWRITTEN_ATTRIBUTE_HEIGHT_M
} from '../../../code/models/room'
import { BottomSheetHeader } from '../../../components/containers/bottom_sheet_header'

type Props = {
  survey: PropertySurvey
  setPage: (page: CurrentFloorPlanPage) => void
  floor: Floor
  floors: Floor[]
  setFloor: (floor: Floor) => void
  setHeader: Dispatch<SetStateAction<JSX.Element>>
  onBack: () => void
}

export const FloorDetails = (
  {
    survey,
    setPage,
    floor,
    floors,
    setFloor,
    setHeader,
    onBack
  }: Props) => {
  // save floor value to compare with the updated value after save
  const floorDeepClone = _.cloneDeep(floor)

  useEffect(() => {
    setHeader(<BottomSheetHeader onBack={onBack} title='Floor' />)
  }, [])

  return <>
    <div className='flex flex-col'>
      <div className="p-5 bg-white flex-col gap-5 flex">
        <FloorAttributesBlock floor={floor} floors={floors} onSave={(updatedFloor) => {
          setFloor(updatedFloor)
          setPage('FLOOR_DETAILS')

          // update all rooms with new floor and ceiling materials
          const updatedRooms = floor.rooms.map(room => {
            const updatedRoom = { ...room }

            // ROOM HEIGHT: is the value changed?
            if (floorDeepClone.default_room_height !== updatedFloor.default_room_height) {
              // if the height_m is not overwritten, update it
              if (!isFlagSet(room.overwritten_attributes_flags, ROOM_OVERWRITTEN_ATTRIBUTE_HEIGHT_M)) {
                updatedRoom.height_m = updatedFloor.default_room_height
              }
            }

            // get the default materials for the floor and ceiling
            // (we know only types for the floor, the real materials are in the survey)
            const [floorMaterial, ceilingMaterial] = getFloorAndCeilingDefaultMaterialsForFloor(updatedFloor, survey)

            // FLOOR TYPE: is the value changed?
            if (floorDeepClone.default_floor_material_type !== updatedFloor.default_floor_material_type) {
              // if the height_m is not overwritten, update it
              if (!isFlagSet(room.overwritten_attributes_flags, ROOM_OVERWRITTEN_ATTRIBUTE_FLOOR_MATERIAL)) {
                updatedRoom.floor_material = floorMaterial
              }
            }

            // CEILING TYPE: is the value changed?
            if (floorDeepClone.default_ceiling_material_type !== updatedFloor.default_ceiling_material_type) {
              // if the height_m is not overwritten, update it
              if (!isFlagSet(room.overwritten_attributes_flags, ROOM_OVERWRITTEN_ATTRIBUTE_CEILING_MATERIAL)) {
                updatedRoom.ceiling_material = ceilingMaterial
              }
            }

            return updatedRoom
          })

          setFloor({ ...updatedFloor, rooms: updatedRooms })
        }} />
      </div>
    </div>
  </>
}
