import { chain, sum } from 'lodash'
import { getWallUValue, type Wall } from './wall'
import { getWindowUValue, type WallWindow } from './window'
import { type Door, getDoorUValue } from './door'
import { getRooflightUValue, type RoofLight } from './rooflight'
import { CEILING_TYPES, FLUE_TYPES, getCeilingUValue, getFloorUValue, type Room } from './room'
import { type GroundFloorAttributes, type Material, MATERIAL_ELEMENT_NAMES } from './material'
import { type PropertySurvey } from './property'
import { ROOM_TYPE_ACH, ROOM_TYPES, THERMAL_BRIDGING_ADDITIONS } from '../../pages/heat_loss/constants'
import { calculateLineLength, getAreaM2 } from '../../pages/heat_loss/floor/code/utils'

export type ConductionHeatLoss = {
  elementName: string
  material: Material
  areaM2: number
  uValueWPerM2K: number
  roomTempC: number
  otherSideTempC: number
  tempDiffK: number
  watts: number
}

export type VentilationHeatLoss = {
  elementName: string
  volumeM3: number
  ACH: number
  roomTempC: number
  externalTempC: number
  tempDiffK: number
  watts: number
  heatRecoveryPercentage: number
}

export const getHeatTransferCoefficientWattsPerKelvin = (survey: PropertySurvey, designTempC: number, groundTempC: number): number => {
  const heatLossWattsPerRoomPerKelvin = survey.floors.flatMap(x => x.rooms.flatMap(y => ({
    room: y,
    value: getRoomWatts(y, x.rooms, designTempC, groundTempC, survey) / (getRoomTemp(y, survey) - designTempC)
  })))
  return sum(heatLossWattsPerRoomPerKelvin.map(x => x.value))
}

export const getTotalHeatLossWatts = (survey: PropertySurvey, externalTempC: number, groundTempC: number) => {
  const totalHeatLossWatts = sum(survey.floors.flatMap(x => x.rooms.flatMap(y =>
    getRoomWatts(y, x.rooms, externalTempC, groundTempC, survey)
  )))
  return totalHeatLossWatts
}
// Just the total watts for the room
export const getRoomWatts = (room: Room, rooms: Room[], externalTempC: number, groundTempC: number, survey: PropertySurvey) => {
  const ventilationHeatLoss = getVentilationHeatLoss(room, externalTempC, survey)
  const conductionHeatLosses = getConductionHeatLossAllElements(room, rooms, externalTempC, groundTempC, survey)

  return sum(conductionHeatLosses.map(x => x.watts)) + ventilationHeatLoss.watts
}

// Return rows of the heat loss details for each element in the room
export const getConductionHeatLossAllElements = (
  room: Room, rooms: Room[], externalTempC: number, groundTempC: number, survey: PropertySurvey
): ConductionHeatLoss[] => {
  const roomTempC = getRoomTemp(room, survey)
  const roomHeightM = getAverageRoomHeightM(room)
  const thermalBridgingUValueAdditionWPerM2K = getThermalBridgingUValueAdditionWPerM2K(survey)

  const wallHeatLosses = room.walls.map((x, i) => {
    const nextWallIndex = room.walls.length - 1 === i ? 0 : i + 1
    return getWallWindowDoorHeatLosses(x, room.walls[nextWallIndex], rooms, roomHeightM, roomTempC, externalTempC, survey, thermalBridgingUValueAdditionWPerM2K)
  })
  const wallHeatLossesSorted = wallHeatLosses.flat().sort((a, b) => a.elementName.localeCompare(b.elementName))

  const ceilingHeatLosses = getCeilingHeatLosses(room, roomTempC, externalTempC, survey.intermittent_heating, survey.intermittent_heating_oversize_factor_percentage, thermalBridgingUValueAdditionWPerM2K)
  const floorHeatLoss = getFloorHeatLoss(room, roomTempC, externalTempC, groundTempC, survey.intermittent_heating, survey.intermittent_heating_oversize_factor_percentage, thermalBridgingUValueAdditionWPerM2K)
  return [floorHeatLoss, ...ceilingHeatLosses, ...wallHeatLossesSorted]
}

export const getWallWindowDoorHeatLosses = (wall: Wall, linkedWall: Wall, rooms: Room[], roomHeightM: number, roomTempC: number, externalTempC: number, survey: PropertySurvey, thermalBridgingUValueAdditionWPerM2K: number) => {
  const wallHeatLoss = getWallHeatLoss(wall, linkedWall, rooms, roomHeightM, roomTempC, externalTempC, survey, thermalBridgingUValueAdditionWPerM2K)

  const otherSideTempC = getOtherSideTempWall(wall, rooms, externalTempC, survey)
  // could move this into the window and door functions later to aid testing if we want
  const windowHeatLosses = wall.windows.map(x => getWindowHeatLoss(x, roomTempC, otherSideTempC, survey.intermittent_heating, survey.intermittent_heating_oversize_factor_percentage, thermalBridgingUValueAdditionWPerM2K))

  const doorHeatLosses = wall.doors.map(x => getDoorHeatLoss(x, roomTempC, otherSideTempC, survey.intermittent_heating, survey.intermittent_heating_oversize_factor_percentage, thermalBridgingUValueAdditionWPerM2K))
  return [wallHeatLoss, ...windowHeatLosses, ...doorHeatLosses]
}

export const getNetWallAreaM2 = (wall: Wall, linkedWall: Wall, roomHeightM: number) => {
  const lineLength = calculateLineLength(wall.x!, wall.y!, linkedWall.x!, linkedWall.y!)
  const grossAreaM2 = lineLength * roomHeightM

  const totalWindowAreaM2 = sum(wall.windows.map(getWindowAreaM2))
  const totalDoorAreaM2 = sum(wall.doors.map(getDoorAreaM2))
  return grossAreaM2 - totalWindowAreaM2 - totalDoorAreaM2
}

export const getWallHeatLoss = (wall: Wall, linkedWall: Wall, rooms: Room[], roomHeightM: number, roomTempC: number, externalTempC: number, survey: PropertySurvey, thermalBridgingUValueAdditionWPerM2K: number) => {
  const netWallAreaM2 = getNetWallAreaM2(wall, linkedWall, roomHeightM)
  const uValueWPerM2K = getWallUValue(wall, thermalBridgingUValueAdditionWPerM2K)
  const otherSideTempC = getOtherSideTempWall(wall, rooms, externalTempC, survey)

  const elementName = MATERIAL_ELEMENT_NAMES[wall.material!.applicable_to] + 's'

  return getConductionHeatLoss(elementName, wall.material!, netWallAreaM2, uValueWPerM2K, roomTempC, otherSideTempC, survey.intermittent_heating, survey.intermittent_heating_oversize_factor_percentage)
}

export const getWindowHeatLoss = (window: WallWindow, roomTempC: number, otherSideTempC: number, intermittentHeating: boolean, intermittentHeatingOversizeFactorPercentage: number, thermalBridgingUValueAdditionWPerM2K: number) => {
  const areaM2 = getWindowAreaM2(window)
  const uValueWPerM2K = getWindowUValue(window, thermalBridgingUValueAdditionWPerM2K)
  return getConductionHeatLoss('Windows', window.material!, areaM2, uValueWPerM2K, roomTempC, otherSideTempC, intermittentHeating, intermittentHeatingOversizeFactorPercentage)
}

export const getRooflightHeatLoss = (rooflight: RoofLight, ceilingTypeUUID: string, roomTempC: number, otherSideTempC: number, intermittentHeating: boolean, intermittentHeatingOversizeFactorPercentage: number, thermalBridgingUValueAdditionWPerM2K: number) => {
  const areaM2 = getRooflightAreaM2(rooflight)
  const uValueWPerM2K = getRooflightUValue(rooflight, ceilingTypeUUID, thermalBridgingUValueAdditionWPerM2K)
  return getConductionHeatLoss('Rooflights', rooflight.material!, areaM2, uValueWPerM2K, roomTempC, otherSideTempC, intermittentHeating, intermittentHeatingOversizeFactorPercentage)
}

export const getDoorHeatLoss = (door: Door, roomTempC: number, otherSideTempC: number, intermittentHeating: boolean, intermittentHeatingOversizeFactorPercentage: number, thermalBridgingUValueAdditionWPerM2K: number) => {
  const areaM2 = getDoorAreaM2(door)
  const uValueWPerM2K = getDoorUValue(door, thermalBridgingUValueAdditionWPerM2K)
  return getConductionHeatLoss('Doors', door.material!, areaM2, uValueWPerM2K, roomTempC, otherSideTempC, intermittentHeating, intermittentHeatingOversizeFactorPercentage)
}

export const getFloorHeatLoss = (room: Room, roomTempC: number, externalTempC: number, groundTempC: number, intermittentHeating: boolean, intermittentHeatingOversizeFactorPercentage: number, thermalBridgingUValueAdditionWPerM2K: number) => {
  const areaM2 = getAreaM2(room.walls.map(x => ({ x: x.x!, y: x.y! })))
  const uValueWPerM2K = getFloorUValue(room, thermalBridgingUValueAdditionWPerM2K)
  const otherSideTempC = getOtherSideTempFloor(room.floor_material!, externalTempC, groundTempC, roomTempC, room.floor_other_side_temp_override_c)

  let elementName: string
  if (room.floor_material?.applicable_to === 'ground-floor') {
    elementName = 'Ground Floor'
  } else if (room.floor_material?.applicable_to === 'exposed-floor') {
    elementName = 'Exposed Floor'
  } else {
    elementName = 'Intermediate Floor'
  }
  return getConductionHeatLoss(elementName, room.floor_material!, areaM2, uValueWPerM2K, roomTempC, otherSideTempC, intermittentHeating, intermittentHeatingOversizeFactorPercentage)
}

export const getCeilingHeatLosses = (room: Room, roomTempC: number, externalTempC: number, intermittentHeating: boolean, intermittentHeatingOversizeFactorPercentage: number, thermalBridgingUValueAdditionWPerM2K: number) => {
  // `later use ceiling type to get more accurate area here for vaulted rooms.
  // Currently we just adjust the average height to account for vaulted ceilings so the volume is right and the wall height (and hence area) is increased but the ceiling area is not.
  // U values of vaulted ceilings are often similar to those for walls so this isn't a huge issue for now.
  const uValueWPerM2K = getCeilingUValue(room, thermalBridgingUValueAdditionWPerM2K)
  const otherSideTempC = getOtherSideTempCeiling(room.ceiling_material!, externalTempC, roomTempC, room.ceiling_other_side_temp_override_c)

  let areaM2 = getCeilingAreaM2(room.walls)
  let rooflightHeatLosses: ConductionHeatLoss[] = []

  // if ceiling is a roof (not intermediate ceiling) then we need to account for rooflights
  if (room.ceiling_material?.applicable_to === 'roof') {
    // subtract the area of all rooflights from the ceiling area
    const rooflightsAreaM2 = room.rooflights.reduce((acc, rooflight) => acc + getRooflightAreaM2(rooflight), 0)
    areaM2 -= rooflightsAreaM2

    rooflightHeatLosses = room.rooflights.map(rl => getRooflightHeatLoss(
      rl,
      room.ceiling_type_uuid,
      roomTempC,
      otherSideTempC,
      intermittentHeating,
      intermittentHeatingOversizeFactorPercentage,
      thermalBridgingUValueAdditionWPerM2K)
    )
  }

  const elementName = room.ceiling_material?.applicable_to === 'roof' ? 'Roof' : 'Intermediate Ceiling'
  return [
    getConductionHeatLoss(elementName, room.ceiling_material!, areaM2, uValueWPerM2K, roomTempC, otherSideTempC, intermittentHeating, intermittentHeatingOversizeFactorPercentage),
    ...rooflightHeatLosses
  ]
}

const getConductionHeatLoss = (elementName: string, material: Material, areaM2: number, uValueWPerM2K: number, roomTempC: number, otherSideTempC: number, intermittentHeating: boolean, intermittentHeatingOversizeFactorPercentage: number) => {
  const tempDiffK = roomTempC - otherSideTempC
  const watts = applyIntermittentHeatingCorrection(areaM2 * uValueWPerM2K * tempDiffK, intermittentHeating, intermittentHeatingOversizeFactorPercentage)
  const heatLoss: ConductionHeatLoss = { elementName, material, areaM2, uValueWPerM2K, roomTempC, otherSideTempC, tempDiffK, watts }
  return heatLoss
}

export const getVentilationHeatLoss = (room: Room, externalTempC: number, survey: PropertySurvey) => {
  const roomTempC = getRoomTemp(room, survey)
  const volumeM3 = getRoomVolumeM3(room)
  const ACH = getRoomACH(room, survey)
  const tempDiffK = roomTempC - externalTempC
  const heatRecoveryPercentage = survey.mvhr_installed ? 0.5 : 0 // this is a bodge based on CIBSE section 3.5.4.3. Reconsider when you allow people to enter infiltration values and then add in MVHR?
  const heatLossWatts = (1 / 3) * volumeM3 * ACH * tempDiffK * (1 - heatRecoveryPercentage)
  const wattsWithIntermittentHeating = applyIntermittentHeatingCorrection(heatLossWatts, survey.intermittent_heating, survey.intermittent_heating_oversize_factor_percentage)
  const heatLoss: VentilationHeatLoss = { elementName: 'Ventilation', volumeM3, ACH, roomTempC, externalTempC, tempDiffK, watts: wattsWithIntermittentHeating, heatRecoveryPercentage }
  return heatLoss
}

export const applyIntermittentHeatingCorrection = (watts: number, intermittentHeating: boolean, intermittentHeatingOversizeFactorPercentage: number) => {
  if (intermittentHeating) {
    return watts * (1 + intermittentHeatingOversizeFactorPercentage / 100)
  }
  return watts
}

export const getRoomTemp = (room: Room, survey: PropertySurvey, onlyDefault: boolean = false) => {
  if (!room) return 0
  if (room.indoor_temp_override_c !== undefined && !onlyDefault) return room.indoor_temp_override_c
  if (!survey.use_cibse_indoor_temps) return survey.indoor_temp_overall_c

  const roomType = ROOM_TYPES.find(x => x.uuid === room.room_type_uuid)!
  if (survey.age_band && (survey.age_band.ach_age_key === 'ach_post_2006')) return roomType.indoor_temp_post_2006_c
  return roomType.indoor_temp_c
}

export const getOtherSideTempWall = (wall: Wall, rooms: Room[], externalTempC: number, survey: PropertySurvey) => {
  if (wall.other_side_temp_override_c) return wall.other_side_temp_override_c

  if (wall.material?.applicable_to === 'party-wall') return 10 // TODO: make settings value

  if (wall.other_room_uuid) return getRoomTemp(rooms.find(x => x.uuid === wall.other_room_uuid)!, survey)

  return externalTempC
}

export const getOtherSideTempFloor = (floorMaterial: Material, externalTempC: number, groundTempC: number, roomTempC: number, otherSideTempOverrideC: number | undefined) => {
  if (otherSideTempOverrideC) return otherSideTempOverrideC
  if (floorMaterial.applicable_to === 'intermediate-floor-and-ceiling') {
    // Not aligning floors so if intermediate floor assume no heat loss across it for now
    return roomTempC
  }
  if (floorMaterial.applicable_to === 'exposed-floor') {
    // CIBSE guide section 3.5.3.3, Adjoining properties and unheated spaces
    return 10
  }
  if (floorMaterial.applicable_to === 'ground-floor') {
    if ((floorMaterial.extra_data as GroundFloorAttributes).construction === 'Suspended') {
      return externalTempC
    }
    // If not suspended assume solid. Will apply for custom ground floors and new build ground floors
    return groundTempC
  //   Notes on this here https://www.notion.so/spruce-energy/Ground-floor-U-values-96db7fae58364ac1ab05db0890fcc8dc?pvs=4
  }
  throw new Error(`Invalid floor material type ${floorMaterial.applicable_to}`)
}

export const getOtherSideTempCeiling = (ceilingMaterial: Material, externalTempC: number, roomTempC: number, otherSideTempOverrideC: number | undefined) => {
  if (otherSideTempOverrideC) return otherSideTempOverrideC
  if (ceilingMaterial.applicable_to === 'roof') return externalTempC

  // Not aligning floors yet so if it's an internal ceiling just assume no heat loss across so rooms total is correct
  // Later use temp of room on other side
  if (ceilingMaterial.applicable_to === 'intermediate-floor-and-ceiling') return roomTempC
  // Later add party ceiling/floor for flats
  throw new Error(`Invalid ceiling material type ${ceilingMaterial.applicable_to}`)
}

export const getRoomACH = (room: Room, survey: PropertySurvey, onlyDefault: boolean = false) => {
  return getRoomACHGranularInputs(
    room.ach_override,
    room.flue_type_uuid,
    getRoomVolumeM3(room),
    survey.use_cibse_air_change_values,
    survey.air_change_per_hour_overall,
    room.room_type_uuid!,
    survey.air_change_year_uuid,
    onlyDefault
  )
}

// Broken down inputs to facilitate testing. Will also make it easier to memo-ise later if needed
export const getRoomACHGranularInputs = (roomAchOverride: number | undefined, flueTypeUuid: string | undefined,
  roomVolumeM3: number, useCIBSEAirChangeValues: boolean, airChangesSetValueSurvey: number, roomTypeUuid: string,
  airChangeYearUuid: string, onlyDefault: boolean = false) => {
  if (roomAchOverride !== undefined && !onlyDefault) return roomAchOverride

  // Unless user has overwritten ACH value in the room, use the flue based ACH value if they have a flue
  if (flueTypeUuid && flueTypeUuid !== 'no') {
    const flueType = FLUE_TYPES.find(x => x.uuid === flueTypeUuid)!
    return roomVolumeM3 <= 40 ? flueType.ach_volume_less_than_or_equal_to_40_m3 : flueType.ach_volume_greater_than_40_m3
  }

  if (!useCIBSEAirChangeValues) return airChangesSetValueSurvey
  return ROOM_TYPE_ACH.find(x => x.room_uuid === roomTypeUuid && x.age_uuid === airChangeYearUuid)!.value
}

export const getRoomVolumeM3 = (room: Room) => {
  const roomHeightM = getAverageRoomHeightM(room)
  const areaM3 = getFloorAreaM2(room.walls)
  return areaM3 * roomHeightM
}

export const getFloorAreaM2 = (walls: Wall[]) => {
  return getAreaM2(walls.map(x => ({ x: x.x!, y: x.y! })))
}

export const getCeilingAreaM2 = (walls: Wall[]) => {
  // FIXME: `later use ceiling type to get more accurate area here for vaulted rooms.
  return getAreaM2(walls.map(x => ({ x: x.x!, y: x.y! })))
}

export const getExposedPerimeterM = (walls: Wall[]) => {
  /*
  From BRE 443 Conventions for U value calculations 2006
  There are newer versions (pay walled!), but I doubt this has changed
  https://files.bregroup.com/bre-co-uk-file-library-copy/filelibrary/rpts/uvalue/BR_443_(2006_Edition).pdf
  Section 9
  The U-value for floors (including basement floors) depends on the exposed perimeter and the area of the floor.
   The perimeter should include the length of all exposed walls bounding the heated space and also any walls between
    the heated space and an unheated space – the floor losses are calculated as if the unheated space were not present.
     Walls to other spaces that can reasonably be assumed to be heated to the same temperature (e.g. the separating
      wall to an adjacent house) should not be included in the perimeter.
   */
  let total = 0
  for (let i = 0, l = walls.length; i < l; i++) {
    if (walls[i].material?.applicable_to === 'external-wall') {
      const nextIndex = i < l - 1 ? i + 1 : 0 // go back to start if at end
      total += calculateLineLength(walls[i].x!, walls[i].y!, walls[nextIndex].x!, walls[nextIndex].y!)
    }
  }
  return total
}

export const getAverageRoomHeightM = (room: Room) => {
  const ceilingType = CEILING_TYPES.find(x => x.uuid === room.ceiling_type_uuid)
  return ceilingType!.getAverageRoomHeightM(room)
}

const getWindowAreaM2 = (window: WallWindow) => {
  // convert mm2 to m2
  return window.width_mm * window.height_mm / 1000000
}

const getRooflightAreaM2 = (rooflight: RoofLight) => {
  const areaMM = rooflight.width_mm * rooflight.height_mm
  // convert mm2 to m2
  return areaMM / 1000000
}

const getDoorAreaM2 = (door: Door) => {
  return door.width_mm * door.height_mm / 1000000
}

// Formulae for manipulating and combining heat loss values

// Combine the volumes and heat losses for rooms with the same ACH. Ignore temp and heat recovery percentage for now
export const combineVentilationHeatLosses = (heatLosses: VentilationHeatLoss[]): VentilationHeatLoss[] => {
  return chain(heatLosses)
    .groupBy(x => `${x.ACH}`)
    .map((values, key) => ({
      ...values[0],
      volumeM3: sum(values.map(x => x.volumeM3)),
      watts: sum(values.map(x => x.watts))
    }))
    .value()
}

// Combine the areas for items that have the same properties so can present them concisely. Sort the results.
export const combineSortConductionHeatLosses = (heatLosses: ConductionHeatLoss[], differentiateOnTempDiff: boolean = true
): ConductionHeatLoss[] => {
  const combined = combineConductionHeatLosses(heatLosses, differentiateOnTempDiff)
  const sorted = sortHeatLosses(combined)
  const uniqueNames = renameDuplicates(sorted)
  return uniqueNames
}

export const combineConductionHeatLosses = (heatLosses: ConductionHeatLoss[], differentiateOnTempDiff: boolean = true) => {
  // Only differentiate on U value if different at 2 decimal places
  return chain(heatLosses)
    .groupBy(x => differentiateOnTempDiff ? `${x.elementName}_${Number(x.uValueWPerM2K)?.toFixed(2)}_${x.tempDiffK}` : `${x.elementName}_${Number(x.uValueWPerM2K)?.toFixed(2)}`)
    .map((values, key) => ({
      ...values[0],
      areaM2: sum(values.map(x => x.areaM2)),
      watts: sum(values.map(x => x.watts))
    }))
    .value()
}

// Sort conduction heat losses based on element names in an intuitive order. Run before combine so the additional number doesn't affect the sorting.
export const sortHeatLosses = (heatLosses: ConductionHeatLoss[]) => {
  const order = ['Ground Floor', 'Exposed Floor', 'Intermediate Floor', 'Intermediate Ceiling', 'Roof', 'External Walls', 'Internal Walls', 'Party Walls', 'Windows', 'Rooflights', 'Doors']
  return heatLosses.sort((a, b) => order.indexOf(a.elementName) - order.indexOf(b.elementName))
}

// Rename duplicates of the same element name - add a number after the name if multiple
const renameDuplicates = <T extends { elementName: string }>(heatLosses: T[]): T[] => {
  const nameTotalCounts = countElementNames(heatLosses)
  const nameCounts: Record<string, number> = {}
  return heatLosses.map(item => {
    if (nameTotalCounts[item.elementName] > 1) {
      // If there is more than 1 of them then number all of them
      if (nameCounts[item.elementName]) {
        nameCounts[item.elementName]++
      } else {
        nameCounts[item.elementName] = 1
      }
      return { ...item, elementName: `${item.elementName} ${nameCounts[item.elementName]}` }
    } else {
      // If there is only 1 of them then don't number them
      return item
    }
  })
}

const countElementNames = <T extends { elementName: string }>(heatLosses: T[]): Record<string, number> => {
  const nameCounts: Record<string, number> = {}
  heatLosses.forEach(item => {
    if (nameCounts[item.elementName]) {
      nameCounts[item.elementName]++
    } else {
      nameCounts[item.elementName] = 1
    }
  })
  return nameCounts
}

// Combine heat losses into just element names and watt totals for the progress charts
// For chart don't want to differentiate between same element type with different U values
export const combineHeatLossesForProgressChart = (conductionHeatLosses: ConductionHeatLoss[], ventilationHeatLosses: VentilationHeatLoss[]) => {
  const sortedConductionHeatLosses = sortHeatLosses(conductionHeatLosses)
  const heatLosses = [...sortedConductionHeatLosses, ...ventilationHeatLosses]
  return chain(heatLosses)
    .groupBy(x => x.elementName)
    .map((values, key) => ({
      name: key,
      value: sum(values.map(x => x.watts))
    })).value()
}

export const getThermalBridgingUValueAdditionWPerM2K = (survey: PropertySurvey): number => {
  // Extra default value of 0 because initial defaults broken so survey key set up in error
  return survey.include_thermal_bridging ? THERMAL_BRIDGING_ADDITIONS.find(x => x.key === survey.thermal_bridging_addition_key)!.uValueAdditionWPerM2 ?? 0 : 0
}
