import React from 'react'
import { SurveyHeatPumpLocationContext } from '../heat_pump_location'
import { SoundAssessmentPage } from '../../sound_assessment_page'

export const HPLSoundAssessmentBlock = () => {
  const hplContext = React.useContext(SurveyHeatPumpLocationContext)
  const { survey, setSurvey, files, setFiles, soundCalculation, currentHeatPump, companyUUID } = hplContext!

  return <>
    <SoundAssessmentPage
      survey={survey}
      setSurvey={setSurvey}
      files={files}
      setFiles={setFiles}
      soundCalculation={soundCalculation}
      currentHeatPump={currentHeatPump}
      companyUUID={companyUUID}
    />
  </>
}
