import React, { type Dispatch, type SetStateAction, useContext, useEffect, useRef, useState } from 'react'
import {
  type Company,
  type CompanyPublicInfo,
  getAllCompaniesList,
  type GetAllCompaniesListResult,
  getCompany
} from '../../code/models/company'
import { AuthContext, AuthProvider, AuthSDK } from '../../code/utils/auth_provider'
import { noop } from 'lodash'
import { getHeatPumps } from '../../code/models/heat_pump'
import { getHotWaterCylinders } from '../../code/models/hot_water_cylinder'
import { getUser, hasEnquriesAccess, hasInventoryAccess, hasSettingsAccess, type User } from '../../code/models/user'
import { useIndexedDBFallback } from '../../code/use_indexed_db_fallback'
import {
  getGenericMaterials,
  getMaterialsGenericLayers,
  type Material,
  type MaterialLayer
} from '../../code/models/material'
import { type BrandRange, getBrandRanges } from '../../code/models/brand_range'
import { TermsAndConditionsModal } from './components/terms_and_conditions_modal'
import { getTermsAndConditions, userHasAcceptedLatestTerms, type TermsAndConditions } from '../../code/models/terms_and_conditions'
import { Alert } from '../../components/indicators_and_messaging/alert'
import { Link } from '../../components/buttons/link'
import { appStoreBadge, googleBadge } from '../../assets/images/store_badges/store_badges'
import { LeadsListPage } from './leads_list_page'
import { Error404Page } from '../error_pages'
import { InstallerAdminPage } from './job_layout/job_layout'
import { match } from 'path-to-regexp'
import { type RouteConfig } from '../../router'
import { SettingsPage } from './settings_page/settings_page'
import { NewPasswordPage } from './new_password'
import { SurveyPageWrapper } from '../survey/survey_page'
import { type Pack, getPacks } from '../../code/models/packs'
import { type InventoryHeatPump, type InventoryHotWaterCylinder, type InventoryPart } from '../../code/models/inventory'
import { getLabour, type Labour } from '../../code/models/labour'
import { getParts } from '../../code/models/parts'
import { CostsAndInventoryPage } from './costs_and_inventory/costs_page'
import { CircleHelp, House, LogOut, PanelLeftClose, PlusCircle, ReceiptPoundSterling, Settings } from 'lucide-react'
import { Select } from '../../components/inputs_and_selections/select'
import { WrappedIcon } from '../../components/buttons/wrapped_icon'
import { MenuSection } from './components/menu_section'
import { MenuItem } from './components/menu_item'
import { openInNewTab } from '../../code/helpers'

const RequireAuth = ({ children, companySubdomain, navigateTo, basePath }: { children: JSX.Element, companySubdomain: string, navigateTo: (url: string) => void, basePath: string }) => {
  const auth = useContext(AuthContext)

  const allowList = [
    `/${companySubdomain}/admin/login`,
    `/${companySubdomain}/admin/remind-password`,
    `/${companySubdomain}/admin/new-password`
  ]

  if (auth.isSignedIn() && (location.pathname.replace(/\/$/, '') === `/${companySubdomain}/admin` || allowList.includes(location.pathname))) {
    navigateTo(`${basePath}/enquiries`)
    return
  }

  if (!auth.isSignedIn() && !allowList.includes(location.pathname)) {
    navigateTo('/login')
    return
  }

  return children
}

type WrapperProps = {
  companyPublicInfo: CompanyPublicInfo
  navigateTo: (url: string) => void
  basePath: string
  currentPath: string
}

const Wrapper = ({ companyPublicInfo, basePath, currentPath, navigateTo }: WrapperProps) => {
  const auth = useContext(AuthContext)
  const adminContext = useContext(AdminContext)
  const [termsModalVisible, setTermsModalVisible] = useState<boolean>(false)

  const navRef = useRef<HTMLDivElement>(null)

  document.addEventListener('mousedown', (e) => {
    if (navRef.current && !navRef.current.contains(e.target as Node)) {
      adminContext.hideSidebar()
    }
  })

  useEffect(() => {
    // We need to do a raw regex check here, react router useMatch does not work.
    const surveyPage = /\/admin\/quotes\/[0-9a-fA-F-]{36}\/survey\/floors\/(.*)$/
    const isSidebarVisible = !surveyPage.test(window.location.pathname)
    adminContext.setIsSidebarVisible(isSidebarVisible)
  }, [location])

  const NEW_ENQUIRY = '/enquiries/new'
  const JOB_LIST = '/enquiries{/:filter}'
  const JOB_DETAIL = '/quotes/:leadUUID{/:tab}{/:secondaryTab}{/*any}'
  const SETTINGS_URL = '/settings{/:tab}{/:secondaryTab}'
  const COSTS_URL = '/costs{/:tab}{/:secondaryTab}'

  const ROUTES: RouteConfig[] = [{
    path: NEW_ENQUIRY,
    component: () => {
      return <SurveyPageWrapper navigateTo={navigateTo} companyPublicInfo={companyPublicInfo} showLogo={true} isAdmin={true} />
    }
  }, {
    path: JOB_LIST,
    component: ({ filter }) => {
      return <LeadsListPage filter={filter ?? 'All'} companyPublicInfo={companyPublicInfo} navigateTo={navigateTo} basePath={basePath} />
    }
  }, {
    path: JOB_DETAIL,
    component: ({ leadUUID, tab, secondaryTab }) => {
      const basePathQuote = `${basePath}/quotes/${leadUUID}`
      return <InstallerAdminPage basePath={basePathQuote} companyPublicInfo={companyPublicInfo} leadUUID={leadUUID} tab={tab} secondaryTab={secondaryTab} currentPath={currentPath} navigateTo={navigateTo} />
    }
  }, {
    path: SETTINGS_URL,
    component: ({ tab, secondaryTab }) => {
      return <SettingsPage navigateTo={navigateTo} tab={tab} secondaryTab={secondaryTab} companyPublicInfo={companyPublicInfo} />
    }
  }, {
    path: COSTS_URL,
    component: ({ tab, secondaryTab }) => <CostsAndInventoryPage companyPublicInfo={companyPublicInfo} tab={tab} secondaryTab={secondaryTab} navigateTo={navigateTo} />
  }, {
    path: '/new-password',
    component: () => <NewPasswordPage navigateTo={navigateTo} companyPublicInfo={companyPublicInfo} />
  }]

  const page = ROUTES.map(x => ({ evaluatedPath: match(x.path)(currentPath.replace(basePath, '')), path: x.path, component: x.component })).find(x => x.evaluatedPath)
  if (!page?.evaluatedPath) return <Error404Page />

  return (<>
    <div className="flex h-full">
      {/* Sidebar. Wrap it into a container to add PWA padding requirement. */}
      {auth.isSignedIn() && <>
        <div
          ref={navRef}
          className={`z-10 h-full bg-zinc-100 border-r w-64 px-4 py-6 ${adminContext.isSidebarVisible ? 'md:flex md:static' : ''} absolute 
            ${adminContext.isSidebarOpen && adminContext.isSidebarVisible ? '' : 'hidden'}
          `}
          data-cy="sidebar"
        >
          <div className='flex flex-col gap-10 justify-between h-full overflow-y-auto no-scrollbar'>
            <div className='flex flex-col gap-10'>
              <div className='flex justify-between items-center'>
                <img src={companyPublicInfo.logo} className='w-32' />
                <div className='cursor-pointer md:hidden' onClick={() => adminContext.hideSidebar()}><WrappedIcon icon={PanelLeftClose} /></div>
              </div>
              {adminContext.data.allCompaniesList && adminContext.data.allCompaniesList?.length > 1 &&
                <Select
                  filter={true}
                  options={adminContext.data.allCompaniesList.map(x => ({
                    key: x.subdomain,
                    value: x.name + ' (' + x.subdomain + ')'
                  }))}
                  selectedKey={companyPublicInfo.subdomain}
                  setSelectedKey={
                    (subdomain) => {
                      if (subdomain) window.location.href = `/${subdomain}/admin`
                    }
                  }
                />}
              {hasEnquriesAccess(adminContext.data?.company, adminContext.data?.user) && <MenuSection name='Jobs'>
                <MenuItem name='Jobs' icon={House} isSelected={[JOB_LIST, JOB_DETAIL].includes(page.path)} onClick={() => {
                  navigateTo(`${basePath}/enquiries`)
                  adminContext.hideSidebar()
                }} />
                <MenuItem name='New enquiry' icon={PlusCircle} isSelected={page.path === NEW_ENQUIRY} onClick={() => {
                  navigateTo(`${basePath}/enquiries/new`)
                  adminContext.hideSidebar()
                }} />
              </MenuSection>}
              {(hasInventoryAccess(adminContext.data?.company, adminContext.data?.user) || hasSettingsAccess(adminContext.data?.company, adminContext.data?.user)) && <MenuSection name='Configuration'>
                <MenuItem name='Costs & inventory' icon={ReceiptPoundSterling} isSelected={page.path === COSTS_URL} onClick={() => {
                  navigateTo(`${basePath}/costs`)
                  adminContext.hideSidebar()
                }} />
                <MenuItem name='Settings' icon={Settings} isSelected={page.path === SETTINGS_URL} onClick={() => {
                  navigateTo(`${basePath}/settings`)
                  adminContext.hideSidebar()
                }} />
              </MenuSection>}
              <MenuSection name='User'>
                <MenuItem name='Visit help centre' icon={CircleHelp} isSelected={false} onClick={() => openInNewTab('https://spruce-energy.notion.site/Spruce-Guide-de6f73384ba94ffbba3fe76adb096072')
                } />
                <MenuItem name='Logout' icon={LogOut} isSelected={false} onClick={() => auth.signOut(() => navigateTo('/login'))} />
              </MenuSection>
            </div>

            <div className="flex flex-col items-center gap-4">
              <a href="https://apps.apple.com/gb/app/spruce-energy/id6530588740" target="_blank" rel="noopener noreferrer">
                <img alt="Download on the App Store" className="w-32 h-auto" src={appStoreBadge} />
              </a>
              <a href="https://play.google.com/store/apps/details?id=eco.spruce.app" target="_blank" rel="noopener noreferrer">
                <img alt="Get it on Google Play" className="w-32 h-auto" src={googleBadge} />
              </a>
            </div>
          </div>
        </div>
      </>}

      {/* Content side */}
      <div className="overflow-auto print:overflow-visible w-full h-full flex flex-col">
        {/* Main content and header here */}
        {(!adminContext.isLoading && adminContext.data.termsAndConditions && !userHasAcceptedLatestTerms(adminContext.data.user, adminContext.data.termsAndConditions)) && <Alert type='WARNING'>We’ve updated our Terms & Conditions. Please could you review and accept them <Link text='here' className='inline' onClick={() => setTermsModalVisible(true)} />.</Alert>}
        {page.component(page.evaluatedPath.params)}
      </div>
    </div>

    {/* T&Cs modal */}
    <TermsAndConditionsModal visible={termsModalVisible} setVisible={setTermsModalVisible} termsAndConditions={adminContext.data.termsAndConditions} />
  </>)
}

export type AdminContextData = {
  company?: Company
  allCompaniesList?: GetAllCompaniesListResult[]
  heatPumps?: InventoryHeatPump[]
  hotWaterCylinders?: InventoryHotWaterCylinder[]
  labour?: Labour[]
  parts?: InventoryPart[]
  packs?: Pack[]
  genericMaterials?: Material[]
  brandRanges?: BrandRange[]
  materialsGenericLayers?: MaterialLayer[]
  user: User | undefined
  termsAndConditions?: TermsAndConditions
}

const AdminContextDataDefaultValue: AdminContextData = {
  company: undefined,
  allCompaniesList: [],
  heatPumps: [],
  hotWaterCylinders: [],
  labour: [],
  parts: [],
  packs: [],
  user: undefined
}

export type AdminContextType = {
  // sidebar operations
  showSidebar: () => void
  hideSidebar: () => void
  isSidebarOpen: boolean
  isLoading: boolean
  isOffline: boolean
  data: AdminContextData
  // setters for the data
  setCompany: (company: Company) => void
  setHeatPumps: (heat_pumps: InventoryHeatPump[]) => void
  setHotWaterCylinders: (hwcs: InventoryHotWaterCylinder[]) => void
  setLabour: (labour: Labour[]) => void
  setParts: (parts: InventoryPart[]) => void
  setPacks: (packs: Pack[]) => void
  acceptTermsAndConditions: (id: number) => void
  setIsOffline: Dispatch<SetStateAction<boolean>>
  isSidebarVisible: boolean // You cannot open or close the sidebar, it is fully hidden
  setIsSidebarVisible: Dispatch<SetStateAction<boolean>>
}

// Used to store global state for the admin UI
// also has triggers to update the high-level state for some objects like sidebar
export const AdminContext = React.createContext<AdminContextType>({
  showSidebar: noop,
  hideSidebar: noop,
  isSidebarOpen: false,
  isLoading: true,
  isOffline: false,
  data: AdminContextDataDefaultValue,
  // seters for the data
  setCompany: noop,
  setHeatPumps: noop,
  setHotWaterCylinders: noop,
  setLabour: noop,
  setParts: noop,
  setPacks: noop,
  acceptTermsAndConditions: noop,
  setIsOffline: noop,
  isSidebarVisible: true,
  setIsSidebarVisible: noop
})

type AdminLayoutProps = {
  companyPublicInfo: CompanyPublicInfo
  basePath: string
  currentPath: string
  navigateTo: (url: string) => void
}

export const AdminLayout = ({ companyPublicInfo, basePath, currentPath, navigateTo }: AdminLayoutProps) => {
  const [isSidebarOpen, setIsSidebarOpen] = useState<boolean>(false)
  const [isLoading, setIsLoading] = useState<boolean>(true)

  const [adminData, setAdminData] = useState<AdminContextData>(AdminContextDataDefaultValue)

  // the only reason this is here is to force reloading data when the user signs in
  const isLoggedIn = useRef<boolean>(AuthSDK.isSignedIn())

  // flags to track loading data
  const [loadedDataFlags, setLoadedDataFlags] = useState<number>(0)
  const FLAG_COMPANY_LOADED = 0x0001
  const FLAG_HEAT_PUMPS_LOADED = 0x0002
  const FLAG_HWCS_LOADED = 0x0004
  const FLAG_ALL_COMPANIES_LOADED = 0x0008
  const FLAG_USER_LOADED = 0x0010
  const FLAG_GENERIC_MATERIALS_LOADED = 0x0020
  const FLAG_MATERIALS_GENERIC_LAYERS_LOADED = 0x0040
  const FLAG_BRAND_RANGES_LOADED = 0x0080
  const FLAG_LABOUR_LOADED = 0x0100
  const FLAG_PARTS_LOADED = 0x0200
  const FLAG_PACKS_LOADED = 0x0400
  const FLAG_ALL_LOADED = FLAG_COMPANY_LOADED | FLAG_HEAT_PUMPS_LOADED | FLAG_HWCS_LOADED |
    FLAG_ALL_COMPANIES_LOADED | FLAG_USER_LOADED | FLAG_GENERIC_MATERIALS_LOADED | FLAG_MATERIALS_GENERIC_LAYERS_LOADED | FLAG_BRAND_RANGES_LOADED |
    FLAG_LABOUR_LOADED | FLAG_PARTS_LOADED | FLAG_PACKS_LOADED

  const [isOffline, setIsOffline] = useState(false)
  const [isSidebarVisible, setIsSidebarVisible] = useState(true)

  window.addEventListener('offline', () => {
    setIsOffline(true)
  })

  window.addEventListener('online', () => {
    setIsOffline(false)
  })

  const [, , , handleDataCompanyReload] = useIndexedDBFallback<Company | undefined>(
    'admin_data',
    'company',
    undefined,
    async () => {
      if (!isLoggedIn.current) return undefined
      return await getCompany(companyPublicInfo.subdomain)
    },
    (data) => {
      setLoadedDataFlags(prev => prev | FLAG_COMPANY_LOADED)
      setAdminData(prev => ({ ...prev, company: data }))
    }
  )

  const [, , , handleDataHeatPumpsReload] = useIndexedDBFallback<InventoryHeatPump[] | undefined>(
    'admin_data',
    'heat_pumps',
    [],
    async () => {
      if (!isLoggedIn.current) return undefined
      return await getHeatPumps(companyPublicInfo.uuid)
    },
    (data) => {
      setLoadedDataFlags(prev => prev | FLAG_HEAT_PUMPS_LOADED)
      setAdminData(prev => ({ ...prev, heatPumps: data }))
    }
  )

  const [, , , handleDataHWCsReload] = useIndexedDBFallback<InventoryHotWaterCylinder[] | undefined>(
    'admin_data',
    'hot_water_cylinders',
    [],
    async () => {
      if (!isLoggedIn.current) return undefined
      return await getHotWaterCylinders(companyPublicInfo.uuid)
    },
    (data) => {
      setLoadedDataFlags(prev => prev | FLAG_HWCS_LOADED)
      setAdminData(prev => ({ ...prev, hotWaterCylinders: data }))
    }
  )

  const [, , , handleDataLabourReload] = useIndexedDBFallback<Labour[] | undefined>(
    'admin_data',
    'labour',
    [],
    async () => {
      if (!isLoggedIn.current) return undefined
      return await getLabour(companyPublicInfo.uuid)
    },
    (data) => {
      setLoadedDataFlags(prev => prev | FLAG_LABOUR_LOADED)
      setAdminData(prev => ({ ...prev, labour: data }))
    }
  )

  const [, , , handleDataPartsReload] = useIndexedDBFallback<InventoryPart[] | undefined>(
    'admin_data',
    'parts',
    [],
    async () => {
      if (!isLoggedIn.current) return undefined
      return await getParts(companyPublicInfo.uuid)
    },
    (data) => {
      setLoadedDataFlags(prev => prev | FLAG_PARTS_LOADED)
      setAdminData(prev => ({ ...prev, parts: data }))
    }
  )

  const [, , , handleDataPacksReload] = useIndexedDBFallback<Pack[] | undefined>(
    'admin_data',
    'packs',
    [],
    async () => {
      if (!isLoggedIn.current) return undefined
      return await getPacks(companyPublicInfo.uuid)
    },
    (data) => {
      setLoadedDataFlags(prev => prev | FLAG_PACKS_LOADED)
      setAdminData(prev => ({ ...prev, packs: data }))
    }
  )

  const [, , , handleDataAllCompaniesReload] = useIndexedDBFallback<GetAllCompaniesListResult[] | undefined>(
    'admin_data',
    'all_companies',
    [],
    async () => {
      if (!isLoggedIn.current) return undefined
      return await getAllCompaniesList()
    },
    (data) => {
      setLoadedDataFlags(prev => prev | FLAG_ALL_COMPANIES_LOADED)
      setAdminData(prev => ({ ...prev, allCompaniesList: data }))
    }
  )

  const [, , , handleDataUserReload] = useIndexedDBFallback<User | undefined>(
    'admin_data',
    'current_user',
    undefined,
    async () => {
      if (!isLoggedIn.current) return undefined
      return await getUser()
    },
    (data) => {
      setLoadedDataFlags(prev => prev | FLAG_USER_LOADED)
      setAdminData(prev => ({ ...prev, user: data }))
    }
  )

  const [, , , handleDataTermsAndConditionsReload] = useIndexedDBFallback<TermsAndConditions | undefined>(
    'admin_data',
    'terms_and_conditions',
    undefined,
    async () => {
      return await getTermsAndConditions()
    },
    (data) => {
      setAdminData(prev => ({ ...prev, termsAndConditions: data }))
    }
  )

  const [, , , handleDataGenericMaterialsReload] = useIndexedDBFallback<Material[] | undefined>(
    'admin_data',
    'generic_materials',
    undefined,
    async () => {
      if (!isLoggedIn.current) return undefined
      return await getGenericMaterials()
    },
    (data) => {
      setLoadedDataFlags(prev => prev | FLAG_GENERIC_MATERIALS_LOADED)
      setAdminData(prev => ({ ...prev, genericMaterials: data }))
    }
  )

  const [, , , handleDataMaterialsGenericLayersReload] = useIndexedDBFallback<MaterialLayer[] | undefined>(
    'admin_data',
    'generic_materials_layers',
    undefined,
    async () => {
      if (!isLoggedIn.current) return undefined
      return await getMaterialsGenericLayers()
    },
    (data) => {
      setLoadedDataFlags(prev => prev | FLAG_MATERIALS_GENERIC_LAYERS_LOADED)
      setAdminData(prev => ({ ...prev, materialsGenericLayers: data }))
    }
  )

  const [, , , handleDataBrandRangesReload] = useIndexedDBFallback<BrandRange[] | undefined>(
    'admin_data',
    'brand_ranges',
    undefined,
    async () => {
      if (!isLoggedIn.current) return undefined
      return await getBrandRanges()
    },
    (data) => {
      setLoadedDataFlags(prev => prev | FLAG_BRAND_RANGES_LOADED)
      setAdminData(prev => ({ ...prev, brandRanges: data }))
    }
  )

  // ask user permission to persist data
  async function persistData () {
    if (navigator.storage?.persist) {
      await navigator.storage.persist()
    }
  }

  // aks user permission to persist data, triggers only on first load
  useEffect(() => {
    const ensureDataPersisted = async () => {
      // check if the browser supports the storage persist API
      const isPersisted = await navigator.storage.persisted()
      if (!isPersisted) {
        await persistData()
      }
    }
    ensureDataPersisted()
  }, [])

  // control the loading state
  useEffect(() => {
    // check if the user is logged in
    if (!isLoggedIn.current) return

    // reset the loading state only when all data is loaded
    if (loadedDataFlags === FLAG_ALL_LOADED) {
      setIsLoading(false)
    }
  }, [loadedDataFlags])

  const toggleSidebar = (arg: boolean) => {
    setIsSidebarOpen(arg)
  }

  const handleLoginCallback = async (newLoginStatus: boolean) => {
    isLoggedIn.current = newLoginStatus

    if (newLoginStatus) {
      setIsLoading(true)
      await handleDataCompanyReload()
      await handleDataHeatPumpsReload()
      await handleDataHWCsReload()
      await handleDataLabourReload()
      await handleDataPartsReload()
      await handleDataPacksReload()
      await handleDataAllCompaniesReload()
      await handleDataUserReload()
      await handleDataGenericMaterialsReload()
      await handleDataMaterialsGenericLayersReload()
      await handleDataBrandRangesReload()
      await handleDataTermsAndConditionsReload()
      setIsLoading(false)
    }
  }

  return (
    <AuthProvider loginCallback={handleLoginCallback}>
      <RequireAuth companySubdomain={companyPublicInfo.subdomain} navigateTo={navigateTo} basePath={basePath} >
        <AdminContext.Provider
          value={{
            showSidebar: () => {
              toggleSidebar(true)
            },
            hideSidebar: () => {
              toggleSidebar(false)
            },
            isSidebarOpen,
            isLoading,
            isOffline,
            setIsOffline,
            data: adminData,
            setCompany: (data) => setAdminData(prev => ({ ...prev, company: data })),
            setHeatPumps: (data) => setAdminData(prev => ({ ...prev, heatPumps: data })),
            setHotWaterCylinders: (data) => setAdminData(prev => ({ ...prev, hotWaterCylinders: data })),
            setLabour: (data) => setAdminData(prev => ({ ...prev, labour: data })),
            setParts: (data) => setAdminData(prev => ({ ...prev, parts: data })),
            setPacks: (data) => setAdminData(prev => ({ ...prev, packs: data })),
            acceptTermsAndConditions: async (id) => { setAdminData(prev => ({ ...prev, user: { ...prev.user!, accepted_terms_and_conditions_id: id } })) },
            isSidebarVisible,
            setIsSidebarVisible
          }}
        >
          <Wrapper companyPublicInfo={companyPublicInfo} navigateTo={navigateTo} basePath={basePath} currentPath={currentPath} />
        </AdminContext.Provider>
      </RequireAuth>
    </AuthProvider>
  )
}
