import { type KonvaEventObject } from 'konva/lib/Node'
import { type Vector2d } from 'konva/lib/types'
import { type Dispatch, type SetStateAction } from 'react'
import { getCenter, getDistance } from './utils'

export const onStageTouchMove = (
  e: KonvaEventObject<TouchEvent>,
  dragStopped: boolean,
  setDragStopped: Dispatch<SetStateAction<boolean>>,
  lastCenter: Vector2d | undefined,
  setLastCenter: Dispatch<SetStateAction<Vector2d>>,
  lastDist: number | undefined,
  setLastDist: Dispatch<SetStateAction<number>>,
  stagePosition: Vector2d,
  setStagePosition: Dispatch<SetStateAction<Vector2d>>,
  stageScale: number,
  setStageScale: Dispatch<SetStateAction<number>>
) => {
  e.evt.preventDefault()

  const touch1 = e.evt.touches[0]
  const touch2 = e.evt.touches[1]
  const stage = e.currentTarget.getStage()!

  if (touch1 && !touch2 && !stage.isDragging() && dragStopped) {
    stage.startDrag()
    setDragStopped(false)
  }

  if (touch1 && touch2) {
    if (stage.isDragging()) {
      setDragStopped(true)
      stage.stopDrag()
    }

    const p1 = {
      x: touch1.clientX,
      y: touch1.clientY
    }
    const p2 = {
      x: touch2.clientX,
      y: touch2.clientY
    }

    if (!lastCenter) {
      setLastCenter(getCenter(p1, p2))
      return
    }
    const newCenter = getCenter(p1, p2)
    const dist = getDistance(p1, p2)

    if (!lastDist) {
      setLastDist(dist)
      return
    }

    const pointTo = {
      x: (newCenter.x - stagePosition.x) / stageScale,
      y: (newCenter.y - stagePosition.y) / stageScale
    }

    const scale = stageScale * (dist / lastDist)
    setStageScale(scale)

    const dx = newCenter.x - lastCenter.x
    const dy = newCenter.y - lastCenter.y

    const newPos = {
      x: newCenter.x - pointTo.x * scale + dx,
      y: newCenter.y - pointTo.y * scale + dy
    }

    setStagePosition(newPos)
    setLastDist(dist)
    setLastCenter(newCenter)
  }
}
