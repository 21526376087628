import { getFluidProperties } from './fluids'
import type { PropertySurvey, SurveyDesign } from './property'
import { type Emitter, getEmitterSizeName, getEmitterTypeName, type SecondaryEmitter } from './radiator'
import type { Room } from './room'
import { sum } from 'lodash'
import { numberFormat } from '../number_format'

export type HydronicEmitter = Exclude<Emitter, SecondaryEmitter>
export type HydronicEmitterWithRoomAndWatts = HydronicEmitter & { room: Room, watts: number, coverageM2?: number }

export type PipeData = {
  id: string
  type: string | undefined
  name: string
  kwatts: string
  velocityMPerS: string
  velocityAlert: boolean
  flowRateM3PerS: number // number because change units later
  pressureDropPaPerM: string
  linearPressureDropAlert: boolean
  lengthM: number | undefined
  totalPressureDropkPa: number // number because sum later
  pipeMaterial: PipeModel | undefined
}

type PipeDataInput = {
  id: string
  type: string | undefined
  name: string
  lengthM: number | undefined
  pipeMaterial: PipeModel | undefined
  connectedEmittersWithRoomAndWatts: HydronicEmitterWithRoomAndWatts[] // the emitters fed by this pipe
}

export const getPipeData = (
  survey: PropertySurvey,
  design: SurveyDesign,
  deltaTFlowReturnC: number,
  flowTempC: number,
  heatPumpCapacitykW: number,
  designedEmitters: HydronicEmitterWithRoomAndWatts[],
  indexEmitterUUID: string | undefined,
  secondaryEmitterUUIDS: string[]
): PipeData[] => {
  // We want the design radiator that replaces the surveyed radiator for name and watts.
  // But keep the surveyed uuid so we can add or remove from secondary_emitter_uuids table

  const indexEmitter = designedEmitters.find(x => x.uuid === indexEmitterUUID)
  const emittersOnIndexSecondary = designedEmitters.filter(x => secondaryEmitterUUIDS.includes(x.uuid!))

  const primaryPipeModel = PIPE_MODELS.find(x => x.uuid === (design.primary_pipework_uuid_override ?? survey.existing_system_pipework_uuid))
  const secondaryPipeModel = PIPE_MODELS.find(x => x.uuid === (design.secondary_pipework_uuid_override ?? survey.secondary_index_pipework_uuid))
  const indexPipeModel = PIPE_MODELS.find(x => x.uuid === (design.index_pipework_uuid_override ?? indexEmitter?.pipe_model_uuid))

  const primaryPipeLengthM = design.primary_pipework_length_m_override ?? survey.primary_pipework_length_m
  const secondaryPipeLengthM = design.secondary_pipework_length_m_override ?? survey.secondary_index_pipework_length_m
  const indexPipeLengthM = design.index_pipework_length_m_override ?? survey.index_emitter_pipe_length_m

  const primaryInputData: PipeDataInput = {
    id: 'PRIMARY',
    type: 'PRIMARY',
    name: primaryPipeModel ? `Primary - ${primaryPipeModel?.exterior_diameter_mm} mm - ${primaryPipeModel?.material}` : 'Primary',
    lengthM: primaryPipeLengthM,
    pipeMaterial: primaryPipeModel,
    connectedEmittersWithRoomAndWatts: designedEmitters // all emitters on the primary
  }

  const secondaryInputData: PipeDataInput = {
    id: 'SECONDARY',
    type: 'SECONDARY',
    name: secondaryPipeModel ? `Secondary - ${secondaryPipeModel?.exterior_diameter_mm} mm - ${secondaryPipeModel?.material}` : 'Secondary',
    lengthM: secondaryPipeLengthM,
    pipeMaterial: secondaryPipeModel,
    connectedEmittersWithRoomAndWatts: emittersOnIndexSecondary
  }

  const indexPipeInputData: PipeDataInput = {
    id: 'INDEX',
    type: indexEmitter?.type ?? undefined,
    name: indexEmitter && indexPipeModel ? `Index emitter - ${indexEmitter.room?.name} ${getEmitterTypeName(indexEmitter)} ${getEmitterSizeName(indexEmitter)}` : 'Index emitter',
    lengthM: indexPipeLengthM,
    pipeMaterial: indexPipeModel,
    connectedEmittersWithRoomAndWatts: indexEmitter ? [indexEmitter] : []
  }

  return [primaryInputData, secondaryInputData, indexPipeInputData].map(x => getPipeDataFromInput(x, heatPumpCapacitykW, deltaTFlowReturnC, flowTempC))
}

/**
 * Add in all the calculated values for the pipe data so don't have to duplicate the calculations
 */
export const getPipeDataFromInput = (
  input: PipeDataInput,
  heatPumpCapacitykW: number,
  deltaTFlowReturnC: number,
  flowTempC: number): PipeData => {
  const watts = Math.min(sum(input.connectedEmittersWithRoomAndWatts.map(x => x.watts)), heatPumpCapacitykW * 1000)
  const { velocityMPerS, flowRateM3PerS } = getVelocityInPipeMPerS(watts, input.pipeMaterial, deltaTFlowReturnC, flowTempC)
  const pressureDropPaPerM = getPipePressureDropPerM(watts, input.pipeMaterial, deltaTFlowReturnC, flowTempC)
  const velocityAlert = velocityMPerS > 1.5
  const linearPressureDropAlert = pressureDropPaPerM > 300

  return {
    id: input.id,
    type: input.type,
    name: input.name,
    kwatts: (watts / 1000).toFixed(2),
    velocityMPerS: numberFormat(2).format(velocityMPerS),
    velocityAlert,
    flowRateM3PerS,
    pressureDropPaPerM: numberFormat(0).format(pressureDropPaPerM),
    linearPressureDropAlert,
    lengthM: input.lengthM,
    totalPressureDropkPa: getTotalPressureDropkPa(input.lengthM, pressureDropPaPerM),
    pipeMaterial: input.pipeMaterial
  }
}

// The length is multiplied by 2 as the entered value should only be flow length not return.
const getTotalPressureDropkPa = (flowLengthM: number | undefined, indexPressureDropPerM: number | undefined) =>
  Number((((flowLengthM ?? 0) * 2 * (indexPressureDropPerM ?? 0)) / 1000).toFixed(2))

export type PipeModel = {
  uuid: string
  material: string
  exterior_diameter_mm: number
  internal_diameter_mm: number
  is_microbore: boolean
}

export type PipeMaterial = {
  name: string
  roughness_mm: number
}

export const getPipeModelName = (pipeSize: PipeModel) => `${pipeSize.exterior_diameter_mm} mm (${pipeSize.internal_diameter_mm} mm internal diameter)`

export const getVelocityInPipeMPerS = (watts: number, pipeModel: PipeModel | undefined, deltaTFlowReturnC: number, flowTempC: number) => {
  if (!pipeModel) return { velocityMPerS: 0, flowRateM3PerS: 0 }

  const fluidProperties = getFluidProperties('WATER', flowTempC, deltaTFlowReturnC)!

  const heatCapacityJoulesPerM3PerC = fluidProperties.specific_heat_capacity_j_per_kg_k * fluidProperties.density_kg_per_m3
  const heatCapacityJoulesPerM3 = heatCapacityJoulesPerM3PerC * deltaTFlowReturnC
  const PI = 3.14159265358979
  // watts have units joules per second
  const flowRateM3PerSecond = watts / heatCapacityJoulesPerM3

  const pipeInternalAreaM2 = PI * (pipeModel.internal_diameter_mm / 1000 / 2) ** 2

  return { velocityMPerS: flowRateM3PerSecond / pipeInternalAreaM2, flowRateM3PerS: flowRateM3PerSecond }
}

export const getPipePressureDropPerM = (watts: number, pipeModel: PipeModel | undefined, deltaTFlowReturnC: number, flowTempC: number): number => {
  if (!pipeModel) return 0

  if (watts === 0) return 0

  const pipeInternalDiameterM = pipeModel.internal_diameter_mm / 1000
  const pipeRoughnessM = PIPE_MATERIALS.find(x => x.name === pipeModel.material)!.roughness_mm / 1000
  const relativeRoughness = pipeRoughnessM / pipeInternalDiameterM

  const fluidProperties = getFluidProperties('WATER', flowTempC, deltaTFlowReturnC)!
  const { velocityMPerS } = getVelocityInPipeMPerS(watts, pipeModel, deltaTFlowReturnC, flowTempC)
  const reynoldsNumber = velocityMPerS * pipeInternalDiameterM / fluidProperties.kinematic_viscosity_m_per_s

  let frictionFactor: number
  if (reynoldsNumber < 2300) {
    //   Laminar flow
    frictionFactor = 64 / reynoldsNumber
  } else {
    // Transitional or tubulent flow - use the Haaland approximation. Won't be perfect in transitional region (2300 to 4000)
    frictionFactor = (1 / (-1.8 * Math.log10((relativeRoughness / 3.7) ** 1.11 + 6.9 / reynoldsNumber))) ** 2
  }
  const pressureDropPaPerM = frictionFactor / pipeInternalDiameterM * 0.5 * fluidProperties.density_kg_per_m3 * velocityMPerS ** 2
  return pressureDropPaPerM
}

export const PIPE_MODELS: PipeModel[] = [
  { uuid: 'copper_8', material: 'Copper', exterior_diameter_mm: 8, internal_diameter_mm: 6.8, is_microbore: true },
  { uuid: 'copper_10', material: 'Copper', exterior_diameter_mm: 10, internal_diameter_mm: 8.8, is_microbore: true },
  { uuid: 'copper_15', material: 'Copper', exterior_diameter_mm: 15, internal_diameter_mm: 13.6, is_microbore: false },
  { uuid: 'copper_22', material: 'Copper', exterior_diameter_mm: 22, internal_diameter_mm: 20.2, is_microbore: false },
  { uuid: 'copper_28', material: 'Copper', exterior_diameter_mm: 28, internal_diameter_mm: 26.2, is_microbore: false },
  { uuid: 'copper_35', material: 'Copper', exterior_diameter_mm: 35, internal_diameter_mm: 32.6, is_microbore: false },
  { uuid: 'copper_42', material: 'Copper', exterior_diameter_mm: 42, internal_diameter_mm: 39.6, is_microbore: false },
  { uuid: 'copper_54', material: 'Copper', exterior_diameter_mm: 54, internal_diameter_mm: 51.6, is_microbore: false },
  { uuid: 'pex_10', material: 'PEX', exterior_diameter_mm: 10, internal_diameter_mm: 7, is_microbore: true },
  { uuid: 'pex_12', material: 'PEX', exterior_diameter_mm: 12, internal_diameter_mm: 8, is_microbore: true },
  { uuid: 'pex_15', material: 'PEX', exterior_diameter_mm: 15, internal_diameter_mm: 10, is_microbore: false },
  { uuid: 'pex_16', material: 'PEX', exterior_diameter_mm: 16, internal_diameter_mm: 11.6, is_microbore: false },
  { uuid: 'pex_20', material: 'PEX', exterior_diameter_mm: 20, internal_diameter_mm: 14.4, is_microbore: false },
  { uuid: 'pex_22', material: 'PEX', exterior_diameter_mm: 22, internal_diameter_mm: 16, is_microbore: false },
  { uuid: 'pex_25', material: 'PEX', exterior_diameter_mm: 25, internal_diameter_mm: 20.4, is_microbore: false },
  { uuid: 'pex_28', material: 'PEX', exterior_diameter_mm: 28, internal_diameter_mm: 22.5, is_microbore: false }, // Taken from RS Datasheet - https://docs.rs-online.com/507d/0900766b8136a3e9.pdf
  { uuid: 'pex_32', material: 'PEX', exterior_diameter_mm: 32, internal_diameter_mm: 26.2, is_microbore: false },
  { uuid: 'pex_40', material: 'PEX', exterior_diameter_mm: 40, internal_diameter_mm: 32.6, is_microbore: false },
  { uuid: 'pex_50', material: 'PEX', exterior_diameter_mm: 50, internal_diameter_mm: 40.8, is_microbore: false },
  { uuid: 'MLCP_14', material: 'MLCP', exterior_diameter_mm: 14, internal_diameter_mm: 10, is_microbore: false },
  { uuid: 'MLCP_16', material: 'MLCP', exterior_diameter_mm: 16, internal_diameter_mm: 12, is_microbore: false },
  { uuid: 'MLCP_18', material: 'MLCP', exterior_diameter_mm: 18, internal_diameter_mm: 14, is_microbore: false },
  { uuid: 'MLCP_20', material: 'MLCP', exterior_diameter_mm: 20, internal_diameter_mm: 15.5, is_microbore: false },
  { uuid: 'MLCP_25', material: 'MLCP', exterior_diameter_mm: 25, internal_diameter_mm: 20, is_microbore: false },
  { uuid: 'MLCP_32', material: 'MLCP', exterior_diameter_mm: 32, internal_diameter_mm: 26, is_microbore: false },
  { uuid: 'MLCP_40', material: 'MLCP', exterior_diameter_mm: 40, internal_diameter_mm: 32, is_microbore: false }
]

export const PIPE_MATERIALS: PipeMaterial[] = [
  { name: 'Copper', roughness_mm: 0.003 }, // EN 1057 X
  { name: 'PEX', roughness_mm: 0.007 },
  { name: 'MLCP', roughness_mm: 0.007 }
]
