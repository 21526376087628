import React, { type CSSProperties, type PropsWithChildren, useState } from 'react'
import { Modal } from '../containers/modal'
import { type LucideIcon } from 'lucide-react'
import { WrappedIcon } from './wrapped_icon'

const colours = {
  TRANSPARENT: 'bg-transparent border-transparent text-gray-600 font-bold',
  DARK: 'bg-gray-900 text-white border border-gray-900 text-white font-bold rounded-md',
  LIGHT: 'bg-white border border-gray-300 font-bold rounded-md hover:bg-gray-50',
  GRAY: 'bg-gray-50 border border-gray-50 rounded-md hover:bg-gray-100',
  LOZENGE_LIGHT: 'bg-gray-100 rounded-full font-bold',
  LOZENGE_DARK: 'bg-gray-200 rounded-full font-bold'
}

const sizes = {
  SM: 'text-xs py-2 px-2.5',
  MD: 'text-sm px-4 py-2',
  LG: 'px-4 py-3'
}

// Force change frontend

export type ButtonProps = {
  onClick?: () => void
  block?: boolean
  disabled?: boolean
  size?: keyof typeof sizes
  className?: string
  colour?: keyof typeof colours
  iconLeft?: LucideIcon
  iconRight?: LucideIcon
  confirmText?: string
  style?: CSSProperties
}

export const Button = ({
  children,
  onClick,
  block,
  disabled,
  size = 'MD',
  className,
  colour = 'DARK',
  iconLeft,
  iconRight,
  confirmText,
  style,
  ...rest
}: PropsWithChildren<ButtonProps>) => {
  const [modalOpen, setModalOpen] = useState(false)

  return <>
    <button
      disabled={disabled}
      onClick={confirmText ? () => setModalOpen(true) : onClick}
      className={`${colours[colour]} ${sizes[size]} disabled:opacity-50 cursor-pointer items-center justify-center flex disabled:cursor-default outline-none ${block ? 'block w-full' : ''
      } ${className}`}
      style={style}
      { ...rest }
    >
      {/* we don't need the gap if there is no button content provided */}
      <div className={`${children ? 'gap-2' : ''} flex items-center`}>
        {iconLeft && <WrappedIcon icon={iconLeft} />}
        {children}
        {iconRight && <WrappedIcon icon={iconRight} />}
      </div>
    </button>
    {confirmText && modalOpen && <Modal onConfirm={onClick} visible={true} title={confirmText} setVisible={() => setModalOpen(!modalOpen)}>This action cannot be undone.</Modal>}
  </>
}
