import React from 'react'
import { ButtonDemo } from './buttons/button_demo'
import { IconDemo } from './buttons/icon_demo'
import { InfoDemo } from './buttons/info_demo'
import { LinkDemo } from './buttons/link_demo'
import { BottomSheetHeaderDemo } from './containers/bottom_sheet_header_demo'
import { ModalDemo } from './containers/modal_demo'
import { SectionDemo } from './containers/section_demo'
import { AccordionDemo } from './content_display/accordion_demo'
import { CardDemo } from './content_display/card_demo'
import { EmptyStateDemo } from './content_display/empty_state_demo'
import { ListItemDemo } from './content_display/list_item_demo'
import { TabDemo } from './content_display/tab_demo'
import { TableDemo } from './content_display/table_demo'
import { TableLiteDemo } from './content_display/table_lite_demo'
import { AlertDemo } from './indicators_and_messaging/alert_demo'
import { BadgeDemo } from './indicators_and_messaging/badge_demo'
import { LoaderDemo } from './indicators_and_messaging/loader_demo'
import { ProgressChartDemo } from './indicators_and_messaging/progress_chart_demo'
import { ToastDemo } from './indicators_and_messaging/toast_demo'
import { CheckboxDemo } from './inputs_and_selections/checkbox_demo'
import { SelectDemo } from './inputs_and_selections/select_demo'
import { FormLabelDemo } from './inputs_and_selections/form_label_demo'
import { InputDemo } from './inputs_and_selections/input_demo'
import { VerticalFormGroupDemo } from './inputs_and_selections/vertical_form_group_demo'
import { PhotoScrollDemo } from './inputs_and_selections/photo_scroll_demo'
import { RadioDemo } from './inputs_and_selections/radio_demo'
import { SliderDemo } from './inputs_and_selections/slider_demo'
import { TextAreaDemo } from './inputs_and_selections/text_area_demo'
import { ToggleDemo } from './inputs_and_selections/toggle_demo'
import { TextDemo } from './content_display/text_demo'
import { HeadingDemo } from './content_display/heading_demo'
import { SummaryTableDemo } from './content_display/summary_table_demo'
import { Header } from '../survey/components/header'
import { HorizontalFormGroupDemo } from './inputs_and_selections/horizontal_form_group_demo'
import { DropdownMenuDemo } from './buttons/dropdown_menu_demo'
import { FileDropzoneDemo } from './inputs_and_selections/file_dropzone_demo'
import { ProgressDemo } from './content_display/progress_demo'

type MenuItem = {
  url: string | undefined
  urlRegex: RegExp | undefined
  title: string
  page: JSX.Element | undefined
  children: MenuItem[]
}
type ComponentsProps = {
  currentPath: string
  navigateTo: (url: string) => void
}

export const Components = ({ currentPath, navigateTo }: ComponentsProps) => {
  const navbarItems: MenuItem[] = [
    {
      title: 'Buttons',
      page: undefined,
      urlRegex: undefined,
      url: undefined,
      children: [
        { url: '/components/button', urlRegex: /^\/components\/button$/, title: 'Button', page: <ButtonDemo />, children: [] },
        { url: '/components/icon', urlRegex: /^\/components\/icon$/, title: 'Icon', page: <IconDemo />, children: [] },
        { url: '/components/info', urlRegex: /^\/components\/info$/, title: 'Info', page: <InfoDemo />, children: [] },
        { url: '/components/link', urlRegex: /^\/components\/link$/, title: 'Link', page: <LinkDemo />, children: [] },
        { url: '/components/dropdown_menu', urlRegex: /^\/components\/dropdown_menu$/, title: 'Dropdown menu', page: <DropdownMenuDemo />, children: [] }
      ]
    },
    {
      title: 'Containers',
      page: undefined,
      urlRegex: undefined,
      url: undefined,
      children: [
        { url: '/components/bottom_sheet_header', urlRegex: /^\/components\/bottom_sheet_header$/, title: 'Bottom Sheet Header', page: <BottomSheetHeaderDemo />, children: [] },
        { url: '/components/modal', urlRegex: /^\/components\/modal$/, title: 'Modal', page: <ModalDemo />, children: [] },
        { url: '/components/section', urlRegex: /^\/components\/section$/, title: 'Section', page: <SectionDemo />, children: [] }
      ]
    },
    {
      title: 'Content Display',
      page: undefined,
      urlRegex: undefined,
      url: undefined,
      children: [
        { url: '/components/text', urlRegex: /^\/components\/text$/, title: 'Text', page: <TextDemo />, children: [] },
        { url: '/components/heading', urlRegex: /^\/components\/heading$/, title: 'Heading', page: <HeadingDemo />, children: [] },
        { url: '/components/accordion', urlRegex: /^\/components\/accordion$/, title: 'Accordion', page: <AccordionDemo />, children: [] },
        { url: '/components/card', urlRegex: /^\/components\/card$/, title: 'Card', page: <CardDemo />, children: [] },
        { url: '/components/empty_state', urlRegex: /^\/components\/empty_state$/, title: 'Empty State', page: <EmptyStateDemo />, children: [] },
        { url: '/components/list_item', urlRegex: /^\/components\/list_item$/, title: 'List Item', page: <ListItemDemo />, children: [] },
        { url: '/components/progress', urlRegex: /^\/components\/progress$/, title: 'Progress', page: <ProgressDemo />, children: [] },
        { url: '/components/tab', urlRegex: /^\/components\/tab$/, title: 'Tab', page: <TabDemo />, children: [] },
        { url: '/components/table_lite', urlRegex: /^\/components\/table_lite$/, title: 'Table Lite', page: <TableLiteDemo />, children: [] },
        { url: '/components/table', urlRegex: /^\/components\/table$/, title: 'Table', page: <TableDemo />, children: [] },
        { url: '/components/summary_table', urlRegex: /^\/components\/summary_table$/, title: 'Summary Table', page: <SummaryTableDemo />, children: [] }
      ]
    },
    {
      title: 'Indicators & Messaging',
      page: undefined,
      urlRegex: undefined,
      url: undefined,
      children: [
        { url: '/components/alert', urlRegex: /^\/components\/alert$/, title: 'Alert', page: <AlertDemo />, children: [] },
        { url: '/components/badge', urlRegex: /^\/components\/badge$/, title: 'Badge', page: <BadgeDemo />, children: [] },
        { url: '/components/loader', urlRegex: /^\/components\/loader$/, title: 'Loader', page: <LoaderDemo />, children: [] },
        { url: '/components/progress_chart', urlRegex: /^\/components\/progress_chart$/, title: 'Progress Chart', page: <ProgressChartDemo />, children: [] },
        { url: '/components/toast', urlRegex: /^\/components\/toast$/, title: 'Toast', page: <ToastDemo />, children: [] }
      ]
    },
    {
      title: 'Inputs & Selectors',
      page: undefined,
      urlRegex: undefined,
      url: undefined,
      children: [
        { url: '/components/checkbox', urlRegex: /^\/components\/checkbox$/, title: 'Checkbox', page: <CheckboxDemo />, children: [] },
        { url: '/components/select', urlRegex: /^\/components\/select$/, title: 'Select', page: <SelectDemo />, children: [] },
        { url: '/components/form_label', urlRegex: /^\/components\/form_label$/, title: 'Form Label', page: <FormLabelDemo />, children: [] },
        { url: '/components/input_group', urlRegex: /^\/components\/input_group$/, title: 'Input Group', page: <VerticalFormGroupDemo />, children: [] },
        { url: '/components/input', urlRegex: /^\/components\/input$/, title: 'Input', page: <InputDemo />, children: [] },
        { url: '/components/photo_scroll', urlRegex: /^\/components\/photo_scroll$/, title: 'Photo Scroll', page: <PhotoScrollDemo />, children: [] },
        { url: '/components/radio', urlRegex: /^\/components\/radio$/, title: 'Radio', page: <RadioDemo />, children: [] },
        { url: '/components/slider', urlRegex: /^\/components\/slider$/, title: 'Slider', page: <SliderDemo />, children: [] },
        { url: '/components/text_area', urlRegex: /^\/components\/text_area$/, title: 'Text Area', page: <TextAreaDemo />, children: [] },
        { url: '/components/toggle_input_group', urlRegex: /^\/components\/toggle_input_group$/, title: 'Toggle Input Group', page: <HorizontalFormGroupDemo />, children: [] },
        { url: '/components/toggle', urlRegex: /^\/components\/toggle$/, title: 'Toggle', page: <ToggleDemo />, children: [] },
        { url: '/components/file_dropzone', urlRegex: /^\/components\/file_dropzone$/, title: 'File Dropzone', page: <FileDropzoneDemo />, children: [] }
      ]
    }
  ]

  const page = recursiveFilter(currentPath, navbarItems)?.page

  return <div className='h-full flex'>
    <div className='overflow-y-auto border-r border-gray-200 flex-shrink-0'>
      <RenderItems menuItems={navbarItems} depth={1} url={currentPath} handleUrlChange={navigateTo} />
    </div>
    <div className='flex-grow p-8 max-w-3xl overflow-y-auto'>{page ?? <Header content='Spruce Component Library' />}</div>
  </div>
}

const RenderItems = ({ menuItems, depth, url, handleUrlChange }: { menuItems: MenuItem[], depth: number, url: string, handleUrlChange: (url: string) => void }) => {
  return menuItems.map(x => <div className={`flex flex-col ${x.page ? 'cursor-pointer' : ''}`} onClick={() => x.page && handleUrlChange(x.url!)} key={x.title}>
    <div style={{ paddingLeft: depth * 10 }} className={`p-4 ${x.children.length > 0 ? 'font-bold text-gray-900' : ''} ${x.urlRegex?.test(url) ? 'underline' : ''} border-b border-gray-200`}>{x.title}</div>
    {x.children.length > 0 && <div>
      <RenderItems menuItems={x.children} depth={depth + 1} url={url} handleUrlChange={handleUrlChange} />
    </div>}
  </div>)
}

const recursiveFilter = (url: string | undefined, menuItems: MenuItem[]): MenuItem | undefined => {
  for (const item of menuItems) {
    if (item.urlRegex?.test(url!)) return item

    const child = recursiveFilter(url, item.children)
    if (child) return child
  }
}
