import React, { useState } from 'react'
import { Menu, MenuButton, MenuItem, MenuItems } from '@headlessui/react'
import { Icon } from './icon'
import { Text } from '../content_display/text'
import { Modal } from '../containers/modal'
import { Ellipsis, type LucideIcon } from 'lucide-react'
import { chain } from 'lodash'

type Item = {
  label: string
  icon?: LucideIcon
  onClick: () => void
  confirmText?: string
  group?: string
}

type Props = {
  icon?: LucideIcon
  items: Item[]
}

export const DropdownMenu = ({ icon, items }: Props) => {
  const [confirmModalItem, setConfirmModalItem] = useState<Item | undefined>(undefined)

  const itemsGrouped = chain(items)
    .groupBy(x => x.group)
    .map((values, key) => ({ key, values }))
    .value()

  return (
    <>
      <Menu>
        <MenuButton>{<Icon icon={icon ?? Ellipsis} />}</MenuButton>
        <MenuItems anchor="bottom end" className="bg-white rounded-md shadow border border-light flex flex-col z-30 divide-y divide-gray-200">
          {itemsGrouped.map(group => {
            return <div key={group.key} className='flex flex-col'>
              {group.values.map((item, index) => (
                <MenuItem key={index}>
                  <button onClick={item.confirmText ? () => setConfirmModalItem(item) : item.onClick} className="first:pt-2 last:pb-2 flex items-center gap-2 px-3 py-2 hover:bg-gray-200">
                    {item.icon && <Icon icon={item.icon} />}
                    <Text size="SM">{item.label}</Text>
                  </button>
                </MenuItem>
              ))}
            </div>
          })}
        </MenuItems>
      </Menu>
      {confirmModalItem && <Modal onConfirm={confirmModalItem.onClick} visible={true} title={confirmModalItem.confirmText} setVisible={() => setConfirmModalItem(undefined)}>This action cannot be undone.</Modal>}
    </>
  )
}
