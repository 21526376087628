import React from 'react'
import { Info } from '../../../../components/buttons/info'

export type InventoryTableProps<T> = {
  columns: Array<InventoryTableColumn<T>>
  rows: T[]
}

export type InventoryTableColumn<T> = {
  name: string
  key?: keyof T
  render?: (row: T) => React.ReactNode
  align?: 'left' | 'center' | 'right'
  infoText?: string
}

export const InventoryTable = <T extends {}>({ columns, rows }: InventoryTableProps<T>) => {
  return (
    <table className='w-full text-default'>
      <thead className='text-xs font-semibold hidden sm:table-header-group'>
        <tr>
          {columns.map((x, i) => (
            <th key={i} className={`first:p-0 first:py-2 p-2 ${x.align === 'right' ? 'text-end' : x.align === 'center' ? 'text-center' : 'text-start'} ${i === 0 && 'w-1/3'}`}>
              {x.name}
              {x.infoText && <Info className="pl-1" infoModalHeader={x.name} infoModalBody={x.infoText} />}
            </th>
          ))}
        </tr>
      </thead>
      <tbody className='sm:divide-y sm:border-light'>
        {rows.map((row, i) => (
          <tr key={i} className='flex flex-col sm:table-row border border-light rounded sm:border-0 mb-2 p-2 sm:mb-0 sm:p-0'>
            {columns.map((column, j) => (
              <td key={j} className={`grid grid-cols-[1fr_2fr] sm:table-cell border-b last:border-0 border-gray-100 sm:border-0 ${column.align === 'right' ? 'sm:text-end' : column.align === 'center' ? 'sm:text-center' : 'text-start'} py-2 sm:p-2 sm:first:ps-0 sm:last:pe-0`}>
                {/* Mobile-only headers */}
                <span className='sm:hidden uppercase tracking-wide text-xs text-gray-500 font-semibold'>
                  {column.name}
                  {column.infoText && <Info className="pl-1" infoModalHeader={column.name} infoModalBody={column.infoText} />}
                </span>
                <div className='sm:whitespace-pre-wrap'>
                  {column.render ? column.render(row) : row[column.key as string]}
                </div>
              </td>
            ))}
          </tr>
        ))}
      </tbody>
    </table>
  )
}
