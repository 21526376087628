import { noop } from 'lodash'
import { createContext } from 'react'

// used to flag that we load some data from the server
export type DataLoaderProps = {
  loadingData: boolean
  setLoadingData: React.Dispatch<React.SetStateAction<boolean>>
}

// used to flag that we load some data from the server
export const DataLoaderContext = createContext<DataLoaderProps>({
  loadingData: false,
  setLoadingData: noop
})
