import React from 'react'
import { Badge } from '../../../components/indicators_and_messaging/badge'
import { Check } from 'lucide-react'

export const BadgeDemo = () => {
  return <div className="flex flex-col gap-8">
    <div className='flex flex-col gap-4'>
      <div className='text-2xl font-bold text-gray-900'>Definition</div>

      <pre className='bg-gray-100 rounded-lg p-4 overflow-x-auto'><code>
        {codeBlock}
      </code></pre>

      <div className='flex flex-col divide-y divide-dashed divide-gray-200 text-sm'>
        {componentProps.map(x => <div key={x.name} className='grid grid-cols-2 py-2'>
          <div className='font-bold text-gray-900'>{x.name}</div>
          <div>{x.description}</div>
        </div>)}
      </div>
    </div>

    <div className='flex flex-col gap-4'>
      <div className='text-2xl font-bold text-gray-900'>Component</div>
      <div className='flex gap-2'>
        <Badge
          text='My badge'
          color='BLUE'
          icon={Check}
        />
        <Badge text='My badge' color='DARK' />
        <Badge text='My badge' color='GREEN' />
        <Badge text='My badge' color='INDIGO' />
        <Badge text='My badge' color='LIGHT' />
        <Badge text='My badge' color='RED' />
        <Badge text='My badge' color='YELLOW' />
      </div>
    </div>

  </div>
}

const componentProps = [
  { name: 'text', description: 'Primary badge text' },
  { name: 'color', description: 'Primary badge colour' },
  { name: 'icon', description: 'Font awesome icon, if present shows on the left' },
  { name: 'button', description: 'Button to show on the right' }
]

const codeBlock = `<Badge
  text='My badge'
  color='BLUE'
  icon={Check}
/>`
