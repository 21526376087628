import React, { createContext, type ReactNode, useEffect, useReducer, useState, useContext } from 'react'
import { type CompanyPublicInfo } from '../../code/models/company'
import {
  getInitialSurveyState,
  stepHashPrefix,
  type SurveyActionProps,
  SurveyActionType,
  SurveyPagesEnum,
  type SurveyProps,
  surveyReducer
} from '../../code/survey/survey'
import { DataLoaderContext } from './components/data_loader_context'
import { LogoHeader } from './components/logo_header'
import { ProgressBar } from './components/progress_bar'
import { BathroomsStep } from './steps/bathrooms_step'
import { BedroomsStep } from './steps/bedrooms_step'
import { ChooseAddressStep } from './steps/choose_address_step'
import { ConfirmLocationStep } from './steps/confirm_location_step'
import { CurrentHeatingTypeStep } from './steps/current_heating_type_step'
import { EnquiryReasonStep } from './steps/enquiry_reason_step'
import { EPCChangesStep } from './steps/epcchanges_step'
import { EPCFoundStep } from './steps/epcfound_step'
import { FloorAreaStep } from './steps/floor_area_step'
import { GetContactsStep } from './steps/get_contacts_step'
import { GlazingStep } from './steps/glazing_step'
import { PropertyTypeStep } from './steps/property_type_step'
import { LoftInsulationStep } from './steps/loft_insulation_step'
import { ManualAddressStep } from './steps/manual_address_step'
import { NoEPCFoundStep } from './steps/no_epcfound_step'
import { OutdoorSpaceStep } from './steps/outdoor_space_step'
import { ThankYouStep } from './steps/thank_you_step'
import { WallCavityInsulationStep } from './steps/wall_cavity_insulation_step'
import { WallSolidInsulationStep } from './steps/wall_solid_insulation_step'
import { WallTypeStep } from './steps/wall_type_step'
import { ConstructionAgeBandStep } from './steps/construction_age_band_step'
import { BuiltFormStep } from './steps/built_form_step'
import { ScottishGrantStep } from './steps/scottish_grant_step'
import { AdminContext } from '../admin/admin_layout'
import { OfflinePage } from '../admin/offline_page'
import { USER_ROLE_HAS_SURVEY_ACCESS, USER_ROLE_SIMPLE } from '../../code/models/user'
import { RequireRole } from '../../require_role'
import { Loader } from '../../components/indicators_and_messaging/loader'
import { PageHeader } from '../heat_loss/design/components/design_page_header'
import { WrappedIcon } from '../../components/buttons/wrapped_icon'
import { Menu } from 'lucide-react'

// used for reducer
export const SurveyContext = createContext<SurveyProps>(getInitialSurveyState(''))

// @ts-expect-error context should not start with null
export const SurveyDispatchContext = createContext<React.Dispatch<SurveyActionProps>>(null)

// the whole idea of the wrapper is to reload the survey page when the user navigates to the same page
// because react-router renders component only once and doesn't re-render it when the URL changes back and forth
// inside the wrapper we have a key (a special ReactJS attribute) that changes every time the user navigates to the same page
// it solves the SPR-329: https://linear.app/spruce-eco/issue/SPR-329/enquiry-duplicates-information-from-the-previous-enquiry
export const SurveyPageWrapper: React.FC<{ showLogo: boolean, isAdmin: boolean, navigateTo: (url: string) => void, companyPublicInfo: CompanyPublicInfo }> = ({ showLogo, isAdmin, navigateTo, companyPublicInfo }) => {
  const [reloadKey, setReloadKey] = useState('')

  const reload = () => {
    setReloadKey(crypto.randomUUID())
  }

  useEffect(() => {
    reload()
  }, [])

  if (isAdmin) {
    return (
      <RequireRole roles={[USER_ROLE_SIMPLE, USER_ROLE_HAS_SURVEY_ACCESS]}>
        <PageHeader title='New enquiry' isOffline={false} />
        <HeatLossSurveyPage showLogo={false} key={reloadKey} isAdmin={isAdmin} companyPublicInfo={companyPublicInfo} navigateTo={navigateTo} />
      </RequireRole>
    )
  }
  return <HeatLossSurveyPage showLogo={showLogo} key={reloadKey} isAdmin={isAdmin} companyPublicInfo={companyPublicInfo} navigateTo={navigateTo} />
}

export const HeatLossSurveyPage: React.FC<{ showLogo: boolean, isAdmin: boolean, companyPublicInfo: CompanyPublicInfo, navigateTo: (url: string) => void }> = ({ showLogo, isAdmin, companyPublicInfo, navigateTo }) => {
  // source is used to track the source of the survey
  const params = new URLSearchParams(window.location.search)
  const source = params.get('source')
  const adminContext = useContext(AdminContext)

  const [loadingData, setLoadingData] = useState<boolean>(false)
  const [page, setPage] = useState<ReactNode>(null)
  const [hasProgressBar, setHasProgressBar] = useState<boolean>(true)

  const [survey, dispatch] = useReducer(
    surveyReducer,
    source,
    getInitialSurveyState
  )

  if (!companyPublicInfo) return

  // hasProgressBar: we have a progress bar on the top of the survey page,
  //      and we have to show it everywhere except some pages,
  //      so, this setting is used to hide the progress bar on the address lookup pages
  //      Also, moving it to the component state leads to infinitive re-rendering of the component
  //      this is why it's managed here as the function return value
  const selectPage = (): { page: ReactNode, hasProgressBar: boolean } => {
    switch (survey.currentPage) {
      // Address lookup pages
      case SurveyPagesEnum.ChooseAddressStep:
        return { page: <ChooseAddressStep companyPublicInfo={companyPublicInfo} />, hasProgressBar: true }
      case SurveyPagesEnum.ManualAddressStep:
        return { page: <ManualAddressStep companyPublicInfo={companyPublicInfo} />, hasProgressBar: true }
      case SurveyPagesEnum.ConfirmLocationStep:
        return { page: <ConfirmLocationStep />, hasProgressBar: true }
      case SurveyPagesEnum.NoEPCFoundStep:
        return { page: <NoEPCFoundStep companyPublicInfo={companyPublicInfo} />, hasProgressBar: true }
      case SurveyPagesEnum.EPCFoundStep:
        return { page: <EPCFoundStep companyPublicInfo={companyPublicInfo} />, hasProgressBar: true }

      // Survey pages
      case SurveyPagesEnum.PropertyTypeStep:
        return { page: <PropertyTypeStep companyPublicInfo={companyPublicInfo} />, hasProgressBar: true }
      case SurveyPagesEnum.BuiltFormStep:
        return { page: <BuiltFormStep companyPublicInfo={companyPublicInfo} />, hasProgressBar: true }
      case SurveyPagesEnum.OutdoorSpaceStep:
        return { page: <OutdoorSpaceStep companyPublicInfo={companyPublicInfo} />, hasProgressBar: true }
      case SurveyPagesEnum.BedroomsStep:
        return { page: <BedroomsStep companyPublicInfo={companyPublicInfo} />, hasProgressBar: true }
      case SurveyPagesEnum.BathroomsStep:
        return { page: <BathroomsStep companyPublicInfo={companyPublicInfo} />, hasProgressBar: true }
      case SurveyPagesEnum.CurrentHeatingTypeStep:
        return { page: <CurrentHeatingTypeStep companyPublicInfo={companyPublicInfo} />, hasProgressBar: true }
      case SurveyPagesEnum.EPCChangesStep:
        return { page: <EPCChangesStep companyPublicInfo={companyPublicInfo} />, hasProgressBar: true }
      case SurveyPagesEnum.ConstructionAgeBandStep:
        return { page: <ConstructionAgeBandStep companyPublicInfo={companyPublicInfo} />, hasProgressBar: true }
      case SurveyPagesEnum.FloorAreaStep:
        return { page: <FloorAreaStep companyPublicInfo={companyPublicInfo} />, hasProgressBar: true }
      case SurveyPagesEnum.WallTypeStep:
        return { page: <WallTypeStep companyPublicInfo={companyPublicInfo} />, hasProgressBar: true }
      case SurveyPagesEnum.WallCavityInsulationStep:
        return { page: <WallCavityInsulationStep companyPublicInfo={companyPublicInfo} />, hasProgressBar: true }
      case SurveyPagesEnum.WallSolidInsulationStep:
        return { page: <WallSolidInsulationStep companyPublicInfo={companyPublicInfo} />, hasProgressBar: true }
      case SurveyPagesEnum.GlazingStep:
        return { page: <GlazingStep companyPublicInfo={companyPublicInfo} />, hasProgressBar: true }
      case SurveyPagesEnum.LoftInsulationStep:
        return { page: <LoftInsulationStep companyPublicInfo={companyPublicInfo} />, hasProgressBar: true }

      // Final pages with results and contacts
      case SurveyPagesEnum.EnquiryReasonStep:
        return { page: <EnquiryReasonStep companyPublicInfo={companyPublicInfo} />, hasProgressBar: true }
      case SurveyPagesEnum.ScottishGrantStep:
        return { page: <ScottishGrantStep companyPublicInfo={companyPublicInfo} />, hasProgressBar: true }
      case SurveyPagesEnum.GetContactsStep:
        return { page: <GetContactsStep navigateTo={navigateTo} companyPublicInfo={companyPublicInfo} adminEstimate={Boolean(adminContext.data.company)} />, hasProgressBar: true }
      case SurveyPagesEnum.ThankYouStep:
        return { page: <ThankYouStep companyPublicInfo={companyPublicInfo} />, hasProgressBar: true }
      default:
        throw new Error('Unknown page')
    }
  }

  const handleHashChange = () => {
    // Handle hash changes
    const stepString = window.location.hash.substring(stepHashPrefix.length)

    // if last page in the stack is the page in the Hash, then we are going back
    if (survey.pagesStack.length !== 0 && survey.pagesStack[survey.pagesStack.length - 1].page === Number(stepString) as SurveyPagesEnum) {
      dispatch({
        type: SurveyActionType.NavigatePreviousPage
      })
    } else {
      // ignore changes in the URL hash
      // this is going to happen probably if user manually changes the URL

      // FIXME: the code below is for Forward navigation, but it doesn't logically work
      // dispatch({
      //   type: SurveyActionType.NavigateToPageFromParams,
      //   payload: {
      //     page: Number(stepString) as SurveyPagesEnum
      //   }
      // })
    }
  }

  // Listen for hash changes
  React.useEffect(() => {
    // this is going to be called ONLY when user changes URL manually or by clicking on the back button
    window.addEventListener('hashchange', handleHashChange)

    return () => {
      // Clean up the event listener when the component unmounts
      window.removeEventListener('hashchange', handleHashChange)
    }
  }, [])

  // re-render only when the currentPage changes
  useEffect(() => {
    // ensure the URL hash contains the current page
    // save current page to the browser history
    window.location.hash = `${stepHashPrefix}${survey.currentPage}`

    // select the page to render
    const { page, hasProgressBar } = selectPage()
    setPage(page)
    setHasProgressBar(hasProgressBar)
  }, [survey.currentPage])

  if (isAdmin && adminContext.isOffline) {
    return <OfflinePage navigateTo={navigateTo} />
  }

  return (
    // pass the survey state and dispatch to all children
    <SurveyContext.Provider value={survey}>
      <SurveyDispatchContext.Provider value={dispatch}>
        <div className="flex flex-col">
          {/* If we don't have a progress bar, our visual divider is just a border. Otherwise, the divider is the progress bar itself. */}
          <div className={!hasProgressBar ? 'border-solid border-b border-gray-300 ' : ''}>
            <div className='flex items-center px-4'>
              {isAdmin && showLogo && <WrappedIcon className='cursor-pointer text-gray-600 lg:hidden' icon={Menu} onClick={() => adminContext.isSidebarOpen ? adminContext.hideSidebar() : adminContext.showSidebar() } />}
              {showLogo && <LogoHeader companyPublicInfo={companyPublicInfo} />}
            </div>
            {hasProgressBar && <ProgressBar progress={survey.pbValue!} />}
          </div>
          <DataLoaderContext.Provider value={{ loadingData, setLoadingData }}>
            <div className="px-7 py-8 flex-col gap-8 flex max-w-xl mx-auto w-full">
              {loadingData && <Loader />}
              {!loadingData && page}
            </div>
          </DataLoaderContext.Provider>
        </div>
      </SurveyDispatchContext.Provider>
    </SurveyContext.Provider>
  )
}
