import React from 'react'
import { HLRSubheaderBlock } from './partials/hlr_subheader'
import { HLRContext } from './heatloss_report'
import { HLRListBlock } from './partials/hlr_list'
import _ from 'lodash'

export const HLRPEDetailedResultsIntroduction = () => {
  const hlrContext = React.useContext(HLRContext)
  const fuelName = hlrContext!.performanceEstimateSummary.hddEstimate.existing.fuelName
  const numberOfMethods = hlrContext!.performanceEstimateSummary.numberOfMethods
  const estimateGroup = hlrContext!.performanceEstimateSummary.estimatesGroup

  return (
    <div className="flex-col gap-6 flex">
      <HLRSubheaderBlock
        section="Performance estimate"
        title="Detailed results"
      />

      <div className=''>
        The running costs and carbon emissions of a heating system depends on a few key factors, all of which are
        explained in (even) more detail further down this report:
      </div>

      <HLRListBlock items={[
        <><span className='font-bold'>Your home’s heating and hot water demand.</span> This is how much heat your
          home
          needs for heating and hot water in
          the year irrespective of what heating system is used to provide it
        </>,
        <><span className='font-bold'>The efficiency of your system.</span> How efficiently your system makes what
          you
          want (heat) from what you pay for
          (electricity/gas/oil), both in heating and when making hot water.</>,
        <><span className='font-bold'>Energy prices.</span> Heat pump running costs depend on the price of
          electricity, and the comparison with your old
          system depends on the price of {fuelName}.
        </>,
        <><span className='font-bold'>Emission factors.</span>Emission factors show how much carbon dioxide is
          emitted per unit of energy provided by a
          fuel. They are a measure of how “clean” a fuel is.
        </>
      ]}/>

      <div>We’ve factored all of these into our calculations, and you can see in the table on the next page that the
        main
        variables that impact the overall running costs are (1) heating and hot water demand, and (2) energy prices.
      </div>

      <div>Energy prices are quite simple. However, it’s surprisingly hard to work out how much heating and hot water
        your
        home needs because it depends on how often you have the heating on and at what temperature, how much hot water
        you use, and the weather outside.
      </div>

      {numberOfMethods > 1 &&
          <div>Given this uncertainty we have estimated this in {numberOfMethods} different ways to try and give you the
              best understanding:</div>
      }

      <HLRListBlock items={_.compact([
        estimateGroup['Based on EPC'] === undefined ? undefined : <><span className='font-bold'>EPC</span> – Based on
          the heating and hot water demand from your EPC. This assumes your home hasn’t changed since your EPC and uses
          assumptions about your heating and hot water system that don’t apply to a heat pump. If your property has
          changed since your last EPC or that EPC had errors, this might not be the best measure, but we have to include
          it.</>,
        <><span className='font-bold'>Heating Degree Days</span> – Based on the heat loss calculations we have done
          combined with “<a className='underline' href={'https://en.wikipedia.org/wiki/Heating_degree_day'}
          target={'_blank'} rel="noreferrer">heating degree day</a>” data which represents the typical
          annual weather pattern in your area.</>,
        estimateGroup['Based on Last years consumption'] === undefined ? undefined : <><span className='font-bold'>Last year’s consumption</span> –
          Based on your {fuelName} consumption from last year. If nothing has changed about your house or heating
          pattern, this should be a pretty representative estimate.</>
      ])} isNumbered={true}/>

      {estimateGroup['Based on EPC'] === undefined &&
          <div>We would usually also provide an estimate based on your EPC but we can’t do that in your case because a
              representative EPC cannot be obtained for this home because of planned building work.</div>
      }
    </div>
  )
}
