import { type KonvaEventObject } from 'konva/lib/Node'
import { orderBy } from 'lodash'
import React, { type Dispatch, type SetStateAction, useState, useEffect } from 'react'
import { Stage, Layer, Circle, Line, Image, Text } from 'react-konva'
import useImage from 'use-image'
import { type Floor } from '../../../code/models/floor'
import { type MaterialsSet } from '../../../code/models/material'
import { type PropertySurvey } from '../../../code/models/property'
import { type Room } from '../../../code/models/room'
import { type Wall } from '../../../code/models/wall'
import { type CurrentFloorPlanPage } from './floor'
import { type UndoEvent } from './floor_canvas/undo'
import { RoomDrawing } from './room_drawing'
import { gray200, indigo600, red500, white } from './code/constants'
import { removeUndefinedFromList } from '../../../code/helpers'
import { type SprucePoint } from './code/types'
import { Grid } from './grid'
import { SNAPPING_TOLERANCE_PIXELS } from '../constants'
import { type SpruceLine, getTextDimensions } from './line_with_text'

type FloorStageProps = {
  onClick: (evt: KonvaEventObject<Event>) => void
  onTouchEnd: () => void
  onTouchMove: (evt: KonvaEventObject<TouchEvent>) => void
  onWheelProp: (evt: KonvaEventObject<WheelEvent>) => void
  onDragMove: (evt: KonvaEventObject<DragEvent>) => void
  stageScale: number
  stagePosition: SprucePoint
  roomsWithMetaData: any
  currentRoomId: string | undefined
  wallsWithoutDuplicates: Array<{
    x1: number
    x2: number
    y1: number
    y2: number
    uuid: string
  }> | undefined
  floor: Floor
  dragStopped: boolean
  currentWall: Wall | undefined
  setStageScale: Dispatch<SetStateAction<number>>
  setCurrentWallId: Dispatch<SetStateAction<string>>
  setPage: (page: CurrentFloorPlanPage) => void
  setWalls: (walls: Wall[]) => void
  currentRoom: Room | undefined
  setCurrentRoomId: Dispatch<SetStateAction<string>>
  defaultMaterials: MaterialsSet
  setStagePosition: Dispatch<SetStateAction<SprucePoint>>
  isStatic?: boolean
  addEvent: (events: UndoEvent[]) => void
  scalingPoints: SprucePoint[]
  mousePosition: SprucePoint | undefined
  survey: PropertySurvey
  tempImageAndScale: { image: string, scale: number }
  stageRef: React.RefObject<HTMLDivElement> | undefined
  stageSize: { width: number, height: number }
  setStageSize: Dispatch<SetStateAction<{ width: number, height: number }>>
  stageStep: number | undefined
  onMouseMove: (evt: KonvaEventObject<MouseEvent>) => void
  isDrawing: boolean
  intersectingShapes: Array<Array<[number, number]>>
  scalingWindow: boolean
  scalingDoor: boolean
  guidelines: SpruceLine[]
  setGuidelines: Dispatch<SetStateAction<SpruceLine[]>>
  showFloorPlan: boolean
  planOpacity: number
}

export const FloorStage = ({
  onClick,
  onTouchEnd,
  onTouchMove,
  onWheelProp,
  onDragMove,
  stageScale,
  stagePosition,
  stageSize,
  roomsWithMetaData,
  currentRoomId,
  wallsWithoutDuplicates,
  survey,
  floor,
  dragStopped,
  currentWall,
  setStageScale,
  setCurrentWallId,
  setPage,
  setWalls,
  currentRoom,
  setCurrentRoomId,
  defaultMaterials,
  isStatic = false,
  setStagePosition,
  addEvent,
  scalingPoints,
  mousePosition,
  tempImageAndScale,
  stageRef,
  setStageSize,
  stageStep,
  onMouseMove,
  isDrawing,
  intersectingShapes,
  scalingWindow,
  scalingDoor,
  guidelines,
  setGuidelines,
  showFloorPlan,
  planOpacity
}: FloorStageProps) => {
  const [image, status] = useImage((floor.floor_plan_image || `${process.env.S3_BUCKET_URL}/${floor.floor_plan_url}`) ?? '', 'anonymous')
  const [tempImage] = useImage(tempImageAndScale.image)
  const [touches, setTouches] = useState(0)

  useEffect(() => {
    const currentWidth = stageRef?.current?.clientWidth ?? 0
    const currentHeight = stageRef?.current?.clientHeight ?? 0
    setStageSize({
      width: currentWidth,
      height: currentHeight
    })
    setStagePosition({
      x: currentWidth ? currentWidth / 2 : 0,
      y: currentHeight ? currentHeight / 2 : 0
    })

    const handleResize = () => {
      setStageSize({
        width: stageRef?.current?.clientWidth ?? 0,
        height: stageRef?.current?.clientHeight ?? 0
      })
    }

    const resizeObserver = new ResizeObserver(handleResize)

    if (stageRef?.current) {
      resizeObserver.observe(stageRef?.current)
    }

    handleResize()

    return () => {
      if (stageRef?.current) {
        resizeObserver.unobserve(stageRef?.current)
      }
    }
  }, [])

  const isCreatingPoints = stageStep === 1 || stageStep === 2
  const cursorType = isCreatingPoints || isDrawing
    ? 'cursor-pointer'
    : 'cursor-move'

  // If we are scaling an image, once we have 2 points we don't want to show the onHover point or line anymore.
  const trueScalingPoints = [...scalingPoints.map(sp => [sp.x * stageScale, sp.y * stageScale]).flat()]
  const trueMousePosition = mousePosition ? [mousePosition.x * stageScale, mousePosition.y * stageScale] : []
  const scalingLinesWithMouse = !stageStep || stageStep === 1 || stageStep === 2
    ? [...trueScalingPoints, ...trueMousePosition]
    : trueScalingPoints

  const scalingPointsWithMouse = !stageStep || stageStep === 1 || stageStep === 2
    ? [mousePosition ? { x: mousePosition.x * stageScale, y: mousePosition.y * stageScale } : undefined, ...scalingPoints.map(sp => ({ x: sp.x * stageScale, y: sp.y * stageScale }))]
    : scalingPoints.map(sp => ({ x: sp.x * stageScale, y: sp.y * stageScale }))

  const historicalOffset = survey.old_unit_vector
    ? (((475) * 20) * stageScale) + (500 * stageScale)
    : 0

  const imageSyncingText = 'Image syncing, please wait...'
  const imageSyncingTextDimensions = getTextDimensions(imageSyncingText, false, 50)

  // onTap fires on mobile, when clicking shape the onTap also calls onTap event on stage below shape.
  // onClick fires on mobile, but only the first event target e.g. clicking a shape will not call the onClick stage below.
  // We cannot call onClick and onTap as it would double up points when drawing rooms.
  // We must set listening=false on all shapes when drawing so the onClick can successfully fire on the stage.
  return <Stage
    hitOnDragEnabled={true}
    className={`bg-gray-100 ${isStatic ? '' : 'fixed'} ${cursorType} flex`}
    onClick={onClick}
    onTouchEnd={() => {
      onTouchEnd()
      setTouches(0)
    }}
    onTouchMove={onTouchMove}
    onMouseMove={onMouseMove}
    onTouchStart={(e) => setTouches(e.evt.touches?.length)}
    x={stagePosition.x}
    y={stagePosition.y}
    draggable={!isStatic}
    width={stageSize.width}
    height={stageSize.height}
    onWheel={onWheelProp}
    onDragMove={onDragMove}
  >
    <Layer>
      <Grid size='MM' scale={stageScale} position={stagePosition} stageHeight={stageSize.height} stageWidth={stageSize.width} />
      <Grid size='CM' scale={stageScale} position={stagePosition} stageHeight={stageSize.height} stageWidth={stageSize.width} />
      <Grid size='M' scale={stageScale} position={stagePosition} stageHeight={stageSize.height} stageWidth={stageSize.width} />
    </Layer>
    <Layer>
      {tempImage && <Image
        x={-((tempImage.width * stageScale * tempImageAndScale.scale) / 2)}
        y={-((tempImage.height * stageScale * tempImageAndScale.scale) / 2)}
        opacity={0.8}
        image={tempImage}
        width={tempImage.width * stageScale}
        height={tempImage.height * stageScale}
        scale={{ x: tempImageAndScale.scale, y: tempImageAndScale.scale }}
      />}
      {status === 'loading' && <Text
        text={imageSyncingText}
        fontSize={50}
        fill={gray200}
        offsetX={imageSyncingTextDimensions.width / 2}
        offsetY={-50}
      />}
      {showFloorPlan && image && !tempImage && floor.floor_plan_is_showing && <Image
        listening={false}
        x={historicalOffset - ((image.width * stageScale * floor.floor_plan_scale) / 2)}
        y={historicalOffset - ((image.height * stageScale * floor.floor_plan_scale) / 2)}
        opacity={planOpacity}
        image={image}
        width={image.width * stageScale}
        height={image.height * stageScale}
        scale={{ x: floor.floor_plan_scale, y: floor.floor_plan_scale }}
      />}
      {!stageStep && !scalingWindow && !scalingDoor && orderBy(roomsWithMetaData, x => x.uuid === currentRoomId ? 1 : 0).map(x => {
        return <RoomDrawing
          touches={touches}
          wallsWithoutDupes={wallsWithoutDuplicates}
          key={x.uuid}
          room={x}
          floor={floor}
          dragStopped={dragStopped}
          currentWall={currentWall}
          stageScale={stageScale}
          setStageScale={setStageScale}
          setCurrentWallId={setCurrentWallId}
          setPage={setPage}
          setWalls={setWalls}
          currentRoom={currentRoom}
          currentRoomId={currentRoomId!}
          setCurrentRoomId={setCurrentRoomId}
          defaultMaterials={defaultMaterials}
          setStagePosition={setStagePosition}
          addEvent={addEvent}
          isDrawing={isDrawing}
          setGuidelines={setGuidelines}
          snappingTolerancePixels={SNAPPING_TOLERANCE_PIXELS}
        />
      })}
      {scalingPoints.length > 0 && <Line
        points={removeUndefinedFromList(scalingLinesWithMouse)}
        strokeWidth={2}
        stroke={indigo600}
        listening={false}
      />}
      {removeUndefinedFromList(scalingPointsWithMouse).map((sp, i) => <Circle
        key={i}
        x={sp.x}
        y={sp.y}
        radius={5}
        stroke={indigo600}
        fill={i === scalingPoints.length ? indigo600 : white}
        listening={false}
      />)}
      {/* Stroke causes "Shape width or length is 0 error" if coming out of canvas and back in, avoid adding stroke unelss fixed in future versions of Konva. */}
      {intersectingShapes.map((x, i) => <Line
        key={i}
        points={x.flat().map(x => x * stageScale)}
        opacity={0.2}
        fill={red500}
        listening={false}
        closed={true} />)}
      {guidelines.map((x, i) => <Line listening={false} key={i} points={[x.p1.x, x.p1.y, x.p2.x, x.p2.y]} strokeWidth={1} stroke={indigo600} dash={[10, 10]} />)}
    </Layer>
  </Stage>
}
