import React from 'react'

type SliderProps = {
  value: number
  onChange: (value: number) => void
  min: number
  max: number
  step?: number
  className?: string
}
export const Slider = ({ value, min, max, onChange, className, step = 1 }: SliderProps) => {
  return <input
    type="range"
    min={min}
    max={max}
    step={step}
    value={value}
    onChange={(e) => onChange(Number(e.currentTarget.value))}
    className={`w-full h-2 my-2 bg-gray-200 rounded-lg appearance-none cursor-pointer accent-gray-900 ${className}`}
  />
}
