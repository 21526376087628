import React from 'react'
import { type Company } from '../../../../code/models/company'
import { SendEstimateEmailTemplateBlock } from './blocks/send_estimate_email_template_block'
import { SettingsLayout } from '../components/settings_layout'

export const EmailTemplatesSendEstimate = ({ company, setCompany }: { company: Company, setCompany: (c: Company) => void }) => {
  return <SettingsLayout
    heading='Send Estimate'
    description='Customise the estimate email sent to your customers'
  >
    <SendEstimateEmailTemplateBlock company={company} setCompany={setCompany} />
  </SettingsLayout>
}
