import React from 'react'
import { Heading } from '../../../../components/content_display/heading'

type Props = {
  section?: string
  title: string
  description?: string
}

export const HLRSubheaderBlock = ({ section, title, description }: Props) => {
  return (<>
    <div className='flex flex-col gap-1'>
      { section && <div className="text-neutral-500 text-xs font-semibold uppercase tracking-wide">{ section }</div> }
      <Heading size={'3xl'}>{ title }</Heading>
    </div>
    { description && <div className="">{ description }</div> }
  </>
  )
}
