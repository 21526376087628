import React from 'react'

type HeaderProps = {
  content: string
  className?: string
}

export const Header: React.FC<HeaderProps> = ({ content, className = '' }) => {
  // TODO: header text does not fit design
  return <div className={`${className} text-gray-900 text-2xl font-bold`}>{content}</div>
}
