import { type InventoryItemBase } from './models/inventory'
import { roundTo2dp } from './number_format'

export const priceCalculations = {
  calculateCustomerPrice: (costPrice: number, markup: number) => {
    return roundTo2dp(costPrice * (1 + markup / 100))
  },

  calculateCostPrice: (customerPrice: number, markup: number) => {
    return roundTo2dp(customerPrice / (1 + markup / 100))
  },

  updateCostPrice: <T extends InventoryItemBase>(item: T, newCostPrice: number): T => {
    return { ...item, cost_price: roundTo2dp(newCostPrice) }
  },

  updateMarkup: <T extends InventoryItemBase>(item: T, newMarkup: number): T => {
    return { ...item, markup: roundTo2dp(newMarkup) }
  },

  updateCustomerPrice: <T extends InventoryItemBase>(part: T, newCustomerPrice: number): T => {
    const newCostPrice = priceCalculations.calculateCostPrice(newCustomerPrice, part.markup)
    return { ...part, cost_price: roundTo2dp(newCostPrice) }
  },

  updateCostPriceAndMarkupToMatchCustomerPrice: <T extends InventoryItemBase>(part: T, newCustomerPrice: number): T => {
    return { ...part, cost_price: newCustomerPrice, markup: 0 }
  }
}
