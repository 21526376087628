import React, { useContext, useState } from 'react'
import { Section } from '../../components/section'
import { type Lead } from '../../../../../code/models/lead'
import { Text } from '../../../../../components/content_display/text'
import { formatPrice } from '../../../../../code/format_price'
import { Select } from '../../../../../components/inputs_and_selections/select'
import { type CompanyPublicInfo } from '../../../../../code/models/company'
import { Button } from '../../../../../components/buttons/button'
import { Icon } from '../../../../../components/buttons/icon'
import { DEFAULT_ESTIMATE_RAD_CHANGE_PERCENTAGE, type Estimate } from '../../../../../code/calculate_estimate'
import { Alert } from '../../../../../components/indicators_and_messaging/alert'
import { Badge } from '../../../../../components/indicators_and_messaging/badge'
import { InventoryTable, type InventoryTableColumn } from '../../../costs_and_inventory/components/inventory_table'
import { groupBy, sum } from 'lodash'
import { CalculatedQuoteDefaultGroups, calculateQuote, type QuoteItem, QuoteOverrideType } from '../../../../../code/calculate_quote'
import { Input } from '../../../../../components/inputs_and_selections/input'
import { Toggle } from '../../../../../components/inputs_and_selections/toggle'
import { bulkDeleteEstimateQuoteItems, bulkInsertEstimateQuoteItems, deleteEstimateQuoteItem, insertEstimateQuoteItem, updateEstimateQuoteItem } from '../../../../../code/models/estimate_quote_item'
import { priceCalculations } from '../../../../../code/price_calculations'
import { AdminContext } from '../../../admin_layout'
import { getApproxNumberOfRadiatorChanges } from '../../../../../code/models/estimated_rooms_and_radiators'
import { AlertTriangle, RotateCw, XCircle } from 'lucide-react'

type Props = {
  lead: Lead
  setLead: (lead: Lead) => void
  estimate: Estimate
  companyPublicInfo: CompanyPublicInfo
}

export const CostBreakdown = ({ lead, setLead, estimate, companyPublicInfo }: Props) => {
  const [selectedLineItemUUID, setSelectedLineItemUUID] = useState<string | undefined>(undefined)
  const adminContext = useContext(AdminContext)

  const [calculatedQuote, quoteOverrideType] = calculateQuote({
    company: adminContext.data.company!,
    selectedHeatPump: estimate.heatPump,
    selectedHotWaterCylinder: estimate.hotWaterCylinder,
    defaultRadiatorChanges: getApproxNumberOfRadiatorChanges(lead, adminContext.data.company?.estimate_default_radiator_change_percentage ?? DEFAULT_ESTIMATE_RAD_CHANGE_PERCENTAGE),
    parts: adminContext.data.parts!,
    labour: adminContext.data.labour!,
    packs: adminContext.data.packs!,
    isScottish: estimate.isScottish,
    override: lead.estimate_quote_items || undefined
  })
  const groupedCalculatedQuote = groupBy(calculatedQuote, 'group_name')

  const quoteOverridden = quoteOverrideType === QuoteOverrideType.FULL || quoteOverrideType === QuoteOverrideType.PARTIAL

  const createQuoteOverrideWithUpdatedItem = async (item: QuoteItem) => {
    const updatedPayload = calculatedQuote.map(x => x.uuid === item.uuid ? item : x).map(x => ({ ...x, uuid: crypto.randomUUID() }))
    setLead({ ...lead, estimate_quote_items: updatedPayload })
    await bulkInsertEstimateQuoteItems(updatedPayload, lead.uuid!, companyPublicInfo.uuid)
  }

  const createQuoteOverrideWithNewItem = async (item: QuoteItem) => {
    const updatedPayload = [...calculatedQuote, item].map(x => ({ ...x, uuid: crypto.randomUUID() }))
    setLead({ ...lead, estimate_quote_items: updatedPayload })
    await bulkInsertEstimateQuoteItems(updatedPayload, lead.uuid!, companyPublicInfo.uuid)
  }

  const createQuoteOverrideWithoutItem = async (item: QuoteItem) => {
    const updatedPayload = calculatedQuote.filter(x => x.uuid !== item.uuid).map(x => ({ ...x, uuid: crypto.randomUUID() }))
    setLead({ ...lead, estimate_quote_items: updatedPayload })
    await bulkInsertEstimateQuoteItems(updatedPayload, lead.uuid!, companyPublicInfo.uuid)
  }

  const handleUpdateItem = async (item: QuoteItem) => {
    // Is the quote overridden?
    if (quoteOverridden) {
      // Just update this specific item
      await updateEstimateQuoteItem(item, lead.uuid!, companyPublicInfo.uuid)
      return setLead({ ...lead, estimate_quote_items: lead.estimate_quote_items!.map(x => x.uuid === item.uuid ? item : x) })
    }
    // Otherwise, create a new quote override, which also updates the state
    await createQuoteOverrideWithUpdatedItem(item)
  }

  const handleAddItem = async (item: QuoteItem) => {
    // Is the quote overridden?
    if (quoteOverridden) {
      // Create a new item
      await insertEstimateQuoteItem(item, lead.uuid!, companyPublicInfo.uuid)
      return setLead({ ...lead, estimate_quote_items: [...lead.estimate_quote_items!, item] })
    }
    // Otherwise, create a new quote override, which also updates the state
    await createQuoteOverrideWithNewItem(item)
  }

  const handleDeleteItem = async (item: QuoteItem) => {
    // Is the quote overridden?
    if (quoteOverridden) {
      // Just delete this specific item
      await deleteEstimateQuoteItem(item.uuid!, lead.uuid!, companyPublicInfo.uuid)
      return setLead({ ...lead, estimate_quote_items: lead.estimate_quote_items!.filter(x => x.uuid !== item.uuid) })
    }
    // Otherwise, create a new quote override, which also updates the state
    await createQuoteOverrideWithoutItem(item)
  }

  const handleResetToDefault = async () => {
    setLead({ ...lead, estimate_quote_items: [] })
    await bulkDeleteEstimateQuoteItems(lead.uuid!, companyPublicInfo.uuid)
  }

  const addableItems = [
    { uuid: 'radiator', name: 'Radiator', cost_price: adminContext.data.company!.default_radiator_cost || 0, deleted_at: null },
    { uuid: 'underfloor', name: 'Underfloor heating', cost_price: adminContext.data.company!.default_underfloor_cost || 0, deleted_at: null },
    ...adminContext.data.parts!,
    ...adminContext.data.labour!,
    { uuid: 'survey', name: 'Survey', cost_price: Math.round((adminContext.data.company!.survey_cost ?? 0) * 1.2), deleted_at: null }
  ]
    .map(x => !x.deleted_at ? { key: x.uuid, value: `${x.name} - £${x.cost_price}` } : undefined)
    .filter(Boolean) as Array<{ key: string, value: string }>

  const handleAddAdditionalItem = async (key: string) => {
    if (!key) return
    if (key === 'radiator' || key === 'underfloor') {
      const item = {
        uuid: crypto.randomUUID(),
        lead_uuid: lead.uuid!,
        group_name: key === 'radiator' ? CalculatedQuoteDefaultGroups.RADIATORS : CalculatedQuoteDefaultGroups.UNDERFLOOR,
        name: key === 'radiator' ? 'Radiator' : 'Underfloor heating',
        price: key === 'radiator' ? adminContext.data.company!.default_radiator_cost || 0 : adminContext.data.company!.default_underfloor_cost || 0,
        quantity: 1,
        include_vat: false,
        selected: true
      }
      if (lead.estimate_quote_items?.find(x => x.name === item.name)) {
        const existingItem = lead.estimate_quote_items.find(x => x.name === item.name)!
        return await handleUpdateItem({ ...existingItem, quantity: existingItem.quantity + 1 })
      }
      await handleAddItem(item)
    } else if (key === 'survey') {
      const survey = {
        group_name: CalculatedQuoteDefaultGroups.SURVEY,
        uuid: crypto.randomUUID(),
        lead_uuid: lead.uuid!,
        name: 'Survey',
        price: Math.round((adminContext.data.company!.survey_cost ?? 0) * 1.2),
        quantity: 1,
        include_vat: true,
        selected: true
      }
      if (lead.estimate_quote_items?.find(x => x.name === survey.name)) {
        const existingItem = lead.estimate_quote_items.find(x => x.name === survey.name)!
        return await handleUpdateItem({ ...existingItem, quantity: existingItem.quantity + 1 })
      }
      await handleAddItem(survey)
    } else if (adminContext.data.parts!.find(x => x.uuid === key)) {
      const item = adminContext.data.parts!.find(x => x.uuid === key)
      if (!item) return
      // Does this part already exist in the quote?
      if (lead.estimate_quote_items?.find(x => x.name === item.name)) {
        // If so, just increment the quantity
        const existingItem = lead.estimate_quote_items.find(x => x.name === item.name)!
        return await handleUpdateItem({ ...existingItem, quantity: existingItem.quantity + 1 })
      }
      // Otherwise, add a new item with a quantity of 1
      await handleAddItem({
        uuid: crypto.randomUUID(),
        lead_uuid: lead.uuid!,
        group_name: CalculatedQuoteDefaultGroups.PARTS,
        name: item.name,
        description: item.description || '',
        price: priceCalculations.calculateCustomerPrice(item.cost_price, item.markup || 0),
        quantity: 1,
        include_vat: false,
        selected: true
      })
    } else {
      const item = adminContext.data.labour!.find(x => x.uuid === key)
      if (!item) return
      // Does this labour item already exist in the quote?
      if (lead.estimate_quote_items?.find(x => x.name === item.name)) {
        // If so, just increment the quantity
        const existingItem = lead.estimate_quote_items.find(x => x.name === item.name)!
        return await handleUpdateItem({ ...existingItem, quantity: existingItem.quantity + 1 })
      }
      // Otherwise, add a new item with a quantity of 1
      await handleAddItem({
        uuid: crypto.randomUUID(),
        lead_uuid: lead.uuid!,
        group_name: CalculatedQuoteDefaultGroups.LABOUR,
        name: item.name,
        description: item.description || '',
        price: item.cost_price,
        quantity: item.days,
        include_vat: false,
        selected: true
      })
    }

    setSelectedLineItemUUID(undefined)
  }

  const costBreakdownColumns: Array<InventoryTableColumn<{ item: React.ReactNode, quantity: React.ReactNode, price: React.ReactNode, total: React.ReactNode, menu: React.ReactNode }>> = [
    { key: 'item', name: 'Item' },
    { key: 'quantity', name: 'Quantity' },
    { key: 'price', name: 'Price' },
    { key: 'total', name: 'Total', align: 'right' },
    { key: 'menu', name: '', align: 'right' }
  ]

  const quoteHeatPump = groupedCalculatedQuote[CalculatedQuoteDefaultGroups.HEAT_PUMPS]?.[0]
  const quoteHotWaterCylinder = groupedCalculatedQuote[CalculatedQuoteDefaultGroups.HOT_WATER_CYLINDERS]?.[0]

  const costBreakdownRows = [
    ...(quoteHeatPump ? [{
      item: <>
        <Text bold={true}>{quoteHeatPump.name || <Badge color="RED" text="No heat pump selected" icon={AlertTriangle} />}</Text>
        {quoteHeatPump.description && <Text size="SM">{quoteHeatPump.description}</Text>}
      </>,
      quantity: <Input type="number" value={quoteHeatPump.quantity} setValue={(value) => handleUpdateItem({ ...quoteHeatPump, quantity: parseInt(value) })} />,
      price: <Input type="number" value={quoteHeatPump.price} setValue={(value) => handleUpdateItem({ ...quoteHeatPump, price: parseInt(value) })} prefix="£" />,
      total: formatPrice(quoteHeatPump.subtotal),
      menu: null
    }] : []),
    ...(quoteHotWaterCylinder ? [{
      item: <>
        <Text bold={true}>{quoteHotWaterCylinder.name || <Badge color="RED" text="No hot water cylinder selected" icon={AlertTriangle} />}</Text>
        {quoteHotWaterCylinder.description && <Text size="SM">{quoteHotWaterCylinder.description}</Text>}
      </>,
      quantity: <Input type="number" value={quoteHotWaterCylinder.quantity} setValue={(value) => handleUpdateItem({ ...quoteHotWaterCylinder, quantity: parseInt(value) })} />,
      price: <Input type="number" value={quoteHotWaterCylinder.price} setValue={(value) => handleUpdateItem({ ...quoteHotWaterCylinder, price: parseInt(value) })} prefix="£" />,
      total: formatPrice(quoteHotWaterCylinder.subtotal),
      menu: <Icon icon={XCircle} onClick={() => handleDeleteItem(quoteHotWaterCylinder)} confirmTextHeader='Delete quote item' confirmTextBody='Are you sure you want to remove this item from the quote?' />
    }] : []),
    ...groupedCalculatedQuote[CalculatedQuoteDefaultGroups.PARTS]?.map(part => ({
      item: <>
        <Text bold={true}>{part.name}</Text>
        {part.description && <Text size="SM">{part.description}</Text>}
      </>,
      quantity: <Input type="number" value={part.quantity} setValue={(value) => handleUpdateItem({ ...part, quantity: parseInt(value) })} />,
      price: <Input type="number" value={part.price} setValue={(value) => handleUpdateItem({ ...part, price: parseInt(value) })} prefix="£" />,
      total: formatPrice(part.subtotal),
      menu: <Icon icon={XCircle} onClick={() => handleDeleteItem(part)} confirmTextHeader='Delete quote item' confirmTextBody='Are you sure you want to remove this item from the quote?' />
    })) ?? [],
    ...groupedCalculatedQuote[CalculatedQuoteDefaultGroups.RADIATORS]?.map(radiator => ({
      item: <Text bold={true}>{radiator.name}</Text>,
      quantity: <Input type="number" value={radiator.quantity} setValue={(value) => handleUpdateItem({ ...radiator, quantity: parseInt(value) })} />,
      price: <Input type="number" value={radiator.price} setValue={(value) => handleUpdateItem({ ...radiator, price: parseInt(value) })} prefix="£" />,
      total: formatPrice(radiator.subtotal),
      menu: <Icon icon={XCircle} onClick={() => handleDeleteItem(radiator)} confirmTextHeader='Delete quote item' confirmTextBody='Are you sure you want to remove this item from the quote?' />
    })) ?? [],
    ...groupedCalculatedQuote[CalculatedQuoteDefaultGroups.UNDERFLOOR]?.map(underfloor => ({
      item: <Text bold={true}>{underfloor.name}</Text>,
      quantity: <Input type="number" value={underfloor.quantity} setValue={(value) => handleUpdateItem({ ...underfloor, quantity: parseInt(value) })} postfix={'m²'} />,
      price: <Input type="number" value={underfloor.price} setValue={(value) => handleUpdateItem({ ...underfloor, price: parseInt(value) })} prefix="£" />,
      total: formatPrice(underfloor.subtotal),
      menu: <Icon icon={XCircle} onClick={() => handleDeleteItem(underfloor)} confirmTextHeader='Delete quote item' confirmTextBody='Are you sure you want to remove this item from the quote?' />
    })) ?? [],
    ...groupedCalculatedQuote[CalculatedQuoteDefaultGroups.LABOUR]?.map(labour => ({
      item: <>
        <Text bold={true}>{labour.name}</Text>
        {labour.description && <Text size="SM">{labour.description}</Text>}
      </>,
      quantity: <Input type="number" value={labour.quantity} setValue={(value) => handleUpdateItem({ ...labour, quantity: parseInt(value) })} postfix={'days'} />,
      price: <Input type="number" value={labour.price} setValue={(value) => handleUpdateItem({ ...labour, price: parseInt(value) })} prefix="£" />,
      total: formatPrice(labour.subtotal),
      menu: <Icon icon={XCircle} onClick={() => handleDeleteItem(labour)} confirmTextHeader='Delete quote item' confirmTextBody='Are you sure you want to remove this item from the quote?' />
    })) ?? [],
    ...groupedCalculatedQuote[CalculatedQuoteDefaultGroups.SURVEY]?.map(survey => ({
      item: <Text bold={true}>Survey</Text>,
      quantity: '',
      price: <Input type="number" value={survey.price} setValue={(value) => handleUpdateItem({ ...survey, price: parseInt(value) })} prefix="£" />,
      total: formatPrice(survey.subtotal),
      menu: <Icon icon={XCircle} onClick={() => handleDeleteItem(survey)} confirmTextHeader='Delete quote item' confirmTextBody='Are you sure you want to remove this item from the quote?' />
    })) ?? []
  ]

  const grantsBreakdownColumns: Array<InventoryTableColumn<{ description: React.ReactNode, apply: React.ReactNode, price: React.ReactNode }>> = [
    { key: 'description', name: 'Description' },
    { key: 'apply', name: 'Apply to estimate' },
    { key: 'price', name: 'Price' }
  ]

  const grantsBreakdownRows = [
    ...(groupedCalculatedQuote[CalculatedQuoteDefaultGroups.GRANTS]?.map(grant => ({
      description: <>
        <Text bold={true}>{grant.name}</Text>
        {grant.description && <Text size="SM">{grant.description}</Text>}
      </>,
      apply: <Toggle value={!!grant.selected} setValue={() => handleUpdateItem({ ...grant, selected: !grant.selected })} />,
      price: formatPrice(grant.price)
    })) ?? [])
  ]

  return (
    <>
      <Section title='Cost breakdown' controls={<Button size="SM" iconLeft={RotateCw} colour='LIGHT' disabled={!quoteOverridden} onClick={handleResetToDefault} confirmText="This will clear any changes you've made and revert back to defaults.">Reset to defaults</Button>}>

        {quoteOverridden && <Alert type="INFO">
          This cost estimate has been manually overridden. It will not update automatically based on changes to the selected heat pump and hot water cylinder, or your costs and inventory. Reset to default to revert to automatic calculation.
        </Alert>}

        <InventoryTable
          columns={costBreakdownColumns}
          rows={costBreakdownRows}
        />

        {/* Add new item form */}
        <div className="flex flex-col lg:flex-row lg:items-center lg:justify-between gap-4">
          <Select
            placeholder='Add item'
            dataCy="additional_items_dropdown"
            setSelectedKey={handleAddAdditionalItem}
            selectedKey={selectedLineItemUUID}
            options={addableItems}
            filter={true}
          />
          {/* Total */}
          <div className="flex items-center flex-1">
            <Text bold={true} className="whitespace-nowrap">Total before deductions:</Text>
            <Text bold={true} className="ml-2">{formatPrice(sum(calculatedQuote.map(x => x.selected ? x.subtotal : 0)) - sum(groupedCalculatedQuote[CalculatedQuoteDefaultGroups.GRANTS]?.map(x => x.selected ? x.price : 0)))}</Text>
          </div>
        </div>

      </Section>

      {grantsBreakdownRows.length > 0 &&
        <Section title='Grants'>
          <InventoryTable
            columns={grantsBreakdownColumns}
            rows={grantsBreakdownRows}
          />
          <div className="flex items-center justify-end gap-4">
            <div className="flex items-center">
              <Text bold={true}>Total after deductions:</Text>
              <Text bold={true} className="ml-2">{formatPrice(sum(calculatedQuote.map(x => x.selected ? x.subtotal : 0)))}</Text>
            </div>
          </div>
        </Section>
      }
    </>
  )
}
