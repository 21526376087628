import { Check } from 'lucide-react'
import React from 'react'

const styles = {
  unchecked: 'bg-white border border-gray-400 text-white',
  checked: 'bg-gray-900 border border-gray-900 text-white'
}

export type CheckboxProps = {
  checked: boolean
  setChecked: () => void
}
export const Checkbox = ({ checked, setChecked }: CheckboxProps) => {
  return (
    <div
      data-cy="checkbox"
      className={`cursor-pointer w-5 h-5 rounded ${checked ? styles.checked : styles.unchecked} flex justify-center items-center`}
      onClick={setChecked}
    >
      <Check className='w-4 h-4 stroke-2' />
    </div>
  )
}
