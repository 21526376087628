import React, { useContext } from 'react'
import { SurveyActionType } from '../../../code/survey/survey'
import { getAddressIncludingPostcode } from '../../../code/models/lead'
import { Button } from '../../../components/buttons/button'
import { ChevronRightIcon } from 'lucide-react'
import { type CompanyPublicInfo } from '../../../code/models/company'
import { logEvent } from '../../../code/log_event'
import { ColoredBlockWrapper } from '../components/colored_block_wrapper'
import { StepHeader } from '../components/steps_header'
import { SubHeader } from '../components/sub_header'
import { SurveyContext, SurveyDispatchContext } from '../survey_page'
import { Caption } from '../components/caption_1'
import { formatDate } from '../../../code/format_date'

export const EPCFoundStep = ({ companyPublicInfo }: { companyPublicInfo: CompanyPublicInfo }) => {
  // survey context and dispatch
  const survey = useContext(SurveyContext)
  const dispatch = useContext(SurveyDispatchContext)

  const handleNavigateNextPage = () => {
    logEvent({ name: 'EPC found step completed', properties: {} }, companyPublicInfo.subdomain)
    dispatch({
      type: SurveyActionType.NavigateNextPage
    })
  }

  return <>
    {/* Header */}
    <StepHeader
      guideText={getAddressIncludingPostcode(survey.lead)}
      headerText="We have found an Energy Performance Certificate (EPC) for your home"
    />

    {/* EPC details table */}
    <div className="flex flex-col gap-y-2 divide-gray-200 divide-y">
      <div className="grid grid-cols-2 gap-x-2 pt-2">
        <div className="text-gray-900 font-bold">Energy rating</div>
        <div className="text-gray-600">{survey.lead.epcData?.currentEnergyRating ?? survey.lead.epc_scotland?.current_energy_rating}</div>
      </div>
      <div className="grid grid-cols-2 gap-x-2 pt-2">
        <div className="text-gray-900 font-bold">Date of EPC</div>
        <div className="text-gray-600">{formatDate(survey.lead.epcData?.inspectionDate ?? survey.lead.epc_scotland?.inspection_date, true)}</div>
      </div>
      <div className="grid grid-cols-2 gap-x-2 pt-2">
        <div className="text-gray-900 font-bold">Property type</div>
        <div className="text-gray-600">{survey.lead.property.propertyType}</div>
      </div>
      <div className="grid grid-cols-2 gap-x-2 pt-2">
        <div className="text-gray-900 font-bold">Floor area</div>
        <div className="text-gray-600">{survey.lead.property.floorArea} square metres</div>
      </div>
    </div>

    {/* Data disclaimer */}
    <ColoredBlockWrapper color="gray">
      <div className="flex flex-col gap-y-4">
        <div className="flex flex-col gap-y-2">
          <SubHeader content="We've used the data from your EPC to pre-fill the following steps" />
          <Caption size="sm" content="Please correct anything that doesn't look right in the following steps." />
        </div>
        <Button size='LG' onClick={handleNavigateNextPage}>
          <div className="flex items-center gap-x-2">
            <div>Check property details</div>
            <ChevronRightIcon className="h-6 w-6" />
          </div>
        </Button>
      </div>
    </ColoredBlockWrapper>
  </>
}
