import React, { useContext, useState } from 'react'
import { SurveyActionType } from '../../../code/survey/survey'
import { Button } from '../../../components/buttons/button'
import { type CompanyPublicInfo } from '../../../code/models/company'
import { logEvent } from '../../../code/log_event'
import { getAddressIncludingPostcode } from '../../../code/models/lead'
import { SurveyOptionGroup } from '../../../components/inputs_and_selections/survey_option_group'
import { StepHeader } from '../components/steps_header'
import { SurveyContext, SurveyDispatchContext } from '../survey_page'
import { SubHeader } from '../components/sub_header'
import { ArrowLeftIcon, ChevronRightIcon } from 'lucide-react'

export const ScottishGrantStep = ({ companyPublicInfo }: { companyPublicInfo: CompanyPublicInfo }) => {
  // survey context and dispatch
  const survey = useContext(SurveyContext)
  const dispatch = useContext(SurveyDispatchContext)

  const [selectedIsInTouchOption, setSelectedIsInTouchOption] = useState<string>()
  const [selectedReasonOption, setSelectedReasonOption] = useState<string>()

  const handleNavigatePreviousPage = () => {
    dispatch({
      type: SurveyActionType.NavigatePreviousPage
    })
  }

  const handleNavigateNextPage = () => {
    logEvent({ name: 'Home Energy Scotland Step Completed', properties: {} }, companyPublicInfo.subdomain)

    dispatch({
      type: SurveyActionType.NavigateNextPage,
      payload: {
        scottishGrantIsInTouch: selectedIsInTouchOption,
        scottishGrantReason: selectedReasonOption
      }
    })
  }

  const NoKey = 'No'
  const YesKey = 'Yes'

  function buildOption (value: string) {
    return { key: value, label: value }
  }

  return <>
    {/* Back button */}
    <Button colour='LIGHT' onClick={handleNavigatePreviousPage} block={false} className="self-start">
      <div className="flex items-center gap-x-2">
        <ArrowLeftIcon className="h-4 w-4" />
        <div>Back</div>
      </div>
    </Button>

    {/* Header */}
    <StepHeader
      guideText={getAddressIncludingPostcode(survey.lead)}
      headerText="Have you been in touch with Home Energy Scotland?"
    />

    {/* First layer options */}
    {/* TODO later: add an enum type for this or just conver to boolean. Update the schema for the scottishGrantIsInTouch attribute at the same time */}
    <SurveyOptionGroup options={[
      { key: NoKey, label: 'No' },
      { key: YesKey, label: 'Yes' }
    ]} onSelect={(key: string) => { setSelectedIsInTouchOption(key); setSelectedReasonOption(undefined) }} selectedOptionKey={selectedIsInTouchOption} />

    { selectedIsInTouchOption !== undefined &&
      <SubHeader content="More detail ..." />
    }

    {/* Second layer options */}
    {/* if No */}
    {selectedIsInTouchOption === NoKey &&
      <SurveyOptionGroup options={[
        buildOption('It\'s on my list to do'),
        buildOption('I\'m not sure what that means?'),
        buildOption('I\'m happy to proceed without a grant')
      ]} onSelect={(key: string) => { setSelectedReasonOption(key) }} selectedOptionKey={selectedReasonOption} />
    }

    {/* if Yes */}
    { selectedIsInTouchOption === YesKey &&
      <SurveyOptionGroup options={[
        buildOption('I\'m in contact with my local Home Energy Specialist'),
        buildOption('I\'ve received a Home Energy Improvement Report'),
        buildOption('I\'ve submitted my application for funding')
      ]} onSelect={(key: string) => { setSelectedReasonOption(key) }} selectedOptionKey={selectedReasonOption} />
    }

    <Button size='LG' disabled={!selectedIsInTouchOption || !selectedReasonOption} onClick={handleNavigateNextPage}>
      <div className="flex items-center gap-x-2">
        <div>Continue</div>
        <ChevronRightIcon className="h-6 w-6" />
      </div>
    </Button>
  </>
}
