import { type Dispatch, type SetStateAction } from 'react'
import { type PropertySurveyWrapper } from '../../code/models/property'
import { type FileWrapper } from '../heat_loss/file_wrapper'
import { axiosPostUnwrapped } from '../../code/axios'
import { type Material } from '../../code/models/material'
import { db } from '../heat_loss/db'
import { captureException } from '@sentry/react'

// We need server_updated_at because:
// t=1, client=A: adds row on client
// t=2, client=B: syncs with server, no changes, update last_synced time to 2
// t=3, client=A: sync with server, push my 1 change at t=1 to db, without setting server_modified to t=3 clientB would never get the change
export type SyncItem = {
  uuid: string | undefined
  created_at: number
  updated_at: number
  server_updated_at: number
  deleted_at: number | undefined
  is_modified: boolean
}

export type Migration = {
  uuid: string
  name: string
}

export type SyncTable =
  { name: 'surveys', data: PropertySurveyWrapper[] } |
  { name: 'files', data: FileWrapper[] } |
  { name: 'custom_materials', data: Material[] }

export type SyncTableName = SyncTable extends { name: infer N } ? N : never
export type SyncTableData = SyncTable extends { data: infer N } ? N : never

export type Sync = {
  tables: SyncTable[]
  last_synced: number
}

const removeKeyProperty = (data: any[]) => data.map(({ key, ...rest }) => rest)

export const syncTick = async (
  lastSynced: number,
  setLastSynced: Dispatch<SetStateAction<number>>,
  isSyncing: boolean,
  setIsSyncing: Dispatch<SetStateAction<boolean>>,
  setHasSynced: Dispatch<SetStateAction<boolean>>,
  setIsOffline: Dispatch<SetStateAction<boolean>>
) => {
  // On slow internet, prevent calls tripping over each other.
  if (isSyncing) return

  setIsSyncing(true)
  try {
    const surveys = await db.surveys.filter(x => x.updated_at > lastSynced || !x.server_updated_at || x.is_modified).toArray() ?? []
    const files = await db.files.filter(x => x.updated_at > lastSynced || !x.server_updated_at || x.is_modified).toArray() ?? []
    const customMaterials = await db.custom_materials.filter(x => x.updated_at > lastSynced || !x.server_updated_at || x.is_modified).toArray() ?? []

    const sync: Sync = {
      last_synced: lastSynced,
      tables: [
        { name: 'surveys', data: removeKeyProperty(surveys) },
        { name: 'custom_materials', data: removeKeyProperty(customMaterials) },
        { name: 'files', data: removeKeyProperty(files) }
      ]
    }

    const result = await axiosPostUnwrapped<Sync>('sync', sync)

    for (const table of result.tables) {
      const existingRecords = await db[table.name as any].where('uuid').anyOf(table.data.map(x => x.uuid)).toArray()
      const existingRecordsMap = new Map(existingRecords.map(record => [record.uuid, record]))

      const filteredData = table.data.filter(incomingRow => {
        const existingRow = existingRecordsMap.get(incomingRow.uuid) as any
        return !existingRow || incomingRow.updated_at >= existingRow.updated_at
      })

      if (filteredData.length > 0) await db[table.name as any].bulkPut(filteredData.map(x => ({ key: x.uuid!, ...x })))
    }

    setLastSynced(result.last_synced)
    setIsOffline(false)
  } catch (e) {
    if (e.code === 'ERR_NETWORK') {
      setIsOffline(true)
    } else {
      captureException(e)
      setIsOffline(false)
    }
  } finally {
    setHasSynced(true)
  }

  setIsSyncing(false)
}
