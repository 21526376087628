import { Check } from 'lucide-react'
import React from 'react'

type SurveyCheckboxProps = {
  label: string
  sublabel?: string
  selected?: boolean
  onSelect?: (isSelected: boolean) => void
}

export const SurveyCheckbox: React.FC<SurveyCheckboxProps> = ({ label, sublabel, selected, onSelect }) => {
  const handleSelect = () => {
    if (onSelect) onSelect(!selected)
  }

  const classNames = selected ? 'border-solid border-2 border-gray-700 px-5 py-3' : 'border-solid border-2 border-gray-100 bg-gray-100 px-5 py-3'

  return <div onClick={handleSelect} className={`${classNames} cursor-pointer rounded-xl gap-4 flex justify-between w-full`}>

    {/* Text */}
    <div className="flex-col flex">
      <div className="text-gray-900 font-bold">{label}</div>
      {sublabel && <div className="text-gray-600 text-sm">{sublabel}</div>}
    </div>

    {/* Checkbox */}
    {selected ? <div className="bg-gray-900 rounded border text-white border-gray-900 w-5 h-5 justify-center items-center flex font-bold"><Check className="w-4 h-4 stroke-2" /></div> : <div className="w-5 h-5 bg-white rounded border border-neutral-300" />}
  </div>
}
