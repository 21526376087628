import { Combobox, ComboboxInput, ComboboxOption, ComboboxOptions, ComboboxButton, Listbox, ListboxButton, ListboxOption, ListboxOptions } from '@headlessui/react'
import React, { Fragment, useState } from 'react'
import { Icon } from '../buttons/icon'
import { ChevronDown, ChevronUp, Loader, Check as CheckL } from 'lucide-react'

const sizes = {
  font: {
    SM: 'text-xs',
    MD: 'text-sm',
    LG: 'text-base'
  },
  spacing: {
    SM: 'px-3 py-2',
    MD: 'px-4 py-2',
    LG: 'px-4 py-3'
  }
}

const colours = {
  // Primary and secondary are just default and light
  // They're only in here for type consistency
  PRIMARY: 'bg-white border-gray-300 text-gray-600',
  SECONDARY: 'bg-gray-100 text-gray-900 border-gray-100',
  DEFAULT: 'bg-white border-gray-300 text-gray-600',
  DARK: 'bg-gray-900 text-gray-100 border-gray-900',
  LIGHT: 'bg-gray-100 text-gray-900 border-gray-100',
  YELLOW: 'bg-yellow-100 text-yellow-900 border-yellow-100',
  RED: 'bg-red-100 text-red-900 border-red-100',
  BLUE: 'bg-blue-100 text-blue-900 border-blue-100',
  GREEN: 'bg-green-100 text-green-900 border-green-100'
}

type Sizes = keyof typeof sizes['font']

type Colours = keyof typeof colours

export type SelectOption = {
  key: string | null
  value: string
}

type Props = {
  options: SelectOption[]
  selectedKey?: string | string[]
  setSelectedKey: ((key: string) => void) | ((key: string[]) => void)
  filter?: boolean
  filterCallback?: (query: string) => void
  isLoading?: boolean
  size?: Sizes
  colour?: Colours
  placeholder?: string
  label?: string
  className?: string
  dataCy?: string
  autoFocus?: boolean
  multiple?: boolean
}

const buttonStyles = (size: Sizes, colour: Colours, focus: boolean) => `relative flex flex-col text-left w-full ${sizes.font[size]} ${sizes.spacing[size]} rounded-lg border ${colours[colour]} cursor-pointer ${focus && 'border-accent outline outline-accent'}`
const dropdownContainerStyles = (type: 'simple' | 'filterable') => `[--anchor-gap:4px] empty:invisible ${type === 'simple' ? 'w-[var(--button-width)]' : 'w-[var(--input-width)]'} overflow-y-auto [--anchor-max-height:240px] z-20 rounded bg-white shadow border border-gray-200 focus:outline-none`
const dropdownItemStyles = (size: Sizes, selected: boolean, focus: boolean) => `group relative text-default cursor-pointer p-2 ${sizes.font[size]} ${sizes.spacing[size]} first:rounded-t last:rounded-b pr-10 ${focus ? 'bg-accent text-white' : selected ? 'bg-gray-100 text-default' : ''}`

const Label = ({ label }: { label: string }) => (
  <div className="uppercase text-xs font-bold">{label}</div>
)

type ArrowProps = {
  open: boolean
  size: Sizes
  colour: Colours
  loading?: boolean
}

const Arrow = React.forwardRef(({ open, size, colour, loading }: ArrowProps, ref) => {
  return (
    <div
      className={`group absolute top-1/2 ${size === 'SM' ? 'right-3' : 'right-4'} -translate-y-1/2 ${sizes.font[size]} pointer-events-none`}
      ref={ref as React.Ref<HTMLDivElement>}
    >
      {loading
        ? <Icon icon={Loader} spin colour={colours[colour]} />
        : <Icon icon={open ? ChevronUp : ChevronDown} colour={colours[colour]} />
      }
    </div>
  )
})

const Check = ({ size, selected, focus }: { size: Sizes, selected: boolean, focus: boolean }) => (
  <Icon icon={CheckL} className={ `absolute top-1/2 ${size === 'SM' ? 'right-3' : 'right-4'} -translate-y-1/2 ${sizes.font[size]} ${selected ? 'hidden sm:block' : 'hidden'} ${focus ? 'text-white' : 'text-default'}` } />
)

const valueForKey = (options: SelectOption[], key?: string | string[]) => options.find((option) => option.key === key)?.value

const DEFAULT_PLACEHOLDER = 'Please select'

const SimpleSelect = ({ options, selectedKey, setSelectedKey, size = 'MD', colour = 'DEFAULT', label, placeholder, dataCy }: Props) => {
  return (
    <Listbox value={selectedKey} onChange={setSelectedKey} >
      <ListboxButton as={Fragment}>
        {({ open, focus, active }) => (
          <button
            className={`${buttonStyles(size, colour, [open, focus, active].some(Boolean))} w-full`}
            data-cy={dataCy}
          >
            {label && <Label label={label} />}
            <div className="flex items-center w-full">
              <div className="mr-6">{valueForKey(options, selectedKey) ?? placeholder ?? DEFAULT_PLACEHOLDER}</div>
              <Arrow open={open} size={size} colour={colour} />
            </div>
          </button>
        )}
      </ListboxButton>
      <ListboxOptions className={dropdownContainerStyles('simple')} anchor="bottom">
        {options.map((option) => (
          <ListboxOption as={Fragment} key={option.key} value={option.key}>
            {({ selected, focus }) => (
              <div className={dropdownItemStyles(size, selected, focus)}>
                {option.value}
                <Check size={size} selected={selected} focus={focus} />
              </div>
            )}
          </ListboxOption>
        ))}
      </ListboxOptions>
    </Listbox>
  )
}

const FilterableSelect = ({ options, selectedKey, setSelectedKey, filterCallback, size = 'MD', colour = 'DEFAULT', placeholder, isLoading, autoFocus, dataCy, multiple }: Props) => {
  const [filteredOptions, setFilteredOptions] = useState<SelectOption[]>(options)

  return (
    <Combobox immediate value={selectedKey} onChange={setSelectedKey} as={Fragment} multiple={multiple}>
      <div className="relative w-full">
        <ComboboxInput
          displayValue={(value?: string) => valueForKey(options, value)!}
          as={Fragment}
        >
          {({ focus }) => (
            <input
              placeholder={placeholder ?? DEFAULT_PLACEHOLDER}
              onChange={(event) => {
                // If a filter function is provided, use it to filter the options (this may be a query to an API)
                // Otherwise, just do a text search on the options
                if (filterCallback) {
                  filterCallback(event.target.value)
                } else {
                  setFilteredOptions(options.filter((option) => option.value.toLowerCase().includes(event.target.value.toLowerCase())))
                }
              }}
              className={`${buttonStyles(size, colour, focus)} pr-10`}
              autoComplete='off'
              autoFocus={autoFocus}
              data-cy={dataCy}
            />
          )}
        </ComboboxInput>
        <ComboboxButton as={Fragment}>
          {({ open }) => (
            <Arrow open={open} size={size} colour={colour} loading={isLoading} />
          )}
        </ComboboxButton>
      </div>
      <ComboboxOptions anchor="bottom" className={dropdownContainerStyles('filterable')}>
        {(filterCallback ? options : filteredOptions).map((option) => (
          <ComboboxOption key={option.key} value={option.key} as={Fragment}>
            {({ selected, focus }) => (
              <div className={dropdownItemStyles(size, selected, focus)}>
                {option.value}
                <Check size={size} selected={selected} focus={focus} />
              </div>
            )}
          </ComboboxOption>
        ))}
      </ComboboxOptions>
    </Combobox>
  )
}

export const Select = ({ options, selectedKey, setSelectedKey, filter, filterCallback, size = 'MD', colour = 'DEFAULT', label, placeholder, isLoading, dataCy, autoFocus, multiple }: Props) => {
  return filter ? (
    <FilterableSelect options={options} selectedKey={selectedKey} setSelectedKey={setSelectedKey} filter={filter} size={size} colour={colour} placeholder={placeholder} filterCallback={filterCallback} isLoading={isLoading} dataCy={dataCy} autoFocus={autoFocus} multiple={multiple} />
  ) : (
    <SimpleSelect options={options} selectedKey={selectedKey} setSelectedKey={setSelectedKey} size={size} colour={colour} label={label} placeholder={placeholder} dataCy={dataCy} multiple={multiple} />
  )
}
