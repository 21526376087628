import React, { type Dispatch, type SetStateAction } from 'react'
import { Button } from '../../components/buttons/button'
import { Input } from '../../components/inputs_and_selections/input'
import { MaterialInputField } from './materials/material_input_field'
import type { Material } from '../../code/models/material'
import type { MaterialsSelectorGroupedProps } from './materials/materials_selector'
import type { PropertySurvey } from '../../code/models/property'
import { type RoofLight } from '../../code/models/rooflight'
import { validateInMm } from '../../code/validators'
import { type CurrentFloorPlanPage } from './floor/floor'

type Props = {
  rooflight: RoofLight
  setRooflight: Dispatch<SetStateAction<RoofLight | undefined>>
  onSave: () => void
  materials: Material[]
  survey: PropertySurvey
  setMsProps: React.Dispatch<React.SetStateAction<MaterialsSelectorGroupedProps | undefined>>
  setPage: (page: CurrentFloorPlanPage) => void }

export const RooflightPage = ({ rooflight, setRooflight, onSave, materials, survey, setMsProps, setPage }: Props) => {
  const isValidForm = [validateInMm(rooflight.width_mm), validateInMm(rooflight.height_mm)].every(x => x.value)

  return <div className='flex flex-col'>
    <div className="p-5 bg-white flex-col gap-5 flex">
      <div className='text-gray-900 font-bold text-sm'>Rooflight material</div>
      <MaterialInputField
        selectorProps={{
          materials,
          title: 'Rooflight material',
          surfaceType: 'window',
          selectedMaterial: rooflight.material,
          ageBand: survey.age_band,
          onSelectCallback: (material: Material) => {
            setRooflight(prev => ({ ...prev!, material }))
          }
        } satisfies MaterialsSelectorGroupedProps}
        setMsProps={setMsProps}
        setPage={setPage}
      />

      <div className='space-y-2'>
        <div className='flex gap-2 text-sm text-gray-600'>
          <div className='flex-1 flex flex-col gap-1'>
            <div>Width</div>
            <Input type='number'
              step={1}
              value={rooflight.width_mm.toString()}
              setValue={(e) => setRooflight(prev => ({ ...prev!, width_mm: parseFloat(e) }))}
              validator={validateInMm}
              postfix='mm'/>
          </div>
          <div className='flex-1 flex flex-col gap-1'>
            <div>Height</div>
            <Input type='number'
              step={1}
              value={rooflight.height_mm.toString()}
              setValue={(e) => setRooflight(prev => ({ ...prev!, height_mm: parseFloat(e) }))}
              validator={validateInMm}
              postfix='mm'/>
          </div>
        </div>
      </div>

      <Button disabled={!isValidForm} onClick={() => {
        onSave()
        setRooflight(undefined)
      }}>Apply</Button>
    </div>
  </div>
}
