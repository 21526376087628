import { setError } from '../../components/indicators_and_messaging/toast'
import { client, url } from '../axios'
import { type AxiosResponse } from 'axios'

export const remindPassword = async (email: string): Promise<void> => {
  try {
    await client.post<string>(`${url}remind_password`, { email })
  } catch (e) {
    setError(e)
  }
}

export const validatePasswordResetToken = async (token: string): Promise<boolean> => {
  try {
    await client.get<string>(`${url}validate_password_reset_token?token=${token}`)
    return true
  } catch (e) {
    setError(e)
    return false
  }
}

export const updateUserPassword = async (token: string, password: string): Promise<void> => {
  try {
    await client.post<string>(`${url}update_user_password`, { token, password })
  } catch (e) {
    setError(e)
  }
}

export type SignUpReturnType = {
  company: { uuid: string, subdomain: string }
}

export const signUp = async (email: string, password: string, firstName: string, lastName: string, companyName: string, companyPhone: string, companyAddress: string, companyLatLng: {
  lat: number
  lng: number
}, logo: string, stripeCustomerId: string): Promise<AxiosResponse<SignUpReturnType>> => {
  try {
    return await client.post<SignUpReturnType>(`${url}sign_up`, {
      user: {
        email,
        password
      },
      company: {
        name: companyName,
        logo,
        phone: companyPhone,
        installer_first_name: firstName,
        installer_last_name: lastName,
        address: companyAddress,
        lat: companyLatLng.lat,
        lng: companyLatLng.lng,
        stripe_customer_id: stripeCustomerId
      }
    })
    // setIsLoggedIn(result.data.token, setState);
    // handleUrlChange(null, `/${result.data.company.subdomain}/admin`, setState);
  } catch (e) {
    return e.response
    // setError(e, setState);
  }
}
