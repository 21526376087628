import { Badge } from '../../components/indicators_and_messaging/badge'
import { ClickableCard } from '../../components/content_display/card'
import React from 'react'
import { type SurveyDesign } from '../../code/models/property'
import { getHeatPumpCapacityAtOutsideTempAndFlowTemp } from '../../code/models/range_heat_pump'
import { FormLabel } from '../../components/inputs_and_selections/form_label'
import { type InventoryHeatPump } from '../../code/models/inventory'

type HeatPumpSelectionProps = {
  //   Undefined properties here have to be defined in the design variant. Not sure if there is a better way to specify this.
  currentHeatPump?: InventoryHeatPump
  soundCalculation: number
  variant: 'SURVEY' | 'DESIGN'
  designTempC?: number
  scop?: number
  design?: SurveyDesign
  totalHeatLossKW?: number
  setCurrentPage: (page: string) => void
}

export const SelectedHeatPump = ({ currentHeatPump, soundCalculation, variant, designTempC, design, scop, totalHeatLossKW, setCurrentPage }: HeatPumpSelectionProps) => {
  const heatPumpCapacityResult = variant === 'DESIGN' ? getHeatPumpCapacityAtOutsideTempAndFlowTemp(currentHeatPump?.range_heat_pump, designTempC!, design!.flow_temp) : undefined

  return <>
    <div className="flex-col gap-3 flex">
      <FormLabel
        labelText={'Heat pump model'}
        required={true}
        complete={currentHeatPump !== undefined}
        helperText={
          variant === 'DESIGN'
            ? 'Update the choice of heat pump below to ensure it matches the heat loss, fits the available space and meets the sound requirements.'
            : 'Pre-filled from the estimate to help you check there is sufficient space.'

        }
      />

      {!currentHeatPump && <ClickableCard onClick={() => setCurrentPage('SELECT_HEAT_PUMP')} variant='PLACEHOLDER'>
            Please select a heat pump
      </ClickableCard>}
      {currentHeatPump &&
              <ClickableCard onClick={() => setCurrentPage('SELECT_HEAT_PUMP')} variant='WHITE' title={currentHeatPump?.name}>
                <div className="flex-col gap-2 flex">
                  <div className="flex-col flex text-xs font-semibold">
                    <div className="py-2 border-t border-gray-200 justify-between gap-2 flex">
                      <div>Sound power level</div>
                      <div>{currentHeatPump?.range_heat_pump?.sound_power_max_dba ?? 0} dB(A)</div>
                    </div>
                    {variant === 'DESIGN' && <div className="py-2 border-t border-gray-200 justify-between gap-2 flex">
                      <div className="text-gray-600 text-xs font-semibold">Sound assessment result</div>
                      <div className="text-right text-gray-600 text-xs font-semibold">
                        <Badge color={soundCalculation === 0 ? 'YELLOW' : soundCalculation <= 42 ? 'GREEN' : 'RED'}
                          text={`${soundCalculation} dB(A)`}/>
                      </div>
                    </div>}
                    <div className="py-2 border-t border-gray-200 justify-between gap-2 flex">
                      <div>Refrigerant</div>
                      <div>{currentHeatPump?.range_heat_pump?.refrigerant ?? 'N/A'}</div>
                    </div>
                    <div className="py-2 border-t border-gray-200 justify-between gap-2 flex">
                      <div>Volume</div>
                      <div>{(((currentHeatPump.range_heat_pump?.width_mm ?? 0) * (currentHeatPump.range_heat_pump?.height_mm ?? 0) * (currentHeatPump.range_heat_pump?.depth_mm ?? 0)) / Math.pow(10, 9)).toFixed(2)} m³</div>
                    </div>
                    <div className="py-2 border-t border-gray-200 justify-between gap-2 flex">
                      <div>Dimensions (WxHxD)</div>
                      <div>{currentHeatPump?.range_heat_pump?.width_mm ?? 0} x {currentHeatPump?.range_heat_pump?.height_mm ?? 0} x {currentHeatPump?.range_heat_pump?.depth_mm ?? 0} mm</div>
                    </div>
                    { variant === 'DESIGN' && <>
                      <div className="py-2 border-t border-gray-200 justify-between gap-2 flex">
                        <div className="text-gray-600 text-xs font-semibold">SCOP at {design!.flow_temp} °C</div>
                        <div className="text-right text-gray-600 text-xs font-semibold">{scop}</div>
                      </div>
                      <div className="py-2 border-t border-gray-200 justify-between gap-2 flex">
                        <div className="text-gray-600 text-xs font-semibold">Capacity at {heatPumpCapacityResult!.flowTempC} °C
                            ({heatPumpCapacityResult!.outsideTempC} °C)
                        </div>
                        <div className="text-right text-gray-600 text-xs font-semibold">
                          <Badge
                            color={heatPumpCapacityResult!.capacityKw === 0 || totalHeatLossKW === 0 ? 'YELLOW' : heatPumpCapacityResult!.capacityKw >= totalHeatLossKW! ? heatPumpCapacityResult!.warning !== undefined ? 'YELLOW' : 'GREEN' : 'RED'}
                            text={`${heatPumpCapacityResult!.capacityKw} kW`}/>
                        </div>
                      </div>
                    </>}

                  </div>
                </div>
                {variant === 'DESIGN' && heatPumpCapacityResult!.warning &&
                  <ClickableCard variant={'WARNING'} border={false} size={'SM'} className={'text-xs px-5 py-2'}>
                    <div className="flex-col gap-2">
                      <div className="font-bold"> Warning</div>
                      <div>{heatPumpCapacityResult!.warning}</div>
                    </div>
                  </ClickableCard>}
              </ClickableCard>}
    </div>
  </>
}
