import React from 'react'
import { WrappedIcon } from '../buttons/wrapped_icon'
import { Check } from 'lucide-react'

const variants = {
  ACTIVE: 'border-gray-900',
  DEFAULT: 'border-gray-200'
}

const iconVariants = {
  ACTIVE: 'bg-gray-900 border border-gray-900',
  DEFAULT: 'bg-white border border-gray-300'
}

export type RadioGroupItem = {
  name: string
  info?: JSX.Element
  description?: string
  icon?: JSX.Element
  onClick: () => void
  variant: keyof typeof variants
  rightContent?: JSX.Element
}

type RadioGroupProps = {
  items: RadioGroupItem[]
  isVertical?: boolean
}

export const RadioGroup = ({ items, isVertical }: RadioGroupProps) => {
  const isVerticalCheck = isVertical || items.some(x => x.description)
  return isVerticalCheck ? <RadioGroupVertical items={items} /> : <RadioGroupHorizontal items={items} />
}

const RadioGroupHorizontal = ({ items }: RadioGroupProps) => {
  return <div className="gap-2 flex overflow-x-auto">
    {items.map(x => <div key={x.name} onClick={x.onClick} className={`cursor-pointer px-4 py-3 bg-white rounded-xl border-2 flex justify-center items-center ${variants[x.variant]}`}>
      <div className='flex flex-col items-center justify-center gap-2'>
        <div className="text-sm whitespace-nowrap">{x.name}</div>
        {x.icon}
      </div>
    </div>)}
  </div>
}

const RadioGroupVertical = ({ items }: RadioGroupProps) => {
  return <div className="gap-2 flex flex-col overflow-x-auto">
    {items.map(x => <div key={x.name} className={`cursor-pointer bg-white rounded-xl border-2 flex gap-2 justify-between items-center ${variants[x.variant]}`}>
      <div className='flex flex-col grow ps-4 py-3' onClick={x.onClick}>
        <div className={'items-center flex gap-0.5'}>
          <div className="text-base font-semibold">{x.name}</div>
          <div className='text-sm'> {x.info && x.info}</div>
        </div>
        <div className="text-sm text-gray-500">{x.description}</div>
      </div>
      <div className='flex gap-4 items-center pe-4 py-3'>
        {x.rightContent}
        <div onClick={x.onClick} className={`flex-shrink-0 w-5 h-5 rounded-full ${iconVariants[x.variant]} text-white flex items-center justify-center text-xs`}>
          <WrappedIcon icon={Check} />
        </div>
      </div>
    </div>)}
  </div>
}
