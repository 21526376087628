import React from 'react'

/**
 * A component that forces the font to be an emoji font,
 * which is necessary to work around a bug seen in Chrome
 * where some emojis were displayed as black and white.
 * Also, emoji should never be bold.
 */
export const Emoji = ({ emoji }: { emoji: string }) => {
  return <span className='font-emoji font-normal'>{emoji}</span>
}
