import { orderBy } from 'lodash'
import {
  BARRIER_MAPPING,
  DIFFERENCE_IN_BACKGROUND_NOISE,
  SOUND_POWER_DISTANCE_REDUCTIONS
} from '../pages/heat_loss/constants'

export const getSoundAssessmentData = (barrierId: string | undefined, reflectiveSurfaces: number | undefined, distanceM: number, heatPumpSoundDba: number | undefined) => {
  // If inputs undefined, return 0
  const backgroundNoiseLevelDba = 40
  const barrier = BARRIER_MAPPING.find(x => x.uuid === (barrierId || 'visible'))

  if (!barrierId || !reflectiveSurfaces || !heatPumpSoundDba || distanceM === 0) {
    return {
      barrier,
      distanceReductionDba: 0,
      barrierReductionDba: 0,
      soundPressureLevelDba: 0,
      backgroundNoiseLevelDba,
      differentialDba: 0,
      correctionDB: 0,
      finalResultDba: 0
    }
  }

  // Can't handle distances less than 1. For now just set to 1 if less than 1
  if (distanceM < 1) distanceM = 1

  // Sound power level reduction from combination of distance and reflective surfaces
  // MCS: If the distance is not in the table, you should round down to the nearest distance that is (e.g. 2.5 should use 2)
  // so filter on only taking distances less than or equal to the input distance
  const distanceReductionMatch = orderBy(SOUND_POWER_DISTANCE_REDUCTIONS.filter(x => x.reflectiveSurfaces === reflectiveSurfaces && x.distanceM <= distanceM), x => Math.abs(distanceM - x.distanceM))[0]
  if (!distanceReductionMatch) throw new Error(`No distance reduction found for ${reflectiveSurfaces} reflective surfaces and ${distanceM}m distance.`)
  const distanceReductionDba = distanceReductionMatch.reductionDB

  // Sound power level reduction from barriers
  if (!barrier) throw new Error(`Invalid barrier type "${barrierId}"`)
  const barrierReductionDba = barrier.reductionDba

  const soundPressureLevelDba = heatPumpSoundDba - distanceReductionDba - barrierReductionDba

  // Adjust for background noise
  const differentialDba = Math.abs(soundPressureLevelDba - backgroundNoiseLevelDba)

  // Take closest match for decibel correction - nothing explicit in MCS
  const correctionDBMatch = orderBy(DIFFERENCE_IN_BACKGROUND_NOISE, x => Math.abs(x.difference - differentialDba))[0]
  if (!correctionDBMatch) throw new Error(`No correction found for ${differentialDba} dB difference.`)
  const backgroundNoiseCorrectionDba = correctionDBMatch.correctionDB

  // MCS says to round up to nearest whole number
  const finalResultDba = Math.ceil(Math.max(backgroundNoiseLevelDba, soundPressureLevelDba) + backgroundNoiseCorrectionDba)

  return {
    barrier,
    distanceReductionDba,
    barrierReductionDba,
    soundPressureLevelDba,
    backgroundNoiseLevelDba,
    differentialDba,
    correctionDB: correctionDBMatch.correctionDB,
    finalResultDba
  }
}
