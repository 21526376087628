import React, { type Dispatch, type SetStateAction, useCallback, useContext, useEffect, useRef, useState } from 'react'
import TabbedDashboardLayout from '../../../layouts/tabbed_dashboard_layout'
import { EnquiryManagerDashboard } from './enquiry_manager/tabs/enquiry_manager_dashboard'
import { AdminContext } from '../admin_layout'
import { type CompanyPublicInfo } from '../../../code/models/company'
import { getAddressIncludingPostcode, getLead, type Lead, patchLead } from '../../../code/models/lead'
import { type CalculatedEstimate, getCalculatedEstimates } from '../../../code/models/calculated_estimate'
import {
  ensureLeadHasEPCRecommendations,
  ensureLeadHasLocation3D,
  ensurePropertySurveyBackwardsCompatibility,
  isPreFlowFeatureSurvey,
  type PropertySurvey,
  type QuoteLineItem,
  type SurveyDesign,
  type SurveyImage
} from '../../../code/models/property'
import {
  getRoleForCompany,
  hasEnquriesAccess,
  hasSurveyAccess,
  USER_ROLE_HAS_SURVEY_ACCESS,
  USER_ROLE_SIMPLE,
  USER_ROLE_SUPERADMIN
} from '../../../code/models/user'
import { UnauthorizedPage } from '../unauthorized_page'
import { calculateEstimate } from '../../../code/calculate_estimate'
import { CostEstimateEditor } from './enquiry_manager/tabs/cost_estimate_editor'
import { Loader } from '../../../components/indicators_and_messaging/loader'
import { EnquiryData } from './enquiry_manager/tabs/enquiry_data'
import { EstimatePreview } from './enquiry_manager/tabs/estimate_preview'
import { EnquiryHistory } from './enquiry_manager/tabs/enquiry_history'
import { useDebounceCallback, useInterval, useLocalStorage } from 'usehooks-ts'
import { SiteDetails } from '../../heat_loss/survey/site_details'
import { convertLatLngListToLatLongLiteral } from '../../../code/geocoding'
import { useLiveQuery } from 'dexie-react-hooks'
import { db } from '../../heat_loss/db'
import { type FileWrapper } from '../../heat_loss/file_wrapper'
import { DEFAULT_PROPERTY_SURVEY, DEFAULT_SURVEY_DESIGN } from '../../../code/survey_defaults'
import { type Material } from '../../../code/models/material'
import { Invitations } from '../components/invitations'
import { chain, compact, sum } from 'lodash'
import { FloorList } from '../../heat_loss/floor/floor_list'
import { DefaultMaterialsTab } from '../../heat_loss/materials/default_materials_tab'
import { CylinderLocation } from '../../heat_loss/survey/cylinder_location'
import { Electrics } from '../../heat_loss/survey/electrics'
import { ExistingHeatingSystem } from '../../heat_loss/survey/existing_heating_system'
import { HeatPumpLocation } from '../../heat_loss/survey/heat_pump_location'
import { Pipework } from '../../heat_loss/survey/pipework'
import { NewFloor, Survey } from '../../heat_loss/survey/survey'
import { SurveySettings } from '../../heat_loss/survey/survey_settings'
import { DesignHome } from '../../heat_loss/design_home'
import {
  getFloorAreaM2,
  getHeatTransferCoefficientWattsPerKelvin,
  getRoomTemp,
  getRoomWatts,
  getTotalHeatLossWatts
} from '../../../code/models/heat_loss'
import {
  getCylinderReheatCalculationRow,
  getDailyHotWaterVolumeL,
  getHotWaterCalculationRowLegionella,
  getHotWaterCalculationRowNormalOperation,
  getNumberOfOccupants
} from '../../../code/models/hot_water_cylinder'
import {
  eligibleForHeatPumpPlus,
  getPerformanceEstimateSummary,
  type PerformanceEstimateRow
} from '../../../code/models/performance_estimate'
import { getPipeData, type HydronicEmitterWithRoomAndWatts } from '../../../code/models/pipes'
import { getEmitterSizeName, getEmitterTypeName, getFullEmittersListByStatus } from '../../../code/models/radiator'
import { getEmitterWatts, RADIATOR_MODELS, type RadiatorModel } from '../../../code/models/radiator_model'
import {
  findMaxValidFlowTempForScop,
  findMinValidFlowTempForScop,
  getHeatPumpCapacityAtOutsideTempAndFlowTemp,
  getHeatPumpScopAtFlowTemp
} from '../../../code/models/range_heat_pump'
import { getRoomEmitterWatts } from '../../../code/models/room'
import { getSoundAssessmentData } from '../../../code/sound_assessment'
import { type TableColumn } from '../../../components/content_display/table_lite'
import { CylinderDesignPage } from '../../heat_loss/design/pages/cylinder_design_page'
import { EmitterDesignPage, getRadiatorBadge } from '../../heat_loss/design/pages/emitter_design_page'
import { HeatPumpDesign } from '../../heat_loss/design/pages/heat_pump_design'
import { PerformanceDesignPage } from '../../heat_loss/design/pages/performance_design_page'
import { PipeworkDesignPage } from '../../heat_loss/design/pages/pipework_design_page'
import { QuoteDesignPage } from '../../heat_loss/design/pages/quote_design_page'
import { type Vector2d } from 'konva/lib/types'
import { getDesignConditions } from '../../../code/models/design_temp'
import { type Floor } from '../../../code/models/floor'
import { FloorPage } from '../../heat_loss/floor/floor'
import { syncTick } from '../sync'
import { Error404Page } from '../../error_pages'
import { HeatLossReportSettings } from '../../heat_loss/proposal/heatloss_report_settings'
import { type InventoryHeatPump } from '../../../code/models/inventory'
import { FLOW_CANCELLED_VALUE, FLOW_FINISHED_VALUE } from '../../heat_loss/survey/components/survey_section_flow_wrapper'
import { getUnderfloorHeatingOutput } from '../../../code/models/underfloor_heating'
import { ProposalConfigurator } from './proposal_configurator/proposal_configurator'

export const IsOfflineContext = React.createContext<boolean>(false)

export type EmitterDemandStatus = 'Sufficient' | 'Accepted' | 'Insufficient'
type RoomGroupHeatDemandMet = { roomGroupKey: string, status: EmitterDemandStatus }

export type SetIndexedDb<T> = (updateFn: T | ((prevPropertySurvey: T) => T)) => Promise<void>

type InstallerAdminPageProps = {
  leadUUID: string
  tab: string
  secondaryTab: string
  companyPublicInfo: CompanyPublicInfo
  currentPath: string
  basePath: string
  navigateTo: (url: string) => void
}

export const InstallerAdminPage = ({ leadUUID, tab, secondaryTab, companyPublicInfo, currentPath, navigateTo, basePath }: InstallerAdminPageProps) => {
  const adminContext = useContext(AdminContext)
  const { isOffline } = adminContext
  const [leadSaved, setLeadSaved] = useState(false)
  const [leads] = useLocalStorage<Lead[]>('leads', [])

  const debouncePatchLead = useDebounceCallback(async (lead: Lead, companyUUID: string) => {
    try {
      await patchLead(lead.uuid!, lead, companyUUID)
      setLeadSaved(true)
    } catch (e) {
      console.error(e)
    }
  }, 1000)

  const [localLead, setLocalLead] = useState<Lead>()

  const lead = localLead ?? leads.find(x => x.uuid === leadUUID)
  const setLead = useCallback((lead: Lead) => {
    setLeadSaved(false)
    setLocalLead(lead)
    debouncePatchLead(lead, companyPublicInfo.uuid)
  }, [])

  const [estimates, setEstimates] = useState<CalculatedEstimate[]>([])
  const [isLoading, setIsLoading] = useState(false)

  const loadData = async () => {
    if (!leadUUID) return

    setIsLoading(true)
    const lead = await ensureLeadHasEPCRecommendations((await getLead(leadUUID, companyPublicInfo.uuid))!, companyPublicInfo.uuid, true)
    if (!lead) {
      setIsLoading(false)
      return
    }

    const newLead = await ensureLeadHasLocation3D(lead, companyPublicInfo.uuid)
    setLead(newLead)

    const estimates = await getCalculatedEstimates(leadUUID, companyPublicInfo.uuid) ?? []
    setEstimates(estimates)

    setIsLoading(false)
  }

  useEffect(() => {
    if (adminContext.isOffline) return
    if (adminContext.data.company === undefined) return
    if (!hasEnquriesAccess(adminContext.data.company, adminContext.data.user)) return
    loadData()
  }, [adminContext.isLoading])

  useEffect(() => {
    // We need to do a raw regex check here, react router useMatch does not work.
    const surveyPage = /\/admin\/quotes\/[0-9a-fA-F-]{36}\/survey\/floors\/(.*)$/
    const isSidebarVisible = !surveyPage.test(window.location.pathname)
    adminContext.setIsSidebarVisible(isSidebarVisible)
  }, [currentPath])

  const surveyDB = useLiveQuery(async () => await db.surveys.get(leadUUID))
  const customMaterials = useLiveQuery(async () => await db.custom_materials.where('company_uuid').equals(companyPublicInfo.uuid).and(x => !x.deleted_at).toArray()) ?? []
  const files = useLiveQuery(async () => await db.files.where('company_uuid').equals(companyPublicInfo.uuid).and(x => !x.deleted_at).toArray()) ?? []

  const [currentRoomId, setCurrentRoomId] = useState<string>()
  const [stageSize, setStageSize] = useState({
    width: 0,
    height: 0
  })

  const stageRef = useRef<HTMLDivElement>(null)

  const [stageScale, setStageScale] = useState<number>(0.05)
  const [stagePosition, setStagePosition] = useState<Vector2d>({ x: stageSize.width / 2, y: stageSize.height / 2 })

  const [isSyncing, setIsSyncing] = useState(false)
  const [lastSynced, setLastSynced] = useLocalStorage('last_synced', 0)
  const [hasSynced, setHasSynced] = useState(false)

  // If the database is ready, check if we have any migrations to run before syncing, if not start syncing.
  useEffect(() => {
    syncTick(lastSynced, setLastSynced, isSyncing, setIsSyncing, setHasSynced, adminContext.setIsOffline)
  }, [])

  useInterval(() => {
    syncTick(lastSynced, setLastSynced, isSyncing, setIsSyncing, setHasSynced, adminContext.setIsOffline)
  }, 10000)

  // Starting render
  if (adminContext.isLoading || isLoading) {
    return (
      <div className='flex justify-center items-center h-full'>
        <Loader />
      </div>
    )
  }

  if (!lead) {
    return <UnauthorizedPage customHeader='Enquiry not found' customMessage='This enquiry may have been deleted or does not exist.' />
  }

  const estimate = calculateEstimate(
    lead,
    adminContext.data?.heatPumps ?? [],
    adminContext.data?.hotWaterCylinders ?? [],
    adminContext.data?.company
  )

  const survey = surveyDB ? ensurePropertySurveyBackwardsCompatibility(surveyDB.data, adminContext.data.genericMaterials) : DEFAULT_PROPERTY_SURVEY
  const setSurvey = async (updateFn: PropertySurvey | ((prevPropertySurvey: PropertySurvey) => PropertySurvey)) => {
    const surveyOrNew = await db.surveys.get(leadUUID)
    const updatedSurvey = typeof updateFn === 'function' ? updateFn(surveyOrNew!.data) : updateFn

    await db.surveys.update(leadUUID, { data: updatedSurvey, updated_at: new Date().getTime(), is_modified: true })
  }

  const setFiles: Dispatch<SetStateAction<FileWrapper[]>> = async (updateFn: FileWrapper[] | ((prevPropertySurvey: FileWrapper[]) => FileWrapper[])) => {
    const files = await db.files.filter(x => x.company_uuid === companyPublicInfo.uuid).toArray()
    const updatedFiles = typeof updateFn === 'function' ? updateFn(files) : updateFn

    await db.files.bulkPut(updatedFiles.map(x => ({ key: x.uuid, ...x })))
  }

  const setCustomMaterials = async (updateFn: Material[] | ((prevPropertySurvey: Material[]) => Material[])) => {
    const customMaterials = await db.custom_materials.filter(x => x.company_uuid === companyPublicInfo.uuid && !x.deleted_at).toArray()
    const updatedMaterials = typeof updateFn === 'function' ? updateFn(customMaterials) : updateFn

    await db.custom_materials.bulkPut(updatedMaterials.map(x => ({ key: x.uuid, ...x })))
  }

  const allSynced = (!surveyDB || !surveyDB.is_modified) &&
    files.every(x => !x.is_modified) &&
    customMaterials.every(x => !x.is_modified)

  // blockers of rendering in case the data is not loaded yet

  if (adminContext.isLoading || !hasSynced) {
    return <div className='p-5 flex justify-center'>
      <Loader />
    </div>
  }

  if (adminContext.data?.user && !hasSurveyAccess(adminContext.data?.company, adminContext.data?.user)) return <div className='p-5 flex justify-center'>403. Access denied</div>

  const setLocationImages = async (value: SurveyImage[]) => await setSurvey({ ...survey, location_images: value })

  const setCustomRadiatorModels = (updateFn: (prevRadiators: RadiatorModel[]) => RadiatorModel[]) => {
    setSurvey((prevSurvey) => ({
      ...prevSurvey,
      custom_radiator_models: updateFn(prevSurvey.custom_radiator_models)
    }))
  }

  const customRadiatorModels = survey.custom_radiator_models // Doing this at top level so can easily swap out for a database read when we move custom radiator models to the company level
  const allRadiatorModels = [...RADIATOR_MODELS, ...customRadiatorModels]

  const setFloors = async (floors: Floor[]) => await setSurvey(prev => ({ ...prev, floors }))
  const setFloor = (floor: Floor) => {
    setFloors([...survey.floors.map(x => x.uuid === floor.uuid ? floor : x)])
  }
  const removeFloor = (id: string) => {
    const filteredFloors = survey.floors.filter(x => x.uuid !== id)
    setFloors([...filteredFloors])
    navigateToWithBasePath('/survey/floors')
  }

  const setDesign = async (design: SurveyDesign) => await setSurvey(prev => ({ ...prev, designs: [design] }))
  const setFlowTemp = async (temp: number) => await setDesign({ ...design, flow_temp: temp })

  const setQuoteLineItems = async (value: QuoteLineItem[]) => await setDesign({ ...design, quote_line_items: value })
  const design = survey.designs[0] ?? DEFAULT_SURVEY_DESIGN
  const heatPumps = (adminContext.data.heatPumps ?? []).filter(x => !x.deleted_at)

  const hydratedHeatPumps: InventoryHeatPump[] = heatPumps.map(x => ({
    ...x,
    range_heat_pump: {
      ...x.range_heat_pump!,
      brand_range: adminContext.data.brandRanges!.find(y => y.uuid === x.range_heat_pump?.brand_range_uuid)
    }
  }))

  const currentHeatPump = hydratedHeatPumps.find(x => x.uuid === design.current_heat_pump_uuid)
  const minFlowTemp = findMinValidFlowTempForScop(currentHeatPump?.range_heat_pump)
  const maxFlowTemp = findMaxValidFlowTempForScop(currentHeatPump?.range_heat_pump)

  const latLng = convertLatLngListToLatLongLiteral(lead.property.postcodeLocation)
  const altitudetoUseM = survey.altitude_override_m ?? lead.property.altitudeM
  const { designTempDefaultC, degreeDays, groundTempC } = getDesignConditions(latLng, altitudetoUseM, survey.exposed_location) // done separately as needed in survey_settings
  const designTempC = survey.design_temp_override_c ?? designTempDefaultC

  const currentPathWithoutBase = currentPath.replace(basePath, '')
  const navigateToWithBasePath = (url: string) => navigateTo(`${basePath}${url}`)

  // Do not move this page into the router, we do a lot of calculations on re-render below not needed for floorplan and slows down drag move.
  // TODO: Try to match calculations with individual pages, re-calculating logic for multiple pages where needed so we can bring this into the router.
  const singleFloorPath = /^\/survey\/floors\/([a-zA-Z0-9-]+)$/
  if (singleFloorPath.test(currentPathWithoutBase)) {
    const uuid = currentPathWithoutBase.match(singleFloorPath)?.[1]
    const currentFloor = survey.floors.find(x => x.uuid === uuid)
    if (!currentFloor) return <Error404Page />

    return <FloorPage
      removeFloor={removeFloor}
      survey={survey}
      floors={survey.floors}
      floor={currentFloor}
      setFloor={setFloor}
      files={files}
      setFiles={setFiles}
      designTempC={designTempC}
      groundTempC={groundTempC}
      setSurvey={setSurvey}
      materials={[...adminContext.data.genericMaterials!, ...customMaterials]}
      setMaterials={setCustomMaterials}
      materialsLayers={adminContext.data.materialsGenericLayers!}
      customRadiatorModels={customRadiatorModels}
      setCustomRadiatorModels={setCustomRadiatorModels}
      allRadiatorModels={allRadiatorModels}
      lead={lead}
      design={design}
      currentRoomId={currentRoomId}
      setCurrentRoomId={setCurrentRoomId}
      stageScale={stageScale}
      setStageScale={setStageScale}
      stagePosition={stagePosition}
      setStagePosition={setStagePosition}
      setFlowTemp={setFlowTemp}
      minFlowTemp={minFlowTemp}
      maxFlowTemp={maxFlowTemp}
      stageRef={stageRef}
      stageSize={stageSize}
      setStageSize={setStageSize}
      companyUuid={companyPublicInfo.uuid}
      allSynced={allSynced}
      isOffline={isOffline}
      navigateTo={navigateToWithBasePath}
    />
  }

  // Calculations
  // Inputs
  const hotWaterCylinders = (adminContext.data.hotWaterCylinders ?? []).filter(x => !x.deleted_at)
  const currentHotWaterCylinder = hotWaterCylinders.find(x => x.uuid === design.current_hot_water_cylinder_uuid)

  // Summary calculations
  const totalHeatLossKw = getTotalHeatLossWatts(survey, designTempC, groundTempC) / 1000

  // calculate demandMet by room groups, not independent rooms.
  // because some rooms are gaining heat from the other rooms in the group
  const roomGroupsHeatDemandMet: RoomGroupHeatDemandMet[] = chain(survey.floors.flatMap(x => x.rooms.map(y => ({ ...y, floor: x }))))
    .groupBy(x => x.room_group_uuid ? x.room_group_uuid : x.uuid)
    .map((values, key) => {
      const roomGroupRadiatorWatts = sum(values.map(r => getRoomEmitterWatts(r, design, survey, designTempC, groundTempC)))
      const roomGroupRoomWatts = sum(values.map(r => getRoomWatts(r, r.floor.rooms, designTempC, groundTempC, survey)))
      const status: EmitterDemandStatus = roomGroupRadiatorWatts > roomGroupRoomWatts ? 'Sufficient' : design.undersized_emitter_rooms.some(x => x.room_uuid === key) ? 'Accepted' : 'Insufficient'
      return { roomGroupKey: key, status }
    })
    .value()
  const emitterDemandStatus: EmitterDemandStatus = roomGroupsHeatDemandMet.every(x => x.status === 'Sufficient')
    ? 'Sufficient' // sufficient if all marked sufficient
  //   Insufficient if any marked insufficient
    : roomGroupsHeatDemandMet.some(x => x.status === 'Insufficient')
      ? 'Insufficient'
      : 'Accepted' // accepted if not all sufficient and none insufficient

  // Heat pump calculations
  const scopSpaceHeating = getHeatPumpScopAtFlowTemp(currentHeatPump?.range_heat_pump, design.flow_temp)
  const soundAssessmentData = getSoundAssessmentData(survey.sound_barrier_uuid, survey.reflective_surfaces, survey.sound_distance, currentHeatPump?.range_heat_pump?.sound_power_max_dba)
  const heatPumpCapacityResult = getHeatPumpCapacityAtOutsideTempAndFlowTemp(currentHeatPump?.range_heat_pump, designTempC, design.flow_temp)
  const soundAssessment = currentHeatPump ? soundAssessmentData?.finalResultDba ?? 0 : 0

  // Radiator calculations
  const roomOrRoomGroups = survey.floors.flatMap(x => x.rooms.map(y => ({ uuid: y.uuid, group_uuid: y.room_group_uuid })))
  const addedOrReplacedRadiators = design.radiators
    .filter(x => roomOrRoomGroups.some(y => y.uuid === x.room_uuid || y.group_uuid === x.room_uuid))
    .map(r => ({
      radiator_type_uuid: r.emitter_type === 'RADIATOR' ? r.radiator_type?.uuid : undefined,
      name: getEmitterTypeName(r),
      dimensions: getEmitterSizeName(r),
      badge: getRadiatorBadge(r, false, design.removed_radiator_uuids, false)
    }))

  const emitterRows = [...addedOrReplacedRadiators]

  const emitterColumns: Array<TableColumn<typeof emitterRows[0]>> = [
    { name: 'Item', render: (row) => <div>{row.name}</div> },
    { name: 'Dimensions', render: (row) => <div>{row.dimensions}</div> },
    { name: 'Badge', render: (row) => <div className='flex justify-end'>{row.badge}</div> }
  ]

  // Hot water calculations
  const cylinderReheatRow = getCylinderReheatCalculationRow(currentHotWaterCylinder, design.hot_water_storage_temperature_c, currentHeatPump?.range_heat_pump, designTempC)

  const numberOfOccupants = getNumberOfOccupants(survey)
  const dailyHotWaterVolumeL = getDailyHotWaterVolumeL(numberOfOccupants, survey.volume_per_person_l)
  const hotWaterRowNormal = getHotWaterCalculationRowNormalOperation(currentHeatPump?.range_heat_pump, dailyHotWaterVolumeL, design.hot_water_storage_temperature_c)
  const hotWaterRowLegionella = getHotWaterCalculationRowLegionella(design.hot_water_storage_temperature_c, currentHotWaterCylinder?.litres ?? 0, survey.legionnaires_cycle_weeks)
  const hotWaterConsumptionAnnual = hotWaterRowNormal.electricityKWhPerYear + hotWaterRowLegionella.electricityKWhPerYear
  const scopHotWater = hotWaterRowNormal.heatingEfficiency

  // Performance calculations
  // Ignore legionella for now in HDD based performance estimate - add back in when use a less conservative efficiency
  const designHotWaterDemandKwh = hotWaterRowNormal.heatEnergyKwhPerCycle * hotWaterRowNormal.cyclesPerYear
  const heatLossWattsPerKelvin = getHeatTransferCoefficientWattsPerKelvin(survey, designTempC, groundTempC)
  const performanceEstimateSummary = getPerformanceEstimateSummary(
    survey, heatLossWattsPerKelvin, degreeDays, designHotWaterDemandKwh, scopHotWater, scopSpaceHeating, eligibleForHeatPumpPlus(currentHeatPump))

  // Use HDD based values for bill estimates for now - allow user to choose later
  const annualBillEstimateGBP = performanceEstimateSummary.hddEstimate.proposed.costPriceCap

  const performanceEstimateColumns: Array<TableColumn<PerformanceEstimateRow>> = [
    {
      key: 'name',
      name: 'Name',
      render: (row) => <> {row.name === 'Savings' ? <div className='font-bold'>{row.name}</div> : <div>{row.name}</div>}</>
    },
    {
      key: 'kwh',
      name: 'Energy',
      render: (row) => <> {row.name === 'Savings' ? <div className='font-bold'>{row.kwh}</div> : <div>{row.kwh}</div>}</>
    },
    {
      key: 'costUserEnteredTariff', // render with the user entered tariff so they can play. In report this won't be show, the other 3 options will be
      name: 'Bills',
      render: (row) => <> {row.name === 'Savings' ? <div className='font-bold'>{row.costUserEnteredTariff}</div> : <div>{row.costUserEnteredTariff}</div>}</>
    },
    {
      key: 'emissionsKG',
      name: 'Emissions',
      render: (row) => <> {row.name === 'Savings' ? <div className='font-bold'>{row.emissionsKG}</div> : <div>{row.emissionsKG}</div>}</>
    }
  ]

  // Pipework calculations
  const designedEmitters = getFullEmittersListByStatus(survey, design)
    .filter(x => x.status !== 'REMOVED' && x.radiator.type !== 'SECONDARY')
    .map(x => ({
      ...(x.status === 'REPLACED' && x.replacedBy ? x.replacedBy : x.radiator),
      room: x.room,
      watts: getEmitterWatts(x.status === 'REPLACED' && x.replacedBy ? x.replacedBy : x.radiator, x.room, design, survey, designTempC, groundTempC),
      coverageM2: x.radiator.emitter_type === 'UNDERFLOOR' ? getUnderfloorHeatingOutput(x.radiator, survey.manifolds, getRoomTemp(x.room, survey), getFloorAreaM2(x.room.walls), design.flow_temp, design.delta_t_flow_return_c).activeAreaM2 : undefined
    })) as HydronicEmitterWithRoomAndWatts[]

  // Get the index emitter. If the survey's index emitter has been replaced then set that replacement emitter as the index emitter apart from if an override has been set in the design (this means as soon as the user changes it in design the link to the survey is lost)
  const indexEmitterReplacementUUID = designedEmitters.find(x => x.type === 'DESIGN' && x.replaces_uuid === survey.index_emitter_uuid)?.uuid
  const indexEmitterUUID = design.index_emitter_uuid_override ?? indexEmitterReplacementUUID ?? survey.index_emitter_uuid

  // Get the secondary emitters. If the survey's secondary index emitters have been replaced then set those replacement emitters as the secondary emitters
  const secondaryEmitterUUIDsThroughReplacement: string[] = designedEmitters.filter(x => x.type === 'DESIGN' && x.replaces_uuid && survey.secondary_index_emitter_uuids.includes(x.replaces_uuid)).map(x => x.uuid!)
  const secondaryEmitterUUIDSAll: string[] = [
    ...survey.secondary_index_emitter_uuids.filter(x => !design.removed_secondary_index_emitter_uuids.includes(x)), // remaining survey emitters that were on the survey index circuit
    ...secondaryEmitterUUIDsThroughReplacement.filter(x => !design.removed_secondary_index_emitter_uuids.includes(x)), // survey emitters that were on the survey index circuit but have been replaced
    ...design.secondary_index_emitter_uuids // emitters that have been added on the design side
  ].filter(x => designedEmitters.some(y => y.uuid === x)) // only include if it's in the designed emitters list (normally selecting from design rads so not strictly needed, but clearer this way)
  const secondaryEmitterUUIDS = Array.from(new Set(secondaryEmitterUUIDSAll)) // remove duplicates - unlikely to occur but could if you added a survey to the design list of emitters and then added it on the survey side

  const pipeDataList = getPipeData(survey, design, design.delta_t_flow_return_c, design.flow_temp, heatPumpCapacityResult.capacityKw, designedEmitters, indexEmitterUUID, secondaryEmitterUUIDS)

  const settingsCompleted = survey.completed_sections.includes('SETTINGS')
  const materialsCompleted = survey.completed_sections.includes('MATERIALS')
  const useFloorplanFlow =
    survey.floors.length === 0 &&
    ![FLOW_FINISHED_VALUE, FLOW_CANCELLED_VALUE].includes(survey.flags_floorplan_pages_completed) && // Cancelled flow or completed flow.
    (!settingsCompleted || !materialsCompleted) &&
    !isPreFlowFeatureSurvey(survey)

  // Quote calculations
  // TODO: Remove
  const lineItemColumns: Array<TableColumn<QuoteLineItem>> = [
    { key: 'name', name: 'Item' },
    { key: 'quantity', name: 'Qty' },
    { key: 'value', name: 'Value', render: (row) => <div className='text-gray-600'>£{row.value}</div> }
  ]

  const defaultLineItems: QuoteLineItem[] = []

  const ROUTES = [{
    path: /survey\/site_details/,
    component: () => <SiteDetails
      lead={lead}
      postcodeLocation={latLng!} // we ensure that ths is not null on page load
      survey={survey}
      setSurvey={setSurvey}
      files={files}
      setFiles={setFiles}
      companyUUID={companyPublicInfo.uuid}
      isOffline={isOffline}
      allSynced={allSynced}
    />
  }, {
    path: /survey\/existing_heating/,
    component: () => <ExistingHeatingSystem
      survey={survey}
      setSurvey={setSurvey}
      files={files}
      setFiles={setFiles}
      companyUUID={companyPublicInfo.uuid}
      isOffline={isOffline}
      allSynced={allSynced}
    />
  }, {
    path: /survey\/heat_pump/,
    component: () => <HeatPumpLocation
      survey={survey}
      design={design}
      currentHeatPump={currentHeatPump}
      setSurvey={setSurvey}
      setDesign={setDesign}
      heatPumps={hydratedHeatPumps}
      files={files}
      setFiles={setFiles}
      locationImages={survey.location_images}
      setLocationImages={setLocationImages}
      soundCalculation={soundAssessment}
      designTempC={designTempC}
      flowTempC={design.flow_temp}
      companyUUID={companyPublicInfo.uuid}
      lead={lead}
      isOffline={isOffline}
      allSynced={allSynced}
    />
  }, {
    path: /survey\/cylinder_location/,
    component: () => <CylinderLocation
      survey={survey}
      setSurvey={setSurvey}
      files={files}
      setFiles={setFiles}
      companyUUID={companyPublicInfo.uuid}
      isOffline={isOffline}
      allSynced={allSynced}
    />
  }, {
    path: /survey\/electrics/,
    component: () => <Electrics
      survey={survey}
      setSurvey={setSurvey}
      files={files}
      setFiles={setFiles}
      companyUUID={companyPublicInfo.uuid}
      isOffline={isOffline}
      allSynced={allSynced}
    />
  }, {
    path: /survey\/settings/,
    component: () => <SurveySettings
      survey={survey}
      setSurvey={setSurvey}
      designTempDefault={designTempDefaultC}
      altitudeDefaultM={lead.property.altitudeM ?? 0}
      isOffline={isOffline}
      allSynced={allSynced}
    />
  }, {
    path: /survey\/materials/,
    component: () => <DefaultMaterialsTab
      materials={[...adminContext.data.genericMaterials!, ...customMaterials]}
      setMaterials={setCustomMaterials}
      materialsLayers={adminContext.data.materialsGenericLayers!}
      setSurvey={setSurvey}
      survey={survey}
      companyUUID={companyPublicInfo.uuid}
      isOffline={isOffline}
      allSynced={allSynced}
    />
  }, {
    path: /survey\/floors/,
    component: () => <FloorList
      setFlowTemp={setFlowTemp}
      floors={survey.floors}
      designTempC={designTempC}
      groundTempC={groundTempC}
      minFlowTemp={minFlowTemp}
      maxFlowTemp={maxFlowTemp}
      survey={survey}
      design={design}
      setCurrentRoomId={setCurrentRoomId}
      setSurvey={setSurvey}
      navigateTo={navigateToWithBasePath}
      isOffline={isOffline}
      allSynced={allSynced}
      useFloorplanFlow={useFloorplanFlow}
      customMaterials={customMaterials}
      setCustomMaterials={setCustomMaterials}
      lead={lead}
      companyPublicInfo={companyPublicInfo}
    />
  }, {
    path: /survey\/new_floor/,
    component: () => <NewFloor
      survey={survey}
      setSurvey={setSurvey}
      floors={survey.floors}
      navigateTo={navigateToWithBasePath}
      isOffline={isOffline}
      allSynced={allSynced}
    />
  }, {
    path: /survey\/pipework/,
    component: () => <Pipework
      survey={survey}
      setSurvey={setSurvey}
      isOffline={isOffline}
      allSynced={allSynced}
    />
  }, {
    path: /design\/heat_pump/,
    component: () => <HeatPumpDesign
      survey={survey}
      design={design}
      currentHeatPump={currentHeatPump}
      setSurvey={setSurvey}
      setDesign={setDesign}
      heatPumps={hydratedHeatPumps}
      files={files}
      setFiles={setFiles}
      locationImages={survey.location_images}
      setLocationImages={setLocationImages}
      soundCalculation={soundAssessment}
      designTempC={designTempC}
      minFlowTemp={minFlowTemp}
      maxFlowTemp={maxFlowTemp}
      scop={scopSpaceHeating}
      totalHeatLossKW={totalHeatLossKw}
      companyUUID={companyPublicInfo.uuid}
      isOffline={isOffline}
      allSynced={allSynced}
    />
  }, {
    path: /design\/emitters/,
    component: () => <EmitterDesignPage
      customRadiatorModels={customRadiatorModels}
      setCustomRadiatorModels={setCustomRadiatorModels}
      allRadiatorModels={allRadiatorModels}
      floors={survey.floors}
      setFloor={setFloor}
      setFlowTemp={setFlowTemp}
      minFlowTemp={minFlowTemp}
      maxFlowTemp={maxFlowTemp}
      designTempC={designTempC}
      groundTempC={groundTempC}
      survey={survey}
      design={design}
      setDesign={setDesign}
      annualBillEstimateGBP={annualBillEstimateGBP}
      files={files}
      emitterDemandStatus={emitterDemandStatus}
      totalHeatLossKw={totalHeatLossKw}
      companyUuid={companyPublicInfo.uuid}
      setFiles={setFiles}
      setSurvey={setSurvey}
      isOffline={isOffline}
      allSynced={allSynced}
    />
  }, {
    path: /design\/cylinders/,
    component: () => <CylinderDesignPage
      files={files}
      setFiles={setFiles}
      minFlowTemp={minFlowTemp}
      maxFlowTemp={maxFlowTemp}
      survey={survey}
      setSurvey={setSurvey}
      cylinderReheatRow={cylinderReheatRow}
      hotWaterRowNormal={hotWaterRowNormal}
      hotWaterRowLegionella={hotWaterRowLegionella}
      design={design}
      setDesign={setDesign}
      hotWaterCylinders={hotWaterCylinders}
      currentHotWaterCylinderId={design.current_hot_water_cylinder_uuid}
      companyUUID={companyPublicInfo.uuid}
      isOffline={isOffline}
      allSynced={allSynced}
    />
  }, {
    path: /design\/pipework/,
    component: () => <PipeworkDesignPage
      flowTempC={design.flow_temp}
      deltaTFlowReturnC={design.delta_t_flow_return_c}
      design={design}
      setDesign={setDesign}
      survey={survey}
      pipeDataList={pipeDataList}
      designedEmitters={designedEmitters}
      indexEmitterUUID={indexEmitterUUID}
      secondaryEmitterUUIDS={secondaryEmitterUUIDS}
      secondaryEmitterUUIDsThroughReplacement={secondaryEmitterUUIDsThroughReplacement}
      isOffline={isOffline}
      allSynced={allSynced}
    />
  }, {
    path: /design\/performance/,
    component: () => <PerformanceDesignPage
      survey={survey}
      setSurvey={setSurvey}
      postcode={lead.property.postcode}
      performanceEstimateColumns={performanceEstimateColumns}
      performanceEstimateSummary={performanceEstimateSummary}
      degreeDays={degreeDays}
      heatLossWattsPerKelvin={heatLossWattsPerKelvin}
      scopSpaceHeating={scopSpaceHeating}
      scopHotWater={scopHotWater}
      isOffline={isOffline}
      allSynced={allSynced}
    />
  }, {
    path: /design\/quote/,
    component: () => <QuoteDesignPage
      defaultLineItems={defaultLineItems}
      lineItems={design.quote_line_items}
      setLineItems={setQuoteLineItems}
      isOffline={isOffline}
      allSynced={allSynced}
    />
  }]
  const page = ROUTES.find(x => x.path.test(currentPathWithoutBase))
  if (page) return page.component()

  // Base
  const companyRole = getRoleForCompany(adminContext.data?.user, companyPublicInfo.subdomain)

  return <TabbedDashboardLayout
    onBack={() => navigateTo(`/${companyPublicInfo.subdomain}/admin/enquiries`)}
    navigateTo={navigateTo}
    userRole={companyRole}
    title={getAddressIncludingPostcode(lead)}
    basePath={`/${companyPublicInfo.subdomain}/admin/quotes/${leadUUID}`}
    selectedTabId={tab}
    selectedSecondaryTabId={secondaryTab}
    isOffline={adminContext.isOffline}
    allSynced={leadSaved}
    lead={lead}
    setLead={setLead}
    tabs={compact([
      {
        id: 'estimate',
        label: 'Estimate',
        roles: [USER_ROLE_SIMPLE, USER_ROLE_HAS_SURVEY_ACCESS, USER_ROLE_SUPERADMIN],
        content: <EnquiryManagerDashboard
          lead={lead}
          setLead={setLead}
          estimate={estimate}
          isOffline={adminContext.isOffline}
          navigateTo={navigateTo}
          companyPublicInfo={companyPublicInfo}
          setEstimates={setEstimates}
        />,
        secondaryTabs: [
          {
            id: 'dashboard',
            label: 'Dashboard',
            content: <EnquiryManagerDashboard
              navigateTo={navigateTo}
              companyPublicInfo={companyPublicInfo}
              lead={lead}
              setLead={setLead}
              estimate={estimate}
              isOffline={adminContext.isOffline}
              setEstimates={setEstimates}
            />
          },
          {
            id: 'enquiry-data',
            label: 'Enquiry data',
            content: <EnquiryData
              lead={lead}
              setLead={setLead}
              estimate={estimate}
            />
          },
          {
            id: 'history',
            label: 'History',
            content: <EnquiryHistory
              lead={lead}
              estimate={estimate}
              calculatedEstimates={estimates}
              subdomain={companyPublicInfo.subdomain}
              reloadTrigger={loadData}
            />
          },
          {
            id: 'cost-estimate',
            label: 'Edit cost estimate',
            content: <CostEstimateEditor
              navigateTo={navigateTo}
              companyPublicInfo={companyPublicInfo}
              estimate={estimate}
              lead={lead}
              setLead={setLead}
            />,
            hidden: true
          },
          {
            id: 'preview',
            label: 'Preview',
            content: <EstimatePreview
              companyPublicInfo={companyPublicInfo}
              estimate={estimate}
              lead={lead}
            />
          }
        ]
      },
      {
        id: 'survey',
        label: 'Survey',
        content: <Survey
          lead={lead}
          survey={survey}
          navigateTo={navigateToWithBasePath}
          adminContext={adminContext}
          leadUUID={leadUUID}
          setCustomMaterials={setCustomMaterials}
          customMaterials={customMaterials}
          surveyDB={surveyDB}
          companyPublicInfo={companyPublicInfo}
          useFloorplanFlow={useFloorplanFlow}
        />
      },
      {
        id: 'design',
        label: 'Design',
        isDisabled: !surveyDB,
        content: <DesignHome
          design={design}
          setDesign={setDesign}
          soundAssessment={soundAssessment}
          currentHeatPump={currentHeatPump}
          currentHotWaterCylinder={currentHotWaterCylinder}
          heatPumpCapacityResult={heatPumpCapacityResult}
          totalHeatLossKwSurvey={totalHeatLossKw}
          annualBillEstimateGBP={annualBillEstimateGBP}
          reheatTimeHoursAndMinutes={cylinderReheatRow?.reheatTimeHoursAndMinutes ?? ''}
          hotWaterConsumptionAnnual={hotWaterConsumptionAnnual}
          performanceEstimateColumns={performanceEstimateColumns}
          hddPerformanceEstimate={performanceEstimateSummary.hddEstimate}
          emitterColumns={emitterColumns}
          emitterRows={emitterRows}
          scop={scopSpaceHeating}
          minFlowTemp={minFlowTemp}
          maxFlowTemp={maxFlowTemp}
          lineItemColumns={lineItemColumns}
          emitterDemandStatus={emitterDemandStatus}
          pipeData={pipeDataList}
          navigateTo={navigateToWithBasePath}
        />
      },
      {
        id: 'report',
        label: 'Report',
        isDisabled: !surveyDB || !surveyDB.data.designs.every(x => x.current_heat_pump_uuid && x.current_hot_water_cylinder_uuid),
        content: <HeatLossReportSettings
          companyPublicInfo={companyPublicInfo}
          lead={lead}
          setLead={setLead}
          survey={survey}
          files={files}
          currentHeatPump={currentHeatPump!}
          currentHotWaterCylinder={currentHotWaterCylinder!}
        />
      },
      {
        id: 'proposal',
        label: 'Proposal',
        isDisabled: !surveyDB || !surveyDB.data.designs.every(x => x.current_heat_pump_uuid && x.current_hot_water_cylinder_uuid),
        content: <ProposalConfigurator
          currentPathWithoutBase={currentPathWithoutBase}
          currentHeatPump={currentHeatPump}
          currentHotWaterCylinder={currentHotWaterCylinder}
          // Designed radiators only - we don't want survey raditors in the quote
          emitters={designedEmitters.filter(x => x.survey_or_design === 'DESIGN')}
          navigateTo={navigateToWithBasePath}
          lead={lead}
          survey={survey}
          files={files}
          setLead={setLead}
          companyPublicInfo={companyPublicInfo}
        />
      },
      [USER_ROLE_SIMPLE, USER_ROLE_HAS_SURVEY_ACCESS, USER_ROLE_SUPERADMIN].includes(getRoleForCompany(adminContext?.data?.user, adminContext?.data?.company?.public_info.subdomain)) ? {
        id: 'invites',
        label: 'Invites',
        isDisabled: !surveyDB,
        content: <Invitations
          adminContext={adminContext}
          companyPublicInfo={companyPublicInfo}
          leadUUID={leadUUID}
          title={'Invite someone to this survey'}
          helpText='People you invite to a survey will only have access to that survey, design and report. They will not be able to see the estimate, or your settings and cost and inventory.'
        />
      } : undefined
    ])}
  />
}
