import React, { useState } from 'react'
import { PageHeader } from '../../design/components/design_page_header'
import { isPreFlowFeatureSurvey, type PropertySurvey } from '../../../../code/models/property'
import { type PagesFlowPage, usePagesFlow } from '../../../../code/use_pages_flow'
import { CompleteButton } from '../complete_button'
import { FlowPageHeader } from '../../design/components/flow_page_header'
import { Button } from '../../../../components/buttons/button'
import { type SetIndexedDb } from '../../../admin/job_layout/job_layout'
import { ChevronRight } from 'lucide-react'

type Props = {
  sectionUIName: string
  pages: PagesFlowPage[]
  flags_attribute_name: string
  onPageShow?: (flagBit: number) => void
  onPageComplete: (flagBit: number) => void
  onClose?: () => void
  onFormComplete?: () => void
  startPage?: number // flagBit of the page to start with

  // "Complete" button preferences
  completeSectionID?: string
  completionChecks?: boolean[]
  hideCompleteButton?: boolean

  // some global props to manage the state
  survey: PropertySurvey
  setSurvey: SetIndexedDb<PropertySurvey>
  isOffline: boolean
  allSynced: boolean
}

// is user exits the flow
export const FLOW_CANCELLED_VALUE = -1
// is user finishes the flow. It's a separate value to track stats
export const FLOW_FINISHED_VALUE = -2

type ViewMode = 'FLOW' | 'FULL'

export const FlowWrapperContext = React.createContext<{ viewMode: ViewMode } | undefined>(undefined)

export function SurveySectionFlowWrapper ({
  completeSectionID,
  sectionUIName,
  pages,
  flags_attribute_name,
  onPageShow,
  onPageComplete,
  onClose,
  startPage,
  survey,
  setSurvey,
  completionChecks,
  hideCompleteButton,
  isOffline,
  allSynced,
  onFormComplete
}: Props) {
  const isFinishedOrCancelledFlow =
    survey[flags_attribute_name] === FLOW_FINISHED_VALUE ||
    survey[flags_attribute_name] === FLOW_CANCELLED_VALUE

  const [viewMode, setViewMode] = useState<ViewMode>(
    (isFinishedOrCancelledFlow || isPreFlowFeatureSurvey(survey)) ? 'FULL' : 'FLOW'
  )

  const [currentPageBitFlag, next, prev] = usePagesFlow({
    flagsCompletedPages: survey[flags_attribute_name] as number,
    startPage,
    onEndOfFlow: () => {
      handleOnFlowFinish()
    },
    onPageCompleted: onPageComplete,
    pages
  })

  const currentPage = pages.find(p => p.flagBit === currentPageBitFlag)
  onPageShow && onPageShow(currentPageBitFlag)

  const handleOnFlowFinish = async () => {
    await setSurvey(prev => ({ ...prev, [flags_attribute_name]: FLOW_FINISHED_VALUE }))
    onFormComplete && onFormComplete()
    setViewMode('FULL')
  }

  return <FlowWrapperContext.Provider value={{ viewMode }}>
    <div className='flex flex-col h-full min-h-0'>

      {/* TOP CONTROLS */}
      { viewMode === 'FULL' &&
        <PageHeader isOffline={isOffline} allSynced={allSynced} title={sectionUIName} onBack={() => window.history.back()} />
      }
      { viewMode === 'FLOW' &&
        <FlowPageHeader
          progress={currentPage?.pbValue ?? 0}
          title={sectionUIName}
          onBack={() => {
            // if the first page, exit the section
            if (currentPageBitFlag === 0x001) {
              window.history.back()
            } else {
              // otherwise, go to the previous page
              prev()
            }
          }}
          onClose={async () => {
            await setSurvey(prev => ({ ...prev, [flags_attribute_name]: FLOW_CANCELLED_VALUE }))

            // if there is a custom onClose function, call it
            if (onClose) {
              onClose()
            } else {
              setViewMode('FULL')
            }
          }}
          // TODO: disable Skip button for now
          // onSkip={next}
        />
      }

      {/* PAGE CONTENT */}
      <div className="p-5 bg-white flex-col gap-6 flex flex-grow overflow-y-auto">
        {/* FLOW mode view */}
        {viewMode === 'FLOW' && currentPage?.page}

        {/* FULL mode view */}
        {viewMode === 'FULL' && <>
          {/* render pages one-by-one with divider */}
          {pages.map((p, idx) => {
            const shouldHide = p.hideOrSkipPage && p.hideOrSkipPage()

            return <React.Fragment key={idx}>
              {/* if the page not visible */}
              {shouldHide && <></>}

              {/* if the page is visible */}
              {!shouldHide && <>
                {/* add divider if not the first page */}
                {idx !== 0 && <div className="border border-gray-200"></div>}
                {p.page}
              </>}
            </React.Fragment>
          })}
        </>}
      </div>

      {/* BOTTOM CONTROLS */}
      {/* FLOW mode controls */}
      {viewMode === 'FLOW' && <>
        {/* if NOT last page */}
        { currentPageBitFlag !== pages[pages.length - 1].flagBit &&
           <div className="p-3 bg-white flex-col gap-3 flex justify-center items-center shadow-2xl">
             <Button block={true} iconRight={ChevronRight} onClick={next} colour={'DARK'}>Continue</Button>
           </div>
        }

        {/* if last page */}
        {completionChecks && completeSectionID && currentPageBitFlag === pages[pages.length - 1].flagBit &&
          (hideCompleteButton
            ? <div className="p-3 bg-white flex-col gap-3 flex justify-center items-center shadow-2xl">
                <Button block={true} iconRight={ChevronRight} onClick={handleOnFlowFinish} colour={'DARK'}>Continue</Button>
              </div>
            : <CompleteButton
                page={completeSectionID}
                survey={survey}
                setSurvey={setSurvey}
                pageChecks={completionChecks}
                onBack={async () => {
                  await handleOnFlowFinish()
                }}
                secondaryButton={
                  <Button
                    className={'h-full'}
                    block={true}
                    onClick={() => {
                      handleOnFlowFinish()
                    }}
                    colour={'LIGHT'}
                  >Close section</Button>
                }
              />)
        }
      </>}

      {/* FULL mode controls */}
      {!hideCompleteButton && completeSectionID && completionChecks && viewMode === 'FULL' && <>
        <CompleteButton
          page={completeSectionID}
          survey={survey}
          setSurvey={setSurvey}
          pageChecks={completionChecks}
          onBack={async () => {
            await handleOnFlowFinish()
          }}
        />
      </>}
    </div>
  </FlowWrapperContext.Provider>
}
