import React from 'react'
import { CanvasNav } from './canvas_nav'
import { type Wall } from '../../../code/models/wall'
import { type CurrentFloorPlanPage } from './floor'
import { MATERIAL_ELEMENT_NAMES } from '../../../code/models/material'
import { DoorClosed, Ruler, Scissors, PictureInPicture2, Box } from 'lucide-react'

type WallButtonsProps = {
  setPage: (page: CurrentFloorPlanPage, open: boolean) => void
  currentWall: Wall
}

export const WallButtons = ({ setPage, currentWall }: WallButtonsProps) => {
  return <CanvasNav title={MATERIAL_ELEMENT_NAMES[currentWall.material!.applicable_to] ?? 'Wall'} items={[
    { name: 'Length', onClick: () => setPage('WALL_LENGTH', true), icon: Ruler, variant: 'DEFAULT' },
    { name: 'Materials', onClick: () => setPage('WALL_MATERIALS', true), icon: Box, variant: 'DEFAULT' },
    { name: `${currentWall.windows.length > 0 ? currentWall.windows.length : ''} Window${currentWall.windows.length > 1 || currentWall.windows.length === 0 ? 's' : ''}`, onClick: () => setPage('WALL_WINDOWS', true), icon: PictureInPicture2, variant: currentWall.windows.length > 0 ? 'BLUE' : 'DEFAULT' },
    { name: `${currentWall.doors.length > 0 ? currentWall.doors.length : ''} Door${currentWall.doors.length > 1 || currentWall.doors.length === 0 ? 's' : ''}`, onClick: () => setPage('WALL_DOORS', true), icon: DoorClosed, variant: currentWall.doors.length > 0 ? 'BLUE' : 'DEFAULT' },
    { name: 'Remove', onClick: () => setPage('REMOVE_WALL', true), icon: Scissors, disabled: !currentWall.other_room_uuid, variant: 'DEFAULT' }
  ]} />
}
