import React, { useEffect, useRef, useState } from 'react'
import { type Company } from '../../../../code/models/company'
import { Section } from '../../../../components/containers/section'
import { Input } from '../../../../components/inputs_and_selections/input'
import { Map, Marker, useAutocomplete } from '@vis.gl/react-google-maps'
import { SettingsLayout } from '../components/settings_layout'
import { VerticalFormGroup } from '../../../../components/inputs_and_selections/vertical_form_group'
import { FormLabel } from '../../../../components/inputs_and_selections/form_label'
import { validateEmail, validatePhone } from '../../../../code/validators'
import { Info } from '../../../../components/buttons/info'

export const AccountProfile = ({ company, setCompany }: { company: Company, setCompany: (c: Company) => void }) => {
  const [addressLocation, setAddressLocation] = useState<google.maps.LatLngLiteral>()
  const [mapCenter, setMapCenter] = useState<google.maps.LatLngLiteral>()

  const inputRef = useRef<HTMLInputElement>(null)

  useEffect(() => {
    setAddressLocation({ lat: company.lat ?? 0, lng: company.lng ?? 0 })
    setMapCenter({ lat: company.lat ?? 0, lng: company.lng ?? 0 })
  }, [])

  const onPlaceChanged = (place: google.maps.places.PlaceResult) => {
    // OnPlaceChanged fires when we click an autocompleted suggestion when typing an address in the Address input.
    // Update the company address, company lat and lng with the new details.
    // And update the map view with the pin on the new location.
    if (place) {
      setCompany({ ...company, public_info: { ...company.public_info, address: place.formatted_address ?? place.name ?? '' }, lat: place.geometry?.location?.lat() ?? 0, lng: place.geometry?.location?.lng() ?? 0 })
      setAddressLocation(place.geometry?.location?.toJSON())
      setMapCenter(place.geometry?.location?.toJSON())
    }
  }

  useAutocomplete({
    inputField: inputRef?.current,
    onPlaceChanged
  })

  return <SettingsLayout
    heading='Profile'
    description='Manage your Spruce profile'
  >
    <Section border title="Account details">
      <div className='flex flex-col gap-4'>
        <div className='grid grid-cols-1 sm:grid-cols-2 gap-4'>
          <VerticalFormGroup
            formLabel={<FormLabel labelText='First name' />}
            input={<Input value={company.public_info.installer_first_name}
              setValue={(e) => setCompany({
                ...company,
                public_info: {
                  ...company.public_info,
                  installer_first_name: e
                }
              })}/>}
          />
          <VerticalFormGroup
            formLabel={<FormLabel labelText='Last name' />}
            input={<Input value={company.public_info.installer_last_name}
              setValue={(e) => setCompany({
                ...company,
                public_info: {
                  ...company.public_info,
                  installer_last_name: e
                }
              })}/>}
          />
          <VerticalFormGroup
            formLabel={<FormLabel
              labelText='Notification email'
              info={
                <Info
                  infoModalHeader='Notification email'
                  infoModalBody='This is the email address where you receive notification emails from Spruce when customers submit an enquiry, book a heat loss survey, or request a callback. It can be different from your login email.'
                />}
            />}
            input={<Input
              value={company.notification_email}
              setValue={(e) => {
                if (validateEmail(e).value) {
                  setCompany({ ...company, notification_email: e })
                }
              }}
              validator={validateEmail}
              validateImmediately
            />}
          />
          <VerticalFormGroup
            formLabel={<FormLabel labelText='Phone number'/>}
            input={<Input
              value={company.phone}
              setValue={(e) => {
                if (validatePhone(e).value) {
                  setCompany({ ...company, phone: e })
                }
              }}
              validator={validatePhone}
              validateImmediately
            />}
          />
        </div>
        <VerticalFormGroup
          formLabel={<FormLabel labelText='Company name'/>}
          input={<Input
            value={company.public_info.name}
            setValue={(e) => {
              setCompany({ ...company, public_info: { ...company.public_info, name: e } })
            }}
          />}
        />
        <VerticalFormGroup
          formLabel={<FormLabel labelText='Company address'/>}
          input={
            <>
              <Input
                ref={inputRef}
                value={company.public_info.address}
                setValue={(e) => setCompany({ ...company, public_info: { ...company.public_info, address: e } })}
              />
              {(addressLocation?.lat !== 0 && addressLocation?.lng !== 0) &&
                 <Map
                   onClick={(e) => {
                     const newLocation = e.detail.latLng
                     setAddressLocation(newLocation ?? undefined)
                     setCompany({
                       ...company,
                       lat: e.detail.latLng?.lat ?? undefined,
                       lng: e.detail.latLng?.lng ?? undefined
                     })
                   }}
                   streetViewControl={false} zoom={18}
                   className="min-h-[200px] w-full h-full rounded overflow-hidden" mapTypeId="satellite"
                   center={mapCenter}
                 >
                   <Marker position={addressLocation}/>
                 </Map>}
            </>
          }
        />
      </div>
    </Section>
  </SettingsLayout>
}
