import React, { type Dispatch, type SetStateAction, useState } from 'react'
import { orderBy, uniq } from 'lodash'
import { getHeatPumpCapacityAtOutsideTempAndFlowTemp } from '../../code/models/range_heat_pump'
import { PageHeader } from './design/components/design_page_header'
import { RadioGroup } from '../../components/inputs_and_selections/radio'
import { ListItem } from '../../components/content_display/list_item'
import { Badge } from '../../components/indicators_and_messaging/badge'
import type { SurveyDesign } from '../../code/models/property'
import { type InventoryHeatPump } from '../../code/models/inventory'

type SelectAHeatPumpPageProps = {
  heatPumps: InventoryHeatPump[]
  designTempC: number
  flowTempC: number
  totalHeatLossKW?: number
  setCurrentPage: Dispatch<SetStateAction<string>>
  design: SurveyDesign
  setDesign: (design: SurveyDesign) => void
  isOffline: boolean
  allSynced: boolean
}

export const SelectAHeatPumpPage = ({ heatPumps, designTempC, flowTempC, totalHeatLossKW, setCurrentPage, design, setDesign, isOffline, allSynced }: SelectAHeatPumpPageProps) => {
  const [rangeFilter, setRangeFilter] = useState<string>('All')
  const ranges = ['All', ...uniq(heatPumps.map(x => x.range_heat_pump?.brand_range?.name)).filter(x => !!x)]
  const filteredHeatPumps = orderBy(heatPumps.filter(x => rangeFilter === 'All' || x.range_heat_pump?.brand_range?.name === rangeFilter).map(x => ({
    uuid: x.uuid,
    name: x.name,
    capacity: getHeatPumpCapacityAtOutsideTempAndFlowTemp(x.range_heat_pump, designTempC, flowTempC).capacityKw
  })), x => x.capacity)

  return <div className='flex flex-col'>
    <PageHeader title='Select a heat pump' onBack={() => setCurrentPage('MAIN')} isOffline={isOffline} allSynced={allSynced} >
      <div className='flex flex-col'>
        <div className='flex flex-col gap-3'>
          <div className="bg-white flex-col flex text-left">
            <div className="flex-col gap-2 flex">
              <div className="text-gray-500 text-xs font-semibold uppercase">Range</div>
              <RadioGroup items={ranges.map(x => ({
                name: x!,
                onClick: () => setRangeFilter(x),
                variant: rangeFilter === x ? 'ACTIVE' : 'DEFAULT'
              }))}/>
            </div>
          </div>
        </div>
      </div>
    </PageHeader>

    <div className="bg-white rounded-md flex-col flex divide-y divide-gray-200 ">
      {filteredHeatPumps.map(x =>
        <ListItem
          onClick={() => {
            setDesign({ ...design, current_heat_pump_uuid: x.uuid })
            setCurrentPage('MAIN')
          }}
          key={x.uuid}
          primaryText={x.name}
          rightBadge={ totalHeatLossKW
            ? <Badge
                color={x.capacity >= totalHeatLossKW ? 'GREEN' : 'RED'}
                text={x.capacity.toFixed(1) + ' kW'}
              />
            : <Badge
                color={'LIGHT'}
                text={x.capacity.toFixed(1) + ' kW'}/>
          }
        />)
      }
    </div>
  </div>
}
