import React from 'react'
import { FlowWrapperContext } from '../components/survey_section_flow_wrapper'
import { SurveyEHSContext } from '../existing_heating_system'
import { PhotoScroll } from '../../../../components/inputs_and_selections/photo_scroll'

export const EHSPhotosBlock = () => {
  const ehsContext = React.useContext(SurveyEHSContext)
  const flowContext = React.useContext(FlowWrapperContext)
  const { survey, setSurvey, files, setFiles, companyUUID } = ehsContext!

  const images = survey.existing_system_photos.map(x => files.find(y => y.uuid === x.image_uuid)!)

  const deleteImage = (imageUUID: string) => {
    setSurvey({ ...survey, existing_system_photos: survey.existing_system_photos.filter(x => x.image_uuid !== imageUUID) })
  }

  const isFullScreen = images.length === 0 && flowContext!.viewMode === 'FLOW'

  return <>
    <div className={isFullScreen ? 'p-5 h-full bg-gray-50 flex flex-col items-center justify-center gap-4' : ''}>
      {isFullScreen &&
        <div className='flex flex-col gap-1 items-center'>
          <div className="text-gray-900 text-xl font-bold">Photos of existing system</div>
          <div className="text-gray-500 text-sm text-center">
            Please take at least one photo of the existing boiler (or equivalent).
          </div>
        </div>
      }
      <div className="flex-col gap-3 flex">
        {!isFullScreen && <div className="text-gray-900 font-bold">Photos of system</div>}
        <PhotoScroll viewMode={isFullScreen ? 'COMPACT' : 'NORMAL'} images={images} addImage={(fileWrapper) => {
          fileWrapper && setFiles(prev => [...prev, { ...fileWrapper, created_at: new Date().getTime(), updated_at: new Date().getTime(), is_modified: true }])
          fileWrapper && setSurvey({
            ...survey,
            existing_system_photos: [...survey.existing_system_photos, {
              uuid: crypto.randomUUID(),
              entity_uuid: 'survey',
              entity_attribute: 'existing_system_photos',
              image_uuid: fileWrapper.uuid!
            }]
          })
        }} deleteImage={deleteImage} companyUUID={companyUUID} />
      </div>
    </div>
  </>
}
