import React from 'react'
import { getRoomWatts } from '../../../code/models/heat_loss'
import { Badge } from '../../../components/indicators_and_messaging/badge'
import { type TableColumn, TableLite } from '../../../components/content_display/table_lite'
import { getEmitterSizeName, getEmitterTypeName, getFullEmittersListByStatus } from '../../../code/models/radiator'
import { HLRContext } from './heatloss_report'
import { HLRSubheaderBlock } from './partials/hlr_subheader'
import { RadiatorIcon, UFHIcon } from '../../../assets/images/survey_images/survey_images'
import { numberFormat } from '../../../code/number_format'
import { getEmitterWatts } from '../../../code/models/radiator_model'
import { chain, sum, uniq } from 'lodash'

export const HLREmittersProposed = () => {
  const hlrContext = React.useContext(HLRContext)

  const fullRadiatorsList = getFullEmittersListByStatus(hlrContext!.survey, hlrContext!.design)

  const emitters = hlrContext!.survey.floors.flatMap(floor => {
    const roomGroups = chain(floor.rooms)
      .groupBy(x => x.room_group_uuid ? x.room_group_uuid : x.uuid)
      .map((values, key) => ({ key, values }))
      .value()

    return roomGroups.flatMap(roomGroup => {
      const roomGroupRoomWatts = sum(roomGroup.values.map(r => getRoomWatts(r, floor.rooms, hlrContext!.designTempC, hlrContext!.groundTempC, hlrContext!.survey)))
      const isMultiRoomGroup = roomGroup.values.length > 1
      const name = isMultiRoomGroup
        ? roomGroup.values.map(r => r.name).join(' + ')
        : roomGroup.values[0].name

      const roomGroupRows = roomGroup.values.flatMap((room, idx) => {
        const roomRads = fullRadiatorsList
          .filter(x => x.room.uuid === room.uuid)
          .sort((a, b) => a.status === 'REMOVED' ? 1 : b.status === 'REMOVED' ? -1 : 0)

        return roomRads.map((r, index) => {
          const realRad = r.replacedBy ?? r.radiator

          let badge = <Badge color='YELLOW' text='Keep'/>
          let image = realRad.emitter_type === 'UNDERFLOOR'
            ? <img alt='' src={UFHIcon} className="p-2 w-10 h-10 rounded bg-gray-100"/>
            : <img alt='' src={RadiatorIcon} className="p-2 w-10 h-10 rounded bg-gray-100"/>

          // try to load image for surveyed radiator
          if (realRad.survey_or_design === 'SURVEY') {
            const images = realRad.photos.map(x => hlrContext!.files.find(y => y.uuid === x.image_uuid)!)
            image = images[0] ? <img src={images[0].file_base64 || `${process.env.S3_BUCKET_URL}/${images[0].file_url}`} alt='' className='w-10 h-10 rounded'/> : image
          }

          switch (r.status) {
            case 'REMOVED':
              badge = <Badge color='RED' text='Remove'/>
              break
            case 'REPLACED':
              badge = <Badge color='INDIGO' text='Replacement'/>
              // if radiators is replaced, show the replacement
              break
            case 'ADDED':
              badge = <Badge color='BLUE' text='New'/>
              break
          }

          return {
            firstRadInList: index === 0,
            firstRoomInGroupList: idx === 0,
            roomName: name,
            status: r.status,
            badge,
            image,
            type: realRad.emitter_type === 'UNDERFLOOR' ? 'UFH' : realRad.emitter_type === 'SECONDARY' ? 'Secondary' : 'Radiator',
            details: <div className='flex flex-col gap-1'>
              <div>{getEmitterTypeName(realRad)}</div>
              <div>{getEmitterSizeName(realRad)}</div>
            </div>,
            emitterOutput: Math.round(getEmitterWatts(realRad, room, hlrContext!.design, hlrContext!.survey, hlrContext!.designTempC, hlrContext!.groundTempC)),
            roomDemand: Math.round(roomGroupRoomWatts),
            roomOutput: 0, // calculated below as sum of all emitterOutput
            percentMet: 0, // calculated below based on roomOutput and roomHeatLoss
            isUndersized: hlrContext!.design.undersized_emitter_rooms.some(x => x.room_uuid === room.uuid || x.room_uuid === room.room_group_uuid)
          }
        })
      })

      // calc roomOutput
      const roomOutput = roomGroupRows.reduce((acc, radiator) => acc + (radiator.status !== 'REMOVED' ? radiator.emitterOutput : 0), 0)

      // return radiators with roomOutput updated
      return roomGroupRows.map(radiator => {
        return {
          ...radiator,
          roomOutput,
          percentMet: Math.round((roomOutput / roomGroupRoomWatts) * 100)
        }
      })
    })
  }).flat()

  const rooms = hlrContext!.survey.floors.flatMap(x => x.rooms)
  const roomGroups = uniq(rooms.map(x => x.room_group_uuid))
  const undersizedRooms = hlrContext!.design.undersized_emitter_rooms.filter(x => rooms.some(y => y.uuid === x.room_uuid) || roomGroups.includes(x.room_uuid))

  const emittersTableColumns: Array<TableColumn<typeof emitters[0]>> = [
    {
      name: 'room',
      key: 'roomName',
      render: (row) => {
        // render only if the room name renders first time in the list
        if (row.firstRadInList && row.firstRoomInGroupList) {
          return <span className='text-gray-900 text-xs'>{row.roomName}</span>
        } else {
          return <span></span>
        }
      }
    },
    { name: 'Status', key: 'status', render: (row) => <div className='flex justify-end'>{row.badge}</div> },
    { name: 'Image', key: 'image', render: (row) => <div className='flex justify-end'>{row.image}</div> },
    { name: 'Type', key: 'type', render: (row) => <span className={ row.status === 'REMOVED' ? 'text-xs line-through ' : 'text-xs'}>{row.type}</span> },
    { name: 'Details', key: 'details', render: (row) => <span className={ row.status === 'REMOVED' ? 'text-xs line-through ' : 'text-xs'}>{row.details}</span> },
    { name: 'Output', key: 'emitterOutput', render: (row) => <span className={ row.status === 'REMOVED' ? 'text-xs line-through ' : 'text-xs'}>{row.emitterOutput} W</span> },
    {
      name: 'Room demand',
      key: 'roomDemand',
      render: (row) => {
        if (row.firstRadInList && row.firstRoomInGroupList) {
          return <span className='text-xs'>{row.roomDemand} W</span>
        } else {
          return <span></span>
        }
      }
    },
    {
      name: '% heat demand met*',
      key: 'percentMet',
      render: (row) => {
        if (row.firstRadInList && row.firstRoomInGroupList) {
          return <div className='flex justify-end'>
            <Badge color={row.percentMet >= 100 ? 'GREEN' : row.isUndersized ? 'YELLOW' : 'RED'} text={row.percentMet.toString() + ' %' + (row.isUndersized ? '**' : '')}/>
          </div>
        } else {
          return <span></span>
        }
      }
    }
  ]

  return <div className='flex-col gap-6 flex'>
    <HLRSubheaderBlock
      section="System design"
      title="Proposed emitter changes"
    />

    <TableLite size='SM' rows={emitters} columns={emittersTableColumns}/>

    <div className="">
      <span className="text-gray-900 text-xs font-bold">*% Heat demand met: </span>
      <span className="text-xs tracking-tight">This is calculated for a day when the outdoor temperature is {numberFormat(1).format(hlrContext!.designTempC)} °C and the flow temperature is {numberFormat(1).format(hlrContext!.design.flow_temp)} °C</span>
    </div>

    {undersizedRooms.length > 0 && <div className='flex flex-col gap-4 text-xs'>
      <div className='flex flex-col gap-1'>
        <div className='font-bold text-gray-900'>** Accepting undersized emitters</div>
        <div>Please note that the emitters do not meet the room heat demand in all cases.
          This means that when its cold outside the following rooms will not reach their set point temperature.
          If you choose to go ahead, you are acknowledging that you are happy with the above.
          If that doesn't sound right please contact us before proceeding with the install.</div>
      </div>
      <div className='flex flex-col gap-1'>
        {undersizedRooms.map(x => <div key={x.room_uuid} className='flex gap-2'>
          <div className='font-bold text-gray-900'>{roomGroups.includes(x.room_uuid)
            ? rooms.filter(y => y.room_group_uuid === x.room_uuid).map(y => y.name).join(', ')
            : rooms.find(y => y.uuid === x.room_uuid)?.name}</div>
          <div>{x.notes}</div>
        </div>)}
      </div>
    </div>}

  </div>
}
