import React from 'react'
import { SurveyElectricsContext } from '../electrics'
import { RadioGroup } from '../../../../components/inputs_and_selections/radio'
import { Input } from '../../../../components/inputs_and_selections/input'
import { Toggle } from '../../../../components/inputs_and_selections/toggle'

export const ElectricsIncomingMainAndFuseDetailsBlock = () => {
  const electricsContext = React.useContext(SurveyElectricsContext)
  const { survey, setSurvey } = electricsContext!

  const PHASES = ['Single', 'Three']

  return <>
    <div className="text-gray-900 text-xl font-bold">Incoming main and fuse</div>
    <div className="flex-col gap-5 flex">
      <div className="flex-col gap-2 flex">
        <div className="text-gray-900 font-bold">Phase</div>
        <RadioGroup items={PHASES.map(x => ({
          name: x,
          onClick: () => setSurvey({ ...survey, electrics_phase: x }),
          variant: x === survey.electrics_phase ? 'ACTIVE' : 'DEFAULT'
        }))}/>
      </div>
      <div className="flex-col gap-2 flex">
        <div className="text-gray-900 font-bold">Main fuse size</div>
        <Input
          type='number'
          value={survey.electrics_main_fuse_size_amps.toString()}
          setValue={(e) => setSurvey({ ...survey, electrics_main_fuse_size_amps: Number(e) })}
          postfix='amps'
        />
      </div>
      <div className="gap-3 flex justify-between items-center">
        <div className="flex-col flex">
          <div className="text-gray-900 font-bold">Looped service</div>
          <div className="text-gray-500 text-sm">A looped service is where two properties share a single electricity
            service cable from the main network. There will be two cables to the cut out.
          </div>
        </div>
        <Toggle
          value={survey.electrics_looped_service}
          setValue={() => setSurvey({ ...survey, electrics_looped_service: !survey.electrics_looped_service })}
        />
      </div>
    </div>
  </>
}
