import { type KonvaEventObject } from 'konva/lib/Node'
import { type Vector2d } from 'konva/lib/types'
import { noop, orderBy } from 'lodash'
import React, { type Dispatch, type SetStateAction, useState } from 'react'
import { Circle, Group, Line, Text } from 'react-konva'
import { type Floor } from '../../../code/models/floor'
import { type MaterialsSet } from '../../../code/models/material'
import { type Room } from '../../../code/models/room'
import { type Wall } from '../../../code/models/wall'
import { FONT_SIZE, gray900, indigo600 } from './code/constants'
import { type CurrentFloorPlanPage } from './floor'
import { type UndoEvent } from './floor_canvas/undo'
import { getDistance, getTextDimensions, LineWithText, type MatchedLine, type MatchedPoint, snapLine, snapToAngle, type SpruceLine } from './line_with_text'
import { calculateNewTouchingWalls, getNextWall, removeInvalidRoomGroups, simplifyRooms } from './code/utils'
import { type SprucePoint } from './code/types'
import { calculateCentroid } from '../constants'

type RoomDrawingProps = {
  room: Room
  wallsWithoutDupes: Array<{
    x1: number
    x2: number
    y1: number
    y2: number
    uuid: string
  }> | undefined
  floor: Floor
  dragStopped: boolean
  currentWall: Wall | undefined
  stageScale: number
  setPage: (page: CurrentFloorPlanPage) => void
  setCurrentWallId: Dispatch<SetStateAction<string>>
  setWalls: (wall: Wall[]) => void
  currentRoomId: string
  currentRoom: Room | undefined
  setCurrentRoomId: Dispatch<SetStateAction<string>>
  defaultMaterials: MaterialsSet
  setStagePosition: Dispatch<SetStateAction<Vector2d>>
  setStageScale: Dispatch<SetStateAction<number>>
  addEvent: (events: UndoEvent[]) => void
  touches: number
  isDrawing: boolean
  setGuidelines: Dispatch<SetStateAction<SpruceLine[]>>
  snappingTolerancePixels: number
}

export const RoomDrawing = ({
  wallsWithoutDupes,
  room,
  floor,
  dragStopped,
  currentWall,
  stageScale,
  setPage,
  setCurrentWallId,
  setWalls,
  currentRoomId,
  currentRoom,
  setCurrentRoomId,
  addEvent,
  touches,
  isDrawing,
  setGuidelines,
  defaultMaterials,
  snappingTolerancePixels
}: RoomDrawingProps) => {
  // onDragStart = set tempRoom.
  // onDragEnd = update the floor to tempRoom and reset tempRoom.
  // Updates to indexedDb should be treated like API requests, this allows us to make the call only when the action is finished.
  const [tempRoom, setTempRoom] = useState<Room>()

  const onDragEnd = () => {
    const roomsWithTemp = floor.rooms.map(x => x.uuid === tempRoom?.uuid ? tempRoom! : x)

    const simplifiedRooms = simplifyRooms(roomsWithTemp)
    const newRooms = calculateNewTouchingWalls(simplifiedRooms, defaultMaterials)
    const removedInvalidRoomGroups = removeInvalidRoomGroups(newRooms)

    const events: UndoEvent[] = [{ type: 'FLOOR', action: 'UPDATE', oldValue: floor, newValue: { ...floor, rooms: removedInvalidRoomGroups } }]
    addEvent(events)

    // Incredibly annoying, but even await addEvent the order will be:
    // setSurvey, setTempRoom, tempRoom = undefined, survey = new survey with temp room
    // Therefore set a very small timeout to prevent a flicker where user would see old room when temp room is cleared but before survey update has happened.
    setTimeout(() => {
      setTempRoom(undefined)
      setGuidelines([])
    }, 50)
  }

  const onDragMove = (e: KonvaEventObject<DragEvent>) => onDragInner(e)

  const calculatedRoom = tempRoom ?? room
  const trueRoomX = calculatedRoom.x! * stageScale
  const trueRoomY = calculatedRoom.y! * stageScale

  const onDragInner = (e) => {
    if (e.currentTarget === e.target) {
      const newPos = e.currentTarget.position()
      e.currentTarget.position(({ x: trueRoomX, y: trueRoomY }))

      const snaps: Array<MatchedLine & { wall: Wall }> = []
      for (const wall of room.walls) {
        const nextWall = getNextWall(wall, room.walls)

        const trueX = (wall.x! * stageScale) + newPos.x
        const trueY = (wall.y! * stageScale) + newPos.y
        const trueNextX = (nextWall.x! * stageScale) + newPos.x
        const trueNextY = (nextWall.y! * stageScale) + newPos.y

        const snapped = snapLine({
          p1: { x: trueX, y: trueY },
          p2: { x: trueNextX, y: trueNextY }
        }, floor.rooms, stageScale, room.walls.map(x => x.uuid!), snappingTolerancePixels)

        if (snapped) {
          snaps.push({ ...snapped, wall })
        }
      }

      const bestSnap = snaps.find(x => x.type === 'POINT') ?? orderBy(snaps, x => x.distance)[0]
      const newPosX = bestSnap
        ? bestSnap.newLine.p1.x - (bestSnap.wall.x! * stageScale)
        : newPos.x
      const newPosY = bestSnap
        ? bestSnap.newLine.p1.y - (bestSnap.wall.y! * stageScale)
        : newPos.y
      const guidelines = bestSnap ? bestSnap.guidelines : []

      setGuidelines(guidelines)
      setTempRoom(prev => ({
        ...prev!,
        x: Math.round(newPosX / stageScale),
        y: Math.round(newPosY / stageScale)
      }))
    }
  }

  const onPointDragMove = (wallUUID: string, e: KonvaEventObject<DragEvent | TouchEvent>, setGuidelines: Dispatch<SetStateAction<SpruceLine[]>>, stageScale: number) => {
    const currentPoint = tempRoom!.walls.find(x => x.uuid === wallUUID)!

    const trueX = (currentPoint.x! * stageScale) + trueRoomX
    const trueY = (currentPoint.y! * stageScale) + trueRoomY

    if (e.currentTarget === e.target) {
      const newPos = e.target.position()
      e.currentTarget.position(({ x: trueX, y: trueY }))

      const snappedPositions = snapPoint(newPos, floor.rooms, stageScale, [currentPoint.uuid!], snappingTolerancePixels)

      const newPoint = snappedPositions?.newPoint ?? newPos
      snappedPositions ? setGuidelines(snappedPositions.guidelines) : setGuidelines([])

      const newRoom = { ...tempRoom!, walls: tempRoom!.walls.map(x => x.uuid === wallUUID ? { ...currentPoint, x: Math.round((newPoint.x - trueRoomX) / stageScale), y: Math.round((newPoint.y - trueRoomY) / stageScale) } : x) }
      setTempRoom(newRoom)
    }
  }

  const isCurrentRoom = room.uuid === currentRoomId
  const onClick = (e: KonvaEventObject<MouseEvent>) => {
    setCurrentRoomId(room.uuid!)
    setCurrentWallId('')
    setPage('ROOM_DETAILS')
  }

  const points = room.walls.map(x => [x.x! * stageScale, x.y! * stageScale])
  const [centerX, centerY] = calculateCentroid(points)

  return <>
    <Group
      opacity={isCurrentRoom ? 1 : 0.5}
      onTap={isDrawing ? noop : onClick}
      onClick={isDrawing ? noop : onClick}
      listening={!isDrawing}
    >
      <Line
        points={[...calculatedRoom.walls?.map(x => [x.x! * stageScale, x.y! * stageScale]).flat()]}
        fill={'white'}
        draggable={isCurrentRoom && touches < 2}
        onDragStart={(e) => setTempRoom(room)}
        onDragEnd={onDragEnd}
        onDragMove={onDragMove}
        x={trueRoomX}
        y={trueRoomY}
        closed={true} />
      {!isCurrentRoom && <Text
        x={trueRoomX + centerX - (getTextDimensions(calculatedRoom.text, true).width / 2)}
        y={trueRoomY + centerY - FONT_SIZE - 2} // slightly increase clearance between two lines
        fontStyle='bold'
        fontFamily='Manrope'
        fill={gray900}
        fontSize={FONT_SIZE}
        text={calculatedRoom.text}
      />}
      {!isCurrentRoom && <Text
        x={trueRoomX + centerX - (getTextDimensions(calculatedRoom.roomText).width / 2)}
        y={trueRoomY + centerY}
        fontFamily='Manrope'
        fill={indigo600}
        fontSize={FONT_SIZE}
        text={calculatedRoom.roomText}
      />}
    </Group>
    {calculatedRoom.walls.map((x, i) => {
      const otherRoom = floor.rooms.find(y => y.uuid === x.other_room_uuid)
      if (calculatedRoom.room_group_uuid !== undefined && calculatedRoom.room_group_uuid === otherRoom?.room_group_uuid) return null

      const innerText = [
        x.windows.length > 0 ? `${x.windows.length} W` : '',
        x.doors.length > 0 ? `${x.doors.length} D` : ''
      ].filter(x => x.length > 0).join(', ')

      return <LineWithText
        onDragStart={() => setTempRoom(currentRoom)}
        otherRoomId={x.other_room_uuid!}
        onDragEnd={onDragEnd}
        draggable={isCurrentRoom && touches < 2 && currentWall?.uuid === x.uuid && !dragStopped}
        isCurrentWall={currentWall?.uuid === x.uuid}
        scale={{ x: stageScale, y: stageScale }}
        innerText={innerText}
        key={i}
        onClick={isCurrentRoom && !isDrawing ? () => { setCurrentWallId(x.uuid!); setPage('WALL_MATERIALS') } : noop}
        x={trueRoomX}
        y={trueRoomY}
        wallIndex={i}
        walls={calculatedRoom.walls}
        setWalls={(w) => setTempRoom(prev => ({ ...prev!, walls: w }))}
        setCurrentWallId={setCurrentWallId}
        setPage={setPage}
        isCurrentRoom={isCurrentRoom}
        rooms={floor.rooms}
        isDrawing={isDrawing}
        stageScale={stageScale}
        setGuidelines={setGuidelines}
        snappingTolerancePixels={snappingTolerancePixels}
      />
    })}
    {currentRoom?.uuid === calculatedRoom.uuid && calculatedRoom.walls.map(x => {
      const truePointX = x.x! * stageScale + trueRoomX
      const truePointY = x.y! * stageScale + trueRoomY
      return <Circle
        key={x.uuid}
        draggable={true}
        onDragStart={() => setTempRoom(currentRoom)}
        onDragMove={(e) => onPointDragMove(x.uuid!, e, setGuidelines, stageScale)}
        onDragEnd={onDragEnd}
        fill={indigo600}
        radius={5}
        x={truePointX}
        y={truePointY}
      />
    })}
    {/* {calculatedRoom.walls.map(x => <Text
      key={x.uuid}
      text={`(${x.uuid})`}
      x={x.x! * stageScale + trueRoomX}
      y={x.y! * stageScale + trueRoomY}
    />)} */}
    {/* {isCurrentRoom && calculatedRoom.walls.map(x => <Text
      key={x.uuid}
      text={`(${x.x! + room.x!}, ${x.y! + room.y!})`}
      x={x.x! * stageScale + trueRoomX}
      y={x.y! * stageScale + trueRoomY}
    />)} */}
  </>
}

export const snapPoint = (
  point: SprucePoint,
  rooms: Room[],
  stageScale: number,
  excludeWallUUIDs: string[] = [],
  snappingTolerance: number
): MatchedPoint | undefined => {
  const pointTolerance = snappingTolerance
  const matchedPoints: MatchedPoint[] = []

  const wallSegments = [...rooms.flatMap(r => {
    const trueRoomX = r.x! * stageScale
    const trueRoomY = r.y! * stageScale

    const walls = r.walls.map(w => {
      const nextWall = getNextWall(w, r.walls)
      return {
        p1: { uuid: w.uuid, x: w.x! * stageScale + trueRoomX, y: w.y! * stageScale + trueRoomY },
        p2: { uuid: nextWall.uuid, x: nextWall.x! * stageScale + trueRoomX, y: nextWall.y! * stageScale + trueRoomY }
      }
    })

    return walls
  })].filter(x => !excludeWallUUIDs.includes(x.p1.uuid!))

  const points = wallSegments.map(x => x.p1)
  const angleWallSegments = wallSegments.filter(x => !excludeWallUUIDs.includes(x.p2.uuid!))

  const anyClosePoint = points.find(x =>
    Math.abs(x.x - point.x) < pointTolerance &&
    Math.abs(x.y - point.y) < pointTolerance
  )
  if (anyClosePoint) {
    matchedPoints.push({ newPoint: anyClosePoint, guidelines: [{ p1: anyClosePoint, p2: anyClosePoint }], type: 'POINT', distance: 0 })
  }

  const distances = points.map(x => ({ point: x, distance: getDistance(x, point) }))
  const closestX = distances.filter(x => Math.abs(x.point.x - point.x) < pointTolerance).sort((x, y) => x.distance - y.distance)[0]
  const closestY = distances.filter(x => Math.abs(x.point.y - point.y) < pointTolerance).sort((x, y) => x.distance - y.distance)[0]

  if (closestX && closestY) {
    const newPosition = { x: closestX.point.x, y: closestY.point.y }
    matchedPoints.push({
      type: 'VERTICAL_HORIZONTAL',
      newPoint: newPosition,
      distance: 0,
      guidelines: [
        { p1: newPosition, p2: closestY.point },
        { p1: newPosition, p2: closestX.point }
      ]
    })
  }

  if (closestX) {
    const newPosition = { x: closestX.point.x, y: point.y }
    matchedPoints.push({ type: 'VERTICAL', newPoint: newPosition, guidelines: [{ p1: newPosition, p2: closestX.point }], distance: closestX.distance })
  }

  if (closestY) {
    const newPosition = { x: point.x, y: closestY.point.y }
    matchedPoints.push({ type: 'HORIZONTAL', newPoint: newPosition, guidelines: [{ p1: newPosition, p2: closestY.point }], distance: closestY.distance })
  }

  for (let i = 0; i < angleWallSegments.length; i++) {
    for (let j = i + 1; j < angleWallSegments.length; j++) {
      const intersection = getExtendedIntersection(angleWallSegments[i], angleWallSegments[j])
      if (intersection && getDistance(intersection, point) < pointTolerance) {
        matchedPoints.push({
          type: 'ANGLE_INTERSECTION',
          newPoint: intersection,
          guidelines: [
            { p1: intersection, p2: angleWallSegments[i].p1 },
            { p1: intersection, p2: angleWallSegments[j].p1 }
          ],
          distance: getDistance(intersection, point)
        })
      }
    }
  }

  for (const segment of angleWallSegments) {
    const counterClockwiseCheck = snapToAngle(segment, point, snappingTolerance)
    if (counterClockwiseCheck?.newPoint) {
      matchedPoints.push(counterClockwiseCheck)
    }
  }

  const pointMatch = matchedPoints.find(x => x.type === 'POINT')
  if (pointMatch) return pointMatch

  const verticalHorizontal = matchedPoints.find(x => x.type === 'VERTICAL_HORIZONTAL')
  if (verticalHorizontal) return verticalHorizontal

  const angleIntersectionMatch = matchedPoints.find(x => x.type === 'ANGLE_INTERSECTION')
  if (angleIntersectionMatch) return angleIntersectionMatch

  const angleMatch = matchedPoints.find(x => x.type === 'ANGLE')
  if (angleMatch) return angleMatch

  const closestHorizontalVertical = matchedPoints
    .filter(x => x.type === 'VERTICAL' || x.type === 'HORIZONTAL')
    .sort((x, y) => x.distance - y.distance)[0]

  if (closestHorizontalVertical) return closestHorizontalVertical
}

const getExtendedIntersection = (segment1: SpruceLine, segment2: SpruceLine): SprucePoint | null => {
  const { p1: p1A, p2: p2A } = segment1
  const { p1: p1B, p2: p2B } = segment2

  const denominator = (p2A.x - p1A.x) * (p2B.y - p1B.y) - (p2A.y - p1A.y) * (p2B.x - p1B.x)

  if (Math.abs(denominator) < 1e-6) {
    // Lines are parallel, no intersection
    return null
  }

  const ua = ((p2B.x - p1B.x) * (p1A.y - p1B.y) - (p2B.y - p1B.y) * (p1A.x - p1B.x)) / denominator

  // Calculate the intersection point
  const x = p1A.x + ua * (p2A.x - p1A.x)
  const y = p1A.y + ua * (p2A.y - p1A.y)

  return { x, y }
}
