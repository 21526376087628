import React from 'react'
import { Section } from '../../../../../components/containers/section'
import { VerticalFormGroup } from '../../../../../components/inputs_and_selections/vertical_form_group'
import { FormLabel } from '../../../../../components/inputs_and_selections/form_label'
import { Input } from '../../../../../components/inputs_and_selections/input'
import { type Company } from '../../../../../code/models/company'
import { TextArea } from '../../../../../components/inputs_and_selections/text_area'
import { ClickableCard } from '../../../../../components/content_display/card'
import { HorizontalFormGroup } from '../../../../../components/inputs_and_selections/horizontal_form_group'
import { Toggle } from '../../../../../components/inputs_and_selections/toggle'
import { validateURLAndHasProtocol } from '../../../../../code/validators'

type CustomerReview = {
  customer_review_name?: string
  customer_review_location?: string
  customer_review?: string
  customer_review_link_text?: string
  customer_review_link?: string
}

export const EstimateCustomerReviewBlock = ({ company, setCompany }: { company: Company, setCompany: (c: Company) => void }) => {
  const handleUpdateReview = (name: keyof CustomerReview, value: string) => {
    setCompany({
      ...company,
      public_info: {
        ...company.public_info,
        [name]: value
      }
    })
  }

  return <Section border title='Customer review' >
    <div className='flex flex-col gap-4'>
      <ClickableCard variant='GREY'>
        <HorizontalFormGroup
          formLabel={
            <FormLabel
              labelText={'Customer review section'}
              helperText={'Display the customer review section on the estimate'}
            />
          }
          input={<Toggle value={company.public_info.show_customer_review_on_estimate}
            setValue={async () => {
              const newCompany = {
                ...company,
                public_info: {
                  ...company.public_info,
                  show_customer_review_on_estimate: !company.public_info.show_customer_review_on_estimate
                }
              }
              setCompany(newCompany)
            }} />}
        />
      </ClickableCard>
      <VerticalFormGroup
        formLabel={<FormLabel labelText='Name' helperText='Leave blank to remove name & location from review' />}
        input={<Input
          placeholder='Customer name'
          value={company.public_info.customer_review_name ?? ''}
          setValue={reviewerName => handleUpdateReview('customer_review_name', reviewerName)}
        />}
      />
      <VerticalFormGroup
        formLabel={<FormLabel labelText='Location' />}
        input={<Input
          placeholder='Customer location'
          value={company.public_info.customer_review_location ?? ''}
          setValue={reviewerLocation => handleUpdateReview('customer_review_location', reviewerLocation)}
        />}
      />
      <VerticalFormGroup
        formLabel={<FormLabel labelText='Review' />}
        input={<TextArea
          placeholder='Customer review'
          value={company.public_info.customer_review ?? ''}
          setValue={review => handleUpdateReview('customer_review', review)}
          rows={6}
        />}
      />
      <VerticalFormGroup
        formLabel={<FormLabel labelText='Button label' helperText='Leave blank to remove link' />}
        input={<Input
          placeholder='Link text'
          value={company.public_info.customer_review_link_text ?? ''}
          setValue={reviewLinkText => handleUpdateReview('customer_review_link_text', reviewLinkText)}
        />}
      />
      <VerticalFormGroup
        formLabel={<FormLabel labelText='Button link' />}
        input={<Input
          placeholder='URL'
          value={company.public_info.customer_review_link ?? ''}
          setValue={reviewLink => {
            // If the URL is empty or valid, update the review link
            if (reviewLink === '' || validateURLAndHasProtocol(reviewLink).value) {
              handleUpdateReview('customer_review_link', reviewLink)
            }
          }}
          validator={validateURLAndHasProtocol}
          doNotValidateWhenEmpty
        />}
      />
    </div>

  </Section>
}
