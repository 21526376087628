import React, { useState } from 'react'
import { type TableColumn, TableLite } from '../../../components/content_display/table_lite'

type TableExample = {
  uuid: string
  column1: string
  column2: string
  column3: string
}

export const TableLiteDemo = () => {
  const columns: Array<TableColumn<TableExample>> = [
    { key: 'column1', name: 'Column 1', render: (e) => <div>Custom render</div> },
    { key: 'column2', name: 'Column 2' },
    { key: 'column3', name: 'Column 3' }
  ]

  const rows = [
    { uuid: '1', column1: 'Test 1.1', column2: 'Test 2.1', column3: 'Test 3.1' },
    { uuid: '2', column1: 'Test 1.2', column2: 'Test 2.2', column3: 'Test 3.2' },
    { uuid: '3', column1: 'Test 1.3', column2: 'Test 2.3', column3: 'Test 3.3' }
  ]

  const [selectedIds, setSelectedIds] = useState<string[]>([])

  return <div className="flex flex-col gap-8">
    <div className='flex flex-col gap-4'>
      <div className='text-2xl font-bold text-gray-900'>Definition</div>

      <pre className='bg-gray-100 rounded-lg p-4 overflow-x-auto'><code>
        {codeBlock}
      </code></pre>

      <div className='flex flex-col divide-y divide-dashed divide-gray-200 text-sm'>
        {componentProps.map(x => <div key={x.name} className='grid grid-cols-2 py-2'>
          <div className='font-bold text-gray-900'>{x.name}</div>
          <div>{x.description}</div>
        </div>)}
      </div>
    </div>

    <div className='flex flex-col gap-4'>
      <div className='text-2xl font-bold text-gray-900'>Component</div>
      <TableLite
        rows={rows}
        columns={columns}
        rowIdName='uuid'
        selectedIds={selectedIds}
        setSelectedIds={setSelectedIds}
      />
    </div>
  </div>
}

const componentProps = [
  { name: 'rows', description: 'The raw table data, properties should match column headers' },
  { name: 'columns', description: 'How many columns should the table have, each with its own definition below' },
  { name: 'name', description: 'column prop: header text of the column' },
  { name: 'key', description: 'column prop: unique row identifier' },
  { name: 'render', description: 'column prop: should we render the row differently when viewing' },
  { name: 'rowIdName', description: 'The unique ID of the row to add to selectedIds' },
  { name: 'selectedIds', description: 'Which rows in the table are selected (checkbox)' },
  { name: 'setSelectedIds', description: 'What should we do when clicking a rows checkbox' }
]

const codeBlock = `type TableExample = {
  uuid: string
  column1: string
  column2: string
  column3: string
}

const columns: Array<TableColumn<TableExample>> = [
  { key: 'column1', name: 'Column 1', render: (e) => <div>Custom render</div> },
  { key: 'column2', name: 'Column 2' },
  { key: 'column3', name: 'Column 3' }
]

const rows = [
  { uuid: '1', column1: 'Test 1.1', column2: 'Test 2.1', column3: 'Test 3.1' },
  { uuid: '2', column1: 'Test 1.2', column2: 'Test 2.2', column3: 'Test 3.2' },
  { uuid: '3', column1: 'Test 1.3', column2: 'Test 2.3', column3: 'Test 3.3' }
]

<TableLite
  rows={rows}
  columns={columns}
  rowIdName='uuid'
  selectedIds={selectedIds}
  setSelectedIds={setSelectedIds}
/>
`
