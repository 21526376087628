import React, { useContext, useState } from 'react'
import { Section } from '../../components/section'
import { Heading } from '../../../../../components/content_display/heading'
import { type Lead } from '../../../../../code/models/lead'
import { ClickableCard } from '../../../../../components/content_display/card'
import { SummaryTable } from '../../../../../components/content_display/summary_table'
import { Modal } from '../../../../../components/containers/modal'
import { orderBy, uniq } from 'lodash'
import { type CapacityResult, getHeatPumpCapacityAtOutsideTempAndFlowTemp } from '../../../../../code/models/range_heat_pump'
import { ListItem } from '../../../../../components/content_display/list_item'
import { RadioGroup } from '../../../../../components/inputs_and_selections/radio'
import { AdminContext, type AdminContextType } from '../../../admin_layout'
import { Badge } from '../../../../../components/indicators_and_messaging/badge'
import { FlowTempSlider } from '../../../../heat_loss/design/pages/emitter_design_page'
import { DEFAULT_FLOW_TEMP_C, type Estimate } from '../../../../../code/calculate_estimate'
import { Alert } from '../../../../../components/indicators_and_messaging/alert'
import { HeatLossSummaryCards } from './heat_loss_estimate'

type Props = {
  lead: Lead
  setLead: (lead: Lead) => void
  estimate: Estimate
}

type SelectHeatPumpModalProps = {
  visible: boolean
  setVisible: (visible: boolean) => void
  designTempC: number
  flowTempC: number
  totalHeatLossKW?: number
  lead: Lead
  setLead: (lead: Lead) => void
  adminContext: AdminContextType
}

type SelectHotWaterCylinderModalProps = {
  visible: boolean
  setVisible: (visible: boolean) => void
  lead: Lead
  setLead: (lead: Lead) => void
  adminContext: AdminContextType
}

const SelectHeatPumpModal = ({ visible, setVisible, designTempC, flowTempC, totalHeatLossKW, lead, setLead, adminContext }: SelectHeatPumpModalProps) => {
  const [rangeFilter, setRangeFilter] = useState<string>('All')
  const ranges = ['All', ...uniq(adminContext.data.heatPumps?.filter(x => !x.deleted_at).map(x => x.range_heat_pump?.brand_range?.name)).filter(x => !!x)]
  const filteredHeatPumps = orderBy(
    adminContext.data.heatPumps
      ?.filter(x => (!x.deleted_at))
      .filter(x => rangeFilter === 'All' || x.range_heat_pump?.brand_range?.name === rangeFilter)
      .map(x => {
        const capacityResult = getHeatPumpCapacityAtOutsideTempAndFlowTemp(x.range_heat_pump, designTempC, flowTempC)
        return {
          uuid: x.uuid,
          name: x.name,
          capacityKw: capacityResult.capacityKw,
          outsideTempC: capacityResult.outsideTempC,
          flowTempC: capacityResult.flowTempC,
          warning: capacityResult.warning
        }
      }), x => x.capacityKw
  )

  return (
    <Modal title='Select a heat pump' visible={visible} setVisible={setVisible}>
      <div className='flex flex-col w-full gap-5'>
        <div className='flex flex-col gap-2'>
          <div className="text-gray-500 text-xs font-semibold uppercase">Range</div>
          <div className="flex-col gap-2 flex overflow-x-auto">
            <RadioGroup items={ranges.map(x => ({
              name: x!,
              onClick: () => setRangeFilter(x),
              variant: rangeFilter === x ? 'ACTIVE' : 'DEFAULT'
            }))} />
          </div>
        </div>

        <div className="bg-white rounded-md flex-col flex divide-y divide-gray-200 ">
          {filteredHeatPumps.map(x =>
            <ListItem
              onClick={async () => {
                setLead({ ...lead, heat_pump_uuid: x.uuid })
                setVisible(false)
              }}
              key={x.uuid}
              primaryText={x.name}
              rightBadge={ getCapacityBadge(x, totalHeatLossKW) }
            />)
          }
        </div>
      </div>
    </Modal>
  )
}

const SelectHotWaterCylinderModal = ({ visible, setVisible, lead, setLead, adminContext }: SelectHotWaterCylinderModalProps) => {
  return (
    <Modal title='Select a hot water cylinder' visible={visible} setVisible={setVisible}>
      <div className="bg-white rounded-md flex-col flex divide-y divide-light ">
        {adminContext.data.hotWaterCylinders?.filter(x => (!x.deleted_at)).map(x =>
          <ListItem
            onClick={async () => {
              setLead({ ...lead, hot_water_cylinder_uuid: x.uuid })
              setVisible(false)
            }}
            key={x.uuid}
            primaryText={x.name}
            rightBadge={<Badge
              color={'LIGHT'}
              text={`${Math.round(x.litres)} litres`} />
            }
          />)
        }
      </div>
    </Modal>
  )
}

export const SystemDesign = ({ lead, setLead, estimate }: Props) => {
  const adminContext = useContext(AdminContext)
  const [selectHeatPumpModalVisible, setSelectHeatPumpModalVisible] = useState(false)
  const [selectHotWaterCylinderModalVisible, setSelectHotWaterCylinderModalVisible] = useState(false)

  const flowTemp = lead.flow_temperature_c ?? adminContext.data.company?.default_flow_temp_c ?? DEFAULT_FLOW_TEMP_C

  const selectedHeatPumpCapacity = getHeatPumpCapacityAtOutsideTempAndFlowTemp(estimate.heatPump?.range_heat_pump, estimate.designTempC, flowTemp)
  const totalHeatLossKW = estimate.totalWatts / 1000

  const heatPumpRows = [
    { key: 'Sound power level', value: `${estimate.heatPump?.range_heat_pump.sound_power_max_dba} dB(A)` },
    { key: 'Refrigerant', value: estimate.heatPump?.range_heat_pump?.refrigerant },
    { key: 'Volume', value: <>{(((estimate.heatPump?.range_heat_pump?.width_mm ?? 0) * (estimate.heatPump?.range_heat_pump?.height_mm ?? 0) * (estimate.heatPump?.range_heat_pump?.depth_mm ?? 0)) / Math.pow(10, 9)).toFixed(2)} m³</> },
    { key: 'Dimensions (WxHxD)', value: `${estimate.heatPump?.range_heat_pump?.width_mm}mm x ${estimate.heatPump?.range_heat_pump?.height_mm}mm x ${estimate.heatPump?.range_heat_pump?.depth_mm}mm` },
    {
      key: `SCOP at ${flowTemp}°C`,
      value: estimate.sCOP > 0 ? `${Math.floor(estimate.sCOP * 100)}%` : <Badge
        color={'RED'}
        text={`${Math.floor(estimate.sCOP * 100)}%`}
      />
    },
    {
      key: `Capacity at ${selectedHeatPumpCapacity.flowTempC}°C (${selectedHeatPumpCapacity.outsideTempC} °C)`,
      value: getCapacityBadge(selectedHeatPumpCapacity, totalHeatLossKW)
    }
  ]

  const setFlowTemp = (flowTemp: number) => {
    setLead({ ...lead, flow_temperature_c: flowTemp })
  }

  return (
    <>
      <Section title='System design'>
        <div className='space-y-3'>
          <HeatLossSummaryCards
            lead={lead}
            estimate={estimate}
            columns={4} />
          <Heading size='lg'>Heat pump model</Heading>
          {/* No heat pump selected */}
          {!estimate.heatPump && <ClickableCard variant='PLACEHOLDER' onClick={() => setSelectHeatPumpModalVisible(true)}>
            Please select a heat pump
          </ClickableCard>}
          {/* Heat pump selected */}
          {
            estimate.heatPump &&
            <ClickableCard variant="WHITE" title={estimate.heatPump.name} onClick={() => setSelectHeatPumpModalVisible(true)} showIcon={true}>
              <SummaryTable rows={heatPumpRows} />
              {/* if HP was deleted */}
              {estimate.heatPump.deleted_at && <Alert type='DANGER'>This heat pump has been deleted from your inventory. You won't be able to select it again if you change to a different one.</Alert>}
              {/* if capacity data isn't at the correct outdoor temp and flow temp */}
              {selectedHeatPumpCapacity.warning && <Alert type='WARNING'>{selectedHeatPumpCapacity.warning}.</Alert>}
            </ClickableCard>
          }
        </div>
        <div className='space-y-3'>
          <Heading size='lg'>Cylinder model</Heading>
          {/* No cylinder selected */}
          {!estimate.hotWaterCylinder && <ClickableCard variant='PLACEHOLDER' onClick={() => setSelectHotWaterCylinderModalVisible(true)}>
            Please select a hot water cylinder
          </ClickableCard>}
          {/* Cylinder selected */}
          {
            estimate.hotWaterCylinder &&
            <ClickableCard variant="WHITE" title={estimate.hotWaterCylinder.name} onClick={() => setSelectHotWaterCylinderModalVisible(true)} showIcon={true}>
              <SummaryTable rows={[
                { key: 'Capacity', value: `${Math.round(estimate.hotWaterCylinder.litres || 0)} litres` }
              ]} />
              {/* if HWC was deleted */}
              {estimate.hotWaterCylinder?.deleted_at && <Alert type='DANGER'>This hot water cylinder has been deleted from your inventory. You won't be able to select it again if you change to a different one.</Alert>}
            </ClickableCard>
          }
        </div>
        <FlowTempSlider
          flowTemp={flowTemp}
          setFlowTemp={setFlowTemp}
        />
        {/* TODO: Uncomment when radiator changes are implemented */}
        {/* <div className='grid grid-cols-1 md:grid-cols-2 gap-5'>
          <div>
            <Heading size='lg'>Radiator changes</Heading>
          </div>
          <div>
            <Heading size='lg'>SCOP at 45°C</Heading>
          </div>
        </div> */}
      </Section>
      {selectHeatPumpModalVisible && <SelectHeatPumpModal
        visible={selectHeatPumpModalVisible}
        setVisible={setSelectHeatPumpModalVisible}
        designTempC={estimate.designTempC}
        flowTempC={flowTemp}
        totalHeatLossKW={totalHeatLossKW}
        lead={lead}
        setLead={setLead}
        adminContext={adminContext}
      />}
      {selectHotWaterCylinderModalVisible && <SelectHotWaterCylinderModal
        visible={selectHotWaterCylinderModalVisible}
        setVisible={setSelectHotWaterCylinderModalVisible}
        lead={lead}
        setLead={setLead}
        adminContext={adminContext}
      />}
    </>
  )
}

const getCapacityBadge = (selectedHeatPumpCapacity: CapacityResult, totalHeatLossKW: number | undefined) => {
  if (totalHeatLossKW) {
    return <Badge
      color={selectedHeatPumpCapacity.capacityKw >= totalHeatLossKW ? selectedHeatPumpCapacity.warning ? 'YELLOW' : 'GREEN' : 'RED'}
      text={selectedHeatPumpCapacity.capacityKw.toFixed(1) + ' kW'}
    />
  }
  return <Badge
    color={'LIGHT'}
    text={selectedHeatPumpCapacity.capacityKw.toFixed(1) + ' kW'}
  />
}
