import React from 'react'
import { type SurveyImage } from '../../../../code/models/property'
import { type FileWrapper } from '../../file_wrapper'
import { SurveySiteDetailsContext } from '../site_details'
import { FlowWrapperContext } from '../components/survey_section_flow_wrapper'
import { PhotoScroll } from '../../../../components/inputs_and_selections/photo_scroll'

export const SiteDetailsMainsWaterPhotosBlock = () => {
  const prContext = React.useContext(SurveySiteDetailsContext)
  const flowContext = React.useContext(FlowWrapperContext)

  const { survey, setSurvey, files, setFiles, companyUUID } = prContext!

  const stopcockImagesHydrated = survey.stopcock_images.map(x => files.find(y => y.uuid === x.image_uuid)!)
  const setStopcockImages = (images: SurveyImage[]) => setSurvey({ ...survey, stopcock_images: images })

  const isFullScreen = stopcockImagesHydrated.length === 0 && flowContext!.viewMode === 'FLOW'

  return <>
    <div className={isFullScreen ? 'p-5 h-full bg-gray-50 flex flex-col items-center justify-center gap-4' : 'flex flex-col gap-4'}>
      <div className={`${isFullScreen ? 'items-center' : ''} flex flex-col gap-1`}>
        <div className="text-gray-900 text-xl font-bold">Mains water</div>
        <div className={`${isFullScreen ? 'text-center' : ''} text-gray-500 text-sm`}>
          Add photos of the incoming water main and stopcock
        </div>
      </div>

      <div className="flex-col gap-3 flex">
        {!isFullScreen &&
          <div className='flex justify-between'>
            <div className="text-gray-900 font-bold">Photos</div>
          </div>
        }

        <PhotoScroll
          viewMode={isFullScreen ? 'COMPACT' : 'NORMAL'}
          images={stopcockImagesHydrated}
          addImage={(image: FileWrapper) => {
            setFiles(prev => [...prev, { ...image, created_at: new Date().getTime(), updated_at: new Date().getTime(), is_modified: true }])
            const newImage: SurveyImage = { uuid: crypto.randomUUID(), image_uuid: image.uuid!, entity_uuid: 'survey', entity_attribute: 'stopcock_images' }
            setStopcockImages([...survey.stopcock_images, newImage])
          }}
          deleteImage={(imageUUID: string) => {
            setStopcockImages(survey.stopcock_images.filter(x => x.image_uuid !== imageUUID))
          }}
          companyUUID={companyUUID}
        />
      </div>
    </div>
  </>
}
