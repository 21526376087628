import React, { type ReactElement } from 'react'
import type { PropertySurvey } from './property'
import { numberFormat } from '../number_format'
import {
  AGILE_PENCE_PER_KWH,
  getElectricityPencePerKwh,
  getHeatingFuel,
  getHeatingPencePerKWh,
  HEATING_FUELS,
  OVO_HEAT_PUMP_PLUS_PENCE_PER_KWH,
  PRICE_CAP_ELECTRICITY_PENCE_PER_KWH
} from './heating_fuel'
import { getExistingSystemEfficiencyFromSurvey } from './heating_system'
import { formatPrice } from '../format_price'
import { type InventoryHeatPump } from './inventory'

export type PerformanceEstimateSummary = {
  epcEstimate: PerformanceEstimate
  hddEstimate: PerformanceEstimate
  currentConsumptionEstimate: PerformanceEstimate
  estimatesGroup: Record<string, PerformanceEstimate> // Keep both individual and dict of estimates for now as useful in different circumstances
  numberOfMethods: number
  currentBillRange: string
  proposedBillRange: string
  minBillSavings: number
  maxBillSavings: number
  currentEmissionsRange: string
  proposedEmissionsRange: string
  minEmissionsTonnesSavings: number
  maxEmissionsTonnesSavings: number
  minEmissionsPercentageSavings: number
  maxEmissionsPercentageSavings: number
}

export type PerformanceEstimate = { 'existing': PerformanceEstimateRow, 'proposed': PerformanceEstimateRow, 'savings': PerformanceEstimateRow }

export type PerformanceEstimateRow = {
  name: string
  fuelName: string
  subtitle: ReactElement | string
  demandHotWaterKwh: string
  demandSpaceHeatingKwh: string
  demandTotalKwh: string
  efficiencyHotWater: number
  efficiencySpaceHeating: number
  consumptionHotWaterKwh: string
  consumptionSpaceHeatingKwh: string
  kwh: string
  pencePerKwhPriceCap: number
  costUserEnteredTariff: string
  costPriceCap: string
  costAgile: string
  costOvoHeatPumpPlus: string | undefined // undefined when not relevant to that heat pump
  emissionsFactorGPerKwh: number
  emissionsKG: string
}

export const eligibleForHeatPumpPlus = (currentHeatPump?: InventoryHeatPump) => {
  return currentHeatPump?.range_heat_pump?.brand_range?.brand ? ['Vaillant', 'Viessman', 'Mitsubishi'].includes(currentHeatPump.range_heat_pump?.brand_range?.brand) : false
}

export const getPerformanceEstimateSummary = (
  survey: PropertySurvey,
  heatLossWattsPerKelvin: number,
  degreeDays: number,
  designHotWaterDemandKwh: number,
  scopHotWater: number,
  scopSpaceHeating: number,
  eligibleForHeatPumpPlus: boolean
) => {
  // May have epc data stored even if not meant to use it. That messes up all the range calcs, so don't pass it in if not meant to use it
  const epcHotWaterDemandKwh = survey.use_epc_performance ? survey.epc_hot_water_kwh ?? 0 : 0
  const epcSpaceHeatingDemandKwh = survey.use_epc_performance ? survey.epc_heating_kwh ?? 0 : 0
  const epcPerformanceEstimate = getPerformanceEstimate(
    survey,
    epcHotWaterDemandKwh,
    epcSpaceHeatingDemandKwh,
    scopHotWater,
    scopSpaceHeating,
    eligibleForHeatPumpPlus,
    <>Certificate number:<br/><a className='underline' href={`https://find-energy-certificate.service.gov.uk/energy-certificate/${survey.epc_certificate_number}`}>{survey.epc_certificate_number}</a></>
  )

  // Heating degree days based
  const hddSpaceHeatingDemandKwh = Math.round((heatLossWattsPerKelvin * degreeDays * 24) / 1000)
  const hddPerformanceEstimate = getPerformanceEstimate(
    survey,
    designHotWaterDemandKwh,
    hddSpaceHeatingDemandKwh,
    scopHotWater,
    scopSpaceHeating,
    eligibleForHeatPumpPlus,
    <>{degreeDays} degree days, {heatLossWattsPerKelvin.toFixed(0)} W/°C</>)

  // Existing consumption based
  const existingSystemEfficiency = getExistingSystemEfficiencyFromSurvey(survey)
  const currentDemandKwh = survey.existing_system_annual_consumption_kWh * existingSystemEfficiency
  // Assume that hot water/ space heating split is the same as HDD based estimate
  const pctSpaceHeating = hddSpaceHeatingDemandKwh / (hddSpaceHeatingDemandKwh + designHotWaterDemandKwh)
  const currentDemandSpaceHeatingDemandKwh = currentDemandKwh * pctSpaceHeating
  const currentDemandHotWaterDemandKwh = currentDemandKwh * (1 - pctSpaceHeating)
  const fuelName = getHeatingFuel(survey.existing_system_fuel_uuid).name
  const currentConsumptionPerformanceEstimate = getPerformanceEstimate(
    survey,
    currentDemandHotWaterDemandKwh,
    currentDemandSpaceHeatingDemandKwh,
    scopHotWater,
    scopSpaceHeating,
    eligibleForHeatPumpPlus,
    <>{survey.existing_system_annual_consumption_kWh} kWh {fuelName.toLowerCase()}, {(existingSystemEfficiency * 100).toFixed(0)}% efficiency</>
  )

  return summarisePerformanceEstimates(
    epcPerformanceEstimate,
    hddPerformanceEstimate,
    currentConsumptionPerformanceEstimate
  )
}

export const summarisePerformanceEstimates = (
  epcEstimate: PerformanceEstimate,
  hddEstimate: PerformanceEstimate,
  currentConsumptionEstimate: PerformanceEstimate
): PerformanceEstimateSummary => {
  // Filter out empty estimates when doing savings calculations
  // Keep in list generally though as useful for rendering design page for now
  // below just used in report currently

  const estimatesGroupUnordered = {
    'Based on EPC': reversePriceOrNumberFormat(epcEstimate.existing.demandTotalKwh) !== 0 ? epcEstimate : undefined,
    'Based on Heat loss calculations and heating degree days': hddEstimate, // always present
    'Based on Last years consumption': reversePriceOrNumberFormat(currentConsumptionEstimate.existing.demandTotalKwh) !== 0 ? currentConsumptionEstimate : undefined
  }
  // TODO: there must be a neater way to do the below?
  const columnNamesShowingData = Object.entries(estimatesGroupUnordered).map(([key, value]) => value ? key : 'delete').filter(x => x !== 'delete')
  const columNamesNotShowingData = Object.entries(estimatesGroupUnordered).map(([key, value]) => value ? 'delete' : key).filter(x => x !== 'delete')
  const columnNamesInOrder = columnNamesShowingData.concat(columNamesNotShowingData)
  // Reorder the data so the data we have is first
  const estimatesGroup: Record<string, PerformanceEstimate> = {}
  columnNamesInOrder.forEach((key) => { estimatesGroup[key] = estimatesGroupUnordered[key] })
  const numberOfMethods = columnNamesShowingData.length

  const estimates = Object.values(estimatesGroup).filter(x => x !== undefined)

  const billsCurrent = estimates.map(x => x.existing.costPriceCap)
  const billsProposed = [
    ...estimates.map(x => x.proposed.costPriceCap),
    ...estimates.map(x => x.proposed.costAgile),
    ...estimates.map(x => x.proposed.costOvoHeatPumpPlus)
  ].filter(x => x !== undefined) as string[]
  const billSavings = [
    ...estimates.map(x => x.savings.costPriceCap),
    ...estimates.map(x => x.savings.costAgile),
    ...estimates.map(x => x.savings.costOvoHeatPumpPlus)
  ].filter(x => x !== undefined) as string[]
  const { min: minBillSavings, max: maxBillSavings } = getMinMaxFromStringList(billSavings)

  const emissionsCurrent = estimates.map(x => x.existing.emissionsKG)
  const emissionsProposed = estimates.map(x => x.proposed.emissionsKG)
  const emissionsTonnesSavings = estimates.map(x => reversePriceOrNumberFormat(x.savings.emissionsKG) / 1000)
  const { min: minEmissionsTonnesSavings, max: maxEmissionsTonnesSavings } = getMinMaxFromNumberList(emissionsTonnesSavings)
  const emissionsPercentageSavings = estimates.map(x => reversePriceOrNumberFormat(x.savings.emissionsKG) / reversePriceOrNumberFormat(x.existing.emissionsKG))
  const { min: minEmissionsPercentageSavings, max: maxEmissionsPercentageSavings } = getMinMaxFromNumberList(emissionsPercentageSavings)

  return {
    epcEstimate,
    hddEstimate,
    currentConsumptionEstimate,
    estimatesGroup,
    numberOfMethods,
    currentBillRange: getPriceRange(billsCurrent),
    proposedBillRange: getPriceRange(billsProposed),
    minBillSavings,
    maxBillSavings,
    currentEmissionsRange: getEmissionsRange(emissionsCurrent),
    proposedEmissionsRange: getEmissionsRange(emissionsProposed),
    minEmissionsTonnesSavings,
    maxEmissionsTonnesSavings,
    minEmissionsPercentageSavings,
    maxEmissionsPercentageSavings
  }
}

export const getPerformanceEstimate = (survey: PropertySurvey, hotWaterDemandKwh: number, spaceHeatingDemandKwh: number, scopHotWater: number, scopSpaceHeating: number, eligibleForHeatPumpPlus: boolean, subtitle: ReactElement | string
): PerformanceEstimate => {
  const existingSystemPerformanceEstimateRow = getExistingSystemPerformanceEstimateRow(survey, hotWaterDemandKwh, spaceHeatingDemandKwh, eligibleForHeatPumpPlus, subtitle)
  const proposedSystemPerformanceEstimateRow = getDesignPerformanceEstimateRow(hotWaterDemandKwh, spaceHeatingDemandKwh, scopHotWater, scopSpaceHeating, survey.electricity_p_per_kwh_override, eligibleForHeatPumpPlus, subtitle)
  // Doesn't make complete sense to put the savings in the same structure as the others, but done for convenience
  const SavingsPerformanceEstimateRow = {
    name: 'Savings',
    fuelName: 'Savings',
    subtitle,
    demandHotWaterKwh: numberFormat(0).format(hotWaterDemandKwh) + ' kWh',
    demandSpaceHeatingKwh: numberFormat(0).format(spaceHeatingDemandKwh) + ' kWh',
    demandTotalKwh: numberFormat(0).format(hotWaterDemandKwh + spaceHeatingDemandKwh) + ' kWh',
    efficiencyHotWater: 0, // efficiency savings doesn't make sense
    efficiencySpaceHeating: 0, // efficiency savings doesn't make sense
    consumptionHotWaterKwh: getEnergySavingFromExistingAndProposedStrings(existingSystemPerformanceEstimateRow.consumptionHotWaterKwh, proposedSystemPerformanceEstimateRow.consumptionHotWaterKwh),
    consumptionSpaceHeatingKwh: getEnergySavingFromExistingAndProposedStrings(existingSystemPerformanceEstimateRow.consumptionSpaceHeatingKwh, proposedSystemPerformanceEstimateRow.consumptionSpaceHeatingKwh),
    kwh: getEnergySavingFromExistingAndProposedStrings(existingSystemPerformanceEstimateRow.kwh, proposedSystemPerformanceEstimateRow.kwh),
    pencePerKwhPriceCap: 0,
    costUserEnteredTariff: getPriceSavingFromExistingAndProposedStrings(existingSystemPerformanceEstimateRow.costUserEnteredTariff, proposedSystemPerformanceEstimateRow.costUserEnteredTariff),
    costPriceCap: getPriceSavingFromExistingAndProposedStrings(existingSystemPerformanceEstimateRow.costPriceCap, proposedSystemPerformanceEstimateRow.costPriceCap),
    costAgile: getPriceSavingFromExistingAndProposedStrings(existingSystemPerformanceEstimateRow.costAgile, proposedSystemPerformanceEstimateRow.costAgile),
    costOvoHeatPumpPlus: proposedSystemPerformanceEstimateRow.costOvoHeatPumpPlus ? getPriceSavingFromExistingAndProposedStrings(existingSystemPerformanceEstimateRow.costOvoHeatPumpPlus!, proposedSystemPerformanceEstimateRow.costOvoHeatPumpPlus) : undefined,
    emissionsFactorGPerKwh: 0,
    emissionsKG: getCarbonSavingFromExistingAndProposedStrings(existingSystemPerformanceEstimateRow.emissionsKG, proposedSystemPerformanceEstimateRow.emissionsKG)
  }
  return { existing: existingSystemPerformanceEstimateRow, proposed: proposedSystemPerformanceEstimateRow, savings: SavingsPerformanceEstimateRow }
}

export const getExistingSystemPerformanceEstimateRow = (survey: PropertySurvey, hotWaterDemandKwh: number, spaceHeatingDemandKwh: number, eligibleForHeatPumpPlus: boolean, subtitle: ReactElement | string
): PerformanceEstimateRow => {
  const existingSystemFuel = getHeatingFuel(survey.existing_system_fuel_uuid)
  const existingSystemEfficiency = getExistingSystemEfficiencyFromSurvey(survey)
  const pencePerKwhUserEnteredTariff = getHeatingPencePerKWh(existingSystemFuel, survey.existing_system_p_per_unit_override)
  const pencePerKWhDefault = getHeatingPencePerKWh(existingSystemFuel, undefined)
  return getPerformanceEstimateRows(
    existingSystemFuel.name,
    existingSystemFuel.name,
    subtitle,
    hotWaterDemandKwh,
    spaceHeatingDemandKwh,
    existingSystemEfficiency,
    existingSystemEfficiency,
    pencePerKwhUserEnteredTariff,
    pencePerKWhDefault,
    pencePerKWhDefault,
    eligibleForHeatPumpPlus ? pencePerKWhDefault : undefined,
    existingSystemFuel.emissions_g_per_kwh ?? 0
  )
}
export const getDesignPerformanceEstimateRow = (
  hotWaterDemandKwh: number,
  spaceHeatingDemandKwh: number,
  hotWaterEfficiency: number,
  spaceHeatingEfficiency: number,
  electricityPencePerKwhOverride: number | undefined,
  eligibleForOvoHeatPumpPlus: boolean,
  subtitle: ReactElement | string
): PerformanceEstimateRow => {
  const pencePerKWh = getElectricityPencePerKwh(electricityPencePerKwhOverride)
  const electricity = HEATING_FUELS.find(x => x.uuid === 'electricity')
  return getPerformanceEstimateRows(
    'Proposed system',
    'Electricity',
    subtitle,
    hotWaterDemandKwh,
    spaceHeatingDemandKwh,
    hotWaterEfficiency,
    spaceHeatingEfficiency,
    pencePerKWh,
    PRICE_CAP_ELECTRICITY_PENCE_PER_KWH,
    AGILE_PENCE_PER_KWH,
    eligibleForOvoHeatPumpPlus ? OVO_HEAT_PUMP_PLUS_PENCE_PER_KWH : undefined,
    electricity?.emissions_g_per_kwh ?? 0
  )
}
export const getPerformanceEstimateRows = (
  systemName: string,
  fuelName: string,
  subtitle: ReactElement | string,
  hotWaterDemandKwh: number,
  spaceHeatingDemandKwh: number,
  hotWaterEfficiency: number,
  spaceHeatingEfficiency: number,
  pencePerKWhUserEntered: number,
  pencePerKWhPriceCap: number,
  pencePerKWhAgile: number,
  pencePerKWhOvoHeatPumpPlus: number | undefined,
  gramsCo2PerKwh: number): PerformanceEstimateRow => {
  if (hotWaterEfficiency > 10) {
    throw new Error(`Efficiency values should be the decimals rather than the values multiplied by 100. ${hotWaterEfficiency} passed for hot water efficiency`)
  }
  if (spaceHeatingEfficiency > 10) {
    throw new Error(`Efficiency values should be the decimals rather than the values multiplied by 100. ${spaceHeatingEfficiency} passed for space heating efficiency`)
  }

  const consumptionSpaceHeatingKwh = spaceHeatingDemandKwh / spaceHeatingEfficiency
  const consumptionHotWaterKwh = hotWaterDemandKwh / hotWaterEfficiency
  const consumptionKwh = consumptionSpaceHeatingKwh + consumptionHotWaterKwh
  const row: PerformanceEstimateRow = {
    name: systemName,
    fuelName,
    subtitle,
    demandHotWaterKwh: numberFormat(0).format(hotWaterDemandKwh) + ' kWh',
    demandSpaceHeatingKwh: numberFormat(0).format(spaceHeatingDemandKwh) + ' kWh',
    demandTotalKwh: numberFormat(0).format(hotWaterDemandKwh + spaceHeatingDemandKwh) + ' kWh',
    efficiencyHotWater: hotWaterEfficiency,
    efficiencySpaceHeating: spaceHeatingEfficiency,
    consumptionHotWaterKwh: numberFormat(0).format(consumptionHotWaterKwh) + ' kWh',
    consumptionSpaceHeatingKwh: numberFormat(0).format(consumptionSpaceHeatingKwh) + ' kWh',
    kwh: numberFormat(0).format(consumptionKwh) + ' kWh',
    pencePerKwhPriceCap: pencePerKWhPriceCap,
    costUserEnteredTariff: formatPrice(consumptionKwh * pencePerKWhUserEntered / 100),
    costPriceCap: formatPrice(consumptionKwh * pencePerKWhPriceCap / 100),
    costAgile: formatPrice(consumptionKwh * pencePerKWhAgile / 100),
    costOvoHeatPumpPlus: pencePerKWhOvoHeatPumpPlus ? formatPrice(consumptionKwh * pencePerKWhOvoHeatPumpPlus / 100) : undefined,
    emissionsFactorGPerKwh: gramsCo2PerKwh,
    emissionsKG: numberFormat(0).format(consumptionKwh * gramsCo2PerKwh / 1000) + ' kg'
  }
  return row
}

// helper functions
const getPriceRange = (prices: string []) => {
  const { min, max } = getMinMaxFromStringList(prices)
  return `${formatPrice(min)} to ${formatPrice(max)}`
}

const getEmissionsRange = (emissionsKg: string []) => {
  const { min, max } = getMinMaxFromStringList(emissionsKg)
  if (min === max) {
    return `${numberFormat(1).format(min / 1000)} tonnes CO\u2082`
  }
  return `${numberFormat(1).format(min / 1000)} to ${numberFormat(1).format(max / 1000)}  tonnes CO₂`
}

const getMinMaxFromStringList = (items: string[]) => {
  return getMinMaxFromNumberList(items.map(x => reversePriceOrNumberFormat(x)))
}

const getMinMaxFromNumberList = (items: number[]) => {
  const sortedPrices = items.sort((a, b) => a - b)
  return { min: sortedPrices[0], max: sortedPrices[sortedPrices.length - 1] }
}

export const getCarbonSavingFromExistingAndProposedStrings = (existingValue: string, proposedValue: string): string => {
  return numberFormat(0).format(getSavingFromExistingAndProposedStrings(existingValue, proposedValue)) + ' kg'
}

export const getEnergySavingFromExistingAndProposedStrings = (existingValue: string, proposedValue: string): string => {
  return numberFormat(0).format(getSavingFromExistingAndProposedStrings(existingValue, proposedValue)) + ' kWh'
}

export const getPriceSavingFromExistingAndProposedStrings = (existingValue: string, proposedValue: string): string => {
  return formatPrice(getSavingFromExistingAndProposedStrings(existingValue, proposedValue))
}

export const getSavingFromExistingAndProposedStrings = (existingValue: string, proposedValue: string): number => {
  return reversePriceOrNumberFormat(existingValue) - reversePriceOrNumberFormat(proposedValue)
}

export const reversePriceOrNumberFormat = (value: string | undefined): number => {
  if (!value) { return 0 }
  return parseFloat(value.replace('£', '').replace(/,/g, '').replace('kWh', '').replace(' kg', ''))
}
