import React from 'react'
import { ToastInner } from '../../../components/indicators_and_messaging/toast'
import { noop } from 'lodash'

export const ToastDemo = () => {
  return <div className="flex flex-col gap-8">
    <div className='flex flex-col gap-4'>
      <div className='text-2xl font-bold text-gray-900'>Definition</div>

      <pre className='bg-gray-100 rounded-lg p-4 overflow-x-auto'><code>
        {codeBlock}
      </code></pre>

      <div className='flex flex-col divide-y divide-dashed divide-gray-200 text-sm'>
        {componentProps.map(x => <div key={x.name} className='grid grid-cols-2 py-2'>
          <div className='font-bold text-gray-900'>{x.name}</div>
          <div>{x.description}</div>
        </div>)}
      </div>
    </div>

    <div className='flex flex-col gap-4'>
      <div className='text-2xl font-bold text-gray-900'>Component</div>
      <ToastInner
        setGlobalState={noop}
        toast={{ message: 'Danger!', colour: 'bg-red-500' }}
      />
    </div>

  </div>
}

const componentProps = [
  { name: 'setGlobalState', description: 'Toast state exists at top level, allows us to dismiss toast' },
  { name: 'toast', description: 'Message bundle we want to show' },
  { name: 'message', description: 'toast prop: actual message' },
  { name: 'colour', description: 'toast prop: bg-red-500 | bg-yellow-500 | bg-blue-500 | bg-green-500' }
]

const codeBlock = `<ToastInner 
  setGlobalState={noop} 
  toast={{ message: 'Danger!', colour: 'bg-red-500' }} 
/>`
