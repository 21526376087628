import React from 'react'
import { noop } from 'lodash'
import { HorizontalFormGroup } from '../../../components/inputs_and_selections/horizontal_form_group'
import { Toggle } from '../../../components/inputs_and_selections/toggle'
import { FormLabel } from '../../../components/inputs_and_selections/form_label'

export const HorizontalFormGroupDemo = () => {
  return <div className="flex flex-col gap-8">
    <div className='flex flex-col gap-4'>
      <div className='text-2xl font-bold text-gray-900'>Definition</div>

      <pre className='bg-gray-100 rounded-lg p-4 overflow-x-auto'><code>
        {codeBlock}
      </code></pre>

      <div className='flex flex-col divide-y divide-dashed divide-gray-200 text-sm'>
        {componentProps.map(x => <div key={x.name} className='grid grid-cols-2 py-2'>
          <div className='font-bold text-gray-900'>{x.name}</div>
          <div>{x.description}</div>
        </div>)}
      </div>
    </div>

    <div className='flex flex-col gap-4'>
      <div className='text-2xl font-bold text-gray-900'>Component</div>
      <HorizontalFormGroup
        formLabel={<FormLabel labelText='Hello world' helperText='some helper' />}
        input={<Toggle value={true} setValue={noop} />}
      />
    </div>

  </div>
}

const componentProps = [
  { name: 'formLabel', description: 'Accepts any react component but expects a FormLabel' },
  { name: 'input', description: 'Accepts any react component but should be a Toggle, Input type element' }
]

const codeBlock = `<HorizontalFormGroup
  formLabel={<FormLabel labelText='Hello world' helperText='some helper' />}
  toggle={<Toggle value={true} setValue={noop} />}
/>`
