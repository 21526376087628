import React, { useContext, useState } from 'react'
import { SurveyActionType } from '../../../code/survey/survey'
import { Input } from '../../../components/inputs_and_selections/input'
import { Button } from '../../../components/buttons/button'
import { ArrowLeftIcon, ChevronRightIcon } from 'lucide-react'
import { logEvent } from '../../../code/log_event'
import { type CompanyPublicInfo } from '../../../code/models/company'
import { getAddressIncludingPostcode } from '../../../code/models/lead'
import { SurveyOptionGroup } from '../../../components/inputs_and_selections/survey_option_group'
import { Caption } from '../components/caption_1'
import { StepHeader } from '../components/steps_header'
import { SubHeader } from '../components/sub_header'
import { SurveyCheckbox } from '../components/survey_checkbox'
import { SurveyContext, SurveyDispatchContext } from '../survey_page'

export const EnquiryReasonStep = ({ companyPublicInfo }: { companyPublicInfo: CompanyPublicInfo }) => {
  // survey context and dispatch
  const survey = useContext(SurveyContext)
  const dispatch = useContext(SurveyDispatchContext)

  const [heatPumpReasons, setHeatPumpReasons] = useState<string[]>([])
  const [whenToInstall, setWhenToInstall] = useState<string>('')
  const [comments, setComments] = useState<string>('')

  const handleNavigatePreviousPage = () => {
    dispatch({
      type: SurveyActionType.NavigatePreviousPage
    })
  }

  const handleNavigateNextPage = () => {
    logEvent({ name: 'Enquiry reasoning step completed', properties: {} }, companyPublicInfo.subdomain)

    dispatch({
      type: SurveyActionType.NavigateNextPage,
      payload: {
        heatPumpReason: heatPumpReasons.join(', '),
        notes: comments,
        whenHeatPump: whenToInstall
      }
    })
  }

  return <>
    {/* Back button */}
    <Button colour='LIGHT' onClick={handleNavigatePreviousPage} block={false} className="self-start">
      <div className="flex items-center gap-x-2">
        <ArrowLeftIcon className="h-4 w-4" />
        <div>Back</div>
      </div>
    </Button>

    {/* Header */}
    <StepHeader
      guideText={getAddressIncludingPostcode(survey.lead)}
      headerText="Your enquiry"
    />

    {/* Heat pump installation reason */}
    <div className="flex flex-col gap-4">
      <div className="flex flex-col gap-1">
        <SubHeader content="Why do you want a heat pump (optional)?" />
        <Caption content="Select all that apply." />
      </div>
      <SurveyCheckbox
        label="Save money"
        key="save-money"
        onSelect={() => setHeatPumpReasons(prev => prev.includes('save-money') ? prev.filter(x => x !== 'save-money') : [...prev, 'save-money'])}
        selected={heatPumpReasons.includes('save-money')}
      />
      <SurveyCheckbox
        label="Go green"
        key="go-green"
        onSelect={(v) => setHeatPumpReasons(prev => prev.includes('go-green') ? prev.filter(x => x !== 'go-green') : [...prev, 'go-green'])}
        selected={heatPumpReasons.includes('go-green')}
      />
      <SurveyCheckbox
        label="Better comfort"
        key="better-comfort"
        onSelect={(v) => setHeatPumpReasons(prev => prev.includes('better-comfort') ? prev.filter(x => x !== 'better-comfort') : [...prev, 'better-comfort'])}
        selected={heatPumpReasons.includes('better-comfort')}
      />
    </div>

    {/* When do you want a heat pump? */}
    <div className="flex flex-col gap-4">
      <SubHeader content="When do you want the heat pump (optional)?" />
      <SurveyOptionGroup options={[
        { label: 'As soon as possible', key: 'asap' },
        { label: 'Within 6 months', key: '6mo' },
        { label: 'In the next 1-2 years', key: '1-2y' },
        { label: 'I’m unsure', key: 'unsure' }
      ]} onSelect={setWhenToInstall} selectedOptionKey={whenToInstall} />
    </div>

    {/* Additional info */}
    <div className="flex flex-col gap-4">
      <SubHeader content="Any final information we should know (optional)?" />
      <Input
        size='LG'
        setValue={setComments}
        type="text"
        value={comments}
        placeholder={'E.g. I have a mix of double and single glazing'}
      />
    </div>

    <Button size='LG' onClick={handleNavigateNextPage}>
      <div className="flex items-center gap-x-2">
        <div>Continue</div>
        <ChevronRightIcon className="h-6 w-6" />
      </div>
    </Button>
  </>
}
