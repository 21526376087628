// TODO: should get rid of this and move any useful functionality into the heating fuels model
// Currently this is used int he estimate and heating_fuels.ts is used in the survey
import { KWH_PER_LITRE_OF_OIL, PRICE_CAP_ELECTRICITY_PENCE_PER_KWH, PRICE_CAP_GAS_PENCE_PER_KWH } from './heating_fuel'

export const getFuelTypeFromEPC = (mainFuelFromEPC: string) => {
  mainFuelFromEPC = mainFuelFromEPC.toLowerCase()
  if (mainFuelFromEPC.includes('lpg')) return 'LPG'
  if (mainFuelFromEPC.includes('oil')) return 'Oil'
  if (mainFuelFromEPC.includes('gas')) return 'Mains Gas'
  if (['electricity', 'heat pump'].some(x => mainFuelFromEPC.includes(x))) return 'Electric'

  return mainFuelFromEPC
}

// gCO2PerkWh values from SAP 10.2, Table 12. Electricity values are based on BEIS future generation mix predictions
//    might be clearer to use current values and update each year, but it would underestimate the carbon savings
//    worth changing method if we want to include a detailed explanation of how calculated however
// Efficiencies from SAP 10, Table 4b. Gas: condensing combi, Oil: "Standard oil boiler, 1998 or later"
const pPerLitreOfOil = 75.0 // https://www.boilerjuice.com/heating-oil-prices/
// For fossil fuel systems hard coding the "middle" age efficiencies used in the surveys for now
export const FUELS: Fuel[] = [
  { uuid: '27704272-f79e-4ac5-93bc-0d5be17d08ff', name: 'Mains Gas', gCO2PerkWh: 210, pPerkWh: PRICE_CAP_GAS_PENCE_PER_KWH, pPerDay: 31.66, defaultHeatingSystemEfficiency: 0.87 },
  { uuid: 'a8a369a9-9461-4a3e-a91d-e215083146c0', name: 'LPG', gCO2PerkWh: 241, pPerkWh: 14.49, pPerDay: 0, defaultHeatingSystemEfficiency: 0.87 },
  { uuid: 'b0f7ac71-10d2-486d-a9c7-d10bdc9fe109', name: 'Oil', gCO2PerkWh: 298, pPerkWh: pPerLitreOfOil / KWH_PER_LITRE_OF_OIL, pPerDay: 0, defaultHeatingSystemEfficiency: 0.87 },
  { uuid: 'b2bad9af-3c29-40fe-8375-6fa97740463b', name: 'Electric', gCO2PerkWh: 136, pPerkWh: PRICE_CAP_ELECTRICITY_PENCE_PER_KWH, pPerDay: 60.99, defaultHeatingSystemEfficiency: 1.0 },
  { uuid: 'ef5bf930-11aa-403d-b8ad-90a38dcbf1ca', name: 'Other', gCO2PerkWh: 210, pPerkWh: PRICE_CAP_GAS_PENCE_PER_KWH, pPerDay: 0, defaultHeatingSystemEfficiency: 0.87 }
]// use mains gas for Other for now. Likely to be either coal or wood. Other option would be to leave undefined and not produce a carbon saving value?

export const getFuelByName = (name: string): Fuel => {
  return FUELS.find(fuel => fuel.name === name) ?? FUELS.find(fuel => fuel.name === 'Mains gas')!
}

export type Fuel = {
  uuid: string
  name: string
  gCO2PerkWh: number
  pPerkWh: number
  pPerDay: number
  defaultHeatingSystemEfficiency: number
}
