import React, { useState } from 'react'
import { Input } from '../../../components/inputs_and_selections/input'
import { noop } from 'lodash'
import { Icon } from '../../../components/buttons/icon'
import { Square } from 'lucide-react'

export const InputDemo = () => {
  const [inputText, setInputText] = useState('')

  return <div className="flex flex-col gap-8">
    <div className='flex flex-col gap-4'>
      <div className='text-2xl font-bold text-gray-900'>Definition</div>

      <pre className='bg-gray-100 rounded-lg p-4 overflow-x-auto'><code>
        {codeBlock}
      </code></pre>

      <div className='flex flex-col divide-y divide-dashed divide-gray-200 text-sm'>
        {componentProps.map(x => <div key={x.name} className='grid grid-cols-2 py-2'>
          <div className='font-bold text-gray-900'>{x.name}</div>
          <div>{x.description}</div>
        </div>)}
      </div>
    </div>

    <div className='flex flex-col gap-4'>
      <div className='text-2xl font-bold text-gray-900'>Component</div>
      <div>Default</div>
      <Input
        onEnter={noop}
        value={inputText}
        setValue={setInputText}
        type='text'
        step={undefined}
        label='Label'
        placeholder='placeholder'
        size='MD'
        shrink={false}
        validator={(e) => ({ message: '', value: e })}
        className=''
        disabled={false}
        postfix={<Icon icon={Square} />}
        prefix={<Icon icon={Square} />}
      />

      <div>Invalid (focus and unfocus to test)</div>
      <Input
        value={inputText}
        setValue={setInputText}
        validator={(e) => ({ message: 'Not valid', value: undefined })}
      />

      <div>Disabled</div>
      <Input
        disabled={true}
        value={inputText}
        setValue={setInputText}
      />
    </div>

  </div>
}

const componentProps = [
  { name: 'onEnter', description: 'If we hit the enter key while focussed do something, e.g. call the submit form onClick' },
  { name: 'value', description: 'Value of the input' },
  { name: 'setValue', description: 'What to do on keypress of input' },
  { name: 'type', description: 'number | text' },
  { name: 'step', description: 'If input is a number, what should we increment by?' },
  { name: 'label', description: 'Label text above the input' },
  { name: 'placeholder', description: 'Placeholder when no value is present' },
  { name: 'size', description: 'SM | MD | LG' },
  { name: 'shrink', description: 'Prevent the input from being full width of container' },
  { name: 'validator', description: 'Validate the input, if message is not empty show error state' },
  { name: 'className', description: 'Any extra formatting or styling required' },
  { name: 'disabled', description: 'Should the input be disabled' },
  { name: 'prefix', description: 'Should anything exist before the input? Usually an icon' },
  { name: 'postfix', description: 'Should anything exist after the input? Usually an icon' }
]

const codeBlock = `const [inputText, setInputText] = useState('')

<Input
  onEnter={noop}
  value={inputText}
  setValue={setInputText}
  type='text'
  step={undefined}
  label='Label'
  placeholder='placeholder'
  size='MD'
  shrink={false}
  validator={(e) => ({ message: '', value: e })}
  className=''
  disabled={false}
  postfix={<Icon icon={Square} />}
  prefix={<Icon icon={Square} />}
/>`
