import {
  BubbleMenu,
  EditorContent,
  type JSONContent,
  NodeViewWrapper,
  ReactNodeViewRenderer,
  useEditor
} from '@tiptap/react'
import StarterKit from '@tiptap/starter-kit'
import Link from '@tiptap/extension-link'
import React, { useCallback, useEffect } from 'react'

import { Editor, mergeAttributes, Node } from '@tiptap/core'
import { type TiptapPlaceholderProps } from '../../code/tiptap_placeholders'
import { Button } from '../buttons/button'

export function tiptapHTMLToJSON (html: string): JSONContent {
  // a hack to get the default estimate email text in Tiptap JSON format: apply HTML and then get JSON
  const editor = new Editor({
    extensions: TiptapExtensions,
    content: html
  })
  return editor.getJSON()
}

const InlinePlaceholderWrapper = (props) => {
  return (
    <NodeViewWrapper as={'span'}>
      {/* eslint-disable-next-line react/prop-types */}
      <span className='bg-amber-100 rounded'>{props.node.attrs.label}</span>
    </NodeViewWrapper>
  )
}

const ButtonPlaceholderWrapper = (props) => {
  return (
    <NodeViewWrapper as={'div'}>
      <div className={'table m-auto text-center'}>
        {/* eslint-disable-next-line react/prop-types */}
        <Button>{ props.node.attrs.label }</Button>
      </div>
    </NodeViewWrapper>
  )
}

const ButtonPlaceholderComponent = Node.create({
  name: 'button-ph',
  inline: false,
  group: 'block',
  atom: true,
  addAttributes () {
    return {
      id: { default: '' },
      label: { default: '' }
    }
  },
  parseHTML () { return [{ tag: 'button-ph' }] },
  renderHTML ({ HTMLAttributes }) { return ['button-ph', mergeAttributes(HTMLAttributes)] },
  addNodeView () { return ReactNodeViewRenderer(ButtonPlaceholderWrapper) }
})

const InlinePlaceholderComponent = Node.create({
  name: 'inline-ph',
  inline: true,
  group: 'inline',
  atom: true,
  addAttributes () {
    return {
      id: { default: '' },
      label: { default: '' }
    }
  },
  parseHTML () { return [{ tag: 'inline-ph' }] },
  renderHTML ({ HTMLAttributes }) { return ['inline-ph', mergeAttributes(HTMLAttributes)] },
  addNodeView () { return ReactNodeViewRenderer(InlinePlaceholderWrapper) }
})

type TiptapProps = {
  editable: boolean
  className: string
  content: JSONContent
  onUpdateCallback: (editor: Editor) => void
  placeholders?: TiptapPlaceholderProps[]
  noPlaceholderButtons?: boolean
  onEditorInit?: (editor: Editor) => void
}

// share extensions between all editors and for background rendering
export const TiptapExtensions = [
  StarterKit,
  Link.configure({
    openOnClick: false,
    autolink: true
  }),
  InlinePlaceholderComponent,
  ButtonPlaceholderComponent
]

export const Tiptap = ({ editable, className, content, onUpdateCallback, placeholders, noPlaceholderButtons, onEditorInit }: TiptapProps) => {
  const editor = useEditor({
    editable,
    extensions: TiptapExtensions,
    editorProps: {
      attributes: {
        class: className
      }
    },
    onUpdate: ({ editor }) => {
      onUpdateCallback(editor)
    },
    content
  })

  // apply className
  useEffect(() => {
    if (!editor) {
      return
    }
    editor?.setOptions({
      editorProps: {
        attributes: { class: className }
      }
    })
  }, [className])

  useEffect(() => {
    if (!onEditorInit) {
      return
    }
    if (editor) {
      onEditorInit(editor)
    }
  }, [onEditorInit, editor])

  useEffect(() => {
    if (!editor) {
      return
    }
    editor?.setEditable(editable)
  }, [editable])

  const setLink = useCallback(() => {
    if (!editor) {
      return null
    }

    const previousUrl = editor.getAttributes('link').href
    const url = window.prompt('URL', previousUrl)

    // cancelled
    if (url === null) {
      return
    }

    // empty
    if (url === '') {
      editor.chain().focus().extendMarkRange('link').unsetLink()
        .run()

      return
    }

    // update link
    editor.chain().focus().extendMarkRange('link').setLink({ href: url })
      .run()
  }, [editor])

  if (!editor) {
    return null
  }

  return (
    <div>
      {editor && <BubbleMenu
        className="flex flex-row gap-2 bg-black p-1 rounded text-sm" tippyOptions={{ duration: 100 }}
        editor={editor}
      >
        <button
          onClick={() => editor.chain().focus().toggleBold().run()}
          className={`${editor.isActive('bold') ? 'text-blue-300' : 'text-white'} border-0 bg-none p-1 hover:text-gray-300`}
        >
              Bold
        </button>
        <button
          onClick={() => editor.chain().focus().toggleItalic().run()}
          className={`${editor.isActive('italic') ? 'text-blue-300' : 'text-white'} border-0 bg-none p-1 hover:text-gray-300`}
        >
              Italic
        </button>
        <button
          onClick={setLink}
          className={`${editor.isActive('link') ? 'text-blue-300' : 'text-white'} border-0 bg-none p-1 hover:text-gray-300`}
        >
              Link
        </button>
        {editor.isActive('link') &&
            <button
              onClick={() => editor.chain().focus().unsetLink().run()}
              className={`${editor.isActive('link') ? 'text-blue-300' : 'text-white'} border-0 bg-none p-1 hover:text-gray-300`}
            >
                Unlink
            </button>
        }
        <button
          onClick={() => editor.chain().focus().toggleHeading({ level: 1 }).run()}
          className={`${editor.isActive('heading', { level: 1 }) ? 'text-blue-300' : 'text-white'} border-0 bg-none p-1 hover:text-gray-300`}
        >
          Heading
        </button>
      </BubbleMenu>}

      {/* Wrapper */}
      <div>
        { placeholders && !noPlaceholderButtons && <div className="pb-2 justify-start items-start gap-2.5 flex overflow-x-auto relative no-scrollbar md:flex-wrap">
          { placeholders?.map((control) => {
            return <button
              key={control.id}
              onClick={() => editor.chain().focus().insertContent(control.code).run()}
              className={`${control.type === 'button-ph' ? 'hover:bg-black text-white bg-gray-700' : 'hover:bg-amber-300 bg-amber-100'} border-0 bg-none py-1 px-2 whitespace-nowrap text-nowrap text-sm rounded`}
            >
              {control.label}
            </button>
          })}
        </div>}
        <EditorContent editor={editor} className={`${editable ? '' : 'bg-gray-100'}`}/>
      </div>
    </div>
  )
}
