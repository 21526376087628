import { orderBy } from 'lodash'
import { z } from 'zod'
import { BrandRangeSchema } from './brand_range'

const HeatPumpCapacityAtFlowAndOutsideTempSchema = z.object({
  uuid: z.string(),
  flow_temperature_c: z.number(),
  outside_temperature_c: z.number(),
  capacity: z.number()
})

// HeatPumpScopAtFlowTemp schema
const HeatPumpScopAtFlowTempSchema = z.object({
  uuid: z.string(),
  flow_temperature_c: z.number(),
  scop: z.number()
})

// RangeHeatPump schema
export const RangeHeatPumpSchema = z.object({
  uuid: z.string(),
  deleted_at: z.date().optional(),
  name: z.string(),
  refrigerant: z.string(),
  model_number: z.string(),
  sound_power_max_dba: z.number(),
  width_mm: z.number(),
  height_mm: z.number(),
  depth_mm: z.number(),
  weight_kg: z.number(),
  mcs_certificate_numbers: z.array(z.string()),
  brand_range_uuid: z.string(),
  brand_range: BrandRangeSchema,
  range_heat_pumps_by_flow: z.array(HeatPumpScopAtFlowTempSchema),
  range_heat_pumps_by_flow_and_outside: z.array(HeatPumpCapacityAtFlowAndOutsideTempSchema)
})

export type HeatPumpCapacityAtFlowAndOutsideTemp = z.infer<typeof HeatPumpCapacityAtFlowAndOutsideTempSchema>
export type HeatPumpScopAtFlowTemp = z.infer<typeof HeatPumpScopAtFlowTempSchema>
export type RangeHeatPump = z.infer<typeof RangeHeatPumpSchema>

export const getHeatPumpScopAtFlowTemp = (rangeHeatPump: RangeHeatPump | undefined, flowTempC: number): number => {
  if (!rangeHeatPump?.range_heat_pumps_by_flow) return 0

  const scopsByFlowTemp = rangeHeatPump.range_heat_pumps_by_flow
  const exactMatch = scopsByFlowTemp.find(x => x.flow_temperature_c === flowTempC)
  if (exactMatch) return exactMatch.scop

  const scopsFlowTempAbove = orderBy(scopsByFlowTemp.filter(x => x.flow_temperature_c > flowTempC),
    x => x.flow_temperature_c - flowTempC)[0]
  if (!scopsFlowTempAbove) return 0 // this value is above the highest flow temp we have data for

  const scopsFlowTempBelow = orderBy(scopsByFlowTemp.filter(x => x.flow_temperature_c < flowTempC),
    x => flowTempC - x.flow_temperature_c)[0]
  if (!scopsFlowTempBelow) return scopsFlowTempAbove.scop
  // Case where flow temp is below the lowest flow temp we have data for - take scop at lowest flow temp we have

  return Number((
    linearInterpolate(
      scopsFlowTempBelow.flow_temperature_c,
      scopsFlowTempBelow.scop,
      scopsFlowTempAbove.flow_temperature_c,
      scopsFlowTempAbove.scop, flowTempC) ??
    0).toFixed(2))
}

export type CapacityResult = { capacityKw: number, flowTempC: number, outsideTempC: number, warning: string | undefined }

export const getHeatPumpCapacityAtOutsideTempAndFlowTemp = (rangeHeatPump: RangeHeatPump | undefined, outsideTempC: number, flowTempC: number): CapacityResult => {
  if (!rangeHeatPump) return { capacityKw: 0, flowTempC: 0, outsideTempC: 0, warning: 'No data for this heat pump' }

  const capacitiesByFlowAndOutsideTemp = rangeHeatPump.range_heat_pumps_by_flow_and_outside
  if (!capacitiesByFlowAndOutsideTemp) return { capacityKw: 0, flowTempC: 0, outsideTempC: 0, warning: 'No data for this heat pump' }
  const exactMatch = capacitiesByFlowAndOutsideTemp.find(c => c.outside_temperature_c === outsideTempC && c.flow_temperature_c === flowTempC)
  if (exactMatch) return { capacityKw: exactMatch.capacity, flowTempC, outsideTempC, warning: undefined }

  // interpolate the capacity based on the outside temp and flow temp
  // Find the closest match (calculate based on the closest distance in the 2 dimensions)
  // Valid cases here are: CorrectXY, xBelowCorrectY (flow temp is below the range we have data for, so capacity conservative), xCorrectYAbove ( air temp is above the range we have data for, so capacity conservative)
  const interpolationResult = bilinearInterpolate(
    capacitiesByFlowAndOutsideTemp.map(c => ({
      x: c.flow_temperature_c,
      y: c.outside_temperature_c,
      output: c.capacity
    })).filter(c => c.output > 0), // filter out any 0 values
    flowTempC,
    outsideTempC)

  return {
    capacityKw: Number(interpolationResult.dataPoint.output.toFixed(2)),
    flowTempC: interpolationResult.dataPoint.x,
    outsideTempC: interpolationResult.dataPoint.y,
    warning: interpolationResult.accuracy !== 'CorrectXY'
      ? `The design conditions (${flowTempC} °C flow, ${outsideTempC} °C air) are outside the bounds that we have manufacturer's capacity data for. We've used the closest value we have (${interpolationResult.dataPoint.x} °C flow, ${interpolationResult.dataPoint.y} °C air). Please contact steph@spruce.eco if you think we should have data at these conditions!`
      : undefined
  }
}

export const findMaxValidFlowTempForScop = (rangeHeatPump: RangeHeatPump | undefined): number => {
  if (!rangeHeatPump?.range_heat_pumps_by_flow) return 0
  return Math.max(...rangeHeatPump.range_heat_pumps_by_flow.map(x => x.scop > 0 ? x.flow_temperature_c : undefined).filter(x => x !== undefined) as number[])
}

export const findMinValidFlowTempForScop = (rangeHeatPump: RangeHeatPump | undefined): number => {
  if (!rangeHeatPump?.range_heat_pumps_by_flow) return 0
  return Math.min(...rangeHeatPump.range_heat_pumps_by_flow.map(x => x.scop > 0 ? x.flow_temperature_c : undefined).filter(x => x !== undefined) as number[])
}

// Helpers for interpolation

type DataPoint = { x: number, y: number, output: number }
type InterpolationResult = { accuracy: 'CorrectXY' | 'xBelowCorrectY' | 'xAboveCorrectY' | 'xCorrectYBelow' | 'xCorrectYAbove' | 'outOfBoundsUsedNearest' | 'noData', dataPoint: DataPoint }
// "xBelow" refers to the fact that the x value entered is below the bounds that we have data for
// So the x value passed in was below the bounds of the data we have

// Function to perform bilinear interpolation
// https://en.wikipedia.org/wiki/Bilinear_interpolation
// Assumes that you have all the x's for each y and vice versa ( i.e. a parallel grid)
// If that breaks then we need to use something like inverse distance weighting
// If you can't return a data that is within a grid, return a flag to say so.
// In all cases return the result along with the x and y it's calculated at
export const bilinearInterpolate = (dataPoints: DataPoint[], x: number, y: number): InterpolationResult => {
  // Sort dataPoints based on x and y
  dataPoints.sort((a, b) => {
    if (a.x === b.x) {
      return a.y - b.y
    }
    return a.x - b.x
  })

  // Find the four dataPoints for interpolation - flow temp on x, outside temp on y
  let xLowYLow, xLowYHigh, xHighYLow, xHighYHigh
  xLowYLow = xLowYHigh = xHighYLow = xHighYHigh = null

  for (const dataPoint of dataPoints) {
    if (dataPoint.x <= x && dataPoint.y <= y && (!xLowYLow || ((dataPoint.x >= xLowYLow.x) && (dataPoint.y >= xLowYLow.y)))) {
      xLowYLow = dataPoint
    }
    if (dataPoint.x >= x && dataPoint.y <= y && (!xHighYLow || ((dataPoint.x <= xHighYLow.x) && (dataPoint.y >= xHighYLow.y)))) {
      xHighYLow = dataPoint
    }
    if (dataPoint.x <= x && dataPoint.y >= y && (!xLowYHigh || ((dataPoint.x >= xLowYHigh.x) && (dataPoint.y <= xLowYHigh.y)))) {
      xLowYHigh = dataPoint
    }
    if (dataPoint.x >= x && dataPoint.y >= y && (!xHighYHigh || ((dataPoint.x <= xHighYHigh.x) && (dataPoint.y <= xHighYHigh.y)))) {
      xHighYHigh = dataPoint
    }
  }

  if (!xLowYLow || !xLowYHigh || !xHighYLow || !xHighYHigh) {
    if (xHighYLow && xHighYHigh) {
      // Don't have data for x this low - so just linear interpolate at the lowest x we have
      return { accuracy: 'xBelowCorrectY', dataPoint: { x: xHighYLow.x, y, output: linearInterpolate(xHighYLow.y, xHighYLow.output, xHighYHigh.y, xHighYHigh.output, y) ?? 0 } }
    }
    if (xLowYLow && xLowYHigh) {
      // Don't have data for x this high - so just linear interpolate at the highest x we have
      return { accuracy: 'xAboveCorrectY', dataPoint: { x: xLowYLow.x, y, output: linearInterpolate(xLowYLow.y, xLowYLow.output, xLowYHigh.y, xLowYHigh.output, y) ?? 0 } }
    }

    if (xLowYHigh && xHighYHigh) {
      // Don't have data for y this low - so just linear interpolate at the lowest y we have
      return { accuracy: 'xCorrectYAbove', dataPoint: { x, y: xLowYHigh.y, output: linearInterpolate(xLowYHigh.x, xLowYHigh.output, xHighYHigh.x, xHighYHigh.output, x) ?? 0 } }
    }

    if (xLowYLow && xHighYLow) {
      // Don't have data for y this high - so just linear interpolate at the highest y we have
      return { accuracy: 'xCorrectYAbove', dataPoint: { x, y: xLowYLow.y, output: linearInterpolate(xLowYLow.x, xLowYLow.output, xHighYLow.x, xHighYLow.output, x) ?? 0 } }
    }

    //   Cases where only have one data point (x,y is not in x range or y range), just use the nearest point. which will be the only defined point
    if (xLowYLow) return { accuracy: 'outOfBoundsUsedNearest', dataPoint: xLowYLow }
    if (xLowYHigh) return { accuracy: 'outOfBoundsUsedNearest', dataPoint: xLowYHigh }
    if (xHighYLow) return { accuracy: 'outOfBoundsUsedNearest', dataPoint: xHighYLow }
    if (xHighYHigh) return { accuracy: 'outOfBoundsUsedNearest', dataPoint: xHighYHigh }
    // error if we get here
  }

  const xLowYCorrectOutput = linearInterpolate(xLowYLow.y, xLowYLow.output, xLowYHigh.y, xLowYHigh.output, y)
  const xHighYCorrectOutput = linearInterpolate(xHighYLow.y, xHighYLow.output, xHighYHigh.y, xHighYHigh.output, y)
  if (xLowYCorrectOutput !== undefined && xHighYCorrectOutput !== undefined) {
    return { accuracy: 'CorrectXY', dataPoint: { x, y, output: linearInterpolate(xLowYLow.x, xLowYCorrectOutput, xHighYHigh.x, xHighYCorrectOutput, x) } }
  }
  // The below shouldn't happen - but keeping in in case there are cases I haven't thought about
  throw new Error(`Error in bilinearInterpolate - no values found for ${x} ${y}`)
}

export const linearInterpolate = (x0: number, y0: number, x1: number, y1: number, x: number): number => {
  if (x0 === x) { // Case where the two data points are the same
    return y0
  }

  // If for some reason x1 = x0 but x != x0, then the below will give a divide by 0 error?
  // Don't see a case where this will happen though
  return y0 + (y1 - y0) * (x - x0) / (x1 - x0)
}
