import { CheckCircle } from 'lucide-react'
import React from 'react'

type SurveyOptionGroupProps = {
  options: Array<{ icon?: any, label: string, sublabel?: string, key: string }>
  selectedOptionKey?: string
  onSelect?: (key: any) => void
  noCheckmark?: boolean
  inline?: boolean
}

export const SurveyOptionGroup: React.FC<SurveyOptionGroupProps> = ({ options, selectedOptionKey, onSelect, noCheckmark = false, inline = false }) => {
  // a function to calc class for selected option
  const selectedClass = (key: string) => {
    if (selectedOptionKey === key) {
      return 'border-solid border-2 border-gray-700'
    } else {
      // unselected option also has an invisible border (color is the same as background),
      // it prevents the layout from jumping when the selected state visible border appears
      // playing with padding didn't help
      return 'border-solid border-2 border-gray-100 bg-gray-100'
    }
  }

  const positioningClasses = inline ? 'flex-row' : 'flex-col'

  return <div className={`${positioningClasses} w-full gap-3 flex`}>
    {options.map((option, index) => {
      return <div key={index} onClick={() => { onSelect?.(option.key) }}
        className={`${selectedClass?.(option.key)} cursor-pointer rounded-xl items-center flex justify-between p-2 sm:p-3`}>

        <div className="flex gap-x-4 items-center">
          {option.icon && <img className="h-8 w-8 sm:h-16 sm:w-16" alt={option.label} src={option.icon} />}
          <div className="flex-col flex text-sm sm:text-base">
            <div className="text-gray-900 font-bold">{option.label}</div>
            {option.sublabel && <div className="select-none text-gray-500">{option.sublabel}</div>}
          </div>
        </div>

        {!noCheckmark && <>
          {selectedOptionKey === option.key && <CheckCircle className="w-5 h-5" />}
          {selectedOptionKey !== option.key && <div className="w-5 h-5">
            <div className="w-5 h-5 bg-white rounded-full border-solid border border-neutral-300" />
          </div>}
        </>}
      </div>
    })}
  </div>
}
