import React, { useEffect, useState } from 'react'
import { type CompanyPublicInfo } from '../code/models/company'
import { Loader } from '../components/indicators_and_messaging/loader'
import {
  type CustomerFacingQuoteItem,
  getQuotePDFAPI,
  getQuotePublicAPI,
  type QuoteSnapshot,
  type QuoteSnapshotData
} from '../code/models/proposal'
import { Button } from '../components/buttons/button'
import { Heading } from '../components/content_display/heading'
import { Text } from '../components/content_display/text'
import { CalculatedQuoteDefaultGroups, type QuoteItem, quoteItemNameWithQuantity } from '../code/calculate_quote'
import { formatPrice } from '../code/format_price'
import { groupBy } from 'lodash'
import { Badge } from '../components/indicators_and_messaging/badge'
import { HeatPump } from '../assets/images/survey_images/survey_images'
import { PrintLayout, PrintLayoutPageBreak } from './components/print_layout'
import { downloadBlob } from '../code/helpers'
import { renderHTMLReplacingPlaceholders } from '../code/tiptap_placeholders'
import { FileText } from 'lucide-react'

type QuotePublicPageProps = {
  companyPublicInfo: CompanyPublicInfo
  quoteUUID?: string
  snapshot?: QuoteSnapshotData
}

const PrimaryQuoteItemRow = ({ item, subtotal, showImage = false }: { item: CustomerFacingQuoteItem, subtotal?: number, showImage?: boolean }) => {
  return (
    <div className={ `grid gap-2 py-5 ${showImage ? 'grid-cols-[56px_1fr]' : 'grid-cols-1'}` }key={item.uuid}>
      {/* Icon or image */}
      {showImage &&
        <img
          src={item.image_url || HeatPump}
          alt={item.name}
          className='w-12 h-12 object-contain'
          onError={({ currentTarget }) => {
            currentTarget.onerror = null
            currentTarget.src = HeatPump
          }}
        />
      }
      <div className='flex gap-2'>
        <div className='flex flex-col gap-1'>
          <Text size='SM' bold>{item.name}</Text>
          <Text size='XS' className='text-gray-500'>{item.description}</Text>
        </div>
        {subtotal && <div className='ml-auto text-right'>
          <Text size='SM'>{formatPrice(subtotal, 2)}</Text>
        </div>}
      </div>
    </div>
  )
}

const QuoteItemGroupRow = ({ groupName, items, subtotal, postfix }: { groupName: string, items: CustomerFacingQuoteItem[], subtotal: number, postfix?: string }) => {
  return (
    <div className='py-5 flex flex-col gap-3'>
      <div className='flex flex-row items-center justify-between'>
        <Text size="SM" bold>{groupName}</Text>
        {subtotal > 0 && <Text size="SM">{formatPrice(subtotal, 2)}</Text>}
      </div>
      <div className='flex flex-col gap-2'>
        {items.map(x => (
          <div className="ml-6" key={x.uuid}>
            <Text size="XS" bold>{quoteItemNameWithQuantity(x as QuoteItem, postfix)}</Text>
            {x.description && <Text size="XS" className="text-gray-500">{x.description}</Text>}
          </div>
        ))}
      </div>
    </div>
  )
}

export const QuotePublicPage = (props: QuotePublicPageProps) => {
  // If we're passing in a live snapshot, this page is being embedded somewhere in the dashboard,
  // so we fill the rest of the snapshot data with defaults
  const [quote, setQuote] = useState<QuoteSnapshot | undefined>(props.snapshot ? {
    snapshot: props.snapshot,
    recipients: [],
    email_text: ''
  } : undefined)

  useEffect(() => {
    const asyncCall = async () => {
      if (!props.quoteUUID) return
      const result = await getQuotePublicAPI(props.quoteUUID, props.companyPublicInfo.uuid)
      if (result) {
        setQuote(result)
      }
    }
    if (props.quoteUUID) {
      asyncCall()
    }
  }, [])

  if (!quote) {
    return (
      <div className="flex flex-col items-center justify-center mt-10 mx-auto">
        <Loader/>
      </div>
    )
  }

  return <>
    {/* no wrapper for print version */}
    <div className='hidden print:block'>
      <QuotePublicPageInner
        quoteUUID={props.quoteUUID}
        companyPublicInfo={props.companyPublicInfo}
        snapshot={quote.snapshot}
      />
    </div>

    {/* has wrapper for screen version */}
    <div className='print:hidden bg-gray-100 lg:p-6 md:p-4 p-0'>
      <div className='bg-white max-w-[260mm] m-auto pb-6 rounded'>
        <QuotePublicPageInner
          quoteUUID={props.quoteUUID}
          companyPublicInfo={props.companyPublicInfo}
          snapshot={quote.snapshot}
        />
      </div>
    </div>

    {/* DO NOT REMOVE! This selector is used by browserless */}
    <div id='pdf-ready'/>
  </>
}

type QuotePublicPageInnerProps = {
  quoteUUID?: string
  companyPublicInfo: CompanyPublicInfo
  snapshot: QuoteSnapshotData
}

export const QuotePublicPageInner = (props: QuotePublicPageInnerProps) => {
  const logoImageData = props.companyPublicInfo.logo

  return <PrintLayout
    headerHeightPx={120}
    header={<>
      <div className={[
        'w-full bg-white border-b border-b-gray-200 justify-between items-center gap-6 flex px-1 py-1',
        'print:p-6', // print
        'sm:px-3 sm:py-3 ', // sm
        'md:px-6 md:py-6 ', // md
        'lg:top-0 ' // lg
      ].join(' ')}>
        {/* logo and MCS certification */}
        <div className='flex flex-row gap-4'>
          {logoImageData && <img src={logoImageData} className="max-h-10 md:max-h-12" alt="Installer logo"/>}
          {/* TODO MCS cert logo */}
        </div>

        {/* action buttons */}
        <div className={'flex flex-row gap-4 print:hidden'}>
          {props.quoteUUID && <Button
            colour={'LIGHT'}
            iconLeft={FileText}
            onClick={async () => {
              const pdfData = await getQuotePDFAPI(
                props.quoteUUID!,
                props.companyPublicInfo.uuid
              )
              downloadBlob(pdfData!, 'Heat Pump Installation Quote.pdf')
            }}
          ><span className='hidden sm:inline'>Save to PDF</span></Button>}
        </div>
      </div>
    </>}

    content={<>
      {/* content container */}
      <div className={'px-3 md:px-6 print:px-0'}>
        <PrintLayoutPageBreak/>
        <Heading size='3xl' className='pb-10'>
          Your heat pump quote
        </Heading>

        <PublicQuoteBlock quoteSnapshot={props.snapshot}/>

        <PublicQuotePaymentScheduleBlock quoteSnapshot={props.snapshot}/>
      </div>
    </>}
  />
}

type PublicQuoteBlockProps = {
  quoteSnapshot: QuoteSnapshotData
}

export const PublicQuoteBlock = ({ quoteSnapshot }: PublicQuoteBlockProps) => {
  const groupedQuote = groupBy(quoteSnapshot.quote_items, 'group_name')

  const total = quoteSnapshot.total_pre_deductions + quoteSnapshot.vat_on_all + quoteSnapshot.vat_on_survey + quoteSnapshot.discount_total + quoteSnapshot.bus_grant

  return <div className="flex flex-col divide-y divide-gray-200 border-y border-gray-200">
    {groupedQuote[CalculatedQuoteDefaultGroups.HEAT_PUMPS]?.map(x => <PrimaryQuoteItemRow key={x.uuid} item={x}
      subtotal={quoteSnapshot.group_totals[CalculatedQuoteDefaultGroups.HEAT_PUMPS]}
      showImage={true}/>)}
    {groupedQuote[CalculatedQuoteDefaultGroups.HOT_WATER_CYLINDERS]?.map(x => <PrimaryQuoteItemRow key={x.uuid} item={x}
      subtotal={quoteSnapshot.group_totals[CalculatedQuoteDefaultGroups.HOT_WATER_CYLINDERS]}/>)}
    {groupedQuote[CalculatedQuoteDefaultGroups.PARTS] &&
        <QuoteItemGroupRow groupName="Parts & fittings" items={groupedQuote[CalculatedQuoteDefaultGroups.PARTS]}
          subtotal={quoteSnapshot.group_totals[CalculatedQuoteDefaultGroups.PARTS]}/>}
    {groupedQuote[CalculatedQuoteDefaultGroups.RADIATORS] &&
        <QuoteItemGroupRow groupName="New radiators" items={groupedQuote[CalculatedQuoteDefaultGroups.RADIATORS]}
          subtotal={quoteSnapshot.group_totals[CalculatedQuoteDefaultGroups.RADIATORS]}/>}
    {groupedQuote[CalculatedQuoteDefaultGroups.UNDERFLOOR] &&
        <QuoteItemGroupRow groupName="Underfloor heating" items={groupedQuote[CalculatedQuoteDefaultGroups.UNDERFLOOR]}
          subtotal={quoteSnapshot.group_totals[CalculatedQuoteDefaultGroups.UNDERFLOOR]}
          postfix='m²'/>}
    {groupedQuote[CalculatedQuoteDefaultGroups.LABOUR] &&
        <QuoteItemGroupRow groupName="Labour" items={groupedQuote[CalculatedQuoteDefaultGroups.LABOUR]}
          subtotal={quoteSnapshot.group_totals[CalculatedQuoteDefaultGroups.LABOUR]} postfix='days'/>}
    {groupedQuote[CalculatedQuoteDefaultGroups.SURVEY]?.map(x => <PrimaryQuoteItemRow key={x.uuid} item={x}
      subtotal={quoteSnapshot.group_totals[CalculatedQuoteDefaultGroups.SURVEY]}/>)}
    {/* Totals */}
    <div className='py-5 flex flex-row justify-end'>
      <div className='grid grid-cols-[auto_1fr] gap-2'>
        <Text size="XS" bold>Total before grant (excl. VAT)</Text>
        <div className="ml-2 text-right">
          <Text size="XS">{formatPrice(quoteSnapshot.total_pre_deductions, 2)}</Text>
        </div>
        {quoteSnapshot.discount_total < 0 && <>
          <Text size="XS" bold>Discount</Text>
          <div className="ml-2 text-right">
            <Text size="XS">{formatPrice(quoteSnapshot.discount_total, 2)}</Text>
          </div>
        </>}
        {quoteSnapshot.vat_on_all > 0
          ? <>
              <Text size="XS" bold>VAT (20%)</Text>
              <div className="ml-2 text-right">
                <Text size="XS">{formatPrice(quoteSnapshot.vat_on_all, 2)}</Text>
              </div>
            </>
          : quoteSnapshot.vat_on_survey > 0 && <>
            <Text size="XS" bold>VAT (20%) - Survey</Text>
            <div className="ml-2 text-right">
              <Text size="XS">{formatPrice(quoteSnapshot.vat_on_survey, 2)}</Text>
            </div>
            <Text size="XS" bold>VAT (0%) - Installation</Text>
            <div className="ml-2 text-right">
              <Text size="XS">£0.00</Text>
            </div>
          </>
        }
        {(quoteSnapshot.vat_on_survey === 0 && quoteSnapshot.vat_on_all === 0) && <>
          <Text size="XS" bold>VAT (0%)</Text>
          <div className="ml-2 text-right">
            <Text size="XS">£0.00</Text>
          </div>
        </>}
        {quoteSnapshot.bus_grant < 0 && <>
          <Text size="XS" bold>Boiler Upgrade Scheme (BUS) Grant</Text>
          <div className="ml-2 text-right">
            <Text size="XS">{formatPrice(quoteSnapshot.bus_grant, 2)}</Text>
          </div>
        </>}
        <Text size="SM" bold>Total</Text>
        <div className="ml-2 text-right">
          <Text size="SM"
            bold>{formatPrice(total, 2)}</Text>
        </div>
      </div>
    </div>
    {/* Optional items */}
    {groupedQuote['Optional items'] && (
      <div className='py-5 flex flex-col gap-3'>
        <Text size="SM" bold>Optional items</Text>
        <div className='flex flex-col gap-2'>
          {groupedQuote['Optional items'].map(x => (
            <div className="ml-6" key={x.uuid}>
              <div className='flex flex-row items-center justify-between'>
                <div className='flex flex-col gap-1'>
                  <Text size="XS" bold>{quoteItemNameWithQuantity(x as QuoteItem)}</Text>
                  {x.description && <Text size="XS" className="text-gray-500">{x.description}</Text>}
                </div>
                {x.subtotal && (
                  <div className='text-right ml-auto flex gap-2'>
                    <Badge color='INDIGO' text='Optional'/>
                    <Text size="SM">{formatPrice(x.subtotal, 2)}</Text>
                  </div>
                )}
              </div>
            </div>
          ))}
        </div>
      </div>
    )}
    {/* Additional notes */}
    {quoteSnapshot.additional_notes && (
      <div className='py-5 space-y-2' dangerouslySetInnerHTML={{ __html: renderHTMLReplacingPlaceholders(JSON.parse(quoteSnapshot.additional_notes), {}, 'text-xs', 'text-sm text-bold font-bold') }}></div>
    )}
  </div>
}

export const PublicQuotePaymentScheduleBlock = ({ quoteSnapshot }: PublicQuoteBlockProps) => {
  const total = quoteSnapshot.total_pre_deductions + quoteSnapshot.vat_on_all + quoteSnapshot.vat_on_survey + quoteSnapshot.discount_total + quoteSnapshot.bus_grant

  return <>
    {(quoteSnapshot.payment_schedule && quoteSnapshot.payment_schedule.length > 0) && (
      <>
        <PrintLayoutPageBreak/>
        <Heading size='3xl' className='py-10'>
          Payment schedule
        </Heading>
        <div className="flex flex-col divide-y divide-gray-200 border-y border-gray-200">
          {quoteSnapshot.payment_schedule.map((x, i) => {
            return (
              <div key={i} className="flex flex-row items-start py-5 gap-2">
                <div>
                  <Badge color="LIGHT" text={`${i + 1}/${quoteSnapshot.payment_schedule?.length}`} />
                </div>
                <div>
                  <Text size="SM" bold>{x.name}</Text>
                  {x.due_date && <Text size="XS" className="text-gray-500">{new Date(x.due_date).toLocaleDateString('en-GB', { year: 'numeric', month: 'short', day: 'numeric' })}</Text>}
                  {x.description && <div className='border-l-2 border-gray-300 pl-2 mt-2'>
                    <Text size="XS" className='text-gray-600'>{x.description}</Text>
                  </div>}
                </div>
                <div className="ml-auto text-right">
                  <Text size="SM" bold>{x.percentage}%</Text>
                  <Text size="XS" className='text-gray-500'>{formatPrice(x.percentage / 100 * total, 2)}</Text>
                </div>
              </div>
            )
          })}
        </div>
      </>
    )}
  </>
}
