import React from 'react'
import { FormLabel } from '../../../components/inputs_and_selections/form_label'
import { Info } from '../../../components/buttons/info'

export const FormLabelDemo = () => {
  return <div className="flex flex-col gap-8">
    <div className='flex flex-col gap-4'>
      <div className='text-2xl font-bold text-gray-900'>Definition</div>

      <pre className='bg-gray-100 rounded-lg p-4 overflow-x-auto'><code>
        {codeBlock}
      </code></pre>

      <div className='flex flex-col divide-y divide-dashed divide-gray-200 text-sm'>
        {componentProps.map(x => <div key={x.name} className='grid grid-cols-2 py-2'>
          <div className='font-bold text-gray-900'>{x.name}</div>
          <div>{x.description}</div>
        </div>)}
      </div>
    </div>

    <div className='flex flex-col gap-4'>
      <div className='text-2xl font-bold text-gray-900'>Component</div>
      <FormLabel
        labelText='Hello world'
        helperText='helper'
        required={true}
        complete={true}
        size='MD'
        info={<Info infoModalHeader="What is this?" infoModalBody="Some info"/>}
      />
    </div>

  </div>
}

const componentProps = [
  { name: 'labelText', description: 'Primary text for label' },
  { name: 'helperText', description: 'Secondary helper text, lighter colour' },
  { name: 'required', description: 'Is the input for this label required, if so show a required badge' },
  { name: 'complete', description: 'If required badge is true, is the step complete?' },
  { name: 'info', description: 'Info component to give more info on the title' },
  { name: 'size', description: 'SM | MD | LG | XL' }
]

const codeBlock = `<FormLabel
  labelText='Hello world'
  helperText='helper'
  required={true}
  complete={true}
  size='MD'
  info={<Info infoModalHeader="What is this?" infoModalBody="Some info"/>}
/>`
