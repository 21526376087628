import React, { useContext } from 'react'
import { AdminContext } from '../../admin/admin_layout'

export const OfflineBlock = () => {
  const adminContext = useContext(AdminContext)

  return <>
    { adminContext.isOffline &&
      <div className="w-full px-5 py-2 bg-yellow-200 justify-start items-start flex">
        <div className="grow shrink basis-0 flex-col justify-start items-start inline-flex">
          <div className="self-stretch text-yellow-900 text-xs font-bold">Unable to connect
                  to internet
          </div>
          <div
            className="self-stretch text-yellow-900 text-xs font-medium tracking-tight">Changes
                  are saved locally and will sync when reconnected.
          </div>
        </div>
      </div>
    }
  </>
}
