import React, { useEffect, useState } from 'react'
import { FormLabel } from '../../../../components/inputs_and_selections/form_label'
import { Button } from '../../../../components/buttons/button'
import { AttachmentCard } from '../../../../code/proposal_attachments/attachment_card'
import { type Proposal } from '../../../../code/models/proposal'
import { Section } from '../components/section'
import { deleteProposalLink, insertProposalLink, type ProposalLink } from '../../../../code/models/proposal_link'
import { type CompanyPublicInfo } from '../../../../code/models/company'
import { Plus } from 'lucide-react'

type Props = {
  proposal: Proposal
  setProposal: (proposal: Proposal) => void
  companyPublicInfo: CompanyPublicInfo
}

export const ProposalFiles = ({ proposal, setProposal, companyPublicInfo }: Props) => {
  // Required attachments
  const [requiredAttachments, setRequiredAttachments] = useState<Array<{ key: string, attachment?: ProposalLink }>>([
    { key: 'terms_and_conditions', attachment: proposal.links?.find(link => link.category === 'terms_and_conditions') },
    { key: 'heat_pump_spec', attachment: proposal.links?.find(link => link.category === 'heat_pump_spec') },
    { key: 'hot_water_cylinder_spec', attachment: proposal.links?.find(link => link.category === 'hot_water_cylinder_spec') }
  ])
  // Allow installer to upload additional documents
  const [customAttachments, setCustomAttachments] = useState<Array<ProposalLink | undefined>>(proposal.links?.filter(link => link.category === 'custom') ?? [])

  // Map category keys to their titles
  const categoryTitles: Record<string, string> = {
    terms_and_conditions: 'Terms & Conditions',
    heat_pump_spec: 'Heat pump data sheet',
    hot_water_cylinder_spec: 'Hot water cylinder data sheet'
  }

  const createAttachment = (uuid: string, title: string, url: string, internal: boolean, category: ProposalLink['category']): ProposalLink => {
    // We get the URL from the external link, or from the newly uploaded internal file downstream.
    return { uuid, proposal_uuid: proposal.uuid, title, url, internal, category }
  }

  // Helper function for adding / removing required attachments. Doing it inline was too messy.
  const handleRequiredAttachmentChange = (key: string, title: string, url: string, internal: boolean, isAdding: boolean) => {
    // Generate 1 uuid for both state and DB updates
    const uuid = crypto.randomUUID()

    setRequiredAttachments(prev => prev.map(e =>
      e.key === key ? { key: e.key, attachment: isAdding ? createAttachment(uuid, title, url, internal, key as ProposalLink['category']) : undefined } : e
    ))
    if (isAdding) {
      insertProposalLink(
        {
          uuid,
          proposal_uuid: proposal.uuid,
          title,
          url,
          internal,
          category: key as ProposalLink['category']
        },
        proposal.uuid,
        proposal.lead_uuid,
        companyPublicInfo.uuid
      )
    } else {
      const uuidToDelete = proposal.links?.find(link => link.category === key)?.uuid
      if (uuidToDelete) {
        deleteProposalLink(uuidToDelete, proposal.uuid, proposal.lead_uuid, companyPublicInfo.uuid)
      }
    }
  }

  const handleCustomAttachmentChange = (index: number, title: string, url: string, internal: boolean, isAdding: boolean) => {
    // Generate 1 uuid for both state and DB updates
    const uuid = crypto.randomUUID()

    setCustomAttachments(prev => prev.map((e, i) =>
      i === index ? (isAdding ? createAttachment(uuid, title, url, internal, 'custom') : undefined) : e
    ))
    if (isAdding) {
      insertProposalLink(
        {
          uuid,
          proposal_uuid: proposal.uuid,
          title,
          url,
          internal,
          category: 'custom'
        },
        proposal.uuid,
        proposal.lead_uuid,
        companyPublicInfo.uuid
      )
    } else {
      const uuidToDelete = customAttachments[index]?.uuid
      if (uuidToDelete) {
        deleteProposalLink(uuidToDelete, proposal.uuid, proposal.lead_uuid, companyPublicInfo.uuid)
      }
    }
  }

  // Update proposal when attachments change
  useEffect(() => {
    const links = [
      ...requiredAttachments.map(e => e.attachment).filter((e): e is ProposalLink => e !== undefined),
      ...customAttachments.filter((e): e is ProposalLink => e !== undefined)
    ]
    setProposal({ ...proposal, links })
  }, [requiredAttachments, customAttachments])

  return (
    <Section>
      <FormLabel size='LG' labelText='Documents' helperText='To ensure MCS compliance, we recommend you include the following documents.' />

      <div className='flex flex-col gap-4'>
        {/* Required Attachments */}
        {requiredAttachments.map(({ key }) => (
          <AttachmentCard
            key={key}
            title={categoryTitles[key]}
            attachment={requiredAttachments.find(e => e.key === key)?.attachment}
            forceTitle={categoryTitles[key]}
            onAddAttachment={(title, url, internal) => handleRequiredAttachmentChange(key, categoryTitles[key], url, internal, true)}
            onRemoveAttachment={() => handleRequiredAttachmentChange(key, '', '', false, false)}
          />
        ))}

        <FormLabel size='LG' labelText='Extra documents' helperText='You can also include any other supporting documentation.' />

        {/* Custom Attachments */}
        {customAttachments.map((customAttachment, index) => (
          <AttachmentCard
            showLabel={false}
            key={index}
            attachment={customAttachment}
            onAddAttachment={(title, url, internal) => handleCustomAttachmentChange(index, title, url, internal, true)}
            onRemoveAttachment={() => handleCustomAttachmentChange(index, '', '', false, false)}
          />
        ))}
      </div>

      <Button
        iconLeft={Plus}
        colour='LIGHT'
        className='w-fit'
        disabled={customAttachments.length > 0 && customAttachments.some(attachment => attachment === undefined)}
        onClick={() => setCustomAttachments(prev => [...prev, undefined])}
      >
        Add document
      </Button>
    </Section>
  )
}
