// Unique data from propertyType: Bungalow, Flat, House, Maisonette, Park home

import {
  InsulationTypeCavityInsulatedIcon,
  InsulationTypeCavityUninsluatedIcon,
  InsulationTypeInnerIcon,
  InsulationTypeOuterIcon,
  InsulationTypeUnsureIcon,
  QuestionMarkIcon,
  WallTypeCavityIcon,
  WallTypeOtherIcon,
  WallTypeSolidBrickIcon,
  WallTypeSolidConcreteIcon,
  WallTypeSolidStoneIcon
} from '../../assets/images/domain_icons/domain_icons'
import { type EPC } from './epc'
import { type EpcScotland } from './epc_scotland'
import { type PropertyType } from './property_type'
import { type BuiltForm } from './built_form'

export type UValue = {
  uuid: string
  name: string
  description?: string
  u_value: number
  group?: string
  synonyms?: string[]
  icon?: any // TODO: figure out proper type - was string but didn't work with the property type typing. Not sure why they should be linked though?
}

type WallGroupType = 'SOLID' | 'CAVITY' | 'TIMBER'
type WallGroup = {
  icon: any
  name: string
  u_value?: number
  type: WallGroupType
}

export const TIMBER: string = 'Timber'
export const CAVITY_WALL: string = 'Cavity Wall'
export const WALL_GROUPS: WallGroup[] = [
  { icon: WallTypeCavityIcon, name: CAVITY_WALL, type: 'CAVITY' },
  { icon: WallTypeSolidBrickIcon, name: 'Solid Brick', u_value: 2.24, type: 'SOLID' },
  { icon: WallTypeSolidStoneIcon, name: 'Solid Stone', u_value: 2.23, type: 'SOLID' },
  { icon: WallTypeSolidConcreteIcon, name: 'Solid Concrete', u_value: 2.99, type: 'SOLID' },
  { icon: WallTypeOtherIcon, name: TIMBER, u_value: 0.36, type: 'TIMBER' }
]

export const CAVITY_WALL_INSULATION = [
  { uuid: 'Insulated', name: 'Insulated', u_value: 0.45, synonyms: ['filled'], icon: InsulationTypeCavityInsulatedIcon },
  { uuid: 'Uninsulated', name: 'Uninsulated', u_value: 0.87, icon: InsulationTypeCavityUninsluatedIcon }
]

export const SOLID_WALL_INSULATION: UValue[] = [
  { uuid: 'internal', name: 'Internal', description: 'Insulation on the interior surfaces of external walls.', group: '', u_value: 1 / 2.75, icon: InsulationTypeInnerIcon },
  { uuid: 'external', name: 'External', description: 'A protective insulation layer on the outer surfaces of external walls.', u_value: 1 / 2.75, icon: InsulationTypeOuterIcon },
  { uuid: 'none', name: 'None', u_value: 0, icon: InsulationTypeUnsureIcon },
  { uuid: 'idk', name: 'I don\'t know', u_value: 0, icon: QuestionMarkIcon }
]

export const OUTDOOR_SPACE = [
  { label: 'On the ground floor', sublabel: 'E.g. garden or alongside the house', key: 'On the ground floor' },
  { label: 'On an upper floor', sublabel: 'E.g. balcony or terrace', key: 'On an upper floor' },
  { label: 'No', key: 'None' }
]

export const FLOOR_INSULATION: UValue[] = [
  { uuid: 'None', name: 'None', u_value: 1.82 },
  { uuid: '100mm', name: '100mm', u_value: 0.57 }
]

// MCS U values calculator
// Pitched roof - Slates or tiles, sarking felt, ventilated air space, 9.5 mm plasterboard
// + x mm insulation between joists
// For interpolation calculation see shared version of MCS Heat Pump Calculator
export const LOFT_INSULATION: UValue[] = [
  { uuid: 'None', name: 'None', u_value: 2.51 }, // MCS
  { uuid: '50mm', name: '50mm', u_value: 0.6 }, // MCS
  { uuid: '100mm', name: '100mm', u_value: 0.34 }, // MCS
  { uuid: '150mm', name: '150mm', u_value: 0.23 }, // Interpolated from MCS
  { uuid: '200mm', name: '200mm', u_value: 0.18 }, // MCS
  { uuid: '250+mm', name: '250+mm', u_value: 0.15 } // Interpolated from MCS
]

export const WINDOW_TYPES: UValue[] = [
  { uuid: 'Single', name: 'Single Glazed', u_value: 4.8 },
  { uuid: 'Double', name: 'Double Glazed', u_value: 2.8 },
  { uuid: 'Triple', name: 'Triple Glazed', u_value: 2.1 }
]

export const DOOR_UVALUES: UValue[] = [
  { uuid: 'plastic', name: 'Plastic', u_value: 0.5 },
  { uuid: 'wood', name: 'Wood', u_value: 0.5 },
  { uuid: 'metal', name: 'Metal', u_value: 0.5 }
]

export const getConstructionAge = (epc: Partial<EPC>) => {
  if (epc.constructionAgeBand && /\d/.test(epc.constructionAgeBand)) return epc.constructionAgeBand
  if (epc.transactionType?.toLowerCase() === 'new dwelling') return epc.inspectionDate && new Date(epc.inspectionDate).getFullYear().toString()
  return ''
}

export const getConstructionAgeScotland = (epc: EpcScotland) => {
  if (/\d/.test(epc.construction_age_band)) return epc.construction_age_band
  if (epc.transaction_type?.toLowerCase() === 'new dwelling') return new Date(epc.inspection_date).getFullYear().toString()
  return ''
}

export const getPropertyType = (epc: EPC | EpcScotland): PropertyType | '' => {
  const propertyType = (epc as EPC).propertyType || (epc as EpcScotland).property_type

  if (propertyType.toLowerCase() === 'house') return 'House'
  if (propertyType.toLowerCase() === 'bungalow') return 'Bungalow'
  if (propertyType.toLowerCase() === 'flat') return 'Flat'
  if (propertyType.toLowerCase() === 'maisonette') return 'Flat'
  return ''
}

export const getBuiltForm = (epc: EPC | EpcScotland): BuiltForm => {
  const builtForm = (epc as EPC).builtForm || (epc as EpcScotland).built_form

  if (builtForm.toLowerCase() === 'semi-detached') return 'Semi-Detached'
  if (builtForm.toLowerCase().includes('end-')) return 'End-Terrace'
  if (builtForm.toLowerCase().includes('mid-')) return 'Mid-Terrace'
  if (builtForm.toLowerCase() === 'detached') return 'Detached'
  return 'Detached' // assume detached if we don't know. Typically looks like this field is empty in newer builds. so assume detached
}

export const getWallGroup = (value: string) => {
  // TODO later: improve mapping
  if (!value) return ''
  const valueLower = value.toLowerCase()

  if (valueLower.includes('solid') && valueLower.includes('brick')) return 'Solid Brick'
  if (valueLower.includes('solid') && valueLower.includes('stone')) return 'Solid Stone'
  if (valueLower.includes('solid') && valueLower.includes('concrete')) return 'Solid Concrete'
  if (valueLower.includes('cavity')) return CAVITY_WALL
  if (valueLower.includes('timber')) return TIMBER
}

export const getWallType = (value: string, wallGroup: string) => {
  // TODO later: improve mapping - include ewi/iwi
  const lowerValue = value.toLowerCase()
  return (wallGroup === CAVITY_WALL ? CAVITY_WALL_INSULATION : SOLID_WALL_INSULATION).find(x => (lowerValue.includes(x.uuid.toLowerCase()) || x.synonyms?.some(y => lowerValue.includes(y))))?.uuid
}

export const getFloorInsulation = (value: string) => {
  // TODO later: map floor construction and improve mapping
  if (value.includes('100')) return '100mm'
  return 'None'
}

const extractNumberBeforeMM = (input: string) => {
  const regex = /(\d+)\s*\+?mm/i // find a number followed by mm or +mm (not case sensitive)
  const match = input.match(regex)

  if (input === 'None') return 0 // deal with None in LOFT_INSULATION

  if (match?.[1]) {
    return parseInt(match[1], 10)
  }

  return null
}

export const getLoftInsulation = (value: string) => {
  // TODO later: improve mapping and add room in roof
  if (value.includes('no insulation') || value.includes('dim inswleiddio')) { return 'None' }

  const thickness = extractNumberBeforeMM(value)
  // if a number followed by mm is not in the EPC roof description, set insulation thickness to 100 mm
  // TODO: at some point could update this to use RdSAP default for age band
  if (thickness === null) { return '100mm' }

  // Find the closest option within our tabulated U values. Will take lower thickness if right in the middle
  const optionNameThicknessPairs = LOFT_INSULATION.map(x => ({ uuid: x.uuid, thickness: extractNumberBeforeMM(x.name) ?? 0 }))
  const optionThicknesses = optionNameThicknessPairs.map(x => x.thickness)
  const closest = optionThicknesses.reduce((prev, curr) => {
    return (Math.abs(curr - thickness) < Math.abs(prev - thickness) ? curr : prev)
  })
  return optionNameThicknessPairs.find(x => x.thickness === closest)?.uuid
}

// We expect this to fail sometimes empty catch is okay.
// Only used when the U value itself is passed (i.e., for new build)
export const getUValue = (value: string) => {
  try {
    if (!(value.includes('Average thermal') || value.includes('Trawsyriannedd thermol'))) return

    const result = value.match(/\d+\.?\d*/)
    return result?.[0] ? parseFloat(result[0]) : undefined
  } catch (e) { }
}

// Many potential options from EPC, ordered this way so in combinations of "some triple, some double" we are optimistic and go for triple.
export const getWindowType = (epc: EPC) => {
  if (epc.windowsDescription.toLowerCase().includes('triple')) return 'Triple'
  if (epc.windowsDescription.toLowerCase().includes('double')) return 'Double'
  if (epc.windowsDescription.toLowerCase().includes('single')) return 'Single'

  return 'Double'
}

export const getWindowTypeScotland = (epc: EpcScotland) => {
  if (epc.windows_description.toLowerCase().includes('triple')) return 'Triple'
  if (epc.windows_description.toLowerCase().includes('double')) return 'Double'
  if (epc.windows_description.toLowerCase().includes('single')) return 'Single'

  return 'Double'
}

export const PREV_QUOTE = [
  'Yes',
  'No'
]

export const WHY_HEAT_PUMP = [
  'Save money',
  'Go Green',
  'Better Comfort'
]

export const WHEN_HEAT_PUMP = [
  'ASAP',
  'Within 6 Months',
  'Next 1-2 Years',
  'Unsure'
]
