import React, { useEffect, type Dispatch, type SetStateAction } from 'react'
import { type UndoEvent } from './floor_canvas/undo'
import { type Floor } from '../../../code/models/floor'
import { type SprucePoint } from './code/types'
import { calculateLineLength } from './code/utils'
import { Button } from '../../../components/buttons/button'
import { BottomSheetHeader } from '../../../components/containers/bottom_sheet_header'
import { Input } from '../../../components/inputs_and_selections/input'
import { ChevronRight } from 'lucide-react'

type ScalePageProps = {
  scalingPoints: SprucePoint[]
  tempImageAndScale: { image: string, scale: number }
  scaleLength: number | undefined
  setScaleLength: Dispatch<SetStateAction<number | undefined>>
  addEvent: (events: UndoEvent[]) => void
  floor: Floor
  resetUploadPlan: () => void
  setHeader: Dispatch<SetStateAction<JSX.Element>>
  setStageStep: Dispatch<SetStateAction<number>>
  setScalingPoints: Dispatch<SetStateAction<SprucePoint[]>>
  setIsPageOpen: Dispatch<SetStateAction<boolean>>
}
export const ScalePage = ({
  scalingPoints,
  tempImageAndScale,
  scaleLength,
  setScaleLength,
  addEvent,
  floor,
  resetUploadPlan,
  setHeader,
  setStageStep,
  setScalingPoints,
  setIsPageOpen
}: ScalePageProps) => {
  useEffect(() => {
    setHeader(<BottomSheetHeader onBack={() => {
      setStageStep(prev => prev - 1)
      setScalingPoints(prev => prev.slice(0, -1))
    }} title='Enter line length' />)
  }, [])

  return <div className='flex flex-col gap-3 p-5'>
    {scalingPoints.length === 2 && <div className='flex flex-col gap-2'>
      <div className='flex flex-col gap-1'>
        <div className='font-bold text-gray-900'>Object length</div>
        <div className="text-gray-500 text-sm">Enter the length of the object from the floor plan.</div>
      </div>

      <div className='flex flex-col gap-5'>
        <Input type='number' className='flex-grow' postfix='metres' value={scaleLength?.toString() ?? ''} setValue={(e) => setScaleLength(parseFloat(e))} />
        <Button disabled={!tempImageAndScale?.scale} onClick={() => {
          const newScale = scaleLength ? scaleLength / (calculateLineLength(scalingPoints[0].x, scalingPoints[0].y, scalingPoints[1].x, scalingPoints[1].y)) : 0
          addEvent([{ type: 'FLOOR', action: 'UPDATE', oldValue: floor, newValue: { ...floor, floor_plan_image: tempImageAndScale.image, floor_plan_scale: tempImageAndScale.scale * newScale } }])
          resetUploadPlan()
        }} iconRight={ChevronRight}>Apply</Button>
      </div>
    </div>}
  </div>
}
