import React from 'react'
import { SurveyCylinderLocationContext } from '../cylinder_location'
import { Check } from 'lucide-react'
import { Badge } from '../../../../components/indicators_and_messaging/badge'
import { Input } from '../../../../components/inputs_and_selections/input'
import { TextArea } from '../../../../components/inputs_and_selections/text_area'
import { validateInMm } from '../../../../code/validators'

export const CylinderLocationProposedLocationBlock = () => {
  const cylinderLocationContext = React.useContext(SurveyCylinderLocationContext)
  const { survey, setSurvey } = cylinderLocationContext!

  return <>
    <div className="text-gray-900 text-xl font-bold">Proposed location</div>
    <div className="flex-col gap-2 flex">
      <div className="flex-col flex">
        <div className="text-gray-900 font-bold">Available space</div>
        <div className="text-gray-500 text-sm">Space available for the cylinder plus all the tappings.</div>
      </div>
      <div className="gap-4 flex">
        <div className="flex-col gap-2 flex">
          <div className="text-gray-600">Width</div>
          <Input type='number' value={survey.cylinder_available_width_mm.toString()}
            setValue={(e) => setSurvey({ ...survey, cylinder_available_width_mm: parseFloat(e) })}
            validator={validateInMm}
            postfix='mm'/>
        </div>
        <div className="flex-col gap-2 flex">
          <div className="text-gray-600">Height</div>
          <Input type='number' value={survey.cylinder_available_height_mm.toString()}
            setValue={(e) => setSurvey({ ...survey, cylinder_available_height_mm: parseFloat(e) })}
            validator={validateInMm}
            postfix='mm'/>
        </div>
        <div className="flex-col gap-2 flex">
          <div className="text-gray-600">Depth</div>
          <Input type='number' value={survey.cylinder_available_depth_mm.toString()}
            setValue={(e) => setSurvey({ ...survey, cylinder_available_depth_mm: parseFloat(e) })}
            validator={validateInMm}
            postfix='mm'/>
        </div>
      </div>
    </div>
    <div className="flex-col gap-2 flex">
      <div className="flex-col flex">
        <div className='flex justify-between'>
          <div className="text-gray-900 font-bold">Location description</div>
          <Badge color={survey.cylinder_location_description?.length > 0 ? 'GREEN' : 'YELLOW'} text='Required'
            icon={survey.cylinder_location_description?.length > 0 ? Check : undefined}/>
        </div>

        <div className="text-gray-500 text-sm">Proposed location of the cylinder. Include the loft hatch dimensions if
          the cylinder is going in the loft.
        </div>
      </div>
      <TextArea value={survey.cylinder_location_description}
        setValue={(e) => setSurvey({ ...survey, cylinder_location_description: e })}/>
    </div>
  </>
}
