import React, { useState, type Dispatch, type SetStateAction, useEffect } from 'react'
import { PageHeader } from '../design/components/design_page_header'
import { pipeMaterials, type PropertySurvey } from '../../../code/models/property'
import { CompleteButton } from './complete_button'
import { PIPE_MODELS, getPipeModelName } from '../../../code/models/pipes'
import { Toggle } from '../../../components/inputs_and_selections/toggle'
import { getEmitterTypeName, getEmitterSizeName, type Emitter, type SecondaryEmitter } from '../../../code/models/radiator'
import { tryParseFloat } from '../../../code/helpers'
import { type Room } from '../../../code/models/room'
import { Icon } from '../../../components/buttons/icon'
import { Input } from '../../../components/inputs_and_selections/input'
import { RadioGroup } from '../../../components/inputs_and_selections/radio'
import { FormLabel } from '../../../components/inputs_and_selections/form_label'
import { VerticalFormGroup } from '../../../components/inputs_and_selections/vertical_form_group'
import { Info } from '../../../components/buttons/info'
import { Select } from '../../../components/inputs_and_selections/select'
import { type SetIndexedDb } from '../../admin/job_layout/job_layout'
import { XCircle, ChevronRight } from 'lucide-react'

type PipeworkProps = {
  survey: PropertySurvey
  setSurvey: SetIndexedDb<PropertySurvey>
  isOffline: boolean
  allSynced: boolean
}

// Helper types to store the room on the emitter. Hydronic because not worried about piping if the emitters don't carry water
type HydronicEmitterWithRoom = Exclude<Emitter, SecondaryEmitter> & { room: Room }
type HydronicSurveyEmitterWithRoom = Exclude<Emitter, SecondaryEmitter> & { room: Room }

export const Pipework = ({ survey, setSurvey, isOffline, allSynced }: PipeworkProps) => {
  const page = 'PIPEWORK'
  const [currentPage, setCurrentPage] = useState<'MAIN' | 'SECONDARY_EMITTERS'>('MAIN')

  // Add the room onto the Emitter object to make it easier for the user to choose the index rad
  const surveyedEmittersWithRooms = survey.floors
    .flatMap(x => x.rooms
      .flatMap(y => y.radiators
        .filter(x => x.emitter_type !== 'SECONDARY')
        .map(z => ({ ...z, room: y }))
      )
    ) as HydronicSurveyEmitterWithRoom[]

  const possibleIndexEmittersWithRooms = survey.secondary_index_emitter_uuids.length > 0
    ? surveyedEmittersWithRooms.filter(x => survey.secondary_index_emitter_uuids.includes(x.uuid!))
    : surveyedEmittersWithRooms

  const indexEmitterWithRoom = surveyedEmittersWithRooms.find(x => x.uuid === survey.index_emitter_uuid)
  const emittersOnCircuitLength = survey.secondary_index_emitter_uuids.length

  // Define setters needed in the pipework inputs
  const setPrimaryPipeModel = async (pipeModelUUID: string) => await setSurvey(prev => ({ ...prev, existing_system_pipework_uuid: pipeModelUUID }))
  const setPrimaryPipeLength = async (pipeLengthM: number | undefined) => await setSurvey(prev => ({ ...prev, primary_pipework_length_m: pipeLengthM }))
  const setSecondaryPipeModel = async (pipeModelUUID: string) => await setSurvey(prev => ({ ...prev, secondary_index_pipework_uuid: pipeModelUUID }))
  const setSecondaryPipeLength = async (pipeLengthM: number | undefined) => await setSurvey(prev => ({ ...prev, secondary_index_pipework_length_m: pipeLengthM }))
  const setIndexEmitterUuid = async (indexEmitterUuid: string) => await setSurvey(prev => ({ ...prev, index_emitter_uuid: indexEmitterUuid }))
  const setIndexPipeModel = (pipeModelUUID: string) => {
    const { room, ...indexEmitter } = indexEmitterWithRoom!
    const updatedEmitter: Emitter = { ...indexEmitter, pipe_model_uuid: pipeModelUUID }

    const radiatorRoom = survey.floors.flatMap(x => x.rooms).find(x => x.radiators.some(y => y.uuid === updatedEmitter.uuid))!
    const radiatorFloor = survey.floors.find(x => x.rooms.some(y => y.uuid === radiatorRoom?.uuid))!

    const newRoom = { ...radiatorRoom, radiators: radiatorRoom?.radiators.map(x => x.uuid === updatedEmitter.uuid ? updatedEmitter : x) }
    const newFloors = survey.floors.map(x => x.uuid === radiatorFloor?.uuid ? { ...radiatorFloor, rooms: radiatorFloor.rooms.map(y => y.uuid === newRoom.uuid ? newRoom : y) } : x)
    setSurvey(prev => ({ ...prev, floors: newFloors }))
  }
  const setIndexPipeLength = async (pipeLengthM: number | undefined) => await setSurvey(prev => ({ ...prev, index_emitter_pipe_length_m: pipeLengthM }))

  // Split out pipe material so can update based on pipe model
  const [primaryPipeMaterial, setPrimaryPipeMaterial] = useState<string>()
  const [secondaryPipeMaterial, setSecondaryPipeMaterial] = useState<string | undefined>('Copper')
  const [indexEmitterPipeMaterial, setIndexEmitterPipeMaterial] = useState<string>()
  useEffect(() => {
    // Needed to update the pipe material based on the pipe model without having an infinite loop
    const pipeModel = PIPE_MODELS.find(x => x.uuid === survey.existing_system_pipework_uuid)
    const secondaryPipeModel = PIPE_MODELS.find(x => x.uuid === survey.secondary_index_pipework_uuid)
    const indexEmitterPipeModel = PIPE_MODELS.find(x => x.uuid === indexEmitterWithRoom?.pipe_model_uuid)

    setPrimaryPipeMaterial(pipeModel?.material ?? 'Copper')
    setSecondaryPipeMaterial(secondaryPipeModel?.material ?? 'Copper')
    setIndexEmitterPipeMaterial(indexEmitterPipeModel?.material ?? 'Copper')
  }, [survey])

  if (currentPage === 'SECONDARY_EMITTERS') {
    return <SecondaryEmittersSelectPage
      isOffline={isOffline}
      allSynced={allSynced}
      completed={survey.completed_sections.includes(page)}
      onBack={() => setCurrentPage('MAIN')}
      emitterUUIDs={survey.secondary_index_emitter_uuids}
      toggleEmitter={async (rad) => await setSurvey(prev => ({
        ...prev,
        secondary_index_emitter_uuids: prev.secondary_index_emitter_uuids.some(y => y === rad.uuid)
          ? prev.secondary_index_emitter_uuids.filter(y => y !== rad.uuid) // if it was included, remove it
          : [...prev.secondary_index_emitter_uuids, rad.uuid!] // if it wasn't included, add it
      }))}
      allEmitters={surveyedEmittersWithRooms}
    />
  }

  return <div className='flex flex-col h-full min-h-0'>
    <PageHeader isOffline={isOffline} allSynced={allSynced} title='Pipework' onBack={() => window.history.back()} completed={survey.completed_sections.includes(page)} />
    <div className='overflow-y-auto flex-grow'>
      <div className="p-5 flex-col gap-6 flex">
        <div className="items-center gap-1 flex">
          <div className={'text-sm\'}'}>Enter the details of the index circuit for this home so we can calculate the pressure drop</div>
          <IndexCircuitInfo />
        </div>

        {/* Primary */}
        <PrimaryPipeFormLabel />
        <PipeworkInputs
          pipeMaterial={primaryPipeMaterial}
          setPipeMaterial={setPrimaryPipeMaterial}
          pipeModelUUID={survey.existing_system_pipework_uuid}
          setPipeModel={setPrimaryPipeModel}
          pipeworkLengthM={survey.primary_pipework_length_m}
          setPipeworkLength={setPrimaryPipeLength}
          pipeLengthHelperText={PRIMARY_PIPE_LENGTH_HELPER_TEXT}
        />
        <div className="h-0 border border-gray-200"></div>

        {/* Secondary */}
        <SecondaryPipeFormLabel />
        <SecondaryEmittersCard
          setCurrentPage={setCurrentPage}
          emittersOnCircuitLength={emittersOnCircuitLength}
          numberOfEmitters={surveyedEmittersWithRooms.length}
        />
        <PipeworkInputs
          pipeMaterial={secondaryPipeMaterial}
          setPipeMaterial={setSecondaryPipeMaterial}
          pipeModelUUID={survey.secondary_index_pipework_uuid}
          setPipeModel={setSecondaryPipeModel}
          pipeworkLengthM={survey.secondary_index_pipework_length_m}
          setPipeworkLength={setSecondaryPipeLength}
          pipeLengthHelperText={SECONDARY_PIPE_LENGTH_HELPER_TEXT}
        />
        <div className="h-0 border border-gray-200"></div>

        {/* Index emitter */}
        <IndexEmitterFormLabel />
        <IndexEmitterSelect
          indexEmitterUuid={survey.index_emitter_uuid}
          setIndexEmitterUuid={setIndexEmitterUuid}
          possibleIndexEmittersWithRooms={possibleIndexEmittersWithRooms}
        />
        {/* Only let user set pipe properties when they have chosen the index emitter because we are changing the properties of that emitter's pipe when we change the below */}
        {survey.index_emitter_uuid && <PipeworkInputs
          pipeMaterial={indexEmitterPipeMaterial}
          setPipeMaterial={setIndexEmitterPipeMaterial}
          pipeModelUUID={indexEmitterWithRoom?.pipe_model_uuid}
          setPipeModel={setIndexPipeModel}
          pipeworkLengthM={survey.index_emitter_pipe_length_m}
          setPipeworkLength={setIndexPipeLength}
          pipeLengthHelperText={INDEX_PIPE_LENGTH_HELPER_TEXT}
          editPipeModel={indexEmitterWithRoom?.type !== 'UNDERFLOOR'}
        />}
      </div>
    </div>
    <CompleteButton onBack={async () => window.history.back()} page={page} survey={survey} setSurvey={setSurvey} pageChecks={[]}/>
  </div>
}

// Split out the editable fields here as also used on the design side
type PipeworkInputsProps = {
  pipeMaterial: string | undefined
  setPipeMaterial: Dispatch<SetStateAction<string>>
  pipeModelUUID: string | undefined
  setPipeModel: Dispatch<SetStateAction<string>>
  pipeLengthHelperText: string | undefined
  pipeworkLengthM: number | undefined
  setPipeworkLength: Dispatch<SetStateAction<number | undefined>>
  lengthOverridden?: boolean
  clearOverride?: () => void
  editPipeModel?: boolean // set to false for cases like UFH were the UFH itself sets the pipe model
}

export const PipeworkInputs = (
  { pipeMaterial, setPipeMaterial, pipeModelUUID, setPipeModel, pipeworkLengthM, setPipeworkLength, pipeLengthHelperText, editPipeModel = true, lengthOverridden = false, clearOverride = undefined }: PipeworkInputsProps) => {
  return <>
    <div className="flex-col gap-5 flex">
      {editPipeModel && <>
        <VerticalFormGroup
          formLabel={<FormLabel labelText={'Material'}/>}
          input={<RadioGroup items={pipeMaterials.map(x => ({
            name: x,
            onClick: () => setPipeMaterial(x),
            variant: pipeMaterial === x ? 'ACTIVE' : 'DEFAULT'
          }))}/>}
        />
        <VerticalFormGroup
          formLabel={<FormLabel labelText={'Diameter'}/>}
          input={ <Select
            options={PIPE_MODELS.filter(x => x.material === pipeMaterial).map(x => ({
              key: x.uuid,
              value: getPipeModelName(x)
            }))}
            selectedKey={pipeModelUUID}
            setSelectedKey={(e) => setPipeModel(e)}
          />}
        />
      </>}

      <VerticalFormGroup
        formLabel={<FormLabel
          labelText={'Approximate pipe length (flow only)'}
          helperText={pipeLengthHelperText}
        />}
        input={<Input
          type='number'
          value={pipeworkLengthM ?? ''}
          setValue={(e) => setPipeworkLength(tryParseFloat(e, undefined))}
          postfix={
            <div className="gap-2.5 flex items-center">
              <div className="">metres</div>
              {lengthOverridden && clearOverride && <Icon
                icon={XCircle}
                onClick={clearOverride} // clear override
                colour='text-gray-400' />}
            </div>
          }
        />
        }
      />
    </div>
  </>
}
//     TODO: for UFH we need to be calculating the pressure drop through the UFH!

export type SecondaryEmittersCardProps = {
  setCurrentPage: Dispatch<'MAIN' | 'SECONDARY_EMITTERS'>
  emittersOnCircuitLength: number
  numberOfEmitters: number
}

export const SecondaryEmittersCard = ({ setCurrentPage, emittersOnCircuitLength, numberOfEmitters }: SecondaryEmittersCardProps) => {
  return <div
    onClick={() => setCurrentPage('SECONDARY_EMITTERS')}
    className="items-center gap-4 flex justify-between cursor-pointer">
    <div className="flex-col flex">
      <FormLabel
        labelText={'Emitters on this secondary'}
        helperText={emittersOnCircuitLength === 0
          ? 'Please indicate which emitters are fed from this secondary.'
          : `${emittersOnCircuitLength}/${numberOfEmitters} emitters are served by this secondary`
        }
      />
    </div>
    <Icon icon={ChevronRight}/>
  </div>
}

type IndexEmitterSelectProps = {
  indexEmitterUuid: string | undefined
  setIndexEmitterUuid: Dispatch<SetStateAction<string | undefined>>
  possibleIndexEmittersWithRooms: HydronicEmitterWithRoom[]
}

export const IndexEmitterSelect = ({ indexEmitterUuid, setIndexEmitterUuid, possibleIndexEmittersWithRooms }: IndexEmitterSelectProps) => {
  return <div className="flex-col gap-2 flex">
    <FormLabel labelText={'Index Emitter'}/>
    <Select
      selectedKey={indexEmitterUuid}
      setSelectedKey={(e) => setIndexEmitterUuid(e)}
      options={possibleIndexEmittersWithRooms.map(x => ({
        key: x.uuid!,
        value: `${x.room.name} - ${getEmitterTypeName(x)} • ${getEmitterSizeName(x)}`
      }))}
    />
  </div>
}

type SecondaryEmittersSelectPageProps = {
  onBack: () => void
  completed?: boolean
  allEmitters: Array<Emitter & { room: Room }>
  emitterUUIDs: string[]
  toggleEmitter: (emitter: Emitter & { room: Room }) => void
  isOffline: boolean
  allSynced: boolean
}
export const SecondaryEmittersSelectPage = ({
  onBack,
  completed,
  allEmitters,
  emitterUUIDs,
  toggleEmitter,
  isOffline,
  allSynced
}: SecondaryEmittersSelectPageProps) => {
  return <div className='flex flex-col h-full min-h-0'>
    <PageHeader isOffline={isOffline} allSynced={allSynced} title='Pipework' onBack={onBack} completed={completed}/>
    <div className='overflow-y-auto flex-grow'>
      <div className="p-5 bg-white flex-col gap-6 flex">
        <div>Please select the emitters that are fed by this secondary circuit.</div>
        <div className='flex flex-col divide-y divide-gray-200'>
          {allEmitters.map(x => <div key={x.uuid}
            className='flex items-center justify-between gap-3 first:pt-0 last:pb-0 py-3'>
            <div className="flex-col flex">
              <div className="font-bold text-gray-900">{x.room.name}</div>
              <div className="text-gray-500 text-sm">{getEmitterTypeName(x)} • {getEmitterSizeName(x)}</div>
            </div>
            <Toggle value={emitterUUIDs.some(y => y === x.uuid)} setValue={() => toggleEmitter(x)}/>
          </div>)}
        </div>
      </div>
    </div>
  </div>
}

// Make the section headers + info compontents reusable between survey and design
export const PrimaryPipeFormLabel = () => {
  return <FormLabel
    labelText={'Primary'}
    helperText={'Enter the details of the primary pipework'}
    size={'XL'}
    info={<Info
      infoModalHeader={'What is the primary pipework?'}
      infoModalBody={'The primary carries the full output from the heat pump to the first split in the space heating circuit'}
    />}
  />
}

export const SecondaryPipeFormLabel = () => {
  return <FormLabel
    labelText={'Secondary'}
    size={'XL'}
    helperText={'Enter the details of the secondary pipe on the index circuit'}
    info={<Info
      infoModalHeader={'What is the secondary pipework?'}
      infoModalBody={'The secondaries are the pipes that split out from the primary to take heat to groups of emitters. ' +
                'They need to be able to carry all the heat required by the emitters they serve. ' +
                'The secondary we need for the index circuit calculations is the one that that ends up taking heat to the index emitter. ' +
                    'For example if your furthest rad is on the second floor then the secondary needed would be the pipe that splits off from the primary to take heat to the second floor.'
      }
    />}
  />
}

export const IndexEmitterFormLabel = () => {
  return <FormLabel
    labelText={'Index Emitter'}
    size={'XL'}
    helperText={'Enter the details of the emitter on the index circuit and the pipe that serves it.'}
    info={<Info
      infoModalHeader={'What is the index emitter?'}
      infoModalBody={'The emitter at the end of your index circuit. It\'s typically the emitter that\'s furthest from the heat pump or low loss header, but it could be a different emitter if it\'s particularly large or on particularly small pipes.'}
    />
    }
  />
}

export const IndexCircuitInfo = () => {
  return <Info
    infoModalHeader={'What is the index circuit?'}
    infoModalBody={'The index circuit is the route from the heat pump to an emitter and back with the highest pressure drop.' +
                  ' Typically this is the route to the furthest rad, but it could be to a particularly large emitter' +
                  ' or to an emitter on particularly small pipes. If you have a buffer/low loss header then it\'s the route from the buffer to the the furthest rad.'}
  ></Info>
}

export const PRIMARY_PIPE_LENGTH_HELPER_TEXT = 'From the heat pump to the first split in the space heating circuit.'
export const SECONDARY_PIPE_LENGTH_HELPER_TEXT = 'The length of pipe that splits from the primary up to the individual pipe splitting off to feed the index emitter'
export const INDEX_PIPE_LENGTH_HELPER_TEXT = 'The length of pipe that serves only this emitter'
