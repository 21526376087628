import React from 'react'
import { SurveyElectricsContext } from '../electrics'
import { ClickableCard } from '../../../../components/content_display/card'
import { Input } from '../../../../components/inputs_and_selections/input'
import { TextArea } from '../../../../components/inputs_and_selections/text_area'
import { Toggle } from '../../../../components/inputs_and_selections/toggle'

export const ElectricsDirectLoadsBlock = () => {
  const electricsContext = React.useContext(SurveyElectricsContext)
  const { survey, setSurvey } = electricsContext!

  return <>
    <div className="text-gray-900 text-xl font-bold">Direct loads</div>
    <div className="flex-col gap-5 flex">
      <ClickableCard variant='GREY'>
        <div className='flex flex-col gap-5'>
          <div className="gap-3 flex items-center justify-between">
            <div className="flex-col flex">
              <div className="text-gray-900 font-bold">Electric car charger</div>
              <div className="text-gray-500 text-sm">The property has an electric car charger fitted.</div>
            </div>
            <Toggle value={survey.electrics_has_car_charger} setValue={() => setSurvey({
              ...survey,
              electrics_has_car_charger: !survey.electrics_has_car_charger
            })}/>
          </div>
          {survey.electrics_has_car_charger && <>
            <div className='flex flex-col gap-2'>
              <div className='font-bold text-gray-900'>Charger fuse size</div>
              <Input
                type='number'
                value={survey.electrics_car_charger_fuse_size_amps.toString() ?? ''}
                setValue={(e) => setSurvey({ ...survey, electrics_car_charger_fuse_size_amps: Number(e) })}
                postfix='amps'/>
            </div>
            <div className='flex gap-2 justify-between'>
              <div className='font-bold text-gray-900'>Load limiting device</div>
              <Toggle value={survey.electrics_car_charger_is_load_limiting} setValue={() => setSurvey({
                ...survey,
                electrics_car_charger_is_load_limiting: !survey.electrics_car_charger_is_load_limiting
              })}/>
            </div>
          </>}
        </div>
      </ClickableCard>
      <ClickableCard variant='GREY'>
        <div className='flex flex-col gap-5'>
          <div className="gap-3 flex items-center justify-between">
            <div className="flex-col flex">
              <div className="text-gray-900 font-bold">Battery</div>
              <div className="text-gray-500 text-sm">A home battery is installed</div>
            </div>
            <Toggle value={survey.electrics_has_battery}
              setValue={() => setSurvey({ ...survey, electrics_has_battery: !survey.electrics_has_battery })}/>
          </div>
          {survey.electrics_has_battery && <>
            <div className='flex flex-col gap-2'>
              <div className='font-bold text-gray-900'>Notes</div>
              <TextArea value={survey.electrics_battery_notes}
                setValue={(e) => setSurvey({ ...survey, electrics_battery_notes: e })}/>
            </div>
          </>}
        </div>
      </ClickableCard>
      <ClickableCard variant='GREY'>
        <div className='flex flex-col gap-5'>
          <div className="gap-3 flex items-center justify-between">
            <div className="flex-col flex">
              <div className="text-gray-900 font-bold">Photovoltaic cell (PV)</div>
              <div className="text-gray-500 text-sm">Solar PV is installed at the property.</div>
            </div>
            <Toggle value={survey.electrics_has_solar}
              setValue={() => setSurvey({ ...survey, electrics_has_solar: !survey.electrics_has_solar })}/>
          </div>
          {survey.electrics_has_solar && <>
            <div className='flex flex-col gap-2'>
              <div className='font-bold text-gray-900'>Notes</div>
              <TextArea value={survey.electrics_solar_notes}
                setValue={(e) => setSurvey({ ...survey, electrics_solar_notes: e })}/>
            </div>
            <div className='flex justify-between items-center gap-2'>
              <div className='flex flex-col'>
                <div className='font-bold text-gray-900'>Solar diverter</div>
                <div className='text-gray-500 text-sm'>The customer would like a solar diverter installed.</div>
              </div>
              <Toggle value={survey.electrics_solar_wants_diverter} setValue={() => setSurvey({
                ...survey,
                electrics_solar_wants_diverter: !survey.electrics_solar_wants_diverter
              })}/>
            </div>
          </>}
        </div>
      </ClickableCard>
      <ClickableCard variant='GREY'>
        <div className="justify-between items-center gap-3 flex">
          <div className="flex-col flex">
            <div className="text-gray-900 font-bold">Hot tub</div>
          </div>
          <Toggle value={survey.electrics_has_hot_tub}
            setValue={() => setSurvey({ ...survey, electrics_has_hot_tub: !survey.electrics_has_hot_tub })}/>
        </div>
      </ClickableCard>
    </div>
  </>
}
