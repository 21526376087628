import { chain } from 'lodash'
import React, { useState } from 'react'
import { Modal } from '../../../../components/containers/modal'
import { type TableColumn, TableLite } from '../../../../components/content_display/table_lite'
import { getHeatPumpScopAtFlowTemp, type RangeHeatPump } from '../../../../code/models/range_heat_pump'
import { type InventoryHeatPump } from '../../../../code/models/inventory'
import { Input } from '../../../../components/inputs_and_selections/input'
import { Select } from '../../../../components/inputs_and_selections/select'
import { type Pack } from '../../../../code/models/packs'
import { FormLabel } from '../../../../components/inputs_and_selections/form_label'
import { Text } from '../../../../components/content_display/text'

type HeatPumpModalProps = {
  heatPump: InventoryHeatPump
  setVisible: () => void
  updateHeatPump: (heatPump: InventoryHeatPump) => Promise<void>
  packs: Pack[]
}

export const HeatPumpModal = ({ heatPump, setVisible, updateHeatPump, packs }: HeatPumpModalProps) => {
  const { scopRows, scopColumns } = getHeatPumpScopRowsAndColumns(heatPump.range_heat_pump)
  const { capacityRows, capacityColumns } = getHeatPumpCapacityRowsAndColumns(heatPump.range_heat_pump)
  const [selectedPackId, setSelectedPackId] = useState<string | undefined>(heatPump.default_pack_uuid ?? undefined)

  const packOptions = [
    { key: null, value: 'None' },
    ...packs.map(pack => ({ key: pack.uuid, value: pack.name }))
  ]

  const handleSetPack = async (packId: string | undefined) => {
    await updateHeatPump({ ...heatPump, default_pack_uuid: packId })
    setSelectedPackId(packId)
  }

  return <Modal title={heatPump.name} visible={true} setVisible={setVisible}>
    <div className='flex flex-col gap-6 w-full'>
      <Input label="Name" placeholder="Name" value={heatPump.name} setValue={(name) => updateHeatPump({ ...heatPump, name })} />
      <div className="flex flex-col gap-2">
        <FormLabel labelText='Associated pack' />
        <Select placeholder="Select a pack" options={packOptions} selectedKey={selectedPackId} setSelectedKey={handleSetPack} />
        <Text size="XS" className="text-gray-500">This pack will be automatically included when this heat pump is added to an estimate or quote.</Text>
      </div>
      {heatPump.range_heat_pump && <div className='grid grid-cols-3 gap-3 text-sm'>
        <div className='space-y-1'>
          <div className='text-gray-900 font-bold'>Refrigerant</div>
          <div className='text-gray-600'>{heatPump.range_heat_pump?.refrigerant}</div>
        </div>
        <div className='space-y-1'>
          <div className='text-gray-900 font-bold'>Serial number</div>
          <div className='text-gray-600'>{heatPump.range_heat_pump?.model_number}</div>
        </div>
        <div className='space-y-1'>
          <div className='text-gray-900 font-bold'>Sound power</div>
          <div className='text-gray-600'>{heatPump.range_heat_pump?.sound_power_max_dba} dB(A)</div>
        </div>
        <div className='space-y-1'>
          <div className='text-gray-900 font-bold'>Width</div>
          <div className='text-gray-600'>{heatPump.range_heat_pump?.width_mm} mm</div>
        </div>
        <div className='space-y-1'>
          <div className='text-gray-900 font-bold'>Height</div>
          <div className='text-gray-600'>{heatPump.range_heat_pump?.height_mm} mm</div>
        </div>
        <div className='space-y-1'>
          <div className='text-gray-900 font-bold'>Depth</div>
          <div className='text-gray-600'>{heatPump.range_heat_pump?.depth_mm} mm</div>
        </div>
        <div className='space-y-1'>
          <div className='text-gray-900 font-bold'>Weight</div>
          <div className='text-gray-600'>{heatPump.range_heat_pump?.weight_kg} kg</div>
        </div>
        <div className='space-y-1'>
          <div className='text-gray-900 font-bold'>MCS cert numbers</div>
          <div className='text-gray-600'>{heatPump.range_heat_pump?.mcs_certificate_numbers?.join(', ')}</div>
        </div>
      </div>}
      <div className='space-y-2'>
        <div className='text-gray-900 text-lg font-bold'>SCOP</div>
        <TableLite rows={scopRows} columns={scopColumns} />
      </div>
      <div className='space-y-2'>
        <div className='text-gray-900 text-lg font-bold'>Capacity (kW)</div>
        <TableLite rows={capacityRows} columns={capacityColumns} />
      </div>
    </div>
  </Modal>
}

export const getHeatPumpScopRowsAndColumns = (rangeHeatPump: RangeHeatPump | undefined) => {
  if (!rangeHeatPump) return { scopRows: [], scopColumns: [] }
  // Return an array even though always length 1 as easier if undefined to return empty array
  const scopRows = [{
    name: 'SCOP',
    at35: getHeatPumpScopAtFlowTemp(rangeHeatPump, 35),
    at40: getHeatPumpScopAtFlowTemp(rangeHeatPump, 40),
    at45: getHeatPumpScopAtFlowTemp(rangeHeatPump, 45),
    at50: getHeatPumpScopAtFlowTemp(rangeHeatPump, 50),
    at55: getHeatPumpScopAtFlowTemp(rangeHeatPump, 55)
  }]
  const scopColumns: Array<TableColumn<typeof scopRows[0]>> = [
    { name: '', key: 'name', render: (row) => <div>{row?.name}</div> },
    { name: '35°', key: 'at35', render: (row) => <div>{row?.at35}</div> },
    { name: '40°', key: 'at40', render: (row) => <div>{row?.at40}</div> },
    { name: '45°', key: 'at45', render: (row) => <div>{row?.at45}</div> },
    { name: '50°', key: 'at50', render: (row) => <div>{row?.at50}</div> },
    { name: '55°', key: 'at55', render: (row) => <div>{row?.at55}</div> }
  ]

  return { scopRows, scopColumns }
}

export const getHeatPumpCapacityRowsAndColumns = (rangeHeatPump: RangeHeatPump | undefined) => {
  if (!rangeHeatPump) return { capacityRows: [], capacityColumns: [] }

  // Not using the get capacity method as currently shows whatever outdoor temps we have hard data for
  const capacityRows = chain(rangeHeatPump.range_heat_pumps_by_flow_and_outside)
    .groupBy(x => x.outside_temperature_c)
    .map((values, key) => ({
      name: Number(key),
      at35: values.find(x => x.flow_temperature_c === 35)?.capacity,
      at40: values.find(x => x.flow_temperature_c === 40)?.capacity,
      at45: values.find(x => x.flow_temperature_c === 45)?.capacity,
      at50: values.find(x => x.flow_temperature_c === 50)?.capacity,
      at55: values.find(x => x.flow_temperature_c === 55)?.capacity
    }))
    .orderBy(x => x.name)
    .value()

  const capacityColumns: Array<TableColumn<typeof capacityRows[0]>> = [
    { name: 'Outside Temp', key: 'name', render: (row) => <div>{row.name}°C</div> },
    { name: '35°C', key: 'at35', render: (row) => <div>{row.at35}</div> },
    { name: '40°C', key: 'at40', render: (row) => <div>{row.at40}</div> },
    { name: '45°C', key: 'at45', render: (row) => <div>{row.at45}</div> },
    { name: '50°C', key: 'at50', render: (row) => <div>{row.at50}</div> },
    { name: '55°C', key: 'at55', render: (row) => <div>{row.at55}</div> }
  ]

  return { capacityRows, capacityColumns }
}
