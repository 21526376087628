import React, { useContext, type PropsWithChildren, type ReactNode } from 'react'
import { Badge } from '../../../../components/indicators_and_messaging/badge'
import { AdminContext } from '../../../admin/admin_layout'
import { CheckCircle, ChevronLeft, Menu, RefreshCw, Wifi } from 'lucide-react'
import { STATUS_ITEMS } from '../../../admin/job_layout/components/job_layout_header'
import { type LeadStatusType, type Lead } from '../../../../code/models/lead'
import { Select } from '../../../../components/inputs_and_selections/select'
import { type Colour } from '../../../../code/models/colour'
import { WrappedIcon } from '../../../../components/buttons/wrapped_icon'

type PageHeaderProps = {
  title: string
  isOffline: boolean
  allSynced?: boolean
  onBack?: () => void
  rightHandChildren?: ReactNode | undefined
  completed?: boolean
  lead?: Lead
  setLead?: (lead: Lead) => void
}

export const PageHeader = (props: PropsWithChildren<PageHeaderProps>) => {
  return <>
    <div className="px-5 pt-4 pb-3 gap-2 flex flex-col border-b border-gray-300 bg-white">
      <PageHeaderNoPadding {...props} />
    </div>
  </>
}

export const PageHeaderNoPadding = ({ title, onBack, children, rightHandChildren, completed, isOffline, allSynced, lead, setLead }: PropsWithChildren<PageHeaderProps>) => {
  const adminContext = useContext(AdminContext)

  const toggleSidebar = () => adminContext.isSidebarOpen
    ? adminContext.hideSidebar()
    : adminContext.showSidebar()

  const statusColour = lead && STATUS_ITEMS.find(x => x.key === lead.status)?.colour as Colour

  return <>
    <div className='grid md:grid-cols-[auto,1fr] grid-cols-1 gap-4'>
      <div className='flex gap-4 items-center cursor-pointer' onClick={onBack || toggleSidebar}>
        {onBack && <WrappedIcon icon={ChevronLeft} className='flex-shrink-0' />}
        {!onBack && <WrappedIcon icon={Menu} className='md:hidden flex-shrink-0' />}
        <div className='text-gray-900 text-xl font-bold'>{title}</div>
        {isOffline
          ? <Badge color='RED' text='Offline' icon={Wifi} />
          : allSynced
            ? <Badge color='GREEN' text='Synced' icon={CheckCircle} />
            : allSynced === undefined ? '' : <Badge color="YELLOW" icon={RefreshCw} text="Sync pending..." />}
      </div>
      {((lead && setLead) || rightHandChildren || completed) && <div className='flex gap-2 justify-end items-center'>
        {lead && setLead && <div><Select colour={statusColour} options={STATUS_ITEMS} selectedKey={lead.status} setSelectedKey={async (e: LeadStatusType) => {
          setLead({ ...lead, status: e })
        }} /></div>}
        {rightHandChildren}
        {completed !== undefined && <Badge color={completed ? 'GREEN' : 'LIGHT'} text={completed ? 'Complete' : 'Incomplete'} />}
      </div>}
    </div>
    {children}
  </>
}
