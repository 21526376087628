import { type SurveyImage } from './property'

export type Manifold = {
  uuid: string | undefined
  name: string

  location_description: string
  photos: SurveyImage[]

  ufh_temp_differs_from_system_temp: boolean
  max_mean_water_temp_c: number | undefined // Mean water temp can fall below this if system temp is lower but not rise above it
  // Define this rather than flow and return because a) you can validate that we have data for the input they entered and b) it's clearer how to decide whether the system temp is higher or lower than the manifold temp

  /**
   * @deprecated This property is superseded by max_mean_water_temp_c
   */
  flow_temp_c: number | undefined
  /**
   * @deprecated This property is superseded by max_mean_water_temp_c
   */
  return_temp_c: number | undefined

  number_of_loops: number | undefined
}

// If design flow temp and design delta T passed then use those parameters if the design flow temp is less than the manifold flow temp
export const getManifoldMeanWaterTempC = (manifold: Manifold, designFlowTempC: number, designDeltaT: number): number => {
  const meanSystemTempC = designFlowTempC - designDeltaT / 2
  if (manifold.ufh_temp_differs_from_system_temp && manifold.max_mean_water_temp_c && manifold.max_mean_water_temp_c < meanSystemTempC) {
    return manifold.max_mean_water_temp_c
  }
  // Slightly unclear what really controls return temp, but for now assume that if design flow temp wins then so does design delta T
  return meanSystemTempC
}
