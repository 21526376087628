import React, { type Dispatch, type SetStateAction, useState } from 'react'
import { type PropertySurvey, type SurveyDesign, type SurveyImage } from '../../../../code/models/property'
import { type FileWrapper } from '../../file_wrapper'
import { PageHeader } from '../components/design_page_header'
import { ClickableCard } from '../../../../components/content_display/card'
import { Input } from '../../../../components/inputs_and_selections/input'
import { PhotoScroll } from '../../../../components/inputs_and_selections/photo_scroll'
import { SelectAHeatPumpPage } from '../../select_heat_pump'
import { SelectedHeatPump } from '../../selected_heat_pump'
import { SoundAssessmentPage } from '../../sound_assessment_page'
import { FormLabel } from '../../../../components/inputs_and_selections/form_label'
import { FlowTempSlider } from './emitter_design_page'
import { VerticalFormGroup } from '../../../../components/inputs_and_selections/vertical_form_group'
import { type InventoryHeatPump } from '../../../../code/models/inventory'

type HeatPumpDesignProps = {
  survey: PropertySurvey
  design: SurveyDesign
  setSurvey: (survey: PropertySurvey) => void
  setDesign: (design: SurveyDesign) => void
  currentHeatPump?: InventoryHeatPump
  totalHeatLossKW: number
  files: FileWrapper[]
  setFiles: Dispatch<SetStateAction<FileWrapper[]>>
  locationImages: SurveyImage[]
  setLocationImages: (locationImages: SurveyImage[]) => void
  soundCalculation: number
  heatPumps: InventoryHeatPump[]
  designTempC: number
  minFlowTemp: number
  maxFlowTemp: number
  scop: number
  companyUUID: string
  isOffline: boolean
  allSynced: boolean
}

export const HeatPumpDesign = ({
  survey,
  design,
  setSurvey,
  setDesign,
  currentHeatPump,
  totalHeatLossKW,
  files,
  setFiles,
  locationImages,
  setLocationImages,
  soundCalculation,
  heatPumps,
  designTempC,
  minFlowTemp,
  maxFlowTemp,
  scop,
  companyUUID,
  isOffline,
  allSynced
}: HeatPumpDesignProps) => {
  const [currentPage, setCurrentPage] = useState<string>('MAIN')

  const locationImagesHydrated = locationImages.map(x => files.find(y => y.uuid === x.image_uuid)!)

  if (currentPage === 'SELECT_HEAT_PUMP') {
    return <SelectAHeatPumpPage
      heatPumps={heatPumps}
      designTempC={designTempC}
      flowTempC={design.flow_temp}
      totalHeatLossKW={totalHeatLossKW}
      setCurrentPage={setCurrentPage}
      design={design}
      setDesign={setDesign}
      isOffline={isOffline}
      allSynced={allSynced}
    />
  }

  return <div className='flex flex-col h-full min-h-0'>
    <PageHeader isOffline={isOffline} allSynced={allSynced} title={'Heat pump'} onBack={() => window.history.back()} />
    <div className="p-5 bg-white flex-col gap-6 flex overflow-y-auto">

      <div className="flex-col gap-6 flex">
        <div className="text-gray-900 text-xl font-bold">Heat pump design</div>

        <SelectedHeatPump
          currentHeatPump={currentHeatPump}
          soundCalculation={soundCalculation}
          variant={'DESIGN'}
          designTempC={designTempC}
          scop={scop}
          design={design}
          totalHeatLossKW={totalHeatLossKW}
          setCurrentPage={setCurrentPage}
        />
        <FlowTempSlider
          flowTemp={design.flow_temp}
          setFlowTemp={(e) => setDesign({ ...design, flow_temp: e })}
          minFlowTemp={minFlowTemp}
          maxFlowTemp={maxFlowTemp}
        />
        <VerticalFormGroup
          formLabel={
            <FormLabel
              labelText={'Target delta T'}
              helperText={'Enter the target temperature drop between the heat pump flow and return.'}
            />}
          input={
            <Input postfix='°C'
              type='number'
              value={design.delta_t_flow_return_c.toString()}
              setValue={(e) => setDesign({ ...design, delta_t_flow_return_c: Number(e) })}/>
          }
        />
      </div>

      <div className="border border-gray-200"></div>

      {/* Survey summary */}
      <div className="flex-col gap-6 flex">
        <div className="text-gray-900 text-xl font-bold">Survey summary</div>

        <ClickableCard variant='WHITE'>
          <div className="flex-col flex text-xs font-semibold">
            <div className="py-2 justify-between gap-2 flex">
              <div className="text-gray-600 text-xs font-semibold">Heat loss at {designTempC} °C</div>
              <div
                className="text-right text-gray-600 text-xs font-semibold">{totalHeatLossKW.toFixed(2)} kW
              </div>
            </div>
            <div className="py-2 border-t border-gray-200 justify-between gap-2 flex">
              <div>Dimensions (WxHxD)</div>
              <div>{survey.available_space_width_mm} x {survey.available_space_height_mm} x {survey.available_space_depth_mm} mm</div>
            </div>
            <div className="py-2 border-t border-gray-200 justify-between gap-2 flex">
              <div>Location</div>
              <div>{survey.location_description}</div>
            </div>
            <div className="py-2 border-t border-gray-200 justify-between gap-2 flex">
              <div>Mounted on</div>
              {survey.mounting_location !== 'Flat roof' && <div>{survey.mounting_location}</div>}
              {survey.mounting_location === 'Flat roof' &&
                <div>{`${survey.mounting_location} (${survey.roof_height_m}m)`}</div>}
            </div>
            {survey.mounting_location === 'Ground' &&
              <div className="py-2 border-t border-gray-200 justify-between gap-2 flex">
                <div>Base to be built by</div>
                <div>{survey.base_to_be_build_by}</div>
              </div>}
            <div className="py-2 border-t border-gray-200 justify-between gap-2 flex">
              <div>Condensate drain</div>
              <div>{survey.condensate_drain}</div>
            </div>
          </div>
        </ClickableCard>

        <div className="flex-col gap-3 flex">
          <PhotoScroll
            images={locationImagesHydrated}
            deleteImage={(id) => {
              setLocationImages(locationImages.filter(x => x.image_uuid !== id))
            }}
            addImage={(image) => {
              setFiles(prev => [...prev, { ...image, created_at: new Date().getTime(), updated_at: new Date().getTime(), is_modified: true }])
              const newLocationImage = {
                uuid: crypto.randomUUID(),
                image_uuid: image.uuid!,
                entity_uuid: 'survey',
                entity_attribute: 'location_images'
              }
              setLocationImages([...locationImages, newLocationImage])
            }}
            companyUUID={companyUUID}
          />
        </div>
      </div>

      <div className="border border-gray-200"></div>

      {/* Sound assessment */}
      <SoundAssessmentPage
        survey={survey}
        setSurvey={setSurvey}
        files={files}
        setFiles={setFiles}
        soundCalculation={soundCalculation}
        currentHeatPump={currentHeatPump}
        companyUUID={companyUUID}
      />

    </div>
  </div>
}
