import { type Company, getCompanyDeclinedEmailTemplate } from '../../../../../code/models/company'
import React from 'react'
import { Section } from '../../../../../components/containers/section'
import { Tiptap } from '../../../../../components/inputs_and_selections/tiptap'
import {
  TTPlaceholderCompanyName,
  TTPlaceholderCompanyPhone,
  TTPlaceholderCustomerAddress,
  TTPlaceholderCustomerName
} from '../../../../../code/tiptap_placeholders'

export const DeclineEnquiryEmailTemplateBlock = ({ company, setCompany }: { company: Company, setCompany: (c: Company) => void }) => {
  return <Section border title="Decline enquiry email template">
    <div className='flex flex-col gap-4'>
      <Tiptap
        editable={true}
        className='w-full rounded border border-gray-300 p-2 focus:outline-none'
        onUpdateCallback={async (editor) => {
          setCompany({
            ...company,
            decline_template: JSON.stringify(editor.getJSON())
          })
        }}
        placeholders={[
          TTPlaceholderCustomerName,
          TTPlaceholderCustomerAddress,
          TTPlaceholderCompanyName,
          TTPlaceholderCompanyPhone
        ]}
        content={getCompanyDeclinedEmailTemplate(company.decline_template ?? '')}
      />
    </div>
  </Section>
}
