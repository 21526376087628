import React from 'react'

export type LinkProps = {
  onClick: (e: React.MouseEvent) => void
  text: string
  disabled?: boolean
  className?: string
}
export const Link = ({ onClick, disabled, className, text }: LinkProps) => {
  return <div data-cy="link" onClick={(e) => !disabled && onClick(e)} className={`cursor-pointer text-sky-800 font-bold underline ${className}`}>{text}</div>
}
