import { EPCRatingSchema, getEpcRecommendations } from './epc'
import { getAddressIncludingPostcode, type Lead, patchLead } from './lead'
import { type Floor } from './floor'
import { getExposedFloorMaterial, type Material, type MaterialsSet } from './material'
import {
  DEFAULT_DESIGN_RADIATOR,
  DEFAULT_PROPERTY_SURVEY,
  DEFAULT_QUOTE_LINE_ITEM,
  DEFAULT_SURVEY_DESIGN,
  DEFAULT_SURVEY_DESIGN_BOILER,
  DEFAULT_SURVEY_DOOR,
  DEFAULT_SURVEY_FLOOR,
  DEFAULT_SURVEY_IMAGE,
  DEFAULT_SURVEY_RADIATOR,
  DEFAULT_SURVEY_ROOM,
  DEFAULT_SURVEY_WALL,
  DEFAULT_SURVEY_WINDOW
} from '../survey_defaults'
import { geocodeAddress } from '../geocoding'
import { type Manifold } from './manifold'
import { type RadiatorModel } from './radiator_model'
import { type SyncItem } from '../../pages/admin/sync'
import { type Emitter } from './radiator'
import { EstimateAgeBandSchema, type SurveyAgeBand } from './age_bands'
import { z } from 'zod'
import { FloorAreaUnitSchema } from '../extra_types'
import { type THERMAL_BRIDGING_ADDITIONS } from '../../pages/heat_loss/constants'

export const DEFAULT_PROPERTY: Property = {
  roomHeight: 2.4,
  address: '',
  yearBuilt: '',
  postcode: '',
  floorArea: 0,
  floorAreaUnits: 'sqm',
  noBathrooms: 0,
  noBedrooms: 0,
  builtForm: '',
  propertyType: '',
  wallGroup: '',
  wallType: '',
  floorType: 'None',
  loftInsulation: '',
  windowType: '',
  fuelType: ''
}

// Set by installer on the lead detail page
export const HouseOverridesSchema = z.object({
  airChangeOverride: z.coerce.number().optional(),
  designTempOverride: z.coerce.number().optional(),
  internalTempOverride: z.coerce.number().optional(),
  externalWallUValueOverride: z.coerce.number().optional(),
  partyWallUValueOverride: z.coerce.number().optional(),
  windowsUValueOverride: z.coerce.number().optional(),
  floorUValueOverride: z.coerce.number().optional(),
  roofUValueOverride: z.coerce.number().optional(),
  floorArea: z.coerce.number().optional(),
  roomHeight: z.coerce.number().optional(),
  noBedrooms: z.coerce.number().optional(),
  noBathrooms: z.coerce.number().optional(),
  propertyType: z.string().optional(), // TODO later: make enum
  builtForm: z.string().optional(), // TODO later: make enum
  wallGroup: z.string().optional(), // TODO later: make enum
  wallType: z.string().optional(), // TODO later: make enum
  floorType: z.string().optional(), // TODO later: make enum
  loftInsulation: z.string().optional(), // TODO later: make enum
  windowType: z.string().optional(), // TODO later: make enum
  fuelType: z.string().optional(), // TODO later: make enum
  construction_age_band_uuid: z.string().optional(), // TODO later: make enum
  outdoorSpace: z.string().optional() // TODO later: make enum
})

export type HouseOverrides = z.infer<typeof HouseOverridesSchema>

export const PropertySchema = z.object({
  uuid: z.string().optional(),
  address: z.string(),
  postcodeLocation: z.array(z.number()).optional(), // lat, long  // TODO later: should just be called location or latLng as may not be from postcode
  altitudeM: z.number().optional(),
  yearBuilt: z.union([z.string(), z.number()]).optional(),
  postcode: z.string(),
  uprn: z.string().optional(),
  udprn: z.string().optional(),
  floorArea: z.number(),
  roomHeight: z.number(),
  noBedrooms: z.coerce.number(),
  noBathrooms: z.coerce.number(),
  builtForm: z.string(), // TODO later: make enum
  propertyType: z.string(), // TODO later: make enum
  wallGroup: z.string().optional(), // TODO later: make enum
  wallType: z.string().optional(), // TODO later: make enum
  floorType: z.string(), // TODO later: make enum
  loftInsulation: z.string().optional(), // TODO later: make enum
  windowType: z.string(), // TODO later: make enum
  fuelType: z.string(), // TODO later: make enum
  epcRating: EPCRatingSchema.optional(),

  construction_age_band_uuid: z.string().optional(), // Try to match to an age band so we can find materials via property age
  construction_age_band: EstimateAgeBandSchema.optional(), // This is only set if the user directly enters the age band, which thye only get a chance to in the case where there is no EPC

  // Below only defined if it's a new build EPC where the U value itself is passed rather than a description
  // TODO later: Set these to undefined not zero when they not defined. And write migration to set these to undefined if 0
  wallUValue: z.number().optional(),
  windowUValue: z.number().optional(),
  roofUValue: z.number().optional(),
  floorUValue: z.number().optional(),

  houseOverrides: HouseOverridesSchema.optional(),
  newDwelling: z.boolean().optional(),
  floorAreaUnits: FloorAreaUnitSchema.optional(),
  outdoorSpace: z.string().optional() // TODO later: make enum
})
export type Property = z.infer<typeof PropertySchema>

// to avoid mapping something twice, we flag things as mapped when it's done
// we're using the flagging approach because estimate might has more values to be mapped in the future and we need to map them skipping already mapped
export const ESTIMATE_MAPPED_MATERIALS = 0x0001
export const ESTIMATE_MAPPED_AGE_BAND = 0x0002
export const ESTIMATE_MAPPED_FUEL_TYPE = 0x0004
export const ESTIMATE_MAPPED_HEAT_PUMP = 0x0008
export const ESTIMATE_MAPPED_HOT_WATER_CYLINDER = 0x0010
export const ESTIMATE_MAPPED_DESIGN_TEMP = 0x0020
export const ESTIMATE_MAPPED_INDOOR_TEMP = 0x0040
export const ESTIMATE_MAPPED_ACH = 0x0080
export const ESTIMATE_MAPPED_QUOTE_LINE_ITEMS = 0x0100
export const ESTIMATE_MAPPED_PROPERTY_TYPE = 0x0200
export const ESTIMATE_MAPPED_BUILT_FORM = 0x0400
export const ESTIMATE_MAPPED_BEDROOMS_BATHROOMS = 0x0800
export const ESTIMATE_MAPPED_FLOW_TEMP_C = 0x1000

export type PropertySurveyWrapper = SyncItem & { company_uuid: string, data: PropertySurvey }
export type PropertySurvey = {
  uuid: string | undefined

  // creation timestamp
  created_at: number

  // BEGIN: pages completed per flow
  flags_site_details_pages_completed: number
  flags_electrics_pages_completed: number
  flags_hpl_pages_completed: number // heat pump location pages
  flags_ehs_pages_completed: number // existing heating system pages
  flags_cylinder_location_pages_completed: number
  flags_floorplan_pages_completed: number
  // END: pages completed per flow

  flags_estimate_mappings: number

  floors: Floor[]
  age_band?: SurveyAgeBand
  default_materials?: MaterialsSet
  property_type?: string
  built_form?: string

  location_description: string // should be `heat_pump_location_description`
  assessment_description: string
  location_images: SurveyImage[] // should be `heat_pump_location_images`
  current_primary_pipework_mm: number // FIXME:  this isn't used?
  primary_pipework_material: string // FIXME: this also isn't used
  sound_distance: number
  reflective_surfaces: number
  sound_barrier_uuid: string
  designs: SurveyDesign[]
  property_images: SurveyImage[]
  use_epc_performance: boolean
  epc_heating_kwh: number | undefined
  epc_hot_water_kwh: number | undefined
  epc_certificate_number: string

  bedrooms: number
  bathrooms: number
  occupants_override: number | undefined
  volume_per_person_l: number
  legionnaires_cycle_weeks: number
  legionnaire_cycle_in_heat_pump: boolean

  // Settings flags
  intermittent_heating: boolean
  intermittent_heating_oversize_factor_percentage: number // 0-100
  exposed_location: boolean
  include_thermal_bridging: boolean
  thermal_bridging_addition_key: typeof THERMAL_BRIDGING_ADDITIONS[number]['key']
  mvhr_installed: boolean
  use_cibse_indoor_temps: boolean
  indoor_temp_overall_c: number
  use_cibse_air_change_values: boolean
  air_change_year_uuid: string
  air_change_per_hour_overall: number
  design_temp_override_c?: number
  altitude_override_m?: number

  // Site details flags
  parking_available: boolean
  permit_required: boolean
  space_for_kit_to_be_delivered: boolean
  notes: string
  stopcock_works: boolean
  stopcock_location: string
  stopcock_images: SurveyImage[]
  size_of_water_main_mm: number
  mains_flow_rate_l_per_min: number
  mains_pressure_bar: number

  // Heat pump
  available_space_width_mm: number
  available_space_depth_mm: number
  available_space_height_mm: number
  /**
   * @deprecated This property is superseded by available_space_width_mm
   */
  available_space_width_m?: number | undefined
  /**
   * @deprecated This property is superseded by available_space_depth_mm
   */
  available_space_depth_m?: number | undefined
  /**
   * @deprecated This property is superseded by available_space_height_mm
   */
  available_space_height_m?: number | undefined
  mounting_location: string
  base_to_be_build_by: string
  condensate_drain: string
  drain_photos: SurveyImage[]
  roof_height_m: number
  access_notes: string
  sound_assessment_images: SurveyImage[]

  // Planning
  within_1_metre_of_boundary: boolean
  listing_building: boolean
  conservation_area: boolean
  location_fronts_highway: boolean
  location_above_ground_storey: boolean
  location_closer_to_highway: boolean
  // Cylinder details
  has_existing_cylinder: boolean
  cylinder_photos: SurveyImage[]
  cylinder_available_width_mm: number
  cylinder_available_depth_mm: number
  cylinder_available_height_mm: number
  /**
   * @deprecated This property is superseded by cylinder_available_width_mm
   */
  cylinder_available_width_m?: number
  /**
   * @deprecated This property is superseded by cylinder_available_depth_mm
   */
  cylinder_available_depth_m?: number
  /**
   * @deprecated This property is superseded by cylinder_available_height_mm
   */
  cylinder_available_height_m?: number
  cylinder_location_description: string
  pipe_run_distance_metre: number
  pipe_run_description: string
  existing_cylinder_photos: SurveyImage[]
  existing_cylinder_volume_l: number
  existing_cylinder_type: string
  existing_cylinder_coil_size: number
  existing_cylinder_brand_and_model: string
  existing_cylinder_location_description: string
  existing_cylinder_pipe_material: string
  existing_cylinder_pipe_size_uuid: string

  // Existing heating system
  existing_system_photos: SurveyImage[]
  existing_system_fuel_uuid: string
  existing_system_type_uuid: string
  existing_system_age_uuid: string
  existing_system_location: string
  existing_system_annual_consumption_kWh: number
  existing_system_p_per_unit_override?: number
  electricity_p_per_kwh_override?: number
  // TODO: should rename to primary_pipework_uuid
  existing_system_pipework_uuid: string
  primary_pipework_length_m: number | undefined
  existing_system_radiator_pipework_visibility: string
  existing_system_radiator_pipework_uuid: string

  /**
   * @deprecated This property should probably never have been included - let's see what users say
   */
  index_includes_secondary: boolean
  secondary_index_pipework_uuid: string | undefined
  secondary_index_pipework_length_m: number | undefined
  secondary_index_emitter_uuids: string[]
  index_emitter_uuid: string | undefined
  index_emitter_pipe_length_m: number | undefined

  // Electrics
  electrics_images: SurveyImage[]
  electrics_phase: string
  electrics_main_fuse_size_amps: number
  electrics_looped_service: boolean
  electrics_earthing_images: SurveyImage[]
  electrics_arrangement_uuid: string
  electrics_consumer_unit_images: SurveyImage[]
  electrics_spare_way: boolean
  electrics_breaker_make: string
  electrics_notes: string
  electrics_has_car_charger: boolean
  electrics_car_charger_fuse_size_amps: number
  electrics_car_charger_is_load_limiting: boolean
  electrics_has_battery: boolean
  electrics_battery_notes: string
  electrics_has_solar: boolean
  electrics_solar_notes: string
  electrics_solar_wants_diverter: boolean
  electrics_has_hot_tub: boolean
  electrics_mpan_number: string
  electrics_has_smart_meter: boolean
  electrics_peak_electricity_demand_kwh: number
  electrics_distance_from_board_to_heat_pump_m: number
  electrics_distance_from_cylinder_to_heat_pump_m: number
  electrics_cylinder_immersion_required: boolean
  electrics_distance_from_board_to_cylinder_m: number
  electrics_cable_notes: string
  electrics_consumer_unit_items: ElectricsConsumerUnitItem[]

  completed_sections: string[]

  manifolds: Manifold[]
  custom_radiator_models: RadiatorModel[]

  old_unit_vector?: boolean
}

export type ElectricsConsumerUnitItem = {
  uuid: string
  name: string
  fuse_size_a: number
  survey_property_uuid: string
}

export type SurveyImage = {
  uuid: string | undefined
  image_uuid: string
  entity_uuid?: string
  entity_attribute?: string
}

export type QuoteLineItemType = 'HEAT_PUMP' | 'CYLINDER' | 'PARTS' | 'LABOUR' | 'RADIATORS' | 'ADDITIONAL'
export type QuoteLineItem = {
  uuid: string | undefined
  name: string
  quantity?: number
  value: number
  type: QuoteLineItemType
}

export type SurveyDesign = {
  uuid: string | undefined
  survey_uuid: string | undefined
  delta_t_flow_return_c: number
  flow_temp: number
  antifreeze_percentage: number
  allowance_for_emitters_and_fittings: number | undefined
  current_heat_pump_uuid: string | undefined
  current_hot_water_cylinder_uuid: string | undefined
  hot_water_storage_temperature_c: number
  quote_line_items: QuoteLineItem[]

  boilers: SurveyDesignBoiler[] // was old comparison heating systems. Now not used.
  radiators: Emitter[]
  removed_radiator_uuids: string[]

  // Piping
  primary_pipework_uuid_override: string | undefined
  secondary_pipework_uuid_override: string | undefined
  index_pipework_uuid_override: string | undefined

  primary_pipework_length_m_override: number | undefined
  secondary_pipework_length_m_override: number | undefined
  index_pipework_length_m_override: number | undefined

  secondary_index_emitter_uuids: string[]
  removed_secondary_index_emitter_uuids: string[]
  index_emitter_uuid_override: string | undefined

  undersized_emitter_rooms: Array<{
    room_uuid: string
    notes: string
  }>
}

export const pipeMaterials = ['Copper', 'PEX', 'MLCP']

export type SurveyDesignBoiler = {
  uuid: string | undefined
  code: string
  name?: string
  boiler_uuid: string
  price_per_kwh: number
  efficiency_percentage: number
  enabled: boolean

  survey_design_uuid: string | undefined
}

export const isPreFlowFeatureSurvey = (survey: PropertySurvey): boolean => {
  // disable flow for surveys created on or before 2024-08-05 00:00:00
  return !survey.created_at || survey.created_at <= Date.parse('2024-08-05')
}

export const ensurePropertySurveyBackwardsCompatibility = (survey: PropertySurvey, materials?: Material[]): PropertySurvey => {
  const result: PropertySurvey = {
    ...DEFAULT_PROPERTY_SURVEY,
    ...survey,

    created_at: survey.created_at || new Date().getTime(),

    // Migrations m to mm
    // Heat pump available space
    available_space_width_mm: survey.available_space_width_mm ? survey.available_space_width_mm : survey.available_space_width_m ? survey.available_space_width_m * 1000 : 0,
    available_space_width_m: undefined,
    available_space_height_mm: survey.available_space_height_mm ? survey.available_space_height_mm : survey.available_space_height_m ? survey.available_space_height_m * 1000 : 0,
    available_space_height_m: undefined,
    available_space_depth_mm: survey.available_space_depth_mm ? survey.available_space_depth_mm : survey.available_space_depth_m ? survey.available_space_depth_m * 1000 : 0,
    available_space_depth_m: undefined,
    // Cylinder available space
    cylinder_available_width_mm: survey.cylinder_available_width_mm ? survey.cylinder_available_width_mm : survey.cylinder_available_width_m ? survey.cylinder_available_width_m * 1000 : 0,
    cylinder_available_width_m: undefined,
    cylinder_available_height_mm: survey.cylinder_available_height_mm ? survey.cylinder_available_height_mm : survey.cylinder_available_height_m ? survey.cylinder_available_height_m * 1000 : 0,
    cylinder_available_height_m: undefined,
    cylinder_available_depth_mm: survey.cylinder_available_depth_mm ? survey.cylinder_available_depth_mm : survey.cylinder_available_depth_m ? survey.cylinder_available_depth_m * 1000 : 0,
    cylinder_available_depth_m: undefined,

    // Migrations to manifolds
    manifolds: !survey.manifolds ? [] : survey.manifolds.map(manifold => {
      return {
        ...manifold,
        ufh_temp_differs_from_system_temp: manifold.ufh_temp_differs_from_system_temp ?? true,
        max_mean_water_temp_c: manifold.max_mean_water_temp_c ?? (manifold.flow_temp_c && manifold.return_temp_c && (manifold.flow_temp_c + manifold.return_temp_c) / 2) ?? 40,
        flow_temp_c: undefined, // set these to undefined to avoid confusion
        return_temp_c: undefined
      }
    }
    )

  }
  // explicitly apply nested defaults
  // for floors
  result.floors = result.floors.map(floor => {
    return {
      ...DEFAULT_SURVEY_FLOOR,
      ...{
        ...floor,
        rooms: !floor.rooms ? [] : floor.rooms.map(room => {
          return {
            ...DEFAULT_SURVEY_ROOM,
            ...{
              ...room,
              walls: !room.walls ? [] : room.walls.map(wall => {
                return {
                  ...DEFAULT_SURVEY_WALL,
                  ...{
                    ...wall,
                    windows: !wall.windows ? [] : wall.windows.map(window => {
                      return {
                        ...DEFAULT_SURVEY_WINDOW,
                        ...window,
                        //   m to mm migration - delete once happy enough people have opened
                        width_mm: window.width_mm ? window.width_mm : window.width_m ? window.width_m * 1000 : 0,
                        width_m: undefined,
                        height_mm: window.height_mm ? window.height_mm : window.height_m ? window.height_m * 1000 : 0,
                        height_m: undefined
                      }
                    }),
                    doors: !wall.doors ? [] : wall.doors.map(door => {
                      return {
                        ...DEFAULT_SURVEY_DOOR,
                        ...door
                      }
                    })
                  }
                }
              }),
              radiators: !room.radiators ? [] : room.radiators.map(radiator => {
                radiator.survey_or_design = !radiator.survey_or_design ? radiator.type === 'DESIGN' ? 'DESIGN' : 'SURVEY' : radiator.survey_or_design
                radiator.emitter_type = !radiator.emitter_type as any ? (radiator.type === 'DESIGN' || radiator.type === 'SURVEY') ? 'RADIATOR' : radiator.type as any : radiator.emitter_type

                if (radiator.emitter_type === 'SECONDARY') return radiator
                if (radiator.emitter_type === 'UNDERFLOOR') {
                  return { ...radiator, pipe_model_uuid: radiator.pipe_model_uuid ?? (radiator.pipe_diameter_uuid === '16mm' ? 'pex_16' : 'pex_20') }
                }
                return {
                  ...DEFAULT_SURVEY_RADIATOR,
                  ...{
                    ...radiator,
                    // Rename radiator_diameter_uuid to radiator_model_uuid
                    pipe_model_uuid: radiator.pipe_model_uuid ? radiator.pipe_model_uuid : radiator.pipe_diameter_uuid ? radiator.pipe_diameter_uuid : undefined,
                    pipe_diameter_uuid: undefined,
                    // Remove this "migration" after confident that all radiators have pipe_model_uuid
                    photos: !radiator.photos ? [] : radiator.photos.map(photo => {
                      return {
                        ...DEFAULT_SURVEY_IMAGE,
                        ...photo
                      }
                    })
                  }
                }
              }),
              images: !room.images ? [] : room.images.map(image => {
                return {
                  ...DEFAULT_SURVEY_IMAGE,
                  ...image
                }
              })
            }
          }
        })
      }
    }
  })

  // for designs
  result.designs = !result.designs ? [] : result.designs.map(design => {
    return {
      ...DEFAULT_SURVEY_DESIGN,
      ...{
        ...design,
        // boilers: SurveyDesignBoiler[]
        quote_line_items: !design.quote_line_items ? [] : design.quote_line_items.map(item => {
          return {
            DEFAULT_QUOTE_LINE_ITEM,
            ...item
          }
        }),
        boilers: !design.boilers ? [] : design.boilers.map(boiler => {
          return {
            ...DEFAULT_SURVEY_DESIGN_BOILER,
            ...boiler
          }
        }),
        radiators: !design.radiators ? [] : design.radiators.map(radiator => {
          return {
            ...DEFAULT_DESIGN_RADIATOR,
            ...{
              ...radiator,
              // Set design rad to default rad pipework if not defined. This is a temporary migration
              // Ideally would do off paired survey rad, but not enough people using this that that's worth it
              pipe_model_uuid: (radiator.emitter_type === 'RADIATOR' || radiator.emitter_type === 'UNDERFLOOR') && radiator.pipe_model_uuid ? radiator.pipe_model_uuid : survey.existing_system_pipework_uuid
              // Remove this "migration" after confident that all radiators have pipe_model_uuid
            }
          }
        })
      }
    }
  })

  // for location_images
  result.location_images = !result.location_images ? [] : result.location_images.map(image => {
    return {
      ...DEFAULT_SURVEY_IMAGE,
      ...image
    }
  })

  // for drain_photos
  result.drain_photos = !result.drain_photos ? [] : result.drain_photos.map(photo => {
    return {
      ...DEFAULT_SURVEY_IMAGE,
      ...photo
    }
  })

  // for cylinder_photos
  result.cylinder_photos = !result.cylinder_photos ? [] : result.cylinder_photos.map(photo => {
    return {
      ...DEFAULT_SURVEY_IMAGE,
      ...photo
    }
  })

  // for existing_cylinder_photos
  result.existing_cylinder_photos = !result.existing_cylinder_photos ? [] : result.existing_cylinder_photos.map(photo => {
    return {
      ...DEFAULT_SURVEY_IMAGE,
      ...photo
    }
  })

  // for property_images
  result.property_images = !result.property_images ? [] : result.property_images.map(image => {
    return {
      ...DEFAULT_SURVEY_IMAGE,
      ...image
    }
  })

  // for electrics_images
  result.electrics_images = !result.electrics_images ? [] : result.electrics_images.map(image => {
    return {
      ...DEFAULT_SURVEY_IMAGE,
      ...image
    }
  })

  // Migration on exposed floor default
  // Will only run if materials passed in, which they are not in the "migrations" run of this, but that shouldn't be called again as it's from the sync change
  if (survey?.default_materials && !survey.default_materials.exposedFloor && materials) {
    survey.default_materials.exposedFloor = getExposedFloorMaterial(materials, survey.age_band)
  }

  return result
}

export const ensureLeadHasLocation3D = async (lead: Lead, companyUUID: string): Promise<Lead> => {
  // do not store if there is an information in property.location
  if (lead.property.postcodeLocation && lead.property.altitudeM) return lead

  const location3D = await geocodeAddress(getAddressIncludingPostcode(lead), lead.property.postcode)
  if (!location3D) return lead
  const newLead = {
    ...lead,
    property: {
      ...lead.property,
      postcodeLocation: lead.property.postcodeLocation ?? [location3D.lat, location3D.lng],
      altitudeM: lead.property.altitudeM ?? location3D.altitudeM
    }
  }
  await patchLead(lead.uuid!, newLead, companyUUID)
  return newLead
}

export const ensureLeadHasEPCRecommendations = async (lead: Lead, companyUUID: string, save: boolean): Promise<Lead> => {
  // do not store if there is an information in property.location
  if (!lead) return lead
  if (lead.epc_recommendations !== undefined && lead.epc_recommendations !== null) return lead

  const recommendations = lead.epcData?.lmkKey ? await getEpcRecommendations(lead.epcData.lmkKey) : []
  if (save) {
    await patchLead(lead.uuid!, { epc_recommendations: recommendations }, companyUUID)
  }
  return { ...lead, epc_recommendations: recommendations }
}
