import React, { type Dispatch, type SetStateAction, type FC, useRef } from 'react'
import { CanvasNav, type CanvasNavItem } from './canvas_nav'
import { type Floor } from '../../../code/models/floor'
import { FloorPlanInput } from './floor_buttons'
import { Slider } from '../../../components/inputs_and_selections/slider'
import { EyeOff, Eye, Upload, X, MinusCircle } from 'lucide-react'

type FloorPlanButtonsProps = {
  floor: Floor
  setFloor: (floor: Floor) => void
  setShowFloorPlanButtons: Dispatch<SetStateAction<boolean>>
  setTempImageAndScale: Dispatch<SetStateAction<{ image: string, scale: number }>>
  setStageStep: Dispatch<SetStateAction<number>>
  planOpacity: number
  setPlanOpacity: Dispatch<SetStateAction<number>>
}
export const FloorPlanButtons: FC<FloorPlanButtonsProps> = ({ floor, setFloor, setShowFloorPlanButtons, setTempImageAndScale, setStageStep, planOpacity, setPlanOpacity }) => {
  const fileInputRef = useRef<HTMLInputElement>(null)

  const showOrHide: CanvasNavItem = floor.floor_plan_is_showing
    ? { name: 'Hide', onClick: () => { setFloor({ ...floor, floor_plan_is_showing: false }); setShowFloorPlanButtons(false) }, icon: EyeOff, variant: 'DEFAULT' }
    : { name: 'Show', onClick: () => { setFloor({ ...floor, floor_plan_is_showing: true }); setShowFloorPlanButtons(false) }, icon: Eye, variant: 'DEFAULT' }

  const uploadOrRemove: CanvasNavItem = floor.floor_plan_image || floor.floor_plan_url
    ? {
        name: 'Remove',
        onClick: () => {
          setFloor({ ...floor, floor_plan_image: '', floor_plan_url: '', floor_plan_scale: 1 }); setShowFloorPlanButtons(false)
        },
        confirmText: 'Are you sure you want to remove the uploaded floor plan?',
        icon: MinusCircle,
        variant: 'DEFAULT'
      }
    : {
        name: 'Upload',
        onClick: () => {
          fileInputRef.current?.click()
        },
        icon: Upload,
        variant: 'DEFAULT'
      }

  return <>
    <CanvasNav title='Floor plan' headerContent={<div className='p-1 flex w-full'><Slider min={0} max={1} step={0.1} value={planOpacity} onChange={setPlanOpacity} /></div>} items={[
      showOrHide,
      uploadOrRemove,
      {
        name: 'Close',
        onClick: () => {
        // fileInputRef.current?.click()
          setShowFloorPlanButtons(false)
        },
        icon: X,
        variant: 'DEFAULT'
      }
    ]}
    />
    <FloorPlanInput inputRef={fileInputRef} setTempImageAndScale={setTempImageAndScale} setStageStep={setStageStep} />
  </>
}
